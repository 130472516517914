import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MapAddressForm from '../../../components/MapAddressForm';
import VehicleList from './vehicleList';
import PackageForm from './packageForm';
import RouteSummary from './routeSummary';
import PricingForm from './pricingForm';
import { show } from '../../../services/order';
import { UserContext } from '../../../context/UserProvider';

const App = () => {
  const { token } = useContext(UserContext);
  let { id } = useParams();
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [jobOrder, setJobOrder] = useState({
    sender: null,
    receiver: null,
    package: null,
    vehicle: null,
    stops: [],
    pricing: null,
  });

  // const handleBeforeUnload = (e) => {
  //   e.preventDefault();
  //   e.returnValue = 'Reloading will reset the form';
  // };

  useEffect(() => {
    loadPresetData();
    // window.addEventListener('beforeunload', handleBeforeUnload);

    // return () => {
    //   window.removeEventListener('beforeunload', handleBeforeUnload);
    // };
  }, []);

  const loadPresetData = () => {
    if (!id) {
      return;
    }

    show(token, id).then(res => {
      setJobOrder({
        ...res.data.data,
        'order-id': id,
      });
      setStep(7);
    });
  };

  useEffect(() => {
    console.log('jobOrder', jobOrder);
  }, [jobOrder]);

  if (step === 1) { // Sender
    return (
      <MapAddressForm
        labelForm="Pickup Information"
        labelMap="Pin Pickup Location"
        nextHandler={(data) => {
          setJobOrder({
            ...jobOrder,
            sender: data,
          });
          setStep(2);
        }}
        backHandler={() => navigate('/customer/dashboard')}
        showCod={false}
      />
    );
  } else if (step === 2) { // Vehicle
    return (
      <VehicleList
        label="Select Vehicle"
        data={jobOrder}
        backHandler={() => setStep(1)}
        nextHandler={(data) => {
          setJobOrder({
            ...jobOrder,
            vehicle: data,
          });
          setStep(3);
        }}
      />
    );
  } else if (step === 3) { // Package
    return (
      <PackageForm
        label="Package Declaration"
        data={jobOrder}
        backHandler={() => setStep(2)}
        nextHandler={(data) => {
          setJobOrder({
            ...jobOrder,
            package: data,
          });
          setStep(4);
        }}
      />
    );
  } else if (step === 4) { // Receiver
    return (
      <MapAddressForm
        labelForm="Receiver Information"
        labelMap="Pin Receiver Location"
        nextHandler={(data) => {
          setJobOrder({
            ...jobOrder,
            receiver: data,
          });
          setStep(5);
        }}
        backHandler={() => setStep(3)}
        showCod={true}
      />
    );
  } else if (step === 5) { // Route Summary
    return (
      <RouteSummary
        label="Add Stop"
        data={jobOrder}
        backHandler={() => setStep(4)}
        nextHandler={() => {
          setStep(7);
        }}
        updateHandler={(data) => {
          setJobOrder({
            ...jobOrder,
            stops: data,
          });
        }}
        setStep={setStep}
      />
    );
  } else if (step === 6) { // Stops
    return (
      <MapAddressForm
        labelForm="Add Stop Information"
        labelMap="Pin Stop Location"
        nextHandler={(data) => {
          setJobOrder({
            ...jobOrder,
            stops: [...jobOrder.stops, data],
          });
          setStep(5);
        }}
        backHandler={() => setStep(5)}
        showCod={true}
      />
    );
  } else if (step === 7) { // Pricing
    return (
      <PricingForm
        label="Pricing Information"
        data={jobOrder}
        backHandler={() => setStep(5)}
        nextHandler={(data) => {
          setJobOrder({
            ...jobOrder,
            pricing: data,
          });
          setStep(8);
        }}
        removeReloadWarning={(callback) => {
          // window.removeEventListener('beforeunload', handleBeforeUnload);
          // window.onbeforeunload = null;
          callback();
        }}
        isComputed={id ? true : false}
      />
    );
  }

  return null;
};

export default App;
