import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Grid, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { UserContext } from '../../../context/UserProvider';
import { list, listRegular } from '../../../services/order';
import { toTitleCase } from '../../../utils/helpers';
import { status as sameDayStatus } from '../../../config/sameDayStatus';
import { renderStatusLabel } from '../../../config/regularStatus';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import { styles } from '../../../config/defaultPageStyles';
import { accountBalance } from '../../../services/user';
import { moneyFormat } from '../../../utils/helpers';

TimeAgo.addDefaultLocale(en);

let regularLabels = {
  door2door: 'Door 2 Door',
  dropoff2dropoff: 'Dropoff 2 Dropoff',
  dropoff2door: 'Dropoff 2 Door',
  door2dropoff: 'Door 2 Dropoff',
};

let typeUrl = {
  door2door: 'door-2-door',
  dropoff2dropoff: 'dropoff-2-dropoff',
  dropoff2door: 'dropoff-2-door',
  door2dropoff: 'door-2-dropoff',
};

const CustomerDashboardPage = () => {
  const { token, logout } = useContext(UserContext);
  const navigate = useNavigate();
  const [sameDayOrders, setSameDayOrders] = useState([]);
  const [regularOrders, setRegularOrders] = useState([]);
  const [accountCredit, setAccountCredit] = useState(0);
  const timeAgo = new TimeAgo('en-US');
  const [withdrawProcess, setWithdrawProcess] = useState(false);

  const handleLogout = () => {
    const confirm = window.confirm('Are you sure you want to logout?');

    if (!confirm) {
      return;
    }

    logout();
    navigate('/');
  };

  const loadSameDayOrders = () => {
    list(token, {
      fields: {
        status: [
          'active',
          'waiting for payment',
          'driver matched',
          'driver on the way',
          'picked up',
          'delivery in progress',
        ],
      },
      operator: 'in',
    }).then(res => {
      setSameDayOrders(res.collection);
    });
  };

  const loadRegularOrders = () => {
    listRegular(token, {
      fields: {
        status: [
          'active',
          'waiting for payment',
          'driver matched',
          'driver on the way',
          'picked up',
          'delivery in progress',
          'dropoff origin',
          'dropoff origin main',
          'transfer inprogress',
          'dropoff destination main',
          'dropoff destination',
          'for delivery',
          'for pickup',
          'draft',
        ],
      },
      operator: 'in',
    }).then(res => {
      setRegularOrders(res.collection);
    });
  };

  const loadAccountCredit = () => {
    accountBalance(token).then(res => {
      setAccountCredit(res.balance);
    });
  };

  useEffect(() => {
    loadSameDayOrders();
    loadRegularOrders();
    loadAccountCredit();
  }, []);

  const onClickSameDayOrder = order => {
    if (order.data.status === 'waiting for payment') {
      navigate(`/customer/delivery/same-day/${order.raw.id}`);
    } else {
      navigate(`/customer/delivery/${order.raw.id}`);
    }
  };

  const onClickRegularOrder = order => {
    if (order.data.status === 'waiting for payment') {
      navigate(
        `/customer/delivery/regular/${typeUrl[order.data.type]}/${
          order.raw.id
        }`,
      );
    } else {
      navigate(`/customer/delivery/regular/${order.raw.id}`);
    }
  };

  const RenderOrders = () => {
    const renderSameDay = sameDayOrders.map((order, index) => {
      return (
        <div
          key={`same-day-order-${index}`}
          style={{
            width: '100%',
            backgroundColor: '#F5F5F5',
            padding: '10px',
            marginBottom: '10px',
            borderRadius: '5px',
            border: 'solid 1px gainsboro',
          }}
          onClick={() => onClickSameDayOrder(order)}>
          <Typography variant="body1">
            Same Day Delivery to {toTitleCase(order.data.data.receiver.city)}
          </Typography>
          <Typography variant="body2">
            {order.data?.data['reference-number']}
          </Typography>
          <Typography variant="body2">
            {sameDayStatus[order.data.status] ?? ''}
          </Typography>
          <Typography variant="body2">
            {timeAgo.format(new Date(order.raw.created_last))}
          </Typography>
        </div>
      );
    });
    
    const renderRegular = regularOrders.map((order, index) => {
      return (
        <div
          key={`same-day-order-${index}`}
          style={{
            width: '100%',
            backgroundColor: '#F5F5F5',
            padding: '10px',
            marginBottom: '10px',
            borderRadius: '5px',
            border: 'solid 1px gainsboro',
          }}
          onClick={() => onClickRegularOrder(order)}>
          <Typography variant="body1">
            Regular Delivery to {toTitleCase(order.data.data.receiver.city)}
            <br />
            <small>{regularLabels[order.data.type] ?? ''}</small>
          </Typography>
          <Typography variant="body2">
            {order.data?.data['reference-number']}
          </Typography>
          <Typography variant="body2">
            {renderStatusLabel(order.data.status, order.data.type)}
          </Typography>
          <Typography variant="body2">
            {timeAgo.format(new Date(order.raw.created_last))}
          </Typography>
        </div>
      );
    });

    if (sameDayOrders.length === 0 && regularOrders.length === 0) {
      return (
        <div
          style={{
            width: '100%',
            textAlign: 'center',
            fontSize: '1.2rem',
            paddingTop: '15px',
            paddingBottom: '15px',
          }}>
          No orders yet
        </div>
      );
    }

    return (
      <>
        <span
          style={{
            width: '100%',
            textAlign: 'center',
            fontSize: '1.2rem',
            paddingTop: '15px',
            paddingBottom: '15px',
          }}>
          Orders
        </span>
        {renderSameDay}
        {renderRegular}
      </>
    );
  };

  if (withdrawProcess) {
    return (
      <div style={styles.pageContainer}>
      <div style={styles.pageHeader}>Withdraw Process</div>
      <p>1) Send a message to our  <a href='https://www.facebook.com/boxtrackcargo' target='_blank'>Facebook page messenger</a></p>
      <p>2) Indate your login mobile number</p>
      <p>3) Indicate the amount you want to withraw</p>
      <p>3) Indicate the provider you want to use (Gcash, BDO, Unionbank, BPI)</p>
      <p>4) <strong>Example:</strong><br/> Account: 09999999999<br/> Withdraw: 1,000.00<br/> Provider: Gcash</p>
      <p>5) Wait for the confirmation withing 24hrs</p>
      <p>6) Once confirmed, our customer support will contact you</p>
      <p>Note: If you plan to withdraw funds using a bank account, we will verify the bank account. If the registered name on the account does not match with the name on the account you provide, your request may be declined.</p>
        <Button
          onClick={() => {
            setWithdrawProcess(false);
          }}
          variant="contained"
          fullWidth
          style={{ marginTop: '20px' }}
        >
         Back
        </Button>
      </div>
    );
  }

  return (
    <Grid container spacing={2} layout={'row'}>
      <Grid
        item
        xs={6}
        height={'10px'}
        style={{ fontWeight: 'bold', fontSize: '25px', color: '#8b8b8b' }}
        onClick={() => navigate('/customer/wallet')}
      >
        Wallet:
      </Grid>
      <Grid
        item
        xs={6}
        height={'10px'}
        textAlign={'right'}
        style={{ fontWeight: 'bold', fontSize: '25px', color: '#8b8b8b' }}
        onClick={() => setWithdrawProcess(true)}
      >
        PHP {moneyFormat(accountCredit || 0)}
      </Grid>
      <Grid item xs={12} height={'auto'} style={{marginTop: '20px'}}>
        <Link to="/customer/delivery/same-day" style={{ width: '100%' }}>
          <Card className="card" style={{ boxShadow: 'none', height: 'auto' }}>
            <img
              src="/img/dashboard-same-day-delivery.png"
              style={{ width: '100%' }}
            />
          </Card>
        </Link>
        {(process.env.REACT_APP_ENABLE_REGULAR_DELIVERY == 'true') && (
          <>
            <Link to="/customer/delivery/regular" style={{ width: '100%' }}>
              <Card
                className="card"
                style={{ boxShadow: 'none', height: 'auto' }}>
                <img
                  src="/img/dashboard-regular-delivery.png"
                  style={{ width: '100%' }}
                />
              </Card>
            </Link>
          </>
        )}
        {(process.env.REACT_APP_ENABLE_AFFILIATION == 'true') && (
          <>
            <Link to="/customer/affiliation" style={{ width: '100%' }}>
              <Card
                className="card"
                style={{ boxShadow: 'none', height: 'auto' }}>
                <img
                  src="/img/dashboard-affiliation.png"
                  style={{ width: '100%' }}
                />
              </Card>
            </Link>
          </>
        )}
      </Grid>
      <Grid item xs={12} height={'auto'}>
        <RenderOrders />
      </Grid>
      <Grid item xs={12} height={'auto'}>
        <Button onClick={() => handleLogout()} variant="contained" fullWidth>
          Sign Out
        </Button>
      </Grid>
    </Grid>
  );
};

export default CustomerDashboardPage;
