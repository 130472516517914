import React, { useState } from 'react';
import { QrReader } from 'react-qr-reader';
import { isMobile } from 'react-device-detect';

const QRCodeScanner = ({ onChange }) => {
  const handleScan = data => {
    if (data) {
      onChange(data);
    }
  };
 
  return (
    <div>
      <QrReader
        delay={300}
        onResult={(result, error) => {
          if (!!result) {
            handleScan(result?.text);
          }

          if (!!error) {
            console.error(error);
          }
        }}
        style={{ width: '100%' }}
        constraints={
          isMobile
            ? {
                facingMode: { exact: 'environment' },
              }
            : {}
        }
      />
    </div>
  );
};

export default QRCodeScanner;
