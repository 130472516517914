import React, { useContext, useState, useEffect } from 'react';
import Navbar from '../../../components/DesktopNavbar';
import { mainMenu } from '../../../config/dropoffMenu.jsx';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../context/UserProvider';
import { list } from '../../../services/manifest';
import { isMobile } from 'react-device-detect';
import { Button } from '@mui/material';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { toTitleCase } from '../../../utils/helpers.jsx';

const columns = isMobile ? [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'reference-id', headerName: 'Manifest Number', width: 270 },
  { field: 'name', headerName: 'Name', width: 270 },
  { field: 'origin', headerName: 'Origin', width: 270 },
  { field: 'destination', headerName: 'Destination', width: 270 },
  { field: 'status', headerName: 'Status', width: 270 },
  
] : [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'reference-id', headerName: 'Manifest Number', flex: 1 },
  { field: 'name', headerName: 'Name', flex: 1 },
  { field: 'origin', headerName: 'Origin', flex: 1 },
  { field: 'destination', headerName: 'Destination', flex: 1 },
  { field: 'status', headerName: 'Status', flex: 1 },
];

const App = () => {
  const navigate = useNavigate();
  const { token } = useContext(UserContext);
  const [rows, setRows] = useState([]);
  const [pageInfoCurrentPage, setPageInfoCurrentPage] = useState(0);
  const [pageInfoTotalRows, setPageInfoTotalRows] = useState(0);
  const [pageInfoRowPerPage, setPageInfoRowPerPage] = useState(25);
  const [loading, setLoading] = useState(true);

  const updatePageHandler = (pageInfo) => {
    loadData({
      'current-page': pageInfo.page + 1,
      'record-per-page': pageInfo.pageSize,
    })
  }

  const handleRecordSelect = (row) => {
    navigate(`/dropoff/manifest/${row.id}`)
  }

  const loadData = (payload) => {
    setLoading(true)
    list(token, {
      ...payload,
      'user-specific': true,
    }).then((res) => {
      const summary = [];
      res.collection.forEach((item) => {
        if (Object.keys(item.data).length === 0) {
            return;
        }

        summary.push({
          id: item.raw.id,
          ...item.data,
          status: toTitleCase(item.data.status),
        });
      });

      setRows(summary);
      setPageInfoCurrentPage(res['current-page']);
      setPageInfoRowPerPage(res['record-per-page']);
      setPageInfoTotalRows(res['data-total']);
      setLoading(false);
    });
  }

  useEffect(() => {
    loadData({
      'current-page': 1,
      'record-per-page': 25,
    })
  }, []);

  return (
    <>
      <Navbar pages={mainMenu} />
      <Container maxWidth={'xl'}>
      <Grid container spacing={2} style={{height: 'auto'}}>
        <Grid item xs={12} sm={6} style={{height: '100px'}}>
          <h1>My Manifest</h1>
        </Grid>
        <Grid item xs={12} sm={6} style={isMobile ? { height: '100px' } :{ height: '100px', paddingTop: '40px'}}>
            <Button
                variant="contained"
                onClick={() => navigate('/dropoff/manifest/create')}
                style={isMobile ? {} : {
                    width: '160px',
                    float: 'right',
                }}
            >
                Create 
            </Button>
        </Grid>
        <Grid item xs={12} sm={12}>
          <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  id: false,
                },
              },
              pagination: {
                paginationModel: {
                  pageSize: pageInfoRowPerPage,
                  page: pageInfoCurrentPage,
                },
              },
            }}
            pagination
            paginationMode="server"
            rowCount={pageInfoTotalRows}
            loading={loading}
            onPaginationModelChange={newPage => updatePageHandler(newPage)}
            disableColumnFilter
            disableColumnMenu
            onRowClick={handleRecordSelect}
          /> 
        </Grid>
      </Grid>
      </Container>
    </>
  );
};

export default App;
