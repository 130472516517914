import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { status } from '../../services/transaction';
import LoadingScreen from '../../components/LoadingScreen';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function TransactionView() {
  let query = useQuery();
  const [statusVal, setStatus] = useState(null);
  const [messageVal, setMessage] = useState(null);

  useEffect(() => {
    let id = query.get('refno');
    status({ id }).then(response => {
      setStatus(response.status);
      setMessage(response.message);
    });
  }, []);

  if (query.get('type') != 'payment') {
    return <>Invalid Transaction</>;
  }

  if (statusVal == null) {
    return (
      <>
        <LoadingScreen />
      </>
    );
  }

  return (
    <div style={{ margin: '20px', marginTop: '20px' }}>
      <p style={{ marginBottom: '10px', fontSize: '20px' }}>
        <strong>Transactions is {statusVal}</strong>
      </p>

      <p style={{ marginBottom: '0px', marginTop: '0px' }}>
        <strong>Reference #:</strong>
      </p>
      <p style={{ marginTop: '0px' }}>{query.get('refno')}</p>
      <p style={{ marginBottom: '0px', marginTop: '0px' }}>
        <strong>Transaction #:</strong>
      </p>
      <p style={{ marginTop: '0px' }}>{query.get('txnid')}</p>
      <p style={{ marginBottom: '0px', marginTop: '0px' }}>
        <strong>System Message:</strong>
      </p>
      {statusVal == 'Pending' ? (
        <>
          <p style={{ marginTop: '0px' }}>{query.get('message')}</p>
          <p>
            <small>
              <i>NOTE: PLEASE CHECK YOUR EMAIL FOR VERIFICATION</i>
            </small>
          </p>
        </>
      ) : (
        <>
          <p style={{ marginTop: '0px' }}>{messageVal}</p>
        </>
      )}
    </div>
  );
}

export default TransactionView;
