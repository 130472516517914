import React, { useContext, useState, useEffect } from 'react';
import Navbar from '../../../components/DesktopNavbar/admin.jsx';
import { mainMenu } from '../../../config/adminMenu.jsx';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../context/UserProvider';
import { getList } from '../../../services/dropoff';
import { listRegular } from '../../../services/order';
import { moneyFormat, toTitleCase } from '../../../utils/helpers';
import { isMobile } from 'react-device-detect';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { Button } from '@mui/material';
import { status, regularLabels } from '../../../config/regularStatus';
import TableSearch from '../../../components/TableSearch/index.jsx';

const columns = isMobile
  ? [
      { field: 'index', headerName: '#', width: 70},
      { field: 'id', headerName: 'ID', width: 70 },
      { field: 'reference-number', headerName: 'Order Number', width: 270 },
      { field: 'type', headerName: 'Type', width: 270 },
      { field: 'status', headerName: 'Status', width: 270 },
      { field: 'sender-city', headerName: 'Origin City', width: 270 },
      { field: 'sender-province', headerName: 'Origin Province', width: 270 },
      { field: 'receiver-city', headerName: 'Destination City', width: 270 },
      { field: 'receiver-province', headerName: 'Destination Province', width: 270 },
   
    ]
  : [
      { field: 'index', headerName: '#', width: 70},
      { field: 'id', headerName: 'ID', width: 70 },
      { field: 'reference-number', headerName: 'Order Number', flex: 1 },
      { field: 'type', headerName: 'Type', flex: 1 },
      { field: 'status', headerName: 'Status', flex: 1 },
      { field: 'sender-city', headerName: 'Origin City', flex: 1 },
      { field: 'sender-province', headerName: 'Origin Province', flex: 1 },
      { field: 'receiver-city', headerName: 'Destination City', flex: 1 },
      { field: 'receiver-province', headerName: 'Destination Province', flex: 1 },
    ];

const App = () => {
  const navigate = useNavigate();
  const { token, userAttr } = useContext(UserContext);
  const [rows, setRows] = useState([]);
  const [pageInfoCurrentPage, setPageInfoCurrentPage] = useState(0);
  const [pageInfoTotalRows, setPageInfoTotalRows] = useState(0);
  const [pageInfoRowPerPage, setPageInfoRowPerPage] = useState(25);
  const [loading, setLoading] = useState(true);

  const updatePageHandler = pageInfo => {
    loadData({
      'current-page': pageInfo.page + 1,
      'record-per-page': pageInfo.pageSize,
    });
  };

  const handleRecordSelect = row => {
    navigate(`/admin/orders-regular/${row.id}`);
  };

  const statusDisplay = (statusValue, typeValue) => {
    if (
      (typeValue === 'dropoff2dropoff' || typeValue === 'dropoff2door') &&
      statusValue === 'active'
    ) {
      return 'Waiting for the Parcel';
    }

    return status[statusValue] ?? 'Draft';
  }

  const loadData = payload => {
    setLoading(true);
    listRegular(token, {
      fields: {
        status: ['draft']
      },
      'operator': '!=',
      ...payload,
      'user-specific': false,
    }).then(res => {
      const summary = [];
      let indexCounter = res['data-from'] ?? 1;
      res.collection.forEach(item => {
        summary.push({
          index: indexCounter++,
          id: item.raw.id,
          ...item.data,
          status: statusDisplay(item.data.status, item.data.type),
          type: regularLabels[item.data.type],
        });
      });

      setRows(summary);
      setPageInfoCurrentPage(res['current-page']);
      setPageInfoRowPerPage(res['record-per-page']);
      setPageInfoTotalRows(res['data-total']);
      setLoading(false);
    });
  };

  useEffect(() => {
    loadData({
      'current-page': 1,
      'record-per-page': 25,
    });
  }, []);

  return (
    <>
      <Navbar pages={mainMenu} />
      <Container maxWidth={'xl'}>
        <Grid container spacing={2} style={{ height: 'auto' }}>
          <Grid item xs={12} sm={6} style={{ height: '100px' }}>
            <h1>Regular Order List</h1>
          </Grid>
          <Grid item xs={12} sm={6} style={{ height: '100px' }}>
            <TableSearch
              onSearch={value => {
                loadData({
                  fields: {
                    'reference-number': value,
                  },
                  'current-page': 1,
                  'record-per-page': 25,
                  operator: 'like',
                });
              }}
              label="Search Order Number"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <DataGrid
              rows={rows}
              columns={columns}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    id: false,
                  },
                },
                pagination: {
                  paginationModel: {
                    pageSize: pageInfoRowPerPage,
                    page: pageInfoCurrentPage,
                  },
                },
              }}
              pagination
              paginationMode="server"
              rowCount={pageInfoTotalRows}
              loading={loading}
              onPaginationModelChange={newPage => updatePageHandler(newPage)}
              disableColumnFilter
              disableColumnMenu
              onRowClick={handleRecordSelect}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default App;
