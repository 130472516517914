import React, { useContext, useState, useEffect, useRef } from 'react';
import Navbar from '../../../components/DesktopNavbar/admin.jsx';
import ImageLoader from '../../../components/ImageLoader';
import { mainMenu } from '../../../config/adminMenu.jsx';
import { UserContext } from '../../../context/UserProvider';
import { get } from '../../../services/driver';
import { create } from '../../../services/wallet';
import { moneyFormat, toTitleCase } from '../../../utils/helpers';
import { isMobile } from 'react-device-detect';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useNavigate, useParams } from 'react-router-dom';
import FormSelect from '../../../components/FormSelect';
import { Button, FormControl, TextField } from '@mui/material';
import CurrencyFormat from 'react-currency-format';
import ImageUpload from '../../../components/ImageUploader/index.jsx';

const tableStyle = {
  borderCollapse: 'collapse',
  width: '100%',
};

const thStyle = {
  border: '1px solid black',
  textAlign: 'left',
  padding: '8px',
  backgroundColor: '#f2f2f2',
};

const tdStyle = {
  border: '1px solid black',
  textAlign: 'left',
  padding: '8px',
};

const App = () => {
  const navigate = useNavigate();
  const { token, userAttr } = useContext(UserContext);
  const [data, setData] = useState(null);
  const [remarks, setRemarks] = useState('');
  const [transactionType, setTransactionType] = useState({
    label: 'Select Transaction Type',
    value: '',
  });
  const [amount, setAmount] = useState(0);
  const photoList = useRef([]);
  let { id, type } = useParams();

  const loadData = payload => {
    get(token, id).then(res => {
      setData(res);
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  const RowEntry = ({ label, value }) => {
    return (
      <tr>
        <td style={thStyle}>{label}</td>
        <td style={tdStyle}>{value}</td>
      </tr>
    );
  };

  const handleSubmit = () => {
    let confirm = window.confirm('Are you sure you want to submit?');

    if (!confirm) {
      return;
    }

    if (photoList.current.length < 1) {
      return alert('Please upload photo');
    } else if (transactionType.value === '') {
      return alert('Please select transaction type');
    } else if (amount == 0) {
      return alert('Please enter amount');
    }

    const transactionPhoto = photoList.current[0].id;

    let credit = 0;
    let debit = 0;
    if (transactionType.value === 'withdraw') {
      debit = amount;
    } else {
      credit = amount;
    }

    create(token, {
      'user-id': id,
      processor: transactionType.value,
      credit: credit,
      debit: debit,
      data: {
        description: transactionType.label,
        'transaction-photo': transactionPhoto,
        'remarks': remarks,
      },
    }).then(res => {
      alert('Transaction created successfully');
      navigate(-1);
    });
  };

  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Navbar pages={mainMenu} />
      <Container maxWidth={'xl'}>
        <Grid container spacing={2} style={{ height: 'auto' }}>
          <Grid item xs={12} sm={6} style={{ height: '100px' }}>
            <h1>Create Transaction</h1>
          </Grid>
          <Grid item xs={12} sm={6} style={{ height: '100px' }}>
            <Button
              color="error"
              variant="contained"
              onClick={() => handleSubmit()}
              style={
                isMobile
                  ? {}
                  : {
                      width: '160px',
                      float: 'right',
                      marginTop: '20px',
                      marginLeft: '20px',
                    }
              }>
              Submit
            </Button>
            <Button
              variant="contained"
              onClick={() => navigate(-1)}
              style={
                isMobile
                  ? {}
                  : {
                      width: '160px',
                      float: 'right',
                      marginTop: '20px',
                    }
              }>
              Back
            </Button>
          </Grid>
          <Grid item xs={12} sm={8} style={{ height: '500px' }}>
            {type === 'driver' && (
              <table style={tableStyle}>
                <tbody>
                  <tr>
                    <td
                      style={{
                        ...thStyle,
                        fontWeight: 'bold',
                        fontSize: '20px',
                        textAlign: 'center',
                        backgroundColor: '#dedff4',
                      }}
                      colSpan={2}>
                      Account Information
                    </td>
                  </tr>
                  <RowEntry
                    label="Account Status"
                    value={toTitleCase(data.user['status'])}
                  />
                  <RowEntry label="Mobile Number" value={data.user['login']} />
                  <RowEntry
                    label="Name"
                    value={`${data.driver['first-name']} ${data.driver['middle-name']} ${data.driver['last-name']}`}
                  />
                  <RowEntry
                    label="Birth Date"
                    value={data.driver['birth-date']}
                  />
                  <RowEntry
                    label="Address Line 1"
                    value={data.driver['address-line-1']}
                  />
                  <RowEntry
                    label="Address Line 2"
                    value={data.driver['address-line-2']}
                  />
                  <RowEntry label="City" value={data.driver['address-city']} />
                  <RowEntry
                    label="Province"
                    value={data.driver['address-province']}
                  />
                  <RowEntry
                    label="Zip Code"
                    value={data.driver['address-zip-code']}
                  />
                  <RowEntry
                    label="Email Address"
                    value={data.driver['email-address']}
                  />
                </tbody>
              </table>
            )}

            {type === 'customer' && (
              <table style={tableStyle}>
                <tbody>
                  <tr>
                    <td
                      style={{
                        ...thStyle,
                        fontWeight: 'bold',
                        fontSize: '20px',
                        textAlign: 'center',
                        backgroundColor: '#dedff4',
                      }}
                      colSpan={2}>
                      Account Information
                    </td>
                  </tr>
                  <RowEntry
                    label="Account Status"
                    value={toTitleCase(data.user['status'])}
                  />
                  <RowEntry label="Mobile Number" value={data.user['login']} />
                  <RowEntry
                    label="Name"
                    value={`${data.customer['first-name']} ${data.customer['middle-name']} ${data.customer['last-name']}`}
                  />
                  <RowEntry
                    label="Birth Date"
                    value={data.customer['birth-date']}
                  />
                  <RowEntry
                    label="Address Line 1"
                    value={data.customer['address-line-1']}
                  />
                  <RowEntry
                    label="Address Line 2"
                    value={data.customer['address-line-2']}
                  />
                  <RowEntry
                    label="City"
                    value={data.customer['address-city']}
                  />
                  <RowEntry
                    label="Province"
                    value={data.customer['address-province']}
                  />
                  <RowEntry
                    label="Zip Code"
                    value={data.customer['address-zip-code']}
                  />
                  <RowEntry
                    label="Email Address"
                    value={data.customer['email-address']}
                  />
                </tbody>
              </table>
            )}

            {type === 'dropoff-partners' && (
              <table style={tableStyle}>
                <tbody>
                  <tr>
                    <td
                      style={{
                        ...thStyle,
                        fontWeight: 'bold',
                        fontSize: '20px',
                        textAlign: 'center',
                        backgroundColor: '#dedff4',
                      }}
                      colSpan={2}>
                      Account Information
                    </td>
                  </tr>
                  <RowEntry
                    label="Account Status"
                    value={toTitleCase(data.user['status'])}
                  />
                  <RowEntry label="Mobile Number" value={data.user['login']} />
                  <RowEntry
                    label="Name"
                    value={`${data.dropoff['first-name']} ${data.dropoff['middle-name']} ${data.dropoff['last-name']}`}
                  />
                  <RowEntry
                    label="Birth Date"
                    value={data.dropoff['birth-date']}
                  />
                  <RowEntry
                    label="Address Line 1"
                    value={data.dropoff['address-line-1']}
                  />
                  <RowEntry
                    label="Address Line 2"
                    value={data.dropoff['address-line-2']}
                  />
                  <RowEntry label="City" value={data.dropoff['address-city']} />
                  <RowEntry
                    label="Province"
                    value={data.dropoff['address-province']}
                  />
                  <RowEntry
                    label="Zip Code"
                    value={data.dropoff['address-zip-code']}
                  />
                  <RowEntry
                    label="Email Address"
                    value={data.dropoff['email-address']}
                  />
                </tbody>
              </table>
            )}
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormSelect
              label="Transaction Type"
              value={transactionType}
              onChange={e => setTransactionType(e)}
              options={[
                { label: 'Select Transaction Type', value: '' },
                { label: 'Top-up', value: 'top-up' },
                { label: 'Withdraw', value: 'withdraw' },
              ]}
            />
           
            <br />
            <CurrencyFormat
              value={amount}
              displayType={'input'}
              thousandSeparator={true}
              prefix={'PHP '}
              decimalScale={2}
              fixedDecimalScale={true}
              onValueChange={values => {
                const { floatValue } = values;
                let temp = floatValue;

                if (isNaN(temp)) {
                  temp = 0;
                }

                setAmount(temp);
              }}
              customInput={TextField}
              placeholder="Transaction Amount *"
            />

            <ImageUpload
              name={'transactionPhoto'}
              type={'Wallet Transaction Photo'}
              title={'Please Upload Transaction Photo'}
              label={''}
              ref={photoList}
              defaultImage={null}
            />
            
            <TextField 
              label="Remarks"
              variant="outlined"
              fullWidth
              value={transactionType.remarks}
              onChange={e => setRemarks(e.target.value)}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default App;
