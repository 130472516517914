import React, { useState, useContext, useEffect } from 'react';
import Navbar from '../../../components/DesktopNavbar/admin.jsx';
import { mainMenu } from '../../../config/adminMenu.jsx';
import { status as statusLabels } from '../../../config/sameDayStatus';
import { isMobile } from 'react-device-detect';
import { show as showFile } from '../../../services/file';
import { UserContext } from '../../../context/UserProvider';
import { moneyFormat, toTitleCase } from '../../../utils/helpers';
import { styles } from '../../../config/defaultPageStyles';

import { show } from '../../../services/order';
import { show as showDriver } from '../../../services/driver';

import { Button } from '@mui/material';
import CallIcon from '@mui/icons-material/Call';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingScreen from '../../../components/LoadingScreen';

const stylesContainer = {
  sectionContainerAddress: {
    textAlign: 'left',
    backgroundColor: '#dedede',
    border: 'solid 1px #b7b7b7',
    padding: '5px 10px 15px 10px',
    borderRadius: '10px',
    marginBottom: '10px',
    fontSize: '15px',
    color: '#494949',
  },
  sectionContainerDriver: {
    textAlign: 'left',
    backgroundColor: '#ffff',
    border: 'solid 1px #b7b7b7',
    padding: '5px 10px 15px 10px',
    borderRadius: '10px',
    marginBottom: '10px',
    textAlign: 'center',
  },
  sectionHeader: {
    marginBottom: '0px',
    marginTop: '0px',
  },
  sectionData: {
    marginBottom: '0px',
    marginTop: '0px',
  },
  sectionDataTo: {
    fontWeight: 'bold',
  },
  sectionButton: {
    marginTop: '10px',
  },
  packageImage: {
    width: '100%',
    borderRadius: '10px',
    marginTop: '10px',
  },
};

const statusToCancel = ['active', 'draft', 'driver matched'];

const App = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const { token } = useContext(UserContext);
  const [orderId, setOrderId] = useState(null);
  const [jobOrder, setJobOrder] = useState(null);
  const [isAllowedToCancel, setIsAllowedToCancel] = useState(false);
  const [driverData, setDriverData] = useState(null);
  const [accountUid, setAccountUid] = useState(null);

  const loadOrder = () => {
    show(token, orderId).then(res => {
      console.log('Order Information', res.data);
      const originDriver = res.data?.['driver-id'] || null;
      loadDriver(originDriver);
      setJobOrder(res.data);
      setAccountUid(res.created_by);

      if (statusToCancel.includes(res?.data?.status || res?.status)) {
        setIsAllowedToCancel(true);
      }
    });
  };

  const loadDriver = driverId => {
    if (!driverId) {
      return;
    }

    showDriver(token, driverId).then(res => {
      setDriverData(res);
    });
  };

  // const handleCancelOrder = () => {
  //   window.confirm(
  //     'Are you sure you want to cancel this order? This action is irreversible.',
  //   ) &&
  //     update(
  //       token,
  //       {
  //         status: 'canceled',
  //       },
  //       orderId,
  //     ).then(res => {
  //       window.location.reload();
  //     });
  // };

  useEffect(() => {
    if (orderId) {
      loadOrder();
    }
  }, [orderId]);

  useEffect(() => {
    if (id) {
      setOrderId(id);
    }
  }, []);

  return (
    <>
      <Navbar pages={mainMenu} />
      <div style={{ margin: '10px' }}>
        {!jobOrder ? (
          <LoadingScreen />
        ) : (
          <>
            <div
              style={
                isMobile
                  ? styles.pageContainer
                  : {
                      width: '500px',
                      margin: 'auto',
                    }
              }>
              <div style={{ ...styles.pageHeader, marginBottom: '10px' }}>
                Same Day Order Information
                <br />
                <small style={{ fontSize: '12px' }}>
                  Status - {statusLabels[jobOrder?.status] ?? 'Draft'}
                </small>
                <br />
                <small style={{ fontSize: '12px' }}>
                  ORDER # {jobOrder?.['reference-number']}
                </small>
              </div>
              <PackageContainer data={jobOrder?.data?.package || {}} />
              <PricingContainer
                data={jobOrder?.data?.pricing}
                earning={jobOrder?.data?.earning}
                type={jobOrder?.['payment-type'] || 'draft'}
              />
               {jobOrder?.status == 'completed' && jobOrder?.data['proof-of-delivery'] && (
                <>
                  <ProofOfDeliveryContainer data={jobOrder?.data['proof-of-delivery']} />
                </>
              )}
              <AddressContainer
                id={'origin'}
                title={
                  jobOrder?.data?.dropoffOrigin
                    ? 'Sender Details'
                    : 'Origin Location'
                }
                data={jobOrder?.data?.sender || {}}
              />
              <AddressContainer
                id={'destination'}
                title={
                  jobOrder?.data?.dropoffDestination
                    ? 'Receiver Details'
                    : 'Destination Location'
                }
                data={jobOrder?.data?.receiver || {}}
              />
              <RenderHandler
                label={'Driver Assigned'}
                value={
                  driverData ? (
                    <>
                      {`${driverData?.driver?.['first-name']} ${driverData?.driver?.['last-name']}`}
                      <br />
                      {driverData?.user?.['login']}
                    </>
                  ) : (
                    'N/A'
                  )
                }
              />
              <div style={{ marginBottom: '10px' }}>
                {accountUid && (
                  <Button
                    onClick={() => navigate('/admin/customer/' + accountUid)}
                    variant="contained"
                    color="primary">
                    View Account Information
                  </Button>
                )}

                {/* {isAllowedToCancel && (
                  <Button
                    onClick={() => handleCancelOrder()}
                    variant="contained"
                    color="error"
                    style={{ marginTop: '10px' }}>
                    Cancel Order
                  </Button>
                )} */}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default App;

const PackageContainer = ({ data }) => {
  const { token } = useContext(UserContext);
  const [url, setUrl] = useState(null);

  useEffect(() => {
    if (data?.packagePhoto) {
      console.log('load file', data);
      loadFile();
    }
  }, []);

  const loadFile = () => {
    showFile(token, data?.packagePhoto).then(res => {
      setUrl(res?.data?.file || null);
    });
  };

  return (
    <div style={stylesContainer.sectionContainerAddress}>
      <p style={stylesContainer.sectionHeader}>
        <strong>Parcel Information</strong>
      </p>
      {url && (
        <p style={stylesContainer.sectionData}>
          <img src={url} style={stylesContainer.packageImage} />
        </p>
      )}
      <p style={stylesContainer.sectionData}>
        <strong>Parcel Description: </strong>
        <br />
        {data?.packageDescription || ''}
      </p>
      <p style={stylesContainer.sectionData}>
        <strong>Parcel Weight: </strong>
        <br />
        {data?.packageWeight || 'N/A'} KG
      </p>
    </div>
  );
};

const PricingContainer = ({ data, type, earning }) => {
  console.log('PRICING INFORMATION', { data, type });

  const RenderCod = () => {
    if (!data?.totalCodAmount || data?.totalCodAmount == 0) {
      return null;
    }

    return (
      <>
        <div>
          COD Amount:{' '}
          <span style={{ float: 'right' }}>
            PHP {moneyFormat(data?.totalCodAmount || 0)}
          </span>
        </div>
      </>
    );
  };

  const RenderDriverEarnings = () => {
    const wht = earning.wht || 0;
    const tax = earning.tax || 0;
    const net = earning.net || 0;
    const adminFee = earning.adminFee || 0;
    const driverEarning = earning.driverEarning || 0;
    const totalDriverEarning = earning.totalDriverEarning || 0;
    const amountToCollect = earning.amountToDeduct || 0;

    return (
      <>
        <div
          style={{
            borderTop: 'solid 1px #b7b7b7',
            paddingTop: '10px',
            marginTop: '10px',
          }}>
          <strong>Driver Earning Computation</strong>
        </div>
        <div>
          VAT (12%):{' '}
          <span style={{ float: 'right' }}>- PHP {moneyFormat(tax || 0)}</span>
        </div>
        <div style={{ fontWeight: 'bold'}}>
          NET:{' '}
          <span style={{ float: 'right' }}>PHP {moneyFormat(net || 0)}</span>
        </div>
        <div>
          Admin Fee (20%):{' '}
          <span style={{ float: 'right' }}>
            - PHP {moneyFormat(adminFee || 0)}
          </span>
        </div>
        <div>
          Your Earning (80%):{' '}
          <span style={{ float: 'right' }}>
            PHP {moneyFormat(driverEarning || 0)}
          </span>
        </div>
        <div>
          Withholding Tax (2%):{' '}
          <span style={{ float: 'right' }}>- PHP {moneyFormat(wht || 0)}</span>
        </div>

        {(data.totalCodAmount || data.totalCodAmount > 0) && (
          <>
            <div>
              COD Amount:{' '}
              <span style={{ float: 'right' }}>
                - PHP {moneyFormat(data?.totalCodAmount || 0)}
              </span>
            </div>
          </>
        )}

        <div style={{ fontWeight: 'bold', marginTop: '20px' }}>
          Driver NET Earning:{' '}
          <span style={{ float: 'right' }}>
            PHP {moneyFormat(totalDriverEarning || 0)}
          </span>
        </div>
        <div style={{ fontWeight: 'bold' }}>
          Total Deduction:{' '}
          <span style={{ float: 'right' }}>
            PHP {moneyFormat(amountToCollect || 0)}
          </span>
        </div>
      </>
    );
  };


  const RenderOrderComputation = () => {
    const headerStyle = {
      borderTop: '2px solid black',
      marginTop: '20px',
      paddingTop: '20px',
    };


    // check if key exist in object
    const isOldRecord = data?.totalDeliveryFee?.config?.fromKm === undefined;

    if (isOldRecord) {
      return null;
    }

    const totalDistance = data?.totalDistance || 0;

    return (
      <>
        <div style={headerStyle}>
          <strong>Service Variables</strong>
        </div>
        <div>
          Base Fare:{' '}
          <span style={{ float: 'right' }}>
            PHP {moneyFormat(data?.totalDeliveryFee?.totalBaseFee || 0)}
          </span>
        </div>

        {data?.longDistance && (
          <>
            <div>
              Long Distance Fee:{' '}
              <span style={{ float: 'right' }}>
                {data?.totalDeliveryFee?.config?.longDistancePercentage}%
              </span>
            </div>
          </>
        )}

        <div>
          Per KM Cost:
         
          <span style={{ float: 'right' }}>
            PHP {moneyFormat(data?.totalDeliveryFee?.config?.perKm || 0)}
          </span>
        </div>

        {data?.totalDeliveryFee?.config?.numberOfStops > 0 && (
          <div>
            Stop Fee:{' '}
            <span style={{ float: 'right' }}>
              PHP {moneyFormat(data?.totalDeliveryFee?.config?.stopsFee || 0)}
            </span>
          </div>
        )}

        {data?.totalCodFee > 0 && (
          <div>
            COD Fee:{' '}
            <span style={{ float: 'right' }}>
              PHP {moneyFormat(data?.totalCodFee || 0)}
            </span>
          </div>
        )}

        {data?.paymentProcessorFee > 0 && (
          <>
            <div>
              Convenience Fee:{' '}
              <span style={{ float: 'right' }}>
                <>PHP {moneyFormat(data?.paymentProcessorFee || 0)} </>
              </span>
            </div>
          </>
        )}

        <div>
          Total Distance:{' '}
          <span style={{ float: 'right' }}>
            {moneyFormat(data?.totalDistance || 0)} km
          </span>
        </div>

        <div style={headerStyle}>
          <strong>Order Cost Computation</strong>
        </div>
        <div>
          <strong><i>Distance:</i></strong>
          <br />
          {totalDistance} km X PHP{' '} 
          {moneyFormat(data?.totalDeliveryFee?.config?.perKm || 0)} =
          <span style={{ float: 'right' }}>
            <strong>
              PHP {moneyFormat(data?.totalDeliveryFee?.totalDistanceFee || 0)}
            </strong>{' '}
          </span>
        </div>

        {data?.totalDeliveryFee?.config?.numberOfStops > 0 && (
          <div>
            <strong>
              <i>Total Stop Fee:</i>
            </strong>{' '}
            <br />
            {data?.totalDeliveryFee?.config?.numberOfStops || 0} STOP/S X PHP{' '}
            {moneyFormat(data?.totalDeliveryFee?.config?.stopsFee || 0)} =
            <span style={{ float: 'right' }}>
              <strong>
                PHP {moneyFormat(data?.totalDeliveryFee?.totalStopsFee || 0)}
              </strong>{' '}
            </span>
          </div>
        )}

        {data?.longDistance && (
          <>
            <div>
              <strong>
                <i>Long Distance:</i>
              </strong>{' '}
              <br />
              PHP {moneyFormat(
                data?.totalDeliveryFee?.totalDistanceFee || 0,
              )} x {data?.totalDeliveryFee?.config?.longDistancePercentage}% =
              <span style={{ float: 'right' }}>
                <strong>
                  PHP{' '}
                  {moneyFormat(
                    data?.totalDeliveryFee?.totalLongDistanceFee || 0,
                  )}{' '}
                </strong>
              </span>
            </div>
          </>
        )}

        {data?.totalCodFee > 0 && (
          <>
            <div>
              <strong>
                <i>COD Fee:</i>
              </strong>{' '}
              <span style={{ float: 'right' }}>
                <strong>PHP {moneyFormat(data?.totalCodFee || 0)} </strong>
              </span>
            </div>
            <div>
              <strong>
                <i>COD Amount:</i>
              </strong>{' '}
              <span style={{ float: 'right' }}>
                <strong>PHP {moneyFormat(data?.totalCodAmount || 0)} </strong>
              </span>
            </div>
          </>
        )}

        {data?.paymentProcessorFee > 0 && (
          <>
            <div>
              <strong>
                <i>Convenience Fee:</i>
              </strong>{' '}
              <span style={{ float: 'right' }}>
                <strong>
                  PHP {moneyFormat(data?.paymentProcessorFee || 0)}{' '}
                </strong>
              </span>
            </div>
          </>
        )}

        <div>
          Base Fare:{' '}
          <span style={{ float: 'right' }}>
            <strong>
            PHP {moneyFormat(data?.totalDeliveryFee?.totalBaseFee || 0)}
            </strong>
          </span>
        </div>

        <div>
          Total:{' '}
          <span style={{ float: 'right', color: 'green' }}>
            <strong>
            PHP {moneyFormat(data?.total || 0)}
            </strong>
          </span>
        </div>

        {/* /////////// */}
      </>
    );
  };

  return (
    <div style={stylesContainer.sectionContainerAddress}>
      <div>
        Payment Type:{' '}
        <span style={{ float: 'right' }}>{toTitleCase(type ?? '')}</span>
      </div>
      <div>
        Sub Total:{' '}
        <span style={{ float: 'right' }}>
          PHP {moneyFormat(data?.subTotal || 0)}{' '}
        </span>
      </div>
      <RenderCod />
      <div style={{ fontSize: '25px' }}>
        Total:{' '}
        <span style={{ float: 'right' }}>
          PHP {moneyFormat(data?.total || 0)}
        </span>
      </div>
      <RenderDriverEarnings />
      <RenderOrderComputation />
    </div>
  );
};

const AddressContainer = ({
  id,
  title,
  data,
  activeOrder,
  activeTask,
  setActiveTask,
  completeTaskHandler,
  allowStartTask,
}) => {
  const [show, setShow] = useState(false);
  const [taskCompleted, setTaskCompleted] = useState(
    data?.taskCompleted ?? false,
  );
  const [startTask, setStartTask] = useState(false);
  const label = show ? 'Hide' : 'View';

  const onClick = () => {
    setShow(!show);
  };

  useEffect(() => {
    if (activeTask == id) {
      setStartTask(true);
    } else {
      setStartTask(false);
    }
  }, [id, activeTask]);

  useEffect(() => {
    if (startTask) {
      setActiveTask(id);
    }
  }, [startTask]);

  const handleTaskCompleted = () => {
    setTaskCompleted(true);
    setActiveTask(null);
    completeTaskHandler(id);
  };

  return (
    <>
      <div style={stylesContainer.sectionContainerAddress}>
        <div style={stylesContainer.sectionHeader}>
          <strong>{title}</strong>
        </div>
        {!show ? (
          <>
            <p style={stylesContainer.sectionData}>
              {data?.addressLine1} {data?.addressLine2 || ''} {data.city}{' '}
              {data.province} {data.zipCode}
            </p>
          </>
        ) : (
          <>
            <table>
              <tbody>
                <tr>
                  <td style={stylesContainer.sectionDataTo}>Name: </td>
                  <td>{data?.name || ''}</td>
                </tr>
                <tr>
                  <td style={stylesContainer.sectionDataTo}>Phone: </td>
                  <td>{data?.contactNumber || ''}</td>
                </tr>
                <tr>
                  <td style={stylesContainer.sectionDataTo}>Address: </td>
                  <td></td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    {data?.addressLine1 || ''} {data?.addressLine1 || ''}{' '}
                    {data?.city || ''} {data?.province || ''}{' '}
                    {data?.zipCode || ''}
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        )}
        {show && activeOrder && (
          <>
            <Button
              startIcon={<CallIcon />}
              href="tel:09999999999"
              variant="contained"
              color="primary"
              size={'small'}
              fullWidth
              style={stylesContainer.sectionButton}>
              CALL
            </Button>
            <Button
              onClick={() => {
                const lat = data.lat;
                const lon = data.lng;
                const url = `https://www.google.com/maps/search/?api=1&query=${lat},${lon}`;

                window.open(url, '_blank');
              }}
              variant="contained"
              color="primary"
              size={'small'}
              fullWidth
              style={stylesContainer.sectionButton}>
              NAVIGATE VIA GOOGLE MAP
            </Button>
            <Button
              onClick={() => {
                const lat = data.lat;
                const lon = data.lng;
                const url = `https://waze.com/ul?ll=${lat}%2C${lon}&navigate=yes`;

                window.open(url, '_blank');
              }}
              variant="contained"
              color="primary"
              size={'small'}
              fullWidth
              style={stylesContainer.sectionButton}>
              NAVIGATE VIA WAZE
            </Button>
          </>
        )}

        {activeOrder && allowStartTask && !taskCompleted && !startTask && (
          <Button
            onClick={() => setStartTask(true)}
            variant="outlined"
            color="error"
            size={'small'}
            fullWidth
            style={stylesContainer.sectionButton}
            disabled={activeTask && activeTask != id}>
            Start Task
          </Button>
        )}

        {!taskCompleted && startTask && (
          <Button
            onClick={() => handleTaskCompleted()}
            variant="contained"
            color="error"
            size={'small'}
            fullWidth
            style={stylesContainer.sectionButton}>
            MARK AS COMPLETE TASK
          </Button>
        )}

        <Button
          onClick={onClick}
          variant="outlined"
          color="primary"
          size={'small'}
          fullWidth
          style={stylesContainer.sectionButton}>
          {label}
        </Button>
      </div>
    </>
  );
};

const RenderHandler = ({ label, value }) => {
  return (
    <>
      <div style={stylesContainer.sectionContainerAddress}>
        <div style={stylesContainer.sectionHeader}>
          <strong>{label}</strong>
        </div>
        <>{value}</>
      </div>
    </>
  );
};

const ProofOfDeliveryContainer = ({ data }) => {
  const { token } = useContext(UserContext);
  const [url, setUrl] = useState(null);

  useEffect(() => {
    loadFile();
  }, []);

  const loadFile = () => {
    showFile(token, data?.packagePhoto).then(res => {
      setUrl(res?.data?.file || null);
    });
  };

  return (
    <div style={stylesContainer.sectionContainerAddress}>
      <p style={stylesContainer.sectionHeader}>
        <strong>Proof Of Delivery</strong>
      </p>
      {url && (
        <p style={stylesContainer.sectionData}>
          <img src={url} style={stylesContainer.packageImage} />
        </p>
      )}
      <p style={stylesContainer.sectionData}>
        <strong>Delivery Note: </strong>
        <br />
        {data?.packageDescription || ''}
      </p>
    </div>
  );
};