import axios from 'axios';
import { checkErrorResponse } from '../utils/helpers';

export const create = payload => {
  const headers = {
    'Content-Type': 'multipart/form-data',
  };

  return axios
    .post(`${process.env.REACT_APP_API}/file-management/`, payload, { headers })
    .then(function (response) {
      return response.data;
    })
    .catch(error => {
      return checkErrorResponse(error.response);
    });
};

export const show = (token, id) => {
  const headers = {
    'Content-Type': 'multipart/form-data',
    Authorization: `Bearer ${token}`,
  };

  return axios
    .get(`${process.env.REACT_APP_API}/file-management/${id}`, { headers })
    .then(function (response) {
      return response.data;
    })
    .catch(error => {
      return checkErrorResponse(error.response);
    });
};

