import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { geocode } from '../../services/maps';
import { locationPh } from '../../config/locationPH.jsx';
import { phoneRegex } from '../../utils/constants';
import { getMostSimilar, pluck } from '../../utils/helpers';
import { Grid, Button, FormControl, TextField } from '@mui/material';
import Select from '../../components/FormSelect';
import CurrencyFormat from 'react-currency-format';
import { getProvincesAndMunicipalities } from '../../config/locationPH2.jsx';

const Form = ({
  label,
  mapInformation,
  userData,
  nextStep,
  backStep,
  showCod,
}) => {
  const [formData, setFormData] = useState({
    name: null,
    contactNumber: null,
    addressLine1: null,
    addressLine2: null,
    province: null,
    city: null,
    zipCode: null,
    remarks: null,
    cod: false,
    codAmount: null,
  });
  const [selectionOptions, setSelectionOptions] = useState({
    province: [],
    city: [],
  });

  useEffect(() => {
    loadLocationConfig();
  }, []);

  useEffect(() => {
    getProvinceCityFromMap();
  }, [mapInformation, selectionOptions.province]);

  const getProvinceCityFromMap = () => {
    console.log('formData', formData);
    console.log('mapInformation', mapInformation);
    if (!mapInformation) return;
    if (selectionOptions.province.length === 0) return;

    geocode(mapInformation.lat, mapInformation.lng).then(res => {
      let province = null;
      let locality = null;
      let zipCode = null;

      for (const result of res.results) {
        for (const component of result.address_components) {
          if (component.types.includes('administrative_area_level_2')) {
            province = component.long_name.toUpperCase();
          } else if (component.types.includes('locality')) {
            locality = component.long_name.toUpperCase();
          } else if (component.types.includes('postal_code')) {
            zipCode = component.long_name;
          }

          if (
            !province &&
            component.types.includes('administrative_area_level_1')
          ) {
            province = component.long_name.toUpperCase();
          }
        }

        if (province) break;
      }
      if (!province) return;
     
      province = getMostSimilar(province, pluck(selectionOptions.province ?? [], 'value'))?.word ?? '';
      locality = getMostSimilar(locality, pluck(selectionOptions.city[province] ?? [], 'value'))?.word ?? '';
     
      const tempFormData = { ...formData };
      if (mapInformation?.addressLine1 && !tempFormData.addressLine1) {
        tempFormData.addressLine1 = mapInformation.addressLine1;
      }
      if (mapInformation?.addressLine2 && !tempFormData.addressLine2) {
        tempFormData.addressLine2 = mapInformation.addressLine2;
      }
      if (mapInformation?.zipCode && !tempFormData.zipCode) {
        tempFormData.zipCode = mapInformation.zipCode;
      }
      if (mapInformation?.name && !tempFormData.name) {
        tempFormData.name = mapInformation.name;
      }
      if (mapInformation?.contactNumber && !tempFormData.contactNumber) {
        tempFormData.contactNumber = mapInformation.contactNumber;
      }

      setFormData({
        ...tempFormData,
        province: province ?? tempFormData.province,
        city: locality ?? tempFormData.city,
        zipCode: zipCode ?? tempFormData.zipCode,
      });
    });
  };

  const loadLocationConfig = () => {
    const provinceSelection = getProvincesAndMunicipalities();
    const citySelection = [];
    
    for (const province of provinceSelection) {
      citySelection[province.value] = province.municipality.map(item => {
        return {
          label: item,
          value: item,
        };
      });
    }

    setSelectionOptions({ province: provinceSelection, city: citySelection });
  };

  const onSubmit = e => {
    e.preventDefault();
    console.log('formData', formData);

    const fieldsNotRequired = ['addressLine2', 'remarks', 'codAmount', 'cod'];

    for (let key in formData) {
      if (!formData[key] && !fieldsNotRequired.includes(key)) {
        let label = key.replace(/([A-Z])/g, ' $1').trim();
        label = label.charAt(0).toUpperCase() + label.slice(1);

        alert(label + ' is required');
        return;
      }

      if (key === 'contactNumber') {
        if (!phoneRegex.test(formData[key])) {
          alert('Invalid contact number');
          return;
        }
      }
    }

    nextStep(formData);
  };

  return (
    <Grid container direction="column" alignItems="center">
      <form onSubmit={onSubmit}>
        <div
          style={{
            textAlign: 'center',
            fontSize: '1.2rem',
            paddingTop: '15px',
            paddingBottom: '15px',
          }}>
          {label}
        </div>

        <FormControl fullWidth>
          <TextField
            label="Name *"
            variant="outlined"
            type={'text'}
            onChange={e => setFormData({ ...formData, name: e.target.value })}
            value={formData.name ?? ''}
          />
          <TextField
            label="Contact Number *"
            variant="outlined"
            type={'text'}
            onChange={e =>
              setFormData({ ...formData, contactNumber: e.target.value })
            }
            value={formData.contactNumber ?? ''}
          />
          <TextField
            label="Address Line 1 *"
            variant="outlined"
            type={'text'}
            onChange={e =>
              setFormData({ ...formData, addressLine1: e.target.value })
            }
            value={formData.addressLine1 ?? ''}
            inputProps={{
              maxLength: 200
            }}
          />
          <TextField
            label="Address Line 2"
            variant="outlined"
            type={'text'}
            onChange={e =>
              setFormData({ ...formData, addressLine2: e.target.value })
            }
            value={formData.addressLine2 ?? ''}
          />
          <FormControl fullWidth style={{ marginBottom: '10px' }}>
            <Select
              onChange={e =>
                setFormData({ ...formData, province: e.value, city: null })
              }
              value={selectionOptions?.province?.find(
                item => item.value === formData?.province,
              )}
              options={selectionOptions?.province ?? []}
              placeholder="Province *"
            />
          </FormControl>
          <FormControl fullWidth style={{ marginBottom: '10px' }}>
            <Select
              onChange={e => {
                console.log('e', e);
                setFormData({ ...formData, city: e.value });
              }}
              value={
                formData?.city
                  ? selectionOptions?.city[formData?.province]?.find(
                      item => item.value === formData?.city,
                    )
                  : null
              }
              options={selectionOptions?.city[formData?.province] ?? []}
              placeholder="City *"
            />
          </FormControl>
          <TextField
            label="Zip Code *"
            variant="outlined"
            type={'text'}
            onChange={e =>
              setFormData({ ...formData, zipCode: e.target.value })
            }
            value={formData.zipCode ?? ''}
          />
          <TextField
            label="Remarks"
            variant="outlined"
            type={'text'}
            onChange={e =>
              setFormData({ ...formData, remarks: e.target.value })
            }
            value={formData.remarks ?? ''}
          />
          {showCod && (
            <>
            <small>To collect money, add an amount.</small>
            <CurrencyFormat
              value={formData.codAmount ?? ''}
              displayType={'input'}
              thousandSeparator={true}
              prefix={'PHP'}
              decimalScale={2}
              min={0}
              fixedDecimalScale={true}
              onValueChange={values => {
                const { floatValue } = values;
                let temp = floatValue;
      
                if (isNaN(temp)) {
                  temp = 0;
                } else if (temp > 10000) {
                  setFormData({
                    ...formData,
                    cod: false,
                    codAmount: 0,
                  });
                  return alert('Maximum amount is 10,000');
                } else if (temp < 0) {
                  temp = 0;
                }
    
                setFormData({
                  ...formData,
                  cod: temp > 0,
                  codAmount: temp,
                });
              }}
              customInput={TextField}
              placeholder="Cash on Delivery Amount"
            />
            </>
          )}
        </FormControl>

        <div>
          <Button type="submit" variant="contained" className="action">
            Next
          </Button>
          <Link>
            <Button
              onClick={() => backStep()}
              variant="contained"
              className="action">
              Back
            </Button>
          </Link>
        </div>
      </form>
    </Grid>
  );
};

export default Form;
