import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingScreen from '../../../components/LoadingScreen';
import { UserContext } from '../../../context/UserProvider';
import { show } from '../../../services/order';
import { get as getDriver } from '../../../services/driver';
import { Button } from '@mui/material';
import InformationView from './informationView';
import InformationMap from './informationMap';

const App = () => {
  const { token, userType } = useContext(UserContext);
  let { id } = useParams();
  const [status, setStatus] = useState(null);
  const [jobOrder, setJobOrder] = useState(null);
  const navigate = useNavigate();
  const [viewDriverLocation, setViewDriverLocation] = useState(false);
  const [showInformation, setShowInformation] = useState(false);
  const [driverInformation, setDriverInformation] = useState(null);

  useEffect(() => {
    loadOrder();
  }, []);

  useEffect(() => {
    if (status === 'active') {
      const interval = setInterval(() => {
        monitorStatus();
      }, 3000);
      return () => clearInterval(interval);
    }
  }, [status]);

  const loadOrder = () => {
    show(token, id).then(res => {
      setStatus(res.data.status);
      setJobOrder(res.data);
      
      if (res.data?.['driver-id']) { 
        loadDriverInformation(res.data['driver-id']);
      }

      if (res.data.status === 'active') {
        setShowInformation(false);
      } else if (showInformation === false) {
        setShowInformation(true);
      }
    });
  };

  const loadDriverInformation = (id) => {
    getDriver(token, id).then(res => {
      setDriverInformation(res);
    });
  };

  const monitorStatus = () => {
    show(token, id).then(res => {
      if (res.data.status !== 'active') {
        setShowInformation(true);
        setStatus(res.data.status);
        setJobOrder(res.data);

        if (res.data?.['driver-id']) { 
          loadDriverInformation(res.data['driver-id']);
        }
      }
    });
  };

  if (!status) {
    return <LoadingScreen />;
  } else if (!showInformation) {
    return (
      <div style={{ textAlign: 'center' }}>
        <h1>Please Wait</h1>
        <img
          src="/img/looking-for-driver.gif"
          style={{ width: '100%', marginBottom: '30px' }}
        />
        <Button
          variant="contained"
          color="error"
          onClick={() => navigate('/customer')}>
          GO TO DASHBOARD
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setShowInformation(true)}
            style={{ marginTop: '10px' }}
        >
          VIEW ORDER INFORMATION
        </Button>
      </div>
    );
  }

  if (!jobOrder) {
    return <LoadingScreen />;
  } else if (viewDriverLocation === true) {
    return (
      <>
        <div>
          <InformationMap
            driverId={jobOrder?.['driver-id']}
            orderId={id}
            hideMap={() => setViewDriverLocation(false)}
          />
        </div>
      </>
    );
  }

  return (
    <>
      <div>
        <InformationView
          id={id}
          status={jobOrder.status}
          reference={jobOrder['reference-number'] ?? ''}
          data={jobOrder.data}
          showMap={() => setViewDriverLocation(true)}
          driverInformation={driverInformation}
          paymentType={jobOrder?.['payment-type']}
        />
      </div>
    </>
  );
};

export default App;
