import * as React from 'react';
import { useController } from 'react-hook-form';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers';

const DatePicker = React.forwardRef(function DatePicker(props, ref) {
  const { label, format, name, control, maxDate } = props;
  const {
    field: { onBlur, ...field },
    fieldState,
  } = useController({
    name,
    control,
  });
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MuiDatePicker
        forwardedRef={ref}
        {...field}
        label={label}
        maxDate={maxDate}
        inputFormat={format}
        slotProps={{
          textField: {
            onBlur,
            error: !!fieldState.error,
          },
        }}
      />
    </LocalizationProvider>
  );
});

export default DatePicker;
