import FuzzySet from 'fuzzyset.js';

export const dataURLtoFile = (dataurl, filename) => {
  let arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[arr.length - 1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

export const moneyFormat = (total) => {
  total = total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');

  return total;
}

export const getMostSimilar = (mainWord, wordList) => {
  const fset = FuzzySet(wordList);
    const results = fset.get(mainWord, null, 0.5);

    if (results) {
        results.sort((a, b) => {
            const [scoreA, wordA] = a;
            const [scoreB, wordB] = b;

            if (wordA.includes(mainWord) && !wordB.includes(mainWord)) {
                return -1;
            } else if (!wordA.includes(mainWord) && wordB.includes(mainWord)) {
                return 1;
            } else {
                return scoreB - scoreA;
            }
        });

        const [score, closestWord] = results[0];
        return {
            word: closestWord,
            score: score
        };
    } else {
        return null;
    }
}

export const pluck = (arr, key) => arr.map(obj => obj[key]);

export const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export const checkErrorResponse = (response) => {
  console.log(response);
  if (response.status == 401) {
    localStorage.removeItem('token');
    localStorage.removeItem('userType');
    window.location.href = '/';
  } else if (response?.data?.message === '[HTTP 429] Unable to create record: Max send attempts reached') {
    alert('Max attempts reached. Please try again later.');
    window.location.reload();
  } else if (response?.data?.message === '[HTTP 403] Unable to create record: The destination phone number has been temporarily blocked by Twilio due to fraudulent activities. +639994992118 prefix is blocked for the SMS channel') {
    alert('We can\'t send to this number. Please try using email.');
    window.location.reload();
  }

  return response;
}

