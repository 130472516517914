import React, { useEffect, useContext, useState } from 'react';
import Navbar from '../../../components/DesktopNavbar';
import { mainMenu } from '../../../config/dropoffMenu.jsx';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { list } from '../../../services/manifest';
import { updateRegular } from '../../../services/order';
import { UserContext } from '../../../context/UserProvider';
import Select from '../../../components/FormSelect';
import { isMobile } from 'react-device-detect';
import { Button } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingScreen from '../../../components/LoadingScreen/index.jsx';

const App = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { token } = useContext(UserContext);
  const [manifestList, setManifestList] = useState(null);
  const [manifest, setManifest] = useState(null);

  const loadData = payload => {
    list(token, {
      ...payload,
      fields: {
        status: 'draft',
      },
      'user-specific': true,
    }).then(res => {
      const summary = [];
      res.collection.forEach(item => {
        if (Object.keys(item.data).length === 0) {
          return;
        }

        summary.push({
          value: item.raw.id,
          label: item.data.name,
        });
      });

      setManifestList(summary);
    });
  };

  const handleSave = () => {
    if (!manifest) {
        alert('Please select manifest');
        return;
    }

    updateRegular(token, {
        'manifest-id': manifest,
    }, id).then(res => {
        alert('Successfully assigned to manifest');
        navigate('/dropoff/parcels');
    });
  };

  useEffect(() => {
    loadData({
      'current-page': 1,
      'record-per-page': 'all',
    });
  }, []);


  if (!manifestList) {
    return <LoadingScreen />;
  }

  return (
    <>
      <Navbar pages={mainMenu} />
      <Container maxWidth={'xl'}>
        <Grid container spacing={2} style={{ height: 'auto' }}>
          <Grid item xs={12} sm={6} style={{ height: '100px' }}>
            <h1>Assign To Manifest</h1>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            style={
              isMobile
                ? { height: '100px' }
                : { height: '100px', paddingTop: '40px' }
            }>
            <Button
              variant="contained"
              onClick={() => handleSave()}
              style={
                isMobile
                  ? {}
                  : {
                      width: '160px',
                      float: 'right',
                    }
              }>
              Save
            </Button>
            <Button
              variant="contained"
              onClick={() => navigate('/dropoff/parcels')}
              style={
                isMobile
                  ? {}
                  : {
                      width: '160px',
                      float: 'right',
                      marginRight: '10px',
                    }
              }>
              Cancel
            </Button>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Select
              onChange={e => {
                setManifest(e.value);
              }}
              options={manifestList}
              placeholder="Select Manifest"
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default App;
