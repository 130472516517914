import axios from 'axios';
import { checkErrorResponse } from '../utils/helpers';

export const geocode = (lat, lng) => {
    const headers = {
      'Content-Type': 'application/json',
    };
    let payload = {
        latlng: `${lat},${lng}`,
        key: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    };
    payload = new URLSearchParams(payload).toString();
  
    return axios
      .get(
        `${process.env.REACT_APP_GOOGLE_MAP_API}/geocode/json?${payload}`,
        { headers },
      )
      .then(function (response) {
        return response.data;
      })
      .catch(error => {
        return checkErrorResponse(error.response);
      });
  };
  