import axios from 'axios';
import { checkErrorResponse } from '../utils/helpers';

export const create = payload => {
  const headers = {
    'Content-Type': 'application/json',
  };

  return axios
    .post(`${process.env.REACT_APP_API}/driver-vehicle-management/`, payload, {
      headers,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(error => {
      return checkErrorResponse(error.response);
    });
};

export const getVehicleType = () => {
  const headers = {
    'Content-Type': 'application/json',
  };
  const payload = {};

  return axios
    .get(
      `${process.env.REACT_APP_API}/vehicle-type/config`,
      { headers },
    )
    .then(function (response) {
      return response.data;
    })
    .catch(error => {
      return checkErrorResponse(error.response);
    });
};
