import * as React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

const format = 'YYYY-MM-DD';
const DatePicker = ({ label, maxDate, onChange, value, minDate }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MuiDatePicker
        label={label}
        maxDate={maxDate}
        inputFormat={format}
        format={format}
        onChange={(e) => onChange(dayjs(e).format(format))}
        value={dayjs(value)}
        minDate={minDate}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;
