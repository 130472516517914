export const styles = {
    pageContainer: {
        display: 'flex', flexDirection: 'column', height: '100vh'
    },
    pageHeader: {
        textAlign: 'center',
        fontSize: '1.2rem',
        paddingTop: '35px',
        fontWeight: 'bold',
    },
    pageBody: {
        flex: 1
    },
    cardSelection: {
        textAlign: 'left',
        backgroundColor: '#e6e6e6',
        padding: '10px',
        borderRadius: '5px',
        marginBottom: '10px',
        display: 'flex',
        color: '#000000',
    },
    searchContainer: {
        display: 'flex',
        marginBottom: '10px',
    },
    searchInput: {
        marginTop: '20px',
    },
    searchButton: {
        marginLeft: '10px',
        marginTop: '20px',
        height: '40px',
        width: '150px',
    },

} 