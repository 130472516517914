import React, { useContext, useState, useEffect } from 'react';
import Navbar from '../../../components/DesktopNavbar/index.jsx';
import { mainMenu } from '../../../config/dropoffMenu.jsx';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../context/UserProvider.jsx';
import { listRegular } from '../../../services/order.jsx';
import { list } from '../../../services/manifest.jsx';
import { moneyFormat, toTitleCase } from '../../../utils/helpers.jsx';
import { isMobile } from 'react-device-detect';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { Button } from '@mui/material';
import { status as statusLabel } from '../../../config/regularStatus.jsx';

let regularLabels = {
  door2door: 'Door 2 Door',
  dropoff2dropoff: 'Dropoff 2 Dropoff',
  dropoff2door: 'Dropoff 2 Door',
  door2dropoff: 'Door 2 Dropoff',
};

const columns = isMobile
  ? [
      {field: 'id', headerName: 'ID', width: 70 },
      {field: 'reference-number', headerName: 'Order Number', width: 270 },
      {field: 'type', headerName: 'Delivery Type', width: 270 },
      {field: 'orgin', headerName: 'Origin', width: 270 },
      {field: 'destination', headerName: 'Destination', width: 270 },
      {field: 'total', headerName: 'Cost', width: 270 },
      {field: 'payment', headerName: 'Payment Type', width: 270 },
      {field: 'status', headerName: 'Status', width: 270, renderCell: (params) => {
        return <>{statusLabel[params.row.status] ?? ''}</>
      }},
      {field: 'manifest', headerName: 'Manifest', width: 270 },
    ]
  : [
      {field: 'id', headerName: 'ID', width: 70 },
      {field: 'reference-number', headerName: 'Order Number', width: 200 },
      {field: 'type', headerName: 'Delivery Type', flex: 1 },
      {field: 'orgin', headerName: 'Origin', flex: 1 },
      {field: 'destination', headerName: 'Destination', flex: 1 },
      {field: 'total', headerName: 'Cost', flex: 1 },
      {field: 'payment', headerName: 'Payment Type', flex: 1 },
      {field: 'status', headerName: 'Status', flex: 1, renderCell: (params) => {
        return <>{statusLabel[params.row.status] ?? ''}</>
      }},
      {field: 'manifest', headerName: 'Manifest', flex: 1 },
    ];

const App = () => {
  const navigate = useNavigate();
  const { token, userAttr } = useContext(UserContext);
  const [rows, setRows] = useState([]);
  const [pageInfoCurrentPage, setPageInfoCurrentPage] = useState(0);
  const [pageInfoTotalRows, setPageInfoTotalRows] = useState(0);
  const [pageInfoRowPerPage, setPageInfoRowPerPage] = useState(25);
  const [loading, setLoading] = useState(true);
  const [manifest, setManifest] = useState(null);
  const [dropoffType, setDropoffType] = useState(null);

  useEffect(() => {
    if (userAttr) {
      let userAttribute = JSON.parse(userAttr);
      setDropoffType(userAttribute?.type);
    }
  }, [userAttr]);

  const updatePageHandler = pageInfo => {
    loadData({
      'current-page': pageInfo.page + 1,
      'record-per-page': pageInfo.pageSize,
    });
  };

  const handleRecordSelect = row => {
    navigate(`/dropoff/scan/${row.id}/${row.row.data.verification}`);
  };

  const loadData = (payload, fields = {}) => {
    setLoading(true);
    let keys = 'dropoff-destination-id';

    let params = {
      [keys]: token,
    };
   

    listRegular(token, {
      ...payload,
      fields: {
        ...params,
        ...fields,
      },
      token: keys,
      'user-specific': false,
    }).then(res => {
      const summary = [];
      res.collection.forEach(item => {
        summary.push({
          id: item.raw.id,
          ...item.data,
          type: regularLabels[item.data.type],
          orgin: `${item.data['sender-city']}, ${item.data['sender-province']}`,
          destination: `${item.data['receiver-city']}, ${item.data['receiver-province']}`,
          payment: toTitleCase(item.data['payment-type']),
          total: 'PHP ' + moneyFormat(item.data['total']),
          manifest: manifest[item.data['manifest-id']] ?? 'Not Set',
        });
      });

      setRows(summary);
      setPageInfoCurrentPage(res['current-page']);
      setPageInfoRowPerPage(res['record-per-page']);
      setPageInfoTotalRows(res['data-total']);
      setLoading(false);
    });
  };

  const loadManifest = () => {
    list(token, {
      'current-page': 1,
      'record-per-page': 'all',
      'user-specific': true,
    }).then(res => {
      const summary = {};
      res.collection.forEach(item => {
        if (Object.keys(item.data).length === 0) {
          return;
        }

        summary[item.raw.id] = item.data.name;
      });

      setManifest(summary);
    });
  };

  useEffect(() => {
    loadManifest();
  }, []);

  useEffect(() => {
    if (manifest) {
      loadData({
        'current-page': 1,
        'record-per-page': 25,
      });
    }
  }, [manifest]);

  if (!dropoffType) {
    return null;
  }

  return (
    <>
      <Navbar pages={mainMenu} />
      <Container maxWidth={'xl'}>
        <Grid container spacing={2} style={{ height: 'auto' }}>
          <Grid item xs={12} sm={6} style={{ height: '100px' }}>
            <h1>Received Parcels</h1>
          </Grid>
          <Grid item xs={12} sm={6} style={{ height: '100px' }}>
          </Grid>
          <Grid item xs={12} sm={12}>
            <DataGrid
              rows={rows}
              columns={columns}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    id: false,
                    manifest: (dropoffType == 'main'),
                  },
                },
                pagination: {
                  paginationModel: {
                    pageSize: pageInfoRowPerPage,
                    page: pageInfoCurrentPage,
                  },
                },
              }}
              pagination
              paginationMode="server"
              rowCount={pageInfoTotalRows}
              loading={loading}
              onPaginationModelChange={newPage => updatePageHandler(newPage)}
              disableColumnFilter
              disableColumnMenu
              onRowClick={handleRecordSelect}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default App;
