import React, { useContext } from 'react';
import { styles } from '../../../../config/defaultPageStyles.jsx';
import MapAddressForm from '../../../../components/MapAddressForm';
import PackageForm from '../packageForm.jsx';
import PricingForm from '../../../../components/PricingForm';
import { DeliveryTemplateContext } from '../../../../context/DeliveryTemplateProvider.jsx';

const App = () => {
  const { 
    step,
    jobOrder,
    setJobOrder,
    backHandler,
    nextHandler,
    navigate,
    id,
    handleBeforeUnload
  } = useContext(DeliveryTemplateContext);

  if (step === 1) { // Sender
    return (
      <MapAddressForm
        labelForm="Pickup Information"
        labelMap="Pin Pickup Location"
        nextHandler={(data) => {
          setJobOrder({
            ...jobOrder,
            sender: data,
          });
          nextHandler();
        }}
        backHandler={() => navigate('/customer/delivery/regular')}
        showCod={false}
      />
    );
  } else if (step === 2) { // Package
    return (
      <PackageForm
        label="Package Declaration"
        data={jobOrder}
        backHandler={() => backHandler()}
        nextHandler={(data) => {
          setJobOrder({
            ...jobOrder,
            package: data,
          });
          nextHandler();
        }}
      />
    );
  } else if (step === 3) { // Receiver
    return (
      <MapAddressForm
        labelForm="Receiver Information"
        labelMap="Pin Receiver Location"
        nextHandler={(data) => {
          setJobOrder({
            ...jobOrder,
            receiver: data,
          });
          nextHandler();
        }}
        backHandler={() => backHandler()}
        showCod={true}
      />
    );
  } else if (step === 4) { // Pricing
    return (
      <PricingForm
        orderType='regular'
        label="Pricing Information"
        data={jobOrder}
        backHandler={() => backHandler()}
        nextHandler={(data) => {
          setJobOrder({
            ...jobOrder,
            pricing: data,
          });
          nextHandler();
        }}
        removeReloadWarning={() => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
        }}
        isComputed={id ? true : false}
      />
    );
  }

  return (
    <div style={styles.pageContainer}>
      <div style={styles.pageBody}>
        <div style={styles.pageHeader}>Select Delivery Type</div>
      </div>
    </div>
  );
};

export default App;
