import React, { useState } from 'react';
import { Button, TextField } from '@mui/material';
import { moneyFormat } from '../../../utils/helpers';

const App = ({ label, data, backHandler, nextHandler, setStep, updateHandler }) => {
  const submit = () => {
    nextHandler();
  };

  return (
    <>
      <div
        style={{
          textAlign: 'center',
          fontSize: '1.2rem',
          paddingTop: '15px',
          paddingBottom: '15px',
        }}>
        {label}
      </div>
      <div
        style={{
          textAlign: 'center',
          fontSize: '1.2rem',
          paddingTop: '15px',
          paddingBottom: '15px',
        }}>
        <AddressContainer onClick={() => setStep(1)} label={'Change'}>
          <p>
            <strong>Pickup</strong>
          </p>
          <p>
            {data.sender.addressLine1} {data.sender?.addressLine2 || ''}{' '}
            {data.sender.city} {data.sender.province} {data.sender.zipCode}
          </p>
        </AddressContainer>
        {data.stops.map((item, index) => {
          return (
            <AddressStopContainer 
              onClick={() => setStep(2)} 
              label={'Change'}
              remove={() => {
                let newStops = data?.stops.filter((item, i) => i !== index);
                updateHandler(newStops);
              }}
            >
              <p>
                <strong>Stop {index + 1}</strong>
              </p>
              <p>
                {item?.addressLine1} {item?.addressLine2 || ''} {item?.city}{' '}
                {item?.province} {item?.zipCode}
              </p>
              {
                item?.codAmount && (
                  <p>
                    <strong>COD Amount:</strong> PHP {moneyFormat(item?.codAmount)}
                  </p>
                )
              }
            </AddressStopContainer>
          );
        })}
    

        <Button onClick={() => setStep(6)} variant="outlined" color="error" style={{marginBottom: '10px'}} fullWidth>
          {data.stops.length > 0 ? 'Add More Stop' : 'Add Stop'}
        </Button>

        <AddressContainer onClick={() => setStep(4)} label={'Change'}>
          <p>
            <strong>Receiver</strong>
          </p>
          <p>
            {data.receiver?.addressLine1 || ''} {data.receiver?.addressLine2 || ''}{' '}
            {data.receiver?.city || ''} {data.receiver?.province || ''}{' '}
            {data.receiver?.zipCode || ''}
          </p>
          {
            data.receiver?.codAmount && (
              <p>
                <strong>COD Amount:</strong> PHP {moneyFormat(data.receiver?.codAmount)}
              </p>
            )
          }
        </AddressContainer>
      </div>
      <div style={{ width: '100%', paddingBottom: '15px' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => submit()}
          style={{ marginBottom: '10px' }}>
          Next
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => backHandler()}>
          Back
        </Button>
      </div>
    </>
  );
};

const AddressContainer = ({ onClick, children, label }) => {
  return (
    <>
      <div
        style={{
          textAlign: 'left',
          backgroundColor: '#d8d4d4',
          padding: '5px 10px 15px 10px',
          borderRadius: '10px',
          marginBottom: '10px',
        }}>
        {children}
        <Button onClick={onClick} variant="outlined" color="primary" fullWidth>
          {label}
        </Button>
      </div>
    </>
  );
};


const AddressStopContainer = ({ onClick, children, label, remove }) => {
  return (
    <>
      <div
        style={{
          textAlign: 'left',
          backgroundColor: 'rgb(255 204 204)',
          padding: '5px 10px 15px 10px',
          borderRadius: '10px',
          marginBottom: '10px',
          border: '#934d4d solid 1px'
        }}>
        {children}
        <Button onClick={onClick} variant="outlined" color="primary" fullWidth>
          {label}
        </Button>
        <Button onClick={remove} variant="outlined" color="error" fullWidth style={{marginTop: '10px'}}>
          Remove
        </Button>
      </div>
    </>
  );
};

export default App;
