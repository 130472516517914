import React, { useState, useRef, useContext, useEffect } from 'react';
import ImageUploader from '../../../components/ImageUploader';
import { updateRegular } from '../../../services/order';
import { UserContext } from '../../../context/UserProvider';
import { useNavigate, useParams } from 'react-router-dom';
import QRCodeScanner from '../../../components/QrCodeScanner';
import { Grid, Button, FormControl, TextField } from '@mui/material';
import {
  listRegular as list,
} from '../../../services/order';

const App = () => {
  const navigate = useNavigate();
  const { token } = useContext(UserContext);
  const photoList = useRef([]);
  const description = useRef(null);
  const [valid, setValid] = useState(null);
  const [orderId, setOrderId] = useState(null);
  const refOrderNumber = useRef(null);
  const refCode = useRef(null);

  const handleScan = data => {
    console.log('SCAN DATA', data);
    if (data) {
      const qrCodeData = data.split(':');
      setOrderId(qrCodeData[2]);
      setValid(qrCodeData[2] ? true : false);
    }
  };

  useEffect(() => {
    if (valid != null) {
        if (valid) {
            navigate(`/driver/orders/regular/${orderId}`);
        }
    }
  }, [valid]);

  const backHandler = () => {
    navigate(`/driver/orders`);
  };

  const handleDesktopProcess = () => {
    if (!refOrderNumber.current.value || !refCode.current.value) {
      alert('Please fill up all required fields');
      return;
    }

    list(token, {
      fields: {
        'reference-number': refOrderNumber.current.value,
      },
      operator: '=',
      'user-specific': false,
      'current-page': 1,
      'record-per-page': 'all',
    }).then(res => {
      if (res['data-total'] == 0) {
        alert('Order not found');
        return;
      }

      if (res.collection[0]?.data?.data?.verification == refCode.current.value) {
        setOrderId(res.collection[0].raw.id);
        setValid(res.collection[0]?.data?.data['verification'] === res.collection[0]?.data?.data?.verification  ? true : false);
      } else {
        alert('Invalid verification code');
      }
    });
  };

  return (
    <>
      <div
        style={{
          textAlign: 'center',
          fontSize: '1.2rem',
          paddingTop: '15px',
          paddingBottom: '15px',
        }}>
        PROOF OF PICKUP
      </div>
      <div
        style={{
          textAlign: 'center',
          fontSize: '1.2rem',
          paddingTop: '15px',
          paddingBottom: '15px',
        }}>
        <>
          <strong>Scan QR Code from the parcel waybill</strong>
          <QRCodeScanner onChange={handleScan} />
          
          {(valid === false) && (
            <div style={{ color: 'red' }}>Invalid QR</div>
          )}

          <strong>Or Enter Parcel Information</strong>
          <FormControl fullWidth>
              <TextField
                label="Order Ref # *"
                variant="outlined"
                type={'text'}
                inputRef={refOrderNumber}
              />
              <TextField
                label="Verification # *"
                variant="outlined"
                type={'text'}
                inputRef={refCode}
              />
              <Button
                onClick={() => handleDesktopProcess()}
                variant="contained"
                color="primary"
                size={'small'}
                fullWidth={false}>
                Submit
              </Button>
            </FormControl>
        </>
      </div>
      <div style={{ width: '100%', paddingBottom: '15px' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => backHandler()}
        >
          BACK
        </Button>
      </div>
    </>
  );
};

export default App;
