import React from 'react';
import RecoveryForm from '../../../components/LoginForm/recoverForm';

const RecoverForm = () => {
  return (
    <RecoveryForm nextPage={'/driver/login'} userType={'driver'} />
  );
};

export default RecoverForm;
