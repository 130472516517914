import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from 'react';
import {
  GoogleMap,
  LoadScript,
  Marker,
  StandaloneSearchBox,
} from '@react-google-maps/api';
import { Button, Grid } from '@mui/material';
import Form from './form';
import { getAddress } from '../../services/customer';
import { UserContext } from '../../context/UserProvider';
import LoadingScreen from '../LoadingScreen';

const containerStyle = {
  width: '100%',
  height: '85vh',
};

const initialCenter = {
  lat: 40.73061,
  lng: -73.935242,
};

const App = ({
  backHandler,
  nextHandler,
  labelMap,
  labelForm,
  showCod = false,
}) => {
  const [mapInformation, setMapInformation] = useState(null);
  const [useSelectedAddress, setUseSelectedAddress] = useState(null);
  const [step, setStep] = useState(0);

  useEffect(() => {
    console.log('mapInformation', mapInformation);
  }, [mapInformation]);

  const nextHandlerProcess = data => {
    nextHandler({
      ...data,
      ...mapInformation,
    });
  };

  if (step === 0) {
    return (
      <Suggestions
        setStep={setStep}
        backHandler={() => backHandler()}
        setMapInformation={data => setMapInformation(data)}
      />
    );
  } else if (step === 1) {
    return (
      <Map
        label={labelMap}
        backHandler={() => backHandler()}
        submitMap={data => {
          setMapInformation({
            ...mapInformation,
            ...data,
          });
          setStep(2);
        }}
        defaultData={mapInformation}
      />
    );
  } else if (step === 2) {
    return (
      <Form
        label={labelForm}
        userData={{}}
        nextStep={data => nextHandlerProcess(data)}
        backStep={() => setStep(1)}
        mapInformation={mapInformation}
        showCod={showCod}
      />
    );
  }

  //   if (!mapInformation) {
  //     return (
  //       <Map
  //         label={labelMap}
  //         backHandler={() => backHandler()}
  //         submitMap={data => setMapInformation(data)}
  //       />
  //     );
  //   } else {
  //     return (
  //       <Form
  //         label={labelForm}
  //         userData={{}}
  //         nextStep={data => nextHandlerProcess(data)}
  //         backStep={() => setMapInformation(null)}
  //         mapInformation={mapInformation}
  //         showCod={showCod}
  //       />
  //     );
  //   }
};

const Map = ({ label, backHandler, submitMap, defaultData }) => {
  const [position, setPosition] = useState(null);
  const [mapCenter, setMapCenter] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const searchBoxRef = useRef(null);

  const submit = () => {
    submitMap(position);
  };

  const onSearchBoxLoaded = useCallback(ref => {
    searchBoxRef.current = ref;
  }, []);

  useEffect(() => {
    if (defaultData && defaultData.lat && defaultData.lng) {
      setPosition({
        lat: defaultData.lat,
        lng: defaultData.lng,
      });
      setMapCenter({
        lat: defaultData.lat,
        lng: defaultData.lng,
      });
    } else if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        setPosition({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        setMapCenter({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });
    } else {
      setPosition(initialCenter);
      setMapCenter(initialCenter);
    }
    setLoading(false);
  }, [isMapLoaded, defaultData]);

  const onPlacesChanged = () => {
    const places = searchBoxRef.current.getPlaces();
    const place = places[0];
    if (place) {
      setMapCenter({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
      setPosition({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
    }
  };

  const libraries = ['places'];

  return (
    <>
      <div
        style={{
          textAlign: 'center',
          fontSize: '1.2rem',
          paddingTop: '15px',
          paddingBottom: '15px',
        }}>
        {label}
      </div>
      {!loading && (
        <LoadScript
          googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
          libraries={libraries}>
          <style>
            {`
            body,
            html,
            #root,
            .MuiContainer-root {
            margin: 0 !important;
            padding: 0  !important;
            height: 100%;
            width: 100%;
            }
        `}
          </style>
          <div style={{ position: 'relative' }}>
            <StandaloneSearchBox
              onLoad={onSearchBoxLoaded}
              onPlacesChanged={onPlacesChanged}>
              <input
                type="text"
                placeholder="Search location"
                style={{
                  boxSizing: `border-box`,
                  border: `1px solid transparent`,
                  width: `calc(100% - 20px)`,
                  height: `35px`,
                  position: `absolute`,
                  top: `10px`,
                  left: `10px`,
                  padding: `0 12px`,
                  borderRadius: `3px`,
                  boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                  fontSize: `14px`,
                  outline: `none`,
                  textOverflow: `ellipses`,
                  zIndex: 5,
                  border: '3px solid #000FA0',
                }}
              />
            </StandaloneSearchBox>
          </div>
          <GoogleMap
            onLoad={() => setIsMapLoaded(true)}
            options={{
              streetViewControl: false,
              scaleControl: false,
              mapTypeControl: false,
              rotateControl: false,
              fullscreenControl: false,
              zoomControl: false,
              gestureHandling: 'auto',
              clickableIcons: false,
            }}
            mapContainerStyle={containerStyle}
            center={mapCenter}
            zoom={20}
            onClick={e => {
              setPosition({ lat: e.latLng.lat(), lng: e.latLng.lng() });
            }}>
            {position && <Marker position={position} />}
          </GoogleMap>
        </LoadScript>
      )}
      {position && (
        <div
          style={{
            position: 'absolute',
            bottom: '0',
            height: '13vh',
            width: '100%',
            display: 'block',
            justifyContent: 'center',
            padding: '0 1rem 0 1rem',
            backgroundColor: 'white',
          }}>
          <div
            style={{ width: '100%', marginBottom: '10px', marginTop: '10px' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => submit()}>
              Next
            </Button>
          </div>
          <div style={{ width: '100%' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => backHandler()}>
              Cancel
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

const Suggestions = ({ setStep, backHandler, setMapInformation }) => {
  const { token } = useContext(UserContext);
  const [address, setAddress] = useState(null);

  useEffect(() => {
    getAddress(token).then(res => {
      setAddress(res);
    });
  }, []);

  if (!address) {
    return <LoadingScreen />;
  }

  let addressList = [];
  for (const key in address) {
    let data = address[key];
    addressList.push(
      <AddressContainer
        key={key}
        label="Use this address"
        onClick={() => {
          console.log('Selected Address', data);
          setMapInformation(data);
          setStep(1);
        }}>
        <div style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
          {data.name} {data.contactNumber}
        </div>
        <div style={{ fontSize: '1rem' }}>{data.addressLine1}</div>
        <div style={{ fontSize: '1rem' }}>{data.addressLine2}</div>
        <div style={{ fontSize: '1rem' }}>
          {data.city}, {data.province} {data.zipCode}
        </div>
      </AddressContainer>,
    );
  }

  return (
    <>
      <div
        style={{
          textAlign: 'center',
          fontSize: '1.2rem',
          paddingTop: '15px',
          paddingBottom: '15px',
        }}>
        <div
          style={{
            textAlign: 'center',
            fontSize: '1.2rem',
            paddingTop: '15px',
            paddingBottom: '15px',
          }}>
          <strong>ADDRESS SUGGESTION</strong>
        </div>
        {addressList}
        <Button
          onClick={() => setStep(1)}
          variant="contained"
          color="primary"
          fullWidth
          style={{ marginBottom: '10px' }}>
          Enter New Address
        </Button>
        <Button
          variant="outlined"
          color="error"
          onClick={() => backHandler()}
          fullWidth>
          Cancel
        </Button>
      </div>
    </>
  );
};

const AddressContainer = ({ onClick, children, label }) => {
  return (
    <>
      <div
        style={{
          textAlign: 'left',
          backgroundColor: '#d8d4d4',
          padding: '5px 10px 15px 10px',
          borderRadius: '10px',
          marginBottom: '10px',
        }}>
        {children}
        <div>
          <Button
            onClick={onClick}
            variant="outlined"
            color="primary"
            fullWidth>
            {label}
          </Button>
        </div>
      </div>
    </>
  );
};

export default App;
