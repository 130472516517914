// export const locationPh2 = {
//   BAUNGON: {
//     province: 'BUKIDNON',
//     municipality: 'BAUNGON',
//     region: 'REGION X',
//     barangay_list: [
//       'BALINTAD',
//       'BUENAVISTA',
//       'DANATAG',
//       'IMBATUG (POB.)',
//       'KALILANGAN',
//       'LACOLAC',
//       'LANGAON',
//       'LIBORAN',
//       'LINGATING',
//       'MABUHAY',
//       'MABUNGA',
//       'NICDAO',
//       'PUALAS',
//       'SALIMBALAN',
//       'SAN MIGUEL',
//       'SAN VICENTE',
//     ],
//   },
//   CABANGLASAN: {
//     province: 'BUKIDNON',
//     municipality: 'CABANGLASAN',
//     region: 'REGION X',
//     barangay_list: [
//       'ANLOGAN',
//       'CABULOHAN',
//       'CANANGAAN',
//       'CAPINONAN',
//       'DALACUTAN',
//       'FREEDOM',
//       'IBA',
//       'IMBATUG',
//       'JASAAN',
//       'LAMBANGAN',
//       'MANDAHIKAN',
//       'MANDAING',
//       'MAUSWAGON',
//       'PARADISE',
//       'POBLACION',
//     ],
//   },
//   'MALAYBALAY': {
//     province: 'BUKIDNON',
//     municipality: 'MALAYBALAY',
//     region: 'REGION X',
//     barangay_list: [
//       'AGLAYAN',
//       'APO MACOTE',
//       'BANGCUD',
//       'BARANGAY 1 (POB.)',
//       'BARANGAY 2 (POB.)',
//       'BARANGAY 3 (POB.)',
//       'BARANGAY 4 (POB.)',
//       'BARANGAY 5 (POB.)',
//       'BARANGAY 6 (POB.)',
//       'BARANGAY 7 (POB.)',
//       'BARANGAY 8 (POB.)',
//       'BARANGAY 9 (POB.)',
//       'BARANGAY 10 (POB.)',
//       'BARANGAY 11 (POB.)',
//       'BUSDI',
//       'CABANGAHAN',
//       'CABURACANAN',
//       'CANAYAN',
//       'CAPITAN ANGEL',
//       'CASISANG',
//       'DALWANGAN',
//       'IMBAYAO',
//       'INDALAZA',
//       'KABALABAG',
//       'KALASUNGAY',
//       'KULAMAN',
//       'LAGUITAS',
//       'LINABO',
//       'MAGSAYSAY',
//       'MALIGAYA',
//       'MANAGOK',
//       'MANALOG',
//       'MAPAYAG',
//       'MAPULO',
//       'MIGLAMIN',
//       'PATPAT (LAPU-LAPU)',
//       'SAINT PETER',
//       'SAN JOSE',
//       'SAN MARTIN',
//       'SANTO NIÑO',
//       'SILAE',
//       'SIMAYA',
//       'SINANGLANAN',
//       'SUMPONG',
//       'VIOLETA',
//       'ZAMBOANGUITA',
//     ],
//   },
//   DAMULOG: {
//     province: 'BUKIDNON',
//     municipality: 'DAMULOG',
//     region: 'REGION X',
//     barangay_list: [
//       'ALUDAS',
//       'ANGGA-AN',
//       'KINAPAT',
//       'KIRAON',
//       'KITINGTING',
//       'LAGANDANG',
//       'MACAPARI',
//       'MAICAN',
//       'MIGCAWAYAN',
//       'NEW COMPOSTELA',
//       'OLD DAMULOG',
//       'OMONAY',
//       'POBLACION (NEW DAMULOG)',
//       'POCOPOCO',
//       'SAMPAGAR',
//       'SAN ISIDRO',
//       'TANGKULAN (JOSE RIZAL)',
//     ],
//   },
//   DANGCAGAN: {
//     province: 'BUKIDNON',
//     municipality: 'DANGCAGAN',
//     region: 'REGION X',
//     barangay_list: [
//       'BARONGCOT',
//       'BUGWAK',
//       'DOLOROSA',
//       'KAPALARAN',
//       'KIANGGAT',
//       'LOURDES',
//       'MACARTHUR',
//       'MIARAY',
//       'MIGCUYA',
//       'NEW VISAYAS',
//       'OSMEÑA',
//       'POBLACION',
//       'SAGBAYAN',
//       'SAN VICENTE',
//     ],
//   },
//   'DON CARLOS': {
//     province: 'BUKIDNON',
//     municipality: 'DON CARLOS',
//     region: 'REGION X',
//     barangay_list: [
//       'BISMARTZ',
//       'BOCBOC',
//       'BUYOT',
//       'CABADIANGAN',
//       'CALAOCALAO',
//       'DON CARLOS NORTE',
//       'DON CARLOS SUR (POB.)',
//       'EMBAYAO',
//       'KALUBIHON',
//       'KASIGKOT',
//       'KAWILIHAN',
//       'KIARA',
//       'KIBATANG',
//       'MAHAYAHAY',
//       'MANLAMONAY',
//       'MARAYMARAY',
//       'MAUSWAGON',
//       'MINSALAGAN',
//       'NEW NONGNONGAN (MASIMAG)',
//       'NEW VISAYAS',
//       'OLD NONGNONGAN',
//       'PINAMALOY',
//       'PUALAS',
//       'SAN ANTONIO EAST',
//       'SAN ANTONIO WEST',
//       'SAN FRANCISCO',
//       'SAN NICOLAS (BANBAN)',
//       'SAN ROQUE',
//       'SINANGGUYAN',
//     ],
//   },
//   'IMPASUG-ONG': {
//     province: 'BUKIDNON',
//     municipality: 'IMPASUG-ONG',
//     region: 'REGION X',
//     barangay_list: [
//       'BONTONGON',
//       'BULONAY',
//       'CAPITAN BAYONG',
//       'CAWAYAN',
//       'DUMALAGUING',
//       'GUIHEAN',
//       'HAGPA',
//       'IMPALUTAO',
//       'KALABUGAO',
//       'KIBENTON',
//       'LA FORTUNA',
//       'POBLACION',
//       'SAYAWAN',
//     ],
//   },
//   KADINGILAN: {
//     province: 'BUKIDNON',
//     municipality: 'KADINGILAN',
//     region: 'REGION X',
//     barangay_list: [
//       'BAGONGBAYAN',
//       'BAGOR',
//       'BALAORO',
//       'BAROY',
//       'CABADIANGAN',
//       'HUSAYAN',
//       'KIBALAGON',
//       'KIBOGTOK',
//       'MABUHAY',
//       'MALINAO',
//       'MATAMPAY',
//       'PAY-AS',
//       'PINAMANGUHAN',
//       'POBLACION',
//       'SALVACION',
//       'SAN ANDRES',
//       'SIBONGA',
//     ],
//   },
//   KALILANGAN: {
//     province: 'BUKIDNON',
//     municipality: 'KALILANGAN',
//     region: 'REGION X',
//     barangay_list: [
//       'BABORAWON',
//       'BANGBANG',
//       'CANITUAN',
//       'KIBANING',
//       'KINURA',
//       'LAMPANUSAN',
//       'MACA-OPAO',
//       'MALINAO',
//       'NINOY AQUINO',
//       'PAMOTOLON (PAMOTDON)',
//       'POBLACION',
//       'PUBLIC',
//       'SAN VICENTE FERRER',
//       'WEST POBLACION',
//     ],
//   },
//   KIBAWE: {
//     province: 'BUKIDNON',
//     municipality: 'KIBAWE',
//     region: 'REGION X',
//     barangay_list: [
//       'BALINTAWAK',
//       'BUKANG LIWAYWAY',
//       'CAGAWASAN',
//       'EAST KIBAWE (POB.)',
//       'GUTAPOL',
//       'KIORAO',
//       'KISAWA',
//       'LABUAGON',
//       'MAGSAYSAY',
//       'MARAPANGI',
//       'MASCARIÑAS',
//       'NATULONGAN',
//       'NEW KIDAPAWAN',
//       'OLD KIBAWE',
//       'PALMA',
//       'PINAMULA',
//       'ROMAGOOC',
//       'SAMPAGUITA',
//       'SANIPON',
//       'SPRING',
//       'TALAHIRON',
//       'TUMARAS',
//       'WEST KIBAWE (POB.)',
//     ],
//   },
//   KITAOTAO: {
//     province: 'BUKIDNON',
//     municipality: 'KITAOTAO',
//     region: 'REGION X',
//     barangay_list: [
//       'BALANGIGAY',
//       'BALUKBUKAN',
//       'BERSHIBA',
//       'BINOONGAN',
//       'BOBONG',
//       'BOLOCAON',
//       'CABALANTIAN',
//       'CALAPATON',
//       'DIGONGAN',
//       'EAST DALURONG',
//       'KAHUSAYAN',
//       'KALUMIHAN',
//       'KAUYONAN',
//       'KIMOLONG',
//       'KIPILAS',
//       'KITAIHON',
//       'KITOBO',
//       'KIULOM',
//       'MAGSAYSAY',
//       'MALOBALO',
//       'METEBAGAO',
//       'NAPALICO',
//       'PAGAN',
//       'PANGANAN',
//       'POBLACION',
//       'SAGUNDANON',
//       'SAN ISIDRO',
//       'SAN LORENZO',
//       'SANTO ROSARIO',
//       'SINAYSAYAN (DALURONG)',
//       'SINUDA (SIMOD)',
//       'TANDONG',
//       'TAWAS',
//       'WEST DALURONG',
//       'WHITE KULAMAN',
//     ],
//   },
//   LANTAPAN: {
//     province: 'BUKIDNON',
//     municipality: 'LANTAPAN',
//     region: 'REGION X',
//     barangay_list: [
//       'ALANIB',
//       'BACLAYON',
//       'BALILA',
//       'BANTUANON',
//       'BASAK',
//       'BUGCAON',
//       'CAPITAN JUAN',
//       'CAWAYAN',
//       'KA-ATOAN (KAATUAN)',
//       'KIBANGAY',
//       'KULASIHAN',
//       'POBLACION',
//       'SONGCO',
//       'VICTORY',
//     ],
//   },
//   LIBONA: {
//     province: 'BUKIDNON',
//     municipality: 'LIBONA',
//     region: 'REGION X',
//     barangay_list: [
//       'CAPIHAN',
//       'CROSSING',
//       'GANGO',
//       'KILIOG',
//       'KINAWE',
//       'LATURAN',
//       'MAAMBONG',
//       'NANGKA',
//       'PALABUCAN',
//       'POBLACION',
//       'PONGOL',
//       'SAN JOSE',
//       'SANTA FE',
//       'SIL-IPON',
//     ],
//   },
//   MALITBOG: {
//     province: 'SOUTHERN LEYTE',
//     municipality: 'MALITBOG',
//     region: 'REGION VIII',
//     barangay_list: [
//       'ABGAO',
//       'ASUNCION',
//       'AURORA',
//       'BENIT',
//       'CAAGA',
//       'CABUL-ANONAN (POB.)',
//       'CADARUHAN',
//       'CADARUHAN SUR',
//       'CANDATAG',
//       'CANTAMUAC',
//       'CARAATAN',
//       'CONCEPCION',
//       'FATIMA',
//       'GUINABONAN',
//       'IBA',
//       'JUANGON',
//       'KAUSWAGAN',
//       'LAMBONAO',
//       'MAHAYAHAY',
//       'MANINGNING',
//       'MAUJO',
//       'NEW KATIPUNAN',
//       'PANCIL',
//       'PASIL (POB.)',
//       'SABANG',
//       'SAN ANTONIO (POB.)',
//       'SAN ISIDRO',
//       'SAN JOSE',
//       'SAN ROQUE',
//       'SAN VICENTE',
//       'SANGAHON',
//       'SANTA CRUZ',
//       'SANTO NIÑO',
//       'TALIWA (POB.)',
//       'TIGBAWAN I',
//       'TIGBAWAN II',
//       'TIMBA',
//     ],
//   },
//   'MANOLO FORTICH': {
//     province: 'BUKIDNON',
//     municipality: 'MANOLO FORTICH',
//     region: 'REGION X',
//     barangay_list: [
//       'AGUSAN CANYON',
//       'ALAE',
//       'DAHILAYAN',
//       'DALIRIG',
//       'DAMILAG',
//       'DICLUM',
//       'GUILANG-GUILANG',
//       'KALUGMANAN',
//       'LINDABAN',
//       'LINGION',
//       'LUNOCAN',
//       'MALUKO',
//       'MAMBATANGAN',
//       'MAMPAYAG',
//       'MANTIBUGAO',
//       'MINSURO',
//       'SAN MIGUEL',
//       'SANKANAN',
//       'SANTIAGO',
//       'SANTO NIÑO',
//       'TANKULAN (POB.)',
//       'TICALA',
//     ],
//   },
//   MARAMAG: {
//     province: 'BUKIDNON',
//     municipality: 'MARAMAG',
//     region: 'REGION X',
//     barangay_list: [
//       'ANAHAWON',
//       'BAGONGSILANG',
//       'BASE CAMP',
//       'BAYABASON (SPRING)',
//       'CAMP I',
//       'COLAMBUGAN',
//       'DAGUMBA-AN',
//       'DANGGAWAN',
//       'DOLOGON',
//       'KIHARONG',
//       'KISANDAY',
//       'KUYA',
//       'LA ROXAS',
//       'NORTH POBLACION',
//       'PANADTALAN',
//       'PANALSALAN',
//       'SAN MIGUEL',
//       'SAN ROQUE',
//       'SOUTH POBLACION',
//       'TUBIGON',
//     ],
//   },
//   PANGANTUCAN: {
//     province: 'BUKIDNON',
//     municipality: 'PANGANTUCAN',
//     region: 'REGION X',
//     barangay_list: [
//       'ADTUYON',
//       'BACUSANON',
//       'BANGAHAN',
//       'BARANDIAS',
//       'CONCEPCION',
//       'GANDINGAN',
//       'KIMANAIT',
//       'KIPADUKAN',
//       'LANGCATAON',
//       'LANTAY',
//       'MADAYA',
//       'MALIPAYON',
//       'MENDIS',
//       'NABALIWA',
//       'NEW EDEN',
//       'PAYAD',
//       'PIGTAURANAN',
//       'POBLACION',
//       'PORTULIN',
//     ],
//   },
//   QUEZON: {
//     province: 'PALAWAN',
//     municipality: 'QUEZON',
//     region: 'REGION IV-B',
//     barangay_list: [
//       'ALFONSO XIII (POB.)',
//       'ARAMAYWAN',
//       'BERONG',
//       'CALATAGBAK',
//       'CALUMPANG',
//       'ISUGOD',
//       'MAASIN',
//       'MALATGAO',
//       'PANITIAN',
//       'PINAGLABANAN',
//       'QUINLOGAN',
//       'SOWANGAN',
//       'TABON',
//       'TAGUSAO',
//     ],
//   },
//   'SAN FERNANDO': {
//     province: 'CEBU',
//     municipality: 'SAN FERNANDO',
//     region: 'REGION VII',
//     barangay_list: [
//       'BALUD',
//       'BALUNGAG',
//       'BASAK',
//       'BUGHO',
//       'CABATBATAN',
//       'GREENHILLS',
//       'ILAYA',
//       'LANTAWAN',
//       'LIBURON',
//       'MAGSICO',
//       'PANADTARAN',
//       'PITALO',
//       'POBLACION NORTH',
//       'POBLACION SOUTH',
//       'SAN ISIDRO',
//       'SANGAT',
//       'TABIONAN',
//       'TANANAS',
//       'TINUBDAN',
//       'TONGGO',
//       'TUBOD',
//     ],
//   },
//   SUMILAO: {
//     province: 'BUKIDNON',
//     municipality: 'SUMILAO',
//     region: 'REGION X',
//     barangay_list: [
//       'CULASI',
//       'KISOLON',
//       'LICOAN',
//       'LUPIAGAN',
//       'OCASION',
//       'POBLACION',
//       'PUNTIAN',
//       'SAN ROQUE',
//       'SAN VICENTE',
//       'VISTA VILLA',
//     ],
//   },
//   TALAKAG: {
//     province: 'BUKIDNON',
//     municipality: 'TALAKAG',
//     region: 'REGION X',
//     barangay_list: [
//       'BARANGAY 1 (POB.)',
//       'BARANGAY 2 (POB.)',
//       'BARANGAY 3 (POB.)',
//       'BARANGAY 4 (POB.)',
//       'BARANGAY 5 (POB.)',
//       'BASAK',
//       'BAYLANAN',
//       'CACAON',
//       'COLAWINGON',
//       'COSINA',
//       'DAGUMBAAN',
//       'DAGUNDALAHON',
//       'DOMINOROG',
//       'INDULANG',
//       'LANTUD',
//       'LAPOK',
//       'LIGURON',
//       'LINGI-ON',
//       'LIRONGAN',
//       'MIARAYON',
//       'SAGARAN',
//       'SALUCOT',
//       'SAN ANTONIO',
//       'SAN ISIDRO',
//       'SAN MIGUEL',
//       'SAN RAFAEL',
//       'SANTO NIÑO (LUMBAYAWA)',
//       'TAGBAK',
//       'TIKALAAN',
//     ],
//   },
//   'VALENCIA': {
//     province: 'BUKIDNON',
//     municipality: 'VALENCIA',
//     region: 'REGION X',
//     barangay_list: [
//       'BAGONTAAS',
//       'BANLAG',
//       'BAROBO',
//       'BATANGAN',
//       'CATUMBALON',
//       'COLONIA',
//       'CONCEPCION',
//       'DAGAT-KIDAVAO',
//       'GUINOYURAN',
//       'KAHAPUNAN',
//       'LALIGAN',
//       'LILINGAYON',
//       'LOURDES',
//       'LUMBAYAO',
//       'LUMBO',
//       'LUROGAN',
//       'MAAPAG',
//       'MABUHAY',
//       'MAILAG',
//       'MT. NEBO',
//       'NABAGO',
//       'PINATILAN',
//       'POBLACION',
//       'SAN CARLOS',
//       'SAN ISIDRO',
//       'SINABUAGAN',
//       'SINAYAWAN',
//       'SUGOD',
//       'TONGANTONGAN',
//       'TUGAYA',
//       'VINTAR',
//     ],
//   },
//   CATARMAN: {
//     province: 'NORTHERN SAMAR',
//     municipality: 'CATARMAN',
//     region: 'REGION VIII',
//     barangay_list: [
//       'ACACIA (POB.)',
//       'AGUINALDO',
//       'AIRPORT VILLAGE',
//       'BANGKEROHAN',
//       'BAYBAY',
//       'BOCSOL',
//       'CABAYHAN',
//       'CAG-ABACA',
//       'CAL-IGANG',
//       'CALACHUCHI (POB.)',
//       'CAWAYAN',
//       'CERVANTES',
//       'CULARIMA',
//       'DAGANAS',
//       'DALAKIT (POB.)',
//       'DOÑA PULQUERIA (HIMBANG)',
//       'GALUTAN',
//       'GEBALAGNAN (HIBALAGNAN)',
//       'GEBULWANGAN',
//       'GENERAL MALVAR',
//       'GUBA',
//       'HINATAD',
//       'IMELDA (ELIMBO)',
//       'IPIL-IPIL (POB.)',
//       'JOSE ABAD SANTOS (POB.)',
//       'JOSE P. RIZAL (POB.)',
//       'KASOY (POB.)',
//       'LAPU-LAPU (POB.)',
//       'LIBERTY',
//       'LIBJO (BINOG)',
//       'MABINI',
//       'MABOLO (POB.)',
//       'MACAGTAS',
//       'MCKINLEY',
//       'MOLAVE (POB.)',
//       'NARRA (POB.)',
//       'NEW RIZAL',
//       'OLD RIZAL',
//       'PATICUA',
//       'POLANGI',
//       'QUEZON',
//       'SALVACION',
//       'SAMPAGUITA (POB.)',
//       'SAN JULIAN',
//       'SAN PASCUAL',
//       'SANTOL (POB.)',
//       'SOMOGE',
//       'TALISAY (POB.)',
//       'TINOWARAN',
//       'TRANGUE',
//       'UEP I',
//       'UEP II',
//       'UEP III',
//       'WASHINGTON',
//       'YAKAL (POB.)',
//     ],
//   },
//   GUINSILIBAN: {
//     province: 'CAMIGUIN',
//     municipality: 'GUINSILIBAN',
//     region: 'REGION X',
//     barangay_list: [
//       'BUTAY',
//       'CABUAN',
//       'CANTAAN',
//       'LIONG',
//       'MAAC',
//       'NORTH POBLACION',
//       'SOUTH POBLACION',
//     ],
//   },
//   MAHINOG: {
//     province: 'CAMIGUIN',
//     municipality: 'MAHINOG',
//     region: 'REGION X',
//     barangay_list: [
//       'BENONI',
//       'BINATUBO (BINALIWAN)',
//       'CATOHUGAN',
//       'HUBANGON',
//       'OWAKAN',
//       'POBLACION',
//       'PUNTOD',
//       'SAN ISIDRO',
//       'SAN JOSE',
//       'SAN MIGUEL',
//       'SAN ROQUE',
//       'TUBOD',
//       'TUPSAN PEQUEÑO',
//     ],
//   },
//   MAMBAJAO: {
//     province: 'CAMIGUIN',
//     municipality: 'MAMBAJAO',
//     region: 'REGION X',
//     barangay_list: [
//       'AGOHO',
//       'ANITO',
//       'BALBAGON',
//       'BAYLAO',
//       'BENHAAN',
//       'BUG-ONG',
//       'KUGUITA',
//       'MAGTING',
//       'NAASAG',
//       'PANDAN',
//       'POBLACION',
//       'SORO-SORO',
//       'TAGDO',
//       'TUPSAN',
//       'YUMBING',
//     ],
//   },
//   SAGAY: {
//     province: 'CAMIGUIN',
//     municipality: 'SAGAY',
//     region: 'REGION X',
//     barangay_list: [
//       'ALANGILAN',
//       'BACNIT',
//       'BALITE',
//       'BONBON',
//       'BUGANG',
//       'CUNA',
//       'MANUYOG',
//       'MAYANA',
//       'POBLACION',
//     ],
//   },
//   BACOLOD: {
//     province: 'LANAO DEL NORTE',
//     municipality: 'BACOLOD',
//     region: 'REGION X',
//     barangay_list: [
//       'ALEGRIA',
//       'BABALAYA',
//       'BABALAYAN TOWNSITE',
//       'BINUNI',
//       'DELABAYAN WEST',
//       'DEMOLOGAN',
//       'DIMARAO',
//       'ESPERANZA',
//       'KAHAYAG',
//       'LIANGAN EAST',
//       'MATI',
//       'MINAULON',
//       'PAGAYAWAN',
//       'POBLACION BACOLOD',
//       'PUNOD (MALIWANAG)',
//       'RUPAGAN',
//     ],
//   },
//   BALOI: {
//     province: 'LANAO DEL NORTE',
//     municipality: 'BALOI',
//     region: 'REGION X',
//     barangay_list: [
//       'ABAGA',
//       'ADAPUN-ALI (DARIAT)',
//       'ANGANDOG (BULAO)',
//       'ANGAYEN (BALUT)',
//       'BANGKO',
//       'BATOLACONGAN (BASAGAD)',
//       'BUENAVISTA',
//       'CADAYONAN',
//       'LANDA (GADONGAN)',
//       'LUMBAC',
//       'MAMAANUN',
//       'MARIA-CRISTINA',
//       'MATAMPAY',
//       'NANGKA',
//       'PACALUNDO',
//       'POBLACION EAST',
//       'POBLACION WEST',
//       'SANDOR (DADUAN)',
//       'SANGCAD (CORMATAN )',
//       'SARIP-ALAWI (PAYAWAN)',
//       'SIGAYAN',
//     ],
//   },
//   BAROY: {
//     province: 'LANAO DEL NORTE',
//     municipality: 'BAROY',
//     region: 'REGION X',
//     barangay_list: [
//       'ANDIL',
//       'BAGONG DAWIS',
//       'BAROY DAKU',
//       'BATO',
//       'CABASAGAN',
//       'DALAMA',
//       'LIBERTAD',
//       'LIMWAG',
//       'LINDONGAN',
//       'MALIWANAG',
//       'MANAN-AO',
//       'PANGE',
//       'PINDOLONAN',
//       'POBLACION',
//       'PRINCESA',
//       'RAWAN POINT',
//       'RIVERSIDE',
//       'SAGADAN (SAGADAN LOWER)',
//       'SAGADAN UPPER',
//       'SALONG',
//       'SAN JUAN',
//       'TINUBDAN',
//       'VILLAGE',
//     ],
//   },
//   'ILIGAN': {
//     province: 'LANAO DEL NORTE',
//     municipality: 'ILIGAN',
//     region: 'REGION X',
//     barangay_list: [
//       'ABUNO',
//       'ACMAC',
//       'BAGONG SILANG',
//       'BONBONON',
//       'BUNAWAN',
//       'BURU-UN',
//       'DALIPUGA',
//       'DEL CARMEN',
//       'DIGKILAAN',
//       'DITUCALAN',
//       'DULAG',
//       'HINAPLANON',
//       'HINDANG',
//       'KABACSANAN',
//       'KALILANGAN',
//       'KIWALAN',
//       'LANIPAO',
//       'LUINAB',
//       'MAHAYHAY',
//       'MAINIT',
//       'MANDULOG',
//       'MARIA CRISTINA',
//       'PALAO',
//       'PANOROGANAN',
//       'POBLACION',
//       'PUGA-AN',
//       'ROGONGON',
//       'SAN MIGUEL',
//       'SAN ROQUE',
//       'SANTA ELENA',
//       'SANTA FILOMENA',
//       'SANTIAGO',
//       'SANTO ROSARIO',
//       'SARAY-TIBANGA',
//       'SUAREZ',
//       'TAMBACAN',
//       'TIBANGA',
//       'TIPANOY',
//       'TOMINOBO PROPER',
//       'TOMINOBO UPPER',
//       'TUBOD',
//       'UBALDO LAYA',
//       'UPPER HINAPLANON',
//       'VILLA VERDE',
//     ],
//   },
//   KAPATAGAN: {
//     province: 'LANAO DEL SUR',
//     municipality: 'KAPATAGAN',
//     region: 'BARMM',
//     barangay_list: [
//       'BAKIKIS',
//       'BARAO',
//       'BONGABONG',
//       'DAGUAN',
//       'INUDARAN',
//       'KABANIAKAWAN',
//       'KAPATAGAN',
//       'LUSAIN',
//       'MATIMOS',
//       'MINIMAO',
//       'PINANTAO',
//       'SALAMAN',
//       'SIGAYAN',
//       'TABUAN',
//       'UPPER IGABAY',
//     ],
//   },
//   KAUSWAGAN: {
//     province: 'LANAO DEL NORTE',
//     municipality: 'KAUSWAGAN',
//     region: 'REGION X',
//     barangay_list: [
//       'BAGUMBAYAN (POB.)',
//       'BARA-ASON',
//       'CAYONTOR',
//       'DELABAYAN',
//       'INUDARAN',
//       'KAWIT OCCIDENTAL',
//       'KAWIT ORIENTAL',
//       'LIBERTAD',
//       'PAITON',
//       'POBLACION',
//       'TACUB',
//       'TINGINTINGIN',
//       'TUGAR',
//     ],
//   },
//   KOLAMBUGAN: {
//     province: 'LANAO DEL NORTE',
//     municipality: 'KOLAMBUGAN',
//     region: 'REGION X',
//     barangay_list: [
//       'AUSTIN HEIGHTS',
//       'BAYBAY',
//       'BUBONG',
//       'CAROMATAN',
//       'INUDARAN',
//       'KULASIHAN',
//       'LIBERTAD',
//       'LUMBAC',
//       'MANGA',
//       'MATAMPAY',
//       'MUKAS',
//       'MUNTAY',
//       'PAGALUNGAN',
//       'PALAO',
//       'PANTAON',
//       'PANTAR',
//       'POBLACION',
//       'REBUCON',
//       'RIVERSIDE',
//       'SAN ROQUE',
//       'SANTO NIÑO',
//       'SIMBUCO',
//       'SMALL BANISILAN',
//       'SUCODAN',
//       'TABIGUE',
//       'TITUNOD',
//     ],
//   },
//   LALA: {
//     province: 'LANAO DEL NORTE',
//     municipality: 'LALA',
//     region: 'REGION X',
//     barangay_list: [
//       'ABAGA',
//       'ANDIL',
//       'CABASAGAN',
//       'CAMALAN',
//       'DARUMAWANG BUCANA',
//       'DARUMAWANG ILAYA',
//       'EL SALVADOR',
//       'GUMAGAMOT',
//       'LALA PROPER (POB.)',
//       'LANIPAO',
//       'MAGPATAO',
//       'MARANDING',
//       'MATAMPAY BUCANA',
//       'MATAMPAY ILAYA',
//       'PACITA',
//       'PENDOLONAN',
//       'PINOYAK',
//       'RAW-AN',
//       'REBE',
//       'SAN ISIDRO LOWER',
//       'SAN ISIDRO UPPER',
//       'SAN MANUEL',
//       'SANTA CRUZ LOWER',
//       'SANTA CRUZ UPPER',
//       'SIMPAK',
//       'TENAZAS',
//       'TUNA-AN',
//     ],
//   },
//   LINAMON: {
//     province: 'LANAO DEL NORTE',
//     municipality: 'LINAMON',
//     region: 'REGION X',
//     barangay_list: [
//       'BUSQUE',
//       'LARAPAN',
//       'MAGOONG',
//       'NAPO',
//       'POBLACION',
//       'PURAKAN',
//       'ROBOCON',
//       'SAMBURON',
//     ],
//   },
//   MAGSAYSAY: {
//     province: 'PALAWAN',
//     municipality: 'MAGSAYSAY',
//     region: 'REGION IV-B',
//     barangay_list: [
//       'ALCOBA',
//       'BALAGUEN',
//       'CANIPO',
//       'COCORO',
//       'DANAWAN (POB.)',
//       'EMILOD',
//       'IGABAS',
//       'LACAREN',
//       'LOS ANGELES',
//       'LUCBUAN',
//       'RIZAL',
//     ],
//   },
//   MAIGO: {
//     province: 'LANAO DEL NORTE',
//     municipality: 'MAIGO',
//     region: 'REGION X',
//     barangay_list: [
//       'BALAGATASA',
//       'CAMP 1',
//       'CLARO M. RECTO',
//       'INOMA',
//       'KULASIHAN (VILLANUEVA)',
//       'LABUAY',
//       'LIANGAN WEST',
//       'MAHAYAHAY',
//       'MALIWANAG',
//       'MENTRING',
//       'POBLACION',
//       'SANTA CRUZ',
//       'SOGAPOD',
//     ],
//   },
//   MATUNGAO: {
//     province: 'LANAO DEL NORTE',
//     municipality: 'MATUNGAO',
//     region: 'REGION X',
//     barangay_list: [
//       'BANGCO',
//       'BATAL',
//       'BATANGAN',
//       'BUBONG RADAPAN',
//       'CADAYONAN',
//       'MATAMPAY',
//       'PANGI',
//       'PASAYANON',
//       'POBLACION (MATUNGAO)',
//       'PUNTOD',
//       'SANTA CRUZ',
//       'SOMIORANG',
//     ],
//   },
//   MUNAI: {
//     province: 'LANAO DEL NORTE',
//     municipality: 'MUNAI',
//     region: 'REGION X',
//     barangay_list: [
//       'BACAYAWAN',
//       'BALABACUN',
//       'BALINTAD',
//       'DALAMA',
//       'KADAYONAN',
//       'LINDONGAN',
//       'LINGCO-AN',
//       'LININDING',
//       'LUMBA-BAYABAO',
//       'MADAYA',
//       'MAGANDING',
//       'MATAMPAY',
//       'NORTH CADULAWAN',
//       'OLD POBLACION',
//       'PANGGAO',
//       'PANTAO',
//       'PANTAO-A-MUNAI',
//       'PANTAON',
//       'PINDOLONAN',
//       'PUNONG',
//       'RAMAIN',
//       'SANDIGAMUNAI',
//       'TAGORANAO',
//       'TAMBO',
//       'TAMPARAN (MANDAYA)',
//       'TAPOROG',
//     ],
//   },
//   NUNUNGAN: {
//     province: 'LANAO DEL NORTE',
//     municipality: 'NUNUNGAN',
//     region: 'REGION X',
//     barangay_list: [
//       'ABAGA',
//       'BANGCO',
//       'CABASARAN (LAYA)',
//       'CANIBONGAN',
//       'DIMAYON',
//       'INAYAWAN',
//       'KALUDAN',
//       'KARCUM',
//       'KATUBUAN',
//       'LIANGAN',
//       'LUPITAN',
//       'MALAIG',
//       'MANGAN',
//       'MASIBAY',
//       'NOTONGAN',
//       'PANGANAPAN',
//       'PANTAR',
//       'PARIDI',
//       'PETADUN',
//       'POBLACION (NUNUNGAN PROPER)',
//       'RARAB',
//       'RARABAN',
//       'REBUCON',
//       'SONGGOD',
//       'TARAKA',
//     ],
//   },
//   'PANTAO RAGAT': {
//     province: 'LANAO DEL NORTE',
//     municipality: 'PANTAO RAGAT',
//     region: 'REGION X',
//     barangay_list: [
//       'ALOON',
//       'BANDAY',
//       'BOBONGA PANTAO RAGAT',
//       'BOBONGA RADAPAN',
//       'CABASAGAN',
//       'CALAWE',
//       'CULUBUN',
//       'DILIMBAYAN',
//       'DIMAYON',
//       'LOMIDONG',
//       'MADAYA',
//       'MALIWANAG',
//       'MATAMPAY',
//       'NATANGCOPAN',
//       'PANSOR',
//       'PANTAO MARUG',
//       'POBLACION EAST',
//       'POBLACION WEST',
//       'TANGCAL',
//       'TONGCOPAN',
//     ],
//   },
//   PANTAR: {
//     province: 'LANAO DEL NORTE',
//     municipality: 'PANTAR',
//     region: 'REGION X',
//     barangay_list: [
//       'BANGCAL',
//       'BOWI',
//       'BUBONG MADAYA',
//       'CABASARAN',
//       'CADAYONAN',
//       'CAMPONG',
//       'DIBAROSAN',
//       'KALANGANAN EAST',
//       'KALANGANAN LOWER',
//       'KALILANGAN',
//       'LUMBA-PUNOD',
//       'PANTAO-MARUG',
//       'PANTAO-RANAO',
//       'PANTAR EAST',
//       'PITUBO',
//       'POBLACION',
//       'POONA-PUNOD',
//       'PUNOD',
//       'SUNDIGA-PUNOD',
//       'TAWANAN',
//       'WEST PANTAR',
//     ],
//   },
//   'POONA PIAGAPO': {
//     province: 'LANAO DEL NORTE',
//     municipality: 'POONA PIAGAPO',
//     region: 'REGION X',
//     barangay_list: [
//       'ALOWIN',
//       'BUBONG-DINAIG',
//       'CABASARAN',
//       'CADAYONAN',
//       'CAROMATAN',
//       'DARAMBA',
//       'DINAIG',
//       'KABLANGAN',
//       'LININDINGAN',
//       'LUMBATAN',
//       'LUPITAN',
//       'MADAMBA',
//       'MADAYA',
//       'MALIWANAG',
//       'NUNANG',
//       'NUNUNGAN',
//       'PANTAO RAYA',
//       'PANTAON',
//       'PENDOLONAN',
//       'PENED',
//       'PIANGAMANGAAN',
//       'POBLACION (LUMBACAINGUD)',
//       'SULO',
//       'TAGORANAO',
//       'TANGCLAO',
//       'TIMBANGALAN',
//     ],
//   },
//   SALVADOR: {
//     province: 'LANAO DEL NORTE',
//     municipality: 'SALVADOR',
//     region: 'REGION X',
//     barangay_list: [
//       'BARANDIA',
//       'BULACON',
//       'BUNTONG',
//       'CALIMODAN',
//       'CAMP III',
//       'CURVA-MIAGAO',
//       'DALIGDIGAN',
//       'INASAGAN',
//       'KILALA',
//       'MABATAO',
//       'MADAYA',
//       'MAMAANON',
//       'MAPANTAO',
//       'MINDALANO',
//       'PADIANAN',
//       'PAGALONGAN',
//       'PAGAYAWAN',
//       'PANALIWAD-ON',
//       'PANGANTAPAN',
//       'PANSOR',
//       'PATIDON',
//       'PAWAK',
//       'POBLACION',
//       'SAUMAY',
//       'SUDLON',
//     ],
//   },
//   SAPAD: {
//     province: 'LANAO DEL NORTE',
//     municipality: 'SAPAD',
//     region: 'REGION X',
//     barangay_list: [
//       'BANING',
//       'BURIASAN (POB.)',
//       'DANSALAN',
//       'GAMAL',
//       'INUDARAN I',
//       'INUDARAN II',
//       'KARKUM',
//       'KATIPUNAN',
//       'MABUGNAO',
//       'MAITO SALUG',
//       'MALA SALUG',
//       'MAMA-ANON',
//       'MAPUROG',
//       'PANCILAN',
//       'PANOLOON',
//       'PILI',
//       'SAPAD',
//     ],
//   },
//   'SULTAN NAGA DIMAPORO (KAROMATAN)': {
//     province: 'LANAO DEL NORTE',
//     municipality: 'SULTAN NAGA DIMAPORO (KAROMATAN)',
//     region: 'REGION X',
//     barangay_list: [
//       'BANGAAN',
//       'BANGCO',
//       'BANSARVIL II',
//       'BAUYAN',
//       'CABONGBONGAN',
//       'CALIBAO',
//       'CALIPAPA',
//       'CALUBE',
//       'CAMPO ISLAM',
//       'CAPOCAO',
//       'DABLISTON',
//       'DALAMA',
//       'DANGULAAN',
//       'DITAGO',
//       'ILIAN',
//       'KAUSWAGAN',
//       'KIRAPAN',
//       'KOREO',
//       'LANTAWAN',
//       'MABUHAY',
//       'MAGUINDANAO',
//       'MAHAYAHAY',
//       'MAMAGUM',
//       'MINA',
//       'PANDANAN',
//       'PAYONG',
//       'PIKALAWAG',
//       'PIKINIT',
//       'PIRAKA',
//       'POBLACION',
//       'RAMAIN',
//       'REBUCON',
//       'SIGAYAN',
//       'SUGOD',
//       'TAGULO',
//       'TANTAON',
//       'TOPOCON (CAPOCGO)',
//     ],
//   },
//   TAGOLOAN: {
//     province: 'LANAO DEL SUR',
//     municipality: 'TAGOLOAN',
//     region: 'BARMM',
//     barangay_list: [
//       'BAGOAINGUD',
//       'BANTALAN',
//       'BAYOG',
//       'CADAYONAN',
//       'DAGONALAN',
//       'DIMALAMA',
//       'GAYAKAY',
//       'INODARAN',
//       'KALILANGAN',
//       'KIANIBONG',
//       'KINGAN',
//       'KITAON',
//       'MAIMBAGUIANG',
//       'MALINAO',
//       'MALINGON',
//       'MAMA-AN PAGALONGAN',
//       'MARAWI',
//       'SIGAYAN',
//       'TAGOLOAN POBLACION',
//     ],
//   },
//   TANGCAL: {
//     province: 'LANAO DEL NORTE',
//     municipality: 'TANGCAL',
//     region: 'REGION X',
//     barangay_list: [
//       'BAYABAO',
//       'BERWAR',
//       'BIG BANISILON',
//       'BIG MELADOC',
//       'BUBONG',
//       'LAMAOSA',
//       'LINAO',
//       'LINDONGAN',
//       'LINGCO-AN',
//       'PAPAN',
//       'PELINGKINGAN',
//       'POBLACION',
//       'POONA KAPATAGAN',
//       'PUNOD',
//       'SMALL BANISILON',
//       'SMALL MELADOC',
//       'SOMIORANG',
//       'TANGCAL PROPER',
//     ],
//   },
//   TUBOD: {
//     province: 'SURIGAO DEL NORTE',
//     municipality: 'TUBOD',
//     region: 'REGION XIII',
//     barangay_list: [
//       'CAPAYAHAN',
//       'CAWILAN',
//       'DEL ROSARIO',
//       'MARGA',
//       'MOTORPOOL',
//       'POBLACION (TUBOD)',
//       'SAN ISIDRO',
//       'SAN PABLO',
//       'TIMAMANA',
//     ],
//   },
//   ALORAN: {
//     province: 'MISAMIS OCCIDENTAL',
//     municipality: 'ALORAN',
//     region: 'REGION X',
//     barangay_list: [
//       'BALINTONGA',
//       'BANISILON',
//       'BURGOS',
//       'CALUBE',
//       'CAPUTOL',
//       'CASUSAN',
//       'CONAT',
//       'CULPAN',
//       'DALISAY',
//       'DULLAN',
//       'IBABAO',
//       'LABO',
//       'LAWA-AN',
//       'LOBOGON',
//       'LUMBAYAO',
//       'MACUBON (SINA-AD)',
//       'MAKAWA',
//       'MANAMONG',
//       'MATIPAZ',
//       'MAULAR',
//       'MITAZAN',
//       'MOHON',
//       'MONTERICO',
//       'NABUNA',
//       'OSPITAL (POB.)',
//       'PALAYAN',
//       'PELONG',
//       'ROXAS',
//       'SAN PEDRO',
//       'SANTA ANA',
//       'SINAMPONGAN',
//       'TAGUANAO',
//       'TAWI-TAWI',
//       'TORIL',
//       'TUBOD (JUAN BACAYO)',
//       'TUBURAN',
//       'TUGAYA',
//       'ZAMORA',
//     ],
//   },
//   BALIANGAO: {
//     province: 'MISAMIS OCCIDENTAL',
//     municipality: 'BALIANGAO',
//     region: 'REGION X',
//     barangay_list: [
//       'DEL PILAR',
//       'LANDING',
//       'LUMIPAC',
//       'LUSOT',
//       'MABINI',
//       'MAGSAYSAY',
//       'MISOM',
//       'MITACAS',
//       'NABUROS',
//       'NORTHERN POBLACION',
//       'PUNTA MIRAY',
//       'PUNTA SULONG',
//       'SINIAN',
//       'SOUTHERN POBLACION',
//       'TUGAS',
//     ],
//   },
//   BONIFACIO: {
//     province: 'MISAMIS OCCIDENTAL',
//     municipality: 'BONIFACIO',
//     region: 'REGION X',
//     barangay_list: [
//       'BAG-ONG ANONANG',
//       'BAGUMBANG',
//       'BAYBAY',
//       'BOLINSONG',
//       'BUENAVISTA',
//       'BURACAN',
//       'CALOLOT',
//       'DEMETRIO FERNAN',
//       'DIGSON',
//       'DIMALCO',
//       'DULLAN',
//       'KANAOKANAO',
//       'LILOAN',
//       'LINCONAN',
//       'LODIONG',
//       'LOWER USUGAN',
//       'MAPUROG (MIGSALE)',
//       'MIGPANGE',
//       'MONTOL',
//       'PISA-AN',
//       'POBLACION (CENTRO)',
//       'REMEDIOS',
//       'RUFINO LUMAPAS',
//       'SIBUYON',
//       'TANGAB',
//       'TIAMAN',
//       'TUSIK',
//       'UPPER USOGAN',
//     ],
//   },
//   CALAMBA: {
//     province: 'MISAMIS OCCIDENTAL',
//     municipality: 'CALAMBA',
//     region: 'REGION X',
//     barangay_list: [
//       'BONIFACIO',
//       'BUNAWAN',
//       'CALARAN',
//       'DAPACAN ALTO',
//       'DAPACAN BAJO',
//       'DON BERNARDO NERI POB. (TRES DE MAYO)',
//       'LANGUB',
//       'LIBERTAD',
//       'MAGCAMIGUING',
//       'MAMALAD',
//       'MAUSWAGON',
//       'NORTHERN POBLACION',
//       'SALVADOR',
//       'SAN ISIDRO (SAN ISIDRO-SAN PEDRO)',
//       'SILOY',
//       'SINGALAT',
//       'SOLINOG',
//       'SOUTHWESTERN POBLACION',
//       'SULIPAT',
//     ],
//   },
//   CLARIN: {
//     province: 'BOHOL',
//     municipality: 'CLARIN',
//     region: 'REGION VII',
//     barangay_list: [
//       'BACANI',
//       'BOGTONGBOD',
//       'BONBON',
//       'BONTUD',
//       'BUACAO',
//       'BUANGAN',
//       'CABOG',
//       'CABOY',
//       'CALUWASAN',
//       'CANDAJEC',
//       'CANTOYOC',
//       'COMAANG',
//       'DANAHAO',
//       'KATIPUNAN',
//       'LAJOG',
//       'MATAUB',
//       'NAHAWAN',
//       'POBLACION CENTRO',
//       'POBLACION NORTE',
//       'POBLACION SUR',
//       'TANGARAN',
//       'TONTUNAN',
//       'TUBOD',
//       'VILLAFLOR',
//     ],
//   },
//   CONCEPCION: {
//     province: 'ILOILO',
//     municipality: 'CONCEPCION',
//     region: 'REGION VI',
//     barangay_list: [
//       'AGLOSONG',
//       'AGNAGA',
//       'BACJAWAN NORTE',
//       'BACJAWAN SUR',
//       'BAGONGON',
//       'BATITI',
//       'BOTLOG',
//       'CALAMIGAN',
//       'DUNGON',
//       'IGBON',
//       'JAMUL-AWON',
//       'LO-ONG',
//       'MACALBANG',
//       'MACATUNAO',
//       'MALANGABANG',
//       'MALIOGLIOG',
//       'NIÑO',
//       'NIPA',
//       'PLANDICO',
//       'POBLACION',
//       'POLOPINA',
//       'SALVACION',
//       'TALOTU-AN',
//       'TAMBALIZA',
//       'TAMIS-AC',
//     ],
//   },
//   'DON VICTORIANO CHIONGBIAN (DON MARIANO MARCOS)': {
//     province: 'MISAMIS OCCIDENTAL',
//     municipality: 'DON VICTORIANO CHIONGBIAN (DON MARIANO MARCOS)',
//     region: 'REGION X',
//     barangay_list: [
//       'BAGONG CLARIN',
//       'GANDAWAN',
//       'LAKE DUMINAGAT',
//       'LALUD',
//       'LAMPASAN',
//       'LIBORON',
//       'MARAMARA',
//       'NAPANGAN',
//       'NUEVA VISTA (MASAWAN)',
//       'PETIANAN',
//       'TUNO',
//     ],
//   },
//   JIMENEZ: {
//     province: 'MISAMIS OCCIDENTAL',
//     municipality: 'JIMENEZ',
//     region: 'REGION X',
//     barangay_list: [
//       'ADORABLE',
//       'BUTUAY',
//       'CARMEN',
//       'CORRALES',
//       'DICOLOC',
//       'GATA',
//       'GUINTOMOYAN',
//       'MACABAYAO',
//       'MALIBACSAN',
//       'MATUGAS ALTO',
//       'MATUGAS BAJO',
//       'MIALEM',
//       'NACIONAL (POB.)',
//       'NAGA (POB.)',
//       'PALILAN',
//       'RIZAL (POB.)',
//       'SAN ISIDRO',
//       'SANTA CRUZ (POB.)',
//       'SETI',
//       'SIBAROC',
//       'SINARA ALTO',
//       'SINARA BAJO',
//       'TABO-O',
//       'TARAKA (POB.)',
//     ],
//   },
//   'LOPEZ JAENA': {
//     province: 'MISAMIS OCCIDENTAL',
//     municipality: 'LOPEZ JAENA',
//     region: 'REGION X',
//     barangay_list: [
//       'ALEGRIA',
//       'BAGONG SILANG',
//       'BIASONG',
//       'BONIFACIO',
//       'BURGOS',
//       'DALACON',
//       'DAMPALAN',
//       'DON ANDRES SORIANO',
//       'EASTERN POBLACION',
//       'ESTANTE',
//       'JASA-AN',
//       'KATIPA',
//       'LUZARAN',
//       'MABAS',
//       'MACALIBRE ALTO',
//       'MACALIBRE BAJO',
//       'MAHAYAHAY',
//       'MANGUEHAN',
//       'MANSABAY ALTO',
//       'MANSABAY BAJO',
//       'MOLATUHAN ALTO',
//       'MOLATUHAN BAJO',
//       'PENIEL',
//       'PUNTOD',
//       'RIZAL',
//       'SIBUGON',
//       'SIBULA',
//       'WESTERN POBLACION',
//     ],
//   },
//   'OROQUIETA': {
//     province: 'MISAMIS OCCIDENTAL',
//     municipality: 'OROQUIETA',
//     region: 'REGION X',
//     barangay_list: [
//       'APIL',
//       'BINUANGAN',
//       'BOLIBOL',
//       'BUENAVISTA',
//       'BUNGA',
//       'BUNTAWAN',
//       'BURGOS',
//       'CANUBAY',
//       'CIRIACO C. PASTRANO (NILABO)',
//       'CLARIN SETTLEMENT',
//       'DOLIPOS ALTO',
//       'DOLIPOS BAJO',
//       'DULAPO',
//       'DULLAN NORTE',
//       'DULLAN SUR',
//       'LAMAC LOWER',
//       'LAMAC UPPER',
//       'LANGCANGAN LOWER',
//       'LANGCANGAN PROPER',
//       'LANGCANGAN UPPER',
//       'LAYAWAN',
//       'LOBOC LOWER',
//       'LOBOC UPPER',
//       'MALINDANG',
//       'MIALEN',
//       'MOBOD',
//       'PAYPAYAN',
//       'PINES',
//       'POBLACION I',
//       'POBLACION II',
//       'RIZAL LOWER',
//       'RIZAL UPPER',
//       'SAN VICENTE ALTO',
//       'SAN VICENTE BAJO',
//       'SEBUCAL',
//       'SENOTE',
//       'TABOC NORTE',
//       'TABOC SUR',
//       'TALAIRON',
//       'TALIC',
//       'TIPAN',
//       'TOLIYOK',
//       'TUYABANG ALTO',
//       'TUYABANG BAJO',
//       'TUYABANG PROPER',
//       'VICTORIA',
//       'VILLAFLOR',
//     ],
//   },
//   'OZAMIS': {
//     province: 'MISAMIS OCCIDENTAL',
//     municipality: 'OZAMIS',
//     region: 'REGION X',
//     barangay_list: [
//       '50TH DISTRICT (POB.)',
//       'AGUADA (POB.)',
//       'BACOLOD',
//       'BAGAKAY',
//       'BALINTAWAK',
//       'BANADERO (POB.)',
//       'BAYBAY SAN ROQUE',
//       'BAYBAY SANTA CRUZ',
//       'BAYBAY TRIUNFO',
//       'BONGBONG',
//       'CALABAYAN',
//       'CAPUCAO C.',
//       'CAPUCAO P.',
//       'CARANGAN',
//       'CARMEN (MISAMIS ANNEX)',
//       'CATADMAN-MANABAY',
//       'CAVINTE',
//       'COGON',
//       'DALAPANG',
//       'DIGUAN',
//       'DIMALUNA',
//       'DOÑA CONSUELO',
//       'EMBARGO',
//       'GALA',
//       'GANGO',
//       'GOTOKAN DAKU',
//       'GOTOKAN DIOT',
//       'GUIMAD',
//       'GUINGONA',
//       'KINUMAN NORTE',
//       'KINUMAN SUR',
//       'LABINAY',
//       'LABO',
//       'LAM-AN',
//       'LIPOSONG',
//       'LITAPAN',
//       'MALAUBANG',
//       'MANAKA',
//       'MANINGCOL',
//       'MENTERING',
//       'MOLICAY',
//       'PANTAON',
//       'PULOT',
//       'SAN ANTONIO',
//       'SANGAY DAKU',
//       'SANGAY DIOT',
//       'SINUZA',
//       'STIMSON ABORDO (MONTOL)',
//       'TABID',
//       'TINAGO',
//       'TRIGOS',
//     ],
//   },
//   PANAON: {
//     province: 'MISAMIS OCCIDENTAL',
//     municipality: 'PANAON',
//     region: 'REGION X',
//     barangay_list: [
//       'BAGA',
//       'BANGKO',
//       'CAMANUCAN',
//       'DELA PAZ',
//       'LUTAO',
//       'MAGSAYSAY',
//       'MAP-AN',
//       'MOHON',
//       'POBLACION',
//       'PUNTA',
//       'SALIMPUNO',
//       'SAN ANDRES',
//       'SAN JUAN',
//       'SAN ROQUE',
//       'SUMASAP',
//       'VILLALIN',
//     ],
//   },
//   PLARIDEL: {
//     province: 'QUEZON',
//     municipality: 'PLARIDEL',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'CENTRAL (POB.)',
//       'CONCEPCION',
//       'DUHAT',
//       'ILAYA',
//       'ILOSONG',
//       'M. L. TUMAGAY POB. (REMAS MABUTON)',
//       'PAANG BUNDOK (POB.)',
//       'PAMPAARALAN (POB.)',
//       'TANAUAN',
//     ],
//   },
//   'SAPANG DALAGA': {
//     province: 'MISAMIS OCCIDENTAL',
//     municipality: 'SAPANG DALAGA',
//     region: 'REGION X',
//     barangay_list: [
//       'AGAPITO YAP, SR. (NAPILAN)',
//       'BAUTISTA',
//       'BITIBUT',
//       'BOUNDARY',
//       'CALUYA',
//       'CAPUNDAG',
//       'CASUL',
//       'DALUMPINAS',
//       'DASA',
//       'DIOYO',
//       'DISOY',
//       'EL PARAISO',
//       'GUINABOT',
//       'LIBERTAD',
//       'LOCUS',
//       'MACABIBO',
//       'MANLA',
//       'MASUBONG',
//       'MEDALLO',
//       'POBLACION',
//       'SALIMPUNO',
//       'SAN AGUSTIN',
//       'SAPANG AMA',
//       'SINAAD',
//       'SIPAC',
//       'SIXTO VELEZ, SR.',
//       'UPPER BAUTISTA',
//       'VENTURA',
//     ],
//   },
//   SINACABAN: {
//     province: 'MISAMIS OCCIDENTAL',
//     municipality: 'SINACABAN',
//     region: 'REGION X',
//     barangay_list: [
//       'CAGAY-ANON',
//       'CAMANSE',
//       'COLUPAN ALTO',
//       'COLUPAN BAJO',
//       'DINAS',
//       'ESTRELLA',
//       'KATIPUNAN',
//       'LIBERTAD ALTO',
//       'LIBERTAD BAJO',
//       'POBLACION',
//       'SAN ISIDRO ALTO',
//       'SAN ISIDRO BAJO',
//       'SAN LORENZO RUIZ (SUNGAN)',
//       'SAN VICENTE',
//       'SEÑOR',
//       'SINONOC',
//       'TIPAN',
//     ],
//   },
//   'TANGUB': {
//     province: 'MISAMIS OCCIDENTAL',
//     municipality: 'TANGUB',
//     region: 'REGION X',
//     barangay_list: [
//       'AQUINO (MARCOS)',
//       'BALATACAN',
//       'BALUK',
//       'BANGLAY',
//       'BARANGAY I - CITY HALL (POB.)',
//       'BARANGAY II - MARILOU ANNEX (POB.)',
//       'BARANGAY III- MARKET KALUBIAN (POB.)',
//       'BARANGAY IV - ST. MICHAEL (POB.)',
//       'BARANGAY V - MALUBOG (POB.)',
//       'BARANGAY VI - LOWER POLAO (POB.)',
//       'BARANGAY VII - UPPER POLAO (POB.)',
//       'BINTANA',
//       'BOCATOR',
//       'BONGABONG',
//       'CANIANGAN',
//       'CAPALARAN',
//       'CATAGAN',
//       'GARANG',
//       'GUINABOT',
//       'GUINALABAN',
//       'HUYOHOY',
//       'ISIDRO D. TAN (DIMALOC-OC)',
//       'KAUSWAGAN',
//       'KIMAT',
//       'LABUYO',
//       'LORENZO TAN',
//       'LUMBAN',
//       'MALORO',
//       'MANGA',
//       'MANTIC',
//       'MAQUILAO',
//       'MATUGNAW',
//       'MIGCANAWAY',
//       'MINSUBONG',
//       'OWAYAN',
//       'PAITON',
//       'PANALSALAN',
//       'PANGABUAN',
//       'PRENZA',
//       'SALIMPUNO',
//       'SAN ANTONIO',
//       'SAN APOLINARIO',
//       'SAN VICENTE',
//       'SANTA CRUZ',
//       'SANTA MARIA (BAGA)',
//       'SANTO NIÑO',
//       'SICOT',
//       'SILANGA',
//       'SILANGIT',
//       'SIMASAY',
//       'SUMIRAP',
//       'TAGUITE',
//       'TITURON',
//       'TUGAS',
//       'VILLABA',
//     ],
//   },
//   TUDELA: {
//     province: 'CEBU',
//     municipality: 'TUDELA',
//     region: 'REGION VII',
//     barangay_list: [
//       'BUENAVISTA',
//       'CALMANTE',
//       'DAAN SECANTE',
//       'GENERAL',
//       'MCARTHUR',
//       'NORTHERN POBLACION',
//       'PUERTOBELLO',
//       'SANTANDER',
//       'SECANTE BAG-O',
//       'SOUTHERN POBLACION',
//       'VILLAHERMOSA',
//     ],
//   },
//   ALUBIJID: {
//     province: 'MISAMIS ORIENTAL',
//     municipality: 'ALUBIJID',
//     region: 'REGION X',
//     barangay_list: [
//       'BAYBAY',
//       'BENIGWAYAN',
//       'CALATCAT',
//       'LAGTANG',
//       'LANAO',
//       'LOGUILO',
//       'LOURDES',
//       'LUMBO',
//       'MOLOCBOLOC',
//       'POBLACION',
//       'SAMPATULOG',
//       'SUNGAY',
//       'TALABA',
//       'TAPARAK',
//       'TUGASNON',
//       'TULA',
//     ],
//   },
//   BALINGASAG: {
//     province: 'MISAMIS ORIENTAL',
//     municipality: 'BALINGASAG',
//     region: 'REGION X',
//     barangay_list: [
//       'BALAGNAN',
//       'BALIWAGAN',
//       'BARANGAY 1 (POB.)',
//       'BARANGAY 2 (POB.)',
//       'BARANGAY 3 (POB.)',
//       'BARANGAY 4 (POB.)',
//       'BARANGAY 5 (POB.)',
//       'BARANGAY 6 (POB.)',
//       'BINITINAN',
//       'BLANCO',
//       'CALAWAG',
//       'CAMUAYAN',
//       'COGON',
//       'DANSULI',
//       'DUMARAIT',
//       'HERMANO',
//       'KIBANBAN',
//       'LINABU',
//       'LINGGANGAO',
//       'MAMBAYAAN',
//       'MANDANGOA',
//       'NAPALIRAN',
//       'QUEZON',
//       'ROSARIO',
//       'SAMAY',
//       'SAN FRANCISCO',
//       'SAN ISIDRO',
//       'SAN JUAN',
//       'TALUSAN',
//       'WATERFALL',
//     ],
//   },
//   BALINGOAN: {
//     province: 'MISAMIS ORIENTAL',
//     municipality: 'BALINGOAN',
//     region: 'REGION X',
//     barangay_list: [
//       'BAUKBAUK POB. (G.E. ANTONINO)',
//       'DAHILIG',
//       'KABANGASAN',
//       'KABULAKAN',
//       'KAUSWAGAN',
//       'LAPINIG (POB.)',
//       'MANTANGALE',
//       'MAPUA',
//       'SAN ALONZO',
//     ],
//   },
//   BINUANGAN: {
//     province: 'MISAMIS ORIENTAL',
//     municipality: 'BINUANGAN',
//     region: 'REGION X',
//     barangay_list: [
//       'DAMPIAS',
//       'KITAMBAN',
//       'KITAMBIS',
//       'MABINI',
//       'MOSANGOT',
//       'NABATAAN',
//       'POBLACION',
//       'VALDECONCHA',
//     ],
//   },
//   'CAGAYAN DE ORO': {
//     province: 'MISAMIS ORIENTAL',
//     municipality: 'CAGAYAN DE ORO',
//     region: 'REGION X',
//     barangay_list: [
//       'AGUSAN',
//       'BAIKINGON',
//       'BALUBAL',
//       'BALULANG',
//       'BARANGAY 1 (POB.)',
//       'BARANGAY 2 (POB.)',
//       'BARANGAY 3 (POB.)',
//       'BARANGAY 4 (POB.)',
//       'BARANGAY 5 (POB.)',
//       'BARANGAY 6 (POB.)',
//       'BARANGAY 7 (POB.)',
//       'BARANGAY 8 (POB.)',
//       'BARANGAY 9 (POB.)',
//       'BARANGAY 10 (POB.)',
//       'BARANGAY 11 (POB.)',
//       'BARANGAY 12 (POB.)',
//       'BARANGAY 13 (POB.)',
//       'BARANGAY 14 (POB.)',
//       'BARANGAY 15 (POB.)',
//       'BARANGAY 16 (POB.)',
//       'BARANGAY 17 (POB.)',
//       'BARANGAY 18 (POB.)',
//       'BARANGAY 19 (POB.)',
//       'BARANGAY 20 (POB.)',
//       'BARANGAY 21 (POB.)',
//       'BARANGAY 22 (POB.)',
//       'BARANGAY 23 (POB.)',
//       'BARANGAY 24 (POB.)',
//       'BARANGAY 25 (POB.)',
//       'BARANGAY 26 (POB.)',
//       'BARANGAY 27 (POB.)',
//       'BARANGAY 28 (POB.)',
//       'BARANGAY 29 (POB.)',
//       'BARANGAY 30 (POB.)',
//       'BARANGAY 31 (POB.)',
//       'BARANGAY 32 (POB.)',
//       'BARANGAY 33 (POB.)',
//       'BARANGAY 34 (POB.)',
//       'BARANGAY 35 (POB.)',
//       'BARANGAY 36 (POB.)',
//       'BARANGAY 37 (POB.)',
//       'BARANGAY 38 (POB.)',
//       'BARANGAY 39 (POB.)',
//       'BARANGAY 40 (POB.)',
//       'BAYABAS',
//       'BAYANGA',
//       'BESIGAN',
//       'BONBON',
//       'BUGO',
//       'BULUA',
//       'CAMAMAN-AN',
//       'CANITO-AN',
//       'CARMEN',
//       'CONSOLACION',
//       'CUGMAN',
//       'DANSOLIHON',
//       'F. S. CATANICO',
//       'GUSA',
//       'INDAHAG',
//       'IPONAN',
//       'KAUSWAGAN',
//       'LAPASAN',
//       'LUMBIA',
//       'MACABALAN',
//       'MACASANDIG',
//       'MAMBUAYA',
//       'NAZARETH',
//       'PAGALUNGAN',
//       'PAGATPAT',
//       'PATAG',
//       'PIGSAG-AN',
//       'PUERTO',
//       'PUNTOD',
//       'SAN SIMON',
//       'TABLON',
//       'TAGLIMAO',
//       'TAGPANGI',
//       'TIGNAPOLOAN',
//       'TUBURAN',
//       'TUMPAGON',
//     ],
//   },
//   CLAVERIA: {
//     province: 'MASBATE',
//     municipality: 'CLAVERIA',
//     region: 'REGION V',
//     barangay_list: [
//       'ALBASAN',
//       'BOCA ENGAÑO',
//       'BUYO',
//       'CALPI',
//       'CANOMAY',
//       'CAWAYAN',
//       'IMELDA',
//       'MABABANG BAYBAY',
//       'MABITON',
//       'MANAPAO',
//       'NABASAGAN',
//       'NONOC',
//       'OSMEÑA',
//       'PASIG',
//       'PEÑAFRANCIA',
//       'POBLACION DISTRICT I (BGY. 1)',
//       'POBLACION DISTRICT II (BGY. 2)',
//       'QUEZON',
//       'SAN ISIDRO',
//       'SAN RAMON',
//       'SAN VICENTE',
//       'TAGUILID',
//     ],
//   },
//   'EL SALVADOR': {
//     province: 'MISAMIS ORIENTAL',
//     municipality: 'EL SALVADOR',
//     region: 'REGION X',
//     barangay_list: [
//       'AMOROS',
//       'BOLISONG',
//       'BOLOBOLO',
//       'CALONGONAN',
//       'COGON',
//       'HIMAYA',
//       'HINIGDAAN',
//       'KALABAYLABAY',
//       'KIBONBON',
//       'MOLUGAN',
//       'POBLACION',
//       'SAMBULAWAN',
//       'SINALOC',
//       'TAYTAY',
//       'ULALIMAN',
//     ],
//   },
//   'GINGOOG': {
//     province: 'MISAMIS ORIENTAL',
//     municipality: 'GINGOOG',
//     region: 'REGION X',
//     barangay_list: [
//       'AGAY-AYAN',
//       'ALAGATAN',
//       'ANAKAN',
//       'BAGUBAD',
//       'BAKIDBAKID',
//       'BAL-ASON',
//       'BANTAAWAN',
//       'BARANGAY 1 (POB.)',
//       'BARANGAY 2 (POB.)',
//       'BARANGAY 3 (POB.)',
//       'BARANGAY 4 (POB.)',
//       'BARANGAY 5 (POB.)',
//       'BARANGAY 6 (POB.)',
//       'BARANGAY 7 (POB.)',
//       'BARANGAY 8 (POB.)',
//       'BARANGAY 9 (POB.)',
//       'BARANGAY 10 (POB.)',
//       'BARANGAY 11 (POB.)',
//       'BARANGAY 12 (POB.)',
//       'BARANGAY 13 (POB.)',
//       'BARANGAY 14 (POB.)',
//       'BARANGAY 15 (POB.)',
//       'BARANGAY 16 (POB.)',
//       'BARANGAY 17 (POB.)',
//       'BARANGAY 18 (POB.)',
//       'BARANGAY 18-A (POB.)',
//       'BARANGAY 19 (POB.)',
//       'BARANGAY 20 (POB.)',
//       'BARANGAY 21 (POB.)',
//       'BARANGAY 22 (POB.)',
//       'BARANGAY 22-A (POB.)',
//       'BARANGAY 23 (POB.)',
//       'BARANGAY 24 (POB.)',
//       'BARANGAY 24-A (POB.)',
//       'BARANGAY 25 (POB.)',
//       'BARANGAY 26 (POB.)',
//       'BINAKALAN',
//       'CAPITULANGAN',
//       'DAAN-LUNGSOD',
//       'DINAWEHAN',
//       'EUREKA',
//       'HINDANGON',
//       'KALAGONOY',
//       'KALIPAY',
//       'KAMANIKAN',
//       'KIANLAGAN',
//       'KIBUGING',
//       'KIPUNTOS',
//       'LAWAAN',
//       'LAWIT',
//       'LIBERTAD',
//       'LIBON',
//       'LUNAO',
//       'LUNOTAN',
//       'MALIBUD',
//       'MALINAO',
//       'MARIBUCAO',
//       'MIMBALAGON',
//       'MIMBUNGA',
//       'MIMBUNTONG',
//       'MINSAPINIT',
//       'MURALLON',
//       'ODIONGAN',
//       'PANGASIHAN',
//       'PIGSALUHAN',
//       'PUNONG',
//       'RICORO',
//       'SAMAY',
//       'SAN JOSE',
//       'SAN JUAN',
//       'SAN LUIS',
//       'SAN MIGUEL',
//       'SANGALAN',
//       'SANTIAGO',
//       'TAGPAKO',
//       'TALISAY',
//       'TALON',
//       'TINABALAN',
//       'TINULONGAN',
//     ],
//   },
//   GITAGUM: {
//     province: 'MISAMIS ORIENTAL',
//     municipality: 'GITAGUM',
//     region: 'REGION X',
//     barangay_list: [
//       'BURNAY',
//       'CARLOS P. GARCIA',
//       'COGON',
//       'GREGORIO PELAEZ (LAGUTAY)',
//       'KILANGIT',
//       'MATANGAD',
//       'PANGAYAWAN',
//       'POBLACION',
//       'QUEZON',
//       'TALA-O',
//       'ULAB',
//     ],
//   },
//   INITAO: {
//     province: 'MISAMIS ORIENTAL',
//     municipality: 'INITAO',
//     region: 'REGION X',
//     barangay_list: [
//       'ALUNA',
//       'ANDALES',
//       'APAS',
//       'CALACAPAN',
//       'GIMANGPANG',
//       'JAMPASON',
//       'KAMELON',
//       'KANITOAN',
//       'OGUIS',
//       'PAGAHAN',
//       'POBLACION',
//       'PONTACON',
//       'SAN PEDRO',
//       'SINALAC',
//       'TAWANTAWAN',
//       'TUBIGAN',
//     ],
//   },
//   JASAAN: {
//     province: 'MISAMIS ORIENTAL',
//     municipality: 'JASAAN',
//     region: 'REGION X',
//     barangay_list: [
//       'APLAYA',
//       'BOBONTUGAN',
//       'CORRALES',
//       'DANAO',
//       'I. S. CRUZ',
//       'JAMPASON',
//       'KIMAYA',
//       'LOWER JASAAN (POB.)',
//       'LUZ BANZON',
//       'NATUBO',
//       'SAN ANTONIO',
//       'SAN ISIDRO',
//       'SAN NICOLAS',
//       'SOLANA',
//       'UPPER JASAAN (POB.)',
//     ],
//   },
//   KINOGUITAN: {
//     province: 'MISAMIS ORIENTAL',
//     municipality: 'KINOGUITAN',
//     region: 'REGION X',
//     barangay_list: [
//       'BERAY',
//       'BOLISONG',
//       'BUKO',
//       'CALUBO',
//       'CAMPO',
//       'ESPERANZA',
//       'KAGUMAHAN',
//       'KALITIAN',
//       'KITOTOK',
//       'PANABOL',
//       'POBLACION',
//       'SALICAPAWAN',
//       'SALUBSOB',
//       'SUAREZ',
//       'SUMALAG',
//     ],
//   },
//   LAGONGLONG: {
//     province: 'MISAMIS ORIENTAL',
//     municipality: 'LAGONGLONG',
//     region: 'REGION X',
//     barangay_list: [
//       'BANGLAY',
//       'DAMPIL',
//       'GASTON',
//       'KABULAWAN',
//       'KAUSWAGAN',
//       'LUMBO',
//       'MANAOL',
//       'POBLACION',
//       'TABOK',
//       'UMAGOS',
//     ],
//   },
//   LAGUINDINGAN: {
//     province: 'MISAMIS ORIENTAL',
//     municipality: 'LAGUINDINGAN',
//     region: 'REGION X',
//     barangay_list: [
//       'AROMAHON',
//       'GASI',
//       'KIBAGHOT',
//       'LAPAD',
//       'LIBERTY',
//       'MAUSWAGON',
//       'MOOG',
//       'POBLACION',
//       'SAMBULAWAN',
//       'SINAI',
//       'TUBAJON',
//     ],
//   },
//   LIBERTAD: {
//     province: 'ANTIQUE',
//     municipality: 'LIBERTAD',
//     region: 'REGION VI',
//     barangay_list: [
//       'BARUSBUS',
//       'BULANAO',
//       'CENTRO ESTE (POB.)',
//       'CENTRO WESTE (POB.)',
//       'CODIONG',
//       'CUBAY',
//       'IGCAGAY',
//       'INYAWAN',
//       'LINDERO',
//       'MARAMIG',
//       'PAJO',
//       'PANANGKILON',
//       'PAZ',
//       'PUCIO',
//       'SAN ROQUE',
//       'TABOC',
//       'TINIGBAS',
//       'TININDUGAN',
//       'UNION',
//     ],
//   },
//   LUGAIT: {
//     province: 'MISAMIS ORIENTAL',
//     municipality: 'LUGAIT',
//     region: 'REGION X',
//     barangay_list: [
//       'AYA-AYA',
//       'BETAHON',
//       'BIGA',
//       'CALANGAHAN',
//       'KALUKNAYAN',
//       'LOWER TALACOGON',
//       'POBLACION',
//       'UPPER TALACOGON',
//     ],
//   },
//   'MAGSAYSAY (LINUGOS)': {
//     province: 'MISAMIS ORIENTAL',
//     municipality: 'MAGSAYSAY (LINUGOS)',
//     region: 'REGION X',
//     barangay_list: [
//       'ABUNDA',
//       'ARTADI',
//       'BONIFACIO AQUINO',
//       'CABALAWAN',
//       'CABANTIAN',
//       'CABUBUHAN',
//       'CANDIIS',
//       'CONSUELO',
//       'DAMAYUHAN',
//       'GUMABON',
//       'KATIPUNAN',
//       'KAUSWAGAN',
//       'KIBUNGSOD',
//       'MAHAYAHAY',
//       'MINDULAO',
//       'PAG-ASA',
//       'POBLACION',
//       'SAN ISIDRO',
//       'SAN VICENTE',
//       'SANTA CRUZ',
//       'TAMA',
//       'TIBON-TIBON',
//       'TINAAN',
//       'TULANG (CADENA DE AMOR)',
//       'VILLA FELIPA',
//     ],
//   },
//   MANTICAO: {
//     province: 'MISAMIS ORIENTAL',
//     municipality: 'MANTICAO',
//     region: 'REGION X',
//     barangay_list: [
//       'ARGAYOSO',
//       'BALINTAD',
//       'CABALANTIAN',
//       'CAMANGA',
//       'DIGKILAAN',
//       'MAHAYAHAY',
//       'PAGAWAN',
//       'PANIANGAN',
//       'PATAG',
//       'POBLACION',
//       'PUNTA SILUM',
//       'TUOD',
//       'UPPER MALUBOG',
//     ],
//   },
//   MEDINA: {
//     province: 'MISAMIS ORIENTAL',
//     municipality: 'MEDINA',
//     region: 'REGION X',
//     barangay_list: [
//       'BANGBANG',
//       'BULWA',
//       'CABUG',
//       'DIG-AGUYAN',
//       'DUKA',
//       'GASA',
//       'MAANAS',
//       'MANANUM BAG-O',
//       'MANANUM DAAN',
//       'NORTH POBLACION',
//       'PAHINDONG',
//       'PORTULIN',
//       'SAN ISIDRO',
//       'SAN JOSE',
//       'SAN ROQUE',
//       'SAN VICENTE',
//       'SOUTH POBLACION',
//       'TAMBAGAN',
//       'TUP-ON',
//     ],
//   },
//   NAAWAN: {
//     province: 'MISAMIS ORIENTAL',
//     municipality: 'NAAWAN',
//     region: 'REGION X',
//     barangay_list: [
//       'DON PEDRO',
//       'LINANGKAYAN',
//       'LUBILAN',
//       'MAPULOG',
//       'MAPUTI',
//       'MAT-I',
//       'PATAG',
//       'POBLACION',
//       'TAGBALOGO',
//       'TUBORAN',
//     ],
//   },
//   OPOL: {
//     province: 'MISAMIS ORIENTAL',
//     municipality: 'OPOL',
//     region: 'REGION X',
//     barangay_list: [
//       'AWANG',
//       'BAGOCBOC',
//       'BARRA',
//       'BONBON',
//       'CAUYONAN',
//       'IGPIT',
//       'LIMONDA',
//       'LUYONGBONBON',
//       'MALANANG',
//       'NANGCAON',
//       'PATAG',
//       'POBLACION',
//       'TABOC',
//       'TINGALAN',
//     ],
//   },
//   SALAY: {
//     province: 'MISAMIS ORIENTAL',
//     municipality: 'SALAY',
//     region: 'REGION X',
//     barangay_list: [
//       'ALIPUATON',
//       'AMPENICAN',
//       'BUNAL',
//       'CASULOG',
//       'DINAGSAAN',
//       'GUINALABAN',
//       'ILI-ILIHON',
//       'INOBULAN',
//       'LOOC',
//       'MATAMPA',
//       'MEMBULI',
//       'POBLACION',
//       'SALAGSAG',
//       'SALAY RIVER I',
//       'SALAY RIVER II',
//       'SARAY',
//       'TINAGAAN',
//       'YUNGOD',
//     ],
//   },
//   SUGBONGCOGON: {
//     province: 'MISAMIS ORIENTAL',
//     municipality: 'SUGBONGCOGON',
//     region: 'REGION X',
//     barangay_list: [
//       'ALICOMOHAN',
//       'AMPIANGA',
//       'KAULAYANAN',
//       'KIDAMPAS',
//       'KIRAGING',
//       'MANGGA',
//       'MIMBUAHAN',
//       'POBLACION',
//       'SANTA CRUZ (MABINI)',
//       'SILAD',
//     ],
//   },
//   TALISAYAN: {
//     province: 'MISAMIS ORIENTAL',
//     municipality: 'TALISAYAN',
//     region: 'REGION X',
//     barangay_list: [
//       'BUGDANG',
//       'CALAMCAM',
//       'CASIBOLE',
//       'MACOPA',
//       'MAGKARILA',
//       'MAHAYAG',
//       'MANDAHILAG',
//       'MINTABON',
//       'PANGPANGON',
//       'POBLACION',
//       'POOK',
//       'PUNTA SANTIAGO',
//       'PUTING BALAS',
//       'SAN JOSE',
//       'SANTA INES',
//       'SIBANTANG',
//       'SINDANGAN',
//       'TAGBOCBOC',
//     ],
//   },
//   VILLANUEVA: {
//     province: 'MISAMIS ORIENTAL',
//     municipality: 'VILLANUEVA',
//     region: 'REGION X',
//     barangay_list: [
//       'BALACANAS',
//       'DAYAWAN',
//       'IMELDA',
//       'KATIPUNAN',
//       'KIMAYA',
//       'LOOC',
//       'POBLACION 1',
//       'POBLACION 2',
//       'POBLACION 3',
//       'SAN MARTIN',
//       'TAMBOBONG',
//     ],
//   },
//   COMPOSTELA: {
//     province: 'CEBU',
//     municipality: 'COMPOSTELA',
//     region: 'REGION VII',
//     barangay_list: [
//       'BAGALNGA',
//       'BASAK',
//       'BULUANG',
//       'CABADIANGAN',
//       'CAMBAYOG',
//       'CANAMUCAN',
//       'COGON',
//       'DAPDAP',
//       'ESTACA',
//       'LUPA',
//       'MAGAY',
//       'MULAO',
//       'PANANGBAN',
//       'POBLACION',
//       'TAG-UBE',
//       'TAMIAO',
//       'TUBIGAN',
//     ],
//   },
//   'LAAK (SAN VICENTE)': {
//     province: 'COMPOSTELA VALLEY',
//     municipality: 'LAAK (SAN VICENTE)',
//     region: 'REGION XI',
//     barangay_list: [
//       'AGUINALDO',
//       'AMOR CRUZ',
//       'AMPAWID',
//       'ANDAP',
//       'ANITAP',
//       'BAGONG SILANG',
//       'BANBANON',
//       'BELMONTE',
//       'BINASBAS',
//       'BULLUCAN',
//       'CEBULIDA',
//       'CONCEPCION',
//       'DATU AMPUNAN',
//       'DATU DAVAO',
//       'DOÑA JOSEFA',
//       'EL KATIPUNAN',
//       'IL PAPA',
//       'IMELDA',
//       'INACAYAN',
//       'KALIGUTAN',
//       'KAPATAGAN',
//       'KIDAWA',
//       'KILAGDING',
//       'KIOKMAY',
//       'LAAC (POB.)',
//       'LANGTUD',
//       'LONGANAPAN',
//       'MABUHAY',
//       'MACOPA',
//       'MALINAO',
//       'MANGLOY',
//       'MELALE',
//       'NAGA',
//       'NEW BETHLEHEM',
//       'PANAMOREN',
//       'SABUD',
//       'SAN ANTONIO',
//       'SANTA EMILIA',
//       'SANTO NIÑO',
//       'SISIMON',
//     ],
//   },
//   'MABINI (DOÑA ALICIA)': {
//     province: 'COMPOSTELA VALLEY',
//     municipality: 'MABINI (DOÑA ALICIA)',
//     region: 'REGION XI',
//     barangay_list: [
//       'ANITAPAN',
//       'CABUYUAN',
//       'CADUNAN',
//       'CUAMBOG (POB.)',
//       'DEL PILAR',
//       'GOLDEN VALLEY (MARAUT)',
//       'LIBODON',
//       'PANGIBIRAN',
//       'PINDASAN',
//       'SAN ANTONIO',
//       'TAGNANAN (MAMPISING)',
//     ],
//   },
//   MACO: {
//     province: 'COMPOSTELA VALLEY',
//     municipality: 'MACO',
//     region: 'REGION XI',
//     barangay_list: [
//       'ANIBONGAN',
//       'ANISLAGAN',
//       'BINUANGAN',
//       'BUCANA',
//       'CALABCAB',
//       'CONCEPCION',
//       'DUMLAN',
//       'ELIZALDE (SOMIL)',
//       'GUBATAN',
//       'HIJO',
//       'KINUBAN',
//       'LANGGAM',
//       'LAPU-LAPU',
//       'LIBAY-LIBAY',
//       'LIMBO',
//       'LUMATAB',
//       'MAGANGIT',
//       'MAINIT',
//       'MALAMODAO',
//       'MANIPONGOL',
//       'MAPAANG',
//       'MASARA',
//       'NEW ASTURIAS',
//       'NEW BARILI',
//       'NEW LEYTE',
//       'NEW VISAYAS',
//       'PANANGAN',
//       'PANGI (GAUDENCIO ANTONIO)',
//       'PANIBASAN',
//       'PANORAON',
//       'POBLACION',
//       'SAN JUAN',
//       'SAN ROQUE',
//       'SANGAB',
//       'TAGBAROS',
//       'TAGLAWIG',
//       'TERESA',
//     ],
//   },
//   'MARAGUSAN (SAN MARIANO)': {
//     province: 'COMPOSTELA VALLEY',
//     municipality: 'MARAGUSAN (SAN MARIANO)',
//     region: 'REGION XI',
//     barangay_list: [
//       'BAGONG SILANG',
//       'BAHI',
//       'CAMBAGANG',
//       'CORONOBE',
//       'KATIPUNAN',
//       'LAHI',
//       'LANGGAWISAN',
//       'MABUGNAO',
//       'MAGCAGONG',
//       'MAHAYAHAY',
//       'MAPAWA',
//       'MARAGUSAN (POB.)',
//       'MAUSWAGON',
//       'NEW ALBAY',
//       'NEW KATIPUNAN',
//       'NEW MAN-AY',
//       'NEW PANAY',
//       'PALOC',
//       'PAMINTARAN',
//       'PARASANON',
//       'TALIAN',
//       'TANDIK',
//       'TIGBAO',
//       'TUPAZ',
//     ],
//   },
//   MAWAB: {
//     province: 'COMPOSTELA VALLEY',
//     municipality: 'MAWAB',
//     region: 'REGION XI',
//     barangay_list: [
//       'ANDILI',
//       'BAWANI',
//       'CONCEPCION',
//       'MALINAWON',
//       'NUEVA VISAYAS',
//       'NUEVO ILOCO',
//       'POBLACION',
//       'SALVACION',
//       'SAOSAO',
//       'SAWANGAN',
//       'TUBORAN',
//     ],
//   },
//   MONKAYO: {
//     province: 'COMPOSTELA VALLEY',
//     municipality: 'MONKAYO',
//     region: 'REGION XI',
//     barangay_list: [
//       'AWAO',
//       'BABAG',
//       'BANLAG',
//       'BAYLO',
//       'CASOON',
//       'HAGUIMITAN',
//       'INAMBATAN',
//       'MACOPA',
//       'MAMUNGA',
//       'MOUNT DIWATA',
//       'NABOC',
//       'OLAYCON',
//       'PASIAN (SANTA FILOMENA)',
//       'POBLACION',
//       'RIZAL',
//       'SALVACION',
//       'SAN ISIDRO',
//       'SAN JOSE',
//       'TUBO-TUBO (NEW DEL MONTE)',
//       'UNION',
//       'UPPER ULIP',
//     ],
//   },
//   MONTEVISTA: {
//     province: 'COMPOSTELA VALLEY',
//     municipality: 'MONTEVISTA',
//     region: 'REGION XI',
//     barangay_list: [
//       'BANAGBANAG',
//       'BANGLASAN',
//       'BANKEROHAN NORTE',
//       'BANKEROHAN SUR',
//       'CAMANSI',
//       'CAMANTANGAN',
//       'CANIDKID',
//       'CONCEPCION',
//       'DAUMAN',
//       'LEBANON',
//       'LINOAN',
//       'MAYAON',
//       'NEW CALAPE',
//       'NEW CEBULAN (SAMBAYON)',
//       'NEW DALAGUETE',
//       'NEW VISAYAS',
//       'PROSPERIDAD',
//       'SAN JOSE (POB.)',
//       'SAN VICENTE',
//       'TAPIA',
//     ],
//   },
//   NABUNTURAN: {
//     province: 'COMPOSTELA VALLEY',
//     municipality: 'NABUNTURAN',
//     region: 'REGION XI',
//     barangay_list: [
//       'ANISLAGAN',
//       'ANTIQUERA',
//       'BASAK',
//       'BAYABAS',
//       'BUKAL',
//       'CABACUNGAN',
//       'CABIDIANAN',
//       'KATIPUNAN',
//       'LIBASAN',
//       'LINDA',
//       'MAGADING',
//       'MAGSAYSAY',
//       'MAINIT',
//       'MANAT',
//       'MATILO',
//       'MIPANGI',
//       'NEW DAUIS',
//       'NEW SIBONGA',
//       'OGAO',
//       'PANGUTOSAN',
//       'POBLACION',
//       'SAN ISIDRO',
//       'SAN ROQUE',
//       'SAN VICENTE',
//       'SANTA MARIA',
//       'SANTO NIÑO (KAO)',
//       'SASA',
//       'TAGNOCON',
//     ],
//   },
//   'NEW BATAAN': {
//     province: 'COMPOSTELA VALLEY',
//     municipality: 'NEW BATAAN',
//     region: 'REGION XI',
//     barangay_list: [
//       'ANDAP',
//       'BANTACAN',
//       'BATINAO',
//       'CABINUANGAN (POB.)',
//       'CAMANLANGAN',
//       'COGONON',
//       'FATIMA',
//       'KAHAYAG',
//       'KATIPUNAN',
//       'MAGANGIT',
//       'MAGSAYSAY',
//       'MANURIGAO',
//       'PAGSABANGAN',
//       'PANAG',
//       'SAN ROQUE',
//       'TANDAWAN',
//     ],
//   },
//   PANTUKAN: {
//     province: 'COMPOSTELA VALLEY',
//     municipality: 'PANTUKAN',
//     region: 'REGION XI',
//     barangay_list: [
//       'ARAIBO',
//       'BONGABONG',
//       'BONGBONG',
//       'KINGKING (POB.)',
//       'LAS ARENAS',
//       'MAGNAGA',
//       'MATIAO',
//       'NAPNAPAN',
//       'P. FUENTES',
//       'TAG-UGPO',
//       'TAGDANGUA',
//       'TAMBONGON',
//       'TIBAGON',
//     ],
//   },
//   'ASUNCION (SAUG)': {
//     province: 'DAVAO (DAVAO DEL NORTE)',
//     municipality: 'ASUNCION (SAUG)',
//     region: 'REGION XI',
//     barangay_list: [
//       'BINANCIAN',
//       'BUAN',
//       'BUCLAD',
//       'CABAYWA',
//       'CAMANSA',
//       'CAMBANOGOY (POB.)',
//       'CAMONING',
//       'CANATAN',
//       'CONCEPCION',
//       'DOÑA ANDREA',
//       'MAGATOS',
//       'NAPUNGAS',
//       'NEW BANTAYAN',
//       'NEW LOON',
//       'NEW SANTIAGO',
//       'PAMACAUN',
//       'SAGAYEN',
//       'SAN VICENTE',
//       'SANTA FILOMENA',
//       'SONLON',
//     ],
//   },
//   'BRAULIO E. DUJALI': {
//     province: 'DAVAO (DAVAO DEL NORTE)',
//     municipality: 'BRAULIO E. DUJALI',
//     region: 'REGION XI',
//     barangay_list: [
//       'CABAY-ANGAN',
//       'DUJALI',
//       'MAGUPISING',
//       'NEW CASAY',
//       'TANGLAW',
//     ],
//   },
//   CARMEN: {
//     province: 'CEBU',
//     municipality: 'CARMEN',
//     region: 'REGION VII',
//     barangay_list: [
//       'BARING',
//       'CANTIPAY',
//       'CANTUKONG',
//       'CANTUMOG',
//       'CAURASAN',
//       'COGON EAST',
//       'COGON WEST',
//       'CORTE',
//       'DAWIS NORTE',
//       'DAWIS SUR',
//       'HAGNAYA',
//       'IPIL',
//       'LANIPGA',
//       'LIBORON',
//       'LOWER NATIMAO-AN',
//       'LUYANG',
//       'POBLACION',
//       'PUENTE',
//       'SAC-ON',
//       'TRIUMFO',
//       'UPPER NATIMAO-AN',
//     ],
//   },
//   'TAGUM': {
//     province: 'DAVAO (DAVAO DEL NORTE)',
//     municipality: 'TAGUM',
//     region: 'REGION XI',
//     barangay_list: [
//       'APOKON',
//       'BINCUNGAN',
//       'BUSAON',
//       'CANOCOTAN',
//       'CUAMBOGAN',
//       'LA FILIPINA',
//       'LIBOGANON',
//       'MADAUM',
//       'MAGDUM',
//       'MAGUGPO EAST',
//       'MAGUGPO NORTH',
//       'MAGUGPO POBLACION',
//       'MAGUGPO SOUTH',
//       'MAGUGPO WEST',
//       'MANKILAM',
//       'NEW BALAMBAN',
//       'NUEVA FUERZA',
//       'PAGSABANGAN',
//       'PANDAPAN',
//       'SAN AGUSTIN',
//       'SAN ISIDRO',
//       'SAN MIGUEL (CAMP 4)',
//       'VISAYAN VILLAGE',
//     ],
//   },
//   'ISLAND GARDEN SAMAL': {
//     province: 'DAVAO (DAVAO DEL NORTE)',
//     municipality: 'ISLAND GARDEN SAMAL',
//     region: 'REGION XI',
//     barangay_list: [
//       'ADECOR',
//       'ANONANG',
//       'AUMBAY',
//       'AUNDANAO',
//       'BALET',
//       'BANDERA',
//       'CALICLIC (DANGCA-AN)',
//       'CAMUDMUD',
//       'CATAGMAN',
//       'CAWAG',
//       'COGON',
//       'COGON (TALICOD)',
//       'DADATAN',
//       'DEL MONTE',
//       'GUILON',
//       'KANAAN',
//       'KINAWITNON',
//       'LIBERTAD',
//       'LIBUAK',
//       'LICUP',
//       'LIMAO',
//       'LINOSUTAN',
//       'MAMBAGO-A',
//       'MAMBAGO-B',
//       'MIRANDA (POB.)',
//       'MONCADO (POB.)',
//       'PANGUBATAN (TALICOD I)',
//       'PEÑAPLATA (POB.)',
//       'POBLACION (KAPUTIAN)',
//       'SAN AGUSTIN',
//       'SAN ANTONIO',
//       'SAN ISIDRO (BABAK)',
//       'SAN ISIDRO (KAPUTIAN)',
//       'SAN JOSE (SAN LAPUZ)',
//       'SAN MIGUEL (MAGAMONO)',
//       'SAN REMIGIO',
//       'SANTA CRUZ (TALICOD II)',
//       'SANTO NIÑO',
//       'SION (ZION)',
//       'TAGBAOBO',
//       'TAGBAY',
//       'TAGBITAN-AG',
//       'TAGDALIAO',
//       'TAGPOPONGAN',
//       'TAMBO',
//       'TORIL',
//     ],
//   },
//   KAPALONG: {
//     province: 'DAVAO (DAVAO DEL NORTE)',
//     municipality: 'KAPALONG',
//     region: 'REGION XI',
//     barangay_list: [
//       'CAPUNGAGAN',
//       'FLORIDA',
//       'GABUYAN',
//       'GUPITAN',
//       'KATIPUNAN',
//       'LUNA',
//       'MABANTAO',
//       'MAMACAO',
//       'MANIKI (POBLACION)',
//       'PAG-ASA',
//       'SAMPAO',
//       'SEMONG',
//       'SUA-ON',
//       'TIBURCIA',
//     ],
//   },
//   'NEW CORELLA': {
//     province: 'DAVAO (DAVAO DEL NORTE)',
//     municipality: 'NEW CORELLA',
//     region: 'REGION XI',
//     barangay_list: [
//       'CABIDIANAN',
//       'CARCOR',
//       'DEL MONTE',
//       'DEL PILAR',
//       'EL SALVADOR',
//       'LIMBA-AN',
//       'MACGUM',
//       'MAMBING',
//       'MESAOY',
//       'NEW BOHOL',
//       'NEW CORTEZ',
//       'NEW SAMBOG',
//       'PATROCENIO',
//       'POBLACION',
//       'SAN JOSE',
//       'SAN ROQUE',
//       'SANTA CRUZ',
//       'SANTA FE',
//       'SANTO NIÑO',
//       'SUAWON',
//     ],
//   },
//   'PANABO': {
//     province: 'DAVAO (DAVAO DEL NORTE)',
//     municipality: 'PANABO',
//     region: 'REGION XI',
//     barangay_list: [
//       'A. O. FLOIRENDO',
//       'BUENAVISTA',
//       'CACAO',
//       'CAGANGOHAN',
//       'CONSOLACION',
//       'DAPCO',
//       'DATU ABDUL DADIA',
//       'GREDU (POB.)',
//       'J.P. LAUREL',
//       'KASILAK',
//       'KATIPUNAN',
//       'KATUALAN',
//       'KAUSWAGAN',
//       'KIOTOY',
//       'LITTLE PANAY',
//       'LOWER PANAGA (ROXAS)',
//       'MABUNAO',
//       'MADUAO',
//       'MALATIVAS',
//       'MANAY',
//       'NANYO',
//       'NEW MALAGA (DALISAY)',
//       'NEW MALITBOG',
//       'NEW PANDAN (POB.)',
//       'NEW VISAYAS',
//       'QUEZON',
//       'SALVACION',
//       'SAN FRANCISCO (POB.)',
//       'SAN NICOLAS',
//       'SAN PEDRO',
//       'SAN ROQUE',
//       'SAN VICENTE',
//       'SANTA CRUZ',
//       'SANTO NIÑO (POB.)',
//       'SINDATON',
//       'SOUTHERN DAVAO',
//       'TAGPORE',
//       'TIBUNGOL',
//       'UPPER LICANAN',
//       'WATERFALL',
//     ],
//   },
//   'SAN ISIDRO': {
//     province: 'ABRA',
//     municipality: 'SAN ISIDRO',
//     region: 'CAR',
//     barangay_list: [
//       'CABAYOGAN',
//       'DALIMAG',
//       'LANGBABAN',
//       'MANAYDAY',
//       'PANTOC',
//       'POBLACION',
//       'SABTAN-OLO',
//       'SAN MARCIAL',
//       'TANGBAO',
//     ],
//   },
//   'SANTO TOMAS': {
//     province: 'BATANGAS',
//     municipality: 'SANTO TOMAS',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'BARANGAY I (POB.)',
//       'BARANGAY II (POB.)',
//       'BARANGAY III (POB.)',
//       'BARANGAY IV (POB.)',
//       'SAN AGUSTIN',
//       'SAN ANTONIO',
//       'SAN BARTOLOME',
//       'SAN FELIX',
//       'SAN FERNANDO',
//       'SAN FRANCISCO',
//       'SAN ISIDRO NORTE',
//       'SAN ISIDRO SUR',
//       'SAN JOAQUIN',
//       'SAN JOSE',
//       'SAN JUAN',
//       'SAN LUIS',
//       'SAN MIGUEL',
//       'SAN PABLO',
//       'SAN PEDRO',
//       'SAN RAFAEL',
//       'SAN ROQUE',
//       'SAN VICENTE',
//       'SANTA ANA',
//       'SANTA ANASTACIA',
//       'SANTA CLARA',
//       'SANTA CRUZ',
//       'SANTA ELENA',
//       'SANTA MARIA',
//       'SANTA TERESITA',
//       'SANTIAGO',
//     ],
//   },
//   TALAINGOD: {
//     province: 'DAVAO (DAVAO DEL NORTE)',
//     municipality: 'TALAINGOD',
//     region: 'REGION XI',
//     barangay_list: ['DAGOHOY', 'PALMA GIL', 'SANTO NIÑO'],
//   },
//   BANSALAN: {
//     province: 'DAVAO DEL SUR',
//     municipality: 'BANSALAN',
//     region: 'REGION XI',
//     barangay_list: [
//       'ALEGRE',
//       'ALTA VISTA',
//       'ANONANG',
//       'BITAUG',
//       'BONIFACIO',
//       'BUENAVISTA',
//       'DARAPUAY',
//       'DOLO',
//       'EMAN',
//       'KINUSKUSAN',
//       'LIBERTAD',
//       'LINAWAN',
//       'MABUHAY',
//       'MABUNGA',
//       'MANAGA',
//       'MARBER',
//       'NEW CLARIN (MIRAL)',
//       'POBLACION',
//       'POBLACION DOS',
//       'RIZAL',
//       'SANTO NIÑO',
//       'SIBAYAN',
//       'TINONGTONGAN',
//       'TUBOD',
//       'UNION',
//     ],
//   },
//   'DAVAO': {
//     province: 'DAVAO DEL SUR',
//     municipality: 'DAVAO',
//     region: 'REGION XI',
//     barangay_list: [
//       'ACACIA',
//       'AGDAO',
//       'ALAMBRE',
//       'ALEJANDRA NAVARRO (LASANG)',
//       'ALFONSO ANGLIONGTO SR.',
//       'ANGALAN',
//       'ATAN-AWE',
//       'BAGANIHAN',
//       'BAGO APLAYA',
//       'BAGO GALLERA',
//       'BAGO OSHIRO',
//       'BAGUIO (POB.)',
//       'BALENGAENG',
//       'BALIOK',
//       'BANGKAS HEIGHTS',
//       'BANTOL',
//       'BARACATAN',
//       'BARANGAY 1-A (POB.)',
//       'BARANGAY 2-A (POB.)',
//       'BARANGAY 3-A (POB.)',
//       'BARANGAY 4-A (POB.)',
//       'BARANGAY 5-A (POB.)',
//       'BARANGAY 6-A (POB.)',
//       'BARANGAY 7-A (POB.)',
//       'BARANGAY 8-A (POB.)',
//       'BARANGAY 9-A (POB.)',
//       'BARANGAY 10-A (POB.)',
//       'BARANGAY 11-B (POB.)',
//       'BARANGAY 12-B (POB.)',
//       'BARANGAY 13-B (POB.)',
//       'BARANGAY 14-B (POB.)',
//       'BARANGAY 15-B (POB.)',
//       'BARANGAY 16-B (POB.)',
//       'BARANGAY 17-B (POB.)',
//       'BARANGAY 18-B (POB.)',
//       'BARANGAY 19-B (POB.)',
//       'BARANGAY 20-B (POB.)',
//       'BARANGAY 21-C (POB.)',
//       'BARANGAY 22-C (POB.)',
//       'BARANGAY 23-C (POB.)',
//       'BARANGAY 24-C (POB.)',
//       'BARANGAY 25-C (POB.)',
//       'BARANGAY 26-C (POB.)',
//       'BARANGAY 27-C (POB.)',
//       'BARANGAY 28-C (POB.)',
//       'BARANGAY 29-C (POB.)',
//       'BARANGAY 30-C (POB.)',
//       'BARANGAY 31-D (POB.)',
//       'BARANGAY 32-D (POB.)',
//       'BARANGAY 33-D (POB.)',
//       'BARANGAY 34-D (POB.)',
//       'BARANGAY 35-D (POB.)',
//       'BARANGAY 36-D (POB.)',
//       'BARANGAY 37-D (POB.)',
//       'BARANGAY 38-D (POB.)',
//       'BARANGAY 39-D (POB.)',
//       'BARANGAY 40-D (POB.)',
//       'BATO',
//       'BAYABAS',
//       'BIAO ESCUELA',
//       'BIAO GUIANGA',
//       'BIAO JOAQUIN',
//       'BINUGAO',
//       'BUCANA',
//       'BUDA',
//       'BUHANGIN (POB.)',
//       'BUNAWAN (POB.)',
//       'CABANTIAN',
//       'CADALIAN',
//       'CALINAN (POB.)',
//       'CALLAWA',
//       'CAMANSI',
//       'CARMEN',
//       'CATALUNAN GRANDE',
//       'CATALUNAN PEQUEÑO',
//       'CATIGAN',
//       'CAWAYAN',
//       'CENTRO (SAN JUAN)',
//       'COLOSAS',
//       'COMMUNAL',
//       'CROSSING BAYABAS',
//       'DACUDAO',
//       'DALAG',
//       'DALAGDAG',
//       'DALIAO',
//       'DALIAON PLANTATION',
//       'DATU SALUMAY',
//       'DOMINGA',
//       'DUMOY',
//       'EDEN',
//       'FATIMA (BENOWANG)',
//       'GATUNGAN',
//       'GOV. PACIANO BANGOY',
//       'GOV. VICENTE DUTERTE',
//       'GUMALANG',
//       'GUMITAN',
//       'ILANG',
//       'INAYANGAN',
//       'INDANGAN',
//       'KAP. TOMAS MONTEVERDE, SR.',
//       'KILATE',
//       'LACSON',
//       'LAMANAN',
//       'LAMPIANAO',
//       'LANGUB',
//       'LAPU-LAPU',
//       'LEON GARCIA, SR.',
//       'LIZADA',
//       'LOS AMIGOS',
//       'LUBOGAN',
//       'LUMIAD',
//       'MA-A',
//       'MABUHAY',
//       'MAGSAYSAY',
//       'MAGTUOD',
//       'MAHAYAG',
//       'MALABOG',
//       'MALAGOS',
//       'MALAMBA',
//       'MANAMBULAN',
//       'MANDUG',
//       'MANUEL GUIANGA',
//       'MAPULA',
//       'MARAPANGI',
//       'MARILOG',
//       'MATINA APLAYA',
//       'MATINA BIAO',
//       'MATINA CROSSING',
//       'MATINA PANGI',
//       'MEGKAWAYAN',
//       'MINTAL',
//       'MUDIANG',
//       'MULIG',
//       'NEW CARMEN',
//       'NEW VALENCIA',
//       'PAMPANGA',
//       'PANACAN',
//       'PANALUM',
//       'PANDAITAN',
//       'PANGYAN',
//       'PAQUIBATO (POB.)',
//       'PARADISE EMBAK',
//       'RAFAEL CASTILLO',
//       'RIVERSIDE',
//       'SALAPAWAN',
//       'SALAYSAY',
//       'SALOY',
//       'SAN ANTONIO',
//       'SAN ISIDRO (LICANAN)',
//       'SANTO NIÑO',
//       'SASA',
//       'SIBULAN',
//       'SIRAWAN',
//       'SIRIB',
//       'SUAWAN (TULI)',
//       'SUBASTA',
//       'SUMIMAO',
//       'TACUNAN',
//       'TAGAKPAN',
//       'TAGLUNO',
//       'TAGURANO',
//       'TALANDANG',
//       'TALOMO (POB.)',
//       'TALOMO RIVER',
//       'TAMAYONG',
//       'TAMBOBONG',
//       'TAMUGAN',
//       'TAPAK',
//       'TAWAN-TAWAN',
//       'TIBULOY',
//       'TIBUNGCO',
//       'TIGATTO',
//       'TORIL (POB.)',
//       'TUGBOK (POB.)',
//       'TUNGAKALAN',
//       'UBALDE',
//       'ULA',
//       'VICENTE HIZON SR.',
//       'WAAN',
//       'WANGAN',
//       'WILFREDO AQUINO',
//       'WINES',
//     ],
//   },
//   'DIGOS': {
//     province: 'DAVAO DEL SUR',
//     municipality: 'DIGOS',
//     region: 'REGION XI',
//     barangay_list: [
//       'APLAYA',
//       'BALABAG',
//       'BINATON',
//       'COGON',
//       'COLORADO',
//       'DAWIS',
//       'DULANGAN',
//       'GOMA',
//       'IGPIT',
//       'KAPATAGAN (RIZAL)',
//       'KIAGOT',
//       'LUNGAG',
//       'MAHAYAHAY',
//       'MATTI',
//       'RUPARAN',
//       'SAN AGUSTIN',
//       'SAN JOSE (BALUTAKAY)',
//       'SAN MIGUEL (ODACA)',
//       'SAN ROQUE',
//       'SINAWILAN',
//       'SOONG',
//       'TIGUMAN',
//       'TRES DE MAYO',
//       'ZONE 1 (POB.)',
//       'ZONE 2 (POB.)',
//       'ZONE 3 (POB.)',
//     ],
//   },
//   HAGONOY: {
//     province: 'BULACAN',
//     municipality: 'HAGONOY',
//     region: 'REGION III',
//     barangay_list: [
//       'ABULALAS',
//       'CARILLO',
//       'IBA',
//       'IBA-IBAYO',
//       'MERCADO',
//       'PALAPAT',
//       'PUGAD',
//       'SAGRADA FAMILIA',
//       'SAN AGUSTIN',
//       'SAN ISIDRO',
//       'SAN JOSE',
//       'SAN JUAN',
//       'SAN MIGUEL',
//       'SAN NICOLAS',
//       'SAN PABLO',
//       'SAN PASCUAL',
//       'SAN PEDRO',
//       'SAN ROQUE',
//       'SAN SEBASTIAN',
//       'SANTA CRUZ',
//       'SANTA ELENA',
//       'SANTA MONICA',
//       'SANTO NIÑO (POB.)',
//       'SANTO ROSARIO',
//       'TAMPOK',
//       'TIBAGUIN',
//     ],
//   },
//   KIBLAWAN: {
//     province: 'DAVAO DEL SUR',
//     municipality: 'KIBLAWAN',
//     region: 'REGION XI',
//     barangay_list: [
//       'ABNATE',
//       'BAGONG NEGROS',
//       'BAGONG SILANG',
//       'BAGUMBAYAN',
//       'BALASIAO',
//       'BONIFACIO',
//       'BULOL-SALO',
//       'BUNOT',
//       'COGON-BACACA',
//       'DAPOK',
//       'IHAN',
//       'KIBONGBONG',
//       'KIMLAWIS',
//       'KISULAN',
//       'LATI-AN',
//       'MANUAL',
//       'MARAGA-A',
//       'MOLOPOLO',
//       'NEW SIBONGA',
//       'PANAGLIB',
//       'PASIG',
//       'POBLACION',
//       'POCALEEL',
//       'SAN ISIDRO',
//       'SAN JOSE',
//       'SAN PEDRO',
//       'SANTO NIÑO',
//       'TACUB',
//       'TACUL',
//       'WATERFALL',
//     ],
//   },
//   MALALAG: {
//     province: 'DAVAO DEL SUR',
//     municipality: 'MALALAG',
//     region: 'REGION XI',
//     barangay_list: [
//       'BAGUMBAYAN',
//       'BAYBAY',
//       'BOLTON',
//       'BULACAN',
//       'CAPUTIAN',
//       'IBO',
//       'KIBLAGON',
//       'LAPLA',
//       'MABINI',
//       'NEW BACLAYON',
//       'PITU',
//       'POBLACION',
//       'RIZAL (PARAME)',
//       'SAN ISIDRO',
//       'TAGANSULE',
//     ],
//   },
//   MATANAO: {
//     province: 'DAVAO DEL SUR',
//     municipality: 'MATANAO',
//     region: 'REGION XI',
//     barangay_list: [
//       'ASBANG',
//       'ASINAN',
//       'BAGUMBAYAN',
//       'BANGKAL',
//       'BUAS',
//       'BURI',
//       'CABLIGAN (MANAGA)',
//       'CAMANCHILES',
//       'CEBOZA',
//       'COLONSABAK',
//       'DONGAN-PEKONG',
//       'KABASAGAN',
//       'KAPOK',
//       'KAUSWAGAN',
//       'KIBAO',
//       'LA SUERTE',
//       'LANGA-AN',
//       'LOWER MARBER',
//       'MANGA',
//       'NEW KATIPUNAN',
//       'NEW MURCIA',
//       'NEW VISAYAS',
//       'POBLACION',
//       'SABOY',
//       'SAN JOSE',
//       'SAN MIGUEL',
//       'SAN VICENTE',
//       'SAUB',
//       'SINARAGAN',
//       'SINAWILAN',
//       'TAMLANGON',
//       'TIBONGBONG',
//       'TOWAK',
//     ],
//   },
//   PADADA: {
//     province: 'DAVAO DEL SUR',
//     municipality: 'PADADA',
//     region: 'REGION XI',
//     barangay_list: [
//       'ALMENDRAS (POB.)',
//       'DON SERGIO OSMEÑA, SR.',
//       'HARADA BUTAI',
//       'LOWER KATIPUNAN',
//       'LOWER LIMONZO',
//       'LOWER MALINAO',
//       'N C ORDANEZA DISTRICT (POB.)',
//       'NORTHERN PALIGUE',
//       'PALILI',
//       'PIAPE',
//       'PUNTA PIAPE',
//       'QUIRINO DISTRICT (POB.)',
//       'SAN ISIDRO',
//       'SOUTHERN PALIGUE',
//       'TULOGAN',
//       'UPPER LIMONZO',
//       'UPPER MALINAO',
//     ],
//   },
//   'SANTA CRUZ': {
//     province: 'METRO MANILA',
//     municipality: 'SANTA CRUZ',
//     region: 'NCR',
//     barangay_list: [
//       'BARANGAY 297',
//       'BARANGAY 298',
//       'BARANGAY 299',
//       'BARANGAY 300',
//       'BARANGAY 301',
//       'BARANGAY 302',
//       'BARANGAY 303',
//       'BARANGAY 304',
//       'BARANGAY 305',
//       'BARANGAY 310',
//       'BARANGAY 311',
//       'BARANGAY 312',
//       'BARANGAY 313',
//       'BARANGAY 314',
//       'BARANGAY 315',
//       'BARANGAY 316',
//       'BARANGAY 317',
//       'BARANGAY 318',
//       'BARANGAY 319',
//       'BARANGAY 320',
//       'BARANGAY 321',
//       'BARANGAY 322',
//       'BARANGAY 323',
//       'BARANGAY 324',
//       'BARANGAY 325',
//       'BARANGAY 326',
//       'BARANGAY 327',
//       'BARANGAY 328',
//       'BARANGAY 329',
//       'BARANGAY 330',
//       'BARANGAY 331',
//       'BARANGAY 332',
//       'BARANGAY 333',
//       'BARANGAY 334',
//       'BARANGAY 335',
//       'BARANGAY 336',
//       'BARANGAY 337',
//       'BARANGAY 338',
//       'BARANGAY 339',
//       'BARANGAY 340',
//       'BARANGAY 341',
//       'BARANGAY 342',
//       'BARANGAY 343',
//       'BARANGAY 344',
//       'BARANGAY 345',
//       'BARANGAY 346',
//       'BARANGAY 347',
//       'BARANGAY 348',
//       'BARANGAY 349',
//       'BARANGAY 350',
//       'BARANGAY 351',
//       'BARANGAY 352',
//       'BARANGAY 353',
//       'BARANGAY 354',
//       'BARANGAY 355',
//       'BARANGAY 356',
//       'BARANGAY 357',
//       'BARANGAY 358',
//       'BARANGAY 359',
//       'BARANGAY 360',
//       'BARANGAY 361',
//       'BARANGAY 362',
//       'BARANGAY 363',
//       'BARANGAY 364',
//       'BARANGAY 365',
//       'BARANGAY 366',
//       'BARANGAY 367',
//       'BARANGAY 368',
//       'BARANGAY 369',
//       'BARANGAY 370',
//       'BARANGAY 371',
//       'BARANGAY 372',
//       'BARANGAY 373',
//       'BARANGAY 374',
//       'BARANGAY 375',
//       'BARANGAY 376',
//       'BARANGAY 377',
//       'BARANGAY 378',
//       'BARANGAY 379',
//       'BARANGAY 380',
//       'BARANGAY 381',
//       'BARANGAY 382',
//     ],
//   },
//   SULOP: {
//     province: 'DAVAO DEL SUR',
//     municipality: 'SULOP',
//     region: 'REGION XI',
//     barangay_list: [
//       'BALASINON',
//       'BUGUIS',
//       'CARRE',
//       'CLIB',
//       'HARADA BUTAI',
//       'KATIPUNAN',
//       'KIBLAGON',
//       'LABON',
//       'LAPERAS',
//       'LAPLA',
//       'LITOS',
//       'LUPARAN',
//       'MCKINLEY',
//       'NEW CEBU',
//       'OSMEÑA',
//       'PALILI',
//       'PARAME',
//       'POBLACION',
//       'ROXAS',
//       'SOLONGVALE',
//       'TAGOLILONG',
//       'TALA-O',
//       'TALAS',
//       'TANWALANG',
//       'WATERFALL',
//     ],
//   },
//   'DON MARCELINO': {
//     province: 'DAVAO OCCIDENTAL',
//     municipality: 'DON MARCELINO',
//     region: 'REGION XI',
//     barangay_list: [
//       'BALUNTAYA',
//       'CALIAN',
//       'DALUPAN',
//       'KINANGA',
//       'KIOBOG',
//       'LANAO',
//       'LAPUAN',
//       'LAWA (POB.)',
//       'LINADASAN',
//       'MABUHAY',
//       'NORTH LAMIDAN',
//       'NUEVA VILLA',
//       'SOUTH LAMIDAN',
//       'TALAGUTONG (POB.)',
//       'WEST LAMIDAN',
//     ],
//   },
//   'JOSE ABAD SANTOS (TRINIDAD)': {
//     province: 'DAVAO OCCIDENTAL',
//     municipality: 'JOSE ABAD SANTOS (TRINIDAD)',
//     region: 'REGION XI',
//     barangay_list: [
//       'BALANGONAN',
//       'BUGUIS',
//       'BUKID',
//       'BUTUAN',
//       'BUTULAN',
//       'CABURAN BIG',
//       'CABURAN SMALL (POB.)',
//       'CAMALIAN',
//       'CARAHAYAN',
//       'CAYAPONGA',
//       'CULAMAN',
//       'KALBAY',
//       'KITAYO',
//       'MAGULIBAS',
//       'MALALAN',
//       'MANGILE',
//       'MARABUTUAN',
//       'MEYBIO',
//       'MOLMOL',
//       'NUING',
//       'PATULANG',
//       'QUIAPO',
//       'SAN ISIDRO',
//       'SUGAL',
//       'TABAYON',
//       'TANUMAN',
//     ],
//   },
//   MALITA: {
//     province: 'DAVAO OCCIDENTAL',
//     municipality: 'MALITA',
//     region: 'REGION XI',
//     barangay_list: [
//       'BITO',
//       'BOLILA',
//       'BUHANGIN',
//       'CULAMAN',
//       'DATU DANWATA',
//       'DEMOLOC',
//       'FELIS',
//       "FISHING VILLAGE (FISHERMAN'S VILLAGE)",
//       'KIBALATONG',
//       'KIDALAPONG',
//       'KILALAG',
//       'KINANGAN',
//       'LACARON',
//       'LAGUMIT',
//       'LAIS',
//       'LITTLE BAGUIO',
//       'MACOL',
//       'MANA',
//       'MANUEL PERALTA',
//       'NEW ARGAO',
//       'PANGALEON',
//       'PANGIAN',
//       'PINALPALAN',
//       'POBLACION',
//       'SANGAY',
//       'TALOGOY',
//       'TICAL',
//       'TICULON',
//       'TINGOLO',
//       'TUBALAN',
//     ],
//   },
//   'SANTA MARIA': {
//     province: 'LAGUNA',
//     municipality: 'SANTA MARIA',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'ADIA',
//       'BAGONG POOK',
//       'BAGUMBAYAN',
//       'BARANGAY I (POB.)',
//       'BARANGAY II (POB.)',
//       'BARANGAY III (POB.)',
//       'BARANGAY IV (POB.)',
//       'BUBUKAL',
//       'CABOOAN',
//       'CALANGAY',
//       'CAMBUJA',
//       'CORALAN',
//       'CUEVA',
//       'INAYAPAN',
//       'JOSE LAUREL, SR.',
//       'JOSE RIZAL',
//       'KAYHAKAT',
//       'MACASIPAC',
//       'MASINAO',
//       'MATALING-TING',
//       'PAO-O',
//       'PARANG NG BUHO',
//       'SANTIAGO',
//       'TALANGKA',
//       'TUNGKOD',
//     ],
//   },
//   SARANGANI: {
//     province: 'DAVAO OCCIDENTAL',
//     municipality: 'SARANGANI',
//     region: 'REGION XI',
//     barangay_list: [
//       'BATUGANDING',
//       'CAMAHUAL',
//       'CAMALIG',
//       'GOMTAGO',
//       'KONEL',
//       'LAKER (SARANGANI SUR)',
//       'LIPOL',
//       'MABILA (POB.)',
//       'PATUCO (SARANGANI NORTE)',
//       'TAGEN',
//       'TININA',
//       'TUCAL',
//     ],
//   },
//   BAGANGA: {
//     province: 'DAVAO ORIENTAL',
//     municipality: 'BAGANGA',
//     region: 'REGION XI',
//     barangay_list: [
//       'BACULIN',
//       'BANAO',
//       'BATAWAN',
//       'BATIANO',
//       'BINONDO',
//       'BOBONAO',
//       'CAMPAWAN',
//       'CENTRAL (POB.)',
//       'DAPNAN',
//       'KINABLANGAN',
//       'LAMBAJON',
//       'LUCOD',
//       'MAHANUB',
//       'MIKIT',
//       'SALINGCOMOT',
//       'SAN ISIDRO',
//       'SAN VICTOR',
//       'SAOQUEGUE',
//     ],
//   },
//   BANAYBANAY: {
//     province: 'DAVAO ORIENTAL',
//     municipality: 'BANAYBANAY',
//     region: 'REGION XI',
//     barangay_list: [
//       'CABANGCALAN',
//       'CAGANGANAN',
//       'CALUBIHAN',
//       'CAUSWAGAN',
//       'MAHAYAG',
//       'MAPUTI',
//       'MOGBONGCOGON',
//       'PANIKIAN',
//       'PINTATAGAN',
//       'PISO PROPER',
//       'POBLACION',
//       'PUNTA LINAO',
//       'RANG-AY',
//       'SAN VICENTE',
//     ],
//   },
//   BOSTON: {
//     province: 'DAVAO ORIENTAL',
//     municipality: 'BOSTON',
//     region: 'REGION XI',
//     barangay_list: [
//       'CAATIHAN',
//       'CABASAGAN',
//       'CARMEN',
//       'CAWAYANAN',
//       'POBLACION',
//       'SAN JOSE',
//       'SIBAJAY',
//       'SIMULAO',
//     ],
//   },
//   CARAGA: {
//     province: 'DAVAO ORIENTAL',
//     municipality: 'CARAGA',
//     region: 'REGION XI',
//     barangay_list: [
//       'ALVAR',
//       'CANINGAG',
//       'DON LEON BALANTE',
//       'LAMIAWAN',
//       'MANORIGAO',
//       'MERCEDES',
//       'PALMA GIL',
//       'PICHON',
//       'POBLACION',
//       'SAN ANTONIO',
//       'SAN JOSE',
//       'SAN LUIS',
//       'SAN MIGUEL',
//       'SAN PEDRO',
//       'SANTA FE',
//       'SANTIAGO',
//       'SOBRECAREY',
//     ],
//   },
//   CATEEL: {
//     province: 'DAVAO ORIENTAL',
//     municipality: 'CATEEL',
//     region: 'REGION XI',
//     barangay_list: [
//       'ABIJOD',
//       'ALEGRIA',
//       'ALIWAGWAG',
//       'ARAGON',
//       'BAYBAY',
//       'MAGLAHUS',
//       'MAINIT',
//       'MALIBAGO',
//       'POBLACION',
//       'SAN ALFONSO',
//       'SAN ANTONIO',
//       'SAN MIGUEL',
//       'SAN RAFAEL',
//       'SAN VICENTE',
//       'SANTA FILOMENA',
//       'TAYTAYAN',
//     ],
//   },
//   'GOVERNOR GENEROSO': {
//     province: 'DAVAO ORIENTAL',
//     municipality: 'GOVERNOR GENEROSO',
//     region: 'REGION XI',
//     barangay_list: [
//       'ANITAP',
//       'CRISPIN DELA CRUZ',
//       'DON AURELIO CHICOTE',
//       'LAVIGAN',
//       'LUZON',
//       'MAGDUG',
//       'MANUEL ROXAS',
//       'MONSERRAT',
//       'NANGAN',
//       'OREGON',
//       'POBLACION',
//       'PUNDAGUITAN',
//       'SERGIO OSMEÑA',
//       'SUROP',
//       'TAGABEBE',
//       'TAMBAN',
//       'TANDANG SORA',
//       'TIBANBAN',
//       'TIBLAWAN',
//       'UPPER TIBANBAN',
//     ],
//   },
//   LUPON: {
//     province: 'DAVAO ORIENTAL',
//     municipality: 'LUPON',
//     region: 'REGION XI',
//     barangay_list: [
//       'BAGUMBAYAN',
//       'CABADIANGAN',
//       'CALAPAGAN',
//       'COCORNON',
//       'CORPORACION',
//       'DON MARIANO MARCOS',
//       'ILANGAY',
//       'LANGKA',
//       'LANTAWAN',
//       'LIMBAHAN',
//       'MACANGAO',
//       'MAGSAYSAY',
//       'MAHAYAHAY',
//       'MARAGATAS',
//       'MARAYAG',
//       'NEW VISAYAS',
//       'POBLACION',
//       'SAN ISIDRO',
//       'SAN JOSE',
//       'TAGBOA',
//       'TAGUGPO',
//     ],
//   },
//   MANAY: {
//     province: 'DAVAO ORIENTAL',
//     municipality: 'MANAY',
//     region: 'REGION XI',
//     barangay_list: [
//       'CAPASNAN',
//       'CAYAWAN',
//       'CENTRAL (POB.)',
//       'CONCEPCION',
//       'DEL PILAR',
//       'GUZA',
//       'HOLY CROSS',
//       'LAMBOG',
//       'MABINI',
//       'MANREZA',
//       'NEW TAOKANGA',
//       'OLD MACOPA',
//       'RIZAL',
//       'SAN FERMIN',
//       'SAN IGNACIO',
//       'SAN ISIDRO',
//       'ZARAGOSA',
//     ],
//   },
//   'MATI': {
//     province: 'DAVAO ORIENTAL',
//     municipality: 'MATI',
//     region: 'REGION XI',
//     barangay_list: [
//       'BADAS',
//       'BOBON',
//       'BUSO',
//       'CABUAYA',
//       'CENTRAL (POB.)',
//       'CULIAN',
//       'DAHICAN',
//       'DANAO',
//       'DAWAN',
//       'DON ENRIQUE LOPEZ',
//       'DON MARTIN MARUNDAN',
//       'DON SALVADOR LOPEZ, SR.',
//       'LANGKA',
//       'LAWIGAN',
//       'LIBUDON',
//       'LUBAN',
//       'MACAMBOL',
//       'MAMALI',
//       'MATIAO',
//       'MAYO',
//       'SAINZ',
//       'SANGHAY',
//       'TAGABAKID',
//       'TAGBINONGA',
//       'TAGUIBO',
//       'TAMISAN',
//     ],
//   },
//   TARRAGONA: {
//     province: 'DAVAO ORIENTAL',
//     municipality: 'TARRAGONA',
//     region: 'REGION XI',
//     barangay_list: [
//       'CABAGAYAN',
//       'CENTRAL (POB.)',
//       'DADONG',
//       'JOVELLAR',
//       'LIMOT',
//       'LUCATAN',
//       'MAGANDA',
//       'OMPAO',
//       'TOMOAONG',
//       'TUBAON',
//     ],
//   },
//   ALAMADA: {
//     province: 'COTABATO (NORTH COT.)',
//     municipality: 'ALAMADA',
//     region: 'REGION XII',
//     barangay_list: [
//       'BAO',
//       'BARANGIRAN',
//       'CAMANSI',
//       'DADO',
//       'GUILING',
//       'KITACUBONG (POB.)',
//       'LOWER DADO',
//       'MACABASA',
//       'MALITUBOG',
//       'MAPUROK',
//       'MIRASOL',
//       'PACAO',
//       'PARUAYAN',
//       'PIGCAWARAN',
//       'POLAYAGAN',
//       'RANGAYEN',
//       'RARADANGAN',
//     ],
//   },
//   ALEOSAN: {
//     province: 'COTABATO (NORTH COT.)',
//     municipality: 'ALEOSAN',
//     region: 'REGION XII',
//     barangay_list: [
//       'BAGOLIBAS',
//       'CAWILIHAN',
//       'DUALING',
//       'DUNGUAN',
//       'KATALICANAN',
//       'LAWILI',
//       'LOWER MINGADING',
//       'LUANAN',
//       'MALAPANG',
//       'NEW LEON',
//       'NEW PANAY',
//       'PAGANGAN',
//       'PALACAT',
//       'PENTIL',
//       'SAN MATEO',
//       'SANTA CRUZ',
//       'TAPODOC',
//       'TOMADO',
//       'UPPER MINGADING',
//     ],
//   },
//   ANTIPAS: {
//     province: 'COTABATO (NORTH COT.)',
//     municipality: 'ANTIPAS',
//     region: 'REGION XII',
//     barangay_list: [
//       'B. CADUNGON',
//       'CAMUTAN',
//       'CANAAN',
//       'DATU AGOD',
//       'DOLORES',
//       'KIYAAB',
//       'LUHONG',
//       'MAGSAYSAY',
//       'MALANGAG',
//       'MALATAD',
//       'MALIRE',
//       'NEW PONTEVEDRA',
//       'POBLACION',
//     ],
//   },
//   ARAKAN: {
//     province: 'COTABATO (NORTH COT.)',
//     municipality: 'ARAKAN',
//     region: 'REGION XII',
//     barangay_list: [
//       'ALLAB',
//       'ANAPOLON',
//       'BADIANGON',
//       'BINOONGAN',
//       'DALLAG',
//       'DATU LADAYON',
//       'DATU MATANGKIL',
//       'DOROLUMAN',
//       'GAMBODES',
//       'GANATAN',
//       'GREENFIELD',
//       'KABALANTIAN',
//       'KATIPUNAN',
//       'KINAWAYAN',
//       'KULAMAN VALLEY',
//       'LANAO KURAN',
//       'LIBERTAD',
//       'MAKALANGOT',
//       'MALIBATUAN',
//       'MARIA CARIDAD',
//       'MEOCAN',
//       'NAJE',
//       'NAPALICO',
//       'SALASANG',
//       'SAN MIGUEL',
//       'SANTO NIÑO',
//       'SUMALILI',
//       'TUMANDING',
//     ],
//   },
//   BANISILAN: {
//     province: 'COTABATO (NORTH COT.)',
//     municipality: 'BANISILAN',
//     region: 'REGION XII',
//     barangay_list: [
//       'BANISILAN POBLACION',
//       'BUSAON',
//       'CAPAYANGAN',
//       'CARUGMANAN',
//       'GASTAY',
//       'KALAWAIG',
//       'KIARING',
//       'MALAGAP',
//       'MALINAO',
//       'MIGUEL MACASARTE',
//       'PANTAR',
//       'PARADISE',
//       'PINAMULAAN',
//       'POBLACION II',
//       'PUTING-BATO',
//       'SOLAMA',
//       'THAILAND',
//       'TINIMBACAN',
//       'TUMBAO-CAMALIG',
//       'WADYA',
//     ],
//   },
//   'KIDAPAWAN': {
//     province: 'COTABATO (NORTH COT.)',
//     municipality: 'KIDAPAWAN',
//     region: 'REGION XII',
//     barangay_list: [
//       'AMAS',
//       'AMAZION',
//       'BALABAG',
//       'BALINDOG',
//       'BENOLIGAN',
//       'BERADA',
//       'GAYOLA',
//       'GINATILAN',
//       'ILOMAVIS',
//       'INDANGAN',
//       'JUNCTION',
//       'KALAISAN',
//       'KALASUYAN',
//       'KATIPUNAN',
//       'LANAO',
//       'LINANGCOB',
//       'LUVIMIN',
//       'MACABOLIG',
//       'MAGSAYSAY',
//       'MALINAN',
//       'MANONGOL',
//       'MARBEL (EMBAC)',
//       'MATEO',
//       'MEOCHAO',
//       'MUA-AN',
//       'NEW BOHOL',
//       'NUANGAN',
//       'ONICA',
//       'PACO',
//       'PATADON (PATADON EAST)',
//       'PEREZ',
//       'POBLACION',
//       'SAN ISIDRO',
//       'SAN ROQUE',
//       'SANTO NIÑO',
//       'SIBAWAN',
//       'SIKITAN',
//       'SINGAO',
//       'SUDAPIN',
//       'SUMBAO',
//     ],
//   },
//   KABACAN: {
//     province: 'COTABATO (NORTH COT.)',
//     municipality: 'KABACAN',
//     region: 'REGION XII',
//     barangay_list: [
//       'ARINGAY',
//       'BANGILAN',
//       'BANNAWAG',
//       'BULUAN',
//       'CUYAPON',
//       'DAGUPAN',
//       'KATIDTUAN',
//       'KAYAGA',
//       'KILAGASAN',
//       'MAGATOS',
//       'MALAMOTE',
//       'MALANDUAGUE',
//       'NANGA-AN',
//       'OSIAS',
//       'PAATAN LOWER',
//       'PAATAN UPPER',
//       'PEDTAD',
//       'PISAN',
//       'POBLACION',
//       'SALAPUNGAN',
//       'SANGGADONG',
//       'SIMBUHAY',
//       'SIMONE',
//       'TAMPED',
//     ],
//   },
//   LIBUNGAN: {
//     province: 'COTABATO (NORTH COT.)',
//     municipality: 'LIBUNGAN',
//     region: 'REGION XII',
//     barangay_list: [
//       'ABAGA',
//       'BAGUER',
//       'BARONGIS',
//       'BATIOCAN',
//       'CABARUYAN',
//       'CABPANGI',
//       'DEMAPACO',
//       'GREBONA',
//       'GUMAGA',
//       'KAPAYAWI',
//       'KILOYAO',
//       'KITUBOD',
//       'MALENGEN',
//       'MONTAY',
//       'NICA-AN',
//       'PALAO',
//       'POBLACION',
//       'SINAPANGAN',
//       'SINAWINGAN',
//       'ULAMIAN',
//     ],
//   },
//   "M'LANG": {
//     province: 'COTABATO (NORTH COT.)',
//     municipality: "M'LANG",
//     region: 'REGION XII',
//     barangay_list: [
//       'BAGONTAPAY',
//       'BIALONG',
//       'BUAYAN',
//       'CALUNASAN',
//       'DAGONG',
//       'DALIPE',
//       'DUNGO-AN',
//       'GAUNAN',
//       'INAS',
//       'KATIPUNAN',
//       'LA FORTUNA',
//       'LA SUERTE',
//       'LANGKONG',
//       'LEPAGA',
//       'LIBOO',
//       'LIKA',
//       'LUZ VILLAGE',
//       'MAGALLON',
//       'MALAYAN',
//       'NEW ANTIQUE',
//       'NEW BARBAZA',
//       'NEW CONSOLACION',
//       'NEW ESPERANZA',
//       'NEW JANIUAY',
//       'NEW KALIBO',
//       'NEW LAWA-AN',
//       'NEW RIZAL',
//       'NUEVA VIDA',
//       'PAG-ASA',
//       'PALMA-PEREZ',
//       'POBLACION',
//       'POBLACION B',
//       'PULANG-LUPA',
//       'SANGAT',
//       'TAWANTAWAN',
//       'TIBAO',
//       'UGPAY',
//     ],
//   },
//   MAGPET: {
//     province: 'COTABATO (NORTH COT.)',
//     municipality: 'MAGPET',
//     region: 'REGION XII',
//     barangay_list: [
//       'ALIBAYON',
//       'AMABEL',
//       'BAGUMBAYAN',
//       'BALETE',
//       'BANGKAL',
//       'BANTAC',
//       'BASAK',
//       'BINAY',
//       'BONGOLANON',
//       'DATU CELO',
//       'DEL PILAR',
//       'DOLES',
//       'DON PANACA',
//       'GUBATAN',
//       'ILIAN',
//       'IMAMALING',
//       'INAC',
//       'KAMADA',
//       'KAUSWAGAN',
//       'KINARUM',
//       'KISANDAL',
//       'MAGCAALAM',
//       'MAHONGCOG',
//       'MANOBISA',
//       'MANOBO',
//       'NOA',
//       'OWAS',
//       'PANGAO-AN',
//       'POBLACION',
//       'SALLAB',
//       'TAGBAC',
//       'TEMPORAN',
//     ],
//   },
//   MAKILALA: {
//     province: 'COTABATO (NORTH COT.)',
//     municipality: 'MAKILALA',
//     region: 'REGION XII',
//     barangay_list: [
//       'BATASAN',
//       'BATO',
//       'BIANGAN',
//       'BUENA VIDA',
//       'BUHAY',
//       'BULAKANON',
//       'CABILAO',
//       'CONCEPCION',
//       'DAGUPAN',
//       'GARSIKA',
//       'GUANGAN',
//       'INDANGAN',
//       'JOSE RIZAL',
//       'KATIPUNAN II',
//       'KAWAYANON',
//       'KISANTE',
//       'LEBOCE',
//       'LIBERTAD',
//       'LUAYON',
//       'LUNA NORTE',
//       'LUNA SUR',
//       'MALABUAN',
//       'MALASILA',
//       'MALUNGON',
//       'NEW BAGUIO',
//       'NEW BULATUKAN',
//       'NEW CEBU',
//       'NEW ISRAEL',
//       'OLD BULATUKAN',
//       'POBLACION',
//       'RODERO',
//       'SAGUING',
//       'SAN VICENTE',
//       'SANTA FELOMINA',
//       'SANTO NIÑO',
//       'SINKATULAN',
//       'TALUNTALUNAN',
//       'VILLAFLORES',
//     ],
//   },
//   MATALAM: {
//     province: 'COTABATO (NORTH COT.)',
//     municipality: 'MATALAM',
//     region: 'REGION XII',
//     barangay_list: [
//       'ARAKAN',
//       'BANGBANG',
//       'BATO',
//       'CENTRAL MALAMOTE',
//       'DALAPITAN',
//       'ESTADO',
//       'ILIAN',
//       'KABULACAN',
//       'KIBIA',
//       'KIBUDOC',
//       'KIDAMA',
//       'KILADA',
//       'LAMPAYAN',
//       'LATAGAN',
//       'LINAO',
//       'LOWER MALAMOTE',
//       'MANUBUAN',
//       'MANUPAL',
//       'MARBEL',
//       'MINAMAING',
//       'NATUTUNGAN',
//       'NEW ABRA',
//       'NEW ALIMODIAN',
//       'NEW BUGASONG',
//       'NEW PANDAN',
//       'PATADON WEST',
//       'PINAMATON',
//       'POBLACION',
//       'SALVACION',
//       'SANTA MARIA',
//       'SARAYAN',
//       'TACULEN',
//       'TAGURANAO',
//       'TAMPED (TAMPAD)',
//     ],
//   },
//   MIDSAYAP: {
//     province: 'COTABATO (NORTH COT.)',
//     municipality: 'MIDSAYAP',
//     region: 'REGION XII',
//     barangay_list: [
//       'AGRICULTURE',
//       'ANONANG',
//       'ARIZONA',
//       'BAGUMBA',
//       'BALIKI',
//       'BARANGAY POBLACION 1',
//       'BARANGAY POBLACION 2',
//       'BARANGAY POBLACION 3',
//       'BARANGAY POBLACION 4',
//       'BARANGAY POBLACION 5',
//       'BARANGAY POBLACION 6',
//       'BARANGAY POBLACION 7',
//       'BARANGAY POBLACION 8',
//       'BITOKA',
//       'BUAL NORTE',
//       'BUAL SUR',
//       'BULANAN UPPER',
//       'CENTRAL BULANAN',
//       'CENTRAL GLAD',
//       'CENTRAL KATINGAWAN',
//       'CENTRAL LABAS',
//       'DAMATULAN',
//       'ILBOCEAN',
//       'KADIGASAN',
//       'KADINGILAN',
//       'KAPINPILAN',
//       'KIMAGANGO',
//       'KIWANAN',
//       'KUDARANGAN',
//       'LAGUMBINGAN',
//       'LOMOPOG',
//       'LOWER GLAD',
//       'LOWER KATINGAWAN',
//       'MACASENDEG',
//       'MALAMOTE',
//       'MALINGAO',
//       'MILAYA',
//       'MUDSENG',
//       'NABALAWAG',
//       'NALIN',
//       'NES',
//       'OLANDANG',
//       'PALONGOGUEN',
//       'PATINDEGUEN',
//       'RANGABAN',
//       'SADAAN',
//       'SALUNAYAN',
//       'SAMBULAWAN',
//       'SAN ISIDRO',
//       'SAN PEDRO',
//       'SANTA CRUZ',
//       'TUGAL',
//       'TUMBRAS',
//       'UPPER GLAD I',
//       'UPPER GLAD II',
//       'UPPER LABAS',
//       'VILLARICA',
//     ],
//   },
//   PIGKAWAYAN: {
//     province: 'COTABATO (NORTH COT.)',
//     municipality: 'PIGKAWAYAN',
//     region: 'REGION XII',
//     barangay_list: [
//       'ANICK (UPPER BALOGO)',
//       'BALACAYON',
//       'BALOGO',
//       'BANUCAGON',
//       'BULUAN',
//       'BULUCAON',
//       'BURICAIN',
//       'CABPANGI',
//       'CAPAYURAN',
//       'CENTRAL PANATAN',
//       'DATU BINASING',
//       'DATU MANTIL',
//       'KADINGILAN',
//       'KIMARAYANG',
//       'LIBUNGAN TORRETA',
//       'LOWER BAGUER',
//       'LOWER PANGANGKALAN',
//       'MALAGAKIT',
//       'MALUAO',
//       'MATILAC',
//       'MIDPAPAN I',
//       'MIDPAPAN II',
//       'MULOK',
//       'NEW CULASI',
//       'NEW IGBARAS',
//       'NEW PANAY',
//       'NORTH MANUANGAN',
//       'PATOT',
//       'PAYONG-PAYONG',
//       'POBLACION I',
//       'POBLACION II',
//       'POBLACION III',
//       'PRESBITERO',
//       'RENIBON',
//       'SIMSIMAN',
//       'SOUTH MANUANGAN',
//       'TIGBAWAN',
//       'TUBON',
//       'UPPER BAGUER (BAGUER)',
//       'UPPER PANGANGKALAN',
//     ],
//   },
//   PIKIT: {
//     province: 'COTABATO (NORTH COT.)',
//     municipality: 'PIKIT',
//     region: 'REGION XII',
//     barangay_list: [
//       'BAGOAINGUD (BAGOINGED)',
//       'BALABAK',
//       'BALATICAN',
//       'BALONG',
//       'BALUNGIS',
//       'BARUNGIS',
//       'BATULAWAN',
//       'BUALAN',
//       'BULIOK',
//       'BULOD',
//       'BULOL',
//       'CALAWAG',
//       'DALINGAOEN (LALINGAON)',
//       'DAMALASAK',
//       'FORT PIKIT',
//       'GINATILAN',
//       'GLIGLI',
//       'GOKOTON (GOKOTAN)',
//       'INUG-UG',
//       'KABASALAN',
//       'KALACACAN',
//       'KATILACAN',
//       'KOLAMBOG',
//       'LADTINGAN',
//       'LAGUNDE',
//       'LANGAYEN',
//       'MACABUAL',
//       'MACASENDEG',
//       'MANAULANAN',
//       'NABUNDAS',
//       'NALAPAAN',
//       'NUNGUAN',
//       'PAIDU PULANGI',
//       'PAMALIAN',
//       'PANICUPAN',
//       'POBLACION',
//       'PUNOL',
//       'RAJAH MUDA',
//       'SILIK',
//       'TAKIPAN',
//       'TALITAY',
//       'TINUTULAN',
//     ],
//   },
//   'PRESIDENT ROXAS': {
//     province: 'CAPIZ',
//     municipality: 'PRESIDENT ROXAS',
//     region: 'REGION VI',
//     barangay_list: [
//       'ARANGUEL',
//       'BADIANGON',
//       'BAYUYAN',
//       'CABUGCABUG',
//       'CARMENCITA',
//       'CUBAY',
//       'CULILANG',
//       'GOCE',
//       'HANGLID',
//       'IBACA',
//       'MADULANO',
//       'MANOLING',
//       'MARITA',
//       'PANDAN',
//       'PANTALAN CABUGCABUG',
//       'PINAMIHAGAN',
//       'POBLACION (ELIZALDE)',
//       'PONDOL',
//       'QUIAJO',
//       'SANGKAL',
//       'SANTO NIÑO',
//       'VIZCAYA',
//     ],
//   },
//   TULUNAN: {
//     province: 'COTABATO (NORTH COT.)',
//     municipality: 'TULUNAN',
//     region: 'REGION XII',
//     barangay_list: [
//       'BACONG',
//       'BAGUMBAYAN',
//       'BANAYAL',
//       'BATANG',
//       'BITUAN',
//       'BUAL',
//       'BUNAWAN',
//       'DAIG',
//       'DAMAWATO',
//       'DUNGOS',
//       'F. CAJELO',
//       'GALIDAN',
//       'GENOVEVA BAYNOSA',
//       'KANIBONG',
//       'LA ESPERANZA',
//       'LAMPAGANG',
//       'MAGBOK',
//       'MAYBULA',
//       'MINAPAN',
//       'NABUNDASAN',
//       'NEW CARIDAD',
//       'NEW CULASI',
//       'NEW PANAY',
//       'PARAISO',
//       'POBLACION',
//       'POPOYON',
//       'SIBSIB',
//       'TAMBAC',
//       'TUBURAN',
//     ],
//   },
//   ALABEL: {
//     province: 'SARANGANI',
//     municipality: 'ALABEL',
//     region: 'REGION XII',
//     barangay_list: [
//       'ALEGRIA',
//       'BAGACAY',
//       'BALUNTAY',
//       'DATAL ANGGAS',
//       'DOMOLOK',
//       'KAWAS',
//       'LADOL',
//       'MARIBULAN',
//       'PAG-ASA',
//       'PARAISO',
//       'POBLACION (ALABEL)',
//       'SPRING',
//       'TOKAWAL',
//     ],
//   },
//   GLAN: {
//     province: 'SARANGANI',
//     municipality: 'GLAN',
//     region: 'REGION XII',
//     barangay_list: [
//       'BALITON',
//       'BATOTULING',
//       'BATULAKI',
//       'BIG MARGUS',
//       'BURIAS',
//       'CABLALAN',
//       'CALABANIT',
//       'CALPIDONG',
//       'CONGAN',
//       'CROSS',
//       'DATALBUKAY',
//       'E. ALEGADO',
//       'GLAN PADIDU',
//       'GUMASA',
//       'ILAYA',
//       'KALTUAD',
//       'KAPATAN',
//       'LAGO',
//       'LAGUIMIT',
//       'MUDAN',
//       'NEW AKLAN',
//       'PANGYAN',
//       'POBLACION',
//       'RIO DEL PILAR',
//       'SAN JOSE',
//       'SAN VICENTE',
//       'SMALL MARGUS',
//       'SUFATUBO',
//       'TALUYA',
//       'TANGO',
//       'TAPON',
//     ],
//   },
//   KIAMBA: {
//     province: 'SARANGANI',
//     municipality: 'KIAMBA',
//     region: 'REGION XII',
//     barangay_list: [
//       'BADTASAN',
//       'DATU DANI',
//       'GASI',
//       'KAPATE',
//       'KATUBAO',
//       'KAYUPO',
//       'KLING (LUMIT)',
//       'LAGUNDI',
//       'LEBE',
//       'LOMUYON',
//       'LUMA',
//       'MALIGANG',
//       'NALUS',
//       'POBLACION',
//       'SALAKIT',
//       'SULI',
//       'TABLAO',
//       'TAMADANG',
//       'TAMBILIL',
//     ],
//   },
//   MAASIM: {
//     province: 'SARANGANI',
//     municipality: 'MAASIM',
//     region: 'REGION XII',
//     barangay_list: [
//       'AMSIPIT',
//       'BALES',
//       'COLON',
//       'DALIAO',
//       'KABATIOL',
//       'KABLACAN',
//       'KAMANGA',
//       'KANALO',
//       'LUMASAL',
//       'LUMATIL',
//       'MALBANG',
//       'NOMOH',
//       'PANANAG',
//       'POBLACION (MAASIM)',
//       'SEVEN HILLS',
//       'TINOTO',
//     ],
//   },
//   MAITUM: {
//     province: 'SARANGANI',
//     municipality: 'MAITUM',
//     region: 'REGION XII',
//     barangay_list: [
//       'BATI-AN',
//       'KALANEG',
//       'KALAONG',
//       'KIAMBING',
//       'KIAYAP',
//       'MABAY',
//       'MAGULING',
//       'MALALAG (POB.)',
//       'MINDUPOK',
//       'NEW LA UNION',
//       'OLD POBLACION (MAITUM)',
//       'PANGI (LINAO)',
//       'PINOL',
//       'SISON (EDENTON)',
//       'TICULAB',
//       'TUANADATU',
//       'UPO (LANAO)',
//       'WALI (KAMBUHAN)',
//       'ZION',
//     ],
//   },
//   MALAPATAN: {
//     province: 'SARANGANI',
//     municipality: 'MALAPATAN',
//     region: 'REGION XII',
//     barangay_list: [
//       'DAAN SUYAN',
//       'KIHAN',
//       'KINAM',
//       'LIBI',
//       'LUN MASLA',
//       'LUN PADIDU',
//       'PATAG',
//       'POBLACION (MALAPATAN)',
//       'SAPU MASLA',
//       'SAPU PADIDU',
//       'TUYAN',
//       'UPPER SUYAN',
//     ],
//   },
//   MALUNGON: {
//     province: 'SARANGANI',
//     municipality: 'MALUNGON',
//     region: 'REGION XII',
//     barangay_list: [
//       'ALKIKAN',
//       'AMPON',
//       'ATLAE',
//       "B'LAAN",
//       'BANAHAW',
//       'BANATE',
//       'DATAL BATONG',
//       'DATAL BILA',
//       'DATAL TAMPAL',
//       'J.P. LAUREL',
//       'KAWAYAN',
//       'KIBALA',
//       'KIBLAT',
//       'KINABALAN',
//       'LOWER MAINIT',
//       'LUTAY',
//       'MALABOD',
//       'MALALAG COGON',
//       'MALANDAG',
//       'MALUNGON GAMAY',
//       'NAGPAN',
//       'PANAMIN',
//       'POBLACION',
//       'SAN JUAN',
//       'SAN MIGUEL',
//       'SAN ROQUE',
//       'TALUS',
//       'TAMBAN',
//       'UPPER BIANGAN',
//       'UPPER LUMABAT',
//       'UPPER MAINIT',
//     ],
//   },
//   BANGA: {
//     province: 'AKLAN',
//     municipality: 'BANGA',
//     region: 'REGION VI',
//     barangay_list: [
//       'AGBANAWAN',
//       'BACAN',
//       'BADIANGAN',
//       'CERRUDO',
//       'CUPANG',
//       'DAGUITAN',
//       'DAJA NORTE',
//       'DAJA SUR',
//       'DINGLE',
//       'JUMARAP',
//       'LAPNAG',
//       'LIBAS',
//       'LINABUAN SUR',
//       'MAMBOG',
//       'MANGAN',
//       'MUGUING',
//       'PAGSANGHAN',
//       'PALALE',
//       'POBLACION',
//       'POLO',
//       'POLOCATE',
//       'SAN ISIDRO',
//       'SIBALEW',
//       'SIGCAY',
//       'TABA-AO',
//       'TABAYON',
//       'TINAPUAY',
//       'TORRALBA',
//       'UGSOD',
//       'VENTURANZA',
//     ],
//   },
//   'GENERAL SANTOS CITY (DADIANGAS)': {
//     province: 'SOUTH COTABATO',
//     municipality: 'GENERAL SANTOS CITY (DADIANGAS)',
//     region: 'REGION XII',
//     barangay_list: [
//       'APOPONG',
//       'BALUAN',
//       'BATOMELONG',
//       'BUAYAN',
//       'BULA',
//       'CALUMPANG',
//       'CITY HEIGHTS',
//       'CONEL',
//       'DADIANGAS EAST (POB.)',
//       'DADIANGAS NORTH',
//       'DADIANGAS SOUTH',
//       'DADIANGAS WEST',
//       'FATIMA',
//       'KATANGAWAN',
//       'LABANGAL',
//       'LAGAO (1ST AND 3RD)',
//       'LIGAYA',
//       'MABUHAY',
//       'OLYMPOG',
//       'SAN ISIDRO (LAGAO 2ND)',
//       'SAN JOSE',
//       'SIGUEL',
//       'SINAWAL',
//       'TAMBLER',
//       'TINAGACAN',
//       'UPPER LABAY',
//     ],
//   },
//   'KORONADAL': {
//     province: 'SOUTH COTABATO',
//     municipality: 'KORONADAL',
//     region: 'REGION XII',
//     barangay_list: [
//       'ASSUMPTION (BULOL)',
//       'AVANCEÑA (BO. 3)',
//       'CACUB',
//       'CALOOCAN',
//       'CARPENTER HILL',
//       'CONCEPCION (BO. 6)',
//       'ESPERANZA',
//       'GENERAL PAULINO SANTOS (BO. 1)',
//       'MABINI',
//       'MAGSAYSAY',
//       'MAMBUCAL',
//       'MORALES',
//       'NAMNAMA',
//       'NEW PANGASINAN (BO. 4)',
//       'PARAISO',
//       'ROTONDA',
//       'SAN ISIDRO',
//       'SAN JOSE (BO. 5)',
//       'SAN ROQUE',
//       'SANTA CRUZ',
//       'SANTO NIÑO (BO. 2)',
//       'SARABIA (BO. 8)',
//       'ZONE I (POB.)',
//       'ZONE II (POB.)',
//       'ZONE III (POB.)',
//       'ZONE IV (POB.)',
//       'ZULUETA (BO. 7)',
//     ],
//   },
//   'LAKE SEBU': {
//     province: 'SOUTH COTABATO',
//     municipality: 'LAKE SEBU',
//     region: 'REGION XII',
//     barangay_list: [
//       'BACDULONG',
//       'DENLAG',
//       'HALILAN',
//       'HANOON',
//       'KLUBI',
//       'LAKE LAHIT',
//       'LAMCADE',
//       'LAMDALAG',
//       'LAMFUGON',
//       'LAMLAHAK',
//       'LOWER MACULAN',
//       'LUHIB',
//       'NED',
//       'POBLACION',
//       'SILUTON',
//       'TAKUNEL',
//       'TALISAY',
//       'TASIMAN',
//       'UPPER MACULAN',
//     ],
//   },
//   NORALA: {
//     province: 'SOUTH COTABATO',
//     municipality: 'NORALA',
//     region: 'REGION XII',
//     barangay_list: [
//       'BENIGNO AQUINO, JR.',
//       'DUMAGUIL',
//       'ESPERANZA',
//       'KIBID',
//       'LAPUZ',
//       'LIBERTY',
//       'LOPEZ JAENA',
//       'MATAPOL',
//       'POBLACION',
//       'PUTI',
//       'SAN JOSE',
//       'SAN MIGUEL',
//       'SIMSIMAN',
//       'TINAGO',
//     ],
//   },
//   POLOMOLOK: {
//     province: 'SOUTH COTABATO',
//     municipality: 'POLOMOLOK',
//     region: 'REGION XII',
//     barangay_list: [
//       'BENTUNG',
//       'CANNERY SITE',
//       'CROSSING PALKAN',
//       'GLAMANG',
//       'KINILIS',
//       'KLINAN 6',
//       'KORONADAL PROPER',
//       'LAM-CALIAF',
//       'LANDAN',
//       'LAPU',
//       'LUMAKIL',
//       'MAGSAYSAY',
//       'MALIGO',
//       'PAGALUNGAN',
//       'PALKAN',
//       'POBLACION',
//       'POLO',
//       'RUBBER',
//       'SILWAY 7',
//       'SILWAY 8',
//       'SULIT',
//       'SUMBAKIL',
//       'UPPER KLINAN',
//     ],
//   },
//   'SANTO NIÑO': {
//     province: 'SAMAR (WESTERN SAMAR)',
//     municipality: 'SANTO NIÑO',
//     region: 'REGION VIII',
//     barangay_list: [
//       'BALATGUTI',
//       'BARAS',
//       'BASUD (POB.)',
//       'BUENAVISTA',
//       'CABUNGA-AN',
//       'COROCAWAYAN',
//       'ILIJAN',
//       'ILO (POB.)',
//       'LOBELOBE',
//       'PINANANGNAN',
//       'SEVILLA',
//       'TAKUT',
//       'VILLAHERMOSA',
//     ],
//   },
//   SURALLAH: {
//     province: 'SOUTH COTABATO',
//     municipality: 'SURALLAH',
//     region: 'REGION XII',
//     barangay_list: [
//       'BUENAVISTA',
//       'CANAHAY (GODWINO)',
//       'CENTRALA',
//       'COLONGULO',
//       'DAJAY',
//       'DUENGAS',
//       'LAMBONTONG',
//       'LAMIAN',
//       'LAMSUGOD',
//       'LIBERTAD (POB.)',
//       'LITTLE BAGUIO',
//       'MOLOY',
//       'NACI (DOCE)',
//       'TALAHIK',
//       'TUBIALA',
//       'UPPER SEPAKA',
//       'VETERANS',
//     ],
//   },
//   'T`BOLI': {
//     province: 'SOUTH COTABATO',
//     municipality: 'T`BOLI',
//     region: 'REGION XII',
//     barangay_list: [
//       'AFLEK',
//       'AFUS',
//       'BASAG',
//       'DATAL BOB',
//       'DESAWO',
//       'DLANAG',
//       'EDWARDS (POB.)',
//       'KEMATU',
//       'LACONON',
//       'LAMBANGAN',
//       'LAMBULING',
//       'LAMHAKO',
//       'LAMSALOME',
//       'LEMSNOLON',
//       'MAAN',
//       'MALUGONG',
//       'MONGOCAYO',
//       'NEW DUMANGAS',
//       'POBLACION',
//       'SALACAFE',
//       'SINOLON',
//       "T'BOLOK",
//       'TALCON',
//       'TALUFO',
//       'TUDOK',
//     ],
//   },
//   TAMPAKAN: {
//     province: 'SOUTH COTABATO',
//     municipality: 'TAMPAKAN',
//     region: 'REGION XII',
//     barangay_list: [
//       'ALBAGAN',
//       'BUTO',
//       'DANLAG',
//       'KIPALBIG',
//       'LAMBAYONG',
//       'LAMPITAK',
//       'LIBERTY',
//       'MALTANA',
//       'PALO',
//       'POBLACION',
//       'PULA-BATO',
//       'SAN ISIDRO',
//       'SANTA CRUZ',
//       'TABLU',
//     ],
//   },
//   TANTANGAN: {
//     province: 'SOUTH COTABATO',
//     municipality: 'TANTANGAN',
//     region: 'REGION XII',
//     barangay_list: [
//       'BUKAY PAIT',
//       'CABULING',
//       'DUMADALIG',
//       'LIBAS',
//       'MAGON',
//       'MAIBO',
//       'MANGILALA',
//       'NEW CUYAPO',
//       'NEW ILOILO',
//       'NEW LAMBUNAO',
//       'POBLACION',
//       'SAN FELIPE',
//       'TINONGCOP',
//     ],
//   },
//   TUPI: {
//     province: 'SOUTH COTABATO',
//     municipality: 'TUPI',
//     region: 'REGION XII',
//     barangay_list: [
//       'ACMONAN',
//       'BOLOLMALA',
//       'BUNAO',
//       'CEBUANO',
//       'CROSSING RUBBER',
//       'KABLON',
//       'KALKAM',
//       'LINAN',
//       'LUNEN',
//       'MIASONG',
//       'PALIAN',
//       'POBLACION',
//       'POLONULING',
//       'SIMBO',
//       'TUBENG',
//     ],
//   },
//   BAGUMBAYAN: {
//     province: 'SULTAN KUDARAT',
//     municipality: 'BAGUMBAYAN',
//     region: 'REGION XII',
//     barangay_list: [
//       'BAI SARIFINANG',
//       'BIWANG',
//       'BUSOK',
//       'CHUA',
//       'DAGUMA',
//       'DALUGA',
//       'KABULANAN',
//       'KANULAY',
//       'KAPAYA',
//       'KINAYAO',
//       'MASIAG',
//       'MONTEVERDE',
//       'POBLACION',
//       'SANTO NIÑO',
//       'SISON',
//       'SOUTH SEPAKA',
//       'SUMILIL',
//       'TITULOK',
//       'TUKA',
//     ],
//   },
//   COLUMBIO: {
//     province: 'SULTAN KUDARAT',
//     municipality: 'COLUMBIO',
//     region: 'REGION XII',
//     barangay_list: [
//       'BANTANGAN (LASAK)',
//       'DATABLAO',
//       'EDAY',
//       'ELBEBE',
//       'LASAK',
//       'LIBERTAD',
//       'LOMOYON',
//       'MAKAT (SUMALI PAS)',
//       'MALIGAYA',
//       'MAYO',
//       'NATIVIDAD',
//       'POBLACION',
//       'POLOMOLOK',
//       'SINAPULAN',
//       'SUCOB',
//       'TELAFAS',
//     ],
//   },
//   ESPERANZA: {
//     province: 'MASBATE',
//     municipality: 'ESPERANZA',
//     region: 'REGION V',
//     barangay_list: [
//       'AGOHO',
//       'ALMERO',
//       'BARAS',
//       'DOMOROG',
//       'GUADALUPE',
//       'ILIGAN',
//       'LABANGTAYTAY',
//       'LABRADOR',
//       'LIBERTAD',
//       'MAGSAYSAY',
//       'MASBARANON',
//       'POBLACION',
//       'POTINGBATO',
//       'RIZAL',
//       'SAN ROQUE',
//       'SANTIAGO',
//       'SOROSIMBAJAN',
//       'TAWAD',
//       'TUNGA',
//       'VILLA',
//     ],
//   },
//   ISULAN: {
//     province: 'SULTAN KUDARAT',
//     municipality: 'ISULAN',
//     region: 'REGION XII',
//     barangay_list: [
//       'BAMBAD',
//       'BUAL',
//       "D'LOTILLA",
//       'DANSULI',
//       'IMPAO',
//       'KALAWAG I (POB.)',
//       'KALAWAG II (POB.)',
//       'KALAWAG III (POB.)',
//       'KENRAM',
//       'KOLAMBOG',
//       'KUDANDING',
//       'LAGANDANG',
//       'LAGUILAYAN',
//       'MAPANTIG',
//       'NEW PANGASINAN',
//       'SAMPAO',
//       'TAYUGO',
//     ],
//   },
//   KALAMANSIG: {
//     province: 'SULTAN KUDARAT',
//     municipality: 'KALAMANSIG',
//     region: 'REGION XII',
//     barangay_list: [
//       'BANTOGON (SANTA CLARA)',
//       'CADIZ',
//       'DATU ITO ANDONG',
//       'DATU WASAY',
//       'DUMANGAS NUEVO',
//       'HINALAAN',
//       'LIMULAN',
//       'NALILIDAN',
//       'OBIAL',
//       'PAG-ASA',
//       'PARIL',
//       'POBLACION',
//       'SABANAL',
//       'SANGAY',
//       'SANTA MARIA',
//     ],
//   },
//   'LAMBAYONG (MARIANO MARCOS)': {
//     province: 'SULTAN KUDARAT',
//     municipality: 'LAMBAYONG (MARIANO MARCOS)',
//     region: 'REGION XII',
//     barangay_list: [
//       'CARIDAD (CUYAPON)',
//       'DIDTARAS',
//       'GANSING (BILUMEN)',
//       'KABULAKAN',
//       'KAPINGKONG',
//       'KATITISAN',
//       'LAGAO',
//       'LILIT',
//       'MADANDING',
//       'MALIGAYA',
//       'MAMALI',
//       'MATIOMPONG',
//       'MIDTAPOK',
//       'NEW CEBU',
//       'PALUMBI',
//       'PIDTIGUIAN',
//       'PIMBALAYAN',
//       'PINGUIAMAN',
//       'POBLACION (LAMBAYONG)',
//       'SADSALAN',
//       'SENEBEN',
//       'SIGAYAN',
//       'TAMBAK',
//       'TINUMIGUES',
//       'TUMIAO (TINAGA)',
//       'UDTONG',
//     ],
//   },
//   LEBAK: {
//     province: 'SULTAN KUDARAT',
//     municipality: 'LEBAK',
//     region: 'REGION XII',
//     barangay_list: [
//       'AURELIO F. FREIRES SR. ( POBLACION II)',
//       'BARURAO',
//       'BARURAO II',
//       'BASAK',
//       'BOLEBOK',
//       'BULULAWAN',
//       'CAPILAN',
//       'CHRISTIANNUEVO',
//       'DATU KARON',
//       'KALAMONGOG',
//       'KEYTODAC',
//       'KINODALAN',
//       'NEW CALINOG',
//       'NULING',
//       'PANSUD',
//       'PASANDALAN',
//       'POBLACION',
//       'POBLACION III',
//       'POLOY-POLOY',
//       'PURIKAY',
//       'RAGANDANG',
//       'SALAMAN',
//       'SALANGSANG',
//       'TAGUISA',
//       'TIBPUAN',
//       'TRAN',
//       'VILLAMONTE',
//     ],
//   },
//   LUTAYAN: {
//     province: 'SULTAN KUDARAT',
//     municipality: 'LUTAYAN',
//     region: 'REGION XII',
//     barangay_list: [
//       'ANTONG',
//       'BAYASONG',
//       'BLINGKONG',
//       'LUTAYAN PROPER',
//       'MAINDANG',
//       'MAMALI',
//       'MANILI',
//       'PALAVILLA',
//       'SAMPAO',
//       'SISIMAN',
//       'TAMNAG (POB.)',
//     ],
//   },
//   PALIMBANG: {
//     province: 'SULTAN KUDARAT',
//     municipality: 'PALIMBANG',
//     region: 'REGION XII',
//     barangay_list: [
//       'AKOL',
//       'BADIANGON',
//       'BALIANGO',
//       'BALWAN (BULAN)',
//       'BAMBANEN',
//       'BARANAYAN',
//       'BARONGIS',
//       'BATANG-BAGLAS',
//       'BUTRIL',
//       'COLOBE (TAGADTAL)',
//       'DATU MAGUIALES',
//       'DOMOLOL',
//       'KABULING',
//       'KALIBUHAN',
//       'KANIPAAN',
//       'KIDAYAN',
//       'KIPONGET',
//       'KISEK',
//       'KRAAN',
//       'KULONG-KULONG',
//       'LANGALI',
//       'LIBUA',
//       'LIGAO',
//       'LOPOKEN (LEPOLON)',
//       'LUMITAN',
//       'MAGANAO',
//       'MAGUID',
//       'MALATUNENG (MALATUNOL)',
//       'MALISBONG',
//       'MEDOL',
//       'MILBUK',
//       'MINA',
//       'MOLON',
//       'NAMAT MASLA',
//       'NAPNAPON',
//       'POBLACION',
//       'SAN ROQUE',
//       'TIBUHOL (EAST BADIANGON)',
//       'WAL',
//       'WASAG',
//     ],
//   },
//   'PRESIDENT QUIRINO': {
//     province: 'SULTAN KUDARAT',
//     municipality: 'PRESIDENT QUIRINO',
//     region: 'REGION XII',
//     barangay_list: [
//       'BAGUMBAYAN',
//       'BANNAWAG',
//       'BAYAWA',
//       'C. MANGILALA',
//       'ESTRELLA',
//       'KALANAWE I',
//       'KALANAWE II',
//       'KATICO',
//       'MALINGON',
//       'MANGALEN',
//       'PEDTUBO',
//       'POBLACION (SAMBULAWAN)',
//       'ROMUALDEZ',
//       'SAN JOSE',
//       'SAN PEDRO (TUATO)',
//       'SINAKULAY',
//       'SUBEN',
//       'TINAUNGAN',
//       'TUAL (LIGUASAN)',
//     ],
//   },
//   'SEN. NINOY AQUINO': {
//     province: 'SULTAN KUDARAT',
//     municipality: 'SEN. NINOY AQUINO',
//     region: 'REGION XII',
//     barangay_list: [
//       'BANALI',
//       'BASAG',
//       'BUENAFLORES',
//       'BUGSO',
//       'BUKLOD',
//       'GAPOK',
//       'KADI',
//       'KAPATAGAN',
//       'KIADSAM',
//       'KUDEN',
//       'KULAMAN',
//       'LAGUBANG',
//       'LANGGAL',
//       'LIMUHAY',
//       'MALEGDEG',
//       'MIDTUNGOK',
//       'NATI',
//       'SEWOD',
//       'TACUPIS',
//       'TINALON',
//     ],
//   },
//   'TACURONG': {
//     province: 'SULTAN KUDARAT',
//     municipality: 'TACURONG',
//     region: 'REGION XII',
//     barangay_list: [
//       'BARAS',
//       'BUENAFLOR',
//       'CALEAN',
//       'CARMEN',
//       "D'LEDESMA",
//       'GANSING',
//       'KALANDAGAN',
//       'LANCHETA',
//       'LOWER KATUNGAL',
//       'NEW ISABELA',
//       'NEW LAGAO',
//       'NEW PASSI',
//       'POBLACION',
//       'RAJAH NUDA',
//       'SAN ANTONIO',
//       'SAN EMMANUEL',
//       'SAN PABLO',
//       'SAN RAFAEL',
//       'TINA',
//       'UPPER KATUNGAL',
//     ],
//   },
//   BUENAVISTA: {
//     province: 'BOHOL',
//     municipality: 'BUENAVISTA',
//     region: 'REGION VII',
//     barangay_list: [
//       'ANONANG',
//       'ASINAN',
//       'BAGO',
//       'BALUARTE',
//       'BANTUAN',
//       'BATO',
//       'BONOTBONOT',
//       'BUGAONG',
//       'CAMBUHAT',
//       'CAMBUS-OC',
//       'CANGAWA',
//       'CANTOMUGCAD',
//       'CANTORES',
//       'CANTUBA',
//       'CATIGBIAN',
//       'CAWAG',
//       'CRUZ',
//       'DAIT',
//       'EASTERN CABUL-AN',
//       'HUNAN',
//       'LAPACAN NORTE',
//       'LAPACAN SUR',
//       'LUBANG',
//       'LUSONG (PLATEAU)',
//       'MAGKAYA',
//       'MERRYLAND',
//       'NUEVA GRANADA',
//       'NUEVA MONTANA',
//       'OVERLAND',
//       'PANGHAGBAN',
//       'POBLACION',
//       'PUTING BATO',
//       'RUFO HILL',
//       'SWEETLAND',
//       'WESTERN CABUL-AN',
//     ],
//   },
//   'BUTUAN': {
//     province: 'AGUSAN DEL NORTE',
//     municipality: 'BUTUAN',
//     region: 'REGION XIII',
//     barangay_list: [
//       'AGAO POB. (BGY. 3)',
//       'AGUSAN PEQUEÑO',
//       'AMBAGO',
//       'AMPARO',
//       'AMPAYON',
//       'ANTICALA',
//       'ANTONGALON',
//       'AUPAGAN',
//       'BAAN KM 3',
//       'BAAN RIVERSIDE POB. (BGY. 20)',
//       'BABAG',
//       'BADING POB. (BGY. 22)',
//       'BANCASI',
//       'BANZA',
//       'BAOBAOAN',
//       'BASAG',
//       'BAYANIHAN POB. (BGY. 27)',
//       'BILAY',
//       'BIT-OS',
//       'BITAN-AGAN',
//       'BOBON',
//       'BONBON',
//       'BUGABUS',
//       'BUGSUKAN',
//       'BUHANGIN POB. (BGY. 19)',
//       'CABCABON',
//       'CAMAYAHAN',
//       'DAGOHOY POB. (BGY. 7)',
//       'DANKIAS',
//       'DE ORO',
//       'DIEGO SILANG POB. (BGY. 6)',
//       'DON FRANCISCO',
//       'DOONGAN',
//       'DULAG',
//       'DUMALAGAN',
//       'FLORIDA',
//       'GOLDEN RIBBON POB. (BGY. 2)',
//       'HOLY REDEEMER POB. (BGY. 23)',
//       'HUMABON POB. (BGY. 11)',
//       'IMADEJAS POB. (BGY. 24)',
//       'JOSE RIZAL POB. (BGY. 25)',
//       'KINAMLUTAN',
//       'LAPU-LAPU POB. (BGY. 8)',
//       'LEMON',
//       'LEON KILAT POB. (BGY. 13)',
//       'LIBERTAD',
//       'LIMAHA POB. (BGY. 14)',
//       'LOS ANGELES',
//       'LUMBOCAN',
//       'MAGUINDA',
//       'MAHAY',
//       'MAHOGANY POB. (BGY. 21)',
//       'MAIBU',
//       'MANDAMO',
//       'MANILA DE BUGABUS',
//       'MAON POB. (BGY. 1)',
//       'MASAO',
//       'MAUG',
//       'NEW SOCIETY VILLAGE POB. (BGY. 26)',
//       'NONG-NONG',
//       'OBRERO POB. (BGY. 18)',
//       'ONG YIU POB. (BGY. 16)',
//       'PAGATPATAN',
//       'PANGABUGAN',
//       'PIANING',
//       'PIGDAULAN',
//       'PINAMANCULAN',
//       'PORT POYOHON POB. (BGY. 17 - NEW ASIA)',
//       'RAJAH SOLIMAN POB. (BGY. 4)',
//       'SALVACION',
//       'SAN IGNACIO POB. (BGY. 15)',
//       'SAN MATEO',
//       'SAN VICENTE',
//       'SANTO NIÑO',
//       'SIKATUNA POB. (BGY. 10)',
//       'SILONGAN POB. (BGY. 5)',
//       'SUMILE',
//       'SUMILIHON',
//       'TAGABACA',
//       'TAGUIBO',
//       'TALIGAMAN',
//       'TANDANG SORA POB. (BGY. 12)',
//       'TINIWISAN',
//       'TUNGAO',
//       'URDUJA POB. (BGY. 9)',
//       'VILLA KANANGA',
//     ],
//   },
//   'CABADBARAN': {
//     province: 'AGUSAN DEL NORTE',
//     municipality: 'CABADBARAN',
//     region: 'REGION XIII',
//     barangay_list: [
//       'ANTONIO LUNA',
//       'BAY-ANG',
//       'BAYABAS',
//       'CAASINAN',
//       'CABINET',
//       'CALAMBA',
//       'CALIBUNAN',
//       'COMAGASCAS',
//       'CONCEPCION',
//       'DEL PILAR',
//       'KATUGASAN',
//       'KAUSWAGAN',
//       'LA UNION',
//       'MABINI',
//       'MAHABA',
//       'POBLACION 1',
//       'POBLACION 2',
//       'POBLACION 3',
//       'POBLACION 4',
//       'POBLACION 5',
//       'POBLACION 6',
//       'POBLACION 7',
//       'POBLACION 8',
//       'POBLACION 9',
//       'POBLACION 10',
//       'POBLACION 11',
//       'POBLACION 12',
//       'PUTING BATO',
//       'SANGHAN',
//       'SORIANO',
//       'TOLOSA',
//     ],
//   },
//   JABONGA: {
//     province: 'AGUSAN DEL NORTE',
//     municipality: 'JABONGA',
//     region: 'REGION XIII',
//     barangay_list: [
//       'A. BELTRAN (CAMALIG)',
//       'BALEGUIAN',
//       'BANGONAY',
//       'BUNGA',
//       'COLORADO',
//       'CUYAGO',
//       'LIBAS',
//       'MAGDAGOOC',
//       'MAGSAYSAY',
//       'MARAIGING',
//       'POBLACION (JABONGA)',
//       'SAN JOSE',
//       'SAN PABLO',
//       'SAN VICENTE',
//       'SANTO NIÑO',
//     ],
//   },
//   KITCHARAO: {
//     province: 'AGUSAN DEL NORTE',
//     municipality: 'KITCHARAO',
//     region: 'REGION XIII',
//     barangay_list: [
//       'BANGAYAN',
//       'CANAWAY',
//       'CROSSING',
//       'HINIMBANGAN',
//       'JALIOBONG',
//       'MAHAYAHAY',
//       'POBLACION',
//       'SAN ISIDRO',
//       'SAN ROQUE',
//       'SANGAY',
//       'SONGKOY',
//     ],
//   },
//   'LAS NIEVES': {
//     province: 'AGUSAN DEL NORTE',
//     municipality: 'LAS NIEVES',
//     region: 'REGION XIII',
//     barangay_list: [
//       'AMBACON',
//       'BALUNGAGAN',
//       'BONIFACIO',
//       'CASIKLAN',
//       'CONSORCIA',
//       'DURIAN',
//       'EDUARDO G. MONTILLA (CAMBOAYON)',
//       'IBUAN',
//       'KATIPUNAN',
//       'LINGAYAO',
//       'MALICATO',
//       'MANINGALAO',
//       'MARCOS CALO',
//       'MAT-I',
//       'PINANA-AN',
//       'POBLACION',
//       'ROSARIO',
//       'SAN ISIDRO',
//       'SAN ROQUE',
//       'TINUCORAN',
//     ],
//   },
//   MAGALLANES: {
//     province: 'SORSOGON',
//     municipality: 'MAGALLANES',
//     region: 'REGION V',
//     barangay_list: [
//       'AGUADA NORTE',
//       'AGUADA SUR',
//       'ANIBONG',
//       'BACALON',
//       'BACOLOD',
//       'BANACUD',
//       'BEHIA',
//       'BIGA',
//       'BINISITAHAN DEL NORTE',
//       'BINISITAHAN DEL SUR',
//       'BITON',
//       'BULALA',
//       'BUSAY',
//       'CADITAAN',
//       'CAGBOLO',
//       'CAGTALABA',
//       'CAWIT EXTENSION',
//       'CAWIT PROPER',
//       'GINANGRA',
//       'HUBO',
//       'INCARIZAN',
//       'LAPINIG',
//       'MAGSAYSAY',
//       'MALBOG',
//       'PANTALAN',
//       'PAWIK',
//       'PILI',
//       'POBLACION',
//       'SALVACION',
//       'SANTA ELENA',
//       'SIUTON',
//       'TAGAS',
//       'TULATULA NORTE',
//       'TULATULA SUR',
//     ],
//   },
//   NASIPIT: {
//     province: 'AGUSAN DEL NORTE',
//     municipality: 'NASIPIT',
//     region: 'REGION XIII',
//     barangay_list: [
//       'ACLAN',
//       'AMONTAY',
//       'ATA-ATAHON',
//       'BARANGAY 1 (POB.)',
//       'BARANGAY 2 (POB.)',
//       'BARANGAY 3 (POB.)',
//       'BARANGAY 4 (POB.)',
//       'BARANGAY 5 (POB.)',
//       'BARANGAY 6 (POB.)',
//       'BARANGAY 7 (POB.)',
//       'CAMAGONG',
//       'CUBI-CUBI',
//       'CULIT',
//       'JAGUIMITAN',
//       'KINABJANGAN',
//       'PUNTA',
//       'SANTA ANA',
//       'TALISAY',
//       'TRIANGULO',
//     ],
//   },
//   'REMEDIOS T. ROMUALDEZ': {
//     province: 'AGUSAN DEL NORTE',
//     municipality: 'REMEDIOS T. ROMUALDEZ',
//     region: 'REGION XIII',
//     barangay_list: [
//       'BALANGBALANG',
//       'BASILISA',
//       'HUMILOG',
//       'PANAYTAYON',
//       'POBLACION I (AGAY)',
//       'POBLACION II',
//       'SAN ANTONIO',
//       'TAGBONGABONG',
//     ],
//   },
//   SANTIAGO: {
//     province: 'ILOCOS SUR',
//     municipality: 'SANTIAGO',
//     region: 'REGION I',
//     barangay_list: [
//       'AL-ALUDIG',
//       'AMBUCAO',
//       'BAYBAYABAS',
//       'BIGBIGA',
//       'BULBULALA',
//       'BUSEL-BUSEL',
//       'BUTOL',
//       'CABURAO',
//       'DAN-AR',
//       'GABAO',
//       'GUINABANG',
//       'IMUS',
//       'LANG-AYAN',
//       'MAMBUG',
//       'NALASIN',
//       'OLO-OLO NORTE',
//       'OLO-OLO SUR',
//       'POBLACION NORTE',
//       'POBLACION SUR',
//       'SABANGAN',
//       'SALINCUB',
//       'SAN JOSE (BARAOAS)',
//       'SAN ROQUE',
//       'UBBOG',
//     ],
//   },
//   TUBAY: {
//     province: 'AGUSAN DEL NORTE',
//     municipality: 'TUBAY',
//     region: 'REGION XIII',
//     barangay_list: [
//       'BINUANGAN',
//       'CABAYAWA',
//       'DOÑA ROSARIO',
//       'DOÑA TELESFORA',
//       'LA FRATERNIDAD',
//       'LAWIGAN',
//       'POBLACION 1',
//       'POBLACION 2',
//       'SANTA ANA',
//       'TAGMAMARKAY',
//       'TAGPANGAHOY',
//       'TINIGBASAN',
//       'VICTORY',
//     ],
//   },
//   'BAYUGAN': {
//     province: 'AGUSAN DEL SUR',
//     municipality: 'BAYUGAN',
//     region: 'REGION XIII',
//     barangay_list: [
//       'BERSEBA',
//       'BUCAC',
//       'CAGBAS',
//       'CALAITAN',
//       'CANAYUGAN',
//       'CHARITO',
//       'CLARO CORTEZ',
//       'FILI',
//       'GAMAO',
//       'GETSEMANE',
//       'GRACE ESTATE',
//       'HAMOGAWAY',
//       'KATIPUNAN',
//       'MABUHAY',
//       'MAGKIANGKANG',
//       'MAHAYAG',
//       'MARCELINA',
//       'MAYGATASAN',
//       'MONTIVESTA',
//       'MT. ARARAT',
//       'MT. CARMEL',
//       'MT. OLIVE',
//       'NEW SALEM',
//       'NOLI',
//       'OSMEÑA',
//       'PANAYTAY',
//       'PINAGALAAN',
//       'POBLACION',
//       'SAGMONE',
//       'SAGUMA',
//       'SALVACION',
//       'SAN AGUSTIN',
//       'SAN ISIDRO',
//       'SAN JUAN',
//       'SANTA IRENE',
//       'SANTA TERESITA',
//       'SANTO NIÑO',
//       'TAGLATAWAN',
//       'TAGLIBAS',
//       'TAGUBAY',
//       'VERDU',
//       'VILLA UNDAYON',
//       'WAWA',
//     ],
//   },
//   BUNAWAN: {
//     province: 'AGUSAN DEL SUR',
//     municipality: 'BUNAWAN',
//     region: 'REGION XIII',
//     barangay_list: [
//       'BUNAWAN BROOK',
//       'CONSUELO',
//       'IMELDA',
//       'LIBERTAD',
//       'MAMBALILI',
//       'NUEVA ERA',
//       'POBLACION',
//       'SAN ANDRES',
//       'SAN MARCOS',
//       'SAN TEODORO',
//     ],
//   },
//   'LA PAZ': {
//     province: 'ABRA',
//     municipality: 'LA PAZ',
//     region: 'CAR',
//     barangay_list: [
//       'BENBEN (BONBON)',
//       'BULBULALA',
//       'BULI',
//       'CANAN (GAPAN)',
//       'LIGUIS',
//       'MALABBAGA',
//       'MUDENG',
//       'PIDIPID',
//       'POBLACION',
//       'SAN GREGORIO',
//       'TOON',
//       'UDANGAN',
//     ],
//   },
//   LORETO: {
//     province: 'DINAGAT ISLANDS',
//     municipality: 'LORETO',
//     region: 'REGION XIII',
//     barangay_list: [
//       'CARMEN (POB.)',
//       'ESPERANZA',
//       'FERDINAND',
//       'HELENE',
//       'LIBERTY',
//       'MAGSAYSAY',
//       'PANAMAON',
//       'SAN JUAN (POB.)',
//       'SANTA CRUZ (POB.)',
//       'SANTIAGO (POB.)',
//     ],
//   },
//   PROSPERIDAD: {
//     province: 'AGUSAN DEL SUR',
//     municipality: 'PROSPERIDAD',
//     region: 'REGION XIII',
//     barangay_list: [
//       'AURORA',
//       'AWA',
//       'AZPETIA',
//       'LA CARIDAD',
//       'LA PERIAN',
//       'LA PURISIMA',
//       'LA SUERTE',
//       'LA UNION',
//       'LAS NAVAS',
//       'LIBERTAD',
//       'LOS ARCOS',
//       'LUCENA',
//       'MABUHAY',
//       'MAGSAYSAY',
//       'MAPAGA',
//       'NAPO',
//       'NEW MAUG',
//       'PATIN-AY',
//       'POBLACION (BAHBAH)',
//       'SALIMBOGAON',
//       'SALVACION',
//       'SAN JOAQUIN',
//       'SAN JOSE',
//       'SAN LORENZO',
//       'SAN MARTIN',
//       'SAN PEDRO',
//       'SAN RAFAEL',
//       'SAN ROQUE',
//       'SAN SALVADOR',
//       'SAN VICENTE',
//       'SANTA IRENE',
//       'SANTA MARIA',
//     ],
//   },
//   ROSARIO: {
//     province: 'NORTHERN SAMAR',
//     municipality: 'ROSARIO',
//     region: 'REGION VIII',
//     barangay_list: [
//       'AGUADA',
//       'BANTOLINAO',
//       'BUENAVISTA',
//       'COMMONWEALTH',
//       'GUINDAULAN',
//       'JAMOOG',
//       'KAILINGAN',
//       'LIGAYA',
//       'POBLACION',
//       'SALHAG',
//       'SAN LORENZO',
//     ],
//   },
//   'SAN FRANCISCO': {
//     province: 'SOUTHERN LEYTE',
//     municipality: 'SAN FRANCISCO',
//     region: 'REGION VIII',
//     barangay_list: [
//       'ANISLAGON',
//       'BONGAWISAN',
//       'BONGBONG',
//       'CAHAYAG',
//       'CAUSI',
//       'CENTRAL (POB.)',
//       'DAKIT (POB.)',
//       'GABI',
//       'HABAY',
//       'MALICO',
//       'MARAYAG',
//       'NAPANTAO',
//       'PASANON',
//       'PINAMUDLAN',
//       'PUNTA',
//       'SANTA CRUZ',
//       'SANTA PAZ NORTE',
//       'SANTA PAZ SUR',
//       'SUDMON',
//       'TINAAN',
//       'TUNO',
//       'UBOS (POB.)',
//     ],
//   },
//   'SAN LUIS': {
//     province: 'BATANGAS',
//     municipality: 'SAN LUIS',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'ABIACAO',
//       'BAGONG TUBIG',
//       'BALAGTASIN',
//       'BALITE',
//       'BANOYO',
//       'BOBOY',
//       'BONLIW',
//       'CALUMPANG EAST',
//       'CALUMPANG WEST',
//       'DULANGAN',
//       'DURUNGAO',
//       'LOCLOC',
//       'LUYA',
//       'MAHABANG PARANG',
//       'MANGGAHAN',
//       'MUZON',
//       'POBLACION',
//       'SAN ANTONIO',
//       'SAN ISIDRO',
//       'SAN JOSE',
//       'SAN MARTIN',
//       'SANTA MONICA',
//       'TALIBA',
//       'TALON',
//       'TEJERO',
//       'TUNGAL',
//     ],
//   },
//   'SANTA JOSEFA': {
//     province: 'AGUSAN DEL SUR',
//     municipality: 'SANTA JOSEFA',
//     region: 'REGION XIII',
//     barangay_list: [
//       'ANGAS',
//       'AURORA',
//       'AWAO',
//       'CONCEPCION',
//       'PAG-ASA',
//       'PATROCINIO',
//       'POBLACION',
//       'SAN JOSE',
//       'SANTA ISABEL',
//       'SAYON',
//       'TAPAZ',
//     ],
//   },
//   SIBAGAT: {
//     province: 'AGUSAN DEL SUR',
//     municipality: 'SIBAGAT',
//     region: 'REGION XIII',
//     barangay_list: [
//       'AFGA',
//       'ANAHAWAN',
//       'BANAGBANAG',
//       'DEL ROSARIO',
//       'EL RIO',
//       'ILIHAN',
//       'KAUSWAGAN',
//       'KIOYA',
//       'KOLAMBUGAN',
//       'MAGKALAPE',
//       'MAGSAYSAY',
//       'MAHAYAHAY',
//       'NEW TUBIGON',
//       'PADIAY',
//       'PEREZ',
//       'POBLACION',
//       'SAN ISIDRO',
//       'SAN VICENTE',
//       'SANTA CRUZ',
//       'SANTA MARIA',
//       'SINAI',
//       'TABON-TABON',
//       'TAG-UYANGO',
//       'VILLANGIT',
//     ],
//   },
//   TALACOGON: {
//     province: 'AGUSAN DEL SUR',
//     municipality: 'TALACOGON',
//     region: 'REGION XIII',
//     barangay_list: [
//       'BATUCAN',
//       'BUENAGRACIA',
//       'CAUSWAGAN',
//       'CULI',
//       'DEL MONTE',
//       'DESAMPARADOS',
//       'LA FLORA',
//       'LABNIG',
//       'MAHARLIKA',
//       'MARBON',
//       'SABANG GIBUNG',
//       'SAN AGUSTIN (POB.)',
//       'SAN ISIDRO (POB.)',
//       'SAN NICOLAS (POB.)',
//       'ZAMORA',
//       'ZILLOVIA',
//     ],
//   },
//   TRENTO: {
//     province: 'AGUSAN DEL SUR',
//     municipality: 'TRENTO',
//     region: 'REGION XIII',
//     barangay_list: [
//       'BASA',
//       'CEBOLIN',
//       'CUEVAS',
//       'KAPATUNGAN',
//       'LANGKILA-AN',
//       'MANAT',
//       'NEW VISAYAS',
//       'PANGYAN',
//       'POBLACION',
//       'PULANG-LUPA',
//       'SALVACION',
//       'SAN IGNACIO',
//       'SAN ISIDRO',
//       'SAN ROQUE',
//       'SANTA MARIA',
//       'TUDELA',
//     ],
//   },
//   VERUELA: {
//     province: 'AGUSAN DEL SUR',
//     municipality: 'VERUELA',
//     region: 'REGION XIII',
//     barangay_list: [
//       'ANITAP',
//       'BACAY II',
//       'BINONGAN',
//       'CAIGANGAN',
//       'CANDIIS',
//       'DEL MONTE',
//       'DON MATEO',
//       'KATIPUNAN',
//       'LA FORTUNA',
//       'LIMOT',
//       'MAGSAYSAY',
//       'MASAYAN',
//       'POBLACION',
//       'SAMPAGUITA',
//       'SAN GABRIEL',
//       'SANTA CRUZ',
//       'SANTA EMELIA',
//       'SAWAGAN',
//       'SINOBONG',
//       'SISIMON',
//     ],
//   },
//   'BASILISA (RIZAL)': {
//     province: 'DINAGAT ISLANDS',
//     municipality: 'BASILISA (RIZAL)',
//     region: 'REGION XIII',
//     barangay_list: [
//       'BENGLEN',
//       'CATADMAN',
//       'COLUMBUS',
//       'CORING',
//       'CORTES',
//       'DIEGAS',
//       'DOÑA HELENE',
//       'EDERA',
//       'FERDINAND',
//       'GEOTINA',
//       'IMEE (BACTASAN)',
//       'MELGAR',
//       'MONTAG',
//       'NAVARRO',
//       'NEW NAZARETH',
//       'POBLACION',
//       'PUERTO PRINCESA',
//       'RITA GLENDA',
//       'ROMA',
//       'ROXAS',
//       'SANTA MONICA',
//       'SANTO NIÑO',
//       'SERING',
//       'SOMBRADO',
//       'TAG-ABACA',
//       'VILLA ECLEO',
//       'VILLA PANTINOPLE',
//     ],
//   },
//   CAGDIANAO: {
//     province: 'DINAGAT ISLANDS',
//     municipality: 'CAGDIANAO',
//     region: 'REGION XIII',
//     barangay_list: [
//       'BOA',
//       'CABUNGA-AN',
//       'DEL PILAR',
//       'LAGUNA',
//       'LEGASPI',
//       'MA-ATAS',
//       'MABINI (BORJA)',
//       'NUEVA ESTRELLA',
//       'POBLACION',
//       'R. ECLEO, SR.',
//       'SAN JOSE',
//       'SANTA RITA',
//       'TIGBAO',
//       'VALENCIA',
//     ],
//   },
//   DINAGAT: {
//     province: 'DINAGAT ISLANDS',
//     municipality: 'DINAGAT',
//     region: 'REGION XIII',
//     barangay_list: [
//       'BAGUMBAYAN',
//       'CAB-ILAN',
//       'CABAYAWAN',
//       'CAYETANO',
//       'ESCOLTA (POB.)',
//       'GOMEZ',
//       'JUSTINIANA EDERA',
//       'MAGSAYSAY',
//       'MAUSWAGON (POB.)',
//       'NEW MABUHAY',
//       'WADAS',
//       'WHITE BEACH (POB.)',
//     ],
//   },
//   'LIBJO (ALBOR)': {
//     province: 'DINAGAT ISLANDS',
//     municipality: 'LIBJO (ALBOR)',
//     region: 'REGION XIII',
//     barangay_list: [
//       'ALBOR (POB.)',
//       'ARELLANO',
//       'BAYANIHAN',
//       'DOÑA HELEN',
//       'GARCIA',
//       'GENERAL AGUINALDO (BOLOD-BOLOD)',
//       'KANIHAAN',
//       'LLAMERA',
//       'MAGSAYSAY',
//       'OSMEÑA',
//       'PLARIDEL',
//       'QUEZON',
//       'ROSITA',
//       'SAN ANTONIO (POB.)',
//       'SAN JOSE',
//       'SANTO NIÑO',
//     ],
//   },
//   'SAN JOSE': {
//     province: 'NORTHERN SAMAR',
//     municipality: 'SAN JOSE',
//     region: 'REGION VIII',
//     barangay_list: [
//       'AGUADAHAN',
//       'BAGONG SABANG',
//       'BALITE',
//       'BARANGAY EAST (POB.)',
//       'BARANGAY NORTH (POB.)',
//       'BARANGAY SOUTH (POB.)',
//       'BARANGAY WEST (POB.)',
//       'BONGLAS',
//       'DA-O',
//       'GENGAROG',
//       'GERATAG',
//       'LAYUHAN',
//       'MANDUGANG',
//       'P. TINGZON',
//       'SAN LORENZO',
//       'TUBIGDANAO',
//     ],
//   },
//   TUBAJON: {
//     province: 'DINAGAT ISLANDS',
//     municipality: 'TUBAJON',
//     region: 'REGION XIII',
//     barangay_list: [
//       'DIAZ (ROMUALDEZ)',
//       'IMELDA',
//       'MABINI',
//       'MALINAO',
//       'NAVARRO',
//       'ROXAS',
//       'SAN ROQUE (POB.)',
//       'SAN VICENTE (POB.)',
//       'SANTA CRUZ (POB.)',
//     ],
//   },
//   ALEGRIA: {
//     province: 'CEBU',
//     municipality: 'ALEGRIA',
//     region: 'REGION VII',
//     barangay_list: [
//       'COMPOSTELA',
//       'GUADALUPE',
//       'LEGASPI',
//       'LEPANTO',
//       'MADRIDEJOS',
//       'MONTPELLER',
//       'POBLACION',
//       'SANTA FILOMENA',
//       'VALENCIA',
//     ],
//   },
//   BACUAG: {
//     province: 'SURIGAO DEL NORTE',
//     municipality: 'BACUAG',
//     region: 'REGION XIII',
//     barangay_list: [
//       'CABUGAO',
//       'CAMBUAYON',
//       'CAMPO',
//       'DUGSANGON',
//       'PAUTAO',
//       'PAYAPAG',
//       'POBLACION',
//       'PUNGTOD',
//       'SANTO ROSARIO',
//     ],
//   },
//   BURGOS: {
//     province: 'ISABELA',
//     municipality: 'BURGOS',
//     region: 'REGION II',
//     barangay_list: [
//       'BACNOR EAST',
//       'BACNOR WEST',
//       'CALIGUIAN (POB.)',
//       'CATABBAN',
//       'CULLALABO DEL NORTE',
//       'CULLALABO DEL SUR',
//       'CULLALABO SAN ANTONIO',
//       'DALIG',
//       'MALASIN',
//       'MASIGUN',
//       'RANIAG',
//       'SAN BONIFACIO',
//       'SAN MIGUEL',
//       'SAN ROQUE',
//     ],
//   },
//   CLAVER: {
//     province: 'SURIGAO DEL NORTE',
//     municipality: 'CLAVER',
//     region: 'REGION XIII',
//     barangay_list: [
//       'BAGAKAY (POB. WEST)',
//       'CABUGO',
//       'CAGDIANAO',
//       'DAYWAN',
//       'HAYANGGABON',
//       'LADGARON (POB.)',
//       'LAPINIGAN',
//       'MAGALLANES',
//       'PANATAO',
//       'SAPA',
//       'TAGANITO',
//       'TAYAGA (POB. EAST)',
//       'URBIZTONDO',
//       'WANGKE',
//     ],
//   },
//   DAPA: {
//     province: 'SURIGAO DEL NORTE',
//     municipality: 'DAPA',
//     region: 'REGION XIII',
//     barangay_list: [
//       'BAGAKAY',
//       'BARANGAY 1 (POB.)',
//       'BARANGAY 2 (POB.)',
//       'BARANGAY 3 (POB.)',
//       'BARANGAY 4 (POB.)',
//       'BARANGAY 5 (POB.)',
//       'BARANGAY 6 (POB.)',
//       'BARANGAY 7 (POB.)',
//       'BARANGAY 8 (POB.)',
//       'BARANGAY 9 (POB.)',
//       'BARANGAY 10 (POB.)',
//       'BARANGAY 11 (POB.)',
//       'BARANGAY 12 (POB.)',
//       'BARANGAY 13 (POB.)',
//       'BUENAVISTA',
//       'CABAWA',
//       'CAMBAS-AC',
//       'CONSOLACION',
//       'CORREGIDOR',
//       'DAGOHOY',
//       'DON PAULINO',
//       'JUBANG',
//       'MONTSERRAT',
//       'OSMEÑA',
//       'SAN CARLOS',
//       'SAN MIGUEL',
//       'SANTA FE',
//       'SANTA FELOMINA',
//       'UNION',
//     ],
//   },
//   'DEL CARMEN': {
//     province: 'SURIGAO DEL NORTE',
//     municipality: 'DEL CARMEN',
//     region: 'REGION XIII',
//     barangay_list: [
//       'ANTIPOLO',
//       'BAGAKAY (ALBURO)',
//       'BITOON',
//       'CABUGAO',
//       'CANCOHOY',
//       'CAUB',
//       'DEL CARMEN (POB.)',
//       'DOMOYOG',
//       'ESPERANZA',
//       'HALIAN',
//       'JAMOYAON',
//       'KATIPUNAN',
//       'LOBOGON',
//       'MABUHAY',
//       'MAHAYAHAY',
//       'QUEZON',
//       'SAN FERNANDO',
//       'SAN JOSE (POB.)',
//       'SAYAK',
//       'TUBORAN',
//     ],
//   },
//   'GENERAL LUNA': {
//     province: 'QUEZON',
//     municipality: 'GENERAL LUNA',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'BACONG IBABA',
//       'BACONG ILAYA',
//       'BARANGAY 1 (POB.)',
//       'BARANGAY 2 (POB.)',
//       'BARANGAY 3 (POB.)',
//       'BARANGAY 4 (POB.)',
//       'BARANGAY 5 (POB.)',
//       'BARANGAY 6 (POB.)',
//       'BARANGAY 7 (POB.)',
//       'BARANGAY 8 (POB.)',
//       'BARANGAY 9 (POB.)',
//       'LAVIDES',
//       'MAGSAYSAY',
//       'MALAYA',
//       'NIEVA',
//       'RECTO',
//       'SAN IGNACIO IBABA',
//       'SAN IGNACIO ILAYA',
//       'SAN ISIDRO IBABA',
//       'SAN ISIDRO ILAYA',
//       'SAN JOSE',
//       'SAN NICOLAS',
//       'SAN VICENTE',
//       'SANTA MARIA IBABA',
//       'SANTA MARIA ILAYA',
//       'SUMILANG',
//       'VILLARICA',
//     ],
//   },
//   GIGAQUIT: {
//     province: 'SURIGAO DEL NORTE',
//     municipality: 'GIGAQUIT',
//     region: 'REGION XIII',
//     barangay_list: [
//       'ALAMBIQUE (POB.)',
//       'ANIBONGAN',
//       'CAM-BOAYON',
//       'CAMAM-ONAN',
//       'IPIL (POB.)',
//       'LAHI',
//       'MAHANUB',
//       'PONIENTE',
//       'SAN ANTONIO (BONOT)',
//       'SAN ISIDRO',
//       'SICO-SICO',
//       'VILLAFLOR',
//       'VILLAFRANCA',
//     ],
//   },
//   MAINIT: {
//     province: 'SURIGAO DEL NORTE',
//     municipality: 'MAINIT',
//     region: 'REGION XIII',
//     barangay_list: [
//       'BINGA',
//       'BOBONA-ON',
//       'CANTUGAS',
//       'DAYANO',
//       'MABINI',
//       'MAGPAYANG',
//       'MAGSAYSAY (POB.)',
//       'MANSAYAO',
//       'MARAYAG',
//       'MATIN-AO',
//       'PACO',
//       'QUEZON (POB.)',
//       'ROXAS',
//       'SAN FRANCISCO',
//       'SAN ISIDRO',
//       'SAN JOSE',
//       'SIANA',
//       'SILOP',
//       'TAGBUYAWAN',
//       'TAPI-AN',
//       'TOLINGON',
//     ],
//   },
//   MALIMONO: {
//     province: 'SURIGAO DEL NORTE',
//     municipality: 'MALIMONO',
//     region: 'REGION XIII',
//     barangay_list: [
//       'BUNYASAN',
//       'CAGTINAE',
//       'CAN-AGA',
//       'CANSAYONG',
//       'CANTAPOY',
//       'CAYAWAN',
//       'DORO (BINOCARAN)',
//       'HANAGDONG',
//       'KARIHATAG',
//       'MASGAD',
//       'PILI',
//       'SAN ISIDRO (POB.)',
//       'TINAGO',
//       'VILLARIZA',
//     ],
//   },
//   PILAR: {
//     province: 'ABRA',
//     municipality: 'PILAR',
//     region: 'CAR',
//     barangay_list: [
//       'BOLBOLO',
//       'BROOKSIDE',
//       'DALIT',
//       'DINTAN',
//       'GAPANG',
//       'KINABITI',
//       'MALIPLIPIT',
//       'NAGCANASAN',
//       'NANANGDUAN',
//       'NARNARA',
//       'OCUP',
//       'PANG-OT',
//       'PATAD',
//       'POBLACION',
//       'SAN JUAN EAST',
//       'SAN JUAN WEST',
//       'SOUTH BALIOAG',
//       'TIKITIK',
//       'VILLAVIEJA',
//     ],
//   },
//   PLACER: {
//     province: 'MASBATE',
//     municipality: 'PLACER',
//     region: 'REGION V',
//     barangay_list: [
//       'AGUADA',
//       'BAN-AO',
//       'BURABOD',
//       'CABANGCALAN',
//       'CALUMPANG',
//       'CAMAYABSAN',
//       'DAANLUNGSOD',
//       'DANGPANAN',
//       'DARAGA',
//       'GUIN-AWAYAN',
//       'GUINHAN-AYAN',
//       'KATIPUNAN',
//       'LIBAS',
//       'LOCSO-AN',
//       'LUNA',
//       'MAHAYAG',
//       'MAHAYAHAY',
//       'MANLUT-OD',
//       'MATAGANTANG',
//       'NABOCTOT',
//       'NAGARAO',
//       'NAINDAY',
//       'NAOCONDIOT',
//       'PASIAGON',
//       'PILI',
//       'POBLACION',
//       'PURO',
//       'QUIBRADA',
//       'SAN MARCOS',
//       'SANTA CRUZ',
//       'TABOC',
//       'TAN-AWAN',
//       'TAVERNA',
//       'TUBOD',
//       'VILLA INOCENCIO',
//     ],
//   },
//   'SAN BENITO': {
//     province: 'SURIGAO DEL NORTE',
//     municipality: 'SAN BENITO',
//     region: 'REGION XIII',
//     barangay_list: [
//       'BONGDO',
//       'MARIBOJOC',
//       'NUEVO CAMPO',
//       'SAN JUAN',
//       'SANTA CRUZ (POB.)',
//       'TALISAY (POB.)',
//     ],
//   },
//   'SAN FRANCISCO (ANAO-AON)': {
//     province: 'SURIGAO DEL NORTE',
//     municipality: 'SAN FRANCISCO (ANAO-AON)',
//     region: 'REGION XIII',
//     barangay_list: [
//       'AMONTAY',
//       'BALITE',
//       'BANBANON',
//       'DIAZ',
//       'HONRADO',
//       'JUBGAN',
//       'LINONGGANAN',
//       'MACOPA',
//       'MAGTANGALE',
//       'OSLAO',
//       'POBLACION',
//     ],
//   },
//   'SANTA MONICA (SAPAO)': {
//     province: 'SURIGAO DEL NORTE',
//     municipality: 'SANTA MONICA (SAPAO)',
//     region: 'REGION XIII',
//     barangay_list: [
//       'ABAD SANTOS',
//       'ALEGRIA',
//       'BAILAN',
//       'GARCIA',
//       'LIBERTAD',
//       'MABINI',
//       'MABUHAY (POB.)',
//       'MAGSAYSAY',
//       'RIZAL',
//       'T. ARLAN (POB.)',
//       'TANGBO',
//     ],
//   },
//   SISON: {
//     province: 'PANGASINAN',
//     municipality: 'SISON',
//     region: 'REGION I',
//     barangay_list: [
//       'AGAT',
//       'ALIBENG',
//       'AMAGBAGAN',
//       'ARTACHO',
//       'ASAN NORTE',
//       'ASAN SUR',
//       'BANTAY INSIK',
//       'BILA',
//       'BINMECKEG',
//       'BULAOEN EAST',
//       'BULAOEN WEST',
//       'CABARITAN',
//       'CALUNETAN',
//       'CAMANGAAN',
//       'CAURINGAN',
//       'DUNGON',
//       'ESPERANZA',
//       'INMALOG',
//       'KILLO',
//       'LABAYUG',
//       'PALDIT',
//       'PINDANGAN',
//       'PINMILAPIL',
//       'POBLACION CENTRAL',
//       'POBLACION NORTE',
//       'POBLACION SUR',
//       'SAGUNTO',
//       'TARA-TARA',
//     ],
//   },
//   SOCORRO: {
//     province: 'ORIENTAL MINDORO',
//     municipality: 'SOCORRO',
//     region: 'REGION IV-B',
//     barangay_list: [
//       'BAGSOK',
//       'BATONG DALIG',
//       'BAYUIN',
//       'BUGTONG NA TUOG',
//       'CALOCMOY',
//       'CALUBAYAN',
//       'CATININGAN',
//       'FORTUNA',
//       'HAPPY VALLEY',
//       'LEUTEBORO I',
//       'LEUTEBORO II',
//       'MA. CONCEPCION',
//       'MABUHAY I',
//       'MABUHAY II',
//       'MALUGAY',
//       'MATUNGAO',
//       'MONTEVERDE',
//       'PASI I',
//       'PASI II',
//       'SANTO DOMINGO (LAPOG)',
//       'SUBAAN',
//       'VILLAREAL (DAAN)',
//       'ZONE I (POB.)',
//       'ZONE II (POB.)',
//       'ZONE III (POB.)',
//       'ZONE IV (POB.)',
//     ],
//   },
//   'SURIGAO': {
//     province: 'SURIGAO DEL NORTE',
//     municipality: 'SURIGAO',
//     region: 'REGION XIII',
//     barangay_list: [
//       'ALANG-ALANG',
//       'ALEGRIA',
//       'ANOMAR',
//       'AURORA',
//       'BALIBAYON',
//       'BAYBAY',
//       'BILABID',
//       'BITAUGAN',
//       'BONIFACIO',
//       'BUENAVISTA',
//       'CABONGBONGAN',
//       'CAGNIOG',
//       'CAGUTSAN',
//       'CANLANIPA',
//       'CANTIASAY',
//       'CAPALAYAN',
//       'CATADMAN',
//       'DANAO',
//       'DANAWAN',
//       'DAY-ASAN',
//       'IPIL',
//       'LIBUAC',
//       'LIPATA',
//       'LISONDRA',
//       'LUNA',
//       'MABINI',
//       'MABUA',
//       'MANYAGAO',
//       'MAPAWA',
//       'MAT-I',
//       'NABAGO',
//       'NONOC',
//       'OROK',
//       'POCTOY',
//       'PUNTA BILAR',
//       'QUEZON',
//       'RIZAL',
//       'SABANG',
//       'SAN ISIDRO',
//       'SAN JOSE',
//       'SAN JUAN',
//       'SAN PEDRO (HANIGAD)',
//       'SAN ROQUE',
//       'SERNA (BAD-ASAY)',
//       'SIDLAKAN',
//       'SILOP',
//       'SUGBAY',
//       'SUKAILANG',
//       'TAFT (POB.)',
//       'TALISAY',
//       'TOGBONGON',
//       'TRINIDAD',
//       'WASHINGTON (POB.)',
//       'ZARAGOZA',
//     ],
//   },
//   'TAGANA-AN': {
//     province: 'SURIGAO DEL NORTE',
//     municipality: 'TAGANA-AN',
//     region: 'REGION XIII',
//     barangay_list: [
//       'AURORA (POB.)',
//       'AZUCENA (POB.)',
//       'BANBAN',
//       'CAWILAN',
//       'FABIO',
//       'HIMAMAUG',
//       'LAUREL',
//       'LOWER LIBAS',
//       'OPONG',
//       'PATINO',
//       'SAMPAGUITA (POB.)',
//       'TALAVERA',
//       'UNION',
//       'UPPER LIBAS',
//     ],
//   },
//   BAROBO: {
//     province: 'SURIGAO DEL SUR',
//     municipality: 'BAROBO',
//     region: 'REGION XIII',
//     barangay_list: [
//       'AMAGA',
//       'BAHI',
//       'CABACUNGAN',
//       'CAMBAGANG',
//       'CAUSWAGAN',
//       'DAPDAP',
//       'DUGHAN',
//       'GAMUT',
//       'JAVIER',
//       'KINAYAN',
//       'MAMIS',
//       'POBLACION',
//       'RIZAL',
//       'SAN JOSE',
//       'SAN ROQUE',
//       'SAN VICENTE',
//       'SUA',
//       'SUDLON',
//       'TAMBIS',
//       'UNIDAD',
//       'WAKAT',
//     ],
//   },
//   BAYABAS: {
//     province: 'SURIGAO DEL SUR',
//     municipality: 'BAYABAS',
//     region: 'REGION XIII',
//     barangay_list: [
//       'AMAG',
//       'BALETE (POB.)',
//       'CABUGO',
//       'CAGBAOTO',
//       'LA PAZ',
//       'MAGOBAWOK',
//       'PANAOSAWON',
//     ],
//   },
//   'BISLIG': {
//     province: 'SURIGAO DEL SUR',
//     municipality: 'BISLIG',
//     region: 'REGION XIII',
//     barangay_list: [
//       'BUCTO',
//       'BURBOANAN',
//       'CAGUYAO',
//       'COLETO',
//       'COMAWAS',
//       'KAHAYAG',
//       'LABISMA',
//       'LAWIGAN',
//       'MAHARLIKA',
//       'MANGAGOY',
//       'MONE',
//       'PAMANLINAN',
//       'PAMAYPAYAN',
//       'POBLACION',
//       'SAN ANTONIO',
//       'SAN FERNANDO',
//       'SAN ISIDRO (BAGNAN)',
//       'SAN JOSE',
//       'SAN ROQUE (CADANGLASAN)',
//       'SAN VICENTE',
//       'SANTA CRUZ',
//       'SIBAROY',
//       'TABON',
//       'TUMANAN',
//     ],
//   },
//   CAGWAIT: {
//     province: 'SURIGAO DEL SUR',
//     municipality: 'CAGWAIT',
//     region: 'REGION XIII',
//     barangay_list: [
//       'ARAS-ASAN',
//       'BACOLOD',
//       'BITAUGAN EAST',
//       'BITAUGAN WEST',
//       'LA PURISIMA (PALHE)',
//       'LACTUDAN',
//       'MAT-E',
//       'POBLACION',
//       'TAWAGAN',
//       'TUBO-TUBO',
//       'UNIDAD',
//     ],
//   },
//   CANTILAN: {
//     province: 'SURIGAO DEL SUR',
//     municipality: 'CANTILAN',
//     region: 'REGION XIII',
//     barangay_list: [
//       'BUGSUKAN',
//       'BUNTALID',
//       'CABANGAHAN',
//       'CABAS-AN',
//       'CALAGDAAN',
//       'CONSUELO',
//       'GENERAL ISLAND',
//       'LININTI-AN (POB.)',
//       'LOBO',
//       'MAGASANG',
//       'MAGOSILOM (POB.)',
//       'PAG-ANTAYAN',
//       'PALASAO',
//       'PARANG',
//       'SAN PEDRO',
//       'TAPI',
//       'TIGABONG',
//     ],
//   },
//   CARRASCAL: {
//     province: 'SURIGAO DEL SUR',
//     municipality: 'CARRASCAL',
//     region: 'REGION XIII',
//     barangay_list: [
//       'ADLAY',
//       'BABUYAN',
//       'BACOLOD',
//       'BAYBAY (POB.)',
//       'BON-OT',
//       'CAGLAYAG',
//       'DAHICAN',
//       'DOYOS (POB.)',
//       'EMBARCADERO (POB.)',
//       'GAMUTON',
//       'PANIKIAN',
//       'PANTUKAN',
//       'SACA (POB.)',
//       'TAG-ANITO',
//     ],
//   },
//   CORTES: {
//     province: 'BOHOL',
//     municipality: 'CORTES',
//     region: 'REGION VII',
//     barangay_list: [
//       'DE LA PAZ',
//       'FATIMA',
//       'LORETO',
//       'LOURDES',
//       'MALAYO NORTE',
//       'MALAYO SUR',
//       'MONSERRAT',
//       'NEW LOURDES',
//       'PATROCINIO',
//       'POBLACION',
//       'ROSARIO',
//       'SALVADOR',
//       'SAN ROQUE',
//       'UPPER DE LA PAZ',
//     ],
//   },
//   HINATUAN: {
//     province: 'SURIGAO DEL SUR',
//     municipality: 'HINATUAN',
//     region: 'REGION XIII',
//     barangay_list: [
//       'BACULIN',
//       'BENIGNO AQUINO (ZONE I) POB.',
//       'BIGAAN',
//       'CAMBATONG',
//       'CAMPA',
//       'DUGMANON',
//       'HARIP',
//       'LA CASA (POB.)',
//       'LOYOLA',
//       'MALIGAYA',
//       'PAGTIGNI-AN (BITOON)',
//       'POCTO',
//       'PORT LAMON',
//       'ROXAS',
//       'SAN JUAN',
//       'SASA',
//       'TAGASAKA',
//       'TAGBOBONGA',
//       'TALISAY',
//       'TARUSAN',
//       'TIDMAN',
//       'TIWI',
//       'ZONE II (POB.)',
//       'ZONE III MAHARLIKA (POB.)',
//     ],
//   },
//   LANUZA: {
//     province: 'SURIGAO DEL SUR',
//     municipality: 'LANUZA',
//     region: 'REGION XIII',
//     barangay_list: [
//       'AGSAM',
//       'BOCAWE',
//       'BUNGA',
//       'GAMUTON',
//       'HABAG',
//       'MAMPI',
//       'NURCIA',
//       'PAKWAN',
//       'SIBAHAY',
//       'ZONE I (POB.)',
//       'ZONE II (POB.)',
//       'ZONE III (POB.)',
//       'ZONE IV (POB.)',
//     ],
//   },
//   LIANGA: {
//     province: 'SURIGAO DEL SUR',
//     municipality: 'LIANGA',
//     region: 'REGION XIII',
//     barangay_list: [
//       'ANIBONGAN',
//       'BAN-AS',
//       'BANAHAO',
//       'BAUCAWE',
//       'DIATAGON',
//       'GANAYON',
//       'LIATIMCO',
//       'MANYAYAY',
//       'PAYASAN',
//       'POBLACION',
//       'SAINT CHRISTINE',
//       'SAN ISIDRO',
//       'SAN PEDRO',
//     ],
//   },
//   LINGIG: {
//     province: 'SURIGAO DEL SUR',
//     municipality: 'LINGIG',
//     region: 'REGION XIII',
//     barangay_list: [
//       'ANIBONGAN',
//       'BARCELONA',
//       'BOGAK',
//       'BONGAN',
//       'HANDAMAYAN',
//       'MAHAYAHAY',
//       'MANDUS',
//       'MANSA-ILAO',
//       'PAGTILA-AN',
//       'PALO ALTO',
//       'POBLACION',
//       'RAJAH CABUNGSO-AN',
//       'SABANG',
//       'SALVACION',
//       'SAN ROQUE',
//       'TAGPOPORAN',
//       'UNION',
//       'VALENCIA',
//     ],
//   },
//   MADRID: {
//     province: 'SURIGAO DEL SUR',
//     municipality: 'MADRID',
//     region: 'REGION XIII',
//     barangay_list: [
//       'BAGSAC',
//       'BAYOGO',
//       'LINIBONAN',
//       'MAGSAYSAY',
//       'MANGA',
//       'PANAYOGON',
//       'PATONG PATONG',
//       'QUIRINO (POB.)',
//       'SAN ANTONIO',
//       'SAN JUAN',
//       'SAN ROQUE',
//       'SAN VICENTE',
//       'SONGKIT',
//       'UNION',
//     ],
//   },
//   MARIHATAG: {
//     province: 'SURIGAO DEL SUR',
//     municipality: 'MARIHATAG',
//     region: 'REGION XIII',
//     barangay_list: [
//       'ALEGRIA',
//       'AMONTAY',
//       'ANTIPOLO',
//       'AROROGAN',
//       'BAYAN',
//       'MAHABA',
//       'MARARAG',
//       'POBLACION',
//       'SAN ANTONIO',
//       'SAN ISIDRO',
//       'SAN PEDRO',
//       'SANTA CRUZ',
//     ],
//   },
//   'SAN AGUSTIN': {
//     province: 'ROMBLON',
//     municipality: 'SAN AGUSTIN',
//     region: 'REGION IV-B',
//     barangay_list: [
//       'BACHAWAN',
//       'BINONGAHAN',
//       'BINUGUSAN',
//       'BULI',
//       'CABOLUTAN',
//       'CAGBUAYA',
//       'CAMANTAYA',
//       'CARMEN',
//       'CAWAYAN',
//       'DOÑA JUANA',
//       'DUBDUBAN',
//       'LUSONG',
//       'MAHABANG BAYBAY (LONG BEACH)',
//       'POBLACION',
//       'SUGOD',
//     ],
//   },
//   'SAN MIGUEL': {
//     province: 'METRO MANILA',
//     municipality: 'SAN MIGUEL',
//     region: 'NCR',
//     barangay_list: [
//       'BARANGAY 637',
//       'BARANGAY 638',
//       'BARANGAY 639',
//       'BARANGAY 640',
//       'BARANGAY 641',
//       'BARANGAY 642',
//       'BARANGAY 643',
//       'BARANGAY 644',
//       'BARANGAY 645',
//       'BARANGAY 646',
//       'BARANGAY 647',
//       'BARANGAY 648',
//     ],
//   },
//   TAGBINA: {
//     province: 'SURIGAO DEL SUR',
//     municipality: 'TAGBINA',
//     region: 'REGION XIII',
//     barangay_list: [
//       'BATUNAN',
//       'CARPENITO',
//       'DOÑA CARMEN',
//       'HINAGDANAN',
//       'KAHAYAGAN',
//       'LAGO',
//       'MAGLAMBING',
//       'MAGLATAB',
//       'MAGSAYSAY',
//       'MALIXI',
//       'MANAMBIA',
//       'OSMEÑA',
//       'POBLACION',
//       'QUEZON',
//       'SAN VICENTE',
//       'SANTA CRUZ',
//       'SANTA FE',
//       'SANTA JUANA',
//       'SANTA MARIA',
//       'SAYON',
//       'SORIANO',
//       'TAGONGON',
//       'TRINIDAD',
//       'UGOBAN',
//       'VILLAVERDE',
//     ],
//   },
//   TAGO: {
//     province: 'SURIGAO DEL SUR',
//     municipality: 'TAGO',
//     region: 'REGION XIII',
//     barangay_list: [
//       'ALBA',
//       'ANAHAO BAG-O',
//       'ANAHAO DAAN',
//       'BADONG',
//       'BAJAO',
//       'BANGSUD',
//       'CABANGAHAN',
//       'CAGDAPAO',
//       'CAMAGONG',
//       'CARAS-AN',
//       'CAYALE',
//       'DAYO-AN',
//       'GAMUT',
//       'JUBANG',
//       'KINABIGTASAN',
//       'LAYOG',
//       'LINDOY',
//       'MERCEDES',
//       'PURISIMA (POB.)',
//       'SUMO-SUMO',
//       'UMBAY',
//       'UNABAN',
//       'UNIDOS',
//       'VICTORIA',
//     ],
//   },
//   'TANDAG': {
//     province: 'SURIGAO DEL SUR',
//     municipality: 'TANDAG',
//     region: 'REGION XIII',
//     barangay_list: [
//       'AWASIAN',
//       'BAGONG LUNGSOD (POB.)',
//       'BIOTO',
//       'BONGTOD POB. (EAST WEST)',
//       'BUENAVISTA',
//       'DAGOCDOC (POB.)',
//       'MABUA',
//       'MABUHAY',
//       'MAITUM',
//       'MATICDUM',
//       'PANDANON',
//       'PANGI',
//       'QUEZON',
//       'ROSARIO',
//       'SALVACION',
//       'SAN AGUSTIN NORTE',
//       'SAN AGUSTIN SUR',
//       'SAN ANTONIO',
//       'SAN ISIDRO',
//       'SAN JOSE',
//       'TELAJE',
//     ],
//   },
//   ADAMS: {
//     province: 'ILOCOS NORTE',
//     municipality: 'ADAMS',
//     region: 'REGION I',
//     barangay_list: ['ADAMS (POB.)'],
//   },
//   BACARRA: {
//     province: 'ILOCOS NORTE',
//     municipality: 'BACARRA',
//     region: 'REGION I',
//     barangay_list: [
//       'BANI',
//       'BUYON',
//       'CABARUAN',
//       'CABULALAAN',
//       'CABUSLIGAN',
//       'CADARATAN',
//       'CALIOET-LIBONG',
//       'CASILIAN',
//       'COROCOR',
//       'DURIPES',
//       'GANAGAN',
//       'LIBTONG',
//       'MACUPIT',
//       'NAMBARAN',
//       'NATBA',
//       'PANINAAN',
//       'PASIOCAN',
//       'PASNGAL',
//       'PIPIAS',
//       'PULANGI',
//       'PUNGTO',
//       'SAN AGUSTIN I (POB.)',
//       'SAN AGUSTIN II (POB.)',
//       'SAN ANDRES I (POB.)',
//       'SAN ANDRES II (POB.)',
//       'SAN GABRIEL I (POB.)',
//       'SAN GABRIEL II (POB.)',
//       'SAN PEDRO I (POB.)',
//       'SAN PEDRO II (POB.)',
//       'SAN ROQUE I (POB.)',
//       'SAN ROQUE II (POB.)',
//       'SAN SIMON I (POB.)',
//       'SAN SIMON II (POB.)',
//       'SAN VICENTE (POB.)',
//       'SANGIL',
//       'SANTA FILOMENA I (POB.)',
//       'SANTA FILOMENA II (POB.)',
//       'SANTA RITA (POB.)',
//       'SANTO CRISTO I (POB.)',
//       'SANTO CRISTO II (POB.)',
//       'TAMBIDAO',
//       'TEPPANG',
//       'TUBBURAN',
//     ],
//   },
//   BADOC: {
//     province: 'ILOCOS NORTE',
//     municipality: 'BADOC',
//     region: 'REGION I',
//     barangay_list: [
//       'ALAY-NANGBABAAN (ALAY 15-B)',
//       'ALOGOOG',
//       'AR-ARUSIP',
//       'ARING',
//       'BALBALDEZ',
//       'BATO',
//       'CAMANGA',
//       'CANAAN (POB.)',
//       'CARAITAN',
//       'GABUT NORTE',
//       'GABUT SUR',
//       'GARRETA (POB.)',
//       'LA VIRGEN MILAGROSA (PAGUETPET)',
//       'LABUT',
//       'LACUBEN',
//       'LUBIGAN',
//       'MABUSAG NORTE',
//       'MABUSAG SUR',
//       'MADUPAYAS',
//       'MORONG',
//       'NAGREBCAN',
//       'NAPU',
//       'PAGSANAHAN NORTE',
//       'PAGSANAHAN SUR',
//       'PALTIT',
//       'PARANG',
//       'PASUC',
//       'SANTA CRUZ NORTE',
//       'SANTA CRUZ SUR',
//       'SAUD',
//       'TUROD',
//     ],
//   },
//   BANGUI: {
//     province: 'ILOCOS NORTE',
//     municipality: 'BANGUI',
//     region: 'REGION I',
//     barangay_list: [
//       'ABACA',
//       'BACSIL',
//       'BANBAN',
//       'BARUYEN',
//       'DADAOR',
//       'LANAO',
//       'MALASIN',
//       'MANAYON',
//       'MASIKIL',
//       'NAGBALAGAN',
//       'PAYAC',
//       'SAN LORENZO (POB.)',
//       'TAGUIPORO',
//       'UTOL',
//     ],
//   },
//   'BANNA (ESPIRITU)': {
//     province: 'ILOCOS NORTE',
//     municipality: 'BANNA (ESPIRITU)',
//     region: 'REGION I',
//     barangay_list: [
//       'BALIOEG',
//       'BANGSAR',
//       'BARBARANGAY',
//       'BINACAG',
//       'BOMITOG',
//       'BUGASI',
//       'CAESTEBANAN',
//       'CARIBQUIB',
//       'CATAGTAGUEN',
//       'CRISPINA',
//       'HILARIO (POB.)',
//       'IMELDA',
//       'LORENZO (POB.)',
//       'MACAYEPYEP',
//       'MARCOS (POB.)',
//       'NAGPATAYAN',
//       'SINAMAR',
//       'TABTABAGAN',
//       'VALDEZ',
//       'VALENCIANO (POB.)',
//     ],
//   },
//   'BATAC': {
//     province: 'ILOCOS NORTE',
//     municipality: 'BATAC',
//     region: 'REGION I',
//     barangay_list: [
//       'ABLAN POB. (LABUCAO)',
//       'ACOSTA POB. (ILOILO)',
//       'AGLIPAY (POB.)',
//       'BAAY',
//       'BALIGAT',
//       'BAOA EAST',
//       'BAOA WEST',
//       'BARANI (POB.)',
//       'BEN-AGAN (POB.)',
//       'BIL-LOCA',
//       'BININGAN',
//       'BUNGON',
//       'CALLAGUIP (POB.)',
//       'CAMANDINGAN',
//       'CAMGUIDAN',
//       'CANGRUNAAN (POB.)',
//       'CAPACUAN',
//       'CAUNAYAN (POB.)',
//       'COLO',
//       'DARIWDIW',
//       'LACUB (POB.)',
//       'MABALENG',
//       'MAGNUANG',
//       'MAIPALIG',
//       'NAGBACALAN',
//       'NAGUIRANGAN',
//       'PALONGPONG',
//       'PALPALICONG (POB.)',
//       'PARANGOPONG',
//       'PAYAO',
//       'PIMENTEL (CUBOL)',
//       'QUILING NORTE',
//       'QUILING SUR',
//       'QUIOM',
//       'RAYURAY',
//       'RICARTE POB. (NALASIN)',
//       'SAN JULIAN (POB.)',
//       'SAN MATEO',
//       'SAN PEDRO',
//       'SUABIT (POB.)',
//       'SUMADER',
//       'TABUG',
//       'VALDEZ POB. (CAOAYAN)',
//     ],
//   },
//   CARASI: {
//     province: 'ILOCOS NORTE',
//     municipality: 'CARASI',
//     region: 'REGION I',
//     barangay_list: ['ANGSET', 'BARBAQUESO (POB.)', 'VIRBIRA'],
//   },
//   CURRIMAO: {
//     province: 'ILOCOS NORTE',
//     municipality: 'CURRIMAO',
//     region: 'REGION I',
//     barangay_list: [
//       'ANGGAPANG NORTE',
//       'ANGGAPANG SUR',
//       'BIMMANGA',
//       'CABUUSAN',
//       'COMCOMLOONG',
//       'GAANG',
//       'LANG-AYAN-BARAMBAN',
//       'LIOES',
//       'MAGLAOI CENTRO',
//       'MAGLAOI NORTE',
//       'MAGLAOI SUR',
//       'PAGULUDAN-SALINDEG',
//       'PANGIL',
//       'PIAS NORTE',
//       'PIAS SUR',
//       'POBLACION I',
//       'POBLACION II',
//       'SALUGAN',
//       'SAN SIMEON',
//       'SANTA CRUZ',
//       'TAPAO-TIGUE',
//       'TORRE',
//       'VICTORIA',
//     ],
//   },
//   DINGRAS: {
//     province: 'ILOCOS NORTE',
//     municipality: 'DINGRAS',
//     region: 'REGION I',
//     barangay_list: [
//       'ALBANO (POB.)',
//       'BACSIL',
//       'BAGUT',
//       'BARESBES',
//       'BARONG',
//       'BUNGCAG',
//       'CALI',
//       'CAPASAN',
//       'DANCEL (POB.)',
//       'ELIZABETH',
//       'ESPIRITU',
//       'FOZ',
//       'GUERRERO (POB.)',
//       'LANAS',
//       'LUMBAD',
//       'MADAMBA (POB.)',
//       'MANDALOQUE',
//       'MEDINA',
//       'PARADO (BANGAY)',
//       'PERALTA (POB.)',
//       'PURUGANAN (POB.)',
//       'ROOT (BALDIAS)',
//       'SAGPATAN',
//       'SALUDARES',
//       'SAN ESTEBAN',
//       'SAN FRANCISCO (SURRATE)',
//       'SAN MARCELINO (PADONG)',
//       'SAN MARCOS',
//       'SULQUIANO (SIDIRAN)',
//       'SUYO',
//       'VER (NAGLAYAAN)',
//     ],
//   },
//   DUMALNEG: {
//     province: 'ILOCOS NORTE',
//     municipality: 'DUMALNEG',
//     region: 'REGION I',
//     barangay_list: ['CABARITAN', 'KALAW', 'QUIBEL', 'SAN ISIDRO'],
//   },
//   'LAOAG': {
//     province: 'ILOCOS NORTE',
//     municipality: 'LAOAG',
//     region: 'REGION I',
//     barangay_list: [
//       'BGY. NO. 1, SAN LORENZO (POB.)',
//       'BGY. NO. 2, SANTA JOAQUINA (POB.)',
//       'BGY. NO. 3, NSTRA. SRA. DEL ROSARIO (POB.)',
//       'BGY. NO. 4, SAN GUILLERMO (POB.)',
//       'BGY. NO. 5, SAN PEDRO (POB.)',
//       'BGY. NO. 6, SAN AGUSTIN (POB.)',
//       'BGY. NO. 7-A, NSTRA. SRA. DE NATIVIDAD (POB.)',
//       'BGY. NO. 7-B, NSTRA. SRA. DE NATIVIDAD (POB.)',
//       'BGY. NO. 8, SAN VICENTE (POB.)',
//       'BGY. NO. 9, SANTA ANGELA (POB.)',
//       'BGY. NO. 10, SAN JOSE (POB.)',
//       'BGY. NO. 11, SANTA BALBINA (POB.)',
//       'BGY. NO. 12, SAN ISIDRO (POB.)',
//       'BGY. NO. 13, NSTRA. SRA. DE VISITACION (POB.)',
//       'BGY. NO. 14, SANTO TOMAS (POB.)',
//       'BGY. NO. 15, SAN GUILLERMO (POB.)',
//       'BGY. NO. 16, SAN JACINTO (POB.)',
//       'BGY. NO. 17, SAN FRANCISCO (POB.)',
//       'BGY. NO. 19, SANTA MARCELA (POB.)',
//       'BGY. NO. 20, SAN MIGUEL (POB.)',
//       'BGY. NO. 21, SAN PEDRO (POB.)',
//       'BGY. NO. 22, SAN ANDRES (POB.)',
//       'BGY. NO. 23, SAN MATIAS (POB.)',
//       'BGY. NO. 24, NSTRA. SRA. DE CONSOLACION (POB.)',
//       'BGY. NO. 25, SANTA CAYETANA (POB.)',
//       'BGY. NO. 26, SAN MARCELINO (POB.)',
//       'BGY. NO. 27, NSTRA. SRA. DE SOLEDAD (POB.)',
//       'BGY. NO. 28, SAN BERNARDO (POB.)',
//       'BGY. NO. 29, SANTO TOMAS (POB.)',
//       'BGY. NO. 30-A, SUYO',
//       'BGY. NO. 30-B, SANTA MARIA',
//       'BGY. NO. 31, TALINGAAN',
//       'BGY. NO. 32-A, LA PAZ EAST',
//       'BGY. NO. 32-B, LA PAZ WEST',
//       'BGY. NO. 32-C LA PAZ EAST',
//       'BGY. NO. 33-A, LA PAZ PROPER',
//       'BGY. NO. 33-B, LA PAZ PROPER',
//       'BGY. NO. 34-A, GABU NORTE WEST',
//       'BGY. NO. 34-B, GABU NORTE EAST',
//       'BGY. NO. 35, GABU SUR',
//       'BGY. NO. 36, ARANIW',
//       'BGY. NO. 37, CALAYAB',
//       'BGY. NO. 38-A, MANGATO EAST',
//       'BGY. NO. 38-B, MANGATO WEST',
//       'BGY. NO. 39, SANTA ROSA',
//       'BGY. NO. 40, BALATONG',
//       'BGY. NO. 41, BALACAD',
//       'BGY. NO. 42, APAYA',
//       'BGY. NO. 43, CAVIT',
//       'BGY. NO. 44, ZAMBOANGA',
//       'BGY. NO. 45, TANGID',
//       'BGY. NO. 46, NALBO',
//       'BGY. NO. 47, BENGCAG',
//       'BGY. NO. 48-B, CABUNGAAN SOUTH',
//       'BGY. NO. 49-A, DARAYDAY',
//       'BGY. NO. 49-B, RARABURAN',
//       'BGY. NO. 50, BUTTONG',
//       'BGY. NO. 51-A, NANGALISAN EAST',
//       'BGY. NO. 51-B, NANGALISAN WEST',
//       'BGY. NO. 52-A, SAN MATEO',
//       'BGY. NO. 52-B, LATAAG',
//       'BGY. NO. 53, RIOENG',
//       'BGY. NO. 54-A, LAGUI-SAIL',
//       'BGY. NO. 54-B, CAMANGAAN',
//       'BGY. NO. 55-A, BARIT-PANDAN',
//       'BGY. NO. 55-B, SALET-BULANGON',
//       'BGY. NO. 55-C, VIRA',
//       'BGY. NO. 56-A, BACSIL NORTH',
//       'BGY. NO. 56-B, BACSIL SOUTH',
//       'BGY. NO. 57, PILA',
//       'BGY. NO. 58, CASILI',
//       'BGY. NO. 59-A, DIBUA SOUTH',
//       'BGY. NO. 59-B, DIBUA NORTH',
//       'BGY. NO. 60-A, CAAOACAN',
//       'BGY. NO. 60-B, MADILADIG',
//       'BGY. NO. 61, CATABAN',
//       'BGY. NO. 62-A, NAVOTAS NORTH',
//       'BGY. NO. 62-B, NAVOTAS SOUTH',
//       'BRY. NO. 18, SAN QUIRINO (POB.)',
//       'BRY. NO. 48-A, CABUNGAAN NORTH',
//     ],
//   },
//   MARCOS: {
//     province: 'ILOCOS NORTE',
//     municipality: 'MARCOS',
//     region: 'REGION I',
//     barangay_list: [
//       'CACAFEAN',
//       'DAQUIOAG',
//       'ELIZABETH (CULAO)',
//       'ESCODA',
//       'FERDINAND',
//       'FORTUNA',
//       'IMELDA (CAPARIAAN)',
//       'LYDIA (POB.)',
//       'MABUTI',
//       'PACIFICO (AGUNIT)',
//       'SANTIAGO',
//       'TABUCBUC (RAGAS)',
//       'VALDEZ (BIDING)',
//     ],
//   },
//   'NUEVA ERA': {
//     province: 'ILOCOS NORTE',
//     municipality: 'NUEVA ERA',
//     region: 'REGION I',
//     barangay_list: [
//       'ACNAM',
//       'BARANGOBONG',
//       'BARIKIR',
//       'BUGAYONG',
//       'CABITTAURAN',
//       'CARAY',
//       'GARNADEN',
//       'NAGUILLAN (PAGPAG-ONG)',
//       'POBLACION',
//       'SANTO NIÑO',
//       'UGUIS',
//     ],
//   },
//   PAGUDPUD: {
//     province: 'ILOCOS NORTE',
//     municipality: 'PAGUDPUD',
//     region: 'REGION I',
//     barangay_list: [
//       'AGGASI',
//       'BADUANG',
//       'BALAOI',
//       'BURAYOC',
//       'CAPARISPISAN',
//       'CAUNAYAN',
//       'DAMPIG',
//       'LIGAYA',
//       'PANCIAN',
//       'PASALENG',
//       'POBLACION 1',
//       'POBLACION 2',
//       'SAGUIGUI',
//       'SAUD',
//       'SUBEC',
//       'TARRAG',
//     ],
//   },
//   PAOAY: {
//     province: 'ILOCOS NORTE',
//     municipality: 'PAOAY',
//     region: 'REGION I',
//     barangay_list: [
//       'BACSIL',
//       'CABAGOAN',
//       'CABANGARAN',
//       'CALLAGUIP',
//       'CAYUBOG',
//       'DOLORES',
//       'LAOA',
//       'MASINTOC',
//       'MONTE',
//       'MUMULAAN',
//       'NAGBACALAN',
//       'NALASIN',
//       'NANGUYUDAN',
//       'OAIG-UPAY-ABULAO',
//       'PAMBARAN',
//       'PANNARATAN (POB.)',
//       'PARATONG',
//       'PASIL',
//       'SALBANG (POB.)',
//       'SAN AGUSTIN',
//       'SAN BLAS (POB.)',
//       'SAN JUAN',
//       'SAN PEDRO',
//       'SAN ROQUE (POB.)',
//       'SANGLADAN POB. (NALBUAN)',
//       'SANTA RITA (POB.)',
//       'SIDEG',
//       'SUBA',
//       'SUNGADAN',
//       'SURGUI',
//       'VERONICA',
//     ],
//   },
//   PASUQUIN: {
//     province: 'ILOCOS NORTE',
//     municipality: 'PASUQUIN',
//     region: 'REGION I',
//     barangay_list: [
//       'BATULI',
//       'BINSANG',
//       'CARUAN',
//       'CARUSIKIS',
//       'CARUSIPAN',
//       'DADAEMAN',
//       'DARUPIDIP',
//       'DAVILA',
//       'DILANIS',
//       'DILAVO',
//       'ESTANCIA',
//       'NAGLICUAN',
//       'NAGSANGA',
//       'NALVO (CABABAAN-NALVO)',
//       'NGABANGAB',
//       'PANGIL',
//       'POBLACION 1',
//       'POBLACION 2',
//       'POBLACION 3',
//       'POBLACION 4',
//       'PRAGATA (PRAGATA-BUNGRO)',
//       'PUYUPUYAN',
//       'SALPAD',
//       'SAN JUAN',
//       'SANTA CATALINA',
//       'SANTA MATILDE',
//       'SAPAT',
//       'SULBEC',
//       'SULONGAN',
//       'SURONG',
//       'SUSUGAEN',
//       'TABUNGAO',
//       'TADAO',
//     ],
//   },
//   PIDDIG: {
//     province: 'ILOCOS NORTE',
//     municipality: 'PIDDIG',
//     region: 'REGION I',
//     barangay_list: [
//       'AB-ABUT',
//       'ABUCAY',
//       'ANAO (POB.)',
//       'ARUA-AY',
//       'BIMMANGA',
//       'BOYBOY',
//       'CABAROAN (POB.)',
//       'CALAMBEG',
//       'CALLUSA',
//       'DUPITAC',
//       'ESTANCIA',
//       'GAYAMAT',
//       'LAGANDIT',
//       'LIBNAOAN',
//       'LOING (POB.)',
//       'MAAB-ABACA',
//       'MANGITAYAG',
//       'MARUAYA',
//       'SAN ANTONIO',
//       'SANTA MARIA',
//       'SUCSUQUEN',
//       'TANGAOAN',
//       'TONOTON',
//     ],
//   },
//   PINILI: {
//     province: 'ILOCOS NORTE',
//     municipality: 'PINILI',
//     region: 'REGION I',
//     barangay_list: [
//       'AGLIPAY',
//       'APATUT-LUBONG',
//       'BADIO',
//       'BARBAR',
//       'BUANGA',
//       'BULBULALA',
//       'BUNGRO',
//       'CABAROAN',
//       'CAPANGDANAN',
//       'DALAYAP',
//       'DARAT',
//       'GULPENG',
//       'LILIPUTEN',
//       'LUMBAAN-BICBICA',
//       'NAGTRIGOAN',
//       'PAGDILAO (POB.)',
//       'PUGAOAN',
//       'PURITAC',
//       'PUZOL',
//       'SACRITAN',
//       'SALANAP',
//       'SANTO TOMAS',
//       'TARTARABANG',
//       'UPON',
//       'VALBUENA (POB.)',
//     ],
//   },
//   'SAN NICOLAS': {
//     province: 'METRO MANILA',
//     municipality: 'SAN NICOLAS',
//     region: 'NCR',
//     barangay_list: [
//       'BARANGAY 268',
//       'BARANGAY 269',
//       'BARANGAY 270',
//       'BARANGAY 271',
//       'BARANGAY 272',
//       'BARANGAY 273',
//       'BARANGAY 274',
//       'BARANGAY 275',
//       'BARANGAY 276',
//       'BARANGAY 281',
//       'BARANGAY 282',
//       'BARANGAY 283',
//       'BARANGAY 284',
//       'BARANGAY 285',
//       'BARANGAY 286',
//     ],
//   },
//   SARRAT: {
//     province: 'ILOCOS NORTE',
//     municipality: 'SARRAT',
//     region: 'REGION I',
//     barangay_list: [
//       'SAN AGUSTIN (POB.)',
//       'SAN ANDRES',
//       'SAN ANTONIO',
//       'SAN BERNABE',
//       'SAN CRISTOBAL',
//       'SAN FELIPE',
//       'SAN FRANCISCO (POB.)',
//       'SAN ISIDRO',
//       'SAN JOAQUIN (POB.)',
//       'SAN JOSE',
//       'SAN JUAN',
//       'SAN LEANDRO (POB.)',
//       'SAN LORENZO',
//       'SAN MANUEL',
//       'SAN MARCOS',
//       'SAN NICOLAS',
//       'SAN PEDRO',
//       'SAN ROQUE',
//       'SAN VICENTE (POB.)',
//       'SANTA BARBARA (POB.)',
//       'SANTA MAGDALENA',
//       'SANTA ROSA',
//       'SANTO SANTIAGO',
//       'SANTO TOMAS',
//     ],
//   },
//   SOLSONA: {
//     province: 'ILOCOS NORTE',
//     municipality: 'SOLSONA',
//     region: 'REGION I',
//     barangay_list: [
//       'AGUITAP',
//       'BAGBAG',
//       'BAGBAGO',
//       'BARCELONA',
//       'BUBUOS',
//       'CAPURICTAN',
//       'CATANGRARAN',
//       'DARASDAS',
//       'JUAN (POB.)',
//       'LAURETA (POB.)',
//       'LIPAY',
//       'MAANANTENG',
//       'MANALPAC',
//       'MARIQUET',
//       'NAGPATPATAN',
//       'NALASIN',
//       'PUTTAO',
//       'SAN JUAN',
//       'SAN JULIAN',
//       'SANTA ANA',
//       'SANTIAGO',
//       'TALUGTOG',
//     ],
//   },
//   VINTAR: {
//     province: 'ILOCOS NORTE',
//     municipality: 'VINTAR',
//     region: 'REGION I',
//     barangay_list: [
//       'ABKIR',
//       'ALEJO MALASIG',
//       'ALSEM',
//       'BAGO',
//       'BULBULALA',
//       'CABANGARAN',
//       'CABAYO',
//       'CABISOCOLAN',
//       'CANAAM',
//       'COLUMBIA',
//       'DAGUPAN',
//       'DIPILAT',
//       'ESPERANZA',
//       'ESTER',
//       'ISIC ISIC',
//       'LUBNAC',
//       'MABANBANAG',
//       'MALAMPA (PENINAAN-MALAMPA)',
//       'MANARANG',
//       'MARGAAY',
//       'NAMOROC',
//       'PARPAROROC',
//       'PARUT',
//       'PEDRO S. ALVIAR (DIATON)',
//       'SALSALAMAGUI',
//       'SAN JOSE (LIPAY)',
//       'SAN NICOLAS (POB.)',
//       'SAN PEDRO (POB.)',
//       'SAN RAMON (POB.)',
//       'SAN ROQUE (POB.)',
//       'SANTA MARIA (POB.)',
//       'TAMDAGAN',
//       'VISAYA',
//     ],
//   },
//   ALILEM: {
//     province: 'ILOCOS SUR',
//     municipality: 'ALILEM',
//     region: 'REGION I',
//     barangay_list: [
//       'ALILEM DAYA (POB.)',
//       'AMILONGAN',
//       'ANAAO',
//       'APANG',
//       'APAYA',
//       'BATBATO',
//       'DADDAAY',
//       'DALAWA',
//       'KIAT',
//     ],
//   },
//   BANAYOYO: {
//     province: 'ILOCOS SUR',
//     municipality: 'BANAYOYO',
//     region: 'REGION I',
//     barangay_list: [
//       'BAGBAGOTOT',
//       'BANBANAAL',
//       'BISANGOL',
//       'CADANGLAAN',
//       'CASILAGAN NORTE',
//       'CASILAGAN SUR',
//       'ELEFANTE',
//       'GUARDIA',
//       'LINTIC',
//       'LOPEZ',
//       'MONTERO',
//       'NAGUIMBA',
//       'PILA',
//       'POBLACION',
//     ],
//   },
//   BANTAY: {
//     province: 'ILOCOS SUR',
//     municipality: 'BANTAY',
//     region: 'REGION I',
//     barangay_list: [
//       'AGGAY',
//       'AN-ANNAM',
//       'BALALENG',
//       'BANAOANG',
//       'BARANGAY 1 (POB.)',
//       'BARANGAY 2 (POB.)',
//       'BARANGAY 3 (POB.)',
//       'BARANGAY 4 (POB.)',
//       'BARANGAY 5 (POB.)',
//       'BARANGAY 6 (POB.)',
//       'BULAG',
//       'BUQUIG',
//       'CABALANGGAN',
//       'CABAROAN',
//       'CABUSLIGAN',
//       'CAPANGDANAN',
//       'GUIMOD',
//       'LINGSAT',
//       'MALINGEB',
//       'MIRA',
//       'NAGUIDDAYAN',
//       'ORA',
//       'PAING',
//       'PUSPUS',
//       'QUIMMARAYAN',
//       'SAGNEB',
//       'SAGPAT',
//       'SAN ISIDRO',
//       'SAN JULIAN',
//       'SAN MARIANO (SALLACONG)',
//       'SINABAAN',
//       'TAGUIPORO',
//       'TALEB',
//       'TAY-AC',
//     ],
//   },
//   CABUGAO: {
//     province: 'ILOCOS SUR',
//     municipality: 'CABUGAO',
//     region: 'REGION I',
//     barangay_list: [
//       'ALINAAY',
//       'ARAGAN',
//       'ARNAP',
//       'BACLIG (POB.)',
//       'BATO',
//       'BONIFACIO (POB.)',
//       'BUNGRO',
//       'CACADIRAN',
//       'CAELLAYAN',
//       'CARUSIPAN',
//       'CATUCDAAN',
//       'CUANCABAL',
//       'CUANTACLA',
//       'DACLAPAN',
//       'DARDARAT',
//       'LIPIT',
//       'MARADODON',
//       'MARGAAY',
//       'NAGSANTAAN',
//       'NAGSINCAOAN',
//       'NAMRUANGAN',
//       'PILA',
//       'PUG-OS',
//       'QUEZON (POB.)',
//       'REPPAAC',
//       'RIZAL (POB.)',
//       'SABANG',
//       'SAGAYADEN',
//       'SALAPASAP',
//       'SALOMAGUE',
//       'SISIM',
//       'TUROD',
//       'TUROD-PATAC',
//     ],
//   },
//   'CANDON': {
//     province: 'ILOCOS SUR',
//     municipality: 'CANDON',
//     region: 'REGION I',
//     barangay_list: [
//       'ALLANGIGAN PRIMERO',
//       'ALLANGIGAN SEGUNDO',
//       'AMGUID',
//       'AYUDANTE',
//       'BAGANI CAMPOSANTO',
//       'BAGANI GABOR',
//       'BAGANI TOCGO',
//       'BAGANI UBBOG',
//       'BAGAR',
//       'BALINGAOAN',
//       'BUGNAY',
//       'CALAOAAN',
//       'CALONGBUYAN',
//       'CATERMAN',
//       'CUBCUBBOOT',
//       'DARAPIDAP',
//       'LANGLANGCA PRIMERO',
//       'LANGLANGCA SEGUNDO',
//       'OAIG-DAYA',
//       'PALACAPAC',
//       'PARAS',
//       'PARIOC PRIMERO',
//       'PARIOC SEGUNDO',
//       'PATPATA PRIMERO',
//       'PATPATA SEGUNDO',
//       'PAYPAYAD',
//       'SALVADOR PRIMERO',
//       'SALVADOR SEGUNDO',
//       'SAN AGUSTIN',
//       'SAN ANDRES',
//       'SAN ANTONIO (POB.)',
//       'SAN ISIDRO (POB.)',
//       'SAN JOSE (POB.)',
//       'SAN JUAN (POB.)',
//       'SAN NICOLAS',
//       'SAN PEDRO',
//       'SANTO TOMAS',
//       'TABLAC',
//       'TALOGTOG',
//       'TAMURONG PRIMERO',
//       'TAMURONG SEGUNDO',
//       'VILLARICA',
//     ],
//   },
//   CAOAYAN: {
//     province: 'ILOCOS SUR',
//     municipality: 'CAOAYAN',
//     region: 'REGION I',
//     barangay_list: [
//       'ANONANG MAYOR',
//       'ANONANG MENOR',
//       'BAGGOC',
//       'CALLAGUIP',
//       'CAPARACADAN',
//       'DON ALEJANDRO QUIROLGICO (POB.)',
//       'DON DIMAS QUERUBIN (POB.)',
//       'DON LORENZO QUERUBIN (POB.)',
//       'FUERTE',
//       'MANANGAT',
//       'NAGUILIAN',
//       'NANSUAGAO',
//       'PANDAN',
//       'PANTAY TAMURONG',
//       'PANTAY-QUITIQUIT',
//       'PURO',
//       'VILLAMAR',
//     ],
//   },
//   CERVANTES: {
//     province: 'ILOCOS SUR',
//     municipality: 'CERVANTES',
//     region: 'REGION I',
//     barangay_list: [
//       'ALULING',
//       'COMILLAS NORTH',
//       'COMILLAS SOUTH',
//       'CONCEPCION (POB.)',
//       'DINWEDE EAST',
//       'DINWEDE WEST',
//       'LIBANG',
//       'MALAYA',
//       'PILIPIL',
//       'REMEDIOS',
//       'ROSARIO (POB.)',
//       'SAN JUAN',
//       'SAN LUIS',
//     ],
//   },
//   GALIMUYOD: {
//     province: 'ILOCOS SUR',
//     municipality: 'GALIMUYOD',
//     region: 'REGION I',
//     barangay_list: [
//       'ABAYA',
//       'BARACBAC',
//       'BIDBIDAY',
//       'BITONG',
//       'BOROBOR',
//       'CALIMUGTONG',
//       'CALONGBUYAN',
//       'CALUMBAYA',
//       'DALDAGAN',
//       'KILANG',
//       'LEGASPI',
//       'MABAYAG',
//       'MATANUBONG',
//       'MCKINLEY',
//       'NAGSINGCAOAN',
//       'OAIG-DAYA',
//       'PAGANGPANG',
//       'PATAC',
//       'POBLACION',
//       'RUBIO',
//       'SABANGAN-BATO',
//       'SACAANG',
//       'SAN VICENTE',
//       'SAPANG',
//     ],
//   },
//   'GREGORIO DEL PILAR (CONCEPCION)': {
//     province: 'ILOCOS SUR',
//     municipality: 'GREGORIO DEL PILAR (CONCEPCION)',
//     region: 'REGION I',
//     barangay_list: [
//       'ALFONSO (TANGAOAN)',
//       'BUSSOT',
//       'CONCEPCION',
//       'DAPDAPPIG',
//       'MATUE-BUTARAG',
//       'POBLACION NORTE',
//       'POBLACION SUR',
//     ],
//   },
//   LIDLIDDA: {
//     province: 'ILOCOS SUR',
//     municipality: 'LIDLIDDA',
//     region: 'REGION I',
//     barangay_list: [
//       'BANUCAL',
//       'BEQUI-WALIN',
//       'BUGUI',
//       'CALUNGBUYAN',
//       'CARCARABASA',
//       'LABUT',
//       'POBLACION NORTE',
//       'POBLACION SUR',
//       'SAN VICENTE',
//       'SUYSUYAN',
//       'TAY-AC',
//     ],
//   },
//   MAGSINGAL: {
//     province: 'ILOCOS SUR',
//     municipality: 'MAGSINGAL',
//     region: 'REGION I',
//     barangay_list: [
//       'ALANGAN',
//       'BACAR',
//       'BARBARIT',
//       'BUNGRO',
//       'CABAROAN',
//       'CADANGLAAN',
//       'CARAISAN',
//       'DACUTAN',
//       'LABUT',
//       'MAAS-ASIN',
//       'MACATCATUD',
//       'MANZANTE',
//       'MARATUDO',
//       'MIRAMAR',
//       'NAMALPALAN',
//       'NAPO',
//       'PAGSANAAN NORTE',
//       'PAGSANAAN SUR',
//       'PANAY NORTE',
//       'PANAY SUR',
//       'PATONG',
//       'PURO',
//       'SAN BASILIO (POB.)',
//       'SAN CLEMENTE (POB.)',
//       'SAN JULIAN (POB.)',
//       'SAN LUCAS (POB.)',
//       'SAN RAMON (POB.)',
//       'SAN VICENTE (POB.)',
//       'SANTA MONICA',
//       'SARSARACAT',
//     ],
//   },
//   NAGBUKEL: {
//     province: 'ILOCOS SUR',
//     municipality: 'NAGBUKEL',
//     region: 'REGION I',
//     barangay_list: [
//       'BALAWEG',
//       'BANDRIL',
//       'BANTUGO',
//       'CADACAD',
//       'CASILAGAN',
//       'CASOCOS',
//       'LAPTING',
//       'MAPISI',
//       'MISSION',
//       'POBLACION EAST',
//       'POBLACION WEST',
//       'TALEB',
//     ],
//   },
//   NARVACAN: {
//     province: 'ILOCOS SUR',
//     municipality: 'NARVACAN',
//     region: 'REGION I',
//     barangay_list: [
//       'ABUOR',
//       'AMBULOGAN',
//       'AQUIB',
//       'BANGLAYAN',
//       'BANTAY ABOT',
//       'BULANOS',
//       'CADACAD',
//       'CAGAYUNGAN',
//       'CAMARAO',
//       'CASILAGAN',
//       'CODOOG',
//       'DASAY',
//       'DINALAOAN',
//       'ESTANCIA',
//       'LANIPAO',
//       'LUNGOG',
//       'MARGAAY',
//       'MAROZO',
//       'NAGUNEG',
//       'ORENCE',
//       'PANTOC',
//       'PARATONG',
//       'PARPARIA',
//       'QUINARAYAN',
//       'RIVADAVIA',
//       'SAN ANTONIO',
//       'SAN JOSE (POB.)',
//       'SAN PABLO',
//       'SAN PEDRO',
//       'SANTA LUCIA (POB.)',
//       'SARMINGAN',
//       'SUCOC',
//       'SULVEC',
//       'TUROD',
//     ],
//   },
//   'QUIRINO (ANGKAKI)': {
//     province: 'ILOCOS SUR',
//     municipality: 'QUIRINO (ANGKAKI)',
//     region: 'REGION I',
//     barangay_list: [
//       'BANOEN',
//       'CAYUS',
//       'LAMAG (TUBTUBA)',
//       'LEGLEG (POB.)',
//       'MALIDEG',
//       'NAMITPIT',
//       'PATIACAN',
//       'PATUNGCALEO (LAMAG)',
//       'SUAGAYAN',
//     ],
//   },
//   'SALCEDO (BAUGEN)': {
//     province: 'ILOCOS SUR',
//     municipality: 'SALCEDO (BAUGEN)',
//     region: 'REGION I',
//     barangay_list: [
//       'ATABAY',
//       'BALIDBID',
//       'BALUARTE',
//       'BAYBAYADING',
//       'BOGUIBOG',
//       'BULALA-LEGUEY',
//       'CALANGCUASAN',
//       'CULIONG',
//       'DINARATAN',
//       'KALIWAKIW',
//       'KINMARIN',
//       'LUCBUBAN',
//       'MADARANG',
//       'MALIGCONG',
//       'PIAS',
//       'POBLACION NORTE',
//       'POBLACION SUR',
//       'SAN GASPAR',
//       'SAN TIBURCIO',
//       'SORIOAN',
//       'UBBOG',
//     ],
//   },
//   'SAN EMILIO': {
//     province: 'ILOCOS SUR',
//     municipality: 'SAN EMILIO',
//     region: 'REGION I',
//     barangay_list: [
//       'CABAROAN (POB.)',
//       'KALUMSING',
//       'LANCUAS',
//       'MATIBUEY',
//       'PALTOC',
//       'SAN MILIANO',
//       'SIBSIBBU',
//       'TIAGAN',
//     ],
//   },
//   'SAN ESTEBAN': {
//     province: 'ILOCOS SUR',
//     municipality: 'SAN ESTEBAN',
//     region: 'REGION I',
//     barangay_list: [
//       'ANSAD',
//       'APATOT',
//       'BATERIA',
//       'CABAROAN',
//       'CAPPA-CAPPA',
//       'POBLACION',
//       'SAN NICOLAS',
//       'SAN PABLO',
//       'SAN RAFAEL',
//       'VILLA QUIRINO',
//     ],
//   },
//   'SAN ILDEFONSO': {
//     province: 'BULACAN',
//     municipality: 'SAN ILDEFONSO',
//     region: 'REGION III',
//     barangay_list: [
//       'AKLE',
//       'ALAGAO',
//       'ANYATAM',
//       'BAGONG BARRIO',
//       'BASUIT',
//       'BUBULONG MALAKI',
//       'BUBULONG MUNTI',
//       'BUHOL NA MANGGA',
//       'BULUSUKAN',
//       'CALASAG',
//       'CALAWITAN',
//       'CASALAT',
//       'GABIHAN',
//       'GARLANG',
//       'LAPNIT',
//       'MAASIM',
//       'MAKAPILAPIL',
//       'MALIPAMPANG',
//       'MATAAS NA PARANG',
//       'MATIMBUBONG',
//       'NABAONG GARLANG',
//       'PALAPALA',
//       'PASONG BANGKAL',
//       'PINAOD',
//       'POBLACION',
//       'PULONG TAMO',
//       'SAN JUAN',
//       'SANTA CATALINA BATA',
//       'SANTA CATALINA MATANDA',
//       'SAPANG DAYAP',
//       'SAPANG PUTIK',
//       'SAPANG PUTOL',
//       'SUMANDIG',
//       'TELEPATIO',
//       'UMPUCAN',
//       'UPIG',
//     ],
//   },
//   'SAN JUAN (LAPOG)': {
//     province: 'ILOCOS SUR',
//     municipality: 'SAN JUAN (LAPOG)',
//     region: 'REGION I',
//     barangay_list: [
//       'ASILANG',
//       'BACSIL',
//       'BALIW',
//       'BANNUAR (POB.)',
//       'BARBAR',
//       'CABANGLOTAN',
//       'CACANDONGAN',
//       'CAMANGGAAN',
//       'CAMINDOROAN',
//       'CARONOAN',
//       'DARAO',
//       'DARDARAT',
//       'GUIMOD NORTE',
//       'GUIMOD SUR',
//       'IMMAYOS NORTE',
//       'IMMAYOS SUR',
//       'LABNIG',
//       'LAPTING',
//       'LIRA (POB.)',
//       'MALAMIN',
//       'MURAYA',
//       'NAGSABARAN',
//       'NAGSUPOTAN',
//       'PANDAYAN (POB.)',
//       'REFARO',
//       'RESURRECCION (POB.)',
//       'SABANGAN',
//       'SAN ISIDRO',
//       'SAOANG',
//       'SOLOTSOLOT',
//       'SUNGGIAM',
//       'SURNGIT',
//     ],
//   },
//   'SAN VICENTE': {
//     province: 'NORTHERN SAMAR',
//     municipality: 'SAN VICENTE',
//     region: 'REGION VIII',
//     barangay_list: [
//       'DESTACADO POB. (BGY.2)',
//       'MARAGAT',
//       'MONGOL BONGOL POB. (BGY.1)',
//       'PUNTA POB. (BGY.3)',
//       'SANGPUTAN',
//       'SILA',
//       'TARNATE',
//     ],
//   },
//   SANTA: {
//     province: 'ILOCOS SUR',
//     municipality: 'SANTA',
//     region: 'REGION I',
//     barangay_list: [
//       'AMPANDULA',
//       'BANAOANG',
//       'BASUG',
//       'BUCALAG',
//       'CABANGARAN',
//       'CALUNGBOYAN',
//       'CASIBER',
//       'DAMMAY',
//       'LABUT NORTE',
//       'LABUT SUR',
//       'MABILBILA NORTE',
//       'MABILBILA SUR',
//       'MAGSAYSAY DISTRICT (POB.)',
//       'MANUEVA',
//       'MARCOS (POB.)',
//       'NAGPANAOAN',
//       'NAMALANGAN',
//       'ORIBI',
//       'PASUNGOL',
//       'QUEZON (POB.)',
//       'QUIRINO (POB.)',
//       'RANCHO',
//       'RIZAL',
//       'SACUYYA NORTE',
//       'SACUYYA SUR',
//       'TABUCOLAN',
//     ],
//   },
//   'SANTA CATALINA': {
//     province: 'NEGROS ORIENTAL',
//     municipality: 'SANTA CATALINA',
//     region: 'REGION VII',
//     barangay_list: [
//       'ALANGILAN',
//       'AMIO',
//       'BUENAVISTA',
//       'CAIGANGAN',
//       'CARANOCHE',
//       'CAWITAN',
//       'FATIMA',
//       'KABULACAN',
//       'MABUHAY',
//       'MANALONGON',
//       'MANSAGOMAYON',
//       'MILAGROSA',
//       'NAGBALAYE',
//       'NAGBINLOD',
//       'OBAT',
//       'POBLACION',
//       'SAN FRANCISCO',
//       'SAN JOSE',
//       'SAN MIGUEL',
//       'SAN PEDRO',
//       'SANTO ROSARIO',
//       'TALALAK',
//     ],
//   },
//   'SANTA LUCIA': {
//     province: 'ILOCOS SUR',
//     municipality: 'SANTA LUCIA',
//     region: 'REGION I',
//     barangay_list: [
//       'ALINCAOEG',
//       'ANGKILENG',
//       'ARANGIN',
//       'AYUSAN (POB.)',
//       'BANBANABA',
//       'BANI',
//       'BAO-AS',
//       'BARANGOBONG (POB.)',
//       'BULICLIC',
//       'BURGOS (POB.)',
//       'CABARITAN',
//       'CATAYAGAN',
//       'CONCONIG EAST',
//       'CONCONIG WEST',
//       'DAMACUAG',
//       'LUBA',
//       'LUBONG',
//       'NAGREBCAN',
//       'NAGTABLAAN',
//       'NAMATICAN',
//       'NANGALISAN',
//       'PALALI NORTE',
//       'PALALI SUR',
//       'PAOC NORTE',
//       'PAOC SUR',
//       'PARATONG',
//       'PILA EAST',
//       'PILA WEST',
//       'QUINABALAYANGAN',
//       'RONDA',
//       'SABUANAN',
//       'SAN JUAN',
//       'SAN PEDRO',
//       'SAPANG',
//       'SUAGAYAN',
//       'VICAL',
//     ],
//   },
//   'SANTO DOMINGO': {
//     province: 'NUEVA ECIJA',
//     municipality: 'SANTO DOMINGO',
//     region: 'REGION III',
//     barangay_list: [
//       'BALOC',
//       'BUASAO',
//       'BURGOS',
//       'CABUGAO',
//       'CASULUCAN',
//       'COMITANG',
//       'CONCEPCION',
//       'DOLORES',
//       'GENERAL LUNA',
//       'HULO',
//       'MABINI',
//       'MALASIN',
//       'MALAYA (POOK MALAYA)',
//       'MALAYANTOC',
//       'MAMBARAO',
//       'POBLACION',
//       'PULONG BULI',
//       'SAGABA',
//       'SAN AGUSTIN',
//       'SAN FABIAN',
//       'SAN FRANCISCO',
//       'SAN PASCUAL',
//       'SANTA RITA',
//       'SANTO ROSARIO',
//     ],
//   },
//   SIGAY: {
//     province: 'ILOCOS SUR',
//     municipality: 'SIGAY',
//     region: 'REGION I',
//     barangay_list: [
//       'ABACCAN',
//       'MABILEG',
//       'MATALLUCOD',
//       'POBLACION (MADAYAW)',
//       'SAN ELIAS',
//       'SAN RAMON',
//       'SANTO ROSARIO',
//     ],
//   },
//   SINAIT: {
//     province: 'ILOCOS SUR',
//     municipality: 'SINAIT',
//     region: 'REGION I',
//     barangay_list: [
//       'AGUING',
//       'BALIW',
//       'BALLAIGUI (POB.)',
//       'BARACBAC',
//       'BARIKIR',
//       'BATTOG',
//       'BINACUD',
//       'CABANGTALAN',
//       'CABARAMBANAN',
//       'CABULALAAN',
//       'CADANGLAAN',
//       'CALANUTIAN',
//       'CALINGAYAN',
//       'CURTIN',
//       'DADALAQUITEN NORTE',
//       'DADALAQUITEN SUR',
//       'DEAN LEOPOLDO YABES (PUG-OS)',
//       'DUYAYYAT',
//       'JORDAN',
//       'KATIPUNAN',
//       'MACABIAG (POB.)',
//       'MAGSAYSAY',
//       'MARNAY',
//       'MASADAG',
//       'NAGBALIOARTIAN',
//       'NAGCULLOOBAN',
//       'NAGONGBURAN',
//       'NAMNAMA (POB.)',
//       'PACIS',
//       'PARATONG',
//       'PURAG',
//       'QUIBIT-QUIBIT',
//       'QUIMMALLOGONG',
//       'RANG-AY (POB.)',
//       'RICUDO',
//       'SABAÑGAN (MARCOS)',
//       'SALLACAPO',
//       'SANTA CRUZ',
//       'SAPRIANA',
//       'TAPAO',
//       'TEPPENG',
//       'TUBIGAY',
//       'UBBOG',
//       'ZAPAT',
//     ],
//   },
//   SUGPON: {
//     province: 'ILOCOS SUR',
//     municipality: 'SUGPON',
//     region: 'REGION I',
//     barangay_list: [
//       'BALBALAYANG (POB.)',
//       'BANGA',
//       'CAOAYAN',
//       'DANAC',
//       'LICUNGAN (CULLANG)',
//       'PANGOTAN',
//     ],
//   },
//   SUYO: {
//     province: 'ILOCOS SUR',
//     municipality: 'SUYO',
//     region: 'REGION I',
//     barangay_list: [
//       'BARINGCUCURONG',
//       'CABUGAO',
//       'MAN-ATONG',
//       'PATOC-AO',
//       'POBLACION (KIMPUSA)',
//       'SUYO PROPER',
//       'URZADAN',
//       'USO',
//     ],
//   },
//   TAGUDIN: {
//     province: 'ILOCOS SUR',
//     municipality: 'TAGUDIN',
//     region: 'REGION I',
//     barangay_list: [
//       'AG-AGUMAN',
//       'AMBALAYAT',
//       'BARACBAC',
//       'BARIO-AN',
//       'BARITAO',
//       'BECQUES',
//       'BIMMANGA',
//       'BIO',
//       'BITALAG',
//       'BORONO',
//       'BUCAO EAST',
//       'BUCAO WEST',
//       'CABAROAN',
//       'CABUGBUGAN',
//       'CABULANGLANGAN',
//       'DACUTAN',
//       'DARDARAT',
//       'DEL PILAR (POB.)',
//       'FAROLA',
//       'GABUR',
//       'GARITAN',
//       'JARDIN',
//       'LACONG',
//       'LANTAG',
//       'LAS-UD',
//       'LIBTONG',
//       'LUBNAC',
//       'MAGSAYSAY (POB.)',
//       'MALACAÑANG',
//       'PACAC',
//       'PALLOGAN',
//       'PUDOC EAST',
//       'PUDOC WEST',
//       'PULA',
//       'QUIRINO (POB.)',
//       'RANGET',
//       'RIZAL (POB.)',
//       'SALVACION',
//       'SAN MIGUEL',
//       'SAWAT',
//       'TALLAOEN',
//       'TAMPUGO',
//       'TARANGOTONG',
//     ],
//   },
//   'VIGAN': {
//     province: 'ILOCOS SUR',
//     municipality: 'VIGAN',
//     region: 'REGION I',
//     barangay_list: [
//       'AYUSAN NORTE',
//       'AYUSAN SUR',
//       'BARANGAY I (POB.)',
//       'BARANGAY II (POB.)',
//       'BARANGAY III (POB.)',
//       'BARANGAY IV (POB.)',
//       'BARANGAY IX',
//       'BARANGAY V (POB.)',
//       'BARANGAY VI (POB.)',
//       'BARANGAY VII',
//       'BARANGAY VIII',
//       'BARRACA',
//       'BEDDENG DAYA',
//       'BEDDENG LAUD',
//       'BONGTOLAN',
//       'BULALA',
//       'CABALANGEGAN',
//       'CABAROAN DAYA',
//       'CABAROAN LAUD',
//       'CAMANGAAN',
//       'CAPANGPANGAN',
//       'MINDORO',
//       'NAGSANGALAN',
//       'PANTAY DAYA',
//       'PANTAY FATIMA',
//       'PANTAY LAUD',
//       'PAOA',
//       'PARATONG',
//       'PONG-OL',
//       'PUROK-A-BASSIT',
//       'PUROK-A-DACKEL',
//       'RAOIS',
//       'RUGSUANAN',
//       'SALINDEG',
//       'SAN JOSE',
//       'SAN JULIAN NORTE',
//       'SAN JULIAN SUR',
//       'SAN PEDRO',
//       'TAMAG',
//     ],
//   },
//   AGOO: {
//     province: 'LA UNION',
//     municipality: 'AGOO',
//     region: 'REGION I',
//     barangay_list: [
//       'AMBITACAY',
//       'BALAWARTE',
//       'CAPAS',
//       'CONSOLACION (POB.)',
//       'MACALVA CENTRAL',
//       'MACALVA NORTE',
//       'MACALVA SUR',
//       'NAZARENO',
//       'PUROK',
//       'SAN AGUSTIN EAST',
//       'SAN AGUSTIN NORTE',
//       'SAN AGUSTIN SUR',
//       'SAN ANTONINO',
//       'SAN ANTONIO',
//       'SAN FRANCISCO',
//       'SAN ISIDRO',
//       'SAN JOAQUIN NORTE',
//       'SAN JOAQUIN SUR',
//       'SAN JOSE NORTE',
//       'SAN JOSE SUR',
//       'SAN JUAN',
//       'SAN JULIAN CENTRAL',
//       'SAN JULIAN EAST',
//       'SAN JULIAN NORTE',
//       'SAN JULIAN WEST',
//       'SAN MANUEL NORTE',
//       'SAN MANUEL SUR',
//       'SAN MARCOS',
//       'SAN MIGUEL',
//       'SAN NICOLAS CENTRAL (POB.)',
//       'SAN NICOLAS EAST',
//       'SAN NICOLAS NORTE (POB.)',
//       'SAN NICOLAS SUR (POB.)',
//       'SAN NICOLAS WEST',
//       'SAN PEDRO',
//       'SAN ROQUE EAST',
//       'SAN ROQUE WEST',
//       'SAN VICENTE NORTE',
//       'SAN VICENTE SUR',
//       'SANTA ANA',
//       'SANTA BARBARA (POB.)',
//       'SANTA FE',
//       'SANTA MARIA',
//       'SANTA MONICA',
//       'SANTA RITA (NALINAC)',
//       'SANTA RITA EAST',
//       'SANTA RITA NORTE',
//       'SANTA RITA SUR',
//       'SANTA RITA WEST',
//     ],
//   },
//   ARINGAY: {
//     province: 'LA UNION',
//     municipality: 'ARINGAY',
//     region: 'REGION I',
//     barangay_list: [
//       'ALASKA',
//       'BASCA',
//       'DULAO',
//       'GALLANO',
//       'MACABATO',
//       'MANGA',
//       'PANGAO-AOAN EAST',
//       'PANGAO-AOAN WEST',
//       'POBLACION',
//       'SAMARA',
//       'SAN ANTONIO',
//       'SAN BENITO NORTE',
//       'SAN BENITO SUR',
//       'SAN EUGENIO',
//       'SAN JUAN EAST',
//       'SAN JUAN WEST',
//       'SAN SIMON EAST',
//       'SAN SIMON WEST',
//       'SANTA CECILIA',
//       'SANTA LUCIA',
//       'SANTA RITA EAST',
//       'SANTA RITA WEST',
//       'SANTO ROSARIO EAST',
//       'SANTO ROSARIO WEST',
//     ],
//   },
//   BACNOTAN: {
//     province: 'LA UNION',
//     municipality: 'BACNOTAN',
//     region: 'REGION I',
//     barangay_list: [
//       'AGTIPAL',
//       'AROSIP',
//       'BACQUI',
//       'BACSIL',
//       'BAGUTOT',
//       'BALLOGO',
//       'BARORO',
//       'BITALAG',
//       'BULALA',
//       'BURAYOC',
//       'BUSSAOIT',
//       'CABAROAN',
//       'CABARSICAN',
//       'CABUGAO',
//       'CALAUTIT',
//       'CARCARMAY',
//       'CASIAMAN',
//       'GALONGEN',
//       'GUINABANG',
//       'LEGLEG',
//       'LISQUEB',
//       'MABANENGBENG 1ST',
//       'MABANENGBENG 2ND',
//       'MARAGAYAP',
//       'NAGATIRAN',
//       'NAGSARABOAN',
//       'NAGSIMBAANAN',
//       'NANGALISAN',
//       'NARRA',
//       'ORTEGA',
//       'OYA-OY',
//       'PAAGAN',
//       'PANDAN',
//       'PANG-PANG',
//       'POBLACION',
//       'QUIRINO',
//       'RAOIS',
//       'SALINCOB',
//       'SAN MARTIN',
//       'SANTA CRUZ',
//       'SANTA RITA',
//       'SAPILANG',
//       'SAYOAN',
//       'SIPULO',
//       'TAMMOCALAO',
//       'UBBOG',
//       'ZARAGOSA',
//     ],
//   },
//   BAGULIN: {
//     province: 'LA UNION',
//     municipality: 'BAGULIN',
//     region: 'REGION I',
//     barangay_list: [
//       'ALIBANGSAY',
//       'BAAY',
//       'CAMBALY',
//       'CARDIZ',
//       'DAGUP',
//       'LIBBO',
//       'SUYO (POB.)',
//       'TAGUDTUD',
//       'TIO-ANGAN',
//       'WALLAYAN',
//     ],
//   },
//   BALAOAN: {
//     province: 'LA UNION',
//     municipality: 'BALAOAN',
//     region: 'REGION I',
//     barangay_list: [
//       'ALMIEDA',
//       'ANTONINO',
//       'APATUT',
//       'AR-ARAMPANG',
//       'BARACBAC ESTE',
//       'BARACBAC OESTE',
//       'BET-ANG',
//       'BULBULALA',
//       'BUNGOL',
//       'BUTUBUT ESTE',
//       'BUTUBUT NORTE',
//       'BUTUBUT OESTE',
//       'BUTUBUT SUR',
//       'CABUAAN OESTE (POB.)',
//       'CALLIAT',
//       'CALUNGBUYAN',
//       'CAMILING',
//       'DR. CAMILO OSIAS POB. (CABUAAN ESTE)',
//       'GUINABURAN',
//       'MASUPE',
//       'NAGSABARAN NORTE',
//       'NAGSABARAN SUR',
//       'NALASIN',
//       'NAPASET',
//       'PA-O',
//       'PAGBENNECAN',
//       'PAGLEDDEGAN',
//       'PANTAR NORTE',
//       'PANTAR SUR',
//       'PARAOIR',
//       'PATPATA',
//       'SABLUT',
//       'SAN PABLO',
//       'SINAPANGAN NORTE',
//       'SINAPANGAN SUR',
//       'TALLIPUGO',
//     ],
//   },
//   BANGAR: {
//     province: 'LA UNION',
//     municipality: 'BANGAR',
//     region: 'REGION I',
//     barangay_list: [
//       'AGDEPPA',
//       'ALZATE',
//       'BANGAOILAN EAST',
//       'BANGAOILAN WEST',
//       'BARRACA',
//       'CADAPLI',
//       'CAGGAO',
//       'CENTRAL EAST NO. 1 (POB.)',
//       'CENTRAL EAST NO. 2 (POB.)',
//       'CENTRAL WEST NO. 1 (POB.)',
//       'CENTRAL WEST NO. 2 (POB.)',
//       'CENTRAL WEST NO. 3 (POB.)',
//       'CONSUEGRA',
//       'GENERAL PRIM EAST',
//       'GENERAL PRIM WEST',
//       'GENERAL TERRERO',
//       'LUZONG NORTE',
//       'LUZONG SUR',
//       'MARIA CRISTINA EAST',
//       'MARIA CRISTINA WEST',
//       'MINDORO',
//       'NAGSABARAN',
//       'PARATONG NO. 3',
//       'PARATONG NO. 4',
//       'PARATONG NORTE',
//       'QUINTARONG',
//       'REYNA REGENTE',
//       'RISSING',
//       'SAN BLAS',
//       'SAN CRISTOBAL',
//       'SINAPANGAN NORTE',
//       'SINAPANGAN SUR',
//       'UBBOG',
//     ],
//   },
//   BAUANG: {
//     province: 'LA UNION',
//     municipality: 'BAUANG',
//     region: 'REGION I',
//     barangay_list: [
//       'ACAO',
//       'BACCUIT NORTE',
//       'BACCUIT SUR',
//       'BAGBAG',
//       'BALLAY',
//       'BAWANTA',
//       'BOY-UTAN',
//       'BUCAYAB',
//       'CABALAYANGAN',
//       'CABISILAN',
//       'CALUMBAYA',
//       'CARMAY',
//       'CASILAGAN',
//       'CENTRAL EAST (POB.)',
//       'CENTRAL WEST (POB.)',
//       'DILI',
//       'DISSO-OR',
//       'GUERRERO',
//       'LOWER SAN AGUSTIN',
//       'NAGREBCAN',
//       'PAGDALAGAN SUR',
//       'PALINTUCANG',
//       'PALUGSI-LIMMANSANGAN',
//       'PARIAN ESTE',
//       'PARIAN OESTE',
//       'PARINGAO',
//       'PAYOCPOC NORTE ESTE',
//       'PAYOCPOC NORTE OESTE',
//       'PAYOCPOC SUR',
//       'PILAR',
//       'POTTOT',
//       'PUDOC',
//       'PUGO',
//       'QUINAVITE',
//       'SANTA MONICA',
//       'SANTIAGO',
//       'TABERNA',
//       'UPPER SAN AGUSTIN',
//       'URAYONG',
//     ],
//   },
//   CABA: {
//     province: 'LA UNION',
//     municipality: 'CABA',
//     region: 'REGION I',
//     barangay_list: [
//       'BAUTISTA',
//       'GANA',
//       'JUAN CARTAS',
//       'LAS-UD',
//       'LIQUICIA',
//       'POBLACION NORTE',
//       'POBLACION SUR',
//       'SAN CARLOS',
//       'SAN CORNELIO',
//       'SAN FERMIN',
//       'SAN GREGORIO',
//       'SAN JOSE',
//       'SANTIAGO NORTE',
//       'SANTIAGO SUR',
//       'SOBREDILLO',
//       'URAYONG',
//       'WENCESLAO',
//     ],
//   },
//   'SAN FERNANDO': {
//     province: 'LA UNION',
//     municipality: 'SAN FERNANDO',
//     region: 'REGION I',
//     barangay_list: [
//       'ABUT',
//       'APALENG',
//       'BACSIL',
//       'BANGBANGOLAN',
//       'BANGCUSAY',
//       'BARANGAY I (POB.)',
//       'BARANGAY II (POB.)',
//       'BARANGAY III (POB.)',
//       'BARANGAY IV (POB.)',
//       'BARAOAS',
//       'BATO',
//       'BIDAY',
//       'BIRUNGET',
//       'BUNGRO',
//       'CABAROAN (NEGRO)',
//       'CABARSICAN',
//       'CADACLAN',
//       'CALABUGAO',
//       'CAMANSI',
//       'CANAOAY',
//       'CARLATAN',
//       'CATBANGEN',
//       'DALLANGAYAN ESTE',
//       'DALLANGAYAN OESTE',
//       'DALUMPINAS ESTE',
//       'DALUMPINAS OESTE',
//       'ILOCANOS NORTE',
//       'ILOCANOS SUR',
//       'LANGCUAS',
//       'LINGSAT',
//       'MADAYEGDEG',
//       'MAMELTAC',
//       'MASICONG',
//       'NAGYUBUYUBAN',
//       'NAMTUTAN',
//       'NARRA ESTE',
//       'NARRA OESTE',
//       'PACPACO',
//       'PAGDALAGAN',
//       'PAGDARAOAN',
//       'PAGUDPUD',
//       'PAO NORTE',
//       'PAO SUR',
//       'PARIAN',
//       'PIAS',
//       'PORO',
//       'PUSPUS',
//       'SACYUD',
//       'SAGAYAD',
//       'SAN AGUSTIN',
//       'SAN FRANCISCO',
//       'SAN VICENTE',
//       'SANTIAGO NORTE',
//       'SANTIAGO SUR',
//       'SAOAY',
//       'SEVILLA',
//       'SIBOAN-OTONG',
//       'TANQUI',
//       'TANQUIGAN',
//     ],
//   },
//   LUNA: {
//     province: 'APAYAO',
//     municipality: 'LUNA',
//     region: 'CAR',
//     barangay_list: [
//       'BACSAY',
//       'CAGANDUNGAN',
//       'CALABIGAN',
//       'CANGISITAN',
//       'CAPAGAYPAYAN',
//       'DAGUPAN',
//       'LAPPA',
//       'LUYON',
//       'MARAG',
//       'POBLACION',
//       'QUIRINO',
//       'SALVACION',
//       'SAN FRANCISCO',
//       'SAN GREGORIO',
//       'SAN ISIDRO NORTE',
//       'SAN ISIDRO SUR',
//       'SAN SEBASTIAN',
//       'SANTA LINA',
//       'SHALOM',
//       'TUMOG',
//       'TUROD',
//       'ZUMIGUI',
//     ],
//   },
//   NAGUILIAN: {
//     province: 'ISABELA',
//     municipality: 'NAGUILIAN',
//     region: 'REGION II',
//     barangay_list: [
//       'AGUINALDO',
//       'BAGONG SIKAT',
//       'BURGOS',
//       'CABARUAN',
//       'FLORES',
//       'LA UNION',
//       'MAGSAYSAY (POB.)',
//       'MANARING',
//       'MANSIBANG',
//       'MINALLO',
//       'MINANGA',
//       'PALATTAO',
//       'QUEZON (POB.)',
//       'QUINALABASA',
//       'QUIRINO (POB.)',
//       'RANGAYAN',
//       'RIZAL',
//       'ROXAS (POB.)',
//       'SAN MANUEL',
//       'SANTA VICTORIA (VILLA CAPUCHINO)',
//       'SANTO TOMAS',
//       'SUNLIFE',
//       'SURCOC',
//       'TOMINES',
//       'VILLA PAZ',
//     ],
//   },
//   PUGO: {
//     province: 'LA UNION',
//     municipality: 'PUGO',
//     region: 'REGION I',
//     barangay_list: [
//       'AMBALITE',
//       'AMBANGONAN',
//       'CARES',
//       'CUENCA',
//       'DUPLAS',
//       'MAOASOAS NORTE',
//       'MAOASOAS SUR',
//       'PALINA',
//       'POBLACION EAST',
//       'POBLACION WEST',
//       'SAN LUIS',
//       'SAYTAN',
//       'TAVORA EAST',
//       'TAVORA PROPER',
//     ],
//   },
//   'SAN GABRIEL': {
//     province: 'LA UNION',
//     municipality: 'SAN GABRIEL',
//     region: 'REGION I',
//     barangay_list: [
//       'AMONTOC',
//       'APAYAO',
//       'BALBALAYANG',
//       'BAYABAS',
//       'BUCAO',
//       'BUMBUNEG',
//       'DAKING',
//       'LACONG',
//       'LIPAY ESTE',
//       'LIPAY NORTE',
//       'LIPAY PROPER',
//       'LIPAY SUR',
//       'LON-OY',
//       'POBLACION',
//       'POLIPOL',
//     ],
//   },
//   'SAN JUAN': {
//     province: 'ABRA',
//     municipality: 'SAN JUAN',
//     region: 'CAR',
//     barangay_list: [
//       'ABUALAN',
//       'BA-UG',
//       'BADAS',
//       'CABCABORAO',
//       'COLABAOAN',
//       'CULIONG',
//       'DAOIDAO',
//       'GUIMBA',
//       'LAM-AG',
//       'LUMOBANG',
//       'NANGOBONGAN',
//       'PATTAOIG',
//       'POBLACION NORTH',
//       'POBLACION SOUTH',
//       'QUIDAOEN',
//       'SABANGAN',
//       'SILET',
//       'SUPI-IL',
//       'TAGAYTAY',
//     ],
//   },
//   SANTOL: {
//     province: 'LA UNION',
//     municipality: 'SANTOL',
//     region: 'REGION I',
//     barangay_list: [
//       'CORROOY',
//       'LETTAC NORTE',
//       'LETTAC SUR',
//       'MANGAAN',
//       'PAAGAN',
//       'POBLACION',
//       'PUGUIL',
//       'RAMOT',
//       'SAPDAAN',
//       'SASABA',
//       'TUBADAY',
//     ],
//   },
//   SUDIPEN: {
//     province: 'LA UNION',
//     municipality: 'SUDIPEN',
//     region: 'REGION I',
//     barangay_list: [
//       'BIGBIGA',
//       'BULALAAN',
//       'CASTRO',
//       'DUPLAS',
//       'ILOCANO',
//       'IPET',
//       'MALICLICO',
//       'NAMALTUGAN',
//       'OLD CENTRAL',
//       'POBLACION',
//       'PORPORIKET',
//       'SAN FRANCISCO NORTE',
//       'SAN FRANCISCO SUR',
//       'SAN JOSE',
//       'SENGNGAT',
//       'TUROD',
//       'UP-UPLAS',
//     ],
//   },
//   TUBAO: {
//     province: 'LA UNION',
//     municipality: 'TUBAO',
//     region: 'REGION I',
//     barangay_list: [
//       'AMALLAPAY',
//       'ANDUYAN',
//       'CAOIGUE',
//       'FRANCIA SUR',
//       'FRANCIA WEST',
//       'GARCIA',
//       'GONZALES',
//       'HALOG EAST',
//       'HALOG WEST',
//       'LEONES EAST',
//       'LEONES WEST',
//       'LINAPEW',
//       'LLOREN',
//       'MAGSAYSAY',
//       'PIDEG',
//       'POBLACION',
//       'RIZAL',
//       'SANTA TERESA',
//     ],
//   },
//   AGNO: {
//     province: 'PANGASINAN',
//     municipality: 'AGNO',
//     region: 'REGION I',
//     barangay_list: [
//       'ALLABON',
//       'ALOLENG',
//       'BANGAN-ODA',
//       'BARUAN',
//       'BOBOY',
//       'CAYUNGNAN',
//       'DANGLEY',
//       'GAYUSAN',
//       'MACABOBONI',
//       'MAGSAYSAY',
//       'NAMATUCAN',
//       'PATAR',
//       'POBLACION EAST',
//       'POBLACION WEST',
//       'SAN JUAN',
//       'TUPA',
//       'VIGA',
//     ],
//   },
//   AGUILAR: {
//     province: 'PANGASINAN',
//     municipality: 'AGUILAR',
//     region: 'REGION I',
//     barangay_list: [
//       'BAYAOAS',
//       'BAYBAY',
//       'BOCACLIW',
//       'BOCBOC EAST',
//       'BOCBOC WEST',
//       'BUER',
//       'CALSIB',
//       'LAOAG',
//       'MANLOCBOC',
//       'NINOY',
//       'PANACOL',
//       'POBLACION',
//       'POGOMBOA',
//       'POGONSILI',
//       'SAN JOSE',
//       'TAMPAC',
//     ],
//   },
//   'ALAMINOS': {
//     province: 'PANGASINAN',
//     municipality: 'ALAMINOS',
//     region: 'REGION I',
//     barangay_list: [
//       'ALOS',
//       'AMANDIEGO',
//       'AMANGBANGAN',
//       'BALANGOBONG',
//       'BALAYANG',
//       'BALEYADAAN',
//       'BISOCOL',
//       'BOLANEY',
//       'BUED',
//       'CABATUAN',
//       'CAYUCAY',
//       'DULACAC',
//       'INERANGAN',
//       'LANDOC',
//       'LINMANSANGAN',
//       'LUCAP',
//       'MAAWI',
//       'MACATIW',
//       'MAGSAYSAY',
//       'MONA',
//       'PALAMIS',
//       'PANDAN',
//       'PANGAPISAN',
//       'POBLACION',
//       'POCALPOCAL',
//       'POGO',
//       'POLO',
//       'QUIBUAR',
//       'SABANGAN',
//       'SAN ANTONIO (R. MAGSAYSAY)',
//       'SAN JOSE',
//       'SAN ROQUE',
//       'SAN VICENTE',
//       'SANTA MARIA',
//       'TANAYTAY',
//       'TANGCARANG',
//       'TAWINTAWIN',
//       'TELBANG',
//       'VICTORIA',
//     ],
//   },
//   ALCALA: {
//     province: 'CAGAYAN',
//     municipality: 'ALCALA',
//     region: 'REGION II',
//     barangay_list: [
//       'ABBEG',
//       'AFUSING BATO',
//       'AFUSING DAGA',
//       'AGANI',
//       'BACULOD',
//       'BAYBAYOG',
//       'CABULUAN',
//       'CALANTAC',
//       'CARALLANGAN',
//       'CENTRO NORTE (POB.)',
//       'CENTRO SUR (POB.)',
//       'DALAOIG',
//       'DAMUROG',
//       'JURISDICTION',
//       'MALALATAN',
//       'MARABURAB',
//       'MASIN',
//       'PAGBANGKERUAN',
//       'PARED',
//       'PIGGATAN',
//       'PINOPOC',
//       'PUSSIAN',
//       'SAN ESTEBAN',
//       'TAMBAN',
//       'TUPANG',
//     ],
//   },
//   ANDA: {
//     province: 'BOHOL',
//     municipality: 'ANDA',
//     region: 'REGION VII',
//     barangay_list: [
//       'ALMARIA',
//       'BACONG',
//       'BADIANG',
//       'BUENASUERTE',
//       'CANDABONG',
//       'CASICA',
//       'KATIPUNAN',
//       'LINAWAN',
//       'LUNDAG',
//       'POBLACION',
//       'SANTA CRUZ',
//       'SUBA',
//       'TALISAY',
//       'TANOD',
//       'TAWID',
//       'VIRGEN',
//     ],
//   },
//   ASINGAN: {
//     province: 'PANGASINAN',
//     municipality: 'ASINGAN',
//     region: 'REGION I',
//     barangay_list: [
//       'ARISTON ESTE',
//       'ARISTON WESTE',
//       'BANTOG',
//       'BARO',
//       'BOBONAN',
//       'CABALITIAN',
//       'CALEPAAN',
//       'CAROSUCAN NORTE',
//       'CAROSUCAN SUR',
//       'COLDIT',
//       'DOMANPOT',
//       'DUPAC',
//       'MACALONG',
//       'PALARIS',
//       'POBLACION EAST',
//       'POBLACION WEST',
//       'SAN VICENTE ESTE',
//       'SAN VICENTE WESTE',
//       'SANCHEZ',
//       'SOBOL',
//       'TOBOY',
//     ],
//   },
//   BALUNGAO: {
//     province: 'PANGASINAN',
//     municipality: 'BALUNGAO',
//     region: 'REGION I',
//     barangay_list: [
//       'ANGAYAN NORTE',
//       'ANGAYAN SUR',
//       'CAPULAAN',
//       'ESMERALDA',
//       'KITA-KITA',
//       'MABINI',
//       'MAUBAN',
//       'POBLACION',
//       'PUGARO',
//       'RAJAL',
//       'SAN ANDRES',
//       'SAN AURELIO 1ST',
//       'SAN AURELIO 2ND',
//       'SAN AURELIO 3RD',
//       'SAN JOAQUIN',
//       'SAN JULIAN',
//       'SAN LEON',
//       'SAN MARCELINO',
//       'SAN MIGUEL',
//       'SAN RAYMUNDO',
//     ],
//   },
//   BANI: {
//     province: 'PANGASINAN',
//     municipality: 'BANI',
//     region: 'REGION I',
//     barangay_list: [
//       'AMBABAAY',
//       'APORAO',
//       'ARWAS',
//       'BALLAG',
//       'BANOG NORTE',
//       'BANOG SUR',
//       'CALABENG',
//       'CENTRO TOMA',
//       'COLAYO',
//       'DACAP NORTE',
//       'DACAP SUR',
//       'GARRITA',
//       'LUAC',
//       'MACABIT',
//       'MASIDEM',
//       'POBLACION',
//       'QUINAOAYANAN',
//       'RANAO',
//       'RANOM ILOCO',
//       'SAN JOSE',
//       'SAN MIGUEL',
//       'SAN SIMON',
//       'SAN VICENTE',
//       'TIEP',
//       'TIPOR',
//       'TUGUI GRANDE',
//       'TUGUI NORTE',
//     ],
//   },
//   BASISTA: {
//     province: 'PANGASINAN',
//     municipality: 'BASISTA',
//     region: 'REGION I',
//     barangay_list: [
//       'ANAMBONGAN',
//       'BAYOYONG',
//       'CABELDATAN',
//       'DUMPAY',
//       'MALIMPEC EAST',
//       'MAPOLOPOLO',
//       'NALNERAN',
//       'NAVATAT',
//       'OBONG',
//       'OSMENA SR.',
//       'PALMA',
//       'PATACBO',
//       'POBLACION',
//     ],
//   },
//   BAUTISTA: {
//     province: 'PANGASINAN',
//     municipality: 'BAUTISTA',
//     region: 'REGION I',
//     barangay_list: [
//       'ARTACHO',
//       'BALUYOT',
//       'CABUAAN',
//       'CACANDONGAN',
//       'DIAZ',
//       'KETEGAN',
//       'NANDACAN',
//       'NIBALIW NORTE',
//       'NIBALIW SUR',
//       'PALISOC',
//       'POBLACION EAST',
//       'POBLACION WEST',
//       'POGO',
//       'POPONTO',
//       'PRIMICIAS',
//       'SINABAAN',
//       'VACANTE',
//       'VILLANUEVA',
//     ],
//   },
//   BAYAMBANG: {
//     province: 'PANGASINAN',
//     municipality: 'BAYAMBANG',
//     region: 'REGION I',
//     barangay_list: [
//       'ALINGGAN',
//       'AMAMPEREZ',
//       'AMANCOSILING NORTE',
//       'AMANCOSILING SUR',
//       'AMBAYAT I',
//       'AMBAYAT II',
//       'APALEN',
//       'ASIN',
//       'ATAYNAN',
//       'BACNONO',
//       'BALAYBUAYA',
//       'BANABAN',
//       'BANI',
//       'BATANGCAWA',
//       'BELENG',
//       'BICAL NORTE',
//       'BICAL SUR',
//       'BONGATO EAST',
//       'BONGATO WEST',
//       'BUAYAEN',
//       'BUENLAG 1ST',
//       'BUENLAG 2ND',
//       'CADRE SITE',
//       'CARUNGAY',
//       'CATURAY',
//       'DARAWEY (TANGAL)',
//       'DUERA',
//       'DUSOC',
//       'HERMOZA',
//       'IDONG',
//       'INANLORENZANA',
//       'INIRANGAN',
//       'ITON',
//       'LANGIRAN',
//       'LIGUE',
//       'M. H. DEL PILAR',
//       'MACAYOCAYO',
//       'MAGSAYSAY',
//       'MAIGPA',
//       'MALIMPEC',
//       'MALIOER',
//       'MANAGOS',
//       'MANAMBONG NORTE',
//       'MANAMBONG PARTE',
//       'MANAMBONG SUR',
//       'MANGAYAO',
//       'NALSIAN NORTE',
//       'NALSIAN SUR',
//       'PANGDEL',
//       'PANTOL',
//       'PARAGOS',
//       'POBLACION SUR',
//       'PUGO',
//       'REYNADO',
//       'SAN GABRIEL 1ST',
//       'SAN GABRIEL 2ND',
//       'SAN VICENTE',
//       'SANGCAGULIS',
//       'SANLIBO',
//       'SAPANG',
//       'TAMARO',
//       'TAMBAC',
//       'TAMPOG',
//       'TANOLONG',
//       'TATARAO',
//       'TELBANG',
//       'TOCOC EAST',
//       'TOCOC WEST',
//       'WARDING',
//       'WAWA',
//       'ZONE I (POB.)',
//       'ZONE II (POB.)',
//       'ZONE III (POB.)',
//       'ZONE IV (POB.)',
//       'ZONE V (POB.)',
//       'ZONE VI (POB.)',
//       'ZONE VII (POB.)',
//     ],
//   },
//   BINALONAN: {
//     province: 'PANGASINAN',
//     municipality: 'BINALONAN',
//     region: 'REGION I',
//     barangay_list: [
//       'BALANGOBONG',
//       'BUED',
//       'BUGAYONG',
//       'CAMANGAAN',
//       'CANARVACANAN',
//       'CAPAS',
//       'CILI',
//       'DUMAYAT',
//       'LINMANSANGAN',
//       'MANGCASUY',
//       'MORENO',
//       'PASILENG NORTE',
//       'PASILENG SUR',
//       'POBLACION',
//       'SAN FELIPE CENTRAL',
//       'SAN FELIPE SUR',
//       'SAN PABLO',
//       'SANTA CATALINA',
//       'SANTA MARIA NORTE',
//       'SANTIAGO',
//       'SANTO NIÑO',
//       'SUMABNIT',
//       'TABUYOC',
//       'VACANTE',
//     ],
//   },
//   BINMALEY: {
//     province: 'PANGASINAN',
//     municipality: 'BINMALEY',
//     region: 'REGION I',
//     barangay_list: [
//       'AMANCORO',
//       'BALAGAN',
//       'BALOGO',
//       'BASING',
//       'BAYBAY LOPEZ',
//       'BAYBAY POLONG',
//       'BIEC',
//       'BUENLAG',
//       'CALIT',
//       'CALOOCAN DUPO',
//       'CALOOCAN NORTE',
//       'CALOOCAN SUR',
//       'CAMALEY',
//       'CANAOALAN',
//       'DULAG',
//       'GAYAMAN',
//       'LINOC',
//       'LOMBOY',
//       'MALINDONG',
//       'MANAT',
//       'NAGPALANGAN',
//       'NAGUILAYAN',
//       'PALLAS',
//       'PAPAGUEYAN',
//       'PARAYAO',
//       'POBLACION',
//       'POTOTAN',
//       'SABANGAN',
//       'SALAPINGAO',
//       'SAN ISIDRO NORTE',
//       'SAN ISIDRO SUR',
//       'SANTA ROSA',
//       'TOMBOR',
//     ],
//   },
//   BOLINAO: {
//     province: 'PANGASINAN',
//     municipality: 'BOLINAO',
//     region: 'REGION I',
//     barangay_list: [
//       'ARNEDO',
//       'BALINGASAY',
//       'BINABALIAN',
//       'CABUYAO',
//       'CATUDAY',
//       'CATUNGI',
//       'CONCORDIA (POB.)',
//       'CULANG',
//       'DEWEY',
//       'ESTANZA',
//       'GERMINAL (POB.)',
//       'GOYODEN',
//       'ILOGMALINO',
//       'LAMBES (LAMES)',
//       'LIWA-LIWA',
//       'LUCERO',
//       'LUCIENTE 1.0',
//       'LUCIENTE 2.0',
//       'LUNA',
//       'PATAR',
//       'PILAR',
//       'SALUD',
//       'SAMANG NORTE',
//       'SAMANG SUR',
//       'SAMPALOC',
//       'SAN ROQUE',
//       'TARA',
//       'TUPA',
//       'VICTORY',
//       'ZARAGOZA',
//     ],
//   },
//   BUGALLON: {
//     province: 'PANGASINAN',
//     municipality: 'BUGALLON',
//     region: 'REGION I',
//     barangay_list: [
//       'ANGARIAN',
//       'ASINAN',
//       'BACABAC',
//       'BANAGA',
//       'BOLAOEN',
//       'BUENLAG',
//       'CABAYAOASAN',
//       'CAYANGA',
//       'GUESET',
//       'HACIENDA',
//       'LAGUIT CENTRO',
//       'LAGUIT PADILLA',
//       'MAGTAKING',
//       'PANGASCASAN',
//       'PANTAL',
//       'POBLACION',
//       'POLONG',
//       'PORTIC',
//       'SALASA',
//       'SALOMAGUE NORTE',
//       'SALOMAGUE SUR',
//       'SAMAT',
//       'SAN FRANCISCO',
//       'UMANDAY',
//     ],
//   },
//   CALASIAO: {
//     province: 'PANGASINAN',
//     municipality: 'CALASIAO',
//     region: 'REGION I',
//     barangay_list: [
//       'AMBONAO',
//       'AMBUETEL',
//       'BANAOANG',
//       'BUED',
//       'BUENLAG',
//       'CABILOCAAN',
//       'DINALAOAN',
//       'DOYONG',
//       'GABON',
//       'LASIP',
//       'LONGOS',
//       'LUMBANG',
//       'MACABITO',
//       'MALABAGO',
//       'MANCUP',
//       'NAGSAING',
//       'NALSIAN',
//       'POBLACION EAST',
//       'POBLACION WEST',
//       'QUESBAN',
//       'SAN MIGUEL',
//       'SAN VICENTE',
//       'SONGKOY',
//       'TALIBAEW',
//     ],
//   },
//   'DAGUPAN': {
//     province: 'PANGASINAN',
//     municipality: 'DAGUPAN',
//     region: 'REGION I',
//     barangay_list: [
//       'BACAYAO NORTE',
//       'BACAYAO SUR',
//       'BARANGAY I (T. BUGALLON)',
//       'BARANGAY II (NUEVA)',
//       'BARANGAY IV (ZAMORA)',
//       'BOLOSAN',
//       'BONUAN BINLOC',
//       'BONUAN BOQUIG',
//       'BONUAN GUESET',
//       'CALMAY',
//       'CARAEL',
//       'CARANGLAAN',
//       'HERRERO',
//       'LASIP CHICO',
//       'LASIP GRANDE',
//       'LOMBOY',
//       'LUCAO',
//       'MALUED',
//       'MAMALINGLING',
//       'MANGIN',
//       'MAYOMBO',
//       'PANTAL',
//       'POBLACION OESTE',
//       'POGO CHICO',
//       'POGO GRANDE',
//       'PUGARO SUIT',
//       'SALAPINGAO',
//       'SALISAY',
//       'TAMBAC',
//       'TAPUAC',
//       'TEBENG',
//     ],
//   },
//   DASOL: {
//     province: 'PANGASINAN',
//     municipality: 'DASOL',
//     region: 'REGION I',
//     barangay_list: [
//       'ALILAO',
//       'AMALBALAN',
//       'BOBONOT',
//       'EGUIA',
//       'GAIS-GUIPE',
//       'HERMOSA',
//       'MACALANG',
//       'MAGSAYSAY',
//       'MALACAPAS',
//       'MALIMPIN',
//       'OSMEÑA',
//       'PETAL',
//       'POBLACION',
//       'SAN VICENTE',
//       'TAMBAC',
//       'TAMBOBONG',
//       'ULI',
//       'VIGA',
//     ],
//   },
//   INFANTA: {
//     province: 'QUEZON',
//     municipality: 'INFANTA',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'ABIAWIN',
//       'AGOS-AGOS',
//       'ALITAS',
//       'AMOLONGIN',
//       'ANIBONG',
//       'ANTIKIN',
//       'BACONG',
//       'BALOBO',
//       'BANTILAN',
//       'BANUGAO',
//       'BATICAN',
//       'BINONOAN',
//       'BINULASAN',
//       'BOBOIN',
//       'CATAMBUNGAN',
//       'CAWAYNIN',
//       'COMON',
//       'DINAHICAN',
//       'GUMIAN',
//       'ILOG',
//       'INGAS',
//       'LANGGAS',
//       'LIBJO',
//       'LUAL',
//       'MAGSAYSAY',
//       'MAYPULOT',
//       'MISWA',
//       'PILAWAY',
//       'PINAGLAPATAN',
//       'POBLACION 1 (BARANGAY 1)',
//       'POBLACION 38 (POBLACION BARANGAY 2)',
//       'POBLACION 39 (POBLACION BARANGAY 3)',
//       'PULO',
//       'SILANGAN',
//       'TONGOHIN',
//       'TUDTURAN',
//     ],
//   },
//   LABRADOR: {
//     province: 'PANGASINAN',
//     municipality: 'LABRADOR',
//     region: 'REGION I',
//     barangay_list: [
//       'BOLO',
//       'BONGALON',
//       'DULIG',
//       'LAOIS',
//       'MAGSAYSAY',
//       'POBLACION',
//       'SAN GONZALO',
//       'SAN JOSE',
//       'TOBUAN',
//       'UYONG',
//     ],
//   },
//   LAOAC: {
//     province: 'PANGASINAN',
//     municipality: 'LAOAC',
//     region: 'REGION I',
//     barangay_list: [
//       'ANIS',
//       'BALLIGI',
//       'BANUAR',
//       'BOTIQUE',
//       'CAARINGAYAN',
//       'CABILAOAN WEST',
//       'CABULALAAN',
//       'CALAOAGAN',
//       'CALMAY',
//       'CASAMPAGAAN',
//       'CASANESTEBANAN',
//       'CASANTIAGOAN',
//       'DOMINGO ALARCIO (CABILAOAN EAST)',
//       'INMANDUYAN',
//       'LEBUEG',
//       'MARABOC',
//       'NANBAGATAN',
//       'PANAGA',
//       'POBLACION (LAOAC)',
//       'TALOGTOG',
//       'TURKO',
//       'YATYAT',
//     ],
//   },
//   LINGAYEN: {
//     province: 'PANGASINAN',
//     municipality: 'LINGAYEN',
//     region: 'REGION I',
//     barangay_list: [
//       'ALIWEKWEK',
//       'BAAY',
//       'BALANGOBONG',
//       'BALOCOC',
//       'BANTAYAN',
//       'BASING',
//       'CAPANDANAN',
//       'DOMALANDAN CENTER',
//       'DOMALANDAN EAST',
//       'DOMALANDAN WEST',
//       'DORONGAN',
//       'DULAG',
//       'ESTANZA',
//       'LASIP',
//       'LIBSONG EAST',
//       'LIBSONG WEST',
//       'MALAWA',
//       'MALIMPUEC',
//       'MANIBOC',
//       'MATALAVA',
//       'NAGUELGUEL',
//       'NAMOLAN',
//       'PANGAPISAN NORTH',
//       'PANGAPISAN SUR',
//       'POBLACION',
//       'QUIBAOL',
//       'ROSARIO',
//       'SABANGAN',
//       'TALOGTOG',
//       'TONTON',
//       'TUMBAR',
//       'WAWA',
//     ],
//   },
//   MABINI: {
//     province: 'BOHOL',
//     municipality: 'MABINI',
//     region: 'REGION VII',
//     barangay_list: [
//       'ABACA',
//       'ABAD SANTOS',
//       'AGUIPO',
//       'BAYBAYON',
//       'BULAWAN',
//       'CABIDIAN',
//       'CAWAYANAN',
//       'CONCEPCION (BANLAS)',
//       'DEL MAR',
//       'LUNGSODA-AN',
//       'MARCELO',
//       'MINOL',
//       'PARAISO',
//       'POBLACION I',
//       'POBLACION II',
//       'SAN ISIDRO',
//       'SAN JOSE',
//       'SAN RAFAEL',
//       'SAN ROQUE (CABULAO)',
//       'TAMBO',
//       'TANGKIGAN',
//       'VALAGA',
//     ],
//   },
//   MALASIQUI: {
//     province: 'PANGASINAN',
//     municipality: 'MALASIQUI',
//     region: 'REGION I',
//     barangay_list: [
//       'ABONAGAN',
//       'AGDAO',
//       'ALACAN',
//       'ALIAGA',
//       'AMACALAN',
//       'ANOLID',
//       'APAYA',
//       'ASIN ESTE',
//       'ASIN WESTE',
//       'BACUNDAO ESTE',
//       'BACUNDAO WESTE',
//       'BAKITIW',
//       'BALITE',
//       'BANAWANG',
//       'BARANG',
//       'BAWER',
//       'BINALAY',
//       'BOBON',
//       'BOLAOIT',
//       'BONGAR',
//       'BUTAO',
//       'CABATLING',
//       'CABUELDATAN',
//       'CALBUEG',
//       'CANAN NORTE',
//       'CANAN SUR',
//       'CAWAYAN BOGTONG',
//       'DON PEDRO',
//       'GATANG',
//       'GOLIMAN',
//       'GOMEZ',
//       'GUILIG',
//       'ICAN',
//       'INGALAGALA',
//       'LAREG-LAREG',
//       'LASIP',
//       'LEPA',
//       'LOQUEB ESTE',
//       'LOQUEB NORTE',
//       'LOQUEB SUR',
//       'LUNEC',
//       'MABULITEC',
//       'MALIMPEC',
//       'MANGGAN-DAMPAY',
//       'NALSIAN NORTE',
//       'NALSIAN SUR',
//       'NANCAPIAN',
//       'NANSANGAAN',
//       'OLEA',
//       'PACUAN',
//       'PALAPAR NORTE',
//       'PALAPAR SUR',
//       'PALONG',
//       'PAMARANUM',
//       'PASIMA',
//       'PAYAR',
//       'POBLACION',
//       'POLONG NORTE',
//       'POLONG SUR',
//       'POTIOCAN',
//       'SAN JULIAN',
//       'TABO-SILI',
//       'TALOSPATANG',
//       'TALOY',
//       'TALOYAN',
//       'TAMBAC',
//       'TOBOR',
//       'TOLONGUAT',
//       'TOMLING',
//       'UMANDO',
//       'VIADO',
//       'WAIG',
//       'WAREY',
//     ],
//   },
//   MANAOAG: {
//     province: 'PANGASINAN',
//     municipality: 'MANAOAG',
//     region: 'REGION I',
//     barangay_list: [
//       'BABASIT',
//       'BAGUINAY',
//       'BARITAO',
//       'BISAL',
//       'BUCAO',
//       'CABANBANAN',
//       'CALAOCAN',
//       'INAMOTAN',
//       'LELEMAAN',
//       'LICSI',
//       'LIPIT NORTE',
//       'LIPIT SUR',
//       'MATOLONG',
//       'MERMER',
//       'NALSIAN',
//       'ORAAN EAST',
//       'ORAAN WEST',
//       'PANTAL',
//       'PAO',
//       'PARIAN',
//       'POBLACION',
//       'PUGARO',
//       'SAN RAMON',
//       'SANTA INES',
//       'SAPANG',
//       'TEBUEL',
//     ],
//   },
//   MANGALDAN: {
//     province: 'PANGASINAN',
//     municipality: 'MANGALDAN',
//     region: 'REGION I',
//     barangay_list: [
//       'ALITAYA',
//       'AMANSABINA',
//       'ANOLID',
//       'BANAOANG',
//       'BANTAYAN',
//       'BARI',
//       'BATENG',
//       'BUENLAG',
//       'DAVID',
//       'EMBARCADERO',
//       'GUEGUESANGEN',
//       'GUESANG',
//       'GUIGUILONEN',
//       'GUILIG',
//       'INLAMBO',
//       'LANAS',
//       'LANDAS',
//       'MAASIN',
//       'MACAYUG',
//       'MALABAGO',
//       'NAVALUAN',
//       'NIBALIW',
//       'OSIEM',
//       'PALUA',
//       'POBLACION',
//       'POGO',
//       'SALAAN',
//       'SALAY',
//       'TALOGTOG',
//       'TEBAG',
//     ],
//   },
//   MANGATAREM: {
//     province: 'PANGASINAN',
//     municipality: 'MANGATAREM',
//     region: 'REGION I',
//     barangay_list: [
//       'ANDANGIN',
//       'ARELLANO STREET (POB.)',
//       'BANTAY',
//       'BANTOCALING',
//       'BARACBAC',
//       'BOGTONG BOLO',
//       'BOGTONG BUNAO',
//       'BOGTONG CENTRO',
//       'BOGTONG NIOG',
//       'BOGTONG SILAG',
//       'BUAYA',
//       'BUENLAG',
//       'BUENO',
//       'BUNAGAN',
//       'BUNLALACAO',
//       'BURGOS STREET (POB.)',
//       'CABALUYAN 1ST',
//       'CABALUYAN 2ND',
//       'CABARABUAN',
//       'CABARUAN',
//       'CABAYAOASAN',
//       'CABAYUGAN',
//       'CACAOITEN',
//       'CALUMBOYAN NORTE',
//       'CALUMBOYAN SUR',
//       'CALVO (POB.)',
//       'CASILAGAN',
//       'CATARATARAAN',
//       'CATURAY NORTE',
//       'CATURAY SUR',
//       'CAVIERNESAN',
//       'DORONGAN KETAKET',
//       'DORONGAN LINMANSANGAN',
//       'DORONGAN PUNTA',
//       'DORONGAN SAWAT',
//       'DORONGAN VALERIO',
//       'GENERAL LUNA (POB.)',
//       'HISTORIA',
//       'LAWAK LANGKA',
//       'LINMANSANGAN',
//       'LOPEZ (POB.)',
//       'MABINI (POB.)',
//       'MACARANG',
//       'MALABOBO',
//       'MALIBONG',
//       'MALUNEC',
//       'MARAVILLA (POB.)',
//       'MARAVILLA-ARELLANO EXT. (POB.)',
//       'MUELANG',
//       'NAGUILAYAN EAST',
//       'NAGUILAYAN WEST',
//       'NANCASALAN',
//       'NIOG-CABISON-BULANEY',
//       'OLEGARIO-CAOILE (POB.)',
//       'OLO CACAMPOSAN',
//       'OLO CAFABROSAN',
//       'OLO CAGARLITAN',
//       'OSMEÑA (POB.)',
//       'PACALAT',
//       'PAMPANO',
//       'PARIAN',
//       'PAUL',
//       'PEANIA PEDANIA (BEDANIA)',
//       'POGON-ANIAT',
//       'POGON-LOMBOY (POB.)',
//       'PONGLO-BALEG',
//       'PONGLO-MUELAG',
//       'QUETEGAN (POGON-BALEG)',
//       'QUEZON (POB.)',
//       'SALAVANTE',
//       'SAPANG',
//       'SONSON ONGKIT',
//       'SUACO',
//       'TAGAC',
//       'TAKIPAN',
//       'TALOGTOG',
//       'TOCOC BARIKIR',
//       'TORRE 1ST',
//       'TORRE 2ND',
//       'TORRES BUGALLON (POB.)',
//       'UMANGAN',
//       'ZAMORA (POB.)',
//     ],
//   },
//   MAPANDAN: {
//     province: 'PANGASINAN',
//     municipality: 'MAPANDAN',
//     region: 'REGION I',
//     barangay_list: [
//       'AMANOAOAC',
//       'APAYA',
//       'ASERDA',
//       'BALOLING',
//       'CORAL',
//       'GOLDEN',
//       'JIMENEZ',
//       'LAMBAYAN',
//       'LUYAN (LUYAN SOUTH)',
//       'NILOMBOT',
//       'PIAS',
//       'POBLACION',
//       'PRIMICIAS',
//       'SANTA MARIA (LUYAN NORTH)',
//       'TORRES',
//     ],
//   },
//   NATIVIDAD: {
//     province: 'PANGASINAN',
//     municipality: 'NATIVIDAD',
//     region: 'REGION I',
//     barangay_list: [
//       'BARANGOBONG',
//       'BATCHELOR EAST',
//       'BATCHELOR WEST',
//       'BURGOS (SAN NARCISO)',
//       'CACANDUNGAN',
//       'CALAPUGAN',
//       'CANAREM',
//       'LUNA',
//       'POBLACION EAST',
//       'POBLACION WEST',
//       'RIZAL',
//       'SALUD',
//       'SAN EUGENIO',
//       'SAN MACARIO NORTE',
//       'SAN MACARIO SUR',
//       'SAN MAXIMO',
//       'SAN MIGUEL',
//       'SILAG',
//     ],
//   },
//   POZORRUBIO: {
//     province: 'PANGASINAN',
//     municipality: 'POZORRUBIO',
//     region: 'REGION I',
//     barangay_list: [
//       'ALIPANGPANG',
//       'AMAGBAGAN',
//       'BALACAG',
//       'BANDING',
//       'BANTUGAN',
//       'BATAKIL',
//       'BOBONAN',
//       'BUNEG',
//       'CABLONG',
//       'CASANFERNANDOAN',
//       'CASTAÑO',
//       'DILAN',
//       'DON BENITO',
//       'HAWAY',
//       'IMBALBALATONG',
//       'INOMAN',
//       'LAOAC',
//       'MAAMBAL',
//       'MALASIN',
//       'MALOKIAT',
//       'MANAOL',
//       'NAMA',
//       'NANTANGALAN',
//       'PALACPALAC',
//       'PALGUYOD',
//       'POBLACION I',
//       'POBLACION II',
//       'POBLACION III',
//       'POBLACION IV',
//       'ROSARIO',
//       'SUGCONG',
//       'TALOGTOG',
//       'TULNAC',
//       'VILLEGAS',
//     ],
//   },
//   ROSALES: {
//     province: 'PANGASINAN',
//     municipality: 'ROSALES',
//     region: 'REGION I',
//     barangay_list: [
//       'ACOP',
//       'BAKITBAKIT',
//       'BALINGCANAWAY',
//       'CABALAOANGAN NORTE',
//       'CABALAOANGAN SUR',
//       'CALANUTAN (DON FELIX COLOMA)',
//       'CAMANGAAN',
//       'CAPITAN TOMAS',
//       'CARMAY EAST',
//       'CARMAY WEST',
//       'CARMEN EAST',
//       'CARMEN WEST',
//       'CASANICOLASAN',
//       'COLILING',
//       'DON ANTONIO VILLAGE',
//       'GUILING',
//       'PALAKIPAK',
//       'PANGAOAN',
//       'RABAGO',
//       'RIZAL',
//       'SALVACION',
//       'SAN ANGEL',
//       'SAN ANTONIO',
//       'SAN BARTOLOME',
//       'SAN ISIDRO',
//       'SAN LUIS',
//       'SAN PEDRO EAST',
//       'SAN PEDRO WEST',
//       'SAN VICENTE',
//       'STATION DISTRICT',
//       'TOMANA EAST',
//       'TOMANA WEST',
//       'ZONE I (POB.)',
//       'ZONE II (POB.)',
//       'ZONE III (POB.)',
//       'ZONE IV (POB.)',
//       'ZONE V (POB.)',
//     ],
//   },
//   'SAN CARLOS': {
//     province: 'NEGROS OCCIDENTAL',
//     municipality: 'SAN CARLOS',
//     region: 'REGION VI',
//     barangay_list: [
//       'BAGONBON',
//       'BARANGAY I (POB.)',
//       'BARANGAY II (POB.)',
//       'BARANGAY III (POB.)',
//       'BARANGAY IV (POB.)',
//       'BARANGAY V (POB.)',
//       'BARANGAY VI (POB.)',
//       'BULUANGAN',
//       'CODCOD',
//       'ERMITA',
//       'GUADALUPE',
//       'NATABAN',
//       'PALAMPAS',
//       'PROSPERIDAD',
//       'PUNAO',
//       'QUEZON',
//       'RIZAL',
//       'SAN JUAN',
//     ],
//   },
//   'SAN FABIAN': {
//     province: 'PANGASINAN',
//     municipality: 'SAN FABIAN',
//     region: 'REGION I',
//     barangay_list: [
//       'ALACAN',
//       'AMBALANGAN-DALIN',
//       'ANGIO',
//       'ANONANG',
//       'ARAMAL',
//       'BIGBIGA',
//       'BINDAY',
//       'BOLAOEN',
//       'BOLASI',
//       'CABARUAN',
//       'CAYANGA',
//       'COLISAO',
//       'GOMOT',
//       'INMALOG',
//       'INMALOG NORTE',
//       'LEKEP-BUTAO',
//       'LIPIT-TOMEENG',
//       'LONGOS',
//       'LONGOS PROPER',
//       'LONGOS-AMANGONAN-PARAC-PARAC FABRICA',
//       'MABILAO',
//       'NIBALIW CENTRAL',
//       'NIBALIW EAST',
//       'NIBALIW MAGLIBA',
//       'NIBALIW NARVARTE (NIBALIW WEST COMPOUND)',
//       'NIBALIW VIDAL (NIBALIW WEST PROPER)',
//       'PALAPAD',
//       'POBLACION',
//       'RABON',
//       'SAGUD-BAHLEY',
//       'SOBOL',
//       'TEMPRA-GUILIG',
//       'TIBLONG',
//       'TOCOK',
//     ],
//   },
//   'SAN JACINTO': {
//     province: 'MASBATE',
//     municipality: 'SAN JACINTO',
//     region: 'REGION V',
//     barangay_list: [
//       'ALMIÑE',
//       'BAGACAY',
//       'BAGAHANGLAD',
//       'BARTOLABAC',
//       'BURGOS',
//       'CALIPAT-AN',
//       'DANAO',
//       'DISTRICT I (POB.)',
//       'DISTRICT II (POB.)',
//       'DISTRICT III (POB.)',
//       'DISTRICT IV (POB.)',
//       'DORONG-AN DAPLIAN',
//       'INTERIOR',
//       'JAGNA-AN',
//       'LUNA',
//       'MABINI',
//       'PIÑA',
//       'ROOSEVELT',
//       'SAN ISIDRO',
//       'SANTA ROSA',
//       'WASHINGTON',
//     ],
//   },
//   'SAN MANUEL': {
//     province: 'TARLAC',
//     municipality: 'SAN MANUEL',
//     region: 'REGION III',
//     barangay_list: [
//       'COLUBOT',
//       'LANAT',
//       'LEGASPI',
//       'MANGANDINGAY',
//       'MATARANNOC',
//       'PACPACO',
//       'POBLACION',
//       'SALCEDO',
//       'SAN AGUSTIN',
//       'SAN FELIPE',
//       'SAN JACINTO',
//       'SAN MIGUEL',
//       'SAN NARCISO',
//       'SAN VICENTE',
//       'SANTA MARIA',
//     ],
//   },
//   'SAN QUINTIN': {
//     province: 'ABRA',
//     municipality: 'SAN QUINTIN',
//     region: 'CAR',
//     barangay_list: [
//       'LABAAN',
//       'PALANG',
//       'PANTOC',
//       'POBLACION',
//       'TANGADAN',
//       'VILLA MERCEDES',
//     ],
//   },
//   'SANTA BARBARA': {
//     province: 'ILOILO',
//     municipality: 'SANTA BARBARA',
//     region: 'REGION VI',
//     barangay_list: [
//       'AGUSIPAN',
//       'AGUTAYAN',
//       'BAGUMBAYAN',
//       'BALABAG',
//       'BALIBAGAN ESTE',
//       'BALIBAGAN OESTE',
//       'BAN-AG',
//       'BANTAY',
//       'BARANGAY ZONE I (POB.)',
//       'BARANGAY ZONE II (POB.)',
//       'BARANGAY ZONE III (POB.)',
//       'BARANGAY ZONE IV (POB.)',
//       'BARANGAY ZONE V (POB.)',
//       'BARANGAY ZONE VI (POB.)',
//       'BARASAN ESTE',
//       'BARASAN OESTE',
//       'BINANGKILAN',
//       'BITAOG-TAYTAY',
//       'BOLONG ESTE',
//       'BOLONG OESTE',
//       'BUAYAHON',
//       'BUYO',
//       'CABUGAO NORTE',
//       'CABUGAO SUR',
//       'CADAGMAYAN NORTE',
//       'CADAGMAYAN SUR',
//       'CAFE',
//       'CALABOA ESTE',
//       'CALABOA OESTE',
//       'CAMAMBUGAN',
//       'CANIPAYAN',
//       'CONAYNAY',
//       'DAGA',
//       'DALID',
//       'DUYANDUYAN',
//       'GEN. MARTIN T. DELGADO',
//       'GUNO',
//       'INANGAYAN',
//       'JIBAO-AN',
//       'LACADON',
//       'LANAG',
//       'LUPA',
//       'MAGANCINA',
//       'MALAWOG',
//       'MAMBUYO',
//       'MANHAYANG',
//       'MIRAGA-GUIBUANGAN',
//       'NASUGBAN',
//       'OMAMBOG',
//       'PAL-AGON',
//       'PUNGSOD',
//       'SAN SEBASTIAN',
//       'SANGCATE',
//       'TAGSING',
//       'TALANGHAUAN',
//       'TALONGADIAN',
//       'TIGTIG',
//       'TUBURAN',
//       'TUGAS',
//       'TUNGAY',
//     ],
//   },
//   SUAL: {
//     province: 'PANGASINAN',
//     municipality: 'SUAL',
//     region: 'REGION I',
//     barangay_list: [
//       'BAQUIOEN',
//       'BAYBAY NORTE',
//       'BAYBAY SUR',
//       'BOLAOEN',
//       'CABALITIAN',
//       'CALUMBUYAN',
//       'CAMAGSINGALAN',
//       'CAOAYAN',
//       'CAPANTOLAN',
//       'MACAYCAYAWAN',
//       'PAITAN EAST',
//       'PAITAN WEST',
//       'PANGASCASAN',
//       'POBLACION',
//       'SANTO DOMINGO',
//       'SESELANGEN',
//       'SIOASIO EAST',
//       'SIOASIO WEST',
//       'VICTORIA',
//     ],
//   },
//   TAYUG: {
//     province: 'PANGASINAN',
//     municipality: 'TAYUG',
//     region: 'REGION I',
//     barangay_list: [
//       'AGNO',
//       'AMISTAD',
//       'BARANGAY A (POB.)',
//       'BARANGAY B (POB.)',
//       'BARANGAY C (POB.)',
//       'BARANGAY D (POB.)',
//       'BARANGOBONG',
//       'C. LICHAUCO',
//       'CARRIEDO',
//       'EVANGELISTA',
//       'GUZON',
//       'LAWAK',
//       'LEGASPI',
//       'LIBERTAD',
//       'MAGALLANES',
//       'PANGANIBAN',
//       'SALENG',
//       'SANTO DOMINGO',
//       'TOKETEC',
//       'TRENCHERA',
//       'ZAMORA',
//     ],
//   },
//   UMINGAN: {
//     province: 'PANGASINAN',
//     municipality: 'UMINGAN',
//     region: 'REGION I',
//     barangay_list: [
//       'ABOT MOLINA',
//       'ALO-O',
//       'AMARONAN',
//       'ANNAM',
//       'BANTUG',
//       'BARACBAC',
//       'BARAT',
//       'BUENAVISTA',
//       'CABALITIAN',
//       'CABANGARAN',
//       'CABARUAN',
//       'CABATUAN',
//       'CADIZ',
//       'CALITLITAN',
//       'CAPAS',
//       'CARAYUNGAN SUR',
//       'CAROSALESAN',
//       'CASILAN',
//       'CAURDANETAAN',
//       'CONCEPCION',
//       'DECRETO',
//       'DEL ROSARIO',
//       'DIAZ',
//       'DIKET',
//       'DON JUSTO ABALOS (CAROAN)',
//       'DON MONTANO',
//       'ESPERANZA',
//       'EVANGELISTA',
//       'FLORES',
//       'FULGOSINO',
//       'GONZALES',
//       'LA PAZ',
//       'LABUAN',
//       'LAUREN',
//       'LUBONG',
//       'LUNA ESTE',
//       'LUNA WESTE',
//       'MANTACDANG',
//       'MASEIL-SEIL',
//       'NAMPALCAN',
//       'NANCALABASAAN',
//       'PANGANGAAN',
//       'PAPALLASEN',
//       'PEMIENTA',
//       'POBLACION EAST',
//       'POBLACION WEST',
//       'PRADO',
//       'RESURRECCION',
//       'RICOS',
//       'SAN ANDRES',
//       'SAN JUAN',
//       'SAN LEON',
//       'SAN PABLO',
//       'SAN VICENTE',
//       'SANTA MARIA',
//       'SANTA ROSA',
//       'SINABAAN',
//       'TANGGAL SAWANG',
//     ],
//   },
//   URBIZTONDO: {
//     province: 'PANGASINAN',
//     municipality: 'URBIZTONDO',
//     region: 'REGION I',
//     barangay_list: [
//       'ANGATEL',
//       'BALANGAY',
//       'BATANGCAOA',
//       'BAUG',
//       'BAYAOAS',
//       'BITUAG',
//       'CAMAMBUGAN',
//       'DALANGIRING',
//       'DUPLAC',
//       'GALARIN',
//       'GUETEB',
//       'MALACA',
//       'MALAYO',
//       'MALIBONG',
//       'PASIBI EAST',
//       'PASIBI WEST',
//       'PISUAC',
//       'POBLACION',
//       'REAL',
//       'SALAVANTE',
//       'SAWAT',
//     ],
//   },
//   'URDANETA': {
//     province: 'PANGASINAN',
//     municipality: 'URDANETA',
//     region: 'REGION I',
//     barangay_list: [
//       'ANONAS',
//       'BACTAD EAST',
//       'BAYAOAS',
//       'BOLAOEN',
//       'CABARUAN',
//       'CABULOAN',
//       'CAMANANG',
//       'CAMANTILES',
//       'CASANTAAN',
//       'CATABLAN',
//       'CAYAMBANAN',
//       'CONSOLACION',
//       'DILAN PAURIDO',
//       'DR. PEDRO T. ORATA (BACTAD PROPER)',
//       'LABIT PROPER',
//       'LABIT WEST',
//       'MABANOGBOG',
//       'MACALONG',
//       'NANCALOBASAAN',
//       'NANCAMALIRAN EAST',
//       'NANCAMALIRAN WEST',
//       'NANCAYASAN',
//       'OLTAMA',
//       'PALINA EAST',
//       'PALINA WEST',
//       'PINMALUDPOD',
//       'POBLACION',
//       'SAN JOSE',
//       'SAN VICENTE',
//       'SANTA LUCIA',
//       'SANTO DOMINGO',
//       'SUGCONG',
//       'TIPUSO',
//       'TULONG',
//     ],
//   },
//   VILLASIS: {
//     province: 'PANGASINAN',
//     municipality: 'VILLASIS',
//     region: 'REGION I',
//     barangay_list: [
//       'AMAMPEREZ',
//       'BACAG',
//       'BARANGOBONG',
//       'BARRACA',
//       'CAPULAAN',
//       'CARAMUTAN',
//       'LA PAZ',
//       'LABIT',
//       'LIPAY',
//       'LOMBOY',
//       'PIAZ (PLAZA)',
//       'PUELAY',
//       'SAN BLAS',
//       'SAN NICOLAS',
//       'TOMBOD',
//       'UNZAD',
//       'ZONE I (POB.)',
//       'ZONE II (POB.)',
//       'ZONE III (POB.)',
//       'ZONE IV (POB.)',
//       'ZONE V (POB.)',
//     ],
//   },
//   BASCO: {
//     province: 'BATANES',
//     municipality: 'BASCO',
//     region: 'REGION II',
//     barangay_list: [
//       'CHANARIAN',
//       'IHUBOK I (KAYCHANARIANAN)',
//       'IHUBOK II (KAYVALUGANAN)',
//       'KAYHUVOKAN',
//       'SAN ANTONIO',
//       'SAN JOAQUIN',
//     ],
//   },
//   ITBAYAT: {
//     province: 'BATANES',
//     municipality: 'ITBAYAT',
//     region: 'REGION II',
//     barangay_list: [
//       'RAELE',
//       'SAN RAFAEL (IDIANG)',
//       'SANTA LUCIA (KAUHAUHASAN)',
//       'SANTA MARIA (MARAPUY)',
//       'SANTA ROSA (KAYNATUAN)',
//     ],
//   },
//   IVANA: {
//     province: 'BATANES',
//     municipality: 'IVANA',
//     region: 'REGION II',
//     barangay_list: [
//       'RADIWAN',
//       'SALAGAO',
//       'SAN VICENTE (IGANG)',
//       'TUHEL (POB.)',
//     ],
//   },
//   MAHATAO: {
//     province: 'BATANES',
//     municipality: 'MAHATAO',
//     region: 'REGION II',
//     barangay_list: ['HANIB', 'KAUMBAKAN', 'PANATAYAN', 'UVOY (POB.)'],
//   },
//   SABTANG: {
//     province: 'BATANES',
//     municipality: 'SABTANG',
//     region: 'REGION II',
//     barangay_list: [
//       'CHAVAYAN',
//       'MALAKDANG (POB.)',
//       'NAKANMUAN',
//       'SAVIDUG',
//       'SINAKAN (POB.)',
//       'SUMNANGA',
//     ],
//   },
//   UYUGAN: {
//     province: 'BATANES',
//     municipality: 'UYUGAN',
//     region: 'REGION II',
//     barangay_list: [
//       'IMNAJBU',
//       'ITBUD',
//       'KAYUGANAN (POB.)',
//       'KAYVALUGANAN (POB.)',
//     ],
//   },
//   ABULUG: {
//     province: 'CAGAYAN',
//     municipality: 'ABULUG',
//     region: 'REGION II',
//     barangay_list: [
//       'ALINUNU',
//       'BAGU',
//       'BANGUIAN',
//       'CALOG NORTE',
//       'CALOG SUR',
//       'CANAYUN',
//       'CENTRO (POB.)',
//       'DANA-ILI',
//       'GUIDDAM',
//       'LIBERTAD',
//       'LUCBAN',
//       'PINILI',
//       'SAN AGUSTIN',
//       'SAN JULIAN',
//       'SANTA FILOMENA',
//       'SANTA ROSA',
//       'SANTO TOMAS',
//       'SIGUIRAN',
//       'SIMAYUNG',
//       'SIRIT',
//     ],
//   },
//   ALLACAPAN: {
//     province: 'CAGAYAN',
//     municipality: 'ALLACAPAN',
//     region: 'REGION II',
//     barangay_list: [
//       'BESSANG',
//       'BINOBONGAN',
//       'BULO',
//       'BUROT',
//       'CAPAGARAN (BRIGIDA)',
//       'CAPALUTAN',
//       'CAPANICKIAN NORTE',
//       'CAPANICKIAN SUR',
//       'CATARATAN',
//       'CENTRO EAST (POB.)',
//       'CENTRO WEST (POB.)',
//       'DAAN-ILI',
//       'DAGUPAN',
//       'DALAYAP',
//       'GAGADDANGAN',
//       'IRINGAN',
//       'LABBEN',
//       'MALUYO',
//       'MAPURAO',
//       'MATUCAY',
//       'NAGATTATAN',
//       'PACAC',
//       'SAN JUAN (MAGUININANGO)',
//       'SILANGAN',
//       'TAMBOLI',
//       'TUBEL',
//       'UTAN',
//     ],
//   },
//   AMULUNG: {
//     province: 'CAGAYAN',
//     municipality: 'AMULUNG',
//     region: 'REGION II',
//     barangay_list: [
//       'ABOLO',
//       'AGGUIRIT',
//       'ALITUNGTUNG',
//       'ANNABUCULAN',
//       'ANNAFATAN',
//       'ANQUIRAY',
//       'BABAYUAN',
//       'BACCUIT',
//       'BACRING',
//       'BACULUD',
//       'BALAUINI',
//       'BAUAN',
//       'BAYABAT',
//       'CALAMAGUI',
//       'CALINTAAN',
//       'CARATACAT',
//       'CASINGSINGAN NORTE',
//       'CASINGSINGAN SUR',
//       'CATARAUAN',
//       'CENTRO',
//       'CONCEPCION',
//       'CORDOVA',
//       'DADDA',
//       'DAFUNGANAY',
//       'DUGAYUNG',
//       'ESTEFANIA',
//       'GABUT',
//       'GANGAUAN',
//       'GORAN',
//       'JURISDICCION',
//       'LA SUERTE',
//       'LOGUNG',
//       'MAGOGOD',
//       'MANALO',
//       'MAROBBOB',
//       'MASICAL',
//       'MONTE ALEGRE',
//       'NABBIALAN',
//       'NAGSABARAN',
//       'NANGALASAUAN',
//       'NANUCCAUAN',
//       'PACAC-GRANDE',
//       'PACAC-PEQUEÑO',
//       'PALACU',
//       'PALAYAG',
//       'TANA',
//       'UNAG',
//     ],
//   },
//   APARRI: {
//     province: 'CAGAYAN',
//     municipality: 'APARRI',
//     region: 'REGION II',
//     barangay_list: [
//       'BACKILING',
//       'BANGAG',
//       'BINALAN',
//       'BISAGU',
//       'BUKIG',
//       'BULALA NORTE',
//       'BULALA SUR',
//       'CAAGAMAN',
//       'CENTRO 1 (POB.)',
//       'CENTRO 2 (POB.)',
//       'CENTRO 3 (POB.)',
//       'CENTRO 4 (POB.)',
//       'CENTRO 5 (POB.)',
//       'CENTRO 6 (POB.)',
//       'CENTRO 7 (POB.)',
//       'CENTRO 8 (POB.)',
//       'CENTRO 9 (POB.)',
//       'CENTRO 10 (POB.)',
//       'CENTRO 11 (POB.)',
//       'CENTRO 12 (POB.)',
//       'CENTRO 13 (POB.)',
//       'CENTRO 14 (POB.)',
//       'CENTRO 15 (POB.)',
//       'DODAN',
//       'FUGA ISLAND',
//       'GADDANG',
//       'LINAO',
//       'MABANGUC',
//       'MACANAYA (PESCARIA)',
//       'MAURA',
//       'MINANGA',
//       'NAVAGAN',
//       'PADDAYA',
//       'PARUDDUN NORTE',
//       'PARUDDUN SUR',
//       'PLAZA',
//       'PUNTA',
//       'SAN ANTONIO',
//       'SANJA',
//       'TALLUNGAN',
//       'TORAN',
//       'ZINARAG',
//     ],
//   },
//   BAGGAO: {
//     province: 'CAGAYAN',
//     municipality: 'BAGGAO',
//     region: 'REGION II',
//     barangay_list: [
//       'ADAOAG',
//       'AGAMAN (PROPER)',
//       'AGAMAN NORTE',
//       'AGAMAN SUR',
//       'ALBA',
//       'ANNAYATAN',
//       'ASASSI',
//       'ASINGA-VIA',
//       'AWALLAN',
//       'BACAGAN',
//       'BAGUNOT',
//       'BARSAT EAST',
//       'BARSAT WEST',
//       'BITAG GRANDE',
//       'BITAG PEQUEÑO',
//       'BUNUGAN',
//       'C. VERZOSA (VALLEY COVE)',
//       'CANAGATAN',
//       'CARUPIAN',
//       'CATUGAY',
//       'DABBAC GRANDE',
//       'DALIN',
//       'DALLA',
//       'HACIENDA INTAL',
//       'IBULO',
//       'IMURONG',
//       'J. PALLAGAO',
//       'LASILAT',
//       'MABINI',
//       'MASICAL',
//       'MOCAG',
//       'NANGALINAN',
//       'POBLACION (CENTRO)',
//       'REMUS',
//       'SAN ANTONIO',
//       'SAN FRANCISCO',
//       'SAN ISIDRO',
//       'SAN JOSE',
//       'SAN MIGUEL',
//       'SAN VICENTE',
//       'SANTA MARGARITA',
//       'SANTOR',
//       'TAGUING',
//       'TAGUNTUNGAN',
//       'TALLANG',
//       'TAYTAY',
//       'TEMBLIQUE',
//       'TUNGEL',
//     ],
//   },
//   BALLESTEROS: {
//     province: 'CAGAYAN',
//     municipality: 'BALLESTEROS',
//     region: 'REGION II',
//     barangay_list: [
//       'AMMUBUAN',
//       'BARAN',
//       'CABARITAN EAST',
//       'CABARITAN WEST',
//       'CABAYU',
//       'CABULUAN EAST',
//       'CABULUAN WEST',
//       'CENTRO EAST (POB.)',
//       'CENTRO WEST (POB.)',
//       'FUGU',
//       'MABUTTAL EAST',
//       'MABUTTAL WEST',
//       'NARARAGAN',
//       'PALLOC',
//       'PAYAGAN EAST',
//       'PAYAGAN WEST',
//       'SAN JUAN',
//       'SANTA CRUZ',
//       'ZITANGA',
//     ],
//   },
//   BUGUEY: {
//     province: 'CAGAYAN',
//     municipality: 'BUGUEY',
//     region: 'REGION II',
//     barangay_list: [
//       'ALUCAO WESTE (SAN LORENZO*)',
//       'ANTIPORDA',
//       'BALLANG',
//       'BALZA',
//       'CABARITAN',
//       'CALAMEGATAN',
//       'CENTRO (POB.)',
//       'CENTRO WEST',
//       'DALAYA',
//       'FULA',
//       'LERON',
//       'MADDALERO',
//       'MALA ESTE',
//       'MALA WESTE',
//       'MINANGA ESTE',
//       'MINANGA WESTE',
//       'PADDAYA ESTE',
//       'PADDAYA WESTE',
//       'PATTAO',
//       'QUINAWEGAN',
//       'REMEBELLA',
//       'SAN ISIDRO',
//       'SAN JUAN',
//       'SAN VICENTE',
//       'SANTA ISABEL',
//       'SANTA MARIA',
//       'TABBAC',
//       'VILLA CIELO',
//       'VILLA GRACIA',
//       'VILLA LEONORA',
//     ],
//   },
//   CALAYAN: {
//     province: 'CAGAYAN',
//     municipality: 'CALAYAN',
//     region: 'REGION II',
//     barangay_list: [
//       'BABUYAN CLARO',
//       'BALATUBAT',
//       'CABUDADAN',
//       'CENTRO II',
//       'DADAO',
//       'DALUPIRI',
//       'DIBAY',
//       'DILAM',
//       'MAGSIDEL',
//       'MINABEL',
//       'NAGUILIAN',
//       'POBLACION',
//     ],
//   },
//   CAMALANIUGAN: {
//     province: 'CAGAYAN',
//     municipality: 'CAMALANIUGAN',
//     region: 'REGION II',
//     barangay_list: [
//       'ABAGAO',
//       'AFUNAN CABAYU',
//       'AGUSI',
//       'ALILINU',
//       'BAGGAO',
//       'BANTAY',
//       'BULALA',
//       'CASILI NORTE',
//       'CASILI SUR',
//       'CATOTORAN NORTE',
//       'CATOTORAN SUR',
//       'CENTRO NORTE (POB.)',
//       'CENTRO SUR (POB.)',
//       'CULLIT',
//       'DACAL-LAFUGU',
//       'DAMMANG NORTE (JOAQUIN DE LA CRUZ*)',
//       'DAMMANG SUR (FELIPE TUZON*)',
//       'DUGO',
//       'FUSINA',
//       'GANG-NGO',
//       'JURISDICTION',
//       'LUEC',
//       'MINANGA',
//       'PARAGAT',
//       'SAPPING',
//       'TAGUM',
//       'TULUTTUGING',
//       'ZIMINILA',
//     ],
//   },
//   ENRILE: {
//     province: 'CAGAYAN',
//     municipality: 'ENRILE',
//     region: 'REGION II',
//     barangay_list: [
//       'ALIBAGO (VILLA MARIA)',
//       'BARANGAY I (POB.)',
//       'BARANGAY II (POB.)',
//       'BARANGAY III (SAN ROQUE*)',
//       'BARANGAY III-A',
//       'BARANGAY IV (POB.)',
//       'BATU',
//       'DIVISORIA',
//       'INGA',
//       'LANNA',
//       'LEMU NORTE',
//       'LEMU SUR',
//       'LIWAN NORTE',
//       'LIWAN SUR',
//       'MADDARULUG NORTE',
//       'MADDARULUG SUR',
//       'MAGALALAG EAST',
//       'MAGALALAG WEST (SAN NICOLAS*)',
//       'MARACURU',
//       'ROMA NORTE',
//       'ROMA SUR',
//       'SAN ANTONIO',
//     ],
//   },
//   GATTARAN: {
//     province: 'CAGAYAN',
//     municipality: 'GATTARAN',
//     region: 'REGION II',
//     barangay_list: [
//       'ABRA',
//       'AGUIGUICAN',
//       'BANGATAN NGAGAN',
//       'BARACAOIT',
//       'BARAOIDAN',
//       'BARBARIT',
//       'BASAO',
//       'BOLOS POINT',
//       'CABAYU',
//       'CALAOAGAN BASSIT',
//       'CALAOAGAN DACKEL',
//       'CAPIDDIGAN',
//       'CAPISSAYAN NORTE',
//       'CAPISSAYAN SUR',
//       'CASICALLAN NORTE',
//       'CASICALLAN SUR',
//       'CENTRO NORTE (POB.)',
//       'CENTRO SUR (POB.)',
//       'CULLIT',
//       'CUMAO',
//       'CUNIG',
//       'DUMMUN',
//       'FUGU',
//       'GANZANO',
//       'GUISING',
//       'L. ADVIENTO',
//       'LANGGAN',
//       'LAPOGAN',
//       'MABUNO',
//       'NABACCAYAN',
//       'NADDUNGAN',
//       'NAGATUTUAN',
//       'NASSIPING',
//       'NEWAGAC',
//       'PALAGAO NORTE',
//       'PALAGAO SUR',
//       'PIÑA ESTE',
//       'PIÑA WESTE',
//       'SAN CARLOS',
//       'SAN VICENTE',
//       'SANTA ANA',
//       'SANTA MARIA',
//       'SIDEM',
//       'T. ELIZAGA (MABIRBIRA)',
//       'TAGUMAY',
//       'TAKIKI',
//       'TALIGAN',
//       'TANGLAGAN',
//       'TUBUNGAN ESTE',
//       'TUBUNGAN WESTE',
//     ],
//   },
//   GONZAGA: {
//     province: 'CAGAYAN',
//     municipality: 'GONZAGA',
//     region: 'REGION II',
//     barangay_list: [
//       'AMUNITAN',
//       'BATANGAN',
//       'BAUA',
//       'CABANBANAN NORTE',
//       'CABANBANAN SUR',
//       'CABIRAOAN',
//       'CALAYAN',
//       'CALLAO',
//       'CAROAN',
//       'CASITAN',
//       'FLOURISHING (POB.)',
//       'IPIL',
//       'ISCA',
//       'MAGRAFIL',
//       'MINANGA',
//       'PARADISE (POB.)',
//       'PATENG',
//       'PROGRESSIVE (POB.)',
//       'REBECCA (NAGBABALACAN*)',
//       'SAN JOSE',
//       'SANTA CLARA',
//       'SANTA CRUZ',
//       'SANTA MARIA',
//       'SMART (POB.)',
//       'TAPEL',
//     ],
//   },
//   IGUIG: {
//     province: 'CAGAYAN',
//     municipality: 'IGUIG',
//     region: 'REGION II',
//     barangay_list: [
//       'AJAT (POB.)',
//       'ATULU',
//       'BACULUD',
//       'BAYO',
//       'CAMPO',
//       'DUMPAO',
//       'GAMMAD',
//       'GARAB',
//       'MALABBAC',
//       'MANAOAG (AQUILIQUILAO)',
//       'MINANGA NORTE',
//       'MINANGA SUR',
//       'NATTANZAN (POB.)',
//       'REDONDO',
//       'SALAMAGUE',
//       'SAN ESTEBAN (CAPITAN)',
//       'SAN ISIDRO (UGAC WEST)',
//       'SAN LORENZO',
//       'SAN VICENTE (UGAC EAST)',
//       'SANTA BARBARA',
//       'SANTA ROSA',
//       'SANTA TERESA (GAMMAD SUR)',
//       'SANTIAGO',
//     ],
//   },
//   'LAL-LO': {
//     province: 'CAGAYAN',
//     municipality: 'LAL-LO',
//     region: 'REGION II',
//     barangay_list: [
//       'ABAGAO',
//       'ALAGUIA',
//       'BAGUMBAYAN',
//       'BANGAG',
//       'BICAL',
//       'BICUD',
//       'BINAG',
//       'CABAYABASAN (CAPACUAN)',
//       'CAGORAN',
//       'CAMBONG',
//       'CATAYAUAN',
//       'CATUGAN',
//       'CENTRO (POB.)',
//       'CULLIT',
//       'DAGUPAN',
//       'DALAYA',
//       'FABRICA',
//       'FUSINA',
//       'JURISDICTION',
//       'LALAFUGAN',
//       'LOGAC',
//       'MAGAPIT',
//       'MALANAO',
//       'MAXINGAL',
//       'NAGUILIAN',
//       'PARANUM',
//       'ROSARIO',
//       'SAN ANTONIO (LAFU)',
//       'SAN JOSE',
//       'SAN JUAN',
//       'SAN LORENZO',
//       'SAN MARIANO',
//       'SANTA MARIA',
//       'SANTA TERESA (MAGALLUNGON)',
//       'TUCALANA',
//     ],
//   },
//   LASAM: {
//     province: 'CAGAYAN',
//     municipality: 'LASAM',
//     region: 'REGION II',
//     barangay_list: [
//       'AGGUNETAN',
//       'ALANNAY',
//       'BATTALAN',
//       'CABATACAN EAST (DULDUGAN*)',
//       'CABATACAN WEST',
//       'CALAPANGAN NORTE',
//       'CALAPANGAN SUR',
//       'CALLAO NORTE',
//       'CALLAO SUR',
//       'CATALIGANAN',
//       'CENTRO I (POB.)',
//       'CENTRO II (POB.)',
//       'CENTRO III (POB.)',
//       'FINUGO NORTE',
//       'GABUN',
//       'IGNACIO JURADO (FINUGU SUR)',
//       'MAGSAYSAY',
//       'MALINTA',
//       'MINANGA NORTE',
//       'MINANGA SUR',
//       'NABANNAGAN EAST',
//       'NABANNAGAN WEST',
//       'NEW ORLINS',
//       'NICOLAS AGATEP',
//       'PERU',
//       'SAN PEDRO',
//       'SICALAO',
//       'TAGAO',
//       'TUCALAN PASSING',
//       'VIGA',
//     ],
//   },
//   PAMPLONA: {
//     province: 'NEGROS ORIENTAL',
//     municipality: 'PAMPLONA',
//     region: 'REGION VII',
//     barangay_list: [
//       'ABANTE',
//       'BALAYONG',
//       'BANAWE',
//       'CALICANAN',
//       'DATAGON',
//       'FATIMA',
//       'INAWASAN',
//       'MAGSUSUNOG',
//       'MALALANGSI',
//       'MAMBURAO',
//       'MANGOTO',
//       'POBLACION',
//       'SAN ISIDRO',
//       'SANTA AGUEDA',
//       'SIMBORIO',
//       'YUPISAN',
//     ],
//   },
//   PEÑABLANCA: {
//     province: 'CAGAYAN',
//     municipality: 'PEÑABLANCA',
//     region: 'REGION II',
//     barangay_list: [
//       'AGGUGADDAN',
//       'ALIMANAO',
//       'BALIUAG',
//       'BICAL',
//       'BUGATAY',
//       'BUYUN',
//       'CABASAN',
//       'CABBO',
//       'CALLAO',
//       'CAMASI',
//       'CENTRO (POB.)',
//       'DODAN',
//       'LAPI',
//       'MALIBABAG',
//       'MANGA',
//       'MINANGA',
//       'NABBABALAYAN',
//       'NANGUILATTAN',
//       'NANNARIAN',
//       'PARABBA',
//       'PATAGUELEG',
//       'QUIBAL',
//       'SAN ROQUE (LITTO)',
//       'SISIM',
//     ],
//   },
//   PIAT: {
//     province: 'CAGAYAN',
//     municipality: 'PIAT',
//     region: 'REGION II',
//     barangay_list: [
//       'APAYAO',
//       'AQUIB',
//       'BAUNG',
//       'CALAOAGAN',
//       'CATARAUAN',
//       'DUGAYUNG',
//       'GUMARUENG',
//       'MACAPIL',
//       'MAGUILLING',
//       'MINANGA',
//       'POBLACION I',
//       'POBLACION II',
//       'SANTA BARBARA',
//       'SANTO DOMINGO',
//       'SICATNA',
//       'VILLA REY (SAN GASPAR*)',
//       'VILLA REYNO',
//       'WARAT',
//     ],
//   },
//   RIZAL: {
//     province: 'ZAMBOANGA DEL NORTE',
//     municipality: 'RIZAL',
//     region: 'REGION IX',
//     barangay_list: [
//       'BALUBOHAN',
//       'BIRAYAN',
//       'DAMASING',
//       'EAST POBLACION',
//       'LA ESPERANZA',
//       'MABUHAY',
//       'MABUNAO',
//       'MITIMOS',
//       'NANGCA',
//       'NANGCAAN',
//       'NAPILAN',
//       'NASIPANG',
//       'NEW DAPITAN',
//       'NILABO',
//       'NORTH MAPANG',
//       'RIZALINA',
//       'SAN ROQUE',
//       'SEBACA',
//       'SIPAON',
//       'SOUTH MAPANG',
//       'TOLON',
//       'WEST POBLACION',
//     ],
//   },
//   'SANCHEZ-MIRA': {
//     province: 'CAGAYAN',
//     municipality: 'SANCHEZ-MIRA',
//     region: 'REGION II',
//     barangay_list: [
//       'BANGAN',
//       'CALLUNGAN',
//       'CENTRO I (POB.)',
//       'CENTRO II (POB.)',
//       'DACAL',
//       'DAGUERAY',
//       'DAMMANG',
//       'KITTAG',
//       'LANGAGAN',
//       'MAGACAN',
//       'MARZAN',
//       'MASISIT',
//       'NAGRANGTAYAN',
//       'NAMUAC',
//       'SAN ANDRES',
//       'SANTIAGO',
//       'SANTOR',
//       'TOKITOK',
//     ],
//   },
//   'SANTA ANA': {
//     province: 'METRO MANILA',
//     municipality: 'SANTA ANA',
//     region: 'NCR',
//     barangay_list: [
//       'BARANGAY 745',
//       'BARANGAY 746',
//       'BARANGAY 747',
//       'BARANGAY 748',
//       'BARANGAY 749',
//       'BARANGAY 750',
//       'BARANGAY 751',
//       'BARANGAY 752',
//       'BARANGAY 753',
//       'BARANGAY 754',
//       'BARANGAY 755',
//       'BARANGAY 756',
//       'BARANGAY 757',
//       'BARANGAY 758',
//       'BARANGAY 759',
//       'BARANGAY 760',
//       'BARANGAY 761',
//       'BARANGAY 762',
//       'BARANGAY 763',
//       'BARANGAY 764',
//       'BARANGAY 765',
//       'BARANGAY 766',
//       'BARANGAY 767',
//       'BARANGAY 768',
//       'BARANGAY 769',
//       'BARANGAY 770',
//       'BARANGAY 771',
//       'BARANGAY 772',
//       'BARANGAY 773',
//       'BARANGAY 774',
//       'BARANGAY 775',
//       'BARANGAY 776',
//       'BARANGAY 777',
//       'BARANGAY 778',
//       'BARANGAY 779',
//       'BARANGAY 780',
//       'BARANGAY 781',
//       'BARANGAY 782',
//       'BARANGAY 783',
//       'BARANGAY 784',
//       'BARANGAY 785',
//       'BARANGAY 786',
//       'BARANGAY 787',
//       'BARANGAY 788',
//       'BARANGAY 789',
//       'BARANGAY 790',
//       'BARANGAY 791',
//       'BARANGAY 792',
//       'BARANGAY 793',
//       'BARANGAY 794',
//       'BARANGAY 795',
//       'BARANGAY 796',
//       'BARANGAY 797',
//       'BARANGAY 798',
//       'BARANGAY 799',
//       'BARANGAY 800',
//       'BARANGAY 801',
//       'BARANGAY 802',
//       'BARANGAY 803',
//       'BARANGAY 804',
//       'BARANGAY 805',
//       'BARANGAY 806',
//       'BARANGAY 807',
//       'BARANGAY 808',
//       'BARANGAY 818-A',
//       'BARANGAY 866',
//       'BARANGAY 873',
//       'BARANGAY 874',
//       'BARANGAY 875',
//       'BARANGAY 876',
//       'BARANGAY 877',
//       'BARANGAY 878',
//       'BARANGAY 879',
//       'BARANGAY 880',
//       'BARANGAY 881',
//       'BARANGAY 882',
//       'BARANGAY 883',
//       'BARANGAY 884',
//       'BARANGAY 885',
//       'BARANGAY 886',
//       'BARANGAY 887',
//       'BARANGAY 888',
//       'BARANGAY 889',
//       'BARANGAY 890',
//       'BARANGAY 891',
//       'BARANGAY 892',
//       'BARANGAY 893',
//       'BARANGAY 894',
//       'BARANGAY 895',
//       'BARANGAY 896',
//       'BARANGAY 897',
//       'BARANGAY 898',
//       'BARANGAY 899',
//       'BARANGAY 900',
//       'BARANGAY 901',
//       'BARANGAY 902',
//       'BARANGAY 903',
//       'BARANGAY 904',
//       'BARANGAY 905',
//     ],
//   },
//   'SANTA PRAXEDES': {
//     province: 'CAGAYAN',
//     municipality: 'SANTA PRAXEDES',
//     region: 'REGION II',
//     barangay_list: [
//       'CADONGDONGAN',
//       'CAPACUAN',
//       'CENTRO I (POB.)',
//       'CENTRO II (POB.)',
//       'MACATEL',
//       'PORTABAGA',
//       'SALUNGSONG',
//       'SAN JUAN',
//       'SAN MIGUEL',
//       'SICUL',
//     ],
//   },
//   'SANTA TERESITA': {
//     province: 'BATANGAS',
//     municipality: 'SANTA TERESITA',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'ANTIPOLO',
//       'BIHIS',
//       'BUROL',
//       'CALAYAAN',
//       'CALUMALA',
//       'CUTA EAST',
//       'CUTANG CAWAYAN',
//       'IRUKAN',
//       'PACIFICO',
//       'POBLACION I',
//       'POBLACION II',
//       'POBLACION III',
//       'SAIMSIM',
//       'SAMPA',
//       'SINIPIAN',
//       'TAMBO IBABA',
//       'TAMBO ILAYA',
//     ],
//   },
//   'SANTO NIÑO (FAIRE)': {
//     province: 'CAGAYAN',
//     municipality: 'SANTO NIÑO (FAIRE)',
//     region: 'REGION II',
//     barangay_list: [
//       'ABARIONGAN RUAR',
//       'ABARIONGAN UNEG',
//       'BALAGAN',
//       'BALANNI',
//       'CABAYO',
//       'CALAPANGAN',
//       'CALASSITAN',
//       'CAMPO',
//       'CENTRO NORTE (POB.)',
//       'CENTRO SUR (POB.)',
//       'DUNGAO',
//       'LATTAC',
//       'LIPATAN',
//       'LUBO',
//       'MABITBITNONG',
//       'MAPITAC',
//       'MASICAL',
//       'MATALAO',
//       'NAG-UMA',
//       'NAMUCCAYAN',
//       'NIUG NORTE',
//       'NIUG SUR',
//       'PALUSAO',
//       'SAN MANUEL',
//       'SAN ROQUE',
//       'SANTA FELICITAS',
//       'SANTA MARIA',
//       'SIDIRAN',
//       'TABANG',
//       'TAMUCCO',
//       'VIRGINIA',
//     ],
//   },
//   SOLANA: {
//     province: 'CAGAYAN',
//     municipality: 'SOLANA',
//     region: 'REGION II',
//     barangay_list: [
//       'ANDARAYAN NORTH',
//       'ANDARAYAN SOUTH',
//       'BANGAG',
//       'BANTAY',
//       'BASI EAST',
//       'BASI WEST',
//       'BAUAN EAST',
//       'BAUAN WEST',
//       'CADAANAN',
//       'CALAMAGUI',
//       'CALILLAUAN',
//       'CARILUCUD',
//       'CATTARAN',
//       'CENTRO NORTHEAST (POB.)',
//       'CENTRO NORTHWEST (POB.)',
//       'CENTRO SOUTHEAST (POB.)',
//       'CENTRO SOUTHWEST (POB.)',
//       'DASSUN',
//       'FURAGUI',
//       'GADU',
//       'GEN. EULOGIO BALAO',
//       'IRAGA',
//       'LANNA',
//       'LANNIG',
//       'LINGU',
//       'MADDARULUG (SANTO DOMINGO)',
//       'MAGUIRIG',
//       'MALALAM-MALACABIBI',
//       'NABBOTUAN',
//       'NANGALISAN',
//       'NATAPPIAN EAST',
//       'NATAPPIAN WEST',
//       'PADUL',
//       'PALAO',
//       'PARUG-PARUG',
//       'PATAYA',
//       'SAMPAGUITA',
//       'UBONG',
//     ],
//   },
//   TUAO: {
//     province: 'CAGAYAN',
//     municipality: 'TUAO',
//     region: 'REGION II',
//     barangay_list: [
//       'ACCUSILIAN',
//       'ALABIAO',
//       'ALABUG',
//       'ANGANG',
//       'BAGUMBAYAN',
//       'BALAGAO',
//       'BARANCUAG',
//       'BATTUNG',
//       'BICOK',
//       'BUGNAY',
//       'CAGUMITAN',
//       'CATO',
//       'CULONG',
//       'DAGUPAN',
//       'FUGU',
//       'LAKAMBINI',
//       'LALLAYUG',
//       'MALALINTA',
//       'MALUMIN',
//       'MAMBACAG',
//       'MUNGO',
//       'NARUANGAN',
//       'PALCA',
//       'PATA',
//       'POBLACION I (WARD I CENTRO)',
//       'POBLACION II (WARD II CENTRO)',
//       'SAN JUAN',
//       'SAN LUIS (GURENGAD)',
//       'SAN VICENTE (MALEG)',
//       'SANTO TOMAS',
//       'TARIBUBU',
//       'VILLA LAIDA',
//     ],
//   },
//   'TUGUEGARAO': {
//     province: 'CAGAYAN',
//     municipality: 'TUGUEGARAO',
//     region: 'REGION II',
//     barangay_list: [
//       'ANNAFUNAN EAST',
//       'ANNAFUNAN WEST',
//       'ATULAYAN NORTE',
//       'ATULAYAN SUR',
//       'BAGAY',
//       'BUNTUN',
//       'CAGGAY',
//       'CAPATAN',
//       'CARIG',
//       'CARITAN CENTRO',
//       'CARITAN NORTE',
//       'CARITAN SUR',
//       'CATAGGAMAN NUEVO',
//       'CATAGGAMAN PARDO',
//       'CATAGGAMAN VIEJO',
//       'CENTRO 1 (POB.)',
//       'CENTRO 2 (POB.)',
//       'CENTRO 3 (POB.)',
//       'CENTRO 4 (POB.)',
//       'CENTRO 5 (POB.)',
//       'CENTRO 6 (POB.)',
//       'CENTRO 7 (POB.)',
//       'CENTRO 8 (POB.)',
//       'CENTRO 9 (POB.)',
//       'CENTRO 10 (POB.)',
//       'CENTRO 11 (POB.)',
//       'CENTRO 12 (POB.)',
//       'DADDA',
//       'GOSI NORTE',
//       'GOSI SUR',
//       'LARION ALTO',
//       'LARION BAJO',
//       'LEONARDA',
//       'LIBAG NORTE',
//       'LIBAG SUR',
//       'LINAO EAST',
//       'LINAO NORTE',
//       'LINAO WEST',
//       'NAMBBALAN NORTE',
//       'NAMBBALAN SUR',
//       'PALLUA NORTE',
//       'PALLUA SUR',
//       'PENGUE (PENGUE-RUYU)',
//       'REYES',
//       'SAN GABRIEL',
//       'TAGGA',
//       'TANZA',
//       'UGAC NORTE',
//       'UGAC SUR',
//     ],
//   },
//   ALICIA: {
//     province: 'ZAMBOANGA SIBUGAY',
//     municipality: 'ALICIA',
//     region: 'REGION IX',
//     barangay_list: [
//       'ALEGRIA',
//       'BAGONG BUHAY',
//       'BELLA',
//       'CALADES',
//       'CONCEPCION',
//       'DAWA-DAWA',
//       'GULAYON',
//       'ILISAN',
//       'KAPATAGAN',
//       'KAUSWAGAN',
//       'KAWAYAN',
//       'LA PAZ',
//       'LAMBUYOGAN',
//       'LAPIRAWAN',
//       'LITAYON',
//       'LUTIMAN',
//       'MILAGROSA (BALUNO)',
//       'NAGA-NAGA',
//       'PANDAN-PANDAN',
//       'PAYONGAN',
//       'POBLACION',
//       'SANTA MARIA',
//       'SANTO NIÑO',
//       'TALAPTAP',
//       'TAMPALAN',
//       'TANDIONG MUSLIM',
//       'TIMBANG-TIMBANG',
//     ],
//   },
//   ANGADANAN: {
//     province: 'ISABELA',
//     municipality: 'ANGADANAN',
//     region: 'REGION II',
//     barangay_list: [
//       'ALLANGIGAN',
//       'ANIOG',
//       'BANIKET',
//       'BANNAWAG',
//       'BANTUG',
//       'BARANGCUAG',
//       'BAUI',
//       'BONIFACIO',
//       'BUENAVISTA',
//       'BUNNAY',
//       'CALABAYAN-MINANGA',
//       'CALACCAB',
//       'CALAOCAN',
//       'CAMPANARIO',
//       'CANANGAN',
//       'CENTRO I (POB.)',
//       'CENTRO II (POB.)',
//       'CENTRO III (POB.)',
//       'CONSULAR',
//       'CUMU',
//       'DALAKIP',
//       'DALENAT',
//       'DIPALUDA',
//       'DUROC',
//       'ESPERANZA',
//       'FUGARU',
//       'INGUD NORTE',
//       'INGUD SUR',
//       'KALUSUTAN',
//       'LA SUERTE',
//       'LIWLIWA',
//       'LOMBOY',
//       'LORIA',
//       'LOURDES (EL ESCAÑO)',
//       'MABUHAY',
//       'MACALAUAT',
//       'MACANIAO',
//       'MALANNAO',
//       'MALASIN',
//       'MANGANDINGAY',
//       'MINANGA PROPER',
//       'PAPPAT',
//       'PISSAY',
//       'RAMONA',
//       'RANCHO BASSIT',
//       'RANG-AYAN',
//       'SALAY',
//       'SAN AMBROCIO',
//       'SAN GUILLERMO',
//       'SAN ISIDRO',
//       'SAN MARCELO',
//       'SAN ROQUE',
//       'SAN VICENTE',
//       'SANTO NIÑO',
//       'SARANAY',
//       'SINABBARAN',
//       'VICTORY',
//       'VIGA',
//       'VILLA DOMINGO',
//     ],
//   },
//   AURORA: {
//     province: 'ZAMBOANGA DEL SUR',
//     municipality: 'AURORA',
//     region: 'REGION IX',
//     barangay_list: [
//       'ACAD',
//       'ALANG-ALANG',
//       'ALEGRIA',
//       'ANONANG',
//       'BAGONG MANDAUE',
//       'BAGONG MASLOG',
//       'BAGONG OSLOB',
//       'BAGONG PITOGO',
//       'BAKI',
//       'BALAS',
//       'BALIDE',
//       'BALINTAWAK',
//       'BAYABAS',
//       'BEMPOSA',
//       'CABILINAN',
//       'CAMPO UNO',
//       'CEBONEG',
//       'COMMONWEALTH',
//       'GUBAAN',
//       'INASAGAN',
//       'INROAD',
//       'KAHAYAGAN EAST (KATIPUNAN)',
//       'KAHAYAGAN WEST',
//       'KAUSWAGAN',
//       'LA PAZ (TINIBTIBAN)',
//       'LA VICTORIA',
//       'LANTUNGAN',
//       'LIBERTAD',
//       'LINTUGOP',
//       'LUBID',
//       'MAGUIKAY',
//       'MAHAYAHAY',
//       'MONTE ALEGRE',
//       'MONTELA',
//       'NAPO',
//       'PANAGHIUSA',
//       'POBLACION',
//       'RESTHOUSE',
//       'ROMARATE',
//       'SAN JOSE',
//       'SAN JUAN',
//       'SAPA LOBOC',
//       'TAGULALO',
//       'WATERFALL',
//     ],
//   },
//   'BENITO SOLIVEN': {
//     province: 'ISABELA',
//     municipality: 'BENITO SOLIVEN',
//     region: 'REGION II',
//     barangay_list: [
//       'ANDABUEN',
//       'ARA',
//       'BALLIAO',
//       'BINOGTUNGAN',
//       'CAPUSERAN (CAPUROCAN)',
//       'DAGUPAN',
//       'DANIPA',
//       'DISTRICT I (POB.)',
//       'DISTRICT II (POB.)',
//       'GOMEZ',
//       'GUILINGAN',
//       'LA SALETTE',
//       'LUCBAN',
//       'MAKINDOL',
//       'MALUNO NORTE',
//       'MALUNO SUR',
//       'NACALMA',
//       'NEW MAGSAYSAY',
//       'PLACER',
//       'PUNIT',
//       'SAN CARLOS',
//       'SAN FRANCISCO',
//       'SANTA CRUZ',
//       'SANTIAGO',
//       'SEVILLANA',
//       'SINIPIT',
//       'VILLALUZ',
//       'YEBAN NORTE',
//       'YEBAN SUR',
//     ],
//   },
//   CABAGAN: {
//     province: 'ISABELA',
//     municipality: 'CABAGAN',
//     region: 'REGION II',
//     barangay_list: [
//       'AGGUB',
//       'ANAO',
//       'ANGANCASILIAN',
//       'BALASIG',
//       'CANSAN',
//       'CASIBARAG NORTE',
//       'CASIBARAG SUR',
//       'CATABAYUNGAN',
//       'CENTRO (POB.)',
//       'CUBAG',
//       'GARITA',
//       'LUQUILU',
//       'MABANGUG',
//       'MAGASSI',
//       'MASIPI EAST',
//       'MASIPI WEST',
//       'NGARAG',
//       'PILIG ABAJO',
//       'PILIG ALTO',
//       'SAN ANTONIO',
//       'SAN BERNARDO',
//       'SAN JUAN',
//       'SAUI',
//       'TALLAG',
//       'UGAD',
//       'UNION',
//     ],
//   },
//   CABATUAN: {
//     province: 'ILOILO',
//     municipality: 'CABATUAN',
//     region: 'REGION VI',
//     barangay_list: [
//       'ACAO',
//       'AMERANG',
//       'AMURAO',
//       'ANUANG',
//       'AYAMAN',
//       'AYONG',
//       'BACAN',
//       'BALABAG',
//       'BALUYAN',
//       'BANGUIT',
//       'BULAY',
//       'CADOLDOLAN',
//       'CAGBAN',
//       'CALAWAGAN',
//       'CALAYO',
//       'DUYANDUYAN',
//       'GAUB',
//       'GINES INTERIOR',
//       'GINES PATAG',
//       'GUIBUANGAN TIGBAUAN',
//       'INABASAN',
//       'INACA',
//       'INALADAN',
//       'INGAS',
//       'ITO NORTE',
//       'ITO SUR',
//       'JANIPAAN CENTRAL',
//       'JANIPAAN ESTE',
//       'JANIPAAN OESTE',
//       'JANIPAAN OLO',
//       'JELICUON LUSAYA',
//       'JELICUON MONTINOLA',
//       'LAG-AN',
//       'LEONG',
//       'LUTAC',
//       'MANGUNA',
//       'MARAGUIT',
//       'MORUBUAN',
//       'PACATIN',
//       'PAGOTPOT',
//       'PAMUL-OGAN',
//       'PAMURINGAO GARRIDO',
//       'PAMURINGAO PROPER',
//       'PUNGTOD',
//       'PUYAS',
//       'SALACAY',
//       'SULANGA',
//       'TABUCAN',
//       'TACDANGAN',
//       'TALANGHAUAN',
//       'TIGBAUAN ROAD',
//       'TINIO-AN',
//       'TIRING',
//       'TUPOL CENTRAL',
//       'TUPOL ESTE',
//       'TUPOL OESTE',
//       'TUY-AN',
//       'ZONE I POB. (BARANGAY 1)',
//       'ZONE II POB. (BARANGAY 2)',
//       'ZONE III POB. (BARANGAY 3)',
//       'ZONE IV POB. (BARANGAY 4)',
//       'ZONE IX POB. (BARANGAY 9)',
//       'ZONE V POB. (BARANGAY 5)',
//       'ZONE VI POB. (BARANGAY 6 )',
//       'ZONE VII POB. (BARANGAY 7)',
//       'ZONE VIII POB. (BARANGAY 8)',
//       'ZONE X POB. (BARANGAY 10)',
//       'ZONE XI POB. (BARANGAY 11)',
//     ],
//   },
//   'CAUAYAN': {
//     province: 'ISABELA',
//     municipality: 'CAUAYAN',
//     region: 'REGION II',
//     barangay_list: [
//       'ALICAOCAO',
//       'ALINAM',
//       'AMOBOCAN',
//       'ANDARAYAN',
//       'BACULOD',
//       'BARINGIN NORTE',
//       'BARINGIN SUR',
//       'BUENA SUERTE',
//       'BUGALLON',
//       'BUYON',
//       'CABARUAN',
//       'CABUGAO',
//       'CARABATAN BACARENO',
//       'CARABATAN CHICA',
//       'CARABATAN GRANDE',
//       'CARABATAN PUNTA',
//       'CASALATAN',
//       'CASSAP FUERA',
//       'CATALINA',
//       'CULALABAT',
//       'DABBURAB',
//       'DE VERA',
//       'DIANAO',
//       'DISIMURAY',
//       'DISTRICT I (POB.)',
//       'DISTRICT II (POB.)',
//       'DISTRICT III (POB.)',
//       'DUMINIT',
//       'FAUSTINO (SIPAY)',
//       'GAGABUTAN',
//       'GAPPAL',
//       'GUAYABAL',
//       'LABINAB',
//       'LINGLINGAY',
//       'MABANTAD',
//       'MALIGAYA',
//       'MANAOAG',
//       'MARABULIG I',
//       'MARABULIG II',
//       'MINANTE I',
//       'MINANTE II',
//       'NAGANACAN',
//       'NAGCAMPEGAN',
//       'NAGRUMBUAN',
//       'NUNGNUNGAN I',
//       'NUNGNUNGAN II',
//       'PINOMA',
//       'RIZAL',
//       'ROGUS',
//       'SAN ANTONIO',
//       'SAN FERMIN',
//       'SAN FRANCISCO',
//       'SAN ISIDRO',
//       'SAN LUIS',
//       'SAN PABLO (CASAP HACIENDA)',
//       'SANTA LUCIANA (DABURAB 2)',
//       'SANTA MARIA',
//       'SILLAWIT',
//       'SINIPPIL',
//       'TAGARAN',
//       'TURAYONG',
//       'UNION',
//       'VILLA CONCEPCION',
//       'VILLA LUNA',
//       'VILLAFLOR',
//     ],
//   },
//   'SANTIAGO': {
//     province: 'ISABELA',
//     municipality: 'SANTIAGO',
//     region: 'REGION II',
//     barangay_list: [
//       'ABRA',
//       'AMBALATUNGAN',
//       'BALINTOCATOC',
//       'BALUARTE',
//       'BANNAWAG NORTE',
//       'BATAL',
//       'BUENAVISTA',
//       'CABULAY',
//       'CALAO EAST (POB.)',
//       'CALAO WEST (POB.)',
//       'CALAOCAN',
//       'CENTRO EAST (POB.)',
//       'CENTRO WEST (POB.)',
//       'DIVISORIA',
//       'DUBINAN EAST',
//       'DUBINAN WEST',
//       'LUNA',
//       'MABINI',
//       'MALVAR',
//       'NABBUAN',
//       'NAGGASICAN',
//       'PATUL',
//       'PLARIDEL',
//       'RIZAL',
//       'ROSARIO',
//       'SAGANA',
//       'SALVADOR',
//       'SAN ANDRES',
//       'SAN ISIDRO',
//       'SAN JOSE',
//       'SANTA ROSA',
//       'SINILI',
//       'SINSAYON',
//       'VICTORY NORTE',
//       'VICTORY SUR',
//       'VILLA GONZAGA',
//       'VILLASIS',
//     ],
//   },
//   CORDON: {
//     province: 'ISABELA',
//     municipality: 'CORDON',
//     region: 'REGION II',
//     barangay_list: [
//       'AGUINALDO (RIZALUNA ESTE)',
//       'ANONANG (BALITOC*)',
//       'CALIMATUROD',
//       'CAMARAO',
//       'CAPIRPIRIWAN',
//       'CAQUILINGAN (SAN LUIS)',
//       'DALLAO',
//       'GAYONG',
//       'LAUREL (CENTRO NORTE)',
//       'MAGSAYSAY (CENTRO SUR OESTE)',
//       'MALAPAT',
//       'OSMENA (CENTRO SUR ESTE)',
//       'QUEZON (CENTRO NORTE ESTE)',
//       'QUIRINO (MANASIN)',
//       'RIZALUNA (RIZALUNA OESTE)',
//       'ROXAS POB. (CENTRO SUR)',
//       'SAGAT',
//       'SAN JUAN (SAN JUAN ESTE)',
//       'TALIKTIK',
//       'TANGGAL',
//       'TARINSING',
//       'TUROD NORTE',
//       'TUROD SUR',
//       'VILLAMARZO',
//       'VILLAMIEMBAN',
//       'WIGAN',
//     ],
//   },
//   'DELFIN ALBANO (MAGSAYSAY)': {
//     province: 'ISABELA',
//     municipality: 'DELFIN ALBANO (MAGSAYSAY)',
//     region: 'REGION II',
//     barangay_list: [
//       'AGA',
//       'ANDARAYAN',
//       'ANEG',
//       'BAYABO',
//       'CALINAOAN SUR',
//       'CALOOCAN',
//       'CAPITOL',
//       'CARMENCITA',
//       'CONCEPCION',
//       'MAUI',
//       'QUIBAL',
//       'RAGAN ALMACEN',
//       'RAGAN NORTE',
//       'RAGAN SUR (POB.)',
//       'RIZAL (RAGAN ALMACEN ALTO)',
//       'SAN ANDRES',
//       'SAN ANTONIO',
//       'SAN ISIDRO',
//       'SAN JOSE',
//       'SAN JUAN',
//       'SAN MACARIO',
//       'SAN NICOLAS (FUSI)',
//       'SAN PATRICIO',
//       'SAN ROQUE',
//       'SANTO ROSARIO',
//       'SANTOR',
//       'VILLA LUZ',
//       'VILLA PEREDA',
//       'VISITACION',
//     ],
//   },
//   DINAPIGUE: {
//     province: 'ISABELA',
//     municipality: 'DINAPIGUE',
//     region: 'REGION II',
//     barangay_list: [
//       'AYOD',
//       'BUCAL NORTE',
//       'BUCAL SUR',
//       'DIBULO',
//       'DIGUMASED (POB.)',
//       'DIMALUADE',
//     ],
//   },
//   DIVILACAN: {
//     province: 'ISABELA',
//     municipality: 'DIVILACAN',
//     region: 'REGION II',
//     barangay_list: [
//       'BICOBIAN',
//       'DIBULOS',
//       'DICAMBANGAN',
//       'DICAROYAN',
//       'DICATIAN',
//       'DILAKIT',
//       'DIMAPNAT',
//       'DIMAPULA (POB.)',
//       'DIMASALANSAN',
//       'DIPUDO',
//       'DITARUM',
//       'SAPINIT',
//     ],
//   },
//   ECHAGUE: {
//     province: 'ISABELA',
//     municipality: 'ECHAGUE',
//     region: 'REGION II',
//     barangay_list: [
//       'ANGOLUAN',
//       'ANNAFUNAN',
//       'ARABIAT',
//       'AROMIN',
//       'BABARAN',
//       'BACRADAL',
//       'BENGUET',
//       'BUNEG',
//       'BUSILELAO',
//       'CABUGAO (POB.)',
//       'CANIGUING',
//       'CARULAY',
//       'CASTILLO',
//       'DAMMANG EAST',
//       'DAMMANG WEST',
//       'DIASAN',
//       'DICARAOYAN',
//       'DUGAYONG',
//       'FUGU',
//       'GARIT NORTE',
//       'GARIT SUR',
//       'GUCAB',
//       'GUMBAUAN',
//       'IPIL',
//       'LIBERTAD',
//       'MABBAYAD',
//       'MABUHAY',
//       'MADADAMIAN',
//       'MAGLETICIA',
//       'MALIBAGO',
//       'MALIGAYA',
//       'MALITAO',
//       'NARRA',
//       'NILUMISU',
//       'PAG-ASA',
//       'PANGAL NORTE',
//       'PANGAL SUR',
//       'RUMANG-AY',
//       'SALAY',
//       'SALVACION',
//       'SAN ANTONIO MINIT',
//       'SAN ANTONIO UGAD',
//       'SAN CARLOS',
//       'SAN FABIAN',
//       'SAN FELIPE',
//       'SAN JUAN',
//       'SAN MANUEL',
//       'SAN MIGUEL',
//       'SAN SALVADOR',
//       'SANTA ANA',
//       'SANTA CRUZ',
//       'SANTA MARIA',
//       'SANTA MONICA',
//       'SANTO DOMINGO',
//       'SILAUAN NORTE (POB.)',
//       'SILAUAN SUR (POB.)',
//       'SINABBARAN',
//       'SOYUNG',
//       'TAGGAPPAN',
//       'TUGUEGARAO',
//       'VILLA CAMPO',
//       'VILLA FERMIN',
//       'VILLA REY',
//       'VILLA VICTORIA',
//     ],
//   },
//   GAMU: {
//     province: 'ISABELA',
//     municipality: 'GAMU',
//     region: 'REGION II',
//     barangay_list: [
//       'BARCOLAN',
//       'BUENAVISTA',
//       'DAMMAO',
//       'DISTRICT I (POB.)',
//       'DISTRICT II (POB.)',
//       'DISTRICT III (POB.)',
//       'FURAO',
//       'GUIBANG',
//       'LENZON',
//       'LINGLINGAY',
//       'MABINI',
//       'PINTOR',
//       'RIZAL',
//       'SONGSONG',
//       'UNION',
//       'UPI',
//     ],
//   },
//   'ILAGAN': {
//     province: 'ISABELA',
//     municipality: 'ILAGAN',
//     region: 'REGION II',
//     barangay_list: [
//       'AGGASIAN',
//       'ALIBAGU',
//       'ALLINGUIGAN 1ST',
//       'ALLINGUIGAN 2ND',
//       'ALLINGUIGAN 3RD',
//       'ARUSIP',
//       'BACULOD (POB.)',
//       'BAGONG SILANG',
//       'BAGUMBAYAN (POB.)',
//       'BALIGATAN',
//       'BALLACONG',
//       'BANGAG',
//       'BATONG-LABANG',
//       'BIGAO',
//       'CABANNUNGAN 1ST',
//       'CABANNUNGAN 2ND',
//       'CABESERIA 2 (DAPPAT)',
//       'CABESERIA 3 (SAN FERNANDO)',
//       'CABESERIA 4 (SAN MANUEL)',
//       'CABESERIA 5 (BARIBAD)',
//       'CABESERIA 6 AND 24 (VILLA MARCOS*)',
//       'CABESERIA 7 (NANGALISAN)',
//       'CABESERIA 9 AND 11 (CAPOGOTAN)',
//       'CABESERIA 10 (LAPIGUI)',
//       'CABESERIA 14 AND 16 (CASILAGAN)',
//       'CABESERIA 17 AND 21 (SAN RAFAEL)',
//       'CABESERIA 19 (VILLA SUERTE*)',
//       'CABESERIA 22 (SABLANG)',
//       'CABESERIA 23 (SAN FRANCISCO*)',
//       'CABESERIA 25 (STA. LUCIA*)',
//       'CABESERIA 27 (ABUAN)',
//       'CADU',
//       'CALAMAGUI 1ST',
//       'CALAMAGUI 2ND',
//       'CAMUNATAN',
//       'CAPELLAN',
//       'CAPO',
//       'CARIKKIKAN NORTE',
//       'CARIKKIKAN SUR',
//       'CENTRO - SAN ANTONIO',
//       'CENTRO POBLACION',
//       'FUGU',
//       'FUYO',
//       'GAYONG-GAYONG NORTE',
//       'GAYONG-GAYONG SUR',
//       'GUINATAN',
//       'IMELDA BLISS VILLAGE',
//       'LULLUTAN',
//       'MALALAM',
//       'MALASIN (ANGELES)',
//       'MANARING',
//       'MANGCURAM',
//       'MARANA I',
//       'MARANA II',
//       'MARANA III',
//       'MINABANG',
//       'MORADO',
//       'NAGUILIAN NORTE',
//       'NAGUILIAN SUR',
//       'NAMNAMA',
//       'NANAGUAN',
//       'OSMEÑA (SINIPPIL)',
//       'PALIUEG',
//       'PASA',
//       'PILAR',
//       'QUIMALABASA',
//       'RANG-AYAN (BINTACAN)',
//       'RUGAO',
//       'SALINDINGAN',
//       'SAN ANDRES (ANGARILLA)',
//       'SAN FELIPE',
//       'SAN IGNACIO (CANAPI)',
//       'SAN ISIDRO',
//       'SAN JUAN',
//       'SAN LORENZO',
//       'SAN PABLO',
//       'SAN RODRIGO',
//       'SAN VICENTE (POB.)',
//       'SANTA BARBARA (POB.)',
//       'SANTA CATALINA',
//       'SANTA ISABEL NORTE',
//       'SANTA ISABEL SUR',
//       'SANTA MARIA (CABESERIA 8)',
//       'SANTA VICTORIA',
//       'SANTO TOMAS',
//       'SIFFU',
//       'SINDON BAYABO',
//       'SINDON MARIDE',
//       'SIPAY',
//       'TANGCUL',
//       'VILLA IMELDA (MAPLAS)',
//     ],
//   },
//   JONES: {
//     province: 'ISABELA',
//     municipality: 'JONES',
//     region: 'REGION II',
//     barangay_list: [
//       'ABULAN',
//       'ADDALAM',
//       'ARUBUB',
//       'BANNAWAG',
//       'BANTAY',
//       'BARANGAY I (POB.)',
//       'BARANGAY II (POB.)',
//       'BARANGCUAG',
//       'DALIBUBON',
//       'DALIGAN',
//       'DIARAO',
//       'DIBULUAN',
//       'DICAMAY I',
//       'DICAMAY II',
//       'DIPANGIT',
//       'DISIMPIT',
//       'DIVINAN',
//       'DUMAWING',
//       'FUGU',
//       'LACAB',
//       'LINAMANAN',
//       'LINOMOT',
//       'MALANNIT',
//       'MINURI',
//       'NAMNAMA',
//       'NAPALIONG',
//       'PALAGAO',
//       'PAPAN ESTE',
//       'PAPAN WESTE',
//       'PAYAC',
//       'PONGPONGAN',
//       'SAN ANTONIO',
//       'SAN ISIDRO',
//       'SAN JOSE',
//       'SAN ROQUE',
//       'SAN SEBASTIAN',
//       'SAN VICENTE',
//       'SANTA ISABEL',
//       'SANTO DOMINGO',
//       'TUPAX',
//       'USOL',
//       'VILLA BELLO',
//     ],
//   },
//   MACONACON: {
//     province: 'ISABELA',
//     municipality: 'MACONACON',
//     region: 'REGION II',
//     barangay_list: [
//       'APLAYA',
//       'CANADAM',
//       'DIANA',
//       'ELEONOR (POB.)',
//       'FELY (POB.)',
//       'LITA (POB.)',
//       'MALASIN',
//       'MINANGA',
//       'REINA MERCEDES',
//       'SANTA MARINA (DIANGGO)',
//     ],
//   },
//   MALLIG: {
//     province: 'ISABELA',
//     municipality: 'MALLIG',
//     region: 'REGION II',
//     barangay_list: [
//       'BINMONTON',
//       'CASILI',
//       'CENTRO I (POB.)',
//       'CENTRO II (POB.)',
//       'HOLY FRIDAY',
//       'MALIGAYA',
//       'MANANO',
//       'OLANGO',
//       'RANG-AYAN',
//       'SAN JOSE NORTE I',
//       'SAN JOSE NORTE II',
//       'SAN JOSE SUR',
//       'SAN PEDRO (BARUCBUC SUR)',
//       'SAN RAMON',
//       'SIEMPRE VIVA NORTE',
//       'SIEMPRE VIVA SUR',
//       'TRINIDAD',
//       'VICTORIA',
//     ],
//   },
//   PALANAN: {
//     province: 'ISABELA',
//     municipality: 'PALANAN',
//     region: 'REGION II',
//     barangay_list: [
//       'ALOMANAY',
//       'BISAG',
//       'CULASI',
//       'DIALAOYAO',
//       'DICABISAGAN EAST (POB.)',
//       'DICABISAGAN WEST (POB.)',
//       'DICADYUAN',
//       'DIDDADUNGAN',
//       'DIDIYAN',
//       'DIMALICU-LICU',
//       'DIMASARI',
//       'DIMATICAN',
//       'MALIGAYA',
//       'MARIKIT',
//       'SAN ISIDRO',
//       'SANTA JACINTA',
//       'VILLA ROBLES',
//     ],
//   },
//   QUIRINO: {
//     province: 'ISABELA',
//     municipality: 'QUIRINO',
//     region: 'REGION II',
//     barangay_list: [
//       'BINARZANG',
//       'CABARUAN',
//       'CAMAAL',
//       'DOLORES',
//       'LUNA',
//       'MANAOAG',
//       'RIZAL',
//       'SAN ISIDRO',
//       'SAN JOSE',
//       'SAN JUAN',
//       'SAN MATEO',
//       'SAN VICENTE',
//       'SANTA CATALINA',
//       'SANTA LUCIA (POB.)',
//       'SANTIAGO',
//       'SANTO DOMINGO',
//       'SINAIT',
//       'SUERTE',
//       'VILLA BULUSAN',
//       'VILLA MIGUEL (UGAK)',
//       'VINTAR',
//     ],
//   },
//   RAMON: {
//     province: 'ISABELA',
//     municipality: 'RAMON',
//     region: 'REGION II',
//     barangay_list: [
//       'AMBATALI',
//       'BANTUG',
//       'BUGALLON NORTE',
//       'BUGALLON PROPER (POB.)',
//       'BURGOS',
//       'GENERAL AGUINALDO',
//       'NAGBACALAN',
//       'OSCARIZ',
//       'PABIL',
//       'PAGRANG-AYAN',
//       'PLANAS',
//       'PUROK NI BULAN',
//       'RANIAG',
//       'SAN ANTONIO',
//       'SAN MIGUEL',
//       'SAN SEBASTIAN',
//       'VILLA BELTRAN',
//       'VILLA CARMEN',
//       'VILLA MARCOS',
//     ],
//   },
//   'REINA MERCEDES': {
//     province: 'ISABELA',
//     municipality: 'REINA MERCEDES',
//     region: 'REGION II',
//     barangay_list: [
//       'BANQUERO',
//       'BINARSANG',
//       'CUTOG GRANDE',
//       'CUTOG PEQUEÑO',
//       'DANGAN',
//       'DISTRICT I (POB.)',
//       'DISTRICT II (POB.)',
//       'LABINAB GRANDE (POB.)',
//       'LABINAB PEQUEÑO (POB.)',
//       'MALLALATANG GRANDE',
//       'MALLALATANG TUNGGUI',
//       'NAPACCU GRANDE',
//       'NAPACCU PEQUEÑO',
//       'SALUCONG',
//       'SANTIAGO',
//       'SANTOR',
//       'SINIPPIL',
//       'TALLUNGAN (POB.)',
//       'TUROD',
//       'VILLADOR',
//     ],
//   },
//   ROXAS: {
//     province: 'PALAWAN',
//     municipality: 'ROXAS',
//     region: 'REGION IV-B',
//     barangay_list: [
//       'ABAROAN',
//       'ANTONINO',
//       'BAGONG BAYAN',
//       'BARANGAY 1 (POB.)',
//       'BARANGAY II (POB.)',
//       'BARANGAY III (POB.)',
//       'BARANGAY IV (POB.)',
//       'BARANGAY V POB. (PORAO ISLAND)',
//       'BARANGAY VI POB. (JOHNSON ISLAND)',
//       'CARAMAY',
//       'DUMARAO',
//       'IRAAN',
//       'JOLO',
//       'MAGARA (ARASAN)',
//       'MALCAMPO',
//       'MENDOZA',
//       'NARRA (MINARA)',
//       'NEW BARBACAN (RETAC)',
//       'NEW CUYO',
//       'NICANOR ZABALA',
//       'RIZAL',
//       'SALVACION',
//       'SAN ISIDRO',
//       'SAN JOSE',
//       'SAN MIGUEL',
//       'SAN NICOLAS',
//       'SANDOVAL',
//       'TAGUMPAY',
//       'TARADUNGAN',
//       'TINITIAN',
//       'TUMARBONG',
//     ],
//   },
//   'SAN GUILLERMO': {
//     province: 'ISABELA',
//     municipality: 'SAN GUILLERMO',
//     region: 'REGION II',
//     barangay_list: [
//       'ANONANG',
//       'ARINGAY',
//       'BURGOS',
//       'CALAOAGAN',
//       'CENTRO 1 (POB.)',
//       'CENTRO 2 (POB.)',
//       'COLORADO',
//       'DIETBAN',
//       'DINGADING',
//       'DIPACAMO',
//       'ESTRELLA',
//       'GUAM',
//       'NAKAR',
//       'PALAWAN',
//       'PROGRESO',
//       'RIZAL',
//       'SAN FRANCISCO NORTE',
//       'SAN FRANCISCO SUR',
//       'SAN MARIANO NORTE',
//       'SAN MARIANO SUR',
//       'SAN RAFAEL',
//       'SINALUGAN',
//       'VILLA REMEDIOS',
//       'VILLA ROSE',
//       'VILLA SANCHEZ',
//       'VILLA TERESITA',
//     ],
//   },
//   'SAN MARIANO': {
//     province: 'ISABELA',
//     municipality: 'SAN MARIANO',
//     region: 'REGION II',
//     barangay_list: [
//       'ALIBADABAD',
//       'BALAGAN',
//       'BINATUG',
//       'BITABIAN',
//       'BUYASAN',
//       'CADSALAN',
//       'CASALA',
//       'CATAGUING',
//       'DARAGUTAN EAST',
//       'DARAGUTAN WEST',
//       'DEL PILAR',
//       'DIBULUAN',
//       'DICAMAY',
//       'DIPUSU',
//       'DISULAP',
//       'DISUSUAN',
//       'GANGALAN',
//       'IBUJAN',
//       'LIBERTAD',
//       'MACAYUCAYU',
//       'MALLABO',
//       'MARANNAO',
//       'MINANGA',
//       'OLD SAN MARIANO',
//       'PALUTAN',
//       'PANNINAN',
//       'SAN JOSE',
//       'SAN PABLO',
//       'SAN PEDRO',
//       'SANTA FILOMINA',
//       'TAPPA',
//       'UEG',
//       'ZAMORA',
//       'ZONE I (POB.)',
//       'ZONE II (POB.)',
//       'ZONE III (POB.)',
//     ],
//   },
//   'SAN MATEO': {
//     province: 'RIZAL',
//     municipality: 'SAN MATEO',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'AMPID I',
//       'AMPID II',
//       'BANABA',
//       'DULONG BAYAN 1',
//       'DULONG BAYAN 2',
//       'GUINAYANG',
//       'GUITNANG BAYAN I (POB.)',
//       'GUITNANG BAYAN II (POB.)',
//       'GULOD MALAYA',
//       'MALANDAY',
//       'MALY',
//       'PINTONG BOCAWE',
//       'SANTA ANA',
//       'SANTO NIÑO',
//       'SILANGAN',
//     ],
//   },
//   'SAN PABLO': {
//     province: 'ZAMBOANGA DEL SUR',
//     municipality: 'SAN PABLO',
//     region: 'REGION IX',
//     barangay_list: [
//       'BAG-ONG MISAMIS',
//       'BUBUAL',
//       'BUTON',
//       'CULASIAN',
//       'DAPLAYAN',
//       'KALILANGAN',
//       'KAPAMANOK',
//       'KONDUM',
//       'LUMBAYAO',
//       'MABUHAY',
//       'MARCOS VILLAGE',
//       'MIASIN',
//       'MOLANSONG',
//       'PANTAD',
//       'PAO',
//       'PAYAG',
//       'POBLACION (SAN PABLO)',
//       'PONGAPONG',
//       'SACBULAN',
//       'SAGASAN',
//       'SAN JUAN',
//       'SENIOR',
//       'SONGGOY',
//       'TANDUBUAY',
//       'TANIAPAN',
//       'TICALA ISLAND',
//       'TUBO-PAIT',
//       'VILLAKAPA',
//     ],
//   },
//   TUMAUINI: {
//     province: 'ISABELA',
//     municipality: 'TUMAUINI',
//     region: 'REGION II',
//     barangay_list: [
//       'ANNAFUNAN',
//       'ANTAGAN I',
//       'ANTAGAN II',
//       'ARCON',
//       'BALUG',
//       'BANIG',
//       'BANTUG',
//       'BARANGAY DISTRICT 1 (POB.)',
//       'BARANGAY DISTRICT 2 (POB.)',
//       'BARANGAY DISTRICT 3 (POB.)',
//       'BARANGAY DISTRICT 4 (POB.)',
//       'BAYABO EAST',
//       'CALIGAYAN',
//       'CAMASI',
//       'CARPENTERO',
//       'COMPANIA',
//       'CUMABAO',
//       'FERMELDY (HCDA. SAN FRANCISCO)',
//       'FUGU ABAJO',
//       'FUGU NORTE',
//       'FUGU SUR',
//       'LALAUANAN',
//       'LANNA',
//       'LAPOGAN',
//       'LINGALING',
//       'LIWANAG',
//       'MALAMAG EAST',
//       'MALAMAG WEST',
//       'MALIGAYA',
//       'MINANGA',
//       'MOLDERO',
//       'NAMNAMA',
//       'PARAGU',
//       'PILITAN',
//       'SAN MATEO',
//       'SAN PEDRO',
//       'SAN VICENTE',
//       'SANTA',
//       'SANTA CATALINA',
//       'SANTA VISITACION (MAGGAYU)',
//       'SANTO NIÑO',
//       'SINIPPIL',
//       'SISIM ABAJO',
//       'SISIM ALTO',
//       'TUNGGUI',
//       'UGAD',
//     ],
//   },
//   'ALFONSO CASTANEDA': {
//     province: 'NUEVA VIZCAYA',
//     municipality: 'ALFONSO CASTANEDA',
//     region: 'REGION II',
//     barangay_list: [
//       'ABUYO',
//       'CAUAYAN',
//       'GALINTUJA',
//       'LIPUGA',
//       'LUBLUB (POB.)',
//       'PELAWAY',
//     ],
//   },
//   AMBAGUIO: {
//     province: 'NUEVA VIZCAYA',
//     municipality: 'AMBAGUIO',
//     region: 'REGION II',
//     barangay_list: [
//       'AMMUEG',
//       'CAMANDAG',
//       'DULLI',
//       'LABANG',
//       'NAPO',
//       'POBLACION',
//       'SALINGSINGAN',
//       'TIBLAC',
//     ],
//   },
//   ARITAO: {
//     province: 'NUEVA VIZCAYA',
//     municipality: 'ARITAO',
//     region: 'REGION II',
//     barangay_list: [
//       'ANAYO',
//       'BAAN',
//       'BALITE',
//       'BANGANAN',
//       'BETI',
//       'BONE NORTH',
//       'BONE SOUTH',
//       'CALITLITAN',
//       'CANABUAN',
//       'CANAREM',
//       'COMON',
//       'CUTAR',
//       'DARAPIDAP',
//       'KIRANG',
//       'LATAR-NOCNOC-SAN FRANCISCO',
//       'NAGCUARTELAN',
//       'OCAO-CAPINIAAN',
//       'POBLACION',
//       'SANTA CLARA',
//       'TABUENG',
//       'TUCANON',
//       'YAWAY',
//     ],
//   },
//   BAGABAG: {
//     province: 'NUEVA VIZCAYA',
//     municipality: 'BAGABAG',
//     region: 'REGION II',
//     barangay_list: [
//       'BAKIR',
//       'BARETBET',
//       'CAREB',
//       'LANTAP',
//       'MURONG',
//       'NANGALISAN',
//       'PANIKI',
//       'POGONSINO',
//       'QUIRINO (POB.)',
//       'SAN GERONIMO (POB.)',
//       'SAN PEDRO (POB.)',
//       'SANTA CRUZ',
//       'SANTA LUCIA',
//       'TUAO NORTH',
//       'TUAO SOUTH',
//       'VILLA COLOMA (POB.)',
//       'VILLAROS',
//     ],
//   },
//   BAMBANG: {
//     province: 'NUEVA VIZCAYA',
//     municipality: 'BAMBANG',
//     region: 'REGION II',
//     barangay_list: [
//       'ABIAN',
//       'ABINGANAN',
//       'ALIAGA',
//       'ALMAGUER NORTH',
//       'ALMAGUER SOUTH',
//       'BANGGOT (POB.)',
//       'BARAT',
//       'BUAG (POB.)',
//       'CALAOCAN (POB.)',
//       'DULLAO',
//       'HOMESTEAD',
//       'INDIANA',
//       'MABUSLO',
//       'MACATE',
//       'MAGSAYSAY HILLS',
//       'MANAMTAM',
//       'MAUAN',
//       'PALLAS',
//       'SALINAS',
//       'SAN ANTONIO NORTH',
//       'SAN ANTONIO SOUTH',
//       'SAN FERNANDO',
//       'SAN LEONARDO',
//       'SANTO DOMINGO (TABANGAN)',
//       'SANTO DOMINGO WEST',
//     ],
//   },
//   BAYOMBONG: {
//     province: 'NUEVA VIZCAYA',
//     municipality: 'BAYOMBONG',
//     region: 'REGION II',
//     barangay_list: [
//       'BANSING',
//       'BONFAL EAST',
//       'BONFAL PROPER',
//       'BONFAL WEST',
//       'BUENAVISTA (VISTA HILLS)',
//       'BUSILAC',
//       'CABUAAN',
//       'CASAT',
//       'DISTRICT III POB. (DON M. PEREZ)',
//       'DISTRICT IV (POB.)',
//       'DON DOMINGO MADDELA POB. (DISTRICT I)',
//       'DON MARIANO MARCOS',
//       'DON TOMAS MADDELA POB. (DISTRICT II)',
//       'IPIL-CUNEG',
//       'LA TORRE NORTH',
//       'LA TORRE SOUTH',
//       'LUYANG',
//       'MAGAPUY',
//       'MAGSAYSAY',
//       'MASOC',
//       'PAITAN',
//       'SALVACION',
//       'SAN NICOLAS NORTH (LUYANG)',
//       'SANTA ROSA',
//       'VISTA ALEGRE (B. BARINGIN)',
//     ],
//   },
//   DIADI: {
//     province: 'NUEVA VIZCAYA',
//     municipality: 'DIADI',
//     region: 'REGION II',
//     barangay_list: [
//       'AMPAKLING',
//       'ARWAS',
//       'BALETE',
//       'BUGNAY',
//       'BUTAO',
//       'DECABACAN',
//       'DURUAROG',
//       'ESCOTING',
//       'LANGCA',
//       'LURAD',
//       'NAGSABARAN',
//       'NAMAMPARAN',
//       'PINYA',
//       'POBLACION',
//       'ROSARIO',
//       'SAN LUIS',
//       'SAN PABLO',
//       'VILLA AURORA',
//       'VILLA FLORENTINO',
//     ],
//   },
//   'DUPAX DEL NORTE': {
//     province: 'NUEVA VIZCAYA',
//     municipality: 'DUPAX DEL NORTE',
//     region: 'REGION II',
//     barangay_list: [
//       'BELANCE',
//       'BINNUANGAN',
//       'BITNONG',
//       'BULALA',
//       'INABAN',
//       'INEANGAN',
//       'LAMO',
//       'MABASA',
//       'MACABENGA',
//       'MALASIN (POB.)',
//       'MUNGUIA',
//       'NEW GUMIAD',
//       'OYAO',
//       'PARAI',
//       'YABBI',
//     ],
//   },
//   'DUPAX DEL SUR': {
//     province: 'NUEVA VIZCAYA',
//     municipality: 'DUPAX DEL SUR',
//     region: 'REGION II',
//     barangay_list: [
//       'ABACA',
//       'BAGUMBAYAN',
//       'BALSAIN',
//       'BANILA',
//       'BIRUK',
//       'CANABAY',
//       'CAROLOTAN',
//       'DOMANG',
//       'DOPAJ',
//       'GABUT',
//       'GANAO (LINGAD)',
//       'KIMBUTAN',
//       'KINABUAN',
//       'LUKIDNON',
//       'MANGAYANG',
//       'PALABOTAN',
//       'SANGUIT',
//       'SANTA MARIA',
//       'TALBEK',
//     ],
//   },
//   KASIBU: {
//     province: 'NUEVA VIZCAYA',
//     municipality: 'KASIBU',
//     region: 'REGION II',
//     barangay_list: [
//       'ALIMIT',
//       'ALLOY',
//       'ANTUTOT',
//       'BILET',
//       'BINOGAWAN',
//       'BIYOY',
//       'BUA',
//       'CAMAMASI',
//       'CAPISAAN',
//       'CATARAWAN',
//       'CORDON',
//       'DIDIPIO',
//       'DINE',
//       'KAKIDUGUEN',
//       'KONGKONG',
//       'LUPA',
//       'MACALONG',
//       'MALABING',
//       'MUTA',
//       'NANTAWACAN',
//       'PACQUET (ILONGOT RES.)',
//       'PAO',
//       'PAPAYA',
//       'POBLACION (ALLOY)',
//       'PUDI',
//       'SEGUEM',
//       'TADJI',
//       'TOKOD',
//       'WANGAL',
//       'WATWAT',
//     ],
//   },
//   KAYAPA: {
//     province: 'NUEVA VIZCAYA',
//     municipality: 'KAYAPA',
//     region: 'REGION II',
//     barangay_list: [
//       'ACACIA',
//       'ALANG-SALACSAC',
//       'AMILONG LABENG',
//       'ANSIPSIP',
//       'BAAN',
//       'BABADI',
//       'BALANGABANG',
//       'BALETE',
//       'BANAO',
//       'BESONG',
//       'BINALIAN',
//       'BUYASYAS',
//       'CABALATAN-ALANG',
//       'CABANGLASAN',
//       'CABAYO',
//       'CASTILLO VILLAGE',
//       'KAYAPA PROPER EAST',
//       'KAYAPA PROPER WEST',
//       'LATBANG',
//       'LAWIGAN',
//       'MAPAYAO',
//       'NANSIAKAN',
//       'PAMPANG (POB.)',
//       'PANGAWAN',
//       'PINAYAG',
//       'PINGKIAN',
//       'SAN FABIAN',
//       'TALECABCAB',
//       'TIDANG VILLAGE',
//       'TUBONGAN',
//     ],
//   },
//   'SANTA FE': {
//     province: 'LEYTE',
//     municipality: 'SANTA FE',
//     region: 'REGION VIII',
//     barangay_list: [
//       'BACULANAD',
//       'BADIANGAY',
//       'BULOD',
//       'CATOOGAN',
//       'CUTAY',
//       'GAPAS',
//       'KATIPUNAN',
//       'MILAGROSA',
//       'PILIT',
//       'PITOGO',
//       'SAN ISIDRO',
//       'SAN JUAN',
//       'SAN MIGUELAY',
//       'SAN ROQUE',
//       'TIBAK',
//       'VICTORIA',
//       'ZONE 1 (POB.)',
//       'ZONE 2 (POB.)',
//       'ZONE 3 (POB.)',
//       'ZONE 4 POB. (CABANGCALAN)',
//     ],
//   },
//   SOLANO: {
//     province: 'NUEVA VIZCAYA',
//     municipality: 'SOLANO',
//     region: 'REGION II',
//     barangay_list: [
//       'AGGUB',
//       'BAGAHABAG',
//       'BANGAAN',
//       'BANGAR',
//       'BASCARAN',
//       'COMMUNAL',
//       'CONCEPCION (CALALABANGAN)',
//       'CURIFANG',
//       'DADAP',
//       'LACTAWAN',
//       'OSMEÑA',
//       'PILAR D. GALIMA',
//       'POBLACION NORTH',
//       'POBLACION SOUTH',
//       'QUEZON',
//       'QUIRINO',
//       'ROXAS',
//       'SAN JUAN',
//       'SAN LUIS',
//       'TUCAL',
//       'UDDIAWAN',
//       'WACAL',
//     ],
//   },
//   VILLAVERDE: {
//     province: 'NUEVA VIZCAYA',
//     municipality: 'VILLAVERDE',
//     region: 'REGION II',
//     barangay_list: [
//       'BINTAWAN NORTE',
//       'BINTAWAN SUR',
//       'CABULUAN',
//       'IBUNG',
//       'NAGBITIN',
//       'OCAPON',
//       'PIEZA',
//       'POBLACION (TUROD)',
//       'SAWMILL',
//     ],
//   },
//   AGLIPAY: {
//     province: 'QUIRINO',
//     municipality: 'AGLIPAY',
//     region: 'REGION II',
//     barangay_list: [
//       'ALICIA',
//       'CABUGAO',
//       'DAGUPAN',
//       'DIODOL',
//       'DUMABEL',
//       'DUNGO (OSMEÑA)',
//       'GUINALBIN',
//       'LIGAYA',
//       'NAGABGABAN',
//       'PALACIAN',
//       'PINARIPAD NORTE',
//       'PINARIPAD SUR',
//       'PROGRESO (POB.)',
//       'RAMOS',
//       'RANG-AYAN',
//       'SAN ANTONIO',
//       'SAN BENIGNO',
//       'SAN FRANCISCO',
//       'SAN LEONARDO (CABARROGUIS)',
//       'SAN MANUEL',
//       'SAN RAMON',
//       'VICTORIA',
//       'VILLA PAGADUAN',
//       'VILLA SANTIAGO',
//       'VILLA VENTURA',
//     ],
//   },
//   CABARROGUIS: {
//     province: 'QUIRINO',
//     municipality: 'CABARROGUIS',
//     region: 'REGION II',
//     barangay_list: [
//       'BANUAR',
//       'BURGOS',
//       'CALAOCAN',
//       'DEL PILAR',
//       'DIBIBI',
//       'DINGASAN',
//       'EDEN',
//       'GOMEZ',
//       'GUNDAWAY (POB.)',
//       'MANGANDINGAY (POB.)',
//       'SAN MARCOS',
//       'SANTO DOMINGO',
//       'TUCOD',
//       'VILLA PEÑA (CAPELLANGAN)',
//       'VILLAMOR',
//       'VILLAROSE',
//       'ZAMORA',
//     ],
//   },
//   DIFFUN: {
//     province: 'QUIRINO',
//     municipality: 'DIFFUN',
//     region: 'REGION II',
//     barangay_list: [
//       'AKLAN VILLAGE',
//       'ANDRES BONIFACIO (POB.)',
//       'AURORA EAST (POB.)',
//       'AURORA WEST (POB.)',
//       'BAGUIO VILLAGE',
//       'BALAGBAG',
//       'BANNAWAG',
//       'CAJEL',
//       'CAMPAMENTO',
//       'DIEGO SILANG',
//       'DON FAUSTINO PAGADUAN',
//       'DON MARIANO PEREZ, SR.',
//       'DOÑA IMELDA',
//       'DUMANISI',
//       'GABRIELA SILANG',
//       'GREGORIO PIMENTEL',
//       'GULAC',
//       'GURIBANG',
//       'IFUGAO VILLAGE',
//       'ISIDRO PAREDES',
//       'LIWAYWAY',
//       'LUTTUAD',
//       'MAGSAYSAY',
//       'MAKATE',
//       'MARIA CLARA',
//       'RAFAEL PALMA (DON SERGIO OSMEÑA)',
//       'RICARTE NORTE',
//       'RICARTE SUR',
//       'RIZAL (POB.)',
//       'SAN ANTONIO',
//       'SAN ISIDRO',
//       'SAN PASCUAL',
//       'VILLA PASCUA',
//     ],
//   },
//   MADDELA: {
//     province: 'QUIRINO',
//     municipality: 'MADDELA',
//     region: 'REGION II',
//     barangay_list: [
//       'ABBAG',
//       'BALLIGUI',
//       'BUENAVISTA',
//       'CABARUAN',
//       'CABUA-AN',
//       'COFCAVILLE',
//       'DIDUYON',
//       'DIPINTIN',
//       'DIVISORIA NORTE',
//       'DIVISORIA SUR (BISANGAL)',
//       'DUMABATO NORTE',
//       'DUMABATO SUR',
//       'JOSE ANCHETA',
//       'LUSOD',
//       'MANGLAD',
//       'PEDLISAN',
//       'POBLACION NORTE',
//       'POBLACION SUR',
//       'SAN BERNABE',
//       'SAN DIONISIO I',
//       'SAN MARTIN',
//       'SAN PEDRO',
//       'SAN SALVADOR',
//       'SANTA MARIA',
//       'SANTO NIÑO',
//       'SANTO TOMAS',
//       'VILLA AGULLANA',
//       'VILLA GRACIA',
//       'VILLA HERMOSA NORTE',
//       'VILLA HERMOSA SUR',
//       'VILLA JOSE V YLANAN',
//       'YSMAEL',
//     ],
//   },
//   NAGTIPUNAN: {
//     province: 'QUIRINO',
//     municipality: 'NAGTIPUNAN',
//     region: 'REGION II',
//     barangay_list: [
//       'ANAK',
//       'ASAKLAT',
//       'DIPANTAN',
//       'DISSIMUNGAL',
//       'GUINO (GIAYAN)',
//       'LA CONWAP (GUINGIN)',
//       'LANDINGAN',
//       'MATADDI',
//       'MATMAD',
//       'OLD GUMIAD',
//       'PONGGO',
//       'SAN DIONISIO II',
//       'SAN PUGO',
//       'SAN RAMOS',
//       'SANGBAY',
//       'WASID',
//     ],
//   },
//   SAGUDAY: {
//     province: 'QUIRINO',
//     municipality: 'SAGUDAY',
//     region: 'REGION II',
//     barangay_list: [
//       'CARDENAS',
//       'DIBUL',
//       'GAMIS',
//       'LA PAZ',
//       'MAGSAYSAY (POB.)',
//       'RIZAL (POB.)',
//       'SALVACION',
//       'SANTO TOMAS',
//       'TRES REYES',
//     ],
//   },
//   BALER: {
//     province: 'AURORA',
//     municipality: 'BALER',
//     region: 'REGION III',
//     barangay_list: [
//       'BARANGAY I (POB.)',
//       'BARANGAY II (POB.)',
//       'BARANGAY III (POB.)',
//       'BARANGAY IV (POB.)',
//       'BARANGAY V (POB.)',
//       'BUHANGIN',
//       'CALABUANAN',
//       'OBLIGACION',
//       'PINGIT',
//       'RESERVA',
//       'SABANG',
//       'SUCLAYIN',
//       'ZABALI',
//     ],
//   },
//   CASIGURAN: {
//     province: 'SORSOGON',
//     municipality: 'CASIGURAN',
//     region: 'REGION V',
//     barangay_list: [
//       'ADOVIS (POB.)',
//       'BOTON',
//       'BURGOS',
//       'CASAY',
//       'CAWIT',
//       'CENTRAL (POB.)',
//       'COGON',
//       'COLAMBIS',
//       'ESCUALA',
//       'INLAGADIAN',
//       'LUNGIB',
//       'MABINI',
//       'PONONG',
//       'RIZAL',
//       'SAN ANTONIO (BOHO)',
//       'SAN ISIDRO',
//       'SAN JUAN',
//       'SAN PASCUAL',
//       'SANTA CRUZ',
//       'SOMAL-OT (POB.)',
//       'TIGBAO',
//       'TIMBAYOG (POB.)',
//       'TIRIS',
//       'TRECE MARTIREZ',
//       'TULAY',
//     ],
//   },
//   DILASAG: {
//     province: 'AURORA',
//     municipality: 'DILASAG',
//     region: 'REGION III',
//     barangay_list: [
//       'DIAGYAN',
//       'DICABASAN',
//       'DILAGUIDI',
//       'DIMASESET',
//       'DINIOG',
//       'ESPERANZA',
//       'LAWANG',
//       'MALIGAYA (POB.)',
//       'MANGGITAHAN',
//       'MASAGANA (POB.)',
//       'URA',
//     ],
//   },
//   DINALUNGAN: {
//     province: 'AURORA',
//     municipality: 'DINALUNGAN',
//     region: 'REGION III',
//     barangay_list: [
//       'ABULEG',
//       'DIBARAYBAY',
//       'DITAWINI',
//       'MAPALAD',
//       'NIPOO (BULO)',
//       'PALEG',
//       'SIMBAHAN',
//       'ZONE I (POB.)',
//       'ZONE II (POB.)',
//     ],
//   },
//   DINGALAN: {
//     province: 'AURORA',
//     municipality: 'DINGALAN',
//     region: 'REGION III',
//     barangay_list: [
//       'APLAYA',
//       'BUTAS NA BATO',
//       'CABOG (MATAWE)',
//       'CARAGSACAN',
//       'DAVILDAVILAN',
//       'DIKAPANIKIAN',
//       'IBONA',
//       'PALTIC',
//       'POBLACION',
//       'TANAWAN',
//       'UMIRAY (MALAMIG)',
//     ],
//   },
//   DIPACULAO: {
//     province: 'AURORA',
//     municipality: 'DIPACULAO',
//     region: 'REGION III',
//     barangay_list: [
//       'BAYABAS',
//       'BORLONGAN',
//       'BUENAVISTA',
//       'CALAOCAN',
//       'DIAMANEN',
//       'DIANED',
//       'DIARABASIN',
//       'DIBUTUNAN',
//       'DIMABUNO',
//       'DINADIAWAN',
//       'DITALE',
//       'GUPA',
//       'IPIL',
//       'LABOY',
//       'LIPIT',
//       'LOBBOT',
//       'MALIGAYA',
//       'MIJARES',
//       'MUCDOL',
//       'NORTH POBLACION',
//       'PUANGI',
//       'SALAY',
//       'SAPANGKAWAYAN',
//       'SOUTH POBLACION',
//       'TOYTOYAN',
//     ],
//   },
//   'MARIA AURORA': {
//     province: 'AURORA',
//     municipality: 'MARIA AURORA',
//     region: 'REGION III',
//     barangay_list: [
//       'ALCALA',
//       'BAGTU',
//       'BANGCO',
//       'BANNAWAG',
//       'BARANGAY I (POB.)',
//       'BARANGAY II (POB.)',
//       'BARANGAY III (POB.)',
//       'BARANGAY IV (POB.)',
//       'BAUBO',
//       'BAYANIHAN',
//       'BAZAL',
//       'CABITUCULAN EAST',
//       'CABITUCULAN WEST',
//       'CADAYACAN',
//       'DEBUCAO',
//       'DECOLIAT',
//       'DETAILEN',
//       'DIAAT',
//       'DIALATMAN',
//       'DIAMAN',
//       'DIANAWAN',
//       'DIKILDIT',
//       'DIMANPUDSO',
//       'DIOME',
//       'ESTONILO',
//       'FLORIDA',
//       'GALINTUJA',
//       'MALASIN',
//       'PONGLO',
//       'QUIRINO',
//       'RAMADA',
//       'SAN JOAQUIN',
//       'SAN JOSE',
//       'SAN JUAN',
//       'SAN LEONARDO',
//       'SANTA LUCIA',
//       'SANTO TOMAS',
//       'SUGUIT',
//       'VILLA AURORA',
//       'WENCESLAO',
//     ],
//   },
//   ABUCAY: {
//     province: 'BATAAN',
//     municipality: 'ABUCAY',
//     region: 'REGION III',
//     barangay_list: [
//       'BANGKAL',
//       'CALAYLAYAN (POB.)',
//       'CAPITANGAN',
//       'GABON',
//       'LAON (POB.)',
//       'MABATANG',
//       'OMBOY',
//       'SALIAN',
//       'WAWA (POB.)',
//     ],
//   },
//   BAGAC: {
//     province: 'BATAAN',
//     municipality: 'BAGAC',
//     region: 'REGION III',
//     barangay_list: [
//       'ATILANO L. RICARDO',
//       'BAGUMBAYAN (POB.)',
//       'BANAWANG',
//       'BINUANGAN',
//       'BINUKAWAN',
//       'IBABA',
//       'IBIS',
//       'PAG-ASA (WAWA-SIBACAN)',
//       'PARANG',
//       'PAYSAWAN',
//       'QUINAWAN',
//       'SAN ANTONIO',
//       'SAYSAIN',
//       'TABING-ILOG (POB.)',
//     ],
//   },
//   'BALANGA': {
//     province: 'BATAAN',
//     municipality: 'BALANGA',
//     region: 'REGION III',
//     barangay_list: [
//       'BAGONG SILANG',
//       'BAGUMBAYAN',
//       'CABOG-CABOG',
//       'CAMACHO',
//       'CATANING',
//       'CENTRAL',
//       'CUPANG NORTH',
//       'CUPANG PROPER',
//       'CUPANG WEST',
//       'DANGCOL (BERNABE)',
//       'DOÑA FRANCISCA',
//       'IBAYO',
//       'LOTE',
//       'MALABIA',
//       'MUNTING BATANGAS (CADRE)',
//       'POBLACION',
//       'PTO. RIVAS IBABA',
//       'PTO. RIVAS ITAAS',
//       'SAN JOSE',
//       'SIBACAN',
//       'TALISAY',
//       'TANATO',
//       'TENEJERO',
//       'TORTUGAS',
//       'TUYO',
//     ],
//   },
//   DINALUPIHAN: {
//     province: 'BATAAN',
//     municipality: 'DINALUPIHAN',
//     region: 'REGION III',
//     barangay_list: [
//       'AQUINO',
//       'BANGAL',
//       'BAYAN-BAYANAN',
//       'BONIFACIO (POB.)',
//       'BURGOS (POB.)',
//       'COLO',
//       'DAANG BAGO',
//       'DALAO',
//       'DEL PILAR (POB.)',
//       'GEN. LUNA (POB.)',
//       'GOMEZ (POB.)',
//       'HAPPY VALLEY',
//       'JOSE C. PAYUMO, JR.',
//       'KATAASAN',
//       'LAYAC',
//       'LUACAN',
//       'MABINI EXT. (POB.)',
//       'MABINI PROPER (POB.)',
//       'MAGSAYSAY',
//       'MALIGAYA',
//       'NAPARING',
//       'NEW SAN JOSE',
//       'OLD SAN JOSE',
//       'PADRE DANDAN (POB.)',
//       'PAG-ASA',
//       'PAGALANGGANG',
//       'PAYANGAN',
//       'PENTOR',
//       'PINULOT',
//       'PITA',
//       'RIZAL (POB.)',
//       'ROOSEVELT',
//       'ROXAS (POB.)',
//       'SAGUING',
//       'SAN BENITO',
//       'SAN ISIDRO (POB.)',
//       'SAN PABLO (BULATE)',
//       'SAN RAMON',
//       'SAN SIMON',
//       'SANTA ISABEL (TABACAN)',
//       'SANTO NIÑO',
//       'SAPANG BALAS',
//       'TORRES BUGAUEN (POB.)',
//       'TUBO-TUBO',
//       'TUCOP',
//       'ZAMORA (POB.)',
//     ],
//   },
//   HERMOSA: {
//     province: 'BATAAN',
//     municipality: 'HERMOSA',
//     region: 'REGION III',
//     barangay_list: [
//       'A. RIVERA (POB.)',
//       'ALMACEN',
//       'BACONG',
//       'BALSIC',
//       'BAMBAN',
//       'BURGOS-SOLIMAN (POB.)',
//       'CATANING (POB.)',
//       'CULIS',
//       'DAUNGAN (POB.)',
//       'JUDGE ROMAN CRUZ SR. (MANDAMA)',
//       'MABIGA',
//       'MABUCO',
//       'MAITE',
//       'MAMBOG - MANDAMA',
//       'PALIHAN',
//       'PANDATUNG',
//       'PULO',
//       'SABA',
//       'SACRIFICE VALLEY',
//       'SAN PEDRO (POB.)',
//       'SANTO CRISTO (POB.)',
//       'SUMALO',
//       'TIPO',
//     ],
//   },
//   LIMAY: {
//     province: 'BATAAN',
//     municipality: 'LIMAY',
//     region: 'REGION III',
//     barangay_list: [
//       'ALANGAN',
//       'DUALE',
//       'KITANG 2 AND LUZ',
//       'KITANG I',
//       'LAMAO',
//       'LANDING',
//       'POBLACION',
//       'REFORMISTA',
//       'SAN FRANCISCO DE ASIS',
//       'ST. FRANCIS II',
//       'TOWNSITE',
//       'WAWA',
//     ],
//   },
//   MARIVELES: {
//     province: 'BATAAN',
//     municipality: 'MARIVELES',
//     region: 'REGION III',
//     barangay_list: [
//       'ALAS-ASIN',
//       'ALION',
//       'BALON-ANITO',
//       'BASECO COUNTRY (NASSCO)',
//       'BATANGAS II',
//       'BIAAN',
//       'CABCABEN',
//       'CAMAYA',
//       'IPAG',
//       'LUCANIN',
//       'MALAYA',
//       'MALIGAYA',
//       'MT. VIEW',
//       'POBLACION',
//       'SAN CARLOS',
//       'SAN ISIDRO',
//       'SISIMAN',
//       'TOWNSITE',
//     ],
//   },
//   MORONG: {
//     province: 'RIZAL',
//     municipality: 'MORONG',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'BOMBONGAN',
//       'CAN-CAL-LAN (CANIOGAN-CALERO-LANANG)',
//       'LAGUNDI',
//       'MAYBANCAL',
//       'SAN GUILLERMO',
//       'SAN JOSE (POB.)',
//       'SAN JUAN (POB.)',
//       'SAN PEDRO (POB.)',
//     ],
//   },
//   ORANI: {
//     province: 'BATAAN',
//     municipality: 'ORANI',
//     region: 'REGION III',
//     barangay_list: [
//       'APOLLO',
//       'BAGONG PARAISO (POB.)',
//       'BALUT (POB.)',
//       'BAYAN (POB.)',
//       'CALERO (POB.)',
//       'CENTRO I (POB.)',
//       'CENTRO II (POB.)',
//       'DONA',
//       'KABALUTAN',
//       'KAPARANGAN',
//       'MARIA FE',
//       'MASANTOL',
//       'MULAWIN',
//       'PAG-ASA',
//       'PAKING-CARBONERO (POB.)',
//       'PALIHAN (POB.)',
//       'PANTALAN BAGO (POB.)',
//       'PANTALAN LUMA (POB.)',
//       'PARANG PARANG (POB.)',
//       'PUKSUAN',
//       'SIBUL',
//       'SILAHIS',
//       'TAGUMPAY',
//       'TALA',
//       'TALIMUNDOC',
//       'TAPULAO',
//       'TENEJERO (POB.)',
//       'TUGATOG',
//       'WAWA (POB.)',
//     ],
//   },
//   ORION: {
//     province: 'BATAAN',
//     municipality: 'ORION',
//     region: 'REGION III',
//     barangay_list: [
//       'ARELLANO (POB.)',
//       'BAGUMBAYAN (POB.)',
//       'BALAGTAS (POB.)',
//       'BALUT (POB.)',
//       'BANTAN',
//       'BILOLO',
//       'CALUNGUSAN',
//       'CAMACHILE',
//       'DAANG BAGO (POB.)',
//       'DAANG BILOLO (POB.)',
//       'DAANG PARE',
//       'GENERAL LIM (KAPUT)',
//       'KAPUNITAN',
//       'LATI (POB.)',
//       'LUSUNGAN (POB.)',
//       'PUTING BUHANGIN',
//       'SABATAN',
//       'SAN VICENTE (POB.)',
//       'SANTA ELENA',
//       'SANTO DOMINGO',
//       'VILLA ANGELES (POB.)',
//       'WAKAS (POB.)',
//       'WAWA (POB.)',
//     ],
//   },
//   SAMAL: {
//     province: 'BATAAN',
//     municipality: 'SAMAL',
//     region: 'REGION III',
//     barangay_list: [
//       'EAST CALAGUIMAN (POB.)',
//       'EAST DAANG BAGO (POB.)',
//       'GUGO',
//       'IBABA (POB.)',
//       'IMELDA',
//       'LALAWIGAN',
//       'PALILI',
//       'SAN JUAN (POB.)',
//       'SAN ROQUE (POB.)',
//       'SANTA LUCIA',
//       'SAPA',
//       'TABING ILOG',
//       'WEST CALAGUIMAN (POB.)',
//       'WEST DAANG BAGO (POB.)',
//     ],
//   },
//   ANGAT: {
//     province: 'BULACAN',
//     municipality: 'ANGAT',
//     region: 'REGION III',
//     barangay_list: [
//       'BANABAN',
//       'BAYBAY',
//       'BINAGBAG',
//       'DONACION',
//       'ENCANTO',
//       'LAOG',
//       'MARUNGKO',
//       'NIUGAN',
//       'PALTOK',
//       'PULONG YANTOK',
//       'SAN ROQUE (POB.)',
//       'SANTA CRUZ (POB.)',
//       'SANTA LUCIA',
//       'SANTO CRISTO (POB.)',
//       'SULUCAN',
//       'TABOC',
//     ],
//   },
//   'BALAGTAS (BIGAA)': {
//     province: 'BULACAN',
//     municipality: 'BALAGTAS (BIGAA)',
//     region: 'REGION III',
//     barangay_list: [
//       'BOROL 1ST',
//       'BOROL 2ND',
//       'DALIG',
//       'LONGOS',
//       'PANGINAY',
//       'PULONG GUBAT',
//       'SAN JUAN',
//       'SANTOL',
//       'WAWA (POB.)',
//     ],
//   },
//   BALIUAG: {
//     province: 'BULACAN',
//     municipality: 'BALIUAG',
//     region: 'REGION III',
//     barangay_list: [
//       'BAGONG NAYON',
//       'BARANGCA',
//       'CALANTIPAY',
//       'CATULINAN',
//       'CONCEPCION',
//       'HINUKAY',
//       'MAKINABANG',
//       'MATANGTUBIG',
//       'PAGALA',
//       'PAITAN',
//       'PIEL',
//       'PINAGBARILAN',
//       'POBLACION',
//       'SABANG',
//       'SAN JOSE',
//       'SAN ROQUE',
//       'SANTA BARBARA',
//       'SANTO CRISTO',
//       'SANTO NIÑO',
//       'SUBIC',
//       'SULIVAN',
//       'TANGOS',
//       'TARCAN',
//       'TIAONG',
//       'TIBAG',
//       'TILAPAYONG',
//       'VIRGEN DELAS FLORES',
//     ],
//   },
//   BOCAUE: {
//     province: 'BULACAN',
//     municipality: 'BOCAUE',
//     region: 'REGION III',
//     barangay_list: [
//       'ANTIPONA',
//       'BAGUMBAYAN',
//       'BAMBANG',
//       'BATIA',
//       'BIÑANG 1ST',
//       'BIÑANG 2ND',
//       'BOLACAN',
//       'BUNDUKAN',
//       'BUNLO',
//       'CAINGIN',
//       'DUHAT',
//       'IGULOT',
//       'LOLOMBOY',
//       'POBLACION',
//       'SULUCAN',
//       'TAAL',
//       'TAMBOBONG',
//       'TURO',
//       'WAKAS',
//     ],
//   },
//   BULACAN: {
//     province: 'BULACAN',
//     municipality: 'BULACAN',
//     region: 'REGION III',
//     barangay_list: [
//       'BAGUMBAYAN',
//       'BALUBAD',
//       'BAMBANG',
//       'MATUNGAO',
//       'MAYSANTOL',
//       'PEREZ',
//       'PITPITAN',
//       'SAN FRANCISCO',
//       'SAN JOSE (POB.)',
//       'SAN NICOLAS',
//       'SANTA ANA',
//       'SANTA INES',
//       'TALIPTIP',
//       'TIBIG',
//     ],
//   },
//   BUSTOS: {
//     province: 'BULACAN',
//     municipality: 'BUSTOS',
//     region: 'REGION III',
//     barangay_list: [
//       'BONGA MAYOR',
//       'BONGA MENOR',
//       'BUISAN',
//       'CAMACHILIHAN',
//       'CAMBAOG',
//       'CATACTE',
//       'LICIADA',
//       'MALAMIG',
//       'MALAWAK',
//       'POBLACION',
//       'SAN PEDRO',
//       'TALAMPAS',
//       'TANAWAN',
//       'TIBAGAN',
//     ],
//   },
//   CALUMPIT: {
//     province: 'BULACAN',
//     municipality: 'CALUMPIT',
//     region: 'REGION III',
//     barangay_list: [
//       'BALITE',
//       'BALUNGAO',
//       'BUGUION',
//       'BULUSAN',
//       'CALIZON',
//       'CALUMPANG',
//       'CANIOGAN',
//       'CORAZON',
//       'FRANCES',
//       'GATBUCA',
//       'GUGO',
//       'IBA ESTE',
//       "IBA O'ESTE",
//       'LONGOS',
//       'MEYSULAO',
//       'MEYTO',
//       'PALIMBANG',
//       'PANDUCOT',
//       'PIO CRUZCOSA',
//       'POBLACION',
//       'PUNGO',
//       'SAN JOSE',
//       'SAN MARCOS',
//       'SAN MIGUEL',
//       'SANTA LUCIA',
//       'SANTO NIÑO',
//       'SAPANG BAYAN',
//       'SERGIO BAYAN',
//       'SUCOL',
//     ],
//   },
//   'DOÑA REMEDIOS TRINIDAD': {
//     province: 'BULACAN',
//     municipality: 'DOÑA REMEDIOS TRINIDAD',
//     region: 'REGION III',
//     barangay_list: [
//       'BAYABAS',
//       'CAMACHILE',
//       'CAMACHIN',
//       'KABAYUNAN',
//       'KALAWAKAN',
//       'PULONG SAMPALOK',
//       'SAPANG BULAK',
//       'TALBAK',
//     ],
//   },
//   GUIGUINTO: {
//     province: 'BULACAN',
//     municipality: 'GUIGUINTO',
//     region: 'REGION III',
//     barangay_list: [
//       'CUTCUT',
//       'DAUNGAN',
//       'ILANG-ILANG',
//       'MALIS',
//       'PANGINAY',
//       'POBLACION',
//       'PRITIL',
//       'PULONG GUBAT',
//       'SANTA CRUZ',
//       'SANTA RITA',
//       'TABANG',
//       'TABE',
//       'TIAONG',
//       'TUKTUKAN',
//     ],
//   },
//   'MALOLOS': {
//     province: 'BULACAN',
//     municipality: 'MALOLOS',
//     region: 'REGION III',
//     barangay_list: [
//       'ANILAO',
//       'ATLAG',
//       'BABATNIN',
//       'BAGNA',
//       'BAGONG BAYAN',
//       'BALAYONG',
//       'BALITE',
//       'BANGKAL',
//       'BARIHAN',
//       'BULIHAN',
//       'BUNGAHAN',
//       'CAINGIN',
//       'CALERO',
//       'CALILIGAWAN',
//       'CANALATE',
//       'CANIOGAN',
//       'CATMON',
//       'COFRADIA',
//       'DAKILA',
//       'GUINHAWA',
//       'LIGAS',
//       'LIYANG',
//       'LONGOS',
//       'LOOK 1ST',
//       'LOOK 2ND',
//       'LUGAM',
//       'MABOLO',
//       'MAMBOG',
//       'MASILE',
//       'MATIMBO',
//       'MOJON',
//       'NAMAYAN',
//       'NIUGAN',
//       'PAMARAWAN',
//       'PANASAHAN',
//       'PINAGBAKAHAN',
//       'SAN AGUSTIN',
//       'SAN GABRIEL',
//       'SAN JUAN',
//       'SAN PABLO',
//       'SAN VICENTE (POB.)',
//       'SANTIAGO',
//       'SANTISIMA TRINIDAD',
//       'SANTO CRISTO',
//       'SANTO NIÑO (POB.)',
//       'SANTO ROSARIO (POB.)',
//       'SANTOL',
//       'SUMAPANG BATA',
//       'SUMAPANG MATANDA',
//       'TAAL',
//       'TIKAY',
//     ],
//   },
//   MARILAO: {
//     province: 'BULACAN',
//     municipality: 'MARILAO',
//     region: 'REGION III',
//     barangay_list: [
//       'ABANGAN NORTE',
//       'ABANGAN SUR',
//       'IBAYO',
//       'LAMBAKIN',
//       'LIAS',
//       'LOMA DE GATO',
//       'NAGBALON',
//       'PATUBIG',
//       'POBLACION I',
//       'POBLACION II',
//       'PRENZA I',
//       'PRENZA II',
//       'SANTA ROSA I',
//       'SANTA ROSA II',
//       'SAOG',
//       'TABING ILOG',
//     ],
//   },
//   'MEYCAUAYAN': {
//     province: 'BULACAN',
//     municipality: 'MEYCAUAYAN',
//     region: 'REGION III',
//     barangay_list: [
//       'BAGBAGUIN',
//       'BAHAY PARE',
//       'BANCAL',
//       'BANGA',
//       'BAYUGO',
//       'CAINGIN',
//       'CALVARIO',
//       'CAMALIG',
//       'GASAK (SAINT FRANCIS)',
//       'HULO',
//       'IBA',
//       'LANGKA',
//       'LAWA',
//       'LIBTONG',
//       'LIPUTAN',
//       'LONGOS',
//       'MALHACAN',
//       'PAJO',
//       'PANDAYAN',
//       'PANTOC',
//       'PEREZ',
//       'POBLACION',
//       'SALUYSOY',
//       'TUGATOG',
//       'UBIHAN',
//       'ZAMORA',
//     ],
//   },
//   NORZAGARAY: {
//     province: 'BULACAN',
//     municipality: 'NORZAGARAY',
//     region: 'REGION III',
//     barangay_list: [
//       'BANGKAL',
//       'BARAKA',
//       'BIGTE',
//       'BITUNGOL',
//       'FRIENDSHIP VILLAGE RESOURCES',
//       'MATICTIC',
//       'MINUYAN',
//       'PARTIDA',
//       'PINAGTULAYAN',
//       'POBLACION',
//       'SAN LORENZO',
//       'SAN MATEO',
//       'TIGBE',
//     ],
//   },
//   OBANDO: {
//     province: 'BULACAN',
//     municipality: 'OBANDO',
//     region: 'REGION III',
//     barangay_list: [
//       'BINUANGAN',
//       'CATANGHALAN',
//       'HULO',
//       'LAWA',
//       'PACO',
//       'PAG-ASA (POB.)',
//       'PALIWAS',
//       'PANGHULO',
//       'SALAMBAO',
//       'SAN PASCUAL',
//       'TAWIRAN',
//     ],
//   },
//   PANDI: {
//     province: 'BULACAN',
//     municipality: 'PANDI',
//     region: 'REGION III',
//     barangay_list: [
//       'BAGBAGUIN',
//       'BAGONG BARRIO',
//       'BAKA-BAKAHAN',
//       'BUNSURAN I',
//       'BUNSURAN II',
//       'BUNSURAN III',
//       'CACARONG BATA',
//       'CACARONG MATANDA',
//       'CUPANG',
//       'MALIBONG BATA',
//       'MALIBONG MATANDA',
//       'MANATAL',
//       'MAPULANG LUPA',
//       'MASAGANA',
//       'MASUSO',
//       'PINAGKUARTELAN',
//       'POBLACION',
//       'REAL DE CACARONG',
//       'SAN ROQUE',
//       'SANTO NIÑO',
//       'SILING BATA',
//       'SILING MATANDA',
//     ],
//   },
//   PAOMBONG: {
//     province: 'BULACAN',
//     municipality: 'PAOMBONG',
//     region: 'REGION III',
//     barangay_list: [
//       'BINAKOD',
//       'KAPITANGAN',
//       'MALUMOT',
//       'MASUKOL',
//       'PINALAGDAN',
//       'POBLACION',
//       'SAN ISIDRO I',
//       'SAN ISIDRO II',
//       'SAN JOSE',
//       'SAN ROQUE',
//       'SAN VICENTE',
//       'SANTA CRUZ',
//       'SANTO NIÑO',
//       'SANTO ROSARIO',
//     ],
//   },
//   PULILAN: {
//     province: 'BULACAN',
//     municipality: 'PULILAN',
//     region: 'REGION III',
//     barangay_list: [
//       'BALATONG A',
//       'BALATONG B',
//       'CUTCOT',
//       'DAMPOL I',
//       'DAMPOL II-A',
//       'DAMPOL II-B',
//       'DULONG MALABON',
//       'INAON',
//       'LONGOS',
//       'LUMBAC',
//       'PALTAO',
//       'PENABATAN',
//       'POBLACION',
//       'SANTA PEREGRINA',
//       'SANTO CRISTO',
//       'TAAL',
//       'TABON',
//       'TIBAG',
//       'TINEJERO',
//     ],
//   },
//   'SAN JOSE DEL MONTE': {
//     province: 'BULACAN',
//     municipality: 'SAN JOSE DEL MONTE',
//     region: 'REGION III',
//     barangay_list: [
//       'ASSUMPTION',
//       'BAGONG BUHAY',
//       'BAGONG BUHAY II',
//       'BAGONG BUHAY III',
//       'CITRUS',
//       'CIUDAD REAL',
//       'DULONG BAYAN',
//       'FATIMA',
//       'FATIMA II',
//       'FATIMA III',
//       'FATIMA IV',
//       'FATIMA V',
//       'FRANCISCO HOMES-GUIJO',
//       'FRANCISCO HOMES-MULAWIN',
//       'FRANCISCO HOMES-NARRA',
//       'FRANCISCO HOMES-YAKAL',
//       'GAYA-GAYA',
//       'GRACEVILLE',
//       'GUMAOC CENTRAL',
//       'GUMAOC EAST',
//       'GUMAOC WEST',
//       'KAYBANBAN',
//       'KAYPIAN',
//       'LAWANG PARI',
//       'MAHARLIKA',
//       'MINUYAN',
//       'MINUYAN II',
//       'MINUYAN III',
//       'MINUYAN IV',
//       'MINUYAN PROPER',
//       'MINUYAN V',
//       'MUZON',
//       'PARADISE III',
//       'POBLACION',
//       'POBLACION I',
//       'SAN ISIDRO',
//       'SAN MANUEL',
//       'SAN MARTIN',
//       'SAN MARTIN II',
//       'SAN MARTIN III',
//       'SAN MARTIN IV',
//       'SAN PEDRO',
//       'SAN RAFAEL',
//       'SAN RAFAEL I',
//       'SAN RAFAEL III',
//       'SAN RAFAEL IV',
//       'SAN RAFAEL V',
//       'SAN ROQUE',
//       'SANTA CRUZ',
//       'SANTA CRUZ II',
//       'SANTA CRUZ III',
//       'SANTA CRUZ IV',
//       'SANTA CRUZ V',
//       'SANTO CRISTO',
//       'SANTO NIÑO',
//       'SANTO NIÑO II',
//       'SAPANG PALAY',
//       'ST. MARTIN DE PORRES',
//       'TUNGKONG MANGGA',
//     ],
//   },
//   'SAN RAFAEL': {
//     province: 'ILOILO',
//     municipality: 'SAN RAFAEL',
//     region: 'REGION VI',
//     barangay_list: [
//       'ARIPDIP',
//       'BAGACAY',
//       'CALAIGANG',
//       'ILONGBUKID',
//       'POBLACION',
//       'POSCOLON',
//       'SAN ANDRES',
//       'SAN DIONISIO',
//       'SAN FLORENTINO',
//     ],
//   },
//   ALIAGA: {
//     province: 'NUEVA ECIJA',
//     municipality: 'ALIAGA',
//     region: 'REGION III',
//     barangay_list: [
//       'BETES',
//       'BIBICLAT',
//       'BUCOT',
//       'LA PURISIMA',
//       'MACABUCOD',
//       'MAGSAYSAY',
//       'PANTOC',
//       'POBLACION CENTRO',
//       'POBLACION EAST I',
//       'POBLACION EAST II',
//       'POBLACION WEST III',
//       'POBLACION WEST IV',
//       'SAN CARLOS',
//       'SAN EMILIANO',
//       'SAN EUSTACIO',
//       'SAN FELIPE BATA',
//       'SAN FELIPE MATANDA',
//       'SAN JUAN',
//       'SAN PABLO BATA',
//       'SAN PABLO MATANDA',
//       'SANTA MONICA',
//       'SANTIAGO',
//       'SANTO ROSARIO',
//       'SANTO TOMAS',
//       'SUNSON',
//       'UMANGAN',
//     ],
//   },
//   BONGABON: {
//     province: 'NUEVA ECIJA',
//     municipality: 'BONGABON',
//     region: 'REGION III',
//     barangay_list: [
//       'ANTIPOLO',
//       'ARIENDO',
//       'BANTUG',
//       'CALAANAN',
//       'COMMERCIAL (POB.)',
//       'CRUZ',
//       'CURVA (J. TOMACRUZ)',
//       'DIGMALA',
//       'KAINGIN (POB.)',
//       'LABI',
//       'LARCON',
//       'LUSOK',
//       'MACABACLAY',
//       'MAGTANGGOL (POB.)',
//       'MANTILE (POB.)',
//       'OLIVETE',
//       'PALO MARIA (POB.)',
//       'PESA',
//       'RIZAL (POB.)',
//       'SAMPALUCAN (POB.)',
//       'SAN ROQUE (POB.)',
//       'SANTOR',
//       'SINIPIT (POB.)',
//       'SISILANG NA LIGAYA (POB.)',
//       'SOCIAL (POB.)',
//       'TUGATUG',
//       'TULAY NA BATO (POB.)',
//       'VEGA',
//     ],
//   },
//   'CABANATUAN': {
//     province: 'NUEVA ECIJA',
//     municipality: 'CABANATUAN',
//     region: 'REGION III',
//     barangay_list: [
//       'ADUAS CENTRO (ADUAS)',
//       'ADUAS NORTE',
//       'ADUAS SUR',
//       'BAGONG BUHAY',
//       'BAGONG SIKAT',
//       'BAKERO',
//       'BAKOD BAYAN',
//       'BALITE',
//       'BANGAD',
//       'BANTUG BULALO',
//       'BANTUG NORTE',
//       'BARLIS',
//       'BARRERA DISTRICT (POB.)',
//       'BERNARDO DISTRICT (POB.)',
//       'BITAS',
//       'BONIFACIO DISTRICT (POB.)',
//       'BULIRAN',
//       'CAALIBANGBANGAN',
//       'CABU',
//       'CALAWAGAN (KALAWAGAN)',
//       'CAMPO TINIO',
//       'CARIDAD',
//       'CAUDILLO',
//       'CINCO-CINCO',
//       'CITY SUPERMARKET (POB.)',
//       'COMMUNAL',
//       'CRUZ ROJA',
//       'DAANG SARILE',
//       'DALAMPANG',
//       'DICARMA (POB.)',
//       'DIMASALANG (POB.)',
//       'DIONISIO S. GARCIA',
//       'FATIMA (POB.)',
//       'GENERAL LUNA (POB.)',
//       'HERMOGENES C. CONCEPCION, SR.',
//       'IBABAO BANA',
//       'IMELDA DISTRICT',
//       'ISLA (POB.)',
//       'KALIKID NORTE',
//       'KALIKID SUR',
//       'KAPITAN PEPE (POB.)',
//       'LAGARE',
//       'LOURDES (MATUNGAL-TUNGAL)',
//       'M. S. GARCIA',
//       'MABINI EXTENSION',
//       'MABINI HOMESITE',
//       'MACATBONG',
//       'MAGSAYSAY DISTRICT',
//       'MAGSAYSAY SOUTH',
//       'MARIA THERESA',
//       'MATADERO (POB.)',
//       'MAYAPYAP NORTE',
//       'MAYAPYAP SUR',
//       'MELOJAVILLA (POB.)',
//       'NABAO (POB.)',
//       'OBRERO',
//       'PADRE BURGOS (POB.)',
//       'PADRE CRISOSTOMO',
//       'PAGAS',
//       'PALAGAY',
//       'PAMALDAN',
//       'PANGATIAN',
//       'PATALAC',
//       'POLILIO',
//       'PULA',
//       'QUEZON DISTRICT (POB.)',
//       'RIZDELIS (POB.)',
//       'SAMON',
//       'SAN ISIDRO',
//       'SAN JOSEF NORTE',
//       'SAN JOSEF SUR',
//       'SAN JUAN POB. (ACOFA)',
//       'SAN ROQUE NORTE',
//       'SAN ROQUE SUR',
//       'SANBERMICRISTI (POB.)',
//       'SANGITAN',
//       'SANGITAN EAST',
//       'SANTA ARCADIA',
//       'SANTO NIÑO',
//       'SAPANG',
//       'SUMACAB ESTE',
//       'SUMACAB NORTE',
//       'SUMACAB SOUTH',
//       'TALIPAPA',
//       'VALDEFUENTE',
//       'VALLE CRUZ',
//       'VIJANDRE DISTRICT (POB.)',
//       'VILLA OFELIA-CARIDAD',
//       'ZULUETA DISTRICT (POB.)',
//     ],
//   },
//   CABIAO: {
//     province: 'NUEVA ECIJA',
//     municipality: 'CABIAO',
//     region: 'REGION III',
//     barangay_list: [
//       'BAGONG BUHAY',
//       'BAGONG SIKAT',
//       'BAGONG SILANG',
//       'CONCEPCION',
//       'ENTABLADO',
//       'MALIGAYA',
//       'NATIVIDAD NORTH (POB.)',
//       'NATIVIDAD SOUTH (POB.)',
//       'PALASINAN',
//       'POLILIO',
//       'SAN ANTONIO',
//       'SAN CARLOS',
//       'SAN FERNANDO NORTE',
//       'SAN FERNANDO SUR',
//       'SAN GREGORIO',
//       'SAN JUAN NORTH (POB.)',
//       'SAN JUAN SOUTH (POB.)',
//       'SAN ROQUE',
//       'SAN VICENTE',
//       'SANTA INES',
//       'SANTA ISABEL',
//       'SANTA RITA',
//       'SINIPIT',
//     ],
//   },
//   CARRANGLAN: {
//     province: 'NUEVA ECIJA',
//     municipality: 'CARRANGLAN',
//     region: 'REGION III',
//     barangay_list: [
//       'BANTUG',
//       'BUNGA',
//       'BURGOS',
//       'CAPINTALAN',
//       'D. L. MAGLANOC POB. (BGY.III)',
//       'F. C. OTIC POB. (BGY. II)',
//       'G. S. ROSARIO POB. (BGY. IV)',
//       'GENERAL LUNA',
//       'JOSON (DIGIDIG)',
//       'MINULI',
//       'PIUT',
//       'PUNCAN',
//       'PUTLAN',
//       'R.A.PADILLA (BALUARTE)',
//       'SALAZAR',
//       'SAN AGUSTIN',
//       'T. L. PADILLA POB. (BGY. I)',
//     ],
//   },
//   CUYAPO: {
//     province: 'NUEVA ECIJA',
//     municipality: 'CUYAPO',
//     region: 'REGION III',
//     barangay_list: [
//       'BALOY',
//       'BAMBANABA',
//       'BANTUG',
//       'BENTIGAN',
//       'BIBICLAT',
//       'BONIFACIO',
//       'BUED',
//       'BULALA',
//       'BURGOS',
//       'CABATUAN',
//       'CABILEO',
//       'CACAPASAN',
//       'CALANCUASAN NORTE',
//       'CALANCUASAN SUR',
//       'COLOSBOA',
//       'COLUMBITIN',
//       'CURVA',
//       'DISTRICT I (POB. I)',
//       'DISTRICT II (POB. II)',
//       'DISTRICT IV (POB. IV)',
//       'DISTRICT V (POB. V)',
//       'DISTRICT VI (POB. VI)',
//       'DISTRICT VII (POB. VII)',
//       'DISTRICT VIII (POB. VIII)',
//       'LANDIG',
//       'LATAP',
//       'LOOB',
//       'LUNA',
//       'MALBEG-PATALAN',
//       'MALINENG',
//       'MATINDEG',
//       'MAYCABAN',
//       'NACURALAN',
//       'NAGMISAHAN',
//       'PAITAN NORTE',
//       'PAITAN SUR',
//       'PIGLISAN',
//       'PUGO',
//       'RIZAL',
//       'SABIT',
//       'SALAGUSOG',
//       'SAN ANTONIO',
//       'SAN JOSE',
//       'SAN JUAN',
//       'SANTA CLARA',
//       'SANTA CRUZ',
//       'SINIMBAAN',
//       'TAGTAGUMBAO',
//       'TUTULOY',
//       'UNGAB',
//       'VILLAFLORES',
//     ],
//   },
//   'GABALDON (BITULOK AND SABANI)': {
//     province: 'NUEVA ECIJA',
//     municipality: 'GABALDON (BITULOK AND SABANI)',
//     region: 'REGION III',
//     barangay_list: [
//       'BAGONG SIKAT',
//       'BAGTING',
//       'BANTUG',
//       'BITULOK (NORTH POB.)',
//       'BUGNAN',
//       'CALABASA',
//       'CAMACHILE',
//       'CUYAPA',
//       'LIGAYA',
//       'MACASANDAL',
//       'MALINAO',
//       'PANTOC',
//       'PINAMALISAN',
//       'SAWMILL',
//       'SOUTH POBLACION',
//       'TAGUMPAY',
//     ],
//   },
//   'GAPAN': {
//     province: 'NUEVA ECIJA',
//     municipality: 'GAPAN',
//     region: 'REGION III',
//     barangay_list: [
//       'BALANTE',
//       'BAYANIHAN',
//       'BULAK',
//       'BUNGO',
//       'KAPALANGAN',
//       'MABUNGA',
//       'MABURAK',
//       'MAHIPON',
//       'MAKABACLAY',
//       'MALIMBA',
//       'MANGINO',
//       'MARELO',
//       'PAMBUAN',
//       'PARCUTELA',
//       'PUTING TUBIG',
//       'SAN LORENZO (POB.)',
//       'SAN NICOLAS',
//       'SAN ROQUE',
//       'SAN VICENTE (POB.)',
//       'SANTA CRUZ',
//       'SANTO CRISTO NORTE',
//       'SANTO CRISTO SUR',
//       'SANTO NIÑO',
//     ],
//   },
//   'GENERAL MAMERTO NATIVIDAD': {
//     province: 'NUEVA ECIJA',
//     municipality: 'GENERAL MAMERTO NATIVIDAD',
//     region: 'REGION III',
//     barangay_list: [
//       'BALANGKARE NORTE',
//       'BALANGKARE SUR',
//       'BALARING',
//       'BELEN',
//       'BRAVO',
//       'BUROL',
//       'KABULIHAN',
//       'MAG-ASAWANG SAMPALOC',
//       'MANAROG',
//       'MATAAS NA KAHOY',
//       'PANACSAC',
//       'PICALEON',
//       'PINAHAN',
//       'PLATERO',
//       'POBLACION',
//       'PULA',
//       'PULONG SINGKAMAS',
//       'SAPANG BATO',
//       'TALABUTAB NORTE',
//       'TALABUTAB SUR',
//     ],
//   },
//   'GENERAL TINIO (PAPAYA)': {
//     province: 'NUEVA ECIJA',
//     municipality: 'GENERAL TINIO (PAPAYA)',
//     region: 'REGION III',
//     barangay_list: [
//       'BAGO',
//       'CONCEPCION',
//       'NAZARETH',
//       'PADOLINA',
//       'PALALE',
//       'PIAS',
//       'POBLACION CENTRAL',
//       'POBLACION EAST',
//       'POBLACION WEST',
//       'PULONG MATONG',
//       'RIO CHICO',
//       'SAMPAGUITA',
//       'SAN PEDRO (POB.)',
//     ],
//   },
//   GUIMBA: {
//     province: 'NUEVA ECIJA',
//     municipality: 'GUIMBA',
//     region: 'REGION III',
//     barangay_list: [
//       'AGCANO',
//       'AYOS LOMBOY',
//       'BACAYAO',
//       'BAGONG BARRIO',
//       'BALBALINO',
//       'BALINGOG EAST',
//       'BALINGOG WEST',
//       'BANITAN',
//       'BANTUG',
//       'BULAKID',
//       'BUNOL',
//       'CABALLERO',
//       'CABARUAN',
//       'CAINGIN TABING ILOG',
//       'CALEM',
//       'CAMILING',
//       'CARDINAL',
//       'CASONGSONG',
//       'CATIMON',
//       'CAVITE',
//       'CAWAYAN BUGTONG',
//       'CONSUELO',
//       'CULONG',
//       'ESCANO',
//       'FAIGAL',
//       'GALVAN',
//       'GUISET',
//       'LAMORITO',
//       'LENNEC',
//       'MACAMIAS',
//       'MACAPABELLAG',
//       'MACATCATUIT',
//       'MANACSAC',
//       'MANGGANG MARIKIT',
//       'MATURANOC',
//       'MAYBUBON',
//       'NAGLABRAHAN',
//       'NAGPANDAYAN',
//       'NARVACAN I',
//       'NARVACAN II',
//       'PACAC',
//       'PARTIDA I',
//       'PARTIDA II',
//       'PASONG INCHIC',
//       'SAINT JOHN DISTRICT (POB.)',
//       'SAN AGUSTIN',
//       'SAN ANDRES',
//       'SAN BERNARDINO',
//       'SAN MARCELINO',
//       'SAN MIGUEL',
//       'SAN RAFAEL',
//       'SAN ROQUE',
//       'SANTA ANA',
//       'SANTA CRUZ',
//       'SANTA LUCIA',
//       'SANTA VERONICA DISTRICT (POB.)',
//       'SANTO CRISTO DISTRICT (POB.)',
//       'SARANAY DISTRICT (POB.)',
//       'SINULATAN',
//       'SUBOL',
//       'TAMPAC I',
//       'TAMPAC II AND III',
//       'TRIALA',
//       'YUSON',
//     ],
//   },
//   JAEN: {
//     province: 'NUEVA ECIJA',
//     municipality: 'JAEN',
//     region: 'REGION III',
//     barangay_list: [
//       'CALABASA',
//       'DAMPULAN (POB.)',
//       'DON MARIANO MARCOS (POB.)',
//       'HILERA',
//       'IMBUNIA',
//       'IMELDA POB. (DOÑA AURORA)',
//       'LAMBAKIN',
//       'LANGLA',
//       'MAGSALISI',
//       'MALABON-KAINGIN',
//       'MARAWA',
//       'NIYUGAN',
//       'OCAMPO-RIVERA DISTRICT (POB.)',
//       'PAKOL',
//       'PAMACPACAN',
//       'PINANGGAAN',
//       'PUTLOD',
//       'SAN JOSE',
//       'SAN JOSEF (NABAO)',
//       'SAN PABLO',
//       'SAN ROQUE',
//       'SAN VICENTE',
//       'SANTA RITA',
//       'SANTO TOMAS NORTH',
//       'SANTO TOMAS SOUTH',
//       'SAPANG',
//       'ULANIN-PITAK',
//     ],
//   },
//   LAUR: {
//     province: 'NUEVA ECIJA',
//     municipality: 'LAUR',
//     region: 'REGION III',
//     barangay_list: [
//       'BARANGAY I (POB.)',
//       'BARANGAY II (POB.)',
//       'BARANGAY III (POB.)',
//       'BARANGAY IV (POB.)',
//       'BETANIA',
//       'CANANTONG',
//       'NAUZON',
//       'PANGARULONG',
//       'PINAGBAYANAN',
//       'SAGANA',
//       'SAN FELIPE',
//       'SAN FERNANDO',
//       'SAN ISIDRO',
//       'SAN JOSEF',
//       'SAN JUAN',
//       'SAN VICENTE',
//       'SICLONG',
//     ],
//   },
//   LICAB: {
//     province: 'NUEVA ECIJA',
//     municipality: 'LICAB',
//     region: 'REGION III',
//     barangay_list: [
//       'AQUINO',
//       'LINAO',
//       'POBLACION NORTE',
//       'POBLACION SUR',
//       'SAN CASIMIRO',
//       'SAN CRISTOBAL',
//       'SAN JOSE',
//       'SAN JUAN',
//       'SANTA MARIA',
//       'TABING ILOG',
//       'VILLAROSA',
//     ],
//   },
//   LLANERA: {
//     province: 'NUEVA ECIJA',
//     municipality: 'LLANERA',
//     region: 'REGION III',
//     barangay_list: [
//       'A. BONIFACIO',
//       'BAGUMBAYAN (POB.)',
//       'BOSQUE',
//       'CARIDAD NORTE',
//       'CARIDAD SUR',
//       'CASILE',
//       'FLORIDA BLANCA',
//       'GENERAL LUNA',
//       'GENERAL RICARTE',
//       'GOMEZ',
//       'INANAMA',
//       'LIGAYA',
//       'MABINI',
//       'MURCON',
//       'PLARIDEL',
//       'SAN FELIPE',
//       'SAN FRANCISCO',
//       'SAN NICOLAS',
//       'SAN VICENTE',
//       'SANTA BARBARA',
//       'VICTORIA',
//       'VILLA VINIEGAS',
//     ],
//   },
//   LUPAO: {
//     province: 'NUEVA ECIJA',
//     municipality: 'LUPAO',
//     region: 'REGION III',
//     barangay_list: [
//       'AGUPALO ESTE',
//       'AGUPALO WESTE',
//       'ALALAY CHICA',
//       'ALALAY GRANDE',
//       'BAGONG FLORES',
//       'BALBALUNGAO',
//       'BURGOS',
//       'CORDERO',
//       'J. U. TIENZO (ARIMAL)',
//       'MAPANGPANG',
//       'NAMULANDAYAN',
//       'PARISTA',
//       'POBLACION EAST',
//       'POBLACION NORTH',
//       'POBLACION SOUTH',
//       'POBLACION WEST',
//       'SALVACION I',
//       'SALVACION II',
//       'SAN ANTONIO ESTE',
//       'SAN ANTONIO WESTE',
//       'SAN ISIDRO',
//       'SAN PEDRO',
//       'SAN ROQUE',
//       'SANTO DOMINGO',
//     ],
//   },
//   NAMPICUAN: {
//     province: 'NUEVA ECIJA',
//     municipality: 'NAMPICUAN',
//     region: 'REGION III',
//     barangay_list: [
//       'ALEMANIA',
//       'AMBASADOR ALZATE VILLAGE',
//       'CABADUCAN EAST (POB.)',
//       'CABADUCAN WEST (POB.)',
//       'CABAWANGAN',
//       'EAST CENTRAL POBLACION',
//       'EDY',
//       'ESTACION (POB.)',
//       'MAELING',
//       'MAYANTOC',
//       'MEDICO',
//       'MONIC',
//       'NORTH POBLACION',
//       'NORTHWEST POBLACION',
//       'RECUERDO',
//       'SOUTH CENTRAL POBLACION',
//       'SOUTHEAST POBLACION',
//       'SOUTHWEST POBLACION',
//       'TONY',
//       'WEST CENTRAL POBLACION',
//       'WEST POBLACION',
//     ],
//   },
//   'PALAYAN': {
//     province: 'NUEVA ECIJA',
//     municipality: 'PALAYAN',
//     region: 'REGION III',
//     barangay_list: [
//       'ATATE',
//       'AULO',
//       'BAGONG BUHAY',
//       'BO. MILITAR (FORT MAGSAYSAY)',
//       'CABALLERO',
//       'CAIMITO',
//       'DOÑA JOSEFA',
//       'GANADERIA',
//       'IMELDA VALLEY',
//       'LANGKA',
//       'MALATE (POB.)',
//       'MALIGAYA',
//       'MANACNAC',
//       'MAPAIT',
//       'MARCOS VILLAGE',
//       'POPOLON PAGAS',
//       'SANTOLAN',
//       'SAPANG BUHO',
//       'SINGALAT',
//     ],
//   },
//   PANTABANGAN: {
//     province: 'NUEVA ECIJA',
//     municipality: 'PANTABANGAN',
//     region: 'REGION III',
//     barangay_list: [
//       'CADACLAN',
//       'CAMBITALA',
//       'CONVERSION',
//       'FATIMA',
//       'GANDUZ',
//       'LIBERTY',
//       'MALBANG',
//       'MARIKIT',
//       'NAPON-NAPON',
//       'POBLACION EAST',
//       'POBLACION WEST',
//       'SAMPALOC',
//       'SAN JUAN',
//       'VILLARICA',
//     ],
//   },
//   PEÑARANDA: {
//     province: 'NUEVA ECIJA',
//     municipality: 'PEÑARANDA',
//     region: 'REGION III',
//     barangay_list: [
//       'CALLOS',
//       'LAS PIÑAS',
//       'POBLACION I',
//       'POBLACION II',
//       'POBLACION III',
//       'POBLACION IV',
//       'SAN JOSEF',
//       'SAN MARIANO (MAUGAT)',
//       'SANTO TOMAS',
//       'SINASAJAN',
//     ],
//   },
//   'SAN ANTONIO': {
//     province: 'NORTHERN SAMAR',
//     municipality: 'SAN ANTONIO',
//     region: 'REGION VIII',
//     barangay_list: [
//       'BURABOD',
//       'DALUPIRIT',
//       'MANRAYA',
//       'PILAR',
//       'RIZAL',
//       'SAN NICOLAS',
//       'VINISITAHAN',
//       'WARD I (POB.)',
//       'WARD II (POB.)',
//       'WARD III (POB.)',
//     ],
//   },
//   'SAN JOSE': {
//     province: 'NUEVA ECIJA',
//     municipality: 'SAN JOSE',
//     region: 'REGION III',
//     barangay_list: [
//       'A. PASCUAL',
//       'ABAR 2ND',
//       'ABAR IST',
//       'BAGONG SIKAT',
//       'CAANAWAN',
//       'CALAOCAN',
//       'CAMANACSACAN',
//       'CANUTO RAMOS POB. (DISTRICT III)',
//       'CRISANTO SANCHEZ POB. (DISTRICT V)',
//       'CULAYLAY',
//       'DIZOL',
//       'FERDINAND E. MARCOS POB. (DISTRICT II)',
//       'KALIWANAGAN',
//       'KITA-KITA',
//       'MALASIN',
//       'MANICLA',
//       'PALESTINA',
//       'PARANG MANGGA',
//       'PINILI',
//       'PORAIS',
//       'RAFAEL RUEDA, SR. POB. (DISTRICT I)',
//       'RAYMUNDO EUGENIO POB. (DISTRICT IV)',
//       'SAN AGUSTIN',
//       'SAN JUAN',
//       'SAN MAURICIO',
//       'SANTO NIÑO 1ST',
//       'SANTO NIÑO 2ND',
//       'SANTO NIÑO 3RD',
//       'SANTO TOMAS',
//       'SIBUT',
//       'SINIPIT BUBON',
//       'TABULAC',
//       'TAYABO',
//       'TONDOD',
//       'TULAT',
//       'VILLA FLORESCA',
//       'VILLA JOSON (PARILLA)',
//       'VILLA MARINA',
//     ],
//   },
//   'SAN LEONARDO': {
//     province: 'NUEVA ECIJA',
//     municipality: 'SAN LEONARDO',
//     region: 'REGION III',
//     barangay_list: [
//       'BONIFACIO DISTRICT (POB.)',
//       'BURGOS DISTRICT (POB.)',
//       'CASTELLANO',
//       'DIVERSION',
//       'MAGPAPALAYOC',
//       'MALLORCA',
//       'MAMBANGNAN',
//       'NIEVES',
//       'RIZAL DISTRICT (POB.)',
//       'SAN ANTON',
//       'SAN BARTOLOME (POB.)',
//       'SAN ROQUE',
//       'TABUATING',
//       'TAGUMPAY',
//       'TAMBO ADORABLE',
//     ],
//   },
//   'SANTA ROSA': {
//     province: 'NUEVA ECIJA',
//     municipality: 'SANTA ROSA',
//     region: 'REGION III',
//     barangay_list: [
//       'AGUINALDO',
//       'BERANG',
//       'BURGOS',
//       'COJUANGCO (POB.)',
//       'DEL PILAR',
//       'GOMEZ',
//       'INSPECTOR',
//       'ISLA',
//       'LA FUENTE',
//       'LIWAYWAY',
//       'LOURDES',
//       'LUNA',
//       'MABINI',
//       'MALACAÑANG',
//       'MALIOLIO',
//       'MAPALAD',
//       'RAJAL CENTRO',
//       'RAJAL NORTE',
//       'RAJAL SUR',
//       'RIZAL (POB.)',
//       'SAN GREGORIO',
//       'SAN ISIDRO',
//       'SAN JOSEP',
//       'SAN MARIANO',
//       'SAN PEDRO',
//       'SANTA TERESITA',
//       'SANTO ROSARIO',
//       'SAPSAP',
//       'SOLEDAD',
//       'TAGPOS',
//       'TRAMO',
//       'VALENZUELA (POB.)',
//       'ZAMORA (POB.)',
//     ],
//   },
//   'SCIENCE MUÑOZ': {
//     province: 'NUEVA ECIJA',
//     municipality: 'SCIENCE MUÑOZ',
//     region: 'REGION III',
//     barangay_list: [
//       'BAGONG SIKAT',
//       'BALANTE',
//       'BANTUG',
//       'BICAL',
//       'CABISUCULAN',
//       'CALABALABAAN',
//       'CALISITAN',
//       'CATALANACAN',
//       'CURVA',
//       'FRANZA',
//       'GABALDON',
//       'LABNEY',
//       'LICAONG',
//       'LINGLINGAY',
//       'MAGTANGGOL',
//       'MALIGAYA',
//       'MANGANDINGAY',
//       'MAPANGPANG',
//       'MARAGOL',
//       'MATINGKIS',
//       'NAGLABRAHAN',
//       'PALUSAPIS',
//       'PANDALLA',
//       'POBLACION EAST',
//       'POBLACION NORTH',
//       'POBLACION SOUTH',
//       'POBLACION WEST',
//       'RANG-AYAN',
//       'RIZAL',
//       'SAN ANDRES',
//       'SAN ANTONIO',
//       'SAN FELIPE',
//       'SAPANG CAWAYAN',
//       'VILLA CUIZON',
//       'VILLA ISLA',
//       'VILLA NATI',
//       'VILLA SANTOS',
//     ],
//   },
//   TALAVERA: {
//     province: 'NUEVA ECIJA',
//     municipality: 'TALAVERA',
//     region: 'REGION III',
//     barangay_list: [
//       'ANDAL ALINO (POB.)',
//       'BAGONG SIKAT',
//       'BAGONG SILANG',
//       'BAKAL I',
//       'BAKAL II',
//       'BAKAL III',
//       'BALUGA',
//       'BANTUG',
//       'BANTUG HACIENDA',
//       'BANTUG HAMOG (BASANG HAMOG)',
//       'BUGTONG NA BULI',
//       'BULAC',
//       'BURNAY',
//       'CAANIPLAHAN',
//       'CABUBULAONAN',
//       'CALIPAHAN',
//       'CAMPOS',
//       'CAPUTICAN',
//       'CASULUCAN ESTE',
//       'COLLADO',
//       'DIMASALANG NORTE',
//       'DIMASALANG SUR',
//       'DINARAYAT',
//       'ESGUERRA DISTRICT (POB.)',
//       'GULOD',
//       'HOMESTEAD I',
//       'HOMESTEAD II',
//       'KINALANGUYAN',
//       'LA TORRE',
//       'LOMBOY',
//       'MABUHAY',
//       'MAESTRANG KIKAY (POB.)',
//       'MAMANDIL',
//       'MARCOS DISTRICT (POB.)',
//       'MATINGKIS',
//       'MINABUYOC',
//       'PAG-ASA (POB.)',
//       'PALUDPOD',
//       'PANTOC BULAC',
//       'PINAGPANAAN',
//       'POBLACION SUR',
//       'PULA',
//       'PULONG SAN MIGUEL (POB.)',
//       'PUROK MATIAS (POB.)',
//       'SAMPALOC',
//       'SAN MIGUEL NA MUNTI',
//       'SAN PASCUAL',
//       'SAN RICARDO',
//       'SIBUL',
//       'SICSICAN MATANDA',
//       'TABACAO',
//       'TAGAYTAY',
//       'VALLE',
//     ],
//   },
//   TALUGTUG: {
//     province: 'NUEVA ECIJA',
//     municipality: 'TALUGTUG',
//     region: 'REGION III',
//     barangay_list: [
//       'ALULA',
//       'BAYBAYABAS',
//       'BUTED',
//       'CABIANGAN',
//       'CALISITAN',
//       'CINENSE',
//       'CULIAT',
//       'MAASIN',
//       'MAGSAYSAY (POB.)',
//       'MAYAMOT I',
//       'MAYAMOT II',
//       'NANGABULAN',
//       'OSMEÑA (POB.)',
//       'PANGIT',
//       'PATOLA',
//       'QUEZON (POB.)',
//       'QUIRINO (POB.)',
//       'ROXAS (POB.)',
//       'SAGUING',
//       'SAMPALOC',
//       'SANTA CATALINA',
//       'SANTO DOMINGO',
//       'SARINGAYA',
//       'SAVERONA',
//       'TANDOC',
//       'TIBAG',
//       'VILLA BOADO',
//       'VILLA ROSARIO',
//     ],
//   },
//   ZARAGOZA: {
//     province: 'NUEVA ECIJA',
//     municipality: 'ZARAGOZA',
//     region: 'REGION III',
//     barangay_list: [
//       'BATITANG',
//       'CARMEN',
//       'CONCEPCION',
//       'DEL PILAR',
//       'GENERAL LUNA',
//       'H. ROMERO',
//       'MACARSE',
//       'MANAUL',
//       'MAYAMOT',
//       'PANTOC',
//       'SAN ISIDRO',
//       'SAN RAFAEL',
//       'SAN VICENTE (POB.)',
//       'SANTA CRUZ',
//       'SANTA LUCIA OLD',
//       'SANTA LUCIA YOUNG',
//       'SANTO ROSARIO OLD',
//       'SANTO ROSARIO YOUNG',
//       'VALERIANA',
//     ],
//   },
//   'ANGELES': {
//     province: 'PAMPANGA',
//     municipality: 'ANGELES',
//     region: 'REGION III',
//     barangay_list: [
//       'AGAPITO DEL ROSARIO',
//       'AMSIC',
//       'ANUNAS',
//       'BALIBAGO',
//       'CAPAYA',
//       'CLARO M. RECTO',
//       'CUAYAN',
//       'CUTCUT',
//       'CUTUD',
//       'LOURDES NORTH WEST',
//       'LOURDES SUR',
//       'LOURDES SUR EAST',
//       'MALABANIAS',
//       'MARGOT',
//       'MINING',
//       'NINOY AQUINO (MARISOL)',
//       'PAMPANG',
//       'PANDAN',
//       'PULUNG CACUTUD',
//       'PULUNG MARAGUL',
//       'PULUNGBULU',
//       'SALAPUNGAN',
//       'SAN JOSE',
//       'SAN NICOLAS',
//       'SANTA TERESITA',
//       'SANTA TRINIDAD',
//       'SANTO CRISTO',
//       'SANTO DOMINGO',
//       'SANTO ROSARIO (POB.)',
//       'SAPALIBUTAD',
//       'SAPANGBATO',
//       'TABUN',
//       'VIRGEN DELOS REMEDIOS',
//     ],
//   },
//   APALIT: {
//     province: 'PAMPANGA',
//     municipality: 'APALIT',
//     region: 'REGION III',
//     barangay_list: [
//       'BALUCUC',
//       'CALANTIPE',
//       'CANSINALA',
//       'CAPALANGAN',
//       'COLGANTE',
//       'PALIGUI',
//       'SAMPALOC',
//       'SAN JUAN (POB.)',
//       'SAN VICENTE',
//       'SUCAD',
//       'SULIPAN',
//       'TABUYUC (SANTO ROSARIO)',
//     ],
//   },
//   ARAYAT: {
//     province: 'PAMPANGA',
//     municipality: 'ARAYAT',
//     region: 'REGION III',
//     barangay_list: [
//       'ARENAS',
//       'BALITI',
//       'BATASAN',
//       'BUENSUCESO',
//       'CANDATING',
//       'CUPANG (SANTA LUCIA)',
//       'GATIAWIN',
//       'GUEMASAN',
//       'KALEDIAN (CAMBA)',
//       'LA PAZ (TURU)',
//       'LACMIT',
//       'LACQUIOS',
//       'MANGGA-CACUTUD',
//       'MAPALAD',
//       'MATAMO (SANTA LUCIA)',
//       'PANLINLANG',
//       'PARALAYA',
//       'PLAZANG LUMA',
//       'POBLACION',
//       'SAN AGUSTIN NORTE',
//       'SAN AGUSTIN SUR',
//       'SAN ANTONIO',
//       'SAN JOSE MESULO',
//       'SAN JUAN BANO',
//       'SAN MATEO',
//       'SAN NICOLAS',
//       'SAN ROQUE BITAS',
//       'SANTO NIÑO TABUAN',
//       'SUCLAYIN',
//       'TELAPAYONG',
//     ],
//   },
//   BACOLOR: {
//     province: 'PAMPANGA',
//     municipality: 'BACOLOR',
//     region: 'REGION III',
//     barangay_list: [
//       'BALAS',
//       'CABALANTIAN',
//       'CABAMBANGAN (POB.)',
//       'CABETICAN',
//       'CALIBUTBUT',
//       'CONCEPCION',
//       'DOLORES',
//       'DUAT',
//       'MACABACLE',
//       'MAGLIMAN',
//       'MALIWALU',
//       'MESALIPIT',
//       'PARULOG',
//       'POTRERO',
//       'SAN ANTONIO',
//       'SAN ISIDRO',
//       'SAN VICENTE',
//       'SANTA BARBARA',
//       'SANTA INES',
//       'TALBA',
//       'TINAJERO',
//     ],
//   },
//   CANDABA: {
//     province: 'PAMPANGA',
//     municipality: 'CANDABA',
//     region: 'REGION III',
//     barangay_list: [
//       'BAHAY PARE',
//       'BAMBANG',
//       'BARANGCA',
//       'BARIT',
//       'BUAS (POB.)',
//       'CUAYANG BUGTONG',
//       'DALAYAP',
//       'DULONG ILOG',
//       'GULAP',
//       'LANANG',
//       'LOURDES',
//       'MAGUMBALI',
//       'MANDASIG',
//       'MANDILI',
//       'MANGGA',
//       'MAPANIQUI',
//       'PALIGUI',
//       'PANGCLARA',
//       'PANSINAO',
//       'PARALAYA (POB.)',
//       'PASIG',
//       'PESCADORES (POB.)',
//       'PULONG GUBAT',
//       'PULONG PALAZAN',
//       'SALAPUNGAN',
//       'SAN AGUSTIN (POB.)',
//       'SANTO ROSARIO',
//       'TAGULOD',
//       'TALANG',
//       'TENEJERO',
//       'VIZAL SAN PABLO',
//       'VIZAL SANTO CRISTO',
//       'VIZAL SANTO NIÑO',
//     ],
//   },
//   FLORIDABLANCA: {
//     province: 'PAMPANGA',
//     municipality: 'FLORIDABLANCA',
//     region: 'REGION III',
//     barangay_list: [
//       'ANON',
//       'APALIT',
//       'BASA AIR BASE',
//       'BENEDICTO',
//       'BODEGA',
//       'CABANGCALAN',
//       'CALANTAS',
//       'CARMENCITA',
//       'CONSUELO',
//       'DAMPE',
//       'DEL CARMEN',
//       'FORTUNA',
//       'GUTAD',
//       'MABICAL',
//       'MALIGAYA',
//       'MAWACAT',
//       'NABUCLOD',
//       'PABANLAG',
//       'PAGUIRUAN',
//       'PALMAYO',
//       'PANDAGUIRIG',
//       'POBLACION',
//       'SAN ANTONIO',
//       'SAN ISIDRO',
//       'SAN JOSE',
//       'SAN NICOLAS',
//       'SAN PEDRO',
//       'SAN RAMON',
//       'SAN ROQUE',
//       'SANTA MONICA',
//       'SANTO ROSARIO (MALABO)',
//       'SOLIB',
//       'VALDEZ',
//     ],
//   },
//   GUAGUA: {
//     province: 'PAMPANGA',
//     municipality: 'GUAGUA',
//     region: 'REGION III',
//     barangay_list: [
//       'ASCOMO',
//       'BANCAL',
//       'JOSE ABAD SANTOS (SIRAN)',
//       'LAMBAC',
//       'MAGSAYSAY',
//       'MAQUIAPO',
//       'NATIVIDAD',
//       'PLAZA BURGOS (POB.)',
//       'PULUNGMASLE',
//       'RIZAL',
//       'SAN AGUSTIN',
//       'SAN ANTONIO',
//       'SAN ISIDRO',
//       'SAN JOSE',
//       'SAN JUAN',
//       'SAN JUAN BAUTISTA',
//       'SAN JUAN NEPOMUCENO',
//       'SAN MATIAS',
//       'SAN MIGUEL (BETIS)',
//       'SAN NICOLAS 1ST',
//       'SAN NICOLAS 2ND',
//       'SAN PABLO',
//       'SAN PEDRO',
//       'SAN RAFAEL (DUCK ISLAND)',
//       'SAN ROQUE',
//       'SAN VICENTE (IBUS)',
//       'SANTA FILOMENA (POB.)',
//       'SANTA INES',
//       'SANTA URSULA',
//       'SANTO CRISTO',
//       'SANTO NIÑO (POB.)',
//     ],
//   },
//   LUBAO: {
//     province: 'PAMPANGA',
//     municipality: 'LUBAO',
//     region: 'REGION III',
//     barangay_list: [
//       'BALANTACAN',
//       'BANCAL PUGAD',
//       'BANCAL SINUBLI',
//       'BARUYA (SAN RAFAEL)',
//       'CALANGAIN',
//       'CONCEPCION',
//       'DE LA PAZ',
//       'DEL CARMEN',
//       'DON IGNACIO DIMSON',
//       'LOURDES (LAUC PAU)',
//       'PRADO SIONGCO',
//       'REMEDIOS',
//       'SAN AGUSTIN',
//       'SAN ANTONIO',
//       'SAN FRANCISCO',
//       'SAN ISIDRO',
//       'SAN JOSE APUNAN',
//       'SAN JOSE GUMI',
//       'SAN JUAN (POB.)',
//       'SAN MATIAS',
//       'SAN MIGUEL',
//       'SAN NICOLAS 1ST (POB.)',
//       'SAN NICOLAS 2ND',
//       'SAN PABLO 1ST',
//       'SAN PABLO 2ND',
//       'SAN PEDRO PALCARANGAN',
//       'SAN PEDRO SAUG',
//       'SAN ROQUE ARBOL',
//       'SAN ROQUE DAU',
//       'SAN VICENTE',
//       'SANTA BARBARA',
//       'SANTA CATALINA',
//       'SANTA CRUZ',
//       'SANTA LUCIA (POB.)',
//       'SANTA MARIA',
//       'SANTA MONICA',
//       'SANTA RITA',
//       'SANTA TERESA 1ST',
//       'SANTA TERESA 2ND',
//       'SANTIAGO',
//       'SANTO CRISTO',
//       'SANTO DOMINGO',
//       'SANTO NIÑO (PRADO ARUBA)',
//       'SANTO TOMAS (POB.)',
//     ],
//   },
//   'MABALACAT': {
//     province: 'PAMPANGA',
//     municipality: 'MABALACAT',
//     region: 'REGION III',
//     barangay_list: [
//       'ATLU-BOLA',
//       'BICAL',
//       'BUNDAGUL',
//       'CACUTUD',
//       'CALUMPANG',
//       'CAMACHILES',
//       'DAPDAP',
//       'DAU',
//       'DOLORES',
//       'DUQUIT',
//       'LAKANDULA',
//       'MABIGA',
//       'MACAPAGAL VILLAGE',
//       'MAMATITANG',
//       'MANGALIT',
//       'MARCOS VILLAGE',
//       'MAWAQUE',
//       'PARALAYUNAN',
//       'POBLACION',
//       'SAN FRANCISCO',
//       'SAN JOAQUIN',
//       'SANTA INES',
//       'SANTA MARIA',
//       'SANTO ROSARIO',
//       'SAPANG BALEN',
//       'SAPANG BIABAS',
//       'TABUN',
//     ],
//   },
//   MACABEBE: {
//     province: 'PAMPANGA',
//     municipality: 'MACABEBE',
//     region: 'REGION III',
//     barangay_list: [
//       'BATASAN',
//       'CADUANG TETE',
//       'CANDELARIA',
//       'CASTULI',
//       'CONSUELO',
//       'DALAYAP',
//       'MATAGUITI',
//       'SAN ESTEBAN',
//       'SAN FRANCISCO',
//       'SAN GABRIEL (POB.)',
//       'SAN ISIDRO',
//       'SAN JOSE',
//       'SAN JUAN',
//       'SAN RAFAEL',
//       'SAN ROQUE',
//       'SAN VICENTE',
//       'SANTA CRUZ (POB.)',
//       'SANTA LUTGARDA',
//       'SANTA MARIA',
//       'SANTA RITA (POB.)',
//       'SANTO NIÑO',
//       'SANTO ROSARIO (POB.)',
//       'SAPLAD DAVID',
//       'TACASAN',
//       'TELACSAN',
//     ],
//   },
//   MAGALANG: {
//     province: 'PAMPANGA',
//     municipality: 'MAGALANG',
//     region: 'REGION III',
//     barangay_list: [
//       'AYALA',
//       'BUCANAN',
//       'CAMIAS',
//       'DOLORES',
//       'ESCALER',
//       'LA PAZ',
//       'NAVALING',
//       'SAN AGUSTIN',
//       'SAN ANTONIO',
//       'SAN FRANCISO',
//       'SAN ILDEFONSO',
//       'SAN ISIDRO',
//       'SAN JOSE',
//       'SAN MIGUEL',
//       'SAN NICOLAS 1ST (POB.)',
//       'SAN NICOLAS 2ND',
//       'SAN PABLO (POB.)',
//       'SAN PEDRO I',
//       'SAN PEDRO II',
//       'SAN ROQUE',
//       'SAN VICENTE',
//       'SANTA CRUZ (POB.)',
//       'SANTA LUCIA',
//       'SANTA MARIA',
//       'SANTO NIÑO',
//       'SANTO ROSARIO',
//       'TURU',
//     ],
//   },
//   MASANTOL: {
//     province: 'PAMPANGA',
//     municipality: 'MASANTOL',
//     region: 'REGION III',
//     barangay_list: [
//       'ALAULI',
//       'BAGANG',
//       'BALIBAGO',
//       'BEBE ANAC',
//       'BEBE MATUA',
//       'BULACUS',
//       'CAMBASI',
//       'MALAULI',
//       'NIGUI',
//       'PALIMPE',
//       'PUTI',
//       'SAGRADA (TIBAGIN)',
//       'SAN AGUSTIN (CAINGIN)',
//       'SAN ISIDRO ANAC',
//       'SAN ISIDRO MATUA (POB.)',
//       'SAN NICOLAS (POB.)',
//       'SAN PEDRO',
//       'SANTA CRUZ',
//       'SANTA LUCIA ANAC (POB.)',
//       'SANTA LUCIA MATUA',
//       'SANTA LUCIA PAGUIBA',
//       'SANTA LUCIA WAKAS',
//       'SANTA MONICA (CAINGIN)',
//       'SANTO NIÑO',
//       'SAPANG KAWAYAN',
//       'SUA',
//     ],
//   },
//   MEXICO: {
//     province: 'PAMPANGA',
//     municipality: 'MEXICO',
//     region: 'REGION III',
//     barangay_list: [
//       'ACLI',
//       'ANAO',
//       'BALAS',
//       'BUENAVISTA',
//       'CAMUNING',
//       'CAWAYAN',
//       'CONCEPCION',
//       'CULUBASA',
//       'DIVISORIA',
//       'DOLORES (PIRING)',
//       'EDEN',
//       'GANDUS',
//       'LAGUNDI',
//       'LAPUT',
//       'LAUG',
//       'MASAMAT',
//       'MASANGSANG',
//       'NUEVA VICTORIA',
//       'PANDACAQUI',
//       'PANGATLAN',
//       'PANIPUAN',
//       'PARIAN (POB.)',
//       'SABANILLA',
//       'SAN ANTONIO',
//       'SAN CARLOS',
//       'SAN JOSE MALINO',
//       'SAN JOSE MATULID',
//       'SAN JUAN',
//       'SAN LORENZO',
//       'SAN MIGUEL',
//       'SAN NICOLAS',
//       'SAN PABLO',
//       'SAN PATRICIO',
//       'SAN RAFAEL',
//       'SAN ROQUE',
//       'SAN VICENTE',
//       'SANTA CRUZ',
//       'SANTA MARIA',
//       'SANTO DOMINGO',
//       'SANTO ROSARIO',
//       'SAPANG MAISAC',
//       'SUCLABAN',
//       'TANGLE (TANGLAY)',
//     ],
//   },
//   MINALIN: {
//     province: 'PAMPANGA',
//     municipality: 'MINALIN',
//     region: 'REGION III',
//     barangay_list: [
//       'BULAC',
//       'DAWE',
//       'LOURDES',
//       'MANIANGO',
//       'SAN FRANCISCO 1ST',
//       'SAN FRANCISCO 2ND',
//       'SAN ISIDRO',
//       'SAN NICOLAS (POB.)',
//       'SAN PEDRO',
//       'SANTA CATALINA',
//       'SANTA MARIA',
//       'SANTA RITA',
//       'SANTO DOMINGO',
//       'SANTO ROSARIO (POB.)',
//       'SAPLAD',
//     ],
//   },
//   PORAC: {
//     province: 'PAMPANGA',
//     municipality: 'PORAC',
//     region: 'REGION III',
//     barangay_list: [
//       'BABO PANGULO',
//       'BABO SACAN',
//       'BALUBAD',
//       'CALZADANG BAYU',
//       'CAMIAS',
//       'CANGATBA',
//       'DIAZ',
//       'DOLORES (HACIENDA DOLORES)',
//       'INARARO (AETAS)',
//       'JALUNG',
//       'MANCATIAN',
//       'MANIBAUG LIBUTAD',
//       'MANIBAUG PARALAYA',
//       'MANIBAUG PASIG',
//       'MANUALI',
//       'MITLA PROPER',
//       'PALAT',
//       'PIAS',
//       'PIO',
//       'PLANAS',
//       'POBLACION',
//       'PULONG SANTOL',
//       'SALU',
//       'SAN JOSE MITLA',
//       'SANTA CRUZ',
//       'SAPANG UWAK (AETAS)',
//       'SEPUNG BULAUN',
//       'SINURA',
//       'VILLA MARIA (AETAS)',
//     ],
//   },
//   'SAN FERNANDO': {
//     province: 'PAMPANGA',
//     municipality: 'SAN FERNANDO',
//     region: 'REGION III',
//     barangay_list: [
//       'ALASAS',
//       'BALITI',
//       'BULAON',
//       'CALULUT',
//       'DEL CARMEN',
//       'DEL PILAR',
//       'DEL ROSARIO',
//       'DELA PAZ NORTE',
//       'DELA PAZ SUR',
//       'DOLORES',
//       'JULIANA',
//       'LARA',
//       'LOURDES',
//       'MAGLIMAN',
//       'MAIMPIS',
//       'MALINO',
//       'MALPITIC',
//       'PANDARAS',
//       'PANIPUAN',
//       'PULUNG BULU',
//       'QUEBIAUAN',
//       'SAGUIN',
//       'SAN AGUSTIN',
//       'SAN FELIPE',
//       'SAN ISIDRO',
//       'SAN JOSE',
//       'SAN JUAN',
//       'SAN NICOLAS',
//       'SAN PEDRO',
//       'SANTA LUCIA',
//       'SANTA TERESITA',
//       'SANTO NIÑO',
//       'SANTO ROSARIO (POB.)',
//       'SINDALAN',
//       'TELABASTAGAN',
//     ],
//   },
//   'SAN SIMON': {
//     province: 'PAMPANGA',
//     municipality: 'SAN SIMON',
//     region: 'REGION III',
//     barangay_list: [
//       'CONCEPCION',
//       'DE LA PAZ',
//       'SAN AGUSTIN',
//       'SAN ISIDRO',
//       'SAN JOSE',
//       'SAN JUAN (POB.)',
//       'SAN MIGUEL',
//       'SAN NICOLAS',
//       'SAN PABLO LIBUTAD',
//       'SAN PABLO PROPER',
//       'SAN PEDRO',
//       'SANTA CRUZ',
//       'SANTA MONICA',
//       'SANTO NIÑO',
//     ],
//   },
//   'SANTA RITA': {
//     province: 'SAMAR (WESTERN SAMAR)',
//     municipality: 'SANTA RITA',
//     region: 'REGION VIII',
//     barangay_list: [
//       'ALEGRIA',
//       'ANIBONGAN',
//       'ASLUM',
//       'BAGOLIBAS',
//       'BINANALAN',
//       'BOKINGGAN POB. (ZONE I)',
//       'BOUGAINVILLA POB. (ZONE II)',
//       'CABACUNGAN',
//       'CABUNGA-AN',
//       'CAMAYSE',
//       'CANSADONG',
//       'CATICUGAN',
//       'DAMPIGAN',
//       'GUINBALOT-AN',
//       'GUMAMELA POB. (ZONE III)',
//       'HINANGUDTAN',
//       'IGANG-IGANG',
//       'LA PAZ',
//       'LUPIG',
//       'MAGSAYSAY',
//       'MALIGAYA',
//       'NEW MANUNCA',
//       'OLD MANUNCA',
//       'PAGSULHOGON',
//       'ROSAL POB. (ZONE IV)',
//       'SALVACION',
//       'SAN EDUARDO',
//       'SAN ISIDRO',
//       'SAN JUAN',
//       'SAN PASCUAL (CROSSING)',
//       'SAN PEDRO',
//       'SAN ROQUE',
//       'SANTA ELENA',
//       'SANTAN POB. (ZONE V)',
//       'TAGACAY',
//       'TOMINAMOS',
//       'TULAY',
//       'UNION',
//     ],
//   },
//   'SASMUAN (SEXMOAN)': {
//     province: 'PAMPANGA',
//     municipality: 'SASMUAN (SEXMOAN)',
//     region: 'REGION III',
//     barangay_list: [
//       'BATANG 1ST',
//       'BATANG 2ND',
//       'MABUANBUAN',
//       'MALUSAC',
//       'SABITANAN',
//       'SAN ANTONIO',
//       'SAN NICOLAS 1ST',
//       'SAN NICOLAS 2ND',
//       'SAN PEDRO',
//       'SANTA LUCIA (POB.)',
//       'SANTA MONICA',
//       'SANTO TOMAS',
//     ],
//   },
//   ANAO: {
//     province: 'TARLAC',
//     municipality: 'ANAO',
//     region: 'REGION III',
//     barangay_list: [
//       'BAGUINDOC (BAGUINLOC)',
//       'BANTOG',
//       'CAMPOS',
//       'CARMEN',
//       'CASILI',
//       'DON RAMON',
//       'HERNANDO',
//       'POBLACION',
//       'RIZAL',
//       'SAN FRANCISCO EAST',
//       'SAN FRANCISCO WEST',
//       'SAN JOSE NORTH',
//       'SAN JOSE SOUTH',
//       'SAN JUAN',
//       'SAN ROQUE',
//       'SANTO DOMINGO',
//       'SINENSE',
//       'SUAVERDEZ',
//     ],
//   },
//   BAMBAN: {
//     province: 'TARLAC',
//     municipality: 'BAMBAN',
//     region: 'REGION III',
//     barangay_list: [
//       'ANUPUL',
//       'BANABA',
//       'BANGCU',
//       'CULUBASA',
//       'DELA CRUZ',
//       'LA PAZ',
//       'LOURDES',
//       'MALONZO',
//       'SAN NICOLAS (POB.)',
//       'SAN PEDRO',
//       'SAN RAFAEL',
//       'SAN ROQUE',
//       'SAN VICENTE',
//       'SANTO NIÑO',
//       'VIRGEN DE LOS REMEDIOS (PACALCAL)',
//     ],
//   },
//   CAMILING: {
//     province: 'TARLAC',
//     municipality: 'CAMILING',
//     region: 'REGION III',
//     barangay_list: [
//       'ANOLING 1ST',
//       'ANOLING 2ND',
//       'ANOLING 3RD',
//       'BACABAC',
//       'BACSAY',
//       'BANCAY 1ST',
//       'BANCAY 2ND',
//       'BILAD',
//       'BIRBIRA',
//       'BOBON 1ST',
//       'BOBON 2ND',
//       'BOBON CAAROSIPAN',
//       'CABANABAAN',
//       'CACAMILINGAN NORTE',
//       'CACAMILINGAN SUR',
//       'CANIAG',
//       'CARAEL',
//       'CAYAOAN',
//       'CAYASAN',
//       'FLORIDA',
//       'LASONG',
//       'LIBUEG',
//       'MALACAMPA',
//       'MANAKEM',
//       'MANUPEG',
//       'MARAWI',
//       'MATUBOG',
//       'NAGRAMBACAN',
//       'NAGSERIALAN',
//       'PALIMBO PROPER',
//       'PALIMBO-CAAROSIPAN',
//       'PAO 1ST',
//       'PAO 2ND',
//       'PAO 3RD',
//       'PAPAAC',
//       'PINDANGAN 1ST',
//       'PINDANGAN 2ND',
//       'POBLACION A',
//       'POBLACION B',
//       'POBLACION C',
//       'POBLACION D',
//       'POBLACION E',
//       'POBLACION F',
//       'POBLACION G',
//       'POBLACION H',
//       'POBLACION I',
//       'POBLACION J',
//       'SANTA MARIA',
//       'SAWAT',
//       'SINILIAN 1ST',
//       'SINILIAN 2ND',
//       'SINILIAN 3RD',
//       'SINILIAN CACALIBOSOAN',
//       'SINULATAN 1ST',
//       'SINULATAN 2ND',
//       'SURGUI 1ST',
//       'SURGUI 2ND',
//       'SURGUI 3RD',
//       'TAMBUGAN',
//       'TELBANG',
//       'TUEC',
//     ],
//   },
//   CAPAS: {
//     province: 'TARLAC',
//     municipality: 'CAPAS',
//     region: 'REGION III',
//     barangay_list: [
//       'ARANGUREN',
//       'BUENO',
//       'CRISTO REY',
//       'CUBCUB (POB.)',
//       'CUTCUT 1ST',
//       'CUTCUT 2ND',
//       'DOLORES',
//       'ESTRADA (CALINGCUAN)',
//       'LAWY',
//       'MANGA',
//       'MANLAPIG',
//       'MARUGLU',
//       "O'DONNELL",
//       'SANTA JULIANA',
//       'SANTA LUCIA',
//       'SANTA RITA',
//       'SANTO DOMINGO 1ST',
//       'SANTO DOMINGO 2ND',
//       'SANTO ROSARIO',
//       'TALAGA',
//     ],
//   },
//   'TARLAC': {
//     province: 'TARLAC',
//     municipality: 'TARLAC',
//     region: 'REGION III',
//     barangay_list: [
//       'AGUSO',
//       'ALVINDIA SEGUNDO',
//       'AMUCAO',
//       'ARMENIA',
//       'ASTURIAS',
//       'ATIOC',
//       'BALANTI',
//       'BALETE',
//       'BALIBAGO I',
//       'BALIBAGO II',
//       'BALINGCANAWAY',
//       'BANABA',
//       'BANTOG',
//       'BARAS-BARAS',
//       'BATANG-BATANG',
//       'BINAUGANAN',
//       'BORA',
//       'BUENAVISTA',
//       'BUHILIT (BUBULIT)',
//       'BUROT',
//       'CALINGCUAN',
//       'CAPEHAN',
//       'CARANGIAN',
//       'CARE',
//       'CENTRAL',
//       'CULIPAT',
//       'CUT-CUT I',
//       'CUT-CUT II',
//       'DALAYAP',
//       'DELA PAZ',
//       'DOLORES',
//       'LAOANG',
//       'LIGTASAN',
//       'LOURDES',
//       'MABINI',
//       'MALIGAYA',
//       'MALIWALO',
//       'MAPALACSIAO',
//       'MAPALAD',
//       'MATADERO',
//       'MATATALAIB',
//       'PARAISO',
//       'POBLACION',
//       'SALAPUNGAN',
//       'SAN CARLOS',
//       'SAN FRANCISCO',
//       'SAN ISIDRO',
//       'SAN JOSE',
//       'SAN JOSE DE URQUICO',
//       'SAN JUAN DE MATA',
//       'SAN LUIS',
//       'SAN MANUEL',
//       'SAN MIGUEL',
//       'SAN NICOLAS',
//       'SAN PABLO',
//       'SAN PASCUAL',
//       'SAN RAFAEL',
//       'SAN ROQUE',
//       'SAN SEBASTIAN',
//       'SAN VICENTE',
//       'SANTA CRUZ (ALVINDIA PRIMERO)',
//       'SANTA MARIA',
//       'SANTO CRISTO',
//       'SANTO DOMINGO',
//       'SANTO NIÑO',
//       'SAPANG MARAGUL',
//       'SAPANG TAGALOG',
//       'SEPUNG CALZADA',
//       'SINAIT',
//       'SUIZO',
//       'TARIJI',
//       'TIBAG',
//       'TIBAGAN',
//       'TRINIDAD (TRINIDAD PRIMERO)',
//       'UNGOT',
//       'VILLA BACOLOR',
//     ],
//   },
//   GERONA: {
//     province: 'TARLAC',
//     municipality: 'GERONA',
//     region: 'REGION III',
//     barangay_list: [
//       'ABAGON',
//       'AMACALAN',
//       'APSAYAN',
//       'AYSON',
//       'BAWA',
//       'BUENLAG',
//       'BULARIT',
//       'CALAYAAN',
//       'CARBONEL',
//       'CARDONA',
//       'CATURAY',
//       'DANZO',
//       'DICOLOR',
//       'DON BASILIO',
//       'LUNA',
//       'MABINI',
//       'MAGASPAC',
//       'MALAYEP',
//       'MATAPITAP',
//       'MATAYUMCAB',
//       'NEW SALEM',
//       'OLOYBUAYA',
//       'PADAPADA',
//       'PARSOLINGAN',
//       'PINASLING (PINASUNG)',
//       'PLASTADO',
//       'POBLACION 1',
//       'POBLACION 2',
//       'POBLACION 3',
//       'QUEZON',
//       'RIZAL',
//       'SALAPUNGAN',
//       'SAN AGUSTIN',
//       'SAN ANTONIO',
//       'SAN BARTOLOME',
//       'SAN JOSE',
//       'SANTA LUCIA',
//       'SANTIAGO',
//       'SEMBRANO',
//       'SINGAT',
//       'SULIPA',
//       'TAGUMBAO',
//       'TANGCARAN',
//       'VILLA PAZ',
//     ],
//   },
//   MAYANTOC: {
//     province: 'TARLAC',
//     municipality: 'MAYANTOC',
//     region: 'REGION III',
//     barangay_list: [
//       'AMBALINGIT',
//       'BAYBAYAOAS',
//       'BIGBIGA',
//       'BINBINACA',
//       'CALABTANGAN',
//       'CAOCAOAYAN',
//       'CARABAOAN',
//       'CUBCUB',
//       'GAYONGGAYONG',
//       'GOSSOOD',
//       'LABNEY',
//       'MAMONIT',
//       'MANINIOG',
//       'MAPANDAN',
//       'NAMBALAN',
//       'PEDRO L. QUINES',
//       'PITOMBAYOG',
//       'POBLACION NORTE',
//       'POBLACION SUR',
//       'ROTROTTOOC',
//       'SAN BARTOLOME',
//       'SAN JOSE',
//       'TALDIAPAN',
//       'TANGCARANG',
//     ],
//   },
//   MONCADA: {
//     province: 'TARLAC',
//     municipality: 'MONCADA',
//     region: 'REGION III',
//     barangay_list: [
//       'ABLANG-SAPANG',
//       'ARINGIN',
//       'ATENCIO',
//       'BANAOANG EAST',
//       'BANAOANG WEST',
//       'BAQUERO NORTE',
//       'BAQUERO SUR',
//       'BURGOS',
//       'CALAMAY',
//       'CALAPAN',
//       'CAMANGAAN EAST',
//       'CAMANGAAN WEST',
//       'CAMPOSANTO 1 - NORTE',
//       'CAMPOSANTO 1 - SUR',
//       'CAMPOSANTO 2',
//       'CAPAOAYAN',
//       'LAPSING',
//       'MABINI',
//       'MALUAC',
//       'POBLACION 1',
//       'POBLACION 2',
//       'POBLACION 3',
//       'POBLACION 4',
//       'RIZAL',
//       'SAN JUAN',
//       'SAN JULIAN',
//       'SAN LEON',
//       'SAN PEDRO',
//       'SAN ROQUE',
//       'SANTA LUCIA EAST',
//       'SANTA LUCIA WEST',
//       'SANTA MARIA',
//       'SANTA MONICA',
//       'TOLEGA NORTE',
//       'TOLEGA SUR',
//       'TUBECTUBANG',
//       'VILLA',
//     ],
//   },
//   PANIQUI: {
//     province: 'TARLAC',
//     municipality: 'PANIQUI',
//     region: 'REGION III',
//     barangay_list: [
//       'ABOGADO',
//       'ACOCOLAO',
//       'ADUAS',
//       'APULID',
//       'BALAOANG',
//       'BARANG (BORANG)',
//       'BRILLANTE',
//       'BURGOS',
//       'CABAYAOASAN',
//       'CANAN',
//       'CARINO',
//       'CAYANGA',
//       'COLIBANGBANG',
//       'CORAL',
//       'DAPDAP',
//       'ESTACION',
//       'MABILANG',
//       'MANAOIS',
//       'MATALAPITAP',
//       'NAGMISAAN',
//       'NANCAMARINAN',
//       'NIPACO',
//       'PATALAN',
//       'POBLACION NORTE',
//       'POBLACION SUR',
//       'RANG-AYAN',
//       'SALUMAGUE',
//       'SAMPUT',
//       'SAN CARLOS',
//       'SAN ISIDRO',
//       'SAN JUAN DE MILLA',
//       'SANTA INES',
//       'SINIGPIT',
//       'TABLANG',
//       'VENTENILLA',
//     ],
//   },
//   PURA: {
//     province: 'TARLAC',
//     municipality: 'PURA',
//     region: 'REGION III',
//     barangay_list: [
//       'BALITE',
//       'BUENAVISTA',
//       'CADANGLAAN',
//       'ESTIPONA',
//       'LINAO',
//       'MAASIN',
//       'MATINDEG',
//       'MAUNGIB',
//       'NAYA',
//       'NILASIN 1ST',
//       'NILASIN 2ND',
//       'POBLACION 1',
//       'POBLACION 2',
//       'POBLACION 3',
//       'POROC',
//       'SINGAT',
//     ],
//   },
//   RAMOS: {
//     province: 'TARLAC',
//     municipality: 'RAMOS',
//     region: 'REGION III',
//     barangay_list: [
//       'CORAL-ILOCO',
//       'GUITEB',
//       'PANCE',
//       'POBLACION CENTER',
//       'POBLACION NORTH',
//       'POBLACION SOUTH',
//       'SAN JUAN',
//       'SAN RAYMUNDO',
//       'TOLEDO',
//     ],
//   },
//   'SAN CLEMENTE': {
//     province: 'TARLAC',
//     municipality: 'SAN CLEMENTE',
//     region: 'REGION III',
//     barangay_list: [
//       'BALLOC',
//       'BAMBAN',
//       'CASIPO',
//       'CATAGUDINGAN',
//       'DALDALAYAP',
//       'DOCLONG 1',
//       'DOCLONG 2',
//       'MAASIN',
//       'NAGSABARAN',
//       'PIT-AO',
//       'POBLACION NORTE',
//       'POBLACION SUR',
//     ],
//   },
//   'SANTA IGNACIA': {
//     province: 'TARLAC',
//     municipality: 'SANTA IGNACIA',
//     region: 'REGION III',
//     barangay_list: [
//       'BALDIOS',
//       'BOTBOTONES',
//       'CAANAMONGAN',
//       'CABARUAN',
//       'CABUGBUGAN',
//       'CADULDULAOAN',
//       'CALIPAYAN',
//       'MACAGUING',
//       'NAMBALAN',
//       'PADAPADA',
//       'PILPILA',
//       'PINPINAS',
//       'POBLACION EAST',
//       'POBLACION WEST',
//       'PUGO-CECILIO',
//       'SAN FRANCISCO',
//       'SAN SOTERO',
//       'SAN VICENTE',
//       'SANTA INES CENTRO',
//       'SANTA INES EAST',
//       'SANTA INES WEST',
//       'TAGUIPORO',
//       'TIMMAGUAB',
//       'VARGAS',
//     ],
//   },
//   VICTORIA: {
//     province: 'NORTHERN SAMAR',
//     municipality: 'VICTORIA',
//     region: 'REGION VIII',
//     barangay_list: [
//       'ACEDILLO',
//       'BUENASUERTE',
//       'BUENOS AIRES',
//       'COLAB-OG',
//       'ERENAS',
//       'LIBERTAD',
//       'LUISITA',
//       'LUNGIB',
//       'MAXVILLA',
//       'PASABUENA',
//       'SAN LAZARO',
//       'SAN MIGUEL',
//       'SAN ROMAN',
//       'ZONE I (POB.)',
//       'ZONE II (POB.)',
//       'ZONE III (POB.)',
//     ],
//   },
//   BOTOLAN: {
//     province: 'ZAMBALES',
//     municipality: 'BOTOLAN',
//     region: 'REGION III',
//     barangay_list: [
//       'BANCAL',
//       'BANGAN',
//       'BATONLAPOC',
//       'BELBEL',
//       'BENEG',
//       'BINUCLUTAN',
//       'BURGOS',
//       'CABATUAN',
//       'CAPAYAWAN',
//       'CARAEL',
//       'DANACBUNGA',
//       'MAGUISGUIS',
//       'MALOMBOY',
//       'MAMBOG',
//       'MORAZA',
//       'NACOLCOL',
//       'OWAOG-NIBLOC',
//       'PACO (POB.)',
//       'PALIS',
//       'PANAN',
//       'PAREL',
//       'PAUDPOD',
//       'POONBATO',
//       'PORAC',
//       'SAN ISIDRO',
//       'SAN JUAN',
//       'SAN MIGUEL',
//       'SANTIAGO',
//       'TAMPO (POB.)',
//       'TAUGTOG',
//       'VILLAR',
//     ],
//   },
//   CABANGAN: {
//     province: 'ZAMBALES',
//     municipality: 'CABANGAN',
//     region: 'REGION III',
//     barangay_list: [
//       'ANONANG',
//       'APO-APO',
//       'AREW',
//       'BANUAMBAYO (POB.)',
//       'CADMANG-RESERVA',
//       'CAMILING (CAMIING)',
//       'CASABAAN',
//       'DEL CARMEN (POB.)',
//       'DOLORES (POB.)',
//       'FELMIDA-DIAZ',
//       'LAOAG',
//       'LOMBOY',
//       'LONGOS',
//       'MABANGLIT',
//       'NEW SAN JUAN',
//       'SAN ANTONIO',
//       'SAN ISIDRO',
//       'SAN JUAN (POB.)',
//       'SAN RAFAEL',
//       'SANTA RITA',
//       'SANTO NIÑO',
//       'TONDO',
//     ],
//   },
//   CANDELARIA: {
//     province: 'QUEZON',
//     municipality: 'CANDELARIA',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'BUENAVISTA EAST',
//       'BUENAVISTA WEST',
//       'BUKAL NORTE',
//       'BUKAL SUR',
//       'KINATIHAN I',
//       'KINATIHAN II',
//       'MALABANBAN NORTE',
//       'MALABANBAN SUR',
//       'MANGILAG NORTE',
//       'MANGILAG SUR',
//       'MASALUKOT I',
//       'MASALUKOT II',
//       'MASALUKOT III',
//       'MASALUKOT IV',
//       'MASALUKOT V',
//       'MASIN NORTE',
//       'MASIN SUR',
//       'MAYABOBO',
//       'PAHINGA NORTE',
//       'PAHINGA SUR',
//       'POBLACION',
//       'SAN ANDRES',
//       'SAN ISIDRO',
//       'SANTA CATALINA NORTE',
//       'SANTA CATALINA SUR',
//     ],
//   },
//   CASTILLEJOS: {
//     province: 'ZAMBALES',
//     municipality: 'CASTILLEJOS',
//     region: 'REGION III',
//     barangay_list: [
//       'BALAYBAY',
//       'BUENAVISTA',
//       'DEL PILAR',
//       'LOOC',
//       'MAGSAYSAY',
//       'NAGBAYAN',
//       'NAGBUNGA',
//       'SAN AGUSTIN',
//       'SAN JOSE (POB.)',
//       'SAN JUAN (POB.)',
//       'SAN NICOLAS',
//       'SAN PABLO (POB.)',
//       'SAN ROQUE',
//       'SANTA MARIA',
//     ],
//   },
//   IBA: {
//     province: 'ZAMBALES',
//     municipality: 'IBA',
//     region: 'REGION III',
//     barangay_list: [
//       'AMUNGAN',
//       'BANGANTALINGA',
//       'DIRITA-BALOGUEN',
//       'LIPAY-DINGIN-PANIBUATAN',
//       'PALANGINAN (PALANGUINAN-TAMBAK)',
//       'SAN AGUSTIN',
//       'SANTA BARBARA',
//       'SANTO ROSARIO',
//       'ZONE 1 POB. (LIBABA)',
//       'ZONE 2 POB. (AYPA)',
//       'ZONE 3 POB. (BOTLAY)',
//       'ZONE 4 POB. (SAGAPAN)',
//       'ZONE 5 POB. (BANO)',
//       'ZONE 6 POB. (BAYTAN)',
//     ],
//   },
//   MASINLOC: {
//     province: 'ZAMBALES',
//     municipality: 'MASINLOC',
//     region: 'REGION III',
//     barangay_list: [
//       'BALOGANON',
//       'BAMBAN',
//       'BANI',
//       'COLLAT',
//       'INHOBOL',
//       'NORTH POBLACION',
//       'SAN LORENZO',
//       'SAN SALVADOR',
//       'SANTA RITA',
//       'SANTO ROSARIO',
//       'SOUTH POBLACION',
//       'TALTAL',
//       'TAPUAC',
//     ],
//   },
//   'OLONGAPO': {
//     province: 'ZAMBALES',
//     municipality: 'OLONGAPO',
//     region: 'REGION III',
//     barangay_list: [
//       'ASINAN',
//       'BANICAIN',
//       'BARRETO',
//       'EAST BAJAC-BAJAC',
//       'EAST TAPINAC',
//       'GORDON HEIGHTS',
//       'KALAKLAN',
//       'MABAYUAN',
//       'NEW CABALAN',
//       'NEW ILALIM',
//       'NEW KABABAE',
//       'NEW KALALAKE',
//       'OLD CABALAN',
//       'PAG-ASA',
//       'SANTA RITA',
//       'WEST BAJAC-BAJAC',
//       'WEST TAPINAC',
//     ],
//   },
//   PALAUIG: {
//     province: 'ZAMBALES',
//     municipality: 'PALAUIG',
//     region: 'REGION III',
//     barangay_list: [
//       'ALWA',
//       'BATO',
//       'BULAWEN',
//       'CAUYAN',
//       'EAST POBLACION',
//       'GARRETA',
//       'LIBABA',
//       'LIOZON',
//       'LIPAY',
//       'LOCLOC',
//       'MACARANG',
//       'MAGALAWA',
//       'PANGOLINGAN',
//       'SALAZA',
//       'SAN JUAN',
//       'SANTO NIÑO',
//       'SANTO TOMAS',
//       'TITION (SAN VICENTE)',
//       'WEST POBLACION',
//     ],
//   },
//   'SAN FELIPE': {
//     province: 'ZAMBALES',
//     municipality: 'SAN FELIPE',
//     region: 'REGION III',
//     barangay_list: [
//       'AMAGNA (POB.)',
//       'APOSTOL (POB.)',
//       'BALINCAGUING',
//       'FARAÑAL (POB.)',
//       'FERIA (POB.)',
//       'MALOMA',
//       'MANGLICMOT (POB.)',
//       'ROSETE (POB.)',
//       'SAN RAFAEL',
//       'SANTO NIÑO',
//       'SINDOL',
//     ],
//   },
//   'SAN MARCELINO': {
//     province: 'ZAMBALES',
//     municipality: 'SAN MARCELINO',
//     region: 'REGION III',
//     barangay_list: [
//       'AGLAO',
//       'BUHAWEN',
//       'BURGOS (POB.)',
//       'CENTRAL (POB.)',
//       'CONSUELO NORTE',
//       'CONSUELO SUR (POB.)',
//       'LA PAZ (POB.)',
//       'LAOAG',
//       'LINASIN',
//       'LINUSUNGAN',
//       'LUCERO (POB.)',
//       'NAGBUNGA',
//       'RABANES',
//       'RIZAL (POB.)',
//       'SAN GUILLERMO (POB.)',
//       'SAN ISIDRO (POB.)',
//       'SAN RAFAEL',
//       'SANTA FE',
//     ],
//   },
//   'SAN NARCISO': {
//     province: 'QUEZON',
//     municipality: 'SAN NARCISO',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'ABUYON',
//       'ANDRES BONIFACIO',
//       'BANI',
//       'BAYANIHAN (POB.)',
//       'BINAY',
//       'BUENAVISTA',
//       'BUSOKBUSOKAN',
//       'CALWIT',
//       'GUINHALINAN',
//       'LACDAYAN',
//       'MAGUITING',
//       'MALIGAYA (POB.)',
//       'MANLAMPONG',
//       'PAGDADAMAYAN (POB.)',
//       'PAGKAKAISA (POB.)',
//       'PUNTA',
//       'RIZAL',
//       'SAN ISIDRO',
//       'SAN JUAN',
//       'SAN VICENTE',
//       'VIGO CENTRAL',
//       'VILLA AURIN (PINAGSAMA)',
//       'VILLA REYES',
//       'WHITE CLIFF',
//     ],
//   },
//   SUBIC: {
//     province: 'ZAMBALES',
//     municipality: 'SUBIC',
//     region: 'REGION III',
//     barangay_list: [
//       'ANINGWAY SACATIHAN',
//       'ASINAN POBLACION',
//       'ASINAN PROPER',
//       'BARACA-CAMACHILE (POB.)',
//       'BATIAWAN',
//       'CALAPACUAN',
//       'CALAPANDAYAN (POB.)',
//       'CAWAG',
//       'ILWAS (POB.)',
//       'MANGAN-VACA',
//       'MATAIN',
//       'NAUGSOL',
//       'PAMATAWAN',
//       'SAN ISIDRO',
//       'SANTO TOMAS',
//       'WAWANDUE (POB.)',
//     ],
//   },
//   AGONCILLO: {
//     province: 'BATANGAS',
//     municipality: 'AGONCILLO',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'ADIA',
//       'BAGONG SIKAT',
//       'BALANGON',
//       'BANGIN',
//       'BANYAGA',
//       'BARIGON',
//       'BILIBINWANG',
//       'CORAL NA MUNTI',
//       'GUITNA',
//       'MABINI',
//       'PAMIGA',
//       'PANHULAN',
//       'PANSIPIT',
//       'POBLACION',
//       'POOK',
//       'SAN JACINTO',
//       'SAN TEODORO',
//       'SANTA CRUZ',
//       'SANTO TOMAS',
//       'SUBIC IBABA',
//       'SUBIC ILAYA',
//     ],
//   },
//   ALITAGTAG: {
//     province: 'BATANGAS',
//     municipality: 'ALITAGTAG',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'BALAGBAG',
//       'CONCEPCION',
//       'CONCORDIA',
//       'DALIPIT EAST',
//       'DALIPIT WEST',
//       'DOMINADOR EAST',
//       'DOMINADOR WEST',
//       'MUNLAWIN NORTE',
//       'MUNLAWIN SUR',
//       'MUZON PRIMERO',
//       'MUZON SEGUNDO',
//       'PINAGKURUSAN',
//       'PING-AS',
//       'POBLACION EAST',
//       'POBLACION WEST',
//       'SAN JOSE',
//       'SAN JUAN',
//       'SANTA CRUZ',
//       'TADLAC',
//     ],
//   },
//   BALAYAN: {
//     province: 'BATANGAS',
//     municipality: 'BALAYAN',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'BACLARAN',
//       'BARANGAY 1 (POB.)',
//       'BARANGAY 2 (POB.)',
//       'BARANGAY 3 (POB.)',
//       'BARANGAY 4 (POB.)',
//       'BARANGAY 5 (POB.)',
//       'BARANGAY 6 (POB.)',
//       'BARANGAY 7 (POB.)',
//       'BARANGAY 8 (POB.)',
//       'BARANGAY 9 (POB.)',
//       'BARANGAY 10 (POB.)',
//       'BARANGAY 11 (POB.)',
//       'BARANGAY 12 (POB.)',
//       'CALAN',
//       'CALOOCAN',
//       'CALZADA',
//       'CANDA',
//       'CARENAHAN',
//       'CAYBUNGA',
//       'CAYPONCE',
//       'DALIG',
//       'DAO',
//       'DILAO',
//       'DUHATAN',
//       'DURUNGAO',
//       'GIMALAS',
//       'GUMAMELA',
//       'LAGNAS',
//       'LANATAN',
//       'LANGGANGAN',
//       'LUCBAN POOK',
//       'LUCBAN PUTOL',
//       'MAGABE',
//       'MALALAY',
//       'MUNTING TUBIG',
//       'NAVOTAS',
//       'PALIKPIKAN',
//       'PATUGO',
//       'POOC',
//       'SAMBAT',
//       'SAMPAGA',
//       'SAN JUAN',
//       'SAN PIRO',
//       'SANTOL',
//       'SUKOL',
//       'TACTAC',
//       'TALUDTUD',
//       'TANGGOY',
//     ],
//   },
//   BALETE: {
//     province: 'AKLAN',
//     municipality: 'BALETE',
//     region: 'REGION VI',
//     barangay_list: [
//       'ARANAS',
//       'ARCANGEL',
//       'CALIZO',
//       'CORTES',
//       'FELICIANO',
//       'FULGENCIO',
//       'GUANKO',
//       'MORALES',
//       'OQUENDO',
//       'POBLACION',
//     ],
//   },
//   'BATANGAS': {
//     province: 'BATANGAS',
//     municipality: 'BATANGAS',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'ALANGILAN',
//       'BALAGTAS',
//       'BALETE',
//       'BANABA CENTER',
//       'BANABA IBABA',
//       'BANABA KANLURAN',
//       'BANABA SILANGAN',
//       'BARANGAY 1 (POB.)',
//       'BARANGAY 2 (POB.)',
//       'BARANGAY 3 (POB.)',
//       'BARANGAY 4 (POB.)',
//       'BARANGAY 5 (POB.)',
//       'BARANGAY 6 (POB.)',
//       'BARANGAY 7 (POB.)',
//       'BARANGAY 8 (POB.)',
//       'BARANGAY 9 (POB.)',
//       'BARANGAY 10 (POB.)',
//       'BARANGAY 11 (POB.)',
//       'BARANGAY 12 (POB.)',
//       'BARANGAY 13 (POB.)',
//       'BARANGAY 14 (POB.)',
//       'BARANGAY 15 (POB.)',
//       'BARANGAY 16 (POB.)',
//       'BARANGAY 17 (POB.)',
//       'BARANGAY 18 (POB.)',
//       'BARANGAY 19 (POB.)',
//       'BARANGAY 20 (POB.)',
//       'BARANGAY 21 (POB.)',
//       'BARANGAY 22 (POB.)',
//       'BARANGAY 23 (POB.)',
//       'BARANGAY 24 (POB.)',
//       'BILOGO',
//       'BOLBOK',
//       'BUKAL',
//       'CALICANTO',
//       'CATANDALA',
//       'CONCEPCION',
//       'CONDE ITAAS',
//       'CONDE LABAK',
//       'CUTA',
//       'DALIG',
//       'DELA PAZ',
//       'DELA PAZ PULOT APLAYA',
//       'DELA PAZ PULOT ITAAS',
//       'DOMOCLAY',
//       'DUMANTAY',
//       'GULOD ITAAS',
//       'GULOD LABAK',
//       'HALIGUE KANLURAN',
//       'HALIGUE SILANGAN',
//       'ILIHAN',
//       'KUMBA',
//       'KUMINTANG IBABA',
//       'KUMINTANG ILAYA',
//       'LIBJO',
//       'LIPONPON, ISLA VERDE',
//       'MAAPAS',
//       'MABACONG (MATOCO)',
//       'MAHABANG DAHILIG',
//       'MAHABANG PARANG',
//       'MAHACOT KANLURAN',
//       'MAHACOT SILANGAN',
//       'MALALIM',
//       'MALIBAYO',
//       'MALITAM',
//       'MARUCLAP',
//       'PAGKILATAN',
//       'PAHARANG KANLURAN',
//       'PAHARANG SILANGAN',
//       'PALLOCAN KANLURAN',
//       'PALLOCAN SILANGAN',
//       'PINAMUCAN',
//       'PINAMUCAN IBABA',
//       'PINAMUCAN SILANGAN',
//       'SAMPAGA',
//       'SAN AGAPITO, ISLA VERDE',
//       'SAN AGUSTIN KANLURAN, ISLA VERDE',
//       'SAN AGUSTIN SILANGAN, ISLA VERDE',
//       'SAN ANDRES, ISLA VERDE',
//       'SAN ANTONIO, ISLA VERDE',
//       'SAN ISIDRO',
//       'SAN JOSE SICO',
//       'SAN MIGUEL',
//       'SAN PEDRO',
//       'SANTA CLARA',
//       'SANTA RITA APLAYA',
//       'SANTA RITA KARSADA',
//       'SANTO DOMINGO',
//       'SANTO NIÑO',
//       'SIMLONG',
//       'SIRANG LUPA',
//       'SOROSORO IBABA',
//       'SOROSORO ILAYA',
//       'SOROSORO KARSADA',
//       'TABANGAO AMBULONG',
//       'TABANGAO APLAYA (TABANGAO PROPER)',
//       'TABANGAO DAO',
//       'TALAHIB PANDAYAN',
//       'TALAHIB PAYAPA',
//       'TALUMPOK KANLURAN',
//       'TALUMPOK SILANGAN',
//       'TINGA ITAAS',
//       'TINGA LABAK',
//       'TULO',
//       'WAWA',
//     ],
//   },
//   BAUAN: {
//     province: 'BATANGAS',
//     municipality: 'BAUAN',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'ALAGAO',
//       'APLAYA',
//       'AS-IS',
//       'BAGONG SILANG',
//       'BAGUILAWA',
//       'BALAYONG',
//       'BARANGAY I (POB.)',
//       'BARANGAY II (POB.)',
//       'BARANGAY III (POB.)',
//       'BARANGAY IV (POB.)',
//       'BOLO',
//       'COLVO',
//       'CUPANG',
//       'DURUNGAO',
//       'GULIBAY',
//       'INICBULAN',
//       'LOCLOC',
//       'MAGALANG-GALANG',
//       'MALINDIG',
//       'MANALUPONG',
//       'MANGHINAO PROPER',
//       'MANGHINAO UNO',
//       'NEW DANGLAYAN',
//       'ORENSE',
//       'PITUGO',
//       'RIZAL',
//       'SAMPAGUITA',
//       'SAN AGUSTIN',
//       'SAN ANDRES PROPER',
//       'SAN ANDRES UNO',
//       'SAN DIEGO',
//       'SAN MIGUEL',
//       'SAN PABLO',
//       'SAN PEDRO',
//       'SAN ROQUE',
//       'SAN TEODORO',
//       'SAN VICENTE',
//       'SANTA MARIA',
//       'SANTO DOMINGO',
//       'SINALA',
//     ],
//   },
//   CALACA: {
//     province: 'BATANGAS',
//     municipality: 'CALACA',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'BACLAS',
//       'BAGONG TUBIG',
//       'BALIMBING',
//       'BAMBANG',
//       'BARANGAY 1 (POB.)',
//       'BARANGAY 2 (POB.)',
//       'BARANGAY 3 (POB.)',
//       'BARANGAY 4 (POB.)',
//       'BARANGAY 5 (POB.)',
//       'BARANGAY 6 (POB.)',
//       'BISAYA',
//       'CAHIL',
//       'CALANTAS',
//       'CALUANGAN',
//       'CAMASTILISAN',
//       'CORAL NI BACAL',
//       'CORAL NI LOPEZ (SUGOD)',
//       'DACANLAO',
//       'DILA',
//       'LOMA',
//       'LUMBANG CALZADA',
//       'LUMBANG NA BATA',
//       'LUMBANG NA MATANDA',
//       'MADALUNOT',
//       'MAKINA',
//       'MATIPOK',
//       'MUNTING CORAL',
//       'NIYUGAN',
//       'PANTAY',
//       'PUTING BATO EAST',
//       'PUTING BATO WEST',
//       'PUTING KAHOY',
//       'QUISUMBING',
//       'SALONG',
//       'SAN RAFAEL',
//       'SINISIAN',
//       'TAKLANG ANAK',
//       'TALISAY',
//       'TAMAYO',
//       'TIMBAIN',
//     ],
//   },
//   CALATAGAN: {
//     province: 'BATANGAS',
//     municipality: 'CALATAGAN',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'BAGONG SILANG',
//       'BAHA',
//       'BALIBAGO',
//       'BALITOC',
//       'BARANGAY 1 (POB.)',
//       'BARANGAY 2 (POB.)',
//       'BARANGAY 3 (POB.)',
//       'BARANGAY 4 (POB.)',
//       'BIGA',
//       'BUCAL',
//       'CARLOSA',
//       'CARRETUNAN',
//       'ENCARNACION',
//       'GULOD',
//       'HUKAY',
//       'LUCSUHIN',
//       'LUYA',
//       'PARAISO',
//       'QUILITISAN',
//       'REAL',
//       'SAMBUNGAN',
//       'SANTA ANA',
//       'TALIBAYOG',
//       'TALISAY',
//       'TANAGAN',
//     ],
//   },
//   CUENCA: {
//     province: 'BATANGAS',
//     municipality: 'CUENCA',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'BALAGBAG',
//       'BARANGAY 1 (POB.)',
//       'BARANGAY 2 (POB.)',
//       'BARANGAY 3 (POB.)',
//       'BARANGAY 4 (POB.)',
//       'BARANGAY 5 (POB.)',
//       'BARANGAY 6 (POB.)',
//       'BARANGAY 7 (POB.)',
//       'BARANGAY 8 (POB.)',
//       'BUNGAHAN',
//       'CALUMAYIN',
//       'DALIPIT EAST',
//       'DALIPIT WEST',
//       'DITA',
//       'DON JUAN',
//       'EMMANUEL',
//       'IBABAO',
//       'LABAC',
//       'PINAGKAISAHAN',
//       'SAN FELIPE',
//       'SAN ISIDRO',
//     ],
//   },
//   IBAAN: {
//     province: 'BATANGAS',
//     municipality: 'IBAAN',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'BAGO',
//       'BALANGA',
//       'BUNGAHAN',
//       'CALAMIAS',
//       'CATANDALA',
//       'COLIAT',
//       'DAYAPAN',
//       'LAPU-LAPU',
//       'LUCSUHIN',
//       'MABALOR',
//       'MALAININ',
//       'MATALA',
//       'MUNTING-TUBIG',
//       'PALINDAN',
//       'PANGAO',
//       'PANGHAYAAN',
//       'POBLACION',
//       'QUILO',
//       'SABANG',
//       'SALABAN I',
//       'SALABAN II',
//       'SAN AGUSTIN',
//       'SANDALAN',
//       'SANTO NIÑO',
//       'TALAIBON',
//       'TULAY NA PATPAT',
//     ],
//   },
//   LAUREL: {
//     province: 'BATANGAS',
//     municipality: 'LAUREL',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'AS-IS',
//       'BALAKILONG',
//       'BARANGAY 1 (POB.)',
//       'BARANGAY 2 (POB.)',
//       'BARANGAY 3 (POB.)',
//       'BARANGAY 4 (POB.)',
//       'BARANGAY 5 (POB.)',
//       'BERINAYAN',
//       'BUGAAN EAST',
//       'BUGAAN WEST',
//       'BUSO-BUSO',
//       'DAYAP ITAAS',
//       'GULOD',
//       'J. LEVISTE',
//       'MOLINETE',
//       'NIYUGAN',
//       'PALIPARAN',
//       'SAN GABRIEL',
//       'SAN GREGORIO',
//       'SANTA MARIA',
//       'TICUB',
//     ],
//   },
//   LEMERY: {
//     province: 'ILOILO',
//     municipality: 'LEMERY',
//     region: 'REGION VI',
//     barangay_list: [
//       'AGPIPILI',
//       'ALCANTARA',
//       'ALMEÑANA',
//       'ANABO',
//       'BANKAL',
//       'BUENAVISTA',
//       'CABANTOHAN',
//       'CAPIÑAHAN',
//       'DALIPE',
//       'DAPDAPAN',
//       'GERONGAN',
//       'IMBAULAN',
//       'LAYOGBATO',
//       'MARAPAL',
//       'MILAN',
//       'NAGSULANG',
//       'NASAPAHAN',
//       'OMIO',
//       'PACUAN',
//       'POBLACION NW ZONE',
//       'POBLACION SE ZONE',
//       'PONTOC',
//       'SAN ANTONIO',
//       'SAN DIEGO',
//       'SAN JOSE MOTO',
//       'SEPANTON',
//       'SINCUA',
//       'TABUNAN',
//       'TUGAS',
//       'VELASCO',
//       'YAWYAWAN',
//     ],
//   },
//   LIAN: {
//     province: 'BATANGAS',
//     municipality: 'LIAN',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'BAGONG POOK',
//       'BALIBAGO',
//       'BARANGAY 1 (POB.)',
//       'BARANGAY 2 (POB.)',
//       'BARANGAY 3 (POB.)',
//       'BARANGAY 4 (POB.)',
//       'BARANGAY 5 (POB.)',
//       'BINUBUSAN',
//       'BUNGAHAN',
//       'CUMBA',
//       'HUMAYINGAN',
//       'KAPITO',
//       'LUMANIAG',
//       'LUYAHAN',
//       'MALARUHATAN',
//       'MATABUNGKAY',
//       'PRENZA',
//       'PUTING-KAHOY',
//       'SAN DIEGO',
//     ],
//   },
//   'LIPA': {
//     province: 'BATANGAS',
//     municipality: 'LIPA',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'ADYA',
//       'ANILAO',
//       'ANILAO-LABAC',
//       'ANTIPOLO DEL NORTE',
//       'ANTIPOLO DEL SUR',
//       'BAGONG POOK',
//       'BALINTAWAK',
//       'BANAYBANAY',
//       'BARANGAY 12 (POB.)',
//       'BOLBOK',
//       'BUGTONG NA PULO',
//       'BULACNIN',
//       'BULAKLAKAN',
//       'CALAMIAS',
//       'CUMBA',
//       'DAGATAN',
//       'DUHATAN',
//       'HALANG',
//       'INOSLOBAN',
//       'KAYUMANGGI',
//       'LATAG',
//       'LODLOD',
//       'LUMBANG',
//       'MABINI',
//       'MALAGONLONG',
//       'MALITLIT',
//       'MARAUOY',
//       'MATAAS NA LUPA',
//       'MUNTING PULO',
//       'PAGOLINGIN BATA',
//       'PAGOLINGIN EAST',
//       'PAGOLINGIN WEST',
//       'PANGAO',
//       'PINAGKAWITAN',
//       'PINAGTONGULAN',
//       'PLARIDEL',
//       'POBLACION BARANGAY 1',
//       'POBLACION BARANGAY 2',
//       'POBLACION BARANGAY 3',
//       'POBLACION BARANGAY 4',
//       'POBLACION BARANGAY 5',
//       'POBLACION BARANGAY 6',
//       'POBLACION BARANGAY 7',
//       'POBLACION BARANGAY 8',
//       'POBLACION BARANGAY 9',
//       'POBLACION BARANGAY 9-A',
//       'POBLACION BARANGAY 10',
//       'POBLACION BARANGAY 11',
//       'PUSIL',
//       'QUEZON',
//       'RIZAL',
//       'SABANG',
//       'SAMPAGUITA',
//       'SAN BENITO',
//       'SAN CARLOS',
//       'SAN CELESTINO',
//       'SAN FRANCISCO',
//       'SAN GUILLERMO',
//       'SAN JOSE',
//       'SAN LUCAS',
//       'SAN SALVADOR',
//       'SAN SEBASTIAN (BALAGBAG)',
//       'SANTO NIÑO',
//       'SANTO TORIBIO',
//       'SAPAC',
//       'SICO',
//       'TALISAY',
//       'TAMBO',
//       'TANGOB',
//       'TANGUAY',
//       'TIBIG',
//       'TIPACAN',
//     ],
//   },
//   LOBO: {
//     province: 'BATANGAS',
//     municipality: 'LOBO',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'APAR',
//       'BALATBAT',
//       'BALIBAGO',
//       'BANALO',
//       'BIGA',
//       'BIGNAY',
//       'CALO',
//       'CALUMPIT',
//       'FABRICA',
//       'JAYBANGA',
//       'LAGADLARIN',
//       'MABILOG NA BUNDOK',
//       'MALABRIGO',
//       'MALALIM NA SANOG',
//       'MALAPAD NA PARANG',
//       'MASAGUITSIT',
//       'NAGTALONGTONG',
//       'NAGTOCTOC',
//       'OLO-OLO',
//       'PINAGHAWANAN',
//       'POBLACION',
//       'SAN MIGUEL',
//       'SAN NICOLAS',
//       'SAWANG',
//       'SOLOC',
//       'TAYUMAN',
//     ],
//   },
//   MALVAR: {
//     province: 'BATANGAS',
//     municipality: 'MALVAR',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'BAGONG POOK',
//       'BILUCAO (SAN ISIDRO WESTERN)',
//       'BULIHAN',
//       'LUTA DEL NORTE',
//       'LUTA DEL SUR',
//       'POBLACION',
//       'SAN ANDRES',
//       'SAN FERNANDO',
//       'SAN GREGORIO',
//       'SAN ISIDRO EAST',
//       'SAN JUAN',
//       'SAN PEDRO I (EASTERN)',
//       'SAN PEDRO II (WESTERN)',
//       'SAN PIOQUINTO',
//       'SANTIAGO',
//     ],
//   },
//   'MATAAS NA KAHOY': {
//     province: 'BATANGAS',
//     municipality: 'MATAAS NA KAHOY',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'BARANGAY II-A (POB.)',
//       'BAYORBOR',
//       'BUBUYAN',
//       'CALINGATAN',
//       'DISTRICT I (POB.)',
//       'DISTRICT II (POB.)',
//       'DISTRICT III (POB.)',
//       'DISTRICT IV (POB.)',
//       'KINALAGLAGAN',
//       'LOOB',
//       'LUMANG LIPA',
//       'MANGGAHAN',
//       'NANGKAAN',
//       'SAN SEBASTIAN',
//       'SANTOL',
//       'UPA',
//     ],
//   },
//   NASUGBU: {
//     province: 'BATANGAS',
//     municipality: 'NASUGBU',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'AGA',
//       'BALAYTIGUI',
//       'BANILAD',
//       'BARANGAY 1 (POB.)',
//       'BARANGAY 2 (POB.)',
//       'BARANGAY 3 (POB.)',
//       'BARANGAY 4 (POB.)',
//       'BARANGAY 5 (POB.)',
//       'BARANGAY 6 (POB.)',
//       'BARANGAY 7 (POB.)',
//       'BARANGAY 8 (POB.)',
//       'BARANGAY 9 (POB.)',
//       'BARANGAY 10 (POB.)',
//       'BARANGAY 11 (POB.)',
//       'BARANGAY 12 (POB.)',
//       'BILARAN',
//       'BUCANA',
//       'BULIHAN',
//       'BUNDUCAN',
//       'BUTUCAN',
//       'CALAYO',
//       'CATANDAAN',
//       'COGUNAN',
//       'DAYAP',
//       'KAYLAWAY',
//       'KAYRILAW',
//       'LATAG',
//       'LOOC',
//       'LUMBANGAN',
//       'MALAPAD NA BATO',
//       'MATAAS NA PULO',
//       'MAUGAT',
//       'MUNTING INDAN',
//       'NATIPUAN',
//       'PANTALAN',
//       'PAPAYA',
//       'PUTAT',
//       'REPARO',
//       'TALANGAN',
//       'TUMALIM',
//       'UTOD',
//       'WAWA',
//     ],
//   },
//   'PADRE GARCIA': {
//     province: 'BATANGAS',
//     municipality: 'PADRE GARCIA',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'BANABA',
//       'BANAYBANAY',
//       'BAWI',
//       'BUKAL',
//       'CASTILLO',
//       'CAWONGAN',
//       'MANGGAS',
//       'MAUGAT EAST',
//       'MAUGAT WEST',
//       'PANSOL',
//       'PAYAPA',
//       'POBLACION',
//       'QUILO-QUILO NORTH',
//       'QUILO-QUILO SOUTH',
//       'SAN FELIPE',
//       'SAN MIGUEL',
//       'TAMAK',
//       'TANGOB',
//     ],
//   },
//   'SAN PASCUAL': {
//     province: 'MASBATE',
//     municipality: 'SAN PASCUAL',
//     region: 'REGION V',
//     barangay_list: [
//       'BOCA CHICA',
//       'BOLOD (POB.)',
//       'BUSING',
//       'CUEVA',
//       'DANGCALAN',
//       'HALABANGBAYBAY',
//       'INIWARAN',
//       'KI-BUAYA (RIZAL)',
//       'KI-ROMERO (ROXAS)',
//       'LAURENTE',
//       'MABINI',
//       'MABUHAY',
//       'MALAKING ILOG',
//       'MAPANIQUE',
//       'NAZARENO',
//       'PINAMASINGAN',
//       'QUINTINA',
//       'SAN JOSE',
//       'SAN PEDRO',
//       'SAN RAFAEL',
//       'SANTA CRUZ',
//       'TERRAPLIN (POB.)',
//     ],
//   },
//   TAAL: {
//     province: 'BATANGAS',
//     municipality: 'TAAL',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'APACAY',
//       'BALISONG',
//       'BIHIS',
//       'BOLBOK',
//       'BULI',
//       'BUTONG',
//       'CARASUCHE',
//       'CAWIT',
//       'CAYSASAY',
//       'CUBAMBA',
//       'CULTIHAN',
//       'GAHOL',
//       'HALANG',
//       'IBA',
//       'ILOG',
//       'IMAMAWO',
//       'IPIL',
//       'LAGUILE',
//       'LATAG',
//       'LUNTAL',
//       'MAHABANG LODLOD',
//       'NIOGAN',
//       'PANSOL',
//       'POBLACION 1',
//       'POBLACION 2',
//       'POBLACION 3',
//       'POBLACION 4',
//       'POBLACION 5',
//       'POBLACION 6',
//       'POBLACION 7',
//       'POBLACION 8',
//       'POBLACION 9',
//       'POBLACION 10',
//       'POBLACION 11',
//       'POBLACION 12',
//       'POBLACION 13',
//       'POBLACION 14',
//       'POOK',
//       'SEIRAN',
//       'TATLONG MARIA',
//       'TIERRA ALTA',
//       'TULO',
//     ],
//   },
//   TALISAY: {
//     province: 'CAMARINES NORTE',
//     municipality: 'TALISAY',
//     region: 'REGION V',
//     barangay_list: [
//       'BINANUAAN',
//       'CAAWIGAN',
//       'CAHABAAN',
//       'CALINTAAN',
//       'DEL CARMEN',
//       'GABON',
//       'ITOMANG',
//       'POBLACION',
//       'SAN FRANCISCO',
//       'SAN ISIDRO',
//       'SAN JOSE',
//       'SAN NICOLAS',
//       'SANTA CRUZ',
//       'SANTA ELENA',
//       'SANTO NIÑO',
//     ],
//   },
//   'TANAUAN': {
//     province: 'BATANGAS',
//     municipality: 'TANAUAN',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'ALTURA BATA',
//       'ALTURA MATANDA',
//       'ALTURA-SOUTH',
//       'AMBULONG',
//       'BAGBAG',
//       'BAGUMBAYAN',
//       'BALELE',
//       'BANADERO',
//       'BANJO EAST',
//       'BANJO LAUREL (BANJO WEST)',
//       'BILOG-BILOG',
//       'BOOT',
//       'CALE',
//       'DARASA',
//       'GONZALES',
//       'HIDALGO',
//       'JANOPOL',
//       'JANOPOL ORIENTAL',
//       'LAUREL',
//       'LUYOS',
//       'MABINI',
//       'MALAKING PULO',
//       'MARIA PAZ',
//       'MAUGAT',
//       'MONTAÑA (IK-IK)',
//       'NATATAS',
//       'PAGASPAS',
//       'PANTAY BATA',
//       'PANTAY MATANDA',
//       'POBLACION BARANGAY 1',
//       'POBLACION BARANGAY 2',
//       'POBLACION BARANGAY 3',
//       'POBLACION BARANGAY 4',
//       'POBLACION BARANGAY 5',
//       'POBLACION BARANGAY 6',
//       'POBLACION BARANGAY 7',
//       'SALA',
//       'SAMBAT',
//       'SAN JOSE',
//       'SANTOL (DOÑA JACOBA GARCIA)',
//       'SANTOR',
//       'SULPOC',
//       'SUPLANG',
//       'TALAGA',
//       'TINURIK',
//       'TRAPICHE',
//       'ULANGO',
//       'WAWA',
//     ],
//   },
//   TAYSAN: {
//     province: 'BATANGAS',
//     municipality: 'TAYSAN',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'BACAO',
//       'BILOGO',
//       'BUKAL',
//       'DAGATAN',
//       'GUINHAWA',
//       'LAUREL',
//       'MABAYABAS',
//       'MAHANADIONG',
//       'MAPULO',
//       'MATAAS NA LUPA',
//       'PAG-ASA',
//       'PANGHAYAAN',
//       'PINAGBAYANAN',
//       'PIÑA',
//       'POBLACION EAST',
//       'POBLACION WEST',
//       'SAN ISIDRO',
//       'SAN MARCELINO',
//       'SANTO NIÑO',
//       'TILAMBO',
//     ],
//   },
//   TINGLOY: {
//     province: 'BATANGAS',
//     municipality: 'TINGLOY',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'BARANGAY 13 (POBLACION 1)',
//       'BARANGAY 14 (POBLACION 2)',
//       'BARANGAY 15 (POBLACION 3)',
//       'CORONA',
//       'GAMAO',
//       'MAKAWAYAN',
//       'MARIKABAN',
//       'PAPAYA',
//       'PISA',
//       'SAN ISIDRO',
//       'SAN JOSE',
//       'SAN JUAN',
//       'SAN PEDRO',
//       'SANTO TOMAS',
//       'TALAHIB',
//     ],
//   },
//   TUY: {
//     province: 'BATANGAS',
//     municipality: 'TUY',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'ACLE',
//       'BAYUDBUD',
//       'BOLBOC',
//       'BURGOS (POB.)',
//       'DALIMA',
//       'DAO',
//       'GUINHAWA',
//       'LUMBANGAN',
//       'LUNA (POB.)',
//       'LUNTAL',
//       'MAGAHIS',
//       'MALIBU',
//       'MATAYWANAC',
//       'PALINCARO',
//       'PUTOL',
//       'RILLO (POB.)',
//       'RIZAL (POB.)',
//       'SABANG',
//       'SAN JOSE',
//       'TALON',
//       'TOONG',
//       'TUYON-TUYON',
//     ],
//   },
//   ALFONSO: {
//     province: 'CAVITE',
//     municipality: 'ALFONSO',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'AMUYONG',
//       'BARANGAY I (POB.)',
//       'BARANGAY II (POB.)',
//       'BARANGAY III (POB.)',
//       'BARANGAY IV (POB.)',
//       'BARANGAY V (POB.)',
//       'BILOG',
//       'BUCK ESTATE',
//       'ESPERANZA IBABA',
//       'ESPERANZA ILAYA',
//       'KAYSUYO',
//       'KAYTITINGA I',
//       'KAYTITINGA II',
//       'KAYTITINGA III',
//       'LUKSUHIN',
//       'LUKSUHIN ILAYA',
//       'MANGAS I',
//       'MANGAS II',
//       'MARAHAN I',
//       'MARAHAN II',
//       'MATAGBAK I',
//       'MATAGBAK II',
//       'PAJO',
//       'PALUMLUM',
//       'SANTA TERESA',
//       'SIKAT',
//       'SINALIW MALAKI',
//       'SINALIW NA MUNTI',
//       'SULSUGIN',
//       'TAYWANAK IBABA',
//       'TAYWANAK ILAYA',
//       'UPLI',
//     ],
//   },
//   AMADEO: {
//     province: 'CAVITE',
//     municipality: 'AMADEO',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'BANAYBANAY',
//       'BARANGAY I (POB.)',
//       'BARANGAY II (POB.)',
//       'BARANGAY III (POB.)',
//       'BARANGAY IV (POB.)',
//       'BARANGAY IX (POB.)',
//       'BARANGAY V (POB.)',
//       'BARANGAY VI (POB.)',
//       'BARANGAY VII (POB.)',
//       'BARANGAY VIII (POB.)',
//       'BARANGAY X (POB.)',
//       'BARANGAY XI (POB.)',
//       'BARANGAY XII (POB.)',
//       'BUCAL',
//       'BUHO',
//       'DAGATAN',
//       'HALANG',
//       'LOMA',
//       'MAITIM I',
//       'MAYMANGGA',
//       'MINANTOK KANLURAN',
//       'MINANTOK SILANGAN',
//       'PANGIL',
//       'SALABAN',
//       'TALON',
//       'TAMACAN',
//     ],
//   },
//   'BACOOR': {
//     province: 'CAVITE',
//     municipality: 'BACOOR',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'ALIMA',
//       'ANIBAN I',
//       'ANIBAN II',
//       'ANIBAN III',
//       'ANIBAN IV',
//       'ANIBAN V',
//       'BANALO',
//       'BAYANAN',
//       'CAMPO SANTO',
//       'DAANG BUKID',
//       'DIGMAN',
//       'DULONG BAYAN',
//       'HABAY I',
//       'HABAY II',
//       'KAINGIN (POB.)',
//       'LIGAS I',
//       'LIGAS II',
//       'LIGAS III',
//       'MABOLO I',
//       'MABOLO II',
//       'MABOLO III',
//       'MALIKSI I',
//       'MALIKSI II',
//       'MALIKSI III',
//       'MAMBOG I',
//       'MAMBOG II',
//       'MAMBOG III',
//       'MAMBOG IV',
//       'MAMBOG V',
//       'MOLINO I',
//       'MOLINO II',
//       'MOLINO III',
//       'MOLINO IV',
//       'MOLINO V',
//       'MOLINO VI',
//       'MOLINO VII',
//       'NIOG I',
//       'NIOG II',
//       'NIOG III',
//       'P.F. ESPIRITU I (PANAPAAN)',
//       'P.F. ESPIRITU II',
//       'P.F. ESPIRITU III',
//       'P.F. ESPIRITU IV',
//       'P.F. ESPIRITU V',
//       'P.F. ESPIRITU VI',
//       'P.F. ESPIRITU VII',
//       'P.F. ESPIRITU VIII',
//       'QUEENS ROW CENTRAL',
//       'QUEENS ROW EAST',
//       'QUEENS ROW WEST',
//       'REAL I',
//       'REAL II',
//       'SALINAS I',
//       'SALINAS II',
//       'SALINAS III',
//       'SALINAS IV',
//       'SAN NICOLAS I',
//       'SAN NICOLAS II',
//       'SAN NICOLAS III',
//       'SINEGUELASAN',
//       'TABING DAGAT',
//       'TALABA I',
//       'TALABA II',
//       'TALABA III',
//       'TALABA IV',
//       'TALABA V',
//       'TALABA VI',
//       'TALABA VII',
//       'ZAPOTE I',
//       'ZAPOTE II',
//       'ZAPOTE III',
//       'ZAPOTE IV',
//       'ZAPOTE V',
//     ],
//   },
//   CARMONA: {
//     province: 'CAVITE',
//     municipality: 'CARMONA',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'BANCAL',
//       'BARANGAY 1 (POB.)',
//       'BARANGAY 2 (POB.)',
//       'BARANGAY 3 (POB.)',
//       'BARANGAY 4 (POB.)',
//       'BARANGAY 5 (POB.)',
//       'BARANGAY 6 (POB.)',
//       'BARANGAY 7 (POB.)',
//       'BARANGAY 8 (POB.)',
//       'CABILANG BAYBAY',
//       'LANTIC',
//       'MABUHAY',
//       'MADUYA',
//       'MILAGROSA',
//     ],
//   },
//   'CAVITE': {
//     province: 'CAVITE',
//     municipality: 'CAVITE',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'BARANGAY 1 (HEN. M. ALVAREZ)',
//       'BARANGAY 2 (C. TIRONA)',
//       'BARANGAY 3 (HEN. E. AGUINALDO)',
//       'BARANGAY 4 (HEN. M. TRIAS)',
//       'BARANGAY 5 (HEN. E. EVANGELISTA)',
//       'BARANGAY 6 (DIEGO SILANG)',
//       'BARANGAY 7 (KAPITAN KONG)',
//       'BARANGAY 8 (MANUEL S. ROJAS)',
//       'BARANGAY 9 (KANAWAY)',
//       'BARANGAY 10 (KINGFISHER)',
//       'BARANGAY 10-A (KINGFISHER-A)',
//       'BARANGAY 10-B (KINGFISHER-B)',
//       'BARANGAY 11 (LAWIN)',
//       'BARANGAY 12 (LOVE BIRD)',
//       'BARANGAY 13 (AGUILA)',
//       'BARANGAY 14 (LORO)',
//       'BARANGAY 15 (KILYAWAN)',
//       'BARANGAY 16 (MARTINES)',
//       'BARANGAY 17 (KALAPATI)',
//       'BARANGAY 18 (MAYA)',
//       'BARANGAY 19 (GEMINI)',
//       'BARANGAY 20 (VIRGO)',
//       'BARANGAY 21 (SCORPIO)',
//       'BARANGAY 22 (LEO)',
//       'BARANGAY 22-A (LEO A)',
//       'BARANGAY 23 (AQUARIUS)',
//       'BARANGAY 24 (LIBRA)',
//       'BARANGAY 25 (CAPRICORN)',
//       'BARANGAY 26 (CANCER)',
//       'BARANGAY 27 (SAGITARIUS)',
//       'BARANGAY 28 (TAURUS)',
//       'BARANGAY 29 (LAO-LAO)',
//       'BARANGAY 29-A (LAO-LAO A)',
//       'BARANGAY 30 (BID-BID)',
//       'BARANGAY 31 (MAYA-MAYA)',
//       'BARANGAY 32 (SALAY-SALAY)',
//       'BARANGAY 33 (BUWAN-BUWAN)',
//       'BARANGAY 34 (LAPU-LAPU)',
//       'BARANGAY 35 (HASA-HASA)',
//       'BARANGAY 36 (SAP-SAP)',
//       'BARANGAY 36-A (SAP-SAP A)',
//       'BARANGAY 37 (CADENA DE AMOR)',
//       'BARANGAY 37-A (CADENA DE AMOR A)',
//       'BARANGAY 38 (SAMPAGUITA)',
//       'BARANGAY 38-A (SAMPAGUITA A)',
//       'BARANGAY 39 (JASMIN)',
//       'BARANGAY 40 (GUMAMELA)',
//       'BARANGAY 41 (ROSAL)',
//       'BARANGAY 42 (PINAGBUKLOD)',
//       'BARANGAY 42-A (PINAGBUKLOD A)',
//       'BARANGAY 42-B (PINAGBUKLOD B)',
//       'BARANGAY 42-C (PINAGBUKLOD C)',
//       'BARANGAY 43 (PINAGPALA)',
//       'BARANGAY 44 (MALIGAYA)',
//       'BARANGAY 45 (KAUNLARAN)',
//       'BARANGAY 45-A (KAUNLARAN A)',
//       'BARANGAY 46 (SINAGTALA)',
//       'BARANGAY 47 (PAGKAKAISA)',
//       'BARANGAY 47-A (PAGKAKAISA A)',
//       'BARANGAY 47-B (PAGKAKAISA B)',
//       'BARANGAY 48 (NARRA)',
//       'BARANGAY 48-A (NARRA A)',
//       'BARANGAY 49 (AKASYA)',
//       'BARANGAY 49-A (AKASYA A)',
//       'BARANGAY 50 (KABALYERO)',
//       'BARANGAY 51 (KAMAGONG)',
//       'BARANGAY 52 (IPIL)',
//       'BARANGAY 53 (YAKAL)',
//       'BARANGAY 53-A (YAKAL A)',
//       'BARANGAY 53-B (YAKAL B)',
//       'BARANGAY 54 (PECHAY)',
//       'BARANGAY 54-A (PECHAY A)',
//       'BARANGAY 55 (AMPALAYA)',
//       'BARANGAY 56 (LABANOS)',
//       'BARANGAY 57 (REPOLYO)',
//       'BARANGAY 58 (PATOLA)',
//       'BARANGAY 58-A (PATOLA A)',
//       'BARANGAY 59 (SITAW)',
//       'BARANGAY 60 (LETSUGAS)',
//       'BARANGAY 61 (TALONG)',
//       'BARANGAY 61-A (TALONG A)',
//       'BARANGAY 62 (KANGKONG)',
//       'BARANGAY 62-A (KANGKONG A)',
//       'BARANGAY 62-B (KANGKONG B)',
//     ],
//   },
//   'DASMARIÑAS': {
//     province: 'CAVITE',
//     municipality: 'DASMARIÑAS',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'BUROL',
//       'BUROL I',
//       'BUROL II',
//       'BUROL III',
//       'DATU ESMAEL (BAGO-A-INGUD)',
//       'EMMANUEL BERGADO I',
//       'EMMANUEL BERGADO II',
//       'FATIMA I',
//       'FATIMA II',
//       'FATIMA III',
//       'H-2',
//       'LANGKAAN I',
//       'LANGKAAN II',
//       'LUZVIMINDA I',
//       'LUZVIMINDA II',
//       'PALIPARAN I',
//       'PALIPARAN II',
//       'PALIPARAN III',
//       'SABANG',
//       'SAINT PETER I',
//       'SAINT PETER II',
//       'SALAWAG',
//       'SALITRAN I',
//       'SALITRAN II',
//       'SALITRAN III',
//       'SALITRAN IV',
//       'SAMPALOC I',
//       'SAMPALOC II',
//       'SAMPALOC III',
//       'SAMPALOC IV',
//       'SAMPALOC V',
//       'SAN AGUSTIN I',
//       'SAN AGUSTIN II',
//       'SAN AGUSTIN III',
//       'SAN ANDRES I',
//       'SAN ANDRES II',
//       'SAN ANTONIO DE PADUA I',
//       'SAN ANTONIO DE PADUA II',
//       'SAN DIONISIO (BARANGAY 1)',
//       'SAN ESTEBAN (BARANGAY 4)',
//       'SAN FRANCISCO I',
//       'SAN FRANCISCO II',
//       'SAN ISIDRO LABRADOR I',
//       'SAN ISIDRO LABRADOR II',
//       'SAN JOSE',
//       'SAN JUAN (SAN JUAN I)',
//       'SAN LORENZO RUIZ I',
//       'SAN LORENZO RUIZ II',
//       'SAN LUIS I',
//       'SAN LUIS II',
//       'SAN MANUEL I',
//       'SAN MANUEL II',
//       'SAN MATEO',
//       'SAN MIGUEL',
//       'SAN MIGUEL II',
//       'SAN NICOLAS I',
//       'SAN NICOLAS II',
//       'SAN ROQUE (STA. CRISTINA II)',
//       'SAN SIMON (BARANGAY 7)',
//       'SANTA CRISTINA I',
//       'SANTA CRISTINA II',
//       'SANTA CRUZ I',
//       'SANTA CRUZ II',
//       'SANTA FE',
//       'SANTA LUCIA (SAN JUAN II)',
//       'SANTA MARIA (BARANGAY 20)',
//       'SANTO CRISTO (BARANGAY 3)',
//       'SANTO NIÑO I',
//       'SANTO NIÑO II',
//       'VICTORIA REYES',
//       'ZONE I (POB.)',
//       'ZONE I-B',
//       'ZONE II (POB.)',
//       'ZONE III (POB.)',
//       'ZONE IV (POB.)',
//     ],
//   },
//   'GEN. MARIANO ALVAREZ': {
//     province: 'CAVITE',
//     municipality: 'GEN. MARIANO ALVAREZ',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'ALDIANO OLAES',
//       'BARANGAY 1 POBLACION (AREA I)',
//       'BARANGAY 2 POBLACION',
//       'BARANGAY 3 POBLACION',
//       'BARANGAY 4 POBLACION',
//       'BARANGAY 5 POBLACION',
//       'BENJAMIN TIRONA (AREA D)',
//       'BERNARDO PULIDO (AREA H)',
//       'EPIFANIO MALIA',
//       'FIORELLO CARIMAG (AREA C)',
//       'FRANCISCO DE CASTRO (SUNSHINE VILL.)',
//       'FRANCISCO REYES',
//       'GAVINO MADERAN',
//       'GREGORIA DE JESUS',
//       'INOCENCIO SALUD',
//       'JACINTO LUMBRERAS',
//       'KAPITAN KUA (AREA F)',
//       'KORONEL JOSE P. ELISES (AREA E)',
//       'MACARIO DACON',
//       'MARCELINO MEMIJE',
//       'NICOLASA VIRATA (SAN JOSE)',
//       'PANTALEON GRANADOS (AREA G)',
//       'RAMON CRUZ (AREA J)',
//       'SAN GABRIEL (AREA K)',
//       'SAN JOSE',
//       'SEVERINO DE LAS ALAS',
//       'TINIENTE TIAGO',
//     ],
//   },
//   'GENERAL EMILIO AGUINALDO': {
//     province: 'CAVITE',
//     municipality: 'GENERAL EMILIO AGUINALDO',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'A. DALUSAG',
//       'BATAS DAO',
//       'CASTAÑOS CERCA',
//       'CASTAÑOS LEJOS',
//       'KABULUSAN',
//       'KAYMISAS',
//       'KAYPAABA',
//       'LUMIPA',
//       'NARVAEZ',
//       'POBLACION I',
//       'POBLACION II',
//       'POBLACION III',
//       'POBLACION IV',
//       'TABORA',
//     ],
//   },
//   'GENERAL TRIAS': {
//     province: 'CAVITE',
//     municipality: 'GENERAL TRIAS',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'ALINGARO',
//       'ARNALDO POB. (BGY. 7)',
//       'BACAO I',
//       'BACAO II',
//       'BAGUMBAYAN POB. (BGY. 5)',
//       'BICLATAN',
//       'BUENAVISTA I',
//       'BUENAVISTA II',
//       'BUENAVISTA III',
//       'CORREGIDOR POB. (BGY. 10)',
//       'DULONG BAYAN POB. (BGY. 3)',
//       'GOV. FERRER POB. (BGY. 1)',
//       'JAVALERA',
//       'MANGGAHAN',
//       'NAVARRO',
//       'NINETY SIXTH POB. (BGY. 8)',
//       'PANUNGYANAN',
//       'PASONG CAMACHILE I',
//       'PASONG CAMACHILE II',
//       'PASONG KAWAYAN I',
//       'PASONG KAWAYAN II',
//       'PINAGTIPUNAN',
//       'PRINZA POB. (BGY. 9)',
//       'SAMPALUCAN POB. (BGY. 2)',
//       'SAN FRANCISCO',
//       'SAN GABRIEL POB. (BGY. 4)',
//       'SAN JUAN I',
//       'SAN JUAN II',
//       'SANTA CLARA',
//       'SANTIAGO',
//       'TAPIA',
//       'TEJERO',
//       'VIBORA POB. (BGY. 6)',
//     ],
//   },
//   'IMUS': {
//     province: 'CAVITE',
//     municipality: 'IMUS',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'ALAPAN I-A',
//       'ALAPAN I-B',
//       'ALAPAN I-C',
//       'ALAPAN II-A',
//       'ALAPAN II-B',
//       'ANABU I-A',
//       'ANABU I-B',
//       'ANABU I-C',
//       'ANABU I-D',
//       'ANABU I-E',
//       'ANABU I-F',
//       'ANABU I-G',
//       'ANABU II-A',
//       'ANABU II-B',
//       'ANABU II-C',
//       'ANABU II-D',
//       'ANABU II-E',
//       'ANABU II-F',
//       'BAGONG SILANG (BAHAYANG PAG-ASA)',
//       'BAYAN LUMA I',
//       'BAYAN LUMA II',
//       'BAYAN LUMA III',
//       'BAYAN LUMA IV',
//       'BAYAN LUMA IX',
//       'BAYAN LUMA V',
//       'BAYAN LUMA VI',
//       'BAYAN LUMA VII',
//       'BAYAN LUMA VIII',
//       'BUCANDALA I',
//       'BUCANDALA II',
//       'BUCANDALA III',
//       'BUCANDALA IV',
//       'BUCANDALA V',
//       'BUHAY NA TUBIG',
//       'CARSADANG BAGO I',
//       'CARSADANG BAGO II',
//       'MAGDALO',
//       'MAHARLIKA',
//       'MALAGASANG I-A',
//       'MALAGASANG I-B',
//       'MALAGASANG I-C',
//       'MALAGASANG I-D',
//       'MALAGASANG I-E',
//       'MALAGASANG I-F',
//       'MALAGASANG I-G',
//       'MALAGASANG II-A',
//       'MALAGASANG II-B',
//       'MALAGASANG II-C',
//       'MALAGASANG II-D',
//       'MALAGASANG II-E',
//       'MALAGASANG II-F',
//       'MALAGASANG II-G',
//       'MARIANO ESPELETA I',
//       'MARIANO ESPELETA II',
//       'MARIANO ESPELETA III',
//       'MEDICION I-A',
//       'MEDICION I-B',
//       'MEDICION I-C',
//       'MEDICION I-D',
//       'MEDICION II-A',
//       'MEDICION II-B',
//       'MEDICION II-C',
//       'MEDICION II-D',
//       'MEDICION II-E',
//       'MEDICION II-F',
//       'PAG-ASA I',
//       'PAG-ASA II',
//       'PAG-ASA III',
//       'PALICO I',
//       'PALICO II',
//       'PALICO III',
//       'PALICO IV',
//       'PASONG BUAYA I',
//       'PASONG BUAYA II',
//       'PINAGBUKLOD',
//       'POBLACION I-A (POB.)',
//       'POBLACION I-B',
//       'POBLACION I-C',
//       'POBLACION II-A (POB.)',
//       'POBLACION II-B',
//       'POBLACION III-A (POB.)',
//       'POBLACION III-B',
//       'POBLACION IV-A (POB.)',
//       'POBLACION IV-B',
//       'POBLACION IV-C',
//       'POBLACION IV-D',
//       'TANZANG LUMA I',
//       'TANZANG LUMA II',
//       'TANZANG LUMA III',
//       'TANZANG LUMA IV (SOUTHERN CITY)',
//       'TANZANG LUMA V',
//       'TANZANG LUMA VI',
//       'TOCLONG I-A',
//       'TOCLONG I-B',
//       'TOCLONG I-C',
//       'TOCLONG II-A',
//       'TOCLONG II-B',
//     ],
//   },
//   INDANG: {
//     province: 'CAVITE',
//     municipality: 'INDANG',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'AGUS-US',
//       'ALULOD',
//       'BANABA CERCA',
//       'BANABA LEJOS',
//       'BANCOD',
//       'BARANGAY 1 (POB.)',
//       'BARANGAY 2 (POB.)',
//       'BARANGAY 3 (POB.)',
//       'BARANGAY 4 (POB.)',
//       'BUNA CERCA',
//       'BUNA LEJOS I',
//       'BUNA LEJOS II',
//       'CALUMPANG CERCA',
//       'CALUMPANG LEJOS I',
//       'CARASUCHI',
//       'DAINE I',
//       'DAINE II',
//       'GUYAM MALAKI',
//       'GUYAM MUNTI',
//       'HARASAN',
//       'KAYQUIT I',
//       'KAYQUIT II',
//       'KAYQUIT III',
//       'KAYTAMBOG',
//       'KAYTAPOS',
//       'LIMBON',
//       'LUMAMPONG BALAGBAG',
//       'LUMAMPONG HALAYHAY',
//       'MAHABANGKAHOY CERCA',
//       'MAHABANGKAHOY LEJOS',
//       'MATAAS NA LUPA (CHECKPOINT)',
//       'PULO',
//       'TAMBO BALAGBAG',
//       'TAMBO ILAYA',
//       'TAMBO KULIT',
//       'TAMBO MALAKI',
//     ],
//   },
//   KAWIT: {
//     province: 'CAVITE',
//     municipality: 'KAWIT',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'BALSAHAN-BISITA',
//       'BATONG DALIG',
//       'BINAKAYAN-APLAYA',
//       'BINAKAYAN-KANLURAN',
//       'CONGBALAY-LEGASPI',
//       'GAHAK',
//       'KAINGEN',
//       'MAGDALO (PUTOL)',
//       'MANGGAHAN-LAWIN',
//       'MARULAS',
//       'PANAMITAN',
//       'POBLACION',
//       'PULVORISTA',
//       'SAMALA-MARQUEZ',
//       'SAN SEBASTIAN',
//       'SANTA ISABEL',
//       'TABON I',
//       'TABON II',
//       'TABON III',
//       'TOCLONG',
//       'TRAMO-BANTAYAN',
//       'WAKAS I',
//       'WAKAS II',
//     ],
//   },
//   MARAGONDON: {
//     province: 'CAVITE',
//     municipality: 'MARAGONDON',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'BUCAL I',
//       'BUCAL II',
//       'BUCAL III A',
//       'BUCAL III B',
//       'BUCAL IV A',
//       'BUCAL IV B',
//       'CAINGIN POB. (BARANGAY 3)',
//       'GARITA I A',
//       'GARITA I B',
//       'LAYONG MABILOG',
//       'MABATO',
//       'PANTIHAN I (BALAYUNGAN)',
//       'PANTIHAN II (SAGBAT)',
//       'PANTIHAN III (POOK NA MUNTI)',
//       'PANTIHAN IV (POOK NI SARA)',
//       'PATUNGAN',
//       'PINAGSANHAN I A',
//       'PINAGSANHAN I B',
//       'POBLACION I A',
//       'POBLACION I B',
//       'POBLACION II A',
//       'POBLACION II B',
//       'SAN MIGUEL I A (CAPUTATAN)',
//       'SAN MIGUEL I B',
//       'TALIPUSNGO',
//       'TULAY KANLURAN',
//       'TULAY SILANGAN',
//     ],
//   },
//   'MENDEZ (MENDEZ-NUÑEZ)': {
//     province: 'CAVITE',
//     municipality: 'MENDEZ (MENDEZ-NUÑEZ)',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'ANULING CERCA I',
//       'ANULING CERCA II',
//       'ANULING LEJOS I (ANULING)',
//       'ANULING LEJOS II',
//       'ASIS I',
//       'ASIS II',
//       'ASIS III',
//       'BANAYAD',
//       'BUKAL',
//       'GALICIA I',
//       'GALICIA II',
//       'GALICIA III',
//       'MIGUEL MOJICA',
//       'PALOCPOC I',
//       'PALOCPOC II',
//       'PANUNGYAN I',
//       'PANUNGYAN II',
//       'POBLACION I (BARANGAY I)',
//       'POBLACION II (BARANGAY II)',
//       'POBLACION III (BARANGAY III)',
//       'POBLACION IV (BARANGAY IV)',
//       'POBLACION V (BARANGAY V)',
//       'POBLACION VI (BARANGAY VI)',
//       'POBLACION VII (BARANGAY VII)',
//     ],
//   },
//   NAIC: {
//     province: 'CAVITE',
//     municipality: 'NAIC',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'BAGONG KARSADA',
//       'BALSAHAN',
//       'BANCAAN',
//       'BUCANA MALAKI',
//       'BUCANA SASAHAN',
//       'CALUBCOB',
//       'CAPT. C. NAZARENO (POB.)',
//       'GOMEZ-ZAMORA (POB.)',
//       'HALANG',
//       'HUMBAC',
//       'IBAYO ESTACION',
//       'IBAYO SILANGAN',
//       'KANLURAN',
//       'LABAC',
//       'LATORIA',
//       'MABOLO',
//       'MAKINA',
//       'MALAINEN BAGO',
//       'MALAINEN LUMA',
//       'MOLINO',
//       'MUNTING MAPINO',
//       'MUZON',
//       'PALANGUE 1',
//       'PALANGUE 2 AND 3',
//       'SABANG',
//       'SAN ROQUE',
//       'SANTULAN',
//       'SAPA',
//       'TIMALAN BALSAHAN',
//       'TIMALAN CONCEPCION',
//     ],
//   },
//   NOVELETA: {
//     province: 'CAVITE',
//     municipality: 'NOVELETA',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'MAGDIWANG',
//       'POBLACION',
//       'SALCEDO I',
//       'SALCEDO II',
//       'SAN ANTONIO I',
//       'SAN ANTONIO II',
//       'SAN JOSE I',
//       'SAN JOSE II',
//       'SAN JUAN I',
//       'SAN JUAN II',
//       'SAN RAFAEL I',
//       'SAN RAFAEL II',
//       'SAN RAFAEL III',
//       'SAN RAFAEL IV',
//       'SANTA ROSA I',
//       'SANTA ROSA II',
//     ],
//   },
//   SILANG: {
//     province: 'CAVITE',
//     municipality: 'SILANG',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'ACACIA',
//       'ADLAS',
//       'ANAHAW I',
//       'ANAHAW II',
//       'BALITE I',
//       'BALITE II',
//       'BALUBAD',
//       'BANABA',
//       'BARANGAY I (POB.)',
//       'BARANGAY II (POB.)',
//       'BARANGAY III (POB.)',
//       'BARANGAY IV (POB.)',
//       'BARANGAY V (POB.)',
//       'BATAS',
//       'BIGA I',
//       'BIGA II',
//       'BILUSO',
//       'BUCAL',
//       'BUHO',
//       'BULIHAN',
//       'CABANGAAN',
//       'CARMEN',
//       'HOYO',
//       'HUKAY',
//       'IBA',
//       'INCHICAN',
//       'IPIL I',
//       'IPIL II',
//       'KALUBKOB',
//       'KAONG',
//       'LALAAN I',
//       'LALAAN II',
//       'LITLIT',
//       'LUCSUHIN',
//       'LUMIL',
//       'MAGUYAM',
//       'MALABAG',
//       'MALAKING TATYAO',
//       'MATAAS NA BUROL',
//       'MUNTING ILOG',
//       'NARRA I',
//       'NARRA II',
//       'NARRA III',
//       'PALIGAWAN',
//       'PASONG LANGKA',
//       'POOC I',
//       'POOC II',
//       'PULONG BUNGA',
//       'PULONG SAGING',
//       'PUTING KAHOY',
//       'SABUTAN',
//       'SAN MIGUEL I',
//       'SAN MIGUEL II',
//       'SAN VICENTE I',
//       'SAN VICENTE II',
//       'SANTOL',
//       'TARTARIA',
//       'TIBIG',
//       'TOLEDO',
//       'TUBUAN I',
//       'TUBUAN II',
//       'TUBUAN III',
//       'ULAT',
//       'YAKAL',
//     ],
//   },
//   'TAGAYTAY': {
//     province: 'CAVITE',
//     municipality: 'TAGAYTAY',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'ASISAN',
//       'BAGONG TUBIG',
//       'CALABUSO',
//       'DAPDAP EAST',
//       'DAPDAP WEST',
//       'FRANCISCO (SAN FRANCISCO)',
//       'GUINHAWA NORTH',
//       'GUINHAWA SOUTH',
//       'IRUHIN EAST',
//       'IRUHIN SOUTH',
//       'IRUHIN WEST',
//       'KAYBAGAL EAST',
//       'KAYBAGAL NORTH',
//       'KAYBAGAL SOUTH (POB.)',
//       'MAG-ASAWANG ILAT',
//       'MAHARLIKA EAST',
//       'MAHARLIKA WEST',
//       'MAITIM 2ND CENTRAL',
//       'MAITIM 2ND EAST',
//       'MAITIM 2ND WEST',
//       'MENDEZ CROSSING EAST',
//       'MENDEZ CROSSING WEST',
//       'NEOGAN',
//       'PATUTONG MALAKI NORTH',
//       'PATUTONG MALAKI SOUTH',
//       'SAMBONG',
//       'SAN JOSE',
//       'SILANG JUNCTION NORTH',
//       'SILANG JUNCTION SOUTH',
//       'SUNGAY NORTH',
//       'SUNGAY SOUTH',
//       'TOLENTINO EAST',
//       'TOLENTINO WEST',
//       'ZAMBAL',
//     ],
//   },
//   TANZA: {
//     province: 'CAVITE',
//     municipality: 'TANZA',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'AMAYA I',
//       'AMAYA II',
//       'AMAYA III',
//       'AMAYA IV',
//       'AMAYA V',
//       'AMAYA VI',
//       'AMAYA VII',
//       'BAGTAS',
//       'BARANGAY I (POB.)',
//       'BARANGAY II (POB.)',
//       'BARANGAY III (POB.)',
//       'BARANGAY IV (POB.)',
//       'BIGA',
//       'BIWAS',
//       'BUCAL',
//       'BUNGA',
//       'CALIBUYO',
//       'CAPIPISA',
//       'DAANG AMAYA I',
//       'DAANG AMAYA II',
//       'DAANG AMAYA III',
//       'HALAYHAY',
//       'JULUGAN I',
//       'JULUGAN II',
//       'JULUGAN III',
//       'JULUGAN IV',
//       'JULUGAN V',
//       'JULUGAN VI',
//       'JULUGAN VII',
//       'JULUGAN VIII',
//       'LAMBINGAN',
//       'MULAWIN',
//       'PARADAHAN I',
//       'PARADAHAN II',
//       'PUNTA I',
//       'PUNTA II',
//       'SAHUD ULAN',
//       'SANJA MAYOR',
//       'SANTOL',
//       'TANAUAN',
//       'TRES CRUSES',
//     ],
//   },
//   TERNATE: {
//     province: 'CAVITE',
//     municipality: 'TERNATE',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'BUCANA',
//       'POBLACION I (BARANGAY I)',
//       'POBLACION I A',
//       'POBLACION II (BARANGAY II)',
//       'POBLACION III (BARANGAY III)',
//       'SAN JOSE',
//       'SAN JUAN I',
//       'SAN JUAN II',
//       'SAPANG I',
//       'SAPANG II',
//     ],
//   },
//   'TRECE MARTIRES': {
//     province: 'CAVITE',
//     municipality: 'TRECE MARTIRES',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'AGUADO (PISCAL MUNDO)',
//       'CABEZAS',
//       'CABUCO',
//       'CONCHU (LAGUNDIAN)',
//       'DE OCAMPO',
//       'GREGORIO (ALIANG)',
//       'INOCENCIO (B. POOK)',
//       'LALLANA',
//       'LAPIDARIO (BAYOG)',
//       'LUCIANO (BITANGAN)',
//       'OSORIO',
//       'PEREZ (LUCBANAN)',
//       'SAN AGUSTIN (POB.)',
//     ],
//   },
//   ALAMINOS: {
//     province: 'LAGUNA',
//     municipality: 'ALAMINOS',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'BARANGAY I (POB.)',
//       'BARANGAY II (POB.)',
//       'BARANGAY III (POB.)',
//       'BARANGAY IV (POB.)',
//       'DEL CARMEN',
//       'PALMA',
//       'SAN AGUSTIN',
//       'SAN ANDRES',
//       'SAN BENITO',
//       'SAN GREGORIO',
//       'SAN ILDEFONSO',
//       'SAN JUAN',
//       'SAN MIGUEL',
//       'SAN ROQUE',
//       'SANTA ROSA',
//     ],
//   },
//   BAY: {
//     province: 'LAGUNA',
//     municipality: 'BAY',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'BITIN',
//       'CALO',
//       'DILA',
//       'MAITIM',
//       'MASAYA',
//       'PACIANO RIZAL',
//       'PUYPUY',
//       'SAN AGUSTIN (POB.)',
//       'SAN ANTONIO',
//       'SAN ISIDRO',
//       'SAN NICOLAS (POB.)',
//       'SANTA CRUZ',
//       'SANTO DOMINGO',
//       'TAGUMPAY',
//       'TRANCA',
//     ],
//   },
//   'CABUYAO': {
//     province: 'LAGUNA',
//     municipality: 'CABUYAO',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'BACLARAN',
//       'BANAYBANAY',
//       'BANLIC',
//       'BARANGAY DOS (POB.)',
//       'BARANGAY TRES (POB.)',
//       'BARANGAY UNO (POB.)',
//       'BIGAA',
//       'BUTONG',
//       'CASILE',
//       'DIEZMO',
//       'GULOD',
//       'MAMATID',
//       'MARINIG',
//       'NIUGAN',
//       'PITTLAND',
//       'PULO',
//       'SALA',
//       'SAN ISIDRO',
//     ],
//   },
//   'CALAMBA': {
//     province: 'LAGUNA',
//     municipality: 'CALAMBA',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'BAGONG KALSADA',
//       'BANADERO',
//       'BANLIC',
//       'BARANDAL',
//       'BARANGAY 1 (POB.)',
//       'BARANGAY 2 (POB.)',
//       'BARANGAY 3 (POB.)',
//       'BARANGAY 4 (POB.)',
//       'BARANGAY 5 (POB.)',
//       'BARANGAY 6 (POB.)',
//       'BARANGAY 7 (POB.)',
//       'BATINO',
//       'BUBUYAN',
//       'BUCAL',
//       'BUNGGO',
//       'BUROL',
//       'CAMALIGAN',
//       'CANLUBANG',
//       'HALANG',
//       'HORNALAN',
//       'KAY-ANLOG',
//       'LA MESA',
//       'LAGUERTA',
//       'LAWA',
//       'LECHERIA',
//       'LINGGA',
//       'LOOC',
//       'MABATO',
//       'MAJADA LABAS',
//       'MAKILING',
//       'MAPAGONG',
//       'MASILI',
//       'MAUNONG',
//       'MAYAPA',
//       'MILAGROSA (TULO)',
//       'PACIANO RIZAL',
//       'PALINGON',
//       'PALO-ALTO',
//       'PANSOL',
//       'PARIAN',
//       'PRINZA',
//       'PUNTA',
//       'PUTING LUPA',
//       'REAL',
//       'SAIMSIM',
//       'SAMPIRUHAN',
//       'SAN CRISTOBAL',
//       'SAN JOSE',
//       'SAN JUAN',
//       'SIRANG LUPA',
//       'SUCOL',
//       'TURBINA',
//       'ULANGO',
//       'UWISAN',
//     ],
//   },
//   CALAUAN: {
//     province: 'LAGUNA',
//     municipality: 'CALAUAN',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'BALAYHANGIN',
//       'BANGYAS',
//       'DAYAP',
//       'HANGGAN',
//       'IMOK',
//       'KANLURAN (POB.)',
//       'LAMOT 1',
//       'LAMOT 2',
//       'LIMAO',
//       'MABACAN',
//       'MASIIT',
//       'PALIPARAN',
//       'PEREZ',
//       'PRINZA',
//       'SAN ISIDRO',
//       'SANTO TOMAS',
//       'SILANGAN (POB.)',
//     ],
//   },
//   CAVINTI: {
//     province: 'LAGUNA',
//     municipality: 'CAVINTI',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'ANGLAS',
//       'BANGCO',
//       'BUKAL',
//       'BULAJO',
//       'CANSUSO',
//       'DUHAT',
//       'INAO-AWAN',
//       'KANLURAN TALAONGAN',
//       'LABAYO',
//       'LAYASIN',
//       'LAYUG',
//       'MAHIPON',
//       'PAOWIN',
//       'POBLACION',
//       'SILANGAN TALAONGAN',
//       'SISILMIN',
//       'SUMUCAB',
//       'TIBATIB',
//       'UDIA',
//     ],
//   },
//   'BIÑAN': {
//     province: 'LAGUNA',
//     municipality: 'BIÑAN',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'BIÑAN (POBLACION)',
//       'BUNGAHAN',
//       'CANLALAY',
//       'CASILE',
//       'DE LA PAZ',
//       'GANADO',
//       'LANGKIWA',
//       'LOMA',
//       'MALABAN',
//       'MALAMIG',
//       'MAMPALASAN',
//       'PLATERO',
//       'POBLACION',
//       'SAN ANTONIO',
//       'SAN FRANCISCO (HALANG)',
//       'SAN JOSE',
//       'SAN VICENTE',
//       'SANTO DOMINGO',
//       'SANTO NIÑO',
//       'SANTO TOMAS (CALABUSO)',
//       'SORO-SORO',
//       'TIMBAO',
//       'TUBIGAN',
//       'ZAPOTE',
//     ],
//   },
//   FAMY: {
//     province: 'LAGUNA',
//     municipality: 'FAMY',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'ASANA (POB.)',
//       'BACONG-SIGSIGAN',
//       'BAGONG PAG-ASA (POB.)',
//       'BALITOC',
//       'BANABA (POB.)',
//       'BATUHAN',
//       'BULIHAN',
//       'CABALLERO (POB.)',
//       'CALUMPANG (POB.)',
//       'CUEBANG BATO',
//       'DAMAYAN (POB.)',
//       'KAPATALAN',
//       'KATAYPUANAN',
//       'LIYANG',
//       'MAATE',
//       'MAGDALO (POB.)',
//       'MAYATBA',
//       'MINAYUTAN',
//       'SALANGBATO',
//       'TUNHAC',
//     ],
//   },
//   KALAYAAN: {
//     province: 'PALAWAN',
//     municipality: 'KALAYAAN',
//     region: 'REGION IV-B',
//     barangay_list: ['PAG-ASA (POB.)'],
//   },
//   LILIW: {
//     province: 'LAGUNA',
//     municipality: 'LILIW',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'BAGONG ANYO (POB.)',
//       'BAYATE',
//       'BONGKOL',
//       'BUBUKAL',
//       'CABUYAO',
//       'CALUMPANG',
//       'CULOY',
//       'DAGATAN',
//       'DANIW (DANLIW)',
//       'DITA',
//       'IBABANG PALINA',
//       'IBABANG SAN ROQUE',
//       'IBABANG SUNGI',
//       'IBABANG TAYKIN',
//       'ILAYANG PALINA',
//       'ILAYANG SAN ROQUE',
//       'ILAYANG SUNGI',
//       'ILAYANG TAYKIN',
//       'KANLURANG BUKAL',
//       'LAGUAN',
//       'LUQUIN',
//       'MALABO-KALANTUKAN',
//       'MASIKAP (POB.)',
//       'MASLUN (POB.)',
//       'MOJON',
//       'NOVALICHES',
//       'OPLES',
//       'PAG-ASA (POB.)',
//       'PALAYAN',
//       'RIZAL (POB.)',
//       'SAN ISIDRO',
//       'SILANGANG BUKAL',
//       'TUY-BAANAN',
//     ],
//   },
//   'LOS BAÑOS': {
//     province: 'LAGUNA',
//     municipality: 'LOS BAÑOS',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'ANOS',
//       'BAGONG SILANG',
//       'BAMBANG',
//       'BATONG MALAKE',
//       'BAYBAYIN (POB.)',
//       'BAYOG',
//       'LALAKAY',
//       'MAAHAS',
//       'MALINTA',
//       'MAYONDON',
//       'PUTHO TUNTUNGIN',
//       'SAN ANTONIO',
//       'TADLAK',
//       'TIMUGAN (POB.)',
//     ],
//   },
//   LUISIANA: {
//     province: 'LAGUNA',
//     municipality: 'LUISIANA',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'BARANGAY ZONE I (POB.)',
//       'BARANGAY ZONE II (POB.)',
//       'BARANGAY ZONE III (POB.)',
//       'BARANGAY ZONE IV (POB.)',
//       'BARANGAY ZONE V (POB.)',
//       'BARANGAY ZONE VI (POB.)',
//       'BARANGAY ZONE VII (POB.)',
//       'BARANGAY ZONE VIII (POB.)',
//       'DE LA PAZ',
//       'SAN ANTONIO',
//       'SAN BUENAVENTURA',
//       'SAN DIEGO',
//       'SAN ISIDRO',
//       'SAN JOSE',
//       'SAN JUAN',
//       'SAN LUIS',
//       'SAN PABLO',
//       'SAN PEDRO',
//       'SAN RAFAEL',
//       'SAN ROQUE',
//       'SAN SALVADOR',
//       'SANTO DOMINGO',
//       'SANTO TOMAS',
//     ],
//   },
//   LUMBAN: {
//     province: 'LAGUNA',
//     municipality: 'LUMBAN',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'BAGONG SILANG',
//       'BALIMBINGAN (POB.)',
//       'BALUBAD',
//       'CALIRAYA',
//       'CONCEPCION',
//       'LEWIN',
//       'MARACTA (POB.)',
//       'MAYTALANG I',
//       'MAYTALANG II',
//       'PRIMERA PARANG (POB.)',
//       'PRIMERA PULO (POB.)',
//       'SALAC (POB.)',
//       'SANTO NIÑO (POB.)',
//       'SEGUNDA PARANG (POB.)',
//       'SEGUNDA PULO (POB.)',
//       'WAWA',
//     ],
//   },
//   MABITAC: {
//     province: 'LAGUNA',
//     municipality: 'MABITAC',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'AMUYONG',
//       'BAYANIHAN (POB.)',
//       'LAMBAC (POB.)',
//       'LIBIS NG NAYON (POB.)',
//       'LUCONG',
//       'MALIGAYA (POB.)',
//       'MASIKAP (POB.)',
//       'MATALATALA',
//       'NANGUMA',
//       'NUMERO',
//       'PAAGAHAN',
//       'PAG-ASA (POB.)',
//       'SAN ANTONIO',
//       'SAN MIGUEL',
//       'SINAGTALA (POB.)',
//     ],
//   },
//   MAGDALENA: {
//     province: 'LAGUNA',
//     municipality: 'MAGDALENA',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'ALIPIT',
//       'BAANAN',
//       'BALANAC',
//       'BUCAL',
//       'BUENAVISTA',
//       'BUNGKOL',
//       'BUO',
//       'BURLUNGAN',
//       'CIGARAS',
//       'HALAYHAYIN',
//       'IBABANG ATINGAY',
//       'IBABANG BUTNONG',
//       'ILAYANG ATINGAY',
//       'ILAYANG BUTNONG',
//       'ILOG',
//       'MALAKING AMBLING',
//       'MALINAO',
//       'MARAVILLA',
//       'MUNTING AMBLING',
//       'POBLACION',
//       'SABANG',
//       'SALASAD',
//       'TANAWAN',
//       'TIPUNAN',
//     ],
//   },
//   MAJAYJAY: {
//     province: 'LAGUNA',
//     municipality: 'MAJAYJAY',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'AMONOY',
//       'BAKIA',
//       'BALANAC',
//       'BALAYONG',
//       'BANILAD',
//       'BANTI',
//       'BITAOY',
//       'BOTOCAN',
//       'BUKAL',
//       'BURGOS',
//       'BUROL',
//       'CORALAO',
//       'GAGALOT',
//       'IBABANG BANGA',
//       'IBABANG BAYUCAIN',
//       'ILAYANG BANGA',
//       'ILAYANG BAYUCAIN',
//       'ISABANG',
//       'MALINAO',
//       'MAY-IT',
//       'MUNTING KAWAYAN',
//       'OLLA',
//       'OOBI',
//       'ORIGUEL (POB.)',
//       'PANALABAN',
//       'PANGIL',
//       'PANGLAN',
//       'PIIT',
//       'POOK',
//       'RIZAL',
//       'SAN FRANCISCO (POB.)',
//       'SAN ISIDRO',
//       'SAN MIGUEL (POB.)',
//       'SAN ROQUE',
//       'SANTA CATALINA (POB.)',
//       'SUBA',
//       'TALORTOR',
//       'TANAWAN',
//       'TAYTAY',
//       'VILLA NOGALES',
//     ],
//   },
//   NAGCARLAN: {
//     province: 'LAGUNA',
//     municipality: 'NAGCARLAN',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'ABO',
//       'ALIBUNGBUNGAN',
//       'ALUMBRADO',
//       'BALAYONG',
//       'BALIMBING',
//       'BALINACON',
//       'BAMBANG',
//       'BANAGO',
//       'BANCA-BANCA',
//       'BANGCURO',
//       'BANILAD',
//       'BAYAQUITOS',
//       'BUBOY',
//       'BUENAVISTA',
//       'BUHANGINAN',
//       'BUKAL',
//       'BUNGA',
//       'CABUYEW',
//       'CALUMPANG',
//       'KANLURAN KABUBUHAYAN',
//       'KANLURAN LAZAAN',
//       'LABANGAN',
//       'LAGULO',
//       'LAWAGUIN',
//       'MAIIT',
//       'MALAYA',
//       'MALINAO',
//       'MANAOL',
//       'MARAVILLA',
//       'NAGCALBANG',
//       'OPLES',
//       'PALAYAN',
//       'PALINA',
//       'POBLACION I (POB.)',
//       'POBLACION II (POB.)',
//       'POBLACION III (POB.)',
//       'SABANG',
//       'SAN FRANCISCO',
//       'SANTA LUCIA',
//       'SIBULAN',
//       'SILANGAN ILAYA',
//       'SILANGAN KABUBUHAYAN',
//       'SILANGAN LAZAAN',
//       'SILANGAN NAPAPATID',
//       'SINIPIAN',
//       'SULSUGUIN',
//       'TALAHIB',
//       'TALANGAN',
//       'TAYTAY',
//       'TIPACAN',
//       'WAKAT',
//       'YUKOS',
//     ],
//   },
//   PAETE: {
//     province: 'LAGUNA',
//     municipality: 'PAETE',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'BAGUMBAYAN (POB.)',
//       'BANGKUSAY (POB.)',
//       'ERMITA (POB.)',
//       'IBABA DEL NORTE (POB.)',
//       'IBABA DEL SUR (POB.)',
//       'ILAYA DEL NORTE (POB.)',
//       'ILAYA DEL SUR (POB.)',
//       'MAYTOONG (POB.)',
//       'QUINALE (POB.)',
//     ],
//   },
//   PAGSANJAN: {
//     province: 'LAGUNA',
//     municipality: 'PAGSANJAN',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'ANIBONG',
//       'BARANGAY I (POB.)',
//       'BARANGAY II (POB.)',
//       'BIÑAN',
//       'BUBOY',
//       'CABANBANAN',
//       'CALUSICHE',
//       'DINGIN',
//       'LAMBAC',
//       'LAYUGAN',
//       'MAGDAPIO',
//       'MAULAWIN',
//       'PINAGSANJAN',
//       'SABANG',
//       'SAMPALOC',
//       'SAN ISIDRO',
//     ],
//   },
//   PAKIL: {
//     province: 'LAGUNA',
//     municipality: 'PAKIL',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'BANILAN',
//       'BAÑO (POB.)',
//       'BURGOS (POB.)',
//       'CASA REAL',
//       'CASINSIN',
//       'DORADO',
//       'GONZALES (POB.)',
//       'KABULUSAN',
//       'MATIKIW',
//       'RIZAL (POB.)',
//       'SARAY',
//       'TAFT (POB.)',
//       'TAVERA (POB.)',
//     ],
//   },
//   PANGIL: {
//     province: 'LAGUNA',
//     municipality: 'PANGIL',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'BALIAN',
//       'DAMBO',
//       'GALALAN',
//       'ISLA (POB.)',
//       'MABATO-AZUFRE',
//       'NATIVIDAD (POB.)',
//       'SAN JOSE (POB.)',
//       'SULIB',
//     ],
//   },
//   PILA: {
//     province: 'LAGUNA',
//     municipality: 'PILA',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'APLAYA',
//       'BAGONG POOK',
//       'BUKAL',
//       'BULILAN NORTE (POB.)',
//       'BULILAN SUR (POB.)',
//       'CONCEPCION',
//       'LABUIN',
//       'LINGA',
//       'MASICO',
//       'MOJON',
//       'PANSOL',
//       'PINAGBAYANAN',
//       'SAN ANTONIO',
//       'SAN MIGUEL',
//       'SANTA CLARA NORTE (POB.)',
//       'SANTA CLARA SUR (POB.)',
//       'TUBUAN',
//     ],
//   },
//   'SAN PABLO': {
//     province: 'LAGUNA',
//     municipality: 'SAN PABLO',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'ATISAN',
//       'BAGONG BAYAN II-A (POB.)',
//       'BAGONG POOK VI-C (POB.)',
//       'BARANGAY I-A (POB.)',
//       'BARANGAY I-B (POB.)',
//       'BARANGAY II-A (POB.)',
//       'BARANGAY II-B (POB.)',
//       'BARANGAY II-C (POB.)',
//       'BARANGAY II-D (POB.)',
//       'BARANGAY II-E (POB.)',
//       'BARANGAY II-F (POB.)',
//       'BARANGAY III-A (POB.)',
//       'BARANGAY III-B (POB.)',
//       'BARANGAY III-C (POB.)',
//       'BARANGAY III-D (POB.)',
//       'BARANGAY III-E (POB.)',
//       'BARANGAY III-F (POB.)',
//       'BARANGAY IV-A (POB.)',
//       'BARANGAY IV-B (POB.)',
//       'BARANGAY IV-C (POB.)',
//       'BARANGAY V-A (POB.)',
//       'BARANGAY V-B (POB.)',
//       'BARANGAY V-C (POB.)',
//       'BARANGAY V-D (POB.)',
//       'BARANGAY VI-A (POB.)',
//       'BARANGAY VI-B (POB.)',
//       'BARANGAY VI-D (POB.)',
//       'BARANGAY VI-E (POB.)',
//       'BARANGAY VII-A (POB.)',
//       'BARANGAY VII-B (POB.)',
//       'BARANGAY VII-C (POB.)',
//       'BARANGAY VII-D (POB.)',
//       'BARANGAY VII-E (POB.)',
//       'BAUTISTA',
//       'CONCEPCION',
//       'DEL REMEDIO',
//       'DOLORES',
//       'SAN ANTONIO 1',
//       'SAN ANTONIO 2',
//       'SAN BARTOLOME',
//       'SAN BUENAVENTURA',
//       'SAN CRISPIN',
//       'SAN CRISTOBAL',
//       'SAN DIEGO',
//       'SAN FRANCISCO',
//       'SAN GABRIEL',
//       'SAN GREGORIO',
//       'SAN IGNACIO',
//       'SAN ISIDRO',
//       'SAN JOAQUIN',
//       'SAN JOSE',
//       'SAN JUAN',
//       'SAN LORENZO',
//       'SAN LUCAS 1',
//       'SAN LUCAS 2',
//       'SAN MARCOS',
//       'SAN MATEO',
//       'SAN MIGUEL',
//       'SAN NICOLAS',
//       'SAN PEDRO',
//       'SAN RAFAEL',
//       'SAN ROQUE',
//       'SAN VICENTE',
//       'SANTA ANA',
//       'SANTA CATALINA',
//       'SANTA CRUZ',
//       'SANTA ELENA',
//       'SANTA FELOMINA',
//       'SANTA ISABEL',
//       'SANTA MARIA',
//       'SANTA MARIA MAGDALENA',
//       'SANTA MONICA',
//       'SANTA VERONICA',
//       'SANTIAGO I',
//       'SANTIAGO II',
//       'SANTISIMO ROSARIO',
//       'SANTO ANGEL',
//       'SANTO CRISTO',
//       'SANTO NIÑO',
//       'SOLEDAD',
//     ],
//   },
//   'SAN PEDRO': {
//     province: 'LAGUNA',
//     municipality: 'SAN PEDRO',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'BAGONG SILANG',
//       'CALENDOLA',
//       'CHRYSANTHEMUM',
//       'CUYAB',
//       'ESTRELLA',
//       'FATIMA',
//       'G.S.I.S.',
//       'LANDAYAN',
//       'LANGGAM',
//       'LARAM',
//       'MAGSAYSAY',
//       'MAHARLIKA',
//       'NARRA',
//       'NUEVA',
//       'PACITA 1',
//       'PACITA 2',
//       'POBLACION',
//       'RIVERSIDE',
//       'ROSARIO',
//       'SAMPAGUITA VILLAGE',
//       'SAN ANTONIO',
//       'SAN LORENZO RUIZ',
//       'SAN ROQUE',
//       'SAN VICENTE',
//       'SANTO NIÑO',
//       'UNITED BAYANIHAN',
//       'UNITED BETTER LIVING',
//     ],
//   },
//   'SANTA ROSA': {
//     province: 'LAGUNA',
//     municipality: 'SANTA ROSA',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'APLAYA',
//       'BALIBAGO',
//       'CAINGIN',
//       'DILA',
//       'DITA',
//       'DON JOSE',
//       'IBABA',
//       'KANLURAN (POB.)',
//       'LABAS',
//       'MACABLING',
//       'MALITLIT',
//       'MALUSAK (POB.)',
//       'MARKET AREA (POB.)',
//       'POOK',
//       'PULONG SANTA CRUZ',
//       'SANTO DOMINGO',
//       'SINALHAN',
//       'TAGAPO',
//     ],
//   },
//   SINILOAN: {
//     province: 'LAGUNA',
//     municipality: 'SINILOAN',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'ACEVIDA',
//       'BAGONG PAG-ASA (POB.)',
//       'BAGUMBARANGAY (POB.)',
//       'BUHAY',
//       'G. REDOR (POB.)',
//       'GEN. LUNA',
//       'HALAYHAYIN',
//       'J. RIZAL (POB.)',
//       'KAPATALAN',
//       'LAGUIO',
//       'LIYANG',
//       'LLAVAC',
//       'MACATAD',
//       'MAGSAYSAY',
//       'MAYATBA',
//       'MENDIOLA',
//       'P. BURGOS',
//       'PANDENO',
//       'SALUBUNGAN',
//       'WAWA',
//     ],
//   },
//   AGDANGAN: {
//     province: 'QUEZON',
//     municipality: 'AGDANGAN',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'BINAGBAG',
//       'DAYAP',
//       'IBABANG KINAGUNAN',
//       'ILAYANG KINAGUNAN',
//       'KANLURANG CALUTAN',
//       'KANLURANG MALIGAYA',
//       'POBLACION I',
//       'POBLACION II',
//       'SALVACION',
//       'SILANGANG CALUTAN',
//       'SILANGANG MALIGAYA',
//       'SILDORA',
//     ],
//   },
//   ALABAT: {
//     province: 'QUEZON',
//     municipality: 'ALABAT',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'ANGELES',
//       'BACONG',
//       'BALUNGAY',
//       'BARANGAY 1 (POB.)',
//       'BARANGAY 2 (POB.)',
//       'BARANGAY 3 (POB.)',
//       'BARANGAY 4 (POB.)',
//       'BARANGAY 5 (POB.)',
//       'BUENAVISTA',
//       'CAGLATE',
//       'CAMAGONG',
//       'GORDON',
//       'PAMBILAN NORTE',
//       'PAMBILAN SUR',
//       'VILLA ESPERANZA',
//       'VILLA JESUS ESTE',
//       'VILLA JESUS WESTE',
//       'VILLA NORTE',
//       'VILLA VICTORIA',
//     ],
//   },
//   ATIMONAN: {
//     province: 'QUEZON',
//     municipality: 'ATIMONAN',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'ANGELES',
//       'BALUBAD',
//       'BALUGOHIN',
//       'BARANGAY ZONE 1 (POB.)',
//       'BARANGAY ZONE 2 (POB.)',
//       'BARANGAY ZONE 3 (POB.)',
//       'BARANGAY ZONE 4 (POB.)',
//       'BUHANGIN',
//       'CARIDAD IBABA',
//       'CARIDAD ILAYA',
//       'HABINGAN',
//       'INACLAGAN',
//       'INALIG',
//       'KILAIT',
//       'KULAWIT',
//       'LAKIP',
//       'LUBI',
//       'LUMUTAN',
//       'MAGSAYSAY',
//       'MALINAO IBABA',
//       'MALINAO ILAYA',
//       'MALUSAK',
//       'MANGGALAYAN BUNDOK',
//       'MANGGALAYAN LABAK',
//       'MATANAG',
//       'MONTES BALAON',
//       'MONTES KALLAGAN',
//       'PONON',
//       'RIZAL',
//       'SAN ANDRES BUNDOK',
//       'SAN ANDRES LABAK',
//       'SAN ISIDRO',
//       'SAN JOSE BALATOK',
//       'SAN RAFAEL',
//       'SANTA CATALINA',
//       'SAPAAN',
//       'SOKOL',
//       'TAGBAKIN',
//       'TALABA',
//       'TINANDOG',
//       'VILLA IBABA',
//       'VILLA ILAYA',
//     ],
//   },
//   BURDEOS: {
//     province: 'QUEZON',
//     municipality: 'BURDEOS',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'ALUYON',
//       'AMOT',
//       'ANIBAWAN',
//       'BONIFACIO',
//       'CABUGAO',
//       'CABUNGALUNAN',
//       'CALUTCOT',
//       'CANIWAN',
//       'CARLAGAN',
//       'MABINI',
//       'PALASAN',
//       'POBLACION',
//       'SAN RAFAEL',
//     ],
//   },
//   CALAUAG: {
//     province: 'QUEZON',
//     municipality: 'CALAUAG',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'AGOHO',
//       'ANAHAWAN',
//       'ANAS',
//       'APAD LUTAO',
//       'APAD QUEZON',
//       'APAD TAISAN',
//       'ATULAYAN',
//       'BACLARAN',
//       'BAGONG SILANG',
//       'BALIBAGO',
//       'BANGKURUHAN',
//       'BANTOLINAO',
//       'BARANGAY I (POB.)',
//       'BARANGAY II (POB.)',
//       'BARANGAY III (POB.)',
//       'BARANGAY IV (POB.)',
//       'BARANGAY V (POB.)',
//       'BIGAAN',
//       'BINUTAS',
//       'BIYAN',
//       'BUKAL',
//       'BULI',
//       'DAPDAP',
//       'DOMINLOG',
//       'DOÑA AURORA',
//       'GUINOSAYAN',
//       'IPIL',
//       'KALIBO (SANTA CRUZ)',
//       'KAPALUHAN',
//       'KATANGTANG',
//       'KIGTAN',
//       'KINALIN IBABA',
//       'KINALIN ILAYA',
//       'KINAMALIGAN',
//       'KUMALUDKUD',
//       'KUNALUM',
//       'KUYAOYAO',
//       'LAGAY',
//       'LAINGLAINGAN',
//       'LUNGIB',
//       'MABINI',
//       'MADLANGDUNGAN',
//       'MAGLIPAD (ROSARIO)',
//       'MALIGAYA',
//       'MAMBALING',
//       'MANHULUGIN',
//       'MARILAG (PUNAYA)',
//       'MULAY',
//       'PANDANAN',
//       'PANSOL',
//       'PATIHAN',
//       'PINAGBAYANAN',
//       'PINAGKAMALIGAN',
//       'PINAGSAKAHAN',
//       'PINAGTALLERAN',
//       'RIZAL IBABA',
//       'RIZAL ILAYA',
//       'SABANG I',
//       'SABANG II',
//       'SALVACION',
//       'SAN QUINTIN',
//       'SAN ROQUE IBABA',
//       'SAN ROQUE ILAYA',
//       'SANTA CECILIA',
//       'SANTA MARIA',
//       'SANTA MILAGROSA',
//       'SANTA ROSA',
//       'SANTO ANGEL (PANGAHOY)',
//       'SANTO DOMINGO',
//       'SINAG',
//       'SUMILANG',
//       'SUMULONG',
//       'TABANSAK',
//       'TALINGTING',
//       'TAMIS',
//       'TIKIWAN',
//       'TINIGUIBAN',
//       'VILLA MAGSINO',
//       'VILLA SAN ISIDRO',
//       'VIÑAS',
//       'YAGANAK',
//     ],
//   },
//   CATANAUAN: {
//     province: 'QUEZON',
//     municipality: 'CATANAUAN',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'AJOS',
//       'ANUSAN',
//       'BARANGAY 1 (POB.)',
//       'BARANGAY 2 (POB.)',
//       'BARANGAY 3 (POB.)',
//       'BARANGAY 4 (POB.)',
//       'BARANGAY 5 (POB.)',
//       'BARANGAY 6 (POB.)',
//       'BARANGAY 7 (POB.)',
//       'BARANGAY 8 (POB.)',
//       'BARANGAY 9 (POB.)',
//       'BARANGAY 10 (POB.)',
//       'BOLO',
//       'BULAGSONG',
//       'CAMANDIISON',
//       'CANCULAJAO',
//       'CATUMBO',
//       'CAWAYANIN IBABA',
//       'CAWAYANIN ILAYA',
//       'CUTCUTAN',
//       'DAHICAN',
//       'DOONGAN IBABA',
//       'DOONGAN ILAYA',
//       'GATASAN',
//       'MACPAC',
//       'MADULAO',
//       'MATANDANG SABANG KANLURAN',
//       'MATANDANG SABANG SILANGAN',
//       'MILAGROSA',
//       'NAVITAS',
//       'PACABIT',
//       'SAN ANTONIO MAGKUPA',
//       'SAN ANTONIO PALA',
//       'SAN ISIDRO',
//       'SAN JOSE (ANYAO)',
//       'SAN PABLO (SUHA)',
//       'SAN ROQUE (DOYON)',
//       'SAN VICENTE KANLURAN',
//       'SAN VICENTE SILANGAN',
//       'SANTA MARIA (DAO)',
//       'TAGABAS IBABA',
//       'TAGABAS ILAYA',
//       'TAGBACAN IBABA',
//       'TAGBACAN ILAYA',
//       'TAGBACAN SILANGAN',
//       'TUHIAN',
//     ],
//   },
//   DOLORES: {
//     province: 'ABRA',
//     municipality: 'DOLORES',
//     region: 'CAR',
//     barangay_list: [
//       'BAYAAN',
//       'CABAROAN',
//       'CALUMBAYA',
//       'CARDONA',
//       'ISIT',
//       'KIMMALABA',
//       'LIBTEC',
//       'LUB-LUBBA',
//       'MUDIIT',
//       'NAMIT-INGAN',
//       'PACAC',
//       'POBLACION',
//       'SALUCAG',
//       'TALOGTOG',
//       'TAPING',
//     ],
//   },
//   'GENERAL NAKAR': {
//     province: 'QUEZON',
//     municipality: 'GENERAL NAKAR',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'ANOLING',
//       'BANGLOS',
//       'BATANGAN',
//       'CANAWAY',
//       'CATABLINGAN',
//       'LUMUTAN',
//       'MAGSIKAP',
//       'MAHABANG LALIM',
//       'MAIGANG',
//       'MALIGAYA',
//       'MINAHAN NORTE',
//       'MINAHAN SUR',
//       'PAGSANGAHAN',
//       'PAMPLONA',
//       'PISA',
//       'POBLACION',
//       'SABLANG',
//       'SAN MARCELINO',
//       'UMIRAY',
//     ],
//   },
//   GUINAYANGAN: {
//     province: 'QUEZON',
//     municipality: 'GUINAYANGAN',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'A. MABINI',
//       'ALONEROS',
//       'ARBISMEN',
//       'BAGONG SILANG',
//       'BALINARIN',
//       'BUKAL MALIGAYA',
//       'CABIBIHAN',
//       'CABONG NORTE',
//       'CABONG SUR',
//       'CALIMPAK',
//       'CAPULUAN CENTRAL',
//       'CAPULUAN TULON',
//       'DANCALAN CAIMAWAN',
//       'DANCALAN CENTRAL',
//       'DANLAGAN BATIS',
//       'DANLAGAN CABAYAO',
//       'DANLAGAN CENTRAL',
//       'DANLAGAN RESERVA',
//       'DEL ROSARIO',
//       'DUNGAWAN CENTRAL',
//       'DUNGAWAN PAALYUNAN',
//       'DUNGAWAN PANTAY',
//       'ERMITA',
//       'GAPAS',
//       'HIMBUBULO ESTE',
//       'HIMBUBULO WESTE',
//       'HINABAAN',
//       'LIGPIT BANTAYAN',
//       'LUBIGAN',
//       'MAGALLANES',
//       'MAGSAYSAY',
//       'MANGGAGAWA',
//       'MANGGALANG',
//       'MANLAYO',
//       'POBLACION',
//       'SALAKAN',
//       'SAN ANTONIO',
//       'SAN ISIDRO',
//       'SAN JOSE',
//       'SAN LORENZO',
//       'SAN LUIS I',
//       'SAN LUIS II',
//       'SAN MIGUEL',
//       'SAN PEDRO I',
//       'SAN PEDRO II',
//       'SAN ROQUE',
//       'SANTA CRUZ',
//       'SANTA MARIA',
//       'SANTA TERESITA',
//       'SINTONES',
//       'SISI',
//       'TIKAY',
//       'TRIUMPO',
//       'VILLA HIWASAYAN',
//     ],
//   },
//   GUMACA: {
//     province: 'QUEZON',
//     municipality: 'GUMACA',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'ADIA BITAOG',
//       'ANONANGIN',
//       'BAGONG BUHAY (POB.)',
//       'BAMBAN',
//       'BANTAD',
//       'BATONG DALIG',
//       'BIGA',
//       'BINAMBANG',
//       'BUENSUCESO',
//       'BUNGAHAN',
//       'BUTAGUIN',
//       'CALUMANGIN',
//       'CAMOHAGUIN',
//       'CASASAHAN IBABA',
//       'CASASAHAN ILAYA',
//       'CAWAYAN',
//       'GAYAGAYAAN',
//       'GITNANG BARRIO',
//       'HAGAKHAKIN',
//       'HARDINAN',
//       'INACLAGAN',
//       'INAGBUHAN ILAYA',
//       'LABNIG',
//       'LAGUNA',
//       'LAGYO',
//       'MABINI (POB.)',
//       'MABUNGA',
//       'MALABTOG',
//       'MANLAYAAN',
//       'MARCELO H. DEL PILAR',
//       'MATAAS NA BUNDOK',
//       'MAUNLAD (POB.)',
//       'PAGSABANGAN',
//       'PANIKIHAN',
//       'PEÑAFRANCIA (POB.)',
//       'PIPISIK (POB.)',
//       'PROGRESO',
//       'RIZAL (POB.)',
//       'ROSARIO',
//       'SAN AGUSTIN',
//       'SAN DIEGO',
//       'SAN DIEGO POBLACION',
//       'SAN ISIDRO KANLURAN',
//       'SAN ISIDRO SILANGAN',
//       'SAN JUAN DE JESUS',
//       'SAN VICENTE',
//       'SASTRE',
//       'TABING DAGAT (POB.)',
//       'TUMAYAN',
//       'VILLA ARCAYA',
//       'VILLA BOTA',
//       'VILLA FUERTE',
//       'VILLA M. PRINCIPE',
//       'VILLA MENDOZA',
//       'VILLA NAVA',
//       'VILLA PADUA',
//       'VILLA PEREZ',
//       'VILLA TAÑADA',
//       'VILLA VICTORIA',
//     ],
//   },
//   JOMALIG: {
//     province: 'QUEZON',
//     municipality: 'JOMALIG',
//     region: 'REGION IV-A',
//     barangay_list: ['APAD', 'BUKAL', 'CASUGURAN', 'GANGO', 'TALISOY (POB.)'],
//   },
//   LOPEZ: {
//     province: 'QUEZON',
//     municipality: 'LOPEZ',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'BACUNGAN',
//       'BAGACAY',
//       'BANABAHIN IBABA',
//       'BANABAHIN ILAYA',
//       'BAYABAS',
//       'BEBITO',
//       'BIGAJO',
//       'BINAHIAN A',
//       'BINAHIAN B',
//       'BINAHIAN C',
//       'BOCBOC',
//       'BUENAVISTA',
//       'BURGOS (POB.)',
//       'BUYACANIN',
//       'CAGACAG',
//       'CALANTIPAYAN',
//       'CANDA IBABA',
//       'CANDA ILAYA',
//       'CAWAYAN',
//       'CAWAYANIN',
//       'COGORIN IBABA',
//       'COGORIN ILAYA',
//       'CONCEPCION',
//       'DANLAGAN',
//       'DE LA PAZ',
//       'DEL PILAR',
//       'DEL ROSARIO',
//       'ESPERANZA IBABA',
//       'ESPERANZA ILAYA',
//       'GOMEZ (POB.)',
//       'GUIHAY',
//       'GUINUANGAN',
//       'GUITES',
//       'HONDAGUA',
//       'ILAYANG ILOG A',
//       'ILAYANG ILOG B',
//       'INALUSAN',
//       'JONGO',
//       'LALAGUNA',
//       'LOURDES',
//       'MABANBAN',
//       'MABINI',
//       'MAGALLANES',
//       'MAGSAYSAY (POB.)',
//       'MAGUILAYAN',
//       'MAHAYOD-HAYOD',
//       'MAL-AY',
//       'MANDOOG',
//       'MANGUISIAN',
//       'MATINIK',
//       'MONTECLARO',
//       'PAMAMPANGIN',
//       'PANSOL',
//       'PEÑAFRANCIA',
//       'PISIPIS',
//       'RIZAL (POBLACION)',
//       'RIZAL (RURAL)',
//       'ROMA',
//       'ROSARIO',
//       'SAMAT',
//       'SAN ANDRES',
//       'SAN ANTONIO',
//       'SAN FRANCISCO A',
//       'SAN FRANCISCO B',
//       'SAN ISIDRO',
//       'SAN JOSE',
//       'SAN MIGUEL (DAO)',
//       'SAN PEDRO',
//       'SAN RAFAEL',
//       'SAN ROQUE',
//       'SANTA CATALINA',
//       'SANTA ELENA',
//       'SANTA JACOBE',
//       'SANTA LUCIA',
//       'SANTA MARIA',
//       'SANTA ROSA',
//       'SANTA TERESA (MAYUPAG)',
//       'SANTO NIÑO IBABA',
//       'SANTO NIÑO ILAYA',
//       'SILANG',
//       'SUGOD',
//       'SUMALANG',
//       'TALOLONG (POB.)',
//       'TAN-AG IBABA',
//       'TAN-AG ILAYA',
//       'TOCALIN',
//       'VEGAFLOR',
//       'VERGAÑA',
//       'VERONICA',
//       'VILLA AURORA',
//       'VILLA ESPINA',
//       'VILLA GEDA',
//       'VILLA HERMOSA',
//       'VILLAMONTE',
//       'VILLANACAOB',
//     ],
//   },
//   LUCBAN: {
//     province: 'QUEZON',
//     municipality: 'LUCBAN',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'ABANG',
//       'ALILIW',
//       'ATULINAO',
//       'AYUTI',
//       'BARANGAY 1 (POB.)',
//       'BARANGAY 2 (POB.)',
//       'BARANGAY 3 (POB.)',
//       'BARANGAY 4 (POB.)',
//       'BARANGAY 5 (POB.)',
//       'BARANGAY 6 (POB.)',
//       'BARANGAY 7 (POB.)',
//       'BARANGAY 8 (POB.)',
//       'BARANGAY 9 (POB.)',
//       'BARANGAY 10 (POB.)',
//       'IGANG',
//       'KABATETE',
//       'KAKAWIT',
//       'KALANGAY',
//       'KALYAAT',
//       'KILIB',
//       'KULAPI',
//       'MAHABANG PARANG',
//       'MALUPAK',
//       'MANASA',
//       'MAY-IT',
//       'NAGSINAMO',
//       'NALUNAO',
//       'PALOLA',
//       'PIIS',
//       'SAMIL',
//       'TIAWE',
//       'TINAMNAN',
//     ],
//   },
//   'LUCENA': {
//     province: 'QUEZON',
//     municipality: 'LUCENA',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'BARANGAY 1 (POB.)',
//       'BARANGAY 2 (POB.)',
//       'BARANGAY 3 (POB.)',
//       'BARANGAY 4 (POB.)',
//       'BARANGAY 5 (POB.)',
//       'BARANGAY 6 (POB.)',
//       'BARANGAY 7 (POB.)',
//       'BARANGAY 8 (POB.)',
//       'BARANGAY 9 (POB.)',
//       'BARANGAY 10 (POB.)',
//       'BARANGAY 11 (POB.)',
//       'BARRA',
//       'BOCOHAN',
//       'COTTA',
//       'DALAHICAN',
//       'DOMOIT',
//       'GULANG-GULANG',
//       'IBABANG DUPAY',
//       'IBABANG IYAM',
//       'IBABANG TALIM',
//       'ILAYANG DUPAY',
//       'ILAYANG IYAM',
//       'ILAYANG TALIM',
//       'ISABANG',
//       'MARKET VIEW',
//       'MAYAO CASTILLO',
//       'MAYAO CROSSING',
//       'MAYAO KANLURAN',
//       'MAYAO PARADA',
//       'MAYAO SILANGAN',
//       'RANSOHAN',
//       'SALINAS',
//       'TALAO-TALAO',
//     ],
//   },
//   MACALELON: {
//     province: 'QUEZON',
//     municipality: 'MACALELON',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'AMONTAY',
//       'ANOS',
//       'BUYAO',
//       'CALANTAS',
//       'CANDANGAL',
//       'CASTILLO (POB.)',
//       'DAMAYAN (POB.)',
//       'LAHING',
//       'LUCTOB',
//       'MABINI IBABA',
//       'MABINI ILAYA',
//       'MALABAHAY',
//       'MAMBOG',
//       'MASIPAG (POB.)',
//       'OLONGTAO IBABA',
//       'OLONGTAO ILAYA',
//       'PADRE HERRERA',
//       'PAG-ASA (POB.)',
//       'PAJARILLO',
//       'PINAGBAYANAN',
//       'RIZAL (POB.)',
//       'RODRIQUEZ (POB.)',
//       'SAN ISIDRO',
//       'SAN JOSE',
//       'SAN NICOLAS',
//       'SAN VICENTE',
//       'TAGUIN',
//       'TUBIGAN IBABA',
//       'TUBIGAN ILAYA',
//       'VISTA HERMOSA',
//     ],
//   },
//   MAUBAN: {
//     province: 'QUEZON',
//     municipality: 'MAUBAN',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'ABO-ABO',
//       'ALITAP',
//       'BAAO',
//       'BAGONG BAYAN (POB.)',
//       'BALAYBALAY',
//       'BATO',
//       'CAGBALETE I',
//       'CAGBALETE II',
//       'CAGSIAY I',
//       'CAGSIAY II',
//       'CAGSIAY III',
//       'CONCEPCION',
//       'DAUNGAN (POB.)',
//       'LIWAYWAY',
//       'LUAL (POB.)',
//       'LUAL RURAL',
//       'LUCUTAN',
//       'LUYA-LUYA',
//       'MABATO (POB.)',
//       'MACASIN',
//       'POLO',
//       'REMEDIOS I',
//       'REMEDIOS II',
//       'RIZALIANA (POB.)',
//       'ROSARIO',
//       'SADSARAN (POB.)',
//       'SAN GABRIEL',
//       'SAN ISIDRO',
//       'SAN JOSE',
//       'SAN LORENZO',
//       'SAN MIGUEL',
//       'SAN RAFAEL',
//       'SAN ROQUE',
//       'SAN VICENTE',
//       'SANTA LUCIA',
//       'SANTO ANGEL',
//       'SANTO NIÑO',
//       'SANTOL',
//       'SOLEDAD',
//       'TAPUCAN',
//     ],
//   },
//   MULANAY: {
//     province: 'QUEZON',
//     municipality: 'MULANAY',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'AJOS',
//       'AMUGUIS',
//       'ANONANG',
//       'BAGONG SILANG',
//       'BAGUPAYE',
//       'BARANGAY 1 (POB.)',
//       'BARANGAY 2 (POB.)',
//       'BARANGAY 3 (POB.)',
//       'BARANGAY 4 (POB.)',
//       'BOLO',
//       'BUENAVISTA',
//       'BURGOS',
//       'BUTANYOG',
//       'CANUYEP',
//       'F. NANADIEGO',
//       'IBABANG CAMBUGA',
//       'IBABANG YUNI',
//       'ILAYANG CAMBUGA (MABINI)',
//       'ILAYANG YUNI',
//       'LATANGAN',
//       'MAGSAYSAY',
//       'MATATAJA',
//       'PAKIING',
//       'PATABOG',
//       'SAGONGON',
//       'SAN ISIDRO',
//       'SAN PEDRO',
//       'SANTA ROSA',
//     ],
//   },
//   'PADRE BURGOS': {
//     province: 'SOUTHERN LEYTE',
//     municipality: 'PADRE BURGOS',
//     region: 'REGION VIII',
//     barangay_list: [
//       'BUENAVISTA',
//       'BUNGA',
//       'CANTUTANG',
//       'DINAHUGAN',
//       'LACA',
//       'LUNGSODAAN',
//       'POBLACION',
//       'SAN JUAN',
//       'SANTA SOFIA',
//       'SANTO ROSARIO',
//       'TANGKAAN',
//     ],
//   },
//   PAGBILAO: {
//     province: 'QUEZON',
//     municipality: 'PAGBILAO',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'ALUPAYE',
//       'ANTIPOLO',
//       'AÑATO',
//       'BANTIGUE',
//       'BARANGAY 1 CASTILLO (POB.)',
//       'BARANGAY 2 DAUNGAN (POB.)',
//       'BARANGAY 3 DEL CARMEN (POB.)',
//       'BARANGAY 4 PARANG (POB.)',
//       'BARANGAY 5 SANTA CATALINA (POB.)',
//       'BARANGAY 6 TAMBAK (POB.)',
//       'BIGO',
//       'BINAHAAN',
//       'BUKAL',
//       'IBABANG BAGUMBUNGAN',
//       'IBABANG PALSABANGON',
//       'IBABANG POLO',
//       'IKIRIN',
//       'ILAYANG BAGUMBUNGAN',
//       'ILAYANG PALSABANGON',
//       'ILAYANG POLO',
//       'KANLURAN MALICBOY',
//       'MAPAGONG',
//       'MAYHAY',
//       'PINAGBAYANAN',
//       'SILANGAN MALICBOY',
//       'TALIPAN',
//       'TUKALAN',
//     ],
//   },
//   PANUKULAN: {
//     province: 'QUEZON',
//     municipality: 'PANUKULAN',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'BALUNGAY',
//       'BATO',
//       'BONBON',
//       'CALASUMANGA',
//       'KINALAGTI',
//       'LIBO',
//       'LIPATA',
//       'MATANGKAP',
//       'MILAWID',
//       'PAGITAN',
//       'PANDAN',
//       'RIZAL',
//       'SAN JUAN (POB.)',
//     ],
//   },
//   PATNANUNGAN: {
//     province: 'QUEZON',
//     municipality: 'PATNANUNGAN',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'AMAGA',
//       'BUSDAK',
//       'KILOGAN',
//       'LUOD',
//       'PATNANUNGAN NORTE',
//       'PATNANUNGAN SUR (POB.)',
//     ],
//   },
//   PEREZ: {
//     province: 'QUEZON',
//     municipality: 'PEREZ',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'BAGONG PAG-ASA POB. (BARANGAY 3)',
//       'BAGONG SILANG POB. (BARANGAY 4)',
//       'MAABOT',
//       'MAINIT NORTE',
//       'MAINIT SUR',
//       'MAPAGMAHAL POB. (BARANGAY 2)',
//       'PAGKAKAISA POB. (BARANGAY 1)',
//       'PAMBUHAN',
//       'PINAGTUBIGAN ESTE',
//       'PINAGTUBIGAN WESTE',
//       'RIZAL',
//       'SANGIRIN',
//       'VILLAMANZANO NORTE',
//       'VILLAMANZANO SUR',
//     ],
//   },
//   PITOGO: {
//     province: 'ZAMBOANGA DEL SUR',
//     municipality: 'PITOGO',
//     region: 'REGION IX',
//     barangay_list: [
//       'BALABAWAN',
//       'BALONG-BALONG',
//       'COLOJO',
//       'LIASAN',
//       'LIGUAC',
//       'LIMBAYAN',
//       'LOWER PANIKI-AN',
//       'MATIN-AO',
//       'PANUBIGAN',
//       'POBLACION (PITOGO)',
//       'PUNTA FLECHA',
//       'SAN ISIDRO',
//       'SUGBAY DOS',
//       'TONGAO',
//       'UPPER PANIKI-AN',
//     ],
//   },
//   POLILLO: {
//     province: 'QUEZON',
//     municipality: 'POLILLO',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'ANAWAN',
//       'ATULAYAN',
//       'BALESIN',
//       'BAÑADERO',
//       'BINIBITINAN',
//       'BISLIAN',
//       'BUCAO',
//       'CANICANIAN',
//       'KALUBAKIS',
//       'LANGUYIN',
//       'LIBJO',
//       'PAMATDAN',
//       'PILION',
//       'PINAGLUBAYAN',
//       'POBLACION',
//       'SABANG',
//       'SALIPSIP',
//       'SIBULAN',
//       'TALUONG',
//       'TAMULAYA-ANITONG',
//     ],
//   },
//   REAL: {
//     province: 'QUEZON',
//     municipality: 'REAL',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'BAGONG SILANG',
//       'CAPALONG',
//       'CAWAYAN',
//       'KILOLORAN',
//       'LLAVAC',
//       'LUBAYAT',
//       'MALAPAD',
//       'MARAGONDON',
//       'MASIKAP',
//       'MAUNLAD',
//       'PANDAN',
//       'POBLACION 61 (BARANGAY 2)',
//       'POBLACION I (BARANGAY 1)',
//       'TAGUMPAY',
//       'TANAUAN',
//       'TIGNOAN',
//       'UNGOS',
//     ],
//   },
//   SAMPALOC: {
//     province: 'METRO MANILA',
//     municipality: 'SAMPALOC',
//     region: 'NCR',
//     barangay_list: [
//       'BARANGAY 395',
//       'BARANGAY 396',
//       'BARANGAY 397',
//       'BARANGAY 398',
//       'BARANGAY 399',
//       'BARANGAY 400',
//       'BARANGAY 401',
//       'BARANGAY 402',
//       'BARANGAY 403',
//       'BARANGAY 404',
//       'BARANGAY 405',
//       'BARANGAY 406',
//       'BARANGAY 407',
//       'BARANGAY 408',
//       'BARANGAY 409',
//       'BARANGAY 410',
//       'BARANGAY 411',
//       'BARANGAY 412',
//       'BARANGAY 413',
//       'BARANGAY 414',
//       'BARANGAY 415',
//       'BARANGAY 416',
//       'BARANGAY 417',
//       'BARANGAY 418',
//       'BARANGAY 419',
//       'BARANGAY 420',
//       'BARANGAY 421',
//       'BARANGAY 422',
//       'BARANGAY 423',
//       'BARANGAY 424',
//       'BARANGAY 425',
//       'BARANGAY 426',
//       'BARANGAY 427',
//       'BARANGAY 428',
//       'BARANGAY 429',
//       'BARANGAY 430',
//       'BARANGAY 431',
//       'BARANGAY 432',
//       'BARANGAY 433',
//       'BARANGAY 434',
//       'BARANGAY 435',
//       'BARANGAY 436',
//       'BARANGAY 437',
//       'BARANGAY 438',
//       'BARANGAY 439',
//       'BARANGAY 440',
//       'BARANGAY 441',
//       'BARANGAY 442',
//       'BARANGAY 443',
//       'BARANGAY 444',
//       'BARANGAY 445',
//       'BARANGAY 446',
//       'BARANGAY 447',
//       'BARANGAY 448',
//       'BARANGAY 449',
//       'BARANGAY 450',
//       'BARANGAY 451',
//       'BARANGAY 452',
//       'BARANGAY 453',
//       'BARANGAY 454',
//       'BARANGAY 455',
//       'BARANGAY 456',
//       'BARANGAY 457',
//       'BARANGAY 458',
//       'BARANGAY 459',
//       'BARANGAY 460',
//       'BARANGAY 461',
//       'BARANGAY 462',
//       'BARANGAY 463',
//       'BARANGAY 464',
//       'BARANGAY 465',
//       'BARANGAY 466',
//       'BARANGAY 467',
//       'BARANGAY 468',
//       'BARANGAY 469',
//       'BARANGAY 470',
//       'BARANGAY 471',
//       'BARANGAY 472',
//       'BARANGAY 473',
//       'BARANGAY 474',
//       'BARANGAY 475',
//       'BARANGAY 476',
//       'BARANGAY 477',
//       'BARANGAY 478',
//       'BARANGAY 479',
//       'BARANGAY 480',
//       'BARANGAY 481',
//       'BARANGAY 482',
//       'BARANGAY 483',
//       'BARANGAY 484',
//       'BARANGAY 485',
//       'BARANGAY 486',
//       'BARANGAY 487',
//       'BARANGAY 488',
//       'BARANGAY 489',
//       'BARANGAY 490',
//       'BARANGAY 491',
//       'BARANGAY 492',
//       'BARANGAY 493',
//       'BARANGAY 494',
//       'BARANGAY 495',
//       'BARANGAY 496',
//       'BARANGAY 497',
//       'BARANGAY 498',
//       'BARANGAY 499',
//       'BARANGAY 500',
//       'BARANGAY 501',
//       'BARANGAY 502',
//       'BARANGAY 503',
//       'BARANGAY 504',
//       'BARANGAY 505',
//       'BARANGAY 506',
//       'BARANGAY 507',
//       'BARANGAY 508',
//       'BARANGAY 509',
//       'BARANGAY 510',
//       'BARANGAY 511',
//       'BARANGAY 512',
//       'BARANGAY 513',
//       'BARANGAY 514',
//       'BARANGAY 515',
//       'BARANGAY 516',
//       'BARANGAY 517',
//       'BARANGAY 518',
//       'BARANGAY 519',
//       'BARANGAY 520',
//       'BARANGAY 521',
//       'BARANGAY 522',
//       'BARANGAY 523',
//       'BARANGAY 524',
//       'BARANGAY 525',
//       'BARANGAY 526',
//       'BARANGAY 527',
//       'BARANGAY 528',
//       'BARANGAY 529',
//       'BARANGAY 530',
//       'BARANGAY 531',
//       'BARANGAY 532',
//       'BARANGAY 533',
//       'BARANGAY 534',
//       'BARANGAY 535',
//       'BARANGAY 536',
//       'BARANGAY 537',
//       'BARANGAY 538',
//       'BARANGAY 539',
//       'BARANGAY 540',
//       'BARANGAY 541',
//       'BARANGAY 542',
//       'BARANGAY 543',
//       'BARANGAY 544',
//       'BARANGAY 545',
//       'BARANGAY 546',
//       'BARANGAY 547',
//       'BARANGAY 548',
//       'BARANGAY 549',
//       'BARANGAY 550',
//       'BARANGAY 551',
//       'BARANGAY 552',
//       'BARANGAY 553',
//       'BARANGAY 554',
//       'BARANGAY 555',
//       'BARANGAY 556',
//       'BARANGAY 557',
//       'BARANGAY 558',
//       'BARANGAY 559',
//       'BARANGAY 560',
//       'BARANGAY 561',
//       'BARANGAY 562',
//       'BARANGAY 563',
//       'BARANGAY 564',
//       'BARANGAY 565',
//       'BARANGAY 566',
//       'BARANGAY 567',
//       'BARANGAY 568',
//       'BARANGAY 569',
//       'BARANGAY 570',
//       'BARANGAY 571',
//       'BARANGAY 572',
//       'BARANGAY 573',
//       'BARANGAY 574',
//       'BARANGAY 575',
//       'BARANGAY 576',
//       'BARANGAY 577',
//       'BARANGAY 578',
//       'BARANGAY 579',
//       'BARANGAY 580',
//       'BARANGAY 581',
//       'BARANGAY 582',
//       'BARANGAY 583',
//       'BARANGAY 584',
//       'BARANGAY 585',
//       'BARANGAY 586',
//       'BARANGAY 587',
//       'BARANGAY 587-A',
//       'BARANGAY 588',
//       'BARANGAY 589',
//       'BARANGAY 590',
//       'BARANGAY 591',
//       'BARANGAY 592',
//       'BARANGAY 593',
//       'BARANGAY 594',
//       'BARANGAY 595',
//       'BARANGAY 596',
//       'BARANGAY 597',
//       'BARANGAY 598',
//       'BARANGAY 599',
//       'BARANGAY 600',
//       'BARANGAY 601',
//       'BARANGAY 602',
//       'BARANGAY 603',
//       'BARANGAY 604',
//       'BARANGAY 605',
//       'BARANGAY 606',
//       'BARANGAY 607',
//       'BARANGAY 608',
//       'BARANGAY 609',
//       'BARANGAY 610',
//       'BARANGAY 611',
//       'BARANGAY 612',
//       'BARANGAY 613',
//       'BARANGAY 614',
//       'BARANGAY 615',
//       'BARANGAY 616',
//       'BARANGAY 617',
//       'BARANGAY 618',
//       'BARANGAY 619',
//       'BARANGAY 620',
//       'BARANGAY 621',
//       'BARANGAY 622',
//       'BARANGAY 623',
//       'BARANGAY 624',
//       'BARANGAY 625',
//       'BARANGAY 626',
//       'BARANGAY 627',
//       'BARANGAY 628',
//       'BARANGAY 629',
//       'BARANGAY 630',
//       'BARANGAY 631',
//       'BARANGAY 632',
//       'BARANGAY 633',
//       'BARANGAY 634',
//       'BARANGAY 635',
//       'BARANGAY 636',
//     ],
//   },
//   'SAN ANDRES': {
//     province: 'ROMBLON',
//     municipality: 'SAN ANDRES',
//     region: 'REGION IV-B',
//     barangay_list: [
//       'AGPUDLOS',
//       'CALUNACON',
//       'DOÑA TRINIDAD',
//       'JUNCARLO',
//       'LINAWAN',
//       'MABINI',
//       'MARIGONDON NORTE',
//       'MARIGONDON SUR',
//       'MATUTUNA',
//       'PAG-ALAD',
//       'POBLACION',
//       'TAN-AGAN',
//       'VICTORIA',
//     ],
//   },
//   'SAN FRANCISCO (AURORA)': {
//     province: 'QUEZON',
//     municipality: 'SAN FRANCISCO (AURORA)',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'BUTANGUIAD',
//       'CASAY',
//       'CAWAYAN I',
//       'CAWAYAN II',
//       'DON JUAN VERCELOS',
//       'HUYON-UYON',
//       'IBABANG TAYUMAN',
//       'ILAYANG TAYUMAN',
//       'INABUAN',
//       'MABUÑGA',
//       'NASALAAN',
//       'PAGSANGAHAN',
//       'POBLACION',
//       'PUGON',
//       'SANTO NIÑO',
//       'SILONGIN',
//     ],
//   },
//   SARIAYA: {
//     province: 'QUEZON',
//     municipality: 'SARIAYA',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'ANTIPOLO',
//       'BALUBAL',
//       'BARANGAY 1 (POB.)',
//       'BARANGAY 2 (POB.)',
//       'BARANGAY 3 (POB.)',
//       'BARANGAY 4 (POB.)',
//       'BARANGAY 5 (POB.)',
//       'BARANGAY 6 (POB.)',
//       'BIGNAY 1',
//       'BIGNAY 2',
//       'BUCAL',
//       'CANDA',
//       'CASTAÑAS',
//       'CONCEPCION BANAHAW',
//       'CONCEPCION NO. 1',
//       'CONCEPCION PALASAN',
//       'CONCEPCION PINAGBAKURAN',
//       'GIBANGA',
//       'GUISGUIS-SAN ROQUE',
//       'GUISGUIS-TALON',
//       'JANAGDONG 1',
//       'JANAGDONG 2',
//       'LIMBON',
//       'LUTUCAN 1',
//       'LUTUCAN BATA',
//       'LUTUCAN MALABAG',
//       'MAMALA I',
//       'MAMALA II',
//       'MANGGALANG 1',
//       'MANGGALANG TULO-TULO',
//       'MANGGALANG-BANTILAN',
//       'MANGGALANG-KILING',
//       'MONTECILLO',
//       'MORONG',
//       'PILI',
//       'SAMPALOC 1',
//       'SAMPALOC 2',
//       'SAMPALOC BOGON',
//       'SAMPALOC SANTO CRISTO',
//       'TALAAN APLAYA',
//       'TALAANPANTOC',
//       'TUMBAGA 1',
//       'TUMBAGA 2',
//     ],
//   },
//   TAGKAWAYAN: {
//     province: 'QUEZON',
//     municipality: 'TAGKAWAYAN',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'ALDAVOC',
//       'ALIJI',
//       'BAGONG SILANG',
//       'BAMBAN',
//       'BOSIGON',
//       'BUKAL',
//       'CABIBIHAN',
//       'CABUGWANG',
//       'CAGASCAS',
//       'CANDALAPDAP',
//       'CASISPALAN',
//       'COLONG-COLONG',
//       'DEL ROSARIO',
//       'KATIMO',
//       'KINATAKUTAN',
//       'LANDING',
//       'LAUREL',
//       'MAGSAYSAY',
//       'MAGUIBUAY',
//       'MAHINTA',
//       'MALBOG',
//       'MANATO CENTRAL',
//       'MANATO STATION',
//       'MANGAYAO',
//       'MANSILAY',
//       'MAPULOT',
//       'MUNTING PARANG',
//       'PAYAPA',
//       'POBLACION',
//       'RIZAL',
//       'SABANG',
//       'SAN DIEGO',
//       'SAN FRANCISCO',
//       'SAN ISIDRO',
//       'SAN ROQUE',
//       'SAN VICENTE',
//       'SANTA CECILIA',
//       'SANTA MONICA',
//       'SANTO NIÑO I',
//       'SANTO NIÑO II',
//       'SANTO TOMAS',
//       'SEGUIWAN',
//       'TABASON',
//       'TUNTON',
//       'VICTORIA',
//     ],
//   },
//   'TAYABAS': {
//     province: 'QUEZON',
//     municipality: 'TAYABAS',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'ALITAO',
//       'ALSAM IBABA',
//       'ALSAM ILAYA',
//       'ALUPAY',
//       'ANGELES ZONE I (POB.)',
//       'ANGELES ZONE II',
//       'ANGELES ZONE III',
//       'ANGELES ZONE IV',
//       'ANGUSTIAS ZONE I (POB.)',
//       'ANGUSTIAS ZONE II',
//       'ANGUSTIAS ZONE III',
//       'ANGUSTIAS ZONE IV',
//       'ANOS',
//       'AYAAS',
//       'BAGUIO',
//       'BANILAD',
//       'BUKAL IBABA',
//       'BUKAL ILAYA',
//       'CALANTAS',
//       'CALUMPANG',
//       'CAMAYSA',
//       'DAPDAP',
//       'DOMOIT KANLURAN',
//       'DOMOIT SILANGAN',
//       'GIBANGA',
//       'IBAS',
//       'ILASAN IBABA',
//       'ILASAN ILAYA',
//       'IPILAN',
//       'ISABANG',
//       'KATIGAN KANLURAN',
//       'KATIGAN SILANGAN',
//       'LAKAWAN',
//       'LALO',
//       'LAWIGUE',
//       'LITA (POB.)',
//       'MALAOA',
//       'MASIN',
//       'MATE',
//       'MATEUNA',
//       'MAYOWE',
//       'NANGKA IBABA',
//       'NANGKA ILAYA',
//       'OPIAS',
//       'PALALE IBABA',
//       'PALALE ILAYA',
//       'PALALE KANLURAN',
//       'PALALE SILANGAN',
//       'PANDAKAKI',
//       'POOK',
//       'POTOL',
//       'SAN DIEGO ZONE I (POB.)',
//       'SAN DIEGO ZONE II',
//       'SAN DIEGO ZONE III',
//       'SAN DIEGO ZONE IV',
//       'SAN ISIDRO ZONE I (POB.)',
//       'SAN ISIDRO ZONE II',
//       'SAN ISIDRO ZONE III',
//       'SAN ISIDRO ZONE IV',
//       'SAN ROQUE ZONE I (POB.)',
//       'SAN ROQUE ZONE II',
//       'TALOLONG',
//       'TAMLONG',
//       'TONGKO',
//       'VALENCIA',
//       'WAKAS',
//     ],
//   },
//   TIAONG: {
//     province: 'QUEZON',
//     municipality: 'TIAONG',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'ANASTACIA (TAGBAK)',
//       'AQUINO',
//       'AYUSAN I',
//       'AYUSAN II',
//       'BARANGAY I (POB.)',
//       'BARANGAY II (POB.)',
//       'BARANGAY III (POB.)',
//       'BARANGAY IV (POB.)',
//       'BEHIA',
//       'BUKAL',
//       'BULA',
//       'BULAKIN',
//       'CABATANG',
//       'CABAY',
//       'DEL ROSARIO',
//       'LAGALAG',
//       'LALIG',
//       'LUMINGON',
//       'LUSACAN',
//       'PAIISA',
//       'PALAGARAN',
//       'QUIPOT',
//       'SAN AGUSTIN',
//       'SAN FRANCISCO',
//       'SAN ISIDRO',
//       'SAN JOSE',
//       'SAN JUAN',
//       'SAN PEDRO',
//       'TAGBAKIN',
//       'TALISAY',
//       'TAMISIAN',
//     ],
//   },
//   UNISAN: {
//     province: 'QUEZON',
//     municipality: 'UNISAN',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'ALMACEN',
//       'BALAGTAS',
//       'BALANACAN',
//       'BONIFACIO',
//       'BULO IBABA',
//       'BULO ILAYA',
//       'BURGOS',
//       'CABULIHAN IBABA',
//       'CABULIHAN ILAYA',
//       'CAIGDAL',
//       'F. DE JESUS (POB.)',
//       'GENERAL LUNA',
//       'KALILAYAN IBABA',
//       'KALILAYAN ILAYA',
//       'MABINI',
//       'MAIROK IBABA',
//       'MAIROK ILAYA',
//       'MALVAR',
//       'MAPUTAT',
//       'MULIGUIN',
//       'PAGAGUASAN',
//       'PANAON IBABA',
//       'PANAON ILAYA',
//       'PLARIDEL',
//       'POCTOL',
//       'PUNTA',
//       'R. LAPU-LAPU (POB.)',
//       'R. MAGSAYSAY (POB.)',
//       'RAJA SOLIMAN (POB.)',
//       'RIZAL IBABA',
//       'RIZAL ILAYA',
//       'SAN ROQUE',
//       'SOCORRO',
//       'TAGUMPAY',
//       'TUBAS',
//       'TUBIGAN',
//     ],
//   },
//   ANGONO: {
//     province: 'RIZAL',
//     municipality: 'ANGONO',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'BAGUMBAYAN',
//       'KALAYAAN',
//       'MAHABANG PARANG',
//       'POBLACION IBABA',
//       'POBLACION ITAAS',
//       'SAN ISIDRO',
//       'SAN PEDRO',
//       'SAN ROQUE',
//       'SAN VICENTE',
//       'SANTO NIÑO',
//     ],
//   },
//   BARAS: {
//     province: 'CATANDUANES',
//     municipality: 'BARAS',
//     region: 'REGION V',
//     barangay_list: [
//       'ABIHAO',
//       'AGBAN',
//       'BAGONG SIRANG',
//       'BATOLINAO',
//       'BENTICAYAN',
//       'BUENAVISTA',
//       'CARAGUMIHAN',
//       'DANAO',
//       'EASTERN POBLACION',
//       'GINITLIGAN',
//       'GUINSAANAN',
//       'J. M. ALBERTO',
//       'MACUTAL',
//       'MONING',
//       'NAGBARORONG',
//       'OSMEÑA',
//       'P. TESTON',
//       'PANIQUIHAN',
//       'PURARAN',
//       'PUTSAN',
//       'QUEZON',
//       'RIZAL',
//       'SAGRADA',
//       'SALVACION',
//       'SAN LORENZO',
//       'SAN MIGUEL',
//       'SANTA MARIA',
//       'TILOD',
//       'WESTERN POBLACION',
//     ],
//   },
//   BINANGONAN: {
//     province: 'RIZAL',
//     municipality: 'BINANGONAN',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'BANGAD',
//       'BATINGAN',
//       'BILIBIRAN',
//       'BINITAGAN',
//       'BOMBONG',
//       'BUHANGIN',
//       'CALUMPANG',
//       'GINOONG SANAY',
//       'GULOD',
//       'HABAGATAN',
//       'ITHAN',
//       'JANOSA',
//       'KALAWAAN',
//       'KALINAWAN',
//       'KASILE',
//       'KAYTOME',
//       'KINABOOGAN',
//       'KINAGATAN',
//       'LAYUNAN (POB.)',
//       'LIBID (POB.)',
//       'LIBIS (POB.)',
//       'LIMBON-LIMBON',
//       'LUNSAD',
//       'MACAMOT',
//       'MAHABANG PARANG',
//       'MALAKABAN',
//       'MAMBOG',
//       'PAG-ASA',
//       'PALANGOY',
//       'PANTOK',
//       'PILA PILA',
//       'PINAGDILAWAN',
//       'PIPINDAN',
//       'RAYAP',
//       'SAN CARLOS',
//       'SAPANG',
//       'TABON',
//       'TAGPOS',
//       'TATALA',
//       'TAYUMAN',
//     ],
//   },
//   CAINTA: {
//     province: 'RIZAL',
//     municipality: 'CAINTA',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'SAN ANDRES (POB.)',
//       'SAN ISIDRO',
//       'SAN JUAN',
//       'SAN ROQUE',
//       'SANTA ROSA',
//       'SANTO DOMINGO',
//       'SANTO NIÑO',
//     ],
//   },
//   CARDONA: {
//     province: 'RIZAL',
//     municipality: 'CARDONA',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'BALIBAGO',
//       'BOOR',
//       'CALAHAN',
//       'DALIG',
//       'DEL REMEDIO (POB.)',
//       'IGLESIA (POB.)',
//       'LAMBAC',
//       'LOOC',
//       'MALANGGAM-CALUBACAN',
//       'NAGSULO',
//       'NAVOTAS',
//       'PATUNHAY',
//       'REAL (POB.)',
//       'SAMPAD',
//       'SAN ROQUE (POB.)',
//       'SUBAY',
//       'TICULIO',
//       'TUNA',
//     ],
//   },
//   'ANTIPOLO': {
//     province: 'RIZAL',
//     municipality: 'ANTIPOLO',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'BAGONG NAYON',
//       'BEVERLY HILLS',
//       'CALAWIS',
//       'CUPANG',
//       'DALIG',
//       'DELA PAZ (POB.)',
//       'INARAWAN',
//       'MAMBUGAN',
//       'MAYAMOT',
//       'MUNTINGDILAW',
//       'SAN ISIDRO (POB.)',
//       'SAN JOSE (POB.)',
//       'SAN JUAN',
//       'SAN LUIS',
//       'SAN ROQUE (POB.)',
//       'SANTA CRUZ',
//     ],
//   },
//   'JALA-JALA': {
//     province: 'RIZAL',
//     municipality: 'JALA-JALA',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'BAGUMBONG',
//       'BAYUGO',
//       'LUBO',
//       'PAALAMAN',
//       'PAGKALINAWAN',
//       'PALAYPALAY',
//       'PUNTA',
//       'SECOND DISTRICT (POB.)',
//       'SIPSIPIN',
//       'SPECIAL DISTRICT (POB.)',
//       'THIRD DISTRICT (POB.)',
//     ],
//   },
//   PILILLA: {
//     province: 'RIZAL',
//     municipality: 'PILILLA',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'BAGUMBAYAN (POB.)',
//       'HALAYHAYIN',
//       'HULO (POB.)',
//       'IMATONG (POB.)',
//       'MALAYA',
//       'NIOGAN',
//       'QUISAO',
//       'TAKUNGAN (POB.)',
//       'WAWA (POB.)',
//     ],
//   },
//   'RODRIGUEZ (MONTALBAN)': {
//     province: 'RIZAL',
//     municipality: 'RODRIGUEZ (MONTALBAN)',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'BALITE (POB.)',
//       'BURGOS',
//       'GERONIMO',
//       'MACABUD',
//       'MANGGAHAN',
//       'MASCAP',
//       'PURAY',
//       'ROSARIO',
//       'SAN ISIDRO',
//       'SAN JOSE',
//       'SAN RAFAEL',
//     ],
//   },
//   TANAY: {
//     province: 'RIZAL',
//     municipality: 'TANAY',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'CAYABU',
//       'CUYAMBAY',
//       'DARAITAN',
//       'KATIPUNAN-BAYAN (POB.)',
//       'KAYBUTO (POB.)',
//       'LAIBAN',
//       'MADILAY-DILAY',
//       'MAG-AMPON (POB.)',
//       'MAMUYAO',
//       'PINAGKAMALIGAN (POB.)',
//       'PLAZA ALDEA (POB.)',
//       'SAMPALOC',
//       'SAN ANDRES',
//       'SAN ISIDRO (POB.)',
//       'SANTA INEZ',
//       'SANTO NIÑO',
//       'TABING ILOG (POB.)',
//       'TANDANG KUTYO (POB.)',
//       'TINUCAN',
//       'WAWA (POB.)',
//     ],
//   },
//   TAYTAY: {
//     province: 'PALAWAN',
//     municipality: 'TAYTAY',
//     region: 'REGION IV-B',
//     barangay_list: [
//       'ABONGAN',
//       'ALACALIAN',
//       'BANBANAN',
//       'BANTULAN',
//       'BARAS (PANGPANG)',
//       'BATAS',
//       'BATO',
//       'BETON',
//       'BUSY BEES',
//       'CALAWAG',
//       'CASIAN',
//       'CATABAN',
//       'DEBANGAN',
//       'DEPLA',
//       'LIBERTAD',
//       'LIMINANGCONG',
//       'MEYTEGUED',
//       'MINAPLA',
//       'NEW GUINLO',
//       'OLD GUINLO',
//       'PAGLAUM',
//       'PALY (PALY ISLAND)',
//       'PAMANTOLON',
//       'PANCOL',
//       'POBLACION',
//       'PULARAQUEN (CANIQUE)',
//       'SAN JOSE',
//       'SANDOVAL',
//       'SILANGA',
//       'TALOG',
//       'TUMBOD',
//     ],
//   },
//   TERESA: {
//     province: 'RIZAL',
//     municipality: 'TERESA',
//     region: 'REGION IV-A',
//     barangay_list: [
//       'BAGUMBAYAN',
//       'CALUMPANG SANTO CRISTO',
//       'DALIG',
//       'DULUMBAYAN',
//       'MAY-IBA',
//       'POBLACION',
//       'PRINZA',
//       'SAN GABRIEL',
//       'SAN ROQUE',
//     ],
//   },
//   BOAC: {
//     province: 'MARINDUQUE',
//     municipality: 'BOAC',
//     region: 'REGION IV-B',
//     barangay_list: [
//       'AGOT',
//       'AGUMAYMAYAN',
//       'AMOINGON',
//       'APITONG',
//       'BALAGASAN',
//       'BALARING',
//       'BALIMBING',
//       'BALOGO',
//       'BAMBAN',
//       'BANGBANGALON',
//       'BANTAD',
//       'BANTAY',
//       'BAYUTI',
//       'BINUNGA',
//       'BOI',
//       'BOTON',
//       'BULIASNIN',
//       'BUNGANAY',
//       'CAGANHAO',
//       'CANAT',
//       'CATUBUGAN',
//       'CAWIT',
//       'DAIG',
//       'DAYPAY',
//       'DUYAY',
//       'HINAPULAN',
//       'IHATUB',
//       'ISOK I (POB.)',
//       'ISOK II POB. (KALAMIAS)',
//       'LAYLAY',
//       'LUPAC',
//       'MAHINHIN',
//       'MAINIT',
//       'MALBOG',
//       'MALIGAYA',
//       'MALUSAK (POB.)',
//       'MANSIWAT',
//       'MATAAS NA BAYAN (POB.)',
//       'MAYBO',
//       'MERCADO (POB.)',
//       'MURALLON (POB.)',
//       'OGBAC',
//       'PAWA',
//       'PILI',
//       'POCTOY',
//       'PORAS',
//       'PUTING BUHANGIN',
//       'PUYOG',
//       'SABONG',
//       'SAN MIGUEL (POB.)',
//       'SANTOL',
//       'SAWI',
//       'TABI',
//       'TABIGUE',
//       'TAGWAK',
//       'TAMBUNAN',
//       'TAMPUS (POB.)',
//       'TANZA',
//       'TUGOS',
//       'TUMAGABOK',
//       'TUMAPON',
//     ],
//   },
//   GASAN: {
//     province: 'MARINDUQUE',
//     municipality: 'GASAN',
//     region: 'REGION IV-B',
//     barangay_list: [
//       'ANTIPOLO',
//       'BACHAO IBABA',
//       'BACHAO ILAYA',
//       'BACONGBACONG',
//       'BAHI',
//       'BANGBANG',
//       'BANOT',
//       'BANUYO',
//       'BARANGAY I (POB.)',
//       'BARANGAY II (POB.)',
//       'BARANGAY III (POB.)',
//       'BOGNUYAN',
//       'CABUGAO',
//       'DAWIS',
//       'DILI',
//       'LIBTANGIN',
//       'MAHUNIG',
//       'MANGILIOL',
//       'MASIGA',
//       'MATANDANG GASAN',
//       'PANGI',
//       'PINGAN',
//       'TABIONAN',
//       'TAPUYAN',
//       'TIGUION',
//     ],
//   },
//   MOGPOG: {
//     province: 'MARINDUQUE',
//     municipality: 'MOGPOG',
//     region: 'REGION IV-B',
//     barangay_list: [
//       'ANAPOG-SIBUCAO',
//       'ARGAO',
//       'BALANACAN',
//       'BANTO',
//       'BINTAKAY',
//       'BOCBOC',
//       'BUTANSAPA',
//       'CANDAHON',
//       'CAPAYANG',
//       'DANAO',
//       'DULONG BAYAN (POB.)',
//       'GITNANG BAYAN (POB.)',
//       'GUISIAN',
//       'HINADHARAN',
//       'HINANGGAYON',
//       'INO',
//       'JANAGDONG',
//       'LAMESA',
//       'LAON',
//       'MAGAPUA',
//       'MALAYAK',
//       'MALUSAK',
//       'MAMPAITAN',
//       'MANGYAN-MABABAD',
//       'MARKET SITE (POB.)',
//       'MATAAS NA BAYAN',
//       'MENDEZ',
//       'NANGKA I',
//       'NANGKA II',
//       'PAYE',
//       'PILI',
//       'PUTING BUHANGIN',
//       'SAYAO',
//       'SILANGAN',
//       'SUMANGGA',
//       'TARUG',
//       'VILLA MENDEZ (POB.)',
//     ],
//   },
//   TORRIJOS: {
//     province: 'MARINDUQUE',
//     municipality: 'TORRIJOS',
//     region: 'REGION IV-B',
//     barangay_list: [
//       'BANGWAYIN',
//       'BAYAKBAKIN',
//       'BOLO',
//       'BONLIW',
//       'BUANGAN',
//       'CABUYO',
//       'CAGPO',
//       'DAMPULAN',
//       'KAY DUKE',
//       'MABUHAY',
//       'MAKAWAYAN',
//       'MALIBAGO',
//       'MALINAO',
//       'MARANLIG',
//       'MARLANGGA',
//       'MATUYATUYA',
//       'NANGKA',
//       'PAKASKASAN',
//       'PAYANAS',
//       'POBLACION',
//       'POCTOY',
//       'SIBUYAO',
//       'SUHA',
//       'TALAWAN',
//       'TIGWI',
//     ],
//   },
//   'ABRA DE ILOG': {
//     province: 'OCCIDENTAL MINDORO',
//     municipality: 'ABRA DE ILOG',
//     region: 'REGION IV-B',
//     barangay_list: [
//       'ARMADO',
//       'BALAO',
//       'CABACAO',
//       'LUMANGBAYAN',
//       'POBLACION',
//       'SAN VICENTE',
//       'STA. MARIA',
//       'TIBAG',
//       'UDALO (CAMURONG)',
//       'WAWA',
//     ],
//   },
//   CALINTAAN: {
//     province: 'OCCIDENTAL MINDORO',
//     municipality: 'CALINTAAN',
//     region: 'REGION IV-B',
//     barangay_list: [
//       'CONCEPCION',
//       'IRIRON',
//       'MALPALON',
//       'NEW DAGUPAN',
//       'POBLACION',
//       'POYPOY',
//       'TANYAG',
//     ],
//   },
//   LOOC: {
//     province: 'ROMBLON',
//     municipality: 'LOOC',
//     region: 'REGION IV-B',
//     barangay_list: [
//       'AGOJO',
//       'BALATUCAN',
//       'BUENAVISTA',
//       'CAMANDAO',
//       'GUINHAYAAN',
//       'LIMON NORTE',
//       'LIMON SUR',
//       'MANHAC',
//       'PILI',
//       'POBLACION',
//       'PUNTA',
//       'TUGUIS',
//     ],
//   },
//   LUBANG: {
//     province: 'OCCIDENTAL MINDORO',
//     municipality: 'LUBANG',
//     region: 'REGION IV-B',
//     barangay_list: [
//       'ARAW AT BITUIN (POB.)',
//       'BAGONG SIKAT (POB.)',
//       'BANAAG AT PAG-ASA (POB.)',
//       'BINAKAS',
//       'CABRA',
//       'LIKAS NG SILANGAN (POB.)',
//       'MAGINHAWA (POB.)',
//       'MALIGAYA',
//       'MALIIG',
//       'NINIKAT NG PAG-ASA (POB.)',
//       'PARAISO (POB.)',
//       'SURVILLE (POB.)',
//       'TAGBAC',
//       'TANGAL',
//       'TILIK',
//       'VIGO',
//     ],
//   },
//   MAMBURAO: {
//     province: 'OCCIDENTAL MINDORO',
//     municipality: 'MAMBURAO',
//     region: 'REGION IV-B',
//     barangay_list: [
//       'BALANSAY',
//       'FATIMA (TII)',
//       'PAYOMPON',
//       'POBLACION 1 (BARANGAY 1)',
//       'POBLACION 2 (BARANGAY 2)',
//       'POBLACION 3 (BARANGAY 3)',
//       'POBLACION 4 (BARANGAY 4)',
//       'POBLACION 5 (BARANGAY 5)',
//       'POBLACION 6 (BARANGAY 6)',
//       'POBLACION 7 (BARANGAY 7)',
//       'POBLACION 8 (BARANGAY 8)',
//       'SAN LUIS (LIGANG)',
//       'TALABAAN',
//       'TANGKALAN',
//       'TAYAMAAN',
//     ],
//   },
//   PALUAN: {
//     province: 'OCCIDENTAL MINDORO',
//     municipality: 'PALUAN',
//     region: 'REGION IV-B',
//     barangay_list: [
//       'ALIPAOY',
//       'BAGONG SILANG POB. (BGY 5)',
//       'HANDANG TUMULONG POB. (BGY 2)',
//       'HARRISON',
//       'LUMANGBAYAN',
//       'MANANAO',
//       'MAPALAD POB. (BGY 1)',
//       'MARIKIT',
//       'PAG-ASA NG BAYAN POB. (BGY 4)',
//       'SAN JOSE POB. (BGY 6)',
//       'SILAHIS NG PAG-ASA POB. (BGY 3)',
//       'TUBILI',
//     ],
//   },
//   SABLAYAN: {
//     province: 'OCCIDENTAL MINDORO',
//     municipality: 'SABLAYAN',
//     region: 'REGION IV-B',
//     barangay_list: [
//       'BATONG BUHAY',
//       'BUENAVISTA',
//       'BURGOS',
//       'CLAUDIO SALGADO',
//       'GENERAL EMILIO AGUINALDO',
//       'IBUD',
//       'ILVITA',
//       'LAGNAS',
//       'LIGAYA',
//       'MALISBONG',
//       'PAETAN',
//       'PAG-ASA',
//       'POBLACION (LUMANGBAYAN)',
//       'SAN AGUSTIN',
//       'SAN FRANCISCO',
//       'SAN NICOLAS',
//       'SAN VICENTE',
//       'SANTA LUCIA',
//       'SANTO NIÑO',
//       'TAGUMPAY',
//       'TUBAN',
//       'VICTORIA',
//     ],
//   },
//   BACO: {
//     province: 'ORIENTAL MINDORO',
//     municipality: 'BACO',
//     region: 'REGION IV-B',
//     barangay_list: [
//       'ALAG',
//       'BANGKATAN',
//       'BARAS (MANGYAN MINORITY)',
//       'BAYANAN',
//       'BURBULI',
//       'CATWIRAN I',
//       'CATWIRAN II',
//       'DULANGAN I',
//       'DULANGAN II',
//       'LANTUYANG (MANGYAN MINORITY)',
//       'LUMANG BAYAN',
//       'MALAPAD',
//       'MANGANGAN I',
//       'MANGANGAN II',
//       'MAYABIG',
//       'PAMBISAN',
//       'POBLACION',
//       'PULANG-TUBIG',
//       'PUTICAN-CABULO',
//       'SAN ANDRES',
//       'SAN IGNACIO (DULANGAN III)',
//       'SANTA CRUZ',
//       'SANTA ROSA I',
//       'SANTA ROSA II',
//       'TABON-TABON',
//       'TAGUMPAY',
//       'WATER',
//     ],
//   },
//   BANSUD: {
//     province: 'ORIENTAL MINDORO',
//     municipality: 'BANSUD',
//     region: 'REGION IV-B',
//     barangay_list: [
//       'ALCADESMA',
//       'BATO',
//       'CONRAZON',
//       'MALO',
//       'MANIHALA',
//       'PAG-ASA',
//       'POBLACION',
//       'PROPER BANSUD',
//       'PROPER TIGUISAN',
//       'ROSACARA',
//       'SALCEDO',
//       'SUMAGUI',
//       'VILLA PAG-ASA',
//     ],
//   },
//   BONGABONG: {
//     province: 'ORIENTAL MINDORO',
//     municipality: 'BONGABONG',
//     region: 'REGION IV-B',
//     barangay_list: [
//       'ANILAO',
//       'APLAYA',
//       'BAGUMBAYAN I',
//       'BAGUMBAYAN II',
//       'BATANGAN',
//       'BUKAL',
//       'CAMANTIGUE',
//       'CARMUNDO',
//       'CAWAYAN',
//       'DAYHAGAN',
//       'FORMON',
//       'HAGAN',
//       'HAGUPIT',
//       'IPIL',
//       'KALIGTASAN',
//       'LABASAN',
//       'LABONAN',
//       'LIBERTAD',
//       'LISAP',
//       'LUNA',
//       'MALITBOG',
//       'MAPANG',
//       'MASAGUISI',
//       'MINA DE ORO',
//       'MORENTE',
//       'OGBOT',
//       'ORCONUMA',
//       'POBLACION',
//       'POLUSAHI',
//       'SAGANA',
//       'SAN ISIDRO',
//       'SAN JOSE',
//       'SAN JUAN',
//       'SANTA CRUZ',
//       'SIGANGE',
//       'TAWAS',
//     ],
//   },
//   'BULALACAO (SAN PEDRO)': {
//     province: 'ORIENTAL MINDORO',
//     municipality: 'BULALACAO (SAN PEDRO)',
//     region: 'REGION IV-B',
//     barangay_list: [
//       'BAGONG SIKAT',
//       'BALATASAN',
//       'BENLI (MANGYAN SETTLEMENT)',
//       'CABUGAO',
//       'CAMBUNANG (POB.)',
//       'CAMPAASAN (POB.)',
//       'MAASIN',
//       'MAUJAO',
//       'MILAGROSA (GUIOB)',
//       'NASUKOB (POB.)',
//       'POBLACION',
//       'SAN FRANCISCO (ALIMAWAN)',
//       'SAN ISIDRO',
//       'SAN JUAN',
//       'SAN ROQUE (BUYAYAO)',
//     ],
//   },
//   'CALAPAN': {
//     province: 'ORIENTAL MINDORO',
//     municipality: 'CALAPAN',
//     region: 'REGION IV-B',
//     barangay_list: [
//       'BALINGAYAN',
//       'BALITE',
//       'BARUYAN',
//       'BATINO',
//       'BAYANAN I',
//       'BAYANAN II',
//       'BIGA',
//       'BONDOC',
//       'BUCAYAO',
//       'BUHUAN',
//       'BULUSAN',
//       'CALERO (POB.)',
//       'CAMANSIHAN',
//       'CAMILMIL',
//       'CANUBING I',
//       'CANUBING II',
//       'COMUNAL',
//       'GUINOBATAN',
//       'GULOD',
//       'GUTAD',
//       'IBABA EAST (POB.)',
//       'IBABA WEST (POB.)',
//       'ILAYA (POB.)',
//       'LALUD',
//       'LAZARETO',
//       'LIBIS (POB.)',
//       'LUMANG BAYAN',
//       'MAHAL NA PANGALAN',
//       'MAIDLANG',
//       'MALAD',
//       'MALAMIG',
//       'MANAGPI',
//       'MASIPIT',
//       'NAG-IBA I',
//       'NAG-IBA II',
//       'NAVOTAS',
//       'PACHOCA',
//       'PALHI',
//       'PANGGALAAN',
//       'PARANG',
//       'PATAS',
//       'PERSONAS',
//       'PUTINGTUBIG',
//       'SALONG',
//       'SAN ANTONIO',
//       'SAN VICENTE CENTRAL (POB.)',
//       'SAN VICENTE EAST (POB.)',
//       'SAN VICENTE NORTH (POB.)',
//       'SAN VICENTE SOUTH (POB.)',
//       'SAN VICENTE WEST (POB.)',
//       'SANTA CRUZ',
//       'SANTA ISABEL',
//       'SANTA MARIA VILLAGE',
//       'SANTA RITA (BUNGAHAN)',
//       'SANTO NIÑO',
//       'SAPUL',
//       'SILONAY',
//       'SUQUI',
//       'TAWAGAN',
//       'TAWIRAN',
//       'TIBAG',
//       'WAWA',
//     ],
//   },
//   GLORIA: {
//     province: 'ORIENTAL MINDORO',
//     municipality: 'GLORIA',
//     region: 'REGION IV-B',
//     barangay_list: [
//       'AGOS',
//       'AGSALIN',
//       'ALMA VILLA',
//       'ANDRES BONIFACIO',
//       'BALETE',
//       'BANUS',
//       'BANUTAN',
//       'BULAKLAKAN',
//       'BUONG LUPA',
//       'GAUDENCIO ANTONINO',
//       'GUIMBONAN',
//       'KAWIT',
//       'LUCIO LAUREL',
//       'MACARIO ADRIATICO',
//       'MALAMIG',
//       'MALAYONG',
//       'MALIGAYA (POB.)',
//       'MALUBAY',
//       'MANGUYANG',
//       'MARAGOOC',
//       'MIRAYAN',
//       'NARRA',
//       'PAPANDUNGIN',
//       'SAN ANTONIO',
//       'SANTA MARIA',
//       'SANTA THERESA',
//       'TAMBONG',
//     ],
//   },
//   MANSALAY: {
//     province: 'ORIENTAL MINDORO',
//     municipality: 'MANSALAY',
//     region: 'REGION IV-B',
//     barangay_list: [
//       'B. DEL MUNDO',
//       'BALUGO',
//       'BONBON',
//       'BUDBURAN',
//       'CABALWA',
//       'DON PEDRO',
//       'MALIWANAG',
//       'MANAUL',
//       'PANAYTAYAN',
//       'POBLACION',
//       'ROMA',
//       'SANTA BRIGIDA',
//       'SANTA MARIA',
//       'SANTA TERESITA',
//       'VILLA CELESTIAL',
//       'WASIG',
//       'WAYGAN',
//     ],
//   },
//   NAUJAN: {
//     province: 'ORIENTAL MINDORO',
//     municipality: 'NAUJAN',
//     region: 'REGION IV-B',
//     barangay_list: [
//       'ADRIALUNA',
//       'ANDRES ILAGAN (MAG-ASAWANG TUBIG)',
//       'ANTIPOLO',
//       'APITONG',
//       'ARANGIN',
//       'AURORA',
//       'BACUNGAN',
//       'BAGONG BUHAY',
//       'BALITE',
//       'BANCURO',
//       'BANUTON',
//       'BARCENAGA',
//       'BAYANI',
//       'BUHANGIN',
//       'CABURO',
//       'CONCEPCION',
//       'DAO',
//       'DEL PILAR',
//       'ESTRELLA',
//       'EVANGELISTA',
//       'GAMAO',
//       'GENERAL ESCO',
//       'HERRERA',
//       'INARAWAN',
//       'KALINISAN',
//       'LAGUNA',
//       'MABINI',
//       'MAGTIBAY',
//       'MAHABANG PARANG',
//       'MALAYA',
//       'MALINAO',
//       'MALVAR',
//       'MASAGANA',
//       'MASAGUING',
//       'MELGAR A',
//       'MELGAR B',
//       'METOLZA',
//       'MONTELAGO',
//       'MONTEMAYOR',
//       'MOTODERAZO',
//       'MULAWIN',
//       'NAG-IBA I',
//       'NAG-IBA II',
//       'PAGKAKAISA',
//       'PAITAN',
//       'PANIQUIAN',
//       'PINAGSABANGAN I',
//       'PINAGSABANGAN II',
//       'PIÑAHAN',
//       'POBLACION I (BARANGAY I)',
//       'POBLACION II (BARANGAY II)',
//       'POBLACION III (BARANGAY III)',
//       'SAMPAGUITA',
//       'SAN AGUSTIN I',
//       'SAN AGUSTIN II',
//       'SAN ANDRES',
//       'SAN ANTONIO',
//       'SAN CARLOS',
//       'SAN ISIDRO',
//       'SAN JOSE',
//       'SAN LUIS',
//       'SAN NICOLAS',
//       'SAN PEDRO',
//       'SANTA CRUZ',
//       'SANTA ISABEL',
//       'SANTA MARIA',
//       'SANTIAGO',
//       'SANTO NIÑO',
//       'TAGUMPAY',
//       'TIGKAN',
//     ],
//   },
//   PINAMALAYAN: {
//     province: 'ORIENTAL MINDORO',
//     municipality: 'PINAMALAYAN',
//     region: 'REGION IV-B',
//     barangay_list: [
//       'ANOLING',
//       'BACUNGAN',
//       'BANGBANG',
//       'BANILAD',
//       'BULI',
//       'CACAWAN',
//       'CALINGAG',
//       'DEL RAZON',
//       'GUINHAWA',
//       'INCLANAY',
//       'LUMANGBAYAN',
//       'MALAYA',
//       'MALIANGCOG',
//       'MANINGCOL',
//       'MARAYOS',
//       'MARFRANCISCO',
//       'NABUSLOT',
//       'PAGALAGALA',
//       'PALAYAN',
//       'PAMBISAN MALAKI',
//       'PAMBISAN MUNTI',
//       'PANGGULAYAN',
//       'PAPANDAYAN',
//       'PILI',
//       'QUINABIGAN',
//       'RANZO',
//       'ROSARIO',
//       'SABANG',
//       'SANTA ISABEL',
//       'SANTA MARIA',
//       'SANTA RITA',
//       'SANTO NIÑO',
//       'WAWA',
//       'ZONE I (POB.)',
//       'ZONE II (POB.)',
//       'ZONE III (POB.)',
//       'ZONE IV (POB.)',
//     ],
//   },
//   POLA: {
//     province: 'ORIENTAL MINDORO',
//     municipality: 'POLA',
//     region: 'REGION IV-B',
//     barangay_list: [
//       'BACAWAN',
//       'BACUNGAN',
//       'BATUHAN',
//       'BAYANAN',
//       'BIGA',
//       'BUHAY NA TUBIG',
//       'CALIMA',
//       'CALUBASANHON',
//       'CAMPAMENTO',
//       'CASILIGAN',
//       'MALIBAGO',
//       'MALUANLUAN',
//       'MATULATULA',
//       'MISONG',
//       'PAHILAHAN',
//       'PANIKIHAN',
//       'PULA',
//       'PUTING CACAO',
//       'TAGBAKIN',
//       'TAGUMPAY',
//       'TIGUIHAN',
//       'ZONE I (POB.)',
//       'ZONE II (POB.)',
//     ],
//   },
//   'PUERTO GALERA': {
//     province: 'ORIENTAL MINDORO',
//     municipality: 'PUERTO GALERA',
//     region: 'REGION IV-B',
//     barangay_list: [
//       'ANINUAN',
//       'BACLAYAN',
//       'BALATERO',
//       'DULANGAN',
//       'PALANGAN',
//       'POBLACION',
//       'SABANG',
//       'SAN ANTONIO',
//       'SAN ISIDRO',
//       'SANTO NIÑO',
//       'SINANDIGAN',
//       'TABINAY',
//       'VILLAFLOR',
//     ],
//   },
//   'SAN TEODORO': {
//     province: 'ORIENTAL MINDORO',
//     municipality: 'SAN TEODORO',
//     region: 'REGION IV-B',
//     barangay_list: [
//       'BIGAAN',
//       'CAAGUTAYAN',
//       'CALANGATAN',
//       'CALSAPA',
//       'ILAG',
//       'LUMANGBAYAN',
//       'POBLACION',
//       'TACLIGAN',
//     ],
//   },
//   ABORLAN: {
//     province: 'PALAWAN',
//     municipality: 'ABORLAN',
//     region: 'REGION IV-B',
//     barangay_list: [
//       'APO-APORAWAN',
//       'APOC-APOC',
//       'APORAWAN',
//       'BARAKE',
//       'CABIGAAN',
//       'CULANDANUM',
//       'GOGOGNAN',
//       'IRAAN',
//       'ISAUB',
//       'JOSE RIZAL',
//       'MABINI',
//       'MAGBABADIL',
//       'PLARIDEL',
//       'POBLACION',
//       'RAMON MAGSAYSAY',
//       'SAGPANGAN',
//       'SAN JUAN',
//       'TAGPAIT',
//       'TIGMAN',
//     ],
//   },
//   AGUTAYA: {
//     province: 'PALAWAN',
//     municipality: 'AGUTAYA',
//     region: 'REGION IV-B',
//     barangay_list: [
//       'ABAGAT (POB.)',
//       'ALGECIRAS',
//       'BANGCAL (POB.)',
//       'CAMBIAN (POB.)',
//       'CONCEPCION',
//       'DIIT',
//       'MARACANAO',
//       'MATARAWIS',
//       'VILLAFRIA',
//       'VILLASOL',
//     ],
//   },
//   ARACELI: {
//     province: 'PALAWAN',
//     municipality: 'ARACELI',
//     region: 'REGION IV-B',
//     barangay_list: [
//       'BALOGO',
//       'DAGMAN',
//       'DALAYAWON',
//       'LUMACAD',
//       'MADOLDOLON',
//       'MAURINGUEN',
//       'OSMEÑA',
//       'POBLACION (CENTRO)',
//       'SAN JOSE DE ORO',
//       'SANTO NIÑO',
//       'TALOTO',
//       'TININTINAN',
//       'TUDELA (CALANDAGAN)',
//     ],
//   },
//   BALABAC: {
//     province: 'PALAWAN',
//     municipality: 'BALABAC',
//     region: 'REGION IV-B',
//     barangay_list: [
//       'AGUTAYAN',
//       'BANCALAAN',
//       'BUGSUK (NEW CAGAYANCILLO)',
//       'CATAGUPAN',
//       'INDALAWAN',
//       'MALAKING ILOG',
//       'MANGSEE',
//       'MELVILLE',
//       'PANDANAN',
//       'PASIG',
//       'POBLACION I',
//       'POBLACION II',
//       'POBLACION III',
//       'POBLACION IV',
//       'POBLACION V',
//       'POBLACION VI',
//       'RABOR',
//       'RAMOS',
//       'SALANG',
//       'SEBARING',
//     ],
//   },
//   BATARAZA: {
//     province: 'PALAWAN',
//     municipality: 'BATARAZA',
//     region: 'REGION IV-B',
//     barangay_list: [
//       'BONO-BONO',
//       'BULALACAO',
//       'BULILUYAN',
//       'CULANDANUM',
//       'IGANG-IGANG',
//       'INOGBONG',
//       'IWAHIG',
//       'MALIHUD',
//       'MALITUB',
//       'MARANGAS (POB.)',
//       'OCAYAN',
//       'PURING',
//       'RIO TUBA',
//       'SANDOVAL',
//       'SAPA',
//       'SARONG',
//       'SUMBILING',
//       'TABUD',
//       'TAGNATO',
//       'TAGOLANGO',
//       'TARATAK',
//       'TARUSAN',
//     ],
//   },
//   "BROOKE'S POINT": {
//     province: 'PALAWAN',
//     municipality: "BROOKE'S POINT",
//     region: 'REGION IV-B',
//     barangay_list: [
//       'AMAS',
//       'ARIBUNGOS',
//       'BARONG-BARONG',
//       'CALASAGUEN',
//       'IMULNOD',
//       'IPILAN',
//       'MAASIN',
//       'MAINIT',
//       'MALIS',
//       'MAMBALOT',
//       'ORING-ORING',
//       'PANGOBILIAN',
//       'POBLACION I',
//       'POBLACION II',
//       'SALOGON',
//       'SAMAREÑANA',
//       'SARAZA',
//       'TUBTUB',
//     ],
//   },
//   BUSUANGA: {
//     province: 'PALAWAN',
//     municipality: 'BUSUANGA',
//     region: 'REGION IV-B',
//     barangay_list: [
//       'BOGTONG',
//       'BULUANG',
//       'CHEEY',
//       'CONCEPCION',
//       'MAGLALAMBAY',
//       'NEW BUSUANGA (POB.)',
//       'OLD BUSUANGA',
//       'PANLAITAN',
//       'QUEZON',
//       'SAGRADA',
//       'SALVACION',
//       'SAN ISIDRO',
//       'SAN RAFAEL',
//       'SANTO NIÑO',
//     ],
//   },
//   CAGAYANCILLO: {
//     province: 'PALAWAN',
//     municipality: 'CAGAYANCILLO',
//     region: 'REGION IV-B',
//     barangay_list: [
//       'BANTAYAN (POB.)',
//       'CALSADA (POB.)',
//       'CONVENTO (POB.)',
//       'LIPOT NORTH (POB.)',
//       'LIPOT SOUTH (POB.)',
//       'MAGSAYSAY',
//       'MAMPIO',
//       'NUSA',
//       'SANTA CRUZ',
//       'TACAS (POB.)',
//       'TALAGA',
//       'WAHIG (POB.)',
//     ],
//   },
//   CORON: {
//     province: 'PALAWAN',
//     municipality: 'CORON',
//     region: 'REGION IV-B',
//     barangay_list: [
//       'BANUANG DAAN',
//       'BARANGAY I (POB.)',
//       'BARANGAY II (POB.)',
//       'BARANGAY III (POB.)',
//       'BARANGAY IV (POB.)',
//       'BARANGAY V (POB.)',
//       'BARANGAY VI (POB.)',
//       'BINTUAN',
//       'BORAC',
//       'BUENAVISTA',
//       'BULALACAO',
//       'CABUGAO',
//       'DECABOBO',
//       'DECALACHAO',
//       'GUADALUPE',
//       'LAJALA',
//       'MALAWIG',
//       'MARCILLA',
//       'SAN JOSE',
//       'SAN NICOLAS',
//       'TAGUMPAY',
//       'TARA',
//       'TURDA',
//     ],
//   },
//   CULION: {
//     province: 'PALAWAN',
//     municipality: 'CULION',
//     region: 'REGION IV-B',
//     barangay_list: [
//       'BALALA',
//       'BALDAT',
//       'BINUDAC',
//       'BURABOD',
//       'CULANGO',
//       'DE CARABAO',
//       'GALOC',
//       'HALSEY',
//       'JARDIN',
//       'LIBIS',
//       'LUAC',
//       'MALAKING PATAG',
//       'OSMEÑA',
//       'TIZA',
//     ],
//   },
//   CUYO: {
//     province: 'PALAWAN',
//     municipality: 'CUYO',
//     region: 'REGION IV-B',
//     barangay_list: [
//       'BALADING',
//       'BANGCAL (POB.)',
//       'CABIGSING (POB.)',
//       'CABURIAN',
//       'CAPONAYAN',
//       'CATADMAN (POB.)',
//       'FUNDA',
//       'LAGAORIAO (POB.)',
//       'LUBID',
//       'LUNGSOD (POB.)',
//       'MANAMOC',
//       'MARINGIAN',
//       'PAWA',
//       'SAN CARLOS',
//       'SUBA',
//       'TENGA-TENGA (POB.)',
//       'TOCADAN (POB.)',
//     ],
//   },
//   DUMARAN: {
//     province: 'PALAWAN',
//     municipality: 'DUMARAN',
//     region: 'REGION IV-B',
//     barangay_list: [
//       'BACAO',
//       'BOHOL',
//       'CALASAG',
//       'CAPAYAS',
//       'CATEP',
//       'CULASIAN',
//       'DANLEG',
//       'DUMARAN (POB.)',
//       'ILIAN',
//       'ITANGIL',
//       'MAGSAYSAY',
//       'SAN JUAN',
//       'SANTA MARIA',
//       'SANTA TERESITA',
//       'SANTO TOMAS',
//       'TANATANAON',
//     ],
//   },
//   'EL NIDO (BACUIT)': {
//     province: 'PALAWAN',
//     municipality: 'EL NIDO (BACUIT)',
//     region: 'REGION IV-B',
//     barangay_list: [
//       'ABERAWAN',
//       'BAGONG BAYAN',
//       'BAROTUAN',
//       'BEBELADAN',
//       'BUCANA',
//       'BUENA SUERTE POB. (BARANGAY 2)',
//       'CORONG-CORONG POB. (BARANGAY 4)',
//       'MABINI',
//       'MALIGAYA POB. (BARANGAY 1)',
//       'MANLAG',
//       'MASAGANA POB. (BARANGAY 3)',
//       'NEW IBAJAY',
//       'PASADEÑA',
//       'SAN FERNANDO',
//       'SIBALTAN',
//       'TENEGUIBAN',
//       'VILLA LIBERTAD',
//       'VILLA PAZ',
//     ],
//   },
//   LINAPACAN: {
//     province: 'PALAWAN',
//     municipality: 'LINAPACAN',
//     region: 'REGION IV-B',
//     barangay_list: [
//       'BARANGONAN (ILOC)',
//       'CABUNLAWAN',
//       'CALIBANGBANGAN',
//       'DECABAITOT',
//       'MAROYOGROYOG',
//       'NANGALAO',
//       'NEW CULAYLAYAN',
//       'PICAL',
//       'SAN MIGUEL (POB.)',
//       'SAN NICOLAS',
//     ],
//   },
//   NARRA: {
//     province: 'PALAWAN',
//     municipality: 'NARRA',
//     region: 'REGION IV-B',
//     barangay_list: [
//       'ANTIPULUAN',
//       'ARAMAYWAN',
//       'BAGONG SIKAT',
//       'BATANG-BATANG',
//       'BATO-BATO',
//       'BURIRAO',
//       'CAGUISAN',
//       'CALATEGAS',
//       'DUMAGUEÑA',
//       'ELVITA',
//       'ESTRELLA VILLAGE',
//       'IPILAN',
//       'MALATGAO',
//       'MALINAO',
//       'NARRA (POB.)',
//       'PANACAN',
//       'PANACAN 2',
//       'PRINCESS URDUJA',
//       'SANDOVAL',
//       'TACRAS',
//       'TARITIEN',
//       'TERESA',
//       'TINAGONG DAGAT',
//     ],
//   },
//   'PUERTO PRINCESA': {
//     province: 'PALAWAN',
//     municipality: 'PUERTO PRINCESA',
//     region: 'REGION IV-B',
//     barangay_list: [
//       'BABUYAN',
//       'BACUNGAN',
//       'BAGONG BAYAN',
//       'BAGONG PAG-ASA (POB.)',
//       'BAGONG SIKAT (POB.)',
//       'BAGONG SILANG (POB.)',
//       'BAHILE',
//       'BANCAO-BANCAO',
//       'BARANGAY NG MGA MANGINGISDA',
//       'BINDUYAN',
//       'BUENAVISTA',
//       'CABAYUGAN',
//       'CONCEPCION',
//       'INAGAWAN',
//       'INAGAWAN SUB-COLONY',
//       'IRAWAN',
//       'IWAHIG (POB.)',
//       'KALIPAY (POB.)',
//       'KAMUNING',
//       'LANGOGAN',
//       'LIWANAG (POB.)',
//       'LUCBUAN',
//       'LUZVIMINDA',
//       'MABUHAY (POB.)',
//       'MACARASCAS',
//       'MAGKAKAIBIGAN (POB.)',
//       'MALIGAYA (POB.)',
//       'MANALO',
//       'MANDARAGAT',
//       'MANGGAHAN (POB.)',
//       'MANINGNING (POB.)',
//       'MAOYON',
//       'MARUFINAS',
//       'MARUYOGON',
//       'MASIGLA (POB.)',
//       'MASIKAP (POB.)',
//       'MASIPAG (POB.)',
//       'MATAHIMIK (POB.)',
//       'MATIYAGA (POB.)',
//       'MAUNLAD (POB.)',
//       'MILAGROSA (POB.)',
//       'MODEL (POB.)',
//       'MONTIBLE (POB.)',
//       'NAPSAN',
//       'NEW PANGGANGAN',
//       'PAGKAKAISA (POB.)',
//       'PRINCESA (POB.)',
//       'SALVACION',
//       'SAN JOSE',
//       'SAN MANUEL',
//       'SAN MIGUEL',
//       'SAN PEDRO',
//       'SAN RAFAEL',
//       'SANTA CRUZ',
//       'SANTA LOURDES',
//       'SANTA LUCIA (POB.)',
//       'SANTA MONICA',
//       'SEASIDE (POB.)',
//       'SICSICAN',
//       'SIMPOCAN',
//       'TAGABINIT',
//       'TAGBUROS',
//       'TAGUMPAY (POB.)',
//       'TANABAG',
//       'TANGLAW (POB.)',
//       'TINIGUIBAN',
//     ],
//   },
//   'RIZAL (MARCOS)': {
//     province: 'PALAWAN',
//     municipality: 'RIZAL (MARCOS)',
//     region: 'REGION IV-B',
//     barangay_list: [
//       'BUNOG',
//       'CAMPONG ULAY',
//       'CANDAWAGA',
//       'CANIPAAN',
//       'CULASIAN',
//       'IRAAN',
//       'LATUD',
//       'PANALINGAAN',
//       'PUNTA BAJA',
//       'RANSANG',
//       'TABURI',
//     ],
//   },
//   'SOFRONIO ESPAÑOLA': {
//     province: 'PALAWAN',
//     municipality: 'SOFRONIO ESPAÑOLA',
//     region: 'REGION IV-B',
//     barangay_list: [
//       'ABO-ABO',
//       'IRARAY',
//       'ISUMBO',
//       'LABOG',
//       'PANITIAN',
//       'PULOT CENTER',
//       'PULOT INTERIOR (PULOT II)',
//       'PULOT SHORE (PULOT I)',
//       'PUNANG',
//     ],
//   },
//   ALCANTARA: {
//     province: 'CEBU',
//     municipality: 'ALCANTARA',
//     region: 'REGION VII',
//     barangay_list: [
//       'CABADIANGAN',
//       'CABIL-ISAN',
//       'CANDABONG',
//       'LAWAAN',
//       'MANGA',
//       'PALANAS',
//       'POBLACION',
//       'POLO',
//       'SALAGMAYA',
//     ],
//   },
//   BANTON: {
//     province: 'ROMBLON',
//     municipality: 'BANTON',
//     region: 'REGION IV-B',
//     barangay_list: [
//       'BALOGO',
//       'BANICE',
//       'HAMBI-AN',
//       'LAGANG',
//       'LIBTONG',
//       'MAINIT',
//       'NABALAY',
//       'NASUNOGAN',
//       'POBLACION',
//       'SIBAY',
//       'TAN-AG',
//       'TOCTOC',
//       'TOGBONGAN',
//       'TOGONG',
//       'TUMALUM',
//       'TUNGONAN',
//       'YABAWON',
//     ],
//   },
//   CAJIDIOCAN: {
//     province: 'ROMBLON',
//     municipality: 'CAJIDIOCAN',
//     region: 'REGION IV-B',
//     barangay_list: [
//       'ALIBAGON',
//       'CAMBAJAO',
//       'CAMBALO',
//       'CAMBIJANG',
//       'CANTAGDA',
//       'DANAO',
//       'GUTIVAN',
//       'LICO',
//       'LUMBANG ESTE',
//       'LUMBANG WESTE',
//       'MARIGONDON',
//       'POBLACION',
//       'SUGOD',
//       'TAGUILOS',
//     ],
//   },
//   CALATRAVA: {
//     province: 'NEGROS OCCIDENTAL',
//     municipality: 'CALATRAVA',
//     region: 'REGION VI',
//     barangay_list: [
//       'AGPANGI',
//       'ANI-E',
//       'BAGACAY',
//       'BANTAYANON',
//       'BUENAVISTA',
//       'CABUNGAHAN',
//       'CALAMPISAWAN',
//       'CAMBAYOBO',
//       'CASTELLANO',
//       'CRUZ',
//       'DOLIS',
//       'HILUB-ANG',
//       'HINAB-ONGAN',
//       'ILAYA',
//       'LAGA-AN',
//       'LALONG',
//       'LEMERY',
//       'LIPAT-ON',
//       'LO-OK (POB.)',
//       'MA-ASLOB',
//       'MACASILAO',
//       'MAHILUM',
//       'MALANOG',
//       'MALATAS',
//       'MARCELO',
//       'MENCHACA',
//       'MINA-UTOK',
//       'MINAPASUK',
//       'PAGHUMAYAN',
//       'PANTAO',
//       'PATUN-AN',
//       'PINOCUTAN',
//       'REFUGIO',
//       'SAN BENITO',
//       'SAN ISIDRO',
//       'SUBA (POB.)',
//       'TELIM',
//       'TIGBAO',
//       'TIGBON',
//       'WINASWASAN',
//     ],
//   },
//   CORCUERA: {
//     province: 'ROMBLON',
//     municipality: 'CORCUERA',
//     region: 'REGION IV-B',
//     barangay_list: [
//       'ALEGRIA',
//       'AMBULONG',
//       'COLONGCOLONG',
//       'GOBON',
//       'GUINTIGUIBAN',
//       'ILIJAN',
//       'LABNIG',
//       'MABINI',
//       'MAHABA',
//       'MANGANSAG',
//       'POBLACION',
//       'SAN AGUSTIN',
//       'SAN ROQUE',
//       'SAN VICENTE',
//       'TACASAN',
//     ],
//   },
//   FERROL: {
//     province: 'ROMBLON',
//     municipality: 'FERROL',
//     region: 'REGION IV-B',
//     barangay_list: [
//       'AGNONOC',
//       'BUNSORAN',
//       'CLARO M. RECTO',
//       'HINAGUMAN',
//       'POBLACION (FERROL)',
//       'TUBIGON',
//     ],
//   },
//   MAGDIWANG: {
//     province: 'ROMBLON',
//     municipality: 'MAGDIWANG',
//     region: 'REGION IV-B',
//     barangay_list: [
//       'AGSAO',
//       'AGUTAY',
//       'AMBULONG',
//       'DULANGAN',
//       'IPIL',
//       'JAO-ASAN',
//       'POBLACION',
//       'SILUM',
//       'TAMPAYAN',
//     ],
//   },
//   ODIONGAN: {
//     province: 'ROMBLON',
//     municipality: 'ODIONGAN',
//     region: 'REGION IV-B',
//     barangay_list: [
//       'AMATONG',
//       'ANAHAO',
//       'BANGON',
//       'BATIANO',
//       'BUDIONG',
//       'CANDUYONG',
//       'DAPAWAN',
//       'GABAWAN',
//       'LIBERTAD',
//       'LIGAYA (POB.)',
//       'LIWANAG (POB.)',
//       'LIWAYWAY (POB.)',
//       'MALILICO',
//       'MAYHA',
//       'PANIQUE',
//       'PATO-O',
//       'POCTOY',
//       'PROGRESO ESTE',
//       'PROGRESO WESTE',
//       'RIZAL',
//       'TABING DAGAT (POB.)',
//       'TABOBO-AN',
//       'TUBURAN',
//       'TULAY',
//       'TUMINGAD',
//     ],
//   },
//   ROMBLON: {
//     province: 'ROMBLON',
//     municipality: 'ROMBLON',
//     region: 'REGION IV-B',
//     barangay_list: [
//       'AGBALUTO',
//       'AGBUDIA',
//       'AGNAGA',
//       'AGNAY',
//       'AGNIPA',
//       'AGPANABAT',
//       'AGTONGO',
//       'ALAD',
//       'BAGACAY',
//       'BARANGAY I (POB.)',
//       'BARANGAY II (POB.)',
//       'BARANGAY III (POB.)',
//       'BARANGAY IV (POB.)',
//       'CAJIMOS',
//       'CALABOGO',
//       'CAPACLAN',
//       'COBRADOR (NAGUSO)',
//       'GINABLAN',
//       'GUIMPINGAN',
//       'ILAURAN',
//       'LAMAO',
//       'LI-O',
//       'LOGBON',
//       'LONOS',
//       'LUNAS',
//       'MACALAS',
//       'MAPULA',
//       'PALJE',
//       'SABLAYAN',
//       'SAWANG',
//       'TAMBAC',
//     ],
//   },
//   'SANTA MARIA (IMELDA)': {
//     province: 'ROMBLON',
//     municipality: 'SANTA MARIA (IMELDA)',
//     region: 'REGION IV-B',
//     barangay_list: [
//       'BONGA',
//       'CONCEPCION NORTE (POB.)',
//       'CONCEPCION SUR',
//       'PAROYHOG',
//       'SAN ISIDRO',
//       'SANTO NIÑO',
//     ],
//   },
//   BACACAY: {
//     province: 'ALBAY',
//     municipality: 'BACACAY',
//     region: 'REGION V',
//     barangay_list: [
//       'BACLAYON',
//       'BANAO',
//       'BARANGAY 1 (POB.)',
//       'BARANGAY 2 (POB.)',
//       'BARANGAY 3 (POB.)',
//       'BARANGAY 4 (POB.)',
//       'BARANGAY 5 (POB.)',
//       'BARANGAY 6 (POB.)',
//       'BARANGAY 7 (POB.)',
//       'BARANGAY 8 (POB.)',
//       'BARANGAY 9 (POB.)',
//       'BARANGAY 10 (POB.)',
//       'BARANGAY 11 (POB.)',
//       'BARANGAY 12 (POB.)',
//       'BARANGAY 13 (POB.)',
//       'BARANGAY 14 (POB.)',
//       'BARIW',
//       'BASUD',
//       'BAYANDONG',
//       'BONGA (UPPER)',
//       'BUANG',
//       'BUSDAC (SAN JOSE)',
//       'CABASAN',
//       'CAGBULACAO',
//       'CAGRARAY',
//       'CAJOGUTAN',
//       'CAWAYAN',
//       'DAMACAN',
//       'GUBAT ILAWOD',
//       'GUBAT IRAYA',
//       'HINDI',
//       'IGANG',
//       'LANGATON',
//       'MANAET',
//       'MAPULANG DAGA',
//       'MATAAS',
//       'MISIBIS',
//       'NAHAPUNAN',
//       'NAMANDAY',
//       'NAMANTAO',
//       'NAPAO',
//       'PANARAYON',
//       'PIGCOBOHAN',
//       'PILI ILAWOD',
//       'PILI IRAYA',
//       'PONGCO (LOWER BONGA)',
//       'SAN PABLO',
//       'SAN PEDRO',
//       'SOGOD',
//       'SULA',
//       'TAMBILAGAO (TAMBOGNON)',
//       'TAMBONGON (TAMBILAGAO)',
//       'TANAGAN',
//       'USON',
//       'VINISITAHAN-BASUD (MAINLAND)',
//       'VINISITAHAN-NAPAO (ISLAND)',
//     ],
//   },
//   CAMALIG: {
//     province: 'ALBAY',
//     municipality: 'CAMALIG',
//     region: 'REGION V',
//     barangay_list: [
//       'ANOLING',
//       'BALIGANG',
//       'BANTONAN',
//       'BARANGAY 1 (POB.)',
//       'BARANGAY 2 (POB.)',
//       'BARANGAY 3 (POB.)',
//       'BARANGAY 4 (POB.)',
//       'BARANGAY 5 (POB.)',
//       'BARANGAY 6 (POB.)',
//       'BARANGAY 7 (POB.)',
//       'BARIW',
//       'BINANDERAHAN',
//       'BINITAYAN',
//       'BONGABONG',
//       'CABAGÑAN',
//       'CABRARAN PEQUEÑO',
//       'CAGUIBA',
//       'CALABIDONGAN',
//       'COMUN',
//       'COTMON',
//       'DEL ROSARIO',
//       'GAPO',
//       'GOTOB',
//       'ILAWOD',
//       'ILULUAN',
//       'LIBOD',
//       'LIGBAN',
//       'MABUNGA',
//       'MAGOGON',
//       'MANAWAN',
//       'MANINILA',
//       'MINA',
//       'MITI',
//       'PALANOG',
//       'PANOYPOY',
//       'PARIAAN',
//       'QUINARTILAN',
//       'QUIRANGAY',
//       'QUITINDAY',
//       'SALUGAN',
//       'SOLONG',
//       'SUA',
//       'SUMLANG',
//       'TAGAYTAY',
//       'TAGOYTOY',
//       'TALADONG',
//       'TALOTO',
//       'TAPLACON',
//       'TINAGO',
//       'TUMPA',
//     ],
//   },
//   'LIGAO': {
//     province: 'ALBAY',
//     municipality: 'LIGAO',
//     region: 'REGION V',
//     barangay_list: [
//       'ABELLA',
//       'ALLANG',
//       'AMTIC',
//       'BACONG',
//       'BAGUMBAYAN',
//       'BALANAC',
//       'BALIGANG',
//       'BARAYONG',
//       'BASAG',
//       'BATANG',
//       'BAY',
//       'BINANOWAN',
//       'BINATAGAN (POB.)',
//       'BOBONSURAN',
//       'BONGA',
//       'BUSAC',
//       'BUSAY',
//       'CABARIAN',
//       'CALZADA (POB.)',
//       'CATBURAWAN',
//       'CAVASI',
//       'CULLIAT',
//       'DUNAO',
//       'FRANCIA',
//       'GUILID',
//       'HERRERA',
//       'LAYON',
//       'MACALIDONG',
//       'MAHABA',
//       'MALAMA',
//       'MAONON',
//       'NABONTON',
//       'NASISI',
//       'OMA-OMA',
//       'PALAPAS',
//       'PANDAN',
//       'PAULBA',
//       'PAULOG',
//       'PINAMANIQUIAN',
//       'PINIT',
//       'RANAO-RANAO',
//       'SAN VICENTE',
//       'SANTA CRUZ (POB.)',
//       'TAGPO',
//       'TAMBO',
//       'TANDARURA',
//       'TASTAS',
//       'TINAGO',
//       'TINAMPO',
//       'TIONGSON',
//       'TOMOLIN',
//       'TUBURAN',
//       'TULA-TULA GRANDE',
//       'TULA-TULA PEQUEÑO',
//       'TUPAS',
//     ],
//   },
//   'DARAGA (LOCSIN)': {
//     province: 'ALBAY',
//     municipality: 'DARAGA (LOCSIN)',
//     region: 'REGION V',
//     barangay_list: [
//       'ALCALA',
//       'ALOBO',
//       'ANISLAG',
//       'BAGUMBAYAN',
//       'BALINAD',
//       'BAÑADERO',
//       'BAÑAG',
//       'BASCARAN',
//       'BIGAO',
//       'BINITAYAN',
//       'BONGALON',
//       'BUDIAO',
//       'BURGOS',
//       'BUSAY',
//       'CANAROM',
//       'CULLAT',
//       'DELA PAZ',
//       'DINORONAN',
//       'GABAWAN',
//       'GAPO',
//       'IBAUGAN',
//       'ILAWOD AREA POB. (DIST. 2)',
//       'INARADO',
//       'KIDACO',
//       'KILICAO',
//       'KIMANTONG',
//       'KINAWITAN',
//       'KIWALO',
//       'LACAG',
//       'MABINI',
//       'MALABOG',
//       'MALOBAGO',
//       'MAOPI',
//       'MARKET AREA POB. (DIST. 1)',
//       'MAROROY',
//       'MATNOG',
//       'MAYON',
//       'MI-ISI',
//       'NABASAN',
//       'NAMANTAO',
//       'PANDAN',
//       'PEÑAFRANCIA',
//       'SAGPON',
//       'SALVACION',
//       'SAN RAFAEL',
//       'SAN RAMON',
//       'SAN ROQUE',
//       'SAN VICENTE GRANDE',
//       'SAN VICENTE PEQUEÑO',
//       'SIPI',
//       'TABON-TABON',
//       'TAGAS',
//       'TALAHIB',
//       'VILLAHERMOSA',
//     ],
//   },
//   GUINOBATAN: {
//     province: 'ALBAY',
//     municipality: 'GUINOBATAN',
//     region: 'REGION V',
//     barangay_list: [
//       'AGPAY',
//       'BALITE',
//       'BANAO',
//       'BATBAT',
//       'BINOGSACAN LOWER',
//       'BINOGSACAN UPPER',
//       'BOLOLO',
//       'BUBULUSAN',
//       'CALZADA',
//       'CATOMAG',
//       'DOÑA MERCEDES',
//       'DOÑA TOMASA (MAGATOL)',
//       'ILAWOD',
//       'INAMNAN GRANDE',
//       'INAMNAN PEQUEÑO',
//       'INASCAN',
//       'IRAYA',
//       'LOMACAO',
//       'MAGUIRON',
//       'MAIPON',
//       'MALABNIG',
//       'MALIPO',
//       'MALOBAGO',
//       'MANINILA',
//       'MAPACO',
//       'MARCIAL O. RAÑOLA (CABALOAON)',
//       'MASARAWAG',
//       'MAURARO',
//       'MINTO',
//       'MORERA',
//       'MULADBUCAD GRANDE',
//       'MULADBUCAD PEQUEÑO',
//       'ONGO',
//       'PALANAS',
//       'POBLACION',
//       'POOD',
//       'QUIBONGBONGAN',
//       'QUITAGO',
//       'SAN FRANCISCO',
//       'SAN JOSE (OGSONG)',
//       'SAN RAFAEL',
//       'SINUNGTAN',
//       'TANDARORA',
//       'TRAVESIA',
//     ],
//   },
//   JOVELLAR: {
//     province: 'ALBAY',
//     municipality: 'JOVELLAR',
//     region: 'REGION V',
//     barangay_list: [
//       'AURORA POB. (BGY. 6)',
//       'BAGACAY',
//       'BAUTISTA',
//       'CABRARAN',
//       'CALZADA POB. (BGY. 7)',
//       'DEL ROSARIO',
//       'ESTRELLA',
//       'FLORISTA',
//       'MABINI POB. (BGY. 2)',
//       'MAGSAYSAY POB (BGY. 4)',
//       'MAMLAD',
//       'MAOGOG',
//       'MERCADO POB. (BGY. 5)',
//       'PLAZA POB. (BGY. 3)',
//       'QUITINDAY POB. (BGY. 8)',
//       'RIZAL POB. (BGY. 1)',
//       'SALVACION',
//       'SAN ISIDRO',
//       'SAN ROQUE',
//       'SAN VICENTE',
//       'SINAGARAN',
//       'VILLA PAZ',
//       'WHITE DEER POB. (BGY. 9)',
//     ],
//   },
//   'LEGAZPI': {
//     province: 'ALBAY',
//     municipality: 'LEGAZPI',
//     region: 'REGION V',
//     barangay_list: [
//       "BGY. 1 - EM'S BARRIO (POB.)",
//       "BGY. 2 - EM'S BARRIO SOUTH (POB.)",
//       "BGY. 3 - EM'S BARRIO EAST (POB.)",
//       'BGY. 4 - SAGPON POB. (SAGPON 1)',
//       'BGY. 5 - SAGMIN POB. (SAGPON 2)',
//       'BGY. 6 - BAÑADERO POB. (SAGPON 3)',
//       'BGY. 7 - BAÑO (POB.)',
//       'BGY. 8 - BAGUMBAYAN (POB.)',
//       'BGY. 9 - PINARIC (POB.)',
//       'BGY. 10 - CABUGAO',
//       'BGY. 11 - MAOYOD POB. (BGY. 10 AND 11)',
//       'BGY. 12 - TULA-TULA (POB.)',
//       'BGY. 13 - ILAWOD WEST POB. (ILAWOD 1)',
//       'BGY. 14 - ILAWOD POB. (ILAWOD 2)',
//       'BGY. 15 - ILAWOD EAST POB. (ILAWOD 3)',
//       'BGY. 16 - KAWIT-EAST WASHINGTON DRIVE (POB.)',
//       'BGY. 17 - RIZAL SREET., ILAWOD (POB.)',
//       'BGY. 18 - CABAGÑAN WEST (POB.)',
//       'BGY. 19 - CABAGÑAN',
//       'BGY. 20 - CABAGÑAN EAST (POB.)',
//       'BGY. 21 - BINANUAHAN WEST (POB.)',
//       'BGY. 22 - BINANUAHAN EAST (POB.)',
//       'BGY. 23 - IMPERIAL COURT SUBD. (POB.)',
//       'BGY. 24 - RIZAL STREET',
//       'BGY. 25 - LAPU-LAPU (POB.)',
//       'BGY. 26 - DINAGAAN (POB.)',
//       'BGY. 27 - VICTORY VILLAGE SOUTH (POB.)',
//       'BGY. 28 - VICTORY VILLAGE NORTH (POB.)',
//       'BGY. 29 - SABANG (POB.)',
//       'BGY. 30 - PIGCALE (POB.)',
//       'BGY. 31 - CENTRO-BAYBAY (POB.)',
//       'BGY. 32 - SAN ROQUE (BGY. 66)',
//       'BGY. 33 - PNR-PEÑARANDA ST.-IRAYA (POB.)',
//       'BGY. 34 - ORO SITE-MAGALLANES ST. (POB.)',
//       'BGY. 35 - TINAGO (POB.)',
//       'BGY. 36 - KAPANTAWAN (POB.)',
//       'BGY. 37 - BITANO (POB.)',
//       'BGY. 38 - GOGON (BGY. 54)',
//       'BGY. 39 - BONOT (POB.)',
//       'BGY. 40 - CRUZADA (BGY. 52)',
//       'BGY. 41 - BOGTONG (BGY. 45)',
//       'BGY. 42 - RAWIS (BGY. 65)',
//       'BGY. 43 - TAMAOYAN (BGY. 67)',
//       'BGY. 44 - PAWA (BGY. 61)',
//       'BGY. 45 - DITA (BGY. 51)',
//       'BGY. 46 - SAN JOAQUIN (BGY. 64)',
//       'BGY. 47 - ARIMBAY',
//       'BGY. 48 - BAGONG ABRE (BGY. 42)',
//       'BGY. 49 - BIGAA (BGY. 44)',
//       'BGY. 50 - PADANG (BGY. 60)',
//       'BGY. 51 - BUYUAN (BGY. 49)',
//       'BGY. 52 - MATANAG',
//       'BGY. 53 - BONGA (BGY. 48)',
//       'BGY. 54 - MABINIT (BGY. 59)',
//       'BGY. 55 - ESTANZA (BGY. 53)',
//       'BGY. 56 - TAYSAN (BGY. 68)',
//       'BGY. 57 - DAP-DAP (BGY. 69)',
//       'BGY. 58 - BURAGWIS',
//       'BGY. 59 - PURO (BGY. 63)',
//       'BGY. 60 - LAMBA',
//       'BGY. 61 - MASLOG (BGY. 58)',
//       'BGY. 62 - HOMAPON (BGY. 55)',
//       'BGY. 63 - MARIAWA (BGY. 56)',
//       'BGY. 64 - BAGACAY (BGY. 41 BAGACAY)',
//       'BGY. 65 - IMALNOD (BGY. 57)',
//       'BGY. 66 - BANQUEROHAN (BGY. 43)',
//       'BGY. 67 - BARIIS (BGY. 46)',
//       'BGY. 68 - SAN FRANCISCO (BGY. 62)',
//       'BGY. 69 - BUENAVISTA (BGY.47)',
//       'BGY. 70 - CAGBACONG (BGY. 50)',
//     ],
//   },
//   LIBON: {
//     province: 'ALBAY',
//     municipality: 'LIBON',
//     region: 'REGION V',
//     barangay_list: [
//       'ALONGONG',
//       'APUD',
//       'BACOLOD',
//       'BARIW',
//       'BONBON',
//       'BUGA',
//       'BULUSAN',
//       'BURABOD',
//       'CAGUSCOS',
//       'EAST CARISAC',
//       'HARIGUE',
//       'LIBTONG',
//       'LINAO',
//       'MABAYAWAS',
//       'MACABUGOS',
//       'MAGALLANG',
//       'MALABIGA',
//       'MARAYAG',
//       'MATARA',
//       'MOLOSBOLOS',
//       'NATASAN',
//       'NIÑO JESUS (SANTO NIÑO JESUS)',
//       'NOGPO',
//       'PANTAO',
//       'RAWIS',
//       'SAGRADA FAMILIA',
//       'SALVACION',
//       'SAMPONGAN',
//       'SAN AGUSTIN',
//       'SAN ANTONIO',
//       'SAN ISIDRO',
//       'SAN JOSE',
//       'SAN PASCUAL',
//       'SAN RAMON',
//       'SAN VICENTE',
//       'SANTA CRUZ',
//       'TALIN-TALIN',
//       'TAMBO',
//       'VILLA PETRONA',
//       'WEST CARISAC',
//       'ZONE I (POB.)',
//       'ZONE II (POB.)',
//       'ZONE III (POB.)',
//       'ZONE IV (POB.)',
//       'ZONE V (POB.)',
//       'ZONE VI (POB.)',
//       'ZONE VII (POB.)',
//     ],
//   },
//   MALILIPOT: {
//     province: 'ALBAY',
//     municipality: 'MALILIPOT',
//     region: 'REGION V',
//     barangay_list: [
//       'BARANGAY I (POB.)',
//       'BARANGAY II (POB.)',
//       'BARANGAY III (POB.)',
//       'BARANGAY IV (POB.)',
//       'BARANGAY V (POB.)',
//       'BINITAYAN',
//       'CALBAYOG',
//       'CANAWAY',
//       'SALVACION',
//       'SAN ANTONIO SANTICON (POB.)',
//       'SAN ANTONIO SULONG',
//       'SAN FRANCISCO',
//       'SAN ISIDRO ILAWOD',
//       'SAN ISIDRO IRAYA',
//       'SAN JOSE',
//       'SAN ROQUE',
//       'SANTA CRUZ',
//       'SANTA TERESA',
//     ],
//   },
//   MALINAO: {
//     province: 'AKLAN',
//     municipality: 'MALINAO',
//     region: 'REGION VI',
//     barangay_list: [
//       'BANAYBANAY',
//       'BIGA-A',
//       'BULABUD',
//       'CABAYUGAN',
//       'CAPATAGA',
//       'COGON',
//       'DANGCALAN',
//       'KINALANGAY NUEVO',
//       'KINALANGAY VIEJO',
//       'LILO-AN',
//       'MALANDAYON',
//       'MANHANIP',
//       'NAVITAS',
//       'OSMAN',
//       'POBLACION',
//       'ROSARIO',
//       'SAN DIMAS',
//       'SAN RAMON',
//       'SAN ROQUE',
//       'SIPAC',
//       'SUGNOD',
//       'TAMBUAN',
//       'TIGPALAS',
//     ],
//   },
//   MANITO: {
//     province: 'ALBAY',
//     municipality: 'MANITO',
//     region: 'REGION V',
//     barangay_list: [
//       'BALABAGON',
//       'BALASBAS',
//       'BAMBAN',
//       'BUYO',
//       'CABACONGAN',
//       'CABIT',
//       'CAWAYAN',
//       'CAWIT',
//       'HOLUGAN',
//       'IT-BA (POB.)',
//       'MALOBAGO',
//       'MANUMBALAY',
//       'NAGOTGOT',
//       'PAWA',
//       'TINAPIAN',
//     ],
//   },
//   OAS: {
//     province: 'ALBAY',
//     municipality: 'OAS',
//     region: 'REGION V',
//     barangay_list: [
//       'BADBAD',
//       'BADIAN',
//       'BAGSA',
//       'BAGUMBAYAN',
//       'BALOGO',
//       'BANAO',
//       'BANGIAWON',
//       'BOGTONG',
//       'BONGORAN',
//       'BUSAC',
//       'CADAWAG',
//       'CAGMANABA',
//       'CALAGUIMIT',
//       'CALPI',
//       'CALZADA',
//       'CAMAGONG',
//       'CASINAGAN',
//       'CENTRO POBLACION',
//       'COLIAT',
//       'DEL ROSARIO',
//       'GUMABAO',
//       'ILAOR NORTE',
//       'ILAOR SUR',
//       'IRAYA NORTE',
//       'IRAYA SUR',
//       'MANGA',
//       'MAPORONG',
//       'MARAMBA',
//       'MATAMBO',
//       'MAYAG',
//       'MAYAO',
//       'MOROPONROS',
//       'NAGAS',
//       'OBALIW-RINAS',
//       'PISTOLA',
//       'RAMAY',
//       'RIZAL',
//       'SABAN',
//       'SAN AGUSTIN',
//       'SAN ANTONIO',
//       'SAN ISIDRO',
//       'SAN JOSE',
//       'SAN JUAN',
//       'SAN MIGUEL',
//       'SAN PASCUAL (NALE)',
//       'SAN RAMON',
//       'SAN VICENTE (SUCA)',
//       'TABLON',
//       'TALISAY',
//       'TALONGOG',
//       'TAPEL',
//       'TOBGON',
//       'TOBOG',
//     ],
//   },
//   'PIO DURAN': {
//     province: 'ALBAY',
//     municipality: 'PIO DURAN',
//     region: 'REGION V',
//     barangay_list: [
//       'AGOL',
//       'ALABANGPURO',
//       'BANAWAN (BINAWAN)',
//       'BARANGAY I (POB.)',
//       'BARANGAY II (POB.)',
//       'BARANGAY III (POB.)',
//       'BARANGAY IV (POB.)',
//       'BARANGAY V (POB.)',
//       'BASICAO COASTAL',
//       'BASICAO INTERIOR',
//       'BINODEGAHAN',
//       'BUENAVISTA',
//       'BUYO',
//       'CARATAGAN',
//       'CUYAOYAO',
//       'FLORES',
//       'LA MEDALLA',
//       'LAWINON',
//       'MACASITAS',
//       'MALAPAY',
//       'MALIDONG',
//       'MAMLAD',
//       'MARIGONDON',
//       'MATANGLAD',
//       'NABLANGBULOD',
//       'ORINGON',
//       'PALAPAS',
//       'PANGANIRAN',
//       'RAWIS',
//       'SALVACION',
//       'SANTO CRISTO',
//       'SUKIP',
//       'TIBABO',
//     ],
//   },
//   POLANGUI: {
//     province: 'ALBAY',
//     municipality: 'POLANGUI',
//     region: 'REGION V',
//     barangay_list: [
//       'AGOS',
//       'ALNAY',
//       'ALOMON',
//       'AMOGUIS',
//       'ANOPOL',
//       'APAD',
//       'BALABA',
//       'BALANGIBANG',
//       'BALINAD',
//       'BASUD',
//       'BINAGBANGAN (PINTOR)',
//       'BUYO',
//       'CENTRO OCCIDENTAL (POB.)',
//       'CENTRO ORIENTAL (POB.)',
//       'CEPRES',
//       'COTMON',
//       'COTNOGAN',
//       'DANAO',
//       'GABON',
//       'GAMOT',
//       'ITARAN',
//       'KINALE',
//       'KINUARTILAN',
//       'LA MEDALLA',
//       'LA PURISIMA',
//       'LANIGAY',
//       'LIDONG',
//       'LOURDES',
//       'MAGPANAMBO',
//       'MAGURANG',
//       'MATACON',
//       'MAYNAGA',
//       'MAYSUA',
//       'MENDEZ',
//       'NAPO',
//       'PINAGDAPUGAN',
//       'PONSO',
//       'SALVACION',
//       'SAN ROQUE',
//       'SANTA CRUZ',
//       'SANTA TERESITA',
//       'SANTICON',
//       'SUGCAD',
//       'UBALIW',
//     ],
//   },
//   'RAPU-RAPU': {
//     province: 'ALBAY',
//     municipality: 'RAPU-RAPU',
//     region: 'REGION V',
//     barangay_list: [
//       'BAGAOBAWAN',
//       'BATAN',
//       'BILBAO',
//       'BINOSAWAN',
//       'BOGTONG',
//       'BUENAVISTA',
//       'BUHATAN',
//       'CALANAGA',
//       'CARACARAN',
//       'CAROGCOG',
//       'DAP-DAP',
//       'GABA',
//       'GALICIA',
//       'GUADALUPE',
//       'HAMORAWON',
//       'LAGUNDI',
//       'LIGUAN',
//       'LINAO',
//       'MALOBAGO',
//       'MANANAO',
//       'MANCAO',
//       'MANILA',
//       'MASAGA',
//       'MOROCBOROCAN',
//       'NAGCALSOT',
//       'PAGCOLBON',
//       'POBLACION',
//       'SAGRADA',
//       'SAN RAMON',
//       'SANTA BARBARA',
//       'TINOCAWAN',
//       'TINOPAN',
//       'VIGA',
//       'VILLAHERMOSA',
//     ],
//   },
//   'SANTO DOMINGO (LIBOG)': {
//     province: 'ALBAY',
//     municipality: 'SANTO DOMINGO (LIBOG)',
//     region: 'REGION V',
//     barangay_list: [
//       'ALIMSOG',
//       'BAGONG SAN ROQUE',
//       'BUHATAN',
//       'CALAYUCAY',
//       'DEL ROSARIO POB. (BGY. 3)',
//       'FIDEL SURTIDA',
//       'LIDONG',
//       'MARKET SITE POB. (BGY. 9)',
//       'NAGSIYA POB. (BGY. 8)',
//       'PANDAYAN POB. (BGY. 10)',
//       'SALVACION',
//       'SAN ANDRES',
//       'SAN FERNANDO',
//       'SAN FRANCISCO POB. (BGY. 1)',
//       'SAN ISIDRO',
//       'SAN JUAN POB. (BGY. 2)',
//       'SAN PEDRO POB. (BGY. 5)',
//       'SAN RAFAEL POB. (BGY. 7)',
//       'SAN ROQUE',
//       'SAN VICENTE POB. (BGY. 6.)',
//       'SANTA MISERICORDIA',
//       'SANTO DOMINGO POB. (BGY. 4)',
//       'SANTO NIÑO',
//     ],
//   },
//   'TABACO': {
//     province: 'ALBAY',
//     municipality: 'TABACO',
//     region: 'REGION V',
//     barangay_list: [
//       'AGNAS (SAN MIGUEL ISLAND)',
//       'BACOLOD',
//       'BANGKILINGAN',
//       'BANTAYAN',
//       'BARANGHAWON',
//       'BASAGAN',
//       'BASUD (POB.)',
//       'BOGÑABONG',
//       'BOMBON (POB.)',
//       'BONOT',
//       'BUANG',
//       'BUHIAN',
//       'CABAGÑAN',
//       'COBO',
//       'COMON',
//       'CORMIDAL',
//       'DIVINO ROSTRO (POB.)',
//       'FATIMA',
//       'GUINOBAT',
//       'HACIENDA (SAN MIGUEL ISLAND)',
//       'MAGAPO',
//       'MARIROC',
//       'MATAGBAC',
//       'ORAS',
//       'OSON',
//       'PANAL',
//       'PAWA',
//       'PINAGBOBONG',
//       'QUINALE CABASAN (POB.)',
//       'QUINASTILLOJAN',
//       'RAWIS (SAN MIGUEL ISLAND)',
//       'SAGURONG (SAN MIGUEL ISLAND)',
//       'SALVACION',
//       'SAN ANTONIO',
//       'SAN CARLOS',
//       'SAN ISIDRO (BORING)',
//       'SAN JUAN (POB.)',
//       'SAN LORENZO',
//       'SAN RAMON',
//       'SAN ROQUE',
//       'SAN VICENTE',
//       'SANTO CRISTO (POB.)',
//       'SUA-IGOT',
//       'TABIGUIAN',
//       'TAGAS',
//       'TAYHI (POB.)',
//       'VISITA (SAN MIGUEL ISLAND)',
//     ],
//   },
//   TIWI: {
//     province: 'ALBAY',
//     municipality: 'TIWI',
//     region: 'REGION V',
//     barangay_list: [
//       'BAGUMBAYAN',
//       'BARIIS',
//       'BAYBAY',
//       'BELEN (MALABOG)',
//       'BIYONG',
//       'BOLO',
//       'CALE',
//       'CARARAYAN',
//       'CORO-CORO',
//       'DAP-DAP',
//       'GAJO',
//       'JOROAN',
//       'LIBJO',
//       'LIBTONG',
//       'MATALIBONG',
//       'MAYNONONG',
//       'MAYONG',
//       'MISIBIS',
//       'NAGA',
//       'NAGAS',
//       'OYAMA',
//       'PUTSAN',
//       'SAN BERNARDO',
//       'SOGOD',
//       'TIGBI (POB.)',
//     ],
//   },
//   BASUD: {
//     province: 'CAMARINES NORTE',
//     municipality: 'BASUD',
//     region: 'REGION V',
//     barangay_list: [
//       'ANGAS',
//       'BACTAS',
//       'BINATAGAN',
//       'CAAYUNAN',
//       'GUINATUNGAN',
//       'HINAMPACAN',
//       'LANGA',
//       'LANITON',
//       'LIDONG',
//       'MAMPILI',
//       'MANDAZO',
//       'MANGCAMAGONG',
//       'MANMUNTAY',
//       'MANTUGAWE',
//       'MATNOG',
//       'MOCONG',
//       'OLIVA',
//       'PAGSANGAHAN',
//       'PINAGWARASAN',
//       'PLARIDEL',
//       'POBLACION 1',
//       'POBLACION 2',
//       'SAN FELIPE',
//       'SAN JOSE',
//       'SAN PASCUAL',
//       'TABA-TABA',
//       'TACAD',
//       'TAISAN',
//       'TUACA',
//     ],
//   },
//   CAPALONGA: {
//     province: 'CAMARINES NORTE',
//     municipality: 'CAPALONGA',
//     region: 'REGION V',
//     barangay_list: [
//       'ALAYAO',
//       'BINAWANGAN',
//       'CALABACA',
//       'CAMAGSAAN',
//       'CATABAGUANGAN',
//       'CATIOAN',
//       'DEL PILAR',
//       'ITOK',
//       'LUCBANAN',
//       'MABINI',
//       'MACTANG',
//       'MAGSAYSAY',
//       'MATAQUE',
//       'OLD CAMP',
//       'POBLACION',
//       'SAN ANTONIO',
//       'SAN ISIDRO',
//       'SAN ROQUE',
//       'TANAWAN',
//       'UBANG',
//       'VILLA AURORA',
//       'VILLA BELEN',
//     ],
//   },
//   DAET: {
//     province: 'CAMARINES NORTE',
//     municipality: 'DAET',
//     region: 'REGION V',
//     barangay_list: [
//       'ALAWIHAO',
//       'AWITAN',
//       'BAGASBAS',
//       'BARANGAY I (POB.)',
//       'BARANGAY II (POB.)',
//       'BARANGAY III (POB.)',
//       'BARANGAY IV (POB.)',
//       'BARANGAY V (POB.)',
//       'BARANGAY VI (POB.)',
//       'BARANGAY VII (POB.)',
//       'BARANGAY VIII (POB.)',
//       'BIBIRAO',
//       'BORABOD',
//       'CALASGASAN',
//       'CAMAMBUGAN',
//       'COBANGBANG (CARUMPIT)',
//       'DOGONGAN',
//       'GAHONON',
//       'GUBAT',
//       'LAG-ON',
//       'MAGANG',
//       'MAMBALITE',
//       'MANCRUZ (MANGCRUZ)',
//       'PAMORANGON',
//       'SAN ISIDRO',
//     ],
//   },
//   'JOSE PANGANIBAN': {
//     province: 'CAMARINES NORTE',
//     municipality: 'JOSE PANGANIBAN',
//     region: 'REGION V',
//     barangay_list: [
//       'BAGONG BAYAN',
//       'CALERO',
//       'DAHICAN',
//       'DAYHAGAN',
//       'LARAP',
//       'LUKLUKAN NORTE',
//       'LUKLUKAN SUR',
//       'MOTHERLODE',
//       'NAKALAYA',
//       'NORTH POBLACION',
//       'OSMEÑA',
//       'PAG-ASA',
//       'PARANG',
//       'PLARIDEL',
//       'SALVACION',
//       'SAN ISIDRO',
//       'SAN JOSE',
//       'SAN MARTIN',
//       'SAN PEDRO',
//       'SAN RAFAEL',
//       'SANTA CRUZ',
//       'SANTA ELENA',
//       'SANTA MILAGROSA',
//       'SANTA ROSA NORTE',
//       'SANTA ROSA SUR',
//       'SOUTH POBLACION',
//       'TAMISAN',
//     ],
//   },
//   LABO: {
//     province: 'CAMARINES NORTE',
//     municipality: 'LABO',
//     region: 'REGION V',
//     barangay_list: [
//       'ANAHAW (POB.)',
//       'ANAMEAM',
//       'AWITAN',
//       'BAAY',
//       'BAGACAY',
//       'BAGONG SILANG I',
//       'BAGONG SILANG II',
//       'BAGONG SILANG III (SILANG)',
//       'BAKIAD',
//       'BAUTISTA',
//       'BAYABAS',
//       'BAYAN-BAYAN',
//       'BENIT',
//       'BULHAO',
//       'CABATUHAN',
//       'CABUSAY',
//       'CALABASA',
//       'CANAPAWAN',
//       'DAGUIT',
//       'DALAS',
//       'DUMAGMANG',
//       'EXCIBAN',
//       'FUNDADO',
//       'GUINACUTAN',
//       'GUISICAN',
//       'GUMAMELA (POB.)',
//       'IBERICA',
//       'KALAMUNDING (POB.)',
//       'LUGUI',
//       'MABILO I',
//       'MABILO II',
//       'MACOGON',
//       'MAHAWAN-HAWAN',
//       'MALANGCAO-BASUD',
//       'MALASUGUI',
//       'MALATAP',
//       'MALAYA',
//       'MALIBAGO',
//       'MAOT',
//       'MASALONG',
//       'MATANLANG',
//       'NAPAOD',
//       'PAG-ASA',
//       'PANGPANG',
//       'PINYA (POB.)',
//       'SAN ANTONIO',
//       'SAN FRANCISCO (POB.)',
//       'SANTA CRUZ',
//       'SUBMAKIN',
//       'TALOBATIB',
//       'TIGBINAN',
//       'TULAY NA LUPA',
//     ],
//   },
//   MERCEDES: {
//     province: 'EASTERN SAMAR',
//     municipality: 'MERCEDES',
//     region: 'REGION VIII',
//     barangay_list: [
//       'ANURON',
//       'BANUYO',
//       'BARANGAY 1 POBLACION',
//       'BARANGAY 2 POBLACION',
//       'BARANGAY 3 POBLACION',
//       'BARANGAY 4 POBLACION',
//       'BOBON',
//       'BUSAY',
//       'BUYAYAWON',
//       'CABUNGA-AN',
//       'CAMBANTE',
//       'PALAMRAG (CABILIRI-AN)',
//       'PORT KENNEDY',
//       'SAN JOSE',
//       'SAN ROQUE',
//       'SUNG-AN',
//     ],
//   },
//   PARACALE: {
//     province: 'CAMARINES NORTE',
//     municipality: 'PARACALE',
//     region: 'REGION V',
//     barangay_list: [
//       'AWITAN',
//       'BAGUMBAYAN',
//       'BAKAL',
//       'BATOBALANI',
//       'CALABURNAY',
//       'CAPACUAN',
//       'CASALUGAN',
//       'DAGANG',
//       'DALNAC',
//       'DANCALAN',
//       'GUMAUS',
//       'LABNIG',
//       'MACOLABO ISLAND',
//       'MALACBANG',
//       'MALAGUIT',
//       'MAMPUNGO',
//       'MANGKASAY',
//       'MAYBATO',
//       'PALANAS',
//       'PINAGBIRAYAN MALAKI',
//       'PINAGBIRAYAN MUNTI',
//       'POBLACION NORTE',
//       'POBLACION SUR',
//       'TABAS',
//       'TALUSAN',
//       'TAWIG',
//       'TUGOS',
//     ],
//   },
//   'SAN LORENZO RUIZ (IMELDA)': {
//     province: 'CAMARINES NORTE',
//     municipality: 'SAN LORENZO RUIZ (IMELDA)',
//     region: 'REGION V',
//     barangay_list: [
//       'DACULANG BOLO',
//       'DAGOTDOTAN',
//       'LANGGA',
//       'LANITON',
//       'MAISOG',
//       'MAMPUROG',
//       'MANLIMONSITO',
//       'MATACONG (POB.)',
//       'SALVACION',
//       'SAN ANTONIO',
//       'SAN ISIDRO',
//       'SAN RAMON',
//     ],
//   },
//   'SANTA ELENA': {
//     province: 'CAMARINES NORTE',
//     municipality: 'SANTA ELENA',
//     region: 'REGION V',
//     barangay_list: [
//       'BASIAD',
//       'BULALA',
//       'DON TOMAS',
//       'GUITOL',
//       'KABULUAN',
//       'KAGTALABA',
//       'MAULAWIN',
//       'PATAG IBABA',
//       'PATAG IRAYA',
//       'PLARIDEL',
//       'POLUNGGUITGUIT',
//       'RIZAL',
//       'SALVACION',
//       'SAN LORENZO',
//       'SAN PEDRO',
//       'SAN VICENTE',
//       'SANTA ELENA (POB.)',
//       'TABUGON',
//       'VILLA SAN ISIDRO',
//     ],
//   },
//   VINZONS: {
//     province: 'CAMARINES NORTE',
//     municipality: 'VINZONS',
//     region: 'REGION V',
//     barangay_list: [
//       'AGUIT-IT',
//       'BANOCBOC',
//       'BARANGAY I (POB.)',
//       'BARANGAY II (POB.)',
//       'BARANGAY III (POB.)',
//       'CAGBALOGO',
//       'CALANGCAWAN NORTE',
//       'CALANGCAWAN SUR',
//       'GUINACUTAN',
//       'MANGCAWAYAN',
//       'MANGCAYO',
//       'MANLUCUGAN',
//       'MATANGO',
//       'NAPILIHAN',
//       'PINAGTIGASAN',
//       'SABANG',
//       'SANTO DOMINGO',
//       'SINGI',
//       'SULA',
//     ],
//   },
//   BAAO: {
//     province: 'CAMARINES SUR',
//     municipality: 'BAAO',
//     region: 'REGION V',
//     barangay_list: [
//       'AGDANGAN POB. (SAN CAYETANO)',
//       'ANTIPOLO',
//       'BAGUMBAYAN',
//       'BULUANG (SAN ANTONIO)',
//       'CARANDAY (LA PURISIMA)',
//       'CRISTO REY',
//       'DEL PILAR',
//       'DEL ROSARIO (POB.)',
//       'IYAGAN',
//       'LA MEDALLA',
//       'LOURDES',
//       'NABABARERA',
//       'PUGAY (SAN RAFAEL)',
//       'SAGRADA',
//       'SALVACION',
//       'SAN FRANCISCO (POB.)',
//       'SAN ISIDRO',
//       'SAN JOSE (POB.)',
//       'SAN JUAN',
//       'SAN NICOLAS (POB.)',
//       'SAN RAFAEL (IKPAN)',
//       'SAN RAMON (POB.)',
//       'SAN ROQUE (POB.)',
//       'SAN VICENTE',
//       'SANTA CRUZ (POB.)',
//       'SANTA EULALIA',
//       'SANTA ISABEL',
//       'SANTA TERESA (VIGA)',
//       'SANTA TERESITA',
//       'TAPOL',
//     ],
//   },
//   BALATAN: {
//     province: 'CAMARINES SUR',
//     municipality: 'BALATAN',
//     region: 'REGION V',
//     barangay_list: [
//       'CABANBANAN',
//       'CABUNGAN',
//       'CAMANGAHAN (CAORASAN)',
//       'CAYOGCOG',
//       'COGUIT',
//       'DURAN',
//       'LAGANAC',
//       'LULUASAN',
//       'MONTENEGRO (MAGUIRON)',
//       'PARARAO',
//       'PULANG DAGA',
//       'SAGRADA NACACALE',
//       'SAN FRANCISCO',
//       'SANTIAGO NACACALE',
//       'SIRAMAG (POB.)',
//       'TAPAYAS',
//       'TOMATARAYO',
//     ],
//   },
//   BATO: {
//     province: 'LEYTE',
//     municipality: 'BATO',
//     region: 'REGION VIII',
//     barangay_list: [
//       'ALEGRIA',
//       'ALEJOS',
//       'AMAGOS',
//       'ANAHAWAN',
//       'BAGO',
//       'BAGONG BAYAN DISTRICT (POB.)',
//       'BULI',
//       'CEBUANA',
//       'DAAN LUNGSOD',
//       'DAWAHON',
//       'DOLHO',
//       'DOMAGOCDOC',
//       'GUERRERO DISTRICT (POB.)',
//       'HIMAMAA',
//       'IMELDA',
//       'INIGUIHAN DISTRICT (POB.)',
//       'KALANGGAMAN DISTRICT (POB.)',
//       'KATIPUNAN',
//       'LIBERTY (BINALIW)',
//       'MABINI',
//       'MARCELO',
//       'NAGA',
//       'OSMEÑA',
//       'PLARIDEL',
//       'PONONG',
//       'RIVILLA',
//       'SAN AGUSTIN',
//       'SANTO NIÑO',
//       'TABUNOK',
//       'TAGAYTAY',
//       'TINAGO DISTRICT (POB.)',
//       'TUGAS',
//     ],
//   },
//   BOMBON: {
//     province: 'CAMARINES SUR',
//     municipality: 'BOMBON',
//     region: 'REGION V',
//     barangay_list: [
//       'PAGAO (SAN JUAN)',
//       'SAN ANTONIO',
//       'SAN FRANCISCO',
//       'SAN ISIDRO (POB.)',
//       'SAN JOSE (POB.)',
//       'SAN ROQUE (POB.)',
//       'SANTO DOMINGO',
//       'SIEMBRE',
//     ],
//   },
//   BUHI: {
//     province: 'CAMARINES SUR',
//     municipality: 'BUHI',
//     region: 'REGION V',
//     barangay_list: [
//       'AMLONGAN (DEL ROSARIO)',
//       'ANTIPOLO',
//       'BUROCBUSOC',
//       'CABATUAN',
//       'CAGMASLOG',
//       'DE LA FE',
//       'DELOS ANGELES (LOS ANGELES)',
//       'DIVINO ROSTRO',
//       'GABAS',
//       'IBAYUGAN',
//       'IGBAC',
//       'IPIL (FATIMA)',
//       'IRAYA (DEL ROSARIO)',
//       'LABAWON (SANTA TERESITA)',
//       'LOURDES (SANTA LOURDES)',
//       'MACAANGAY',
//       'MONTE CALVARIO',
//       'NAMURABOD',
//       'SAGRADA FAMILIA',
//       'SALVACION',
//       'SAN ANTONIO',
//       'SAN BUENAVENTURA (POB.)',
//       'SAN FRANCISCO (PARADA)',
//       'SAN ISIDRO',
//       'SAN JOSE BAYBAYON',
//       'SAN JOSE SALAY',
//       'SAN PASCUAL (POB.)',
//       'SAN PEDRO (POB.)',
//       'SAN RAFAEL',
//       'SAN RAMON',
//       'SAN ROQUE (POB.)',
//       'SAN VICENTE',
//       'SANTA CLARA (POB.)',
//       'SANTA CRUZ',
//       'SANTA ELENA (POB.)',
//       'SANTA ISABEL',
//       'SANTA JUSTINA',
//       'TAMBO',
//     ],
//   },
//   BULA: {
//     province: 'CAMARINES SUR',
//     municipality: 'BULA',
//     region: 'REGION V',
//     barangay_list: [
//       'BAGOLADIO',
//       'BAGUMBAYAN',
//       'BALAOGAN',
//       'CAORASAN',
//       'CASUGAD',
//       'CAUSIP',
//       'FABRICA',
//       'INOYONAN',
//       'ITANGON',
//       'KINALABASAHAN',
//       'LA PURISIMA',
//       'LA VICTORIA',
//       'LANIPGA',
//       'LUBGAN',
//       'OMBAO HEIGHTS',
//       'OMBAO POLPOG',
//       'PALSONG',
//       'PANOYPOYAN',
//       'PAWILI',
//       'SAGRADA (SAGRADA FAMILIA)',
//       'SALVACION (POB.)',
//       'SAN AGUSTIN',
//       'SAN FRANCISCO',
//       'SAN ISIDRO',
//       'SAN JOSE',
//       'SAN MIGUEL',
//       'SAN RAMON',
//       'SAN ROQUE (POB.)',
//       'SAN ROQUE HEIGHTS',
//       'SANTA ELENA',
//       'SANTO DOMINGO',
//       'SANTO NIÑO',
//       'TAISAN',
//     ],
//   },
//   CABUSAO: {
//     province: 'CAMARINES SUR',
//     municipality: 'CABUSAO',
//     region: 'REGION V',
//     barangay_list: [
//       'BARCELONITA',
//       'BIONG',
//       'CAMAGONG',
//       'CASTILLO',
//       'NEW POBLACION',
//       'PANDAN',
//       'SAN PEDRO',
//       'SANTA CRUZ',
//       'SANTA LUTGARDA (POB.)',
//     ],
//   },
//   CALABANGA: {
//     province: 'CAMARINES SUR',
//     municipality: 'CALABANGA',
//     region: 'REGION V',
//     barangay_list: [
//       'BALATASAN',
//       'BALOMBON',
//       'BALONGAY',
//       'BELEN',
//       'BIGAAS',
//       'BINALIW',
//       'BINANUAANAN GRANDE',
//       'BINANUAANAN PEQUEÑO',
//       'BONOT-SANTA ROSA',
//       'BURABOD',
//       'CABANBANAN',
//       'CAGSAO',
//       'CAMUNING',
//       'COMAGUINGKING',
//       'DEL CARMEN (POB.)',
//       'DOMINOROG',
//       'FABRICA',
//       'HAROBAY',
//       'LA PURISIMA',
//       'LUGSAD',
//       'MANGUIRING',
//       'PAGATPAT (SAN JOSE)',
//       'PAOLBO',
//       'PINADA',
//       'PUNTA TARAWAL',
//       'QUINALE',
//       'SABANG',
//       'SALVACION-BAYBAY',
//       'SAN ANTONIO (QUIPAYO)',
//       'SAN ANTONIO POBLACION',
//       'SAN BERNARDINO',
//       'SAN FRANCISCO (POB.)',
//       'SAN ISIDRO',
//       'SAN LUCAS',
//       'SAN MIGUEL (POB.)',
//       'SAN PABLO (POB.)',
//       'SAN ROQUE',
//       'SAN VICENTE (POB.)',
//       'SANTA CRUZ POBLACION',
//       'SANTA CRUZ RATAY',
//       'SANTA ISABEL (POB.)',
//       'SANTA SALUD (POB.)',
//       'SANTO DOMINGO',
//       'SANTO NIÑO (QUIPAYO)',
//       'SIBA-O',
//       'SIBOBO',
//       'SOGOD',
//       'TOMAGODTOD',
//     ],
//   },
//   CAMALIGAN: {
//     province: 'CAMARINES SUR',
//     municipality: 'CAMALIGAN',
//     region: 'REGION V',
//     barangay_list: [
//       'DUGCAL',
//       'MARUPIT',
//       'SAN FRANCISCO',
//       'SAN JOSE-SAN PABLO (POB.)',
//       'SAN JUAN-SAN RAMON (POB.)',
//       'SAN LUCAS (POB.)',
//       'SAN MARCOS (POB.)',
//       'SAN MATEO (POB.)',
//       'SAN ROQUE',
//       'SANTO DOMINGO (POB.)',
//       'SANTO TOMAS (POB.)',
//       'SUA',
//       'TAROSANAN',
//     ],
//   },
//   CANAMAN: {
//     province: 'CAMARINES SUR',
//     municipality: 'CANAMAN',
//     region: 'REGION V',
//     barangay_list: [
//       'BARAS (POB.)',
//       'DEL ROSARIO',
//       'DINAGA (POB.)',
//       'FUNDADO',
//       'HARING',
//       'IQUIN',
//       'LINAGA',
//       'MANGAYAWAN',
//       'PALO',
//       'PANGPANG (POB.)',
//       'PORO',
//       'SAN AGUSTIN',
//       'SAN FRANCISCO',
//       'SAN JOSE EAST',
//       'SAN JOSE WEST',
//       'SAN JUAN',
//       'SAN NICOLAS',
//       'SAN ROQUE',
//       'SAN VICENTE',
//       'SANTA CRUZ (POB.)',
//       'SANTA TERESITA',
//       'SUA',
//       'TALIDTID',
//       'TIBGAO (POB.)',
//     ],
//   },
//   CARAMOAN: {
//     province: 'CAMARINES SUR',
//     municipality: 'CARAMOAN',
//     region: 'REGION V',
//     barangay_list: [
//       'AGAAS',
//       'ANTOLON',
//       'BACGONG',
//       'BAHAY',
//       'BIKAL',
//       'BINANUAHAN (POB.)',
//       'CABACONGAN',
//       'CADONG',
//       'CANATUAN',
//       'CAPUTATAN',
//       'COLONGCOGONG',
//       'DARAGA',
//       'GATA',
//       'GIBGOS',
//       'GOGON',
//       'GUIJALO',
//       'HANOPOL',
//       'HANOY',
//       'HAPONAN',
//       'ILAWOD',
//       'ILI-CENTRO (POB.)',
//       'LIDONG',
//       'LUBAS',
//       'MALABOG',
//       'MALIGAYA',
//       'MAMPIRAO',
//       'MANDICLUM (MANDICLOM)',
//       'MAQUEDA',
//       'MINALABA',
//       'ORING',
//       'OROC-OSOC',
//       'PAGOLINAN',
//       'PANDANAN',
//       'PANIMAN',
//       'PATAG-BELEN',
//       'PILI-CENTRO',
//       'PILI-TABIGUIAN',
//       'POLOAN',
//       'SALVACION',
//       'SAN ROQUE',
//       'SAN VICENTE',
//       'SANTA CRUZ',
//       'SOLNOPAN',
//       'TABGON',
//       'TABIGUIAN',
//       'TABOG',
//       'TAWOG (POB.)',
//       'TEROGO',
//       'TOBOAN',
//     ],
//   },
//   'DEL GALLEGO': {
//     province: 'CAMARINES SUR',
//     municipality: 'DEL GALLEGO',
//     region: 'REGION V',
//     barangay_list: [
//       'BAGONG SILANG',
//       'BUCAL',
//       'CABASAG',
//       'COMADAYCADAY',
//       'COMADOGCADOG',
//       'DOMAGONDONG',
//       'KINALANGAN',
//       'MABINI',
//       'MAGAIS I',
//       'MAGAIS II',
//       'MANSALAYA',
//       'NAGKALIT',
//       'PALASPAS',
//       'PAMPLONA',
//       'PASAY',
//       'PINAGDAPIAN',
//       'PINUGUSAN',
//       'POBLACION ZONE III',
//       'SABANG',
//       'SALVACION',
//       'SAN JUAN',
//       'SAN PABLO',
//       'SANTA RITA I',
//       'SANTA RITA II',
//       'SINAGAWSAWAN',
//       'SINUKNIPAN I',
//       'SINUKNIPAN II',
//       'SUGSUGIN',
//       'TABION',
//       'TOMAGOKTOK',
//       'ZONE I FATIMA (POB.)',
//       'ZONE II SAN ANTONIO (POB.)',
//     ],
//   },
//   GAINZA: {
//     province: 'CAMARINES SUR',
//     municipality: 'GAINZA',
//     region: 'REGION V',
//     barangay_list: [
//       'CAGBUNGA',
//       'DAHILIG',
//       'DISTRICT I (POB.)',
//       'DISTRICT II (POB.)',
//       'LOOB',
//       'MALBONG',
//       'NAMUAT',
//       'SAMPALOC',
//     ],
//   },
//   GARCHITORENA: {
//     province: 'CAMARINES SUR',
//     municipality: 'GARCHITORENA',
//     region: 'REGION V',
//     barangay_list: [
//       'ASON (ANSON)',
//       'BAHI',
//       'BARANGAY I (POB.)',
//       'BARANGAY II (POB.)',
//       'BARANGAY III (POB.)',
//       'BARANGAY IV (POB.)',
//       'BINAGASBASAN',
//       'BURABOD',
//       'CAGAMUTAN',
//       'CAGNIPA',
//       'CANLONG',
//       'DANGLA',
//       'DEL PILAR',
//       'DENRICA',
//       'HARRISON',
//       'MANSANGAT',
//       'PAMBUHAN',
//       'SAGRADA',
//       'SALVACION',
//       'SAN VICENTE',
//       'SUMAOY',
//       'TAMIAWON',
//       'TOYTOY',
//     ],
//   },
//   GOA: {
//     province: 'CAMARINES SUR',
//     municipality: 'GOA',
//     region: 'REGION V',
//     barangay_list: [
//       'ABUCAYAN',
//       'BAGUMBAYAN GRANDE (POB.)',
//       'BAGUMBAYAN PEQUEÑO (POB.)',
//       'BALAYNAN',
//       'BELEN (POB.)',
//       'BUYO',
//       'CAGAYCAY',
//       'CATAGBACAN',
//       'DIGDIGON',
//       'GIMAGA',
//       'HALAWIG-GOGON',
//       'HIWACLOY',
//       'LA PURISIMA (POB.)',
//       'LAMON',
//       'MATACLA',
//       'MAYMATAN',
//       'MAYSALAY',
//       'NAPAWON (NAPUNUON)',
//       'PANDAY (POB.)',
//       'PAYATAN',
//       'PINAGLABANAN',
//       'SALOG',
//       'SAN BENITO (POB.)',
//       'SAN ISIDRO (POB.)',
//       'SAN ISIDRO WEST',
//       'SAN JOSE (POB.)',
//       'SAN JUAN BAUTISTA (POB.)',
//       'SAN JUAN EVANGELISTA (POB.)',
//       'SAN PEDRO (ARORO)',
//       'SCOUT FUENTEBELLA',
//       'TABGON',
//       'TAGONGTONG',
//       'TAMBAN (MABINI)',
//       'TAYTAY (HALGON EAST)',
//     ],
//   },
//   'IRIGA': {
//     province: 'CAMARINES SUR',
//     municipality: 'IRIGA',
//     region: 'REGION V',
//     barangay_list: [
//       'ANTIPOLO',
//       'CRISTO REY',
//       'DEL ROSARIO (BANAO)',
//       'FRANCIA',
//       'LA ANUNCIACION',
//       'LA MEDALLA',
//       'LA PURISIMA',
//       'LA TRINIDAD',
//       'NIÑO JESUS',
//       'PERPETUAL HELP',
//       'SAGRADA',
//       'SALVACION',
//       'SAN AGUSTIN',
//       'SAN ANDRES',
//       'SAN ANTONIO',
//       'SAN FRANCISCO (POB.)',
//       'SAN ISIDRO',
//       'SAN JOSE',
//       'SAN JUAN',
//       'SAN MIGUEL',
//       'SAN NICOLAS',
//       'SAN PEDRO',
//       'SAN RAFAEL',
//       'SAN RAMON',
//       'SAN ROQUE (POB.)',
//       'SAN VICENTE NORTE',
//       'SAN VICENTE SUR',
//       'SANTA CRUZ NORTE',
//       'SANTA CRUZ SUR',
//       'SANTA ELENA',
//       'SANTA ISABEL',
//       'SANTA MARIA',
//       'SANTA TERESITA',
//       'SANTIAGO',
//       'SANTO DOMINGO',
//       'SANTO NIÑO',
//     ],
//   },
//   LAGONOY: {
//     province: 'CAMARINES SUR',
//     municipality: 'LAGONOY',
//     region: 'REGION V',
//     barangay_list: [
//       'AGOSAIS',
//       'AGPO-CAMAGONG-TABOG',
//       'AMOGUIS',
//       'BALATON',
//       'BINANUAHAN',
//       'BOCOGAN',
//       'BURABOD',
//       'CABOTONAN',
//       'DAHAT',
//       'DEL CARMEN',
//       'GIMAGTOCON',
//       'GINORANGAN (GENORANGAN)',
//       'GUBAT',
//       'GUIBAHOY',
//       'HIMANAG',
//       'KINAHOLOGAN',
//       'LOHO',
//       'MANAMOC',
//       'MANGOGON',
//       'MAPID',
//       'OLAS',
//       'OMALO',
//       'PANAGAN',
//       'PANICUAN',
//       'PINAMIHAGAN',
//       'SAN FRANCISCO (POB.)',
//       'SAN ISIDRO',
//       'SAN ISIDRO NORTE (POB.)',
//       'SAN ISIDRO SUR (POB.)',
//       'SAN RAFAEL',
//       'SAN RAMON',
//       'SAN ROQUE',
//       'SAN SEBASTIAN',
//       'SAN VICENTE (POB.)',
//       'SANTA CRUZ (POB.)',
//       'SANTA MARIA (POB.)',
//       'SARIPONGPONG (POB.)',
//       'SIPACO',
//     ],
//   },
//   LIBMANAN: {
//     province: 'CAMARINES SUR',
//     municipality: 'LIBMANAN',
//     region: 'REGION V',
//     barangay_list: [
//       'ASLONG',
//       'AWAYAN',
//       'BAGACAY',
//       'BAGADION',
//       'BAGAMELON',
//       'BAGUMBAYAN',
//       'BAHAO',
//       'BAHAY',
//       'BEGAJO NORTE',
//       'BEGAJO SUR',
//       'BEGUITO NUEVO',
//       'BEGUITO VIEJO',
//       'BIKAL',
//       'BUSAK',
//       'CAIMA',
//       'CALABNIGAN',
//       'CAMAMBUGAN',
//       'CAMBALIDIO',
//       'CANDAMI',
//       'CANDATO',
//       'CAWAYAN',
//       'CONCEPCION',
//       'CUYAPI',
//       'DANAWAN',
//       'DUANG NIOG',
//       'HANDONG',
//       'IBID',
//       'INALAHAN',
//       'LABAO',
//       'LIBOD I',
//       'LIBOD II',
//       'LOBA-LOBA',
//       'MABINI',
//       'MALANSAD NUEVO',
//       'MALANSAD VIEJO',
//       'MALBOGON',
//       'MALINAO',
//       'MAMBALITE',
//       'MAMBAYAWAS',
//       'MAMBULO NUEVO',
//       'MAMBULO VIEJO',
//       'MANCAWAYAN',
//       'MANDACANAN',
//       'MANTALISAY',
//       'PADLOS',
//       'PAG-ORING NUEVO',
//       'PAG-ORING VIEJO',
//       'PALANGON',
//       'PALONG',
//       'PATAG',
//       'PLANZA',
//       'POBLACION',
//       'POTOT',
//       'PURO-BATIA',
//       'RONGOS',
//       'SALVACION',
//       'SAN ISIDRO',
//       'SAN JUAN',
//       'SAN PABLO',
//       'SAN VICENTE',
//       'SIBUJO',
//       'SIGAMOT',
//       'STATION-CHURCH SITE',
//       'TABAN-FUNDADO',
//       'TAMPUHAN',
//       'TANAG',
//       'TARUM',
//       'TINALMUD NUEVO',
//       'TINALMUD VIEJO',
//       'TINANGKIHAN',
//       'UDOC',
//       'UMALO',
//       'USON',
//       'VILLADIMA (SANTA CRUZ)',
//       'VILLASOCORRO',
//     ],
//   },
//   LUPI: {
//     province: 'CAMARINES SUR',
//     municipality: 'LUPI',
//     region: 'REGION V',
//     barangay_list: [
//       'ALLEOMAR',
//       'BAGANGAN SR.',
//       'BAGONG SIKAT',
//       'BANGON',
//       'BARRERA JR.',
//       'BARRERA SR.',
//       'BEL-CRUZ',
//       'BELWANG',
//       'BUENASUERTE',
//       'BULAWAN JR.',
//       'BULAWAN SR.',
//       'CABUTAGAN',
//       'CASAY',
//       'COLACLING (DEL ROSARIO)',
//       'CRISTO REY',
//       'DEL CARMEN',
//       'HAGUIMIT',
//       'HALUBAN (PIGBASAGAN)',
//       'KAIBIGAN',
//       'LA PURISIMA',
//       'LOURDES',
//       'MANGCAWAYAN',
//       'NAPOLIDAN',
//       'POBLACION',
//       'POLANTUNA',
//       'SAGRADA',
//       'SALVACION',
//       'SAN ISIDRO',
//       'SAN JOSE',
//       'SAN PEDRO',
//       'SAN RAFAEL NORTE',
//       'SAN RAFAEL SUR',
//       'SAN RAMON',
//       'SAN VICENTE',
//       'SOOC',
//       'TANAWAN',
//       'TAPI (LUPI NUEVO)',
//       'TIBLE',
//     ],
//   },
//   MAGARAO: {
//     province: 'CAMARINES SUR',
//     municipality: 'MAGARAO',
//     region: 'REGION V',
//     barangay_list: [
//       'BAROBAYBAY',
//       'BELL (POB.)',
//       'CARANGCANG',
//       'CARIGSA',
//       'CASURAY',
//       'MONSERRAT (POB.)',
//       'PONONG',
//       'SAN FRANCISCO (POB.)',
//       'SAN ISIDRO (POB.)',
//       'SAN JUAN (POB.)',
//       'SAN MIGUEL',
//       'SAN PANTALEON (POB.)',
//       'SANTA LUCIA (POB.)',
//       'SANTA ROSA',
//       'SANTO TOMAS (POB.)',
//     ],
//   },
//   MILAOR: {
//     province: 'CAMARINES SUR',
//     municipality: 'MILAOR',
//     region: 'REGION V',
//     barangay_list: [
//       'ALIMBUYOG',
//       'AMPARADO (POB.)',
//       'BALAGBAG',
//       'BORONGBORONGAN',
//       'CABUGAO',
//       'CAPUCNASAN',
//       'DALIPAY',
//       'DEL ROSARIO (POB.)',
//       'FLORDELIZ',
//       'LIPOT',
//       'MAYAOPAYAWAN',
//       'MAYCATMON',
//       'MAYDASO',
//       'SAN ANTONIO',
//       'SAN JOSE (POB.)',
//       'SAN MIGUEL (POB.)',
//       'SAN ROQUE (POB.)',
//       'SAN VICENTE (POB.)',
//       'SANTO DOMINGO (POB.)',
//       'TARUSANAN',
//     ],
//   },
//   MINALABAC: {
//     province: 'CAMARINES SUR',
//     municipality: 'MINALABAC',
//     region: 'REGION V',
//     barangay_list: [
//       'ANTIPOLO',
//       'BAGOLATAO',
//       'BAGONGBONG',
//       'BALIUAG NUEVO',
//       'BALIUAG VIEJO',
//       'CATANUSAN',
//       'DEL CARMEN-DEL ROSARIO (POB.)',
//       'DEL SOCORRO',
//       'HAMORAON',
//       'HOBO',
//       'IRAYANG SOLONG',
//       'MAGADAP',
//       'MALITBOG',
//       'MANAPAO',
//       'MATAOROC',
//       'SAGRADA (SAGRADA FAMILIA)',
//       'SALINGOGON',
//       'SAN ANTONIO',
//       'SAN FELIPE-SANTIAGO (POB.)',
//       'SAN FRANCISCO (POB.)',
//       'SAN JOSE',
//       'SAN JUAN-SAN LORENZO (POB.)',
//       'TABAN',
//       'TARIRIC',
//       'TIMBANG',
//     ],
//   },
//   NABUA: {
//     province: 'CAMARINES SUR',
//     municipality: 'NABUA',
//     region: 'REGION V',
//     barangay_list: [
//       'ANGUSTIA (ANGUSTIA INAPATAN)',
//       'ANTIPOLO OLD',
//       'ANTIPOLO YOUNG',
//       'ARO-ALDAO',
//       'BUSTRAC',
//       'DOLOROSA (DOLOROSA INAPATAN)',
//       'DURAN (JESUS DURAN)',
//       'INAPATAN (DEL ROSARIO INAPATAN)',
//       'LA OPINION',
//       'LA PURISIMA (LA PURISIMA AGUPIT)',
//       'LOURDES OLD',
//       'LOURDES YOUNG',
//       'MALAWAG (SAN JOSE MALAWAG)',
//       'PALOYON ORIENTAL',
//       'PALOYON PROPER (SAGRADA PALOYON)',
//       'SALVACION QUE GATOS',
//       'SAN ANTONIO (POB.)',
//       'SAN ANTONIO OGBON',
//       'SAN ESTEBAN (POB.)',
//       'SAN FRANCISCO (POB.)',
//       'SAN ISIDRO (POB.)',
//       'SAN ISIDRO INAPATAN',
//       'SAN JOSE (SAN JOSE PANGARAON)',
//       'SAN JUAN (POB.)',
//       'SAN LUIS (POB.)',
//       'SAN MIGUEL (POB.)',
//       'SAN NICOLAS (POB.)',
//       'SAN ROQUE (POB.)',
//       'SAN ROQUE MADAWON',
//       'SAN ROQUE SAGUMAY',
//       'SAN VICENTE GORONG-GORONG',
//       'SAN VICENTE OGBON',
//       'SANTA BARBARA (MALIBAN)',
//       'SANTA CRUZ',
//       'SANTA ELENA BARAS',
//       'SANTA LUCIA BARAS',
//       'SANTIAGO OLD',
//       'SANTIAGO YOUNG',
//       'SANTO DOMINGO',
//       'TANDAAY',
//       'TOPAS PROPER',
//       'TOPAS SOGOD',
//     ],
//   },
//   'NAGA': {
//     province: 'CEBU',
//     municipality: 'NAGA',
//     region: 'REGION VII',
//     barangay_list: [
//       'ALFACO',
//       'BAIRAN',
//       'BALIRONG',
//       'CABUNGAHAN',
//       'CANTAO-AN',
//       'CENTRAL POBLACION',
//       'COGON',
//       'COLON',
//       'EAST POBLACION',
//       'INAYAGAN',
//       'INOBURAN',
//       'JAGUIMIT',
//       'LANAS',
//       'LANGTAD',
//       'LUTAC',
//       'MAINIT',
//       'MAYANA',
//       'NAALAD',
//       'NORTH POBLACION',
//       'PANGDAN',
//       'PATAG',
//       'SOUTH POBLACION',
//       'TAGJAGUIMIT',
//       'TANGKE',
//       'TINAAN',
//       'TUYAN',
//       'ULING',
//       'WEST POBLACION',
//     ],
//   },
//   OCAMPO: {
//     province: 'CAMARINES SUR',
//     municipality: 'OCAMPO',
//     region: 'REGION V',
//     barangay_list: [
//       'AYUGAN',
//       'CABARIWAN',
//       'CAGMANABA',
//       'DEL ROSARIO',
//       'GATBO',
//       'GUINABAN',
//       'HANAWAN',
//       'HIBAGO',
//       'LA PURISIMA NUEVO',
//       'MAY-OGOB',
//       'NEW MORIONES',
//       'OLD MORIONES',
//       'PINIT',
//       'POBLACION CENTRAL',
//       'POBLACION EAST',
//       'POBLACION WEST',
//       'SALVACION',
//       'SAN ANTONIO',
//       'SAN FRANCISCO',
//       'SAN JOSE ORAS',
//       'SAN ROQUE COMMONAL',
//       'SAN VICENTE',
//       'SANTA CRUZ',
//       'SANTO NIÑO',
//       'VILLAFLORIDA',
//     ],
//   },
//   PASACAO: {
//     province: 'CAMARINES SUR',
//     municipality: 'PASACAO',
//     region: 'REGION V',
//     barangay_list: [
//       'ANTIPOLO',
//       'BAGONG SILANG',
//       'BAHAY',
//       'BALOGO',
//       'CARANAN',
//       'CUCO',
//       'DALUPAON',
//       'HUBO',
//       'ITULAN',
//       'MACAD (HEBRIO LOURDES)',
//       'ODICON',
//       'QUITANG',
//       'SALVACION',
//       'SAN ANTONIO',
//       'SAN CIRILO (POB.)',
//       'SANTA ROSA DEL NORTE (POB.)',
//       'SANTA ROSA DEL SUR (POB.)',
//       'TILNAC',
//       'TINALMUD',
//     ],
//   },
//   PILI: {
//     province: 'CAMARINES SUR',
//     municipality: 'PILI',
//     region: 'REGION V',
//     barangay_list: [
//       'ANAYAN',
//       'BAGONG SIRANG',
//       'BINANWAANAN',
//       'BINOBONG',
//       'CADLAN',
//       'CAROYROYAN',
//       'CURRY',
//       'DEL ROSARIO',
//       'HIMAAO',
//       'LA PURISIMA',
//       'NEW SAN ROQUE',
//       'OLD SAN ROQUE (POB.)',
//       'PALESTINA',
//       'PAWILI',
//       'SAGRADA',
//       'SAGURONG',
//       'SAN AGUSTIN',
//       'SAN ANTONIO (POB.)',
//       'SAN ISIDRO (POB.)',
//       'SAN JOSE',
//       'SAN JUAN (POB.)',
//       'SAN VICENTE (POB.)',
//       'SANTIAGO (POB.)',
//       'SANTO NIÑO',
//       'TAGBONG',
//       'TINANGIS',
//     ],
//   },
//   'PRESENTACION (PARUBCAN)': {
//     province: 'CAMARINES SUR',
//     municipality: 'PRESENTACION (PARUBCAN)',
//     region: 'REGION V',
//     barangay_list: [
//       'AYUGAO',
//       'BAGONG SIRANG',
//       'BALIGUIAN',
//       'BANTUGAN',
//       'BICALEN',
//       'BITAOGAN',
//       'BUENAVISTA',
//       'BULALACAO',
//       'CAGNIPA',
//       'LAGHA',
//       'LIDONG',
//       'LIWACSA',
//       'MAANGAS',
//       'PAGSANGAHAN',
//       'PATROCINIO',
//       'PILI',
//       'SANTA MARIA (POB.)',
//       'TANAWAN',
//     ],
//   },
//   RAGAY: {
//     province: 'CAMARINES SUR',
//     municipality: 'RAGAY',
//     region: 'REGION V',
//     barangay_list: [
//       'AGAO-AO',
//       'AGRUPACION',
//       'AMOMOKPOK',
//       'APAD',
//       'APALE',
//       'BANGA CAVES',
//       'BAYA',
//       'BINAHAN PROPER',
//       'BINAHAN UPPER',
//       'BUENASUERTE',
//       'CABADISAN',
//       'CABINITAN',
//       'CABUGAO',
//       'CADITAAN',
//       'CALE',
//       'CATABANGAN PROPER',
//       'F. SIMEON (PUGOD)',
//       'GODOFREDO REYES SR. (CATABANGAN CROSSING)',
//       'INANDAWA',
//       'LAGUIO',
//       'LANIPGA-CAWAYAN',
//       'LIBORO',
//       'LOHONG',
//       'LOWER OMON',
//       'LOWER SANTA CRUZ',
//       'PANAYTAYAN',
//       'PANAYTAYAN NUEVO',
//       'PATALUNAN',
//       'POBLACION ILAOD',
//       'POBLACION IRAYA',
//       'PORT JUNCTION NORTE',
//       'PORT JUNCTION SUR',
//       'SALVACION',
//       'SAMAY',
//       'SAN RAFAEL',
//       'TAGBAC',
//       'UPPER OMON',
//       'UPPER SANTA CRUZ',
//     ],
//   },
//   SAGÑAY: {
//     province: 'CAMARINES SUR',
//     municipality: 'SAGÑAY',
//     region: 'REGION V',
//     barangay_list: [
//       'ANIOG',
//       'ATULAYAN',
//       'BONGALON',
//       'BURACAN',
//       'CATALOTOAN',
//       'DEL CARMEN (POB.)',
//       'KILANTAAO',
//       'KILOMAON',
//       'MABCA',
//       'MINADONGJOL',
//       'NATO',
//       'PATITINAN',
//       'SAN ANTONIO (POB.)',
//       'SAN ISIDRO (POB.)',
//       'SAN ROQUE (POB.)',
//       'SANTO NIÑO',
//       'SIBAGUAN',
//       'TINORONGAN',
//       'TURAGUE',
//     ],
//   },
//   SIPOCOT: {
//     province: 'CAMARINES SUR',
//     municipality: 'SIPOCOT',
//     region: 'REGION V',
//     barangay_list: [
//       'ALDEZAR',
//       'ALTEZA',
//       'ANIB',
//       'AWAYAN',
//       'AZUCENA',
//       'BAGONG SIRANG',
//       'BINAHIAN',
//       'BOLO NORTE',
//       'BOLO SUR',
//       'BULAN',
//       'BULAWAN',
//       'CABUYAO',
//       'CAIMA',
//       'CALAGBANGAN',
//       'CALAMPINAY',
//       'CARAYRAYAN',
//       'COTMO',
//       'GABI',
//       'GAONGAN',
//       'IMPIG',
//       'LIPILIP',
//       'LUBIGAN JR.',
//       'LUBIGAN SR.',
//       'MALAGUICO',
//       'MALUBAGO',
//       'MANANGLE',
//       'MANGAPO',
//       'MANGGA',
//       'MANLUBANG',
//       'MANTILA',
//       'NORTH CENTRO (POB.)',
//       'NORTH VILLAZAR',
//       'SAGRADA FAMILIA',
//       'SALANDA',
//       'SALVACION',
//       'SAN ISIDRO',
//       'SAN VICENTE',
//       'SERRANZANA',
//       'SOUTH CENTRO (POB.)',
//       'SOUTH VILLAZAR',
//       'TAISAN',
//       'TARA',
//       'TIBLE',
//       'TULA-TULA',
//       'VIGAAN',
//       'YABO',
//     ],
//   },
//   SIRUMA: {
//     province: 'CAMARINES SUR',
//     municipality: 'SIRUMA',
//     region: 'REGION V',
//     barangay_list: [
//       'BAGONG SIRANG',
//       'BAHAO',
//       'BOBOAN',
//       'BUTAWANAN',
//       'CABUGAO',
//       'FUNDADO',
//       'HOMESTEAD',
//       'LA PURISIMA',
//       'MABUHAY',
//       'MALACONINI',
//       'MATANDANG SIRUMA',
//       'NALAYAHAN',
//       'PAMINTAN-BANTILAN',
//       'PINITAN',
//       'POBLACION',
//       'SALVACION',
//       'SAN ANDRES',
//       'SAN RAMON (DALDAGON)',
//       'SULPA',
//       'TANDOC',
//       'TONGO-BANTIGUE',
//       'VITO',
//     ],
//   },
//   TIGAON: {
//     province: 'CAMARINES SUR',
//     municipality: 'TIGAON',
//     region: 'REGION V',
//     barangay_list: [
//       'ABO',
//       'CABALINADAN',
//       'CARAYCAYON',
//       'CASUNA',
//       'CONSOCEP',
//       'COYAOYAO',
//       'GAAO',
//       'GINGAROY',
//       'GUBAT',
//       'HUYONHUYON',
//       'LIBOD',
//       'MABALODBALOD',
//       'MAY-ANAO',
//       'PANAGAN',
//       'POBLACION',
//       'SALVACION',
//       'SAN ANTONIO',
//       'SAN FRANCISCO',
//       'SAN MIGUEL',
//       'SAN RAFAEL',
//       'TALOJONGON',
//       'TINAWAGAN',
//       'VINAGRE',
//     ],
//   },
//   TINAMBAC: {
//     province: 'CAMARINES SUR',
//     municipality: 'TINAMBAC',
//     region: 'REGION V',
//     barangay_list: [
//       'AGAY-AYAN',
//       'ANTIPOLO',
//       'BAGACAY',
//       'BANGA',
//       'BANI',
//       'BATAAN',
//       'BINALAY',
//       'BOLAOBALITE',
//       'BUENAVISTA',
//       'BUYO',
//       'CAGLILIOG',
//       'CALOCO',
//       'CAMAGONG',
//       'CANAYONAN',
//       'CAWAYNAN',
//       'DALIGAN',
//       'FILARCA (POB.)',
//       'LA MEDALLA (MILE 9)',
//       'LA PURISIMA (POB.)',
//       'LUPI',
//       'MAGSAYSAY (CAMP 4)',
//       'MAGTANG',
//       'MANANAO',
//       'NEW CAALUAN',
//       'OLAG GRANDE',
//       'OLAG PEQUEÑO',
//       'OLD CAALUAN',
//       'PAG-ASA',
//       'PANTAT',
//       'SAGRADA (CAMP 6)',
//       'SALVACION',
//       'SALVACION POBLACION',
//       'SAN ANTONIO',
//       'SAN ISIDRO (POB.)',
//       'SAN JOSE (TILTILAN)',
//       'SAN PASCUAL (POB.)',
//       'SAN RAMON (CAMP 7)',
//       'SAN ROQUE',
//       'SAN VICENTE',
//       'SANTA CRUZ (POB.)',
//       'SOGOD',
//       'TAMBANG',
//       'TIERRA NEVADA',
//       'UNION',
//     ],
//   },
//   BAGAMANOC: {
//     province: 'CATANDUANES',
//     municipality: 'BAGAMANOC',
//     region: 'REGION V',
//     barangay_list: [
//       'ANTIPOLO',
//       'BACAK',
//       'BAGATABAO',
//       'BUGAO',
//       'CAHAN',
//       'HINIPAAN',
//       'MAGSAYSAY',
//       'POBLACION',
//       'QUEZON (PANCAYANAN)',
//       'QUIGARAY',
//       'SAGRADA',
//       'SALVACION (PANUTO)',
//       'SAN ISIDRO',
//       'SAN RAFAEL (MAHANTOD)',
//       'SAN VICENTE',
//       'SANTA MESA',
//       'SANTA TERESA',
//       'SUCHAN',
//     ],
//   },
//   CARAMORAN: {
//     province: 'CATANDUANES',
//     municipality: 'CARAMORAN',
//     region: 'REGION V',
//     barangay_list: [
//       'BAYBAY (POB.)',
//       'BOCON',
//       'BOTHOAN (POB.)',
//       'BUENAVISTA',
//       'BULALACAO',
//       'CAMBURO',
//       'DARIAO',
//       'DATAG EAST',
//       'DATAG WEST',
//       'GUIAMLONG',
//       'HITOMA',
//       'ICANBATO (POB.)',
//       'INALMASINAN',
//       'IYAO',
//       'MABINI',
//       'MAUI',
//       'MAYSURAN',
//       'MILAVIGA',
//       'OBI',
//       'PANIQUE',
//       'SABANGAN',
//       'SABLOYON',
//       'SALVACION',
//       'SUPANG',
//       'TOYTOY (POB.)',
//       'TUBLI',
//       'TUCAO',
//     ],
//   },
//   GIGMOTO: {
//     province: 'CATANDUANES',
//     municipality: 'GIGMOTO',
//     region: 'REGION V',
//     barangay_list: [
//       'BIONG',
//       'DORORIAN',
//       'POBLACION DISTRICT I',
//       'POBLACION DISTRICT II',
//       'POBLACION DISTRICT III',
//       'SAN PEDRO',
//       'SAN VICENTE',
//       'SICMIL',
//       'SIORON',
//     ],
//   },
//   PANDAN: {
//     province: 'ANTIQUE',
//     municipality: 'PANDAN',
//     region: 'REGION VI',
//     barangay_list: [
//       'ARACAY',
//       'BADIANGAN',
//       'BAGUMBAYAN',
//       'BAYBAY',
//       'BOTBOT',
//       'BUANG',
//       'CABUGAO',
//       'CANDARI',
//       'CARMEN',
//       'CENTRO NORTE (POB.)',
//       'CENTRO SUR (POB.)',
//       'DIONELA',
//       'DUMROG',
//       'DUYONG',
//       'FRAGANTE',
//       'GUIA',
//       'IDIACACAN',
//       'JINALINAN',
//       'LUHOD-BAYANG',
//       'MAADIOS',
//       'MAG-ABA',
//       'NAPUID',
//       'NAURING',
//       'PATRIA',
//       'PERFECTA',
//       'SAN ANDRES',
//       'SAN JOAQUIN',
//       'SANTA ANA',
//       'SANTA CRUZ',
//       'SANTA FE',
//       'SANTO ROSARIO',
//       'TALISAY',
//       'TINGIB',
//       'ZALDIVAR',
//     ],
//   },
//   'PANGANIBAN (PAYO)': {
//     province: 'CATANDUANES',
//     municipality: 'PANGANIBAN (PAYO)',
//     region: 'REGION V',
//     barangay_list: [
//       'ALINAWAN',
//       'BABAGUAN',
//       'BAGONG BAYAN',
//       'BURABOD',
//       'CABUYOAN',
//       'CAGDARAO',
//       'MABINI',
//       'MACULIW',
//       'PANAY',
//       'SALVACION (POB.)',
//       'SAN ANTONIO',
//       'SAN JOAQUIN (POB.)',
//       'SAN JOSE (POB.)',
//       'SAN JUAN (POB.)',
//       'SAN MIGUEL',
//       'SAN NICOLAS (POB.)',
//       'SAN PEDRO (POB.)',
//       'SAN VICENTE (POB.)',
//       'SANTA ANA (POB.)',
//       'SANTA MARIA (POB.)',
//       'SANTO SANTIAGO (POB.)',
//       'TAOPON (PANGCAYANAN)',
//       'TIBO',
//     ],
//   },
//   'SAN ANDRES (CALOLBON)': {
//     province: 'CATANDUANES',
//     municipality: 'SAN ANDRES (CALOLBON)',
//     region: 'REGION V',
//     barangay_list: [
//       'AGOJO',
//       'ALIBUAG',
//       'ASGAD (JUAN M. ALBERTO)',
//       'BAGONG SIRANG',
//       'BARIHAY',
//       'BATONG PALOWAY',
//       'BELMONTE (POB.)',
//       'BISLIG',
//       'BON-OT',
//       'CABCAB',
//       'CABUNGAHAN',
//       'CARANGAG',
//       'CATAGBACAN',
//       'CODON',
//       'COMAGAYCAY',
//       'DATAG',
//       'DIVINO ROSTRO (POB.)',
//       'ESPERANZA (POB.)',
//       'HILAWAN',
//       'LICTIN',
//       'LUBAS',
//       'MANAMBRAG',
//       'MAYNGAWAY',
//       'PALAWIG',
//       'PUTING BAYBAY',
//       'RIZAL',
//       'SALVACION (POB.)',
//       'SAN ISIDRO',
//       'SAN JOSE',
//       'SAN ROQUE (POB.)',
//       'SAN VICENTE',
//       'SANTA CRUZ (POB.)',
//       'SAPANG PALAY (POB.)',
//       'TIBANG',
//       'TIMBAAN',
//       'TOMINAWOG',
//       'WAGDAS (POB.)',
//       'YOCTI',
//     ],
//   },
//   VIGA: {
//     province: 'CATANDUANES',
//     municipality: 'VIGA',
//     region: 'REGION V',
//     barangay_list: [
//       'ALMOJUELA',
//       'ANANONG',
//       'ASUNCION (POB.)',
//       'BATOHONAN',
//       'BEGONIA',
//       'BOTINAGAN',
//       'BUENAVISTA',
//       'BURGOS',
//       'DEL PILAR',
//       'MABINI',
//       'MAGSAYSAY',
//       'OGBONG',
//       'OSMEÑA',
//       'PEDRO VERA (SUMMIT)',
//       'PEÑAFRANCIA (POB.)',
//       'QUEZON',
//       'QUIRINO (ABUGAN)',
//       'RIZAL',
//       'ROXAS',
//       'SAGRADA',
//       'SAN ISIDRO (POB.)',
//       'SAN JOSE OCO',
//       'SAN JOSE POBLACION',
//       'SAN PEDRO (POB.)',
//       'SAN ROQUE (POB.)',
//       'SAN VICENTE (POB.)',
//       'SANTA ROSA',
//       'SOBOC',
//       'TAMBONGON',
//       'TINAGO',
//       'VILLA AURORA',
//     ],
//   },
//   VIRAC: {
//     province: 'CATANDUANES',
//     municipality: 'VIRAC',
//     region: 'REGION V',
//     barangay_list: [
//       'ANTIPOLO DEL NORTE',
//       'ANTIPOLO DEL SUR',
//       'BALITE',
//       'BATAG',
//       'BIGAA',
//       'BUENAVISTA',
//       'BUYO',
//       'CABIHIAN',
//       'CALABNIGAN',
//       'CALAMPONG',
//       'CALATAGAN PROPER',
//       'CALATAGAN TIBANG',
//       'CAPILIHAN',
//       'CASOOCAN',
//       'CAVINITAN',
//       'CONCEPCION (POB.)',
//       'CONSTANTINO (POB.)',
//       'DANICOP',
//       'DUGUI SAN ISIDRO',
//       'DUGUI SAN VICENTE',
//       'DUGUI TOO',
//       'F. TACORDA VILLAGE',
//       'FRANCIA (POB.)',
//       'GOGON CENTRO',
//       'GOGON SIRANGAN',
//       'HAWAN GRANDE',
//       'HAWAN ILAYA',
//       'HICMING',
//       'IBONG SAPA (SAN VICENTE SUR)',
//       'IGANG',
//       'JUAN M. ALBERTO (PONITON)',
//       'LANAO (POB.)',
//       'MAGNESIA DEL NORTE',
//       'MAGNESIA DEL SUR',
//       'MARCELO ALBERTO (POB.)',
//       'MARILIMA',
//       'PAJO BAGUIO',
//       'PAJO SAN ISIDRO',
//       'PALNAB DEL NORTE',
//       'PALNAB DEL SUR',
//       'PALTA BIG',
//       'PALTA SALVACION',
//       'PALTA SMALL',
//       'RAWIS (POB.)',
//       'SALVACION',
//       'SAN ISIDRO VILLAGE',
//       'SAN JOSE (POB.)',
//       'SAN JUAN (POB.)',
//       'SAN PABLO (POB.)',
//       'SAN PEDRO (POB.)',
//       'SAN ROQUE (POB.)',
//       'SAN VICENTE',
//       'SANTA CRUZ',
//       'SANTA ELENA (POB.)',
//       'SANTO CRISTO',
//       'SANTO DOMINGO',
//       'SANTO NIÑO',
//       'SIMAMLA',
//       'SOGOD-SIMAMLA',
//       'SOGOD-TIBGAO',
//       'TALISOY',
//       'TUBAON',
//       'VALENCIA',
//     ],
//   },
//   AROROY: {
//     province: 'MASBATE',
//     municipality: 'AROROY',
//     region: 'REGION V',
//     barangay_list: [
//       'AMBOLONG',
//       'AMOROY',
//       'AMUTAG',
//       'BAGAUMA',
//       'BALAWING',
//       'BALETE',
//       'BANGON',
//       'CABANGCALAN',
//       'CABAS-AN',
//       'CALANAY',
//       'CAPSAY',
//       'CONCEPCION',
//       'DAYHAGAN',
//       'DON PABLO DELA ROSA',
//       'GUMAHANG',
//       'JABOYOAN',
//       'LANANG',
//       'LUY-A',
//       'MACABUG',
//       'MALUBI',
//       'MANAGANAGA',
//       'MANAMOC',
//       'MARIPOSA',
//       'MATABA',
//       'MATALANGTALANG',
//       'MATANGOG',
//       'NABONGSORAN',
//       'PANGLE',
//       'PANIQUE',
//       'PINANAAN',
//       'POBLACION',
//       'PURO',
//       'SAN AGUSTIN',
//       'SAN ISIDRO',
//       'SAWANG',
//       'SYNDICATE',
//       'TALABAAN',
//       'TALIB',
//       'TIGBAO',
//       'TINAGO',
//       'TINIGBAN',
//     ],
//   },
//   BALENO: {
//     province: 'MASBATE',
//     municipality: 'BALENO',
//     region: 'REGION V',
//     barangay_list: [
//       'BAAO',
//       'BANASE',
//       'BATUILA',
//       'CAGARA',
//       'CAGPANDAN',
//       'CANCAHORAO',
//       'CANJUNDAY',
//       'DOCOL',
//       'EASTERN CAPSAY',
//       'GABI',
//       'GANGAO',
//       'LAGTA',
//       'LAHONG INTERIOR',
//       'LAHONG PROPER',
//       'LIPATA',
//       'MADANGCALAN',
//       'MAGDALENA',
//       'MANOBOC',
//       'OBONGON DIOT',
//       'POBLACION',
//       'POLOT',
//       'POTOSON',
//       'SOG-ONG',
//       'TINAPIAN',
//     ],
//   },
//   BALUD: {
//     province: 'MASBATE',
//     municipality: 'BALUD',
//     region: 'REGION V',
//     barangay_list: [
//       'BAYBAY (LUMOCAB)',
//       'BONGCANAWAY',
//       'CALUMPANG',
//       'CANTIL',
//       'CASAMONGAN',
//       'DANAO',
//       'DAO',
//       'GUINBANWAHAN',
//       'ILAYA',
//       'JANGAN',
//       'JINTOTOLO',
//       'MABUHAY (BONGCANAWAY III)',
//       'MAPILI',
//       'MAPITOGO',
//       'PAJO',
//       'PALANE',
//       'PANGUIRANAN',
//       'PANUBIGAN',
//       'POBLACION (BALUD)',
//       'PULANDUTA',
//       'QUINAYANGAN DIOTAY',
//       'QUINAYANGAN TONGA',
//       'SALVACION',
//       'SAMPAD',
//       'SAN ANDRES (QUINAYANGAN DACU)',
//       'SAN ANTONIO',
//       'SAPATOS',
//       'TALISAY',
//       'TONGA',
//       'UBO',
//       'VICTORY (VICTORIA)',
//       'VILLA ALVAREZ',
//     ],
//   },
//   BATUAN: {
//     province: 'BOHOL',
//     municipality: 'BATUAN',
//     region: 'REGION VII',
//     barangay_list: [
//       'ALOJA',
//       'BEHIND THE CLOUDS (SAN JOSE)',
//       'CABACNITAN',
//       'CAMBACAY',
//       'CANTIGDAS',
//       'GARCIA',
//       'JANLUD',
//       'POBLACION NORTE',
//       'POBLACION SUR',
//       'POBLACION VIEJA (LONGSUDAAN)',
//       'QUEZON',
//       'QUIRINO',
//       'RIZAL',
//       'ROSARIOHAN',
//       'SANTA CRUZ',
//     ],
//   },
//   CATAINGAN: {
//     province: 'MASBATE',
//     municipality: 'CATAINGAN',
//     region: 'REGION V',
//     barangay_list: [
//       'ABACA',
//       'AGUADA',
//       'BADIANG',
//       'BAGUMBAYAN',
//       'CADULAWAN',
//       'CAGBATANG',
//       'CHIMENEA',
//       'CONCEPCION',
//       'CURVADA',
//       'DIVISORIA',
//       'DOMOROG',
//       'ESTAMPAR',
//       'GAHIT',
//       'LIBTONG',
//       'LIONG',
//       'MAANAHAO',
//       'MADAMBA',
//       'MALOBAGO',
//       'MATAYUM',
//       'MATUBINAO',
//       'MINTAC',
//       'NADAWISAN',
//       'OSMEÑA',
//       'PAWICAN',
//       'PITOGO',
//       'POBLACION',
//       'QUEZON',
//       'SAN ISIDRO',
//       'SAN JOSE',
//       'SAN PEDRO',
//       'SAN RAFAEL',
//       'SANTA TERESITA',
//       'SANTO NIÑO',
//       'TAGBOAN',
//       'TUYBO',
//       'VILLA POGADO',
//     ],
//   },
//   CAWAYAN: {
//     province: 'MASBATE',
//     municipality: 'CAWAYAN',
//     region: 'REGION V',
//     barangay_list: [
//       'BEGIA',
//       'CABAYUGAN',
//       'CABUNGAHAN',
//       'CALAPAYAN',
//       'CALUMPANG',
//       'CHICO ISLAND',
//       'DALIPE',
//       'DIVISORIA',
//       'GILOTONGAN',
//       'GUIOM',
//       'IRAYA',
//       'ITOMBATO',
//       'LAGUE-LAGUE',
//       'LIBERTAD',
//       'LOOC',
//       'MACTAN',
//       'MADBAD',
//       'MAHAYAHAY',
//       'MAIHAO',
//       'MALBUG',
//       'NARO',
//       'PALOBANDERA',
//       'PANANAWAN',
//       'PEÑA ISLAND',
//       'PIN-AS',
//       'POBLACION',
//       'PULOT',
//       'PUNTA BATSAN',
//       'R.M. MAGBALON (BEBINAN)',
//       'RECODO',
//       'SAN JOSE',
//       'SAN VICENTE',
//       'TABERNA',
//       'TALISAY',
//       'TUBOG',
//       'TUBURAN',
//       'VILLAHERMOSA',
//     ],
//   },
//   DIMASALANG: {
//     province: 'MASBATE',
//     municipality: 'DIMASALANG',
//     region: 'REGION V',
//     barangay_list: [
//       'BALANTAY',
//       'BALOCAWE',
//       'BANAHAO',
//       'BUENAFLOR',
//       'BURACAN',
//       'CABANOYOAN',
//       'CABRERA',
//       'CADULAN',
//       'CALABAD',
//       'CANOMAY',
//       'DIVISORIA',
//       'GAID',
//       'GREGORIO ALINO (PIA-ONG)',
//       'MAGCARAGUIT',
//       'MAMBOG',
//       'POBLACION',
//       'RIZAL',
//       'SAN VICENTE',
//       'SUBA',
//       'T.R. YANGCO (YANCO)',
//     ],
//   },
//   MANDAON: {
//     province: 'MASBATE',
//     municipality: 'MANDAON',
//     region: 'REGION V',
//     barangay_list: [
//       'ALAS',
//       'AYAT',
//       'BAT-ONGAN',
//       'BUGTONG',
//       'BURI',
//       'CABITAN',
//       'CAGMASOSO',
//       'CANOMOY',
//       'CENTRO',
//       'DAYAO',
//       'GUINCAIPTAN',
//       'LAGUINBANWA',
//       'LANTANGAN',
//       'LOOC',
//       'MABATOBATO',
//       'MAOLINGON',
//       'NAILABAN',
//       'NANIPSAN',
//       'PINAMANGCAAN',
//       'POBLACION',
//       'POLO DACU',
//       'SAN JUAN',
//       'SAN PABLO',
//       'SANTA FE',
//       'TAGPU',
//       'TUMALAYTAY',
//     ],
//   },
//   'MASBATE': {
//     province: 'MASBATE',
//     municipality: 'MASBATE',
//     region: 'REGION V',
//     barangay_list: [
//       'ANAS',
//       'ASID',
//       'B. TITONG',
//       'BAGUMBAYAN',
//       'BANTIGUE',
//       'BAPOR (POB.)',
//       'BATUHAN',
//       'BAYOMBON',
//       'BIYONG',
//       'BOLO',
//       'CAGAY',
//       'CAWAYAN EXTERIOR',
//       'CAWAYAN INTERIOR',
//       'CENTRO (POB.)',
//       'ESPINOSA',
//       'F. MAGALLANES',
//       'IBINGAY',
//       'IGANG',
//       'KALIPAY (POB.)',
//       'KINAMALIGAN',
//       'MALINTA',
//       'MAPIÑA',
//       'MAYNGARAN',
//       'NURSERY',
//       'PATING (POB.)',
//       'PAWA',
//       'SINALONGAN',
//       'TUGBO',
//       'UBONGAN DACU',
//       'USAB',
//     ],
//   },
//   MILAGROS: {
//     province: 'MASBATE',
//     municipality: 'MILAGROS',
//     region: 'REGION V',
//     barangay_list: [
//       'BACOLOD',
//       'BANGAD',
//       'BARA',
//       'BONBON',
//       'CALASUCHE',
//       'CALUMPANG (TAISAN)',
//       'CAPACULAN',
//       'CAYABON',
//       'GUINLUTHANGAN',
//       'JAMORAWON',
//       'MAGSALANGI',
//       'MATAGBAC',
//       'MATANGLAD',
//       'MATIPORON',
//       'MOISES R. ESPINOSA',
//       'NARANGASAN',
//       'PAMANGPANGON',
//       'PARAISO (POTOT)',
//       'POBLACION EAST',
//       'POBLACION WEST',
//       'SAN ANTONIO',
//       'SAN CARLOS',
//       'SAWMILL',
//       'TAGBON',
//       'TAWAD',
//       'TIGBAO',
//       'TINACLIPAN (BATO)',
//     ],
//   },
//   MOBO: {
//     province: 'MASBATE',
//     municipality: 'MOBO',
//     region: 'REGION V',
//     barangay_list: [
//       'BAANG',
//       'BAGACAY',
//       'BALATUCAN',
//       'BARAG',
//       'DACU',
//       'FABRICA',
//       'GUINTORELAN',
//       'HOLJOGON',
//       'LALAGUNA',
//       'LOMOCLOC',
//       'LUYONG CATUNGAN',
//       'MABUHAY',
//       'MANDALI',
//       'MAPUYO',
//       'MARINTOC',
//       'NASUNDUAN',
//       'PINAMALATICAN',
//       'PINAMARBUHAN',
//       'POBLACION DIST. I',
//       'POBLACION DIST. II',
//       'POLOT',
//       'SAMBULAWAN',
//       'SANTA MARIA',
//       'SAWMILL',
//       'TABUC',
//       'TUGAWE',
//       'TUGBO',
//       'UMABAY EXTERIOR',
//       'UMABAY INTERIOR',
//     ],
//   },
//   MONREAL: {
//     province: 'MASBATE',
//     municipality: 'MONREAL',
//     region: 'REGION V',
//     barangay_list: [
//       'CANTORNA',
//       'FAMOSA',
//       'GUINHADAP',
//       'MACARTHUR',
//       'MAGLAMBONG',
//       'MOROCBOROCAN',
//       'POBLACION',
//       'REAL',
//       'RIZAL',
//       'SANTO NIÑO',
//       'TOGORON',
//     ],
//   },
//   PALANAS: {
//     province: 'MASBATE',
//     municipality: 'PALANAS',
//     region: 'REGION V',
//     barangay_list: [
//       'ANTIPOLO',
//       'BANCO',
//       'BIGA-A',
//       'BONTOD',
//       'BUENASUERTE',
//       'INTUSAN',
//       'JOSE A. ABENIR SR. (LIBTONG)',
//       'MAANAHAO',
//       'MABINI',
//       'MALATAWAN',
//       'MALIBAS',
//       'MARAVILLA',
//       'MATUGNAO',
//       'MIABAS',
//       'NABANGIG',
//       'NIPA',
//       'PARINA',
//       'PIÑA',
//       'POBLACION',
//       'SALVACION',
//       'SAN ANTONIO',
//       'SAN CARLOS',
//       'SAN ISIDRO',
//       'SANTA CRUZ',
//     ],
//   },
//   'PIO V. CORPUZ (LIMBUHAN)': {
//     province: 'MASBATE',
//     municipality: 'PIO V. CORPUZ (LIMBUHAN)',
//     region: 'REGION V',
//     barangay_list: [
//       'ALEGRIA',
//       'BUENASUERTE',
//       'BUGANG',
//       'BUGTONG',
//       'BUNDUCAN',
//       'CABANGRAYAN',
//       'CALONGONGAN',
//       'CASABANGAN',
//       'GUINDAWAHAN',
//       'LABIGAN',
//       'LAMPUYANG',
//       'MABUHAY',
//       'PALHO',
//       'POBLACION',
//       'SALVACION',
//       'TANQUE',
//       'TUBIGAN',
//       'TUBOG',
//     ],
//   },
//   USON: {
//     province: 'MASBATE',
//     municipality: 'USON',
//     region: 'REGION V',
//     barangay_list: [
//       'ARADO',
//       'ARMENIA',
//       'AURORA',
//       'BADLING',
//       'BONIFACIO',
//       'BUENASUERTE',
//       'BUENAVISTA',
//       'CAMPANA',
//       'CANDELARIA',
//       'CENTRO',
//       'CROSSING',
//       'DAPDAP',
//       'DEL CARMEN',
//       'DEL ROSARIO',
//       'LIBERTAD',
//       'MABINI',
//       'MABUHAY',
//       'MADAO',
//       'MAGSAYSAY',
//       'MARCELLA',
//       'MIAGA',
//       'MONGAHAY',
//       'MOROCBOROCAN',
//       'PAGUIHAMAN',
//       'PANICIJAN',
//       'POBLACION',
//       'QUEZON',
//       'SAN ISIDRO',
//       'SAN JOSE',
//       'SAN MATEO',
//       'SAN RAMON',
//       'SAN VICENTE',
//       'SANTO CRISTO',
//       'SAWANG',
//       'SIMAWA',
//     ],
//   },
//   BARCELONA: {
//     province: 'SORSOGON',
//     municipality: 'BARCELONA',
//     region: 'REGION V',
//     barangay_list: [
//       'ALEGRIA',
//       'BAGACAY',
//       'BANGATE',
//       'BUGTONG',
//       'CAGANG',
//       'FABRICA',
//       'JIBONG',
//       'LAGO',
//       'LAYOG',
//       'LUNETA',
//       'MACABARI',
//       'MAPAPAC',
//       'OLANDIA',
//       'PAGHALUBAN',
//       'POBLACION CENTRAL',
//       'POBLACION NORTE',
//       'POBLACION SUR',
//       'PUTIAO',
//       'SAN ANTONIO',
//       'SAN ISIDRO',
//       'SAN RAMON',
//       'SAN VICENTE',
//       'SANTA CRUZ',
//       'SANTA LOURDES',
//       'TAGDON',
//     ],
//   },
//   BULAN: {
//     province: 'SORSOGON',
//     municipality: 'BULAN',
//     region: 'REGION V',
//     barangay_list: [
//       'A. BONIFACIO',
//       'ABAD SANTOS',
//       'AGUINALDO',
//       'ANTIPOLO',
//       'BEGUIN',
//       'BENIGNO S. AQUINO (IMELDA)',
//       'BICAL',
//       'BONGA',
//       'BUTAG',
//       'CADANDANAN',
//       'CALOMAGON',
//       'CALPI',
//       'COCOK-CABITAN',
//       'DAGANAS',
//       'DANAO',
//       'DOLOS',
//       'E. QUIRINO',
//       'FABRICA',
//       'G. DEL PILAR (TANGA)',
//       'GATE',
//       'INARARAN',
//       'J. GERONA',
//       'J.P. LAUREL (PON-OD)',
//       'JAMORAWON',
//       'LAJONG',
//       'LIBERTAD',
//       'M. ROXAS',
//       'MAGSAYSAY',
//       'MANAGANAGA',
//       'MARINAB',
//       'MONTECALVARIO',
//       'N. ROQUE (RIZAL)',
//       'NAMO',
//       'NASUJE',
//       'OBRERO',
//       'OSMEÑA',
//       'OTAVI',
//       'PADRE DIAZ',
//       'PALALE',
//       'QUEZON',
//       'R. GERONA',
//       'RECTO',
//       'SAGRADA',
//       'SAN FRANCISCO',
//       'SAN ISIDRO',
//       'SAN JUAN BAG-O',
//       'SAN JUAN DAAN',
//       'SAN RAFAEL',
//       'SAN RAMON',
//       'SAN VICENTE',
//       'SANTA REMEDIOS',
//       'SANTA TERESITA',
//       'SIGAD',
//       'SOMAGONGSONG',
//       'TAROMATA',
//       'ZONE I POB. (BGY. 1- SOUTH ILAWOD)',
//       'ZONE II POB. (BGY. 2- WEST ILAWOD)',
//       'ZONE III POB. (BGY. 3- EAST ILAWOD)',
//       'ZONE IV POB. (BGY. 4- WEST CENTRAL)',
//       'ZONE V POB. (BGY. 5-LANIPAN)',
//       'ZONE VI POB. (BGY. 6- BAYBAY)',
//       'ZONE VII POB. (BGY. 7- IRAYA)',
//       'ZONE VIII POB. (BGY. 8- LOYO)',
//     ],
//   },
//   BULUSAN: {
//     province: 'SORSOGON',
//     municipality: 'BULUSAN',
//     region: 'REGION V',
//     barangay_list: [
//       'BAGACAY',
//       'CENTRAL (POB.)',
//       'COGON',
//       'DANCALAN',
//       'DAPDAP (POB.)',
//       'LALUD',
//       'LOOBAN (POB.)',
//       'MABUHAY (POB.)',
//       'MADLAWON (POB.)',
//       'POCTOL (POB.)',
//       'POROG',
//       'SABANG (POB.)',
//       'SALVACION',
//       'SAN ANTONIO',
//       'SAN BERNARDO',
//       'SAN FRANCISCO',
//       'SAN ISIDRO',
//       'SAN JOSE',
//       'SAN RAFAEL',
//       'SAN ROQUE',
//       'SAN VICENTE (BUHANG)',
//       'SANTA BARBARA',
//       'SAPNGAN (POB.)',
//       'TINAMPO',
//     ],
//   },
//   CASTILLA: {
//     province: 'SORSOGON',
//     municipality: 'CASTILLA',
//     region: 'REGION V',
//     barangay_list: [
//       'AMOMONTING',
//       'BAGALAYAG',
//       'BAGONG SIRANG',
//       'BONGA',
//       'BUENAVISTA',
//       'BURABOD',
//       'CABURACAN',
//       'CANJELA',
//       'COGON',
//       'CUMADCAD',
//       'DANGCALAN',
//       'DINAPA',
//       'LA UNION',
//       'LIBTONG',
//       'LORETO',
//       'MACALAYA',
//       'MARACABAC',
//       'MAYON',
//       'MAYPANGI',
//       'MILAGROSA',
//       'MILUYA',
//       'MONTE CARMELO',
//       'ORAS',
//       'PANDAN',
//       'POBLACION',
//       'QUIRAPI',
//       'SACLAYAN',
//       'SALVACION',
//       'SAN ISIDRO',
//       'SAN RAFAEL',
//       'SAN ROQUE',
//       'SAN VICENTE',
//       'SOGOY',
//       'TOMALAYTAY',
//     ],
//   },
//   DONSOL: {
//     province: 'SORSOGON',
//     municipality: 'DONSOL',
//     region: 'REGION V',
//     barangay_list: [
//       'ALIN',
//       'AWAI',
//       'BANBAN',
//       'BANDI',
//       'BANUANG GURANG',
//       'BARAS',
//       'BAYAWAS',
//       'BORORAN BARANGAY 1 (POB.)',
//       'CABUGAO',
//       'CENTRAL BARANGAY 2 (POB.)',
//       'CRISTO',
//       'DANCALAN',
//       'DE VERA',
//       'GIMAGAAN',
//       'GIRAWAN',
//       'GOGON',
//       'GURA',
//       'JUAN ADRE',
//       'LOURDES',
//       'MABINI',
//       'MALAPOC',
//       'MALINAO',
//       'MARKET SITE BARANGAY 3 (POB.)',
//       'NEW MAGUISA',
//       'OGOD (CROSSING)',
//       'OLD MAGUISA',
//       'ORANGE',
//       'PANGPANG',
//       'PARINA',
//       'PAWALA',
//       'PINAMANAAN',
//       'POSO POB. (BARANGAY 5)',
//       'PUNTA WALING-WALING POB. (BARANGAY 4)',
//       'RAWIS',
//       'SAN ANTONIO',
//       'SAN ISIDRO',
//       'SAN JOSE',
//       'SAN RAFAEL',
//       'SAN RAMON',
//       'SAN VICENTE',
//       'SANTA CRUZ',
//       'SEVILLA',
//       'SIBAGO',
//       'SUGUIAN',
//       'TAGBAC',
//       'TINANOGAN',
//       'TONGDOL',
//       'TRES MARIAS',
//       'TUBA',
//       'TUPAS',
//       'VINISITAHAN',
//     ],
//   },
//   GUBAT: {
//     province: 'SORSOGON',
//     municipality: 'GUBAT',
//     region: 'REGION V',
//     barangay_list: [
//       'ARIMAN',
//       'BAGACAY',
//       'BALUD DEL NORTE (POB.)',
//       'BALUD DEL SUR (POB.)',
//       'BENGUET',
//       'BENTUCO',
//       'BERIRAN',
//       'BUENAVISTA',
//       'BULACAO',
//       'CABIGAAN',
//       'CABIGUHAN',
//       'CARRIEDO',
//       'CASILI',
//       'COGON',
//       'COTA NA DACO (POB.)',
//       'DITA',
//       'JUPI',
//       'LAPINIG',
//       'LUNA-CANDOL (POB.)',
//       'MANAPAO',
//       'MANOOK (POB.)',
//       'NAAGTAN',
//       'NATO',
//       'NAZARENO',
//       'OGAO',
//       'PACO',
//       'PANGANIBAN (POB.)',
//       'PARADIJON (POB.)',
//       'PATAG',
//       'PAYAWIN',
//       'PINONTINGAN (POB.)',
//       'RIZAL',
//       'SAN IGNACIO',
//       'SANGAT',
//       'SANTA ANA',
//       'TABI',
//       'TAGAYTAY',
//       'TIGKIW',
//       'TIRIS',
//       'TOGAWE',
//       'UNION',
//       'VILLAREAL',
//     ],
//   },
//   IROSIN: {
//     province: 'SORSOGON',
//     municipality: 'IROSIN',
//     region: 'REGION V',
//     barangay_list: [
//       'BACOLOD (POB.)',
//       'BAGSANGAN',
//       'BATANG',
//       'BOLOS',
//       'BUENAVISTA',
//       'BULAWAN',
//       'CARRIEDO',
//       'CASINI',
//       'CAWAYAN',
//       'COGON',
//       'GABAO',
//       'GULANG-GULANG',
//       'GUMAPIA',
//       'LIANG',
//       'MACAWAYAN',
//       'MAPASO',
//       'MONBON',
//       'PATAG',
//       'SALVACION',
//       'SAN AGUSTIN (POB.)',
//       'SAN ISIDRO',
//       'SAN JUAN (POB.)',
//       'SAN JULIAN (POB.)',
//       'SAN PEDRO',
//       'SANTO DOMINGO (LAMBOON)',
//       'TABON-TABON',
//       'TINAMPO',
//       'TONGDOL',
//     ],
//   },
//   JUBAN: {
//     province: 'SORSOGON',
//     municipality: 'JUBAN',
//     region: 'REGION V',
//     barangay_list: [
//       'ANOG',
//       'AROROY',
//       'BACOLOD',
//       'BINANUAHAN',
//       'BIRIRAN',
//       'BURABURAN',
//       'CALATEO',
//       'CALMAYON',
//       'CAROHAYON',
//       'CATANAGAN',
//       'CATANUSAN',
//       'COGON',
//       'EMBARCADERO',
//       'GURUYAN',
//       'LAJONG',
//       'MAALO',
//       'NORTH POBLACION',
//       'PUTING SAPA',
//       'RANGAS',
//       'SABLAYAN',
//       'SIPAYA',
//       'SOUTH POBLACION',
//       'TABOC',
//       'TINAGO',
//       'TUGHAN',
//     ],
//   },
//   MATNOG: {
//     province: 'SORSOGON',
//     municipality: 'MATNOG',
//     region: 'REGION V',
//     barangay_list: [
//       'BALOCAWE',
//       'BANOGAO',
//       'BANUANGDAAN',
//       'BARIIS',
//       'BOLO',
//       'BON-OT BIG',
//       'BON-OT SMALL',
//       'CABAGAHAN',
//       'CALAYUAN',
//       'CALINTAAN',
//       'CALOOCAN (POB.)',
//       'CALPI',
//       'CAMACHILES (POB.)',
//       'CAMCAMAN (POB.)',
//       'CORON-CORON',
//       'CULASI',
//       'GADGARON',
//       'GENABLAN OCCIDENTAL',
//       'GENABLAN ORIENTAL',
//       'HIDHID',
//       'LABOY',
//       'LAJONG',
//       'MAMBAJOG',
//       'MANJUNLAD',
//       'MANURABI',
//       'NABURACAN',
//       'PAGHULIRAN',
//       'PANGI',
//       'PAWA',
//       'POROPANDAN',
//       'SANTA ISABEL',
//       'SINALMACAN',
//       'SINANG-ATAN',
//       'SINIBARAN',
//       'SISIGON',
//       'SUA',
//       'SULANGAN',
//       'TABLAC (POB.)',
//       'TABUNAN (POB.)',
//       'TUGAS',
//     ],
//   },
//   'PRIETO DIAZ': {
//     province: 'SORSOGON',
//     municipality: 'PRIETO DIAZ',
//     region: 'REGION V',
//     barangay_list: [
//       'BRILLANTE (POB.)',
//       'BULAWAN',
//       'CALAO',
//       'CARAYAT',
//       'DIAMANTE',
//       'GOGON',
//       'LUPI',
//       'MANINGCAY DE ORO',
//       'MANLABONG',
//       'PERLAS',
//       'QUIDOLOG',
//       'RIZAL',
//       'SAN ANTONIO',
//       'SAN FERNANDO',
//       'SAN ISIDRO',
//       'SAN JUAN',
//       'SAN RAFAEL',
//       'SAN RAMON',
//       'SANTA LOURDES',
//       'SANTO DOMINGO',
//       'TALISAYAN',
//       'TUPAZ',
//       'ULAG',
//     ],
//   },
//   'SANTA MAGDALENA': {
//     province: 'SORSOGON',
//     municipality: 'SANTA MAGDALENA',
//     region: 'REGION V',
//     barangay_list: [
//       'BARANGAY POBLACION I',
//       'BARANGAY POBLACION II',
//       'BARANGAY POBLACION III',
//       'BARANGAY POBLACION IV',
//       'LA ESPERANZA',
//       'PEÑAFRANCIA',
//       'SALVACION',
//       'SAN ANTONIO',
//       'SAN BARTOLOME (TALAONGAN)',
//       'SAN EUGENIO',
//       'SAN ISIDRO',
//       'SAN RAFAEL',
//       'SAN ROQUE',
//       'SAN SEBASTIAN',
//     ],
//   },
//   'SORSOGON': {
//     province: 'SORSOGON',
//     municipality: 'SORSOGON',
//     region: 'REGION V',
//     barangay_list: [
//       'ABUYOG',
//       'ALMENDRAS-COGON (POB.)',
//       'BALETE',
//       'BALOGO',
//       'BALOGO ( FR. THE MUN. OF BACON)',
//       'BARAYONG',
//       'BASUD',
//       'BATO',
//       'BIBINCAHAN',
//       'BITAN-O/DALIPAY (POB.)',
//       'BOGÑA',
//       'BON-OT',
//       'BUCALBUCALAN',
//       'BUENAVISTA',
//       'BUENAVISTA ( FR. THE MUN. OF BACON)',
//       'BUHATAN',
//       'BULABOG',
//       'BURABOD (POB.)',
//       'CABARBUHAN',
//       'CABID-AN',
//       'CAMBULAGA',
//       'CAPUY',
//       'CARICARAN',
//       'DEL ROSARIO',
//       'GATBO',
//       'GIMALOTO',
//       'GUINLAJON',
//       'JAMISLAGAN',
//       'MACABOG',
//       'MARICRUM',
//       'MARINAS',
//       'OSIAO',
//       'PAMURAYAN',
//       'PANGPANG',
//       'PANLAYAAN',
//       'PEÑAFRANCIA',
//       'PIOT (POB.)',
//       'POBLACION',
//       'POLVORISTA (POB.)',
//       'RAWIS',
//       'RIZAL',
//       'SALOG (POB.)',
//       'SALVACION',
//       'SALVACION ( FR. THE MUN. OF BACON)',
//       'SAMPALOC (POB.)',
//       'SAN ISIDRO',
//       'SAN ISIDRO ( FR. THE MUN. OF BACON)',
//       'SAN JUAN ( FR. THE MUN. OF BACON)',
//       'SAN JUAN (RORO)',
//       'SAN PASCUAL',
//       'SAN RAMON',
//       'SAN ROQUE',
//       'SAN VICENTE',
//       'SANTA CRUZ',
//       'SANTA LUCIA',
//       'SANTO DOMINGO',
//       'SANTO NIÑO',
//       'SAWANGA',
//       'SIRANGAN (POB.)',
//       'SUGOD',
//       'SULUCAN (POB.)',
//       'TALISAY (POB.)',
//       'TICOL',
//       'TUGOS',
//     ],
//   },
//   ALTAVAS: {
//     province: 'AKLAN',
//     municipality: 'ALTAVAS',
//     region: 'REGION VI',
//     barangay_list: [
//       'CABANGILA',
//       'CABUGAO',
//       'CATMON',
//       'DALIPDIP',
//       'GINICTAN',
//       'LINAYASAN',
//       'LUMAYNAY',
//       'LUPO',
//       'MAN-UP',
//       'ODIONG',
//       'POBLACION',
//       'QUINASAY-AN',
//       'TALON',
//       'TIBIAO',
//     ],
//   },
//   BATAN: {
//     province: 'AKLAN',
//     municipality: 'BATAN',
//     region: 'REGION VI',
//     barangay_list: [
//       'AMBOLONG',
//       'ANGAS',
//       'BAY-ANG',
//       'CABUGAO',
//       'CAIYANG',
//       'CAMALIGAN',
//       'CAMANCI',
//       'IPIL',
//       'LALAB',
//       'LUPIT',
//       'MAGPAG-ONG',
//       'MAGUBAHAY',
//       'MAMBUQUIAO',
//       'MAN-UP',
//       'MANDONG',
//       'NAPTI',
//       'PALAY',
//       'POBLACION',
//       'SONGCOLAN',
//       'TABON',
//     ],
//   },
//   BURUANGA: {
//     province: 'AKLAN',
//     municipality: 'BURUANGA',
//     region: 'REGION VI',
//     barangay_list: [
//       'ALEGRIA',
//       'BAGONGBAYAN',
//       'BALUSBOS',
//       'BEL-IS',
//       'CABUGAN',
//       'EL PROGRESO',
//       'HABANA',
//       'KATIPUNAN',
//       'MAYAPAY',
//       'NAZARETH',
//       'PANILONGAN',
//       'POBLACION',
//       'SANTANDER',
//       'TAG-OSIP',
//       'TIGUM',
//     ],
//   },
//   IBAJAY: {
//     province: 'AKLAN',
//     municipality: 'IBAJAY',
//     region: 'REGION VI',
//     barangay_list: [
//       'AGBAGO',
//       'AGDUGAYAN',
//       'ANTIPOLO',
//       'APARICIO',
//       'AQUINO',
//       'ASLUM',
//       'BAGACAY',
//       'BATUAN',
//       'BUENAVISTA',
//       'BUGTONGBATO',
//       'CABUGAO',
//       'CAPILIJAN',
//       'COLONGCOLONG',
//       'LAGUINBANUA',
//       'MABUSAO',
//       'MALINDOG',
//       'MALOCO',
//       'MINA-A',
//       'MONLAQUE',
//       'NAILE',
//       'NAISUD',
//       'NALIGUSAN',
//       'ONDOY',
//       'POBLACION',
//       'POLO',
//       'REGADOR',
//       'RIVERA',
//       'RIZAL',
//       'SAN ISIDRO',
//       'SAN JOSE',
//       'SANTA CRUZ',
//       'TAGBAYA',
//       'TUL-ANG',
//       'UNAT',
//       'YAWAN',
//     ],
//   },
//   KALIBO: {
//     province: 'AKLAN',
//     municipality: 'KALIBO',
//     region: 'REGION VI',
//     barangay_list: [
//       'ANDAGAW',
//       'BACHAW NORTE',
//       'BACHAW SUR',
//       'BRIONES',
//       'BUSWANG NEW',
//       'BUSWANG OLD',
//       'CAANO',
//       'ESTANCIA',
//       'LINABUAN NORTE',
//       'MABILO',
//       'MOBO',
//       'NALOOK',
//       'POBLACION',
//       'POOK',
//       'TIGAYON',
//       'TINIGAW',
//     ],
//   },
//   LEZO: {
//     province: 'AKLAN',
//     municipality: 'LEZO',
//     region: 'REGION VI',
//     barangay_list: [
//       'AGCAWILAN',
//       'BAGTO',
//       'BUGASONGAN',
//       'CARUGDOG',
//       'COGON',
//       'IBAO',
//       'MINA',
//       'POBLACION',
//       'SANTA CRUZ',
//       'SANTA CRUZ BIGAA',
//       'SILAKAT-NONOK',
//       'TAYHAWAN',
//     ],
//   },
//   LIBACAO: {
//     province: 'AKLAN',
//     municipality: 'LIBACAO',
//     region: 'REGION VI',
//     barangay_list: [
//       'AGMAILIG',
//       'ALFONSO XII',
//       'BATOBATO',
//       'BONZA',
//       'CALACABIAN',
//       'CALAMCAN',
//       'CAN-AWAN',
//       'CASIT-AN',
//       'DALAGSA-AN',
//       'GUADALUPE',
//       'JANLUD',
//       'JULITA',
//       'LUCTOGA',
//       'MAGUGBA',
//       'MANIKA',
//       'OGSIP',
//       'ORTEGA',
//       'OYANG',
//       'PAMPANGO',
//       'PINONOY',
//       'POBLACION',
//       'RIVERA',
//       'ROSAL',
//       'SIBALEW',
//     ],
//   },
//   MADALAG: {
//     province: 'AKLAN',
//     municipality: 'MADALAG',
//     region: 'REGION VI',
//     barangay_list: [
//       'ALAMINOS',
//       'ALAS-AS',
//       'BACYANG',
//       'BALACTASAN',
//       'CABANGAHAN',
//       'CABILAWAN',
//       'CATABANA',
//       'DIT-ANA',
//       'GALICIA',
//       'GUINATU-AN',
//       'LOGOHON',
//       'MAMBA',
//       'MARIA CRISTINA',
//       'MEDINA',
//       'MERCEDES',
//       'NAPNOT',
//       'PANG-ITAN',
//       'PANINGAYAN',
//       'PANIPIASON',
//       'POBLACION',
//       'SAN JOSE',
//       'SINGAY',
//       'TALANGBAN',
//       'TALIMAGAO',
//       'TIGBAWAN',
//     ],
//   },
//   MAKATO: {
//     province: 'AKLAN',
//     municipality: 'MAKATO',
//     region: 'REGION VI',
//     barangay_list: [
//       'AGBALOGO',
//       'AGLUCAY',
//       'ALIBAGON',
//       'BAGONG BARRIO',
//       'BAYBAY',
//       'CABATANGA',
//       'CAJILO',
//       'CALANGCANG',
//       'CALIMBAJAN',
//       'CASTILLO',
//       'CAYANGWAN',
//       'DUMGA',
//       'LIBANG',
//       'MANTIGUIB',
//       'POBLACION',
//       'TIBIAWAN',
//       'TINA',
//       'TUGAS',
//     ],
//   },
//   MALAY: {
//     province: 'AKLAN',
//     municipality: 'MALAY',
//     region: 'REGION VI',
//     barangay_list: [
//       'ARGAO',
//       'BALABAG',
//       'BALUSBUS',
//       'CABULIHAN',
//       'CATICLAN',
//       'COGON',
//       'CUBAY NORTE',
//       'CUBAY SUR',
//       'DUMLOG',
//       'MANOC-MANOC',
//       'MOTAG',
//       'NAASUG',
//       'NABAOY',
//       'NAPAAN',
//       'POBLACION',
//       'SAN VIRAY',
//       'YAPAK',
//     ],
//   },
//   NABAS: {
//     province: 'AKLAN',
//     municipality: 'NABAS',
//     region: 'REGION VI',
//     barangay_list: [
//       'ALIMBO-BAYBAY',
//       'BUENAFORTUNA',
//       'BUENASUERTE',
//       'BUENAVISTA',
//       'GIBON',
//       'HABANA',
//       'LASERNA',
//       'LIBERTAD',
//       'MAGALLANES',
//       'MATABANA',
//       'NAGUSTAN',
//       'PAWA',
//       'PINATUAD',
//       'POBLACION',
//       'RIZAL',
//       'SOLIDO',
//       'TAGOROROC',
//       'TOLEDO',
//       'UNIDOS',
//       'UNION',
//     ],
//   },
//   'NEW WASHINGTON': {
//     province: 'AKLAN',
//     municipality: 'NEW WASHINGTON',
//     region: 'REGION VI',
//     barangay_list: [
//       'CANDELARIA',
//       'CAWAYAN',
//       'DUMAGUIT',
//       'FATIMA',
//       'GUINBALIWAN',
//       'JALAS',
//       'JUGAS',
//       'LAWA-AN',
//       'MABILO',
//       'MATAPHAO',
//       'OCHANDO',
//       'PINAMUK-AN',
//       'POBLACION',
//       'POLO',
//       'PUIS',
//       'TAMBAK',
//     ],
//   },
//   NUMANCIA: {
//     province: 'AKLAN',
//     municipality: 'NUMANCIA',
//     region: 'REGION VI',
//     barangay_list: [
//       'ALBASAN',
//       'ALIPUTOS',
//       'BADIO',
//       'BUBOG',
//       'BULWANG',
//       'CAMANCI NORTE',
//       'CAMANCI SUR',
//       'DONGON EAST',
//       'DONGON WEST',
//       'JOYAO-JOYAO',
//       'LAGUINBANUA EAST',
//       'LAGUINBANUA WEST',
//       'MARIANOS',
//       'NAVITAS',
//       'POBLACION',
//       'PUSIW',
//       'TABANGKA',
//     ],
//   },
//   TANGALAN: {
//     province: 'AKLAN',
//     municipality: 'TANGALAN',
//     region: 'REGION VI',
//     barangay_list: [
//       'AFGA',
//       'BAYBAY',
//       'DAPDAP',
//       'DUMATAD',
//       'JAWILI',
//       'LANIPGA',
//       'NAPATAG',
//       'PANAYAKAN',
//       'POBLACION',
//       'PUDIOT',
//       'TAGAS',
//       'TAMALAGON',
//       'TAMOKOE',
//       'TONDOG',
//       'VIVO',
//     ],
//   },
//   'ANINI-Y': {
//     province: 'ANTIQUE',
//     municipality: 'ANINI-Y',
//     region: 'REGION VI',
//     barangay_list: [
//       'BAYO GRANDE',
//       'BAYO PEQUEÑO',
//       'BUTUAN',
//       'CASAY',
//       'CASAY VIEJO',
//       'IBA',
//       'IGBARABATUAN',
//       'IGPALGE',
//       'IGTUMAROM',
//       'LISUB A',
//       'LISUB B',
//       'MABUYONG',
//       'MAGDALENA',
//       'NASULI C',
//       'NATO',
//       'POBLACION',
//       'SAGUA',
//       'SALVACION',
//       'SAN FRANCISCO',
//       'SAN RAMON',
//       'SAN ROQUE',
//       'TAGAYTAY',
//       'TALISAYAN',
//     ],
//   },
//   BARBAZA: {
//     province: 'ANTIQUE',
//     municipality: 'BARBAZA',
//     region: 'REGION VI',
//     barangay_list: [
//       'BAGHARI',
//       'BAHUYAN',
//       'BERI',
//       'BIGA-A',
//       'BINANGBANG',
//       'BINANGBANG CENTRO',
//       'BINANU-AN',
//       'CADIAO',
//       'CALAPADAN',
//       'CAPOYUAN',
//       'CUBAY',
//       'EMBRANGGA-AN',
//       'ESPARAR',
//       'GUA',
//       'IDAO',
//       'IGPALGE',
//       'IGTUNARUM',
//       'INTEGASAN',
//       'IPIL',
//       'JINALINAN',
//       'LANAS',
//       'LANGCAON (EVELIO JAVIER)',
//       'LISUB',
//       'LOMBUYAN',
//       'MABLAD',
//       'MAGTULIS',
//       'MARIGNE',
//       'MAYABAY',
//       'MAYOS',
//       'NALUSDAN',
//       'NARIRONG',
//       'PALMA',
//       'POBLACION',
//       'SAN ANTONIO',
//       'SAN RAMON',
//       'SOLIGAO',
//       'TABONGTABONG',
//       'TIG-ALARAN',
//       'YAPO',
//     ],
//   },
//   BELISON: {
//     province: 'ANTIQUE',
//     municipality: 'BELISON',
//     region: 'REGION VI',
//     barangay_list: [
//       'BOROCBOROC',
//       'BUENAVISTA',
//       'CONCEPCION',
//       'DELIMA',
//       'IPIL',
//       'MARADIONA',
//       'MOJON',
//       'POBLACION',
//       'ROMBANG',
//       'SALVACION',
//       'SINAJA',
//     ],
//   },
//   BUGASONG: {
//     province: 'ANTIQUE',
//     municipality: 'BUGASONG',
//     region: 'REGION VI',
//     barangay_list: [
//       'ANILAWAN',
//       'ARANGOTE',
//       'BAGTASON',
//       'CAMANGAHAN',
//       'CENTRO ILAWOD (POB.)',
//       'CENTRO ILAYA (POB.)',
//       'CENTRO POJO (POB.)',
//       'CUBAY NORTH',
//       'CUBAY SOUTH',
//       'GUIJA',
//       'IGBALANGAO',
//       'IGSORO',
//       'ILAURES',
//       'JINALINAN',
//       'LACAYON',
//       'MARAY',
//       'PALIWAN',
//       'PANGALCAGAN',
//       'SABANG EAST',
//       'SABANG WEST',
//       'TAGUDTUD NORTH',
//       'TAGUDTUD SOUTH',
//       'TALISAY',
//       'TICA',
//       'TONO-AN',
//       'YAPU',
//       'ZARAGOZA',
//     ],
//   },
//   CALUYA: {
//     province: 'ANTIQUE',
//     municipality: 'CALUYA',
//     region: 'REGION VI',
//     barangay_list: [
//       'ALEGRIA',
//       'BACONG',
//       'BANAGO',
//       'BONBON',
//       'DAWIS',
//       'DIONELA',
//       'HARIGUE',
//       'HININGA-AN',
//       'IMBA',
//       'MASANAG',
//       'POBLACION',
//       'SABANG',
//       'SALAMENTO',
//       'SEMIRARA',
//       'SIBATO',
//       'SIBAY',
//       'SIBOLO',
//       'TINOGBOC',
//     ],
//   },
//   CULASI: {
//     province: 'ANTIQUE',
//     municipality: 'CULASI',
//     region: 'REGION VI',
//     barangay_list: [
//       'ALOJIPAN',
//       'BAGACAY',
//       'BALAC-BALAC',
//       'BATBATAN ISLAND',
//       'BATONAN NORTE',
//       'BATONAN SUR',
//       'BITA',
//       'BITADTON NORTE',
//       'BITADTON SUR',
//       'BUENAVISTA',
//       'BUHI',
//       'CAMANCIJAN',
//       'CARIDAD',
//       'CARIT-AN',
//       'CENTRO NORTE (POB.)',
//       'CENTRO POBLACION',
//       'CENTRO SUR (POB.)',
//       'CONDES',
//       'ESPERANZA',
//       'FE',
//       'FLORES',
//       'JALANDONI',
//       'JANLAGASI',
//       'LAMPUTONG',
//       'LIPATA',
//       'MAGSAYSAY (BALUA)',
//       'MALACAÑANG',
//       'MALALISON ISLAND',
//       'MANIGUIN',
//       'NABA',
//       'OSORIO',
//       'PANINGAYAN',
//       'SALDE',
//       'SAN ANTONIO',
//       'SAN GREGORIO',
//       'SAN JUAN',
//       'SAN LUIS',
//       'SAN PASCUAL',
//       'SAN VICENTE',
//       'SIMBOLA',
//       'TIGBOBOLO',
//       'TINABUSAN',
//       'TOMAO',
//       'VALDERAMA',
//     ],
//   },
//   HAMTIC: {
//     province: 'ANTIQUE',
//     municipality: 'HAMTIC',
//     region: 'REGION VI',
//     barangay_list: [
//       'APDO',
//       'ASLUMAN',
//       'BANAWON',
//       'BIA-AN',
//       'BONGBONGAN I-II',
//       'BONGBONGAN III',
//       'BOTBOT',
//       'BUDBUDAN',
//       'BUHANG',
//       'CALACJA I',
//       'CALACJA II',
//       'CALALA',
//       'CANTULAN',
//       'CARIDAD',
//       'CAROMANGAY',
//       'CASALNGAN',
//       'DANGCALAN',
//       'DEL PILAR',
//       'FABRICA',
//       'FUNDA',
//       'GENERAL FULLON (TINA)',
//       'GOV. EVELIO B. JAVIER (LANAG)',
//       'GUINTAS',
//       'IGBICAL',
//       'IGBUCAGAY',
//       'INABASAN',
//       'INGWAN-BATANGAN',
//       'LA PAZ',
//       'LINABAN',
//       'MALANDOG',
//       'MAPATAG',
//       'MASANAG',
//       'NALIHAWAN',
//       'PAMANDAYAN (BOTBOT)',
//       'PASU-JUNGAO',
//       'PIAPE I',
//       'PIAPE II',
//       'PIAPE III',
//       'PILI 1, 2, 3',
//       'POBLACION 1',
//       'POBLACION 2',
//       'POBLACION 3',
//       'POBLACION 4',
//       'POBLACION 5',
//       'PU-AO',
//       'SULOC',
//       'VILLAVERT-JIMENEZ',
//     ],
//   },
//   'LAUA-AN': {
//     province: 'ANTIQUE',
//     municipality: 'LAUA-AN',
//     region: 'REGION VI',
//     barangay_list: [
//       'BAGONGBAYAN',
//       'BANBAN',
//       'BONGBONGAN',
//       'CABARIWAN',
//       'CADAJUG',
//       'CANITUAN',
//       'CAPNAYAN',
//       'CASIT-AN',
//       'GUIAMON',
//       'GUINBANGA-AN',
//       'GUISIJAN',
//       'IGTADIAO',
//       'INTAO',
//       'JAGUIKICAN',
//       'JINALINAN',
//       'LACTUDAN',
//       'LATAZON',
//       'LAUA-AN',
//       'LIBERATO',
//       'LINDERO',
//       'LIYA-LIYA',
//       'LOON',
//       'LUGTA',
//       'LUPA-AN',
//       'MAGYAPO',
//       'MARIA',
//       'MAUNO',
//       'MAYBUNGA',
//       'NECESITO (PANIATAN)',
//       'OLOC',
//       'OMLOT',
//       'PANDANAN',
//       'PANINGAYAN',
//       'PASCUALA',
//       'POBLACION (CENTRO)',
//       'SAN RAMON',
//       'SANTIAGO',
//       'TIBACAN',
//       'TIGUNHAO',
//       'VIRGINIA',
//     ],
//   },
//   PATNONGON: {
//     province: 'ANTIQUE',
//     municipality: 'PATNONGON',
//     region: 'REGION VI',
//     barangay_list: [
//       'ALVAÑIZ',
//       'AMPARO',
//       'APGAHAN',
//       'AURELIANA',
//       'BADIANGAN',
//       'BERNALDO A. JULAGTING (BITAS)',
//       'CARIT-AN',
//       'CUYAPIAO',
//       'GELLA',
//       'IGBARAWAN',
//       'IGBOBON',
//       'IGBURI',
//       'LA RIOJA',
//       'MABASA',
//       'MACARINA',
//       'MAGARANG',
//       'MAGSAYSAY',
//       'PADANG',
//       'PANDANAN',
//       'PATLABAWON',
//       'POBLACION',
//       'QUEZON',
//       'SALAGUIAWAN',
//       'SAMALAGUE',
//       'SAN RAFAEL',
//       'TAMAYOC',
//       'TIGBALOGO',
//       'TOBIAS FORNIER',
//       'VILLA CRESPO',
//       'VILLA CRUZ',
//       'VILLA ELIO',
//       'VILLA FLORES',
//       'VILLA LAUA-AN',
//       'VILLA SAL',
//       'VILLA SALOMON',
//       'VISTA ALEGRE',
//     ],
//   },
//   'SAN REMIGIO': {
//     province: 'CEBU',
//     municipality: 'SAN REMIGIO',
//     region: 'REGION VII',
//     barangay_list: [
//       'ANAPOG',
//       'ARGAWANON',
//       'BAGTIC',
//       'BANCASAN',
//       'BATAD',
//       'BUSOGON',
//       'CALAMBUA',
//       'CANAGAHAN',
//       'DAPDAP',
//       'GAWAYGAWAY',
//       'HAGNAYA',
//       'KAYAM',
//       'KINAWAHAN',
//       'LAMBUSAN',
//       'LAWIS',
//       'LIBAONG',
//       'LOOC',
//       'LUYANG',
//       'MANO',
//       'POBLACION',
//       'PUNTA',
//       'SAB-A',
//       'SAN MIGUEL',
//       'TACUP',
//       'TAMBONGON',
//       'TO-ONG',
//       'VICTORIA',
//     ],
//   },
//   SEBASTE: {
//     province: 'ANTIQUE',
//     municipality: 'SEBASTE',
//     region: 'REGION VI',
//     barangay_list: [
//       'ABIERA',
//       'AGUILA',
//       'ALEGRE',
//       'ARAS-ASAN',
//       'BACALAN',
//       'CALLAN',
//       'IDIO',
//       'NAUHON',
//       'P. JAVIER',
//       'POBLACION',
//     ],
//   },
//   SIBALOM: {
//     province: 'ANTIQUE',
//     municipality: 'SIBALOM',
//     region: 'REGION VI',
//     barangay_list: [
//       'ALANGAN',
//       'BARI',
//       'BIGA-A',
//       'BONGBONGAN I',
//       'BONGBONGAN II',
//       'BONGSOD',
//       'BONTOL',
//       'BUGNAY',
//       'BULALACAO',
//       'CABANBANAN',
//       'CABARIUAN',
//       'CABLADAN',
//       'CADOLDOLAN',
//       'CALO-OY',
//       'CALOG',
//       'CATMON',
//       'CATUNGAN I',
//       'CATUNGAN II',
//       'CATUNGAN III',
//       'CATUNGAN IV',
//       'CUBAY-NAPULTAN',
//       'CUBAY-SERMON',
//       'DISTRICT I (POB.)',
//       'DISTRICT II (POB.)',
//       'DISTRICT III (POB.)',
//       'DISTRICT IV (POB.)',
//       'EGAÑA',
//       'ESPERANZA I',
//       'ESPERANZA II',
//       'ESPERANZA III',
//       'IGCOCOC',
//       'IGDAGMAY',
//       'IGDALAQUIT',
//       'IGLANOT',
//       'IGPANOLONG',
//       'IGPARAS',
//       'IGSUMING',
//       'ILABAS',
//       'IMPARAYAN',
//       'INABASAN',
//       'INDAG-AN',
//       'INITAN',
//       'INSARAYAN',
//       'LACARON',
//       'LAGDO',
//       'LAMBAYAGAN',
//       'LUNA',
//       'LUYANG',
//       'MAASIN',
//       'MABINI',
//       'MILLAMENA',
//       'MOJON',
//       'NAGDAYAO',
//       'NAZARETH',
//       'ODIONG',
//       'OLAGA',
//       'PANGPANG',
//       'PANLAGANGAN',
//       'PANTAO',
//       'PASONG',
//       'PIS-ANAN',
//       'ROMBANG',
//       'SALVACION',
//       'SAN JUAN',
//       'SIDO',
//       'SOLONG',
//       'TABONGTABONG',
//       'TIG-OHOT',
//       'TIGBALUA I',
//       'TIGBALUA II',
//       'TORDESILLAS',
//       'TULATULA',
//       'VALENTIN GRASPARIL (BAD-AS)',
//       'VILLAFONT',
//       'VILLAHERMOSA',
//       'VILLAR',
//     ],
//   },
//   TIBIAO: {
//     province: 'ANTIQUE',
//     municipality: 'TIBIAO',
//     region: 'REGION VI',
//     barangay_list: [
//       'ALEGRE',
//       'AMAR',
//       'BANDOJA (LUPA-AN)',
//       'CASTILLO',
//       'ESPARAGOZA',
//       'IMPORTANTE',
//       'LA PAZ',
//       'MALABOR',
//       'MARTINEZ',
//       'NATIVIDAD',
//       'PITAC',
//       'POBLACION',
//       'SALAZAR',
//       'SAN FRANCISCO NORTE',
//       'SAN FRANCISCO SUR',
//       'SAN ISIDRO',
//       'SANTA ANA',
//       'SANTA JUSTA',
//       'SANTO ROSARIO',
//       'TIGBABOY',
//       'TUNO',
//     ],
//   },
//   'TOBIAS FORNIER (DAO)': {
//     province: 'ANTIQUE',
//     municipality: 'TOBIAS FORNIER (DAO)',
//     region: 'REGION VI',
//     barangay_list: [
//       'ABACA',
//       'ARAS-ASAN',
//       'AROBO',
//       'ATABAY',
//       'ATIOTES',
//       'BAGUMBAYAN',
//       'BALLOSCAS',
//       'BALUD',
//       'BARASANAN A',
//       'BARASANAN B',
//       'BARASANAN C',
//       'BARIRI',
//       'CAMANDAGAN',
//       'CATO-OGAN',
//       'DANAWAN',
//       'DICLUM',
//       'FATIMA',
//       'GAMAD (IGDAMACIO)',
//       'IGBALOGO',
//       'IGBANGCAL-A',
//       'IGBANGCAL-B',
//       'IGBANGCAL-C',
//       'IGCABUAD',
//       'IGCADAC',
//       'IGCADO',
//       'IGCALAWAGAN',
//       'IGCAPUYAS',
//       'IGCASICAD (SAN PEDRO)',
//       'IGDALAGUIT',
//       'IGDANLOG',
//       'IGDURAROG',
//       'IGTUGAS',
//       'LAWIGAN',
//       'LINDERO',
//       'MANALING (CATA-AN)',
//       'MASAYO',
//       'NAGSUBUAN',
//       'NASULI-A (SAN RAMON)',
//       'OPSAN (SAN JOSE)',
//       'PACIENCIA',
//       'POBLACION NORTE',
//       'POBLACION SUR',
//       'PORTILLO',
//       'QUEZON',
//       'SALAMAGUE (SANTA MARIA)',
//       'SANTO TOMAS',
//       'TACBUYAN',
//       'TENE',
//       'VILLAFLOR',
//       'YSULAT',
//     ],
//   },
//   VALDERRAMA: {
//     province: 'ANTIQUE',
//     municipality: 'VALDERRAMA',
//     region: 'REGION VI',
//     barangay_list: [
//       'ALON',
//       'BAKIANG',
//       'BINANOGAN',
//       'BOROCBOROC',
//       'BUGNAY',
//       'BULUANGAN I',
//       'BULUANGAN II',
//       'BUNSOD',
//       'BUSOG',
//       'CANANGHAN',
//       'CANIPAYAN',
//       'CANSILAYAN',
//       'CULYAT',
//       'IGLINAB',
//       'IGMASANDIG',
//       'LUBLUB',
//       'MANLACBO',
//       'PANDANAN',
//       'SAN AGUSTIN',
//       'TAKAS (POB.)',
//       'TIGMAMALE',
//       'UBOS (POB.)',
//     ],
//   },
//   CUARTERO: {
//     province: 'CAPIZ',
//     municipality: 'CUARTERO',
//     region: 'REGION VI',
//     barangay_list: [
//       'AGCABUGAO',
//       'AGDAHON',
//       'AGNAGA',
//       'ANGUB',
//       'BALINGASAG',
//       'BITO-ON ILAWOD',
//       'BITO-ON ILAYA',
//       'BUN-OD',
//       'CARATAYA',
//       'LUNAYAN',
//       'MAHABANG SAPA',
//       'MAHUNODHUNOD',
//       'MAINDANG',
//       'MAINIT',
//       'MALAGAB-I',
//       'NAGBA',
//       'POBLACION ILAWOD',
//       'POBLACION ILAYA',
//       'POBLACION TAKAS',
//       'PUTI-AN',
//       'SAN ANTONIO',
//       'SINABSABAN',
//     ],
//   },
//   DAO: {
//     province: 'CAPIZ',
//     municipality: 'DAO',
//     region: 'REGION VI',
//     barangay_list: [
//       'AGANAN',
//       'AGTAMBI',
//       'AGTANGUAY',
//       'BALUCUAN',
//       'BITA',
//       'CENTRO',
//       'DAPLAS',
//       'DUYOC',
//       'ILAS SUR',
//       'LACARON',
//       'MALONOY',
//       'MANHOY',
//       'MAPULANG BATO',
//       'MATAGNOP',
//       'NASUNOGAN',
//       'POBLACION ILAWOD',
//       'POBLACION ILAYA',
//       'QUINABCABAN',
//       'QUINAYUYA',
//       'SAN AGUSTIN (ILAS NORTE)',
//     ],
//   },
//   DUMALAG: {
//     province: 'CAPIZ',
//     municipality: 'DUMALAG',
//     region: 'REGION VI',
//     barangay_list: [
//       'CONCEPCION',
//       'CONSOLACION',
//       'DOLORES',
//       'DURAN',
//       'POBLACION',
//       'SAN AGUSTIN',
//       'SAN JOSE',
//       'SAN MARTIN',
//       'SAN MIGUEL',
//       'SAN RAFAEL',
//       'SAN ROQUE',
//       'SANTA CARMEN',
//       'SANTA CRUZ',
//       'SANTA MONICA',
//       'SANTA RITA',
//       'SANTA TERESA',
//       'SANTO ANGEL',
//       'SANTO NIÑO',
//       'SANTO ROSARIO',
//     ],
//   },
//   DUMARAO: {
//     province: 'CAPIZ',
//     municipality: 'DUMARAO',
//     region: 'REGION VI',
//     barangay_list: [
//       'AGBATUAN',
//       'AGLALANA',
//       'AGLANOT',
//       'AGSIRAB',
//       'ALIPASIAWAN',
//       'ASTORGA',
//       'BAYOG',
//       'BUNGSUAN',
//       'CALAPAWAN',
//       'CODINGLE',
//       'CUBI',
//       'DACUTON',
//       'DANGULA',
//       'GIBATO',
//       'GUINOTOS',
//       'JAMBAD',
//       'JANGUSLOB',
//       'LAWAAN',
//       'MALONOY',
//       'NAGSULANG',
//       'ONGOL ILAWOD',
//       'ONGOL ILAYA',
//       'POBLACION ILAWOD',
//       'POBLACION ILAYA',
//       'SAGRADA FAMILIA',
//       'SALCEDO',
//       'SAN JUAN',
//       'SIBARIWAN',
//       'TAMULALOD',
//       'TASLAN',
//       'TINA',
//       'TINAYTAYAN',
//       'TRACIANO',
//     ],
//   },
//   IVISAN: {
//     province: 'CAPIZ',
//     municipality: 'IVISAN',
//     region: 'REGION VI',
//     barangay_list: [
//       'AGMALOBO',
//       'AGUSTIN NAVARRA',
//       'BALARING',
//       'BASIAO',
//       'CABUGAO',
//       'CUDIAN',
//       'ILAYA-IVISAN',
//       'MALOCLOC NORTE',
//       'MALOCLOC SUR',
//       'MATNOG',
//       'MIANAY',
//       'ONDOY',
//       'POBLACION NORTE',
//       'POBLACION SUR',
//       'SANTA CRUZ',
//     ],
//   },
//   JAMINDAN: {
//     province: 'CAPIZ',
//     municipality: 'JAMINDAN',
//     region: 'REGION VI',
//     barangay_list: [
//       'AGAMBULONG',
//       'AGBUN-OD',
//       'AGCAGAY',
//       'AGLIBACAO',
//       'AGLOLOWAY',
//       'BAYEBAYE',
//       'CARIDAD',
//       'ESPERANZA',
//       'FE',
//       'GANZON',
//       'GUINTAS',
//       'IGANG',
//       'JAENA NORTE',
//       'JAENA SUR',
//       'JAGNAYA',
//       'LAPAZ',
//       'LINAMBASAN',
//       'LUCERO',
//       'MAANTOL',
//       'MASGRAU',
//       'MILAN',
//       'MOLET',
//       'PANGABAT',
//       'PANGABUAN',
//       'PASOL-O',
//       'POBLACION',
//       'SAN JOSE',
//       'SAN JUAN',
//       'SAN VICENTE',
//       'SANTO ROSARIO',
//     ],
//   },
//   'MA-AYON': {
//     province: 'CAPIZ',
//     municipality: 'MA-AYON',
//     region: 'REGION VI',
//     barangay_list: [
//       'AGLIMOCON',
//       'ALASAGING',
//       'ALAYUNAN',
//       'BALIGHOT',
//       'BATABAT',
//       'BONGBONGAN',
//       'CABUNGAHAN',
//       'CANAPIAN',
//       'CARATAYA',
//       'DULUAN',
//       'EAST VILLAFLORES',
//       'FERNANDEZ',
//       'GUINBI-ALAN',
//       'INDAYAGAN',
//       'JEBACA',
//       'MAALAN',
//       'MANAYUPIT',
//       'NEW GUIA',
//       'OLD GUIA',
//       'PALAGUIAN',
//       'PARALLAN',
//       'PIÑA',
//       'POBLACION ILAWOD',
//       'POBLACION ILAYA',
//       'POBLACION TABUC',
//       'QUEVEDO (NGALAN)',
//       'QUINABONGLAN',
//       'QUINAT-UYAN',
//       'SALGAN',
//       'TAPULANG',
//       'TUBURAN',
//       'WEST VILLAFLORES',
//     ],
//   },
//   MAMBUSAO: {
//     province: 'CAPIZ',
//     municipality: 'MAMBUSAO',
//     region: 'REGION VI',
//     barangay_list: [
//       'ATIPLO',
//       'BALAT-AN',
//       'BALIT',
//       'BATIANO',
//       'BATING',
//       'BATO BATO',
//       'BAYE',
//       'BERGANTE',
//       'BULA',
//       'BUNGA',
//       'BUNGSI',
//       'BURIAS',
//       'CAIDQUID',
//       'CALA-AGUS',
//       'LIBO-O',
//       'MANIBAD',
//       'MARALAG',
//       'NAJUS-AN',
//       'PANGPANG NORTE',
//       'PANGPANG SUR',
//       'PINAY',
//       'POBLACION PROPER',
//       'POBLACION TABUC',
//       'SINONDOJAN',
//       'TUGAS',
//       'TUMALALUD',
//     ],
//   },
//   PANAY: {
//     province: 'CAPIZ',
//     municipality: 'PANAY',
//     region: 'REGION VI',
//     barangay_list: [
//       'AGBALO',
//       'AGBANBAN',
//       'AGOJO',
//       'ANHAWON',
//       'BAGACAY',
//       'BAGO CHIQUITO',
//       'BAGO GRANDE',
//       'BAHIT',
//       'BANTIQUE',
//       'BATO',
//       'BINANGIG',
//       'BINANTUAN',
//       'BONGA',
//       'BUNTOD',
//       'BUTACAL',
//       'CABUGAO ESTE',
//       'CABUGAO OESTE',
//       'CALAPAWAN',
//       'CALITAN',
//       'CANDUAL',
//       'COGON',
//       'DAGA',
//       'ILAMNAY',
//       'JAMUL-AWON',
//       'LANIPGA',
//       'LAT-ASAN',
//       'LIBON',
//       'LINAO',
//       'LINATERAN',
//       'LOMBOY',
//       'LUS-ONAN',
//       'MAGUBILAN',
//       'NAVITAS',
//       'PAWA',
//       'PILI',
//       'POBLACION ILAWOD',
//       'POBLACION ILAYA',
//       'POBLACION TABUC',
//       'TALASA',
//       'TANZA NORTE',
//       'TANZA SUR',
//       'TICO',
//     ],
//   },
//   PANITAN: {
//     province: 'CAPIZ',
//     municipality: 'PANITAN',
//     region: 'REGION VI',
//     barangay_list: [
//       'AGBABADIANG',
//       'AGKILO',
//       'AGLOWAY',
//       'AMBILAY',
//       'BAHIT',
//       'BALATUCAN',
//       'BANGA-AN',
//       'CABANGAHAN',
//       'CABUGAO',
//       'CADIO',
//       'CALA-AN',
//       'CAPAGAO',
//       'COGON',
//       'CONCIENCIA',
//       'ENSENAGAN',
//       'INTAMPILAN',
//       'PASUGUE',
//       'POBLACION ILAWOD',
//       'POBLACION ILAYA',
//       'QUIOS',
//       'SALOCON',
//       'TABUC NORTE',
//       'TABUC SUR',
//       'TIMPAS',
//       'TINCUPON',
//       'TINIGBAN',
//     ],
//   },
//   PONTEVEDRA: {
//     province: 'NEGROS OCCIDENTAL',
//     municipality: 'PONTEVEDRA',
//     region: 'REGION VI',
//     barangay_list: [
//       'ANTIPOLO',
//       'BARANGAY I (POB.)',
//       'BARANGAY II (POB.)',
//       'BARANGAY III (POB.)',
//       'BUENAVISTA GIBONG',
//       'BUENAVISTA RIZAL',
//       'BURGOS',
//       'CAMBARUS',
//       'CANROMA',
//       'DON SALVADOR BENEDICTO (CARMEN)',
//       'GENERAL MALVAR',
//       'GOMEZ',
//       'M. H. DEL PILAR',
//       'MABINI',
//       'MIRANDA',
//       'PANDAN',
//       'RECREO',
//       'SAN ISIDRO',
//       'SAN JUAN',
//       'ZAMORA',
//     ],
//   },
//   'ROXAS': {
//     province: 'CAPIZ',
//     municipality: 'ROXAS',
//     region: 'REGION VI',
//     barangay_list: [
//       'ADLAWAN',
//       'BAGO',
//       'BALIJUAGAN',
//       'BANICA',
//       'BARRA',
//       'BATO',
//       'BAYBAY',
//       'BOLO',
//       'CABUGAO',
//       'CAGAY',
//       'COGON',
//       'CULAJAO',
//       'CULASI',
//       'DAYAO',
//       'DINGINAN',
//       'DUMOLOG',
//       'GABU-AN',
//       'INZO ARNALDO VILLAGE (CADIMAHAN)',
//       'JUMAGUICJIC',
//       'LANOT',
//       'LAWA-AN',
//       'LIBAS',
//       'LIONG',
//       'LOCTUGAN',
//       'LONOY',
//       'MILIBILI',
//       'MONGPONG',
//       'OLOTAYAN',
//       'POBLACION I (BARANGAY I)',
//       'POBLACION II (BARANGAY II)',
//       'POBLACION III (BARANGAY III)',
//       'POBLACION IV (BARANGAY IV)',
//       'POBLACION IX (BARANGAY IX)',
//       'POBLACION V (BARANGAY V)',
//       'POBLACION VI (BARANGAY VI)',
//       'POBLACION VII (BARANGAY VII)',
//       'POBLACION VIII (BARANGAY VIII)',
//       'POBLACION X (BARANGAY X)',
//       'POBLACION XI (BARANGAY XI)',
//       'PUNTA COGON',
//       'PUNTA TABUC',
//       'SAN JOSE',
//       'SIBAGUAN',
//       'TALON',
//       'TANQUE',
//       'TANZA',
//       'TIZA',
//     ],
//   },
//   'SAPI-AN': {
//     province: 'CAPIZ',
//     municipality: 'SAPI-AN',
//     region: 'REGION VI',
//     barangay_list: [
//       'AGSILAB',
//       'AGTATACAY NORTE',
//       'AGTATACAY SUR',
//       'BILAO',
//       'DAMAYAN',
//       'DAPDAPAN',
//       'LONOY',
//       'MAJANLUD',
//       'MANINANG',
//       'POBLACION',
//     ],
//   },
//   SIGMA: {
//     province: 'CAPIZ',
//     municipality: 'SIGMA',
//     region: 'REGION VI',
//     barangay_list: [
//       'ACBO',
//       'AMAGA',
//       'BALUCUAN',
//       'BANGONBANGON',
//       'CAPUYHAN',
//       'COGON',
//       'DAYHAGON',
//       'GUINTAS',
//       'MALAPAD COGON',
//       'MANGOSO',
//       'MANSACUL',
//       'MATANGCONG',
//       'MATINABUS',
//       'MIANAY',
//       'OYONG',
//       'PAGBUNITAN',
//       'PARIAN',
//       'PINAMALATICAN',
//       'POBLACION NORTE',
//       'POBLACION SUR',
//       'TAWOG',
//     ],
//   },
//   TAPAZ: {
//     province: 'CAPIZ',
//     municipality: 'TAPAZ',
//     region: 'REGION VI',
//     barangay_list: [
//       'ABANGAY',
//       'ACUÑA',
//       'AGCOCOC',
//       'AGLINAB',
//       'AGLUPACAN',
//       'AGPALALI',
//       'APERO',
//       'ARTUZ',
//       'BAG-ONG BARRIO',
//       'BATO-BATO',
//       'BURI',
//       'CAMBURANAN',
//       'CANDELARIA',
//       'CARIDA',
//       'CRISTINA',
//       'DA-AN BANWA',
//       'DA-AN NORTE',
//       'DA-AN SUR',
//       'GARCIA',
//       'GEBIO-AN',
//       'HILWAN',
//       'INITAN',
//       'KATIPUNAN',
//       'LAGDUNGAN',
//       'LAHUG',
//       'LIBERTAD',
//       'MABINI',
//       'MALIAO',
//       'MALITBOG',
//       'MINAN',
//       'NAYAWAN',
//       'POBLACION',
//       'RIZAL NORTE',
//       'RIZAL SUR',
//       'ROOSEVELT',
//       'ROXAS',
//       'SALONG',
//       'SAN ANTONIO',
//       'SAN FRANCISCO',
//       'SAN JOSE',
//       'SAN JULIAN',
//       'SAN MIGUEL ILAWOD',
//       'SAN MIGUEL ILAYA',
//       'SAN NICOLAS',
//       'SAN PEDRO',
//       'SAN ROQUE',
//       'SAN VICENTE',
//       'SANTA ANA',
//       'SANTA PETRONILA',
//       'SENONOD',
//       'SIYA',
//       'SWITCH',
//       'TABON',
//       'TACAYAN',
//       'TAFT',
//       'TAGANGHIN',
//       'TASLAN',
//       'WRIGHT',
//     ],
//   },
//   JORDAN: {
//     province: 'GUIMARAS',
//     municipality: 'JORDAN',
//     region: 'REGION VI',
//     barangay_list: [
//       'ALAGUISOC',
//       'BALCON MARAVILLA',
//       'BALCON MELLIZA',
//       'BUGNAY',
//       'BULUANGAN',
//       'ESPINOSA',
//       'HOSKYN',
//       'LAWI',
//       'MOROBUAN',
//       'POBLACION',
//       'RIZAL',
//       'SAN MIGUEL',
//       'SANTA TERESA',
//       'SINAPSAPAN',
//     ],
//   },
//   'NUEVA VALENCIA': {
//     province: 'GUIMARAS',
//     municipality: 'NUEVA VALENCIA',
//     region: 'REGION VI',
//     barangay_list: [
//       'CABALAGNAN',
//       'CALAYA',
//       'CANHAWAN',
//       'CONCORDIA SUR',
//       'DOLORES',
//       'GUIWANON',
//       'IGANG',
//       'IGDARAPDAP',
//       'LA PAZ',
//       'LANIPE',
//       'LUCMAYAN',
//       'MAGAMAY',
//       'NAPANDONG',
//       'ORACON SUR',
//       'PANDARAONAN',
//       'PANOBOLON',
//       'POBLACION',
//       'SALVACION',
//       'SAN ANTONIO',
//       'SAN ROQUE',
//       'SANTO DOMINGO',
//       'TANDO',
//     ],
//   },
//   'SAN LORENZO': {
//     province: 'GUIMARAS',
//     municipality: 'SAN LORENZO',
//     region: 'REGION VI',
//     barangay_list: [
//       'AGUILAR',
//       'CABANO',
//       'CABUNGAHAN',
//       'CONSTANCIA',
//       'GABAN',
//       'IGCAWAYAN',
//       'M. CHAVEZ',
//       'SAN ENRIQUE (LEBAS)',
//       'SAPAL',
//       'SEBARIO',
//       'SUCLARAN',
//       'TAMBORONG',
//     ],
//   },
//   SIBUNAG: {
//     province: 'GUIMARAS',
//     municipality: 'SIBUNAG',
//     region: 'REGION VI',
//     barangay_list: [
//       'ALEGRIA',
//       'AYANGAN',
//       'BUBOG',
//       'CONCORDIA',
//       'DASAL',
//       'INAMPOLOGAN',
//       'MAABAY',
//       'MILLAN',
//       'ORACON',
//       'RAVINA',
//       'SABANG',
//       'SAN ISIDRO',
//       'SEBASTE',
//       'TANGLAD',
//     ],
//   },
//   AJUY: {
//     province: 'ILOILO',
//     municipality: 'AJUY',
//     region: 'REGION VI',
//     barangay_list: [
//       'ADCADARAO',
//       'AGBOBOLO',
//       'BADIANGAN',
//       'BARRIDO',
//       'BATO BIASONG',
//       'BAY-ANG',
//       'BUCANA BUNGLAS',
//       'CENTRAL',
//       'CULASI',
//       'LANJAGAN',
//       'LUCA',
//       'MALAYU-AN',
//       'MANGOROCORO',
//       'NASIDMAN',
//       'PANTALAN NABAYE',
//       'PANTALAN NAVARRO',
//       'PEDADA',
//       'PILI',
//       'PINANTAN DIEL',
//       'PINANTAN ELIZALDE',
//       'PINAY ESPINOSA',
//       'POBLACION',
//       'PROGRESO',
//       'PUENTE BUNGLAS',
//       'PUNTA BURI',
//       'ROJAS',
//       'SAN ANTONIO',
//       'SANTO ROSARIO',
//       'SILAGON',
//       'TAGUBANHAN',
//       'TAGUHANGIN',
//       'TANDUYAN',
//       'TIPACLA',
//       'TUBOGAN',
//     ],
//   },
//   ALIMODIAN: {
//     province: 'ILOILO',
//     municipality: 'ALIMODIAN',
//     region: 'REGION VI',
//     barangay_list: [
//       'ABANG-ABANG',
//       'AGSING',
//       'ATABAY',
//       'BA-ONG',
//       'BAGSAKAN',
//       'BAGUINGIN-LANOT',
//       'BAGUMBAYAN-ILAJAS',
//       'BALABAGO',
//       'BAN-AG',
//       'BANCAL',
//       'BINALUD',
//       'BUGANG',
//       'BUHAY',
//       'BULOD',
//       'CABACANAN PROPER',
//       'CABACANAN RIZAL',
//       'CAGAY',
//       'COLINE',
//       'COLINE-DALAG',
//       'CUNSAD',
//       'CUYAD',
//       'DALID',
//       'DAO',
//       'GINES',
//       'GINOMOY',
//       'INGWAN',
//       'LAYLAYAN',
//       'LICO',
//       'LUAN-LUAN',
//       'MALAMBOY-BONDOLAN',
//       'MALAMHAY',
//       'MAMBAWI',
//       'MANASA',
//       'MANDUYOG',
//       'PAJO',
//       'PIANDA-AN NORTE',
//       'PIANDA-AN SUR',
//       'POBLACION',
//       'PUNONG',
//       'QUINASPAN',
//       'SINAMAY',
//       'SULONG',
//       'TABAN-MANGUINING',
//       'TABUG',
//       'TARUG',
//       'TUGASLON',
//       'UBODAN',
//       'UGBO',
//       'ULAY-BUGANG',
//       'ULAY-HINABLAN',
//       'UMINGAN',
//     ],
//   },
//   ANILAO: {
//     province: 'ILOILO',
//     municipality: 'ANILAO',
//     region: 'REGION VI',
//     barangay_list: [
//       'AGBATUAN',
//       'BADIANG',
//       'BALABAG',
//       'BALUNOS',
//       'CAG-AN',
//       'CAMIROS',
//       'DANGULA-AN',
//       'GUIPIS',
//       'MANGANESE',
//       'MEDINA',
//       'MOSTRO',
//       'PALAYPAY',
//       'PANTALAN',
//       'POBLACION',
//       'SAMBAG CULOB',
//       'SAN CARLOS',
//       'SAN JUAN CRISOSTOMO',
//       'SANTA RITA',
//       'SANTO ROSARIO',
//       'SERALLO',
//       'VISTA ALEGRE',
//     ],
//   },
//   BADIANGAN: {
//     province: 'ILOILO',
//     municipality: 'BADIANGAN',
//     region: 'REGION VI',
//     barangay_list: [
//       'AGUSIPAN',
//       'ASTORGA',
//       'BINGAUAN',
//       'BITA-OYAN',
//       'BOTONG',
//       'BUDIAWE',
//       'CABANGA-AN',
//       'CABAYOGAN',
//       'CALANSANAN',
//       'CATUBIG',
//       'GUINAWAHAN',
//       'ILONGBUKID',
//       'INDOROHAN',
//       'INILIGAN',
//       'LATAWAN',
//       'LINAYUAN',
//       'MAINGUIT',
//       'MALUBLUB',
//       'MANAOLAN',
//       'MAPILI GRANDE',
//       'MAPILI SANJO',
//       'ODIONGAN',
//       'POBLACION (BADIANGAN)',
//       'SAN JULIAN',
//       'SARIRI',
//       'SIANON',
//       'SINUAGAN',
//       'TALABA',
//       'TAMOCOL',
//       'TENECLAN',
//       'TINA',
//     ],
//   },
//   BALASAN: {
//     province: 'ILOILO',
//     municipality: 'BALASAN',
//     region: 'REGION VI',
//     barangay_list: [
//       'ARANJUEZ',
//       'BACOLOD',
//       'BALANTI-AN',
//       'BATUAN',
//       'CABALIC',
//       'CAMAMBUGAN',
//       'DOLORES',
//       'GIMAMANAY',
//       'IPIL',
//       'KINALKALAN',
//       'LAWIS',
//       'MALAPOC',
//       'MAMHUT NORTE',
//       'MAMHUT SUR',
//       'MAYA',
//       'PANI-AN',
//       'POBLACION NORTE',
//       'POBLACION SUR',
//       'QUIASAN',
//       'SALONG',
//       'SALVACION',
//       'TINGUI-AN',
//       'ZARAGOSA',
//     ],
//   },
//   BANATE: {
//     province: 'ILOILO',
//     municipality: 'BANATE',
//     region: 'REGION VI',
//     barangay_list: [
//       'ALACAYGAN',
//       'BARIGA',
//       'BELEN',
//       'BOBON',
//       'BULARAN',
//       'CARMELO',
//       'DE LA PAZ',
//       'DUGWAKAN',
//       'FUENTES*',
//       'JUANICO',
//       'LIBERTAD',
//       'MAGDALO',
//       'MANAGOPAYA',
//       'MERCED',
//       'POBLACION',
//       'SAN SALVADOR',
//       'TALOKGANGAN',
//       'ZONA SUR',
//     ],
//   },
//   'BAROTAC NUEVO': {
//     province: 'ILOILO',
//     municipality: 'BAROTAC NUEVO',
//     region: 'REGION VI',
//     barangay_list: [
//       'ACUIT',
//       'AGCUYAWAN CALSADA',
//       'AGCUYAWAN PULO',
//       'BAGONGBONG',
//       'BARAS',
//       'BUNGCA',
//       'CABILAUAN',
//       'CRUZ',
//       'GUINTAS',
//       'IGBONG',
//       'ILAUD POBLACION',
//       'ILAYA POBLACION',
//       'JALAUD',
//       'LAGUBANG',
//       'LANAS',
//       'LICO-AN',
//       'LINAO',
//       'MONPON',
//       'PALACIAWAN',
//       'PATAG',
//       'SALIHID',
//       'SO-OL',
//       'SOHOTON',
//       'TABUC-SUBA',
//       'TABUCAN',
//       'TALISAY',
//       'TINORIAN',
//       'TIWI',
//       'TUBUNGAN',
//     ],
//   },
//   'BAROTAC VIEJO': {
//     province: 'ILOILO',
//     municipality: 'BAROTAC VIEJO',
//     region: 'REGION VI',
//     barangay_list: [
//       'BUGNAY',
//       'CALIFORNIA',
//       'DE LA PEÑA',
//       'DEL PILAR',
//       'GENERAL LUNA',
//       'LA FORTUNA',
//       'LIPATA',
//       'NATIVIDAD',
//       'NUEVA INVENCION',
//       'NUEVA SEVILLA',
//       'POBLACION',
//       'PUERTO PRINCESA',
//       'RIZAL',
//       'SAN ANTONIO',
//       'SAN FERNANDO',
//       'SAN FRANCISCO',
//       'SAN GERONIMO',
//       'SAN JUAN',
//       'SAN LUCAS',
//       'SAN MIGUEL',
//       'SAN ROQUE',
//       'SANTIAGO',
//       'SANTO DOMINGO',
//       'SANTO TOMAS',
//       'UGASAN',
//       'VISTA ALEGRE',
//     ],
//   },
//   BATAD: {
//     province: 'ILOILO',
//     municipality: 'BATAD',
//     region: 'REGION VI',
//     barangay_list: [
//       'ALAPASCO',
//       'ALINSOLONG',
//       'BANBAN',
//       'BATAD VIEJO',
//       'BINON-AN',
//       'BOLHOG',
//       'BULAK NORTE',
//       'BULAK SUR',
//       'CABAGOHAN',
//       'CALANGAG',
//       'CAW-I',
//       'DRANCALAN',
//       'EMBARCADERO',
//       'HAMOD',
//       'MALICO',
//       'NANGKA',
//       'PASAYAN',
//       'POBLACION',
//       'QUIAZAN FLORETE',
//       'QUIAZAN LOPEZ',
//       'SALONG',
//       'SANTA ANA',
//       'TANAO',
//       'TAPI-AN',
//     ],
//   },
//   BINGAWAN: {
//     province: 'ILOILO',
//     municipality: 'BINGAWAN',
//     region: 'REGION VI',
//     barangay_list: [
//       'AGBA-O',
//       'ALABIDHAN',
//       'BULABOG',
//       'CAIROHAN',
//       'GUINHULACAN',
//       'INAMYUNGAN',
//       'MALITBOG ILAWOD',
//       'MALITBOG ILAYA',
//       'NGINGI-AN',
//       'POBLACION',
//       'QUINANGYANA',
//       'QUINAR-UPAN',
//       'TAPACON',
//       'TUBOD',
//     ],
//   },
//   CALINOG: {
//     province: 'ILOILO',
//     municipality: 'CALINOG',
//     region: 'REGION VI',
//     barangay_list: [
//       'AGCALAGA',
//       'AGLIBACAO',
//       'AGLONOK',
//       'ALIBUNAN',
//       'BADLAN GRANDE',
//       'BADLAN PEQUEÑO',
//       'BADU',
//       'BAJE SAN JULIAN',
//       'BALATICON',
//       'BANBAN GRANDE',
//       'BANBAN PEQUEÑO',
//       'BARRIO CALINOG',
//       'BINOLOSAN GRANDE',
//       'BINOLOSAN PEQUEÑO',
//       'CABAGIAO',
//       'CABUGAO',
//       'CAHIGON',
//       'CAMALONGO',
//       'CANABAJAN',
//       'CARATAGAN',
//       'CARVASANA',
//       'DALID',
//       'DATAGAN',
//       'GAMA GRANDE',
//       'GAMA PEQUEÑO',
//       'GARANGAN',
//       'GUINBONYUGAN',
//       'GUISO',
//       'HILWAN',
//       'IMPALIDAN',
//       'IPIL',
//       'JAMIN-AY',
//       'LAMPAYA',
//       'LIBOT',
//       'LONOY',
//       'MALAG-IT',
//       'MALAGUINABOT',
//       'MALAPAWE',
//       'MALITBOG CENTRO',
//       'MAMBIRANAN',
//       'MANARIPAY',
//       'MARANDIG',
//       'MASAROY',
//       'MASPASAN',
//       'NALBUGAN',
//       'OWAK',
//       'POBLACION CENTRO',
//       'POBLACION DELGADO',
//       'POBLACION ILAYA',
//       'POBLACION RIZAL ILAUD',
//       'SAN NICOLAS',
//       'SIMSIMAN',
//       'SUPANGA',
//       'TABUCAN',
//       'TAHING',
//       'TIBIAO',
//       'TIGBAYOG',
//       'TOYUNGAN',
//       'ULAYAN',
//     ],
//   },
//   CARLES: {
//     province: 'ILOILO',
//     municipality: 'CARLES',
//     region: 'REGION VI',
//     barangay_list: [
//       'ABONG',
//       'ALIPATA',
//       'ASLUMAN',
//       'BANCAL',
//       'BARANGCALAN',
//       'BAROSBOS',
//       'BINULUANGAN',
//       'BITO-ON',
//       'BOLO',
//       'BUAYA',
//       'BUENAVISTA',
//       'CABILAO GRANDE',
//       'CABILAO PEQUEÑO',
//       'CABUGUANA',
//       'CAWAYAN',
//       'DAYHAGAN',
//       'GABI',
//       'GRANADA',
//       'GUINTICGAN',
//       'ISLA DE CANA',
//       'LANTANGAN',
//       'MANLOT',
//       'NALUMSAN',
//       'PANTALAN',
//       'POBLACION',
//       'PUNTA (BOLOCAWE)',
//       'PUNTA BATUANAN',
//       'SAN FERNANDO',
//       'TABUGON',
//       'TALINGTING',
//       'TARONG',
//       'TINIGBAN',
//       'TUPAZ',
//     ],
//   },
//   'PASSI': {
//     province: 'ILOILO',
//     municipality: 'PASSI',
//     region: 'REGION VI',
//     barangay_list: [
//       'AGDAHON',
//       'AGDAYAO',
//       'AGLALANA',
//       'AGTABO',
//       'AGTAMBO',
//       'ALIMONO',
//       'ARAC',
//       'AYUYAN',
//       'BACURANAN',
//       'BAGACAY',
//       'BATU',
//       'BAYAN',
//       'BITAOGAN',
//       'BUENAVISTA',
//       'BUYO',
//       'CABUNGA',
//       'CADILANG',
//       'CAIROHAN',
//       'DALICANAN',
//       'GEGACHAC',
//       'GEMAT-Y',
//       'GEMUMUA-AGAHON',
//       'GINES VIEJO',
//       'IMBANG GRANDE',
//       'JAGUIMITAN',
//       'LIBO-O',
//       'MAASIN',
//       'MAGDUNGAO',
//       'MALAG-IT GRANDE',
//       'MALAG-IT PEQUEÑO',
//       'MAMBIRANAN GRANDE',
//       'MAMBIRANAN PEQUEÑO',
//       'MAN-IT',
//       'MANTULANG',
//       'MULAPULA',
//       'NUEVA UNION',
//       'PAGAYPAY',
//       'PANGI',
//       'POBLACION ILAWOD',
//       'POBLACION ILAYA',
//       'PUNONG',
//       'QUINAGARINGAN GRANDE',
//       'QUINAGARINGAN PEQUEÑO',
//       'SABLOGON',
//       'SALNGAN',
//       'SANTO TOMAS',
//       'SARAPAN',
//       'TAGUBONG',
//       'TALONGONAN',
//       'TUBOD',
//       'TUBURAN',
//     ],
//   },
//   DINGLE: {
//     province: 'ILOILO',
//     municipality: 'DINGLE',
//     region: 'REGION VI',
//     barangay_list: [
//       'ABANGAY',
//       'AGSALANAN',
//       'AGTATACAY',
//       'ALEGRIA',
//       'BONGLOY',
//       'BUENAVISTA',
//       'CAGUYUMAN',
//       'CALICUANG',
//       'CAMAMBUGAN',
//       'DAWIS',
//       'GINALINAN NUEVO',
//       'GINALINAN VIEJO',
//       'GUTAO',
//       'ILAJAS',
//       'LIBO-O',
//       'LICU-AN',
//       'LINCUD',
//       'MATANGHARON',
//       'MOROBORO',
//       'NAMATAY',
//       'NAZUNI',
//       'PANDAN',
//       'POBLACION',
//       'POTOLAN',
//       'SAN JOSE',
//       'SAN MATIAS',
//       'SINIBA-AN',
//       'TABUGON',
//       'TAMBUNAC',
//       'TANGHAWAN',
//       'TIGUIB',
//       'TINOCUAN',
//       'TULATULA-AN',
//     ],
//   },
//   DUEÑAS: {
//     province: 'ILOILO',
//     municipality: 'DUEÑAS',
//     region: 'REGION VI',
//     barangay_list: [
//       'AGUTAYAN',
//       'ANGARE',
//       'ANJAWAN',
//       'BAAC',
//       'BAGONGBONG',
//       'BALANGIGAN',
//       'BALINGASAG',
//       'BANUGAN',
//       'BATUAN',
//       'BITA',
//       'BUENAVISTA',
//       'BUGTONGAN',
//       'CABUDIAN',
//       'CALACA-AN',
//       'CALANG',
//       'CALAWINAN',
//       'CAPAYCAPAY',
//       'CAPULING',
//       'CATIG',
//       'DILA-AN',
//       'FUNDACION',
//       'INADLAWAN',
//       'JAGDONG',
//       'JAGUIMIT',
//       'LACADON',
//       'LUAG',
//       'MALUSGOD',
//       'MARIBUYONG',
//       'MINANGA',
//       'MONPON',
//       'NAVALAS',
//       'PADER',
//       'PANDAN',
//       'POBLACION A',
//       'POBLACION B',
//       'POBLACION C',
//       'POBLACION D',
//       'PONONG GRANDE',
//       'PONONG PEQUEÑO',
//       'PUROG',
//       'ROMBLON',
//       'SAN ISIDRO',
//       'SANTO NIÑO',
//       'SAWE',
//       'TAMINLA',
//       'TINOCUAN',
//       'TIPOLO',
//     ],
//   },
//   DUMANGAS: {
//     province: 'ILOILO',
//     municipality: 'DUMANGAS',
//     region: 'REGION VI',
//     barangay_list: [
//       'AURORA-DEL PILAR (POB.)',
//       'BACAY',
//       'BACONG',
//       'BALABAG',
//       'BALUD',
//       'BANTUD',
//       'BANTUD FABRICA',
//       'BARAS',
//       'BARASAN',
//       'BASA-MABINI BONIFACIO (POB.)',
//       'BOLILAO',
//       'BUENAFLOR EMBARKADERO (POB.)',
//       'BURGOS-REGIDOR (POB.)',
//       'CALAO',
//       'CALI',
//       'CANSILAYAN',
//       'CAPALIZ',
//       'CAYOS',
//       'COMPAYAN',
//       'DACUTAN',
//       'ERMITA',
//       'ILAYA 1ST',
//       'ILAYA 2ND',
//       'ILAYA 3RD',
//       'JARDIN',
//       'LACTURAN',
//       'LOPEZ JAENA - RIZAL (POB.)',
//       'MANAGUIT',
//       'MAQUINA',
//       'NANDING LOPEZ',
//       'PAGDUGUE',
//       'PALOC BIGQUE',
//       'PALOC SOOL',
//       'PATLAD',
//       'PD MONFORT NORTH (LUBLUB)',
//       'PD MONFORT SOUTH (GUINSAMPANAN)',
//       'PULAO',
//       'ROSARIO',
//       'SAPAO',
//       'SULANGAN',
//       'TABUCAN',
//       'TALUSAN',
//       'TAMBOBO',
//       'TAMBOILAN',
//       'VICTORIAS',
//     ],
//   },
//   ESTANCIA: {
//     province: 'ILOILO',
//     municipality: 'ESTANCIA',
//     region: 'REGION VI',
//     barangay_list: [
//       'BAYAS (BAYAS ISLAND)',
//       'BAYUYAN',
//       'BOTONGON',
//       'BULAQUEÑA',
//       'CALAPDAN',
//       'CANO-AN',
//       'DAAN BANUA',
//       'DACULAN',
//       'GOGO',
//       'JOLOG',
//       'LOGUINGOT (LOGUINGOT ISLAND)',
//       'LONOY (ROMAN MOSQUEDA)',
//       'LUMBIA (ANA CUENCA)',
//       'MALBOG',
//       'MANIPULON',
//       'PA-ON',
//       'POBLACION ZONE 1',
//       'POBLACION ZONE II',
//       'POBLACION ZONE III',
//       'SAN ROQUE',
//       'SANTA ANA',
//       'TABU-AN',
//       'TACBUYAN',
//       'TANZA',
//       'VILLA PANI-AN',
//     ],
//   },
//   GUIMBAL: {
//     province: 'ILOILO',
//     municipality: 'GUIMBAL',
//     region: 'REGION VI',
//     barangay_list: [
//       'ANONO-O',
//       'BACONG',
//       'BAGUMBAYAN POB. (BAGUMBAYAN-GRANADA)',
//       'BALANTAD-CARLOS FRUTO (POB.)',
//       'BARAS',
//       'BINANUA-AN',
//       'BONGOL SAN MIGUEL',
//       'BONGOL SAN VICENTE',
//       'BULAD',
//       'BULUANGAN',
//       'BURGOS-GENGOS (POB.)',
//       'CABASI',
//       'CABUBUGAN',
//       'CALAMPITAO',
//       'CAMANGAHAN',
//       'GENEROSA-CRISTOBAL COLON (POB.)',
//       'GERONA-GIMENO (POB.)',
//       'GIRADO-MAGSAYSAY (POB.)',
//       'GOTERA (POB.)',
//       'IGCOCOLO',
//       'IYASAN',
//       'LIBO-ON GONZALES (POB.)',
//       'LUBACAN',
//       'NAHAPAY',
//       'NALUNDAN',
//       'NANGA',
//       'NITO-AN LUPSAG',
//       'PARTICION',
//       'PESCADORES (POB.)',
//       'RIZAL-TUGUISAN (POB.)',
//       'SANTA ROSA-LAGUNA',
//       'SIPITAN-BADIANG',
//       'TORREBLANCA-BLUMENTRITT (POB.)',
//     ],
//   },
//   IGBARAS: {
//     province: 'ILOILO',
//     municipality: 'IGBARAS',
//     region: 'REGION VI',
//     barangay_list: [
//       'ALAMEDA',
//       'AMOROGTONG',
//       'ANILAWAN',
//       'BAGACAY',
//       'BAGACAYAN',
//       'BAGAY',
//       'BALIBAGAN',
//       'BARANGAY 1 POBLACION',
//       'BARANGAY 2 POBLACION',
//       'BARANGAY 3 POBLACION',
//       'BARANGAY 4 POBLACION',
//       'BARANGAY 5 POBLACION',
//       'BARANGAY 6 POBLACION',
//       'BARASAN',
//       'BINANUA-AN',
//       'BOCLOD',
//       'BUENAVISTA',
//       'BUGA',
//       'BUGNAY',
//       'CALAMPITAO',
//       'CALE',
//       'CATIRINGAN',
//       'CORUCUAN',
//       'IGCABUGAO',
//       'IGPIGUS',
//       'IGTALONGON',
//       'INDALUYON',
//       'JOVELLAR',
//       'KINAGDAN',
//       'LAB-ON',
//       'LACAY DOL-DOL',
//       'LUMANGAN',
//       'LUTUNGAN',
//       'MANTANGON',
//       'MULANGAN',
//       'PASONG',
//       'PASSI',
//       'PINAOPAWAN',
//       'RIRO-AN',
//       'SAN AMBROSIO',
//       'SANTA BARBARA',
//       'SIGNE',
//       'TABIAC',
//       'TALAYATAY',
//       'TAYTAY',
//       'TIGBANABA',
//     ],
//   },
//   'ILOILO': {
//     province: 'ILOILO',
//     municipality: 'ILOILO',
//     region: 'REGION VI',
//     barangay_list: [
//       'ABETO MIRASOL TAFT SOUTH (QUIRINO ABETO)',
//       'AGUINALDO',
//       'AIRPORT (TABUCAN AIRPORT)',
//       'ALALASAN LAPUZ',
//       'ARGUELLES',
//       'ARSENAL ADUANA',
//       'BAKHAW',
//       'BALABAGO',
//       'BALANTANG',
//       'BALDOZA',
//       'BANTUD',
//       'BANUYAO',
//       'BAYBAY TANZA',
//       'BENEDICTO (JARO)',
//       'BITO-ON',
//       'BOLILAO',
//       'BONIFACIO (AREVALO)',
//       'BONIFACIO TANZA',
//       'BUHANG',
//       'BUHANG TAFT NORTH',
//       'BUNTATALA',
//       'BURGOS-MABINI-PLAZA',
//       'CAINGIN',
//       'CALAHUNAN',
//       'CALAPARAN',
//       'CALUBIHAN',
//       'CALUMPANG',
//       'CAMALIG',
//       'COCHERO',
//       'COMPANIA',
//       'CONCEPCION-MONTES',
//       'CUARTERO',
//       'CUBAY',
//       'DANAO',
//       'DELGADO-JALANDONI-BAGUMBAYAN',
//       'DEMOCRACIA',
//       'DESAMPARADOS',
//       'DIVINAGRACIA',
//       'DON ESTEBAN-LAPUZ',
//       'DULONAN',
//       'DUNGON',
//       'DUNGON A',
//       'DUNGON B',
//       'EAST BALUARTE',
//       'EAST TIMAWA',
//       'EDGANZON',
//       'EL 98 CASTILLA (CLAUDIO LOPEZ)',
//       'FAJARDO',
//       'FLORES',
//       'GENERAL HUGHES-MONTES',
//       'GLORIA',
//       'GUSTILO',
//       'GUZMAN-JESENA',
//       'HABOG-HABOG SALVACION',
//       'HIBAO-AN NORTE',
//       'HIBAO-AN SUR',
//       'HINACTACAN',
//       'HIPODROMO',
//       'INDAY',
//       'INFANTE',
//       'INGORE',
//       'JALANDONI ESTATE-LAPUZ',
//       'JALANDONI-WILSON',
//       'JAVELLANA',
//       'JEREOS',
//       'KAHIRUPAN',
//       'KASINGKASING',
//       'KATILINGBAN',
//       'KAUSWAGAN',
//       'LAGUDA',
//       'LANIT',
//       'LAPUZ NORTE',
//       'LAPUZ SUR',
//       'LEGASPI DELA RAMA',
//       'LIBERATION',
//       'LIBERTAD, SANTA ISABEL',
//       'LIBERTAD-LAPUZ',
//       'LOBOC-LAPUZ',
//       'LOPEZ JAENA (JARO)',
//       'LOPEZ JAENA NORTE',
//       'LOPEZ JAENA SUR',
//       'LUNA (JARO)',
//       'LUNA (LA PAZ)',
//       'M. V. HECHANOVA',
//       'MABOLO-DELGADO',
//       'MACARTHUR',
//       'MAGDALO',
//       'MAGSAYSAY',
//       'MAGSAYSAY VILLAGE',
//       'MALIPAYON-DELGADO',
//       'MANSAYA-LAPUZ',
//       'MARCELO H. DEL PILAR',
//       'MARIA CLARA',
//       'MARIA CRISTINA',
//       'MOHON',
//       'MOLO BOULEVARD',
//       'MONICA BLUMENTRITT',
//       'MONTINOLA',
//       'MUELLE LONEY-MONTES',
//       'NABITASAN',
//       'NAVAIS',
//       'NONOY',
//       'NORTH AVANCEÑA',
//       'NORTH BALUARTE',
//       'NORTH FUNDIDOR',
//       'NORTH SAN JOSE',
//       'OBRERO-LAPUZ',
//       'OÑATE DE LEON',
//       'ORTIZ',
//       'OSMEÑA',
//       'OUR LADY OF FATIMA',
//       'OUR LADY OF LOURDES',
//       'PALE BENEDICTO RIZAL (MANDURRIAO)',
//       'PHHC BLOCK 17',
//       'PHHC BLOCK 22 NHA',
//       'POBLACION MOLO',
//       'PRESIDENT ROXAS',
//       'PROGRESO-LAPUZ',
//       'PUNONG-LAPUZ',
//       'QUEZON',
//       'QUINTIN SALAS',
//       'RAILWAY',
//       'RIMA-RIZAL',
//       'RIZAL (LA PAZ)',
//       'RIZAL ESTANZUELA',
//       'RIZAL IBARRA',
//       'RIZAL PALAPALA I',
//       'RIZAL PALAPALA II',
//       'ROXAS VILLAGE',
//       'SAMBAG',
//       'SAMPAGUITA',
//       'SAN AGUSTIN',
//       'SAN ANTONIO',
//       'SAN FELIX',
//       'SAN ISIDRO (JARO)',
//       'SAN ISIDRO (LA PAZ)',
//       'SAN JOSE (AREVALO)',
//       'SAN JOSE (CITY PROPER)',
//       'SAN JOSE (JARO)',
//       'SAN JUAN',
//       'SAN NICOLAS',
//       'SAN PEDRO (JARO)',
//       'SAN PEDRO (MOLO)',
//       'SAN RAFAEL',
//       'SAN ROQUE',
//       'SAN VICENTE',
//       'SANTA CRUZ',
//       'SANTA FILOMENA',
//       'SANTA ROSA',
//       'SANTO DOMINGO',
//       'SANTO NIÑO NORTE',
//       'SANTO NIÑO SUR',
//       'SANTO ROSARIO-DURAN',
//       'SEMINARIO (BURGOS JALANDONI)',
//       'SIMON LEDESMA',
//       'SINIKWAY (BANGKEROHAN LAPUZ)',
//       'SO-OC',
//       'SOUTH BALUARTE',
//       'SOUTH FUNDIDOR',
//       'SOUTH SAN JOSE',
//       'TAAL',
//       'TABUC SUBA (JARO)',
//       'TABUC SUBA (LA PAZ)',
//       'TABUCAN',
//       'TACAS',
//       'TAGBAC',
//       'TANZA-ESPERANZA',
//       'TAP-OC',
//       'TAYTAY ZONE II',
//       'TICUD (LA PAZ)',
//       'TIMAWA TANZA I',
//       'TIMAWA TANZA II',
//       'UNGKA',
//       'VETERANS VILLAGE',
//       'VILLA ANITA',
//       'WEST HABOG-HABOG',
//       'WEST TIMAWA',
//       'YULO DRIVE',
//       'YULO-ARROYO',
//       'ZAMORA-MELLIZA',
//     ],
//   },
//   JANIUAY: {
//     province: 'ILOILO',
//     municipality: 'JANIUAY',
//     region: 'REGION VI',
//     barangay_list: [
//       'ABANGAY',
//       'AGCAROPE',
//       'AGLOBONG',
//       'AGUINGAY',
//       'ANHAWAN',
//       'AQUINO NOBLEZA EAST (POB.)',
//       'AQUINO NOBLEZA WEST (POB.)',
//       'ATIMONAN',
//       'BALANAC',
//       'BARASALON',
//       'BONGOL',
//       'CABANTOG',
//       'CALMAY',
//       'CANAWILI',
//       'CANAWILLIAN',
//       'CAPT. A. TIRADOR (POB.)',
//       'CARANAS',
//       'CARAUDAN',
//       'CARIGANGAN',
//       'CONCEPCION POB. (D.G. ABORDO)',
//       'CRISPIN SALAZAR NORTH (POB.)',
//       'CRISPIN SALAZAR SOUTH (POB.)',
//       'CUNSAD',
//       'DABONG',
//       'DAMIRES',
//       'DAMO-ONG',
//       'DANAO',
//       'DON T. LUTERO CENTER (POB.)',
//       'DON T. LUTERO EAST (POB.)',
//       'DON T. LUTERO WEST POB. (DON T. LUTERO NORTH)',
//       'GINES',
//       'GOLGOTA (POB.)',
//       'GUADALUPE',
//       'JIBOLO',
//       'KUYOT',
//       'LOCSIN (POB.)',
//       'MADONG',
//       'MANACABAC',
//       'MANGIL',
//       'MATAG-UB',
//       'MONTE-MAGAPA',
//       'PANGILIHAN',
//       'PANURAN',
//       'PARARINGA',
//       'PATONG-PATONG',
//       'QUIPOT',
//       'R. ARMADA (POB.)',
//       'S. M. VILLA (POB.)',
//       'SAN JULIAN (POB.)',
//       'SAN PEDRO (POB.)',
//       'SANTA RITA (POB.)',
//       'SANTO TOMAS',
//       'SARAWAG',
//       'TAMBAL',
//       'TAMU-AN',
//       'TIRINGANAN',
//       'TOLARUCAN',
//       'TUBURAN',
//       'UBIAN',
//       'YABON',
//     ],
//   },
//   LAMBUNAO: {
//     province: 'ILOILO',
//     municipality: 'LAMBUNAO',
//     region: 'REGION VI',
//     barangay_list: [
//       'AGSIRAB',
//       'AGTUMAN',
//       'ALUGMAWA',
//       'BADIANGAN',
//       'BALAGIAO',
//       'BANBAN',
//       'BANSAG',
//       'BAYUCO',
//       'BINABA-AN ARMADA',
//       'BINABA-AN LABAYNO',
//       'BINABA-AN LIMOSO',
//       'BINABA-AN PORTIGO',
//       'BINABA-AN TIRADOR',
//       'BOGONGBONG',
//       'BONBON',
//       'BONTOC',
//       'BURI',
//       'BURIRAO',
//       'BUWANG',
//       'CABATANGAN',
//       'CABUGAO',
//       'CABUNLAWAN',
//       'CAGUISANAN',
//       'CALOY-AHAN',
//       'CANINGUAN',
//       'CAPANGYAN',
//       'CAYAN ESTE',
//       'CAYAN OESTE',
//       'COROT-ON',
//       'COTO',
//       'CUBAY',
//       'CUNARUM',
//       'DAANBANWA',
//       'GINES',
//       'HIPGOS',
//       'JAYUBO',
//       'JOROG',
//       'LANOT GRANDE',
//       'LANOT PEQUEÑO',
//       'LEGAYADA',
//       'LUMANAY',
//       'MADARAG',
//       'MAGBATO',
//       'MAITE GRANDE',
//       'MAITE PEQUEÑO',
//       'MALAG-IT',
//       'MANAULAN',
//       'MARIBONG',
//       'MARONG',
//       'MISI',
//       'NATIVIDAD',
//       'PAJO',
//       'PANDAN',
//       'PANURAN',
//       'PASIG',
//       'PATAG',
//       'POBLACION ILAWOD',
//       'POBLACION ILAYA',
//       'POONG',
//       'PUGHANAN',
//       'PUNGSOD',
//       'QUILING',
//       'SAGCUP',
//       'SAN GREGORIO',
//       'SIBACUNGAN',
//       'SIBAGUAN',
//       'SIMSIMAN',
//       'SUPOC',
//       'TAMPUCAO',
//       'TRANGHAWAN',
//       'TUBUNGAN',
//       'TUBURAN',
//       'WALANG',
//     ],
//   },
//   LEGANES: {
//     province: 'ILOILO',
//     municipality: 'LEGANES',
//     region: 'REGION VI',
//     barangay_list: [
//       'BIGKE',
//       'BUNTATALA',
//       'CAGAMUTAN NORTE',
//       'CAGAMUTAN SUR',
//       'CALABOA',
//       'CAMANGAY',
//       'CARI MAYOR',
//       'CARI MINOR',
//       'GUA-AN',
//       'GUIHAMAN',
//       'GUINOBATAN',
//       'GUINTAS',
//       'LAPAYON',
//       'M.V. HECHANOVA (BALAGON)',
//       'NABITASAN',
//       'NAPNUD',
//       'POBLACION',
//       'SAN VICENTE',
//     ],
//   },
//   LEON: {
//     province: 'ILOILO',
//     municipality: 'LEON',
//     region: 'REGION VI',
//     barangay_list: [
//       'AGBOY NORTE',
//       'AGBOY SUR',
//       'AGTA',
//       'AMBULONG',
//       'ANONANG',
//       'APIAN',
//       'AVANZADA',
//       'AWIS',
//       'AYABANG',
//       'AYUBO',
//       'BACOLOD',
//       'BAJE',
//       'BANAGAN',
//       'BARANGBANG',
//       'BARASAN',
//       'BAYAG NORTE',
//       'BAYAG SUR',
//       'BINOLBOG',
//       'BIRI NORTE',
//       'BIRI SUR',
//       'BOBON',
//       'BUCARI',
//       'BUENAVISTA',
//       'BUGA',
//       'BULAD',
//       'BULWANG',
//       'CABOLO-AN',
//       'CABUNGA-AN',
//       'CABUTONGAN',
//       'CAGAY',
//       'CAMANDAG',
//       'CAMANDO',
//       'CANANAMAN',
//       'CAPT. FERNANDO',
//       'CARARA-AN',
//       'CAROLINA',
//       'CAWILIHAN',
//       'COYUGAN NORTE',
//       'COYUGAN SUR',
//       'DANAO',
//       'DOROG',
//       'DUSACAN',
//       'GINES',
//       'GUMBOC',
//       'IGCADIOS',
//       'INGAY',
//       'ISIAN NORTE',
//       'ISIAN VICTORIA',
//       'JAMOG GINES',
//       'LAMPAYA',
//       'LANAG',
//       'LANG-OG',
//       'LIGTOS',
//       'LONOC',
//       'MAGCAPAY',
//       'MALIAO',
//       'MALUBLUB',
//       'MANAMPUNAY',
//       'MARIRONG',
//       'MINA',
//       'MOCOL',
//       'NAGBANGI',
//       'NALBANG',
//       'ODONG-ODONG',
//       'OLUANGAN',
//       'OMAMBONG',
//       'PAGA',
//       'PANDAN',
//       'PANGINMAN',
//       'PAOY',
//       'PEPE',
//       'POBLACION',
//       'SALNGAN',
//       'SAMLAGUE',
//       'SIOL NORTE',
//       'SIOL SUR',
//       'TACUYONG NORTE',
//       'TACUYONG SUR',
//       'TAGSING',
//       'TALACUAN',
//       'TICUAN',
//       'TINA-AN NORTE',
//       'TINA-AN SUR',
//       'TU-OG',
//       'TUNGUAN',
//     ],
//   },
//   MAASIN: {
//     province: 'ILOILO',
//     municipality: 'MAASIN',
//     region: 'REGION VI',
//     barangay_list: [
//       'ABAY',
//       'ABILAY',
//       'AGROCEL POB. (AGUINALDO-ROXAS--CELSO MAYOR)',
//       'AMERANG',
//       'BAGACAY EAST',
//       'BAGACAY WEST',
//       'BOLO',
//       'BUG-OT',
//       'BULAY',
//       'BUNTALAN',
//       'BURAK',
//       'CABANGCALAN',
//       'CABATAC',
//       'CAIGON',
//       'CANANGHAN',
//       'CANAWILI',
//       'DAGAMI',
//       'DAJA',
//       'DALUSAN',
//       'DELCAR POB. (DELGADO-CARTAGENA)',
//       'INABASAN',
//       'LAYOG',
//       'LINAB',
//       'LIÑAGAN CALSADA',
//       'LIÑAGAN TACAS',
//       'MAGSAYSAY',
//       'MANDOG',
//       'MARI POB. (MABINI-RIZAL)',
//       'MIAPA',
//       'NAGBA',
//       'NASAKA',
//       'NASLO-BUCAO',
//       'NASULI',
//       'PANALIAN',
//       'PIANDAAN EAST',
//       'PIANDAAN WEST',
//       'PISPIS',
//       'PUNONG',
//       'SANTA RITA',
//       'SINUBSUBAN',
//       'SIWALO',
//       'SUBOG',
//       'THTP POB. (TAFT-THOMPSON--HUGHES-DEL PILAR)',
//       'TIGBAUAN',
//       'TRANGKA',
//       'TUBANG',
//       'TULAHONG',
//       'TUY-AN EAST',
//       'TUY-AN WEST',
//       'UBIAN',
//     ],
//   },
//   MIAGAO: {
//     province: 'ILOILO',
//     municipality: 'MIAGAO',
//     region: 'REGION VI',
//     barangay_list: [
//       'AGDUM',
//       'AGUIAUAN',
//       'ALIMODIAS',
//       'AWANG',
//       'BACAUAN',
//       'BACOLOD',
//       'BAGUMBAYAN',
//       'BANBANAN',
//       'BANGA',
//       'BANGLADAN',
//       'BANUYAO',
//       'BARACLAYAN',
//       'BARIRI',
//       'BAYBAY NORTE (POB.)',
//       'BAYBAY SUR (POB.)',
//       'BELEN',
//       'BOLHO (POB.)',
//       'BOLOCAUE',
//       'BUENAVISTA NORTE',
//       'BUENAVISTA SUR',
//       'BUGTONG LUMANGAN',
//       'BUGTONG NAULID',
//       'CABALAUNAN',
//       'CABANGCALAN',
//       'CABUNOTAN',
//       'CADOLDOLAN',
//       'CAGBANG',
//       'CAITIB',
//       'CALAGTANGAN',
//       'CALAMPITAO',
//       'CAVITE',
//       'CAWAYANAN',
//       'CUBAY',
//       'CUBAY UBOS',
//       'DALIJE',
//       'DAMILISAN',
//       'DAWOG',
//       'DIDAY',
//       'DINGLE',
//       'DUROG',
//       'FRANTILLA',
//       'FUNDACION',
//       'GINES',
//       'GUIBONGAN',
//       'IGBITA',
//       'IGBUGO',
//       'IGCABIDIO',
//       'IGCABITO-ON',
//       'IGCATAMBOR',
//       'IGDALAQUIT',
//       'IGDULACA',
//       'IGPAJO',
//       'IGPANDAN',
//       'IGPURO',
//       'IGPURO-BARIRI',
//       'IGSOLIGUE',
//       'IGTUBA',
//       'ILOG-ILOG',
//       'INDAG-AN',
//       'KIRAYAN NORTE',
//       'KIRAYAN SUR',
//       'KIRAYAN TACAS',
//       'LA CONSOLACION',
//       'LACADON',
//       'LANUTAN',
//       'LUMANGAN',
//       'MABAYAN',
//       'MADUYO',
//       'MALAGYAN',
//       'MAMBATAD',
//       'MANINILA',
//       'MARICOLCOL',
//       'MARINGYAN',
//       'MAT-Y (POB.)',
//       'MATALNGON',
//       'NACLUB',
//       'NAM-O NORTE',
//       'NAM-O SUR',
//       'NARAT-AN',
//       'NAROROGAN',
//       'NAULID',
//       'OLANGO',
//       'ONGYOD',
//       'ONOP',
//       'OYA-OY',
//       'OYUNGAN',
//       'PALACA',
//       'PARO-ON',
//       'POTRIDO',
//       'PUDPUD',
//       'PUNGTOD MONTECLARO',
//       'PUNGTOD NAULID',
//       'SAG-ON',
//       'SAN FERNANDO',
//       'SAN JOSE',
//       'SAN RAFAEL',
//       'SAPA (MIAGAO)',
//       'SARING',
//       'SIBUCAO',
//       'TAAL',
//       'TABUNACAN',
//       'TACAS (POB.)',
//       'TAMBONG',
//       'TAN-AGAN',
//       'TATOY',
//       'TICDALAN',
//       'TIG-AMAGA',
//       'TIG-APOG-APOG',
//       'TIGBAGACAY',
//       'TIGLAWA',
//       'TIGMALAPAD',
//       'TIGMARABO',
//       'TO-OG',
//       'TUGURA-AO',
//       'TUMAGBOC',
//       'UBOS ILAWOD (POB.)',
//       'UBOS ILAYA (POB.)',
//       'VALENCIA',
//       'WAYANG',
//     ],
//   },
//   MINA: {
//     province: 'ILOILO',
//     municipality: 'MINA',
//     region: 'REGION VI',
//     barangay_list: [
//       'ABAT',
//       'AGMANAPHAO',
//       'AMIROY',
//       'BADIANGAN',
//       'BANGAC',
//       'CABALABAGUAN',
//       'CAPUL-AN',
//       'DALA',
//       'GUIBUANGAN',
//       'JANIPA-AN EAST',
//       'JANIPA-AN WEST',
//       'MINA EAST (POB.)',
//       'MINA WEST (POB.)',
//       'NASIRUM',
//       'NAUMUAN',
//       'SINGAY',
//       'TALIBONG GRANDE',
//       'TALIBONG PEQUEÑO',
//       'TIPOLO',
//       'TOLARUCAN',
//       'TUMAY',
//       'YUGOT',
//     ],
//   },
//   'NEW LUCENA': {
//     province: 'ILOILO',
//     municipality: 'NEW LUCENA',
//     region: 'REGION VI',
//     barangay_list: [
//       'BACLAYAN',
//       'BADIANG',
//       'BALABAG',
//       'BILIDAN',
//       'BITA-OG GAJA',
//       'BOLOLACAO',
//       'BUROT',
//       'CABILAUAN',
//       'CABUGAO',
//       'CAGBAN',
//       'CALUMBUYAN',
//       'DAMIRES',
//       'DAWIS',
//       'GENERAL DELGADO',
//       'GUINOBATAN',
//       'JANIPA-AN OESTE',
//       'JELICUON ESTE',
//       'JELICUON OESTE',
//       'PASIL',
//       'POBLACION',
//       'WARI-WARI',
//     ],
//   },
//   OTON: {
//     province: 'ILOILO',
//     municipality: 'OTON',
//     region: 'REGION VI',
//     barangay_list: [
//       'ABILAY NORTE',
//       'ABILAY SUR',
//       'ALEGRE',
//       'BATUAN ILAUD',
//       'BATUAN ILAYA',
//       'BITA NORTE',
//       'BITA SUR',
//       'BOTONG',
//       'BURAY',
//       'CABANBANAN',
//       'CABOLOAN NORTE',
//       'CABOLOAN SUR',
//       'CADINGLIAN',
//       'CAGBANG',
//       'CALAM-ISAN',
//       'GALANG',
//       'LAMBUYAO',
//       'MAMBOG',
//       'PAKIAD',
//       'POBLACION EAST',
//       'POBLACION NORTH',
//       'POBLACION SOUTH',
//       'POBLACION WEST',
//       'POLO MAESTRA BITA',
//       'RIZAL',
//       'SALNGAN',
//       'SAMBALUDAN',
//       'SAN ANTONIO',
//       'SAN NICOLAS',
//       'SANTA CLARA',
//       'SANTA MONICA',
//       'SANTA RITA',
//       'TAGBAC NORTE',
//       'TAGBAC SUR',
//       'TRAPICHE',
//       'TUBURAN',
//       'TUROG-TUROG',
//     ],
//   },
//   PAVIA: {
//     province: 'ILOILO',
//     municipality: 'PAVIA',
//     region: 'REGION VI',
//     barangay_list: [
//       'AGANAN',
//       'AMPARO',
//       'ANILAO',
//       'BALABAG',
//       'CABUGAO NORTE',
//       'CABUGAO SUR',
//       'JIBAO-AN',
//       'MALI-AO',
//       'PAGSANGA-AN',
//       'PAL-AGON',
//       'PANDAC',
//       'PUROK I (POB.)',
//       'PUROK II (POB.)',
//       'PUROK III (POB.)',
//       'PUROK IV (POB.)',
//       'TIGUM',
//       'UNGKA I',
//       'UNGKA II',
//     ],
//   },
//   POTOTAN: {
//     province: 'ILOILO',
//     municipality: 'POTOTAN',
//     region: 'REGION VI',
//     barangay_list: [
//       'ABANGAY',
//       'AMAMAROS',
//       'BAGACAY',
//       'BARASAN',
//       'BATUAN',
//       'BONGCO',
//       'CAHAGUICHICAN',
//       'CALLAN',
//       'CANSILAYAN',
//       'CASALSAGAN',
//       'CATO-OGAN',
//       'CAU-AYAN',
//       'CULOB',
//       'DANAO',
//       'DAPITAN',
//       'DAWIS',
//       'DONGSOL',
//       'FERNANDO PARCON WARD (POB.)',
//       'FUNDACION',
//       'GUIBUANGAN',
//       'GUINACAS',
//       'IGANG',
//       'INTALUAN',
//       'IWA ILAUD',
//       'IWA ILAYA',
//       'JAMABALUD',
//       'JEBIOC',
//       'LAY-AHAN',
//       'LOPEZ JAENA WARD (POB.)',
//       'LUMBO',
//       'MACATOL',
//       'MALUSGOD',
//       'NABITASAN',
//       'NAGA',
//       'NANGA',
//       'NASLO',
//       'PAJO',
//       'PALANGUIA',
//       'PITOGO',
//       'POLOT-AN',
//       'PRIMITIVO LEDESMA WARD (POB.)',
//       'PUROG',
//       'RUMBANG',
//       'SAN JOSE WARD (POB.)',
//       'SINUAGAN',
//       'TUBURAN',
//       'TUMCON ILAUD',
//       'TUMCON ILAYA',
//       'UBANG',
//       'ZARRAGUE',
//     ],
//   },
//   'SAN DIONISIO': {
//     province: 'ILOILO',
//     municipality: 'SAN DIONISIO',
//     region: 'REGION VI',
//     barangay_list: [
//       'AGDALIRAN',
//       'AMAYONG',
//       'BAGACAY',
//       'BATUAN',
//       'BONDULAN',
//       'BOROÑGON',
//       'CANAS',
//       'CAPINANG',
//       'CUBAY',
//       'CUDIONAN',
//       'DUGMAN',
//       'HACIENDA CONCHITA',
//       'MADANLOG',
//       'MANDU-AWAK',
//       'MOTO',
//       'NABOROT',
//       'NIPA',
//       'ODIONGAN',
//       'PANGI',
//       'PASE',
//       'POBLACION',
//       'SAN NICOLAS',
//       'SANTOL',
//       'SIEMPREVIVA',
//       'SUA',
//       'TALO-ATO',
//       'TAMANGI',
//       'TIABAS',
//       'TUBLE',
//     ],
//   },
//   'SAN ENRIQUE': {
//     province: 'NEGROS OCCIDENTAL',
//     municipality: 'SAN ENRIQUE',
//     region: 'REGION VI',
//     barangay_list: [
//       'BAGONAWA',
//       'BALIWAGAN',
//       'BATUAN',
//       'GUINTORILAN',
//       'NAYON',
//       'POBLACION',
//       'SIBUCAO',
//       'TABAO BAYBAY',
//       'TABAO RIZAL',
//       'TIBSOC',
//     ],
//   },
//   'SAN JOAQUIN': {
//     province: 'ILOILO',
//     municipality: 'SAN JOAQUIN',
//     region: 'REGION VI',
//     barangay_list: [
//       'AMBOYU-AN',
//       'ANDRES BONIFACIO',
//       'ANTALON',
//       'BAD-AS',
//       'BAGUMBAYAN',
//       'BALABAGO',
//       'BAYBAY',
//       'BAYUNAN (PANDAY ORO)',
//       'BOLBOGAN',
//       'BONGA',
//       'BUCAYA',
//       'BULHO',
//       'CADLUMAN',
//       'CADOLDOLAN',
//       'CAMABA-AN',
//       'CAMIA',
//       'CATA-AN',
//       'CROSSING DAPUYAN',
//       'CUBAY',
//       'CUMARASCAS',
//       'DACDACANAN',
//       'DANAWAN',
//       'DOLDOL',
//       'DONGOC',
//       'ESCALANTERA',
//       'GINOT-AN',
//       'GUIBONGAN BAYUNAN',
//       'HUNA',
//       'IGBAJE',
//       'IGBANGCAL',
//       'IGBINANGON',
//       'IGBURI',
//       'IGCABUTONG',
//       'IGCADLUM',
//       'IGCAPHANG',
//       'IGCARATONG',
//       'IGCONDAO',
//       'IGCORES',
//       'IGDAGMAY',
//       'IGDOMINGDING',
//       'IGLILICO',
//       'IGPAYONG',
//       'JAWOD',
//       'LANGCA',
//       'LANGUANAN',
//       'LAWIGAN',
//       'LOMBOY',
//       'LOMBOYAN (SANTA ANA)',
//       'LOPEZ VITO',
//       'MABINI NORTE',
//       'MABINI SUR',
//       'MANHARA',
//       'MANINILA',
//       'MASAGUD',
//       'MATAMBOG',
//       'MAYUNOC',
//       'MONTINOLA',
//       'NADSADAN',
//       'NAGQUIRISAN',
//       'NAGSIPIT',
//       'NEW GUMAWAN',
//       'PANATAN',
//       'PITOGO',
//       'PUROK 1 (POB.)',
//       'PUROK 2 (POB.)',
//       'PUROK 3 (POB.)',
//       'PUROK 4 (POB.)',
//       'PUROK 5 (POB.)',
//       'QUI-ANAN',
//       'ROMA',
//       'SAN LUIS',
//       'SAN MATEO NORTE',
//       'SAN MATEO SUR',
//       'SANTA RITA',
//       'SANTIAGO',
//       'SINOGBUHAN',
//       'SIWARAGAN',
//       'TALAGUTAC',
//       'TAPIKAN',
//       'TASLAN',
//       'TIGLAWA',
//       'TIOLAS',
//       'TO-OG',
//       'TOROCADAN',
//       'ULAY',
//     ],
//   },
//   SARA: {
//     province: 'ILOILO',
//     municipality: 'SARA',
//     region: 'REGION VI',
//     barangay_list: [
//       'AGUIRRE',
//       'ALDEGUER',
//       'ALIBAYOG',
//       'ANORING',
//       'APELO',
//       'APOLOGISTA',
//       'APOSAGA',
//       'ARANTE',
//       'ARDEMIL',
//       'ASPERA',
//       'ASWE-PABRIAGA',
//       'BAGAYGAY',
//       'BAKABAK',
//       'BATITAO',
//       'BATO',
//       'CASTOR',
//       'CRESPO',
//       'DEL CASTILLO',
//       'DEVERA',
//       'DOMINGO',
//       'FERRARIS',
//       'GILDORE',
//       'IMPROGO',
//       'JUANEZA',
//       'LABIGAN',
//       'LANCIOLA',
//       'LATAWAN',
//       'MALAPAYA',
//       'MUYCO',
//       'PADIOS',
//       'PASIG',
//       'POBLACION ILAWOD',
//       'POBLACION ILAYA',
//       'POBLACION MARKET',
//       'POSADAS',
//       'PRECIOSA',
//       'SALCEDO',
//       'SAN LUIS',
//       'TADY',
//       'TENTAY',
//       'VILLAHERMOSA',
//       'ZERRUDO',
//     ],
//   },
//   TIGBAUAN: {
//     province: 'ILOILO',
//     municipality: 'TIGBAUAN',
//     region: 'REGION VI',
//     barangay_list: [
//       'ALUPIDIAN',
//       'ATABAYAN',
//       'BAGACAY',
//       'BAGUINGIN',
//       'BAGUMBAYAN',
//       'BANGKAL',
//       'BANTUD',
//       'BARANGAY 1 (POB.)',
//       'BARANGAY 2 (POB.)',
//       'BARANGAY 3 (POB.)',
//       'BARANGAY 4 (POB.)',
//       'BARANGAY 5 (POB.)',
//       'BARANGAY 6 (POB.)',
//       'BARANGAY 7 (POB.)',
//       'BARANGAY 8 (POB.)',
//       'BARANGAY 9 (POB.)',
//       'BAROSONG',
//       'BARROC',
//       'BAYUCO',
//       'BINALIUAN MAYOR',
//       'BINALIUAN MENOR',
//       'BITAS',
//       'BUENAVISTA',
//       'BUGASONGAN',
//       'BUYU-AN',
//       'CANABUAN',
//       'CANSILAYAN',
//       'CORDOVA NORTE',
//       'CORDOVA SUR',
//       'DANAO',
//       'DAPDAP',
//       'DORONG-AN',
//       'GUISIAN',
//       'ISAUAN',
//       'ISIAN',
//       'JAMOG',
//       'LANAG',
//       'LINOBAYAN',
//       'LUBOG',
//       'NAGBA',
//       'NAMOCON',
//       'NAPNAPAN NORTE',
//       'NAPNAPAN SUR',
//       'OLO BARROC',
//       'PARARA NORTE',
//       'PARARA SUR',
//       'SAN RAFAEL',
//       'SERMON',
//       'SIPITAN',
//       'SUPA',
//       'TAN PAEL',
//       'TARO',
//     ],
//   },
//   TUBUNGAN: {
//     province: 'ILOILO',
//     municipality: 'TUBUNGAN',
//     region: 'REGION VI',
//     barangay_list: [
//       'ADGAO',
//       'AGO',
//       'AMBARIHON',
//       'AYUBO',
//       'BACAN',
//       'BADIANG',
//       'BAGUNANAY',
//       'BALICUA',
//       'BANTAYANAN',
//       'BATGA',
//       'BATO',
//       'BIKIL',
//       'BOLOC',
//       'BONDOC',
//       'BORONG',
//       'BUENAVISTA',
//       'CADABDAB',
//       'DAGA-AY',
//       'DESPOSORIO',
//       'IGDAMPOG NORTE',
//       'IGDAMPOG SUR',
//       'IGPAHO',
//       'IGTUBLE',
//       'INGAY',
//       'ISAUAN',
//       'JOLASON',
//       'JONA',
//       'LA-AG',
//       'LANAG NORTE',
//       'LANAG SUR',
//       'MALE',
//       'MAYANG',
//       'MOLINA',
//       'MORCILLAS',
//       'NAGBA',
//       'NAVILLAN',
//       'PINAMACALAN',
//       'SAN JOSE',
//       'SIBUCAUAN',
//       'SINGON',
//       'TABAT',
//       'TAGPU-AN',
//       'TALENTO',
//       'TENIENTE BENITO',
//       'VICTORIA',
//       'ZONE I (POB.)',
//       'ZONE II (POB.)',
//       'ZONE III (POB.)',
//     ],
//   },
//   ZARRAGA: {
//     province: 'ILOILO',
//     municipality: 'ZARRAGA',
//     region: 'REGION VI',
//     barangay_list: [
//       'BALUD I',
//       'BALUD II',
//       'BALUD LILO-AN',
//       'DAWIS CENTRO',
//       'DAWIS NORTE',
//       'DAWIS SUR',
//       'GINES',
//       'ILAWOD POBLACION',
//       'ILAYA POBLACION',
//       'INAGDANGAN CENTRO',
//       'INAGDANGAN NORTE',
//       'INAGDANGAN SUR',
//       'JALAUD NORTE',
//       'JALAUD SUR',
//       'LIBONGCOGON',
//       'MALUNANG',
//       'PAJO',
//       'SAMBAG',
//       'SIGANGAO',
//       'TALAUGUIS',
//       'TALIBONG',
//       'TUBIGAN',
//       'TUBURAN',
//       'TUBURAN SULBOD',
//     ],
//   },
//   'BACOLOD': {
//     province: 'NEGROS OCCIDENTAL',
//     municipality: 'BACOLOD',
//     region: 'REGION VI',
//     barangay_list: [
//       'ALANGILAN',
//       'ALIJIS',
//       'BANAGO',
//       'BARANGAY 1 (POB.)',
//       'BARANGAY 2 (POB.)',
//       'BARANGAY 3 (POB.)',
//       'BARANGAY 4 (POB.)',
//       'BARANGAY 5 (POB.)',
//       'BARANGAY 6 (POB.)',
//       'BARANGAY 7 (POB.)',
//       'BARANGAY 8 (POB.)',
//       'BARANGAY 9 (POB.)',
//       'BARANGAY 10 (POB.)',
//       'BARANGAY 11 (POB.)',
//       'BARANGAY 12 (POB.)',
//       'BARANGAY 13 (POB.)',
//       'BARANGAY 14 (POB.)',
//       'BARANGAY 15 (POB.)',
//       'BARANGAY 16 (POB.)',
//       'BARANGAY 17 (POB.)',
//       'BARANGAY 18 (POB.)',
//       'BARANGAY 19 (POB.)',
//       'BARANGAY 20 (POB.)',
//       'BARANGAY 21 (POB.)',
//       'BARANGAY 22 (POB.)',
//       'BARANGAY 23 (POB.)',
//       'BARANGAY 24 (POB.)',
//       'BARANGAY 25 (POB.)',
//       'BARANGAY 26 (POB.)',
//       'BARANGAY 27 (POB.)',
//       'BARANGAY 28 (POB.)',
//       'BARANGAY 29 (POB.)',
//       'BARANGAY 30 (POB.)',
//       'BARANGAY 31 (POB.)',
//       'BARANGAY 32 (POB.)',
//       'BARANGAY 33 (POB.)',
//       'BARANGAY 34 (POB.)',
//       'BARANGAY 35 (POB.)',
//       'BARANGAY 36 (POB.)',
//       'BARANGAY 37 (POB.)',
//       'BARANGAY 38 (POB.)',
//       'BARANGAY 39 (POB.)',
//       'BARANGAY 40 (POB.)',
//       'BARANGAY 41 (POB.)',
//       'BATA',
//       'CABUG',
//       'ESTEFANIA',
//       'FELISA',
//       'GRANADA',
//       'HANDUMANAN',
//       'MANDALAGAN',
//       'MANSILINGAN',
//       'MONTEVISTA',
//       'PAHANOCOY',
//       'PUNTA TAYTAY',
//       'SINGCANG-AIRPORT',
//       'SUM-AG',
//       'TACULING',
//       'TANGUB',
//       'VILLAMONTE',
//       'VISTA ALEGRE',
//     ],
//   },
//   'BAGO': {
//     province: 'NEGROS OCCIDENTAL',
//     municipality: 'BAGO',
//     region: 'REGION VI',
//     barangay_list: [
//       'ABUANAN',
//       'ALIANZA',
//       'ATIPULUAN',
//       'BACONG-MONTILLA',
//       'BAGROY',
//       'BALINGASAG',
//       'BINUBUHAN',
//       'BUSAY',
//       'CALUMANGAN',
//       'CARIDAD',
//       'DULAO',
//       'ILIJAN',
//       'JORGE L. ARANETA (MA-AO CENTRAL)',
//       'LAG-ASAN',
//       'MA-AO BARRIO',
//       'MAILUM',
//       'MALINGIN',
//       'NAPOLES',
//       'PACOL',
//       'POBLACION',
//       'SAGASA',
//       'SAMPINIT',
//       'TABUNAN',
//       'TALOC',
//     ],
//   },
//   BINALBAGAN: {
//     province: 'NEGROS OCCIDENTAL',
//     municipality: 'BINALBAGAN',
//     region: 'REGION VI',
//     barangay_list: [
//       'AMONTAY',
//       'BAGROY',
//       'BI-AO',
//       'CANMOROS (POB.)',
//       'ENCLARO',
//       'MARINA (POB.)',
//       'PAGLAUM (POB.)',
//       'PAYAO',
//       'PROGRESO (POB.)',
//       'SAN JOSE',
//       'SAN JUAN (POB.)',
//       'SAN PEDRO (POB.)',
//       'SAN TEODORO (POB.)',
//       'SAN VICENTE (POB.)',
//       'SANTO ROSARIO (POB.)',
//       'SANTOL',
//     ],
//   },
//   'CADIZ': {
//     province: 'NEGROS OCCIDENTAL',
//     municipality: 'CADIZ',
//     region: 'REGION VI',
//     barangay_list: [
//       'ANDRES BONIFACIO',
//       'BANQUEROHAN',
//       'BARANGAY 1 POB. (ZONE 1)',
//       'BARANGAY 2 POB. (ZONE 2)',
//       'BARANGAY 3 POB. (ZONE 3)',
//       'BARANGAY 4 POB. (ZONE 4)',
//       'BARANGAY 5 POB. (ZONE 5)',
//       'BARANGAY 6 POB. (ZONE 6)',
//       'BURGOS',
//       'CABAHUG',
//       'CADIZ VIEJO',
//       'CADUHA-AN',
//       'CELESTINO VILLACIN',
//       'DAGA',
//       'JERUSALEM',
//       'LUNA',
//       'MABINI',
//       'MAGSAYSAY',
//       'SICABA',
//       'TIGLAWIGAN',
//       'TINAMPA-AN',
//       'V. F. GUSTILO',
//     ],
//   },
//   CANDONI: {
//     province: 'NEGROS OCCIDENTAL',
//     municipality: 'CANDONI',
//     region: 'REGION VI',
//     barangay_list: [
//       'AGBOY',
//       'BANGA',
//       'CABIA-AN',
//       'CANINGAY',
//       'GATUSLAO',
//       'HABA',
//       'PAYAUAN',
//       'POBLACION EAST',
//       'POBLACION WEST',
//     ],
//   },
//   CAUAYAN: {
//     province: 'NEGROS OCCIDENTAL',
//     municipality: 'CAUAYAN',
//     region: 'REGION VI',
//     barangay_list: [
//       'ABACA',
//       'BACLAO',
//       'BASAK',
//       'BULATA',
//       'CALILING',
//       'CAMALANDA-AN',
//       'CAMINDANGAN',
//       'ELIHAN',
//       'GUILJUNGAN',
//       'INAYAWAN',
//       'ISIO',
//       'LINAON',
//       'LUMBIA',
//       'MAMBUGSAY',
//       'MAN-ULING',
//       'MASALING',
//       'MOLOBOLO',
//       'POBLACION',
//       'SURA',
//       'TALACDAN',
//       'TAMBAD',
//       'TILING',
//       'TOMINA',
//       'TUYOM',
//       'YAO-YAO',
//     ],
//   },
//   'ENRIQUE B. MAGALONA (SARAVIA)': {
//     province: 'NEGROS OCCIDENTAL',
//     municipality: 'ENRIQUE B. MAGALONA (SARAVIA)',
//     region: 'REGION VI',
//     barangay_list: [
//       'ALACAYGAN',
//       'ALICANTE',
//       'BATEA',
//       'CANLUSONG',
//       'CONSING',
//       'CUDANGDANG',
//       'DAMGO',
//       'GAHIT',
//       'LATASAN',
//       'MADALAG',
//       'MANTA-ANGAN',
//       'NANCA',
//       'PASIL',
//       'POBLACION I (BARANGAY 1)',
//       'POBLACION II (BARANGAY 2)',
//       'POBLACION III (BARANGAY 3)',
//       'SAN ISIDRO',
//       'SAN JOSE',
//       'SANTO NIÑO',
//       'TABIGUE',
//       'TANZA',
//       'TOMONGTONG',
//       'TUBURAN',
//     ],
//   },
//   'ESCALANTE': {
//     province: 'NEGROS OCCIDENTAL',
//     municipality: 'ESCALANTE',
//     region: 'REGION VI',
//     barangay_list: [
//       'ALIMANGO',
//       'BALINTAWAK (POB.)',
//       'BINAGUIOHAN',
//       'BUENAVISTA',
//       'CERVANTES',
//       'DIAN-AY',
//       'HACIENDA FE',
//       'JAPITAN',
//       'JONOBJONOB',
//       'LANGUB',
//       'LIBERTAD',
//       'MABINI',
//       'MAGSAYSAY (BINABONGOL)',
//       'MALASIBOG',
//       'OLD POBLACION',
//       'PAITAN',
//       'PINAPUGASAN',
//       'RIZAL',
//       'TAMLANG',
//       'UDTONGAN',
//       'WASHINGTON',
//     ],
//   },
//   'HIMAMAYLAN': {
//     province: 'NEGROS OCCIDENTAL',
//     municipality: 'HIMAMAYLAN',
//     region: 'REGION VI',
//     barangay_list: [
//       'AGUISAN',
//       'BARANGAY I (POB.)',
//       'BARANGAY II (POB.)',
//       'BARANGAY III (POB.)',
//       'BARANGAY IV (POB.)',
//       'BUENAVISTA',
//       'CABADIANGAN',
//       'CABANBANAN',
//       'CARABALAN',
//       'CARADIO-AN',
//       'LIBACAO',
//       'MAHALANG',
//       'MAMBAGATON',
//       'NABALI-AN',
//       'SAN ANTONIO',
//       'SARA-ET',
//       'SU-AY',
//       'TALABAN',
//       'TO-OY',
//     ],
//   },
//   HINIGARAN: {
//     province: 'NEGROS OCCIDENTAL',
//     municipality: 'HINIGARAN',
//     region: 'REGION VI',
//     barangay_list: [
//       'ANAHAW',
//       'ARANDA',
//       'BAGA-AS',
//       'BARANGAY I (POB.)',
//       'BARANGAY II (POB.)',
//       'BARANGAY III (POB.)',
//       'BARANGAY IV (POB.)',
//       'BATO',
//       'CALAPI',
//       'CAMALOBALO',
//       'CAMBA-OG',
//       'CAMBUGSA',
//       'CANDUMARAO',
//       'GARGATO',
//       'HIMAYA',
//       'MIRANDA',
//       'NANUNGA',
//       'NARAUIS',
//       'PALAYOG',
//       'PATICUI',
//       'PILAR',
//       'QUIWI',
//       'TAGDA',
//       'TUGUIS',
//     ],
//   },
//   'HINOBA-AN (ASIA)': {
//     province: 'NEGROS OCCIDENTAL',
//     municipality: 'HINOBA-AN (ASIA)',
//     region: 'REGION VI',
//     barangay_list: [
//       'ALIM',
//       'ASIA',
//       'BACUYANGAN',
//       'BARANGAY I (POB.)',
//       'BARANGAY II (POB.)',
//       'BULWANGAN',
//       'CULIPAPA',
//       'DAMUTAN',
//       'DAUG',
//       'PO-OK',
//       'SAN RAFAEL',
//       'SANGKE',
//       'TALACAGAY',
//     ],
//   },
//   ILOG: {
//     province: 'NEGROS OCCIDENTAL',
//     municipality: 'ILOG',
//     region: 'REGION VI',
//     barangay_list: [
//       'ANDULAUAN',
//       'BALICOTOC',
//       'BARANGAY I (POB.)',
//       'BARANGAY II (POB.)',
//       'BOCANA',
//       'CALUBANG',
//       'CANLAMAY',
//       'CONSUELO',
//       'DANCALAN',
//       'DELICIOSO',
//       'GALICIA',
//       'MANALAD',
//       'PINGGOT',
//       'TABU',
//       'VISTA ALEGRE',
//     ],
//   },
//   ISABELA: {
//     province: 'NEGROS OCCIDENTAL',
//     municipality: 'ISABELA',
//     region: 'REGION VI',
//     barangay_list: [
//       'AMIN',
//       'BANOGBANOG',
//       'BARANGAY 1 (POB.)',
//       'BARANGAY 2 (POB.)',
//       'BARANGAY 3 (POB.)',
//       'BARANGAY 4 (POB.)',
//       'BARANGAY 5 (POB.)',
//       'BARANGAY 6 (POB.)',
//       'BARANGAY 7 (POB.)',
//       'BARANGAY 8 (POB.)',
//       'BARANGAY 9 (POB.)',
//       'BULAD',
//       'BUNGAHIN',
//       'CABCAB',
//       'CAMANGCAMANG',
//       'CAMP CLARK',
//       'CANSALONGON',
//       'GUINTUBHAN',
//       'LIBAS',
//       'LIMALIMA',
//       'MAKILIGNIT',
//       'MANSABLAY',
//       'MAYTUBIG',
//       'PANAQUIAO',
//       'RIVERSIDE',
//       'RUMIRANG',
//       'SAN AGUSTIN',
//       'SEBUCAWAN',
//       'SIKATUNA',
//       'TINONGAN',
//     ],
//   },
//   'KABANKALAN': {
//     province: 'NEGROS OCCIDENTAL',
//     municipality: 'KABANKALAN',
//     region: 'REGION VI',
//     barangay_list: [
//       'BANTAYAN',
//       'BARANGAY 1 (POB.)',
//       'BARANGAY 2 (POB.)',
//       'BARANGAY 3 (POB.)',
//       'BARANGAY 4 (POB.)',
//       'BARANGAY 5 (POB.)',
//       'BARANGAY 6 (POB.)',
//       'BARANGAY 7 (POB.)',
//       'BARANGAY 8 (POB.)',
//       'BARANGAY 9 (POB.)',
//       'BINICUIL',
//       'CAMANSI',
//       'CAMINGAWAN',
//       'CAMUGAO',
//       'CAROL-AN',
//       'DAAN BANUA',
//       'HILAMONAN',
//       'INAPOY',
//       'LINAO',
//       'LOCOTAN',
//       'MAGBALLO',
//       'ORINGAO',
//       'ORONG',
//       'PINAGUINPINAN',
//       'SALONG',
//       'TABUGON',
//       'TAGOC',
//       'TAGUKON',
//       'TALUBANGI',
//       'TAMPALON',
//       'TAN-AWAN',
//       'TAPI',
//     ],
//   },
//   'LA CARLOTA': {
//     province: 'NEGROS OCCIDENTAL',
//     municipality: 'LA CARLOTA',
//     region: 'REGION VI',
//     barangay_list: [
//       'ARA-AL',
//       'AYUNGON',
//       'BALABAG',
//       'BARANGAY I (POB.)',
//       'BARANGAY II (POB.)',
//       'BARANGAY III (POB.)',
//       'BATUAN',
//       'CUBAY',
//       'HAGUIMIT',
//       'LA GRANJA',
//       'NAGASI',
//       'ROBERTO S. BENEDICTO (CONSUELO)',
//       'SAN MIGUEL',
//       'YUBO',
//     ],
//   },
//   'LA CASTELLANA': {
//     province: 'NEGROS OCCIDENTAL',
//     municipality: 'LA CASTELLANA',
//     region: 'REGION VI',
//     barangay_list: [
//       'BIAKNABATO',
//       'CABACUNGAN',
//       'CABAGNAAN',
//       'CAMANDAG',
//       'LALAGSAN',
//       'MANGHANOY',
//       'MANSALANAO',
//       'MASULOG',
//       'NATO',
//       'PUSO',
//       'ROBLES (POB.)',
//       'SAG-ANG',
//       'TALAPTAP',
//     ],
//   },
//   MANAPLA: {
//     province: 'NEGROS OCCIDENTAL',
//     municipality: 'MANAPLA',
//     region: 'REGION VI',
//     barangay_list: [
//       'BARANGAY I (POB.)',
//       'BARANGAY I-A (POB.)',
//       'BARANGAY I-B (POB.)',
//       'BARANGAY II (POB.)',
//       'BARANGAY II-A (POB.)',
//       'CHAMBERY',
//       'PUNTA MESA',
//       'PUNTA SALONG',
//       'PURISIMA',
//       'SAN PABLO',
//       'SANTA TERESA',
//       'TORTOSA',
//     ],
//   },
//   'MOISES PADILLA (MAGALLON)': {
//     province: 'NEGROS OCCIDENTAL',
//     municipality: 'MOISES PADILLA (MAGALLON)',
//     region: 'REGION VI',
//     barangay_list: [
//       'BARANGAY 1 (POB.)',
//       'BARANGAY 2 (POB.)',
//       'BARANGAY 3 (POB.)',
//       'BARANGAY 4 (POB.)',
//       'BARANGAY 5 (POB.)',
//       'BARANGAY 6 (POB.)',
//       'BARANGAY 7 (POB.)',
//       'CROSSING MAGALLON',
//       'GUINPANA-AN',
//       'INOLINGAN',
//       'MACAGAHAY',
//       'MAGALLON CADRE',
//       'MONTILLA',
//       'ODIONG',
//       'QUINTIN REMO',
//     ],
//   },
//   MURCIA: {
//     province: 'NEGROS OCCIDENTAL',
//     municipality: 'MURCIA',
//     region: 'REGION VI',
//     barangay_list: [
//       'ABO-ABO',
//       'ALEGRIA',
//       'AMAYCO',
//       'BLUMENTRITT',
//       'BUENAVISTA',
//       'CALIBAN',
//       'CANLANDOG',
//       'CANSILAYAN',
//       'DAMSITE',
//       'IGLAU-AN',
//       'LOPEZ JAENA',
//       'MINOYAN',
//       'PANDANON',
//       'SALVACION',
//       'SAN MIGUEL',
//       'SANTA CRUZ',
//       'SANTA ROSA',
//       'TALOTOG',
//       'ZONE I (POB.)',
//       'ZONE II (POB.)',
//       'ZONE III (POB.)',
//       'ZONE IV (POB.)',
//       'ZONE V (POB.)',
//     ],
//   },
//   PULUPANDAN: {
//     province: 'NEGROS OCCIDENTAL',
//     municipality: 'PULUPANDAN',
//     region: 'REGION VI',
//     barangay_list: [
//       'BARANGAY ZONE 1 (POB.)',
//       'BARANGAY ZONE 1-A (POB.)',
//       'BARANGAY ZONE 2 (POB.)',
//       'BARANGAY ZONE 3 (POB.)',
//       'BARANGAY ZONE 4 (POB.)',
//       'BARANGAY ZONE 4-A (POB.)',
//       'BARANGAY ZONE 5 (POB.)',
//       'BARANGAY ZONE 6 (POB.)',
//       'BARANGAY ZONE 7 (POB.)',
//       'CANJUSA',
//       'CROSSING PULUPANDAN',
//       'CULO',
//       'MABINI',
//       'PAG-AYON',
//       'PALAKA NORTE',
//       'PALAKA SUR',
//       'PATIC',
//       'TAPONG',
//       'UBAY',
//       'UTOD',
//     ],
//   },
//   'SAGAY': {
//     province: 'NEGROS OCCIDENTAL',
//     municipality: 'SAGAY',
//     region: 'REGION VI',
//     barangay_list: [
//       'ANDRES BONIFACIO',
//       'BATO',
//       'BAVIERA',
//       'BULANON',
//       'CAMPO HIMOGA-AN',
//       'CAMPO SANTIAGO',
//       'COLONIA DIVINA',
//       'FABRICA',
//       'GENERAL LUNA',
//       'HIMOGA-AN BAYBAY',
//       'LOPEZ JAENA',
//       'MAKILING',
//       'MALUBON',
//       'MOLOCABOC',
//       'OLD SAGAY',
//       'PARAISO',
//       'PLARIDEL',
//       'POBLACION I (BARANGAY 1)',
//       'POBLACION II (BARANGAY 2)',
//       'PUEY',
//       'RAFAELA BARRERA',
//       'RIZAL',
//       'TABA-AO',
//       'TADLONG',
//       'VITO',
//     ],
//   },
//   'SALVADOR BENEDICTO': {
//     province: 'NEGROS OCCIDENTAL',
//     municipality: 'SALVADOR BENEDICTO',
//     region: 'REGION VI',
//     barangay_list: [
//       'BAGO (LALUNG)',
//       'BAGONG SILANG (MARCELO)',
//       'BUNGA',
//       'IGMAYA-AN',
//       'KUMALISKIS',
//       'PANDANON',
//       'PINOWAYAN (PROSPERIDAD)',
//     ],
//   },
//   'SILAY': {
//     province: 'NEGROS OCCIDENTAL',
//     municipality: 'SILAY',
//     region: 'REGION VI',
//     barangay_list: [
//       'BAGTIC',
//       'BALARING',
//       'BARANGAY I (POB.)',
//       'BARANGAY II (POB.)',
//       'BARANGAY III (POB.)',
//       'BARANGAY IV (POB.)',
//       'BARANGAY V (POB.)',
//       'BARANGAY VI POB. (HAWAIIAN)',
//       'EUSTAQUIO LOPEZ',
//       'GUIMBALA-ON',
//       'GUINHALARAN',
//       'KAPITAN RAMON',
//       'LANTAD',
//       'MAMBULAC',
//       'PATAG',
//       'RIZAL',
//     ],
//   },
//   'SIPALAY': {
//     province: 'NEGROS OCCIDENTAL',
//     municipality: 'SIPALAY',
//     region: 'REGION VI',
//     barangay_list: [
//       'BARANGAY 1 (POB.)',
//       'BARANGAY 2 (POB.)',
//       'BARANGAY 3 (POB.)',
//       'BARANGAY 4 (POB.)',
//       'BARANGAY 5 (POB.)',
//       'CABADIANGAN',
//       'CAMINDANGAN',
//       'CANTURAY',
//       'CARTAGENA',
//       'CAYHAGAN',
//       'GIL MONTILLA',
//       'MAMBAROTO',
//       'MANLUCAHOC',
//       'MARICALUM',
//       'NABULAO',
//       'NAUHANG',
//       'SAN JOSE',
//     ],
//   },
//   'TALISAY': {
//     province: 'CEBU',
//     municipality: 'TALISAY',
//     region: 'REGION VII',
//     barangay_list: [
//       'BIASONG',
//       'BULACAO',
//       'CADULAWAN',
//       'CAMP IV',
//       'CANSOJONG',
//       'DUMLOG',
//       'JACLUPAN',
//       'LAGTANG',
//       'LAWAAN I',
//       'LAWAAN II',
//       'LAWAAN III',
//       'LINAO',
//       'MAGHAWAY',
//       'MANIPIS',
//       'MOHON',
//       'POBLACION',
//       'POOC',
//       'SAN ISIDRO',
//       'SAN ROQUE',
//       'TABUNOC',
//       'TANGKE',
//       'TAPUL',
//     ],
//   },
//   TOBOSO: {
//     province: 'NEGROS OCCIDENTAL',
//     municipality: 'TOBOSO',
//     region: 'REGION VI',
//     barangay_list: [
//       'BANDILA',
//       'BUG-ANG',
//       'GENERAL LUNA',
//       'MAGTICOL',
//       'POBLACION',
//       'SALAMANCA',
//       'SAN ISIDRO',
//       'SAN JOSE',
//       'TABUN-AC',
//     ],
//   },
//   VALLADOLID: {
//     province: 'NEGROS OCCIDENTAL',
//     municipality: 'VALLADOLID',
//     region: 'REGION VI',
//     barangay_list: [
//       'ALIJIS',
//       'AYUNGON',
//       'BAGUMBAYAN',
//       'BATUAN',
//       'BAYABAS',
//       'CENTRAL TABAO',
//       'DOLDOL',
//       'GUINTORILAN',
//       'LACARON',
//       'MABINI',
//       'PACOL',
//       'PALAKA',
//       'PALOMA',
//       'POBLACION',
//       'SAGUA BANUA',
//       'TABAO PROPER',
//     ],
//   },
//   'VICTORIAS': {
//     province: 'NEGROS OCCIDENTAL',
//     municipality: 'VICTORIAS',
//     region: 'REGION VI',
//     barangay_list: [
//       'BARANGAY I (POB.)',
//       'BARANGAY II (POB.)',
//       'BARANGAY III (POB.)',
//       'BARANGAY IV (POB.)',
//       'BARANGAY IX (DAAN BANWA)',
//       'BARANGAY V (POB.)',
//       'BARANGAY VI (POB.)',
//       'BARANGAY VI-A',
//       'BARANGAY VII (POB.)',
//       'BARANGAY VIII (POB.)',
//       'BARANGAY X (ESTADO)',
//       'BARANGAY XI (GAWAHON)',
//       'BARANGAY XII',
//       'BARANGAY XIII',
//       'BARANGAY XIV',
//       'BARANGAY XIX',
//       'BARANGAY XIX-A',
//       'BARANGAY XV',
//       'BARANGAY XV-A',
//       'BARANGAY XVI',
//       'BARANGAY XVI-A',
//       'BARANGAY XVII',
//       'BARANGAY XVIII',
//       'BARANGAY XVIII-A',
//       'BARANGAY XX',
//       'BARANGAY XXI',
//     ],
//   },
//   ALBURQUERQUE: {
//     province: 'BOHOL',
//     municipality: 'ALBURQUERQUE',
//     region: 'REGION VII',
//     barangay_list: [
//       'BAHI',
//       'BASACDACU',
//       'CANTIGUIB',
//       'DANGAY',
//       'EAST POBLACION',
//       'PONONG',
//       'SAN AGUSTIN',
//       'SANTA FILOMENA',
//       'TAGBUANE',
//       'TORIL',
//       'WEST POBLACION',
//     ],
//   },
//   ANTEQUERA: {
//     province: 'BOHOL',
//     municipality: 'ANTEQUERA',
//     region: 'REGION VII',
//     barangay_list: [
//       'ANGILAN',
//       'BANTOLINAO',
//       'BICAHAN',
//       'BITAUGAN',
//       'BUNGAHAN',
//       'CAN-OMAY',
//       'CANLAAS',
//       'CANSIBUAN',
//       'CELING',
//       'DANAO',
//       'DANICOP',
//       'MAG-ASO',
//       'POBLACION',
//       'QUINAPON-AN',
//       'SANTO ROSARIO',
//       'TABUAN',
//       'TAGUBAAS',
//       'TUPAS',
//       'UBOJAN',
//       'VIGA',
//       'VILLA AURORA (CANOC-OC)',
//     ],
//   },
//   BACLAYON: {
//     province: 'BOHOL',
//     municipality: 'BACLAYON',
//     region: 'REGION VII',
//     barangay_list: [
//       'BUENAVENTURA',
//       'CAMBANAC',
//       'DASITAM',
//       'GUIWANON',
//       'LANDICAN',
//       'LAYA',
//       'LIBERTAD',
//       'MONTANA',
//       'PAMILACAN',
//       'PAYAHAN',
//       'POBLACION',
//       'SAN ISIDRO',
//       'SAN ROQUE',
//       'SAN VICENTE',
//       'SANTA CRUZ',
//       'TAGUIHON',
//       'TANDAY',
//     ],
//   },
//   BALILIHAN: {
//     province: 'BOHOL',
//     municipality: 'BALILIHAN',
//     region: 'REGION VII',
//     barangay_list: [
//       'BAUCAN NORTE',
//       'BAUCAN SUR',
//       'BOCTOL',
//       'BOYOG NORTE',
//       'BOYOG PROPER',
//       'BOYOG SUR',
//       'CABAD',
//       'CANDASIG',
//       'CANTALID',
//       'CANTOMIMBO',
//       'COGON',
//       'DATAG NORTE',
//       'DATAG SUR',
//       'DEL CARMEN ESTE (POB.)',
//       'DEL CARMEN NORTE (POB.)',
//       'DEL CARMEN SUR (POB.)',
//       'DEL CARMEN WESTE (POB.)',
//       'DEL ROSARIO',
//       'DOROL',
//       'HAGUILANAN GRANDE',
//       'HANOPOL ESTE',
//       'HANOPOL NORTE',
//       'HANOPOL WESTE',
//       'MAGSIJA',
//       'MASLOG',
//       'SAGASA',
//       'SAL-ING',
//       'SAN ISIDRO',
//       'SAN ROQUE',
//       'SANTO NIÑO',
//       'TAGUSTUSAN',
//     ],
//   },
//   'BIEN UNIDO': {
//     province: 'BOHOL',
//     municipality: 'BIEN UNIDO',
//     region: 'REGION VII',
//     barangay_list: [
//       'BILANGBILANGAN DAKO',
//       'BILANGBILANGAN DIOT',
//       'HINGOTANAN EAST',
//       'HINGOTANAN WEST',
//       'LIBERTY',
//       'MALINGIN',
//       'MANDAWA',
//       'MAOMAWAN',
//       'NUEVA ESPERANZA',
//       'NUEVA ESTRELLA',
//       'PINAMGO',
//       'POBLACION (BIEN UNIDO)',
//       'PUERTO SAN PEDRO',
//       'SAGASA',
//       'TUBORAN',
//     ],
//   },
//   BILAR: {
//     province: 'BOHOL',
//     municipality: 'BILAR',
//     region: 'REGION VII',
//     barangay_list: [
//       'BONIFACIO',
//       'BUGANG NORTE',
//       'BUGANG SUR',
//       'CABACNITAN (MAGSAYSAY)',
//       'CAMBIGSI',
//       'CAMPAGAO',
//       'CANSUMBOL',
//       'DAGOHOY',
//       'OWAC',
//       'POBLACION',
//       'QUEZON',
//       'RIVERSIDE',
//       'RIZAL',
//       'ROXAS',
//       'SUBAYON',
//       'VILLA AURORA',
//       'VILLA SUERTE',
//       'YANAYA',
//       'ZAMORA',
//     ],
//   },
//   CALAPE: {
//     province: 'BOHOL',
//     municipality: 'CALAPE',
//     region: 'REGION VII',
//     barangay_list: [
//       'ABUCAYAN NORTE',
//       'ABUCAYAN SUR',
//       'BANLASAN',
//       'BENTIG',
//       'BINOGAWAN',
//       'BONBON',
//       'CABAYUGAN',
//       'CABUDBURAN',
//       'CALUNASAN',
//       'CAMIAS',
//       'CANGUHA',
//       'CATMONAN',
//       'DESAMPARADOS (POB.)',
//       'KAHAYAG',
//       'KINABAG-AN',
//       'LABUON',
//       'LAWIS',
//       'LIBORON',
//       'LO-OC',
//       'LOMBOY',
//       'LUCOB',
//       'MADANGOG',
//       'MAGTONGTONG',
//       'MANDAUG',
//       'MANTATAO',
//       'SAMPOANGON',
//       'SAN ISIDRO',
//       'SANTA CRUZ (POB.)',
//       'SOJOTON',
//       'TALISAY',
//       'TINIBGAN',
//       'TULTUGAN',
//       'ULBUJAN',
//     ],
//   },
//   CANDIJAY: {
//     province: 'BOHOL',
//     municipality: 'CANDIJAY',
//     region: 'REGION VII',
//     barangay_list: [
//       'ABIHILAN',
//       'ANOLING',
//       'BOYO-AN',
//       'CADAPDAPAN',
//       'CAMBANE',
//       'CAN-OLIN',
//       'CANAWA',
//       'COGTONG',
//       'LA UNION',
//       'LUAN',
//       'LUNGSODA-AN',
//       'MAHANGIN',
//       'PAGAHAT',
//       'PANADTARAN',
//       'PANAS',
//       'POBLACION',
//       'SAN ISIDRO',
//       'TAMBONGAN',
//       'TAWID',
//       'TUBOD (TRES ROSAS)',
//       'TUGAS',
//     ],
//   },
//   CATIGBIAN: {
//     province: 'BOHOL',
//     municipality: 'CATIGBIAN',
//     region: 'REGION VII',
//     barangay_list: [
//       'ALEGRIA',
//       'AMBUAN',
//       'BAANG',
//       'BAGTIC',
//       'BONGBONG',
//       'CAMBAILAN',
//       'CANDUMAYAO',
//       'CAUSWAGAN NORTE',
//       'HAGBUAYA',
//       'HAGUILANAN',
//       'KANG-IRAS',
//       'LIBERTAD SUR',
//       'LIBORON',
//       'MAHAYAG NORTE',
//       'MAHAYAG SUR',
//       'MAITUM',
//       'MANTASIDA',
//       'POBLACION',
//       'POBLACION WESTE',
//       'RIZAL',
//       'SINAKAYANAN',
//       'TRIPLE UNION',
//     ],
//   },
//   CORELLA: {
//     province: 'BOHOL',
//     municipality: 'CORELLA',
//     region: 'REGION VII',
//     barangay_list: [
//       'ANISLAG',
//       'CANANGCA-AN',
//       'CANAPNAPAN',
//       'CANCATAC',
//       'PANDOL',
//       'POBLACION',
//       'SAMBOG',
//       'TANDAY',
//     ],
//   },
//   DAGOHOY: {
//     province: 'BOHOL',
//     municipality: 'DAGOHOY',
//     region: 'REGION VII',
//     barangay_list: [
//       'BABAG',
//       'CAGAWASAN',
//       'CAGAWITAN',
//       'CALUASAN',
//       'CAN-OLING',
//       'CANDELARIA',
//       'ESTACA',
//       'LA ESPERANZA',
//       'MAHAYAG',
//       'MALITBOG',
//       'POBLACION',
//       'SAN MIGUEL',
//       'SAN VICENTE',
//       'SANTA CRUZ',
//       'VILLA AURORA',
//     ],
//   },
//   DANAO: {
//     province: 'BOHOL',
//     municipality: 'DANAO',
//     region: 'REGION VII',
//     barangay_list: [
//       'CABATUAN',
//       'CANTUBOD',
//       'CARBON',
//       'CONCEPCION',
//       'DAGOHOY',
//       'HIBALE',
//       'MAGTANGTANG',
//       'NAHUD',
//       'POBLACION',
//       'REMEDIOS',
//       'SAN CARLOS',
//       'SAN MIGUEL',
//       'SANTA FE',
//       'SANTO NIÑO',
//       'TABOK',
//       'TAMING',
//       'VILLA ANUNCIADO',
//     ],
//   },
//   DAUIS: {
//     province: 'BOHOL',
//     municipality: 'DAUIS',
//     region: 'REGION VII',
//     barangay_list: [
//       'BIKING',
//       'BINGAG',
//       'CATARMAN',
//       'DAO',
//       'MARIVELES',
//       'MAYACABAC',
//       'POBLACION',
//       'SAN ISIDRO (CANLONGON)',
//       'SONGCULAN',
//       'TABALONG',
//       'TINAGO',
//       'TOTOLAN',
//     ],
//   },
//   DIMIAO: {
//     province: 'BOHOL',
//     municipality: 'DIMIAO',
//     region: 'REGION VII',
//     barangay_list: [
//       'ABIHID',
//       'ALEMANIA',
//       'BAGUHAN',
//       'BAKILID',
//       'BALBALAN',
//       'BANBAN',
//       'BAUHUGAN',
//       'BILISAN',
//       'CABAGAKIAN',
//       'CABANBANAN',
//       'CADAP-AGAN',
//       'CAMBACOL',
//       'CAMBAYAON',
//       'CANHAYUPON',
//       'CANLAMBONG',
//       'CASINGAN',
//       'CATUGASAN',
//       'DATAG',
//       'GUINDAGUITAN',
//       'GUINGOYURAN',
//       'ILE',
//       'LAPSAON',
//       'LIMOKON ILAOD',
//       'LIMOKON ILAYA',
//       'LUYO',
//       'MALIJAO',
//       'OAC',
//       'PAGSA',
//       'PANGIHAWAN',
//       'PUANGYUTA',
//       'SAWANG',
//       'TANGOHAY',
//       'TAONGON CABATUAN',
//       'TAONGON CAN-ANDAM',
//       'TAWID BITAOG',
//     ],
//   },
//   DUERO: {
//     province: 'BOHOL',
//     municipality: 'DUERO',
//     region: 'REGION VII',
//     barangay_list: [
//       'ALEJAWAN',
//       'ANGILAN',
//       'ANIBONGAN',
//       'BANGWALOG',
//       'CANSUHAY',
//       'DANAO',
//       'DUAY',
//       'GUINSULARAN',
//       'IMELDA',
//       'ITUM',
//       'LANGKIS',
//       'LOBOGON',
//       'MADUA NORTE',
//       'MADUA SUR',
//       'MAMBOOL',
//       'MAWI',
//       'PAYAO',
//       'SAN ANTONIO (POB.)',
//       'SAN ISIDRO',
//       'SAN PEDRO',
//       'TAYTAY',
//     ],
//   },
//   'GARCIA HERNANDEZ': {
//     province: 'BOHOL',
//     municipality: 'GARCIA HERNANDEZ',
//     region: 'REGION VII',
//     barangay_list: [
//       'ABIJILAN',
//       'ANTIPOLO',
//       'BASIAO',
//       'CAGWANG',
//       'CALMA',
//       'CAMBUYO',
//       'CANAYAON EAST',
//       'CANAYAON WEST',
//       'CANDANAS',
//       'CANDULAO',
//       'CATMON',
//       'CAYAM',
//       'CUPA',
//       'DATAG',
//       'ESTACA',
//       'LIBERTAD',
//       'LUNGSODAAN EAST',
//       'LUNGSODAAN WEST',
//       'MALINAO',
//       'MANABA',
//       'PASONG',
//       'POBLACION EAST',
//       'POBLACION WEST',
//       'SACAON',
//       'SAMPONG',
//       'TABUAN',
//       'TOGBONGON',
//       'ULBUJAN EAST',
//       'ULBUJAN WEST',
//       'VICTORIA',
//     ],
//   },
//   GUINDULMAN: {
//     province: 'BOHOL',
//     municipality: 'GUINDULMAN',
//     region: 'REGION VII',
//     barangay_list: [
//       'BASDIO',
//       'BATO',
//       'BAYONG',
//       'BIABAS',
//       'BULAWAN',
//       'CABANTIAN',
//       'CANHAWAY',
//       'CANSIWANG',
//       'CASBU',
//       'CATUNGAWAN NORTE',
//       'CATUNGAWAN SUR',
//       'GUINACOT',
//       'GUIO-ANG',
//       'LOMBOG',
//       'MAYUGA',
//       'SAWANG (POB.)',
//       'TABAJAN (POB.)',
//       'TABUNOK',
//       'TRINIDAD',
//     ],
//   },
//   INABANGA: {
//     province: 'BOHOL',
//     municipality: 'INABANGA',
//     region: 'REGION VII',
//     barangay_list: [
//       'ANONANG',
//       'BADIANG',
//       'BAGUHAN',
//       'BAHAN',
//       'BANAHAO',
//       'BAOGO',
//       'BUGANG',
//       'CAGAWASAN',
//       'CAGAYAN',
//       'CAMBITOON',
//       'CANLINTE',
//       'CAWAYAN',
//       'COGON',
//       'CUAMING',
//       'DAGNAWAN',
//       'DAGOHOY',
//       'DAIT SUR',
//       'DATAG',
//       'FATIMA',
//       'HAMBONGAN',
//       'ILAUD (POB.)',
//       'ILAYA',
//       'ILIHAN',
//       'LAPACAN NORTE',
//       'LAPACAN SUR',
//       'LAWIS',
//       'LILOAN NORTE',
//       'LILOAN SUR',
//       'LOMBOY',
//       'LONOY CAINSICAN',
//       'LONOY ROMA',
//       'LUTAO',
//       'LUYO',
//       'MABUHAY',
//       'MARIA ROSARIO',
//       'NABUAD',
//       'NAPO',
//       'ONDOL',
//       'POBLACION',
//       'RIVERSIDE',
//       'SAA',
//       'SAN ISIDRO',
//       'SAN JOSE',
//       'SANTO NIÑO',
//       'SANTO ROSARIO',
//       'SUA',
//       'TAMBOOK',
//       'TUNGOD',
//       'U-OG',
//       'UBUJAN',
//     ],
//   },
//   JAGNA: {
//     province: 'BOHOL',
//     municipality: 'JAGNA',
//     region: 'REGION VII',
//     barangay_list: [
//       'ALEJAWAN',
//       'BALILI',
//       'BOCTOL',
//       'BUNGA ILAYA',
//       'BUNGA MAR',
//       'BUYOG',
//       'CABUNGA-AN',
//       'CALABACITA',
//       'CAMBUGASON',
//       'CAN-IPOL',
//       'CAN-UBA',
//       'CAN-UPAO',
//       'CANJULAO',
//       'CANTAGAY',
//       'CANTUYOC',
//       'FARAON',
//       'IPIL',
//       'KINAGBAAN',
//       'LACA',
//       'LARAPAN',
//       'LONOY',
//       'LOOC',
//       'MALBOG',
//       'MAYANA',
//       'NAATANG',
//       'NAUSOK',
//       'ODIONG',
//       'PAGINA',
//       'PANGDAN',
//       'POBLACION (PONDOL)',
//       'TEJERO',
//       'TUBOD MAR',
//       'TUBOD MONTE',
//     ],
//   },
//   JETAFE: {
//     province: 'BOHOL',
//     municipality: 'JETAFE',
//     region: 'REGION VII',
//     barangay_list: [
//       'ALUMAR',
//       'BANACON',
//       'BUYOG',
//       'CABASAKAN',
//       'CAMPAO OCCIDENTAL',
//       'CAMPAO ORIENTAL',
//       'CANGMUNDO',
//       'CARLOS P. GARCIA',
//       'CORTE BAUD',
//       'HANDUMON',
//       'JAGOLIAO',
//       'JANDAYAN NORTE',
//       'JANDAYAN SUR',
//       'MAHANAY (MAHANAY ISLAND)',
//       'NASINGIN',
//       'PANDANON',
//       'POBLACION',
//       'SAGUISE',
//       'SALOG',
//       'SAN JOSE',
//       'SANTO NIÑO',
//       'TAYTAY',
//       'TUGAS',
//       'TULANG',
//     ],
//   },
//   LILA: {
//     province: 'BOHOL',
//     municipality: 'LILA',
//     region: 'REGION VII',
//     barangay_list: [
//       'BANBAN',
//       'BONKOKAN ILAYA',
//       'BONKOKAN UBOS',
//       'CALVARIO',
//       'CANDULANG',
//       'CATUGASAN',
//       'CAYUPO',
//       'COGON',
//       'JAMBAWAN',
//       'LA FORTUNA',
//       'LOMANOY',
//       'MACALINGAN',
//       'MALINAO EAST',
//       'MALINAO WEST',
//       'NAGSULAY',
//       'POBLACION',
//       'TAUG',
//       'TIGUIS',
//     ],
//   },
//   LOAY: {
//     province: 'BOHOL',
//     municipality: 'LOAY',
//     region: 'REGION VII',
//     barangay_list: [
//       'AGAPE',
//       'ALEGRIA NORTE',
//       'ALEGRIA SUR',
//       'BONBON',
//       'BOTOC OCCIDENTAL',
//       'BOTOC ORIENTAL',
//       'CALVARIO',
//       'CONCEPCION',
//       'HINAWANAN',
//       'LAS SALINAS NORTE',
//       'LAS SALINAS SUR',
//       'PALO',
//       'POBLACION IBABAO',
//       'POBLACION UBOS',
//       'SAGNAP',
//       'TAMBANGAN',
//       'TANGCASAN NORTE',
//       'TANGCASAN SUR',
//       'TAYONG OCCIDENTAL',
//       'TAYONG ORIENTAL',
//       'TOCDOG DACU',
//       'TOCDOG ILAYA',
//       'VILLALIMPIA',
//       'YANANGAN',
//     ],
//   },
//   LOBOC: {
//     province: 'BOHOL',
//     municipality: 'LOBOC',
//     region: 'REGION VII',
//     barangay_list: [
//       'AGAPE',
//       'ALEGRIA',
//       'BAGUMBAYAN',
//       'BAHIAN',
//       'BONBON LOWER',
//       'BONBON UPPER',
//       'BUENAVISTA',
//       'BUGHO',
//       'CABADIANGAN',
//       'CALUNASAN NORTE',
//       'CALUNASAN SUR',
//       'CAMAYAAN',
//       'CAMBANCE',
//       'CANDABONG',
//       'CANDASAG',
//       'CANLASID',
//       'GON-OB',
//       'GOTOZON',
//       'JIMILIAN',
//       'OY',
//       'POBLACION ONDOL',
//       'POBLACION SAWANG',
//       'QUINOGUITAN',
//       'TAYTAY',
//       'TIGBAO',
//       'UGPONG',
//       'VALLADOLID',
//       'VILLAFLOR',
//     ],
//   },
//   LOON: {
//     province: 'BOHOL',
//     municipality: 'LOON',
//     region: 'REGION VII',
//     barangay_list: [
//       'AGSOSO',
//       'BADBAD OCCIDENTAL',
//       'BADBAD ORIENTAL',
//       'BAGACAY KATIPUNAN',
//       'BAGACAY KAWAYAN',
//       'BAGACAY SAONG',
//       'BAHI',
//       'BASAC',
//       'BASDACU',
//       'BASDIO',
//       'BIASONG',
//       'BONGCO',
//       'BUGHO',
//       'CABACONGAN',
//       'CABADUG',
//       'CABUG',
//       'CALAYUGAN NORTE',
//       'CALAYUGAN SUR',
//       'CAMBAQUIZ',
//       'CAMPATUD',
//       'CANDAIGAN',
//       'CANHANGDON OCCIDENTAL',
//       'CANHANGDON ORIENTAL',
//       'CANIGAAN',
//       'CANMAAG',
//       'CANMANOC',
//       'CANSUAGWIT',
//       'CANSUBAYON',
//       'CANTAM-IS BAGO',
//       'CANTAM-IS BASLAY',
//       'CANTAONGON',
//       'CANTUMOCAD',
//       'CATAGBACAN HANDIG',
//       'CATAGBACAN NORTE',
//       'CATAGBACAN SUR',
//       'COGON NORTE (POB.)',
//       'COGON SUR',
//       'CUASI',
//       'GENOMOAN',
//       'LINTUAN',
//       'LOOC',
//       'MOCPOC NORTE',
//       'MOCPOC SUR',
//       'MOTO NORTE (POB.)',
//       'MOTO SUR (POB.)',
//       'NAGTUANG',
//       'NAPO (POB.)',
//       'NUEVA VIDA',
//       'PANANGQUILON',
//       'PANTUDLAN',
//       'PIG-OT',
//       'PONDOL',
//       'QUINOBCOBAN',
//       'SONDOL',
//       'SONG-ON',
//       'TALISAY',
//       'TAN-AWAN',
//       'TANGNAN',
//       'TAYTAY',
//       'TICUGAN',
//       'TIWI',
//       'TONTONAN',
//       'TUBODACU',
//       'TUBODIO',
//       'TUBUAN',
//       'UBAYON',
//       'UBOJAN',
//     ],
//   },
//   MARIBOJOC: {
//     province: 'BOHOL',
//     municipality: 'MARIBOJOC',
//     region: 'REGION VII',
//     barangay_list: [
//       'AGAHAY',
//       'ALIGUAY',
//       'ANISLAG',
//       'BAYACABAC',
//       'BOOD',
//       'BUSAO',
//       'CABAWAN',
//       'CANDAVID',
//       'DIPATLONG',
//       'GUIWANON',
//       'JANDIG',
//       'LAGTANGON',
//       'LINCOD',
//       'PAGNITOAN',
//       'POBLACION',
//       'PUNSOD',
//       'PUNTA CRUZ',
//       'SAN ISIDRO',
//       'SAN ROQUE (AGHAO)',
//       'SAN VICENTE',
//       'TINIBGAN',
//       'TORIL',
//     ],
//   },
//   PANGLAO: {
//     province: 'BOHOL',
//     municipality: 'PANGLAO',
//     region: 'REGION VII',
//     barangay_list: [
//       'BIL-ISAN',
//       'BOLOD',
//       'DANAO',
//       'DOLJO',
//       'LIBAONG',
//       'LOOC',
//       'LOURDES',
//       'POBLACION',
//       'TANGNAN',
//       'TAWALA',
//     ],
//   },
//   'PRES. CARLOS P. GARCIA (PITOGO)': {
//     province: 'BOHOL',
//     municipality: 'PRES. CARLOS P. GARCIA (PITOGO)',
//     region: 'REGION VII',
//     barangay_list: [
//       'AGUINING',
//       'BASIAO',
//       'BAUD',
//       'BAYOG',
//       'BOGO',
//       'BONBONON',
//       'BUTAN',
//       'CAMPAMANOG',
//       'CANMANGAO',
//       'GAUS',
//       'KABANGKALAN',
//       'LAPINIG',
//       'LIPATA',
//       'POBLACION',
//       'POPOO',
//       'SAGUISE',
//       'SAN JOSE (TAWID)',
//       'SAN VICENTE',
//       'SANTO ROSARIO',
//       'TILMOBO',
//       'TUGAS',
//       'TUGNAO',
//       'VILLA MILAGROSA',
//     ],
//   },
//   'SAGBAYAN (BORJA)': {
//     province: 'BOHOL',
//     municipality: 'SAGBAYAN (BORJA)',
//     region: 'REGION VII',
//     barangay_list: [
//       'CALANGAHAN',
//       'CANMANO',
//       'CANMAYA CENTRO',
//       'CANMAYA DIOT',
//       'DAGNAWAN',
//       'KABASACAN',
//       'KAGAWASAN',
//       'KATIPUNAN',
//       'LANGTAD',
//       'LIBERTAD NORTE',
//       'LIBERTAD SUR',
//       'MANTALONGON',
//       'POBLACION',
//       'SAGBAYAN SUR',
//       'SAN AGUSTIN',
//       'SAN ANTONIO',
//       'SAN ISIDRO',
//       'SAN RAMON',
//       'SAN ROQUE',
//       'SAN VICENTE NORTE',
//       'SAN VICENTE SUR',
//       'SANTA CATALINA',
//       'SANTA CRUZ',
//       'UBOJAN',
//     ],
//   },
//   SEVILLA: {
//     province: 'BOHOL',
//     municipality: 'SEVILLA',
//     region: 'REGION VII',
//     barangay_list: [
//       'BAYAWAHAN',
//       'CABANCALAN',
//       'CALINGA-AN',
//       'CALINGINAN NORTE',
//       'CALINGINAN SUR',
//       'CAMBAGUI',
//       'EWON',
//       'GUINOB-AN',
//       'LAGTANGAN',
//       'LICOLICO',
//       'LOBGOB',
//       'MAGSAYSAY',
//       'POBLACION',
//     ],
//   },
//   'SIERRA BULLONES': {
//     province: 'BOHOL',
//     municipality: 'SIERRA BULLONES',
//     region: 'REGION VII',
//     barangay_list: [
//       'ABACHANAN',
//       'ANIBONGAN',
//       'BUGSOC',
//       'CAHAYAG',
//       'CANLANGIT',
//       'CANTA-UB',
//       'CASILAY',
//       'DANICOP',
//       'DUSITA',
//       'LA UNION',
//       'LATABAN',
//       'MAGSAYSAY',
//       'MAN-OD',
//       'MATIN-AO',
//       'POBLACION',
//       'SALVADOR',
//       'SAN AGUSTIN',
//       'SAN ISIDRO',
//       'SAN JOSE',
//       'SAN JUAN',
//       'SANTA CRUZ',
//       'VILLA GARCIA',
//     ],
//   },
//   SIKATUNA: {
//     province: 'BOHOL',
//     municipality: 'SIKATUNA',
//     region: 'REGION VII',
//     barangay_list: [
//       'ABUCAY NORTE',
//       'ABUCAY SUR',
//       'BADIANG',
//       'BAHAYBAHAY',
//       'CAMBUAC NORTE',
//       'CAMBUAC SUR',
//       'CANAGONG',
//       'LIBJO',
//       'POBLACION I',
//       'POBLACION II',
//     ],
//   },
//   'TAGBILARAN': {
//     province: 'BOHOL',
//     municipality: 'TAGBILARAN',
//     region: 'REGION VII',
//     barangay_list: [
//       'BOOL',
//       'BOOY',
//       'CABAWAN',
//       'COGON',
//       'DAMPAS',
//       'DAO',
//       'MANGA',
//       'MANSASA',
//       'POBLACION I',
//       'POBLACION II',
//       'POBLACION III',
//       'SAN ISIDRO',
//       'TALOTO',
//       'TIPTIP',
//       'UBUJAN',
//     ],
//   },
//   TALIBON: {
//     province: 'BOHOL',
//     municipality: 'TALIBON',
//     region: 'REGION VII',
//     barangay_list: [
//       'BAGACAY',
//       'BALINTAWAK',
//       'BURGOS',
//       'BUSALIAN',
//       'CALITUBAN',
//       'CATABAN',
//       'GUINDACPAN',
//       'MAGSAYSAY',
//       'MAHANAY',
//       'NOCNOCAN',
//       'POBLACION',
//       'RIZAL',
//       'SAG',
//       'SAN AGUSTIN',
//       'SAN CARLOS',
//       'SAN FRANCISCO',
//       'SAN ISIDRO',
//       'SAN JOSE',
//       'SAN PEDRO',
//       'SAN ROQUE',
//       'SANTO NIÑO',
//       'SIKATUNA',
//       'SUBA',
//       'TANGHALIGUE',
//       'ZAMORA',
//     ],
//   },
//   TRINIDAD: {
//     province: 'BOHOL',
//     municipality: 'TRINIDAD',
//     region: 'REGION VII',
//     barangay_list: [
//       'BANLASAN',
//       'BONGBONG',
//       'CATOOGAN',
//       'GUINOBATAN',
//       'HINLAYAGAN ILAUD',
//       'HINLAYAGAN ILAYA',
//       'KAUSWAGAN',
//       'KINAN-OAN',
//       'LA UNION',
//       'LA VICTORIA',
//       'MABUHAY CABIGOHAN',
//       'MAHAGBU',
//       'MANUEL M. ROXAS',
//       'POBLACION',
//       'SAN ISIDRO',
//       'SAN VICENTE',
//       'SANTO TOMAS',
//       'SOOM',
//       'TAGUM NORTE',
//       'TAGUM SUR',
//     ],
//   },
//   TUBIGON: {
//     province: 'BOHOL',
//     municipality: 'TUBIGON',
//     region: 'REGION VII',
//     barangay_list: [
//       'BAGONGBANWA',
//       'BANLASAN',
//       'BATASAN (BATASAN ISLAND)',
//       'BILANGBILANGAN (BILANGBILANGAN ISLAND)',
//       'BOSONGON',
//       'BUENOS AIRES',
//       'BUNACAN',
//       'CABULIHAN',
//       'CAHAYAG',
//       'CAWAYANAN',
//       'CENTRO (POB.)',
//       'GENONOCAN',
//       'GUIWANON',
//       'ILIHAN NORTE',
//       'ILIHAN SUR',
//       'LIBERTAD',
//       'MACAAS',
//       'MATABAO',
//       'MOCABOC ISLAND',
//       'PANADTARAN',
//       'PANAYTAYON',
//       'PANDAN',
//       'PANGAPASAN (PANGAPASAN ISLAND)',
//       'PINAYAGAN NORTE',
//       'PINAYAGAN SUR',
//       'POOC OCCIDENTAL (POB.)',
//       'POOC ORIENTAL (POB.)',
//       'POTOHAN',
//       'TALENCERAS',
//       'TAN-AWAN',
//       'TINANGNAN',
//       'UBAY ISLAND',
//       'UBOJAN',
//       'VILLANUEVA',
//     ],
//   },
//   UBAY: {
//     province: 'BOHOL',
//     municipality: 'UBAY',
//     region: 'REGION VII',
//     barangay_list: [
//       'ACHILA',
//       'BAY-ANG',
//       'BENLIW',
//       'BIABAS',
//       'BONGBONG',
//       'BOOD',
//       'BUENAVISTA',
//       'BULILIS',
//       'CAGTING',
//       'CALANGGAMAN',
//       'CALIFORNIA',
//       'CAMALI-AN',
//       'CAMAMBUGAN',
//       'CASATE',
//       'CUYA',
//       'FATIMA',
//       'GABI',
//       'GOVERNOR BOYLES',
//       'GUINTABO-AN',
//       'HAMBABAURAN',
//       'HUMAYHUMAY',
//       'ILIHAN',
//       'IMELDA',
//       'JUAGDAN',
//       'KATARUNGAN',
//       'LOMANGOG',
//       'LOS ANGELES',
//       'PAG-ASA',
//       'PANGPANG',
//       'POBLACION',
//       'SAN FRANCISCO',
//       'SAN ISIDRO',
//       'SAN PASCUAL',
//       'SAN VICENTE',
//       'SENTINILA',
//       'SINANDIGAN',
//       'TAPAL',
//       'TAPON',
//       'TINTINAN',
//       'TIPOLO',
//       'TUBOG',
//       'TUBORAN',
//       'UNION',
//       'VILLA TERESITA',
//     ],
//   },
//   VALENCIA: {
//     province: 'BOHOL',
//     municipality: 'VALENCIA',
//     region: 'REGION VII',
//     barangay_list: [
//       'ADLAWAN',
//       'ANAS',
//       'ANONANG',
//       'ANOYON',
//       'BALINGASAO',
//       'BANDERAHAN (UPPER GINOPOLAN)',
//       'BOTONG',
//       'BUYOG',
//       'CANDUAO OCCIDENTAL',
//       'CANDUAO ORIENTAL',
//       'CANLUSONG',
//       'CANMANICO',
//       'CANSIBAO',
//       'CATUG-A',
//       'CUTCUTAN',
//       'DANAO',
//       'GENOVEVA',
//       'GINOPOLAN (GINOPOLAN PROPER)',
//       'LA VICTORIA',
//       'LANTANG',
//       'LIMOCON',
//       'LOCTOB',
//       'MAGSAYSAY',
//       'MARAWIS',
//       'MAUBO',
//       'NAILO',
//       'OMJON',
//       'PANGI-AN',
//       'POBLACION OCCIDENTAL',
//       'POBLACION ORIENTAL',
//       'SIMANG',
//       'TAUG',
//       'TAUSION',
//       'TAYTAY',
//       'TICUM',
//     ],
//   },
//   ALCOY: {
//     province: 'CEBU',
//     municipality: 'ALCOY',
//     region: 'REGION VII',
//     barangay_list: [
//       'ATABAY',
//       'DAAN-LUNGSOD',
//       'GUIWANG',
//       'NUG-AS',
//       'PASOL',
//       'POBLACION',
//       'PUGALO',
//       'SAN AGUSTIN',
//     ],
//   },
//   ALOGUINSAN: {
//     province: 'CEBU',
//     municipality: 'ALOGUINSAN',
//     region: 'REGION VII',
//     barangay_list: [
//       'ANGILAN',
//       'BOJO',
//       'BONBON',
//       'ESPERANZA',
//       'KANDINGAN',
//       'KANTABOGON',
//       'KAWASAN',
//       'OLANGO',
//       'POBLACION',
//       'PUNAY',
//       'ROSARIO',
//       'SAKSAK',
//       'TAMPA-AN',
//       'TOYOKON',
//       'ZARAGOSA',
//     ],
//   },
//   ARGAO: {
//     province: 'CEBU',
//     municipality: 'ARGAO',
//     region: 'REGION VII',
//     barangay_list: [
//       'ALAMBIJUD',
//       'ANAJAO',
//       'APO',
//       'BALAAS',
//       'BALISONG',
//       'BINLOD',
//       'BOGO',
//       'BUG-OT',
//       'BULASA',
//       'BUTONG',
//       'CALAGASAN',
//       'CANBANTUG',
//       'CANBANUA',
//       'CANSUJE',
//       'CAPIO-AN',
//       'CASAY',
//       'CATANG',
//       'COLAWIN',
//       'CONALUM',
//       'GUIWANON',
//       'GUTLANG',
//       'JAMPANG',
//       'JOMGAO',
//       'LAMACAN',
//       'LANGTAD',
//       'LANGUB',
//       'LAPAY',
//       'LENGIGON',
//       'LINUT-OD',
//       'MABASA',
//       'MANDILIKIT',
//       'MOMPELLER',
//       'PANADTARAN',
//       'POBLACION',
//       'SUA',
//       'SUMAGUAN',
//       'TABAYAG',
//       'TALAGA',
//       'TALAYTAY',
//       'TALO-OT',
//       'TIGUIB',
//       'TULANG',
//       'TULIC',
//       'UBAUB',
//       'USMAD',
//     ],
//   },
//   ASTURIAS: {
//     province: 'CEBU',
//     municipality: 'ASTURIAS',
//     region: 'REGION VII',
//     barangay_list: [
//       'AGBANGA',
//       'AGTUGOP',
//       'BAGO',
//       'BAIRAN',
//       'BANBAN',
//       'BAYE',
//       'BOG-O',
//       'KALUANGAN',
//       'LANAO',
//       'LANGUB',
//       'LOOC NORTE',
//       'LUNAS',
//       'MAGCALAPE',
//       'MANGUIAO',
//       'NEW BAGO',
//       'OWAK',
//       'POBLACION',
//       'SAKSAK',
//       'SAN ISIDRO',
//       'SAN ROQUE',
//       'SANTA LUCIA',
//       'SANTA RITA',
//       'TAG-AMAKAN',
//       'TAGBUBONGA',
//       'TUBIGAGMANOK',
//       'TUBOD',
//       'UBOGON',
//     ],
//   },
//   BADIAN: {
//     province: 'CEBU',
//     municipality: 'BADIAN',
//     region: 'REGION VII',
//     barangay_list: [
//       'ALAWIJAO',
//       'BALHAAN',
//       'BANHIGAN',
//       'BASAK',
//       'BASIAO',
//       'BATO',
//       'BUGAS',
//       'CALANGCANG',
//       'CANDIIS',
//       'DAGATAN',
//       'DOBDOB',
//       'GINABLAN',
//       'LAMBUG',
//       'MALABAGO',
//       'MALHIAO',
//       'MANDUYONG',
//       'MATUTINAO',
//       'PATONG',
//       'POBLACION',
//       'SANLAGAN',
//       'SANTICON',
//       'SOHOTON',
//       'SULSUGAN',
//       'TALAYONG',
//       'TAYTAY',
//       'TIGBAO',
//       'TIGUIB',
//       'TUBOD',
//       'ZARAGOSA',
//     ],
//   },
//   BALAMBAN: {
//     province: 'CEBU',
//     municipality: 'BALAMBAN',
//     region: 'REGION VII',
//     barangay_list: [
//       'ABUCAYAN',
//       'ALIWANAY',
//       'ARPILI',
//       'BALIWAGAN (POB.)',
//       'BAYONG',
//       'BIASONG',
//       'BUANOY',
//       'CABAGDALAN',
//       'CABASIANGAN',
//       'CAMBUHAWE',
//       'CANSOMOROY',
//       'CANTIBAS',
//       'CANTUOD',
//       'DUANGAN',
//       'GAAS',
//       'GINATILAN',
//       'HINGATMONAN',
//       'LAMESA',
//       'LIKI',
//       'LUCA',
//       'MATUN-OG',
//       'NANGKA',
//       'PONDOL',
//       'PRENZA',
//       'SANTA CRUZ-SANTO NIÑO (POB.)',
//       'SINGSING',
//       'SUNOG',
//       'VITO',
//     ],
//   },
//   BANTAYAN: {
//     province: 'CEBU',
//     municipality: 'BANTAYAN',
//     region: 'REGION VII',
//     barangay_list: [
//       'ATOP-ATOP',
//       'BAIGAD',
//       'BANTIGUE (POB.)',
//       'BAOD',
//       'BINAOBAO (POB.)',
//       'BOTIGUES',
//       'DOONG',
//       'GUIWANON',
//       'HILOTONGAN',
//       'KABAC',
//       'KABANGBANG',
//       'KAMPINGGANON',
//       'KANGKAIBE',
//       'LIPAYRAN',
//       'LUYONGBAYBAY',
//       'MOJON',
//       'OBO-OB',
//       'PATAO',
//       'PUTIAN',
//       'SILLON',
//       'SUBA (POB.)',
//       'SULANGAN',
//       'SUNGKO',
//       'TAMIAO',
//       'TICAD (POB.)',
//     ],
//   },
//   BARILI: {
//     province: 'CEBU',
//     municipality: 'BARILI',
//     region: 'REGION VII',
//     barangay_list: [
//       'AZUCENA',
//       'BAGAKAY',
//       'BALAO',
//       'BOLOCBOLOC',
//       'BUDBUD',
//       'BUGTONG KAWAYAN',
//       'CABCABAN',
//       'CAGAY',
//       'CAMPANGGA',
//       'CANDUGAY',
//       'DAKIT',
//       'GILOCTOG',
//       'GIWANON',
//       'GUIBUANGAN',
//       'GUNTING',
//       'HILASGASAN',
//       'JAPITAN',
//       'KALUBIHAN',
//       'KANGDAMPAS',
//       'LUHOD',
//       'LUPO',
//       'LUYO',
//       'MAGHANOY',
//       'MAIGANG',
//       'MALOLOS',
//       'MANTALONGON',
//       'MANTAYUPAN',
//       'MAYANA',
//       'MINOLOS',
//       'NABUNTURAN',
//       'NASIPIT',
//       'PANCIL',
//       'PANGPANG',
//       'PARIL',
//       'PATUPAT',
//       'POBLACION',
//       'SAN RAFAEL',
//       'SANTA ANA',
//       'SAYAW',
//       'TAL-OT',
//       'TUBOD',
//       'VITO',
//     ],
//   },
//   'BOGO': {
//     province: 'CEBU',
//     municipality: 'BOGO',
//     region: 'REGION VII',
//     barangay_list: [
//       'ANONANG NORTE',
//       'ANONANG SUR',
//       'BANBAN',
//       'BINABAG',
//       'BUNGTOD (POB.)',
//       'CARBON (POB.)',
//       'CAYANG',
//       'COGON (POB.)',
//       'DAKIT',
//       'DON PEDRO RODRIGUEZ',
//       'GAIRAN',
//       'GUADALUPE',
//       'LA PAZ',
//       'LA PURISIMA CONCEPCION (POB.)',
//       'LIBERTAD',
//       'LOURDES (POB.)',
//       'MALINGIN',
//       'MARANGOG',
//       'NAILON',
//       'ODLOT',
//       'PANDAN (PANDAN HEIGHTS)',
//       'POLAMBATO',
//       'SAMBAG (POB.)',
//       'SAN VICENTE (POB.)',
//       'SANTO NIÑO',
//       'SANTO ROSARIO (POB.)',
//       'SIOCON',
//       'SUDLONON',
//       'TAYTAYAN',
//     ],
//   },
//   BOLJOON: {
//     province: 'CEBU',
//     municipality: 'BOLJOON',
//     region: 'REGION VII',
//     barangay_list: [
//       'ARBOR',
//       'BACLAYAN',
//       'EL PARDO',
//       'GRANADA',
//       'LOWER BECERRIL',
//       'LUNOP',
//       'NANGKA',
//       'POBLACION',
//       'SAN ANTONIO',
//       'SOUTH GRANADA',
//       'UPPER BECERRIL',
//     ],
//   },
//   BORBON: {
//     province: 'CEBU',
//     municipality: 'BORBON',
//     region: 'REGION VII',
//     barangay_list: [
//       'BAGACAY',
//       'BILI',
//       'BINGAY',
//       'BONGDO',
//       'BONGDO GUA',
//       'BONGOYAN',
//       'CADARUHAN',
//       'CAJEL',
//       'CAMPUSONG',
//       'CLAVERA',
//       'DON GREGORIO ANTIGUA (TAYTAYAN)',
//       'LAAW',
//       'LUGO',
//       'MANAGASE',
//       'POBLACION',
//       'SAGAY',
//       'SAN JOSE',
//       'TABUNAN',
//       'TAGNUCAN',
//     ],
//   },
//   'CARCAR': {
//     province: 'CEBU',
//     municipality: 'CARCAR',
//     region: 'REGION VII',
//     barangay_list: [
//       'BOLINAWAN',
//       'BUENAVISTA',
//       'CALIDNGAN',
//       'CAN-ASUJAN',
//       'GUADALUPE',
//       'LIBURON',
//       'NAPO',
//       'OCANA',
//       'PERRELOS',
//       'POBLACION I',
//       'POBLACION II',
//       'POBLACION III',
//       'TUYOM',
//       'VALENCIA',
//       'VALLADOLID',
//     ],
//   },
//   CATMON: {
//     province: 'CEBU',
//     municipality: 'CATMON',
//     region: 'REGION VII',
//     barangay_list: [
//       'AGSUWAO',
//       'AMANCION',
//       'ANAPOG',
//       'BACTAS',
//       'BASAK',
//       'BINONGKALAN',
//       'BONGYAS',
//       'CABUNGAAN',
//       'CAMBANGKAYA',
//       'CAN-IBUANG',
//       'CATMONDAAN',
//       'CORAZON (POB.)',
//       'DUYAN',
//       'FLORES (POB.)',
//       'GINABUCAN',
//       'MACAAS',
//       'PANALIPAN',
//       'SAN JOSE POB. (CATADMAN)',
//       'TABILI',
//       'TINABYONAN',
//     ],
//   },
//   'CEBU': {
//     province: 'CEBU',
//     municipality: 'CEBU',
//     region: 'REGION VII',
//     barangay_list: [
//       'ADLAON',
//       'AGSUNGOT',
//       'APAS',
//       'BABAG',
//       'BACAYAN',
//       'BANILAD',
//       'BASAK PARDO',
//       'BASAK SAN NICOLAS',
//       'BINALIW',
//       'BONBON',
//       'BUDLA-AN (POB.)',
//       'BUHISAN',
//       'BULACAO',
//       'BUOT-TAUP PARDO',
//       'BUSAY (POB.)',
//       'CALAMBA',
//       'CAMBINOCOT',
//       'CAMPUTHAW (POB.)',
//       'CAPITOL SITE (POB.)',
//       'CARRETA',
//       'CENTRAL (POB.)',
//       'COGON PARDO',
//       'COGON RAMOS (POB.)',
//       'DAY-AS',
//       'DULJO (POB.)',
//       'ERMITA (POB.)',
//       'GUADALUPE',
//       'GUBA',
//       'HIPPODROMO',
//       'INAYAWAN',
//       'KALUBIHAN (POB.)',
//       'KALUNASAN',
//       'KAMAGAYAN (POB.)',
//       'KASAMBAGAN',
//       'KINASANG-AN PARDO',
//       'LABANGON',
//       'LAHUG (POB.)',
//       'LOREGA (LOREGA SAN MIGUEL)',
//       'LUSARAN',
//       'LUZ',
//       'MABINI',
//       'MABOLO',
//       'MALUBOG',
//       'MAMBALING',
//       'PAHINA CENTRAL (POB.)',
//       'PAHINA SAN NICOLAS',
//       'PAMUTAN',
//       'PARDO (POB.)',
//       'PARI-AN',
//       'PARIL',
//       'PASIL',
//       'PIT-OS',
//       'PULANGBATO',
//       'PUNG-OL-SIBUGAY',
//       'PUNTA PRINCESA',
//       'QUIOT PARDO',
//       'SAMBAG I (POB.)',
//       'SAMBAG II (POB.)',
//       'SAN ANTONIO (POB.)',
//       'SAN JOSE',
//       'SAN NICOLAS CENTRAL',
//       'SAN ROQUE (CIUDAD)',
//       'SANTA CRUZ (POB.)',
//       'SAPANGDAKU',
//       'SAWANG CALERO (POB.)',
//       'SINSIN',
//       'SIRAO',
//       'SUBA POB. (SUBA SAN NICOLAS)',
//       'SUDLON I',
//       'SUDLON II',
//       'T. PADILLA',
//       'TABUNAN',
//       'TAGBAO',
//       'TALAMBAN',
//       'TAPTAP',
//       'TEJERO (VILLA GONZALO)',
//       'TINAGO',
//       'TISA',
//       'TO-ONG PARDO',
//       'ZAPATERA',
//     ],
//   },
//   CONSOLACION: {
//     province: 'CEBU',
//     municipality: 'CONSOLACION',
//     region: 'REGION VII',
//     barangay_list: [
//       'CABANGAHAN',
//       'CANSAGA',
//       'CASILI',
//       'DANGLAG',
//       'GARING',
//       'JUGAN',
//       'LAMAC',
//       'LANIPGA',
//       'NANGKA',
//       'PANAS',
//       'PANOYPOY',
//       'PITOGO',
//       'POBLACION OCCIDENTAL',
//       'POBLACION ORIENTAL',
//       'POLOG',
//       'PULPOGAN',
//       'SACSAC',
//       'TAYUD',
//       'TILHAONG',
//       'TOLOTOLO',
//       'TUGBONGAN',
//     ],
//   },
//   CORDOBA: {
//     province: 'CEBU',
//     municipality: 'CORDOBA',
//     region: 'REGION VII',
//     barangay_list: [
//       'ALEGRIA',
//       'BANGBANG',
//       'BUAGSONG',
//       'CATARMAN',
//       'COGON',
//       'DAPITAN',
//       'DAY-AS',
//       'GABI',
//       'GILUTONGAN',
//       'IBABAO',
//       'PILIPOG',
//       'POBLACION',
//       'SAN MIGUEL',
//     ],
//   },
//   DAANBANTAYAN: {
//     province: 'CEBU',
//     municipality: 'DAANBANTAYAN',
//     region: 'REGION VII',
//     barangay_list: [
//       'AGUHO',
//       'BAGAY',
//       'BAKHAWAN',
//       'BATERIA',
//       'BITOON',
//       'CALAPE',
//       'CARNAZA',
//       'DALINGDING',
//       'LANAO',
//       'LOGON',
//       'MALBAGO',
//       'MALINGIN',
//       'MAYA',
//       'PAJO',
//       'PAYPAY',
//       'POBLACION',
//       'TALISAY',
//       'TAPILON',
//       'TINUBDAN',
//       'TOMINJAO',
//     ],
//   },
//   DALAGUETE: {
//     province: 'CEBU',
//     municipality: 'DALAGUETE',
//     region: 'REGION VII',
//     barangay_list: [
//       'ABLAYAN',
//       'BABAYONGAN',
//       'BALUD',
//       'BANHIGAN',
//       'BULAK',
//       'CALERIOHAN',
//       'CALIONGAN',
//       'CASAY',
//       'CATOLOHAN',
//       'CAWAYAN',
//       'CONSOLACION',
//       'CORO',
//       'DUGYAN',
//       'DUMALAN',
//       'JOLOMAYNON',
//       'LANAO',
//       'LANGKAS',
//       'LUMBANG',
//       'MALONES',
//       'MALORAY',
//       'MANANGGAL',
//       'MANLAPAY',
//       'MANTALONGON',
//       'NALHUB',
//       'OBO',
//       'OBONG',
//       'PANAS',
//       'POBLACION',
//       'SACSAC',
//       'SALUG',
//       'TABON',
//       'TAPUN',
//       'TUBA',
//     ],
//   },
//   'DANAO': {
//     province: 'CEBU',
//     municipality: 'DANAO',
//     region: 'REGION VII',
//     barangay_list: [
//       'BALIANG',
//       'BAYABAS',
//       'BINALIW',
//       'CABUNGAHAN',
//       'CAGAT-LAMAC',
//       'CAHUMAYAN',
//       'CAMBANAY',
//       'CAMBUBHO',
//       'COGON-CRUZ',
//       'DANASAN',
//       'DUNGGA',
//       'DUNGGOAN',
//       'GUINACOT',
//       'GUINSAY',
//       'IBO',
//       'LANGOSIG',
//       'LAWAAN',
//       'LICOS',
//       'LOOC',
//       'MAGTAGOBTOB',
//       'MALAPOC',
//       'MANLAYAG',
//       'MANTIJA',
//       'MASABA',
//       'MASLOG',
//       'NANGKA',
//       'OGUIS',
//       'PILI',
//       'POBLACION',
//       'QUISOL',
//       'SABANG',
//       'SACSAC',
//       'SANDAYONG NORTE',
//       'SANDAYONG SUR',
//       'SANTA ROSA',
//       'SANTICAN',
//       'SIBACAN',
//       'SUBA',
//       'TABOC',
//       'TAYTAY',
//       'TOGONON',
//       'TUBURAN SUR',
//     ],
//   },
//   DUMANJUG: {
//     province: 'CEBU',
//     municipality: 'DUMANJUG',
//     region: 'REGION VII',
//     barangay_list: [
//       'BALAYGTIKI',
//       'BITOON',
//       'BULAK',
//       'BULLOGAN',
//       'CALABOON',
//       'CAMBOANG',
//       'CANDABONG',
//       'COGON',
//       'COTCOTON',
//       'DOLDOL',
//       'ILAYA (POB.)',
//       'KABALAASNAN',
//       'KABATBATAN',
//       'KAMBANOG',
//       'KANG-ACTOL',
//       'KANGHALO',
//       'KANGHUMAOD',
//       'KANGUHA',
//       'KANTANGKAS',
//       'KANYUKO',
//       'KOLABTINGON',
//       'LAMAK',
//       'LAWAAN',
//       'LIONG',
//       'MANLAPAY',
//       'MASA',
//       'MATALAO',
//       'PACULOB',
//       'PANLAAN',
//       'PAWA',
//       'POBLACION CENTRAL',
//       'POBLACION LOOC',
//       'POBLACION SIMA',
//       'TANGIL',
//       'TAPON',
//       'TUBOD-BITOON',
//       'TUBOD-DUGOAN',
//     ],
//   },
//   GINATILAN: {
//     province: 'CEBU',
//     municipality: 'GINATILAN',
//     region: 'REGION VII',
//     barangay_list: [
//       'ANAO',
//       'CAGSING',
//       'CALABAWAN',
//       'CAMBAGTE',
//       'CAMPISONG',
//       'CANORONG',
//       'GUIWANON',
//       'LOOC',
//       'MALATBO',
//       'MANGACO',
//       'PALANAS',
//       'POBLACION',
//       'SALAMANCA',
//       'SAN ROQUE',
//     ],
//   },
//   'LAPU-LAPU CITY (OPON)': {
//     province: 'CEBU',
//     municipality: 'LAPU-LAPU CITY (OPON)',
//     region: 'REGION VII',
//     barangay_list: [
//       'AGUS',
//       'BABAG',
//       'BANKAL',
//       'BARING',
//       'BASAK',
//       'BUAYA',
//       'CALAWISAN',
//       'CANJULAO',
//       'CAUBIAN',
//       'CAW-OY',
//       'CAWHAGAN',
//       'GUN-OB',
//       'IBO',
//       'LOOC',
//       'MACTAN',
//       'MARIBAGO',
//       'MARIGONDON',
//       'PAJAC',
//       'PAJO',
//       'PANGAN-AN',
//       'POBLACION',
//       'PUNTA ENGAÑO',
//       'PUSOK',
//       'SABANG',
//       'SAN VICENTE',
//       'SANTA ROSA',
//       'SUBABASBAS',
//       'TALIMA',
//       'TINGO',
//       'TUNGASAN',
//     ],
//   },
//   LILOAN: {
//     province: 'SOUTHERN LEYTE',
//     municipality: 'LILOAN',
//     region: 'REGION VIII',
//     barangay_list: [
//       'AMAGA',
//       'ANILAO',
//       'BAHAY',
//       'CAGBUNGALON',
//       'CALIAN',
//       'CALIGANGAN',
//       'CANDAYUMAN',
//       'CATIG',
//       'ESTELA',
//       'FATIMA',
//       'GUD-AN',
//       'GUINTOYLAN',
//       'HIMAYANGAN',
//       'ILAG',
//       'MAGAUPAS',
//       'MALANGSA',
//       'MOLOPOLO',
//       'PANDAN',
//       'POBLACION',
//       'PRES. QUEZON (MAUGOC)',
//       'PRESIDENT ROXAS',
//       'SAN ISIDRO',
//       'SAN ROQUE',
//       'TABUGON',
//     ],
//   },
//   MADRIDEJOS: {
//     province: 'CEBU',
//     municipality: 'MADRIDEJOS',
//     region: 'REGION VII',
//     barangay_list: [
//       'BUNAKAN',
//       'KANGWAYAN',
//       'KAONGKOD',
//       'KODIA',
//       'MAALAT',
//       'MALBAGO',
//       'MANCILANG',
//       'PILI',
//       'POBLACION',
//       'SAN AGUSTIN',
//       'TABAGAK',
//       'TALANGNAN',
//       'TARONG',
//       'TUGAS',
//     ],
//   },
//   MALABUYOC: {
//     province: 'CEBU',
//     municipality: 'MALABUYOC',
//     region: 'REGION VII',
//     barangay_list: [
//       'ARMEÑA (CANSILONGAN)',
//       'BARANGAY I (POB.)',
//       'BARANGAY II (POB.)',
//       'CERDEÑA (ANSAN)',
//       'LABRADOR (BULOD)',
//       'LOMBO',
//       'LOOC',
//       'MAHANLUD',
//       'MINDANAO (PAJO)',
//       'MONTAÑEZA (INAMLANG)',
//       'SALMERON (BULAK)',
//       'SANTO NIÑO',
//       'SORSOGON (BALIMAYA)',
//       'TOLOSA (CALATAGAN)',
//     ],
//   },
//   'MANDAUE': {
//     province: 'CEBU',
//     municipality: 'MANDAUE',
//     region: 'REGION VII',
//     barangay_list: [
//       'ALANG-ALANG',
//       'BAKILID',
//       'BANILAD',
//       'BASAK',
//       'CABANCALAN',
//       'CAMBARO',
//       'CANDUMAN',
//       'CASILI',
//       'CASUNTINGAN',
//       'CENTRO (POB.)',
//       'CUBACUB',
//       'GUIZO',
//       'IBABAO-ESTANCIA',
//       'JAGOBIAO',
//       'LABOGON',
//       'LOOC',
//       'MAGUIKAY',
//       'MANTUYONG',
//       'OPAO',
//       'PAGSABUNGAN',
//       'PAKNA-AN',
//       'SUBANGDAKU',
//       'TABOK',
//       'TAWASON',
//       'TINGUB',
//       'TIPOLO',
//       'UMAPAD',
//     ],
//   },
//   MEDELLIN: {
//     province: 'CEBU',
//     municipality: 'MEDELLIN',
//     region: 'REGION VII',
//     barangay_list: [
//       'ANTIPOLO',
//       'CANHABAGAT',
//       'CAPUTATAN NORTE',
//       'CAPUTATAN SUR',
//       'CURVA',
//       'DAANLUNGSOD',
//       'DALINGDING SUR',
//       'DAYHAGON',
//       'DON VIRGILIO GONZALES',
//       'GIBITNGIL',
//       'KAWIT',
//       'LAMINTAK NORTE',
//       'LAMINTAK SUR',
//       'LUY-A',
//       'MAHARUHAY',
//       'MAHAWAK',
//       'PANUGNAWAN',
//       'POBLACION',
//       'TINDOG',
//     ],
//   },
//   MINGLANILLA: {
//     province: 'CEBU',
//     municipality: 'MINGLANILLA',
//     region: 'REGION VII',
//     barangay_list: [
//       'CADULAWAN',
//       'CALAJO-AN',
//       'CAMP 7',
//       'CAMP 8',
//       'CUANOS',
//       'GUINDARUHAN',
//       'LINAO',
//       'MANDUANG',
//       'PAKIGNE',
//       'POBLACION WARD I',
//       'POBLACION WARD II',
//       'POBLACION WARD III',
//       'POBLACION WARD IV',
//       'TUBOD',
//       'TULAY',
//       'TUNGHAAN',
//       'TUNGKIL',
//       'TUNGKOP',
//       'VITO',
//     ],
//   },
//   MOALBOAL: {
//     province: 'CEBU',
//     municipality: 'MOALBOAL',
//     region: 'REGION VII',
//     barangay_list: [
//       'AGBALANGA',
//       'BALA',
//       'BALABAGON',
//       'BASDIOT',
//       'BATADBATAD',
//       'BUGHO',
//       'BUGUIL',
//       'BUSAY',
//       'LANAO',
//       'POBLACION EAST',
//       'POBLACION WEST',
//       'SAAVEDRA',
//       'TOMONOY',
//       'TUBLE',
//       'TUNGA',
//     ],
//   },
//   OSLOB: {
//     province: 'CEBU',
//     municipality: 'OSLOB',
//     region: 'REGION VII',
//     barangay_list: [
//       'ALO',
//       'BANGCOGON',
//       'BONBON',
//       'CALUMPANG',
//       'CAN-UKBAN',
//       'CANANGCA-AN',
//       'CANSALO-AY',
//       'CAÑANG',
//       'DAANLUNGSOD',
//       'GAWI',
//       'HAGDAN',
//       'LAGUNDE',
//       'LOOC',
//       'LUKA',
//       'MAINIT',
//       'MANLUM',
//       'NUEVA CACERES',
//       'POBLACION',
//       'PUNGTOD',
//       'TAN-AWAN',
//       'TUMALOG',
//     ],
//   },
//   PINAMUNGAHAN: {
//     province: 'CEBU',
//     municipality: 'PINAMUNGAHAN',
//     region: 'REGION VII',
//     barangay_list: [
//       'ANISLAG',
//       'ANOPOG',
//       'BINABAG',
//       'BUHINGTUBIG',
//       'BUSAY',
//       'BUTONG',
//       'CABIANGON',
//       'CAMUGAO',
//       'DUANGAN',
//       'GUIMBAWIAN',
//       'LAMAC',
//       'LUT-OD',
//       'MANGOTO',
//       'OPAO',
//       'PANDACAN',
//       'POBLACION',
//       'PUNOD',
//       'RIZAL',
//       'SACSAC',
//       'SAMBAGON',
//       'SIBAGO',
//       'TAJAO',
//       'TANGUB',
//       'TANIBAG',
//       'TUPAS',
//       'TUTAY',
//     ],
//   },
//   PORO: {
//     province: 'CEBU',
//     municipality: 'PORO',
//     region: 'REGION VII',
//     barangay_list: [
//       'ADELA',
//       'ALTAVISTA',
//       'CAGCAGAN',
//       'CANSABUSAB',
//       'DAAN PAZ',
//       'EASTERN POBLACION',
//       'ESPERANZA',
//       'LIBERTAD',
//       'MABINI',
//       'MERCEDES',
//       'PAGSA',
//       'PAZ',
//       'RIZAL',
//       'SAN JOSE',
//       'SANTA RITA',
//       'TEGUIS',
//       'WESTERN POBLACION',
//     ],
//   },
//   RONDA: {
//     province: 'CEBU',
//     municipality: 'RONDA',
//     region: 'REGION VII',
//     barangay_list: [
//       'BUTONG',
//       'CAN-ABUHON',
//       'CANDULING',
//       'CANSALONOY',
//       'CANSAYAHON',
//       'ILAYA',
//       'LANGIN',
//       'LIBO-O',
//       'MALALAY',
//       'PALANAS',
//       'POBLACION',
//       'SANTA CRUZ',
//       'TUPAS',
//       'VIVE',
//     ],
//   },
//   SAMBOAN: {
//     province: 'CEBU',
//     municipality: 'SAMBOAN',
//     region: 'REGION VII',
//     barangay_list: [
//       'BASAK',
//       'BONBON',
//       'BULANGSURAN',
//       'CALATAGAN',
//       'CAMBIGONG',
//       'CAMBUROY',
//       'CANORONG',
//       'COLASE',
//       'DALAHIKAN',
//       'JUMANGPAS',
//       'MONTEVERDE',
//       'POBLACION',
//       'SAN SEBASTIAN',
//       'SUBA',
//       'TANGBO',
//     ],
//   },
//   SANTANDER: {
//     province: 'CEBU',
//     municipality: 'SANTANDER',
//     region: 'REGION VII',
//     barangay_list: [
//       'BUNLAN',
//       'CABUTONGAN',
//       'CANDAMIANG',
//       'CANLUMACAD',
//       'LILOAN',
//       'LIP-TONG',
//       'LOOC',
//       'PASIL',
//       'POBLACION',
//       'TALISAY',
//     ],
//   },
//   SIBONGA: {
//     province: 'CEBU',
//     municipality: 'SIBONGA',
//     region: 'REGION VII',
//     barangay_list: [
//       'ABUGON',
//       'BAE',
//       'BAGACAY',
//       'BAHAY',
//       'BANLOT',
//       'BASAK',
//       'BATO',
//       'CAGAY',
//       'CAN-AGA',
//       'CANDAGUIT',
//       'CANTOLAROY',
//       'DUGOAN',
//       'GUIMBANGCO-AN',
//       'LAMACAN',
//       'LIBO',
//       'LINDOGON',
//       'MAGCAGONG',
//       'MANATAD',
//       'MANGYAN',
//       'PAPAN',
//       'POBLACION',
//       'SABANG',
//       'SAYAO',
//       'SIMALA',
//       'TUBOD',
//     ],
//   },
//   SOGOD: {
//     province: 'SOUTHERN LEYTE',
//     municipality: 'SOGOD',
//     region: 'REGION VIII',
//     barangay_list: [
//       'BENIT',
//       'BUAC DAKU',
//       'BUAC GAMAY',
//       'CABADBARAN',
//       'CONCEPCION',
//       'CONSOLACION',
//       'DAGSA',
//       'HIBOD-HIBOD',
//       'HINDANGAN',
//       'HIPANTAG',
//       'JAVIER',
//       'KAHUPIAN',
//       'KANANGKAAN',
//       'KAUSWAGAN',
//       'LA PURISIMA CONCEPCION',
//       'LIBAS',
//       'LUM-AN',
//       'MABICAY',
//       'MAC',
//       'MAGATAS',
//       'MAHAYAHAY',
//       'MALINAO',
//       'MARIA PLANA',
//       'MILAGROSO',
//       'OLISIHAN',
//       'PANCHO VILLA',
//       'PANDAN',
//       'RIZAL',
//       'SALVACION',
//       'SAN FRANCISCO MABUHAY',
//       'SAN ISIDRO',
//       'SAN JOSE',
//       'SAN JUAN (AGATA)',
//       'SAN MIGUEL',
//       'SAN PEDRO',
//       'SAN ROQUE',
//       'SAN VICENTE',
//       'SANTA MARIA',
//       'SUBA',
//       'TAMPOONG',
//       'ZONE I (POB.)',
//       'ZONE II (POB.)',
//       'ZONE III (POB.)',
//       'ZONE IV (POB.)',
//       'ZONE V (POB.)',
//     ],
//   },
//   TABOGON: {
//     province: 'CEBU',
//     municipality: 'TABOGON',
//     region: 'REGION VII',
//     barangay_list: [
//       'ALANG-ALANG',
//       'CADUAWAN',
//       'CAMOBOAN',
//       'CANAOCANAO',
//       'COMBADO',
//       'DAANTABOGON',
//       'ILIHAN',
//       'KAL-ANAN',
//       'LABANGON',
//       'LIBJO',
//       'LOONG',
//       'MABULI',
//       'MANAGASE',
//       'MANLAGTANG',
//       'MASLOG',
//       'MUABOG',
//       'PIO',
//       'POBLACION',
//       'SALAG',
//       'SAMBAG',
//       'SAN ISIDRO',
//       'SAN VICENTE',
//       'SOMOSA',
//       'TABA-AO',
//       'TAPUL',
//     ],
//   },
//   TABUELAN: {
//     province: 'CEBU',
//     municipality: 'TABUELAN',
//     region: 'REGION VII',
//     barangay_list: [
//       'BONGON',
//       'DALID',
//       'KANLIM-AO',
//       'KANLUHANGON',
//       'KANTUBAON',
//       'MABUNAO',
//       'MARAVILLA',
//       'OLIVO',
//       'POBLACION',
//       'TABUNOK',
//       'TIGBAWAN',
//       'VILLAHERMOSA',
//     ],
//   },
//   'TOLEDO': {
//     province: 'CEBU',
//     municipality: 'TOLEDO',
//     region: 'REGION VII',
//     barangay_list: [
//       'AWIHAO',
//       'BAGAKAY',
//       'BATO',
//       'BIGA',
//       'BULONGAN',
//       'BUNGA',
//       'CABITOONAN',
//       'CALONGCALONG',
//       'CAMBANG-UG',
//       'CAMP 8',
//       'CANLUMAMPAO',
//       'CANTABACO',
//       'CAPITAN CLAUDIO',
//       'CARMEN',
//       'DAANGLUNGSOD',
//       'DON ANDRES SORIANO (LUTOPAN)',
//       'DUMLOG',
//       'GEN. CLIMACO (MALUBOG)',
//       'IBO',
//       'ILIHAN',
//       'JUAN CLIMACO, SR. (MAGDUGO)',
//       'LANDAHAN',
//       'LOAY',
//       'LURAY II',
//       'MATAB-ANG',
//       'MEDIA ONCE',
//       'PANGAMIHAN',
//       'POBLACION',
//       'POOG',
//       'PUTINGBATO',
//       'SAGAY',
//       'SAM-ANG',
//       'SANGI',
//       'SANTO NIÑO (MAINGGIT)',
//       'SUBAYON',
//       'TALAVERA',
//       'TUBOD',
//       'TUNGKAY',
//     ],
//   },
//   TUBURAN: {
//     province: 'BASILAN',
//     municipality: 'TUBURAN',
//     region: 'BARMM',
//     barangay_list: [
//       'BOHETAMBIS',
//       'CALUT',
//       'DUGA-A',
//       'KATIPUNAN',
//       'LAHI-LAHI',
//       'LOWER SINANGKAPAN',
//       'LOWER TABLAS',
//       'MAHAWID',
//       'SINULATAN',
//       'TABLAS USEW',
//     ],
//   },
//   'AMLAN (AYUQUITAN)': {
//     province: 'NEGROS ORIENTAL',
//     municipality: 'AMLAN (AYUQUITAN)',
//     region: 'REGION VII',
//     barangay_list: [
//       'BIO-OS',
//       'JANTIANON',
//       'JUGNO',
//       'MAG-ABO',
//       'POBLACION',
//       'SILAB',
//       'TAMBOJANGIN',
//       'TANDAYAG',
//     ],
//   },
//   AYUNGON: {
//     province: 'NEGROS ORIENTAL',
//     municipality: 'AYUNGON',
//     region: 'REGION VII',
//     barangay_list: [
//       'AMDUS',
//       'ANIBONG',
//       'ATABAY',
//       'AWA-AN',
//       'BAN-BAN',
//       'CALAGCALAG',
//       'CANDANA-AY',
//       'CAROL-AN',
//       'GOMENTOC',
//       'INACBAN',
//       'INIBAN',
//       'JANDALAMANON',
//       'KILABAN',
//       'LAMIGAN',
//       'MAASLUM',
//       'MABATO',
//       'MANOGTONG',
//       'NABHANG',
//       'POBLACION',
//       'TAMBO',
//       'TAMPOCON I',
//       'TAMPOCON II',
//       'TIBYAWAN',
//       'TIGUIB',
//     ],
//   },
//   BACONG: {
//     province: 'NEGROS ORIENTAL',
//     municipality: 'BACONG',
//     region: 'REGION VII',
//     barangay_list: [
//       'BALAYAGMANOK',
//       'BANILAD',
//       'BUNTIS',
//       'BUNTOD',
//       'CALANGAG',
//       'COMBADO',
//       'DOLDOL',
//       'ISUGAN',
//       'LIPTONG',
//       'LUTAO',
//       'MAGSUHOT',
//       'MALABAGO',
//       'MAMPAS',
//       'NORTH POBLACION',
//       'SACSAC',
//       'SAN MIGUEL',
//       'SOUTH POBLACION',
//       'SULODPAN',
//       'TIMBANGA',
//       'TIMBAO',
//       'TUBOD',
//       'WEST POBLACION',
//     ],
//   },
//   'BAIS': {
//     province: 'NEGROS ORIENTAL',
//     municipality: 'BAIS',
//     region: 'REGION VII',
//     barangay_list: [
//       'BARANGAY I (POB.)',
//       'BARANGAY II (POB.)',
//       'BASAK',
//       'BIÑOHON',
//       'CABANLUTAN',
//       'CALASGA-AN',
//       'CAMBAGAHAN',
//       'CAMBAGUIO',
//       'CAMBANJAO',
//       'CAMBUILAO',
//       'CANLARGO',
//       'CAPIÑAHAN',
//       'CONSOLACION',
//       'DANSULAN',
//       'HANGYAD',
//       'KATACGAHAN (TACGAHAN)',
//       'LA PAZ',
//       'LO-OC',
//       'LONOY',
//       'MABUNAO',
//       'MANLIPAC',
//       'MANSANGABAN',
//       'OKIOT',
//       'OLYMPIA',
//       'PANALA-AN',
//       'PANAM-ANGAN',
//       'ROSARIO',
//       'SAB-AHAN',
//       'SAN ISIDRO',
//       'TAGPO',
//       'TALUNGON',
//       'TAMISU',
//       'TAMOGONG',
//       'TANGCULOGAN',
//       'VALENCIA',
//     ],
//   },
//   BASAY: {
//     province: 'NEGROS ORIENTAL',
//     municipality: 'BASAY',
//     region: 'REGION VII',
//     barangay_list: [
//       'ACTIN',
//       'BAL-OS',
//       'BONGALONAN',
//       'CABALAYONGAN',
//       'CABATUANAN',
//       'LINANTAYAN',
//       'MAGLINAO',
//       'NAGBO-ALAO',
//       'OLANDAO',
//       'POBLACION',
//     ],
//   },
//   'BAYAWAN CITY (TULONG)': {
//     province: 'NEGROS ORIENTAL',
//     municipality: 'BAYAWAN CITY (TULONG)',
//     region: 'REGION VII',
//     barangay_list: [
//       'ALI-IS',
//       'BANAYBANAY',
//       'BANGA',
//       'BOYCO',
//       'BUGAY',
//       'CANSUMALIG',
//       'DAWIS',
//       'KALAMTUKAN',
//       'KALUMBOYAN',
//       'MALABUGAS',
//       'MANDU-AO',
//       'MANINIHON',
//       'MINABA',
//       'NANGKA',
//       'NARRA',
//       'PAGATBAN',
//       'POBLACION',
//       'SAN ISIDRO',
//       'SAN JOSE',
//       'SAN MIGUEL',
//       'SAN ROQUE',
//       'SUBA (POB.)',
//       'TABUAN',
//       'TAYAWAN',
//       'TINAGO (POB.)',
//       'UBOS (POB.)',
//       'VILLAREAL',
//       'VILLASOL (BATO)',
//     ],
//   },
//   'BINDOY (PAYABON)': {
//     province: 'NEGROS ORIENTAL',
//     municipality: 'BINDOY (PAYABON)',
//     region: 'REGION VII',
//     barangay_list: [
//       'ATOTES',
//       'BATANGAN',
//       'BULOD',
//       'CABCABAN',
//       'CABUGAN',
//       'CAMUDLAS',
//       'CANLUTO',
//       'DANAO',
//       'DANAWAN',
//       'DOMOLOG',
//       'MALAGA',
//       'MANSEJE',
//       'MATOBATO',
//       'NAGCASUNOG',
//       'NALUNDAN',
//       'PANGALAYCAYAN',
//       'PEÑAHAN',
//       'POBLACION (PAYABON)',
//       'SALONG',
//       'TAGAYTAY',
//       'TINAOGAN',
//       'TUBOD',
//     ],
//   },
//   'CANLAON': {
//     province: 'NEGROS ORIENTAL',
//     municipality: 'CANLAON',
//     region: 'REGION VII',
//     barangay_list: [
//       'BAYOG',
//       'BINALBAGAN',
//       'BUCALAN (EAST BUDSALAN)',
//       'BUDLASAN (WEST BUDLASAN)',
//       'LINOTHANGAN',
//       'LUMAPAO',
//       'MABIGO (POB.)',
//       'MALAIBA',
//       'MASULOG',
//       'NINOY AQUINO',
//       'PANUBIGAN',
//       'PULA',
//     ],
//   },
//   DAUIN: {
//     province: 'NEGROS ORIENTAL',
//     municipality: 'DAUIN',
//     region: 'REGION VII',
//     barangay_list: [
//       'ANAHAWAN',
//       'APO ISLAND',
//       'BAGACAY',
//       'BASLAY',
//       'BATUHON DACU',
//       'BOLOC-BOLOC',
//       'BULAK',
//       'BUNGA',
//       'CASILE',
//       'LIBJO',
//       'LIPAYO',
//       'MAAYONGTUBIG',
//       'MAG-ASO',
//       'MAGSAYSAY',
//       'MALONGCAY DACU',
//       'MASAPLOD NORTE',
//       'MASAPLOD SUR',
//       'PANUBTUBAN',
//       'POBLACION I',
//       'POBLACION II',
//       'POBLACION III',
//       'TUGAWE',
//       'TUNGA-TUNGA',
//     ],
//   },
//   'DUMAGUETE': {
//     province: 'NEGROS ORIENTAL',
//     municipality: 'DUMAGUETE',
//     region: 'REGION VII',
//     barangay_list: [
//       'BAGACAY',
//       'BAJUMPANDAN',
//       'BALUGO',
//       'BANILAD',
//       'BANTAYAN',
//       'BATINGUEL',
//       'BUNAO',
//       'CADAWINONAN',
//       'CALINDAGAN',
//       'CAMANJAC',
//       'CANDAU-AY',
//       'CANTIL-E',
//       'DARO',
//       'JUNOB',
//       'LOOC',
//       'MANGNAO-CANAL',
//       'MOTONG',
//       'PIAPI',
//       'POBLACION NO. 1 (BARANGAY 1)',
//       'POBLACION NO. 2 (BARANGAY 2)',
//       'POBLACION NO. 3 (BARANGAY 3)',
//       'POBLACION NO. 4 (BARANGAY 4)',
//       'POBLACION NO. 5 (BARANGAY 5)',
//       'POBLACION NO. 6 (BARANGAY 6)',
//       'POBLACION NO. 7 (BARANGAY 7)',
//       'POBLACION NO. 8 (BARANGAY 8)',
//       'PULANTUBIG',
//       'TABUCTUBIG',
//       'TACLOBO',
//       'TALAY',
//     ],
//   },
//   'GUIHULNGAN': {
//     province: 'NEGROS ORIENTAL',
//     municipality: 'GUIHULNGAN',
//     region: 'REGION VII',
//     barangay_list: [
//       'BAKID',
//       'BALOGO',
//       'BANWAQUE',
//       'BASAK',
//       'BINOBOHAN',
//       'BUENAVISTA',
//       'BULADO',
//       'CALAMBA',
//       'CALUPA-AN',
//       'HIBAIYO',
//       'HILAITAN',
//       'HINAKPAN',
//       'HUMAYHUMAY',
//       'IMELDA',
//       'KAGAWASAN',
//       'LINANTUYAN',
//       'LUZ',
//       'MABUNGA',
//       'MAGSAYSAY',
//       'MALUSAY',
//       'MANIAK',
//       'MCKINLEY',
//       'NAGSAHA',
//       'PADRE ZAMORA',
//       'PLAGATASANON',
//       'PLANAS',
//       'POBLACION',
//       'SANDAYAO',
//       'TACPAO',
//       'TINAYUNAN BEACH',
//       'TINAYUNAN HILL',
//       'TRINIDAD',
//       'VILLEGAS',
//     ],
//   },
//   JIMALALUD: {
//     province: 'NEGROS ORIENTAL',
//     municipality: 'JIMALALUD',
//     region: 'REGION VII',
//     barangay_list: [
//       'AGLAHUG',
//       'AGUTAYON',
//       'APANANGON',
//       'BAE',
//       'BALA-AS',
//       'BANGCAL',
//       'BANOG',
//       'BUTO',
//       'CABANG',
//       'CAMANDAYON',
//       'CANGHARAY',
//       'CANLAHAO',
//       'DAYOYO',
//       'ELI',
//       'LACAON',
//       'MAHANLUD',
//       'MALABAGO',
//       'MAMBAID',
//       'MONGPONG',
//       'NORTH POBLACION',
//       'OWACAN',
//       'PACUAN',
//       'PANGLAYA-AN',
//       'POLOPANTAO',
//       'SAMPINITON',
//       'SOUTH POBLACION',
//       'TALAMBAN',
//       'TAMAO',
//     ],
//   },
//   'LA LIBERTAD': {
//     province: 'ZAMBOANGA DEL NORTE',
//     municipality: 'LA LIBERTAD',
//     region: 'REGION IX',
//     barangay_list: [
//       'EL PARAISO',
//       'LA UNION',
//       'LA VICTORIA',
//       'MAUSWAGON',
//       'MERCEDES',
//       'NEW ARGAO',
//       'NEW BATAAN',
//       'NEW CARCAR',
//       'POBLACION',
//       'SAN JOSE',
//       'SANTA CATALINA',
//       'SANTA CRUZ',
//       'SINGARAN',
//     ],
//   },
//   MABINAY: {
//     province: 'NEGROS ORIENTAL',
//     municipality: 'MABINAY',
//     region: 'REGION VII',
//     barangay_list: [
//       'ABIS',
//       'AREBASORE',
//       'BAGTIC',
//       'BANBAN',
//       'BARRAS',
//       'BATO',
//       'BUGNAY',
//       'BULIBULIHAN',
//       'BULWANG',
//       'CAMPANUN-AN',
//       'CANGGOHOB',
//       'CANSAL-ING',
//       'DAGBASAN',
//       'DAHILE',
//       'HAGTU',
//       'HIMOCDONGON',
//       'INAPOY',
//       'LAMDAS',
//       'LUMBANGAN',
//       'LUYANG',
//       'MANLINGAY',
//       'MAYAPOSI',
//       'NAPASU-AN',
//       'NEW NAMANGKA',
//       'OLD NAMANGKA',
//       'PANDANON',
//       'PANIABONAN',
//       'PANTAO',
//       'POBLACION',
//       'SAMAC',
//       'TADLONG',
//       'TARA',
//     ],
//   },
//   MANJUYOD: {
//     province: 'NEGROS ORIENTAL',
//     municipality: 'MANJUYOD',
//     region: 'REGION VII',
//     barangay_list: [
//       'ALANGILANAN',
//       'BAGTIC',
//       'BALAAS',
//       'BANTOLINAO',
//       'BOLISONG',
//       'BUTONG',
//       'CAMPUYO',
//       'CANDABONG',
//       'CONCEPCION',
//       'DUNGO-AN',
//       'KAUSWAGAN',
//       'LAMOGONG',
//       'LIBJO',
//       'MAASLUM',
//       'MANDALUPANG',
//       'PANCIAO',
//       'POBLACION',
//       'SAC-SAC',
//       'SALVACION',
//       'SAN ISIDRO',
//       'SAN JOSE',
//       'SANTA MONICA',
//       'SUBA',
//       'SUNDO-AN',
//       'TANGLAD',
//       'TUBOD',
//       'TUPAS',
//     ],
//   },
//   SIATON: {
//     province: 'NEGROS ORIENTAL',
//     municipality: 'SIATON',
//     region: 'REGION VII',
//     barangay_list: [
//       'ALBIGA',
//       'APOLOY',
//       'BONAWON',
//       'BONBONON',
//       'CABANGAHAN',
//       'CANAWAY',
//       'CASALA-AN',
//       'CATICUGAN',
//       'DATAG',
//       'GILIGA-ON',
//       'INALAD',
//       'MALABUHAN',
//       'MALOH',
//       'MANTIQUIL',
//       'MANTUYOP',
//       'NAPACAO',
//       'POBLACION I',
//       'POBLACION II',
//       'POBLACION III',
//       'POBLACION IV',
//       'SALAG',
//       'SAN JOSE',
//       'SANDULOT',
//       'SI-IT',
//       'SUMALIRING',
//       'TAYAK',
//     ],
//   },
//   SIBULAN: {
//     province: 'NEGROS ORIENTAL',
//     municipality: 'SIBULAN',
//     region: 'REGION VII',
//     barangay_list: [
//       'AGAN-AN',
//       'AJONG',
//       'BALUGO',
//       'BOLOCBOLOC',
//       'CALABNUGAN',
//       'CANGMATING',
//       'ENRIQUE VILLANUEVA',
//       'LOOC',
//       'MAGATAS',
//       'MANINGCAO',
//       'MASLOG',
//       'POBLACION',
//       'SAN ANTONIO',
//       'TUBIGON',
//       'TUBTUBON',
//     ],
//   },
//   'TANJAY': {
//     province: 'NEGROS ORIENTAL',
//     municipality: 'TANJAY',
//     region: 'REGION VII',
//     barangay_list: [
//       'AZAGRA',
//       'BAHI-AN',
//       'LUCA',
//       'MANIPIS',
//       'NOVALLAS',
//       'OBOGON',
//       'PAL-EW',
//       'POBLACION I (BARANGAY 1)',
//       'POBLACION II (BARANGAY 2)',
//       'POBLACION III (BARANGAY 3)',
//       'POBLACION IV (BARANGAY 4)',
//       'POBLACION IX (BARANGAY 9)',
//       'POBLACION V (BARANGAY 5)',
//       'POBLACION VI (BARANGAY 6)',
//       'POBLACION VII (BARANGAY 7)',
//       'POBLACION VIII (BARANGAY 8)',
//       'POLO',
//       'SAN ISIDRO',
//       'SAN JOSE',
//       'SAN MIGUEL',
//       'SANTA CRUZ NUEVO',
//       'SANTA CRUZ VIEJO',
//       'SANTO NIÑO',
//       'TUGAS',
//     ],
//   },
//   TAYASAN: {
//     province: 'NEGROS ORIENTAL',
//     municipality: 'TAYASAN',
//     region: 'REGION VII',
//     barangay_list: [
//       'BACONG',
//       'BAGO',
//       'BANGA',
//       'CABULOTAN',
//       'CAMBAYE',
//       'DALAUPON',
//       'GUINCALABAN',
//       'ILAYA-TAYASAN',
//       'JILABANGAN',
//       'LAG-IT',
//       'LINAO',
//       'LUTAY',
//       'MAGLIHE',
//       'MAGTUHAO',
//       'MATAUTA',
//       'MATUOG',
//       'NUMNUM',
//       'PALASLAN',
//       'PINALUBNGAN',
//       'PINDAHAN',
//       'PINOCAWAN',
//       'POBLACION',
//       'SANTA CRUZ',
//       'SAYING',
//       'SUQUIB',
//       'TAMAO',
//       'TAMBULAN',
//       'TANLAD',
//     ],
//   },
//   'VALENCIA (LUZURRIAGA)': {
//     province: 'NEGROS ORIENTAL',
//     municipality: 'VALENCIA (LUZURRIAGA)',
//     region: 'REGION VII',
//     barangay_list: [
//       'APOLONG',
//       'BALABAG EAST',
//       'BALABAG WEST',
//       'BALAYAGMANOK',
//       'BALILI',
//       'BALUGO',
//       'BONG-AO',
//       'BONGBONG',
//       'CAIDIOCAN',
//       'CALAYUGAN',
//       'CAMBUCAD',
//       'DOBDOB',
//       'JAWA',
//       'LIPTONG',
//       'LUNGA',
//       'MALABO',
//       'MALAUNAY',
//       'MAMPAS',
//       'NORTH POBLACION',
//       'PALINPINON',
//       'PUHAGAN',
//       'PULANGBATO',
//       'SAGBANG',
//       'SOUTH POBLACION',
//     ],
//   },
//   VALLEHERMOSO: {
//     province: 'NEGROS ORIENTAL',
//     municipality: 'VALLEHERMOSO',
//     region: 'REGION VII',
//     barangay_list: [
//       'BAGAWINES',
//       'BAIRAN',
//       'CABULIHAN',
//       'DON ESPIRIDION VILLEGAS',
//       'GUBA',
//       'MACAPSO',
//       'MAGLAHOS',
//       'MALANGSA',
//       'MOLOBOLO',
//       'PINOCAWAN',
//       'POBLACION',
//       'PUAN',
//       'TABON',
//       'TAGBINO',
//       'ULAY',
//     ],
//   },
//   ZAMBOANGUITA: {
//     province: 'NEGROS ORIENTAL',
//     municipality: 'ZAMBOANGUITA',
//     region: 'REGION VII',
//     barangay_list: [
//       'BASAC',
//       'CALANGO',
//       'LOTUBAN',
//       'MALONGCAY DIOT',
//       'MALUAY',
//       'MAYABON',
//       'NABAGO',
//       'NAJANDIG',
//       'NASIG-ID',
//       'POBLACION',
//     ],
//   },
//   'ENRIQUE VILLANUEVA': {
//     province: 'SIQUIJOR',
//     municipality: 'ENRIQUE VILLANUEVA',
//     region: 'REGION VII',
//     barangay_list: [
//       'BALOLONG',
//       'BINO-ONGAN',
//       'BITAUG',
//       'BOLOT',
//       'CAMOGAO',
//       'CANGMANGKI',
//       'LIBO',
//       'LOMANGCAPAN',
//       'LOTLOTON',
//       'MANAN-AO',
//       'OLAVE',
//       'PARIAN',
//       'POBLACION',
//       'TULAPOS',
//     ],
//   },
//   LARENA: {
//     province: 'SIQUIJOR',
//     municipality: 'LARENA',
//     region: 'REGION VII',
//     barangay_list: [
//       'BAGACAY',
//       'BALOLANG',
//       'BASAC',
//       'BINTANGAN',
//       'BONTOD',
//       'CABULIHAN',
//       'CALUNASAN',
//       'CANDIGUM',
//       'CANG-ALLAS',
//       'CANG-APA',
//       'CANGBAGSA',
//       'CANGMALALAG',
//       'CANLAMBO',
//       'CANLASOG',
//       'CATAMBOAN',
//       'HELEN (DATAG)',
//       'NONOC',
//       'NORTH POBLACION',
//       'PONONG',
//       'SABANG',
//       'SANDUGAN',
//       'SOUTH POBLACION',
//       'TACULING',
//     ],
//   },
//   LAZI: {
//     province: 'SIQUIJOR',
//     municipality: 'LAZI',
//     region: 'REGION VII',
//     barangay_list: [
//       'CAMPALANAS',
//       'CANGCLARAN',
//       'CANGOMANTONG',
//       'CAPALASANAN',
//       'CATAMBOAN (POB.)',
//       'GABAYAN',
//       'KIMBA',
//       'KINAMANDAGAN',
//       'LOWER CABANGCALAN',
//       'NAGERONG',
//       'PO-O',
//       'SIMACOLONG',
//       'TAGMANOCAN',
//       'TALAYONG',
//       'TIGBAWAN (POB.)',
//       'TIGNAO',
//       'UPPER CABANGCALAN',
//       'YTAYA',
//     ],
//   },
//   MARIA: {
//     province: 'SIQUIJOR',
//     municipality: 'MARIA',
//     region: 'REGION VII',
//     barangay_list: [
//       'BOGO',
//       'BONGA',
//       'CABAL-ASAN',
//       'CALUNASAN',
//       'CANDAPING A',
//       'CANDAPING B',
//       'CANTAROC A',
//       'CANTAROC B',
//       'CANTUGBAS',
//       'LICO-AN',
//       'LILO-AN',
//       'LOGUCAN',
//       'LOOC',
//       'MINALULAN',
//       'NABUTAY',
//       'OLANG',
//       'PISONG A',
//       'PISONG B',
//       'POBLACION NORTE',
//       'POBLACION SUR',
//       'SAGUING',
//       'SAWANG',
//     ],
//   },
//   SIQUIJOR: {
//     province: 'SIQUIJOR',
//     municipality: 'SIQUIJOR',
//     region: 'REGION VII',
//     barangay_list: [
//       'BANBAN',
//       'BOLOS',
//       'CAIPILAN',
//       'CAITICAN',
//       'CALALINAN',
//       'CANAL',
//       'CANDANAY NORTE',
//       'CANDANAY SUR',
//       'CANG-ADIENG',
//       'CANG-AGONG',
//       'CANG-ALWANG',
//       'CANG-ASA',
//       'CANG-ATUYOM',
//       'CANG-INTE',
//       'CANG-ISAD',
//       'CANGHUNOGHUNOG',
//       'CANGMATNOG',
//       'CANGMOHAO',
//       'CANTABON',
//       'CATICUGAN',
//       'DUMANHOG',
//       'IBABAO',
//       'LAMBOJON',
//       'LUYANG',
//       'LUZONG',
//       'OLO',
//       'PANGI',
//       'PANLAUTAN',
//       'PASIHAGON',
//       'PILI',
//       'POBLACION',
//       'POLANGYUTA',
//       'PONONG',
//       'SABANG',
//       'SAN ANTONIO',
//       'SONGCULAN',
//       'TACDOG',
//       'TACLOBAN',
//       'TAMBISAN',
//       'TEBJONG',
//       'TINAGO',
//       'TONGO',
//     ],
//   },
//   ALMERIA: {
//     province: 'BILIRAN',
//     municipality: 'ALMERIA',
//     region: 'REGION VIII',
//     barangay_list: [
//       'CAUCAB',
//       'IYOSAN',
//       'JAMORAWON',
//       'LO-OK',
//       'MATANGA',
//       'PILI',
//       'POBLACION',
//       'PULANG BATO',
//       'SALANGI',
//       'SAMPAO',
//       'TABUNAN',
//       'TALAHID',
//       'TAMARINDO',
//     ],
//   },
//   BILIRAN: {
//     province: 'BILIRAN',
//     municipality: 'BILIRAN',
//     region: 'REGION VIII',
//     barangay_list: [
//       'BATO',
//       'BURABOD',
//       'BUSALI',
//       'CANILA',
//       'HUGPA',
//       'JULITA',
//       'PINANGUMHAN',
//       'SAN ISIDRO (POB.)',
//       'SAN ROQUE (POB.)',
//       'SANGGALANG',
//       'VILLA ENAGE (BARAS)',
//     ],
//   },
//   CABUCGAYAN: {
//     province: 'BILIRAN',
//     municipality: 'CABUCGAYAN',
//     region: 'REGION VIII',
//     barangay_list: [
//       'BALAQUID',
//       'BASO',
//       'BUNGA',
//       'CAANIBONGAN',
//       'CASIAWAN',
//       'ESPERANZA (POB.)',
//       'LANGGAO',
//       'LIBERTAD',
//       'LOOC',
//       'MAGBANGON (POB.)',
//       'PAWIKAN',
//       'SALAWAD',
//       'TALIBONG',
//     ],
//   },
//   CAIBIRAN: {
//     province: 'BILIRAN',
//     municipality: 'CAIBIRAN',
//     region: 'REGION VIII',
//     barangay_list: [
//       'ALEGRIA',
//       'ASUG',
//       'BARI-IS',
//       'BINOHANGAN',
//       'CABIBIHAN',
//       'CAULANGOHAN (MAREVIL)',
//       'KAWAYANON',
//       'LOOC',
//       'MANLABANG',
//       'MAURANG',
//       'PALANAY (POB.)',
//       'PALENGKE (POB.)',
//       'TOMALISTIS',
//       'UNION',
//       'USON',
//       'VICTORY (POB.)',
//       'VILLA VICENTA (MAINIT)',
//     ],
//   },
//   CULABA: {
//     province: 'BILIRAN',
//     municipality: 'CULABA',
//     region: 'REGION VIII',
//     barangay_list: [
//       'ACABAN',
//       'BACOLOD',
//       'BINONGTOAN',
//       'BOOL CENTRAL (POB.)',
//       'BOOL EAST (POB.)',
//       'BOOL WEST (POB.)',
//       'CALIPAYAN',
//       'CULABA CENTRAL (POB.)',
//       'GUINDAPUNAN',
//       'HABUHAB',
//       'LOOC',
//       'MARVEL (POB.)',
//       'PATAG',
//       'PINAMIHAGAN',
//       'SALVACION',
//       'SAN ROQUE',
//       'VIRGINIA (POB.)',
//     ],
//   },
//   KAWAYAN: {
//     province: 'BILIRAN',
//     municipality: 'KAWAYAN',
//     region: 'REGION VIII',
//     barangay_list: [
//       'BAGANITO',
//       'BALACSON',
//       'BALITE',
//       'BILWANG',
//       'BULALACAO',
//       'BURABOD',
//       'BUYO',
//       'INASUYAN',
//       'KANSANOK',
//       'MADA-O',
//       'MAPUYO',
//       'MASAGAOSAO',
//       'MASAGONGSONG',
//       'POBLACION',
//       'SAN LORENZO',
//       'TABUNAN NORTH',
//       'TUBIG GUINOO',
//       'TUCDAO',
//       'UNGALE',
//       'VILLA CORNEJO (LOOC)',
//     ],
//   },
//   MARIPIPI: {
//     province: 'BILIRAN',
//     municipality: 'MARIPIPI',
//     region: 'REGION VIII',
//     barangay_list: [
//       'AGUTAY',
//       'BANLAS',
//       'BATO',
//       'BINALAYAN EAST',
//       'BINALAYAN WEST',
//       'BINONGTO-AN (POBLACION NORTE)',
//       'BURABOD',
//       'CALBANI',
//       'CANDUHAO',
//       'CASIBANG',
//       'DANAO',
//       'ERMITA (POBLACION SUR)',
//       'OL-OG',
//       'TRABUGAN',
//       'VIGA',
//     ],
//   },
//   NAVAL: {
//     province: 'BILIRAN',
//     municipality: 'NAVAL',
//     region: 'REGION VIII',
//     barangay_list: [
//       'AGPANGI',
//       'ANISLAGAN',
//       'ATIPOLO',
//       'BORAC',
//       'CABUNGAAN',
//       'CALUMPANG',
//       'CAPIÑAHAN',
//       'CARAYCARAY',
//       'CATMON',
//       'HAGUIKHIKAN',
//       'IMELDA',
//       'LARRAZABAL',
//       'LIBERTAD',
//       'LIBTONG',
//       'LICO',
//       'LUCSOON',
//       'MABINI',
//       'PADRE INOCENTES GARCIA (POB.)',
//       'PADRE SERGIO EAMIGUEL',
//       'SABANG',
//       'SAN PABLO',
//       'SANTISSIMO ROSARIO POB. (SANTO ROSA)',
//       'SANTO NIÑO',
//       'TALUSTUSAN',
//       'VILLA CANEJA',
//       'VILLA CONSUELO',
//     ],
//   },
//   ARTECHE: {
//     province: 'EASTERN SAMAR',
//     municipality: 'ARTECHE',
//     region: 'REGION VIII',
//     barangay_list: [
//       'AGUINALDO',
//       'BALUD (POB.)',
//       'BATO (SAN LUIS)',
//       'BERI',
//       'BIGO',
//       'BUENAVISTA',
//       'CAGSALAY',
//       'CAMPACION',
//       'CARAPDAPAN',
//       'CASIDMAN',
//       'CATUMSAN',
//       'CENTRAL (POB.)',
//       'CONCEPCION',
//       'GARDEN (POB.)',
//       'INAYAWAN',
//       'MACARTHUR',
//       'RAWIS (POB.)',
//       'TANGBO',
//       'TAWAGAN',
//       'TEBALAWON',
//     ],
//   },
//   BALANGIGA: {
//     province: 'EASTERN SAMAR',
//     municipality: 'BALANGIGA',
//     region: 'REGION VIII',
//     barangay_list: [
//       'BACJAO',
//       'BARANGAY POBLACION I',
//       'BARANGAY POBLACION II',
//       'BARANGAY POBLACION III',
//       'BARANGAY POBLACION IV',
//       'BARANGAY POBLACION V',
//       'BARANGAY POBLACION VI',
//       'CAG-OLANGO',
//       'CANSUMANGCAY',
//       'GUINMAAYOHAN',
//       'MAYBUNGA',
//       'SAN MIGUEL',
//       'SANTA ROSA',
//     ],
//   },
//   BALANGKAYAN: {
//     province: 'EASTERN SAMAR',
//     municipality: 'BALANGKAYAN',
//     region: 'REGION VIII',
//     barangay_list: [
//       'BALOGO',
//       'BANGON',
//       'CABAY',
//       'CAISAWAN',
//       'CANTUBI',
//       'GENERAL MALVAR',
//       'GUINPOLIRAN',
//       'JULAG',
//       'MAGSAYSAY',
//       'MARAMAG',
//       'POBLACION I',
//       'POBLACION II',
//       'POBLACION III',
//       'POBLACION IV',
//       'POBLACION V',
//     ],
//   },
//   'BORONGAN': {
//     province: 'EASTERN SAMAR',
//     municipality: 'BORONGAN',
//     region: 'REGION VIII',
//     barangay_list: [
//       'ALANG-ALANG',
//       'AMANTACOP',
//       'ANDO',
//       'BALACDAS',
//       'BALUD',
//       'BANUYO',
//       'BARAS',
//       'BATO',
//       'BAYOBAY',
//       'BENOWANGAN',
//       'BUGAS',
//       'CABALAGNAN',
//       'CABONG',
//       'CAGBONGA',
//       'CALICO-AN',
//       'CALINGATNGAN',
//       'CAMADA',
//       'CAMPESAO',
//       'CAN-ABONG',
//       'CAN-AGA',
//       'CANJAWAY',
//       'CANLARAY',
//       'CANYOPAY',
//       'DIVINUBO',
//       'HEBACONG',
//       'HINDANG',
//       'LALAWIGAN',
//       'LIBUTON',
//       'LOCSO-ON',
//       'MAYBACONG',
//       'MAYPANGDAN',
//       'PEPELITAN',
//       'PINANAG-AN',
//       'PUNTA MARIA',
//       'PUROK A (POB.)',
//       'PUROK B (POB.)',
//       'PUROK C (POB.)',
//       'PUROK D1 (POB.)',
//       'PUROK D2 (POB.)',
//       'PUROK E (POB.)',
//       'PUROK F (POB.)',
//       'PUROK G (POB.)',
//       'PUROK H (POB.)',
//       'SABANG NORTH',
//       'SABANG SOUTH',
//       'SAN ANDRES',
//       'SAN GABRIEL',
//       'SAN GREGORIO',
//       'SAN JOSE',
//       'SAN MATEO',
//       'SAN PABLO',
//       'SAN SATURNINO',
//       'SANTA FE',
//       'SIHA',
//       'SOHUTAN',
//       'SONGCO',
//       'SURIBAO',
//       'SUROK',
//       'TABOC',
//       'TABUNAN',
//       'TAMOSO',
//     ],
//   },
//   'CAN-AVID': {
//     province: 'EASTERN SAMAR',
//     municipality: 'CAN-AVID',
//     region: 'REGION VIII',
//     barangay_list: [
//       'BALAGON',
//       'BARANGAY 1 POBLACION',
//       'BARANGAY 2 POBLACION',
//       'BARANGAY 3 POBLACION',
//       'BARANGAY 4 POBLACION',
//       'BARANGAY 5 POBLACION',
//       'BARANGAY 6 POBLACION',
//       'BARANGAY 7 POBLACION',
//       'BARANGAY 8 POBLACION',
//       'BARANGAY 9 POBLACION',
//       'BARANGAY 10 POBLACION',
//       'BARUK',
//       'BOCO',
//       'CAGHALONG',
//       'CAMANTANG',
//       'CAN-ILAY',
//       'CANSANGAYA',
//       'CANTEROS',
//       'CAROLINA',
//       'GUIBUANGAN',
//       'JEPACO',
//       'MABUHAY',
//       'MALOGO',
//       'OBONG',
//       'PANDOL',
//       'RAWIS',
//       'SALVACION',
//       'SOLONG',
//     ],
//   },
//   'GENERAL MACARTHUR': {
//     province: 'EASTERN SAMAR',
//     municipality: 'GENERAL MACARTHUR',
//     region: 'REGION VIII',
//     barangay_list: [
//       'AGUINALDO',
//       'ALANG-ALANG',
//       'BINALAY',
//       'CALUTAN',
//       'CAMCUEVAS',
//       'DOMROG',
//       'LAUREL',
//       'LIMBUJAN',
//       'MACAPAGAL',
//       'MAGSAYSAY',
//       'OSMEÑA',
//       'PINGAN',
//       'POBLACION BARANGAY 1',
//       'POBLACION BARANGAY 2',
//       'POBLACION BARANGAY 3',
//       'POBLACION BARANGAY 4',
//       'POBLACION BARANGAY 5',
//       'POBLACION BARANGAY 6',
//       'POBLACION BARANGAY 7',
//       'POBLACION BARANGAY 8',
//       'QUEZON',
//       'QUIRINO',
//       'ROXAS',
//       'SAN ISIDRO',
//       'SAN ROQUE',
//       'SANTA CRUZ (OPONG)',
//       'SANTA FE',
//       'TANDANG SORA',
//       'TUGOP',
//       'VIGAN',
//     ],
//   },
//   GIPORLOS: {
//     province: 'EASTERN SAMAR',
//     municipality: 'GIPORLOS',
//     region: 'REGION VIII',
//     barangay_list: [
//       'BARANGAY 1 (POB.)',
//       'BARANGAY 2 (POB.)',
//       'BARANGAY 3 (POB.)',
//       'BARANGAY 4 (POB.)',
//       'BARANGAY 5 (POB.)',
//       'BARANGAY 6 (POB.)',
//       'BARANGAY 7 (POB.)',
//       'BARANGAY 8 (POB.)',
//       'BIGA',
//       'COTICOT',
//       'GIGOSO',
//       'HUKNAN',
//       'PARINA',
//       'PAYA',
//       'PRESIDENT ROXAS',
//       'SAN ISIDRO (MALABAG)',
//       'SAN MIGUEL',
//       'SANTA CRUZ (CANSINGKOL)',
//     ],
//   },
//   GUIUAN: {
//     province: 'EASTERN SAMAR',
//     municipality: 'GUIUAN',
//     region: 'REGION VIII',
//     barangay_list: [
//       'ALINGAROG',
//       'BAGUA',
//       'BANAAG',
//       'BANAHAO',
//       'BARAS',
//       'BARBO',
//       'BITAUGAN',
//       'BUCAO',
//       'BUENAVISTA',
//       'BUNGTOD',
//       'CAGDARA-O',
//       'CAGUSU-AN',
//       'CAMPARANG',
//       'CAMPOYONG',
//       'CANAWAYON',
//       'CANTAHAY',
//       'CASUGURAN',
//       'COGON',
//       'CULASI',
//       'DALARAGAN',
//       'GAHOY',
//       'HABAG',
//       'HAGNA',
//       'HAMORAWON',
//       'HOLLYWOOD',
//       'INAPULANGAN',
//       'LUPOK (POB.)',
//       'MAYANA',
//       'NGOLOS',
//       'PAGBABANGNAN',
//       'PAGNAMITAN',
//       'POBLACION WARD 1',
//       'POBLACION WARD 2',
//       'POBLACION WARD 3',
//       'POBLACION WARD 4',
//       'POBLACION WARD 4-A',
//       'POBLACION WARD 5',
//       'POBLACION WARD 6',
//       'POBLACION WARD 7',
//       'POBLACION WARD 8',
//       'POBLACION WARD 9',
//       'POBLACION WARD 9-A',
//       'POBLACION WARD 10',
//       'POBLACION WARD 11',
//       'POBLACION WARD 12',
//       'SALUG',
//       'SAN ANTONIO',
//       'SAN JOSE',
//       'SAN JUAN',
//       'SAN PEDRO',
//       'SANTO NIÑO',
//       'SAPAO',
//       'SULANGAN',
//       'SULUAN',
//       'SUROK',
//       'TAGPORO',
//       'TAYTAY',
//       'TIMALA',
//       'TRINIDAD',
//       'VICTORY ISLAND',
//     ],
//   },
//   HERNANI: {
//     province: 'EASTERN SAMAR',
//     municipality: 'HERNANI',
//     region: 'REGION VIII',
//     barangay_list: [
//       'BARANGAY 1 (POB.)',
//       'BARANGAY 2 (POB.)',
//       'BARANGAY 3 (POB.)',
//       'BARANGAY 4 (POB.)',
//       'BATANG',
//       'CACATMONAN',
//       'CANCILEDES',
//       'CARMEN',
//       'GARAWON',
//       'NAGAJA',
//       'PADANG',
//       'SAN ISIDRO',
//       'SAN MIGUEL',
//     ],
//   },
//   JIPAPAD: {
//     province: 'EASTERN SAMAR',
//     municipality: 'JIPAPAD',
//     region: 'REGION VIII',
//     barangay_list: [
//       'AGSAMAN',
//       'BARANGAY 1 (POB.)',
//       'BARANGAY 2 (POB.)',
//       'BARANGAY 3 (POB.)',
//       'BARANGAY 4 (POB.)',
//       'CAGMANABA',
//       'DORILLO',
//       'JEWARAN',
//       'MABUHAY',
//       'MAGSAYSAY',
//       'RECARE',
//       'ROXAS',
//       'SAN ROQUE',
//     ],
//   },
//   LAWAAN: {
//     province: 'EASTERN SAMAR',
//     municipality: 'LAWAAN',
//     region: 'REGION VIII',
//     barangay_list: [
//       'BARANGAY POBLACION 1',
//       'BARANGAY POBLACION 2',
//       'BARANGAY POBLACION 3',
//       'BARANGAY POBLACION 4',
//       'BARANGAY POBLACION 5',
//       'BARANGAY POBLACION 6',
//       'BARANGAY POBLACION 7',
//       'BARANGAY POBLACION 8',
//       'BARANGAY POBLACION 9',
//       'BARANGAY POBLACION 10',
//       'BETAOG',
//       'BOLUSAO',
//       'GUINOB-AN',
//       'MASLOG',
//       'SAN ISIDRO',
//       'TAGUITE',
//     ],
//   },
//   LLORENTE: {
//     province: 'EASTERN SAMAR',
//     municipality: 'LLORENTE',
//     region: 'REGION VIII',
//     barangay_list: [
//       'ANTIPOLO',
//       'BABANIKHON',
//       'BACAYAWAN',
//       'BARANGAY 1 (POB.)',
//       'BARANGAY 2 (POB.)',
//       'BARANGAY 3 (POB.)',
//       'BARANGAY 4 (POB.)',
//       'BARANGAY 5 (POB.)',
//       'BARANGAY 6 (POB.)',
//       'BARANGAY 7 (POB.)',
//       'BARANGAY 8 (POB.)',
//       'BARANGAY 9 (POB.)',
//       'BARANGAY 10 (POB.)',
//       'BARANGAY 11 (POB.)',
//       'BARANGAY 12 (POB.)',
//       'BAROBO',
//       'BURAK',
//       'CAN-ATO',
//       'CANDOROS',
//       'CANLIWAG',
//       'CANTOMCO',
//       'HUGPA',
//       'MACA-ANGA',
//       'MAGTINO',
//       'MINA-ANOD',
//       'NAUBAY',
//       'PILIW',
//       'SAN JOSE',
//       'SAN MIGUEL',
//       'SAN ROQUE',
//       'SO-ONG',
//       'TABOK',
//       'WASO',
//     ],
//   },
//   MASLOG: {
//     province: 'EASTERN SAMAR',
//     municipality: 'MASLOG',
//     region: 'REGION VIII',
//     barangay_list: [
//       'BARANGAY 1 (POB.)',
//       'BARANGAY 2 (POB.)',
//       'BULAWAN',
//       'CARAYACAY',
//       'LIBERTAD',
//       'MALOBAGO',
//       'MAPUTI',
//       'SAN MIGUEL',
//       'SAN ROQUE',
//       'TANGBO',
//       'TAYTAY',
//       'TUGAS',
//     ],
//   },
//   MAYDOLONG: {
//     province: 'EASTERN SAMAR',
//     municipality: 'MAYDOLONG',
//     region: 'REGION VIII',
//     barangay_list: [
//       'BARANGAY POBLACION 1',
//       'BARANGAY POBLACION 2',
//       'BARANGAY POBLACION 3',
//       'BARANGAY POBLACION 4',
//       'BARANGAY POBLACION 5',
//       'BARANGAY POBLACION 6',
//       'BARANGAY POBLACION 7',
//       'CAMADA',
//       'CAMPAKERIT (BOTAY)',
//       'CANLOTERIO',
//       'DEL PILAR',
//       'GUINDALITAN',
//       'LAPGAP',
//       'MALOBAGO',
//       'MAYBOCOG',
//       'MAYTIGBAO',
//       'OMAWAS',
//       'PATAG',
//       'SAN GABRIEL',
//       'TAGASLIAN',
//     ],
//   },
//   ORAS: {
//     province: 'EASTERN SAMAR',
//     municipality: 'ORAS',
//     region: 'REGION VIII',
//     barangay_list: [
//       'AGSAM',
//       'ALANG-ALANG',
//       'BAGACAY',
//       'BALINGASAG',
//       'BALOCAWE (POB.)',
//       'BANTAYAN',
//       'BATANG',
//       'BATO',
//       'BINALAYAN',
//       'BUNTAY',
//       'BURAK',
//       'BUTNGA (POB.)',
//       'CADIAN',
//       'CAGDINE',
//       'CAGPILE',
//       'CAGTOOG',
//       'CAMANGA (POB.)',
//       'DALID',
//       'DAO',
//       'FACTORIA',
//       'GAMOT',
//       'IWAYAN',
//       'JAPAY',
//       'KALAW',
//       'MABUHAY',
//       'MALINGON',
//       'MINAP-OS',
//       'NADACPAN',
//       'NAGA',
//       'PANGUDTAN',
//       'PAYPAYON (POB.)',
//       'RIVERSIDE (POB.)',
//       'RIZAL',
//       'SABANG',
//       'SAN EDUARDO',
//       'SAN ROQUE (POB.)',
//       'SANTA MONICA',
//       'SAUGAN',
//       'SAURONG',
//       'TAWAGAN (POB.)',
//       'TIGUIB (POB.)',
//       'TRINIDAD (MAYCOROT)',
//     ],
//   },
//   QUINAPONDAN: {
//     province: 'EASTERN SAMAR',
//     municipality: 'QUINAPONDAN',
//     region: 'REGION VIII',
//     barangay_list: [
//       'ALANG-ALANG',
//       'ANISLAG',
//       'BAGTE',
//       'BARANGAY NO. 1 (POB.)',
//       'BARANGAY NO. 2 (POB.)',
//       'BARANGAY NO. 3 (POB.)',
//       'BARANGAY NO. 4 (POB.)',
//       'BARANGAY NO. 5 (POB.)',
//       'BARANGAY NO. 6 (POB.)',
//       'BARANGAY NO. 7 (POB.)',
//       'BUENAVISTA',
//       'CACULANGAN',
//       'CAGDAJA',
//       'CAMBILLA',
//       'CANTENIO',
//       'NAGA',
//       'PACO',
//       'PALACTAD (VALLEY)',
//       'RIZAL (PANA-UGAN)',
//       'SAN ISIDRO',
//       'SAN PEDRO',
//       'SAN VICENTE',
//       'SANTA CRUZ (LORO DIYO)',
//       'SANTA MARGARITA',
//       'SANTO NIÑO',
//     ],
//   },
//   SALCEDO: {
//     province: 'EASTERN SAMAR',
//     municipality: 'SALCEDO',
//     region: 'REGION VIII',
//     barangay_list: [
//       'ABEJAO',
//       'ALOG',
//       'ASGAD',
//       'BAGTONG',
//       'BALUD',
//       'BARANGAY 1 (POB.)',
//       'BARANGAY 2 (POB.)',
//       'BARANGAY 3 (POB.)',
//       'BARANGAY 4 (POB.)',
//       'BARANGAY 5 (POB.)',
//       'BARANGAY 6 (POB.)',
//       'BARANGAY 7 (POB.)',
//       'BARANGAY 8 (POB.)',
//       'BARANGAY 9 (POB.)',
//       'BARANGAY 10 (POB.)',
//       'BARANGAY 11 (POB.)',
//       'BARANGAY 12 (POB.)',
//       'BARANGAY 13 (POB.)',
//       'BUABUA',
//       'BURAK',
//       'BUTIG',
//       'CAGAUT',
//       'CAMANGA',
//       'CANTOMOJA',
//       'CARAPDAPAN',
//       'CARIDAD',
//       'CASILI-ON',
//       'IBERAN',
//       'JAGNAYA',
//       'LUSOD',
//       'MALBOG',
//       'MALIWALIW',
//       'MATARINAO',
//       'NAPARAAN',
//       'PALANAS',
//       'SAN ROQUE (BUGAY)',
//       'SANTA CRUZ',
//       'SEGUINON',
//       'TACLA-ON',
//       'TAGBACAN',
//       'TALANGDAWAN',
//     ],
//   },
//   'SAN JULIAN': {
//     province: 'EASTERN SAMAR',
//     municipality: 'SAN JULIAN',
//     region: 'REGION VIII',
//     barangay_list: [
//       'BARANGAY NO. 1 POBLACION',
//       'BARANGAY NO. 2 POBLACION',
//       'BARANGAY NO. 3 POBLACION',
//       'BARANGAY NO. 4 POBLACION',
//       'BARANGAY NO. 5 POBLACION',
//       'BARANGAY NO. 6 POBLACION',
//       'BUNACAN',
//       'CAMPIDHAN',
//       'CASOROY',
//       'LIBAS',
//       'LUNANG',
//       'NENA (LUNA)',
//       'PAGBABANGNAN',
//       'PUTONG',
//       'SAN ISIDRO',
//       'SAN MIGUEL',
//     ],
//   },
//   'SAN POLICARPO': {
//     province: 'EASTERN SAMAR',
//     municipality: 'SAN POLICARPO',
//     region: 'REGION VIII',
//     barangay_list: [
//       'ALUGAN',
//       'BAHAY',
//       'BANGON',
//       'BARANGAY NO. 1 (POB.)',
//       'BARANGAY NO. 2 (POB.)',
//       'BARANGAY NO. 3 (POB.)',
//       'BARANGAY NO. 4 (POB.)',
//       'BARANGAY NO. 5 (POB.)',
//       'BARAS (LIPATA)',
//       'BINOGAWAN',
//       'CAJAGWAYAN',
//       'JAPUNAN',
//       'NATIVIDAD',
//       'PANGPANG',
//       'SANTA CRUZ',
//       'TABO',
//       'TAN-AWAN',
//     ],
//   },
//   SULAT: {
//     province: 'EASTERN SAMAR',
//     municipality: 'SULAT',
//     region: 'REGION VIII',
//     barangay_list: [
//       'A-ET',
//       'ABUCAY (POB.)',
//       'BAYBAY (POB.)',
//       'DEL REMEDIO',
//       'KANDALAKIT',
//       'LOYOLA HEIGHTS (POB.)',
//       'MABINI',
//       'MAGLIPAY (POB.)',
//       'MARAMARA (POB.)',
//       'RIVERSIDE (POB.)',
//       'SAN FRANCISCO',
//       'SAN ISIDRO',
//       'SAN JUAN',
//       'SAN MATEO',
//       'SAN VICENTE',
//       'SANTO NIÑO',
//       'SANTO TOMAS',
//       'TABI (POB.)',
//     ],
//   },
//   TAFT: {
//     province: 'EASTERN SAMAR',
//     municipality: 'TAFT',
//     region: 'REGION VIII',
//     barangay_list: [
//       'BATIAWAN',
//       'BETO',
//       'BINALOAN',
//       'BONGDO',
//       'DACUL',
//       'DANAO',
//       'DEL REMEDIOS',
//       'GAYAM',
//       'LOMATUD (BURAK)',
//       'MABUHAY',
//       'MALINAO',
//       'MANTANG',
//       'NATO',
//       'PANGABUTAN',
//       'POBLACION BARANGAY 1',
//       'POBLACION BARANGAY 2',
//       'POBLACION BARANGAY 3',
//       'POBLACION BARANGAY 4',
//       'POBLACION BARANGAY 5',
//       'POBLACION BARANGAY 6',
//       'POLANGI',
//       'SAN LUIS',
//       'SAN PABLO',
//       'SAN RAFAEL',
//     ],
//   },
//   ABUYOG: {
//     province: 'LEYTE',
//     municipality: 'ABUYOG',
//     region: 'REGION VIII',
//     barangay_list: [
//       'ALANGILAN',
//       'ANIBONGAN',
//       'BAGACAY',
//       'BAHAY',
//       'BALINSASAYAO',
//       'BALOCAWE',
//       'BALOCAWEHAY',
//       'BARAYONG',
//       'BAYABAS',
//       'BITO (POB.)',
//       'BUAYA',
//       'BUENAVISTA',
//       'BULAK',
//       'BUNGA',
//       'BUNTAY (POB.)',
//       'BURUBUD-AN',
//       'CADAC-AN',
//       'CAGBOLO',
//       'CAN-APORONG',
//       'CAN-UGUIB (POB.)',
//       'CANMARATING',
//       'CAPILIAN',
//       'COMBIS',
//       'DINGLE',
//       'GUINTAGBUCAN (POB.)',
//       'HAMPIPILA',
//       'KATIPUNAN',
//       'KIKILO',
//       'LARAY',
//       'LAWA-AN',
//       'LIBERTAD',
//       'LOYONSAWANG (POB.)',
//       'MAG-ATUBANG',
//       'MAHAGNA (NEW CAGBOLO)',
//       'MAHAYAHAY',
//       'MAITUM',
//       'MALAGUICAY',
//       'MATAGNAO',
//       'NALIBUNAN (POB.)',
//       'NEBGA',
//       'NEW TALIGUE',
//       'ODIONGAN',
//       'OLD TALIGUE',
//       'PAGSANG-AN',
//       'PAGUITE',
//       'PARASANON',
//       'PICAS SUR',
//       'PILAR',
//       'PINAMANAGAN',
//       'SALVACION',
//       'SAN FRANCISCO',
//       'SAN ISIDRO',
//       'SAN ROQUE',
//       'SANTA FE (POB.)',
//       'SANTA LUCIA (POB.)',
//       'SANTO NIÑO (POB.)',
//       'TABIGUE',
//       'TADOC',
//       'TIB-O',
//       'TINALIAN',
//       'TINOCOLAN',
//       'TUY-A',
//       'VICTORY (POB.)',
//     ],
//   },
//   ALANGALANG: {
//     province: 'LEYTE',
//     municipality: 'ALANGALANG',
//     region: 'REGION VIII',
//     barangay_list: [
//       'ASLUM',
//       'ASTORGA (BURABOD)',
//       'BATO',
//       'BINONGTO-AN',
//       'BINOTONG',
//       'BLUMENTRITT (POB.)',
//       'BOBONON',
//       'BORSETH',
//       'BUENAVISTA',
//       'BUGHO',
//       'BURI',
//       'CABADSAN',
//       'CALAASAN',
//       'CAMBAHANON',
//       'CAMBOLAO',
//       'CANVERTUDES',
//       'CAPIZ',
//       'CAVITE',
//       'COGON',
//       'DAPDAP',
//       'DIVISORIA',
//       'EKIRAN',
//       'HINAPOLAN',
//       'HOLY CHILD I (POB.)',
//       'HOLY CHILD II (POB.)',
//       'HUBANG',
//       'HUPIT',
//       'LANGIT',
//       'LINGAYON',
//       'LOURDES',
//       'LUKAY',
//       'MAGSAYSAY',
//       'MILAGROSA (POB.)',
//       'MUDBORON',
//       'P. BARRANTES',
//       'PEÑALOSA',
//       'PEPITA',
//       'SALVACION',
//       'SALVACION POBLACION',
//       'SAN ANTONIO',
//       'SAN ANTONIO POB. (PATONG NORTE)',
//       'SAN DIEGO',
//       'SAN FRANCISCO EAST',
//       'SAN FRANCISCO WEST',
//       'SAN ISIDRO',
//       'SAN PEDRO',
//       'SAN ROQUE (POB.)',
//       'SAN VICENTE',
//       'SANTIAGO',
//       'SANTO NIÑO (POB.)',
//       'SANTOL',
//       'TABANGOHAY',
//       'TOMBO',
//       'VETERANOS',
//     ],
//   },
//   ALBUERA: {
//     province: 'LEYTE',
//     municipality: 'ALBUERA',
//     region: 'REGION VIII',
//     barangay_list: [
//       'ANTIPOLO',
//       'BALUGO',
//       'BENOLHO',
//       'CAMBALADING',
//       'DAMULA-AN',
//       'DOÑA MARIA (KANGKUIRINA)',
//       'MAHAYAG',
//       'MAHAYAHAY',
//       'POBLACION',
//       'SALVACION',
//       'SAN PEDRO',
//       'SEGUINON',
//       'SHERWOOD',
//       'TABGAS',
//       'TALISAYAN',
//       'TINAG-AN',
//     ],
//   },
//   BABATNGON: {
//     province: 'LEYTE',
//     municipality: 'BABATNGON',
//     region: 'REGION VIII',
//     barangay_list: [
//       'BACONG',
//       'BAGONG SILANG',
//       'BIASONG',
//       'GOV. E. JARO (BAGAHUPI)',
//       'GUINTIGUI-AN',
//       'LUKAY',
//       'MAGCASUANG',
//       'MALIBAGO',
//       'NAGA-ASAN',
//       'PAGSULHUGON',
//       'PLANZA',
//       'POBLACION DISTRICT I',
//       'POBLACION DISTRICT II',
//       'POBLACION DISTRICT III',
//       'POBLACION DISTRICT IV',
//       'RIZAL I',
//       'RIZAL II',
//       'SAN AGUSTIN',
//       'SAN ISIDRO',
//       'SAN RICARDO',
//       'SANGPUTAN',
//       'TAGUITE',
//       'UBAN',
//       'VICTORY',
//       'VILLA MAGSAYSAY',
//     ],
//   },
//   BARUGO: {
//     province: 'LEYTE',
//     municipality: 'BARUGO',
//     region: 'REGION VIII',
//     barangay_list: [
//       'ABANGO',
//       'AMAHIT',
//       'BALIRE',
//       'BALUD',
//       'BUKID',
//       'BULOD',
//       'BUSAY',
//       'CABARASAN',
//       'CABOLO-AN',
//       'CALINGCAGUING',
//       'CAN-ISAK',
//       'CANOMANTAG',
//       'CUTA',
//       'DOMOGDOG',
//       'DUKA',
//       'GUINDAOHAN',
//       'HIAGSAM',
//       'HILABA',
//       'HINUGAYAN',
//       'IBAG',
//       'MINUHANG',
//       'MINUSWANG',
//       'PIKAS',
//       'PITOGO',
//       'POBLACION DIST. I',
//       'POBLACION DIST. II',
//       'POBLACION DIST. III',
//       'POBLACION DIST. IV',
//       'POBLACION DIST. V',
//       'POBLACION DIST. VI (NEW ROAD)',
//       'PONGSO',
//       'ROOSEVELT',
//       'SAN ISIDRO',
//       'SAN ROQUE',
//       'SANTA ROSA',
//       'SANTARIN',
//       'TUTUG-AN',
//     ],
//   },
//   'BAYBAY': {
//     province: 'LEYTE',
//     municipality: 'BAYBAY',
//     region: 'REGION VIII',
//     barangay_list: [
//       'ALTAVISTA',
//       'AMBACAN',
//       'AMGUHAN',
//       'AMPIHANON',
//       'BALAO',
//       'BANAHAO',
//       'BIASONG',
//       'BIDLINAN',
//       'BITANHUAN',
//       'BUBON',
//       'BUENAVISTA',
//       'BUNGA',
//       'BUTIGAN',
//       'CANDADAM',
//       'CARIDAD',
//       'CIABO',
//       'COGON',
//       'GA-AS',
//       'GABAS',
//       'GAKAT',
//       'GUADALUPE (UTOD)',
//       'GUBANG',
//       'HIBUNAWAN',
//       'HIGULOAN',
//       'HILAPNITAN',
//       'HIPUSNGO',
//       'IGANG',
//       'IMELDA',
//       'JAENA',
//       'KABALASAN',
//       'KABATUAN',
//       'KABUNGAAN',
//       'KAGUMAY',
//       'KAMBONGGAN',
//       'KAN-IPA',
//       'KANSUNGKA',
//       'KANTAGNOS',
//       'KILIM',
//       'LINTAON',
//       'MAGANHAN',
//       'MAHAYAHAY',
//       'MAILHI',
//       'MAITUM',
//       'MAKINHAS',
//       'MAPGAP',
//       'MARCOS',
//       'MASLUG',
//       'MATAM-IS',
//       'MAYBOG',
//       'MAYPATAG',
//       'MONTE VERDE',
//       'MONTERICO',
//       'PALHI',
//       'PANGASUNGAN',
//       'PANSAGAN',
//       'PATAG',
//       'PLARIDEL',
//       'POBLACION ZONE 1',
//       'POBLACION ZONE 2',
//       'POBLACION ZONE 3',
//       'POBLACION ZONE 4',
//       'POBLACION ZONE 5',
//       'POBLACION ZONE 6',
//       'POBLACION ZONE 7',
//       'POBLACION ZONE 8',
//       'POBLACION ZONE 9',
//       'POBLACION ZONE 10',
//       'POBLACION ZONE 11',
//       'POBLACION ZONE 12',
//       'POBLACION ZONE 13',
//       'POBLACION ZONE 14',
//       'POBLACION ZONE 15',
//       'POBLACION ZONE 16',
//       'POBLACION ZONE 17',
//       'POBLACION ZONE 18',
//       'POBLACION ZONE 19',
//       'POBLACION ZONE 20',
//       'POBLACION ZONE 21',
//       'POBLACION ZONE 22',
//       'POBLACION ZONE 23',
//       'POMPONAN',
//       'PUNTA',
//       'SABANG',
//       'SAN AGUSTIN',
//       'SAN ISIDRO',
//       'SAN JUAN',
//       'SANTA CRUZ',
//       'SANTO ROSARIO',
//       'SAPA',
//       'VILLA MAG-ASO',
//       'VILLA SOLIDARIDAD',
//       'ZACARITO',
//     ],
//   },
//   BURAUEN: {
//     province: 'LEYTE',
//     municipality: 'BURAUEN',
//     region: 'REGION VIII',
//     barangay_list: [
//       'ABUYOGON',
//       'ANONANG',
//       'ARADO',
//       'BALAO',
//       'BALATSON',
//       'BALORINAY',
//       'BOBON',
//       'BUENAVISTA',
//       'BURI',
//       'CAANISLAGAN',
//       'CADAHUNAN',
//       'CAGANGON',
//       'CALI',
//       'CALSADAHAY',
//       'CANDAG-ON',
//       'CANSIBOY',
//       'CATAGBACAN',
//       'DAMULO-AN',
//       'DINA-AYAN',
//       'DUMALAG (PUSOD)',
//       'ESPERANZA',
//       'GAMAY',
//       'GITABLAN',
//       'HAPUNAN',
//       'HIBONAWAN',
//       'HUGPA EAST',
//       'HUGPA WEST',
//       'ILIHAN',
//       'KAGBANA',
//       'KALAO',
//       'KALIPAYAN',
//       'KAPARASANAN',
//       'LAGUIWAN',
//       'LIBAS',
//       'LIMBURAN',
//       'LOGSONGAN',
//       'MAABAB',
//       'MAGHUBAS',
//       'MAHAGNAO',
//       'MALABCA',
//       'MALAGUINABOT',
//       'MALAIHAO (SAN RAMON)',
//       'MATIN-AO',
//       'MOGUING',
//       'PAGHUDLAN',
//       'PAITAN',
//       'PANGDAN',
//       'PATAG',
//       'PATONG',
//       'PAWA',
//       'POBLACION DISTRICT I',
//       'POBLACION DISTRICT II',
//       'POBLACION DISTRICT III',
//       'POBLACION DISTRICT IV',
//       'POBLACION DISTRICT IX',
//       'POBLACION DISTRICT V',
//       'POBLACION DISTRICT VI',
//       'POBLACION DISTRICT VII',
//       'POBLACION DISTRICT VIII',
//       'ROXAS',
//       'SAMBEL',
//       'SAN ESTEBAN',
//       'SAN FERNANDO',
//       'SAN JOSE EAST',
//       'SAN JOSE WEST',
//       'SAN PABLO',
//       'TABUANON',
//       'TAGADTARAN',
//       'TAGHUYAN',
//       'TAKIN',
//       'TAMBIS (NABOYA)',
//       'TAMBUKO',
//       'TOLOYAO',
//       'VILLA AURORA',
//       'VILLA CORAZON',
//       'VILLA PATRIA',
//       'VILLA ROSAS (CABANG)',
//     ],
//   },
//   CALUBIAN: {
//     province: 'LEYTE',
//     municipality: 'CALUBIAN',
//     region: 'REGION VIII',
//     barangay_list: [
//       'ABANILLA',
//       'AGAS',
//       'ANISLAGAN',
//       'BUNACAN',
//       'CABALHIN',
//       'CABALQUINTO',
//       'CABRADILLA',
//       'CANEJA',
//       'CANTONGHAO',
//       'CAROYOCAN',
//       'CASIONGAN',
//       'CRISTINA',
//       'DALUMPINES',
//       'DON LUIS',
//       'DULAO',
//       'EFE',
//       'ENAGE',
//       'ESPINOSA',
//       'FERDINAND E. MARCOS',
//       'GARGANERA',
//       'GARRIDO',
//       'GUADALUPE (GUADALUPE MENDOZA)',
//       'GUTOSAN',
//       'IGANG',
//       'INALAD',
//       'JUBAY',
//       'JUSON',
//       'KAWAYAN BOGTONG',
//       'KAWAYANAN',
//       'KOKOY ROMUALDEZ',
//       'LABTIC',
//       'LARAY',
//       'M. VELOSO',
//       'MAHAIT',
//       'MALOBAGO',
//       'MATAGOK',
//       'NIPA',
//       'OBISPO',
//       'PADOGA',
//       'PAGATPAT',
//       'PANGPANG',
//       'PATAG',
//       'PATES',
//       'PAULA',
//       'PETROLIO',
//       'POBLACION',
//       'RAILES',
//       'TABLA',
//       'TAGHARIGUE',
//       'TUBURAN',
//       'VILLAHERMOSA',
//       'VILLALON',
//       'VILLANUEVA',
//     ],
//   },
//   CAPOOCAN: {
//     province: 'LEYTE',
//     municipality: 'CAPOOCAN',
//     region: 'REGION VIII',
//     barangay_list: [
//       'BALUCANAD',
//       'BALUD',
//       'BALUGO',
//       'CABUL-AN',
//       'CULASIAN',
//       'GAYAD',
//       'GUINADIONGAN',
//       'LEMON',
//       'LIBERTAD',
//       'MANLOY',
//       'NAUGUISAN',
//       'PINAMOPOAN',
//       'POBLACION ZONE I',
//       'POBLACION ZONE II',
//       'POTOT',
//       'SAN JOAQUIN',
//       'SANTO NIÑO',
//       'TALAIRAN',
//       'TALISAY',
//       'TOLIBAO',
//       'VISARES',
//     ],
//   },
//   CARIGARA: {
//     province: 'LEYTE',
//     municipality: 'CARIGARA',
//     region: 'REGION VIII',
//     barangay_list: [
//       'BAGONG LIPUNAN',
//       'BALILIT',
//       'BARAYONG',
//       'BARUGOHAY CENTRAL',
//       'BARUGOHAY NORTE',
//       'BARUGOHAY SUR',
//       'BAYBAY (POB.)',
//       'BINIBIHAN',
//       'BISLIG',
//       'CAGHALO',
//       'CAMANSI',
//       'CANAL',
//       'CANDIGAHUB',
//       'CANFABI',
//       'CANLAMPAY',
//       'COGON',
//       'CUTAY',
//       'EAST VISORIA',
//       'GUINDAPUNAN EAST',
//       'GUINDAPUNAN WEST',
//       'HILUCTOGAN',
//       'JUGABAN (POB.)',
//       'LIBO',
//       'LOWER HIRAAN',
//       'LOWER SOGOD',
//       'MACALPI',
//       'MANLOY',
//       'NAUGUISAN',
//       'PAGLAUM',
//       'PANGNA',
//       'PARAG-UM',
//       'PARINA',
//       'PILORO',
//       'PONONG (POB.)',
//       'RIZAL (TAGAK EAST)',
//       'SAGKAHAN',
//       'SAN ISIDRO',
//       'SAN JUAN',
//       'SAN MATEO (POB.)',
//       'SANTA FE',
//       'SAWANG (POB.)',
//       'TAGAK',
//       'TANGNAN',
//       'TIGBAO',
//       'TINAGUBAN',
//       'UPPER HIRAAN',
//       'UPPER SOGOD',
//       'UYAWAN',
//       'WEST VISORIA',
//     ],
//   },
//   DAGAMI: {
//     province: 'LEYTE',
//     municipality: 'DAGAMI',
//     region: 'REGION VIII',
//     barangay_list: [
//       'ABACA',
//       'ABRE',
//       'BALILIT',
//       'BALUGO',
//       'BANAYON',
//       'BAYABAS',
//       'BOLIRAO',
//       'BUENAVISTA',
//       'BUNTAY',
//       'CAANISLAGAN',
//       'CABARIWAN',
//       'CABULORAN',
//       'CABUNGA-AN',
//       'CALIPAYAN',
//       'CALSADAHAY',
//       'CALUCTOGAN',
//       'CALUTAN',
//       'CAMONO-AN',
//       'CANDAGARA',
//       'CANLINGGA',
//       'CANSAMADA EAST',
//       'CANSAMADA WEST',
//       'CAPULHAN',
//       'DIGAHONGAN',
//       'GUINARONA',
//       'HIABANGAN',
//       'HILABAGO',
//       'HINABUYAN',
//       'HINOLOGAN',
//       'HITUMNOG',
//       'KATIPUNAN',
//       'LAPU-LAPU POB. (DIST. 2)',
//       'LOBE-LOBE',
//       'LOBE-LOBE EAST',
//       'LOS MARTIRES',
//       'LUSAD POB. (DIST. 6)',
//       'MACAALANG',
//       'MALIWALIW',
//       'MARAGONDONG',
//       'ORMOCAY',
//       'PALACIO',
//       'PANDA',
//       'PARAISO',
//       'PATOC',
//       'PLARIDEL',
//       'POPONTON',
//       'RIZAL',
//       'SALVACION',
//       'SAMPAGUITA',
//       'SAMPAO EAST POB. (DIST. 9)',
//       'SAMPAO WEST POB. (DIST. 8)',
//       'SAN ANTONIO POB. (DIST. 5)',
//       'SAN BENITO',
//       'SAN JOSE POB. (DIST. 1)',
//       'SAN ROQUE POB. (DIST. 3)',
//       'SANTO DOMINGO',
//       'SAWAHON',
//       'SIRAB',
//       'STA. MESA POB. (DIST. 7)',
//       'TAGKIP',
//       'TALINHUGON',
//       'TIN-AO',
//       'TUNGA POB. (DIST. 4)',
//       'TUYA',
//       'VICTORIA',
//     ],
//   },
//   DULAG: {
//     province: 'LEYTE',
//     municipality: 'DULAG',
//     region: 'REGION VIII',
//     barangay_list: [
//       'ALEGRE',
//       'ARADO',
//       'BARBO (POB.)',
//       'BATUG',
//       'BOLONGTOHAN',
//       'BULOD',
//       'BUNTAY (POB.)',
//       'CABACUNGAN',
//       'CABARASAN',
//       'CABATO-AN',
//       'CALIPAYAN',
//       'CALUBIAN',
//       'CAMBULA DISTRICT (POB.)',
//       'CAMITOC',
//       'CAMOTE',
//       'CANDAO (POB.)',
//       'CATMONAN (POB.)',
//       'COMBIS (POB.)',
//       'DACAY',
//       'DEL CARMEN',
//       'DEL PILAR',
//       'FATIMA',
//       'GENERAL ROXAS',
//       'HIGHWAY (POB.)',
//       'LUAN',
//       'MAGSAYSAY',
//       'MARICUM',
//       'MARKET SITE (POB.)',
//       'RAWIS',
//       'RIZAL',
//       'ROMUALDEZ',
//       'SABANG DAGUITAN',
//       'SALVACION',
//       'SAN AGUSTIN',
//       'SAN ANTONIO',
//       'SAN ISIDRO',
//       'SAN JOSE',
//       'SAN MIGUEL (POB.)',
//       'SAN RAFAEL',
//       'SAN VICENTE',
//       'SERRANO (POB.)',
//       'SUNGI (POB.)',
//       'TABU',
//       'TIGBAO',
//       'VICTORY',
//     ],
//   },
//   HILONGOS: {
//     province: 'LEYTE',
//     municipality: 'HILONGOS',
//     region: 'REGION VIII',
//     barangay_list: [
//       'AGUTAYAN',
//       'ATABAY',
//       'BAAS',
//       'BAGONG LIPUNAN',
//       'BAGUMBAYAN',
//       'BALIW',
//       'BANTIGUE',
//       'BON-OT',
//       'BUNG-AW',
//       'CACAO',
//       'CAMPINA',
//       'CATANDOG 1',
//       'CATANDOG 2',
//       'CENTRAL BARANGAY (POB.)',
//       'CONCEPCION',
//       'EASTERN BARANGAY (POB.)',
//       'HAMPANGAN',
//       'HIMO-AW',
//       'HITUDPAN',
//       'IMELDA MARCOS (PONG-ON)',
//       'KANG-IRAS',
//       'KANGHA-AS',
//       'LAMAK',
//       'LIBERTAD',
//       'LIBERTY',
//       'LUNANG',
//       'MAGNANGOY',
//       'MANAUL',
//       'MARANGOG',
//       'MATAPAY',
//       'NAVAL',
//       'OWAK',
//       'PA-A',
//       'PONTOD',
//       'PROTECCION',
//       'SAN AGUSTIN',
//       'SAN ANTONIO',
//       'SAN ISIDRO',
//       'SAN JUAN',
//       'SAN ROQUE',
//       'SANTA CRUZ',
//       'SANTA MARGARITA',
//       'SANTO NIÑO',
//       'TABUNOK',
//       'TAGNATE',
//       'TALISAY',
//       'TAMBIS',
//       'TEJERO',
//       'TUGUIPA',
//       'UTANAN',
//       'WESTERN BARANGAY (POB.)',
//     ],
//   },
//   HINDANG: {
//     province: 'LEYTE',
//     municipality: 'HINDANG',
//     region: 'REGION VIII',
//     barangay_list: [
//       'ANAHAW',
//       'ANOLON',
//       'BALDOZA',
//       'BONTOC',
//       'BULACAN',
//       'CANHA-AYON',
//       'CAPUDLOSAN',
//       'DOOS DEL NORTE',
//       'DOOS DEL SUR',
//       'HIMACUGO',
//       'HIMOKILAN ISLAND',
//       'KATIPUNAN',
//       'MAASIN',
//       'MABAGON',
//       'MAHILUM',
//       'POBLACION 1',
//       'POBLACION 2',
//       'SAN VICENTE',
//       'TABOK',
//       'TAGBIBI',
//     ],
//   },
//   INOPACAN: {
//     province: 'LEYTE',
//     municipality: 'INOPACAN',
//     region: 'REGION VIII',
//     barangay_list: [
//       'APID',
//       'CABULISAN',
//       'CAMINTO',
//       'CAN-ANGAY',
//       'CAULISIHAN',
//       'CONALUM',
//       'DE LOS SANTOS (MAHILUM)',
//       'ESPERANZA',
//       'GUADALUPE',
//       'GUINSANGA-AN',
//       'HINABAY',
//       'JUBASAN',
//       'LINAO',
//       'MACAGOCO',
//       'MALJO',
//       'MARAO',
//       'POBLACION',
//       'TAHUD',
//       'TAOTAON',
//       'TINAGO',
//     ],
//   },
//   ISABEL: {
//     province: 'LEYTE',
//     municipality: 'ISABEL',
//     region: 'REGION VIII',
//     barangay_list: [
//       'ANISLAG',
//       'ANTIPOLO',
//       'APALE',
//       'BANTIGUE',
//       'BENOG',
//       'BILWANG',
//       'CAN-ANDAN',
//       'CANGAG',
//       'CONSOLACION',
//       'HONAN',
//       'LIBERTAD',
//       'MAHAYAG',
//       'MARVEL (POB.)',
//       'MATLANG',
//       'MONTE ALEGRE',
//       'PUTING BATO',
//       'SAN FRANCISCO',
//       'SAN ROQUE',
//       'SANTA CRUZ',
//       'SANTO NIÑO (POB.)',
//       'SANTO ROSARIO',
//       'TABUNOK',
//       'TOLINGON',
//       'TUBOD',
//     ],
//   },
//   JARO: {
//     province: 'LEYTE',
//     municipality: 'JARO',
//     region: 'REGION VIII',
//     barangay_list: [
//       'ALAHAG',
//       'ANIBONGAN',
//       'ATIPOLO',
//       'BADIANG',
//       'BATUG',
//       'BIAS ZABALA',
//       'BUENAVISTA',
//       'BUKID',
//       'BURABOD',
//       'BURI',
//       'CANAPUAN',
//       'CANHANDUGAN',
//       'CROSSING RUBAS',
//       'DARO',
//       'DISTRICT I (POB.)',
//       'DISTRICT II (POB.)',
//       'DISTRICT III (POB.)',
//       'DISTRICT IV (POB.)',
//       'HIAGSAM',
//       'HIBUCAWAN',
//       'HIBUNAWON',
//       'KAGLAWAAN',
//       'KALINAWAN',
//       'LA PAZ',
//       'LIKOD',
//       'MACANIP',
//       'MACOPA',
//       'MAG-ASO',
//       'MALOBAGO',
//       'OLOTAN',
//       'PALANOG',
//       'PANGE',
//       'PARASAN',
//       'PITOGO',
//       'SAGKAHAN',
//       'SAN AGUSTIN',
//       'SAN PEDRO',
//       'SAN ROQUE',
//       'SANTA CRUZ',
//       'SANTO NIÑO',
//       'SARI-SARI',
//       'TINAMBACAN',
//       'TUBA',
//       'UGUIAO',
//       'VILLA PAZ',
//       'VILLAGONZOILO (VILLA CONSUELO)',
//     ],
//   },
//   'JAVIER (BUGHO)': {
//     province: 'LEYTE',
//     municipality: 'JAVIER (BUGHO)',
//     region: 'REGION VIII',
//     barangay_list: [
//       'ABUYOGAY',
//       'ANDRES BONIFACIO',
//       'BATUG',
//       'BINULHO',
//       'CALZADA',
//       'CANCAYANG',
//       'CARANHUG',
//       'CARAYE',
//       'CASALUNGAN',
//       'COMATIN',
//       'GUINDAPUNAN',
//       'INAYUPAN',
//       'LARAY',
//       'MAGSAYSAY (RESPONDE)',
//       'MALITBOGAY',
//       'MANARUG',
//       'MANLILISID',
//       'NALIWATAN',
//       'ODIONG',
//       'PICAS NORTE',
//       'PINOCAWAN',
//       'POBLACION ZONE 1',
//       'POBLACION ZONE 2',
//       'RIZAL',
//       'SAN SOTERO (TAMBIS)',
//       'SANTA CRUZ',
//       'TALISAYAN',
//       'ULHAY',
//     ],
//   },
//   JULITA: {
//     province: 'LEYTE',
//     municipality: 'JULITA',
//     region: 'REGION VIII',
//     barangay_list: [
//       'ALEGRIA',
//       'ANIBONG',
//       'ASLUM',
//       'BALANTE',
//       'BONGDO',
//       'BONIFACIO',
//       'BUGHO',
//       'CALBASAG',
//       'CARIDAD',
//       'CUYA-E',
//       'DITA',
//       'GITABLA',
//       'HINDANG',
//       'INAWANGAN',
//       'JURAO',
//       'POBLACION DISTRICT I',
//       'POBLACION DISTRICT II',
//       'POBLACION DISTRICT III',
//       'POBLACION DISTRICT IV',
//       'SAN ANDRES',
//       'SAN PABLO',
//       'SANTA CRUZ',
//       'SANTO NIÑO',
//       'TAGKIP',
//       'TOLOSAHAY',
//       'VILLA HERMOSA',
//     ],
//   },
//   KANANGA: {
//     province: 'LEYTE',
//     municipality: 'KANANGA',
//     region: 'REGION VIII',
//     barangay_list: [
//       'AGUITING',
//       'CACAO',
//       'HILUCTOGAN',
//       'KAWAYAN',
//       'LIBERTAD',
//       'LIBONGAO',
//       'LIM-AO',
//       'LONOY',
//       'MAHAWAN',
//       'MASARAYAO',
//       'MONTE ALEGRE',
//       'MONTE BELLO',
//       'NAGHALIN',
//       'NATUBGAN',
//       'POBLACION',
//       'RIZAL',
//       'SAN IGNACIO',
//       'SAN ISIDRO',
//       'SANTO DOMINGO',
//       'SANTO NIÑO',
//       'TAGAYTAY',
//       'TONGONAN',
//       'TUGBONG',
//     ],
//   },
//   LEYTE: {
//     province: 'LEYTE',
//     municipality: 'LEYTE',
//     region: 'REGION VIII',
//     barangay_list: [
//       'BACHAO',
//       'BACO',
//       'BAGABA-O',
//       'BASUD',
//       'BELEN',
//       'BURABOD',
//       'CALAGUISE',
//       'CONSUEGRA',
//       'CULASI',
//       'DANUS',
//       'ELIZABETH',
//       'KAWAYAN',
//       'LIBAS',
//       'MAANDA',
//       'MACUPA',
//       'MATALOTO',
//       'PALARAO',
//       'PALID I (ILAWOD)',
//       'PALID II (IRAYA)',
//       'PARASAN',
//       'POBLACION',
//       'SALOG',
//       'SAMBULAWAN',
//       'TAG-ABACA',
//       'TAPOL',
//       'TIGBAWAN',
//       'TINOCDUGAN',
//       'TOCTOC',
//       'UGBON',
//       'WAGUE',
//     ],
//   },
//   MACARTHUR: {
//     province: 'LEYTE',
//     municipality: 'MACARTHUR',
//     region: 'REGION VIII',
//     barangay_list: [
//       'BATUG',
//       'BURABOD',
//       'CAPUDLOSAN',
//       'CASUNTINGAN',
//       'CAUSWAGAN',
//       'DANAO',
//       'DOÑA JOSEFA',
//       'GENERAL LUNA',
//       'KILING',
//       'LANAWAN',
//       'LIWAYWAY',
//       'MAYA',
//       'OGUISAN',
//       'OSMEÑA',
//       'PALALE 1',
//       'PALALE 2',
//       'POBLACION DISTRICT 1',
//       'POBLACION DISTRICT 2',
//       'POBLACION DISTRICT 3',
//       'PONGON',
//       'QUEZON',
//       'ROMUALDEZ',
//       'SALVACION',
//       'SAN ANTONIO',
//       'SAN ISIDRO',
//       'SAN PEDRO',
//       'SAN VICENTE',
//       'SANTA ISABEL',
//       'TINAWAN',
//       'TUYO',
//       'VILLA IMELDA',
//     ],
//   },
//   MAHAPLAG: {
//     province: 'LEYTE',
//     municipality: 'MAHAPLAG',
//     region: 'REGION VIII',
//     barangay_list: [
//       'CAMPIN',
//       'CUATRO DE AGOSTO',
//       'HILUCTOGAN',
//       'HILUSIG',
//       'HIMAMARA',
//       'HINAGUIMITAN',
//       'LIBERACION',
//       'MABUHAY',
//       'MABUNGA',
//       'MAGSUGANAO',
//       'MAHAYAG',
//       'MAHAYAHAY',
//       'MALIGAYA',
//       'MALINAO',
//       'MALIPOON',
//       'PALAÑOGAN',
//       'PARIL',
//       'PINAMONOAN',
//       'POBLACION',
//       'POLAHONGON',
//       'SAN ISIDRO',
//       'SAN JUAN',
//       'SANTA CRUZ',
//       'SANTO NIÑO',
//       'TAGAYTAY',
//       'UGUIS',
//       'UNION',
//       'UPPER MAHAPLAG',
//     ],
//   },
//   'MATAG-OB': {
//     province: 'LEYTE',
//     municipality: 'MATAG-OB',
//     region: 'REGION VIII',
//     barangay_list: [
//       'BALAGTAS',
//       'BONOY (POB.)',
//       'BULAK',
//       'CAMBADBAD',
//       'CANDELARIA',
//       'CANSOSO',
//       'IMELDA',
//       'MALAZARTE',
//       'MANSAHAON',
//       'MANSALIP (POB.)',
//       'MASABA',
//       'NAULAYAN',
//       'RIVERSIDE (POB.)',
//       'SAN DIONISIO',
//       'SAN GUILLERMO',
//       'SAN MARCELINO',
//       'SAN SEBASTIAN',
//       'SAN VICENTE',
//       'SANTA ROSA',
//       'SANTO ROSARIO',
//       'TALISAY (POB.)',
//     ],
//   },
//   MATALOM: {
//     province: 'LEYTE',
//     municipality: 'MATALOM',
//     region: 'REGION VIII',
//     barangay_list: [
//       'AGBANGA',
//       'ALTAVISTA',
//       'BAGONG LIPUNAN',
//       'CAHAGNAAN',
//       'CALUMPANG',
//       'CANINGAG',
//       'CARIDAD NORTE',
//       'CARIDAD SUR',
//       'ELEVADO',
//       'ESPERANZA',
//       'HITOOG',
//       'ITUM',
//       'LOWAN',
//       'MONTE ALEGRE',
//       'PRESIDENT GARCIA',
//       'PUNONG',
//       'SAN ISIDRO (POB.)',
//       'SAN JUAN',
//       'SAN PEDRO (POB.)',
//       'SAN SALVADOR',
//       'SAN VICENTE',
//       'SANTA FE',
//       'SANTA PAZ',
//       'SANTO NIÑO (POB.)',
//       'TAG-OS',
//       'TAGLIBAS IMELDA',
//       'TEMPLANZA',
//       'TIGBAO',
//       'WATERLOO',
//       'ZARAGOZA',
//     ],
//   },
//   MAYORGA: {
//     province: 'LEYTE',
//     municipality: 'MAYORGA',
//     region: 'REGION VIII',
//     barangay_list: [
//       'A. BONIFACIO',
//       'BURGOS',
//       'CALIPAYAN',
//       'CAMANSI',
//       'GENERAL ANTONIO LUNA',
//       'LIBERTY',
//       'MABINI',
//       'ORMOCAY',
//       'POBLACION ZONE 1',
//       'POBLACION ZONE 2',
//       'POBLACION ZONE 3',
//       'SAN ROQUE',
//       'SANTA CRUZ',
//       'TALISAY',
//       'UNION',
//       'WILSON',
//     ],
//   },
//   MERIDA: {
//     province: 'LEYTE',
//     municipality: 'MERIDA',
//     region: 'REGION VIII',
//     barangay_list: [
//       'BINABAYE',
//       'CABALIWAN',
//       'CALUNANGAN',
//       'CALUNASAN',
//       'CAMBALONG',
//       'CAN-UNZO',
//       'CANBANTUG',
//       'CASILDA',
//       'LAMANOC',
//       'LIBAS',
//       'LIBJO',
//       'LUNDAG',
//       'MACARIO',
//       'MAHALIT',
//       'MAHAYAG',
//       'MASUMBANG',
//       'MAT-E',
//       'POBLACION',
//       'PUERTO BELLO',
//       'SAN ISIDRO',
//       'SAN JOSE',
//       'TUBOD',
//     ],
//   },
//   'ORMOC': {
//     province: 'LEYTE',
//     municipality: 'ORMOC',
//     region: 'REGION VIII',
//     barangay_list: [
//       'AIRPORT',
//       'ALEGRIA',
//       'ALTA VISTA',
//       'BAGONG',
//       'BAGONG BUHAY',
//       'BANTIGUE',
//       'BARANGAY 1 (POB.)',
//       'BARANGAY 2 (POB.)',
//       'BARANGAY 3 (POB.)',
//       'BARANGAY 4 (POB.)',
//       'BARANGAY 5 (POB.)',
//       'BARANGAY 6 (POB.)',
//       'BARANGAY 7 (POB.)',
//       'BARANGAY 8 (POB.)',
//       'BARANGAY 9 (POB.)',
//       'BARANGAY 10 (POB.)',
//       'BARANGAY 11 (POB.)',
//       'BARANGAY 12 (POB.)',
//       'BARANGAY 13 (POB.)',
//       'BARANGAY 14 (POB.)',
//       'BARANGAY 15 (POB.)',
//       'BARANGAY 16 (POB.)',
//       'BARANGAY 17 (POB.)',
//       'BARANGAY 18 (POB.)',
//       'BARANGAY 19 (POB.)',
//       'BARANGAY 20 (POB.)',
//       'BARANGAY 21 (POB.)',
//       'BARANGAY 22 (POB.)',
//       'BARANGAY 23 (POB.)',
//       'BARANGAY 24 (POB.)',
//       'BARANGAY 25 (POB.)',
//       'BARANGAY 26 (POB.)',
//       'BARANGAY 27 (POB.)',
//       'BARANGAY 28 (POB.)',
//       'BARANGAY 29 (POB.)',
//       'BATUAN',
//       'BAYOG',
//       'BILIBOY',
//       'BOROK',
//       'CABAON-AN',
//       'CABINTAN',
//       'CABULIHAN',
//       'CAGBUHANGIN',
//       'CAMP DOWNES',
//       'CAN-ADIENG',
//       'CAN-UNTOG',
//       'CATMON',
//       'COGON COMBADO',
//       'CONCEPCION',
//       'CURVA',
//       'DANAO',
//       'DANHUG',
//       'DAYHAGAN',
//       'DOLORES',
//       'DOMONAR',
//       'DON FELIPE LARRAZABAL',
//       'DON POTENCIANO LARRAZABAL',
//       'DONGHOL',
//       'DOÑA FELIZA Z. MEJIA',
//       'ESPERANZA',
//       'GAAS',
//       'GREEN VALLEY',
//       'GUINTIGUI-AN',
//       'HIBUNAWON',
//       'HUGPA',
//       'IPIL',
//       'JUATON',
//       'KADAOHAN',
//       'LABRADOR (BALION)',
//       'LAO',
//       'LEONDONI',
//       'LIBERTAD',
//       'LIBERTY',
//       'LICUMA',
//       'LILOAN',
//       'LINAO',
//       'LUNA',
//       'MABATO',
//       'MABINI',
//       'MACABUG',
//       'MAGASWI',
//       'MAHAYAG',
//       'MAHAYAHAY',
//       'MANLILINAO',
//       'MARGEN',
//       'MAS-IN',
//       'MATICA-A',
//       'MILAGRO',
//       'MONTERICO',
//       'NASUNOGAN',
//       'NAUNGAN',
//       'NUEVA SOCIEDAD',
//       'NUEVA VISTA',
//       'PATAG',
//       'PUNTA',
//       'QUEZON, JR.',
//       'RUFINA M. TAN',
//       'SABANG BAO',
//       'SALVACION',
//       'SAN ANTONIO',
//       'SAN ISIDRO',
//       'SAN JOSE',
//       'SAN JUAN',
//       'SAN PABLO (SIMANGAN)',
//       'SAN VICENTE',
//       'SANTO NIÑO',
//       'SUMANGGA',
//       'TAMBULILID',
//       'TONGONAN',
//       'VALENCIA',
//     ],
//   },
//   PALO: {
//     province: 'LEYTE',
//     municipality: 'PALO',
//     region: 'REGION VIII',
//     barangay_list: [
//       'ANAHAWAY',
//       'ARADO',
//       'BARAS',
//       'BARAYONG',
//       'BURI (POB.)',
//       'CABARASAN DAKU',
//       'CABARASAN GUTI',
//       'CAMPETIK',
//       'CANDAHUG',
//       'CANGUMBANG',
//       'CANHIDOC',
//       'CAPIRAWAN',
//       'CASTILLA',
//       'CAVITE EAST (POB.)',
//       'CAVITE WEST (POB.)',
//       'COGON (COGON SAN JOAQUIN)',
//       'GACAO',
//       'GUINDAPUNAN',
//       'LIBERTAD',
//       'LUNTAD (POB.)',
//       'NAGA-NAGA',
//       'PAWING',
//       'SALVACION',
//       'SAN AGUSTIN',
//       'SAN ANTONIO',
//       'SAN FERNANDO',
//       'SAN ISIDRO',
//       'SAN JOAQUIN',
//       'SAN JOSE',
//       'SAN MIGUEL (POB.)',
//       'SANTA CRUZ (POB.)',
//       'TACURANGA',
//       'TERAZA',
//     ],
//   },
//   PALOMPON: {
//     province: 'LEYTE',
//     municipality: 'PALOMPON',
//     region: 'REGION VIII',
//     barangay_list: [
//       'BAGUINBIN',
//       'BELEN',
//       'BITAOG POB. (YPIL III)',
//       'BUENAVISTA',
//       'CADUHAAN',
//       'CAMBAKBAK',
//       'CAMBINOY',
//       'CANGCOSME',
//       'CANGMUYA',
//       'CANIPAAN',
//       'CANTANDOY',
//       'CANTUHAON',
//       'CATIGAHAN',
//       'CENTRAL 1 (POB.)',
//       'CENTRAL 2 (POB.)',
//       'CRUZ',
//       'DULJUGAN',
//       'GUIWAN 1 (POB.)',
//       'GUIWAN 2 (POB.)',
//       'HIMARCO',
//       'HINABLAYAN POB. (CENTRAL 3)',
//       'HINAGBUAN',
//       'LAT-OSAN',
//       'LIBERTY',
//       'LOMONON',
//       'MABINI',
//       'MAGSAYSAY',
//       'MASABA',
//       'MAZAWALO POB. (LILI-ON)',
//       'PARILLA',
//       'PINAGDAIT POB. (YPIL I)',
//       'PINAGHI-USA POB. (YPIL II)',
//       'PLARIDEL',
//       'RIZAL',
//       'SABANG',
//       'SAN GUILLERMO',
//       'SAN ISIDRO',
//       'SAN JOAQUIN',
//       'SAN JUAN',
//       'SAN MIGUEL',
//       'SAN PABLO',
//       'SAN PEDRO',
//       'SAN ROQUE',
//       'SANTIAGO',
//       'TABERNA',
//       'TABUNOK',
//       'TAMBIS',
//       'TINABILAN',
//       'TINAGO',
//       'TINUBDAN',
//     ],
//   },
//   PASTRANA: {
//     province: 'LEYTE',
//     municipality: 'PASTRANA',
//     region: 'REGION VIII',
//     barangay_list: [
//       'ARABUNOG',
//       'ARINGIT',
//       'BAHAY',
//       'CABAOHAN',
//       'CALSADAHAY',
//       'CANCARAJA',
//       'CANINOAN',
//       'CAPILLA',
//       'COLAWEN',
//       'DISTRICT 1 (POB.)',
//       'DISTRICT 2 (POB.)',
//       'DISTRICT 3 (POB.)',
//       'DISTRICT 4 (POB.)',
//       'DUMARAG',
//       'GUINDAPUNAN',
//       'HALABA',
//       'JONES',
//       'LANAWAN',
//       'LIMA',
//       'LOURDES',
//       'MACALPIAY',
//       'MALITBOGAY',
//       'MANAYBANAY',
//       'MARICUM',
//       'PATONG',
//       'SAPSAP',
//       'SOCSOCON',
//       'TINGIB',
//       'YAPAD',
//     ],
//   },
//   TABANGO: {
//     province: 'LEYTE',
//     municipality: 'TABANGO',
//     region: 'REGION VIII',
//     barangay_list: [
//       'BUTASON I',
//       'BUTASON II',
//       'CAMPOKPOK',
//       'CATMON',
//       'GIBACUNGAN',
//       'GIMARCO',
//       'INANGATAN',
//       'MANLAWAAN',
//       'OMAGANHAN',
//       'POBLACION',
//       'SANTA ROSA',
//       'TABING',
//       'TUGAS',
//     ],
//   },
//   TABONTABON: {
//     province: 'LEYTE',
//     municipality: 'TABONTABON',
//     region: 'REGION VIII',
//     barangay_list: [
//       'AMANDANGAY',
//       'ASLUM',
//       'BALINGASAG',
//       'BELISONG',
//       'CAMBUCAO',
//       'CAPAHUAN',
//       'DISTRICT I POB. (QUEZON)',
//       'DISTRICT II POB. (RIZAL)',
//       'DISTRICT III POB. (BONIFACIO)',
//       'DISTRICT IV POB. (MACARTHUR)',
//       'GUINGAWAN',
//       'JABONG',
//       'MERCADOHAY',
//       'MERING',
//       'MOHON',
//       'SAN PABLO (MOOC)',
//     ],
//   },
//   'TACLOBAN': {
//     province: 'LEYTE',
//     municipality: 'TACLOBAN',
//     region: 'REGION VIII',
//     barangay_list: [
//       'BARANGAY 2',
//       'BARANGAY 5',
//       'BARANGAY 5-A',
//       'BARANGAY 6',
//       'BARANGAY 6-A',
//       'BARANGAY 7',
//       'BARANGAY 8',
//       'BARANGAY 8-A',
//       'BARANGAY 12 (PALANOG RESETTLEMENT)',
//       'BARANGAY 13',
//       'BARANGAY 14',
//       'BARANGAY 15',
//       'BARANGAY 16',
//       'BARANGAY 17',
//       'BARANGAY 18',
//       'BARANGAY 19',
//       'BARANGAY 20',
//       'BARANGAY 21',
//       'BARANGAY 21-A',
//       'BARANGAY 22',
//       'BARANGAY 23',
//       'BARANGAY 23-A',
//       'BARANGAY 24',
//       'BARANGAY 25',
//       'BARANGAY 26',
//       'BARANGAY 27',
//       'BARANGAY 28',
//       'BARANGAY 29',
//       'BARANGAY 30',
//       'BARANGAY 31',
//       'BARANGAY 32',
//       'BARANGAY 33',
//       'BARANGAY 34',
//       'BARANGAY 35',
//       'BARANGAY 35-A',
//       'BARANGAY 36',
//       'BARANGAY 36-A',
//       'BARANGAY 37',
//       'BARANGAY 37-A',
//       'BARANGAY 38',
//       'BARANGAY 39',
//       'BARANGAY 40',
//       'BARANGAY 41',
//       'BARANGAY 42',
//       'BARANGAY 42-A',
//       'BARANGAY 43',
//       'BARANGAY 43-A',
//       'BARANGAY 43-B',
//       'BARANGAY 44',
//       'BARANGAY 44-A',
//       'BARANGAY 45',
//       'BARANGAY 46',
//       'BARANGAY 47',
//       'BARANGAY 48',
//       'BARANGAY 48-A',
//       'BARANGAY 48-B',
//       'BARANGAY 49',
//       'BARANGAY 50',
//       'BARANGAY 50-A',
//       'BARANGAY 50-B',
//       'BARANGAY 51',
//       'BARANGAY 51-A',
//       'BARANGAY 52',
//       'BARANGAY 53',
//       'BARANGAY 54',
//       'BARANGAY 54-A',
//       'BARANGAY 56',
//       'BARANGAY 56-A',
//       'BARANGAY 57',
//       'BARANGAY 58',
//       'BARANGAY 59',
//       'BARANGAY 59-A',
//       'BARANGAY 59-B',
//       'BARANGAY 60',
//       'BARANGAY 60-A',
//       'BARANGAY 61',
//       'BARANGAY 62',
//       'BARANGAY 62-A',
//       'BARANGAY 62-B',
//       'BARANGAY 63',
//       'BARANGAY 64',
//       'BARANGAY 65',
//       'BARANGAY 66',
//       'BARANGAY 66-A',
//       'BARANGAY 67',
//       'BARANGAY 68',
//       'BARANGAY 69',
//       'BARANGAY 70',
//       'BARANGAY 71',
//       'BARANGAY 72',
//       'BARANGAY 73',
//       'BARANGAY 74',
//       'BARANGAY 75',
//       'BARANGAY 76',
//       'BARANGAY 77',
//       'BARANGAY 78 (MARASBARAS)',
//       'BARANGAY 79 (MARASBARAS)',
//       'BARANGAY 80 (MARASBARAS)',
//       'BARANGAY 81 (MARASBARAS)',
//       'BARANGAY 82 (MARASBARAS)',
//       'BARANGAY 83 (SAN JOSE)',
//       'BARANGAY 83-A (SAN JOSE)',
//       'BARANGAY 83-B',
//       'BARANGAY 83-C (SAN JOSE)',
//       'BARANGAY 84 (SAN JOSE)',
//       'BARANGAY 85 (SAN JOSE)',
//       'BARANGAY 86',
//       'BARANGAY 87',
//       'BARANGAY 88',
//       'BARANGAY 89',
//       'BARANGAY 90 (SAN JOSE)',
//       'BARANGAY 91 (ABUCAY)',
//       'BARANGAY 92 (APITONG)',
//       'BARANGAY 93 (BAGACAY)',
//       'BARANGAY 94 (TIGBAO)',
//       'BARANGAY 94-A',
//       'BARANGAY 95 (CAIBAAN)',
//       'BARANGAY 95-A (CAIBAAN)',
//       'BARANGAY 96 (CALANIPAWAN)',
//       'BARANGAY 97 (CABALAWAN)',
//       'BARANGAY 98 (CAMANSINAY)',
//       'BARANGAY 99 (DIIT)',
//       'BARANGAY 100 (SAN ROQUE)',
//       'BARANGAY 101 (NEW KAWAYAN)',
//       'BARANGAY 102 (OLD KAWAYAN)',
//       'BARANGAY 103 (PALANOG)',
//       'BARANGAY 103-A (SAN PAGLAUM)',
//       'BARANGAY 104 (SALVACION)',
//       'BARANGAY 105 (SUHI)',
//       'BARANGAY 106 (SANTO NIÑO)',
//       'BARANGAY 107 (SANTA ELENA)',
//       'BARANGAY 108 (TAGAPURO)',
//       'BARANGAY 109 (V AND G SUBD.)',
//       'BARANGAY 109-A',
//       'BARANGAY 110 (UTAP)',
//       'EL REPOSO (BARANGAYS 55 AND 55A)',
//       'LIBERTAD (BARANGAYS 1 AND 4)',
//       'NULA-TULA (BGYS. 3 AND 3A)',
//     ],
//   },
//   TANAUAN: {
//     province: 'LEYTE',
//     municipality: 'TANAUAN',
//     region: 'REGION VIII',
//     barangay_list: [
//       'ADA',
//       'AMANLURAN',
//       'ARADO',
//       'ATIPOLO',
//       'BALUD',
//       'BANGON',
//       'BANTAGAN',
//       'BARAS',
//       'BINOLO',
//       'BINONGTO-AN',
//       'BISLIG',
//       'BUNTAY (POB.)',
//       'CABALAGNAN',
//       'CABARASAN GUTI',
//       'CABONGA-AN',
//       'CABUYNAN',
//       'CAHUMAYHUMAYAN',
//       'CALOGCOG',
//       'CALSADAHAY',
//       'CAMIRE',
//       'CANBALISARA',
//       'CANRAMOS (POB.)',
//       'CATIGBIAN',
//       'CATMON',
//       'COGON',
//       'GUINDAG-AN',
//       'GUINGAWAN',
//       'HILAGPAD',
//       'KILING',
//       'LAPAY',
//       'LICOD (POB.)',
//       'LIMBUHAN DAKU',
//       'LIMBUHAN GUTI',
//       'LINAO',
//       'MAGAY',
//       'MAGHULOD',
//       'MALAGUICAY',
//       'MARIBI',
//       'MOHON',
//       'PAGO',
//       'PASIL',
//       'PIKAS',
//       'SACME',
//       'SALVADOR',
//       'SAN ISIDRO',
//       'SAN MIGUEL (POB.)',
//       'SAN ROQUE (POB.)',
//       'SAN VICTOR',
//       'SANTA CRUZ',
//       'SANTA ELENA',
//       'SANTO NIÑO POB. (HACLAGAN)',
//       'SOLANO',
//       'TALOLORA',
//       'TUGOP',
//     ],
//   },
//   TOLOSA: {
//     province: 'LEYTE',
//     municipality: 'TOLOSA',
//     region: 'REGION VIII',
//     barangay_list: [
//       'BURAK',
//       'CANMOGSAY',
//       'CANTARIWIS',
//       'CAPANGIHAN',
//       'DOÑA BRIGIDA',
//       'IMELDA',
//       'MALBOG',
//       'OLOT',
//       'OPONG',
//       'POBLACION',
//       'QUILAO',
//       'SAN ROQUE',
//       'SAN VICENTE',
//       'TANGHAS',
//       'TELEGRAFO',
//     ],
//   },
//   TUNGA: {
//     province: 'LEYTE',
//     municipality: 'TUNGA',
//     region: 'REGION VIII',
//     barangay_list: [
//       'ASTORGA (UPART)',
//       'BALIRE',
//       'BANAWANG',
//       'SAN ANTONIO (POB.)',
//       'SAN PEDRO',
//       'SAN ROQUE',
//       'SAN VICENTE (POB.)',
//       'SANTO NIÑO (POB.)',
//     ],
//   },
//   VILLABA: {
//     province: 'LEYTE',
//     municipality: 'VILLABA',
//     region: 'REGION VIII',
//     barangay_list: [
//       'ABIJAO',
//       'BALITE',
//       'BANGCAL',
//       'BUGABUGA',
//       'CABUNGA-AN',
//       'CABUNGAHAN',
//       'CAGNOCOT',
//       'CAHIGAN',
//       'CALBUGOS',
//       'CAMPOROG',
//       'CANQUIASON',
//       'CAPINYAHAN',
//       'CASILI-ON',
//       'CATAGBACAN',
//       'FATIMA (POB.)',
//       'HIBULANGAN',
//       'HINABUYAN',
//       'ILIGAY',
//       'JALAS',
//       'JORDAN',
//       'LIBAGONG',
//       'NEW BALANAC',
//       'PAYAO',
//       'POBLACION NORTE',
//       'POBLACION SUR',
//       'SAMBULAWAN',
//       'SAN FRANCISCO',
//       'SAN VICENTE',
//       'SANTA CRUZ',
//       'SILAD',
//       'SUBA',
//       'SULPA',
//       'TABUNOK',
//       'TAGBUBUNGA',
//       'TINGHUB',
//     ],
//   },
//   ALLEN: {
//     province: 'NORTHERN SAMAR',
//     municipality: 'ALLEN',
//     region: 'REGION VIII',
//     barangay_list: [
//       'ALEJANDRO VILLAGE (SANTIAGO)',
//       'BONIFACIO',
//       'CABACUNGAN',
//       'CALARAYAN',
//       'FREDERIC',
//       'GUIN-ARAWAYAN',
//       'IMELDA',
//       'JUBASAN',
//       'KINABRANAN ZONE I (POB.)',
//       'KINABRANAN ZONE II (POB.)',
//       'KINAGUITMAN',
//       'LAGUNDI',
//       'LIPATA',
//       'LO-OC',
//       'LONDRES',
//       'SABANG ZONE I (POB.)',
//       'SABANG ZONE II (POB.)',
//       'SANTA RITA',
//       'TASVILLA',
//       'VICTORIA',
//     ],
//   },
//   BIRI: {
//     province: 'NORTHERN SAMAR',
//     municipality: 'BIRI',
//     region: 'REGION VIII',
//     barangay_list: [
//       'KAUSWAGAN (BASUD)',
//       'MACARTHUR',
//       'PIO DEL PILAR',
//       'POBLACION (BIRI)',
//       'PROGRESSO',
//       'SAN ANTONIO',
//       'SAN PEDRO',
//       'SANTO NIÑO',
//     ],
//   },
//   BOBON: {
//     province: 'NORTHERN SAMAR',
//     municipality: 'BOBON',
//     region: 'REGION VIII',
//     barangay_list: [
//       'ACERIDA',
//       'ARELLANO',
//       'BALAT-BALUD',
//       'CALANTIAO (PANGOBI-AN)',
//       'DANCALAN',
//       'E. DURAN',
//       'GEN. LUCBAN (POB.)',
//       'JOSE ABAD SANTOS',
//       'JOSE P. LAUREL (CASULGAN)',
//       'MAGSAYSAY (DOCE)',
//       'QUEZON (PANICAYAN)',
//       'SALVACION',
//       'SAN ISIDRO',
//       'SAN JUAN (POB.)',
//       'SANTA CLARA (POB.)',
//       'SANTANDER',
//       'SOMOROY',
//       'TROJELLO',
//     ],
//   },
//   CAPUL: {
//     province: 'NORTHERN SAMAR',
//     municipality: 'CAPUL',
//     region: 'REGION VIII',
//     barangay_list: [
//       'AGUIN',
//       'JUBANG',
//       'LANDUSAN',
//       'OSON',
//       'POBLACION BARANGAY 1',
//       'POBLACION BARANGAY 2',
//       'POBLACION BARANGAY 3',
//       'POBLACION BARANGAY 4',
//       'POBLACION BARANGAY 5',
//       'SAGAOSAWAN',
//       'SAN LUIS',
//       'SAWANG',
//     ],
//   },
//   CATUBIG: {
//     province: 'NORTHERN SAMAR',
//     municipality: 'CATUBIG',
//     region: 'REGION VIII',
//     barangay_list: [
//       'ANONGO',
//       'BARANGAY 1 (POB.)',
//       'BARANGAY 2 (POB.)',
//       'BARANGAY 3 (POB.)',
//       'BARANGAY 4 (POB.)',
//       'BARANGAY 5 (POB.)',
//       'BARANGAY 6 (POB.)',
//       'BARANGAY 7 (POB.)',
//       'BARANGAY 8 (POB.)',
//       'BONIFACIO',
//       'BORING',
//       'CAGBUGNA',
//       'CAGMANABA',
//       'CAGOGOBNGAN',
//       'CALINGNAN',
//       'CANUCTAN',
//       'CLARO M. RECTO (LOBEDICO)',
//       'D. MERCADER (BONGOG)',
//       'GUIBWANGAN',
//       'HINAGONOYAN',
//       'HIPARAYAN',
//       'HITAPI-AN',
//       'INOBURAN',
//       'IRAWAHAN',
//       'LENOYAHAN',
//       'LIBON',
//       'MAGONGON',
//       'MAGTUAD',
//       'MANERING',
//       'NABULO',
//       'NAGOOCAN',
//       'NAHULID',
//       'OPONG',
//       'OSANG',
//       'OSMEÑA',
//       'P. REBADULLA',
//       'ROXAS',
//       'SAGUDSURON',
//       'SAN ANTONIO',
//       'SAN FRANCISCO',
//       'SAN JOSE (HEBOBOLLAO)',
//       'SAN VICENTE',
//       'SANTA FE',
//       'SULITAN',
//       'TANGBO',
//       'TUNGODNON',
//       'VIENNA MARIA',
//     ],
//   },
//   GAMAY: {
//     province: 'NORTHERN SAMAR',
//     municipality: 'GAMAY',
//     region: 'REGION VIII',
//     barangay_list: [
//       'ANITO',
//       'BANGON',
//       'BATO',
//       'BAYBAY DISTRICT (POB.)',
//       'BONIFACIO',
//       'BURABOD (POB.)',
//       'CABARASAN',
//       'CADAC-AN (CALINGNAN)',
//       'CADE-AN',
//       'CAGAMUTAN DEL NORTE',
//       'CAGAMUTAN DEL SUR',
//       'DAO',
//       'G. M. OSIAS',
//       'GAMAY CENTRAL (POB.)',
//       'GAMAY OCCIDENTAL I (POB.)',
//       'GAMAY ORIENTAL I (POB.)',
//       'GUIBUANGAN',
//       'HENOGAWE',
//       'LIBERTAD (POB.)',
//       'LONOY',
//       'LUNETA',
//       'MALIDONG',
//       'OCCIDENTAL II (POB.)',
//       'ORIENTAL II (POB.)',
//       'RIZAL',
//       'SAN ANTONIO',
//     ],
//   },
//   LAOANG: {
//     province: 'NORTHERN SAMAR',
//     municipality: 'LAOANG',
//     region: 'REGION VIII',
//     barangay_list: [
//       'ABATON',
//       'AGUADAHAN',
//       'AROGANGA',
//       'ATIPOLO',
//       'BAWANG',
//       'BAYBAY (POB.)',
//       'BINATIKLAN',
//       'BOBOLOSAN',
//       'BONGLIW',
//       'BURABUD',
//       'CABADIANGAN',
//       'CABAGNGAN',
//       'CABAGO-AN',
//       'CABULALOAN',
//       'CAGAASAN',
//       'CAGDARA-O',
//       'CAHAYAGAN',
//       'CALINTAAN POB. (STO. NIÑO)',
//       'CALOMOTAN',
//       'CANDAWID',
//       'CANGCAHIPOS',
//       'CANYOMANAO',
//       'CATIGBIAN',
//       'E. J. DULAY',
//       'G. B. TAN',
//       'GIBATANGAN',
//       'GUILAOANGI (POB.)',
//       'INAMLAN',
//       'LA PERLA',
//       'LANGOB',
//       'LAWAAN',
//       'LITTLE VENICE (POB.)',
//       'MAGSAYSAY',
//       'MARUBAY',
//       'MUALBUAL',
//       'NAPOTIOCAN (SALVACION)',
//       'OLERAS',
//       'ONAY (DOÑA LUISA)',
//       'PALMERA',
//       'PANGDAN',
//       'RAWIS',
//       'ROMBANG',
//       'SAN ANTONIO (SON-OG)',
//       'SAN MIGUEL HEIGHTS (POB.)',
//       'SANGCOL',
//       'SIBUNOT',
//       'SIMORA',
//       'SUBA',
//       'TALISAY',
//       'TAN-AWAN',
//       'TARUSAN',
//       'TINOBLAN',
//       'TUMAGUINGTING (POB.)',
//       'VIGO',
//       'YABYABAN (SAN VICENTE)',
//       'YAPAS',
//     ],
//   },
//   LAPINIG: {
//     province: 'NORTHERN SAMAR',
//     municipality: 'LAPINIG',
//     region: 'REGION VIII',
//     barangay_list: [
//       'ALANG-ALANG',
//       'BAGACAY',
//       'CAHAGWAYAN',
//       'CAN MARIA',
//       'CAN OMANIO',
//       'IMELDA',
//       'LAPINIG DEL NORTE (POB.)',
//       'LAPINIG DEL SUR (POB.)',
//       'LO-OK',
//       'MABINI',
//       'MAY-IGOT',
//       'PALANAS',
//       'PIO DEL PILAR',
//       'POTONG',
//       'POTONG DEL SUR',
//     ],
//   },
//   'LAS NAVAS': {
//     province: 'NORTHERN SAMAR',
//     municipality: 'LAS NAVAS',
//     region: 'REGION VIII',
//     barangay_list: [
//       'BALUGO',
//       'BUGAY',
//       'BUGTOSAN',
//       'BUKID',
//       'BULAO',
//       'CAPUTOAN',
//       'CATOTO-OGAN',
//       'CUENCO',
//       'DAPDAP',
//       'DEL PILAR',
//       'DOLORES',
//       'EPAW',
//       'GEGUINTA',
//       'GERACDO',
//       'GUYO',
//       'H. JOLEJOLE',
//       'H. JOLEJOLE DISTRICT (POB.)',
//       'HANGI',
//       'IMELDA',
//       'L. EMPON',
//       'LAKANDULA',
//       'LOURDES',
//       'LUMALA-OG',
//       'MABINI',
//       'MACARTHUR',
//       'MAGSAYSAY',
//       'MATELARAG',
//       'OSMEÑA',
//       'PACO',
//       'PALANAS',
//       'PEREZ',
//       'POPONTON',
//       'QUEZON',
//       'QUIRINO',
//       'QUIRINO DISTRICT (POB.)',
//       'REBONG',
//       'RIZAL',
//       'ROXAS',
//       'RUFINO',
//       'SAG-OD',
//       'SAN ANDRES',
//       'SAN ANTONIO',
//       'SAN FERNANDO',
//       'SAN FRANCISCO',
//       'SAN ISIDRO',
//       'SAN JORGE',
//       'SAN JOSE',
//       'SAN MIGUEL',
//       'SANTO TOMAS',
//       'TAGAB-IRAN',
//       'TAGAN-AYAN',
//       'TAYLOR',
//       'VICTORY',
//     ],
//   },
//   LAVEZARES: {
//     province: 'NORTHERN SAMAR',
//     municipality: 'LAVEZARES',
//     region: 'REGION VIII',
//     barangay_list: [
//       'BALICUATRO',
//       'BANI',
//       'BAROBAYBAY',
//       'CABURIHAN (POB.)',
//       'CARAGAS (POB.)',
//       'CATAOGAN (POB.)',
//       'CHANSVILLA',
//       'DATAG',
//       'ENRIQUETA',
//       'LIBAS',
//       'LIBERTAD',
//       'MACARTHUR',
//       'MAGSAYSAY',
//       'MARAVILLA',
//       'OCAD (POB.)',
//       'SABONG-TABOK',
//       'SALVACION',
//       'SAN AGUSTIN',
//       'SAN ISIDRO',
//       'SAN JOSE',
//       'SAN JUAN',
//       'SAN MIGUEL',
//       'TO-OG',
//       'URDANETA',
//       'VILLA',
//       'VILLAHERMOSA',
//     ],
//   },
//   'LOPE DE VEGA': {
//     province: 'NORTHERN SAMAR',
//     municipality: 'LOPE DE VEGA',
//     region: 'REGION VIII',
//     barangay_list: [
//       'BAYHO',
//       'BONIFACIO',
//       'CAG-AGUINGAY',
//       'CAGAMESARAG',
//       'CURRY',
//       'GEBONAWAN',
//       'GEN. LUNA',
//       'GETIGO',
//       'HENARONAGAN',
//       'LOPE DE VEGA (POB.)',
//       'LOWER CAYNAGA',
//       'MAGHIPID',
//       'MAGSAYSAY',
//       'OSMEÑA',
//       'PAGUITE',
//       'ROXAS',
//       'SAMPAGUITA',
//       'SAN FRANCISCO',
//       'SAN JOSE',
//       'SAN MIGUEL',
//       'SOMOROY',
//       'UPPER CAYNAGA',
//     ],
//   },
//   MAPANAS: {
//     province: 'NORTHERN SAMAR',
//     municipality: 'MAPANAS',
//     region: 'REGION VIII',
//     barangay_list: [
//       'BURGOS',
//       'DEL NORTE (POB.)',
//       'DEL SUR (POB.)',
//       'E. LAODENIO',
//       'JUBASAN',
//       'MAGSAYSAY',
//       'MAGTAON',
//       'MANAYBANAY',
//       'NAPARASAN',
//       'QUEZON',
//       'SAN JOSE',
//       'SANTA POTENCIANA (POB.)',
//       'SILJAGON',
//     ],
//   },
//   MONDRAGON: {
//     province: 'NORTHERN SAMAR',
//     municipality: 'MONDRAGON',
//     region: 'REGION VIII',
//     barangay_list: [
//       'BAGASBAS',
//       'BUGKO',
//       'CABLANGAN',
//       'CAGMANABA',
//       'CAHICSAN',
//       'CHITONGCO (POB.)',
//       'DE MARIA',
//       'DOÑA LUCIA',
//       'ECO (POB.)',
//       'FLORMINA',
//       'HINABANGAN',
//       'IMELDA',
//       'LA TRINIDAD',
//       'MAKIWALO',
//       'MIRADOR',
//       'NENITA',
//       'ROXAS',
//       'SAN AGUSTIN',
//       'SAN ANTONIO',
//       'SAN ISIDRO',
//       'SAN JOSE',
//       'SAN JUAN',
//       'SANTA CATALINA',
//       'TALOLORA',
//     ],
//   },
//   PALAPAG: {
//     province: 'NORTHERN SAMAR',
//     municipality: 'PALAPAG',
//     region: 'REGION VIII',
//     barangay_list: [
//       'ASUM (POB.)',
//       'BAGACAY',
//       'BANGON',
//       'BENIGNO S. AQUINO, JR.',
//       'BINAY',
//       'CABARIWAN',
//       'CABATUAN',
//       'CAMPEDICO',
//       'CAPACUJAN',
//       'JANGTUD',
//       'LANIWAN (POB.)',
//       'MABARAS',
//       'MAGSAYSAY',
//       'MANAJAO',
//       'MAPNO',
//       'MARAGANO',
//       'MATAMBAG',
//       'MONBON',
//       'NAGBOBTAC',
//       'NAPO',
//       'NATAWO',
//       'NIPA',
//       'OSMEÑA',
//       'PANGPANG',
//       'PAYSUD',
//       'SANGAY',
//       'SIMORA',
//       'SINALARAN',
//       'SUMOROY',
//       'TALOLORA',
//       'TAMBANGAN (POB.)',
//       'TINAMPO (POB.)',
//     ],
//   },
//   PAMBUJAN: {
//     province: 'NORTHERN SAMAR',
//     municipality: 'PAMBUJAN',
//     region: 'REGION VIII',
//     barangay_list: [
//       'CABABTO-AN',
//       'CABARI-AN',
//       'CAGBIGAJO',
//       'CAMPARANGA',
//       'CANJUMADAL',
//       'DOÑA ANECITA',
//       'GE-ADGAWAN',
//       'GEPARAYAN',
//       'GINULGAN',
//       'IGOT',
//       'INANAHAWAN',
//       'MANAHAO',
//       'PANINIRONGAN',
//       'POBLACION DISTRICT 1',
//       'POBLACION DISTRICT 2',
//       'POBLACION DISTRICT 3',
//       'POBLACION DISTRICT 4',
//       'POBLACION DISTRICT 5',
//       'POBLACION DISTRICT 6',
//       'POBLACION DISTRICT 7',
//       'POBLACION DISTRICT 8',
//       'SAN RAMON',
//       'SENONOGAN',
//       'SIXTO T. BALANGUIT, SR.',
//       'TULA',
//       'YNAGUINGAYAN',
//     ],
//   },
//   'SAN ROQUE': {
//     province: 'NORTHERN SAMAR',
//     municipality: 'SAN ROQUE',
//     region: 'REGION VIII',
//     barangay_list: [
//       'BALNASAN',
//       'BALUD',
//       'BANTAYAN',
//       'COROCONOG',
//       'DALE',
//       'GINAGDANAN',
//       'LAO-ANGAN',
//       'LAWAAN',
//       'MALOBAGO',
//       'PAGSANG-AN',
//       'ZONE 1 (POB.)',
//       'ZONE 2 (POB.)',
//       'ZONE 3 (POB.)',
//       'ZONE 4 (POB.)',
//       'ZONE 5 (POB.)',
//       'ZONE 6 (POB.)',
//     ],
//   },
//   'SILVINO LOBOS': {
//     province: 'NORTHERN SAMAR',
//     municipality: 'SILVINO LOBOS',
//     region: 'REGION VIII',
//     barangay_list: [
//       'BALUD',
//       'CABABAYOGAN',
//       'CABUNGA-AN',
//       'CAGDA-O',
//       'CAGHILOT',
//       'CAMANGGARAN',
//       'CAMAYA-AN',
//       'DEIT DE SUBA',
//       'DEIT DE TURAG',
//       'GEBOLWANGAN',
//       'GEBONAWAN',
//       'GECBO-AN',
//       'GENAGASAN',
//       'GEPARAYAN DE TURAG',
//       'GIGUIMITAN',
//       'GUSARAN',
//       'IMELDA',
//       'MONTALBAN',
//       'POBLACION I',
//       'POBLACION II',
//       'SAN ANTONIO',
//       'SAN ISIDRO',
//       'SENONOGAN DE TUBANG',
//       'SUBA (POB.)',
//       'TOBGON',
//       'VICTORY',
//     ],
//   },
//   ALMAGRO: {
//     province: 'SAMAR (WESTERN SAMAR)',
//     municipality: 'ALMAGRO',
//     region: 'REGION VIII',
//     barangay_list: [
//       'BACJAO',
//       'BIASONG I',
//       'BIASONG II',
//       'COSTA RICA',
//       'COSTA RICA II',
//       'GUIN-ANSAN',
//       'IMELDA (BADJANG)',
//       'KERIKITE',
//       'LUNANG I (LOOK)',
//       'LUNANG II',
//       'MABUHAY',
//       'MAGSAYSAY',
//       'MALOBAGO',
//       'MARASBARAS',
//       'PANJOBJOBAN I',
//       'PANJOBJOBAN II',
//       'POBLACION',
//       'ROÑO',
//       'SAN ISIDRO',
//       'SAN JOSE',
//       'TALAHID',
//       'TONGA-TONGA',
//       'VELOSO',
//     ],
//   },
//   BASEY: {
//     province: 'SAMAR (WESTERN SAMAR)',
//     municipality: 'BASEY',
//     region: 'REGION VIII',
//     barangay_list: [
//       'AMANDAYEHAN',
//       'ANGLIT',
//       'BACUBAC',
//       'BALANTE',
//       'BALOOG',
//       'BASIAO',
//       'BAYBAY (POB.)',
//       'BINONGTU-AN',
//       'BUENAVISTA',
//       'BULAO',
//       'BURGOS',
//       'BUSCADA (POB.)',
//       'CAMBAYAN',
//       'CAN-ABAY',
//       'CANCAIYAS',
//       'CANMANILA',
//       'CATADMAN',
//       'COGON',
//       'DEL PILAR',
//       'DOLONGAN',
//       'GUINTIGUI-AN',
//       'GUIRANG',
//       'IBA',
//       'INUNTAN',
//       'LAWA-AN (POB.)',
//       'LOOG',
//       'LOYO (POB.)',
//       'MABINI',
//       'MAGALLANES',
//       'MANLILINAB',
//       'MAY-IT',
//       'MERCADO (POB.)',
//       'MONGABONG',
//       'NEW SAN AGUSTIN',
//       'NOUVELAS OCCIDENTAL',
//       'OLD SAN AGUSTIN',
//       'PALAYPAY (POB.)',
//       'PANUGMONON',
//       'PELIT',
//       'ROXAS',
//       'SALVACION',
//       'SAN ANTONIO',
//       'SAN FERNANDO (NOUVELAS ORIENTAL)',
//       'SAWA',
//       'SERUM',
//       'SUGCA',
//       'SUGPONON',
//       'SULOD (POB.)',
//       'TINAOGAN',
//       'TINGIB',
//       'VILLA AURORA',
//     ],
//   },
//   'CALBAYOG': {
//     province: 'SAMAR (WESTERN SAMAR)',
//     municipality: 'CALBAYOG',
//     region: 'REGION VIII',
//     barangay_list: [
//       'ACEDILLO',
//       'AGUIT-ITAN (POB.)',
//       'ALIBABA',
//       'AMAMPACANG',
//       'ANISLAG',
//       'AWANG EAST (POB.)',
//       'AWANG WEST (POB.)',
//       'BA-AY',
//       'BAGACAY',
//       'BAGONG LIPUNAN',
//       'BAJA',
//       'BALUD (POB.)',
//       'BANTE',
//       'BANTIAN',
//       'BASUD',
//       'BAYO',
//       'BEGAHO',
//       'BINALIW',
//       'BONTAY',
//       'BUENAVISTA',
//       'BUGTONG',
//       'CABACUNGAN',
//       'CABATUAN',
//       'CABICAHAN',
//       'CABUGAWAN',
//       'CACARANSAN',
//       'CAG-ANAHAW',
//       'CAG-ANIBONG',
//       'CAG-OLANGO',
//       'CAGBANAYACAO',
//       'CAGBAYANG',
//       'CAGBILWANG',
//       'CAGBOBORAC',
//       'CAGLANIPAO SUR',
//       'CAGMANIPES NORTE',
//       'CAGMANIPES SUR',
//       'CAGNIPA',
//       'CAGSALAOSAO',
//       'CAHUMPAN',
//       'CALOCNAYAN',
//       'CANGOMAOD',
//       'CANHUMADAC',
//       'CAPACUHAN',
//       'CAPOOCAN',
//       'CARAYMAN',
//       'CARMEN',
//       'CATABUNAN',
//       'CAYBAGO',
//       'CENTRAL (POB.)',
//       'COGON',
//       'DAGUM',
//       'DANAO I',
//       'DANAO II',
//       'DAWO',
//       'DE VICTORIA',
//       'DINABONGAN',
//       'DINAGAN',
//       'DINAWACAN',
//       'ESPERANZA',
//       'GABAY',
//       'GADGARAN',
//       'GASDO',
//       'GERAGA-AN',
//       'GUIMBAOYAN NORTE',
//       'GUIMBAOYAN SUR',
//       'GUIN-ON',
//       'HAMORAWON',
//       'HELINO',
//       'HIBABNGAN',
//       'HIBATANG',
//       'HIGASAAN',
//       'HIMALANDROG',
//       'HUGON ROSALES',
//       'JACINTO',
//       'JIMAUTAN',
//       'JOSE A. ROÑO',
//       'KALILIHAN',
//       'KILIKILI',
//       'LA PAZ',
//       'LANGOYON',
//       'LAPAAN',
//       'LIBERTAD',
//       'LIMARAYON',
//       'LONGSOB',
//       'LONOY',
//       'LOOC',
//       'MABINI I (CALBAYOG DISTRICT)',
//       'MABINI II (OQUENDO DISTRICT)',
//       'MACATINGOG',
//       'MAG-UBAY',
//       'MAGUINO-O',
//       'MALAGA',
//       'MALAJOG',
//       'MALAYOG',
//       'MALOPALO',
//       'MANCOL',
//       'MANTAONG (OQUENDO DISTRICT)',
//       'MANUEL BARRAL, SR.',
//       'MARCATUBIG',
//       'MATOBATO',
//       'MAWACAT',
//       'MAYBOG',
//       'MAYSALONG',
//       'MIGARA',
//       'NABANG',
//       'NAGA',
//       'NAGUMA',
//       'NAVARRO',
//       'NIJAGA',
//       'OBOOB',
//       'OBRERO',
//       'OLERA',
//       'OQUENDO (POB.)',
//       'OSMEÑA',
//       'PAGBALICAN',
//       'PALANAS',
//       'PALANOGAN',
//       'PANLAYAHAN',
//       'PANONONGAN',
//       'PANOYPOY',
//       'PATONG',
//       'PAYAHAN',
//       'PEÑA',
//       'PILAR',
//       'PINAMOROTAN',
//       'QUEZON',
//       'RAWIS',
//       'RIZAL I (CALBAYOG DISTRICT)',
//       'RIZAL II (OQUENDO DISTRICT)',
//       'ROXAS I (CALBAYOG DISTRICT)',
//       'ROXAS II (OQUENDO DISTRICT)',
//       'SALJAG (BAUT)',
//       'SALVACION',
//       'SAN ANTONIO',
//       'SAN ISIDRO',
//       'SAN JOAQUIN',
//       'SAN JOSE',
//       'SAN POLICARPIO',
//       'SAN RUFINO',
//       'SAPUTAN',
//       'SIGO',
//       'SINANTAN',
//       'SINIDMAN OCCIDENTAL',
//       'SINIDMAN ORIENTAL',
//       'TABAWAN',
//       'TALAHIBAN',
//       'TANVAL',
//       'TAPA-E',
//       'TARABUCAN',
//       'TIGBE',
//       'TINAMBACAN NORTE',
//       'TINAMBACAN SUR',
//       'TINAPLACAN',
//       'TOMALIGUEZ',
//       'TRINIDAD (SABANG)',
//       'VICTORY',
//       'VILLAHERMOSA',
//     ],
//   },
//   CALBIGA: {
//     province: 'SAMAR (WESTERN SAMAR)',
//     municipality: 'CALBIGA',
//     region: 'REGION VIII',
//     barangay_list: [
//       'ANTOL',
//       'BACYARAN',
//       'BARANGAY 1 (POB.)',
//       'BARANGAY 2 (POB.)',
//       'BARANGAY 3 (POB.)',
//       'BARANGAY 4 (POB.)',
//       'BARANGAY 5 (POB.)',
//       'BARANGAY 6 (POB.)',
//       'BARANGAY 7 (POB.)',
//       'BAROBAYBAY',
//       'BERI',
//       'BINANGGARAN',
//       'BORONG',
//       'BULAO',
//       'BULUAN',
//       'CAAMLONGAN',
//       'CALAYAAN',
//       'CALINGONAN',
//       'CANBAGTIC',
//       'CANTICUM',
//       'DALIGAN',
//       'GUINBANGA',
//       'HINDANG',
//       'HUBASAN',
//       'LITERON',
//       'LUBANG',
//       'MACAALAN',
//       'MAHANGCAO',
//       'MALABAL',
//       'MINATA',
//       'OTOC',
//       'PANAYURAN',
//       'PASIGAY',
//       'PATONG',
//       'POLANGI',
//       'RAWIS',
//       'SAN IGNACIO',
//       'SAN MAURICIO',
//       'SINALANGTAN',
//       'TIMBANGAN',
//       'TINAGO',
//     ],
//   },
//   'CATBALOGAN': {
//     province: 'SAMAR (WESTERN SAMAR)',
//     municipality: 'CATBALOGAN',
//     region: 'REGION VIII',
//     barangay_list: [
//       'ALBALATE',
//       'BAGONGON',
//       'BANGON',
//       'BASIAO',
//       'BULUAN',
//       'BUNUANAN',
//       'CABUGAWAN',
//       'CAGUDALO',
//       'CAGUSIPAN',
//       'CAGUTIAN',
//       'CAGUTSAN',
//       'CANHAWAN GOTE',
//       'CANLAPWAS (POB.)',
//       'CAWAYAN',
//       'CINCO',
//       'DARAHUWAY DACO',
//       'DARAHUWAY GOTE',
//       'ESTAKA',
//       'GUINDAPONAN',
//       'GUINSORONGAN',
//       'IBOL',
//       'IGUID',
//       'LAGUNDI',
//       'LIBAS',
//       'LOBO',
//       'MANGUEHAY',
//       'MAULONG',
//       'MERCEDES',
//       'MOMBON',
//       'MUÑOZ (POBLACION 14)',
//       'NEW MAHAYAG',
//       'OLD MAHAYAG',
//       'PALANYOGON',
//       'PANGDAN',
//       'PAYAO',
//       'POBLACION 1 (BARANGAY 1)',
//       'POBLACION 2 (BARANGAY 2)',
//       'POBLACION 3 (BARANGAY 3)',
//       'POBLACION 4 (BARANGAY 4)',
//       'POBLACION 5 (BARANGAY 5)',
//       'POBLACION 6 (BARANGAY 6)',
//       'POBLACION 7 (BARANGAY 7)',
//       'POBLACION 8 (BARANGAY 8)',
//       'POBLACION 9 (BARANGAY 9)',
//       'POBLACION 10 (BARANGAY 10)',
//       'POBLACION 11 (BARANGAY 11)',
//       'POBLACION 12 (BARANGAY 12)',
//       'POBLACION 13 (BARANGAY 13)',
//       'PUPUA',
//       'RAMA',
//       'SAN ANDRES',
//       'SAN PABLO',
//       'SAN ROQUE',
//       'SAN VICENTE',
//       'SILANGA',
//       'SOCORRO',
//       'TOTORINGON',
//     ],
//   },
//   DARAM: {
//     province: 'SAMAR (WESTERN SAMAR)',
//     municipality: 'DARAM',
//     region: 'REGION VIII',
//     barangay_list: [
//       'ARAWANE',
//       'ASTORGA',
//       'BACHAO',
//       'BACLAYAN',
//       'BAGACAY',
//       'BAYOG',
//       'BETAUG',
//       'BIRAWAN',
//       'BONO-ANON',
//       'BUENAVISTA',
//       'BURGOS',
//       'CABAC',
//       'CABIL-ISAN',
//       'CABITON-AN',
//       'CABUGAO',
//       'CAGBOBOTO',
//       'CALAWAN-AN',
//       'CAMBUHAY',
//       'CAMPELIPA',
//       'CANDUGUE',
//       'CANLOLOY',
//       'CANSAGANAY',
//       'CASAB-AHAN',
//       'GUINDAPUNAN',
//       'GUINTAMPILAN',
//       'IQUIRAN',
//       'JACOPON',
//       'LOSA',
//       'LUCOB-LUCOB',
//       'MABINI',
//       'MACALPE',
//       'MANDOYUCAN',
//       'MARUPANGDAN',
//       'MAYABAY',
//       'MONGOLBONGOL',
//       'NIPA',
//       'PARASAN',
//       'POBLACION 1 (HILABA)',
//       'POBLACION 2 (MALINGON)',
//       'POBLACION 3 (CANTI-IL)',
//       'PONDANG',
//       'POSO',
//       'REAL',
//       'RIZAL',
//       'SAN ANTONIO',
//       'SAN JOSE',
//       'SAN MIGUEL',
//       'SAN ROQUE',
//       'SAN VICENTE',
//       'SAUGAN',
//       'SO-ONG',
//       'SUA',
//       'SUGOD',
//       'TALISAY',
//       'TUGAS',
//       'UBO',
//       'VALLES-BELLO',
//       'YANGTA',
//     ],
//   },
//   GANDARA: {
//     province: 'SAMAR (WESTERN SAMAR)',
//     municipality: 'GANDARA',
//     region: 'REGION VIII',
//     barangay_list: [
//       'ADELA HEIGHTS (POB.)',
//       'ARONG',
//       'BALOCAWE',
//       'BANGAHON',
//       'BESLIG',
//       'BUAO',
//       'BUNYAGAN',
//       'BURABOD I (POB.)',
//       'BURABOD II (POB.)',
//       'CALIROCAN',
//       'CANHUMAWID',
//       'CAPARANGASAN',
//       'CARANAS',
//       'CARMONA',
//       'CASAB-AHAN',
//       'CASANDIG',
//       'CATORSE DE AGOSTO',
//       'CAUGBUSAN',
//       'CONCEPCION',
//       'DIAZ',
//       'DUMALO-ONG (POB.)',
//       'ELCANO',
//       'GERALI',
//       'GEREGANAN',
//       'GIABOC',
//       'HAMPTON',
//       'HETEBAC',
//       'HIMAMALOTO',
//       'HINAYAGAN',
//       'HINUGACAN',
//       'HIPARAYAN (KINONONGAN)',
//       'JASMINEZ',
//       'LUNGIB',
//       'MABUHAY',
//       'MACUGO',
//       'MALAYOG',
//       'MARCOS',
//       'MINDA (POB.)',
//       'NACUBE',
//       'NALIHUGAN',
//       'NAPALISAN',
//       'NATIMONAN',
//       'NGOSO',
//       'PALAMBRAG',
//       'PALANAS',
//       'PIÑAPLATA',
//       'PIZARRO',
//       'POLOGON',
//       'PUROG',
//       'RAWIS',
//       'RIZAL',
//       'SAMOYAO',
//       'SAN AGUSTIN',
//       'SAN ANTONIO',
//       'SAN ENRIQUE',
//       'SAN FRANCISCO',
//       'SAN ISIDRO (DAO)',
//       'SAN JOSE',
//       'SAN MIGUEL',
//       'SAN PELAYO',
//       'SAN RAMON',
//       'SANTA ELENA',
//       'SANTO NIÑO',
//       'SENIBARAN (VILLA LEONA)',
//       'SIDMON',
//       'TAGNAO',
//       'TAMBONGAN',
//       'TAWIRAN',
//       'TIGBAWON',
//     ],
//   },
//   HINABANGAN: {
//     province: 'SAMAR (WESTERN SAMAR)',
//     municipality: 'HINABANGAN',
//     region: 'REGION VIII',
//     barangay_list: [
//       'BAGACAY',
//       'BINOBUCALAN',
//       'BUCALAN',
//       'CABALAGNAN',
//       'CABANG',
//       'CANANO',
//       'CONCORD',
//       'CONSOLABAO',
//       'DALOSDOSON',
//       'FATIMA',
//       'LIM-AO',
//       'MALIHAO',
//       'MUGDO',
//       'OSMEÑA',
//       'POBLACION 1 (BARANGAY 1)',
//       'POBLACION 2 (BARANGAY 2)',
//       'RAWIS',
//       'SAN JOSE',
//       'SAN RAFAEL',
//       'TABAY',
//       'YABON',
//     ],
//   },
//   JIABONG: {
//     province: 'SAMAR (WESTERN SAMAR)',
//     municipality: 'JIABONG',
//     region: 'REGION VIII',
//     barangay_list: [
//       'BARANGAY NO. 1 (POB.)',
//       'BARANGAY NO. 2 (POB.)',
//       'BARANGAY NO. 3 (POB.)',
//       'BARANGAY NO. 4 (POB.)',
//       'BARANGAY NO. 5 (POB.)',
//       'BARANGAY NO. 6 (POB.)',
//       'BARANGAY NO. 7 (POB.)',
//       'BARANGAY NO. 8 (POB.)',
//       'BAWANG',
//       'BUGHO',
//       'CAMAROBO-AN',
//       'CANDAYAO',
//       'CANTONGTONG',
//       'CASAPA',
//       'CATALINA',
//       'CRISTINA',
//       'DOGONGAN',
//       'GARCIA',
//       'HINAGA',
//       'JIA-AN',
//       'JIDANAO',
//       'LULUGAYAN',
//       'MACABETAS',
//       'MALINO',
//       'MALOBAGO (VILLALINDA)',
//       'MERCEDES',
//       'NAGBAC',
//       'PARINA',
//       'SALVACION',
//       'SAN ANDRES',
//       'SAN FERNANDO',
//       'SAN MIGUEL',
//       'TAGBAYAON',
//       'VICTORY',
//     ],
//   },
//   MARABUT: {
//     province: 'SAMAR (WESTERN SAMAR)',
//     municipality: 'MARABUT',
//     region: 'REGION VIII',
//     barangay_list: [
//       'AMAMBUCALE',
//       'AMANTILLO',
//       'BINUKYAHAN',
//       'CALUWAYAN',
//       'CANYOYO',
//       'CATATO POB. (DIST. I)',
//       'FERRERAS',
//       'LEGASPI',
//       'LIPATA',
//       'LOGERO',
//       'MABUHAY',
//       'MALOBAGO',
//       'ODOC',
//       'OSMEÑA',
//       'PANAN-AWAN',
//       'PINALANGA',
//       'PINAMITINAN (POB.)',
//       'ROÑO',
//       'SAN ROQUE',
//       'SANTA RITA',
//       'SANTO NIÑO POB. (DIST. II)',
//       'TAGALAG',
//       'TINABANAN',
//       'VELOSO',
//     ],
//   },
//   MATUGUINAO: {
//     province: 'SAMAR (WESTERN SAMAR)',
//     municipality: 'MATUGUINAO',
//     region: 'REGION VIII',
//     barangay_list: [
//       'ANGYAP',
//       'BAG-OTAN',
//       'BARRUZ (BARANGAY NO. 1)',
//       'CAMONOAN',
//       'CAROLINA',
//       'DEIT',
//       'DEL ROSARIO',
//       'INUBOD',
//       'LIBERTAD',
//       'LIGAYA',
//       'MABULIGON POB. (BARANGAY NO. 2)',
//       'MADUROTO POB. (BARANGAY NO. 3)',
//       'MAHANUD',
//       'MAHAYAG',
//       'NAGPAPACAO',
//       'RIZAL',
//       'SALVACION',
//       'SAN ISIDRO',
//       'SAN ROQUE (MABUHAY)',
//       'SANTA CRUZ',
//     ],
//   },
//   MOTIONG: {
//     province: 'SAMAR (WESTERN SAMAR)',
//     municipality: 'MOTIONG',
//     region: 'REGION VIII',
//     barangay_list: [
//       'ANGYAP',
//       'BARAYONG',
//       'BAYOG',
//       'BERI',
//       'BONGA',
//       'CALANTAWAN',
//       'CALAPI',
//       'CALUYAHAN',
//       'CANATUAN',
//       'CANDOMACOL',
//       'CANVAIS',
//       'CAPAYSAGAN',
//       'CARANAS',
//       'CAULAYANAN',
//       'HINICA-AN',
//       'INALAD',
//       'LINONOBAN',
//       'MALOBAGO',
//       'MALONOY',
//       'MARARANGSI',
//       'MAYPANGE',
//       'NEW MINAROG',
//       'OYANDIC',
//       'PAMAMASAN',
//       'POBLACION I',
//       'POBLACION I-A',
//       'PUSONGAN',
//       'SAN ANDRES',
//       'SANTO NIÑO',
//       'SARAO',
//     ],
//   },
//   PAGSANGHAN: {
//     province: 'SAMAR (WESTERN SAMAR)',
//     municipality: 'PAGSANGHAN',
//     region: 'REGION VIII',
//     barangay_list: [
//       'BANGON',
//       'BUENOS AIRES',
//       'CALANYUGAN',
//       'CALOLOMA',
//       'CAMBAYE',
//       'CANLAPWAS (POB.)',
//       'LIBERTAD',
//       'PAÑGE',
//       'SAN LUIS',
//       'SANTO NIÑO',
//       'VIEJO',
//       'VILLAHERMOSA OCCIDENTAL',
//       'VILLAHERMOSA ORIENTAL',
//     ],
//   },
//   'PARANAS (WRIGHT)': {
//     province: 'SAMAR (WESTERN SAMAR)',
//     municipality: 'PARANAS (WRIGHT)',
//     region: 'REGION VIII',
//     barangay_list: [
//       'ANAGASI',
//       'APOLONIA',
//       'BAGSA',
//       'BALBAGAN',
//       'BATO',
//       'BURAY (BINOGHO)',
//       'CANTAGUIC',
//       'CANTAO-AN',
//       'CANTATO (CANTURAB)',
//       'CASANDIG I',
//       'CASANDIG II',
//       'CAWAYAN',
//       'CONCEPCION',
//       'JOSE ROÑO',
//       'LAWAAN I',
//       'LAWAAN II',
//       'LIPATA',
//       'LOKILOKON',
//       'MANGCAL',
//       'MAYLOBE',
//       'MINAROG',
//       'NAWI',
//       'PABANOG',
//       'PACO',
//       'PAGSA-OGAN',
//       'PAGSANJAN (PAGSAN-AN)',
//       'PATAG',
//       'PEQUIT',
//       'POBLACION 1',
//       'POBLACION 2',
//       'POBLACION 3',
//       'POBLACION 4',
//       'POBLACION 5',
//       'POBLACION 6',
//       'SALAY',
//       'SAN ISIDRO',
//       'SANTO NIÑO',
//       'SULOPAN',
//       'TABUCAN',
//       'TAPUL',
//       'TENANI',
//       'TIGBAWON',
//       'TULA',
//       'TUTUBIGAN',
//     ],
//   },
//   PINABACDAO: {
//     province: 'SAMAR (WESTERN SAMAR)',
//     municipality: 'PINABACDAO',
//     region: 'REGION VIII',
//     barangay_list: [
//       'BANGON',
//       'BARANGAY I (POB.)',
//       'BARANGAY II (POB.)',
//       'BOTOC',
//       'BUGHO',
//       'CALAMPONG',
//       'CANLOBO',
//       'CATIGAWAN',
//       'DOLORES',
//       'LALE',
//       'LAWAAN',
//       'LAYGAYON',
//       'LAYO',
//       'LOCTOB',
//       'MADALUNOT',
//       'MAGDAWAT',
//       'MAMBOG',
//       'MANAING',
//       'NABONG',
//       'OBAYAN',
//       'PAHUG',
//       'PARASANON',
//       'PELAON',
//       'SAN ISIDRO',
//     ],
//   },
//   'SAN JORGE': {
//     province: 'SAMAR (WESTERN SAMAR)',
//     municipality: 'SAN JORGE',
//     region: 'REGION VIII',
//     barangay_list: [
//       'ANQUIANA (ANGKIANA)',
//       'AURORA',
//       'BAY-ANG',
//       'BLANCA AURORA',
//       'BUENAVISTA I',
//       'BUENAVISTA II',
//       'BULAO',
//       'BUNGLIW',
//       'CABUGAO',
//       'CAG-OLO-OLO',
//       'CALUNDAN',
//       'CANTAGUIC',
//       'CANYAKI',
//       'COGTOTO-OG',
//       'ERENAS',
//       'GAYONDATO',
//       'GUADALUPE',
//       'GUINDAPUNAN',
//       'HERNANDEZ',
//       'HIMAY',
//       'JANIPON',
//       'LA PAZ',
//       'LIBERTAD',
//       'LINCORO',
//       'MABUHAY',
//       'MANCOL (POB.)',
//       'MATALUD',
//       'MOBO-OB',
//       'MOMBON',
//       'PUHAGAN',
//       'QUEZON',
//       'RANERA',
//       'RAWIS',
//       'ROSALIM',
//       'SAN ISIDRO',
//       'SAN JORGE I (POB.)',
//       'SAN JORGE II (POB.)',
//       'SAN JUAN',
//       'SAPINIT',
//       'SINIT-AN',
//       'TOMOGBONG',
//     ],
//   },
//   'SAN JOSE DE BUAN': {
//     province: 'SAMAR (WESTERN SAMAR)',
//     municipality: 'SAN JOSE DE BUAN',
//     region: 'REGION VIII',
//     barangay_list: [
//       'AGUINGAYAN',
//       'BABACLAYON',
//       'BARANGAY 1 (POB.)',
//       'BARANGAY 2 (POB.)',
//       'BARANGAY 3 (POB.)',
//       'BARANGAY 4 (POB.)',
//       'CAN-APONTE',
//       'CATAYDONGAN',
//       'GUSA',
//       'HAGBAY',
//       'HIBACA-AN',
//       'HIDUROMA',
//       'HILUMOT',
//       'SAN NICOLAS',
//     ],
//   },
//   'SAN SEBASTIAN': {
//     province: 'SAMAR (WESTERN SAMAR)',
//     municipality: 'SAN SEBASTIAN',
//     region: 'REGION VIII',
//     barangay_list: [
//       'BALOGO',
//       'BONTOD',
//       'CABAYWA',
//       'CAMANHAGAY',
//       'CAMPIYAK',
//       'CANDUYUCAN',
//       'DOLORES',
//       'HITA-ASAN I',
//       'HITA-ASAN II',
//       'INOBONGAN',
//       'POBLACION BARANGAY 1',
//       'POBLACION BARANGAY 2',
//       'POBLACION BARANGAY 3',
//       'POBLACION BARANGAY 4',
//     ],
//   },
//   'SANTA MARGARITA': {
//     province: 'SAMAR (WESTERN SAMAR)',
//     municipality: 'SANTA MARGARITA',
//     region: 'REGION VIII',
//     barangay_list: [
//       'AGRUPACION',
//       'ARAPISON',
//       'AVELINO',
//       'BAHAY',
//       'BALUD',
//       'BANA-AO',
//       'BURABOD',
//       'CAGSUMJI',
//       'CAMPEIG',
//       'CAMPERITO',
//       'CAN-IPULAN',
//       'CANMOROS',
//       'CAUTOD (POB.)',
//       'CINCO',
//       'CURRY',
//       'GAJO',
//       'HINDANG',
//       'ILO',
//       'IMELDA',
//       'INORAGUIAO',
//       'JOLACAO',
//       'LAMBAO',
//       'MABUHAY',
//       'MAHAYAG',
//       'MATAYONAS',
//       'MONBON (POB.)',
//       'NABULO',
//       'NAPURO',
//       'NAPURO II',
//       'PALALE',
//       'PANABATAN',
//       'PANARUAN',
//       'ROXAS',
//       'SALVACION',
//       'SOLSOGON',
//       'SUNDARA',
//     ],
//   },
//   'TAGAPUL-AN': {
//     province: 'SAMAR (WESTERN SAMAR)',
//     municipality: 'TAGAPUL-AN',
//     region: 'REGION VIII',
//     barangay_list: [
//       'BAGUIW',
//       'BALOCAWE',
//       'GUINBARUCAN',
//       'LABANGBAYBAY',
//       'LUNA',
//       'MATALUTO',
//       'NIPA',
//       'PANTALAN',
//       'PULANGBATO',
//       'SAN JOSE (POB.)',
//       'SAN VICENTE',
//       'SUAREZ (MANLANGIT)',
//       'SUGOD (POB.)',
//       'TRINIDAD',
//     ],
//   },
//   TALALORA: {
//     province: 'SAMAR (WESTERN SAMAR)',
//     municipality: 'TALALORA',
//     region: 'REGION VIII',
//     barangay_list: [
//       'BO. INDEPENDENCIA',
//       'MALAGUINING',
//       'MALLORGA',
//       'NAVATAS DAKU',
//       'NAVATAS GUTI',
//       'PLACER',
//       'POBLACION BARANGAY 1',
//       'POBLACION BARANGAY 2',
//       'SAN JUAN',
//       'TATABUNAN',
//       'VICTORY',
//     ],
//   },
//   TARANGNAN: {
//     province: 'SAMAR (WESTERN SAMAR)',
//     municipality: 'TARANGNAN',
//     region: 'REGION VIII',
//     barangay_list: [
//       'ALCAZAR',
//       'AWANG',
//       'BAHAY',
//       'BALONGA-AS',
//       'BALUGO',
//       'BANGON GOTE',
//       'BARAS',
//       'BINALAYAN',
//       'BISITAHAN',
//       'BONGA',
//       'CABUNGA-AN',
//       'CAGTUTULO',
//       'CAMBATUTAY NUEVO',
//       'CAMBATUTAY VIEJO',
//       'CANUNGHAN',
//       'CATAN-AGAN',
//       'DAPDAP',
//       'GALLEGO',
//       'IMELDA POB. (POSGO)',
//       'LAHONG',
//       'LIBUCAN DACU',
//       'LIBUCAN GOTE',
//       'LUCERDONI (IRONG-IRONG)',
//       'MAJACOB',
//       'MANCARES',
//       'MARABUT',
//       'OESTE - A',
//       'OESTE - B',
//       'PAJO',
//       'PALENCIA',
//       'POBLACION A',
//       'POBLACION B',
//       'POBLACION C',
//       'POBLACION D',
//       'POBLACION E',
//       'SAN VICENTE',
//       'SANTA CRUZ',
//       'SUGOD',
//       'TALINGA',
//       'TIGDARANAO',
//       'TIZON',
//     ],
//   },
//   VILLAREAL: {
//     province: 'SAMAR (WESTERN SAMAR)',
//     municipality: 'VILLAREAL',
//     region: 'REGION VIII',
//     barangay_list: [
//       'BANQUIL',
//       'BINO-ONGAN',
//       'BURABOD',
//       'CAMBAGUIO',
//       'CANMUCAT',
//       'CENTRAL (POB.)',
//       'CONANT',
//       'GUINTARCAN',
//       'HIMYANGAN',
//       'IGOT',
//       'INARUMBACAN',
//       'INASUDLAN',
//       'LAM-AWAN',
//       'LAMINGAO',
//       'LAWA-AN',
//       'MACOPA',
//       'MAHAYAG',
//       'MALONOY',
//       'MERCADO (POB.)',
//       'MIRAMAR (POB.)',
//       'NAGCADUHA',
//       'PACAO',
//       'PACOYOY',
//       'PANGPANG',
//       'PATAG',
//       'PLARIDEL',
//       'POLANGI',
//       'SAN ANDRES',
//       'SAN FERNANDO',
//       'SAN RAFAEL (BUAYA)',
//       'SAN ROQUE',
//       'SANTA ROSA',
//       'SANTO NIÑO',
//       'SOLEDAD (POB.)',
//       'TAYUD (POB.)',
//       'TOMABE',
//       'ULAYAN',
//       'VILLAROSA POB. (CAMPIATOT)',
//     ],
//   },
//   ZUMARRAGA: {
//     province: 'SAMAR (WESTERN SAMAR)',
//     municipality: 'ZUMARRAGA',
//     region: 'REGION VIII',
//     barangay_list: [
//       'ALEGRIA',
//       'ARTECHE',
//       'BIOSO',
//       'BOBLARAN',
//       'BOTAERA',
//       'BUNTAY',
//       'CAMAYSE',
//       'CANWARAK',
//       'IBARRA',
//       'LUMALANTANG',
//       'MACALUNOD',
//       'MAGA-AN',
//       'MAPUTI',
//       'MARAPILIT',
//       'MONBON',
//       'MUALBUAL',
//       'PANGDAN',
//       'POBLACION 1 (BARANGAY 1)',
//       'POBLACION 2 (BARANGAY 2)',
//       'PORO',
//       'SAN ISIDRO',
//       'SUGOD',
//       'TALIB',
//       'TINAUGAN',
//       'TUBIGAN',
//     ],
//   },
//   ANAHAWAN: {
//     province: 'SOUTHERN LEYTE',
//     municipality: 'ANAHAWAN',
//     region: 'REGION VIII',
//     barangay_list: [
//       'AMAGUSAN',
//       'CALINTAAN',
//       'CANLABIAN',
//       'CAPACUHAN',
//       'COGON',
//       'KAGINGKINGAN',
//       'LEWING',
//       'LO-OK',
//       'MAHALO',
//       'MAINIT',
//       'MANIGAWONG',
//       'POBLACION',
//       'SAN VICENTE',
//       'TAGUP-ON',
//     ],
//   },
//   BONTOC: {
//     province: 'MOUNTAIN PROVINCE',
//     municipality: 'BONTOC',
//     region: 'CAR',
//     barangay_list: [
//       'ALAB ORIENTE',
//       'ALAB PROPER',
//       'BALILI',
//       'BAYYO',
//       'BONTOC ILI',
//       'CALUTIT',
//       'CANEO',
//       'DALICAN',
//       'GONOGON',
//       'GUINAANG',
//       'MAINIT',
//       'MALIGCONG',
//       'POBLACION (BONTOC)',
//       'SAMOKI',
//       'TALUBIN',
//       'TOCUCAN',
//     ],
//   },
//   HINUNANGAN: {
//     province: 'SOUTHERN LEYTE',
//     municipality: 'HINUNANGAN',
//     region: 'REGION VIII',
//     barangay_list: [
//       'AMBACON',
//       'BADIANGON',
//       'BANGCAS A',
//       'BANGCAS B',
//       'BIASONG',
//       'BUGHO',
//       'CALAG-ITAN',
//       'CALAYUGAN',
//       'CALINAO',
//       'CANIPAAN',
//       'CATUBLIAN',
//       'ILAYA',
//       'INGAN',
//       'LABRADOR',
//       'LIBAS',
//       'LUMBOG',
//       'MANALOG',
//       'MANLICO',
//       'MATIN-AO',
//       'NAVA',
//       'NUEVA ESPERANZA',
//       'OTAMA',
//       'PALONGPONG',
//       'PANALARON',
//       'PATONG',
//       'POBLACION',
//       'PONDOL',
//       'SALOG',
//       'SALVACION',
//       'SAN PABLO ISLAND',
//       'SAN PEDRO ISLAND',
//       'SANTO NIÑO I',
//       'SANTO NIÑO II',
//       'TAHUSAN',
//       'TALISAY',
//       'TAWOG',
//       'TOPTOP',
//       'TUBURAN',
//       'UNION',
//       'UPPER BANTAWON',
//     ],
//   },
//   HINUNDAYAN: {
//     province: 'SOUTHERN LEYTE',
//     municipality: 'HINUNDAYAN',
//     region: 'REGION VIII',
//     barangay_list: [
//       'AMAGA',
//       'AMBAO',
//       'AN-AN',
//       'BACULOD',
//       'BIASONG',
//       'BUGHO',
//       'CABULISAN',
//       'CAT-IWING',
//       'DISTRICT I (POB.)',
//       'DISTRICT II (POB.)',
//       'DISTRICT III (POB.)',
//       'HUBASAN',
//       'LUNGSODAAN',
//       'NAVALITA',
//       'PLARIDEL',
//       'SABANG',
//       'SAGBOK',
//     ],
//   },
//   LIBAGON: {
//     province: 'SOUTHERN LEYTE',
//     municipality: 'LIBAGON',
//     region: 'REGION VIII',
//     barangay_list: [
//       'BIASONG',
//       'BOGASONG',
//       'CAWAYAN',
//       'GAKAT',
//       'JUBAS (POB.)',
//       'MAGKASAG',
//       'MAYUGA',
//       'NAHAONG',
//       'NAHULID',
//       'OTIKON',
//       'PANGI',
//       'PUNTA',
//       'TALISAY (POB.)',
//       'TIGBAO',
//     ],
//   },
//   LIMASAWA: {
//     province: 'SOUTHERN LEYTE',
//     municipality: 'LIMASAWA',
//     region: 'REGION VIII',
//     barangay_list: [
//       'CABULIHAN',
//       'LUGSONGAN',
//       'MAGALLANES',
//       'SAN AGUSTIN',
//       'SAN BERNARDO',
//       'TRIANA',
//     ],
//   },
//   'MAASIN': {
//     province: 'SOUTHERN LEYTE',
//     municipality: 'MAASIN',
//     region: 'REGION VIII',
//     barangay_list: [
//       'ABGAO (POB.)',
//       'ACASIA',
//       'ASUNCION',
//       'BACTUL I',
//       'BACTUL II',
//       'BADIANG',
//       'BAGTICAN',
//       'BASAK',
//       'BATO I',
//       'BATO II',
//       'BATUAN',
//       'BAUGO',
//       'BILIBOL',
//       'BOGO',
//       'CABADIANGAN',
//       'CABULIHAN',
//       'CAGNITUAN',
//       'CAMBOOC',
//       'CANSIRONG',
//       'CANTURING',
//       'CANYUOM',
//       'COMBADO',
//       'DONGON',
//       'GAWISAN',
//       'GUADALUPE',
//       'HANGINAN',
//       'HANTAG',
//       'HINAPU DAKU',
//       'HINAPU GAMAY',
//       'IBARRA',
//       'ISAGANI',
//       'LABOON',
//       'LANAO',
//       'LIB-OG',
//       'LIBERTAD',
//       'LIBHU',
//       'LONOY',
//       'LUNAS',
//       'MAHAYAHAY',
//       'MALAPOC NORTE',
//       'MALAPOC SUR',
//       'MAMBAJAO (POB.)',
//       'MANHILO',
//       'MANTAHAN (POB.)',
//       'MARIA CLARA',
//       'MATIN-AO',
//       'NASAUG',
//       'NATI',
//       'NONOK NORTE',
//       'NONOK SUR',
//       'PANAN-AWAN',
//       'PANSAAN',
//       'PASAY',
//       'PINASCOHAN',
//       'RIZAL',
//       'SAN AGUSTIN',
//       'SAN ISIDRO',
//       'SAN JOSE',
//       'SAN RAFAEL',
//       'SANTA CRUZ',
//       'SANTA ROSA',
//       'SANTO NIÑO',
//       'SANTO ROSARIO',
//       'SORO-SORO',
//       'TAGNIPA (POB.)',
//       'TAM-IS',
//       'TAWID',
//       'TIGBAWAN',
//       'TOMOY-TOMOY',
//       'TUNGA-TUNGA (POB.)',
//     ],
//   },
//   MACROHON: {
//     province: 'SOUTHERN LEYTE',
//     municipality: 'MACROHON',
//     region: 'REGION VIII',
//     barangay_list: [
//       'AGUINALDO',
//       'AMPARO',
//       'ASUNCION',
//       'BAGONG SILANG',
//       'BUSCAYAN',
//       'CAMBARO',
//       'CANLUSAY',
//       'DANAO',
//       'FLORDELIZ',
//       'GUADALUPE',
//       'ICHON',
//       'ILIHAN',
//       'LARAY',
//       'LOWER VILLA JACINTA',
//       'MABINI',
//       'MOHON',
//       'MOLOPOLO',
//       'RIZAL',
//       'SALVADOR (MANGYANG)',
//       'SAN ISIDRO',
//       'SAN JOAQUIN',
//       'SAN ROQUE',
//       'SAN VICENTE (UPPER SAN ROQUE)',
//       'SAN VICENTE POBLACION',
//       'SANTA CRUZ (POB.)',
//       'SANTO NIÑO',
//       'SANTO ROSARIO (POB.)',
//       'SINDANGAN',
//       'UPPER ICHON',
//       'UPPER VILLA JACINTA',
//     ],
//   },
//   PINTUYAN: {
//     province: 'SOUTHERN LEYTE',
//     municipality: 'PINTUYAN',
//     region: 'REGION VIII',
//     barangay_list: [
//       'BADIANG',
//       'BALONGBALONG',
//       'BUENAVISTA',
//       'BULAWAN',
//       'CANLAWIS',
//       'CATBAWAN',
//       'CAUBANG',
//       'COGON',
//       'DAN-AN',
//       'LOBO',
//       'MAINIT',
//       'MANGLIT',
//       'NUEVA ESTRELLA NORTE',
//       'NUEVA ESTRELLA SUR',
//       'POBLACION IBABAO',
//       'POBLACION UBOS',
//       'POCIANO D. EQUIPILAG',
//       'PONOD',
//       'SAN ROQUE',
//       'SANTA CRUZ',
//       'SON-OK I',
//       'SON-OK II',
//       'TAUTAG',
//     ],
//   },
//   'SAINT BERNARD': {
//     province: 'SOUTHERN LEYTE',
//     municipality: 'SAINT BERNARD',
//     region: 'REGION VIII',
//     barangay_list: [
//       'ATUYAN',
//       'AYAHAG',
//       'BANTAWON',
//       'BOLODBOLOD',
//       'CABAGAWAN',
//       'CARNAGA',
//       'CATMON',
//       'GUINSAUGON',
//       'HIMATAGON (POB.)',
//       'HIMBANGAN',
//       'HIMOS-ONAN',
//       'HINABIAN',
//       'HINDAG-AN',
//       'KAUSWAGAN',
//       'LIBAS',
//       'LIPANTO',
//       'MAGATAS',
//       'MAGBAGACAY',
//       'MAHAYAG',
//       'MAHAYAHAY',
//       'MALIBAGO',
//       'MALINAO',
//       'NUEVA ESPERANZA (CABAC-AN)',
//       'PANIAN',
//       'SAN ISIDRO',
//       'SANTA CRUZ',
//       'SUG-ANGON',
//       'TABONTABON',
//       'TAMBIS I',
//       'TAMBIS II',
//     ],
//   },
//   'SAN JUAN (CABALIAN)': {
//     province: 'SOUTHERN LEYTE',
//     municipality: 'SAN JUAN (CABALIAN)',
//     region: 'REGION VIII',
//     barangay_list: [
//       'AGAY-AY',
//       'BASAK',
//       'BOBON A',
//       'BOBON B',
//       'DAYANOG',
//       'GARRIDO',
//       'MINOYHO',
//       'OSAO',
//       'PONG-OY',
//       'SAN JOSE (POB.)',
//       'SAN ROQUE',
//       'SAN VICENTE',
//       'SANTA CRUZ (POB.)',
//       'SANTA FILOMENA',
//       'SANTO NIÑO (POB.)',
//       'SOMOJE',
//       'SUA',
//       'TIMBA',
//     ],
//   },
//   'SAN RICARDO': {
//     province: 'SOUTHERN LEYTE',
//     municipality: 'SAN RICARDO',
//     region: 'REGION VIII',
//     barangay_list: [
//       'BENIT',
//       'BITOON',
//       'CABUTAN',
//       'CAMANG',
//       'ESPERANZA',
//       'ESPERANZA DOS',
//       'INOLINAN',
//       'KINACHAWA',
//       'LOOC',
//       'PINUT-AN',
//       'POBLACION',
//       'SAN ANTONIO (ALANGALANG)',
//       'SAN RAMON',
//       'SAUB',
//       'TIMBA',
//     ],
//   },
//   SILAGO: {
//     province: 'SOUTHERN LEYTE',
//     municipality: 'SILAGO',
//     region: 'REGION VIII',
//     barangay_list: [
//       'BALAGAWAN',
//       'CATMON',
//       'HINGATUNGAN',
//       'IMELDA',
//       'KATIPUNAN',
//       'LAGUMA',
//       'MERCEDES',
//       'POB. DISTRICT I',
//       'POB. DISTRICT II',
//       'PUNTANA',
//       'SALVACION',
//       'SAP-ANG',
//       'SUDMON',
//       'TUBA-ON',
//       'TUBOD',
//     ],
//   },
//   'TOMAS OPPUS': {
//     province: 'SOUTHERN LEYTE',
//     municipality: 'TOMAS OPPUS',
//     region: 'REGION VIII',
//     barangay_list: [
//       'ANAHAWAN',
//       'BANDAY (POB.)',
//       'BIASONG',
//       'BOGO (POB.)',
//       'CABASCAN',
//       'CAMANSI',
//       'CAMBITE (POB.)',
//       'CANLUPAO',
//       'CARNAGA',
//       'CAWAYAN',
//       'HIGOSOAN',
//       'HINAGTIKAN',
//       'HINAPO',
//       'HUGPA',
//       'INIGUIHAN POB. (SALVACION)',
//       'LOOC',
//       'LUAN',
//       'MAANYAG',
//       'MAG-ATA',
//       'MAPGAP',
//       'MASLOG',
//       'PONONG',
//       'RIZAL',
//       'SAN AGUSTIN',
//       'SAN ANTONIO',
//       'SAN ISIDRO',
//       'SAN MIGUEL',
//       'SAN ROQUE',
//       'TINAGO',
//     ],
//   },
//   'BACUNGAN (LEON T. POSTIGO)': {
//     province: 'ZAMBOANGA DEL NORTE',
//     municipality: 'BACUNGAN (LEON T. POSTIGO)',
//     region: 'REGION IX',
//     barangay_list: [
//       'BACUNGAN (POB.)',
//       'BOGABONGAN',
//       'DELUSOM',
//       'MANGOP',
//       'MANIL',
//       'MAWAL',
//       'MIDATAG',
//       'MOROB',
//       'NASIBAC',
//       'RIZON',
//       'SANTA MARIA',
//       'SIPACONG',
//       'TALINGA',
//       'TINAPLAN',
//       'TINIGUIBAN',
//       'TINUYOP',
//       'TIOGAN',
//       'TITIK',
//     ],
//   },
//   BALIGUIAN: {
//     province: 'ZAMBOANGA DEL NORTE',
//     municipality: 'BALIGUIAN',
//     region: 'REGION IX',
//     barangay_list: [
//       'ALEGRIA',
//       'DIANGAS',
//       'DICULOM',
//       'GUIMOTAN',
//       'KAUSWAGAN',
//       'KILALABAN',
//       'LINAY',
//       'LUMAY',
//       'MALINAO',
//       'MAMAD',
//       'MAMAWAN',
//       'MILIDAN',
//       'NONOYAN',
//       'POBLACION',
//       'SAN JOSE',
//       'TAMAO',
//       'TAN-AWAN',
//     ],
//   },
//   'DAPITAN': {
//     province: 'ZAMBOANGA DEL NORTE',
//     municipality: 'DAPITAN',
//     region: 'REGION IX',
//     barangay_list: [
//       'ALIGUAY',
//       'ANTIPOLO',
//       'ASENIERO',
//       'BA-AO',
//       'BAGTING (POB.)',
//       'BANBANAN',
//       'BANONONG (POB.)',
//       'BARCELONA',
//       'BAYLIMANGO',
//       'BURGOS',
//       'CANLUCANI',
//       'CARANG',
//       'CAWA-CAWA (POB.)',
//       'DAMPALAN',
//       'DARO',
//       'DAWO (POB.)',
//       'DIWA-AN',
//       'GUIMPUTLAN',
//       'HILLTOP',
//       'ILAYA',
//       'KAUSWAGAN (TALISAY)',
//       'LARAYAN',
//       'LINABO (POB.)',
//       'LIYANG',
//       'MARIA CRISTINA',
//       'MARIA URAY',
//       'MASIDLAKON',
//       'MATAGOBTOB POB. (TALISAY)',
//       'NAPO',
//       'OPAO',
//       'ORO',
//       'OWAON',
//       'OYAN',
//       'POLO',
//       'POTOL (POB.)',
//       'POTUNGAN',
//       'SAN FRANCISCO',
//       'SAN NICOLAS',
//       'SAN PEDRO',
//       'SAN VICENTE',
//       'SANTA CRUZ (POB.)',
//       'SANTO NIÑO',
//       'SICAYAB BOCANA',
//       'SIGAYAN',
//       'SILINOG',
//       'SINONOC',
//       'SULANGON',
//       'TAG-OLO',
//       'TAGUILON',
//       'TAMION',
//     ],
//   },
//   'DIPOLOG': {
//     province: 'ZAMBOANGA DEL NORTE',
//     municipality: 'DIPOLOG',
//     region: 'REGION IX',
//     barangay_list: [
//       'BARRA (POB.)',
//       'BIASONG (POB.)',
//       'CENTRAL (POB.)',
//       'COGON',
//       'DICAYAS',
//       'DIWAN',
//       'ESTACA (POB.)',
//       'GALAS',
//       'GULAYON',
//       'LUGDUNGAN',
//       'MINAOG',
//       'MIPUTAK (POB.)',
//       'OLINGAN',
//       'PUNTA',
//       'SAN JOSE',
//       'SANGKOL',
//       'SANTA FILOMENA',
//       'SANTA ISABEL',
//       'SICAYAB',
//       'SINAMAN',
//       'TURNO',
//     ],
//   },
//   GODOD: {
//     province: 'ZAMBOANGA DEL NORTE',
//     municipality: 'GODOD',
//     region: 'REGION IX',
//     barangay_list: [
//       'BALUNO',
//       'BANUANGAN',
//       'BUNAWAN',
//       'DILUCOT',
//       'DIPOPOR',
//       'GUISAPONG',
//       'LIMBONGA (LIMBOANGAN)',
//       'LOMOGOM',
//       'MAUSWAGON',
//       'MIAMPIC',
//       'POBLACION',
//       'RABA',
//       'RAMBON',
//       'SAN PEDRO',
//       'SARAWAGAN',
//       'SIANAN',
//       'SIORAN',
//     ],
//   },
//   GUTALAC: {
//     province: 'ZAMBOANGA DEL NORTE',
//     municipality: 'GUTALAC',
//     region: 'REGION IX',
//     barangay_list: [
//       'BACONG',
//       'BAGONG SILANG',
//       'BANGANON',
//       'BAYANIHAN',
//       'BUENAVISTA',
//       'CANUPONG',
//       'COCOB',
//       'DATAGAN',
//       'IMELDA',
//       'IMMACULADA CONCEPCION',
//       'LA LIBERTAD',
//       'LOAY',
//       'LOWER LUX',
//       'LUX',
//       'MALIAN',
//       'MAMAWAN',
//       'MAP',
//       'MATUNOY',
//       'NEW DAPITAN',
//       'PANGANURAN',
//       'PITAWE',
//       'PITOGO',
//       'POBLACION (GUTALAC)',
//       'SALVADOR',
//       'SAN ISIDRO',
//       'SAN JUAN',
//       'SAN ROQUE',
//       'SAN VICENTE',
//       'SANTO NIÑO',
//       'SAS',
//       'SIBALIC',
//       'TIPAN',
//       'UPPER GUTALAC',
//     ],
//   },
//   'JOSE DALMAN (PONOT)': {
//     province: 'ZAMBOANGA DEL NORTE',
//     municipality: 'JOSE DALMAN (PONOT)',
//     region: 'REGION IX',
//     barangay_list: [
//       'BALATAKAN',
//       'BITOON',
//       'DINASAN',
//       'ILIHAN',
//       'LABAKID',
//       'LIPAY',
//       'LITALIP',
//       'LOPERO',
//       'LUMANPING',
//       'MADALAG',
//       'MANAWAN',
//       'MARUPAY',
//       'POBLACION (PONOT)',
//       'SIGAMOK',
//       'SIPAROK',
//       'TABON',
//       'TAMAROK',
//       'TAMIL',
//     ],
//   },
//   KALAWIT: {
//     province: 'ZAMBOANGA DEL NORTE',
//     municipality: 'KALAWIT',
//     region: 'REGION IX',
//     barangay_list: [
//       'BATAYAN',
//       'BOTONG',
//       'CONCEPCION',
//       'DANIEL MAING (DOMINOLOG)',
//       'FATIMA (LACSUTAN)',
//       'GATAS',
//       'KALAWIT (POB.)',
//       'MARCELO',
//       'NEW CALAMBA',
//       'PALALIAN',
//       'PARAISO',
//       'PIANON',
//       'SAN JOSE',
//       'TUGOP',
//     ],
//   },
//   KATIPUNAN: {
//     province: 'ZAMBOANGA DEL NORTE',
//     municipality: 'KATIPUNAN',
//     region: 'REGION IX',
//     barangay_list: [
//       'BALOK',
//       'BARANGAY DOS (POB.)',
//       'BARANGAY UNO (POB.)',
//       'BASAGAN',
//       'BINIRAY',
//       'BULAWAN',
//       'CARUPAY',
//       'DAANGLUNGSOD',
//       'DABIAK',
//       'DR. JOSE RIZAL (LOWER MIAS)',
//       'FIMAGAS',
//       'LOYURAN',
//       'MALASAY',
//       'MALUGAS',
//       'MATAM',
//       'MIAS',
//       'MIATAN',
//       'NANGINAN',
//       'NEW TAMBO',
//       'PATIK',
//       'SAN ANTONIO (LOOY)',
//       'SAN VICENTE',
//       'SANAO',
//       'SANTO NIÑO',
//       'SERES',
//       'SEROAN',
//       'SINGATONG',
//       'SINUYAK',
//       'SITOG',
//       'TUBURAN',
//     ],
//   },
//   LABASON: {
//     province: 'ZAMBOANGA DEL NORTE',
//     municipality: 'LABASON',
//     region: 'REGION IX',
//     barangay_list: [
//       'ANTONINO (POB.)',
//       'BALAS',
//       'BOBONGAN',
//       'DANSALAN',
//       'GABU',
//       'GIL SANCHEZ',
//       'IMELDA',
//       'IMMACULADA',
//       'KIPIT',
//       'LA UNION',
//       'LAPATAN',
//       'LAWAGAN',
//       'LAWIGAN',
//       'LOPOC (POB.)',
//       'MALINTUBOAN',
//       'NEW SALVACION',
//       'OSUKAN',
//       'PATAWAG',
//       'SAN ISIDRO',
//       'UBAY',
//     ],
//   },
//   LILOY: {
//     province: 'ZAMBOANGA DEL NORTE',
//     municipality: 'LILOY',
//     region: 'REGION IX',
//     barangay_list: [
//       'BANIGAN',
//       'BAYBAY (POB.)',
//       'CABANGCALAN',
//       'CANAAN',
//       'CANDELARIA',
//       'CAUSWAGAN',
//       'COMMUNAL',
//       'COMPRA',
//       'DELA PAZ',
//       'EL PARAISO',
//       'FATIMA',
//       'GANASE',
//       'GOAW',
//       'GOIN',
//       'KAYOK',
//       'LA LIBERTAD (MAWAL)',
//       'LAMAO',
//       'MABUHAY',
//       'MAIGANG',
//       'MALILA',
//       'MAUSWAGON',
//       'NEW BETHLEHEM',
//       'OVERVIEW',
//       'PANABANG',
//       'PATAWAG',
//       'PUNTA',
//       'SAN FRANCISCO',
//       'SAN ISIDRO',
//       'SAN MIGUEL',
//       'SAN ROQUE',
//       'SANTA CRUZ',
//       'SANTO NIÑO',
//       'SILUCAP',
//       'TAPICAN',
//       'TIMAN',
//       'VILLA CALIXTO SUDIACAL',
//       'VILLA M. TEJERO',
//     ],
//   },
//   MANUKAN: {
//     province: 'ZAMBOANGA DEL NORTE',
//     municipality: 'MANUKAN',
//     region: 'REGION IX',
//     barangay_list: [
//       'DIPANE',
//       'DISAKAN',
//       'DON JOSE AGUIRRE',
//       'EAST POBLACION',
//       'GUPOT',
//       'LIBUTON',
//       'LINAY',
//       'LINGATONGAN',
//       'LUPASANG',
//       'MATE',
//       'MESES',
//       'PALARANAN',
//       'PANGANDAO',
//       'PATAGAN',
//       'POBLACION',
//       'PUNTA BLANCA',
//       'SALUYONG',
//       'SAN ANTONIO',
//       'SERONGAN',
//       'SUISAYAN',
//       'UPPER DISAKAN',
//       'VILLARAMOS',
//     ],
//   },
//   MUTIA: {
//     province: 'ZAMBOANGA DEL NORTE',
//     municipality: 'MUTIA',
//     region: 'REGION IX',
//     barangay_list: [
//       'ALVENDA',
//       'BUENASUERTE',
//       'DILAND',
//       'DIOLEN',
//       'HEAD TIPAN',
//       'NEW CASUL',
//       'NEW SIQUIJOR',
//       'NEWLAND',
//       'PASO RIO',
//       'POBLACION',
//       'SAN MIGUEL',
//       'SANTO TOMAS',
//       'TINGLAN',
//       'TOTONGON',
//       'TUBAC',
//       'UNIDOS',
//     ],
//   },
//   'PIÑAN (NEW PIÑAN)': {
//     province: 'ZAMBOANGA DEL NORTE',
//     municipality: 'PIÑAN (NEW PIÑAN)',
//     region: 'REGION IX',
//     barangay_list: [
//       'ADANTE',
//       'BACUYONG',
//       'BAGONG SILANG',
//       'CALICAN',
//       'DEL PILAR',
//       'DESIN',
//       'DILAWA',
//       'DIONUM',
//       'LAPU-LAPU',
//       'LOWER GUMAY',
//       'LUZVILLA',
//       'POBLACION NORTH',
//       'POBLACION SOUTH',
//       'SANTA FE',
//       'SEGABE',
//       'SIKITAN',
//       'SILANO',
//       'TERESITA',
//       'TINAYTAYAN',
//       'UBAY (DAAN TIPAN)',
//       'UPPER GUMAY',
//       'VILLARICO',
//     ],
//   },
//   POLANCO: {
//     province: 'ZAMBOANGA DEL NORTE',
//     municipality: 'POLANCO',
//     region: 'REGION IX',
//     barangay_list: [
//       'ANASTACIO',
//       'BANDERA',
//       'BETHLEHEM',
//       'DANGI',
//       'DANSULLAN',
//       'DE VENTA PERLA',
//       'GUINLES',
//       'ISIS',
//       'LABRADOR (PRINDA)',
//       'LAPAYANBAJA',
//       'LETAPAN',
//       'LINABO',
//       'LINGASAD',
//       'MACLEODES',
//       'MAGANGON',
//       'MALIGAYA',
//       'MILAD',
//       'NEW LEBANGON',
//       'NEW SICAYAB',
//       'OBAY',
//       'PIAN',
//       'POBLACION NORTH',
//       'POBLACION SOUTH',
//       'SAN ANTONIO (PAETAN)',
//       'SAN MIGUEL (LOBOC)',
//       'SAN PEDRO',
//       'SANTO NIÑO (LANTOY)',
//       'SIANIB',
//       'SILAWE',
//       'VILLAHERMOSA',
//     ],
//   },
//   'PRES. MANUEL A. ROXAS': {
//     province: 'ZAMBOANGA DEL NORTE',
//     municipality: 'PRES. MANUEL A. ROXAS',
//     region: 'REGION IX',
//     barangay_list: [
//       'BALUBO',
//       'BANBANAN',
//       'CANIBONGAN',
//       'CAPASE',
//       'CAPE',
//       'DENOMAN',
//       'DOHINOB',
//       'GALOKSO',
//       'GUBAT',
//       'IRASAN',
//       'LABAKID',
//       'LANGATIAN',
//       'LIPAKAN',
//       'MARUPAY',
//       'MOLITON',
//       'NABILID',
//       'PANAMPALAY',
//       'PANGOLOGON',
//       'PIAO',
//       'PIÑALAN',
//       'PIÑAMAR',
//       'PONGOLAN',
//       'SALISIG',
//       'SEBOD',
//       'SIBATOG',
//       'SITUBO',
//       'TANAYAN',
//       'TANTINGON',
//       'UPPER IRASAN',
//       'UPPER MINANG',
//       'VILLAHERMOSO',
//     ],
//   },
//   SALUG: {
//     province: 'ZAMBOANGA DEL NORTE',
//     municipality: 'SALUG',
//     region: 'REGION IX',
//     barangay_list: [
//       'BACONG',
//       'BALAKAN',
//       'BINONI',
//       'CALUCAP',
//       'CANAWAN',
//       'CARACOL',
//       'DANAO',
//       'DINOAN',
//       'DIPOLOD',
//       'FATIMA (POGAN)',
//       'IPILAN',
//       'LANAWAN',
//       'LIGUAC',
//       'LIPAKAN',
//       'MUCAS',
//       'PACUHAN',
//       'POBLACION (SALUG)',
//       'POBLACION EAST',
//       'PUKAY',
//       'RAMON MAGSAYSAY',
//       'SANTO NIÑO',
//       'TAMBALANG',
//       'TAPALAN',
//     ],
//   },
//   'SERGIO OSMEÑA SR.': {
//     province: 'ZAMBOANGA DEL NORTE',
//     municipality: 'SERGIO OSMEÑA SR.',
//     region: 'REGION IX',
//     barangay_list: [
//       'ANTONINO',
//       'BAGONG BAGUIO',
//       'BAGUMBAYAN',
//       'BIAYON',
//       'BUENAVISTA',
//       'DAMPALAN',
//       'DANAO',
//       'DON ELENO',
//       'KAUSWAGAN',
//       'LABIRAY',
//       'LIWANAG',
//       'MABUHAY',
//       'MACALIBRE',
//       'MAHAYAHAY',
//       'MARAPONG',
//       'NAZARETH',
//       'NEBO',
//       'NEW RIZAL',
//       'NEW TANGUB',
//       'NUEVAVISTA',
//       'PEDAGAN',
//       'PENACIO',
//       'POBLACION ALTO',
//       'POBLACION BAJO',
//       'PRINCESA FRESHIA',
//       'PRINCESA LAMAYA',
//       'SAN ANTONIO',
//       'SAN FRANCISCO',
//       'SAN ISIDRO',
//       'SAN JOSE',
//       'SAN JUAN',
//       'SINAAD',
//       'SINAI',
//       'SITUBO',
//       'TINAGO',
//       'TININDUGAN',
//       'TUBURAN',
//       'VENUS',
//       'WILBEN',
//     ],
//   },
//   SIAYAN: {
//     province: 'ZAMBOANGA DEL NORTE',
//     municipality: 'SIAYAN',
//     region: 'REGION IX',
//     barangay_list: [
//       'BALOK',
//       'BALUNOKAN',
//       'DATAGAN',
//       'DENOYAN',
//       'DIONGAN',
//       'DOMOGOK',
//       'DUMPILAS',
//       'GONAYEN',
//       'GUIBO',
//       'GUNYAN',
//       'LITOLET',
//       'MACASING',
//       'MANGILAY',
//       'MOYO',
//       'MUÑOZ',
//       'PANGE',
//       'PARANGLUMBA (POB.)',
//       'POLAYO',
//       'SAYAW',
//       'SERIAC',
//       'SIAYAN PROPER (POB.)',
//       'SUGUILON',
//     ],
//   },
//   SIBUCO: {
//     province: 'ZAMBOANGA DEL NORTE',
//     municipality: 'SIBUCO',
//     region: 'REGION IX',
//     barangay_list: [
//       'ANONGAN',
//       'BASAK',
//       'BONGALAO',
//       'CABBUNAN',
//       'CAWIT-CAWIT',
//       'CULAGUAN',
//       'CUSIPAN',
//       'DINULAN',
//       'JATIAN',
//       'KAMARANGAN',
//       'LAKIKI',
//       'LAMBAGOAN',
//       'LIMPAPA',
//       'LINGAYON',
//       'LINTANGAN',
//       'LITAWAN',
//       'LUNDAY',
//       'MALAYAL',
//       'MANTIVO',
//       'NALA (POB.)',
//       'PANGANURAN',
//       'PANGIAN',
//       'PANIRAN',
//       'PASILNAHUT',
//       'POBLACION',
//       'PULIRAN',
//       'SANTO NIÑO (CULABOG)',
//       'TANGARAK',
//     ],
//   },
//   SIBUTAD: {
//     province: 'ZAMBOANGA DEL NORTE',
//     municipality: 'SIBUTAD',
//     region: 'REGION IX',
//     barangay_list: [
//       'BAGACAY',
//       'CALILIC',
//       'CALUBE',
//       'DELAPA',
//       'KANIM',
//       'LIBAY',
//       'MAGSAYSAY',
//       'MARAPONG',
//       'MINLASAG',
//       'OYAN',
//       'PANGANURAN',
//       'POBLACION (SIBUTAD)',
//       'SAWANG',
//       'SIBULOC',
//       'SINIPAY',
//       'SIPALOC',
//     ],
//   },
//   SINDANGAN: {
//     province: 'ZAMBOANGA DEL NORTE',
//     municipality: 'SINDANGAN',
//     region: 'REGION IX',
//     barangay_list: [
//       'BAGO',
//       'BALOK',
//       'BANTAYAN',
//       'BATO',
//       'BENIGNO AQUINO JR.',
//       'BINUANGAN',
//       'BITOON',
//       'BUCANA',
//       'CALATUNAN',
//       'CALUAN',
//       'CALUBIAN',
//       'DAGOHOY',
//       'DAPAON',
//       'DATAGAN',
//       'DATU TANGKILAN',
//       'DICOYONG',
//       'DISUD',
//       'DON RICARDO MACIAS (DINOBOT)',
//       'DOÑA JOSEFA',
//       'DUMALOGDOG',
//       'FATIMA',
//       'GAMPIS',
//       'GOLEO',
//       'IMELDA',
//       'INUMAN',
//       'JOAQUIN MACIAS',
//       'LA CONCEPCION',
//       'LA ROCHE SAN MIGUEL',
//       'LABAKID',
//       'LAGAG',
//       'LAPERO',
//       'LAWIS',
//       'MAGSAYSAY',
//       'MANDIH',
//       'MARAS',
//       'MAWAL',
//       'MISOK',
//       'MOTIBOT',
//       'NATO',
//       'NIPAAN',
//       'PANGALALAN',
//       'PIAO',
//       'POBLACION',
//       'SANTO NIÑO',
//       'SANTO ROSARIO',
//       'SIARE',
//       'TALINGA',
//       'TIGBAO',
//       'TINAPLAN',
//       'TITIK',
//       'UPPER INUMAN',
//       'UPPER NIPAAN',
//     ],
//   },
//   SIOCON: {
//     province: 'ZAMBOANGA DEL NORTE',
//     municipality: 'SIOCON',
//     region: 'REGION IX',
//     barangay_list: [
//       'ANDRES MICUBO JR. (BALILI)',
//       'BALAGUNAN',
//       'BUCANA',
//       'BULACAN',
//       'CANDIZ',
//       'DATU SAILILA',
//       'DIONISIO RICONALLA',
//       'JOSE P. BRILLANTES, SR. (OLD LITUBAN)',
//       'LATABON',
//       'MAKIANG',
//       'MALAMBUHANGIN',
//       'MALIPOT',
//       'MANAOL',
//       'MATEO FRANCISCO',
//       'MATIAG',
//       'NEW LITUBAN',
//       'PANGIAN',
//       'PISAWAK',
//       'POBLACION',
//       'S. CABRAL',
//       'SANTA MARIA',
//       'SIAY',
//       'SUHAILE ARABI',
//       'TABAYO',
//       'TAGAYTAY',
//       'TIBANGAO',
//     ],
//   },
//   SIRAWAI: {
//     province: 'ZAMBOANGA DEL NORTE',
//     municipality: 'SIRAWAI',
//     region: 'REGION IX',
//     barangay_list: [
//       'BALATAKAN',
//       'BALONKAN',
//       'BALUBUAN',
//       'BITUGAN',
//       'BONGON',
//       'CATUYAN',
//       'CULASIAN',
//       'DANGANON',
//       'DOÑA CECILIA',
//       'GUBAN',
//       'LAGUNDI',
//       'LIBUCON',
//       'LUBOK',
//       'MACUYON',
//       'MINANGA',
//       'MOTONG',
//       'NAPULAN',
//       'PANABUTAN',
//       'PIACAN',
//       'PIÑA',
//       'PISA ITOM',
//       'PISA PUTI',
//       'PUGOS',
//       'PULA BATO',
//       'PULANG LUPA',
//       'SAINT MARY (POB.)',
//       'SAN NICOLAS (POB.)',
//       'SAN ROQUE (POB.)',
//       'SAN VICENTE (POB.)',
//       'SIPAKIT',
//       'SIPAWA',
//       'SIRAWAI PROPER (POB.)',
//       'TALABIGA',
//       'TAPANAYAN',
//     ],
//   },
//   TAMPILISAN: {
//     province: 'ZAMBOANGA DEL NORTE',
//     municipality: 'TAMPILISAN',
//     region: 'REGION IX',
//     barangay_list: [
//       'BALACBAAN',
//       'BANBANAN',
//       'BARILI',
//       'CABONG',
//       'CAMUL',
//       'FARMINGTON',
//       'GALINGON',
//       'LAWAAN',
//       'LUMBAYAO',
//       'MALILA-T',
//       'MOLOS',
//       'NEW DAPITAN',
//       'POBLACION (TAMPILISAN)',
//       'SANDAYONG',
//       'SANTO NIÑO',
//       'SITUBO',
//       'TILUBOG',
//       'TININGGAAN',
//       'TUBOD',
//       'ZNAC',
//     ],
//   },
//   BAYOG: {
//     province: 'ZAMBOANGA DEL SUR',
//     municipality: 'BAYOG',
//     region: 'REGION IX',
//     barangay_list: [
//       'BAKING',
//       'BALUKBAHAN',
//       'BALUMBUNAN',
//       'BANTAL',
//       'BOBUAN',
//       'CAMP BLESSING',
//       'CANOAYAN',
//       'CONACON',
//       'DAGUM',
//       'DAMIT',
//       'DATAGAN',
//       'DEPASE',
//       'DEPILI',
//       'DEPORE',
//       'DEPOREHAN',
//       'DIMALINAO',
//       'KAHAYAGAN',
//       'KANIPAAN',
//       'LAMARE',
//       'LIBA',
//       'MATIN-AO',
//       'MATUN-OG',
//       'PANGI (SAN ISIDRO)',
//       'POBLACION',
//       'PULANG BATO',
//       'SALAWAGAN',
//       'SIGACAD',
//       'SUPON',
//     ],
//   },
//   DIMATALING: {
//     province: 'ZAMBOANGA DEL SUR',
//     municipality: 'DIMATALING',
//     region: 'REGION IX',
//     barangay_list: [
//       'BACAYAWAN',
//       'BAHA',
//       'BALANAGAN',
//       'BALUNO',
//       'BINUAY',
//       'BUBURAY',
//       'GRAP',
//       'JOSEFINA',
//       'KAGAWASAN',
//       'LALAB',
//       'LIBERTAD',
//       'MAGAHIS',
//       'MAHAYAG',
//       'MERCEDES',
//       'POBLACION',
//       'SALOAGAN',
//       'SAN ROQUE',
//       'SUGBAY UNO',
//       'SUMBATO',
//       'SUMPOT',
//       'TINGGABULONG',
//       'TINIGUANGAN',
//       'TIPANGI',
//       'UPPER LUDIONG',
//     ],
//   },
//   DINAS: {
//     province: 'ZAMBOANGA DEL SUR',
//     municipality: 'DINAS',
//     region: 'REGION IX',
//     barangay_list: [
//       'BACAWAN',
//       'BENUATAN',
//       'BERAY',
//       'DON JOSE',
//       'DONGOS',
//       'EAST MIGPULAO',
//       'GUINICOLALAY',
//       'IGNACIO GARRATA (NEW MIRAPAO)',
//       'KINACAP',
//       'LEGARDA 1',
//       'LEGARDA 2',
//       'LEGARDA 3',
//       'LOWER DIMAYA',
//       'LUCOBAN',
//       'LUDIONG',
//       'NANGKA',
//       'NIAN',
//       'OLD MIRAPAO',
//       'PISA-AN',
//       'POBLACION',
//       'PROPER DIMAYA',
//       'SAGACAD',
//       'SAMBULAWAN',
//       'SAN ISIDRO',
//       'SONGAYAN',
//       'SUMPOTAN',
//       'TARAKAN',
//       'UPPER DIMAYA',
//       'UPPER SIBUL',
//       'WEST MIGPULAO',
//     ],
//   },
//   DUMALINAO: {
//     province: 'ZAMBOANGA DEL SUR',
//     municipality: 'DUMALINAO',
//     region: 'REGION IX',
//     barangay_list: [
//       'ANONANG',
//       'BAG-ONG MISAMIS',
//       'BAG-ONG SILAO',
//       'BAGA',
//       'BALOBOAN',
//       'BANTA-AO',
//       'BIBILIK',
//       'CALINGAYAN',
//       'CAMALIG',
//       'CAMANGA',
//       'CUATRO-CUATRO',
//       'LOCUBAN',
//       'MALASIK',
//       'MAMA (SAN JUAN)',
//       'MATAB-ANG',
//       'MECOLONG',
//       'METOKONG',
//       'MOTOSAWA',
//       'PAG-ASA (POB.)',
//       'PAGLAUM (POB.)',
//       'PANTAD',
//       'PINIGLIBANO',
//       'REBOKON',
//       'SAN AGUSTIN',
//       'SIBUCAO',
//       'SUMADAT',
//       'TIKWAS',
//       'TINA',
//       'TUBO-PAIT',
//       'UPPER DUMALINAO',
//     ],
//   },
//   DUMINGAG: {
//     province: 'ZAMBOANGA DEL SUR',
//     municipality: 'DUMINGAG',
//     region: 'REGION IX',
//     barangay_list: [
//       'BAG-ONG VALENCIA',
//       'BAGONG KAUSWAGAN',
//       'BAGONG SILANG',
//       'BUCAYAN',
//       'CALUMANGGI',
//       'CANIBONGAN',
//       'CARIDAD',
//       'DANLUGAN',
//       'DAPIWAK',
//       'DATU TOTOCAN',
//       'DILUD',
//       'DITULAN',
//       'DULIAN',
//       'DULOP',
//       'GUINTANANAN',
//       'GUITRAN',
//       'GUMPINGAN',
//       'LA FORTUNA',
//       'LABANGON',
//       'LIBERTAD',
//       'LICABANG',
//       'LIPAWAN',
//       'LOWER LANDING',
//       'LOWER TIMONAN',
//       'MACASING',
//       'MAHAYAHAY',
//       'MALAGALAD',
//       'MANLABAY',
//       'MARALAG',
//       'MARANGAN',
//       'NEW BASAK',
//       'SAAD',
//       'SALVADOR',
//       'SAN JUAN',
//       'SAN PABLO (POB.)',
//       'SAN PEDRO (POB.)',
//       'SAN VICENTE',
//       'SENOTE',
//       'SINONOK',
//       'SUNOP',
//       'TAGUN',
//       'TAMURAYAN',
//       'UPPER LANDING',
//       'UPPER TIMONAN',
//     ],
//   },
//   GUIPOS: {
//     province: 'ZAMBOANGA DEL SUR',
//     municipality: 'GUIPOS',
//     region: 'REGION IX',
//     barangay_list: [
//       'BAGONG OROQUIETA',
//       'BAGUITAN',
//       'BALONGATING',
//       'CANUNAN',
//       'DACSOL',
//       'DAGOHOY',
//       'DALAPANG',
//       'DATAGAN',
//       'GULING',
//       'KATIPUNAN',
//       'LINTUM',
//       'LITAN',
//       'MAGTING',
//       'POBLACION (GUIPOS)',
//       'REGLA',
//       'SIKATUNA',
//       'SINGCLOT',
//     ],
//   },
//   JOSEFINA: {
//     province: 'ZAMBOANGA DEL SUR',
//     municipality: 'JOSEFINA',
//     region: 'REGION IX',
//     barangay_list: [
//       'BOGO CALABAT',
//       'DAWA (DIWA)',
//       'EBARLE',
//       'GUMAHAN (POB.)',
//       'LEONARDO',
//       'LITAPAN',
//       'LOWER BAGONG TUDELA',
//       'MANSANAS',
//       'MORADJI',
//       'NEMEÑO',
//       'NOPULAN',
//       'SEBUKANG',
//       'TAGAYTAY HILL',
//       'UPPER BAGONG TUDELA (POB.)',
//     ],
//   },
//   KUMALARANG: {
//     province: 'ZAMBOANGA DEL SUR',
//     municipality: 'KUMALARANG',
//     region: 'REGION IX',
//     barangay_list: [
//       'BOGAYO',
//       'BOLISONG',
//       'BOYUGAN EAST',
//       'BOYUGAN WEST',
//       'BUALAN',
//       'DIPLO',
//       'GAWIL',
//       'GUSOM',
//       'KITAAN DAGAT',
//       'LANTAWAN',
//       'LIMAMAWAN',
//       'MAHAYAHAY',
//       'PANGI',
//       'PICANAN',
//       'POBLACION',
//       'SALAGMANOK',
//       'SECADE',
//       'SUMINALUM',
//     ],
//   },
//   LABANGAN: {
//     province: 'ZAMBOANGA DEL SUR',
//     municipality: 'LABANGAN',
//     region: 'REGION IX',
//     barangay_list: [
//       'BAGALUPA',
//       'BALIMBINGAN (WEST LUYA)',
//       'BINAYAN',
//       'BOKONG',
//       'BULANIT',
//       'COGONAN',
//       'COMBO',
//       'DALAPANG',
//       'DIMASANGCA',
//       'DIPAYA',
//       'LANGAPOD',
//       'LANTIAN',
//       'LOWER CAMPO ISLAM (POB.)',
//       'LOWER PULACAN',
//       'LOWER SANG-AN',
//       'NEW LABANGAN',
//       'NOBORAN',
//       'OLD LABANGAN',
//       'SAN ISIDRO',
//       'SANTA CRUZ',
//       'TAPODOC',
//       'TAWAGAN NORTE',
//       'UPPER CAMPO ISLAM (POB.)',
//       'UPPER PULACAN',
//       'UPPER SANG-AN',
//     ],
//   },
//   LAKEWOOD: {
//     province: 'ZAMBOANGA DEL SUR',
//     municipality: 'LAKEWOOD',
//     region: 'REGION IX',
//     barangay_list: [
//       'BAGONG KAHAYAG',
//       'BAKING',
//       'BISWANGAN',
//       'BULULAWAN',
//       'DAGUM',
//       'GASA',
//       'GATUB',
//       'LUKUAN',
//       'MATALANG',
//       'POBLACION (LAKEWOOD)',
//       'SAPANG PINOLES',
//       'SEBUGUEY',
//       'TIWALES',
//       'TUBOD',
//     ],
//   },
//   LAPUYAN: {
//     province: 'ZAMBOANGA DEL SUR',
//     municipality: 'LAPUYAN',
//     region: 'REGION IX',
//     barangay_list: [
//       'BULAWAN',
//       'CARPOC',
//       'DANGANAN',
//       'DANSAL',
//       'DUMARA',
//       'LINOKMADALUM',
//       'LUANAN',
//       'LUBUSAN',
//       'MAHALINGEB',
//       'MANDEG',
//       'MARALAG',
//       'MARUING',
//       'MOLUM',
//       'PAMPANG',
//       'PANTAD',
//       'PINGALAY',
//       'POBLACION',
//       'SALAMBUYAN',
//       'SAN JOSE',
//       'SAYOG',
//       'TABON',
//       'TALABAB',
//       'TIGUHA',
//       'TININGHALANG',
//       'TIPASAN',
//       'TUGAYA',
//     ],
//   },
//   MAHAYAG: {
//     province: 'ZAMBOANGA DEL SUR',
//     municipality: 'MAHAYAG',
//     region: 'REGION IX',
//     barangay_list: [
//       'BAG-ONG BALAMBAN',
//       'BAG-ONG DALAGUETE',
//       'BONIAO',
//       'DELUSOM',
//       'DIWAN',
//       'GURIPAN',
//       'KAANGAYAN',
//       'KABUHI',
//       'LOURMAH (LOWER MAHAYAG)',
//       'LOWER SALUG DAKU',
//       'LOWER SANTO NIÑO',
//       'MALUBO',
//       'MANGUILES',
//       'MARABANAN (BALANAN)',
//       'PANAGAAN',
//       'PARAISO',
//       'PEDAGAN',
//       'POBLACION (UPPER MAHAYAG)',
//       'PUGWAN',
//       'SAN ISIDRO',
//       'SAN JOSE',
//       'SAN VICENTE',
//       'SANTA CRUZ',
//       'SICPAO',
//       'TUBORAN',
//       'TULAN',
//       'TUMAPIC',
//       'UPPER SALUG DAKU',
//       'UPPER SANTO NIÑO',
//     ],
//   },
//   MARGOSATUBIG: {
//     province: 'ZAMBOANGA DEL SUR',
//     municipality: 'MARGOSATUBIG',
//     region: 'REGION IX',
//     barangay_list: [
//       'BALINTAWAK',
//       'BULARONG',
//       'DIGON',
//       'GUINIMANAN',
//       'IGAT ISLAND',
//       'JOSEFINA',
//       'KALIAN',
//       'KOLOT',
//       'LIMABATONG',
//       'LIMAMAWAN',
//       'LUMBOG',
//       'MAGAHIS',
//       'POBLACION',
//       'SAGUA',
//       'TALANUSA',
//       'TIGUIAN',
//       'TULAPOC',
//     ],
//   },
//   MIDSALIP: {
//     province: 'ZAMBOANGA DEL SUR',
//     municipality: 'MIDSALIP',
//     region: 'REGION IX',
//     barangay_list: [
//       'BACAHAN',
//       'BALONAI',
//       'BIBILOP',
//       'BULORON',
//       'CABALORAN',
//       'CANIPAY NORTE',
//       'CANIPAY SUR',
//       'CUMARON',
//       'DAKAYAKAN',
//       'DUELIC',
//       'DUMALINAO',
//       'ECUAN',
//       'GOLICTOP',
//       'GUINABOT',
//       'GUITALOS',
//       'GUMA',
//       'KAHAYAGAN',
//       'LICURO-AN',
//       'LUMPUNID',
//       'MATALANG',
//       'NEW KATIPUNAN',
//       'NEW UNIDOS',
//       'PALILI',
//       'PAWAN',
//       'PILI',
//       'PISOMPONGAN',
//       'PIWAN',
//       'POBLACION A',
//       'POBLACION B',
//       'SIGAPOD',
//       'TIMBABOY',
//       'TULBONG',
//       'TULUAN',
//     ],
//   },
//   MOLAVE: {
//     province: 'ZAMBOANGA DEL SUR',
//     municipality: 'MOLAVE',
//     region: 'REGION IX',
//     barangay_list: [
//       'ALICIA',
//       'ARIOSA',
//       'BAGONG ARGAO',
//       'BAGONG GUTLANG',
//       'BLANCIA',
//       'BOGO CAPALARAN',
//       'CULO',
//       'DALAON',
//       'DIPOLO',
//       'DONTULAN',
//       'GONOSAN',
//       'LOWER DIMALINAO',
//       'LOWER DIMOROK',
//       'MABUHAY',
//       'MADASIGON (POB.)',
//       'MAKUGUIHON (POB.)',
//       'MALOLOY-ON (POB.)',
//       'MILIGAN',
//       'PARASAN',
//       'RIZAL',
//       'SANTO ROSARIO',
//       'SILANGIT',
//       'SIMATA',
//       'SUDLON',
//       'UPPER DIMOROK',
//     ],
//   },
//   'PAGADIAN': {
//     province: 'ZAMBOANGA DEL SUR',
//     municipality: 'PAGADIAN',
//     region: 'REGION IX',
//     barangay_list: [
//       'ALEGRIA',
//       'BALANGASAN (POB.)',
//       'BALINTAWAK',
//       'BALOYBOAN',
//       'BANALE',
//       'BOGO',
//       'BOMBA',
//       'BUENAVISTA',
//       'BULATOK',
//       'BULAWAN',
//       'DAMPALAN',
//       'DANLUGAN',
//       'DAO',
//       'DATAGAN',
//       'DEBOROK',
//       'DITORAY',
//       'DUMAGOC',
//       'GATAS (POB.)',
//       'GUBAC',
//       'GUBANG',
//       'KAGAWASAN',
//       'KAHAYAGAN',
//       'KALASAN',
//       'KAWIT',
//       'LA SUERTE',
//       'LALA',
//       'LAPIDIAN',
//       'LENIENZA',
//       'LIZON VALLEY',
//       'LOURDES',
//       'LOWER SIBATANG',
//       'LUMAD',
//       'LUMBIA',
//       'MACASING',
//       'MANGA',
//       'MURICAY',
//       'NAPOLAN',
//       'PALPALAN',
//       'PEDULONAN',
//       'POLOYAGAN',
//       'SAN FRANCISCO (POB.)',
//       'SAN JOSE (POB.)',
//       'SAN PEDRO (POB.)',
//       'SANTA LUCIA (POB.)',
//       'SANTA MARIA',
//       'SANTIAGO (POB.)',
//       'SANTO NIÑO',
//       'TAWAGAN SUR',
//       'TIGUMA',
//       'TUBURAN (POB.)',
//       'TULANGAN',
//       'TULAWAS',
//       'UPPER SIBATANG',
//       'WHITE BEACH',
//     ],
//   },
//   'RAMON MAGSAYSAY (LIARGO)': {
//     province: 'ZAMBOANGA DEL SUR',
//     municipality: 'RAMON MAGSAYSAY (LIARGO)',
//     region: 'REGION IX',
//     barangay_list: [
//       'BAGONG OPON',
//       'BAMBONG DAKU',
//       'BAMBONG DIUT',
//       'BOBONGAN',
//       'CAMPO IV',
//       'CAMPO V',
//       'CANIANGAN',
//       'DIPALUSAN',
//       'EASTERN BOBONGAN',
//       'ESPERANZA',
//       'GAPASAN',
//       'KATIPUNAN',
//       'KAUSWAGAN',
//       'LOWER SAMBULAWAN',
//       'MABINI',
//       'MAGSAYSAY',
//       'MALATING',
//       'PARADISE',
//       'PASINGKALAN',
//       'POBLACION',
//       'SAN FERNANDO',
//       'SANTO ROSARIO',
//       'SAPA ANDING',
//       'SINAGUING',
//       'SWITCH',
//       'UPPER LAPERIAN',
//       'WAKAT',
//     ],
//   },
//   'SOMINOT (DON MARIANO MARCOS)': {
//     province: 'ZAMBOANGA DEL SUR',
//     municipality: 'SOMINOT (DON MARIANO MARCOS)',
//     region: 'REGION IX',
//     barangay_list: [
//       'BAG-ONG BAROY',
//       'BAG-ONG OROQUIETA',
//       'BARUBUHAN',
//       'BULANAY',
//       'DATAGAN',
//       'EASTERN POBLACION',
//       'LANTAWAN',
//       'LIBERTAD',
//       'LUMANGOY',
//       'NEW CARMEN',
//       'PICTURAN',
//       'POBLACION',
//       'RIZAL',
//       'SAN MIGUEL',
//       'SANTO NIÑO',
//       'SAWA',
//       'TUNGAWAN',
//       'UPPER SICPAO',
//     ],
//   },
//   TABINA: {
//     province: 'ZAMBOANGA DEL SUR',
//     municipality: 'TABINA',
//     region: 'REGION IX',
//     barangay_list: [
//       'ABONG-ABONG',
//       'BAGANIAN',
//       'BAYA-BAYA',
//       'CAPISAN',
//       'CONCEPCION',
//       'CULABAY',
//       'DOÑA JOSEFINA',
//       'LUMBIA',
//       'MABUHAY',
//       'MALIM',
//       'MANIKAAN',
//       'NEW OROQUIETA',
//       'POBLACION',
//       'SAN FRANCISCO',
//       'TULTOLAN',
//     ],
//   },
//   TAMBULIG: {
//     province: 'ZAMBOANGA DEL SUR',
//     municipality: 'TAMBULIG',
//     region: 'REGION IX',
//     barangay_list: [
//       'ALANG-ALANG',
//       'ANGELES',
//       'BAG-ONG KAUSWAGAN',
//       'BAG-ONG TABOGON',
//       'BALUGO',
//       'CABGAN',
//       'CALOLOT',
//       'DIMALINAO',
//       'FABIAN (BALUCOT)',
//       'GABUNON',
//       'HAPPY VALLEY (POB.)',
//       'KAPALARAN',
//       'LIBATO',
//       'LIMAMAWAN',
//       'LOWER LIASAN',
//       'LOWER LODIONG (POB.)',
//       'LOWER TIPARAK',
//       'LOWER USOGAN',
//       'MAYA-MAYA',
//       'NEW VILLAGE (POB.)',
//       'PELOCOBAN',
//       'RIVERSIDE (POB.)',
//       'SAGRADA FAMILIA',
//       'SAN JOSE',
//       'SAN VICENTE',
//       'SUMALIG',
//       'TULUAN',
//       'TUNGAWAN',
//       'UPPER LIASON',
//       'UPPER LODIONG',
//       'UPPER TIPARAK',
//     ],
//   },
//   TIGBAO: {
//     province: 'ZAMBOANGA DEL SUR',
//     municipality: 'TIGBAO',
//     region: 'REGION IX',
//     barangay_list: [
//       'BEGONG',
//       'BUSOL',
//       'CALUMA',
//       'DIANA COUNTRYSIDE',
//       'GUINLIN',
//       'LACARAYAN',
//       'LACUPAYAN',
//       'LIBAYOY',
//       'LIMAS',
//       'LONGMOT',
//       'MARAGANG',
//       'MATE',
//       'NANGAN-NANGAN',
//       'NEW TUBURAN',
//       'NILO',
//       'TIGBAO',
//       'TIMOLAN',
//       'UPPER NILO',
//     ],
//   },
//   TUKURAN: {
//     province: 'ZAMBOANGA DEL SUR',
//     municipality: 'TUKURAN',
//     region: 'REGION IX',
//     barangay_list: [
//       'ALINDAHAW',
//       'BACLAY',
//       'BALIMBINGAN',
//       'BUENASUERTE',
//       'CAMANGA',
//       'CURVADA',
//       'LAPERIAN',
//       'LIBERTAD',
//       'LOWER BAYAO',
//       'LUY-A',
//       'MANILAN',
//       'MANLAYAG',
//       'MILITAR',
//       'NAVALAN',
//       'PANDUMA SENIOR',
//       'SAMBULAWAN',
//       'SAN ANTONIO',
//       'SAN CARLOS (POB.)',
//       'SANTO NIÑO (POB.)',
//       'SANTO ROSARIO',
//       'SUGOD',
//       'TABUAN',
//       'TAGULO',
//       'TINOTUNGAN',
//       'UPPER BAYAO',
//     ],
//   },
//   'VINCENZO A. SAGUN': {
//     province: 'ZAMBOANGA DEL SUR',
//     municipality: 'VINCENZO A. SAGUN',
//     region: 'REGION IX',
//     barangay_list: [
//       'AMBULON',
//       'BUI-OS',
//       'COGON',
//       'DANAN',
//       'KABATAN',
//       'KAPATAGAN',
//       'LIMASON',
//       'LINOGUAYAN',
//       'LUMBAL',
//       'LUNIB',
//       'MACULAY',
//       'MARAYA',
//       'SAGUCAN',
//       'WALING-WALING',
//     ],
//   },
//   'ZAMBOANGA': {
//     province: 'ZAMBOANGA DEL SUR',
//     municipality: 'ZAMBOANGA',
//     region: 'REGION IX',
//     barangay_list: [
//       'ARENA BLANCO',
//       'AYALA',
//       'BALIWASAN',
//       'BALUNO',
//       'BARANGAY ZONE I (POB.)',
//       'BARANGAY ZONE II (POB.)',
//       'BARANGAY ZONE III (POB.)',
//       'BARANGAY ZONE IV (POB.)',
//       'BOALAN',
//       'BOLONG',
//       'BUENAVISTA',
//       'BUNGUIAO',
//       'BUSAY (SACOL ISLAND)',
//       'CABALUAY',
//       'CABATANGAN',
//       'CACAO',
//       'CALABASA',
//       'CALARIAN',
//       'CAMINO NUEVO',
//       'CAMPO ISLAM',
//       'CANELAR',
//       'CAPISAN',
//       'CAWIT',
//       'CULIANAN',
//       'CURUAN',
//       'DITA',
//       'DIVISORIA',
//       'DULIAN (UPPER BUNGUIAO)',
//       'DULIAN (UPPER PASONANCA)',
//       'GUISAO',
//       'GUIWAN',
//       'KASANYANGAN',
//       'LA PAZ',
//       'LABUAN',
//       'LAMISAHAN',
//       'LANDANG GUA',
//       'LANDANG LAUM',
//       'LANZONES',
//       'LAPAKAN',
//       'LATUAN (CURUAN)',
//       'LICOMO',
//       'LIMAONG',
//       'LIMPAPA',
//       'LUBIGAN',
//       'LUMAYANG',
//       'LUMBANGAN',
//       'LUNZURAN',
//       'MAASIN',
//       'MALAGUTAY',
//       'MAMPANG',
//       'MANALIPA',
//       'MANGUSU',
//       'MANICAHAN',
//       'MARIKI',
//       'MERCEDES',
//       'MUTI',
//       'PAMUCUTAN',
//       'PANGAPUYAN',
//       'PANUBIGAN',
//       'PASILMANTA (SACOL ISLAND)',
//       'PASOBOLONG',
//       'PASONANCA',
//       'PATALON',
//       'PUTIK',
//       'QUINIPUT',
//       'RECODO',
//       'RIO HONDO',
//       'SALAAN',
//       'SAN JOSE CAWA-CAWA',
//       'SAN JOSE GUSU',
//       'SAN ROQUE',
//       'SANGALI',
//       'SANTA BARBARA',
//       'SANTA CATALINA',
//       'SANTA MARIA',
//       'SANTO NIÑO',
//       'SIBULAO (CARUAN)',
//       'SINUBUNG',
//       'SINUNOC',
//       'TAGASILAY',
//       'TAGUITI',
//       'TALABAAN',
//       'TALISAYAN',
//       'TALON-TALON',
//       'TALUKSANGAY',
//       'TETUAN',
//       'TICTAPUL',
//       'TIGBALABAG',
//       'TIGTABON',
//       'TOLOSA',
//       'TUGBUNGAN',
//       'TULUNGATUNG',
//       'TUMAGA',
//       'TUMALUTAB',
//       'TUMITUS',
//       'VICTORIA',
//       'VITALI',
//       'ZAMBOWOOD',
//     ],
//   },
//   BUUG: {
//     province: 'ZAMBOANGA SIBUGAY',
//     municipality: 'BUUG',
//     region: 'REGION IX',
//     barangay_list: [
//       'AGUTAYAN',
//       'BAGONG BORBON',
//       'BASALEM',
//       'BAWANG',
//       'BLISS',
//       'BULAAN',
//       'COMPOSTELA',
//       'DANLUGAN',
//       'DATU PANAS',
//       'DEL MONTE',
//       'GUINTULOAN',
//       'GUITOM',
//       'GUMINTA',
//       'LABRADOR',
//       'LANTAWAN',
//       'MABUHAY',
//       'MAGANAY',
//       'MANLIN',
//       'MUYO',
//       'PAMINTAYAN',
//       'PLING',
//       'POBLACION',
//       'PULOG',
//       'SAN JOSE',
//       'TALAIRAN',
//       'TALAMIMI',
//       'VILLACASTOR (GALIT)',
//     ],
//   },
//   DIPLAHAN: {
//     province: 'ZAMBOANGA SIBUGAY',
//     municipality: 'DIPLAHAN',
//     region: 'REGION IX',
//     barangay_list: [
//       'BALANGAO',
//       'BUTONG',
//       'DITAY',
//       'GAULAN',
//       'GOLING',
//       'GUINOMAN',
//       'KAUSWAGAN',
//       'LINDANG',
//       'LOBING',
//       'LUOP',
//       'MANANGON',
//       'MEJO',
//       'NATAN',
//       'PARADISE',
//       'PILAR',
//       'POBLACION (DIPLAHAN)',
//       'SAMPOLI A',
//       'SAMPOLI B',
//       'SANTA CRUZ',
//       'SONGCUYA',
//       'TINONGTONGAN',
//       'TUNO',
//     ],
//   },
//   IMELDA: {
//     province: 'ZAMBOANGA SIBUGAY',
//     municipality: 'IMELDA',
//     region: 'REGION IX',
//     barangay_list: [
//       'BALUGO',
//       'BALUNGISAN',
//       'BALUYAN',
//       'CANA-AN',
//       'DUMPOC',
//       'GANDIANGAN',
//       'ISRAEL (BALIAN ISRAEL)',
//       'LA VICTORIA',
//       'LITTLE BAGUIO',
//       'LOWER BALURAN',
//       'LUMBOG',
//       'LUMPANAC',
//       'MALI LITTLE BAGUIO',
//       'POBLACION (SANTA FE)',
//       'PULAWAN (MT. VIEW)',
//       'SAN JOSE',
//       'SANTA BARBARA',
//       'UPPER BALURAN',
//     ],
//   },
//   IPIL: {
//     province: 'ZAMBOANGA SIBUGAY',
//     municipality: 'IPIL',
//     region: 'REGION IX',
//     barangay_list: [
//       'BACALAN',
//       'BANGKEROHAN',
//       'BULU-AN',
//       'CAPARAN',
//       'DOMANDAN',
//       'DON ANDRES',
//       'DOÑA JOSEFA',
//       'GUITUAN',
//       'IPIL HEIGHTS',
//       'LABI',
//       'LOGAN',
//       'LOWER IPIL HEIGHTS',
//       'LOWER TAWAY',
//       'LUMBIA',
//       'MAASIN',
//       'MAGDAUP',
//       'MAKILAS',
//       'PANGI',
//       'POBLACION',
//       'SANITO',
//       'SUCLEMA',
//       'TAWAY',
//       'TENAN',
//       'TIAYON',
//       'TIMALANG',
//       'TOMITOM',
//       'UPPER PANGI',
//       "VETERAN'S VILLAGE (RUIZ)",
//     ],
//   },
//   KABASALAN: {
//     province: 'ZAMBOANGA SIBUGAY',
//     municipality: 'KABASALAN',
//     region: 'REGION IX',
//     barangay_list: [
//       'BANKER',
//       'BOLO BATALLION',
//       'BUAYAN',
//       'CAINGLET',
//       'CALAPAN',
//       'CALUBIHAN',
//       'CONCEPCION (BALUNGIS)',
//       'DIAMPAK',
//       'DIPALA',
//       'GACBUSAN',
//       'GOODYEAR',
//       'LACNAPAN',
//       'LITTLE BAGUIO',
//       'LUMBAYAO',
//       'NAZARETH',
//       'PALINTA',
//       'PEÑARANDA',
//       'POBLACION',
//       'RIVERSIDE',
//       'SANGHANAN',
//       'SANTA CRUZ',
//       'SAYAO',
//       'SHIOLAN',
//       'SIMBOL',
//       'SININAN',
//       'TAMIN',
//       'TAMPILISAN',
//       'TIGBANGAGAN',
//       'TIMUAY DANDA (MANGAHAS)',
//     ],
//   },
//   MABUHAY: {
//     province: 'ZAMBOANGA SIBUGAY',
//     municipality: 'MABUHAY',
//     region: 'REGION IX',
//     barangay_list: [
//       'ABUNDA',
//       'BAGONG SILANG',
//       'BANGKAW-BANGKAW',
//       'CALIRAN',
//       'CATIPAN',
//       'KAUSWAGAN',
//       'LIGAYA',
//       'LOOC-BARLAK',
//       'MALINAO',
//       'PAMANSAAN',
//       'PINALIM (SAN ROQUE)',
//       'POBLACION',
//       'PUNAWAN',
//       'SANTO NIÑO (CALIRAN)',
//       'SAWA',
//       'SIOTON',
//       'TAGUISIAN',
//       'TANDU-COMOT (KATIPUNAN)',
//     ],
//   },
//   MALANGAS: {
//     province: 'ZAMBOANGA SIBUGAY',
//     municipality: 'MALANGAS',
//     region: 'REGION IX',
//     barangay_list: [
//       'BACAO',
//       'BASAK-BAWANG',
//       'BONTONG',
//       'CAMANGA',
//       'CANDIIS',
//       'CATITUAN',
//       'DANSULAO',
//       'DEL PILAR',
//       'GUILAWA',
//       'KIGAY',
//       'LA DICHA',
//       'LIPACAN',
//       'LOGPOND',
//       'MABINI',
//       'MALUNGON',
//       'MULOM',
//       'OVERLAND',
//       'PALALIAN',
//       'PAYAG',
//       'POBLACION',
//       'REBOCON',
//       'SAN VICENTE',
//       'SINUSAYAN',
//       'TACKLING',
//       'TIGABON',
//     ],
//   },
//   NAGA: {
//     province: 'ZAMBOANGA SIBUGAY',
//     municipality: 'NAGA',
//     region: 'REGION IX',
//     barangay_list: [
//       'AGUINALDO',
//       'BAGA',
//       'BALUNO',
//       'BANGKAW-BANGKAW',
//       'CABONG',
//       'CROSSING STA. CLARA',
//       'GUBAWANG',
//       'GUINTOLOAN',
//       'KALIANTANA',
//       'LA PAZ',
//       'LOWER SULITAN',
//       'MAMAGON',
//       'MARSOLO',
//       'POBLACION',
//       'SAN ISIDRO',
//       'SANDAYONG',
//       'SANTA CLARA',
//       'SULO',
//       'TAMBANAN',
//       'TAYTAY MANUBO',
//       'TILUBOG',
//       'TIPAN',
//       'UPPER SULITAN',
//     ],
//   },
//   OLUTANGA: {
//     province: 'ZAMBOANGA SIBUGAY',
//     municipality: 'OLUTANGA',
//     region: 'REGION IX',
//     barangay_list: [
//       'BATERIA',
//       'CALAIS (KALINES)',
//       'ESPERANZA',
//       'FAMA',
//       'GALAS',
//       'GANDAAN',
//       'KAHAYAGAN',
//       'LOOC SAPI',
//       'MATIM',
//       'NOQUE',
//       'PULO LAUM',
//       'PULO MABAO',
//       'SAN ISIDRO',
//       'SAN JOSE',
//       'SANTA MARIA',
//       'SOLAR (POB.)',
//       'TAMBANAN',
//       'VILLACORTE',
//       'VILLAGONZALO',
//     ],
//   },
//   PAYAO: {
//     province: 'ZAMBOANGA SIBUGAY',
//     municipality: 'PAYAO',
//     region: 'REGION IX',
//     barangay_list: [
//       'BALIAN',
//       'BALOGO',
//       'BALUNGISAN',
//       'BINANGONAN',
//       'BULACAN',
//       'BULAWAN',
//       'CALAPE',
//       'DALAMA',
//       'FATIMA (SILAL)',
//       'GUINTOLAN',
//       'GUIWAN',
//       'KATIPUNAN',
//       'KIMA',
//       'KULASIAN',
//       'KULISAP',
//       'LA FORTUNA',
//       'LABATAN',
//       'MAYABO (SANTA MARIA)',
//       'MINUNDAS (SANTO. NIÑO)',
//       'MOUNTAIN VIEW (PULUAN)',
//       'NANAN',
//       'POBLACION (PAYAO)',
//       'SAN ISIDRO',
//       'SAN ROQUE',
//       'SAN VICENTE (BINANGONAN)',
//       'SILAL',
//       'SUMILONG',
//       'TALAPTAP',
//       'UPPER SUMILONG',
//     ],
//   },
//   'ROSELLER LIM': {
//     province: 'ZAMBOANGA SIBUGAY',
//     municipality: 'ROSELLER LIM',
//     region: 'REGION IX',
//     barangay_list: [
//       'ALI ALSREE',
//       'BALANSAG',
//       'CALULA',
//       'CASACON',
//       'DON PERFECTO',
//       'GANGO',
//       'KATIPUNAN',
//       'KULAMBUGAN',
//       'MABINI',
//       'MAGSAYSAY',
//       'MALUBAL',
//       'NEW ANTIQUE',
//       'NEW SAGAY',
//       'PALMERA',
//       'PRES. ROXAS',
//       'REMEDIOS',
//       'SAN ANTONIO',
//       'SAN FERNANDINO',
//       'SAN JOSE',
//       'SANTO ROSARIO',
//       'SIAWANG',
//       'SILINGAN',
//       'SURABAY',
//       'TARUC',
//       'TILASAN',
//       'TUPILAC',
//     ],
//   },
//   SIAY: {
//     province: 'ZAMBOANGA SIBUGAY',
//     municipality: 'SIAY',
//     region: 'REGION IX',
//     barangay_list: [
//       'BAGONG SILANG',
//       'BALAGON',
//       'BALINGASAN',
//       'BALUCANAN',
//       'BATAAN',
//       'BATU',
//       'BUYOGAN',
//       'CAMANGA',
//       'COLORAN',
//       'KIMOS (KIMA)',
//       'LABASAN',
//       'LAGTING',
//       'LAIH',
//       'LOGPOND',
//       'MAGSAYSAY',
//       'MAHAYAHAY',
//       'MALIGAYA',
//       'MANIHA',
//       'MINSULAO',
//       'MIRANGAN',
//       'MONCHING',
//       'PARUK',
//       'POBLACION',
//       'PRINCESA SUMAMA',
//       'SALINDING',
//       'SAN ISIDRO',
//       'SIBUGUEY',
//       'SILOH',
//       'VILLAGRACIA',
//     ],
//   },
//   TALUSAN: {
//     province: 'ZAMBOANGA SIBUGAY',
//     municipality: 'TALUSAN',
//     region: 'REGION IX',
//     barangay_list: [
//       'AURORA',
//       'BAGANIPAY',
//       'BOLINGAN',
//       'BUALAN',
//       'CAWILAN',
//       'FLORIDA',
//       'KASIGPITAN',
//       'LAPARAY',
//       'MAHAYAHAY',
//       'MOALBOAL',
//       'POBLACION (TALUSAN)',
//       'SAGAY',
//       'SAMONTE',
//       'TUBURAN',
//     ],
//   },
//   TITAY: {
//     province: 'ZAMBOANGA SIBUGAY',
//     municipality: 'TITAY',
//     region: 'REGION IX',
//     barangay_list: [
//       'ACHASOL',
//       'AZUSANO',
//       'BANGCO',
//       'CAMANGA',
//       'CULASIAN',
//       'DALANGIN',
//       'DALANGIN MUSLIM',
//       'DALISAY',
//       'GOMOTOC',
//       'IMELDA (UPPER CAMANGA)',
//       'KIPIT',
//       'KITABOG',
//       'LA LIBERTAD',
//       'LONGILOG',
//       'MABINI',
//       'MALAGANDIS',
//       'MATE',
//       'MOALBOAL',
//       'NAMNAMA',
//       'NEW CANAAN',
//       'PALOMOC',
//       'POBLACION (TITAY)',
//       'POBLACION MUSLIM',
//       'PULIDAN',
//       'SAN ANTONIO',
//       'SAN ISIDRO',
//       'SANTA FE',
//       'SUPIT',
//       'TUGOP',
//       'TUGOP MUSLIM',
//     ],
//   },
//   TUNGAWAN: {
//     province: 'ZAMBOANGA SIBUGAY',
//     municipality: 'TUNGAWAN',
//     region: 'REGION IX',
//     barangay_list: [
//       'BALURAN',
//       'BATUNGAN',
//       'CAYAMCAM',
//       'DATU TUMANGGONG',
//       'GAYCON',
//       'LANGON',
//       'LIBERTAD (POB.)',
//       'LINGUISAN',
//       'LITTLE MARGOS',
//       'LOBOC',
//       'LOOC-LABUAN',
//       'LOWER TUNGAWAN',
//       'MALUNGON',
//       'MASAO',
//       'SAN ISIDRO',
//       'SAN PEDRO',
//       'SAN VICENTE',
//       'SANTO NIÑO',
//       'SISAY',
//       'TAGLIBAS',
//       'TIGBANUANG',
//       'TIGBUCAY',
//       'TIGPALAY',
//       'TIMBABAUAN',
//       'UPPER TUNGAWAN',
//     ],
//   },
//   AKBAR: {
//     province: 'BASILAN',
//     municipality: 'AKBAR',
//     region: 'BARMM',
//     barangay_list: [
//       'CADDAYAN',
//       'LINONGAN',
//       'LOWER BATO-BATO',
//       'MANGALUT',
//       'MANGUSO',
//       'PAGUENGAN',
//       'SEMUT',
//       'UPPER BATO-BATO',
//       'UPPER SINANGKAPAN',
//     ],
//   },
//   'AL-BARKA': {
//     province: 'BASILAN',
//     municipality: 'AL-BARKA',
//     region: 'BARMM',
//     barangay_list: [
//       'APIL-APIL',
//       'BATO-BATO',
//       'BOHE-PIANG',
//       'BUCALAO',
//       'CAMBUG',
//       'DANAPAH',
//       'GUINANTA',
//       'KAILIH',
//       'KINUKUTAN',
//       'KUHON',
//       'KUHON LENNUH',
//       'LINUAN',
//       'LOOKBISAYA (KAULUNGAN ISLAND)',
//       'MACALANG',
//       'MAGCAWA',
//       'SANGKAHAN (KAULUNGAN ISLAND)',
//     ],
//   },
//   'HADJI MOHAMMAD AJUL': {
//     province: 'BASILAN',
//     municipality: 'HADJI MOHAMMAD AJUL',
//     region: 'BARMM',
//     barangay_list: [
//       'BASAKAN',
//       'BUTON',
//       'CANDIIS',
//       'LANGIL',
//       'LANGONG',
//       'LANGUYAN',
//       'PINTASAN',
//       'SERONGGON',
//       'SIBAGO',
//       'SULUTAN MATANGAL',
//       'TUBURAN PROPER (POB.)',
//     ],
//   },
//   'HADJI MUHTAMAD': {
//     province: 'BASILAN',
//     municipality: 'HADJI MUHTAMAD',
//     region: 'BARMM',
//     barangay_list: [
//       'BALUK-BALUK',
//       'DASALAN',
//       'LUBUKAN',
//       'LUUKBONGSOD',
//       'MANANGGAL',
//       'PALAHANGAN',
//       'PANDUCAN',
//       'SANGBAY BIG',
//       'SANGBAY SMALL',
//       'TAUSAN',
//     ],
//   },
//   'ISABELA': {
//     province: 'BASILAN',
//     municipality: 'ISABELA',
//     region: 'BARMM',
//     barangay_list: [
//       'AGUADA',
//       'BALATANAY',
//       'BALUNO',
//       'BEGANG',
//       'BINUANGAN',
//       'BUSAY',
//       'CABUNBATA',
//       'CALVARIO',
//       'CARBON',
//       'DIKI',
//       'DONA RAMONA T. ALANO (ISABELA WESTSITE)',
//       'ISABELA EASTSIDE (POB.)',
//       'ISABELA PROPER (POB.)',
//       'KAPATAGAN GRANDE',
//       'KAPAYAWAN',
//       'KAUMPURNAH ZONE I',
//       'KAUMPURNAH ZONE II',
//       'KAUMPURNAH ZONE III',
//       'KUMALARANG',
//       'LA PIEDAD (POB.)',
//       'LAMPINIGAN',
//       'LANOTE',
//       'LUKBUTON',
//       'LUMBANG',
//       'MAKIRI',
//       'MALIGUE (LUNOT)',
//       'MARANG-MARANG',
//       'MARKETSITE (POB.)',
//       'MASULA',
//       'MENZI',
//       'PANIGAYAN',
//       'PANUNSULAN',
//       'PORT AREA (POB.)',
//       'RIVERSIDE',
//       'SAN RAFAEL',
//       'SANTA BARBARA',
//       'SANTA CRUZ (POB.)',
//       'SEASIDE (POB.)',
//       'SMALL KAPATAGAN',
//       'SUMAGDANG',
//       'SUNRISE VILLAGE (POB.)',
//       'TABIAWAN',
//       'TABUK (POB.)',
//       'TAMPALAN',
//       'TIMPUL',
//     ],
//   },
//   'LAMITAN': {
//     province: 'BASILAN',
//     municipality: 'LAMITAN',
//     region: 'BARMM',
//     barangay_list: [
//       'ARCO',
//       'BA-AS',
//       'BAIMBING',
//       'BALAGTASAN',
//       'BALAS',
//       'BALOBO',
//       'BATO',
//       'BAUNGOS',
//       'BOHEBESSEY',
//       'BOHEIBU',
//       'BOHENANGE',
//       'BOHESAPA',
//       'BOHEYAKAN',
//       'BOHEYAWAS',
//       'BUAHAN',
//       'BULANTING',
//       'BULINGAN',
//       'CABOBO',
//       'CALUGUSAN',
//       'CAMPO UNO',
//       'COLONIA',
//       'DANIT-PUNTOCAN',
//       'KULAY BATO',
//       'LEBBUH',
//       'LIMO-OK',
//       'LO-OK',
//       'LUKSUMBANG',
//       'LUMUTON',
//       'MAGANDA',
//       'MALAKAS',
//       'MALIGAYA',
//       'MALINIS (POB.)',
//       'MALO-ONG CANAL',
//       'MALO-ONG SAN JOSE',
//       'MATATAG',
//       'MATIBAY',
//       'PARANGBASAK',
//       'SABONG',
//       'SANTA CLARA',
//       'SENGAL',
//       'SIMBANGON',
//       'TANDONG AHAS',
//       'TUMAKID',
//       'UBIT',
//       'ULAME',
//     ],
//   },
//   LANTAWAN: {
//     province: 'BASILAN',
//     municipality: 'LANTAWAN',
//     region: 'BARMM',
//     barangay_list: [
//       'ATONG-ATONG',
//       'BAGBAGON',
//       'BAUNGIS',
//       'BULAN-BULAN',
//       'BULANZA',
//       'CALAYAN',
//       'CALUGUSAN',
//       'CANIBUNGAN',
//       'LANDUGAN',
//       'LANTAWAN PROPER (POB.)',
//       'LAWI-LAWI',
//       'LAWILA',
//       'LOWER BAÑAS',
//       'LOWER MANGGAS',
//       'LUUK-MALUHA',
//       'MATARLING',
//       'MATIKANG',
//       'PAMUCALIN',
//       'PANIONGAN',
//       'PARIAN-BAUNOH',
//       'SUBA-AN (PANGASAHAN)',
//       'SWITCH YAKAL',
//       'TAIRAN',
//       'UPPER BAÑAS',
//       'UPPER MANGGAS',
//     ],
//   },
//   MALUSO: {
//     province: 'BASILAN',
//     municipality: 'MALUSO',
//     region: 'BARMM',
//     barangay_list: [
//       'ABONG-ABONG',
//       'BATUNGAL',
//       'CALANG CANAS',
//       'FUENTE MALUSO',
//       'GUANAN NORTH (ZONE I)',
//       'GUANAN SOUTH (ZONE II)',
//       'LIMBUBONG',
//       'MAHAYAHAY LOWER (ZONE I)',
//       'MAHAYAHAY UPPER (ZONE II)',
//       'MUSLIM AREA',
//       'PORT HOLLAND ZONE I POB. (UPPER)',
//       'PORT HOLLAND ZONE II POB. (SHIPYARD MAIN)',
//       'PORT HOLLAND ZONE III POB. (SAMAL VILLAGE)',
//       'PORT HOLLAND ZONE IV (LOWER)',
//       'PORT HOLLAND ZONE V (SHIPYARD TABUK)',
//       'TABERLONGAN',
//       'TAMUK',
//       'TOWNSITE (POB.)',
//       'TUBIGAN',
//       'UPPER GARLAYAN',
//     ],
//   },
//   SUMISIP: {
//     province: 'BASILAN',
//     municipality: 'SUMISIP',
//     region: 'BARMM',
//     barangay_list: [
//       'BACUNG',
//       'BAIWAS',
//       'BASAK',
//       'BENEMBENGAN LOWER',
//       'BENEMBENGAN UPPER',
//       'BOHE-LANGUYAN',
//       'BULI-BULI',
//       'CABCABAN',
//       'CABENGBENG LOWER',
//       'CABENGBENG UPPER',
//       'ETTUB-ETTUB',
//       'GUIONG',
//       'KAUM-AIR',
//       'KAUMPAMATSAKEM',
//       'LIBUG',
//       'LIMBOCANDIS',
//       'LUKKETON',
//       'LUUK-BAIT',
//       'MAHATALANG',
//       'MANAUL',
//       'MANGAL (POB.)',
//       'MARANG',
//       'MEBAK',
//       'SAHAYA BOHE BATO',
//       'SAPAH BULAK',
//       'SUMISIP CENTRAL',
//       'TIKUS',
//       'TONGSENGAL',
//       'TUMAHUBONG',
//     ],
//   },
//   'TABUAN-LASA': {
//     province: 'BASILAN',
//     municipality: 'TABUAN-LASA',
//     region: 'BARMM',
//     barangay_list: [
//       'BABAG (BABUAN ISLAND)',
//       'BALANTING',
//       'BOLOH-BOLOH',
//       'BUKUT-UMUS',
//       'KAUMPURNAH',
//       'LANAWAN',
//       'PISAK-PISAK',
//       'SALUPING',
//       'SULIGAN (BABUAN ISLAND)',
//       'SULLOH (TAPIANTANA)',
//       'TAMBULIG BUTON',
//       'TONG-UMUS',
//     ],
//   },
//   'TIPO-TIPO': {
//     province: 'BASILAN',
//     municipality: 'TIPO-TIPO',
//     region: 'BARMM',
//     barangay_list: [
//       'BADJA',
//       'BAGUINDAN',
//       'BANAH',
//       'BANGCUANG',
//       'BOHE-TAMBAK',
//       'BOHEBACA',
//       'BOHELEBUNG',
//       'LAGAYAS',
//       'LIMBO-UPAS',
//       'SILANGKUM',
//       'TIPO-TIPO PROPER (POB.)',
//     ],
//   },
//   'UNGKAYA PUKAN': {
//     province: 'BASILAN',
//     municipality: 'UNGKAYA PUKAN',
//     region: 'BARMM',
//     barangay_list: [
//       'AMALOY',
//       'BOHE-PAHUH',
//       'BOHE-SUYAK',
//       'CABANGALAN',
//       'DANIT',
//       'KAMAMBURINGAN',
//       'MATATA',
//       'MATERLING',
//       'PIPIL',
//       'SUNGKAYUT',
//       'TONGBATO',
//       'ULITAN',
//     ],
//   },
//   'AMAI MANABILANG (BUMBARAN)': {
//     province: 'LANAO DEL SUR',
//     municipality: 'AMAI MANABILANG (BUMBARAN)',
//     region: 'BARMM',
//     barangay_list: [
//       'BAGUMBAYAN',
//       'BANDARA-INGUD',
//       'COMARA',
//       'FRANKFORT',
//       'LAMBANOGAN',
//       'LICO',
//       'MANSILANO',
//       'NATANGCOPAN',
//       'PAGALAMATAN',
//       'PAGONAYAN',
//       'PIAGMA',
//       'POBLACION (APARTFORT)',
//       'PUNUD',
//       'RANAO-BANING',
//       'SAGUA-AN',
//       'SALAM',
//       'SUMUGOT',
//     ],
//   },
//   'BACOLOD-KALAWI (BACOLOD GRANDE)': {
//     province: 'LANAO DEL SUR',
//     municipality: 'BACOLOD-KALAWI (BACOLOD GRANDE)',
//     region: 'BARMM',
//     barangay_list: [
//       'AMPAO',
//       'BAGOAINGUD',
//       'BALUT',
//       'BARUA',
//       'BUADIAWANI',
//       'BUBONG',
//       'DARAMOYOD',
//       'DILABAYAN',
//       'DIPATUAN',
//       'GANDAMATO',
//       'GURAIN',
//       'ILIAN',
//       'LAMA',
//       'LIAWAO',
//       'LUMBACA-INGUD',
//       'MADANDING',
//       'ORONG',
//       'PINDOLONAN',
//       'POBLACION I',
//       'POBLACION II',
//       'RAYA',
//       'ROROWAN',
//       'SUGOD',
//       'TAMBO',
//       'TUKA I',
//       'TUKA II',
//     ],
//   },
//   BALABAGAN: {
//     province: 'LANAO DEL SUR',
//     municipality: 'BALABAGAN',
//     region: 'BARMM',
//     barangay_list: [
//       'BAGOAINGUD',
//       'BANAGO',
//       'BARORAO',
//       'BATUAN',
//       'BUDAS',
//       'BUENAVISTA',
//       'BUISAN (BENGABENG)',
//       'CALILANGAN',
//       'IGABAY',
//       'ILIAN',
//       'LALABUAN',
//       'LORENZO',
//       'LOWER ITIL',
//       'LUMBAC',
//       'MACAO',
//       'MAGULALUNG OCCIDENTAL',
//       'MAGULALUNG ORIENTAL',
//       'MATAMPAY',
//       'MATANOG',
//       'MOLIMOC',
//       'NARRA',
//       'PINDOLONAN',
//       'PLASAN',
//       'POBLACION (BALABAGAN)',
//       'PURAKAN',
//       'TATAYA',
//       'UPPER ITIL',
//     ],
//   },
//   'BALINDONG (WATU)': {
//     province: 'LANAO DEL SUR',
//     municipality: 'BALINDONG (WATU)',
//     region: 'BARMM',
//     barangay_list: [
//       'ABAGA',
//       'BANTOGA WATO',
//       'BARIT',
//       'BOLINSONG',
//       'BORAKIS',
//       'BUALAN',
//       'BUBONG',
//       'BUBONG CADAPAAN',
//       'CADAPAAN',
//       'CADAYONAN',
//       'DADAYAG',
//       'DADO',
//       'DIBARUSAN',
//       'DILAUSAN',
//       'DIMARAO',
//       'INGUD',
//       'KALUNTAY',
//       'LALABUAN',
//       'LATI',
//       'LILOD',
//       'LIMBO',
//       'LUMBAC LALAN',
//       'LUMBAC WATO',
//       'LUMBAYAO',
//       'MAGARANG',
//       'MALAIG',
//       'NUSA LUMBA RANAO',
//       'PADILA',
//       'PAGAYAWAN',
//       'PAIGOAY',
//       'PANTARAGOO',
//       'POBLACION (BALINDONG)',
//       'RAYA',
//       'SALIPONGAN',
//       'TALUB',
//       'TANTUA RAYA',
//       'TOMAROMPONG',
//       'TUKA BUBONG',
//     ],
//   },
//   BAYANG: {
//     province: 'LANAO DEL SUR',
//     municipality: 'BAYANG',
//     region: 'BARMM',
//     barangay_list: [
//       'BAGOAINGUD',
//       'BAIRAN (POB.)',
//       'BANDINGUN',
//       'BIABI',
//       'BIALAAN',
//       'BUBONG LILOD',
//       'BUBONG RAYA',
//       'CADAYONAN',
//       'CADINGILAN OCCIDENTAL',
//       'CADINGILAN ORIENTAL',
//       'CONDARAAN POB. (CONDARAAN DIMADAP)',
//       'CORMATAN',
//       'GANDAMATO',
//       'ILIAN',
//       'LALAPUNG CENTRAL',
//       'LALAPUNG PROPER (POB.)',
//       'LALAPUNG UPPER',
//       'LINAO',
//       'LINUK (POB.)',
//       'LIONG',
//       'LUMBAC',
//       'LUMBAC CADAYONAN',
//       'MALIWANAG',
//       'MAPANTAO',
//       'MIMBALAWAG',
//       'PALAO',
//       'PAMA-AN',
//       'PAMACOTAN',
//       'PANTAR',
//       'PARAO',
//       'PATONG',
//       'POBLACION (BAYANG)',
//       'POROTAN',
//       'RANTIAN',
//       'RAYA CADAYONAN',
//       'RINABOR (POB.)',
//       'SAMPORNA (POB.)',
//       'SAPA',
//       'SILID',
//       'SUGOD',
//       'SULTAN PANDAPATAN',
//       'SUMBAG (POB.)',
//       'TAGORANAO',
//       'TANGCAL',
//       'TANGCAL PROPER (POB.)',
//       'TOMAROMPONG',
//       'TOMONGCAL LIGI',
//       'TOROGAN',
//       'TUCA (POB.)',
//     ],
//   },
//   BINIDAYAN: {
//     province: 'LANAO DEL SUR',
//     municipality: 'BINIDAYAN',
//     region: 'BARMM',
//     barangay_list: [
//       'BADAK',
//       'BAGUIANGUN',
//       'BALUT MAITO',
//       'BASAK',
//       'BUBONG',
//       'BUBONGA-RANAO',
//       'DANSALAN DACSULA',
//       'INGUD',
//       'KIALILIDAN',
//       'LUMBAC',
//       'MACAGUILING',
//       'MADAYA',
//       'MAGONAYA',
//       'MAINDIG',
//       'MASOLUN',
//       'OLAMA',
//       'PAGALAMATAN (POB.)',
//       'PANTAR',
//       'PICALILANGAN',
//       'PICOTAAN',
//       'PINDOLONAN',
//       'POBLACION',
//       'SOLDARORO',
//       'TAMBAC',
//       'TIMBANGAN',
//       'TUCA',
//     ],
//   },
//   'BUADIPOSO-BUNTONG': {
//     province: 'LANAO DEL SUR',
//     municipality: 'BUADIPOSO-BUNTONG',
//     region: 'BARMM',
//     barangay_list: [
//       'BACOLOD',
//       'BANGON',
//       'BANGON PROPER',
//       'BOTO RAGONDINGAN',
//       'BUADIPOSO LILOD',
//       'BUADIPOSO PROPER',
//       'BUADIPOSO RAYA',
//       'BUBONG',
//       'BUNTONG PROPER',
//       'CADAYONAN',
//       'DANSALAN',
//       'DATU TAMBARA',
//       'DIRISAN',
//       'GATA',
//       'KALAKALA',
//       'KATOGONAN',
//       'LUMBAC',
//       'LUMBATAN MANACAB',
//       'LUMBATAN PATAINGUD',
//       'LUNDUBAN (RAGONDINGAN)',
//       'MANACAB (POB.)',
//       'MINANGA (BUNTONG)',
//       'PALING',
//       'PINDOLONAN',
//       'PUALAS',
//       'RAGONDINGAN EAST',
//       'RAGONDINGAN PROPER',
//       'RAGONDINGAN WEST',
//       'RAYA BUNTONG (BUNTONG EAST)',
//       'SAPOT',
//       'TANGCAL',
//       'TARIK',
//       'TUKA',
//     ],
//   },
//   BUBONG: {
//     province: 'LANAO DEL SUR',
//     municipality: 'BUBONG',
//     region: 'BARMM',
//     barangay_list: [
//       'BACOLOD',
//       'BAGOAINGUD',
//       'BANSAYAN',
//       'BASINGAN',
//       'BATANGAN',
//       'BUALAN',
//       'BUBONGA DIDAGUN',
//       'CARIGONGAN',
//       'DALAON',
//       'DIBAROSAN',
//       'DILABAYAN',
//       'DIMAPATOY',
//       'DIMAYON',
//       'DIMAYON PROPER',
//       'DIOLANGAN',
//       'GUIGUIKUN',
//       'MADANDING',
//       'MALUNGUN',
//       'MASOROT',
//       'MATAMPAY DIMARAO',
//       'MIABALAWAG',
//       'MONTIAAN',
//       'PAGAYAWAN',
//       'PALAO',
//       'PANALAWAN',
//       'PANTAR',
//       'PENDOGOAN',
//       'PINDOLONAN',
//       'POBLACION (BUBONG)',
//       'POLAYAGAN',
//       'PUNUD',
//       'RAMAIN BUBONG',
//       'ROGERO',
//       'SALIPONGAN',
//       'SUNGGOD',
//       'TABORO',
//     ],
//   },
//   BUTIG: {
//     province: 'LANAO DEL SUR',
//     municipality: 'BUTIG',
//     region: 'BARMM',
//     barangay_list: [
//       'BAYABAO POBLACION',
//       'BUTIG PROPER',
//       'CABASARAN',
//       'COLOYAN TAMBO',
//       'DILIMBAYAN',
//       'DOLANGAN',
//       'MALUNGUN',
//       'PINDOLONAN',
//       'POKTAN',
//       'RAGAYAN',
//       'RAYA',
//       'SAMER',
//       'SANDAB MADAYA',
//       'SUNDIG',
//       'TIMBAB',
//       'TIOWI',
//     ],
//   },
//   CALANOGAS: {
//     province: 'LANAO DEL SUR',
//     municipality: 'CALANOGAS',
//     region: 'BARMM',
//     barangay_list: [
//       'BUBONGA RANAO',
//       'CALALAOAN (POB.)',
//       'GAS',
//       'INOMA',
//       'INUDARAN',
//       'LUGUNA',
//       'MIMBALAWAG',
//       'NGINGIR',
//       'PAGALONGAN',
//       'PANGGAWALUPA',
//       'PANTAON',
//       'PIKSAN',
//       'PINDOLONAN',
//       'PUNUD',
//       'TAGORANAO',
//       'TALIBOBOKA',
//       'TAMBAC',
//     ],
//   },
//   'DITSAAN-RAMAIN': {
//     province: 'LANAO DEL SUR',
//     municipality: 'DITSAAN-RAMAIN',
//     region: 'BARMM',
//     barangay_list: [
//       'BACLAYAN LILOD',
//       'BACLAYAN RAYA',
//       'BAGOAINGUD',
//       'BARIMBINGAN',
//       'BAYABAO',
//       'BUADI ALAO',
//       'BUADI BABAI',
//       'BUADI OLOC',
//       'BUAYAAN LILOD',
//       'BUAYAAN MADANDING',
//       'BUAYAAN RAYA',
//       'BUBONG DANGIPRAMPIAI',
//       'DADO',
//       'DANGIMPRAMPIAI',
//       'DARIMBANG',
//       'DILAUSAN',
//       'DITSAAN',
//       'GADONGAN',
//       'LINAMON',
//       'LUMBATAN RAMAIN',
//       'MAINDIG DITSAAN',
//       'MANDARA',
//       'MARANAO TIMBER (DALAMA)',
//       'PAGALONGAN BUADIADINGAN',
//       'PAGALONGAN GINAOPAN',
//       'PAGALONGAN MASIOON',
//       'PAGALONGAN PROPER',
//       'POLO',
//       'RAMAIN POBLACION',
//       'RAMAIN PROPER',
//       'RANTIAN',
//       'SULTAN PANGADAPUN',
//       'SUNDIGA BAYABAO',
//       'TALUB',
//       'UPPER PUGAAN',
//     ],
//   },
//   GANASSI: {
//     province: 'LANAO DEL SUR',
//     municipality: 'GANASSI',
//     region: 'BARMM',
//     barangay_list: [
//       'BAGOAINGUD',
//       'BALINTAD',
//       'BALINTAD A',
//       'BARIT',
//       'BARORAO',
//       'BATO BATORAY',
//       'BAYA',
//       'CAMPONG A RAYA',
//       'CAMPONG SABELA',
//       'DAPAAN',
//       'GADONGAN',
//       'GUI',
//       'LINUK',
//       'LUMBAC',
//       'LUMBACAINGUD',
//       'MACABAO',
//       'MACAGUILING',
//       'MASOLUN',
//       'PAGALONGAN',
//       'PAMALIAN',
//       'PANGADAPUN',
//       'PANGGAWALUPA',
//       'PANTAON',
//       'PANTAON A',
//       'PARA-ABA',
//       'PINDOLONAN',
//       'POBLACION',
//       'SEKUN MATAMPAY',
//       'SOGOD MADAYA',
//       'TABUAN',
//       'TAGANONOK',
//       'TALIOGON',
//     ],
//   },
//   KAPAI: {
//     province: 'LANAO DEL SUR',
//     municipality: 'KAPAI',
//     region: 'BARMM',
//     barangay_list: [
//       'BABAYOG',
//       'CORMATAN',
//       'DILABAYAN',
//       'DILIMBAYAN',
//       'DIMAGALING (DIMAGALIN PROPER)',
//       'DIMUNDA',
//       'DORONAN',
//       'GADONGAN',
//       'KASAYANAN',
//       'KASAYANAN WEST',
//       'KIBOLOS',
//       'KINING',
//       'LIDASAN',
//       'MACADAR',
//       'MALNA PROPER',
//       'PAGALONGAN',
//       'PANTAON',
//       'PARAO',
//       'PINDOLONAN',
//       'POBLACION (KAPAI PROPER)',
//     ],
//   },
//   'LUMBA-BAYABAO (MAGUING)': {
//     province: 'LANAO DEL SUR',
//     municipality: 'LUMBA-BAYABAO (MAGUING)',
//     region: 'BARMM',
//     barangay_list: [
//       'BACOLOD I',
//       'BACOLOD II',
//       'BANTAYAO',
//       'BARIT',
//       'BAUGAN',
//       'BUAD LUMBAC',
//       'CABASARAN',
//       'CALILANGAN',
//       'CARANDANGAN-MIPAGA',
//       'CORMATAN LANGBAN',
//       'DIALONGANA',
//       'DILINDONGAN-CADAYONAN',
//       'GADONGAN',
//       'GALAWAN',
//       'GAMBAI',
//       'KASOLA',
//       'LALANGITUN',
//       'LAMA',
//       'LINDONGAN DIALONGANA',
//       'LOBO BASARA',
//       'LUMBAC BACAYAWAN',
//       'MACAGUILING',
//       'MAPANTAO',
//       'MAPOLING',
//       'MARIBO (POB.)',
//       'MINARING DILADIGAN',
//       'PAGAYAWAN',
//       'POSUDARAGAT',
//       'RUMAYAS',
//       'SABALA BANTAYAO',
//       'SALAMAN',
//       'SALOLODUN BERWAR',
//       'SARIGIDAN MADIAR',
//       'SUNGGOD',
//       'TALUAN',
//       'TAMLANG',
//       'TONGCOPAN',
//       'TUROGAN',
//     ],
//   },
//   'LUMBACA-UNAYAN': {
//     province: 'LANAO DEL SUR',
//     municipality: 'LUMBACA-UNAYAN',
//     region: 'BARMM',
//     barangay_list: [
//       'BANGON (DILAUSAN)',
//       'BETA',
//       'CALALON',
//       'CALIPAPA',
//       'DILAUSAN',
//       'DIMAPAOK',
//       'LUMBAK-DILAUSAN',
//       'ORIENTAL BETA',
//       'TRINGUN',
//     ],
//   },
//   LUMBATAN: {
//     province: 'LANAO DEL SUR',
//     municipality: 'LUMBATAN',
//     region: 'BARMM',
//     barangay_list: [
//       'ALOG',
//       'BASAYUNGUN',
//       'BUAD',
//       'BUBONG MACADAR',
//       'BUDI',
//       'DAGO-OK',
//       'DALAMA',
//       'DALIPUGA',
//       'LALAPUNG',
//       'LIGUE',
//       'LUMBAC',
//       'LUMBAC BACAYAWAN',
//       'LUNAY',
//       'MACADAR',
//       'MADAYA',
//       'MINANGA',
//       'PANTAR',
//       'PENARING',
//       'PICOTAAN',
//       'POBLACION (LUMBATAN)',
//       'TAMBAC',
//     ],
//   },
//   LUMBAYANAGUE: {
//     province: 'LANAO DEL SUR',
//     municipality: 'LUMBAYANAGUE',
//     region: 'BARMM',
//     barangay_list: [
//       'BAGOAINGUD',
//       'BALAIGAY',
//       'BUALAN',
//       'CABUNTUNGAN',
//       'CADAYONAN',
//       'CADINGILAN',
//       'CADINGILAN A',
//       'CASALAYAN',
//       'DALA (DALAMA)',
//       'DILIMBAYAN',
//       'DIROMOYOD',
//       'KABASARAN (POB.)',
//       'LAMIN',
//       'MAPANTAO-BALANGAS',
//       'MINIROS',
//       'NANAGUN',
//       'PANTAON',
//       'PINDOLONAN',
//       'PITATANGLAN',
//       'POCTAN',
//       'SINGCARA',
//       'WAGO',
//     ],
//   },
//   MADALUM: {
//     province: 'LANAO DEL SUR',
//     municipality: 'MADALUM',
//     region: 'BARMM',
//     barangay_list: [
//       'ABAGA',
//       'BACAYAWAN',
//       'BAGOAINGUD',
//       'BASAK',
//       'BATO',
//       'BUBONG',
//       'CABASARAN',
//       'CADAYONAN',
//       'DANDAMUN',
//       'DELAUSAN',
//       'DIAMPACA',
//       'DIBAROSAN',
//       'GADONGAN',
//       'GURAIN',
//       'KORMATAN',
//       'LIANGAN',
//       'LIANGAN I',
//       'LILITUN',
//       'LINAO',
//       'LINUK',
//       'LUMBAC',
//       'MADAYA',
//       'PADIAN TOROGAN I',
//       'PAGAYAWAN',
//       'PARIDI-KALIMODAN',
//       'POBLACION',
//       'PUNUD',
//       'RACOTAN',
//       'RAYA',
//       'RIRAY',
//       'SABANDING',
//       'SALONGABANDING',
//       'SOGOD KALOY',
//       'SUGOD',
//       'TAMPORONG',
//       'TONGANTONGAN',
//       'UDANGUN',
//     ],
//   },
//   MADAMBA: {
//     province: 'LANAO DEL SUR',
//     municipality: 'MADAMBA',
//     region: 'BARMM',
//     barangay_list: [
//       'BALAGUNUN',
//       'BALINTAD',
//       'BAWANG',
//       'BIABE',
//       'BUBONG UYAAN',
//       'CABASARAN',
//       'DIBARUSAN',
//       'ILIAN',
//       'LAKITAN',
//       'LIANGAN',
//       'LINUK',
//       'LUMBACA INGUD',
//       'MADAMBA',
//       'PAGAYONAN',
//       'PALAO',
//       'PANGADAPAN',
//       'PANTAON',
//       'PANTAR',
//       'PUNUD',
//       'TAMBO',
//       'TUBARAN',
//       'TUCA',
//       'TULAY',
//       'UYAAN PROPER (POB.)',
//     ],
//   },
//   MAGUING: {
//     province: 'LANAO DEL SUR',
//     municipality: 'MAGUING',
//     region: 'BARMM',
//     barangay_list: [
//       'AGAGAN',
//       'BALAGUNUN',
//       'BALAWAG',
//       'BALINTAO',
//       'BATO-BATO',
//       'BOLAO',
//       'BOROCOT',
//       'BORROWA',
//       'BOTUD',
//       'BUADIANGKAY',
//       'BUBONG',
//       'BUBONG BAYABAO',
//       'CAMALIG',
//       'CAMBONG',
//       'DILAUSAN (POB.)',
//       'DILIMBAYAN',
//       'ILALAG',
//       'KIANODAN',
//       'LILOD BOROCOT',
//       'LILOD MAGUING',
//       'LUMBAC',
//       'LUMBAC-DIMARAO',
//       'MADANDING',
//       'MADAYA',
//       'MAGUING PROPER',
//       'MALUNGUN',
//       'MALUNGUN BOROCOT',
//       'MALUNGUN PAGALONGAN',
//       'PAGALONGAN',
//       'PANAYANGAN',
//       'PILIMOKNAN',
//       'PINDOLONAN',
//       'RAGAYAN (RUNGAYAN)',
//       'SABALA DILAUSAN',
//     ],
//   },
//   MALABANG: {
//     province: 'LANAO DEL SUR',
//     municipality: 'MALABANG',
//     region: 'BARMM',
//     barangay_list: [
//       'BACAYAWAN',
//       'BADAK LUMAO',
//       'BAGOAINGUD',
//       'BANDAY',
//       'BETAYAN',
//       'BONIGA',
//       'BPS VILLAGE',
//       'BUNK HOUSE',
//       'CABASARAN (SOUTH)',
//       'CALIBAGAT',
//       'CALUMBOG',
//       'CAMPO MUSLIM',
//       'CHINA TOWN (POB.)',
//       'CORAHAB',
//       'DIAMARO',
//       'INANDAYAN',
//       'JOSE ABAD SANTOS',
//       'LAMIN',
//       'MABLE',
//       'MACURANDING',
//       'MADAYA',
//       'MANANAYO',
//       'MANGGAHAN',
//       'MASAO',
//       'MATALIN',
//       'MATAMPAY',
//       'MATLING',
//       'MONTAY',
//       'PASIR',
//       'PIALOT',
//       'REBOCUN',
//       'SARANG',
//       'SUMBAGAROGONG',
//       'TACUB',
//       'TAMBARA',
//       'TIONGCOP',
//       'TUBOC',
//     ],
//   },
//   MARANTAO: {
//     province: 'LANAO DEL SUR',
//     municipality: 'MARANTAO',
//     region: 'BARMM',
//     barangay_list: [
//       'BACAYAWAN',
//       'BACONG',
//       'BANGA-PANTAR',
//       'BATAL-PUNUD',
//       'BUBONG MADANDING (BUBONG)',
//       'CAMALIG (POB.)',
//       'CAMALIG BANDARA INGUD',
//       'CAMALIG BUBONG',
//       'CAWAYAN',
//       'CAWAYAN BACOLOD',
//       'CAWAYAN KALAW',
//       'CAWAYAN LINUK',
//       'DAANAINGUD',
//       'ILIAN',
//       'INUDARAN CAMPONG',
//       'INUDARAN LOWAY',
//       'INUDARAN LUMBAC',
//       'KIALDAN',
//       'LUBO',
//       'LUMBAC KIALDAN',
//       'MANTAPOLI',
//       'MATAMPAY',
//       'MAUL',
//       'MAUL ILIAN',
//       'MAUL LUMBACA INGUD',
//       'NATARON',
//       'PAGALONGAN BACAYAWAN',
//       'PALAO',
//       'PATAIMAS',
//       'POBLACION',
//       'POONA MARANTAO',
//       'PUNUD PROPER',
//       'TACUB',
//       'TUCA KIALDAN',
//     ],
//   },
//   'MARAWI': {
//     province: 'LANAO DEL SUR',
//     municipality: 'MARAWI',
//     region: 'BARMM',
//     barangay_list: [
//       'AMBOLONG',
//       'AMITO MARANTAO',
//       'BACOLOD CHICO PROPER',
//       'BANGA',
//       'BANGCO',
//       'BANGGOLO POBLACION',
//       'BANGON',
//       'BASAK MALUTLUT',
//       'BEYABA-DAMAG',
//       'BITO BUADI ITOWA',
//       'BITO BUADI PARBA',
//       'BOGANGA',
//       'BOTO AMBOLONG',
//       'BUADI SACAYO (GREEN)',
//       'BUBONG LUMBAC',
//       'BUBONGA CADAYONAN',
//       'BUBONGA LILOD MADAYA',
//       'BUBONGA MARAWI',
//       'BUBONGA PAGALAMATAN',
//       'BUBONGA PUNOD',
//       'CABASARAN',
//       'CABINGAN',
//       'CADAYONAN',
//       'CADAYONAN I',
//       'CALOCAN EAST',
//       'CALOCAN WEST',
//       'DAGUDUBAN',
//       'DANSALAN',
//       'DATU NAGA',
//       'DATU SA DANSALAN',
//       'DAYAWAN',
//       'DIMALUNA',
//       'DULAY',
//       'DULAY WEST',
//       'EAST BASAK',
//       'EMIE PUNUD',
//       'FORT',
//       'GADONGAN',
//       'GADONGAN MAPANTAO',
//       'GUIMBA (LILOD PROPER)',
//       'KAPANTARAN',
//       'KILALA',
//       'KORMATAN MATAMPAY',
//       'LILOD MADAYA (POB.)',
//       'LILOD SADUC',
//       'LOMIDONG',
//       'LUMBAC MARINAUT',
//       'LUMBACA MADAYA (POB.)',
//       'LUMBACA TOROS',
//       'MALIMONO',
//       'MARAWI POBLACION',
//       'MARINAUT (MARINAUT WEST)',
//       'MARINAUT EAST',
//       'MATAMPAY',
//       'MIPAGA PROPER',
//       'MONCADO COLONY',
//       'MONCADO KADINGILAN',
//       'MORIATAO LOKSADATO',
//       'NAVARRO (DATU SABER)',
//       'NORHAYA VILLAGE',
//       'OLAWA AMBOLONG',
//       'PAGALAMATAN GAMBAI',
//       'PAGAYAWAN',
//       'PANGGAO SADUC',
//       'PANTAON (LANGCAF)',
//       'PAPANDAYAN',
//       'PAPANDAYAN CANIOGAN',
//       'PARIDI',
//       'PATANI',
//       'PINDOLONAN',
//       'POONA MARANTAO',
//       'PUGAAN',
//       'RAPASUN MSU',
//       'RAYA MADAYA I',
//       'RAYA MADAYA II',
//       'RAYA SADUC',
//       'ROROGAGUS EAST',
//       'ROROGAGUS PROPER',
//       'SABALA MANAO',
//       'SABALA MANAO PROPER',
//       'SADUC PROPER',
//       'SAGONSONGAN',
//       'SANGCAY DANSALAN',
//       'SOMIORANG',
//       'SOUTH MADAYA PROPER',
//       'SUGOD PROPER',
//       'TAMPILONG',
//       'TIMBANGALAN',
//       'TOLALI',
//       'TONGANTONGAN-TUCA TIMBANGALAN',
//       'TOROS',
//       'TUCA',
//       'TUCA AMBOLONG',
//       'TUCA MARINAUT',
//       'WAWALAYAN CALOCAN',
//       'WAWALAYAN MARINAUT',
//     ],
//   },
//   MAROGONG: {
//     province: 'LANAO DEL SUR',
//     municipality: 'MAROGONG',
//     region: 'BARMM',
//     barangay_list: [
//       'BAGUMBAYAN',
//       'BALUT',
//       'BITAYAN',
//       'BOLAWAN',
//       'BONGA',
//       'CABASARAN',
//       'CADAYONAN',
//       'CAHERA',
//       'CAIRANTANG',
//       'CALUMBOG',
//       'CANIBONGAN',
//       'DIRAGUN',
//       'MANTAILOCO',
//       'MAPANTAO',
//       'MAROGONG EAST',
//       'MAROGONG PROPER (POB.)',
//       'MAYAMAN',
//       'PABRICA',
//       'PAIGOAY CODA',
//       'PASAYANAN',
//       'PIANGOLOGAN',
//       'PURACAN',
//       'ROMAGONDONG',
//       'SARANG',
//     ],
//   },
//   MASIU: {
//     province: 'LANAO DEL SUR',
//     municipality: 'MASIU',
//     region: 'BARMM',
//     barangay_list: [
//       'ABDULLAH BUISAN',
//       'ALIP LALABUAN',
//       'ALUMPANG PAINO MIMBALAY',
//       'BUADI AMLOY',
//       'CARAMIAN ALIM RAYA',
//       'DALOG BALUT',
//       'GINDOLONGAN ALABAT',
//       'GONDARANGIN ASA ADIGAO',
//       'KALILANGAN',
//       'LAILA LUMBAC BACON',
//       'LAKADUN',
//       'LANCO DIMAPATOY',
//       'LOMIGIS SUCOD',
//       'LUMBACA INGUD',
//       'MACABANGAN IMBALA',
//       'MACADAAG TALAGUIAN',
//       'MACALUPANG LUMBAC CARAMIAN',
//       'MACOMPARA APA MIMBALAY',
//       'MAGAYO BAGOAINGUD',
//       'MAI DITIMBANG BALINDONG',
//       'MAI SINDAOLOAN DANSALAN',
//       'MANALOCON TALUB',
//       'MARANAT BONTALIS',
//       'MATAO ARAZA',
//       'MOCAMAD TANGUL',
//       'MORIATAO-BAI LABAY',
//       'PANTAO',
//       'PUTAD MARANDANG BUISAN',
//       'SAMBOWANG ATAWA',
//       'SAWIR',
//       'TALUB LANGI',
//       'TAMBORO CORMATAN',
//       'TOMAMBILING LUMBACA INGUD',
//       'TOWANAO ARANGGA',
//       'UNDA DAYAWAN',
//     ],
//   },
//   MULONDO: {
//     province: 'LANAO DEL SUR',
//     municipality: 'MULONDO',
//     region: 'BARMM',
//     barangay_list: [
//       'BAGOAINGUD',
//       'BANGON',
//       'BUADI-ABALA',
//       'BUADI-BAYAWA',
//       'BUADI-INSUBA',
//       'BUBONG',
//       'BUBONGA GUILOPA',
//       'CABASARAN',
//       'CAIRATAN',
//       'CORMATAN',
//       'DALAMA',
//       'DANSALAN',
//       'DIMARAO',
//       'GUILOPA',
//       'ILIAN',
//       'KITAMBUGUN',
//       'LAMA (BAGOAINGUD)',
//       'LILOD',
//       'LILOD RAYBALAI',
//       'LUMBAC (LUMBAC BUBONG)',
//       'LUMBACA INGUD',
//       'MADAYA',
//       'PINDOLONAN',
//       'POBLACION (DADO)',
//       'SALIPONGAN',
//       'SUGAN',
//     ],
//   },
//   'PAGAYAWAN (TATARIKAN)': {
//     province: 'LANAO DEL SUR',
//     municipality: 'PAGAYAWAN (TATARIKAN)',
//     region: 'BARMM',
//     barangay_list: [
//       'AYONG',
//       'BANDARA INGUD',
//       'BANGON (POB.)',
//       'BIALA-AN',
//       'DIAMPACA',
//       'GUIARONG',
//       'ILIAN',
//       'KALALUDAN',
//       'LININDINGAN',
//       'MADANG',
//       'MAPANTAO',
//       'NGINGIR (KABASARAN)',
//       'PADAS',
//       'PAIGOAY',
//       'PINALANGCA',
//       'POBLACION (LUMBAC)',
//       'RANGIRAN',
//       'RUBOKUN',
//     ],
//   },
//   PIAGAPO: {
//     province: 'LANAO DEL SUR',
//     municipality: 'PIAGAPO',
//     region: 'BARMM',
//     barangay_list: [
//       'APOSONG',
//       'BAGOAINGUD',
//       'BANGCO (POB.)',
//       'BANSAYAN',
//       'BASAK',
//       'BOBO',
//       'BUALAN',
//       'BUBONG ILIAN',
//       'BUBONG TAWA-AN',
//       'BUBONGA MAMAANUN',
//       'GACAP',
//       'ILIAN',
//       'ILIAN POBLACION',
//       'KALANGANAN',
//       'KATUMBACAN',
//       'LININDING',
//       'LUMBACA MAMAAN',
//       'MAMAANUN',
//       'MENTRING',
//       'OLANGO',
//       'PALACAT',
//       'PALAO',
//       'PALING',
//       'PANTAON',
//       'PANTAR',
//       'PARIDI',
//       'PINDOLONAN',
//       'RADAPAN',
//       'RADAPAN POBLACION',
//       'RANTIAN',
//       'SAPINGIT',
//       'TALAO',
//       'TAMBO',
//       'TAPOCAN',
//       'TAPORUG',
//       'TAWAAN',
//       'UDALO',
//     ],
//   },
//   'PICONG (SULTAN GUMANDER)': {
//     province: 'LANAO DEL SUR',
//     municipality: 'PICONG (SULTAN GUMANDER)',
//     region: 'BARMM',
//     barangay_list: [
//       'ANAS',
//       'BARA-AS',
//       'BIASONG',
//       'BULANGOS',
//       'DURIAN',
//       'ILIAN',
//       'LIANGAN (POB.)',
//       'MAGANDING',
//       'MALADI',
//       'MAPANTAO',
//       'MICALUBO',
//       'MIMBALAWAG',
//       'PINDOLONAN',
//       'PUNONG',
//       'RAMITAN',
//       'TOROGAN',
//       'TUAL',
//       'TUCA',
//       'UBANOBAN',
//     ],
//   },
//   'POONA BAYABAO (GATA)': {
//     province: 'LANAO DEL SUR',
//     municipality: 'POONA BAYABAO (GATA)',
//     region: 'BARMM',
//     barangay_list: [
//       'ATARAGADONG',
//       'BANGON',
//       'BANSAYAN',
//       'BUALAN',
//       'BUBONG-DIMUNDA',
//       'BUGARAN',
//       'CADAYONAN',
//       'CALILANGAN DICALA',
//       'CALUPAAN',
//       'DILAUSAN',
//       'DIMAYON',
//       'DONGCOAN',
//       'GADONGAN',
//       'LIANGAN',
//       'LUMBAC',
//       'LUMBACA INGUD',
//       'MADANDING',
//       'PANTAO',
//       'POBLACION (GATA PROPER)',
//       'PUNUD',
//       'RAGAYAN',
//       'ROGAN CAIRAN',
//       'ROGAN TANDIONG DIMAYON',
//       'TALAGUIAN',
//       'TAPOROG',
//     ],
//   },
//   PUALAS: {
//     province: 'LANAO DEL SUR',
//     municipality: 'PUALAS',
//     region: 'BARMM',
//     barangay_list: [
//       'BADAK',
//       'BANTAYAN',
//       'BASAGAD',
//       'BOLINSONG',
//       'BORING',
//       'BUALAN',
//       'DANUGAN',
//       'DAPAO',
//       'DIAMLA',
//       'GADONGAN',
//       'INGUD',
//       'LINUK',
//       'LUMBAC',
//       'MALIGO',
//       'MASAO',
//       'NOTONG',
//       'PORUG',
//       'ROMAGONDONG',
//       'TAMBO (POB.)',
//       'TAMLANG',
//       'TOMAROMPONG',
//       'TUKA',
//       'YARAN',
//     ],
//   },
//   SAGUIARAN: {
//     province: 'LANAO DEL SUR',
//     municipality: 'SAGUIARAN',
//     region: 'BARMM',
//     barangay_list: [
//       'ALINUN',
//       'BAGOAINGUD',
//       'BASAK MAITO',
//       'BATANGAN',
//       'BUBONG',
//       'CADAYON',
//       'CADINGILAN',
//       'COMONAL',
//       'DILAUSAN',
//       'DILIMBAYAN',
//       'GADONGAN',
//       'LIMOGAO',
//       'LINAO',
//       'LUMBAC TOROS',
//       'LUMBAYANAGUE',
//       'MALIWANAG',
//       'MAPANTAO',
//       'MIPAGA',
//       'NATANGCOPAN',
//       'PAGALAMATAN',
//       'PAMACOTAN',
//       'PANGGAO',
//       'PANTAO RAYA',
//       'PANTAON',
//       'PATPANGKAT',
//       'PAWAK',
//       'PINDOLONAN',
//       'POBLACION',
//       'SALOCAD',
//       'SUNGCOD',
//     ],
//   },
//   'SULTAN DUMALONDONG': {
//     province: 'LANAO DEL SUR',
//     municipality: 'SULTAN DUMALONDONG',
//     region: 'BARMM',
//     barangay_list: [
//       'BACAYAWAN',
//       'BUTA (SUMALINDAO)',
//       'DINGANUN GUILOPA (DINGUNUN)',
//       'LUMBAC',
//       'MALALIS',
//       'PAGALONGAN',
//       'TAGORANAO',
//     ],
//   },
//   TAMPARAN: {
//     province: 'LANAO DEL SUR',
//     municipality: 'TAMPARAN',
//     region: 'BARMM',
//     barangay_list: [
//       'BALUTMADIAR',
//       'BANGON',
//       'BERUAR',
//       'BOCALAN',
//       'CABASARAN',
//       'DASOMALONG',
//       'DILAUSAN',
//       'GINAOPAN',
//       'LALABUAN',
//       'LILOD TAMPARAN',
//       'LILOD TUBOK',
//       'LINDONGAN',
//       'LINUK',
//       'LINUK ORIENTAL',
//       'LUMBAC',
//       'LUMBACA INGUD',
//       'LUMBACA LILOD',
//       'LUMBACAINGUD SOUTH',
//       'MAIDAN LINUK',
//       'MALA-ABANGON',
//       'MALIWANAG',
//       'MARIATAO DATU',
//       'MINANGA',
//       'MIONDAS',
//       'NEW LUMBACAINGUD',
//       'OCCIDENTAL LINUK',
//       'PAGALAMATAN LINUK',
//       'PAGAYAWAN',
//       'PICARABAWAN',
//       'PIMBAGO-PAGALONGAN',
//       'PINDOLONAN MARIATAO SARIP',
//       'POBLACION I',
//       'POBLACION II',
//       'POBLACION III',
//       'POBLACION IV',
//       'RAYA BUADI BARAO',
//       'RAYA NIONDAS',
//       'RAYA TAMPARAN',
//       'SALONGABANDING',
//       'SAMINUNGGAY',
//       'TALUB',
//       'TATAYAWAN NORTH',
//       'TATAYAWAN SOUTH',
//       'TUBOK',
//     ],
//   },
//   TARAKA: {
//     province: 'LANAO DEL SUR',
//     municipality: 'TARAKA',
//     region: 'BARMM',
//     barangay_list: [
//       'BANDERA BUISAN',
//       'BORIONGAN',
//       'BOROWA',
//       'BUADI AMAO',
//       'BUADI AMUNTA',
//       'BUADI AMUNUD',
//       'BUADI ARORAO',
//       'BUADI ATOPA',
//       'BUADI DAYOMANGGA',
//       'BUADI DINGUN',
//       'BUADI ONGCALO',
//       'BUCALAN',
//       'CADAYONAN BAGUMBAYAN',
//       'CARAMAT',
//       'CARANDANGAN CALOPAAN',
//       'DATU MA-AS',
//       'DILABAYAN',
//       'DIMAYON',
//       'GAPAO BALINDONG',
//       'ILIAN',
//       'LUMASA',
//       'LUMASA PROPER (SALVADOR CONCHA)',
//       'LUMBAC BAGOAINGUD',
//       'LUMBAC BUBONG MAINDANG',
//       'LUMBAC PITAKUS',
//       'MALUNGUN',
//       'MANGAYAO',
//       'MARUHOM',
//       'MASOLUN',
//       'MORIATAO LOKSA DATU',
//       'PAGALAMATAN',
//       'PINDOLONAN',
//       'PITAKUS',
//       'RIRIK',
//       'SALIPONGAN',
//       'SAMBOLAWAN',
//       'SAMPORNA SALAMATOLLAH',
//       'SIGAYAN PROPER',
//       'SOMIORANG BANDINGUN',
//       'SUNDING',
//       'SUNGGOD',
//       'SUPANGAN',
//       'TUPA-AN BUADIATUPA',
//     ],
//   },
//   TUBARAN: {
//     province: 'LANAO DEL SUR',
//     municipality: 'TUBARAN',
//     region: 'BARMM',
//     barangay_list: [
//       'ALOG',
//       'BAGIANGUN',
//       'BETA',
//       'CAMPO',
//       'DATUMANONG',
//       'DINAIGAN',
//       'GADONGAN',
//       'GAPUT',
//       'GUIARONG',
//       'MADAYA',
//       'MALAGANDING',
//       'METADICOP',
//       'MINDAMUDAG',
//       'PAGALAMATAN',
//       'PAIGOAY-PIMBATAAN',
//       'POBLACION (BURIBID)',
//       'POLO',
//       'RIANTARAN',
//       'TANGCAL',
//       'TUBARAN PROPER',
//       'WAGO',
//     ],
//   },
//   TUGAYA: {
//     province: 'LANAO DEL SUR',
//     municipality: 'TUGAYA',
//     region: 'BARMM',
//     barangay_list: [
//       'BAGOAINGUD',
//       'BUADI ALAWANG',
//       'BUADI DICO',
//       'BUBONG',
//       'CAMPONG TALAO',
//       'CAYAGAN',
//       'DANDANUN',
//       'DILIMBAYAN',
//       'GURAIN',
//       'LUMBAC',
//       'MAIDAN',
//       'MAPANTAO',
//       'PAGALAMATAN',
//       'PANDIARANAO',
//       'PINDOLONAN I',
//       'PINDOLONAN II',
//       'POBLACION (INGUD)',
//       'PUTAD',
//       'RAYA',
//       'SUGOD I',
//       'SUGOD MAWATAN',
//       'SUMBAGA ROGONG',
//       'TANGCAL',
//     ],
//   },
//   WAO: {
//     province: 'LANAO DEL SUR',
//     municipality: 'WAO',
//     region: 'BARMM',
//     barangay_list: [
//       'AMOYONG',
//       'BALATIN',
//       'BANGA',
//       'BO-OT',
//       'BUNTONGUN',
//       'CEBUANO GROUP',
//       'CHRISTIAN VILLAGE',
//       'EASTERN WAO',
//       'EXTENSION POBLACION',
//       'GATA',
//       'KABATANGAN',
//       'KADINGILAN',
//       'KATUTUNGAN (POB.)',
//       'KILIKILI EAST',
//       'KILIKILI WEST',
//       'MALAIGANG',
//       'MANILA GROUP',
//       'MILAYA',
//       'MIMBUAYA',
//       'MUSLIM VILLAGE',
//       'PAGALONGAN',
//       'PANANG',
//       'PARK AREA (POB.)',
//       'PILINTANGAN',
//       'SERRAN VILLAGE',
//       'WESTERN WAO (POB.)',
//     ],
//   },
//   AMPATUAN: {
//     province: 'MAGUINDANAO',
//     municipality: 'AMPATUAN',
//     region: 'BARMM',
//     barangay_list: [
//       'DICALONGAN (POB.)',
//       'KAKAL',
//       'KAMASI',
//       'KAPINPILAN',
//       'KAURAN',
//       'MALATIMON',
//       'MATAGABONG',
//       'SALMAN',
//       'SANIAG',
//       'TOMICOR',
//       'TUBAK',
//     ],
//   },
//   BARIRA: {
//     province: 'MAGUINDANAO',
//     municipality: 'BARIRA',
//     region: 'BARMM',
//     barangay_list: [
//       'BARIRA (POB.)',
//       'BUALAN',
//       'GADUNG',
//       'KOROSOYAN',
//       'LAMIN',
//       'LIONG',
//       'LIPA',
//       'LIPAWAN',
//       'MARANG',
//       'MINABAY',
//       'NABALAWAG',
//       'PANGGAO',
//       'ROMINIMBANG',
//       'TOGAIG',
//     ],
//   },
//   BULDON: {
//     province: 'MAGUINDANAO',
//     municipality: 'BULDON',
//     region: 'BARMM',
//     barangay_list: [
//       'AMPUAN',
//       'ARATUC',
//       'CABAYUAN',
//       'CALAAN (POB.)',
//       'DINGANEN',
//       'EDCOR (GALLEGO EDCOR)',
//       'KARIM',
//       'KULIMPANG',
//       'MATAYA',
//       'MINABAY',
//       'NUYO',
//       'ORING',
//       'PANTAWAN',
//       'PIERS',
//       'RUMIDAS',
//     ],
//   },
//   BULUAN: {
//     province: 'MAGUINDANAO',
//     municipality: 'BULUAN',
//     region: 'BARMM',
//     barangay_list: [
//       'DIGAL',
//       'LOWER SILING',
//       'MASLABENG',
//       'POBLACION',
//       'POPOL',
//       'TALITAY',
//       'UPPER SILING',
//     ],
//   },
//   'COTABATO': {
//     province: 'MAGUINDANAO',
//     municipality: 'COTABATO',
//     region: 'BARMM',
//     barangay_list: [
//       'BAGUA',
//       'BAGUA I',
//       'BAGUA II',
//       'BAGUA III',
//       'KALANGANAN',
//       'KALANGANAN I',
//       'KALANGANAN II',
//       'POBLACION',
//       'POBLACION I',
//       'POBLACION II',
//       'POBLACION III',
//       'POBLACION IV',
//       'POBLACION IX',
//       'POBLACION V',
//       'POBLACION VI',
//       'POBLACION VII',
//       'POBLACION VIII',
//       'ROSARY HEIGHTS',
//       'ROSARY HEIGHTS I',
//       'ROSARY HEIGHTS II',
//       'ROSARY HEIGHTS III',
//       'ROSARY HEIGHTS IV',
//       'ROSARY HEIGHTS IX',
//       'ROSARY HEIGHTS V',
//       'ROSARY HEIGHTS VI',
//       'ROSARY HEIGHTS VII',
//       'ROSARY HEIGHTS VIII',
//       'ROSARY HEIGHTS X',
//       'ROSARY HEIGHTS XI',
//       'ROSARY HEIGHTS XII',
//       'ROSARY HEIGHTS XIII',
//       'TAMONTAKA',
//       'TAMONTAKA I',
//       'TAMONTAKA II',
//       'TAMONTAKA III',
//       'TAMONTAKA IV',
//       'TAMONTAKA V',
//     ],
//   },
//   'DATU ABDULLAH SANGKI': {
//     province: 'MAGUINDANAO',
//     municipality: 'DATU ABDULLAH SANGKI',
//     region: 'BARMM',
//     barangay_list: [
//       'BANABA',
//       'DIMAAMPAO',
//       'GUINIBON',
//       'KAYA-KAYA',
//       'MAGANOY',
//       'MAO',
//       'MARANDING',
//       'SUGADOL',
//       'TALISAWA',
//       'TUKANOLOCONG (TUKANOLOGONG)',
//     ],
//   },
//   'DATU ANGGAL MIDTIMBANG': {
//     province: 'MAGUINDANAO',
//     municipality: 'DATU ANGGAL MIDTIMBANG',
//     region: 'BARMM',
//     barangay_list: [
//       'ADAON',
//       'BRAR',
//       'MAPAYAG',
//       'MIDTIMBANG (POB.)',
//       'NUNANGAN (NUNANGEN)',
//       'TUGAL',
//       'TULUNAN',
//     ],
//   },
//   'DATU BLAH T. SINSUAT': {
//     province: 'MAGUINDANAO',
//     municipality: 'DATU BLAH T. SINSUAT',
//     region: 'BARMM',
//     barangay_list: [
//       'KINIMI',
//       'LAGUITAN',
//       'LAPAKEN',
//       'MATUBER',
//       'METI',
//       'NALKAN',
//       'PENANSARAN',
//       'PURA',
//       'RESA',
//       'SEDEM',
//       'SINIPAK',
//       'TAMBAK',
//       'TUBUAN',
//     ],
//   },
//   'DATU HOFFER AMPATUAN': {
//     province: 'MAGUINDANAO',
//     municipality: 'DATU HOFFER AMPATUAN',
//     region: 'BARMM',
//     barangay_list: [
//       'KUBENTONG',
//       'LABU-LABU I',
//       'LABU-LABU II',
//       'LIMPONGO',
//       'MACALAG',
//       'SAYAP',
//       'TAIB',
//       'TALIBADOK',
//       'TUAYAN',
//       'TUAYAN I',
//       'TUNTUNGAN',
//     ],
//   },
//   'DATU ODIN SINSUAT (DINAIG)': {
//     province: 'MAGUINDANAO',
//     municipality: 'DATU ODIN SINSUAT (DINAIG)',
//     region: 'BARMM',
//     barangay_list: [
//       'AMBOLODTO',
//       'AWANG',
//       'BADAK',
//       'BAGOENGED',
//       'BAKA',
//       'BENOLEN',
//       'BITU',
//       'BONGUED',
//       'BUGAWAS',
//       'CAPITON',
//       'DADOS',
//       'DALICAN POBLACION',
//       'DINAIG PROPER',
//       'DULANGAN',
//       'KAKAR',
//       'KENEBEKA',
//       'KURINTEM',
//       'KUSIONG',
//       'LABUNGAN',
//       'LINEK',
//       'MAKIR',
//       'MARGUES',
//       'MOMPONG',
//       'NEKITAN',
//       'SAPALAN',
//       'SEMBA',
//       'SIBUTO',
//       'SIFAREN (SIFARAN)',
//       'TAMBAK',
//       'TAMONTAKA',
//       'TANUEL',
//       'TAPIAN',
//       'TAVIRAN',
//       'TENONGGOS',
//     ],
//   },
//   'DATU PAGLAS': {
//     province: 'MAGUINDANAO',
//     municipality: 'DATU PAGLAS',
//     region: 'BARMM',
//     barangay_list: [
//       'ALIP (POB.)',
//       'BONAWAN',
//       'BULOD',
//       'DAMALUSAY',
//       'DAMAWATO',
//       'DATANG',
//       'ELBEBE',
//       'KALUMENGA (KALUMANGA)',
//       'KATIL',
//       'LIPAO',
//       'LOMOYON',
//       'MADIDIS',
//       'MAKAT',
//       'MALALA',
//       'MANGADEG',
//       'MANINDOLO',
//       'MAO',
//       'NAPOK',
//       'PALAO SA BUTO',
//       'POBLACION',
//       'PUYA',
//       'SALENDAB',
//       'SEPAKA',
//     ],
//   },
//   'DATU PIANG': {
//     province: 'MAGUINDANAO',
//     municipality: 'DATU PIANG',
//     region: 'BARMM',
//     barangay_list: [
//       'ALONGANAN',
//       'AMBADAO',
//       'BALANAKAN',
//       'BALONG',
//       'BUAYAN',
//       'DADO',
//       'DAMABALAS',
//       'DUAMINANGA',
//       'KALIPAPA',
//       'KANGUAN',
//       'LIONG',
//       'MAGASLONG',
//       'MASIGAY',
//       'MONTAY',
//       'POBLACION (DULAWAN)',
//       'REINA REGENTE',
//     ],
//   },
//   'DATU SALIBO': {
//     province: 'MAGUINDANAO',
//     municipality: 'DATU SALIBO',
//     region: 'BARMM',
//     barangay_list: [
//       'ALONGANAN',
//       'ANDAVIT',
//       'BALANAKAN',
//       'BUAYAN',
//       'BUTILEN',
//       'DADO',
//       'DAMABALAS',
//       'DUAMINANGA',
//       'KALIPAPA',
//       'LIONG',
//       'MAGASLONG',
//       'MASIGAY',
//       'PAGATIN',
//       'PANDI',
//       'PENDITEN',
//       'SAMBULAWAN',
//       'TEE',
//     ],
//   },
//   'DATU SAUDI AMPATUAN': {
//     province: 'MAGUINDANAO',
//     municipality: 'DATU SAUDI AMPATUAN',
//     region: 'BARMM',
//     barangay_list: [
//       'DAPIAWAN',
//       'ELIAN',
//       'GAWANG',
//       'KABENGI',
//       'KITANGO',
//       'KITAPOK',
//       'MADIA',
//       'SALBU',
//     ],
//   },
//   'DATU UNSAY': {
//     province: 'MAGUINDANAO',
//     municipality: 'DATU UNSAY',
//     region: 'BARMM',
//     barangay_list: [
//       'BULAYAN',
//       'IGANAGAMPONG',
//       'MACALAG',
//       'MAITUMAIG',
//       'MALANGOG',
//       'META',
//       'PANANGETI',
//       'TUNTUNGAN',
//     ],
//   },
//   'GEN. S. K. PENDATUN': {
//     province: 'MAGUINDANAO',
//     municipality: 'GEN. S. K. PENDATUN',
//     region: 'BARMM',
//     barangay_list: [
//       'BADAK',
//       'BULOD',
//       'KALADTURAN',
//       'KULASI',
//       'LAO-LAO',
//       'LASANGAN',
//       'LOWER IDTIG',
//       'LUMABAO',
//       'MAKAINIS',
//       'MIDCONDING',
//       'MIDPANDACAN',
//       'PANOSOLEN',
//       'PIDTIGUIAN',
//       'QUIPOLOT',
//       'RAMCOR',
//       'SADANGEN',
//       'SUMAKUBAY',
//       'TONGGOL',
//       'UPPER LASANGAN',
//     ],
//   },
//   GUINDULUNGAN: {
//     province: 'MAGUINDANAO',
//     municipality: 'GUINDULUNGAN',
//     region: 'BARMM',
//     barangay_list: [
//       'AHAN',
//       'BAGAN',
//       'DATALPANDAN',
//       'KALUMAMIS',
//       'KATEMAN',
//       'LAMBAYAO',
//       'MACASAMPEN',
//       'MUSLIM',
//       'MUTI',
//       'SAMPAO',
//       'TAMBUNAN',
//     ],
//   },
//   'KABUNTALAN (TUMBAO)': {
//     province: 'MAGUINDANAO',
//     municipality: 'KABUNTALAN (TUMBAO)',
//     region: 'BARMM',
//     barangay_list: [
//       'BAGUMBAYAN',
//       'BUTERIN',
//       'DADTUMOG (DADTUMEG)',
//       'GAMBAR',
//       'GANTA',
//       'KATIDTUAN',
//       'LANGEBAN',
//       'LIONG',
//       'LOWER TAVIRAN',
//       'MAITONG',
//       'MATILAK',
//       'PAGALUNGAN',
//       'PAYAN',
//       'PEDTAD',
//       'PENED',
//       'POBLACION',
//       'UPPER TAVIRAN',
//     ],
//   },
//   MAMASAPANO: {
//     province: 'MAGUINDANAO',
//     municipality: 'MAMASAPANO',
//     region: 'BARMM',
//     barangay_list: [
//       'BAGUMBONG',
//       'DABENAYAN',
//       'DALADAP',
//       'DASIKIL',
//       'LIAB',
//       'LIBUTAN',
//       'LUSAY',
//       'MAMASAPANO',
//       'MANONGKALING',
//       'PIDSANDAWAN',
//       'PIMBALAKAN',
//       'SAPAKAN',
//       'TUKA',
//       'TUKANALIPAO',
//     ],
//   },
//   MANGUDADATU: {
//     province: 'MAGUINDANAO',
//     municipality: 'MANGUDADATU',
//     region: 'BARMM',
//     barangay_list: [
//       'DALADAGAN',
//       'KALIAN',
//       'LUAYAN',
//       'PAITAN',
//       'PANAPAN',
//       'TENOK',
//       'TINAMBULAN',
//       'TUMBAO',
//     ],
//   },
//   MATANOG: {
//     province: 'MAGUINDANAO',
//     municipality: 'MATANOG',
//     region: 'BARMM',
//     barangay_list: [
//       'BAYANGA NORTE',
//       'BAYANGA SUR',
//       'BUGASAN NORTE',
//       'BUGASAN SUR (POB.)',
//       'KIDAMA',
//       'LANGCO',
//       'LANGKONG',
//       'SAPAD',
//     ],
//   },
//   'NORTHERN KABUNTALAN': {
//     province: 'MAGUINDANAO',
//     municipality: 'NORTHERN KABUNTALAN',
//     region: 'BARMM',
//     barangay_list: [
//       'BALONG',
//       'DAMATOG',
//       'GAYONGA',
//       'GUIAWA',
//       'INDATUAN',
//       'KAPIMPILAN',
//       'LIBUNGAN',
//       'MONTAY',
//       'PAULINO LABIO',
//       'SABAKEN',
//       'TUMAGUINTING',
//     ],
//   },
//   PAGAGAWAN: {
//     province: 'MAGUINDANAO',
//     municipality: 'PAGAGAWAN',
//     region: 'BARMM',
//     barangay_list: [
//       'BALATUNGKAYO (BATUNGKAYO)',
//       'BULIT',
//       'BULOD',
//       'DUNGGUAN',
//       'LIMBALUD',
//       'MARIDAGAO',
//       'NABUNDAS',
//       'PAGAGAWAN',
//       'TALAPAS',
//       'TALITAY',
//       'TUNGGOL',
//     ],
//   },
//   PAGALUNGAN: {
//     province: 'MAGUINDANAO',
//     municipality: 'PAGALUNGAN',
//     region: 'BARMM',
//     barangay_list: [
//       'BAGOENGED',
//       'BULIOK',
//       'DALGAN',
//       'DAMALASAK',
//       'GALAKIT',
//       'INUG-UG',
//       'KALBUGAN',
//       'KILANGAN',
//       'KUDAL',
//       'LAYOG',
//       'LINANDANGAN',
//       'POBLACION',
//     ],
//   },
//   PAGLAT: {
//     province: 'MAGUINDANAO',
//     municipality: 'PAGLAT',
//     region: 'BARMM',
//     barangay_list: [
//       'CAMPO',
//       'DAMAKLING',
//       'DAMALUSAY',
//       'KAKAL',
//       'PAGLAT',
//       'SALAM',
//       'TUAL',
//       'UPPER IDTIG',
//     ],
//   },
//   PANDAG: {
//     province: 'MAGUINDANAO',
//     municipality: 'PANDAG',
//     region: 'BARMM',
//     barangay_list: [
//       'KABULING',
//       'KAYAGA',
//       'KAYUPO (CUYAPO)',
//       'LEPAK',
//       "LOWER D'LAG",
//       'MALANGIT',
//       'PANDAG',
//       "UPPER D'LAG",
//     ],
//   },
//   PARANG: {
//     province: 'SULU',
//     municipality: 'PARANG',
//     region: 'BARMM',
//     barangay_list: [
//       'ALU LAYAG-LAYAG',
//       'ALU PANGKOH',
//       'BAGSAK',
//       'BAWISAN',
//       'BIID',
//       'BUKID',
//       'BULI BAWANG',
//       'BUTON',
//       'BUTON MAHABLO',
//       'DANAPA',
//       'DUYAN KABAO',
//       'GIMBA LAGASAN',
//       'KAHA',
//       'KAHOY SINAH',
//       'KANAWAY',
//       'KUTAH SAIRAP',
//       'LAGASAN HIGAD',
//       'LANAO DAKULA',
//       'LAUM BUWAHAN',
//       'LAUM SUWAH',
//       'LIANG',
//       'LINUHO',
//       'LIPUNOS',
//       'LOWER SAMPUNAY',
//       'LUMBAAN MAHABA',
//       'LUNGAN GITONG',
//       'LUPA ABU',
//       'NONOKAN',
//       'PAUGAN',
//       'PAYUHAN',
//       'PIYAHAN',
//       'POBLACION (PARANG)',
//       'SALDANG',
//       'SAMPUNAY',
//       'SILANGKAN',
//       'TAINGTING',
//       'TIKONG',
//       'TUKAY',
//       'TUMANGAS',
//       'WANNI PIYANJIHAN',
//     ],
//   },
//   'RAJAH BUAYAN': {
//     province: 'MAGUINDANAO',
//     municipality: 'RAJAH BUAYAN',
//     region: 'BARMM',
//     barangay_list: [
//       'BAITAL',
//       'BAKAT',
//       'DAPANTIS',
//       'GAUNAN',
//       'MALIBPOLOK',
//       'MILEB',
//       'PANADTABAN',
//       'PIDSANDAWAN',
//       'SAMPAO',
//       'SAPAKAN (POB.)',
//       'TABUNGAO',
//     ],
//   },
//   'SHARIFF AGUAK (MAGANOY)': {
//     province: 'MAGUINDANAO',
//     municipality: 'SHARIFF AGUAK (MAGANOY)',
//     region: 'BARMM',
//     barangay_list: [
//       'BAGONG',
//       'BIALONG',
//       'KULOY',
//       'LABU-LABU',
//       'LAPOK (LEPOK)',
//       'MALINGAO',
//       'POBLACION',
//       'POBLACION I',
//       'POBLACION II',
//       'SATAN',
//       'TAPIKAN',
//       'TIMBANGAN',
//       'TINA',
//     ],
//   },
//   'SHARIFF SAYDONA MUSTAPHA': {
//     province: 'MAGUINDANAO',
//     municipality: 'SHARIFF SAYDONA MUSTAPHA',
//     region: 'BARMM',
//     barangay_list: [
//       'BAKAT',
//       'DALE-BONG',
//       'DASAWAO',
//       'DATU BAKAL',
//       'DATU KILAY',
//       'DUGUENGEN',
//       'GANTA',
//       'INALADAN',
//       'LIBUTAN',
//       'LINANTANGAN',
//       'NABUNDAS',
//       'PAGATIN',
//       'PAGATIN (PAGATIN I)',
//       'PAMALIAN',
//       'PIKEG',
//       'PUSAO',
//     ],
//   },
//   'SOUTH UPI': {
//     province: 'MAGUINDANAO',
//     municipality: 'SOUTH UPI',
//     region: 'BARMM',
//     barangay_list: [
//       'BIARONG',
//       'BONGO',
//       'ITAW',
//       'KIGAN',
//       'KUYA',
//       'LAMUD',
//       'LOOY',
//       'PANDAN',
//       'PILAR',
//       'ROMANGAOB (POB.)',
//       'SAN JOSE',
//     ],
//   },
//   'SULTAN KUDARAT (NULING)': {
//     province: 'MAGUINDANAO',
//     municipality: 'SULTAN KUDARAT (NULING)',
//     region: 'BARMM',
//     barangay_list: [
//       'ALAMADA',
//       'BANATIN',
//       'BANUBO',
//       'BULALO',
//       'BULIBOD',
//       'CALSADA',
//       'CROSSING SIMUAY',
//       'DALUMANGCOB (POB.)',
//       'DAMANIOG',
//       'DARAPANAN',
//       'GANG',
//       'INAWAN',
//       'KABUNTALAN',
//       'KAKAR',
//       'KAPIMPILAN',
//       'KATAMLANGAN (MATAMPAY)',
//       'KATIDTUAN',
//       'KATULI',
//       'LADIA',
//       'LIMBO',
//       'MAIDAPA',
//       'MAKAGUILING',
//       'MATENGEN',
//       'MULAUG',
//       'NALINAN',
//       'NARA',
//       'NEKITAN',
//       'OLAS',
//       'PANATAN',
//       'PIGCALAGAN',
//       'PIGKELEGAN (IBOTEGEN)',
//       'PINARING',
//       'PINGPING',
//       'RAGUISI',
//       'REBUKEN',
//       'SALIMBAO',
//       'SAMBOLAWAN',
//       'SENDITAN',
//       'UNGAP',
//     ],
//   },
//   'SULTAN MASTURA': {
//     province: 'MAGUINDANAO',
//     municipality: 'SULTAN MASTURA',
//     region: 'BARMM',
//     barangay_list: [
//       'BALUT',
//       'BOLIOK',
//       'BUNGABONG',
//       'DAGURONGAN',
//       'KIRKIR',
//       'MACABICO (MACABISO)',
//       'NAMUKEN',
//       'SIMUAY/SEASHORE',
//       'SOLON',
//       'TAMBO',
//       'TAPAYAN',
//       'TARIKEN',
//       'TUKA',
//     ],
//   },
//   'SULTAN SA BARONGIS (LAMBAYONG)': {
//     province: 'MAGUINDANAO',
//     municipality: 'SULTAN SA BARONGIS (LAMBAYONG)',
//     region: 'BARMM',
//     barangay_list: [
//       'ANGKAYAMAT',
//       'BARURAO',
//       'BULOD',
//       'DARAMPUA',
//       'GADUNGAN',
//       'KULAMBOG',
//       'LANGGAPANAN',
//       'MASULOT',
//       'PALDONG',
//       'PAPAKAN',
//       'TUGAL',
//       'TUKANAKUDEN',
//     ],
//   },
//   TALAYAN: {
//     province: 'MAGUINDANAO',
//     municipality: 'TALAYAN',
//     region: 'BARMM',
//     barangay_list: [
//       'BINANGGA NORTH',
//       'BINANGGA SOUTH',
//       'BOBOGUIRON',
//       'DAMABLAC',
//       'FUGOTAN',
//       'FUKOL',
//       'KATIBPUAN',
//       'KEDATI',
//       'LANTING',
//       'LINAMUNAN',
//       'MARADER',
//       'TALAYAN',
//       'TAMAR',
//       'TAMBUNAN I',
//       'TIMBALUAN',
//     ],
//   },
//   TALITAY: {
//     province: 'MAGUINDANAO',
//     municipality: 'TALITAY',
//     region: 'BARMM',
//     barangay_list: [
//       'BINTAN (BENTAN)',
//       'GADUNGAN',
//       'KILADAP',
//       'KILALAN',
//       'KUDEN',
//       'MAKADAYON',
//       'MANGGAY',
//       'PAGEDA',
//       'TALITAY',
//     ],
//   },
//   UPI: {
//     province: 'MAGUINDANAO',
//     municipality: 'UPI',
//     region: 'BARMM',
//     barangay_list: [
//       'BANTEK',
//       'BAYABAS',
//       'BLENSONG',
//       'BORONGOTAN',
//       'BUGABUNGAN',
//       'BUNGCOG',
//       'DARUGAO',
//       'GANASI',
//       'KABAKABA',
//       'KIBLEG',
//       'KIBUCAY',
//       'KIGA',
//       'KINITAN (KINITAAN)',
//       'MIRAB',
//       'NANGI',
//       'NURO POBLACION',
//       'RANAO PILAYAN',
//       'REMPES',
//       'RENEDE',
//       'RENTI',
//       'RIFAO',
//       'SEFEGEFEN',
//       'TINUNGKAAN',
//     ],
//   },
//   'HADJI PANGLIMA TAHIL (MARUNGGAS)': {
//     province: 'SULU',
//     municipality: 'HADJI PANGLIMA TAHIL (MARUNGGAS)',
//     region: 'BARMM',
//     barangay_list: [
//       'BANGAS (POB.)',
//       'BUBUAN',
//       'KABUUKAN',
//       'PAG-ASINAN',
//       'TEOMABAL',
//     ],
//   },
//   INDANAN: {
//     province: 'SULU',
//     municipality: 'INDANAN',
//     region: 'BARMM',
//     barangay_list: [
//       'ADJID',
//       'BANGALAN',
//       'BATO-BATO',
//       'BUANZA',
//       'BUD-TARAN',
//       'BUNUT',
//       'JATI-TUNGGAL',
//       'KABBON MAAS',
//       'KAGAY',
//       'KAJATIAN',
//       'KAN ISLAM',
//       'KANDANG TUKAY',
//       'KARAWAN',
//       'KATIAN',
//       'KUPPONG',
//       'LAMBAYONG',
//       'LANGPAS',
//       'LICUP',
//       'MALIMBAYA',
//       'MANGGIS',
//       'MANILOP',
//       'PALIGUE',
//       'PANABUAN',
//       'PANGLIMA MISUARI (SASAK)',
//       'PASIL',
//       'POBLACION (INDANAN)',
//       'SAPAH MALAUM',
//       'SAWAKI',
//       'SIONOGAN',
//       'TAGBAK',
//       'TIMBANGAN',
//       'TUBIG DAKULAH',
//       'TUBIG PARANG',
//       'TUMANTANGIS',
//     ],
//   },
//   JOLO: {
//     province: 'SULU',
//     municipality: 'JOLO',
//     region: 'BARMM',
//     barangay_list: [
//       'ALAT',
//       'ASTURIAS',
//       'BUS-BUS',
//       'CHINESE PIER',
//       'SAN RAYMUNDO',
//       'TAKUT-TAKUT',
//       'TULAY',
//       'WALLED CITY (POB.)',
//     ],
//   },
//   'KALINGALAN CALUANG': {
//     province: 'SULU',
//     municipality: 'KALINGALAN CALUANG',
//     region: 'BARMM',
//     barangay_list: [
//       'KAMBING',
//       'KANLAGAY',
//       'KARUNGDONG (POB.)',
//       'MASJID BAYLE',
//       'MASJID PUNJUNGAN',
//       'PANG',
//       'PANGDAN PANGDAN',
//       'PITOGO',
//       'TUNGGOL',
//     ],
//   },
//   LUGUS: {
//     province: 'SULU',
//     municipality: 'LUGUS',
//     region: 'BARMM',
//     barangay_list: [
//       'ALU BUS-BUS',
//       'ALU-DUYONG',
//       'BAS LUGUS',
//       'GAPAS RUGASAN',
//       'GAPAS TUBIG TUWAK',
//       'HUWIT-HUWIT BAS NONOK',
//       'HUWIT-HUWIT PROPER',
//       'KUTAH PARANG',
//       'LAHA',
//       'LARAP',
//       'LUGUS PROPER',
//       'MANGKALLAY',
//       'MANTAN',
//       'PAIT',
//       'PARIAN KAYAWAN',
//       'SIBUL',
//       'TINGKANGAN',
//     ],
//   },
//   LUUK: {
//     province: 'SULU',
//     municipality: 'LUUK',
//     region: 'BARMM',
//     barangay_list: [
//       'BUAL',
//       'GUIMBAUN',
//       'KAN-BULAK',
//       'KAN-MINDUS',
//       'LAMBAGO',
//       'LIANUTAN',
//       'LINGAH',
//       'MANANTI',
//       'NIOG-NIOG',
//       'TANDU-BATO',
//       'TUBIG-PUTI (POB.)',
//       'TULAYAN ISLAND',
//     ],
//   },
//   MAIMBUNG: {
//     province: 'SULU',
//     municipality: 'MAIMBUNG',
//     region: 'BARMM',
//     barangay_list: [
//       'ANAK JATI',
//       'BATO UGIS',
//       'BUALO LAHI',
//       'BUALO LIPID',
//       'BULABOG',
//       'DUHOL KABBON',
//       'GULANGAN',
//       'IPIL',
//       'KANDANG',
//       'KAPOK-PUNGGOL',
//       'KULASI',
//       'LABAH',
//       'LAGASAN ASIBIH',
//       'LANTONG',
//       'LAPA',
//       'LAUD KULASI',
//       'LAUM MAIMBUNG',
//       'LOWER TAMBAKING',
//       'LUNGGANG',
//       'MATATAL',
//       'PATAO',
//       'POBLACION (MAIMBUNG)',
//       'RATAG LIMBON',
//       'TABU-BATO',
//       'TANDU PATONG',
//       'TUBIG-SAMIN',
//       'UPPER TAMBAKING',
//     ],
//   },
//   'OLD PANAMAO': {
//     province: 'SULU',
//     municipality: 'OLD PANAMAO',
//     region: 'BARMM',
//     barangay_list: [
//       'ASIN',
//       'BAKUD',
//       'BANGDAY',
//       'BAUNOH',
//       'BITANAG',
//       'BUD SEIT',
//       'BULANGSI',
//       'DATAG',
//       'KAMALIG',
//       'KAN ASAALI',
//       'KAN UKOL',
//       'KAN-DAYOK',
//       'KAN-SIPAT',
//       'KAWASAN',
//       'KULAY-KULAY',
//       'LAKIT',
//       'LOWER PATIBULAN',
//       'LUNGGANG',
//       'PARANG',
//       'PUGAD MANAUL',
//       'PUHAGAN',
//       'SEIT HIGAD',
//       'SEIT LAKE (POB.)',
//       'SU-UH',
//       'TABU MANUK',
//       'TANDU-TANDU',
//       'TAYUNGAN',
//       'TINAH',
//       'TUBIG GANTANG',
//       'TUBIG JATI',
//       'UPPER PATIBULAN',
//     ],
//   },
//   OMAR: {
//     province: 'SULU',
//     municipality: 'OMAR',
//     region: 'BARMM',
//     barangay_list: [
//       'ANDALAN',
//       'ANGILAN',
//       'CAPUAL ISLAND',
//       'HUWIT-HUWIT',
//       'LAHING-LAHING',
//       'NIANGKAAN',
//       'SUCUBAN',
//       'TANGKUAN',
//     ],
//   },
//   PANDAMI: {
//     province: 'SULU',
//     municipality: 'PANDAMI',
//     region: 'BARMM',
//     barangay_list: [
//       'BALIGTANG',
//       'BUD SIBAUD',
//       'HAMBILAN',
//       'KABBON',
//       'LAHI',
//       'LAPAK',
//       'LAUD SIBAUD',
//       'MALANTA',
//       'MAMANOK',
//       'NORTH MANUBUL',
//       'PARIAN DAKULA',
//       'SIBAUD PROPER',
//       'SIGANGGANG',
//       'SOUTH MANUBUL',
//       'SUBA-SUBA',
//       'TENGA MANUBUL',
//     ],
//   },
//   'PANGLIMA ESTINO (NEW PANAMAO)': {
//     province: 'SULU',
//     municipality: 'PANGLIMA ESTINO (NEW PANAMAO)',
//     region: 'BARMM',
//     barangay_list: [
//       'GAGGUIL',
//       'GATA-GATA',
//       'JINGGAN',
//       'KAMIH-PUNGUD',
//       'LIHBUG KABAW',
//       'LIKBAH',
//       'LUBUK-LUBUK',
//       'MARSADA',
//       'PAIKSA',
//       'PANDAKAN',
//       'PUNAY (POB.)',
//       'TIPTIPON',
//     ],
//   },
//   PANGUTARAN: {
//     province: 'SULU',
//     municipality: 'PANGUTARAN',
//     region: 'BARMM',
//     barangay_list: [
//       'ALU BUNAH',
//       'BANGKILAY',
//       'KAWITAN',
//       'KEHI NIOG',
//       'LANTONG BABAG',
//       'LUMAH DAPDAP',
//       'PANDAN NIOG',
//       'PANDUCAN',
//       'PANITIKAN',
//       'PATUTOL',
//       'SE-IPANG',
//       'SIMBAHAN (POB.)',
//       'SUANG BUNAH',
//       'TONGGASANG',
//       'TUBIG NONOK',
//       'TUBIG SALLANG',
//     ],
//   },
//   PATA: {
//     province: 'SULU',
//     municipality: 'PATA',
//     region: 'BARMM',
//     barangay_list: [
//       'ANDALAN',
//       'DAUNGDONG',
//       'KAMAWI',
//       'KANJARANG',
//       'KAYAWAN (POB.)',
//       'KIPUT',
//       'LIKUD',
//       'LUUK-TULAY',
//       'NIOG-NIOG',
//       'PATIAN',
//       'PISAK-PISAK',
//       'SAIMBANGON',
//       'SANGKAP',
//       'TIMUDDAS',
//     ],
//   },
//   PATIKUL: {
//     province: 'SULU',
//     municipality: 'PATIKUL',
//     region: 'BARMM',
//     barangay_list: [
//       'ANULING',
//       'BAKONG',
//       'BANGKAL',
//       'BONBON',
//       'BUHANGINAN (DARAYAN)',
//       'BUNGKAUNG',
//       'DANAG',
//       'GANDASULI',
//       'IGASAN',
//       'KABBON TAKAS',
//       'KADDAY MAMPALLAM',
//       'KAN AGUE',
//       'KAUNAYAN',
//       'LANGHUB',
//       'LATIH',
//       'LIANG',
//       'MALIGAY',
//       'MAUBOH',
//       'PANGDANON',
//       'PANGLAYAHAN',
//       'PANSUL',
//       'PATIKUL HIGAD',
//       'SANDAH',
//       'TAGLIBI (POB.)',
//       'TANDU-BAGUA',
//       'TANUM',
//       'TAUNG',
//       'TIMPOK',
//       'TUGAS',
//       'UMANGAY',
//     ],
//   },
//   SIASI: {
//     province: 'SULU',
//     municipality: 'SIASI',
//     region: 'BARMM',
//     barangay_list: [
//       'BAKUD',
//       'BUAN',
//       'BULANSING TARA',
//       'BULIHKULLUL',
//       'CAMPO ISLAM',
//       'DUGGO',
//       'DUHOL TARA',
//       'EAST KUNGTAD',
//       'EAST SISANGAT',
//       'IPIL',
//       'JAMBANGAN',
//       'KABUBU',
//       'KONG-KONG LAMINUSA',
//       'KUD-KUD',
//       'KUNGTAD WEST',
//       'LATUNG',
//       'LUUK LAMINUSA',
//       'LUUK TARA',
//       'MANTA',
//       'MINAPAN',
//       'NIPA-NIPA',
//       'NORTH LAUD',
//       'NORTH MANTA',
//       'NORTH MUSU LAUD',
//       'NORTH SILUMPAK',
//       'PISLONG',
//       'POBLACION (CAMPO BARO)',
//       'PUNUNGAN',
//       'PUUKAN LAMINUSA',
//       'RATAG',
//       'SABLAY',
//       'SARUKOT',
//       'SIBURI',
//       'SINGKO',
//       'SIOLAKAN',
//       'SIOWING',
//       'SIPANDING',
//       'SISANGAT',
//       'SIUNDOH',
//       'SOUTH MUSU LAUD',
//       'SOUTH SILUMPAK',
//       'SOUTHWESTERN BULIKULLUL',
//       'SUBAH BUAYA',
//       'TAMPAKAN LAMINUSA',
//       'TENGAH LAMINUSA',
//       'TONG LAMINUSA',
//       'TONG-TONG',
//       'TONGLABAH',
//       'TUBIG KUTAH',
//       'TULLING',
//     ],
//   },
//   TALIPAO: {
//     province: 'SULU',
//     municipality: 'TALIPAO',
//     region: 'BARMM',
//     barangay_list: [
//       'ANDALAN',
//       'BAGSAK',
//       'BANDANG',
//       'BILAAN (POB.)',
//       'BUD BUNGA',
//       'BUNTOD',
//       'BUROH',
//       'DALIH',
//       'GATA',
//       'KABATUHAN BILAAN',
//       'KABATUHAN TIIS',
//       'KABUNGKOL',
//       'KAGAY',
//       'KAHAWA',
//       'KANDAGA',
//       'KANLIBOT',
//       'KIUTAAN',
//       'KUHAW',
//       'KULAMBOH',
//       'KUTTONG',
//       'LAGTOH',
//       'LAMBANAH',
//       'LIBAN',
//       'LIU-BUD PANTAO',
//       'LOWER BINUANG',
//       'LOWER KAMUNTAYAN',
//       'LOWER LAUS',
//       'LOWER SINUMAAN',
//       'LOWER TALIPAO',
//       'LUMBAYAO',
//       'LUMPING PIGIH DAHO',
//       'LUNGKIABAN',
//       'MABAHAY',
//       'MAHALA',
//       'MAMPALLAM',
//       'MARSADA',
//       'MAUBOH',
//       'MUNGIT-MUNGIT',
//       'NIOG-SANGAHAN',
//       'PANTAO',
//       'SAMAK',
//       'TALIPAO PROPER',
//       'TAMPAKAN',
//       'TIIS',
//       'TINGGAH',
//       'TUBOD',
//       'TUYANG',
//       'UPPER BINUANG',
//       'UPPER KAMUNTAYAN',
//       'UPPER LAUS',
//       'UPPER SINUMAAN',
//       'UPPER TALIPAO',
//     ],
//   },
//   TAPUL: {
//     province: 'SULU',
//     municipality: 'TAPUL',
//     region: 'BARMM',
//     barangay_list: [
//       'ALU-KABINGAAN',
//       'BANTING',
//       'HAWAN',
//       'KALANG (POB.)',
//       'KAMAUNGGI',
//       'KANAWAY',
//       'KANMANGON',
//       'KAUMPANG',
//       'PAGATPAT',
//       'PANGDAN',
//       'PUOK',
//       'SAYLI',
//       'SUMAMBAT',
//       'TANGKAPAAN',
//       'TULAKAN',
//     ],
//   },
//   TONGKIL: {
//     province: 'SULU',
//     municipality: 'TONGKIL',
//     region: 'BARMM',
//     barangay_list: [
//       'BAKKAAN',
//       'BANGALAW',
//       'DANAO',
//       'DUNGON',
//       'KAHIKUKUK',
//       'LUUK (POB.)',
//       'NORTH PAAROL',
//       'SIGUMBAL',
//       'SOUTH PAAROL',
//       'TABIALAN',
//       'TAINGA-BAKKAO',
//       'TAMBUN-BUN',
//       'TATTALAN',
//       'TINUTUNGAN',
//     ],
//   },
//   BONGAO: {
//     province: 'TAWI-TAWI',
//     municipality: 'BONGAO',
//     region: 'BARMM',
//     barangay_list: [
//       'BONGAO POBLACION',
//       'IPIL',
//       'KAMAGONG',
//       'KARUNGDONG',
//       'LAGASAN',
//       'LAKIT LAKIT',
//       'LAMION',
//       'LAPID LAPID',
//       'LATO LATO',
//       'LUUK PANDAN',
//       'LUUK TULAY',
//       'MALASSA',
//       'MANDULAN',
//       'MASANTONG',
//       'MONTAY MONTAY',
//       'NALIL',
//       'PABABAG',
//       'PAG-ASA',
//       'PAGASINAN',
//       'PAGATPAT',
//       'PAHUT',
//       'PAKIAS',
//       'PANIONGAN',
//       'PASIAGAN',
//       'SANGA-SANGA',
//       'SILUBOG',
//       'SIMANDAGIT',
//       'SUMANGAT',
//       'TARAWAKAN',
//       'TONGSINAH',
//       'TUBIG BASAG',
//       'TUBIG TANAH',
//       'TUBIG-BOH',
//       'TUBIG-MAMPALLAM',
//       'UNGUS-UNGUS',
//     ],
//   },
//   LANGUYAN: {
//     province: 'TAWI-TAWI',
//     municipality: 'LANGUYAN',
//     region: 'BARMM',
//     barangay_list: [
//       'ADNIN',
//       'BAKAW-BAKAW',
//       'BAKONG',
//       'BAS-BAS PROPER',
//       'BASLIKUD',
//       'BASNUNUK',
//       'DARUSSALAM',
//       'JAKARTA (LOOKAN LATUAN)',
//       'KALUPAG',
//       'KINIKTAL',
//       'LANGUYAN PROPER (POB.)',
//       'MARANG-MARANG',
//       'MARANING',
//       'PARANG PANTAY',
//       'SIKULLIS',
//       'SIMALAK',
//       'TUBIG DAKULA',
//       'TUHOG-TUHOG',
//       'TUMAHUBONG',
//       'TUMBAGAAN',
//     ],
//   },
//   'MAPUN (CAGAYAN DE TAWI-TAWI)': {
//     province: 'TAWI-TAWI',
//     municipality: 'MAPUN (CAGAYAN DE TAWI-TAWI)',
//     region: 'BARMM',
//     barangay_list: [
//       'BOKI',
//       'DUHUL BATU',
//       'EROK-EROK',
//       'GUPPAH',
//       'KOMPANG',
//       'LIYUBUD',
//       'LUBBAK PARANG',
//       'LUPA PULA (POB.)',
//       'MAHALO',
//       'PAWAN',
//       'SAPA',
//       'SIKUB',
//       'TABULIAN',
//       'TANDUAN',
//       'UMUS MATAHA',
//     ],
//   },
//   'PANGLIMA SUGALA (BALIMBING)': {
//     province: 'TAWI-TAWI',
//     municipality: 'PANGLIMA SUGALA (BALIMBING)',
//     region: 'BARMM',
//     barangay_list: [
//       'BALIMBING PROPER',
//       'BATU-BATU (POB.)',
//       'BAUNO GARING',
//       'BELATAN HALU',
//       'BUAN',
//       'DUNGON',
//       'KARAHA',
//       'KULAPE',
//       'LIYABURAN',
//       'LUUK BUNTAL',
//       'MAGSAGGAW',
//       'MALACCA',
//       'PARANGAN',
//       'SUMANGDAY',
//       'TABUNAN',
//       'TUNDON',
//       'TUNGBANGKAW',
//     ],
//   },
//   'SAPA-SAPA': {
//     province: 'TAWI-TAWI',
//     municipality: 'SAPA-SAPA',
//     region: 'BARMM',
//     barangay_list: [
//       'BALDATAL ISLAM',
//       'BUTUN',
//       'DALO-DALO',
//       'LAKIT-LAKIT',
//       'LATUAN (SUASANG)',
//       'LOOK NATUH',
//       'LOOKAN BANARAN',
//       'LOOKAN LATUAN',
//       'MALANTA',
//       'MANTABUAN TABUNAN',
//       'NUNUK LIKUD SIKUBONG',
//       'PALATE GADJAMINAH',
//       'PAMASAN',
//       'SAPA-SAPA (POB.)',
//       'SAPAAT',
//       'SUKAH-SUKAH',
//       'TABUNAN LIKUD SIKUBONG',
//       'TANGNGAH (LALUNG SIKUBONG)',
//       'TAPIAN BOHE NORTH',
//       'TAPIAN BOHE SOUTH',
//       'TONGGUSONG BANARAN',
//       'TUP-TUP BANARAN',
//     ],
//   },
//   SIBUTU: {
//     province: 'TAWI-TAWI',
//     municipality: 'SIBUTU',
//     region: 'BARMM',
//     barangay_list: [
//       'AMBULONG SAPAL',
//       'DATU AMILHAMJA JAAFAR',
//       'HADJI IMAM BIDIN',
//       'HADJI MOHTAR SULAYMAN',
//       'HADJI TAHA',
//       'IMAM HADJI MOHAMMAD',
//       'LIGAYAN',
//       'NUNUKAN',
//       'SHEIK MAKDUM',
//       'SIBUTU (POB.)',
//       'TALISAY',
//       'TANDU BANAK',
//       'TAUNGOH',
//       'TONGEHAT',
//       'TONGSIBALO',
//       'UNGUS-UNGUS',
//     ],
//   },
//   SIMUNUL: {
//     province: 'TAWI-TAWI',
//     municipality: 'SIMUNUL',
//     region: 'BARMM',
//     barangay_list: [
//       'BAGID*',
//       'BAKONG',
//       'DOH-TONG',
//       'LUUK DATAN',
//       'MANUK MANGKAW',
//       'MARUWA',
//       'MONGKAY',
//       'PAGASINAN',
//       'PANGLIMA MASTUL',
//       'SUKAH-BULAN',
//       'TAMPAKAN (POB.)',
//       'TIMUNDON',
//       'TONGGOSONG',
//       'TUBIG INDANGAN',
//       'UBOL',
//     ],
//   },
//   SITANGKAI: {
//     province: 'TAWI-TAWI',
//     municipality: 'SITANGKAI',
//     region: 'BARMM',
//     barangay_list: [
//       'DATU BAGUINDA PUTIH',
//       'IMAM SAPIE',
//       'NORTH LARAP',
//       'PANGLIMA ALARI',
//       'SIPANGKOT',
//       'SITANGKAI POBLACION',
//       'SOUTH LARAP (LARAP)',
//       'TONGMAGENG',
//       'TONGUSONG',
//     ],
//   },
//   'SOUTH UBIAN': {
//     province: 'TAWI-TAWI',
//     municipality: 'SOUTH UBIAN',
//     region: 'BARMM',
//     barangay_list: [
//       'BABAGAN',
//       'BENGKOL',
//       'BINTAWLAN',
//       'BOHE',
//       'BUBUAN',
//       'BUNAY BUNAY CENTER',
//       'BUNAY BUNAY LOOKAN',
//       'BUNAY BUNAY TONG',
//       'EAST TALISAY',
//       'LAHAD DAMPONG',
//       'LAITAN',
//       'LAMBI-LAMBIAN',
//       'LAUD',
//       'LIKUD DAMPONG',
//       'LIKUD TABAWAN',
//       'NUNUK',
//       'NUSA',
//       'NUSA-NUSA',
//       'PAMPANG',
//       'PUTAT',
//       'SOLLOGAN',
//       'TALISAY',
//       'TAMPAKAN DAMPONG',
//       'TANGNGAH',
//       'TINDA-TINDAHAN',
//       'TONG TAMPAKAN',
//       'TUBIG DAYANG',
//       'TUBIG DAYANG CENTER',
//       'TUBIG DAYANG RIVERSIDE',
//       'TUKKAI',
//       'UNAS-UNAS',
//     ],
//   },
//   TANDUBAS: {
//     province: 'TAWI-TAWI',
//     municipality: 'TANDUBAS',
//     region: 'BARMM',
//     barangay_list: [
//       'BALIUNGAN',
//       'BALLAK',
//       'BUTUN',
//       'HIMBAH',
//       'KAKOONG',
//       'KALANG-KALANG',
//       'KEPENG',
//       'LAHAY-LAHAY',
//       'NAUNGAN',
//       'SALAMAT',
//       'SALLANGAN',
//       'SAPA',
//       'SIBAKLOON',
//       'SILANTUP',
//       'TANDUBATO',
//       'TANGNGAH (TANGNGAH UNGUS MATATA)',
//       'TAPIAN',
//       'TAPIAN SUKAH',
//       'TARUK',
//       'TONGBANGKAW',
//     ],
//   },
//   'TURTLE ISLANDS': {
//     province: 'TAWI-TAWI',
//     municipality: 'TURTLE ISLANDS',
//     region: 'BARMM',
//     barangay_list: ['LIKUD BAKKAO', 'TAGANAK POBLACION'],
//   },
//   BANGUED: {
//     province: 'ABRA',
//     municipality: 'BANGUED',
//     region: 'CAR',
//     barangay_list: [
//       'AGTANGAO',
//       'ANGAD',
//       'BANGBANGAR',
//       'BAÑACAO',
//       'CABULOAN',
//       'CALABA',
//       'COSILI EAST (PROPER)',
//       'COSILI WEST (BUAYA)',
//       'DANGDANGLA',
//       'LINGTAN',
//       'LIPCAN',
//       'LUBONG',
//       'MACARCARMAY',
//       'MACRAY',
//       'MALITA',
//       'MAOAY',
//       'PALAO',
//       'PATUCANNAY',
//       'SAGAP',
//       'SAN ANTONIO',
//       'SANTA ROSA',
//       'SAO-ATAN',
//       'SAPPAAC',
//       'TABLAC (CALOT)',
//       'ZONE 1 POB. (NALASIN)',
//       'ZONE 2 POB. (CONSILIMAN)',
//       'ZONE 3 POB. (LALAUD)',
//       'ZONE 4 POB. (TOWN PROPER)',
//       'ZONE 5 POB. (BO. BARIKIR)',
//       'ZONE 6 POB. (SINAPANGAN)',
//       'ZONE 7 POB. (BALILING)',
//     ],
//   },
//   BOLINEY: {
//     province: 'ABRA',
//     municipality: 'BOLINEY',
//     region: 'CAR',
//     barangay_list: [
//       'AMTI',
//       'BAO-YAN',
//       'DANAC EAST',
//       'DANAC WEST',
//       'DAO-ANGAN',
//       'DUMAGAS',
//       'KILONG-OLAO',
//       'POBLACION (BOLINEY)',
//     ],
//   },
//   BUCAY: {
//     province: 'ABRA',
//     municipality: 'BUCAY',
//     region: 'CAR',
//     barangay_list: [
//       'ABANG',
//       'BANGBANGCAG',
//       'BANGCAGAN',
//       'BANGLOLAO',
//       'BUGBOG',
//       'CALAO',
//       'DUGONG',
//       'LABON',
//       'LAYUGAN',
//       'MADALIPAY',
//       'NORTH POBLACION',
//       'PAGALA',
//       'PAKILING',
//       'PALAQUIO',
//       'PATOC',
//       'QUIMLOONG',
//       'SALNEC',
//       'SAN MIGUEL',
//       'SIBLONG',
//       'SOUTH POBLACION',
//       'TABIOG',
//     ],
//   },
//   BUCLOC: {
//     province: 'ABRA',
//     municipality: 'BUCLOC',
//     region: 'CAR',
//     barangay_list: ['DUCLIGAN', 'LABAAN', 'LAMAO (POB.)', 'LINGAY'],
//   },
//   DAGUIOMAN: {
//     province: 'ABRA',
//     municipality: 'DAGUIOMAN',
//     region: 'CAR',
//     barangay_list: ['ABLEG', 'CABARUYAN', 'PIKEK', 'TUI (POB.)'],
//   },
//   DANGLAS: {
//     province: 'ABRA',
//     municipality: 'DANGLAS',
//     region: 'CAR',
//     barangay_list: [
//       'ABAQUID',
//       'CABARUAN',
//       'CAUPASAN (POB.)',
//       'DANGLAS',
//       'NAGAPARAN',
//       'PADANGITAN',
//       'PANGAL',
//     ],
//   },
//   LACUB: {
//     province: 'ABRA',
//     municipality: 'LACUB',
//     region: 'CAR',
//     barangay_list: [
//       'BACAG',
//       'BUNEG',
//       'GUINGUINABANG',
//       'LAN-AG',
//       'PACOC',
//       'POBLACION (TALAMPAC)',
//     ],
//   },
//   LAGANGILANG: {
//     province: 'ABRA',
//     municipality: 'LAGANGILANG',
//     region: 'CAR',
//     barangay_list: [
//       'AGUET',
//       'BACOOC',
//       'BALAIS',
//       'CAYAPA',
//       'DALAGUISEN',
//       'LAANG',
//       'LAGBEN',
//       'LAGUIBEN',
//       'NAGTIPULAN',
//       'NAGTUPACAN',
//       'PAGANAO',
//       'PAWA',
//       'POBLACION',
//       'PRESENTAR',
//       'SAN ISIDRO',
//       'TAGODTOD',
//       'TAPING',
//     ],
//   },
//   LAGAYAN: {
//     province: 'ABRA',
//     municipality: 'LAGAYAN',
//     region: 'CAR',
//     barangay_list: ['BA-I', 'COLLAGO', 'PANG-OT', 'POBLACION', 'PULOT'],
//   },
//   LANGIDEN: {
//     province: 'ABRA',
//     municipality: 'LANGIDEN',
//     region: 'CAR',
//     barangay_list: [
//       'BAAC',
//       'DALAYAP (NALAAS)',
//       'MABUNGTOT',
//       'MALAPAAO',
//       'POBLACION',
//       'QUILLAT',
//     ],
//   },
//   'LICUAN-BAAY (LICUAN)': {
//     province: 'ABRA',
//     municipality: 'LICUAN-BAAY (LICUAN)',
//     region: 'CAR',
//     barangay_list: [
//       'BONGLO (PATAGUI)',
//       'BULBULALA',
//       'CAWAYAN',
//       'DOMENGLAY',
//       'LENNENG',
//       'MAPISLA',
//       'MOGAO',
//       'NALBUAN',
//       'POBLACION',
//       'SUBAGAN',
//       'TUMALIP',
//     ],
//   },
//   LUBA: {
//     province: 'ABRA',
//     municipality: 'LUBA',
//     region: 'CAR',
//     barangay_list: [
//       'AMPALIOC',
//       'BARIT',
//       'GAYAMAN',
//       'LUL-LUNO',
//       'LUZONG',
//       'NAGBUKEL-TUQUIPA',
//       'POBLACION',
//       'SABNANGAN',
//     ],
//   },
//   MALIBCONG: {
//     province: 'ABRA',
//     municipality: 'MALIBCONG',
//     region: 'CAR',
//     barangay_list: [
//       'BAYABAS',
//       'BINASARAN',
//       'BUANAO',
//       'DULAO',
//       'DULDULAO',
//       'GACAB',
//       'LAT-EY',
//       'MALIBCONG (POB.)',
//       'MATARAGAN',
//       'PACGUED',
//       'TARIPAN',
//       'UMNAP',
//     ],
//   },
//   MANABO: {
//     province: 'ABRA',
//     municipality: 'MANABO',
//     region: 'CAR',
//     barangay_list: [
//       'AYYENG (POB.)',
//       'CATACDEGAN NUEVO',
//       'CATACDEGAN VIEJO',
//       'LUZONG',
//       'SAN JOSE NORTE',
//       'SAN JOSE SUR',
//       'SAN JUAN NORTE',
//       'SAN JUAN SUR',
//       'SAN RAMON EAST',
//       'SAN RAMON WEST',
//       'SANTO TOMAS',
//     ],
//   },
//   PEÑARRUBIA: {
//     province: 'ABRA',
//     municipality: 'PEÑARRUBIA',
//     region: 'CAR',
//     barangay_list: [
//       'DUMAYCO',
//       'LUSUAC',
//       'MALAMSIT (PAU-MALAMSIT)',
//       'NAMARABAR',
//       'PATIAO',
//       'POBLACION',
//       'RIANG (TIANG)',
//       'SANTA ROSA',
//       'TATTAWA',
//     ],
//   },
//   PIDIGAN: {
//     province: 'ABRA',
//     municipality: 'PIDIGAN',
//     region: 'CAR',
//     barangay_list: [
//       'ALINAYA',
//       'ARAB',
//       'GARRETA',
//       'IMMULI',
//       'LASKIG',
//       'MONGGOC',
//       'NAGUIRAYAN',
//       'PAMUTIC',
//       'PANGTUD',
//       'POBLACION EAST',
//       'POBLACION WEST',
//       'SAN DIEGO',
//       'SULBEC',
//       'SUYO (MALIDONG)',
//       'YUYENG',
//     ],
//   },
//   SALLAPADAN: {
//     province: 'ABRA',
//     municipality: 'SALLAPADAN',
//     region: 'CAR',
//     barangay_list: [
//       'BAZAR',
//       'BILABILA',
//       'GANGAL (POB.)',
//       'MAGUYEPYEP',
//       'NAGUILIAN',
//       'SACCAANG',
//       'SALLAPADAN',
//       'SUBUSOB',
//       'UD-UDIAO',
//     ],
//   },
//   TAYUM: {
//     province: 'ABRA',
//     municipality: 'TAYUM',
//     region: 'CAR',
//     barangay_list: [
//       'BAGALAY',
//       'BASBASA',
//       'BUDAC',
//       'BUMAGCAT',
//       'CABAROAN',
//       'DEET',
//       'GADDANI',
//       'PATUCANNAY',
//       'PIAS',
//       'POBLACION',
//       'VELASCO',
//     ],
//   },
//   TINEG: {
//     province: 'ABRA',
//     municipality: 'TINEG',
//     region: 'CAR',
//     barangay_list: [
//       'ALAOA',
//       'ANAYAN',
//       'APAO',
//       'BELAAT',
//       'CAGANAYAN',
//       'COGON',
//       'LANEC',
//       'LAPAT-BALANTAY',
//       'NAGLIBACAN',
//       'POBLACION (AGSIMAO)',
//     ],
//   },
//   TUBO: {
//     province: 'ABRA',
//     municipality: 'TUBO',
//     region: 'CAR',
//     barangay_list: [
//       'ALANGTIN',
//       'AMTUAGAN',
//       'DILONG',
//       'KILI',
//       'POBLACION (MAYABO)',
//       'SUPO',
//       'TABACDA',
//       'TIEMPO',
//       'TUBTUBA',
//       'WAYANGAN',
//     ],
//   },
//   VILLAVICIOSA: {
//     province: 'ABRA',
//     municipality: 'VILLAVICIOSA',
//     region: 'CAR',
//     barangay_list: [
//       'AP-APAYA',
//       'BOL-LILISING',
//       'CAL-LAO',
//       'LAP-LAPOG',
//       'LUMABA',
//       'POBLACION',
//       'TAMAC',
//       'TUQUIB',
//     ],
//   },
//   'CALANASAN (BAYAG)': {
//     province: 'APAYAO',
//     municipality: 'CALANASAN (BAYAG)',
//     region: 'CAR',
//     barangay_list: [
//       'BUTAO',
//       'CADACLAN',
//       'DON ROQUE ABLAN SR.',
//       'ELEAZAR',
//       'EVA PUZON',
//       'KABUGAWAN',
//       'LANGNAO',
//       'LUBONG',
//       'MACALINO',
//       'NAGUILIAN',
//       'NAMALTUGAN',
//       'POBLACION',
//       'SABANGAN',
//       'SANTA ELENA',
//       'SANTA FILOMENA',
//       'TANGLAGAN',
//       'TUBANG',
//       'TUBONGAN',
//     ],
//   },
//   CONNER: {
//     province: 'APAYAO',
//     municipality: 'CONNER',
//     region: 'CAR',
//     barangay_list: [
//       'ALLANGIGAN',
//       'BANBAN',
//       'BULUAN',
//       'CAGLAYAN (NEW POB.)',
//       'CALAFUG',
//       'CUPIS',
//       'DAGA',
//       'GUINAANG',
//       'GUINAMGAMAN',
//       'ILI',
//       'KARIKITAN',
//       'KATABLANGAN',
//       'MALAMA',
//       'MANAG',
//       'MAWEGUI',
//       'NABUANGAN',
//       'PADDAOAN',
//       'PUGUIN',
//       'RIPANG (OLD POB.)',
//       'SACPIL',
//       'TALIFUGO',
//     ],
//   },
//   FLORA: {
//     province: 'APAYAO',
//     municipality: 'FLORA',
//     region: 'CAR',
//     barangay_list: [
//       'ALLIG',
//       'ANNINIPAN',
//       'ATOK',
//       'BAGUTONG',
//       'BALASI',
//       'BALLUYAN',
//       'MALAYUGAN',
//       'MALLIG',
//       'MALUBIBIT NORTE',
//       'MALUBIBIT SUR',
//       'POBLACION EAST',
//       'POBLACION WEST',
//       'SAN JOSE',
//       'SANTA MARIA',
//       'TAMALUNOG',
//       'UPPER ATOK (COLIMAN)',
//     ],
//   },
//   KABUGAO: {
//     province: 'APAYAO',
//     municipality: 'KABUGAO',
//     region: 'CAR',
//     barangay_list: [
//       'BADDUAT',
//       'BALIWANAN',
//       'BULU',
//       'CABETAYAN',
//       'DAGARA',
//       'DIBAGAT',
//       'KARAGAWAN',
//       'KUMAO',
//       'LACO',
//       'LENNENG (LIYYENG)',
//       'LUCAB',
//       'LUTTUACAN',
//       'MADATAG',
//       'MADDUANG',
//       'MAGABTA',
//       'MARAGAT',
//       'MUSIMUT',
//       'NAGBABALAYAN',
//       'POBLACION',
//       'TUYANGAN',
//       'WAGA',
//     ],
//   },
//   PUDTOL: {
//     province: 'APAYAO',
//     municipality: 'PUDTOL',
//     region: 'CAR',
//     barangay_list: [
//       'AGA',
//       'ALEM',
//       'AMADO',
//       'AURORA',
//       'CABATACAN',
//       'CACALAGGAN',
//       'CAPANNIKIAN',
//       'DOÑA LORETA',
//       'EMILIA',
//       'IMELDA',
//       'LOWER MATON',
//       'LT. BILAG',
//       'LYDIA',
//       'MALIBANG',
//       'MATAGUISI',
//       'POBLACION',
//       'SAN ANTONIO (PUGO)',
//       'SAN JOSE',
//       'SAN LUIS',
//       'SAN MARIANO',
//       'SWAN',
//       'UPPER MATON',
//     ],
//   },
//   'SANTA MARCELA': {
//     province: 'APAYAO',
//     municipality: 'SANTA MARCELA',
//     region: 'CAR',
//     barangay_list: [
//       'BAROCBOC',
//       'CONSUELO',
//       'EMILIANA',
//       'IMELDA (SIPA ANNEX)',
//       'MALEKKEG',
//       'MARCELA (POB.)',
//       'NUEVA',
//       'PANAY',
//       'SAN ANTONIO',
//       'SAN CARLOS',
//       'SAN JUAN',
//       'SAN MARIANO',
//       'SIPA PROPER',
//     ],
//   },
//   ATOK: {
//     province: 'BENGUET',
//     municipality: 'ATOK',
//     region: 'CAR',
//     barangay_list: [
//       'ABIANG',
//       'CALIKING',
//       'CATTUBO',
//       'NAGUEY',
//       'PAOAY',
//       'PASDONG',
//       'POBLACION',
//       'TOPDAC',
//     ],
//   },
//   'BAGUIO': {
//     province: 'BENGUET',
//     municipality: 'BAGUIO',
//     region: 'CAR',
//     barangay_list: [
//       'A. BONIFACIO-CAGUIOA-RIMANDO (ABCR)',
//       'ABANAO-ZANDUETA-KAYANG-CHUGUM-OTEK (AZKCO)',
//       'ALFONSO TABORA',
//       'AMBIONG',
//       'ANDRES BONIFACIO (LOWER BOKAWKAN)',
//       'APUGAN-LOAKAN',
//       'ASIN ROAD',
//       'ATOK TRAIL',
//       'AURORA HILL PROPER (MALVAR-SGT. FLORESCA)',
//       'AURORA HILL, NORTH CENTRAL',
//       'AURORA HILL, SOUTH CENTRAL',
//       'BAKAKENG CENTRAL',
//       'BAKAKENG NORTH',
//       'BAL-MARCOVILLE (MARCOVILLE)',
//       'BALSIGAN',
//       'BAYAN PARK EAST',
//       'BAYAN PARK VILLAGE',
//       'BAYAN PARK WEST (BAYAN PARK)',
//       'BGH COMPOUND',
//       'BROOKSIDE',
//       'BROOKSPOINT',
//       "CABINET HILL-TEACHER'S CAMP",
//       'CAMDAS SUBDIVISION',
//       'CAMP 7',
//       'CAMP 8',
//       'CAMP ALLEN',
//       'CAMPO FILIPINO',
//       'CITY CAMP CENTRAL',
//       'CITY CAMP PROPER',
//       'COUNTRY CLUB VILLAGE',
//       'CRESENCIA VILLAGE',
//       'DAGSIAN, LOWER',
//       'DAGSIAN, UPPER',
//       'DIZON SUBDIVISION',
//       'DOMINICAN HILL-MIRADOR',
//       'DONTOGAN',
//       'DPS AREA',
//       "ENGINEERS' HILL",
//       'FAIRVIEW VILLAGE',
//       'FERDINAND (HAPPY HOMES-CAMPO SIOCO)',
//       'FORT DEL PILAR',
//       'GABRIELA SILANG',
//       'GENERAL EMILIO F. AGUINALDO (QUIRINO-MAGSAYSAY, LOWER)',
//       'GENERAL LUNA, LOWER',
//       'GENERAL LUNA, UPPER',
//       'GIBRALTAR',
//       'GREENWATER VILLAGE',
//       'GUISAD CENTRAL',
//       'GUISAD SORONG',
//       'HAPPY HOLLOW',
//       'HAPPY HOMES (HAPPY HOMES-LUCBAN)',
//       'HARRISON-CLAUDIO CARANTES',
//       'HILLSIDE',
//       'HOLY GHOST EXTENSION',
//       'HOLY GHOST PROPER',
//       'HONEYMOON (HONEYMOON-HOLY GHOST)',
//       'IMELDA R. MARCOS (LA SALLE)',
//       'IMELDA VILLAGE',
//       'IRISAN',
//       'KABAYANIHAN',
//       'KAGITINGAN',
//       'KAYANG EXTENSION',
//       'KAYANG-HILLTOP',
//       'KIAS',
//       'LEGARDA-BURNHAM-KISAD',
//       'LIWANAG-LOAKAN',
//       'LOAKAN PROPER',
//       'LOPEZ JAENA',
//       'LOURDES SUBDIVISION EXTENSION',
//       'LOURDES SUBDIVISION, LOWER',
//       'LOURDES SUBDIVISION, PROPER',
//       'LUALHATI',
//       'LUCNAB',
//       'MAGSAYSAY PRIVATE ROAD',
//       'MAGSAYSAY, LOWER',
//       'MAGSAYSAY, UPPER',
//       'MALCOLM SQUARE-PERFECTO (JOSE ABAD SANTOS)',
//       'MANUEL A. ROXAS',
//       'MARKET SUBDIVISION, UPPER',
//       'MIDDLE QUEZON HILL SUBDIVISION(QUEZON HILL MIDDLE)',
//       'MILITARY CUT-OFF',
//       'MINES VIEW PARK',
//       'MODERN SITE, EAST',
//       'MODERN SITE, WEST',
//       'MRR-QUEEN OF PEACE',
//       'NEW LUCBAN',
//       'OUTLOOK DRIVE',
//       'PACDAL',
//       'PADRE BURGOS',
//       'PADRE ZAMORA',
//       'PALMA-URBANO (CARIÑO-PALMA)',
//       'PHIL-AM',
//       'PINGET',
//       'PINSAO PILOT PROJECT',
//       'PINSAO PROPER',
//       'POLIWES',
//       'PUCSUSAN',
//       'QUEZON HILL PROPER',
//       'QUEZON HILL, UPPER',
//       'QUIRINO HILL, EAST',
//       'QUIRINO HILL, LOWER',
//       'QUIRINO HILL, MIDDLE',
//       'QUIRINO HILL, WEST',
//       'QUIRINO-MAGSAYSAY, UPPER (UPPER QM)',
//       'RIZAL MONUMENT AREA',
//       'ROCK QUARRY, LOWER',
//       'ROCK QUARRY, MIDDLE',
//       'ROCK QUARRY, UPPER',
//       'SAINT JOSEPH VILLAGE',
//       'SALUD MITRA',
//       'SAN ANTONIO VILLAGE',
//       'SAN LUIS VILLAGE',
//       'SAN ROQUE VILLAGE',
//       'SAN VICENTE',
//       'SANITARY CAMP, NORTH',
//       'SANITARY CAMP, SOUTH',
//       'SANTA ESCOLASTICA',
//       'SANTO ROSARIO',
//       'SANTO TOMAS PROPER',
//       'SANTO TOMAS SCHOOL AREA',
//       'SCOUT BARRIO',
//       'SESSION ROAD AREA',
//       'SLAUGHTER HOUSE AREA (SANTO NIÑO SLAUGHTER)',
//       'SLU-SVP HOUSING VILLAGE',
//       'SOUTH DRIVE',
//       'TEODORA ALONZO',
//       'TRANCOVILLE',
//       'VICTORIA VILLAGE',
//     ],
//   },
//   BAKUN: {
//     province: 'BENGUET',
//     municipality: 'BAKUN',
//     region: 'CAR',
//     barangay_list: [
//       'AMPUSONGAN',
//       'BAGU',
//       'DALIPEY',
//       'GAMBANG',
//       'KAYAPA',
//       'POBLACION (CENTRAL)',
//       'SINACBAT',
//     ],
//   },
//   BOKOD: {
//     province: 'BENGUET',
//     municipality: 'BOKOD',
//     region: 'CAR',
//     barangay_list: [
//       'AMBUCLAO',
//       'BILA',
//       'BOBOK-BISAL',
//       'DACLAN',
//       'EKIP',
//       'KARAO',
//       'NAWAL',
//       'PITO',
//       'POBLACION',
//       'TIKEY',
//     ],
//   },
//   BUGUIAS: {
//     province: 'BENGUET',
//     municipality: 'BUGUIAS',
//     region: 'CAR',
//     barangay_list: [
//       'ABATAN',
//       'AMGALEYGUEY',
//       'AMLIMAY',
//       'BACULONGAN NORTE',
//       'BACULONGAN SUR',
//       'BANGAO',
//       'BUYACAOAN',
//       'CALAMAGAN',
//       'CATLUBONG',
//       'LENGAOAN',
//       'LOO',
//       'NATUBLENG',
//       'POBLACION (CENTRAL)',
//       'SEBANG',
//     ],
//   },
//   ITOGON: {
//     province: 'BENGUET',
//     municipality: 'ITOGON',
//     region: 'CAR',
//     barangay_list: [
//       'AMPUCAO',
//       'DALUPIRIP',
//       'GUMATDANG',
//       'LOACAN',
//       'POBLACION (CENTRAL)',
//       'TINONGDAN',
//       'TUDING',
//       'UCAB',
//       'VIRAC',
//     ],
//   },
//   KABAYAN: {
//     province: 'BENGUET',
//     municipality: 'KABAYAN',
//     region: 'CAR',
//     barangay_list: [
//       'ADAOAY',
//       'ANCHUKEY',
//       'BALLAY',
//       'BASHOY',
//       'BATAN',
//       'DUACAN',
//       'EDDET',
//       'GUSARAN',
//       'KABAYAN BARRIO',
//       'LUSOD',
//       'PACSO',
//       'POBLACION (CENTRAL)',
//       'TAWANGAN',
//     ],
//   },
//   KAPANGAN: {
//     province: 'BENGUET',
//     municipality: 'KAPANGAN',
//     region: 'CAR',
//     barangay_list: [
//       'BALAKBAK',
//       'BELENG-BELIS',
//       'BOKLAOAN',
//       'CAYAPES',
//       'CUBA',
//       'DATAKAN',
//       'GADANG',
//       'GASWELING',
//       'LABUEG',
//       'PAYKEK',
//       'POBLACION CENTRAL',
//       'PONGAYAN',
//       'PUDONG',
//       'SAGUBO',
//       'TABA-AO',
//     ],
//   },
//   KIBUNGAN: {
//     province: 'BENGUET',
//     municipality: 'KIBUNGAN',
//     region: 'CAR',
//     barangay_list: [
//       'BADEO',
//       'LUBO',
//       'MADAYMEN',
//       'PALINA',
//       'POBLACION',
//       'SAGPAT',
//       'TACADANG',
//     ],
//   },
//   'LA TRINIDAD': {
//     province: 'BENGUET',
//     municipality: 'LA TRINIDAD',
//     region: 'CAR',
//     barangay_list: [
//       'ALAPANG',
//       'ALNO',
//       'AMBIONG',
//       'BAHONG',
//       'BALILI',
//       'BECKEL',
//       'BETAG',
//       'BINENG',
//       'CRUZ',
//       'LUBAS',
//       'PICO',
//       'POBLACION',
//       'PUGUIS',
//       'SHILAN',
//       'TAWANG',
//       'WANGAL',
//     ],
//   },
//   MANKAYAN: {
//     province: 'BENGUET',
//     municipality: 'MANKAYAN',
//     region: 'CAR',
//     barangay_list: [
//       'BALILI',
//       'BEDBED',
//       'BULALACAO',
//       'CABITEN',
//       'COLALO',
//       'GUINAOANG',
//       'PACO',
//       'PALASAAN',
//       'POBLACION',
//       'SAPID',
//       'TABIO',
//       'TANEG',
//     ],
//   },
//   SABLAN: {
//     province: 'BENGUET',
//     municipality: 'SABLAN',
//     region: 'CAR',
//     barangay_list: [
//       'BAGONG',
//       'BALLUAY',
//       'BANANGAN',
//       'BANENGBENG',
//       'BAYABAS',
//       'KAMOG',
//       'PAPPA',
//       'POBLACION',
//     ],
//   },
//   TUBA: {
//     province: 'BENGUET',
//     municipality: 'TUBA',
//     region: 'CAR',
//     barangay_list: [
//       'ANSAGAN',
//       'CAMP 3',
//       'CAMP 4',
//       'CAMP ONE',
//       'NANGALISAN',
//       'POBLACION',
//       'SAN PASCUAL',
//       'TABAAN NORTE',
//       'TABAAN SUR',
//       'TADIANGAN',
//       'TALOY NORTE',
//       'TALOY SUR',
//       'TWIN PEAKS',
//     ],
//   },
//   TUBLAY: {
//     province: 'BENGUET',
//     municipality: 'TUBLAY',
//     region: 'CAR',
//     barangay_list: [
//       'AMBASSADOR',
//       'AMBONGDOLAN',
//       'BA-AYAN',
//       'BASIL',
//       'CAPONGA (POB.)',
//       'DACLAN',
//       'TUBLAY CENTRAL',
//       'TUEL',
//     ],
//   },
//   AGUINALDO: {
//     province: 'IFUGAO',
//     municipality: 'AGUINALDO',
//     region: 'CAR',
//     barangay_list: [
//       'AWAYAN',
//       'BUNHIAN',
//       'BUTAC',
//       'BUWAG',
//       'CHALALO',
//       'DAMAG',
//       'GALONOGON',
//       'HALAG',
//       'ITAB',
//       'JACMAL',
//       'MAJLONG',
//       'MONGAYANG',
//       'POSNAAN',
//       'TA-ANG',
//       'TALITE',
//       'UBAO',
//     ],
//   },
//   'ALFONSO LISTA (POTIA)': {
//     province: 'IFUGAO',
//     municipality: 'ALFONSO LISTA (POTIA)',
//     region: 'CAR',
//     barangay_list: [
//       'BANGAR',
//       'BUSILAC',
//       'CALIMAG',
//       'CALUPAAN',
//       'CARAGASAN',
//       'DOLOWOG',
//       'KILING',
//       'LAYA',
//       'LITTLE TADIAN',
//       'NAMILLANGAN',
//       'NAMNAMA',
//       'NGILEB',
//       'PINTO',
//       'POBLACION',
//       'SAN JOSE',
//       'SAN JUAN',
//       'SAN MARCOS',
//       'SAN QUINTIN',
//       'SANTA MARIA',
//       'SANTO DOMINGO (CABICALAN)',
//     ],
//   },
//   ASIPULO: {
//     province: 'IFUGAO',
//     municipality: 'ASIPULO',
//     region: 'CAR',
//     barangay_list: [
//       'AMDUNTOG',
//       'ANTIPOLO',
//       'CAMANDAG',
//       'CAWAYAN',
//       'HALLAP',
//       'LIWON',
//       'NAMAL',
//       'NUNGAWA',
//       'PANUBTUBAN',
//       'PULA',
//     ],
//   },
//   BANAUE: {
//     province: 'IFUGAO',
//     municipality: 'BANAUE',
//     region: 'CAR',
//     barangay_list: [
//       'AMGANAD',
//       'ANABA',
//       'BALAWIS',
//       'BANAO',
//       'BANGAAN',
//       'BATAD',
//       'BOCOS',
//       'CAMBULO',
//       'DUCLIGAN',
//       'GOHANG',
//       'KINAKIN',
//       'OHAJ',
//       'POBLACION',
//       'POITAN',
//       'PULA',
//       'SAN FERNANDO',
//       'TAM-AN',
//       'VIEW POINT',
//     ],
//   },
//   HINGYON: {
//     province: 'IFUGAO',
//     municipality: 'HINGYON',
//     region: 'CAR',
//     barangay_list: [
//       'ANAO',
//       'BANGTINON',
//       'BITU',
//       'CABABUYAN',
//       'MOMPOLIA',
//       'NAMULDITAN',
//       'NORTHERN CABABUYAN',
//       'O-ONG',
//       'PIWONG',
//       'POBLACION (HINGYON)',
//       'UBUAG',
//       'UMALBONG',
//     ],
//   },
//   HUNGDUAN: {
//     province: 'IFUGAO',
//     municipality: 'HUNGDUAN',
//     region: 'CAR',
//     barangay_list: [
//       'ABATAN',
//       'BA-ANG',
//       'BANGBANG',
//       'BOKIAWAN',
//       'HAPAO',
//       'LUBO-ONG',
//       'MAGGOK',
//       'NUNGULUNAN',
//       'POBLACION',
//     ],
//   },
//   KIANGAN: {
//     province: 'IFUGAO',
//     municipality: 'KIANGAN',
//     region: 'CAR',
//     barangay_list: [
//       'AMBABAG',
//       'BAGUINGE',
//       'BOKIAWAN',
//       'BOLOG',
//       'DALLIGAN',
//       'DUIT',
//       'HUCAB',
//       'JULONGAN',
//       'LINGAY',
//       'MUNGAYANG',
//       'NAGACADAN',
//       'PINDONGAN',
//       'POBLACION',
//       'TUPLAC',
//     ],
//   },
//   LAGAWE: {
//     province: 'IFUGAO',
//     municipality: 'LAGAWE',
//     region: 'CAR',
//     barangay_list: [
//       'ABINUAN',
//       'BANGA',
//       'BOLIWONG',
//       'BURNAY',
//       'BUYABUYAN',
//       'CABA',
//       'CUDOG',
//       'DULAO',
//       'JUCBONG',
//       'LUTA',
//       'MONTABIONG',
//       'OLILICON',
//       'POBLACION EAST',
//       'POBLACION NORTH',
//       'POBLACION SOUTH',
//       'POBLACION WEST',
//       'PONGHAL',
//       'PULLAAN',
//       'TUNGNGOD',
//       'TUPAYA',
//     ],
//   },
//   LAMUT: {
//     province: 'IFUGAO',
//     municipality: 'LAMUT',
//     region: 'CAR',
//     barangay_list: [
//       'AMBASA',
//       'BIMPAL',
//       'HAPID',
//       'HOLOWON',
//       'LAWIG',
//       'LUCBAN',
//       'MABATOBATO (LAMUT)',
//       'MAGULON',
//       'NAYON',
//       'PANOPDOPAN',
//       'PAYAWAN',
//       'PIEZA',
//       'POBLACION EAST',
//       'POBLACION WEST',
//       'PUGOL (IFUGAO RESERVATION)',
//       'SALAMAGUE',
//       'SANAFE',
//       'UMILAG',
//     ],
//   },
//   MAYOYAO: {
//     province: 'IFUGAO',
//     municipality: 'MAYOYAO',
//     region: 'CAR',
//     barangay_list: [
//       'ADUYONGAN',
//       'ALIMIT',
//       'AYANGAN',
//       'BALANGBANG',
//       'BANAO',
//       'BANHAL',
//       'BATO-ALATBANG',
//       'BONGAN',
//       'BUNINAN',
//       'CHAYA',
//       'CHUMANG',
//       'EPENG',
//       'GUINIHON',
//       'INWALOY',
//       'LANGAYAN',
//       'LIWO',
//       'MAGA',
//       'MAGULON',
//       'MAPAWOY',
//       'MAYOYAO PROPER',
//       'MONGOL',
//       'NALBU',
//       'NATTUM',
//       'PALAAD',
//       'POBLACION',
//       'TALBOC',
//       'TULAED',
//     ],
//   },
//   TINOC: {
//     province: 'IFUGAO',
//     municipality: 'TINOC',
//     region: 'CAR',
//     barangay_list: [
//       'AHIN',
//       'AP-APID',
//       'BINABLAYAN',
//       'DANGGO',
//       'EHEB',
//       'GUMHANG',
//       'IMPUGONG',
//       'LUHONG',
//       'TINOC',
//       'TUKUCAN',
//       'TULLUDAN',
//       'WANGWANG',
//     ],
//   },
//   BALBALAN: {
//     province: 'KALINGA',
//     municipality: 'BALBALAN',
//     region: 'CAR',
//     barangay_list: [
//       'ABABA-AN',
//       'BALANTOY',
//       'BALBALAN PROPER',
//       'BALBALASANG',
//       'BUAYA',
//       'DAO-ANGAN',
//       'GAWA-AN',
//       'MABACA',
//       'MALING (KABUGAO)',
//       'PANTIKIAN',
//       'POBLACION (SALEGSEG)',
//       'POSWOY',
//       'TALALANG',
//       'TAWANG',
//     ],
//   },
//   LUBUAGAN: {
//     province: 'KALINGA',
//     municipality: 'LUBUAGAN',
//     region: 'CAR',
//     barangay_list: [
//       'ANTONIO CANAO',
//       'DANGOY',
//       'LOWER UMA',
//       'MABILONG',
//       'MABONGTOT',
//       'POBLACION',
//       'TANGLAG',
//       'UMA DEL NORTE (WESTERN LUNA UMA)',
//       'UPPER UMA',
//     ],
//   },
//   PASIL: {
//     province: 'KALINGA',
//     municipality: 'PASIL',
//     region: 'CAR',
//     barangay_list: [
//       'ABLEG',
//       'BAGTAYAN',
//       'BALATOC',
//       'BALENCIAGAO SUR',
//       'BALINCIAGAO NORTE',
//       'CAGALUAN',
//       'COLAYO',
//       'DALUPA',
//       'DANGTALAN',
//       'GALDANG (CASALOAN)',
//       'GUINA-ANG (POB.)',
//       'MAGSILAY',
//       'MALUCSAD',
//       'PUGONG',
//     ],
//   },
//   PINUKPUK: {
//     province: 'KALINGA',
//     municipality: 'PINUKPUK',
//     region: 'CAR',
//     barangay_list: [
//       'ACIGA',
//       'ALLAGUIA',
//       'AMMACIAN',
//       'APATAN',
//       'ASIBANGLAN',
//       'BA-AY',
//       'BALLAYANGON',
//       'BAYAO',
//       'CAMALOG',
//       'CAWAGAYAN',
//       'DUGPA',
//       'KATABBOGAN',
//       'LIMOS',
//       'MAGAOGAO',
//       'MALAGNAT',
//       'MAPACO',
//       'PAKAWIT',
//       'PINOCOC',
//       'PINUKPUK JUNCTION',
//       'SOCBOT',
//       'TAGA (POB.)',
//       'TAGGAY',
//       'WAGUD',
//     ],
//   },
//   'RIZAL (LIWAN)': {
//     province: 'KALINGA',
//     municipality: 'RIZAL (LIWAN)',
//     region: 'CAR',
//     barangay_list: [
//       'BABALAG EAST (POB.)',
//       'BABALAG WEST (POB.)',
//       'BULBOL',
//       'CALAOCAN',
//       'KINAMA',
//       'LIWAN EAST',
//       'LIWAN WEST',
//       'MACUTAY',
//       'ROMUALDEZ',
//       'SAN FRANCISCO',
//       'SAN PASCUAL',
//       'SAN PEDRO',
//       'SAN QUINTIN',
//       'SANTOR',
//     ],
//   },
//   'TABUK': {
//     province: 'KALINGA',
//     municipality: 'TABUK',
//     region: 'CAR',
//     barangay_list: [
//       'AGBANNAWAG',
//       'AMLAO',
//       'APPAS',
//       'BADO DANGWA',
//       'BAGUMBAYAN',
//       'BALAWAG',
//       'BALONG',
//       'BANTAY',
//       'BULANAO',
//       'BULANAO NORTE',
//       'BULO',
//       'CABARITAN',
//       'CABARUAN',
//       'CALACCAD',
//       'CALANAN',
//       'CASIGAYAN',
//       'CUDAL',
//       'DAGUPAN CENTRO (POB.)',
//       'DAGUPAN WESTE',
//       'DILAG',
//       'DUPAG',
//       'GOBGOB',
//       'GUILAYON',
//       'IPIL',
//       'LACNOG',
//       'LANNA',
//       'LAYA EAST',
//       'LAYA WEST',
//       'LUCOG',
//       'MAGNAO',
//       'MAGSAYSAY',
//       'MALALAO',
//       'MALIN-AWA',
//       'MASABLANG',
//       'NAMBARAN',
//       'NAMBUCAYAN',
//       'NANENG',
//       'NEW TANGLAG',
//       'SAN JUAN',
//       'SAN JULIAN',
//       'SUYANG',
//       'TUGA',
//     ],
//   },
//   TANUDAN: {
//     province: 'KALINGA',
//     municipality: 'TANUDAN',
//     region: 'CAR',
//     barangay_list: [
//       'ANGGACAN',
//       'ANGGACAN SUR',
//       'BABBANOY',
//       'DACALAN',
//       'DUPLIGAN',
//       'GAANG',
//       'LAY-ASAN',
//       'LOWER LUBO',
//       'LOWER MANGALI',
//       'LOWER TALOCTOC',
//       'MABACA',
//       'MANGALI CENTRO',
//       'PANGOL',
//       'POBLACION',
//       'UPPER LUBO',
//       'UPPER TALOCTOC',
//     ],
//   },
//   TINGLAYAN: {
//     province: 'KALINGA',
//     municipality: 'TINGLAYAN',
//     region: 'CAR',
//     barangay_list: [
//       'AMBATO LEGLEG',
//       'BANGAD CENTRO',
//       'BASAO',
//       'BELONG MANUBAL',
//       'BUGNAY',
//       'BUSCALAN (BUSCALAN-LOCONG)',
//       'BUTBUT (BUTBUT-NGIBAT)',
//       'DANANAO',
//       'LOCCONG',
//       'LOWER BANGAD',
//       'LUPLUPA',
//       'MALLANGO',
//       'NGIBAT',
//       'OLD TINGLAYAN',
//       'POBLACION',
//       'SUMADEL 1',
//       'SUMADEL 2',
//       'TULGAO EAST',
//       'TULGAO WEST',
//       'UPPER BANGAD',
//     ],
//   },
//   BARLIG: {
//     province: 'MOUNTAIN PROVINCE',
//     municipality: 'BARLIG',
//     region: 'CAR',
//     barangay_list: [
//       'CHUPAC',
//       'FIANGTIN',
//       'GAWANA (POB.)',
//       'KALEO',
//       'LATANG',
//       'LIAS KANLURAN',
//       'LIAS SILANGAN',
//       'LINGOY',
//       'LUNAS',
//       'MACALANA',
//       'OGOOG',
//     ],
//   },
//   BAUKO: {
//     province: 'MOUNTAIN PROVINCE',
//     municipality: 'BAUKO',
//     region: 'CAR',
//     barangay_list: [
//       'ABATAN',
//       'BAGNEN ORIENTE',
//       'BAGNEN PROPER',
//       'BALINTAUGAN',
//       'BANAO',
//       'BILA (BUA)',
//       'GUINZADAN CENTRAL',
//       'GUINZADAN NORTE',
//       'GUINZADAN SUR',
//       'LAGAWA',
//       'LESEB',
//       'MABAAY',
//       'MAYAG',
//       'MONAMON NORTE',
//       'MONAMON SUR',
//       'MOUNT DATA',
//       'OTUCAN NORTE',
//       'OTUCAN SUR',
//       'POBLACION (BAUKO)',
//       'SADSADAN',
//       'SINTO',
//       'TAPAPAN',
//     ],
//   },
//   BESAO: {
//     province: 'MOUNTAIN PROVINCE',
//     municipality: 'BESAO',
//     region: 'CAR',
//     barangay_list: [
//       'AGAWA',
//       'AMBAGUIO',
//       'BANGUITAN',
//       'BESAO EAST (BESAO PROPER)',
//       'BESAO WEST',
//       'CATENGAN',
//       'GUEDAY',
//       'KIN-IWAY (POB.)',
//       'LACMAAN',
//       'LAYLAYA',
//       'PANDANGAN',
//       'PAYEO',
//       'SUQUIB',
//       'TAMBOAN',
//     ],
//   },
//   NATONIN: {
//     province: 'MOUNTAIN PROVINCE',
//     municipality: 'NATONIN',
//     region: 'CAR',
//     barangay_list: [
//       'ALUNOGAN',
//       'BALANGAO',
//       'BANAO',
//       'BANAWAL',
//       'BUTAC',
//       'MADUCAYAN',
//       'POBLACION',
//       'PUDO',
//       'SALIOK',
//       'SANTA ISABEL',
//       'TONGLAYAN',
//     ],
//   },
//   PARACELIS: {
//     province: 'MOUNTAIN PROVINCE',
//     municipality: 'PARACELIS',
//     region: 'CAR',
//     barangay_list: [
//       'ANONAT',
//       'BACARNI',
//       'BANANAO',
//       'BANTAY',
//       'BUNOT',
//       'BURINGAL',
//       'BUTIGUE',
//       'PALITOD',
//       'POBLACION',
//     ],
//   },
//   SABANGAN: {
//     province: 'MOUNTAIN PROVINCE',
//     municipality: 'SABANGAN',
//     region: 'CAR',
//     barangay_list: [
//       'BAO-ANGAN',
//       'BUN-AYAN',
//       'BUSA',
//       'CAMATAGAN',
//       'CAPINITAN',
//       'DATA',
//       'GAYANG',
//       'LAGAN',
//       'LOSAD',
//       'NAMATEC',
//       'NAPUA',
//       'PINGAD',
//       'POBLACION',
//       'SUPANG',
//       'TAMBINGAN',
//     ],
//   },
//   SADANGA: {
//     province: 'MOUNTAIN PROVINCE',
//     municipality: 'SADANGA',
//     region: 'CAR',
//     barangay_list: [
//       'ANABEL',
//       'BEKIGAN',
//       'BELWANG',
//       'BETWAGAN',
//       'DEMANG',
//       'POBLACION',
//       'SACASACAN',
//       'SACLIT',
//     ],
//   },
//   SAGADA: {
//     province: 'MOUNTAIN PROVINCE',
//     municipality: 'SAGADA',
//     region: 'CAR',
//     barangay_list: [
//       'AGUID',
//       'AMBASING',
//       'ANGKELING',
//       'ANTADAO',
//       'BALUGAN',
//       'BANGAAN',
//       'DAGDAG (POB.)',
//       'DEMANG (POB.)',
//       'FIDELISAN',
//       'KILONG',
//       'MADONGO',
//       'NACAGANG',
//       'PIDE',
//       'POBLACION (PATAY)',
//       'SUYO',
//       'TACCONG',
//       'TANULONG',
//       'TETEPAN NORTE',
//       'TETEPAN SUR',
//     ],
//   },
//   TADIAN: {
//     province: 'MOUNTAIN PROVINCE',
//     municipality: 'TADIAN',
//     region: 'CAR',
//     barangay_list: [
//       'BALAOA',
//       'BANAAO',
//       'BANTEY',
//       'BATAYAN',
//       'BUNGA',
//       'CADAD-ANAN',
//       'CAGUBATAN',
//       'DACUDAC',
//       'DUAGAN',
//       'KAYAN EAST',
//       'KAYAN WEST',
//       'LENGA',
//       'LUBON',
//       'MABALITE',
//       'MASLA',
//       'PANDAYAN',
//       'POBLACION',
//       'SUMADEL',
//       'TUE',
//     ],
//   },
//   'LAS PIÑAS': {
//     province: 'METRO MANILA',
//     municipality: 'LAS PIÑAS',
//     region: 'NCR',
//     barangay_list: [
//       'ALMANZA DOS',
//       'ALMANZA UNO',
//       'B. F. INTERNATIONAL VILLAGE',
//       'DANIEL FAJARDO',
//       'ELIAS ALDANA',
//       'ILAYA',
//       'MANUYO DOS',
//       'MANUYO UNO',
//       'PAMPLONA DOS',
//       'PAMPLONA TRES',
//       'PAMPLONA UNO',
//       'PILAR',
//       'PULANG LUPA DOS',
//       'PULANG LUPA UNO',
//       'TALON DOS',
//       'TALON KUATRO',
//       'TALON SINGKO',
//       'TALON TRES',
//       'TALON UNO',
//       'ZAPOTE',
//     ],
//   },
//   'MAKATI': {
//     province: 'METRO MANILA',
//     municipality: 'MAKATI',
//     region: 'NCR',
//     barangay_list: [
//       'BANGKAL',
//       'BEL-AIR',
//       'CARMONA',
//       'CEMBO',
//       'COMEMBO',
//       'DASMARIÑAS',
//       'EAST REMBO',
//       'FORBES PARK',
//       'GUADALUPE NUEVO',
//       'GUADALUPE VIEJO',
//       'KASILAWAN',
//       'LA PAZ',
//       'MAGALLANES',
//       'OLYMPIA',
//       'PALANAN',
//       'PEMBO',
//       'PINAGKAISAHAN',
//       'PIO DEL PILAR',
//       'PITOGO',
//       'POBLACION',
//       'POST PROPER NORTHSIDE',
//       'POST PROPER SOUTHSIDE',
//       'RIZAL',
//       'SAN ANTONIO',
//       'SAN ISIDRO',
//       'SAN LORENZO',
//       'SANTA CRUZ',
//       'SINGKAMAS',
//       'SOUTH CEMBO',
//       'TEJEROS',
//       'URDANETA',
//       'VALENZUELA',
//       'WEST REMBO',
//     ],
//   },
//   'MUNTINLUPA': {
//     province: 'METRO MANILA',
//     municipality: 'MUNTINLUPA',
//     region: 'NCR',
//     barangay_list: [
//       'ALABANG',
//       'BAYANAN',
//       'BULI',
//       'CUPANG',
//       'NEW ALABANG VILLAGE',
//       'POBLACION',
//       'PUTATAN',
//       'SUCAT',
//       'TUNASAN',
//     ],
//   },
//   'PARAÑAQUE': {
//     province: 'METRO MANILA',
//     municipality: 'PARAÑAQUE',
//     region: 'NCR',
//     barangay_list: [
//       'B. F. HOMES',
//       'BACLARAN',
//       'DON BOSCO',
//       'DON GALO',
//       'LA HUERTA',
//       'MARCELO GREEN VILLAGE',
//       'MERVILLE',
//       'MOONWALK',
//       'SAN ANTONIO',
//       'SAN DIONISIO',
//       'SAN ISIDRO',
//       'SAN MARTIN DE PORRES',
//       'SANTO NIÑO',
//       'SUN VALLEY',
//       'TAMBO',
//       'VITALEZ',
//     ],
//   },
//   'PASAY': {
//     province: 'METRO MANILA',
//     municipality: 'PASAY',
//     region: 'NCR',
//     barangay_list: [
//       'BARANGAY 1',
//       'BARANGAY 2',
//       'BARANGAY 3',
//       'BARANGAY 4',
//       'BARANGAY 5',
//       'BARANGAY 6',
//       'BARANGAY 7',
//       'BARANGAY 8',
//       'BARANGAY 9',
//       'BARANGAY 10',
//       'BARANGAY 11',
//       'BARANGAY 12',
//       'BARANGAY 13',
//       'BARANGAY 14',
//       'BARANGAY 15',
//       'BARANGAY 16',
//       'BARANGAY 17',
//       'BARANGAY 18',
//       'BARANGAY 19',
//       'BARANGAY 20',
//       'BARANGAY 21',
//       'BARANGAY 22',
//       'BARANGAY 23',
//       'BARANGAY 24',
//       'BARANGAY 25',
//       'BARANGAY 26',
//       'BARANGAY 27',
//       'BARANGAY 28',
//       'BARANGAY 29',
//       'BARANGAY 30',
//       'BARANGAY 31',
//       'BARANGAY 32',
//       'BARANGAY 33',
//       'BARANGAY 34',
//       'BARANGAY 35',
//       'BARANGAY 36',
//       'BARANGAY 37',
//       'BARANGAY 38',
//       'BARANGAY 39',
//       'BARANGAY 40',
//       'BARANGAY 41',
//       'BARANGAY 42',
//       'BARANGAY 43',
//       'BARANGAY 44',
//       'BARANGAY 45',
//       'BARANGAY 46',
//       'BARANGAY 47',
//       'BARANGAY 48',
//       'BARANGAY 49',
//       'BARANGAY 50',
//       'BARANGAY 51',
//       'BARANGAY 52',
//       'BARANGAY 53',
//       'BARANGAY 54',
//       'BARANGAY 55',
//       'BARANGAY 56',
//       'BARANGAY 57',
//       'BARANGAY 58',
//       'BARANGAY 59',
//       'BARANGAY 60',
//       'BARANGAY 61',
//       'BARANGAY 62',
//       'BARANGAY 63',
//       'BARANGAY 64',
//       'BARANGAY 65',
//       'BARANGAY 66',
//       'BARANGAY 67',
//       'BARANGAY 68',
//       'BARANGAY 69',
//       'BARANGAY 70',
//       'BARANGAY 71',
//       'BARANGAY 72',
//       'BARANGAY 73',
//       'BARANGAY 74',
//       'BARANGAY 75',
//       'BARANGAY 76',
//       'BARANGAY 77',
//       'BARANGAY 78',
//       'BARANGAY 79',
//       'BARANGAY 80',
//       'BARANGAY 81',
//       'BARANGAY 82',
//       'BARANGAY 83',
//       'BARANGAY 84',
//       'BARANGAY 85',
//       'BARANGAY 86',
//       'BARANGAY 87',
//       'BARANGAY 88',
//       'BARANGAY 89',
//       'BARANGAY 90',
//       'BARANGAY 91',
//       'BARANGAY 92',
//       'BARANGAY 93',
//       'BARANGAY 94',
//       'BARANGAY 95',
//       'BARANGAY 96',
//       'BARANGAY 97',
//       'BARANGAY 98',
//       'BARANGAY 99',
//       'BARANGAY 100',
//       'BARANGAY 101',
//       'BARANGAY 102',
//       'BARANGAY 103',
//       'BARANGAY 104',
//       'BARANGAY 105',
//       'BARANGAY 106',
//       'BARANGAY 107',
//       'BARANGAY 108',
//       'BARANGAY 109',
//       'BARANGAY 110',
//       'BARANGAY 111',
//       'BARANGAY 112',
//       'BARANGAY 113',
//       'BARANGAY 114',
//       'BARANGAY 115',
//       'BARANGAY 116',
//       'BARANGAY 117',
//       'BARANGAY 118',
//       'BARANGAY 119',
//       'BARANGAY 120',
//       'BARANGAY 121',
//       'BARANGAY 122',
//       'BARANGAY 123',
//       'BARANGAY 124',
//       'BARANGAY 125',
//       'BARANGAY 126',
//       'BARANGAY 127',
//       'BARANGAY 128',
//       'BARANGAY 129',
//       'BARANGAY 130',
//       'BARANGAY 131',
//       'BARANGAY 132',
//       'BARANGAY 133',
//       'BARANGAY 134',
//       'BARANGAY 135',
//       'BARANGAY 136',
//       'BARANGAY 137',
//       'BARANGAY 138',
//       'BARANGAY 139',
//       'BARANGAY 140',
//       'BARANGAY 141',
//       'BARANGAY 142',
//       'BARANGAY 143',
//       'BARANGAY 144',
//       'BARANGAY 145',
//       'BARANGAY 146',
//       'BARANGAY 147',
//       'BARANGAY 148',
//       'BARANGAY 149',
//       'BARANGAY 150',
//       'BARANGAY 151',
//       'BARANGAY 152',
//       'BARANGAY 153',
//       'BARANGAY 154',
//       'BARANGAY 155',
//       'BARANGAY 156',
//       'BARANGAY 157',
//       'BARANGAY 158',
//       'BARANGAY 159',
//       'BARANGAY 160',
//       'BARANGAY 161',
//       'BARANGAY 162',
//       'BARANGAY 163',
//       'BARANGAY 164',
//       'BARANGAY 165',
//       'BARANGAY 166',
//       'BARANGAY 167',
//       'BARANGAY 168',
//       'BARANGAY 169',
//       'BARANGAY 170',
//       'BARANGAY 171',
//       'BARANGAY 172',
//       'BARANGAY 173',
//       'BARANGAY 174',
//       'BARANGAY 175',
//       'BARANGAY 176',
//       'BARANGAY 177',
//       'BARANGAY 178',
//       'BARANGAY 179',
//       'BARANGAY 180',
//       'BARANGAY 181',
//       'BARANGAY 182',
//       'BARANGAY 183',
//       'BARANGAY 184',
//       'BARANGAY 185',
//       'BARANGAY 186',
//       'BARANGAY 187',
//       'BARANGAY 188',
//       'BARANGAY 189',
//       'BARANGAY 190',
//       'BARANGAY 191',
//       'BARANGAY 192',
//       'BARANGAY 193',
//       'BARANGAY 194',
//       'BARANGAY 195',
//       'BARANGAY 196',
//       'BARANGAY 197',
//       'BARANGAY 198',
//       'BARANGAY 199',
//       'BARANGAY 200',
//       'BARANGAY 201',
//     ],
//   },
//   BINONDO: {
//     province: 'METRO MANILA',
//     municipality: 'BINONDO',
//     region: 'NCR',
//     barangay_list: [
//       'BARANGAY 287',
//       'BARANGAY 288',
//       'BARANGAY 289',
//       'BARANGAY 290',
//       'BARANGAY 291',
//       'BARANGAY 292',
//       'BARANGAY 293',
//       'BARANGAY 294',
//       'BARANGAY 295',
//       'BARANGAY 296',
//     ],
//   },
//   ERMITA: {
//     province: 'METRO MANILA',
//     municipality: 'ERMITA',
//     region: 'NCR',
//     barangay_list: [
//       'BARANGAY 659',
//       'BARANGAY 659-A',
//       'BARANGAY 660',
//       'BARANGAY 660-A',
//       'BARANGAY 661',
//       'BARANGAY 663',
//       'BARANGAY 663-A',
//       'BARANGAY 664',
//       'BARANGAY 666',
//       'BARANGAY 667',
//       'BARANGAY 668',
//       'BARANGAY 669',
//       'BARANGAY 670',
//     ],
//   },
//   INTRAMUROS: {
//     province: 'METRO MANILA',
//     municipality: 'INTRAMUROS',
//     region: 'NCR',
//     barangay_list: [
//       'BARANGAY 654',
//       'BARANGAY 655',
//       'BARANGAY 656',
//       'BARANGAY 657',
//       'BARANGAY 658',
//     ],
//   },
//   MALATE: {
//     province: 'METRO MANILA',
//     municipality: 'MALATE',
//     region: 'NCR',
//     barangay_list: [
//       'BARANGAY 688',
//       'BARANGAY 689',
//       'BARANGAY 690',
//       'BARANGAY 691',
//       'BARANGAY 692',
//       'BARANGAY 693',
//       'BARANGAY 694',
//       'BARANGAY 695',
//       'BARANGAY 696',
//       'BARANGAY 697',
//       'BARANGAY 698',
//       'BARANGAY 699',
//       'BARANGAY 700',
//       'BARANGAY 701',
//       'BARANGAY 702',
//       'BARANGAY 703',
//       'BARANGAY 704',
//       'BARANGAY 705',
//       'BARANGAY 706',
//       'BARANGAY 707',
//       'BARANGAY 708',
//       'BARANGAY 709',
//       'BARANGAY 710',
//       'BARANGAY 711',
//       'BARANGAY 712',
//       'BARANGAY 713',
//       'BARANGAY 714',
//       'BARANGAY 715',
//       'BARANGAY 716',
//       'BARANGAY 717',
//       'BARANGAY 718',
//       'BARANGAY 719',
//       'BARANGAY 720',
//       'BARANGAY 721',
//       'BARANGAY 722',
//       'BARANGAY 723',
//       'BARANGAY 724',
//       'BARANGAY 725',
//       'BARANGAY 726',
//       'BARANGAY 727',
//       'BARANGAY 728',
//       'BARANGAY 729',
//       'BARANGAY 730',
//       'BARANGAY 731',
//       'BARANGAY 732',
//       'BARANGAY 733',
//       'BARANGAY 734',
//       'BARANGAY 735',
//       'BARANGAY 736',
//       'BARANGAY 737',
//       'BARANGAY 738',
//       'BARANGAY 739',
//       'BARANGAY 740',
//       'BARANGAY 741',
//       'BARANGAY 742',
//       'BARANGAY 743',
//       'BARANGAY 744',
//     ],
//   },
//   PACO: {
//     province: 'METRO MANILA',
//     municipality: 'PACO',
//     region: 'NCR',
//     barangay_list: [
//       'BARANGAY 662',
//       'BARANGAY 664-A',
//       'BARANGAY 671',
//       'BARANGAY 672',
//       'BARANGAY 673',
//       'BARANGAY 674',
//       'BARANGAY 675',
//       'BARANGAY 676',
//       'BARANGAY 677',
//       'BARANGAY 678',
//       'BARANGAY 679',
//       'BARANGAY 680',
//       'BARANGAY 681',
//       'BARANGAY 682',
//       'BARANGAY 683',
//       'BARANGAY 684',
//       'BARANGAY 685',
//       'BARANGAY 686',
//       'BARANGAY 687',
//       'BARANGAY 809',
//       'BARANGAY 810',
//       'BARANGAY 811',
//       'BARANGAY 812',
//       'BARANGAY 813',
//       'BARANGAY 814',
//       'BARANGAY 815',
//       'BARANGAY 816',
//       'BARANGAY 817',
//       'BARANGAY 818',
//       'BARANGAY 819',
//       'BARANGAY 820',
//       'BARANGAY 821',
//       'BARANGAY 822',
//       'BARANGAY 823',
//       'BARANGAY 824',
//       'BARANGAY 825',
//       'BARANGAY 826',
//       'BARANGAY 827',
//       'BARANGAY 828',
//       'BARANGAY 829',
//       'BARANGAY 830',
//       'BARANGAY 831',
//       'BARANGAY 832',
//     ],
//   },
//   PANDACAN: {
//     province: 'METRO MANILA',
//     municipality: 'PANDACAN',
//     region: 'NCR',
//     barangay_list: [
//       'BARANGAY 833',
//       'BARANGAY 834',
//       'BARANGAY 835',
//       'BARANGAY 836',
//       'BARANGAY 837',
//       'BARANGAY 838',
//       'BARANGAY 839',
//       'BARANGAY 840',
//       'BARANGAY 841',
//       'BARANGAY 842',
//       'BARANGAY 843',
//       'BARANGAY 844',
//       'BARANGAY 845',
//       'BARANGAY 846',
//       'BARANGAY 847',
//       'BARANGAY 848',
//       'BARANGAY 849',
//       'BARANGAY 850',
//       'BARANGAY 851',
//       'BARANGAY 852',
//       'BARANGAY 853',
//       'BARANGAY 855',
//       'BARANGAY 856',
//       'BARANGAY 857',
//       'BARANGAY 858',
//       'BARANGAY 859',
//       'BARANGAY 860',
//       'BARANGAY 861',
//       'BARANGAY 862',
//       'BARANGAY 863',
//       'BARANGAY 864',
//       'BARANGAY 865',
//       'BARANGAY 867',
//       'BARANGAY 868',
//       'BARANGAY 869',
//       'BARANGAY 870',
//       'BARANGAY 871',
//       'BARANGAY 872',
//     ],
//   },
//   'PORT AREA': {
//     province: 'METRO MANILA',
//     municipality: 'PORT AREA',
//     region: 'NCR',
//     barangay_list: [
//       'BARANGAY 649',
//       'BARANGAY 650',
//       'BARANGAY 651',
//       'BARANGAY 652',
//       'BARANGAY 653',
//     ],
//   },
//   QUIAPO: {
//     province: 'METRO MANILA',
//     municipality: 'QUIAPO',
//     region: 'NCR',
//     barangay_list: [
//       'BARANGAY 306',
//       'BARANGAY 307',
//       'BARANGAY 308',
//       'BARANGAY 309',
//       'BARANGAY 383',
//       'BARANGAY 384',
//       'BARANGAY 385',
//       'BARANGAY 386',
//       'BARANGAY 387',
//       'BARANGAY 388',
//       'BARANGAY 389',
//       'BARANGAY 390',
//       'BARANGAY 391',
//       'BARANGAY 392',
//       'BARANGAY 393',
//       'BARANGAY 394',
//     ],
//   },
//   TONDO: {
//     province: 'METRO MANILA',
//     municipality: 'TONDO',
//     region: 'NCR',
//     barangay_list: [
//       'BARANGAY 1',
//       'BARANGAY 2',
//       'BARANGAY 3',
//       'BARANGAY 4',
//       'BARANGAY 5',
//       'BARANGAY 6',
//       'BARANGAY 7',
//       'BARANGAY 8',
//       'BARANGAY 9',
//       'BARANGAY 11',
//       'BARANGAY 12',
//       'BARANGAY 13',
//       'BARANGAY 14',
//       'BARANGAY 15',
//       'BARANGAY 16',
//       'BARANGAY 17',
//       'BARANGAY 18',
//       'BARANGAY 19',
//       'BARANGAY 20',
//       'BARANGAY 25',
//       'BARANGAY 26',
//       'BARANGAY 28',
//       'BARANGAY 29',
//       'BARANGAY 30',
//       'BARANGAY 31',
//       'BARANGAY 32',
//       'BARANGAY 33',
//       'BARANGAY 34',
//       'BARANGAY 35',
//       'BARANGAY 36',
//       'BARANGAY 37',
//       'BARANGAY 38',
//       'BARANGAY 39',
//       'BARANGAY 41',
//       'BARANGAY 42',
//       'BARANGAY 43',
//       'BARANGAY 44',
//       'BARANGAY 45',
//       'BARANGAY 46',
//       'BARANGAY 47',
//       'BARANGAY 48',
//       'BARANGAY 49',
//       'BARANGAY 50',
//       'BARANGAY 51',
//       'BARANGAY 52',
//       'BARANGAY 53',
//       'BARANGAY 54',
//       'BARANGAY 55',
//       'BARANGAY 56',
//       'BARANGAY 57',
//       'BARANGAY 58',
//       'BARANGAY 59',
//       'BARANGAY 60',
//       'BARANGAY 61',
//       'BARANGAY 62',
//       'BARANGAY 63',
//       'BARANGAY 64',
//       'BARANGAY 65',
//       'BARANGAY 66',
//       'BARANGAY 67',
//       'BARANGAY 68',
//       'BARANGAY 69',
//       'BARANGAY 70',
//       'BARANGAY 71',
//       'BARANGAY 72',
//       'BARANGAY 73',
//       'BARANGAY 74',
//       'BARANGAY 75',
//       'BARANGAY 76',
//       'BARANGAY 77',
//       'BARANGAY 78',
//       'BARANGAY 79',
//       'BARANGAY 80',
//       'BARANGAY 81',
//       'BARANGAY 82',
//       'BARANGAY 83',
//       'BARANGAY 84',
//       'BARANGAY 85',
//       'BARANGAY 86',
//       'BARANGAY 87',
//       'BARANGAY 88',
//       'BARANGAY 89',
//       'BARANGAY 90',
//       'BARANGAY 91',
//       'BARANGAY 92',
//       'BARANGAY 93',
//       'BARANGAY 94',
//       'BARANGAY 95',
//       'BARANGAY 96',
//       'BARANGAY 97',
//       'BARANGAY 98',
//       'BARANGAY 99',
//       'BARANGAY 100',
//       'BARANGAY 101',
//       'BARANGAY 102',
//       'BARANGAY 103',
//       'BARANGAY 104',
//       'BARANGAY 105',
//       'BARANGAY 106',
//       'BARANGAY 107',
//       'BARANGAY 108',
//       'BARANGAY 109',
//       'BARANGAY 110',
//       'BARANGAY 111',
//       'BARANGAY 112',
//       'BARANGAY 116',
//       'BARANGAY 117',
//       'BARANGAY 118',
//       'BARANGAY 119',
//       'BARANGAY 120',
//       'BARANGAY 121',
//       'BARANGAY 122',
//       'BARANGAY 123',
//       'BARANGAY 124',
//       'BARANGAY 125',
//       'BARANGAY 126',
//       'BARANGAY 127',
//       'BARANGAY 128',
//       'BARANGAY 129',
//       'BARANGAY 130',
//       'BARANGAY 131',
//       'BARANGAY 132',
//       'BARANGAY 133',
//       'BARANGAY 134',
//       'BARANGAY 135',
//       'BARANGAY 136',
//       'BARANGAY 137',
//       'BARANGAY 138',
//       'BARANGAY 139',
//       'BARANGAY 140',
//       'BARANGAY 141',
//       'BARANGAY 142',
//       'BARANGAY 143',
//       'BARANGAY 144',
//       'BARANGAY 145',
//       'BARANGAY 146',
//       'BARANGAY 147',
//       'BARANGAY 148',
//       'BARANGAY 149',
//       'BARANGAY 150',
//       'BARANGAY 151',
//       'BARANGAY 152',
//       'BARANGAY 153',
//       'BARANGAY 154',
//       'BARANGAY 155',
//       'BARANGAY 156',
//       'BARANGAY 157',
//       'BARANGAY 158',
//       'BARANGAY 159',
//       'BARANGAY 160',
//       'BARANGAY 161',
//       'BARANGAY 162',
//       'BARANGAY 163',
//       'BARANGAY 164',
//       'BARANGAY 165',
//       'BARANGAY 166',
//       'BARANGAY 167',
//       'BARANGAY 168',
//       'BARANGAY 169',
//       'BARANGAY 170',
//       'BARANGAY 171',
//       'BARANGAY 172',
//       'BARANGAY 173',
//       'BARANGAY 174',
//       'BARANGAY 175',
//       'BARANGAY 176',
//       'BARANGAY 177',
//       'BARANGAY 178',
//       'BARANGAY 179',
//       'BARANGAY 180',
//       'BARANGAY 181',
//       'BARANGAY 182',
//       'BARANGAY 183',
//       'BARANGAY 184',
//       'BARANGAY 185',
//       'BARANGAY 186',
//       'BARANGAY 187',
//       'BARANGAY 188',
//       'BARANGAY 189',
//       'BARANGAY 190',
//       'BARANGAY 191',
//       'BARANGAY 192',
//       'BARANGAY 193',
//       'BARANGAY 194',
//       'BARANGAY 195',
//       'BARANGAY 196',
//       'BARANGAY 197',
//       'BARANGAY 198',
//       'BARANGAY 199',
//       'BARANGAY 200',
//       'BARANGAY 201',
//       'BARANGAY 202',
//       'BARANGAY 202-A',
//       'BARANGAY 203',
//       'BARANGAY 204',
//       'BARANGAY 205',
//       'BARANGAY 206',
//       'BARANGAY 207',
//       'BARANGAY 208',
//       'BARANGAY 209',
//       'BARANGAY 210',
//       'BARANGAY 211',
//       'BARANGAY 212',
//       'BARANGAY 213',
//       'BARANGAY 214',
//       'BARANGAY 215',
//       'BARANGAY 216',
//       'BARANGAY 217',
//       'BARANGAY 218',
//       'BARANGAY 219',
//       'BARANGAY 220',
//       'BARANGAY 221',
//       'BARANGAY 222',
//       'BARANGAY 223',
//       'BARANGAY 224',
//       'BARANGAY 225',
//       'BARANGAY 226',
//       'BARANGAY 227',
//       'BARANGAY 228',
//       'BARANGAY 229',
//       'BARANGAY 230',
//       'BARANGAY 231',
//       'BARANGAY 232',
//       'BARANGAY 233',
//       'BARANGAY 234',
//       'BARANGAY 235',
//       'BARANGAY 236',
//       'BARANGAY 237',
//       'BARANGAY 238',
//       'BARANGAY 239',
//       'BARANGAY 240',
//       'BARANGAY 241',
//       'BARANGAY 242',
//       'BARANGAY 243',
//       'BARANGAY 244',
//       'BARANGAY 245',
//       'BARANGAY 246',
//       'BARANGAY 247',
//       'BARANGAY 248',
//       'BARANGAY 249',
//       'BARANGAY 250',
//       'BARANGAY 251',
//       'BARANGAY 252',
//       'BARANGAY 253',
//       'BARANGAY 254',
//       'BARANGAY 255',
//       'BARANGAY 256',
//       'BARANGAY 257',
//       'BARANGAY 258',
//       'BARANGAY 259',
//       'BARANGAY 260',
//       'BARANGAY 261',
//       'BARANGAY 262',
//       'BARANGAY 263',
//       'BARANGAY 264',
//       'BARANGAY 265',
//       'BARANGAY 266',
//       'BARANGAY 267',
//     ],
//   },
//   'MANDALUYONG': {
//     province: 'METRO MANILA',
//     municipality: 'MANDALUYONG',
//     region: 'NCR',
//     barangay_list: [
//       'ADDITION HILLS',
//       'BAGONG SILANG',
//       'BARANGKA DRIVE',
//       'BARANGKA IBABA',
//       'BARANGKA ILAYA',
//       'BARANGKA ITAAS',
//       'BUAYANG BATO',
//       'BUROL',
//       'DAANG BAKAL',
//       'HAGDANG BATO ITAAS',
//       'HAGDANG BATO LIBIS',
//       'HARAPIN ANG BUKAS',
//       'HIGHWAY HILLS',
//       'HULO',
//       'MABINI-J. RIZAL',
//       'MALAMIG',
//       'MAUWAY',
//       'NAMAYAN',
//       'NEW ZAÑIGA',
//       'OLD ZAÑIGA',
//       'PAG-ASA',
//       'PLAINVIEW',
//       'PLEASANT HILLS',
//       'POBLACION',
//       'SAN JOSE',
//       'VERGARA',
//       'WACK-WACK GREENHILLS',
//     ],
//   },
//   'MARIKINA': {
//     province: 'METRO MANILA',
//     municipality: 'MARIKINA',
//     region: 'NCR',
//     barangay_list: [
//       'BARANGKA',
//       'CALUMPANG',
//       'CONCEPCION DOS',
//       'CONCEPCION UNO',
//       'FORTUNE',
//       'INDUSTRIAL VALLEY',
//       'JESUS DE LA PEÑA',
//       'MALANDAY',
//       'MARIKINA HEIGHTS (CONCEPCION)',
//       'NANGKA',
//       'PARANG',
//       'SAN ROQUE',
//       'SANTA ELENA (POB.)',
//       'SANTO NIÑO',
//       'TAÑONG',
//       'TUMANA',
//     ],
//   },
//   'PASIG': {
//     province: 'METRO MANILA',
//     municipality: 'PASIG',
//     region: 'NCR',
//     barangay_list: [
//       'BAGONG ILOG',
//       'BAGONG KATIPUNAN',
//       'BAMBANG',
//       'BUTING',
//       'CANIOGAN',
//       'DELA PAZ',
//       'KALAWAAN',
//       'KAPASIGAN',
//       'KAPITOLYO',
//       'MALINAO',
//       'MANGGAHAN',
//       'MAYBUNGA',
//       'ORANBO',
//       'PALATIW',
//       'PINAGBUHATAN',
//       'PINEDA',
//       'ROSARIO',
//       'SAGAD',
//       'SAN ANTONIO',
//       'SAN JOAQUIN',
//       'SAN JOSE',
//       'SAN MIGUEL',
//       'SAN NICOLAS (POB.)',
//       'SANTA CRUZ',
//       'SANTA LUCIA',
//       'SANTA ROSA',
//       'SANTO TOMAS',
//       'SANTOLAN',
//       'SUMILANG',
//       'UGONG',
//     ],
//   },
//   'QUEZON': {
//     province: 'METRO MANILA',
//     municipality: 'QUEZON',
//     region: 'NCR',
//     barangay_list: [
//       'ALICIA',
//       'AMIHAN',
//       'APOLONIO SAMSON',
//       'AURORA',
//       'BAESA',
//       'BAGBAG',
//       'BAGONG LIPUNAN NG CRAME',
//       'BAGONG PAG-ASA',
//       'BAGONG SILANGAN',
//       'BAGUMBAYAN',
//       'BAGUMBUHAY',
//       'BAHAY TORO',
//       'BALINGASA',
//       'BALONG BATO',
//       'BATASAN HILLS',
//       'BAYANIHAN',
//       'BLUE RIDGE A',
//       'BLUE RIDGE B',
//       'BOTOCAN',
//       'BUNGAD',
//       'CAMP AGUINALDO',
//       'CAPRI',
//       'CENTRAL',
//       'CLARO',
//       'COMMONWEALTH',
//       'CULIAT',
//       'DAMAR',
//       'DAMAYAN',
//       'DAMAYANG LAGI',
//       'DEL MONTE',
//       'DIOQUINO ZOBEL',
//       'DON MANUEL',
//       'DOÑA IMELDA',
//       'DOÑA JOSEFA',
//       'DUYAN-DUYAN',
//       'E. RODRIGUEZ',
//       'EAST KAMIAS',
//       'ESCOPA I',
//       'ESCOPA II',
//       'ESCOPA III',
//       'ESCOPA IV',
//       'FAIRVIEW',
//       'GREATER LAGRO',
//       'GULOD',
//       'HOLY SPIRIT',
//       'HORSESHOE',
//       'IMMACULATE CONCEPCION',
//       'KALIGAYAHAN',
//       'KALUSUGAN',
//       'KAMUNING',
//       'KATIPUNAN',
//       'KAUNLARAN',
//       'KRISTONG HARI',
//       'KRUS NA LIGAS',
//       'LAGING HANDA',
//       'LIBIS',
//       'LOURDES',
//       'LOYOLA HEIGHTS',
//       'MAHARLIKA',
//       'MALAYA',
//       'MANGGA',
//       'MANRESA',
//       'MARIANA',
//       'MARIBLO',
//       'MARILAG',
//       'MASAGANA',
//       'MASAMBONG',
//       'MATANDANG BALARA',
//       'MILAGROSA',
//       'N.S. AMORANTO (GINTONG SILAHIS)',
//       'NAGKAISANG NAYON',
//       'NAYONG KANLURAN',
//       'NEW ERA (CONSTITUTION HILLS)',
//       'NORTH FAIRVIEW',
//       'NOVALICHES PROPER',
//       'OBRERO',
//       'OLD CAPITOL SITE',
//       'PAANG BUNDOK',
//       'PAG-IBIG SA NAYON',
//       'PALIGSAHAN',
//       'PALTOK',
//       'PANSOL',
//       'PARAISO',
//       'PASONG PUTIK PROPER (PASONG PUTIK)',
//       'PASONG TAMO',
//       'PAYATAS',
//       'PHIL-AM',
//       'PINAGKAISAHAN',
//       'PINYAHAN',
//       'PROJECT 6',
//       'QUIRINO 2-A',
//       'QUIRINO 2-B',
//       'QUIRINO 2-C',
//       'QUIRINO 3-A',
//       'RAMON MAGSAYSAY',
//       'ROXAS',
//       'SACRED HEART',
//       'SAINT IGNATIUS',
//       'SAINT PETER',
//       'SALVACION',
//       'SAN AGUSTIN',
//       'SAN ANTONIO',
//       'SAN BARTOLOME',
//       'SAN ISIDRO',
//       'SAN ISIDRO LABRADOR',
//       'SAN JOSE',
//       'SAN MARTIN DE PORRES',
//       'SAN ROQUE',
//       'SAN VICENTE',
//       'SANGANDAAN',
//       'SANTA CRUZ',
//       'SANTA LUCIA',
//       'SANTA MONICA',
//       'SANTA TERESITA',
//       'SANTO CRISTO',
//       'SANTO DOMINGO (MATALAHIB)',
//       'SANTO NIÑO',
//       'SANTOL',
//       'SAUYO',
//       'SIENNA',
//       'SIKATUNA VILLAGE',
//       'SILANGAN',
//       'SOCORRO',
//       'SOUTH TRIANGLE',
//       'TAGUMPAY',
//       'TALAYAN',
//       'TALIPAPA',
//       'TANDANG SORA',
//       'TATALON',
//       'TEACHERS VILLAGE EAST',
//       'TEACHERS VILLAGE WEST',
//       'U.P. CAMPUS',
//       'U.P. VILLAGE',
//       'UGONG NORTE',
//       'UNANG SIGAW',
//       'VALENCIA',
//       'VASRA',
//       'VETERANS VILLAGE',
//       'VILLA MARIA CLARA',
//       'WEST KAMIAS',
//       'WEST TRIANGLE',
//       'WHITE PLAINS',
//     ],
//   },
//   'SAN JUAN': {
//     province: 'METRO MANILA',
//     municipality: 'SAN JUAN',
//     region: 'NCR',
//     barangay_list: [
//       'ADDITION HILLS',
//       'BALONG-BATO',
//       'BATIS',
//       'CORAZON DE JESUS',
//       'ERMITAÑO',
//       'GREENHILLS',
//       'HALO-HALO (ST. JOSEPH)',
//       'ISABELITA',
//       'KABAYANAN',
//       'LITTLE BAGUIO',
//       'MAYTUNAS',
//       'ONSE',
//       'PASADEÑA',
//       'PEDRO CRUZ',
//       'PROGRESO',
//       'RIVERA',
//       'SALAPAN',
//       'SAN PERFECTO',
//       'SANTA LUCIA',
//       'TIBAGAN',
//       'WEST CRAME',
//     ],
//   },
//   'CALOOCAN': {
//     province: 'METRO MANILA',
//     municipality: 'CALOOCAN',
//     region: 'NCR',
//     barangay_list: [
//       'BARANGAY 1',
//       'BARANGAY 2',
//       'BARANGAY 3',
//       'BARANGAY 4',
//       'BARANGAY 5',
//       'BARANGAY 6',
//       'BARANGAY 7',
//       'BARANGAY 8',
//       'BARANGAY 9',
//       'BARANGAY 10',
//       'BARANGAY 11',
//       'BARANGAY 12',
//       'BARANGAY 13',
//       'BARANGAY 14',
//       'BARANGAY 15',
//       'BARANGAY 16',
//       'BARANGAY 17',
//       'BARANGAY 18',
//       'BARANGAY 19',
//       'BARANGAY 20',
//       'BARANGAY 21',
//       'BARANGAY 22',
//       'BARANGAY 23',
//       'BARANGAY 24',
//       'BARANGAY 25',
//       'BARANGAY 26',
//       'BARANGAY 27',
//       'BARANGAY 28',
//       'BARANGAY 29',
//       'BARANGAY 30',
//       'BARANGAY 31',
//       'BARANGAY 32',
//       'BARANGAY 33',
//       'BARANGAY 34',
//       'BARANGAY 35',
//       'BARANGAY 36',
//       'BARANGAY 37',
//       'BARANGAY 38',
//       'BARANGAY 39',
//       'BARANGAY 40',
//       'BARANGAY 41',
//       'BARANGAY 42',
//       'BARANGAY 43',
//       'BARANGAY 44',
//       'BARANGAY 45',
//       'BARANGAY 46',
//       'BARANGAY 47',
//       'BARANGAY 48',
//       'BARANGAY 49',
//       'BARANGAY 50',
//       'BARANGAY 51',
//       'BARANGAY 52',
//       'BARANGAY 53',
//       'BARANGAY 54',
//       'BARANGAY 55',
//       'BARANGAY 56',
//       'BARANGAY 57',
//       'BARANGAY 58',
//       'BARANGAY 59',
//       'BARANGAY 60',
//       'BARANGAY 61',
//       'BARANGAY 62',
//       'BARANGAY 63',
//       'BARANGAY 64',
//       'BARANGAY 65',
//       'BARANGAY 66',
//       'BARANGAY 67',
//       'BARANGAY 68',
//       'BARANGAY 69',
//       'BARANGAY 70',
//       'BARANGAY 71',
//       'BARANGAY 72',
//       'BARANGAY 73',
//       'BARANGAY 74',
//       'BARANGAY 75',
//       'BARANGAY 76',
//       'BARANGAY 77',
//       'BARANGAY 78',
//       'BARANGAY 79',
//       'BARANGAY 80',
//       'BARANGAY 81',
//       'BARANGAY 82',
//       'BARANGAY 83',
//       'BARANGAY 84',
//       'BARANGAY 85',
//       'BARANGAY 86',
//       'BARANGAY 87',
//       'BARANGAY 88',
//       'BARANGAY 89',
//       'BARANGAY 90',
//       'BARANGAY 91',
//       'BARANGAY 92',
//       'BARANGAY 93',
//       'BARANGAY 94',
//       'BARANGAY 95',
//       'BARANGAY 96',
//       'BARANGAY 97',
//       'BARANGAY 98',
//       'BARANGAY 99',
//       'BARANGAY 100',
//       'BARANGAY 101',
//       'BARANGAY 102',
//       'BARANGAY 103',
//       'BARANGAY 104',
//       'BARANGAY 105',
//       'BARANGAY 106',
//       'BARANGAY 107',
//       'BARANGAY 108',
//       'BARANGAY 109',
//       'BARANGAY 110',
//       'BARANGAY 111',
//       'BARANGAY 112',
//       'BARANGAY 113',
//       'BARANGAY 114',
//       'BARANGAY 115',
//       'BARANGAY 116',
//       'BARANGAY 117',
//       'BARANGAY 118',
//       'BARANGAY 119',
//       'BARANGAY 120',
//       'BARANGAY 121',
//       'BARANGAY 122',
//       'BARANGAY 123',
//       'BARANGAY 124',
//       'BARANGAY 125',
//       'BARANGAY 126',
//       'BARANGAY 127',
//       'BARANGAY 128',
//       'BARANGAY 129',
//       'BARANGAY 130',
//       'BARANGAY 131',
//       'BARANGAY 132',
//       'BARANGAY 133',
//       'BARANGAY 134',
//       'BARANGAY 135',
//       'BARANGAY 136',
//       'BARANGAY 137',
//       'BARANGAY 138',
//       'BARANGAY 139',
//       'BARANGAY 140',
//       'BARANGAY 141',
//       'BARANGAY 142',
//       'BARANGAY 143',
//       'BARANGAY 144',
//       'BARANGAY 145',
//       'BARANGAY 146',
//       'BARANGAY 147',
//       'BARANGAY 148',
//       'BARANGAY 149',
//       'BARANGAY 150',
//       'BARANGAY 151',
//       'BARANGAY 152',
//       'BARANGAY 153',
//       'BARANGAY 154',
//       'BARANGAY 155',
//       'BARANGAY 156',
//       'BARANGAY 157',
//       'BARANGAY 158',
//       'BARANGAY 159',
//       'BARANGAY 160',
//       'BARANGAY 161',
//       'BARANGAY 162',
//       'BARANGAY 163',
//       'BARANGAY 164',
//       'BARANGAY 165',
//       'BARANGAY 166',
//       'BARANGAY 167',
//       'BARANGAY 168',
//       'BARANGAY 169',
//       'BARANGAY 170',
//       'BARANGAY 171',
//       'BARANGAY 172',
//       'BARANGAY 173',
//       'BARANGAY 174',
//       'BARANGAY 175',
//       'BARANGAY 176',
//       'BARANGAY 177',
//       'BARANGAY 178',
//       'BARANGAY 179',
//       'BARANGAY 180',
//       'BARANGAY 181',
//       'BARANGAY 182',
//       'BARANGAY 183',
//       'BARANGAY 184',
//       'BARANGAY 185',
//       'BARANGAY 186',
//       'BARANGAY 187',
//       'BARANGAY 188',
//     ],
//   },
//   'VALENZUELA': {
//     province: 'METRO MANILA',
//     municipality: 'VALENZUELA',
//     region: 'NCR',
//     barangay_list: [
//       'ARKONG BATO',
//       'BAGBAGUIN',
//       'BALANGKAS',
//       'BIGNAY',
//       'BISIG',
//       'CANUMAY EAST',
//       'CANUMAY WEST',
//       'COLOONG',
//       'DALANDANAN',
//       'HEN. T. DE LEON',
//       'ISLA',
//       'KARUHATAN',
//       'LAWANG BATO',
//       'LINGUNAN',
//       'MABOLO',
//       'MALANDAY',
//       'MALINTA',
//       'MAPULANG LUPA',
//       'MARULAS',
//       'MAYSAN',
//       'PALASAN',
//       'PARADA',
//       'PARIANCILLO VILLA',
//       'PASO DE BLAS',
//       'PASOLO',
//       'POBLACION',
//       'PULO',
//       'PUNTURIN',
//       'RINCON',
//       'TAGALAG',
//       'UGONG',
//       'VIENTE REALES',
//       'WAWANG PULO',
//     ],
//   },
//   'MALABON': {
//     province: 'METRO MANILA',
//     municipality: 'MALABON',
//     region: 'NCR',
//     barangay_list: [
//       'ACACIA',
//       'BARITAN',
//       'BAYAN-BAYANAN',
//       'CATMON',
//       'CONCEPCION',
//       'DAMPALIT',
//       'FLORES',
//       'HULONG DUHAT',
//       'IBABA',
//       'LONGOS',
//       'MAYSILO',
//       'MUZON',
//       'NIUGAN',
//       'PANGHULO',
//       'POTRERO',
//       'SAN AGUSTIN',
//       'SANTOLAN',
//       'TAÑONG (POB.)',
//       'TINAJEROS',
//       'TONSUYA',
//       'TUGATOG',
//     ],
//   },
//   'NAVOTAS': {
//     province: 'METRO MANILA',
//     municipality: 'NAVOTAS',
//     region: 'NCR',
//     barangay_list: [
//       'BAGUMBAYAN NORTH',
//       'BAGUMBAYAN SOUTH',
//       'BANGCULASI',
//       'DAANGHARI',
//       'NAVOTAS EAST',
//       'NAVOTAS WEST',
//       'NBBS DAGAT-DAGATAN',
//       'NBBS KAUNLARAN',
//       'NBBS PROPER',
//       'NORTH BAY BLVD., NORTH',
//       'SAN JOSE (POB.)',
//       'SAN RAFAEL VILLAGE',
//       'SAN ROQUE',
//       'SIPAC-ALMACEN',
//       'TANGOS NORTH',
//       'TANGOS SOUTH',
//       'TANZA 1',
//       'TANZA 2',
//     ],
//   },
//   PATEROS: {
//     province: 'METRO MANILA',
//     municipality: 'PATEROS',
//     region: 'NCR',
//     barangay_list: [
//       'AGUHO',
//       'MAGTANGGOL',
//       'MARTIRES DEL 96',
//       'POBLACION',
//       'SAN PEDRO',
//       'SAN ROQUE',
//       'SANTA ANA',
//       'SANTO ROSARIO-KANLURAN',
//       'SANTO ROSARIO-SILANGAN',
//       'TABACALERA',
//     ],
//   },
//   TAGUIG: {
//     province: 'METRO MANILA',
//     municipality: 'TAGUIG',
//     region: 'NCR',
//     barangay_list: [
//       'BAGUMBAYAN',
//       'BAMBANG',
//       'CALZADA',
//       'CENTRAL BICUTAN',
//       'CENTRAL SIGNAL VILLAGE',
//       'FORT BONIFACIO',
//       'HAGONOY',
//       'IBAYO-TIPAS',
//       'KATUPARAN',
//       'LIGID-TIPAS',
//       'LOWER BICUTAN',
//       'MAHARLIKA VILLAGE',
//       'NAPINDAN',
//       'NEW LOWER BICUTAN',
//       'NORTH DAANG HARI',
//       'NORTH SIGNAL VILLAGE',
//       'PALINGON',
//       'PINAGSAMA',
//       'SAN MIGUEL',
//       'SANTA ANA',
//       'SOUTH DAANG HARI',
//       'SOUTH SIGNAL VILLAGE',
//       'TANYAG',
//       'TUKTUKAN',
//       'UPPER BICUTAN',
//       'USUSAN',
//       'WAWA',
//       'WESTERN BICUTAN',
//     ],
//   },
// };

export const locationPh = {
  BAUNGON: {
    province: 'BUKIDNON',
    municipality: 'BAUNGON',
    region: 'REGION X',
    barangay_list: [
      'BALINTAD',
      'BUENAVISTA',
      'DANATAG',
      'IMBATUG (POB.)',
      'KALILANGAN',
      'LACOLAC',
      'LANGAON',
      'LIBORAN',
      'LINGATING',
      'MABUHAY',
      'MABUNGA',
      'NICDAO',
      'PUALAS',
      'SALIMBALAN',
      'SAN MIGUEL',
      'SAN VICENTE',
    ],
  },
  CABANGLASAN: {
    province: 'BUKIDNON',
    municipality: 'CABANGLASAN',
    region: 'REGION X',
    barangay_list: [
      'ANLOGAN',
      'CABULOHAN',
      'CANANGAAN',
      'CAPINONAN',
      'DALACUTAN',
      'FREEDOM',
      'IBA',
      'IMBATUG',
      'JASAAN',
      'LAMBANGAN',
      'MANDAHIKAN',
      'MANDAING',
      'MAUSWAGON',
      'PARADISE',
      'POBLACION',
    ],
  },
  'MALAYBALAY': {
    province: 'BUKIDNON',
    municipality: 'MALAYBALAY',
    region: 'REGION X',
    barangay_list: [
      'AGLAYAN',
      'APO MACOTE',
      'BANGCUD',
      'BARANGAY 1 (POB.)',
      'BARANGAY 2 (POB.)',
      'BARANGAY 3 (POB.)',
      'BARANGAY 4 (POB.)',
      'BARANGAY 5 (POB.)',
      'BARANGAY 6 (POB.)',
      'BARANGAY 7 (POB.)',
      'BARANGAY 8 (POB.)',
      'BARANGAY 9 (POB.)',
      'BARANGAY 10 (POB.)',
      'BARANGAY 11 (POB.)',
      'BUSDI',
      'CABANGAHAN',
      'CABURACANAN',
      'CANAYAN',
      'CAPITAN ANGEL',
      'CASISANG',
      'DALWANGAN',
      'IMBAYAO',
      'INDALAZA',
      'KABALABAG',
      'KALASUNGAY',
      'KULAMAN',
      'LAGUITAS',
      'LINABO',
      'MAGSAYSAY',
      'MALIGAYA',
      'MANAGOK',
      'MANALOG',
      'MAPAYAG',
      'MAPULO',
      'MIGLAMIN',
      'PATPAT (LAPU-LAPU)',
      'SAINT PETER',
      'SAN JOSE',
      'SAN MARTIN',
      'SANTO NIÑO',
      'SILAE',
      'SIMAYA',
      'SINANGLANAN',
      'SUMPONG',
      'VIOLETA',
      'ZAMBOANGUITA',
    ],
  },
  DAMULOG: {
    province: 'BUKIDNON',
    municipality: 'DAMULOG',
    region: 'REGION X',
    barangay_list: [
      'ALUDAS',
      'ANGGA-AN',
      'KINAPAT',
      'KIRAON',
      'KITINGTING',
      'LAGANDANG',
      'MACAPARI',
      'MAICAN',
      'MIGCAWAYAN',
      'NEW COMPOSTELA',
      'OLD DAMULOG',
      'OMONAY',
      'POBLACION (NEW DAMULOG)',
      'POCOPOCO',
      'SAMPAGAR',
      'SAN ISIDRO',
      'TANGKULAN (JOSE RIZAL)',
    ],
  },
  DANGCAGAN: {
    province: 'BUKIDNON',
    municipality: 'DANGCAGAN',
    region: 'REGION X',
    barangay_list: [
      'BARONGCOT',
      'BUGWAK',
      'DOLOROSA',
      'KAPALARAN',
      'KIANGGAT',
      'LOURDES',
      'MACARTHUR',
      'MIARAY',
      'MIGCUYA',
      'NEW VISAYAS',
      'OSMEÑA',
      'POBLACION',
      'SAGBAYAN',
      'SAN VICENTE',
    ],
  },
  'DON CARLOS': {
    province: 'BUKIDNON',
    municipality: 'DON CARLOS',
    region: 'REGION X',
    barangay_list: [
      'BISMARTZ',
      'BOCBOC',
      'BUYOT',
      'CABADIANGAN',
      'CALAOCALAO',
      'DON CARLOS NORTE',
      'DON CARLOS SUR (POB.)',
      'EMBAYAO',
      'KALUBIHON',
      'KASIGKOT',
      'KAWILIHAN',
      'KIARA',
      'KIBATANG',
      'MAHAYAHAY',
      'MANLAMONAY',
      'MARAYMARAY',
      'MAUSWAGON',
      'MINSALAGAN',
      'NEW NONGNONGAN (MASIMAG)',
      'NEW VISAYAS',
      'OLD NONGNONGAN',
      'PINAMALOY',
      'PUALAS',
      'SAN ANTONIO EAST',
      'SAN ANTONIO WEST',
      'SAN FRANCISCO',
      'SAN NICOLAS (BANBAN)',
      'SAN ROQUE',
      'SINANGGUYAN',
    ],
  },
  'IMPASUG-ONG': {
    province: 'BUKIDNON',
    municipality: 'IMPASUG-ONG',
    region: 'REGION X',
    barangay_list: [
      'BONTONGON',
      'BULONAY',
      'CAPITAN BAYONG',
      'CAWAYAN',
      'DUMALAGUING',
      'GUIHEAN',
      'HAGPA',
      'IMPALUTAO',
      'KALABUGAO',
      'KIBENTON',
      'LA FORTUNA',
      'POBLACION',
      'SAYAWAN',
    ],
  },
  KADINGILAN: {
    province: 'BUKIDNON',
    municipality: 'KADINGILAN',
    region: 'REGION X',
    barangay_list: [
      'BAGONGBAYAN',
      'BAGOR',
      'BALAORO',
      'BAROY',
      'CABADIANGAN',
      'HUSAYAN',
      'KIBALAGON',
      'KIBOGTOK',
      'MABUHAY',
      'MALINAO',
      'MATAMPAY',
      'PAY-AS',
      'PINAMANGUHAN',
      'POBLACION',
      'SALVACION',
      'SAN ANDRES',
      'SIBONGA',
    ],
  },
  KALILANGAN: {
    province: 'BUKIDNON',
    municipality: 'KALILANGAN',
    region: 'REGION X',
    barangay_list: [
      'BABORAWON',
      'BANGBANG',
      'CANITUAN',
      'KIBANING',
      'KINURA',
      'LAMPANUSAN',
      'MACA-OPAO',
      'MALINAO',
      'NINOY AQUINO',
      'PAMOTOLON (PAMOTDON)',
      'POBLACION',
      'PUBLIC',
      'SAN VICENTE FERRER',
      'WEST POBLACION',
    ],
  },
  KIBAWE: {
    province: 'BUKIDNON',
    municipality: 'KIBAWE',
    region: 'REGION X',
    barangay_list: [
      'BALINTAWAK',
      'BUKANG LIWAYWAY',
      'CAGAWASAN',
      'EAST KIBAWE (POB.)',
      'GUTAPOL',
      'KIORAO',
      'KISAWA',
      'LABUAGON',
      'MAGSAYSAY',
      'MARAPANGI',
      'MASCARIÑAS',
      'NATULONGAN',
      'NEW KIDAPAWAN',
      'OLD KIBAWE',
      'PALMA',
      'PINAMULA',
      'ROMAGOOC',
      'SAMPAGUITA',
      'SANIPON',
      'SPRING',
      'TALAHIRON',
      'TUMARAS',
      'WEST KIBAWE (POB.)',
    ],
  },
  KITAOTAO: {
    province: 'BUKIDNON',
    municipality: 'KITAOTAO',
    region: 'REGION X',
    barangay_list: [
      'BALANGIGAY',
      'BALUKBUKAN',
      'BERSHIBA',
      'BINOONGAN',
      'BOBONG',
      'BOLOCAON',
      'CABALANTIAN',
      'CALAPATON',
      'DIGONGAN',
      'EAST DALURONG',
      'KAHUSAYAN',
      'KALUMIHAN',
      'KAUYONAN',
      'KIMOLONG',
      'KIPILAS',
      'KITAIHON',
      'KITOBO',
      'KIULOM',
      'MAGSAYSAY',
      'MALOBALO',
      'METEBAGAO',
      'NAPALICO',
      'PAGAN',
      'PANGANAN',
      'POBLACION',
      'SAGUNDANON',
      'SAN ISIDRO',
      'SAN LORENZO',
      'SANTO ROSARIO',
      'SINAYSAYAN (DALURONG)',
      'SINUDA (SIMOD)',
      'TANDONG',
      'TAWAS',
      'WEST DALURONG',
      'WHITE KULAMAN',
    ],
  },
  LANTAPAN: {
    province: 'BUKIDNON',
    municipality: 'LANTAPAN',
    region: 'REGION X',
    barangay_list: [
      'ALANIB',
      'BACLAYON',
      'BALILA',
      'BANTUANON',
      'BASAK',
      'BUGCAON',
      'CAPITAN JUAN',
      'CAWAYAN',
      'KA-ATOAN (KAATUAN)',
      'KIBANGAY',
      'KULASIHAN',
      'POBLACION',
      'SONGCO',
      'VICTORY',
    ],
  },
  LIBONA: {
    province: 'BUKIDNON',
    municipality: 'LIBONA',
    region: 'REGION X',
    barangay_list: [
      'CAPIHAN',
      'CROSSING',
      'GANGO',
      'KILIOG',
      'KINAWE',
      'LATURAN',
      'MAAMBONG',
      'NANGKA',
      'PALABUCAN',
      'POBLACION',
      'PONGOL',
      'SAN JOSE',
      'SANTA FE',
      'SIL-IPON',
    ],
  },
  MALITBOG: {
    province: 'SOUTHERN LEYTE',
    municipality: 'MALITBOG',
    region: 'REGION VIII',
    barangay_list: [
      'ABGAO',
      'ASUNCION',
      'AURORA',
      'BENIT',
      'CAAGA',
      'CABUL-ANONAN (POB.)',
      'CADARUHAN',
      'CADARUHAN SUR',
      'CANDATAG',
      'CANTAMUAC',
      'CARAATAN',
      'CONCEPCION',
      'FATIMA',
      'GUINABONAN',
      'IBA',
      'JUANGON',
      'KAUSWAGAN',
      'LAMBONAO',
      'MAHAYAHAY',
      'MANINGNING',
      'MAUJO',
      'NEW KATIPUNAN',
      'PANCIL',
      'PASIL (POB.)',
      'SABANG',
      'SAN ANTONIO (POB.)',
      'SAN ISIDRO',
      'SAN JOSE',
      'SAN ROQUE',
      'SAN VICENTE',
      'SANGAHON',
      'SANTA CRUZ',
      'SANTO NIÑO',
      'TALIWA (POB.)',
      'TIGBAWAN I',
      'TIGBAWAN II',
      'TIMBA',
    ],
  },
  'MANOLO FORTICH': {
    province: 'BUKIDNON',
    municipality: 'MANOLO FORTICH',
    region: 'REGION X',
    barangay_list: [
      'AGUSAN CANYON',
      'ALAE',
      'DAHILAYAN',
      'DALIRIG',
      'DAMILAG',
      'DICLUM',
      'GUILANG-GUILANG',
      'KALUGMANAN',
      'LINDABAN',
      'LINGION',
      'LUNOCAN',
      'MALUKO',
      'MAMBATANGAN',
      'MAMPAYAG',
      'MANTIBUGAO',
      'MINSURO',
      'SAN MIGUEL',
      'SANKANAN',
      'SANTIAGO',
      'SANTO NIÑO',
      'TANKULAN (POB.)',
      'TICALA',
    ],
  },
  MARAMAG: {
    province: 'BUKIDNON',
    municipality: 'MARAMAG',
    region: 'REGION X',
    barangay_list: [
      'ANAHAWON',
      'BAGONGSILANG',
      'BASE CAMP',
      'BAYABASON (SPRING)',
      'CAMP I',
      'COLAMBUGAN',
      'DAGUMBA-AN',
      'DANGGAWAN',
      'DOLOGON',
      'KIHARONG',
      'KISANDAY',
      'KUYA',
      'LA ROXAS',
      'NORTH POBLACION',
      'PANADTALAN',
      'PANALSALAN',
      'SAN MIGUEL',
      'SAN ROQUE',
      'SOUTH POBLACION',
      'TUBIGON',
    ],
  },
  PANGANTUCAN: {
    province: 'BUKIDNON',
    municipality: 'PANGANTUCAN',
    region: 'REGION X',
    barangay_list: [
      'ADTUYON',
      'BACUSANON',
      'BANGAHAN',
      'BARANDIAS',
      'CONCEPCION',
      'GANDINGAN',
      'KIMANAIT',
      'KIPADUKAN',
      'LANGCATAON',
      'LANTAY',
      'MADAYA',
      'MALIPAYON',
      'MENDIS',
      'NABALIWA',
      'NEW EDEN',
      'PAYAD',
      'PIGTAURANAN',
      'POBLACION',
      'PORTULIN',
    ],
  },
  QUEZON: {
    province: 'PALAWAN',
    municipality: 'QUEZON',
    region: 'REGION IV-B',
    barangay_list: [
      'ALFONSO XIII (POB.)',
      'ARAMAYWAN',
      'BERONG',
      'CALATAGBAK',
      'CALUMPANG',
      'ISUGOD',
      'MAASIN',
      'MALATGAO',
      'PANITIAN',
      'PINAGLABANAN',
      'QUINLOGAN',
      'SOWANGAN',
      'TABON',
      'TAGUSAO',
    ],
  },
  'SAN FERNANDO': {
    province: 'CEBU',
    municipality: 'SAN FERNANDO',
    region: 'REGION VII',
    barangay_list: [
      'BALUD',
      'BALUNGAG',
      'BASAK',
      'BUGHO',
      'CABATBATAN',
      'GREENHILLS',
      'ILAYA',
      'LANTAWAN',
      'LIBURON',
      'MAGSICO',
      'PANADTARAN',
      'PITALO',
      'POBLACION NORTH',
      'POBLACION SOUTH',
      'SAN ISIDRO',
      'SANGAT',
      'TABIONAN',
      'TANANAS',
      'TINUBDAN',
      'TONGGO',
      'TUBOD',
    ],
  },
  SUMILAO: {
    province: 'BUKIDNON',
    municipality: 'SUMILAO',
    region: 'REGION X',
    barangay_list: [
      'CULASI',
      'KISOLON',
      'LICOAN',
      'LUPIAGAN',
      'OCASION',
      'POBLACION',
      'PUNTIAN',
      'SAN ROQUE',
      'SAN VICENTE',
      'VISTA VILLA',
    ],
  },
  TALAKAG: {
    province: 'BUKIDNON',
    municipality: 'TALAKAG',
    region: 'REGION X',
    barangay_list: [
      'BARANGAY 1 (POB.)',
      'BARANGAY 2 (POB.)',
      'BARANGAY 3 (POB.)',
      'BARANGAY 4 (POB.)',
      'BARANGAY 5 (POB.)',
      'BASAK',
      'BAYLANAN',
      'CACAON',
      'COLAWINGON',
      'COSINA',
      'DAGUMBAAN',
      'DAGUNDALAHON',
      'DOMINOROG',
      'INDULANG',
      'LANTUD',
      'LAPOK',
      'LIGURON',
      'LINGI-ON',
      'LIRONGAN',
      'MIARAYON',
      'SAGARAN',
      'SALUCOT',
      'SAN ANTONIO',
      'SAN ISIDRO',
      'SAN MIGUEL',
      'SAN RAFAEL',
      'SANTO NIÑO (LUMBAYAWA)',
      'TAGBAK',
      'TIKALAAN',
    ],
  },
  'VALENCIA': {
    province: 'BUKIDNON',
    municipality: 'VALENCIA',
    region: 'REGION X',
    barangay_list: [
      'BAGONTAAS',
      'BANLAG',
      'BAROBO',
      'BATANGAN',
      'CATUMBALON',
      'COLONIA',
      'CONCEPCION',
      'DAGAT-KIDAVAO',
      'GUINOYURAN',
      'KAHAPUNAN',
      'LALIGAN',
      'LILINGAYON',
      'LOURDES',
      'LUMBAYAO',
      'LUMBO',
      'LUROGAN',
      'MAAPAG',
      'MABUHAY',
      'MAILAG',
      'MT. NEBO',
      'NABAGO',
      'PINATILAN',
      'POBLACION',
      'SAN CARLOS',
      'SAN ISIDRO',
      'SINABUAGAN',
      'SINAYAWAN',
      'SUGOD',
      'TONGANTONGAN',
      'TUGAYA',
      'VINTAR',
    ],
  },
  CATARMAN: {
    province: 'NORTHERN SAMAR',
    municipality: 'CATARMAN',
    region: 'REGION VIII',
    barangay_list: [
      'ACACIA (POB.)',
      'AGUINALDO',
      'AIRPORT VILLAGE',
      'BANGKEROHAN',
      'BAYBAY',
      'BOCSOL',
      'CABAYHAN',
      'CAG-ABACA',
      'CAL-IGANG',
      'CALACHUCHI (POB.)',
      'CAWAYAN',
      'CERVANTES',
      'CULARIMA',
      'DAGANAS',
      'DALAKIT (POB.)',
      'DOÑA PULQUERIA (HIMBANG)',
      'GALUTAN',
      'GEBALAGNAN (HIBALAGNAN)',
      'GEBULWANGAN',
      'GENERAL MALVAR',
      'GUBA',
      'HINATAD',
      'IMELDA (ELIMBO)',
      'IPIL-IPIL (POB.)',
      'JOSE ABAD SANTOS (POB.)',
      'JOSE P. RIZAL (POB.)',
      'KASOY (POB.)',
      'LAPU-LAPU (POB.)',
      'LIBERTY',
      'LIBJO (BINOG)',
      'MABINI',
      'MABOLO (POB.)',
      'MACAGTAS',
      'MCKINLEY',
      'MOLAVE (POB.)',
      'NARRA (POB.)',
      'NEW RIZAL',
      'OLD RIZAL',
      'PATICUA',
      'POLANGI',
      'QUEZON',
      'SALVACION',
      'SAMPAGUITA (POB.)',
      'SAN JULIAN',
      'SAN PASCUAL',
      'SANTOL (POB.)',
      'SOMOGE',
      'TALISAY (POB.)',
      'TINOWARAN',
      'TRANGUE',
      'UEP I',
      'UEP II',
      'UEP III',
      'WASHINGTON',
      'YAKAL (POB.)',
    ],
  },
  GUINSILIBAN: {
    province: 'CAMIGUIN',
    municipality: 'GUINSILIBAN',
    region: 'REGION X',
    barangay_list: [
      'BUTAY',
      'CABUAN',
      'CANTAAN',
      'LIONG',
      'MAAC',
      'NORTH POBLACION',
      'SOUTH POBLACION',
    ],
  },
  MAHINOG: {
    province: 'CAMIGUIN',
    municipality: 'MAHINOG',
    region: 'REGION X',
    barangay_list: [
      'BENONI',
      'BINATUBO (BINALIWAN)',
      'CATOHUGAN',
      'HUBANGON',
      'OWAKAN',
      'POBLACION',
      'PUNTOD',
      'SAN ISIDRO',
      'SAN JOSE',
      'SAN MIGUEL',
      'SAN ROQUE',
      'TUBOD',
      'TUPSAN PEQUEÑO',
    ],
  },
  MAMBAJAO: {
    province: 'CAMIGUIN',
    municipality: 'MAMBAJAO',
    region: 'REGION X',
    barangay_list: [
      'AGOHO',
      'ANITO',
      'BALBAGON',
      'BAYLAO',
      'BENHAAN',
      'BUG-ONG',
      'KUGUITA',
      'MAGTING',
      'NAASAG',
      'PANDAN',
      'POBLACION',
      'SORO-SORO',
      'TAGDO',
      'TUPSAN',
      'YUMBING',
    ],
  },
  SAGAY: {
    province: 'CAMIGUIN',
    municipality: 'SAGAY',
    region: 'REGION X',
    barangay_list: [
      'ALANGILAN',
      'BACNIT',
      'BALITE',
      'BONBON',
      'BUGANG',
      'CUNA',
      'MANUYOG',
      'MAYANA',
      'POBLACION',
    ],
  },
  BACOLOD: {
    province: 'LANAO DEL NORTE',
    municipality: 'BACOLOD',
    region: 'REGION X',
    barangay_list: [
      'ALEGRIA',
      'BABALAYA',
      'BABALAYAN TOWNSITE',
      'BINUNI',
      'DELABAYAN WEST',
      'DEMOLOGAN',
      'DIMARAO',
      'ESPERANZA',
      'KAHAYAG',
      'LIANGAN EAST',
      'MATI',
      'MINAULON',
      'PAGAYAWAN',
      'POBLACION BACOLOD',
      'PUNOD (MALIWANAG)',
      'RUPAGAN',
    ],
  },
  BALOI: {
    province: 'LANAO DEL NORTE',
    municipality: 'BALOI',
    region: 'REGION X',
    barangay_list: [
      'ABAGA',
      'ADAPUN-ALI (DARIAT)',
      'ANGANDOG (BULAO)',
      'ANGAYEN (BALUT)',
      'BANGKO',
      'BATOLACONGAN (BASAGAD)',
      'BUENAVISTA',
      'CADAYONAN',
      'LANDA (GADONGAN)',
      'LUMBAC',
      'MAMAANUN',
      'MARIA-CRISTINA',
      'MATAMPAY',
      'NANGKA',
      'PACALUNDO',
      'POBLACION EAST',
      'POBLACION WEST',
      'SANDOR (DADUAN)',
      'SANGCAD (CORMATAN )',
      'SARIP-ALAWI (PAYAWAN)',
      'SIGAYAN',
    ],
  },
  BAROY: {
    province: 'LANAO DEL NORTE',
    municipality: 'BAROY',
    region: 'REGION X',
    barangay_list: [
      'ANDIL',
      'BAGONG DAWIS',
      'BAROY DAKU',
      'BATO',
      'CABASAGAN',
      'DALAMA',
      'LIBERTAD',
      'LIMWAG',
      'LINDONGAN',
      'MALIWANAG',
      'MANAN-AO',
      'PANGE',
      'PINDOLONAN',
      'POBLACION',
      'PRINCESA',
      'RAWAN POINT',
      'RIVERSIDE',
      'SAGADAN (SAGADAN LOWER)',
      'SAGADAN UPPER',
      'SALONG',
      'SAN JUAN',
      'TINUBDAN',
      'VILLAGE',
    ],
  },
  'ILIGAN': {
    province: 'LANAO DEL NORTE',
    municipality: 'ILIGAN',
    region: 'REGION X',
    barangay_list: [
      'ABUNO',
      'ACMAC',
      'BAGONG SILANG',
      'BONBONON',
      'BUNAWAN',
      'BURU-UN',
      'DALIPUGA',
      'DEL CARMEN',
      'DIGKILAAN',
      'DITUCALAN',
      'DULAG',
      'HINAPLANON',
      'HINDANG',
      'KABACSANAN',
      'KALILANGAN',
      'KIWALAN',
      'LANIPAO',
      'LUINAB',
      'MAHAYHAY',
      'MAINIT',
      'MANDULOG',
      'MARIA CRISTINA',
      'PALAO',
      'PANOROGANAN',
      'POBLACION',
      'PUGA-AN',
      'ROGONGON',
      'SAN MIGUEL',
      'SAN ROQUE',
      'SANTA ELENA',
      'SANTA FILOMENA',
      'SANTIAGO',
      'SANTO ROSARIO',
      'SARAY-TIBANGA',
      'SUAREZ',
      'TAMBACAN',
      'TIBANGA',
      'TIPANOY',
      'TOMINOBO PROPER',
      'TOMINOBO UPPER',
      'TUBOD',
      'UBALDO LAYA',
      'UPPER HINAPLANON',
      'VILLA VERDE',
    ],
  },
  KAPATAGAN: {
    province: 'LANAO DEL SUR',
    municipality: 'KAPATAGAN',
    region: 'BARMM',
    barangay_list: [
      'BAKIKIS',
      'BARAO',
      'BONGABONG',
      'DAGUAN',
      'INUDARAN',
      'KABANIAKAWAN',
      'KAPATAGAN',
      'LUSAIN',
      'MATIMOS',
      'MINIMAO',
      'PINANTAO',
      'SALAMAN',
      'SIGAYAN',
      'TABUAN',
      'UPPER IGABAY',
    ],
  },
  KAUSWAGAN: {
    province: 'LANAO DEL NORTE',
    municipality: 'KAUSWAGAN',
    region: 'REGION X',
    barangay_list: [
      'BAGUMBAYAN (POB.)',
      'BARA-ASON',
      'CAYONTOR',
      'DELABAYAN',
      'INUDARAN',
      'KAWIT OCCIDENTAL',
      'KAWIT ORIENTAL',
      'LIBERTAD',
      'PAITON',
      'POBLACION',
      'TACUB',
      'TINGINTINGIN',
      'TUGAR',
    ],
  },
  KOLAMBUGAN: {
    province: 'LANAO DEL NORTE',
    municipality: 'KOLAMBUGAN',
    region: 'REGION X',
    barangay_list: [
      'AUSTIN HEIGHTS',
      'BAYBAY',
      'BUBONG',
      'CAROMATAN',
      'INUDARAN',
      'KULASIHAN',
      'LIBERTAD',
      'LUMBAC',
      'MANGA',
      'MATAMPAY',
      'MUKAS',
      'MUNTAY',
      'PAGALUNGAN',
      'PALAO',
      'PANTAON',
      'PANTAR',
      'POBLACION',
      'REBUCON',
      'RIVERSIDE',
      'SAN ROQUE',
      'SANTO NIÑO',
      'SIMBUCO',
      'SMALL BANISILAN',
      'SUCODAN',
      'TABIGUE',
      'TITUNOD',
    ],
  },
  LALA: {
    province: 'LANAO DEL NORTE',
    municipality: 'LALA',
    region: 'REGION X',
    barangay_list: [
      'ABAGA',
      'ANDIL',
      'CABASAGAN',
      'CAMALAN',
      'DARUMAWANG BUCANA',
      'DARUMAWANG ILAYA',
      'EL SALVADOR',
      'GUMAGAMOT',
      'LALA PROPER (POB.)',
      'LANIPAO',
      'MAGPATAO',
      'MARANDING',
      'MATAMPAY BUCANA',
      'MATAMPAY ILAYA',
      'PACITA',
      'PENDOLONAN',
      'PINOYAK',
      'RAW-AN',
      'REBE',
      'SAN ISIDRO LOWER',
      'SAN ISIDRO UPPER',
      'SAN MANUEL',
      'SANTA CRUZ LOWER',
      'SANTA CRUZ UPPER',
      'SIMPAK',
      'TENAZAS',
      'TUNA-AN',
    ],
  },
  LINAMON: {
    province: 'LANAO DEL NORTE',
    municipality: 'LINAMON',
    region: 'REGION X',
    barangay_list: [
      'BUSQUE',
      'LARAPAN',
      'MAGOONG',
      'NAPO',
      'POBLACION',
      'PURAKAN',
      'ROBOCON',
      'SAMBURON',
    ],
  },
  MAGSAYSAY: {
    province: 'PALAWAN',
    municipality: 'MAGSAYSAY',
    region: 'REGION IV-B',
    barangay_list: [
      'ALCOBA',
      'BALAGUEN',
      'CANIPO',
      'COCORO',
      'DANAWAN (POB.)',
      'EMILOD',
      'IGABAS',
      'LACAREN',
      'LOS ANGELES',
      'LUCBUAN',
      'RIZAL',
    ],
  },
  MAIGO: {
    province: 'LANAO DEL NORTE',
    municipality: 'MAIGO',
    region: 'REGION X',
    barangay_list: [
      'BALAGATASA',
      'CAMP 1',
      'CLARO M. RECTO',
      'INOMA',
      'KULASIHAN (VILLANUEVA)',
      'LABUAY',
      'LIANGAN WEST',
      'MAHAYAHAY',
      'MALIWANAG',
      'MENTRING',
      'POBLACION',
      'SANTA CRUZ',
      'SOGAPOD',
    ],
  },
  MATUNGAO: {
    province: 'LANAO DEL NORTE',
    municipality: 'MATUNGAO',
    region: 'REGION X',
    barangay_list: [
      'BANGCO',
      'BATAL',
      'BATANGAN',
      'BUBONG RADAPAN',
      'CADAYONAN',
      'MATAMPAY',
      'PANGI',
      'PASAYANON',
      'POBLACION (MATUNGAO)',
      'PUNTOD',
      'SANTA CRUZ',
      'SOMIORANG',
    ],
  },
  MUNAI: {
    province: 'LANAO DEL NORTE',
    municipality: 'MUNAI',
    region: 'REGION X',
    barangay_list: [
      'BACAYAWAN',
      'BALABACUN',
      'BALINTAD',
      'DALAMA',
      'KADAYONAN',
      'LINDONGAN',
      'LINGCO-AN',
      'LININDING',
      'LUMBA-BAYABAO',
      'MADAYA',
      'MAGANDING',
      'MATAMPAY',
      'NORTH CADULAWAN',
      'OLD POBLACION',
      'PANGGAO',
      'PANTAO',
      'PANTAO-A-MUNAI',
      'PANTAON',
      'PINDOLONAN',
      'PUNONG',
      'RAMAIN',
      'SANDIGAMUNAI',
      'TAGORANAO',
      'TAMBO',
      'TAMPARAN (MANDAYA)',
      'TAPOROG',
    ],
  },
  NUNUNGAN: {
    province: 'LANAO DEL NORTE',
    municipality: 'NUNUNGAN',
    region: 'REGION X',
    barangay_list: [
      'ABAGA',
      'BANGCO',
      'CABASARAN (LAYA)',
      'CANIBONGAN',
      'DIMAYON',
      'INAYAWAN',
      'KALUDAN',
      'KARCUM',
      'KATUBUAN',
      'LIANGAN',
      'LUPITAN',
      'MALAIG',
      'MANGAN',
      'MASIBAY',
      'NOTONGAN',
      'PANGANAPAN',
      'PANTAR',
      'PARIDI',
      'PETADUN',
      'POBLACION (NUNUNGAN PROPER)',
      'RARAB',
      'RARABAN',
      'REBUCON',
      'SONGGOD',
      'TARAKA',
    ],
  },
  'PANTAO RAGAT': {
    province: 'LANAO DEL NORTE',
    municipality: 'PANTAO RAGAT',
    region: 'REGION X',
    barangay_list: [
      'ALOON',
      'BANDAY',
      'BOBONGA PANTAO RAGAT',
      'BOBONGA RADAPAN',
      'CABASAGAN',
      'CALAWE',
      'CULUBUN',
      'DILIMBAYAN',
      'DIMAYON',
      'LOMIDONG',
      'MADAYA',
      'MALIWANAG',
      'MATAMPAY',
      'NATANGCOPAN',
      'PANSOR',
      'PANTAO MARUG',
      'POBLACION EAST',
      'POBLACION WEST',
      'TANGCAL',
      'TONGCOPAN',
    ],
  },
  PANTAR: {
    province: 'LANAO DEL NORTE',
    municipality: 'PANTAR',
    region: 'REGION X',
    barangay_list: [
      'BANGCAL',
      'BOWI',
      'BUBONG MADAYA',
      'CABASARAN',
      'CADAYONAN',
      'CAMPONG',
      'DIBAROSAN',
      'KALANGANAN EAST',
      'KALANGANAN LOWER',
      'KALILANGAN',
      'LUMBA-PUNOD',
      'PANTAO-MARUG',
      'PANTAO-RANAO',
      'PANTAR EAST',
      'PITUBO',
      'POBLACION',
      'POONA-PUNOD',
      'PUNOD',
      'SUNDIGA-PUNOD',
      'TAWANAN',
      'WEST PANTAR',
    ],
  },
  'POONA PIAGAPO': {
    province: 'LANAO DEL NORTE',
    municipality: 'POONA PIAGAPO',
    region: 'REGION X',
    barangay_list: [
      'ALOWIN',
      'BUBONG-DINAIG',
      'CABASARAN',
      'CADAYONAN',
      'CAROMATAN',
      'DARAMBA',
      'DINAIG',
      'KABLANGAN',
      'LININDINGAN',
      'LUMBATAN',
      'LUPITAN',
      'MADAMBA',
      'MADAYA',
      'MALIWANAG',
      'NUNANG',
      'NUNUNGAN',
      'PANTAO RAYA',
      'PANTAON',
      'PENDOLONAN',
      'PENED',
      'PIANGAMANGAAN',
      'POBLACION (LUMBACAINGUD)',
      'SULO',
      'TAGORANAO',
      'TANGCLAO',
      'TIMBANGALAN',
    ],
  },
  SALVADOR: {
    province: 'LANAO DEL NORTE',
    municipality: 'SALVADOR',
    region: 'REGION X',
    barangay_list: [
      'BARANDIA',
      'BULACON',
      'BUNTONG',
      'CALIMODAN',
      'CAMP III',
      'CURVA-MIAGAO',
      'DALIGDIGAN',
      'INASAGAN',
      'KILALA',
      'MABATAO',
      'MADAYA',
      'MAMAANON',
      'MAPANTAO',
      'MINDALANO',
      'PADIANAN',
      'PAGALONGAN',
      'PAGAYAWAN',
      'PANALIWAD-ON',
      'PANGANTAPAN',
      'PANSOR',
      'PATIDON',
      'PAWAK',
      'POBLACION',
      'SAUMAY',
      'SUDLON',
    ],
  },
  SAPAD: {
    province: 'LANAO DEL NORTE',
    municipality: 'SAPAD',
    region: 'REGION X',
    barangay_list: [
      'BANING',
      'BURIASAN (POB.)',
      'DANSALAN',
      'GAMAL',
      'INUDARAN I',
      'INUDARAN II',
      'KARKUM',
      'KATIPUNAN',
      'MABUGNAO',
      'MAITO SALUG',
      'MALA SALUG',
      'MAMA-ANON',
      'MAPUROG',
      'PANCILAN',
      'PANOLOON',
      'PILI',
      'SAPAD',
    ],
  },
  'SULTAN NAGA DIMAPORO (KAROMATAN)': {
    province: 'LANAO DEL NORTE',
    municipality: 'SULTAN NAGA DIMAPORO (KAROMATAN)',
    region: 'REGION X',
    barangay_list: [
      'BANGAAN',
      'BANGCO',
      'BANSARVIL II',
      'BAUYAN',
      'CABONGBONGAN',
      'CALIBAO',
      'CALIPAPA',
      'CALUBE',
      'CAMPO ISLAM',
      'CAPOCAO',
      'DABLISTON',
      'DALAMA',
      'DANGULAAN',
      'DITAGO',
      'ILIAN',
      'KAUSWAGAN',
      'KIRAPAN',
      'KOREO',
      'LANTAWAN',
      'MABUHAY',
      'MAGUINDANAO',
      'MAHAYAHAY',
      'MAMAGUM',
      'MINA',
      'PANDANAN',
      'PAYONG',
      'PIKALAWAG',
      'PIKINIT',
      'PIRAKA',
      'POBLACION',
      'RAMAIN',
      'REBUCON',
      'SIGAYAN',
      'SUGOD',
      'TAGULO',
      'TANTAON',
      'TOPOCON (CAPOCGO)',
    ],
  },
  TAGOLOAN: {
    province: 'LANAO DEL SUR',
    municipality: 'TAGOLOAN',
    region: 'BARMM',
    barangay_list: [
      'BAGOAINGUD',
      'BANTALAN',
      'BAYOG',
      'CADAYONAN',
      'DAGONALAN',
      'DIMALAMA',
      'GAYAKAY',
      'INODARAN',
      'KALILANGAN',
      'KIANIBONG',
      'KINGAN',
      'KITAON',
      'MAIMBAGUIANG',
      'MALINAO',
      'MALINGON',
      'MAMA-AN PAGALONGAN',
      'MARAWI',
      'SIGAYAN',
      'TAGOLOAN POBLACION',
    ],
  },
  TANGCAL: {
    province: 'LANAO DEL NORTE',
    municipality: 'TANGCAL',
    region: 'REGION X',
    barangay_list: [
      'BAYABAO',
      'BERWAR',
      'BIG BANISILON',
      'BIG MELADOC',
      'BUBONG',
      'LAMAOSA',
      'LINAO',
      'LINDONGAN',
      'LINGCO-AN',
      'PAPAN',
      'PELINGKINGAN',
      'POBLACION',
      'POONA KAPATAGAN',
      'PUNOD',
      'SMALL BANISILON',
      'SMALL MELADOC',
      'SOMIORANG',
      'TANGCAL PROPER',
    ],
  },
  TUBOD: {
    province: 'SURIGAO DEL NORTE',
    municipality: 'TUBOD',
    region: 'REGION XIII',
    barangay_list: [
      'CAPAYAHAN',
      'CAWILAN',
      'DEL ROSARIO',
      'MARGA',
      'MOTORPOOL',
      'POBLACION (TUBOD)',
      'SAN ISIDRO',
      'SAN PABLO',
      'TIMAMANA',
    ],
  },
  ALORAN: {
    province: 'MISAMIS OCCIDENTAL',
    municipality: 'ALORAN',
    region: 'REGION X',
    barangay_list: [
      'BALINTONGA',
      'BANISILON',
      'BURGOS',
      'CALUBE',
      'CAPUTOL',
      'CASUSAN',
      'CONAT',
      'CULPAN',
      'DALISAY',
      'DULLAN',
      'IBABAO',
      'LABO',
      'LAWA-AN',
      'LOBOGON',
      'LUMBAYAO',
      'MACUBON (SINA-AD)',
      'MAKAWA',
      'MANAMONG',
      'MATIPAZ',
      'MAULAR',
      'MITAZAN',
      'MOHON',
      'MONTERICO',
      'NABUNA',
      'OSPITAL (POB.)',
      'PALAYAN',
      'PELONG',
      'ROXAS',
      'SAN PEDRO',
      'SANTA ANA',
      'SINAMPONGAN',
      'TAGUANAO',
      'TAWI-TAWI',
      'TORIL',
      'TUBOD (JUAN BACAYO)',
      'TUBURAN',
      'TUGAYA',
      'ZAMORA',
    ],
  },
  BALIANGAO: {
    province: 'MISAMIS OCCIDENTAL',
    municipality: 'BALIANGAO',
    region: 'REGION X',
    barangay_list: [
      'DEL PILAR',
      'LANDING',
      'LUMIPAC',
      'LUSOT',
      'MABINI',
      'MAGSAYSAY',
      'MISOM',
      'MITACAS',
      'NABUROS',
      'NORTHERN POBLACION',
      'PUNTA MIRAY',
      'PUNTA SULONG',
      'SINIAN',
      'SOUTHERN POBLACION',
      'TUGAS',
    ],
  },
  BONIFACIO: {
    province: 'MISAMIS OCCIDENTAL',
    municipality: 'BONIFACIO',
    region: 'REGION X',
    barangay_list: [
      'BAG-ONG ANONANG',
      'BAGUMBANG',
      'BAYBAY',
      'BOLINSONG',
      'BUENAVISTA',
      'BURACAN',
      'CALOLOT',
      'DEMETRIO FERNAN',
      'DIGSON',
      'DIMALCO',
      'DULLAN',
      'KANAOKANAO',
      'LILOAN',
      'LINCONAN',
      'LODIONG',
      'LOWER USUGAN',
      'MAPUROG (MIGSALE)',
      'MIGPANGE',
      'MONTOL',
      'PISA-AN',
      'POBLACION (CENTRO)',
      'REMEDIOS',
      'RUFINO LUMAPAS',
      'SIBUYON',
      'TANGAB',
      'TIAMAN',
      'TUSIK',
      'UPPER USOGAN',
    ],
  },
  CALAMBA: {
    province: 'MISAMIS OCCIDENTAL',
    municipality: 'CALAMBA',
    region: 'REGION X',
    barangay_list: [
      'BONIFACIO',
      'BUNAWAN',
      'CALARAN',
      'DAPACAN ALTO',
      'DAPACAN BAJO',
      'DON BERNARDO NERI POB. (TRES DE MAYO)',
      'LANGUB',
      'LIBERTAD',
      'MAGCAMIGUING',
      'MAMALAD',
      'MAUSWAGON',
      'NORTHERN POBLACION',
      'SALVADOR',
      'SAN ISIDRO (SAN ISIDRO-SAN PEDRO)',
      'SILOY',
      'SINGALAT',
      'SOLINOG',
      'SOUTHWESTERN POBLACION',
      'SULIPAT',
    ],
  },
  CLARIN: {
    province: 'BOHOL',
    municipality: 'CLARIN',
    region: 'REGION VII',
    barangay_list: [
      'BACANI',
      'BOGTONGBOD',
      'BONBON',
      'BONTUD',
      'BUACAO',
      'BUANGAN',
      'CABOG',
      'CABOY',
      'CALUWASAN',
      'CANDAJEC',
      'CANTOYOC',
      'COMAANG',
      'DANAHAO',
      'KATIPUNAN',
      'LAJOG',
      'MATAUB',
      'NAHAWAN',
      'POBLACION CENTRO',
      'POBLACION NORTE',
      'POBLACION SUR',
      'TANGARAN',
      'TONTUNAN',
      'TUBOD',
      'VILLAFLOR',
    ],
  },
  CONCEPCION: {
    province: 'ILOILO',
    municipality: 'CONCEPCION',
    region: 'REGION VI',
    barangay_list: [
      'AGLOSONG',
      'AGNAGA',
      'BACJAWAN NORTE',
      'BACJAWAN SUR',
      'BAGONGON',
      'BATITI',
      'BOTLOG',
      'CALAMIGAN',
      'DUNGON',
      'IGBON',
      'JAMUL-AWON',
      'LO-ONG',
      'MACALBANG',
      'MACATUNAO',
      'MALANGABANG',
      'MALIOGLIOG',
      'NIÑO',
      'NIPA',
      'PLANDICO',
      'POBLACION',
      'POLOPINA',
      'SALVACION',
      'TALOTU-AN',
      'TAMBALIZA',
      'TAMIS-AC',
    ],
  },
  'DON VICTORIANO CHIONGBIAN (DON MARIANO MARCOS)': {
    province: 'MISAMIS OCCIDENTAL',
    municipality: 'DON VICTORIANO CHIONGBIAN (DON MARIANO MARCOS)',
    region: 'REGION X',
    barangay_list: [
      'BAGONG CLARIN',
      'GANDAWAN',
      'LAKE DUMINAGAT',
      'LALUD',
      'LAMPASAN',
      'LIBORON',
      'MARAMARA',
      'NAPANGAN',
      'NUEVA VISTA (MASAWAN)',
      'PETIANAN',
      'TUNO',
    ],
  },
  JIMENEZ: {
    province: 'MISAMIS OCCIDENTAL',
    municipality: 'JIMENEZ',
    region: 'REGION X',
    barangay_list: [
      'ADORABLE',
      'BUTUAY',
      'CARMEN',
      'CORRALES',
      'DICOLOC',
      'GATA',
      'GUINTOMOYAN',
      'MACABAYAO',
      'MALIBACSAN',
      'MATUGAS ALTO',
      'MATUGAS BAJO',
      'MIALEM',
      'NACIONAL (POB.)',
      'NAGA (POB.)',
      'PALILAN',
      'RIZAL (POB.)',
      'SAN ISIDRO',
      'SANTA CRUZ (POB.)',
      'SETI',
      'SIBAROC',
      'SINARA ALTO',
      'SINARA BAJO',
      'TABO-O',
      'TARAKA (POB.)',
    ],
  },
  'LOPEZ JAENA': {
    province: 'MISAMIS OCCIDENTAL',
    municipality: 'LOPEZ JAENA',
    region: 'REGION X',
    barangay_list: [
      'ALEGRIA',
      'BAGONG SILANG',
      'BIASONG',
      'BONIFACIO',
      'BURGOS',
      'DALACON',
      'DAMPALAN',
      'DON ANDRES SORIANO',
      'EASTERN POBLACION',
      'ESTANTE',
      'JASA-AN',
      'KATIPA',
      'LUZARAN',
      'MABAS',
      'MACALIBRE ALTO',
      'MACALIBRE BAJO',
      'MAHAYAHAY',
      'MANGUEHAN',
      'MANSABAY ALTO',
      'MANSABAY BAJO',
      'MOLATUHAN ALTO',
      'MOLATUHAN BAJO',
      'PENIEL',
      'PUNTOD',
      'RIZAL',
      'SIBUGON',
      'SIBULA',
      'WESTERN POBLACION',
    ],
  },
  'OROQUIETA': {
    province: 'MISAMIS OCCIDENTAL',
    municipality: 'OROQUIETA',
    region: 'REGION X',
    barangay_list: [
      'APIL',
      'BINUANGAN',
      'BOLIBOL',
      'BUENAVISTA',
      'BUNGA',
      'BUNTAWAN',
      'BURGOS',
      'CANUBAY',
      'CIRIACO C. PASTRANO (NILABO)',
      'CLARIN SETTLEMENT',
      'DOLIPOS ALTO',
      'DOLIPOS BAJO',
      'DULAPO',
      'DULLAN NORTE',
      'DULLAN SUR',
      'LAMAC LOWER',
      'LAMAC UPPER',
      'LANGCANGAN LOWER',
      'LANGCANGAN PROPER',
      'LANGCANGAN UPPER',
      'LAYAWAN',
      'LOBOC LOWER',
      'LOBOC UPPER',
      'MALINDANG',
      'MIALEN',
      'MOBOD',
      'PAYPAYAN',
      'PINES',
      'POBLACION I',
      'POBLACION II',
      'RIZAL LOWER',
      'RIZAL UPPER',
      'SAN VICENTE ALTO',
      'SAN VICENTE BAJO',
      'SEBUCAL',
      'SENOTE',
      'TABOC NORTE',
      'TABOC SUR',
      'TALAIRON',
      'TALIC',
      'TIPAN',
      'TOLIYOK',
      'TUYABANG ALTO',
      'TUYABANG BAJO',
      'TUYABANG PROPER',
      'VICTORIA',
      'VILLAFLOR',
    ],
  },
  'OZAMIS': {
    province: 'MISAMIS OCCIDENTAL',
    municipality: 'OZAMIS',
    region: 'REGION X',
    barangay_list: [
      '50TH DISTRICT (POB.)',
      'AGUADA (POB.)',
      'BACOLOD',
      'BAGAKAY',
      'BALINTAWAK',
      'BANADERO (POB.)',
      'BAYBAY SAN ROQUE',
      'BAYBAY SANTA CRUZ',
      'BAYBAY TRIUNFO',
      'BONGBONG',
      'CALABAYAN',
      'CAPUCAO C.',
      'CAPUCAO P.',
      'CARANGAN',
      'CARMEN (MISAMIS ANNEX)',
      'CATADMAN-MANABAY',
      'CAVINTE',
      'COGON',
      'DALAPANG',
      'DIGUAN',
      'DIMALUNA',
      'DOÑA CONSUELO',
      'EMBARGO',
      'GALA',
      'GANGO',
      'GOTOKAN DAKU',
      'GOTOKAN DIOT',
      'GUIMAD',
      'GUINGONA',
      'KINUMAN NORTE',
      'KINUMAN SUR',
      'LABINAY',
      'LABO',
      'LAM-AN',
      'LIPOSONG',
      'LITAPAN',
      'MALAUBANG',
      'MANAKA',
      'MANINGCOL',
      'MENTERING',
      'MOLICAY',
      'PANTAON',
      'PULOT',
      'SAN ANTONIO',
      'SANGAY DAKU',
      'SANGAY DIOT',
      'SINUZA',
      'STIMSON ABORDO (MONTOL)',
      'TABID',
      'TINAGO',
      'TRIGOS',
    ],
  },
  PANAON: {
    province: 'MISAMIS OCCIDENTAL',
    municipality: 'PANAON',
    region: 'REGION X',
    barangay_list: [
      'BAGA',
      'BANGKO',
      'CAMANUCAN',
      'DELA PAZ',
      'LUTAO',
      'MAGSAYSAY',
      'MAP-AN',
      'MOHON',
      'POBLACION',
      'PUNTA',
      'SALIMPUNO',
      'SAN ANDRES',
      'SAN JUAN',
      'SAN ROQUE',
      'SUMASAP',
      'VILLALIN',
    ],
  },
  PLARIDEL: {
    province: 'QUEZON',
    municipality: 'PLARIDEL',
    region: 'REGION IV-A',
    barangay_list: [
      'CENTRAL (POB.)',
      'CONCEPCION',
      'DUHAT',
      'ILAYA',
      'ILOSONG',
      'M. L. TUMAGAY POB. (REMAS MABUTON)',
      'PAANG BUNDOK (POB.)',
      'PAMPAARALAN (POB.)',
      'TANAUAN',
    ],
  },
  'SAPANG DALAGA': {
    province: 'MISAMIS OCCIDENTAL',
    municipality: 'SAPANG DALAGA',
    region: 'REGION X',
    barangay_list: [
      'AGAPITO YAP, SR. (NAPILAN)',
      'BAUTISTA',
      'BITIBUT',
      'BOUNDARY',
      'CALUYA',
      'CAPUNDAG',
      'CASUL',
      'DALUMPINAS',
      'DASA',
      'DIOYO',
      'DISOY',
      'EL PARAISO',
      'GUINABOT',
      'LIBERTAD',
      'LOCUS',
      'MACABIBO',
      'MANLA',
      'MASUBONG',
      'MEDALLO',
      'POBLACION',
      'SALIMPUNO',
      'SAN AGUSTIN',
      'SAPANG AMA',
      'SINAAD',
      'SIPAC',
      'SIXTO VELEZ, SR.',
      'UPPER BAUTISTA',
      'VENTURA',
    ],
  },
  SINACABAN: {
    province: 'MISAMIS OCCIDENTAL',
    municipality: 'SINACABAN',
    region: 'REGION X',
    barangay_list: [
      'CAGAY-ANON',
      'CAMANSE',
      'COLUPAN ALTO',
      'COLUPAN BAJO',
      'DINAS',
      'ESTRELLA',
      'KATIPUNAN',
      'LIBERTAD ALTO',
      'LIBERTAD BAJO',
      'POBLACION',
      'SAN ISIDRO ALTO',
      'SAN ISIDRO BAJO',
      'SAN LORENZO RUIZ (SUNGAN)',
      'SAN VICENTE',
      'SEÑOR',
      'SINONOC',
      'TIPAN',
    ],
  },
  'TANGUB': {
    province: 'MISAMIS OCCIDENTAL',
    municipality: 'TANGUB',
    region: 'REGION X',
    barangay_list: [
      'AQUINO (MARCOS)',
      'BALATACAN',
      'BALUK',
      'BANGLAY',
      'BARANGAY I - CITY HALL (POB.)',
      'BARANGAY II - MARILOU ANNEX (POB.)',
      'BARANGAY III- MARKET KALUBIAN (POB.)',
      'BARANGAY IV - ST. MICHAEL (POB.)',
      'BARANGAY V - MALUBOG (POB.)',
      'BARANGAY VI - LOWER POLAO (POB.)',
      'BARANGAY VII - UPPER POLAO (POB.)',
      'BINTANA',
      'BOCATOR',
      'BONGABONG',
      'CANIANGAN',
      'CAPALARAN',
      'CATAGAN',
      'GARANG',
      'GUINABOT',
      'GUINALABAN',
      'HUYOHOY',
      'ISIDRO D. TAN (DIMALOC-OC)',
      'KAUSWAGAN',
      'KIMAT',
      'LABUYO',
      'LORENZO TAN',
      'LUMBAN',
      'MALORO',
      'MANGA',
      'MANTIC',
      'MAQUILAO',
      'MATUGNAW',
      'MIGCANAWAY',
      'MINSUBONG',
      'OWAYAN',
      'PAITON',
      'PANALSALAN',
      'PANGABUAN',
      'PRENZA',
      'SALIMPUNO',
      'SAN ANTONIO',
      'SAN APOLINARIO',
      'SAN VICENTE',
      'SANTA CRUZ',
      'SANTA MARIA (BAGA)',
      'SANTO NIÑO',
      'SICOT',
      'SILANGA',
      'SILANGIT',
      'SIMASAY',
      'SUMIRAP',
      'TAGUITE',
      'TITURON',
      'TUGAS',
      'VILLABA',
    ],
  },
  TUDELA: {
    province: 'CEBU',
    municipality: 'TUDELA',
    region: 'REGION VII',
    barangay_list: [
      'BUENAVISTA',
      'CALMANTE',
      'DAAN SECANTE',
      'GENERAL',
      'MCARTHUR',
      'NORTHERN POBLACION',
      'PUERTOBELLO',
      'SANTANDER',
      'SECANTE BAG-O',
      'SOUTHERN POBLACION',
      'VILLAHERMOSA',
    ],
  },
  ALUBIJID: {
    province: 'MISAMIS ORIENTAL',
    municipality: 'ALUBIJID',
    region: 'REGION X',
    barangay_list: [
      'BAYBAY',
      'BENIGWAYAN',
      'CALATCAT',
      'LAGTANG',
      'LANAO',
      'LOGUILO',
      'LOURDES',
      'LUMBO',
      'MOLOCBOLOC',
      'POBLACION',
      'SAMPATULOG',
      'SUNGAY',
      'TALABA',
      'TAPARAK',
      'TUGASNON',
      'TULA',
    ],
  },
  BALINGASAG: {
    province: 'MISAMIS ORIENTAL',
    municipality: 'BALINGASAG',
    region: 'REGION X',
    barangay_list: [
      'BALAGNAN',
      'BALIWAGAN',
      'BARANGAY 1 (POB.)',
      'BARANGAY 2 (POB.)',
      'BARANGAY 3 (POB.)',
      'BARANGAY 4 (POB.)',
      'BARANGAY 5 (POB.)',
      'BARANGAY 6 (POB.)',
      'BINITINAN',
      'BLANCO',
      'CALAWAG',
      'CAMUAYAN',
      'COGON',
      'DANSULI',
      'DUMARAIT',
      'HERMANO',
      'KIBANBAN',
      'LINABU',
      'LINGGANGAO',
      'MAMBAYAAN',
      'MANDANGOA',
      'NAPALIRAN',
      'QUEZON',
      'ROSARIO',
      'SAMAY',
      'SAN FRANCISCO',
      'SAN ISIDRO',
      'SAN JUAN',
      'TALUSAN',
      'WATERFALL',
    ],
  },
  BALINGOAN: {
    province: 'MISAMIS ORIENTAL',
    municipality: 'BALINGOAN',
    region: 'REGION X',
    barangay_list: [
      'BAUKBAUK POB. (G.E. ANTONINO)',
      'DAHILIG',
      'KABANGASAN',
      'KABULAKAN',
      'KAUSWAGAN',
      'LAPINIG (POB.)',
      'MANTANGALE',
      'MAPUA',
      'SAN ALONZO',
    ],
  },
  BINUANGAN: {
    province: 'MISAMIS ORIENTAL',
    municipality: 'BINUANGAN',
    region: 'REGION X',
    barangay_list: [
      'DAMPIAS',
      'KITAMBAN',
      'KITAMBIS',
      'MABINI',
      'MOSANGOT',
      'NABATAAN',
      'POBLACION',
      'VALDECONCHA',
    ],
  },
  'CAGAYAN DE ORO': {
    province: 'MISAMIS ORIENTAL',
    municipality: 'CAGAYAN DE ORO',
    region: 'REGION X',
    barangay_list: [
      'AGUSAN',
      'BAIKINGON',
      'BALUBAL',
      'BALULANG',
      'BARANGAY 1 (POB.)',
      'BARANGAY 2 (POB.)',
      'BARANGAY 3 (POB.)',
      'BARANGAY 4 (POB.)',
      'BARANGAY 5 (POB.)',
      'BARANGAY 6 (POB.)',
      'BARANGAY 7 (POB.)',
      'BARANGAY 8 (POB.)',
      'BARANGAY 9 (POB.)',
      'BARANGAY 10 (POB.)',
      'BARANGAY 11 (POB.)',
      'BARANGAY 12 (POB.)',
      'BARANGAY 13 (POB.)',
      'BARANGAY 14 (POB.)',
      'BARANGAY 15 (POB.)',
      'BARANGAY 16 (POB.)',
      'BARANGAY 17 (POB.)',
      'BARANGAY 18 (POB.)',
      'BARANGAY 19 (POB.)',
      'BARANGAY 20 (POB.)',
      'BARANGAY 21 (POB.)',
      'BARANGAY 22 (POB.)',
      'BARANGAY 23 (POB.)',
      'BARANGAY 24 (POB.)',
      'BARANGAY 25 (POB.)',
      'BARANGAY 26 (POB.)',
      'BARANGAY 27 (POB.)',
      'BARANGAY 28 (POB.)',
      'BARANGAY 29 (POB.)',
      'BARANGAY 30 (POB.)',
      'BARANGAY 31 (POB.)',
      'BARANGAY 32 (POB.)',
      'BARANGAY 33 (POB.)',
      'BARANGAY 34 (POB.)',
      'BARANGAY 35 (POB.)',
      'BARANGAY 36 (POB.)',
      'BARANGAY 37 (POB.)',
      'BARANGAY 38 (POB.)',
      'BARANGAY 39 (POB.)',
      'BARANGAY 40 (POB.)',
      'BAYABAS',
      'BAYANGA',
      'BESIGAN',
      'BONBON',
      'BUGO',
      'BULUA',
      'CAMAMAN-AN',
      'CANITO-AN',
      'CARMEN',
      'CONSOLACION',
      'CUGMAN',
      'DANSOLIHON',
      'F. S. CATANICO',
      'GUSA',
      'INDAHAG',
      'IPONAN',
      'KAUSWAGAN',
      'LAPASAN',
      'LUMBIA',
      'MACABALAN',
      'MACASANDIG',
      'MAMBUAYA',
      'NAZARETH',
      'PAGALUNGAN',
      'PAGATPAT',
      'PATAG',
      'PIGSAG-AN',
      'PUERTO',
      'PUNTOD',
      'SAN SIMON',
      'TABLON',
      'TAGLIMAO',
      'TAGPANGI',
      'TIGNAPOLOAN',
      'TUBURAN',
      'TUMPAGON',
    ],
  },
  CLAVERIA: {
    province: 'MASBATE',
    municipality: 'CLAVERIA',
    region: 'REGION V',
    barangay_list: [
      'ALBASAN',
      'BOCA ENGAÑO',
      'BUYO',
      'CALPI',
      'CANOMAY',
      'CAWAYAN',
      'IMELDA',
      'MABABANG BAYBAY',
      'MABITON',
      'MANAPAO',
      'NABASAGAN',
      'NONOC',
      'OSMEÑA',
      'PASIG',
      'PEÑAFRANCIA',
      'POBLACION DISTRICT I (BGY. 1)',
      'POBLACION DISTRICT II (BGY. 2)',
      'QUEZON',
      'SAN ISIDRO',
      'SAN RAMON',
      'SAN VICENTE',
      'TAGUILID',
    ],
  },
  'EL SALVADOR': {
    province: 'MISAMIS ORIENTAL',
    municipality: 'EL SALVADOR',
    region: 'REGION X',
    barangay_list: [
      'AMOROS',
      'BOLISONG',
      'BOLOBOLO',
      'CALONGONAN',
      'COGON',
      'HIMAYA',
      'HINIGDAAN',
      'KALABAYLABAY',
      'KIBONBON',
      'MOLUGAN',
      'POBLACION',
      'SAMBULAWAN',
      'SINALOC',
      'TAYTAY',
      'ULALIMAN',
    ],
  },
  'GINGOOG': {
    province: 'MISAMIS ORIENTAL',
    municipality: 'GINGOOG',
    region: 'REGION X',
    barangay_list: [
      'AGAY-AYAN',
      'ALAGATAN',
      'ANAKAN',
      'BAGUBAD',
      'BAKIDBAKID',
      'BAL-ASON',
      'BANTAAWAN',
      'BARANGAY 1 (POB.)',
      'BARANGAY 2 (POB.)',
      'BARANGAY 3 (POB.)',
      'BARANGAY 4 (POB.)',
      'BARANGAY 5 (POB.)',
      'BARANGAY 6 (POB.)',
      'BARANGAY 7 (POB.)',
      'BARANGAY 8 (POB.)',
      'BARANGAY 9 (POB.)',
      'BARANGAY 10 (POB.)',
      'BARANGAY 11 (POB.)',
      'BARANGAY 12 (POB.)',
      'BARANGAY 13 (POB.)',
      'BARANGAY 14 (POB.)',
      'BARANGAY 15 (POB.)',
      'BARANGAY 16 (POB.)',
      'BARANGAY 17 (POB.)',
      'BARANGAY 18 (POB.)',
      'BARANGAY 18-A (POB.)',
      'BARANGAY 19 (POB.)',
      'BARANGAY 20 (POB.)',
      'BARANGAY 21 (POB.)',
      'BARANGAY 22 (POB.)',
      'BARANGAY 22-A (POB.)',
      'BARANGAY 23 (POB.)',
      'BARANGAY 24 (POB.)',
      'BARANGAY 24-A (POB.)',
      'BARANGAY 25 (POB.)',
      'BARANGAY 26 (POB.)',
      'BINAKALAN',
      'CAPITULANGAN',
      'DAAN-LUNGSOD',
      'DINAWEHAN',
      'EUREKA',
      'HINDANGON',
      'KALAGONOY',
      'KALIPAY',
      'KAMANIKAN',
      'KIANLAGAN',
      'KIBUGING',
      'KIPUNTOS',
      'LAWAAN',
      'LAWIT',
      'LIBERTAD',
      'LIBON',
      'LUNAO',
      'LUNOTAN',
      'MALIBUD',
      'MALINAO',
      'MARIBUCAO',
      'MIMBALAGON',
      'MIMBUNGA',
      'MIMBUNTONG',
      'MINSAPINIT',
      'MURALLON',
      'ODIONGAN',
      'PANGASIHAN',
      'PIGSALUHAN',
      'PUNONG',
      'RICORO',
      'SAMAY',
      'SAN JOSE',
      'SAN JUAN',
      'SAN LUIS',
      'SAN MIGUEL',
      'SANGALAN',
      'SANTIAGO',
      'TAGPAKO',
      'TALISAY',
      'TALON',
      'TINABALAN',
      'TINULONGAN',
    ],
  },
  GITAGUM: {
    province: 'MISAMIS ORIENTAL',
    municipality: 'GITAGUM',
    region: 'REGION X',
    barangay_list: [
      'BURNAY',
      'CARLOS P. GARCIA',
      'COGON',
      'GREGORIO PELAEZ (LAGUTAY)',
      'KILANGIT',
      'MATANGAD',
      'PANGAYAWAN',
      'POBLACION',
      'QUEZON',
      'TALA-O',
      'ULAB',
    ],
  },
  INITAO: {
    province: 'MISAMIS ORIENTAL',
    municipality: 'INITAO',
    region: 'REGION X',
    barangay_list: [
      'ALUNA',
      'ANDALES',
      'APAS',
      'CALACAPAN',
      'GIMANGPANG',
      'JAMPASON',
      'KAMELON',
      'KANITOAN',
      'OGUIS',
      'PAGAHAN',
      'POBLACION',
      'PONTACON',
      'SAN PEDRO',
      'SINALAC',
      'TAWANTAWAN',
      'TUBIGAN',
    ],
  },
  JASAAN: {
    province: 'MISAMIS ORIENTAL',
    municipality: 'JASAAN',
    region: 'REGION X',
    barangay_list: [
      'APLAYA',
      'BOBONTUGAN',
      'CORRALES',
      'DANAO',
      'I. S. CRUZ',
      'JAMPASON',
      'KIMAYA',
      'LOWER JASAAN (POB.)',
      'LUZ BANZON',
      'NATUBO',
      'SAN ANTONIO',
      'SAN ISIDRO',
      'SAN NICOLAS',
      'SOLANA',
      'UPPER JASAAN (POB.)',
    ],
  },
  KINOGUITAN: {
    province: 'MISAMIS ORIENTAL',
    municipality: 'KINOGUITAN',
    region: 'REGION X',
    barangay_list: [
      'BERAY',
      'BOLISONG',
      'BUKO',
      'CALUBO',
      'CAMPO',
      'ESPERANZA',
      'KAGUMAHAN',
      'KALITIAN',
      'KITOTOK',
      'PANABOL',
      'POBLACION',
      'SALICAPAWAN',
      'SALUBSOB',
      'SUAREZ',
      'SUMALAG',
    ],
  },
  LAGONGLONG: {
    province: 'MISAMIS ORIENTAL',
    municipality: 'LAGONGLONG',
    region: 'REGION X',
    barangay_list: [
      'BANGLAY',
      'DAMPIL',
      'GASTON',
      'KABULAWAN',
      'KAUSWAGAN',
      'LUMBO',
      'MANAOL',
      'POBLACION',
      'TABOK',
      'UMAGOS',
    ],
  },
  LAGUINDINGAN: {
    province: 'MISAMIS ORIENTAL',
    municipality: 'LAGUINDINGAN',
    region: 'REGION X',
    barangay_list: [
      'AROMAHON',
      'GASI',
      'KIBAGHOT',
      'LAPAD',
      'LIBERTY',
      'MAUSWAGON',
      'MOOG',
      'POBLACION',
      'SAMBULAWAN',
      'SINAI',
      'TUBAJON',
    ],
  },
  LIBERTAD: {
    province: 'ANTIQUE',
    municipality: 'LIBERTAD',
    region: 'REGION VI',
    barangay_list: [
      'BARUSBUS',
      'BULANAO',
      'CENTRO ESTE (POB.)',
      'CENTRO WESTE (POB.)',
      'CODIONG',
      'CUBAY',
      'IGCAGAY',
      'INYAWAN',
      'LINDERO',
      'MARAMIG',
      'PAJO',
      'PANANGKILON',
      'PAZ',
      'PUCIO',
      'SAN ROQUE',
      'TABOC',
      'TINIGBAS',
      'TININDUGAN',
      'UNION',
    ],
  },
  LUGAIT: {
    province: 'MISAMIS ORIENTAL',
    municipality: 'LUGAIT',
    region: 'REGION X',
    barangay_list: [
      'AYA-AYA',
      'BETAHON',
      'BIGA',
      'CALANGAHAN',
      'KALUKNAYAN',
      'LOWER TALACOGON',
      'POBLACION',
      'UPPER TALACOGON',
    ],
  },
  'MAGSAYSAY (LINUGOS)': {
    province: 'MISAMIS ORIENTAL',
    municipality: 'MAGSAYSAY (LINUGOS)',
    region: 'REGION X',
    barangay_list: [
      'ABUNDA',
      'ARTADI',
      'BONIFACIO AQUINO',
      'CABALAWAN',
      'CABANTIAN',
      'CABUBUHAN',
      'CANDIIS',
      'CONSUELO',
      'DAMAYUHAN',
      'GUMABON',
      'KATIPUNAN',
      'KAUSWAGAN',
      'KIBUNGSOD',
      'MAHAYAHAY',
      'MINDULAO',
      'PAG-ASA',
      'POBLACION',
      'SAN ISIDRO',
      'SAN VICENTE',
      'SANTA CRUZ',
      'TAMA',
      'TIBON-TIBON',
      'TINAAN',
      'TULANG (CADENA DE AMOR)',
      'VILLA FELIPA',
    ],
  },
  MANTICAO: {
    province: 'MISAMIS ORIENTAL',
    municipality: 'MANTICAO',
    region: 'REGION X',
    barangay_list: [
      'ARGAYOSO',
      'BALINTAD',
      'CABALANTIAN',
      'CAMANGA',
      'DIGKILAAN',
      'MAHAYAHAY',
      'PAGAWAN',
      'PANIANGAN',
      'PATAG',
      'POBLACION',
      'PUNTA SILUM',
      'TUOD',
      'UPPER MALUBOG',
    ],
  },
  MEDINA: {
    province: 'MISAMIS ORIENTAL',
    municipality: 'MEDINA',
    region: 'REGION X',
    barangay_list: [
      'BANGBANG',
      'BULWA',
      'CABUG',
      'DIG-AGUYAN',
      'DUKA',
      'GASA',
      'MAANAS',
      'MANANUM BAG-O',
      'MANANUM DAAN',
      'NORTH POBLACION',
      'PAHINDONG',
      'PORTULIN',
      'SAN ISIDRO',
      'SAN JOSE',
      'SAN ROQUE',
      'SAN VICENTE',
      'SOUTH POBLACION',
      'TAMBAGAN',
      'TUP-ON',
    ],
  },
  NAAWAN: {
    province: 'MISAMIS ORIENTAL',
    municipality: 'NAAWAN',
    region: 'REGION X',
    barangay_list: [
      'DON PEDRO',
      'LINANGKAYAN',
      'LUBILAN',
      'MAPULOG',
      'MAPUTI',
      'MAT-I',
      'PATAG',
      'POBLACION',
      'TAGBALOGO',
      'TUBORAN',
    ],
  },
  OPOL: {
    province: 'MISAMIS ORIENTAL',
    municipality: 'OPOL',
    region: 'REGION X',
    barangay_list: [
      'AWANG',
      'BAGOCBOC',
      'BARRA',
      'BONBON',
      'CAUYONAN',
      'IGPIT',
      'LIMONDA',
      'LUYONGBONBON',
      'MALANANG',
      'NANGCAON',
      'PATAG',
      'POBLACION',
      'TABOC',
      'TINGALAN',
    ],
  },
  SALAY: {
    province: 'MISAMIS ORIENTAL',
    municipality: 'SALAY',
    region: 'REGION X',
    barangay_list: [
      'ALIPUATON',
      'AMPENICAN',
      'BUNAL',
      'CASULOG',
      'DINAGSAAN',
      'GUINALABAN',
      'ILI-ILIHON',
      'INOBULAN',
      'LOOC',
      'MATAMPA',
      'MEMBULI',
      'POBLACION',
      'SALAGSAG',
      'SALAY RIVER I',
      'SALAY RIVER II',
      'SARAY',
      'TINAGAAN',
      'YUNGOD',
    ],
  },
  SUGBONGCOGON: {
    province: 'MISAMIS ORIENTAL',
    municipality: 'SUGBONGCOGON',
    region: 'REGION X',
    barangay_list: [
      'ALICOMOHAN',
      'AMPIANGA',
      'KAULAYANAN',
      'KIDAMPAS',
      'KIRAGING',
      'MANGGA',
      'MIMBUAHAN',
      'POBLACION',
      'SANTA CRUZ (MABINI)',
      'SILAD',
    ],
  },
  TALISAYAN: {
    province: 'MISAMIS ORIENTAL',
    municipality: 'TALISAYAN',
    region: 'REGION X',
    barangay_list: [
      'BUGDANG',
      'CALAMCAM',
      'CASIBOLE',
      'MACOPA',
      'MAGKARILA',
      'MAHAYAG',
      'MANDAHILAG',
      'MINTABON',
      'PANGPANGON',
      'POBLACION',
      'POOK',
      'PUNTA SANTIAGO',
      'PUTING BALAS',
      'SAN JOSE',
      'SANTA INES',
      'SIBANTANG',
      'SINDANGAN',
      'TAGBOCBOC',
    ],
  },
  VILLANUEVA: {
    province: 'MISAMIS ORIENTAL',
    municipality: 'VILLANUEVA',
    region: 'REGION X',
    barangay_list: [
      'BALACANAS',
      'DAYAWAN',
      'IMELDA',
      'KATIPUNAN',
      'KIMAYA',
      'LOOC',
      'POBLACION 1',
      'POBLACION 2',
      'POBLACION 3',
      'SAN MARTIN',
      'TAMBOBONG',
    ],
  },
  COMPOSTELA: {
    province: 'CEBU',
    municipality: 'COMPOSTELA',
    region: 'REGION VII',
    barangay_list: [
      'BAGALNGA',
      'BASAK',
      'BULUANG',
      'CABADIANGAN',
      'CAMBAYOG',
      'CANAMUCAN',
      'COGON',
      'DAPDAP',
      'ESTACA',
      'LUPA',
      'MAGAY',
      'MULAO',
      'PANANGBAN',
      'POBLACION',
      'TAG-UBE',
      'TAMIAO',
      'TUBIGAN',
    ],
  },
  'LAAK (SAN VICENTE)': {
    province: 'COMPOSTELA VALLEY',
    municipality: 'LAAK (SAN VICENTE)',
    region: 'REGION XI',
    barangay_list: [
      'AGUINALDO',
      'AMOR CRUZ',
      'AMPAWID',
      'ANDAP',
      'ANITAP',
      'BAGONG SILANG',
      'BANBANON',
      'BELMONTE',
      'BINASBAS',
      'BULLUCAN',
      'CEBULIDA',
      'CONCEPCION',
      'DATU AMPUNAN',
      'DATU DAVAO',
      'DOÑA JOSEFA',
      'EL KATIPUNAN',
      'IL PAPA',
      'IMELDA',
      'INACAYAN',
      'KALIGUTAN',
      'KAPATAGAN',
      'KIDAWA',
      'KILAGDING',
      'KIOKMAY',
      'LAAC (POB.)',
      'LANGTUD',
      'LONGANAPAN',
      'MABUHAY',
      'MACOPA',
      'MALINAO',
      'MANGLOY',
      'MELALE',
      'NAGA',
      'NEW BETHLEHEM',
      'PANAMOREN',
      'SABUD',
      'SAN ANTONIO',
      'SANTA EMILIA',
      'SANTO NIÑO',
      'SISIMON',
    ],
  },
  'MABINI (DOÑA ALICIA)': {
    province: 'COMPOSTELA VALLEY',
    municipality: 'MABINI (DOÑA ALICIA)',
    region: 'REGION XI',
    barangay_list: [
      'ANITAPAN',
      'CABUYUAN',
      'CADUNAN',
      'CUAMBOG (POB.)',
      'DEL PILAR',
      'GOLDEN VALLEY (MARAUT)',
      'LIBODON',
      'PANGIBIRAN',
      'PINDASAN',
      'SAN ANTONIO',
      'TAGNANAN (MAMPISING)',
    ],
  },
  MACO: {
    province: 'COMPOSTELA VALLEY',
    municipality: 'MACO',
    region: 'REGION XI',
    barangay_list: [
      'ANIBONGAN',
      'ANISLAGAN',
      'BINUANGAN',
      'BUCANA',
      'CALABCAB',
      'CONCEPCION',
      'DUMLAN',
      'ELIZALDE (SOMIL)',
      'GUBATAN',
      'HIJO',
      'KINUBAN',
      'LANGGAM',
      'LAPU-LAPU',
      'LIBAY-LIBAY',
      'LIMBO',
      'LUMATAB',
      'MAGANGIT',
      'MAINIT',
      'MALAMODAO',
      'MANIPONGOL',
      'MAPAANG',
      'MASARA',
      'NEW ASTURIAS',
      'NEW BARILI',
      'NEW LEYTE',
      'NEW VISAYAS',
      'PANANGAN',
      'PANGI (GAUDENCIO ANTONIO)',
      'PANIBASAN',
      'PANORAON',
      'POBLACION',
      'SAN JUAN',
      'SAN ROQUE',
      'SANGAB',
      'TAGBAROS',
      'TAGLAWIG',
      'TERESA',
    ],
  },
  'MARAGUSAN (SAN MARIANO)': {
    province: 'COMPOSTELA VALLEY',
    municipality: 'MARAGUSAN (SAN MARIANO)',
    region: 'REGION XI',
    barangay_list: [
      'BAGONG SILANG',
      'BAHI',
      'CAMBAGANG',
      'CORONOBE',
      'KATIPUNAN',
      'LAHI',
      'LANGGAWISAN',
      'MABUGNAO',
      'MAGCAGONG',
      'MAHAYAHAY',
      'MAPAWA',
      'MARAGUSAN (POB.)',
      'MAUSWAGON',
      'NEW ALBAY',
      'NEW KATIPUNAN',
      'NEW MAN-AY',
      'NEW PANAY',
      'PALOC',
      'PAMINTARAN',
      'PARASANON',
      'TALIAN',
      'TANDIK',
      'TIGBAO',
      'TUPAZ',
    ],
  },
  MAWAB: {
    province: 'COMPOSTELA VALLEY',
    municipality: 'MAWAB',
    region: 'REGION XI',
    barangay_list: [
      'ANDILI',
      'BAWANI',
      'CONCEPCION',
      'MALINAWON',
      'NUEVA VISAYAS',
      'NUEVO ILOCO',
      'POBLACION',
      'SALVACION',
      'SAOSAO',
      'SAWANGAN',
      'TUBORAN',
    ],
  },
  MONKAYO: {
    province: 'COMPOSTELA VALLEY',
    municipality: 'MONKAYO',
    region: 'REGION XI',
    barangay_list: [
      'AWAO',
      'BABAG',
      'BANLAG',
      'BAYLO',
      'CASOON',
      'HAGUIMITAN',
      'INAMBATAN',
      'MACOPA',
      'MAMUNGA',
      'MOUNT DIWATA',
      'NABOC',
      'OLAYCON',
      'PASIAN (SANTA FILOMENA)',
      'POBLACION',
      'RIZAL',
      'SALVACION',
      'SAN ISIDRO',
      'SAN JOSE',
      'TUBO-TUBO (NEW DEL MONTE)',
      'UNION',
      'UPPER ULIP',
    ],
  },
  MONTEVISTA: {
    province: 'COMPOSTELA VALLEY',
    municipality: 'MONTEVISTA',
    region: 'REGION XI',
    barangay_list: [
      'BANAGBANAG',
      'BANGLASAN',
      'BANKEROHAN NORTE',
      'BANKEROHAN SUR',
      'CAMANSI',
      'CAMANTANGAN',
      'CANIDKID',
      'CONCEPCION',
      'DAUMAN',
      'LEBANON',
      'LINOAN',
      'MAYAON',
      'NEW CALAPE',
      'NEW CEBULAN (SAMBAYON)',
      'NEW DALAGUETE',
      'NEW VISAYAS',
      'PROSPERIDAD',
      'SAN JOSE (POB.)',
      'SAN VICENTE',
      'TAPIA',
    ],
  },
  NABUNTURAN: {
    province: 'COMPOSTELA VALLEY',
    municipality: 'NABUNTURAN',
    region: 'REGION XI',
    barangay_list: [
      'ANISLAGAN',
      'ANTIQUERA',
      'BASAK',
      'BAYABAS',
      'BUKAL',
      'CABACUNGAN',
      'CABIDIANAN',
      'KATIPUNAN',
      'LIBASAN',
      'LINDA',
      'MAGADING',
      'MAGSAYSAY',
      'MAINIT',
      'MANAT',
      'MATILO',
      'MIPANGI',
      'NEW DAUIS',
      'NEW SIBONGA',
      'OGAO',
      'PANGUTOSAN',
      'POBLACION',
      'SAN ISIDRO',
      'SAN ROQUE',
      'SAN VICENTE',
      'SANTA MARIA',
      'SANTO NIÑO (KAO)',
      'SASA',
      'TAGNOCON',
    ],
  },
  'NEW BATAAN': {
    province: 'COMPOSTELA VALLEY',
    municipality: 'NEW BATAAN',
    region: 'REGION XI',
    barangay_list: [
      'ANDAP',
      'BANTACAN',
      'BATINAO',
      'CABINUANGAN (POB.)',
      'CAMANLANGAN',
      'COGONON',
      'FATIMA',
      'KAHAYAG',
      'KATIPUNAN',
      'MAGANGIT',
      'MAGSAYSAY',
      'MANURIGAO',
      'PAGSABANGAN',
      'PANAG',
      'SAN ROQUE',
      'TANDAWAN',
    ],
  },
  PANTUKAN: {
    province: 'COMPOSTELA VALLEY',
    municipality: 'PANTUKAN',
    region: 'REGION XI',
    barangay_list: [
      'ARAIBO',
      'BONGABONG',
      'BONGBONG',
      'KINGKING (POB.)',
      'LAS ARENAS',
      'MAGNAGA',
      'MATIAO',
      'NAPNAPAN',
      'P. FUENTES',
      'TAG-UGPO',
      'TAGDANGUA',
      'TAMBONGON',
      'TIBAGON',
    ],
  },
  'ASUNCION (SAUG)': {
    province: 'DAVAO (DAVAO DEL NORTE)',
    municipality: 'ASUNCION (SAUG)',
    region: 'REGION XI',
    barangay_list: [
      'BINANCIAN',
      'BUAN',
      'BUCLAD',
      'CABAYWA',
      'CAMANSA',
      'CAMBANOGOY (POB.)',
      'CAMONING',
      'CANATAN',
      'CONCEPCION',
      'DOÑA ANDREA',
      'MAGATOS',
      'NAPUNGAS',
      'NEW BANTAYAN',
      'NEW LOON',
      'NEW SANTIAGO',
      'PAMACAUN',
      'SAGAYEN',
      'SAN VICENTE',
      'SANTA FILOMENA',
      'SONLON',
    ],
  },
  'BRAULIO E. DUJALI': {
    province: 'DAVAO (DAVAO DEL NORTE)',
    municipality: 'BRAULIO E. DUJALI',
    region: 'REGION XI',
    barangay_list: [
      'CABAY-ANGAN',
      'DUJALI',
      'MAGUPISING',
      'NEW CASAY',
      'TANGLAW',
    ],
  },
  CARMEN: {
    province: 'CEBU',
    municipality: 'CARMEN',
    region: 'REGION VII',
    barangay_list: [
      'BARING',
      'CANTIPAY',
      'CANTUKONG',
      'CANTUMOG',
      'CAURASAN',
      'COGON EAST',
      'COGON WEST',
      'CORTE',
      'DAWIS NORTE',
      'DAWIS SUR',
      'HAGNAYA',
      'IPIL',
      'LANIPGA',
      'LIBORON',
      'LOWER NATIMAO-AN',
      'LUYANG',
      'POBLACION',
      'PUENTE',
      'SAC-ON',
      'TRIUMFO',
      'UPPER NATIMAO-AN',
    ],
  },
  'TAGUM': {
    province: 'DAVAO (DAVAO DEL NORTE)',
    municipality: 'TAGUM',
    region: 'REGION XI',
    barangay_list: [
      'APOKON',
      'BINCUNGAN',
      'BUSAON',
      'CANOCOTAN',
      'CUAMBOGAN',
      'LA FILIPINA',
      'LIBOGANON',
      'MADAUM',
      'MAGDUM',
      'MAGUGPO EAST',
      'MAGUGPO NORTH',
      'MAGUGPO POBLACION',
      'MAGUGPO SOUTH',
      'MAGUGPO WEST',
      'MANKILAM',
      'NEW BALAMBAN',
      'NUEVA FUERZA',
      'PAGSABANGAN',
      'PANDAPAN',
      'SAN AGUSTIN',
      'SAN ISIDRO',
      'SAN MIGUEL (CAMP 4)',
      'VISAYAN VILLAGE',
    ],
  },
  'ISLAND GARDEN SAMAL': {
    province: 'DAVAO (DAVAO DEL NORTE)',
    municipality: 'ISLAND GARDEN SAMAL',
    region: 'REGION XI',
    barangay_list: [
      'ADECOR',
      'ANONANG',
      'AUMBAY',
      'AUNDANAO',
      'BALET',
      'BANDERA',
      'CALICLIC (DANGCA-AN)',
      'CAMUDMUD',
      'CATAGMAN',
      'CAWAG',
      'COGON',
      'COGON (TALICOD)',
      'DADATAN',
      'DEL MONTE',
      'GUILON',
      'KANAAN',
      'KINAWITNON',
      'LIBERTAD',
      'LIBUAK',
      'LICUP',
      'LIMAO',
      'LINOSUTAN',
      'MAMBAGO-A',
      'MAMBAGO-B',
      'MIRANDA (POB.)',
      'MONCADO (POB.)',
      'PANGUBATAN (TALICOD I)',
      'PEÑAPLATA (POB.)',
      'POBLACION (KAPUTIAN)',
      'SAN AGUSTIN',
      'SAN ANTONIO',
      'SAN ISIDRO (BABAK)',
      'SAN ISIDRO (KAPUTIAN)',
      'SAN JOSE (SAN LAPUZ)',
      'SAN MIGUEL (MAGAMONO)',
      'SAN REMIGIO',
      'SANTA CRUZ (TALICOD II)',
      'SANTO NIÑO',
      'SION (ZION)',
      'TAGBAOBO',
      'TAGBAY',
      'TAGBITAN-AG',
      'TAGDALIAO',
      'TAGPOPONGAN',
      'TAMBO',
      'TORIL',
    ],
  },
  KAPALONG: {
    province: 'DAVAO (DAVAO DEL NORTE)',
    municipality: 'KAPALONG',
    region: 'REGION XI',
    barangay_list: [
      'CAPUNGAGAN',
      'FLORIDA',
      'GABUYAN',
      'GUPITAN',
      'KATIPUNAN',
      'LUNA',
      'MABANTAO',
      'MAMACAO',
      'MANIKI (POBLACION)',
      'PAG-ASA',
      'SAMPAO',
      'SEMONG',
      'SUA-ON',
      'TIBURCIA',
    ],
  },
  'NEW CORELLA': {
    province: 'DAVAO (DAVAO DEL NORTE)',
    municipality: 'NEW CORELLA',
    region: 'REGION XI',
    barangay_list: [
      'CABIDIANAN',
      'CARCOR',
      'DEL MONTE',
      'DEL PILAR',
      'EL SALVADOR',
      'LIMBA-AN',
      'MACGUM',
      'MAMBING',
      'MESAOY',
      'NEW BOHOL',
      'NEW CORTEZ',
      'NEW SAMBOG',
      'PATROCENIO',
      'POBLACION',
      'SAN JOSE',
      'SAN ROQUE',
      'SANTA CRUZ',
      'SANTA FE',
      'SANTO NIÑO',
      'SUAWON',
    ],
  },
  'PANABO': {
    province: 'DAVAO (DAVAO DEL NORTE)',
    municipality: 'PANABO',
    region: 'REGION XI',
    barangay_list: [
      'A. O. FLOIRENDO',
      'BUENAVISTA',
      'CACAO',
      'CAGANGOHAN',
      'CONSOLACION',
      'DAPCO',
      'DATU ABDUL DADIA',
      'GREDU (POB.)',
      'J.P. LAUREL',
      'KASILAK',
      'KATIPUNAN',
      'KATUALAN',
      'KAUSWAGAN',
      'KIOTOY',
      'LITTLE PANAY',
      'LOWER PANAGA (ROXAS)',
      'MABUNAO',
      'MADUAO',
      'MALATIVAS',
      'MANAY',
      'NANYO',
      'NEW MALAGA (DALISAY)',
      'NEW MALITBOG',
      'NEW PANDAN (POB.)',
      'NEW VISAYAS',
      'QUEZON',
      'SALVACION',
      'SAN FRANCISCO (POB.)',
      'SAN NICOLAS',
      'SAN PEDRO',
      'SAN ROQUE',
      'SAN VICENTE',
      'SANTA CRUZ',
      'SANTO NIÑO (POB.)',
      'SINDATON',
      'SOUTHERN DAVAO',
      'TAGPORE',
      'TIBUNGOL',
      'UPPER LICANAN',
      'WATERFALL',
    ],
  },
  'SAN ISIDRO': {
    province: 'ABRA',
    municipality: 'SAN ISIDRO',
    region: 'CAR',
    barangay_list: [
      'CABAYOGAN',
      'DALIMAG',
      'LANGBABAN',
      'MANAYDAY',
      'PANTOC',
      'POBLACION',
      'SABTAN-OLO',
      'SAN MARCIAL',
      'TANGBAO',
    ],
  },
  'SANTO TOMAS': {
    province: 'BATANGAS',
    municipality: 'SANTO TOMAS',
    region: 'REGION IV-A',
    barangay_list: [
      'BARANGAY I (POB.)',
      'BARANGAY II (POB.)',
      'BARANGAY III (POB.)',
      'BARANGAY IV (POB.)',
      'SAN AGUSTIN',
      'SAN ANTONIO',
      'SAN BARTOLOME',
      'SAN FELIX',
      'SAN FERNANDO',
      'SAN FRANCISCO',
      'SAN ISIDRO NORTE',
      'SAN ISIDRO SUR',
      'SAN JOAQUIN',
      'SAN JOSE',
      'SAN JUAN',
      'SAN LUIS',
      'SAN MIGUEL',
      'SAN PABLO',
      'SAN PEDRO',
      'SAN RAFAEL',
      'SAN ROQUE',
      'SAN VICENTE',
      'SANTA ANA',
      'SANTA ANASTACIA',
      'SANTA CLARA',
      'SANTA CRUZ',
      'SANTA ELENA',
      'SANTA MARIA',
      'SANTA TERESITA',
      'SANTIAGO',
    ],
  },
  TALAINGOD: {
    province: 'DAVAO (DAVAO DEL NORTE)',
    municipality: 'TALAINGOD',
    region: 'REGION XI',
    barangay_list: ['DAGOHOY', 'PALMA GIL', 'SANTO NIÑO'],
  },
  BANSALAN: {
    province: 'DAVAO DEL SUR',
    municipality: 'BANSALAN',
    region: 'REGION XI',
    barangay_list: [
      'ALEGRE',
      'ALTA VISTA',
      'ANONANG',
      'BITAUG',
      'BONIFACIO',
      'BUENAVISTA',
      'DARAPUAY',
      'DOLO',
      'EMAN',
      'KINUSKUSAN',
      'LIBERTAD',
      'LINAWAN',
      'MABUHAY',
      'MABUNGA',
      'MANAGA',
      'MARBER',
      'NEW CLARIN (MIRAL)',
      'POBLACION',
      'POBLACION DOS',
      'RIZAL',
      'SANTO NIÑO',
      'SIBAYAN',
      'TINONGTONGAN',
      'TUBOD',
      'UNION',
    ],
  },
  'DAVAO': {
    province: 'DAVAO DEL SUR',
    municipality: 'DAVAO',
    region: 'REGION XI',
    barangay_list: [
      'ACACIA',
      'AGDAO',
      'ALAMBRE',
      'ALEJANDRA NAVARRO (LASANG)',
      'ALFONSO ANGLIONGTO SR.',
      'ANGALAN',
      'ATAN-AWE',
      'BAGANIHAN',
      'BAGO APLAYA',
      'BAGO GALLERA',
      'BAGO OSHIRO',
      'BAGUIO (POB.)',
      'BALENGAENG',
      'BALIOK',
      'BANGKAS HEIGHTS',
      'BANTOL',
      'BARACATAN',
      'BARANGAY 1-A (POB.)',
      'BARANGAY 2-A (POB.)',
      'BARANGAY 3-A (POB.)',
      'BARANGAY 4-A (POB.)',
      'BARANGAY 5-A (POB.)',
      'BARANGAY 6-A (POB.)',
      'BARANGAY 7-A (POB.)',
      'BARANGAY 8-A (POB.)',
      'BARANGAY 9-A (POB.)',
      'BARANGAY 10-A (POB.)',
      'BARANGAY 11-B (POB.)',
      'BARANGAY 12-B (POB.)',
      'BARANGAY 13-B (POB.)',
      'BARANGAY 14-B (POB.)',
      'BARANGAY 15-B (POB.)',
      'BARANGAY 16-B (POB.)',
      'BARANGAY 17-B (POB.)',
      'BARANGAY 18-B (POB.)',
      'BARANGAY 19-B (POB.)',
      'BARANGAY 20-B (POB.)',
      'BARANGAY 21-C (POB.)',
      'BARANGAY 22-C (POB.)',
      'BARANGAY 23-C (POB.)',
      'BARANGAY 24-C (POB.)',
      'BARANGAY 25-C (POB.)',
      'BARANGAY 26-C (POB.)',
      'BARANGAY 27-C (POB.)',
      'BARANGAY 28-C (POB.)',
      'BARANGAY 29-C (POB.)',
      'BARANGAY 30-C (POB.)',
      'BARANGAY 31-D (POB.)',
      'BARANGAY 32-D (POB.)',
      'BARANGAY 33-D (POB.)',
      'BARANGAY 34-D (POB.)',
      'BARANGAY 35-D (POB.)',
      'BARANGAY 36-D (POB.)',
      'BARANGAY 37-D (POB.)',
      'BARANGAY 38-D (POB.)',
      'BARANGAY 39-D (POB.)',
      'BARANGAY 40-D (POB.)',
      'BATO',
      'BAYABAS',
      'BIAO ESCUELA',
      'BIAO GUIANGA',
      'BIAO JOAQUIN',
      'BINUGAO',
      'BUCANA',
      'BUDA',
      'BUHANGIN (POB.)',
      'BUNAWAN (POB.)',
      'CABANTIAN',
      'CADALIAN',
      'CALINAN (POB.)',
      'CALLAWA',
      'CAMANSI',
      'CARMEN',
      'CATALUNAN GRANDE',
      'CATALUNAN PEQUEÑO',
      'CATIGAN',
      'CAWAYAN',
      'CENTRO (SAN JUAN)',
      'COLOSAS',
      'COMMUNAL',
      'CROSSING BAYABAS',
      'DACUDAO',
      'DALAG',
      'DALAGDAG',
      'DALIAO',
      'DALIAON PLANTATION',
      'DATU SALUMAY',
      'DOMINGA',
      'DUMOY',
      'EDEN',
      'FATIMA (BENOWANG)',
      'GATUNGAN',
      'GOV. PACIANO BANGOY',
      'GOV. VICENTE DUTERTE',
      'GUMALANG',
      'GUMITAN',
      'ILANG',
      'INAYANGAN',
      'INDANGAN',
      'KAP. TOMAS MONTEVERDE, SR.',
      'KILATE',
      'LACSON',
      'LAMANAN',
      'LAMPIANAO',
      'LANGUB',
      'LAPU-LAPU',
      'LEON GARCIA, SR.',
      'LIZADA',
      'LOS AMIGOS',
      'LUBOGAN',
      'LUMIAD',
      'MA-A',
      'MABUHAY',
      'MAGSAYSAY',
      'MAGTUOD',
      'MAHAYAG',
      'MALABOG',
      'MALAGOS',
      'MALAMBA',
      'MANAMBULAN',
      'MANDUG',
      'MANUEL GUIANGA',
      'MAPULA',
      'MARAPANGI',
      'MARILOG',
      'MATINA APLAYA',
      'MATINA BIAO',
      'MATINA CROSSING',
      'MATINA PANGI',
      'MEGKAWAYAN',
      'MINTAL',
      'MUDIANG',
      'MULIG',
      'NEW CARMEN',
      'NEW VALENCIA',
      'PAMPANGA',
      'PANACAN',
      'PANALUM',
      'PANDAITAN',
      'PANGYAN',
      'PAQUIBATO (POB.)',
      'PARADISE EMBAK',
      'RAFAEL CASTILLO',
      'RIVERSIDE',
      'SALAPAWAN',
      'SALAYSAY',
      'SALOY',
      'SAN ANTONIO',
      'SAN ISIDRO (LICANAN)',
      'SANTO NIÑO',
      'SASA',
      'SIBULAN',
      'SIRAWAN',
      'SIRIB',
      'SUAWAN (TULI)',
      'SUBASTA',
      'SUMIMAO',
      'TACUNAN',
      'TAGAKPAN',
      'TAGLUNO',
      'TAGURANO',
      'TALANDANG',
      'TALOMO (POB.)',
      'TALOMO RIVER',
      'TAMAYONG',
      'TAMBOBONG',
      'TAMUGAN',
      'TAPAK',
      'TAWAN-TAWAN',
      'TIBULOY',
      'TIBUNGCO',
      'TIGATTO',
      'TORIL (POB.)',
      'TUGBOK (POB.)',
      'TUNGAKALAN',
      'UBALDE',
      'ULA',
      'VICENTE HIZON SR.',
      'WAAN',
      'WANGAN',
      'WILFREDO AQUINO',
      'WINES',
    ],
  },
  'DIGOS': {
    province: 'DAVAO DEL SUR',
    municipality: 'DIGOS',
    region: 'REGION XI',
    barangay_list: [
      'APLAYA',
      'BALABAG',
      'BINATON',
      'COGON',
      'COLORADO',
      'DAWIS',
      'DULANGAN',
      'GOMA',
      'IGPIT',
      'KAPATAGAN (RIZAL)',
      'KIAGOT',
      'LUNGAG',
      'MAHAYAHAY',
      'MATTI',
      'RUPARAN',
      'SAN AGUSTIN',
      'SAN JOSE (BALUTAKAY)',
      'SAN MIGUEL (ODACA)',
      'SAN ROQUE',
      'SINAWILAN',
      'SOONG',
      'TIGUMAN',
      'TRES DE MAYO',
      'ZONE 1 (POB.)',
      'ZONE 2 (POB.)',
      'ZONE 3 (POB.)',
    ],
  },
  HAGONOY: {
    province: 'BULACAN',
    municipality: 'HAGONOY',
    region: 'REGION III',
    barangay_list: [
      'ABULALAS',
      'CARILLO',
      'IBA',
      'IBA-IBAYO',
      'MERCADO',
      'PALAPAT',
      'PUGAD',
      'SAGRADA FAMILIA',
      'SAN AGUSTIN',
      'SAN ISIDRO',
      'SAN JOSE',
      'SAN JUAN',
      'SAN MIGUEL',
      'SAN NICOLAS',
      'SAN PABLO',
      'SAN PASCUAL',
      'SAN PEDRO',
      'SAN ROQUE',
      'SAN SEBASTIAN',
      'SANTA CRUZ',
      'SANTA ELENA',
      'SANTA MONICA',
      'SANTO NIÑO (POB.)',
      'SANTO ROSARIO',
      'TAMPOK',
      'TIBAGUIN',
    ],
  },
  KIBLAWAN: {
    province: 'DAVAO DEL SUR',
    municipality: 'KIBLAWAN',
    region: 'REGION XI',
    barangay_list: [
      'ABNATE',
      'BAGONG NEGROS',
      'BAGONG SILANG',
      'BAGUMBAYAN',
      'BALASIAO',
      'BONIFACIO',
      'BULOL-SALO',
      'BUNOT',
      'COGON-BACACA',
      'DAPOK',
      'IHAN',
      'KIBONGBONG',
      'KIMLAWIS',
      'KISULAN',
      'LATI-AN',
      'MANUAL',
      'MARAGA-A',
      'MOLOPOLO',
      'NEW SIBONGA',
      'PANAGLIB',
      'PASIG',
      'POBLACION',
      'POCALEEL',
      'SAN ISIDRO',
      'SAN JOSE',
      'SAN PEDRO',
      'SANTO NIÑO',
      'TACUB',
      'TACUL',
      'WATERFALL',
    ],
  },
  MALALAG: {
    province: 'DAVAO DEL SUR',
    municipality: 'MALALAG',
    region: 'REGION XI',
    barangay_list: [
      'BAGUMBAYAN',
      'BAYBAY',
      'BOLTON',
      'BULACAN',
      'CAPUTIAN',
      'IBO',
      'KIBLAGON',
      'LAPLA',
      'MABINI',
      'NEW BACLAYON',
      'PITU',
      'POBLACION',
      'RIZAL (PARAME)',
      'SAN ISIDRO',
      'TAGANSULE',
    ],
  },
  MATANAO: {
    province: 'DAVAO DEL SUR',
    municipality: 'MATANAO',
    region: 'REGION XI',
    barangay_list: [
      'ASBANG',
      'ASINAN',
      'BAGUMBAYAN',
      'BANGKAL',
      'BUAS',
      'BURI',
      'CABLIGAN (MANAGA)',
      'CAMANCHILES',
      'CEBOZA',
      'COLONSABAK',
      'DONGAN-PEKONG',
      'KABASAGAN',
      'KAPOK',
      'KAUSWAGAN',
      'KIBAO',
      'LA SUERTE',
      'LANGA-AN',
      'LOWER MARBER',
      'MANGA',
      'NEW KATIPUNAN',
      'NEW MURCIA',
      'NEW VISAYAS',
      'POBLACION',
      'SABOY',
      'SAN JOSE',
      'SAN MIGUEL',
      'SAN VICENTE',
      'SAUB',
      'SINARAGAN',
      'SINAWILAN',
      'TAMLANGON',
      'TIBONGBONG',
      'TOWAK',
    ],
  },
  PADADA: {
    province: 'DAVAO DEL SUR',
    municipality: 'PADADA',
    region: 'REGION XI',
    barangay_list: [
      'ALMENDRAS (POB.)',
      'DON SERGIO OSMEÑA, SR.',
      'HARADA BUTAI',
      'LOWER KATIPUNAN',
      'LOWER LIMONZO',
      'LOWER MALINAO',
      'N C ORDANEZA DISTRICT (POB.)',
      'NORTHERN PALIGUE',
      'PALILI',
      'PIAPE',
      'PUNTA PIAPE',
      'QUIRINO DISTRICT (POB.)',
      'SAN ISIDRO',
      'SOUTHERN PALIGUE',
      'TULOGAN',
      'UPPER LIMONZO',
      'UPPER MALINAO',
    ],
  },
  SULOP: {
    province: 'DAVAO DEL SUR',
    municipality: 'SULOP',
    region: 'REGION XI',
    barangay_list: [
      'BALASINON',
      'BUGUIS',
      'CARRE',
      'CLIB',
      'HARADA BUTAI',
      'KATIPUNAN',
      'KIBLAGON',
      'LABON',
      'LAPERAS',
      'LAPLA',
      'LITOS',
      'LUPARAN',
      'MCKINLEY',
      'NEW CEBU',
      'OSMEÑA',
      'PALILI',
      'PARAME',
      'POBLACION',
      'ROXAS',
      'SOLONGVALE',
      'TAGOLILONG',
      'TALA-O',
      'TALAS',
      'TANWALANG',
      'WATERFALL',
    ],
  },
  'DON MARCELINO': {
    province: 'DAVAO OCCIDENTAL',
    municipality: 'DON MARCELINO',
    region: 'REGION XI',
    barangay_list: [
      'BALUNTAYA',
      'CALIAN',
      'DALUPAN',
      'KINANGA',
      'KIOBOG',
      'LANAO',
      'LAPUAN',
      'LAWA (POB.)',
      'LINADASAN',
      'MABUHAY',
      'NORTH LAMIDAN',
      'NUEVA VILLA',
      'SOUTH LAMIDAN',
      'TALAGUTONG (POB.)',
      'WEST LAMIDAN',
    ],
  },
  'JOSE ABAD SANTOS (TRINIDAD)': {
    province: 'DAVAO OCCIDENTAL',
    municipality: 'JOSE ABAD SANTOS (TRINIDAD)',
    region: 'REGION XI',
    barangay_list: [
      'BALANGONAN',
      'BUGUIS',
      'BUKID',
      'BUTUAN',
      'BUTULAN',
      'CABURAN BIG',
      'CABURAN SMALL (POB.)',
      'CAMALIAN',
      'CARAHAYAN',
      'CAYAPONGA',
      'CULAMAN',
      'KALBAY',
      'KITAYO',
      'MAGULIBAS',
      'MALALAN',
      'MANGILE',
      'MARABUTUAN',
      'MEYBIO',
      'MOLMOL',
      'NUING',
      'PATULANG',
      'QUIAPO',
      'SAN ISIDRO',
      'SUGAL',
      'TABAYON',
      'TANUMAN',
    ],
  },
  MALITA: {
    province: 'DAVAO OCCIDENTAL',
    municipality: 'MALITA',
    region: 'REGION XI',
    barangay_list: [
      'BITO',
      'BOLILA',
      'BUHANGIN',
      'CULAMAN',
      'DATU DANWATA',
      'DEMOLOC',
      'FELIS',
      "FISHING VILLAGE (FISHERMAN'S VILLAGE)",
      'KIBALATONG',
      'KIDALAPONG',
      'KILALAG',
      'KINANGAN',
      'LACARON',
      'LAGUMIT',
      'LAIS',
      'LITTLE BAGUIO',
      'MACOL',
      'MANA',
      'MANUEL PERALTA',
      'NEW ARGAO',
      'PANGALEON',
      'PANGIAN',
      'PINALPALAN',
      'POBLACION',
      'SANGAY',
      'TALOGOY',
      'TICAL',
      'TICULON',
      'TINGOLO',
      'TUBALAN',
    ],
  },
  'SANTA MARIA': {
    province: 'LAGUNA',
    municipality: 'SANTA MARIA',
    region: 'REGION IV-A',
    barangay_list: [
      'ADIA',
      'BAGONG POOK',
      'BAGUMBAYAN',
      'BARANGAY I (POB.)',
      'BARANGAY II (POB.)',
      'BARANGAY III (POB.)',
      'BARANGAY IV (POB.)',
      'BUBUKAL',
      'CABOOAN',
      'CALANGAY',
      'CAMBUJA',
      'CORALAN',
      'CUEVA',
      'INAYAPAN',
      'JOSE LAUREL, SR.',
      'JOSE RIZAL',
      'KAYHAKAT',
      'MACASIPAC',
      'MASINAO',
      'MATALING-TING',
      'PAO-O',
      'PARANG NG BUHO',
      'SANTIAGO',
      'TALANGKA',
      'TUNGKOD',
    ],
  },
  SARANGANI: {
    province: 'DAVAO OCCIDENTAL',
    municipality: 'SARANGANI',
    region: 'REGION XI',
    barangay_list: [
      'BATUGANDING',
      'CAMAHUAL',
      'CAMALIG',
      'GOMTAGO',
      'KONEL',
      'LAKER (SARANGANI SUR)',
      'LIPOL',
      'MABILA (POB.)',
      'PATUCO (SARANGANI NORTE)',
      'TAGEN',
      'TININA',
      'TUCAL',
    ],
  },
  BAGANGA: {
    province: 'DAVAO ORIENTAL',
    municipality: 'BAGANGA',
    region: 'REGION XI',
    barangay_list: [
      'BACULIN',
      'BANAO',
      'BATAWAN',
      'BATIANO',
      'BINONDO',
      'BOBONAO',
      'CAMPAWAN',
      'CENTRAL (POB.)',
      'DAPNAN',
      'KINABLANGAN',
      'LAMBAJON',
      'LUCOD',
      'MAHANUB',
      'MIKIT',
      'SALINGCOMOT',
      'SAN ISIDRO',
      'SAN VICTOR',
      'SAOQUEGUE',
    ],
  },
  BANAYBANAY: {
    province: 'DAVAO ORIENTAL',
    municipality: 'BANAYBANAY',
    region: 'REGION XI',
    barangay_list: [
      'CABANGCALAN',
      'CAGANGANAN',
      'CALUBIHAN',
      'CAUSWAGAN',
      'MAHAYAG',
      'MAPUTI',
      'MOGBONGCOGON',
      'PANIKIAN',
      'PINTATAGAN',
      'PISO PROPER',
      'POBLACION',
      'PUNTA LINAO',
      'RANG-AY',
      'SAN VICENTE',
    ],
  },
  BOSTON: {
    province: 'DAVAO ORIENTAL',
    municipality: 'BOSTON',
    region: 'REGION XI',
    barangay_list: [
      'CAATIHAN',
      'CABASAGAN',
      'CARMEN',
      'CAWAYANAN',
      'POBLACION',
      'SAN JOSE',
      'SIBAJAY',
      'SIMULAO',
    ],
  },
  CARAGA: {
    province: 'DAVAO ORIENTAL',
    municipality: 'CARAGA',
    region: 'REGION XI',
    barangay_list: [
      'ALVAR',
      'CANINGAG',
      'DON LEON BALANTE',
      'LAMIAWAN',
      'MANORIGAO',
      'MERCEDES',
      'PALMA GIL',
      'PICHON',
      'POBLACION',
      'SAN ANTONIO',
      'SAN JOSE',
      'SAN LUIS',
      'SAN MIGUEL',
      'SAN PEDRO',
      'SANTA FE',
      'SANTIAGO',
      'SOBRECAREY',
    ],
  },
  CATEEL: {
    province: 'DAVAO ORIENTAL',
    municipality: 'CATEEL',
    region: 'REGION XI',
    barangay_list: [
      'ABIJOD',
      'ALEGRIA',
      'ALIWAGWAG',
      'ARAGON',
      'BAYBAY',
      'MAGLAHUS',
      'MAINIT',
      'MALIBAGO',
      'POBLACION',
      'SAN ALFONSO',
      'SAN ANTONIO',
      'SAN MIGUEL',
      'SAN RAFAEL',
      'SAN VICENTE',
      'SANTA FILOMENA',
      'TAYTAYAN',
    ],
  },
  'GOVERNOR GENEROSO': {
    province: 'DAVAO ORIENTAL',
    municipality: 'GOVERNOR GENEROSO',
    region: 'REGION XI',
    barangay_list: [
      'ANITAP',
      'CRISPIN DELA CRUZ',
      'DON AURELIO CHICOTE',
      'LAVIGAN',
      'LUZON',
      'MAGDUG',
      'MANUEL ROXAS',
      'MONSERRAT',
      'NANGAN',
      'OREGON',
      'POBLACION',
      'PUNDAGUITAN',
      'SERGIO OSMEÑA',
      'SUROP',
      'TAGABEBE',
      'TAMBAN',
      'TANDANG SORA',
      'TIBANBAN',
      'TIBLAWAN',
      'UPPER TIBANBAN',
    ],
  },
  LUPON: {
    province: 'DAVAO ORIENTAL',
    municipality: 'LUPON',
    region: 'REGION XI',
    barangay_list: [
      'BAGUMBAYAN',
      'CABADIANGAN',
      'CALAPAGAN',
      'COCORNON',
      'CORPORACION',
      'DON MARIANO MARCOS',
      'ILANGAY',
      'LANGKA',
      'LANTAWAN',
      'LIMBAHAN',
      'MACANGAO',
      'MAGSAYSAY',
      'MAHAYAHAY',
      'MARAGATAS',
      'MARAYAG',
      'NEW VISAYAS',
      'POBLACION',
      'SAN ISIDRO',
      'SAN JOSE',
      'TAGBOA',
      'TAGUGPO',
    ],
  },
  MANAY: {
    province: 'DAVAO ORIENTAL',
    municipality: 'MANAY',
    region: 'REGION XI',
    barangay_list: [
      'CAPASNAN',
      'CAYAWAN',
      'CENTRAL (POB.)',
      'CONCEPCION',
      'DEL PILAR',
      'GUZA',
      'HOLY CROSS',
      'LAMBOG',
      'MABINI',
      'MANREZA',
      'NEW TAOKANGA',
      'OLD MACOPA',
      'RIZAL',
      'SAN FERMIN',
      'SAN IGNACIO',
      'SAN ISIDRO',
      'ZARAGOSA',
    ],
  },
  'MATI': {
    province: 'DAVAO ORIENTAL',
    municipality: 'MATI',
    region: 'REGION XI',
    barangay_list: [
      'BADAS',
      'BOBON',
      'BUSO',
      'CABUAYA',
      'CENTRAL (POB.)',
      'CULIAN',
      'DAHICAN',
      'DANAO',
      'DAWAN',
      'DON ENRIQUE LOPEZ',
      'DON MARTIN MARUNDAN',
      'DON SALVADOR LOPEZ, SR.',
      'LANGKA',
      'LAWIGAN',
      'LIBUDON',
      'LUBAN',
      'MACAMBOL',
      'MAMALI',
      'MATIAO',
      'MAYO',
      'SAINZ',
      'SANGHAY',
      'TAGABAKID',
      'TAGBINONGA',
      'TAGUIBO',
      'TAMISAN',
    ],
  },
  TARRAGONA: {
    province: 'DAVAO ORIENTAL',
    municipality: 'TARRAGONA',
    region: 'REGION XI',
    barangay_list: [
      'CABAGAYAN',
      'CENTRAL (POB.)',
      'DADONG',
      'JOVELLAR',
      'LIMOT',
      'LUCATAN',
      'MAGANDA',
      'OMPAO',
      'TOMOAONG',
      'TUBAON',
    ],
  },
  ALAMADA: {
    province: 'COTABATO (NORTH COT.)',
    municipality: 'ALAMADA',
    region: 'REGION XII',
    barangay_list: [
      'BAO',
      'BARANGIRAN',
      'CAMANSI',
      'DADO',
      'GUILING',
      'KITACUBONG (POB.)',
      'LOWER DADO',
      'MACABASA',
      'MALITUBOG',
      'MAPUROK',
      'MIRASOL',
      'PACAO',
      'PARUAYAN',
      'PIGCAWARAN',
      'POLAYAGAN',
      'RANGAYEN',
      'RARADANGAN',
    ],
  },
  ALEOSAN: {
    province: 'COTABATO (NORTH COT.)',
    municipality: 'ALEOSAN',
    region: 'REGION XII',
    barangay_list: [
      'BAGOLIBAS',
      'CAWILIHAN',
      'DUALING',
      'DUNGUAN',
      'KATALICANAN',
      'LAWILI',
      'LOWER MINGADING',
      'LUANAN',
      'MALAPANG',
      'NEW LEON',
      'NEW PANAY',
      'PAGANGAN',
      'PALACAT',
      'PENTIL',
      'SAN MATEO',
      'SANTA CRUZ',
      'TAPODOC',
      'TOMADO',
      'UPPER MINGADING',
    ],
  },
  ANTIPAS: {
    province: 'COTABATO (NORTH COT.)',
    municipality: 'ANTIPAS',
    region: 'REGION XII',
    barangay_list: [
      'B. CADUNGON',
      'CAMUTAN',
      'CANAAN',
      'DATU AGOD',
      'DOLORES',
      'KIYAAB',
      'LUHONG',
      'MAGSAYSAY',
      'MALANGAG',
      'MALATAD',
      'MALIRE',
      'NEW PONTEVEDRA',
      'POBLACION',
    ],
  },
  ARAKAN: {
    province: 'COTABATO (NORTH COT.)',
    municipality: 'ARAKAN',
    region: 'REGION XII',
    barangay_list: [
      'ALLAB',
      'ANAPOLON',
      'BADIANGON',
      'BINOONGAN',
      'DALLAG',
      'DATU LADAYON',
      'DATU MATANGKIL',
      'DOROLUMAN',
      'GAMBODES',
      'GANATAN',
      'GREENFIELD',
      'KABALANTIAN',
      'KATIPUNAN',
      'KINAWAYAN',
      'KULAMAN VALLEY',
      'LANAO KURAN',
      'LIBERTAD',
      'MAKALANGOT',
      'MALIBATUAN',
      'MARIA CARIDAD',
      'MEOCAN',
      'NAJE',
      'NAPALICO',
      'SALASANG',
      'SAN MIGUEL',
      'SANTO NIÑO',
      'SUMALILI',
      'TUMANDING',
    ],
  },
  BANISILAN: {
    province: 'COTABATO (NORTH COT.)',
    municipality: 'BANISILAN',
    region: 'REGION XII',
    barangay_list: [
      'BANISILAN POBLACION',
      'BUSAON',
      'CAPAYANGAN',
      'CARUGMANAN',
      'GASTAY',
      'KALAWAIG',
      'KIARING',
      'MALAGAP',
      'MALINAO',
      'MIGUEL MACASARTE',
      'PANTAR',
      'PARADISE',
      'PINAMULAAN',
      'POBLACION II',
      'PUTING-BATO',
      'SOLAMA',
      'THAILAND',
      'TINIMBACAN',
      'TUMBAO-CAMALIG',
      'WADYA',
    ],
  },
  'KIDAPAWAN': {
    province: 'COTABATO (NORTH COT.)',
    municipality: 'KIDAPAWAN',
    region: 'REGION XII',
    barangay_list: [
      'AMAS',
      'AMAZION',
      'BALABAG',
      'BALINDOG',
      'BENOLIGAN',
      'BERADA',
      'GAYOLA',
      'GINATILAN',
      'ILOMAVIS',
      'INDANGAN',
      'JUNCTION',
      'KALAISAN',
      'KALASUYAN',
      'KATIPUNAN',
      'LANAO',
      'LINANGCOB',
      'LUVIMIN',
      'MACABOLIG',
      'MAGSAYSAY',
      'MALINAN',
      'MANONGOL',
      'MARBEL (EMBAC)',
      'MATEO',
      'MEOCHAO',
      'MUA-AN',
      'NEW BOHOL',
      'NUANGAN',
      'ONICA',
      'PACO',
      'PATADON (PATADON EAST)',
      'PEREZ',
      'POBLACION',
      'SAN ISIDRO',
      'SAN ROQUE',
      'SANTO NIÑO',
      'SIBAWAN',
      'SIKITAN',
      'SINGAO',
      'SUDAPIN',
      'SUMBAO',
    ],
  },
  KABACAN: {
    province: 'COTABATO (NORTH COT.)',
    municipality: 'KABACAN',
    region: 'REGION XII',
    barangay_list: [
      'ARINGAY',
      'BANGILAN',
      'BANNAWAG',
      'BULUAN',
      'CUYAPON',
      'DAGUPAN',
      'KATIDTUAN',
      'KAYAGA',
      'KILAGASAN',
      'MAGATOS',
      'MALAMOTE',
      'MALANDUAGUE',
      'NANGA-AN',
      'OSIAS',
      'PAATAN LOWER',
      'PAATAN UPPER',
      'PEDTAD',
      'PISAN',
      'POBLACION',
      'SALAPUNGAN',
      'SANGGADONG',
      'SIMBUHAY',
      'SIMONE',
      'TAMPED',
    ],
  },
  LIBUNGAN: {
    province: 'COTABATO (NORTH COT.)',
    municipality: 'LIBUNGAN',
    region: 'REGION XII',
    barangay_list: [
      'ABAGA',
      'BAGUER',
      'BARONGIS',
      'BATIOCAN',
      'CABARUYAN',
      'CABPANGI',
      'DEMAPACO',
      'GREBONA',
      'GUMAGA',
      'KAPAYAWI',
      'KILOYAO',
      'KITUBOD',
      'MALENGEN',
      'MONTAY',
      'NICA-AN',
      'PALAO',
      'POBLACION',
      'SINAPANGAN',
      'SINAWINGAN',
      'ULAMIAN',
    ],
  },
  "M'LANG": {
    province: 'COTABATO (NORTH COT.)',
    municipality: "M'LANG",
    region: 'REGION XII',
    barangay_list: [
      'BAGONTAPAY',
      'BIALONG',
      'BUAYAN',
      'CALUNASAN',
      'DAGONG',
      'DALIPE',
      'DUNGO-AN',
      'GAUNAN',
      'INAS',
      'KATIPUNAN',
      'LA FORTUNA',
      'LA SUERTE',
      'LANGKONG',
      'LEPAGA',
      'LIBOO',
      'LIKA',
      'LUZ VILLAGE',
      'MAGALLON',
      'MALAYAN',
      'NEW ANTIQUE',
      'NEW BARBAZA',
      'NEW CONSOLACION',
      'NEW ESPERANZA',
      'NEW JANIUAY',
      'NEW KALIBO',
      'NEW LAWA-AN',
      'NEW RIZAL',
      'NUEVA VIDA',
      'PAG-ASA',
      'PALMA-PEREZ',
      'POBLACION',
      'POBLACION B',
      'PULANG-LUPA',
      'SANGAT',
      'TAWANTAWAN',
      'TIBAO',
      'UGPAY',
    ],
  },
  MAGPET: {
    province: 'COTABATO (NORTH COT.)',
    municipality: 'MAGPET',
    region: 'REGION XII',
    barangay_list: [
      'ALIBAYON',
      'AMABEL',
      'BAGUMBAYAN',
      'BALETE',
      'BANGKAL',
      'BANTAC',
      'BASAK',
      'BINAY',
      'BONGOLANON',
      'DATU CELO',
      'DEL PILAR',
      'DOLES',
      'DON PANACA',
      'GUBATAN',
      'ILIAN',
      'IMAMALING',
      'INAC',
      'KAMADA',
      'KAUSWAGAN',
      'KINARUM',
      'KISANDAL',
      'MAGCAALAM',
      'MAHONGCOG',
      'MANOBISA',
      'MANOBO',
      'NOA',
      'OWAS',
      'PANGAO-AN',
      'POBLACION',
      'SALLAB',
      'TAGBAC',
      'TEMPORAN',
    ],
  },
  MAKILALA: {
    province: 'COTABATO (NORTH COT.)',
    municipality: 'MAKILALA',
    region: 'REGION XII',
    barangay_list: [
      'BATASAN',
      'BATO',
      'BIANGAN',
      'BUENA VIDA',
      'BUHAY',
      'BULAKANON',
      'CABILAO',
      'CONCEPCION',
      'DAGUPAN',
      'GARSIKA',
      'GUANGAN',
      'INDANGAN',
      'JOSE RIZAL',
      'KATIPUNAN II',
      'KAWAYANON',
      'KISANTE',
      'LEBOCE',
      'LIBERTAD',
      'LUAYON',
      'LUNA NORTE',
      'LUNA SUR',
      'MALABUAN',
      'MALASILA',
      'MALUNGON',
      'NEW BAGUIO',
      'NEW BULATUKAN',
      'NEW CEBU',
      'NEW ISRAEL',
      'OLD BULATUKAN',
      'POBLACION',
      'RODERO',
      'SAGUING',
      'SAN VICENTE',
      'SANTA FELOMINA',
      'SANTO NIÑO',
      'SINKATULAN',
      'TALUNTALUNAN',
      'VILLAFLORES',
    ],
  },
  MATALAM: {
    province: 'COTABATO (NORTH COT.)',
    municipality: 'MATALAM',
    region: 'REGION XII',
    barangay_list: [
      'ARAKAN',
      'BANGBANG',
      'BATO',
      'CENTRAL MALAMOTE',
      'DALAPITAN',
      'ESTADO',
      'ILIAN',
      'KABULACAN',
      'KIBIA',
      'KIBUDOC',
      'KIDAMA',
      'KILADA',
      'LAMPAYAN',
      'LATAGAN',
      'LINAO',
      'LOWER MALAMOTE',
      'MANUBUAN',
      'MANUPAL',
      'MARBEL',
      'MINAMAING',
      'NATUTUNGAN',
      'NEW ABRA',
      'NEW ALIMODIAN',
      'NEW BUGASONG',
      'NEW PANDAN',
      'PATADON WEST',
      'PINAMATON',
      'POBLACION',
      'SALVACION',
      'SANTA MARIA',
      'SARAYAN',
      'TACULEN',
      'TAGURANAO',
      'TAMPED (TAMPAD)',
    ],
  },
  MIDSAYAP: {
    province: 'COTABATO (NORTH COT.)',
    municipality: 'MIDSAYAP',
    region: 'REGION XII',
    barangay_list: [
      'AGRICULTURE',
      'ANONANG',
      'ARIZONA',
      'BAGUMBA',
      'BALIKI',
      'BARANGAY POBLACION 1',
      'BARANGAY POBLACION 2',
      'BARANGAY POBLACION 3',
      'BARANGAY POBLACION 4',
      'BARANGAY POBLACION 5',
      'BARANGAY POBLACION 6',
      'BARANGAY POBLACION 7',
      'BARANGAY POBLACION 8',
      'BITOKA',
      'BUAL NORTE',
      'BUAL SUR',
      'BULANAN UPPER',
      'CENTRAL BULANAN',
      'CENTRAL GLAD',
      'CENTRAL KATINGAWAN',
      'CENTRAL LABAS',
      'DAMATULAN',
      'ILBOCEAN',
      'KADIGASAN',
      'KADINGILAN',
      'KAPINPILAN',
      'KIMAGANGO',
      'KIWANAN',
      'KUDARANGAN',
      'LAGUMBINGAN',
      'LOMOPOG',
      'LOWER GLAD',
      'LOWER KATINGAWAN',
      'MACASENDEG',
      'MALAMOTE',
      'MALINGAO',
      'MILAYA',
      'MUDSENG',
      'NABALAWAG',
      'NALIN',
      'NES',
      'OLANDANG',
      'PALONGOGUEN',
      'PATINDEGUEN',
      'RANGABAN',
      'SADAAN',
      'SALUNAYAN',
      'SAMBULAWAN',
      'SAN ISIDRO',
      'SAN PEDRO',
      'SANTA CRUZ',
      'TUGAL',
      'TUMBRAS',
      'UPPER GLAD I',
      'UPPER GLAD II',
      'UPPER LABAS',
      'VILLARICA',
    ],
  },
  PIGKAWAYAN: {
    province: 'COTABATO (NORTH COT.)',
    municipality: 'PIGKAWAYAN',
    region: 'REGION XII',
    barangay_list: [
      'ANICK (UPPER BALOGO)',
      'BALACAYON',
      'BALOGO',
      'BANUCAGON',
      'BULUAN',
      'BULUCAON',
      'BURICAIN',
      'CABPANGI',
      'CAPAYURAN',
      'CENTRAL PANATAN',
      'DATU BINASING',
      'DATU MANTIL',
      'KADINGILAN',
      'KIMARAYANG',
      'LIBUNGAN TORRETA',
      'LOWER BAGUER',
      'LOWER PANGANGKALAN',
      'MALAGAKIT',
      'MALUAO',
      'MATILAC',
      'MIDPAPAN I',
      'MIDPAPAN II',
      'MULOK',
      'NEW CULASI',
      'NEW IGBARAS',
      'NEW PANAY',
      'NORTH MANUANGAN',
      'PATOT',
      'PAYONG-PAYONG',
      'POBLACION I',
      'POBLACION II',
      'POBLACION III',
      'PRESBITERO',
      'RENIBON',
      'SIMSIMAN',
      'SOUTH MANUANGAN',
      'TIGBAWAN',
      'TUBON',
      'UPPER BAGUER (BAGUER)',
      'UPPER PANGANGKALAN',
    ],
  },
  PIKIT: {
    province: 'COTABATO (NORTH COT.)',
    municipality: 'PIKIT',
    region: 'REGION XII',
    barangay_list: [
      'BAGOAINGUD (BAGOINGED)',
      'BALABAK',
      'BALATICAN',
      'BALONG',
      'BALUNGIS',
      'BARUNGIS',
      'BATULAWAN',
      'BUALAN',
      'BULIOK',
      'BULOD',
      'BULOL',
      'CALAWAG',
      'DALINGAOEN (LALINGAON)',
      'DAMALASAK',
      'FORT PIKIT',
      'GINATILAN',
      'GLIGLI',
      'GOKOTON (GOKOTAN)',
      'INUG-UG',
      'KABASALAN',
      'KALACACAN',
      'KATILACAN',
      'KOLAMBOG',
      'LADTINGAN',
      'LAGUNDE',
      'LANGAYEN',
      'MACABUAL',
      'MACASENDEG',
      'MANAULANAN',
      'NABUNDAS',
      'NALAPAAN',
      'NUNGUAN',
      'PAIDU PULANGI',
      'PAMALIAN',
      'PANICUPAN',
      'POBLACION',
      'PUNOL',
      'RAJAH MUDA',
      'SILIK',
      'TAKIPAN',
      'TALITAY',
      'TINUTULAN',
    ],
  },
  'PRESIDENT ROXAS': {
    province: 'CAPIZ',
    municipality: 'PRESIDENT ROXAS',
    region: 'REGION VI',
    barangay_list: [
      'ARANGUEL',
      'BADIANGON',
      'BAYUYAN',
      'CABUGCABUG',
      'CARMENCITA',
      'CUBAY',
      'CULILANG',
      'GOCE',
      'HANGLID',
      'IBACA',
      'MADULANO',
      'MANOLING',
      'MARITA',
      'PANDAN',
      'PANTALAN CABUGCABUG',
      'PINAMIHAGAN',
      'POBLACION (ELIZALDE)',
      'PONDOL',
      'QUIAJO',
      'SANGKAL',
      'SANTO NIÑO',
      'VIZCAYA',
    ],
  },
  TULUNAN: {
    province: 'COTABATO (NORTH COT.)',
    municipality: 'TULUNAN',
    region: 'REGION XII',
    barangay_list: [
      'BACONG',
      'BAGUMBAYAN',
      'BANAYAL',
      'BATANG',
      'BITUAN',
      'BUAL',
      'BUNAWAN',
      'DAIG',
      'DAMAWATO',
      'DUNGOS',
      'F. CAJELO',
      'GALIDAN',
      'GENOVEVA BAYNOSA',
      'KANIBONG',
      'LA ESPERANZA',
      'LAMPAGANG',
      'MAGBOK',
      'MAYBULA',
      'MINAPAN',
      'NABUNDASAN',
      'NEW CARIDAD',
      'NEW CULASI',
      'NEW PANAY',
      'PARAISO',
      'POBLACION',
      'POPOYON',
      'SIBSIB',
      'TAMBAC',
      'TUBURAN',
    ],
  },
  ALABEL: {
    province: 'SARANGANI',
    municipality: 'ALABEL',
    region: 'REGION XII',
    barangay_list: [
      'ALEGRIA',
      'BAGACAY',
      'BALUNTAY',
      'DATAL ANGGAS',
      'DOMOLOK',
      'KAWAS',
      'LADOL',
      'MARIBULAN',
      'PAG-ASA',
      'PARAISO',
      'POBLACION (ALABEL)',
      'SPRING',
      'TOKAWAL',
    ],
  },
  GLAN: {
    province: 'SARANGANI',
    municipality: 'GLAN',
    region: 'REGION XII',
    barangay_list: [
      'BALITON',
      'BATOTULING',
      'BATULAKI',
      'BIG MARGUS',
      'BURIAS',
      'CABLALAN',
      'CALABANIT',
      'CALPIDONG',
      'CONGAN',
      'CROSS',
      'DATALBUKAY',
      'E. ALEGADO',
      'GLAN PADIDU',
      'GUMASA',
      'ILAYA',
      'KALTUAD',
      'KAPATAN',
      'LAGO',
      'LAGUIMIT',
      'MUDAN',
      'NEW AKLAN',
      'PANGYAN',
      'POBLACION',
      'RIO DEL PILAR',
      'SAN JOSE',
      'SAN VICENTE',
      'SMALL MARGUS',
      'SUFATUBO',
      'TALUYA',
      'TANGO',
      'TAPON',
    ],
  },
  KIAMBA: {
    province: 'SARANGANI',
    municipality: 'KIAMBA',
    region: 'REGION XII',
    barangay_list: [
      'BADTASAN',
      'DATU DANI',
      'GASI',
      'KAPATE',
      'KATUBAO',
      'KAYUPO',
      'KLING (LUMIT)',
      'LAGUNDI',
      'LEBE',
      'LOMUYON',
      'LUMA',
      'MALIGANG',
      'NALUS',
      'POBLACION',
      'SALAKIT',
      'SULI',
      'TABLAO',
      'TAMADANG',
      'TAMBILIL',
    ],
  },
  MAASIM: {
    province: 'SARANGANI',
    municipality: 'MAASIM',
    region: 'REGION XII',
    barangay_list: [
      'AMSIPIT',
      'BALES',
      'COLON',
      'DALIAO',
      'KABATIOL',
      'KABLACAN',
      'KAMANGA',
      'KANALO',
      'LUMASAL',
      'LUMATIL',
      'MALBANG',
      'NOMOH',
      'PANANAG',
      'POBLACION (MAASIM)',
      'SEVEN HILLS',
      'TINOTO',
    ],
  },
  MAITUM: {
    province: 'SARANGANI',
    municipality: 'MAITUM',
    region: 'REGION XII',
    barangay_list: [
      'BATI-AN',
      'KALANEG',
      'KALAONG',
      'KIAMBING',
      'KIAYAP',
      'MABAY',
      'MAGULING',
      'MALALAG (POB.)',
      'MINDUPOK',
      'NEW LA UNION',
      'OLD POBLACION (MAITUM)',
      'PANGI (LINAO)',
      'PINOL',
      'SISON (EDENTON)',
      'TICULAB',
      'TUANADATU',
      'UPO (LANAO)',
      'WALI (KAMBUHAN)',
      'ZION',
    ],
  },
  MALAPATAN: {
    province: 'SARANGANI',
    municipality: 'MALAPATAN',
    region: 'REGION XII',
    barangay_list: [
      'DAAN SUYAN',
      'KIHAN',
      'KINAM',
      'LIBI',
      'LUN MASLA',
      'LUN PADIDU',
      'PATAG',
      'POBLACION (MALAPATAN)',
      'SAPU MASLA',
      'SAPU PADIDU',
      'TUYAN',
      'UPPER SUYAN',
    ],
  },
  MALUNGON: {
    province: 'SARANGANI',
    municipality: 'MALUNGON',
    region: 'REGION XII',
    barangay_list: [
      'ALKIKAN',
      'AMPON',
      'ATLAE',
      "B'LAAN",
      'BANAHAW',
      'BANATE',
      'DATAL BATONG',
      'DATAL BILA',
      'DATAL TAMPAL',
      'J.P. LAUREL',
      'KAWAYAN',
      'KIBALA',
      'KIBLAT',
      'KINABALAN',
      'LOWER MAINIT',
      'LUTAY',
      'MALABOD',
      'MALALAG COGON',
      'MALANDAG',
      'MALUNGON GAMAY',
      'NAGPAN',
      'PANAMIN',
      'POBLACION',
      'SAN JUAN',
      'SAN MIGUEL',
      'SAN ROQUE',
      'TALUS',
      'TAMBAN',
      'UPPER BIANGAN',
      'UPPER LUMABAT',
      'UPPER MAINIT',
    ],
  },
  BANGA: {
    province: 'AKLAN',
    municipality: 'BANGA',
    region: 'REGION VI',
    barangay_list: [
      'AGBANAWAN',
      'BACAN',
      'BADIANGAN',
      'CERRUDO',
      'CUPANG',
      'DAGUITAN',
      'DAJA NORTE',
      'DAJA SUR',
      'DINGLE',
      'JUMARAP',
      'LAPNAG',
      'LIBAS',
      'LINABUAN SUR',
      'MAMBOG',
      'MANGAN',
      'MUGUING',
      'PAGSANGHAN',
      'PALALE',
      'POBLACION',
      'POLO',
      'POLOCATE',
      'SAN ISIDRO',
      'SIBALEW',
      'SIGCAY',
      'TABA-AO',
      'TABAYON',
      'TINAPUAY',
      'TORRALBA',
      'UGSOD',
      'VENTURANZA',
    ],
  },
  'GENERAL SANTOS CITY (DADIANGAS)': {
    province: 'SOUTH COTABATO',
    municipality: 'GENERAL SANTOS CITY (DADIANGAS)',
    region: 'REGION XII',
    barangay_list: [
      'APOPONG',
      'BALUAN',
      'BATOMELONG',
      'BUAYAN',
      'BULA',
      'CALUMPANG',
      'CITY HEIGHTS',
      'CONEL',
      'DADIANGAS EAST (POB.)',
      'DADIANGAS NORTH',
      'DADIANGAS SOUTH',
      'DADIANGAS WEST',
      'FATIMA',
      'KATANGAWAN',
      'LABANGAL',
      'LAGAO (1ST AND 3RD)',
      'LIGAYA',
      'MABUHAY',
      'OLYMPOG',
      'SAN ISIDRO (LAGAO 2ND)',
      'SAN JOSE',
      'SIGUEL',
      'SINAWAL',
      'TAMBLER',
      'TINAGACAN',
      'UPPER LABAY',
    ],
  },
  'KORONADAL': {
    province: 'SOUTH COTABATO',
    municipality: 'KORONADAL',
    region: 'REGION XII',
    barangay_list: [
      'ASSUMPTION (BULOL)',
      'AVANCEÑA (BO. 3)',
      'CACUB',
      'CALOOCAN',
      'CARPENTER HILL',
      'CONCEPCION (BO. 6)',
      'ESPERANZA',
      'GENERAL PAULINO SANTOS (BO. 1)',
      'MABINI',
      'MAGSAYSAY',
      'MAMBUCAL',
      'MORALES',
      'NAMNAMA',
      'NEW PANGASINAN (BO. 4)',
      'PARAISO',
      'ROTONDA',
      'SAN ISIDRO',
      'SAN JOSE (BO. 5)',
      'SAN ROQUE',
      'SANTA CRUZ',
      'SANTO NIÑO (BO. 2)',
      'SARABIA (BO. 8)',
      'ZONE I (POB.)',
      'ZONE II (POB.)',
      'ZONE III (POB.)',
      'ZONE IV (POB.)',
      'ZULUETA (BO. 7)',
    ],
  },
  'LAKE SEBU': {
    province: 'SOUTH COTABATO',
    municipality: 'LAKE SEBU',
    region: 'REGION XII',
    barangay_list: [
      'BACDULONG',
      'DENLAG',
      'HALILAN',
      'HANOON',
      'KLUBI',
      'LAKE LAHIT',
      'LAMCADE',
      'LAMDALAG',
      'LAMFUGON',
      'LAMLAHAK',
      'LOWER MACULAN',
      'LUHIB',
      'NED',
      'POBLACION',
      'SILUTON',
      'TAKUNEL',
      'TALISAY',
      'TASIMAN',
      'UPPER MACULAN',
    ],
  },
  NORALA: {
    province: 'SOUTH COTABATO',
    municipality: 'NORALA',
    region: 'REGION XII',
    barangay_list: [
      'BENIGNO AQUINO, JR.',
      'DUMAGUIL',
      'ESPERANZA',
      'KIBID',
      'LAPUZ',
      'LIBERTY',
      'LOPEZ JAENA',
      'MATAPOL',
      'POBLACION',
      'PUTI',
      'SAN JOSE',
      'SAN MIGUEL',
      'SIMSIMAN',
      'TINAGO',
    ],
  },
  POLOMOLOK: {
    province: 'SOUTH COTABATO',
    municipality: 'POLOMOLOK',
    region: 'REGION XII',
    barangay_list: [
      'BENTUNG',
      'CANNERY SITE',
      'CROSSING PALKAN',
      'GLAMANG',
      'KINILIS',
      'KLINAN 6',
      'KORONADAL PROPER',
      'LAM-CALIAF',
      'LANDAN',
      'LAPU',
      'LUMAKIL',
      'MAGSAYSAY',
      'MALIGO',
      'PAGALUNGAN',
      'PALKAN',
      'POBLACION',
      'POLO',
      'RUBBER',
      'SILWAY 7',
      'SILWAY 8',
      'SULIT',
      'SUMBAKIL',
      'UPPER KLINAN',
    ],
  },
  'SANTO NIÑO': {
    province: 'SAMAR (WESTERN SAMAR)',
    municipality: 'SANTO NIÑO',
    region: 'REGION VIII',
    barangay_list: [
      'BALATGUTI',
      'BARAS',
      'BASUD (POB.)',
      'BUENAVISTA',
      'CABUNGA-AN',
      'COROCAWAYAN',
      'ILIJAN',
      'ILO (POB.)',
      'LOBELOBE',
      'PINANANGNAN',
      'SEVILLA',
      'TAKUT',
      'VILLAHERMOSA',
    ],
  },
  SURALLAH: {
    province: 'SOUTH COTABATO',
    municipality: 'SURALLAH',
    region: 'REGION XII',
    barangay_list: [
      'BUENAVISTA',
      'CANAHAY (GODWINO)',
      'CENTRALA',
      'COLONGULO',
      'DAJAY',
      'DUENGAS',
      'LAMBONTONG',
      'LAMIAN',
      'LAMSUGOD',
      'LIBERTAD (POB.)',
      'LITTLE BAGUIO',
      'MOLOY',
      'NACI (DOCE)',
      'TALAHIK',
      'TUBIALA',
      'UPPER SEPAKA',
      'VETERANS',
    ],
  },
  'T`BOLI': {
    province: 'SOUTH COTABATO',
    municipality: 'T`BOLI',
    region: 'REGION XII',
    barangay_list: [
      'AFLEK',
      'AFUS',
      'BASAG',
      'DATAL BOB',
      'DESAWO',
      'DLANAG',
      'EDWARDS (POB.)',
      'KEMATU',
      'LACONON',
      'LAMBANGAN',
      'LAMBULING',
      'LAMHAKO',
      'LAMSALOME',
      'LEMSNOLON',
      'MAAN',
      'MALUGONG',
      'MONGOCAYO',
      'NEW DUMANGAS',
      'POBLACION',
      'SALACAFE',
      'SINOLON',
      "T'BOLOK",
      'TALCON',
      'TALUFO',
      'TUDOK',
    ],
  },
  TAMPAKAN: {
    province: 'SOUTH COTABATO',
    municipality: 'TAMPAKAN',
    region: 'REGION XII',
    barangay_list: [
      'ALBAGAN',
      'BUTO',
      'DANLAG',
      'KIPALBIG',
      'LAMBAYONG',
      'LAMPITAK',
      'LIBERTY',
      'MALTANA',
      'PALO',
      'POBLACION',
      'PULA-BATO',
      'SAN ISIDRO',
      'SANTA CRUZ',
      'TABLU',
    ],
  },
  TANTANGAN: {
    province: 'SOUTH COTABATO',
    municipality: 'TANTANGAN',
    region: 'REGION XII',
    barangay_list: [
      'BUKAY PAIT',
      'CABULING',
      'DUMADALIG',
      'LIBAS',
      'MAGON',
      'MAIBO',
      'MANGILALA',
      'NEW CUYAPO',
      'NEW ILOILO',
      'NEW LAMBUNAO',
      'POBLACION',
      'SAN FELIPE',
      'TINONGCOP',
    ],
  },
  TUPI: {
    province: 'SOUTH COTABATO',
    municipality: 'TUPI',
    region: 'REGION XII',
    barangay_list: [
      'ACMONAN',
      'BOLOLMALA',
      'BUNAO',
      'CEBUANO',
      'CROSSING RUBBER',
      'KABLON',
      'KALKAM',
      'LINAN',
      'LUNEN',
      'MIASONG',
      'PALIAN',
      'POBLACION',
      'POLONULING',
      'SIMBO',
      'TUBENG',
    ],
  },
  BAGUMBAYAN: {
    province: 'SULTAN KUDARAT',
    municipality: 'BAGUMBAYAN',
    region: 'REGION XII',
    barangay_list: [
      'BAI SARIFINANG',
      'BIWANG',
      'BUSOK',
      'CHUA',
      'DAGUMA',
      'DALUGA',
      'KABULANAN',
      'KANULAY',
      'KAPAYA',
      'KINAYAO',
      'MASIAG',
      'MONTEVERDE',
      'POBLACION',
      'SANTO NIÑO',
      'SISON',
      'SOUTH SEPAKA',
      'SUMILIL',
      'TITULOK',
      'TUKA',
    ],
  },
  COLUMBIO: {
    province: 'SULTAN KUDARAT',
    municipality: 'COLUMBIO',
    region: 'REGION XII',
    barangay_list: [
      'BANTANGAN (LASAK)',
      'DATABLAO',
      'EDAY',
      'ELBEBE',
      'LASAK',
      'LIBERTAD',
      'LOMOYON',
      'MAKAT (SUMALI PAS)',
      'MALIGAYA',
      'MAYO',
      'NATIVIDAD',
      'POBLACION',
      'POLOMOLOK',
      'SINAPULAN',
      'SUCOB',
      'TELAFAS',
    ],
  },
  ESPERANZA: {
    province: 'MASBATE',
    municipality: 'ESPERANZA',
    region: 'REGION V',
    barangay_list: [
      'AGOHO',
      'ALMERO',
      'BARAS',
      'DOMOROG',
      'GUADALUPE',
      'ILIGAN',
      'LABANGTAYTAY',
      'LABRADOR',
      'LIBERTAD',
      'MAGSAYSAY',
      'MASBARANON',
      'POBLACION',
      'POTINGBATO',
      'RIZAL',
      'SAN ROQUE',
      'SANTIAGO',
      'SOROSIMBAJAN',
      'TAWAD',
      'TUNGA',
      'VILLA',
    ],
  },
  ISULAN: {
    province: 'SULTAN KUDARAT',
    municipality: 'ISULAN',
    region: 'REGION XII',
    barangay_list: [
      'BAMBAD',
      'BUAL',
      "D'LOTILLA",
      'DANSULI',
      'IMPAO',
      'KALAWAG I (POB.)',
      'KALAWAG II (POB.)',
      'KALAWAG III (POB.)',
      'KENRAM',
      'KOLAMBOG',
      'KUDANDING',
      'LAGANDANG',
      'LAGUILAYAN',
      'MAPANTIG',
      'NEW PANGASINAN',
      'SAMPAO',
      'TAYUGO',
    ],
  },
  KALAMANSIG: {
    province: 'SULTAN KUDARAT',
    municipality: 'KALAMANSIG',
    region: 'REGION XII',
    barangay_list: [
      'BANTOGON (SANTA CLARA)',
      'CADIZ',
      'DATU ITO ANDONG',
      'DATU WASAY',
      'DUMANGAS NUEVO',
      'HINALAAN',
      'LIMULAN',
      'NALILIDAN',
      'OBIAL',
      'PAG-ASA',
      'PARIL',
      'POBLACION',
      'SABANAL',
      'SANGAY',
      'SANTA MARIA',
    ],
  },
  'LAMBAYONG (MARIANO MARCOS)': {
    province: 'SULTAN KUDARAT',
    municipality: 'LAMBAYONG (MARIANO MARCOS)',
    region: 'REGION XII',
    barangay_list: [
      'CARIDAD (CUYAPON)',
      'DIDTARAS',
      'GANSING (BILUMEN)',
      'KABULAKAN',
      'KAPINGKONG',
      'KATITISAN',
      'LAGAO',
      'LILIT',
      'MADANDING',
      'MALIGAYA',
      'MAMALI',
      'MATIOMPONG',
      'MIDTAPOK',
      'NEW CEBU',
      'PALUMBI',
      'PIDTIGUIAN',
      'PIMBALAYAN',
      'PINGUIAMAN',
      'POBLACION (LAMBAYONG)',
      'SADSALAN',
      'SENEBEN',
      'SIGAYAN',
      'TAMBAK',
      'TINUMIGUES',
      'TUMIAO (TINAGA)',
      'UDTONG',
    ],
  },
  LEBAK: {
    province: 'SULTAN KUDARAT',
    municipality: 'LEBAK',
    region: 'REGION XII',
    barangay_list: [
      'AURELIO F. FREIRES SR. ( POBLACION II)',
      'BARURAO',
      'BARURAO II',
      'BASAK',
      'BOLEBOK',
      'BULULAWAN',
      'CAPILAN',
      'CHRISTIANNUEVO',
      'DATU KARON',
      'KALAMONGOG',
      'KEYTODAC',
      'KINODALAN',
      'NEW CALINOG',
      'NULING',
      'PANSUD',
      'PASANDALAN',
      'POBLACION',
      'POBLACION III',
      'POLOY-POLOY',
      'PURIKAY',
      'RAGANDANG',
      'SALAMAN',
      'SALANGSANG',
      'TAGUISA',
      'TIBPUAN',
      'TRAN',
      'VILLAMONTE',
    ],
  },
  LUTAYAN: {
    province: 'SULTAN KUDARAT',
    municipality: 'LUTAYAN',
    region: 'REGION XII',
    barangay_list: [
      'ANTONG',
      'BAYASONG',
      'BLINGKONG',
      'LUTAYAN PROPER',
      'MAINDANG',
      'MAMALI',
      'MANILI',
      'PALAVILLA',
      'SAMPAO',
      'SISIMAN',
      'TAMNAG (POB.)',
    ],
  },
  PALIMBANG: {
    province: 'SULTAN KUDARAT',
    municipality: 'PALIMBANG',
    region: 'REGION XII',
    barangay_list: [
      'AKOL',
      'BADIANGON',
      'BALIANGO',
      'BALWAN (BULAN)',
      'BAMBANEN',
      'BARANAYAN',
      'BARONGIS',
      'BATANG-BAGLAS',
      'BUTRIL',
      'COLOBE (TAGADTAL)',
      'DATU MAGUIALES',
      'DOMOLOL',
      'KABULING',
      'KALIBUHAN',
      'KANIPAAN',
      'KIDAYAN',
      'KIPONGET',
      'KISEK',
      'KRAAN',
      'KULONG-KULONG',
      'LANGALI',
      'LIBUA',
      'LIGAO',
      'LOPOKEN (LEPOLON)',
      'LUMITAN',
      'MAGANAO',
      'MAGUID',
      'MALATUNENG (MALATUNOL)',
      'MALISBONG',
      'MEDOL',
      'MILBUK',
      'MINA',
      'MOLON',
      'NAMAT MASLA',
      'NAPNAPON',
      'POBLACION',
      'SAN ROQUE',
      'TIBUHOL (EAST BADIANGON)',
      'WAL',
      'WASAG',
    ],
  },
  'PRESIDENT QUIRINO': {
    province: 'SULTAN KUDARAT',
    municipality: 'PRESIDENT QUIRINO',
    region: 'REGION XII',
    barangay_list: [
      'BAGUMBAYAN',
      'BANNAWAG',
      'BAYAWA',
      'C. MANGILALA',
      'ESTRELLA',
      'KALANAWE I',
      'KALANAWE II',
      'KATICO',
      'MALINGON',
      'MANGALEN',
      'PEDTUBO',
      'POBLACION (SAMBULAWAN)',
      'ROMUALDEZ',
      'SAN JOSE',
      'SAN PEDRO (TUATO)',
      'SINAKULAY',
      'SUBEN',
      'TINAUNGAN',
      'TUAL (LIGUASAN)',
    ],
  },
  'SEN. NINOY AQUINO': {
    province: 'SULTAN KUDARAT',
    municipality: 'SEN. NINOY AQUINO',
    region: 'REGION XII',
    barangay_list: [
      'BANALI',
      'BASAG',
      'BUENAFLORES',
      'BUGSO',
      'BUKLOD',
      'GAPOK',
      'KADI',
      'KAPATAGAN',
      'KIADSAM',
      'KUDEN',
      'KULAMAN',
      'LAGUBANG',
      'LANGGAL',
      'LIMUHAY',
      'MALEGDEG',
      'MIDTUNGOK',
      'NATI',
      'SEWOD',
      'TACUPIS',
      'TINALON',
    ],
  },
  'TACURONG': {
    province: 'SULTAN KUDARAT',
    municipality: 'TACURONG',
    region: 'REGION XII',
    barangay_list: [
      'BARAS',
      'BUENAFLOR',
      'CALEAN',
      'CARMEN',
      "D'LEDESMA",
      'GANSING',
      'KALANDAGAN',
      'LANCHETA',
      'LOWER KATUNGAL',
      'NEW ISABELA',
      'NEW LAGAO',
      'NEW PASSI',
      'POBLACION',
      'RAJAH NUDA',
      'SAN ANTONIO',
      'SAN EMMANUEL',
      'SAN PABLO',
      'SAN RAFAEL',
      'TINA',
      'UPPER KATUNGAL',
    ],
  },
  BUENAVISTA: {
    province: 'BOHOL',
    municipality: 'BUENAVISTA',
    region: 'REGION VII',
    barangay_list: [
      'ANONANG',
      'ASINAN',
      'BAGO',
      'BALUARTE',
      'BANTUAN',
      'BATO',
      'BONOTBONOT',
      'BUGAONG',
      'CAMBUHAT',
      'CAMBUS-OC',
      'CANGAWA',
      'CANTOMUGCAD',
      'CANTORES',
      'CANTUBA',
      'CATIGBIAN',
      'CAWAG',
      'CRUZ',
      'DAIT',
      'EASTERN CABUL-AN',
      'HUNAN',
      'LAPACAN NORTE',
      'LAPACAN SUR',
      'LUBANG',
      'LUSONG (PLATEAU)',
      'MAGKAYA',
      'MERRYLAND',
      'NUEVA GRANADA',
      'NUEVA MONTANA',
      'OVERLAND',
      'PANGHAGBAN',
      'POBLACION',
      'PUTING BATO',
      'RUFO HILL',
      'SWEETLAND',
      'WESTERN CABUL-AN',
    ],
  },
  'BUTUAN': {
    province: 'AGUSAN DEL NORTE',
    municipality: 'BUTUAN',
    region: 'REGION XIII',
    barangay_list: [
      'AGAO POB. (BGY. 3)',
      'AGUSAN PEQUEÑO',
      'AMBAGO',
      'AMPARO',
      'AMPAYON',
      'ANTICALA',
      'ANTONGALON',
      'AUPAGAN',
      'BAAN KM 3',
      'BAAN RIVERSIDE POB. (BGY. 20)',
      'BABAG',
      'BADING POB. (BGY. 22)',
      'BANCASI',
      'BANZA',
      'BAOBAOAN',
      'BASAG',
      'BAYANIHAN POB. (BGY. 27)',
      'BILAY',
      'BIT-OS',
      'BITAN-AGAN',
      'BOBON',
      'BONBON',
      'BUGABUS',
      'BUGSUKAN',
      'BUHANGIN POB. (BGY. 19)',
      'CABCABON',
      'CAMAYAHAN',
      'DAGOHOY POB. (BGY. 7)',
      'DANKIAS',
      'DE ORO',
      'DIEGO SILANG POB. (BGY. 6)',
      'DON FRANCISCO',
      'DOONGAN',
      'DULAG',
      'DUMALAGAN',
      'FLORIDA',
      'GOLDEN RIBBON POB. (BGY. 2)',
      'HOLY REDEEMER POB. (BGY. 23)',
      'HUMABON POB. (BGY. 11)',
      'IMADEJAS POB. (BGY. 24)',
      'JOSE RIZAL POB. (BGY. 25)',
      'KINAMLUTAN',
      'LAPU-LAPU POB. (BGY. 8)',
      'LEMON',
      'LEON KILAT POB. (BGY. 13)',
      'LIBERTAD',
      'LIMAHA POB. (BGY. 14)',
      'LOS ANGELES',
      'LUMBOCAN',
      'MAGUINDA',
      'MAHAY',
      'MAHOGANY POB. (BGY. 21)',
      'MAIBU',
      'MANDAMO',
      'MANILA DE BUGABUS',
      'MAON POB. (BGY. 1)',
      'MASAO',
      'MAUG',
      'NEW SOCIETY VILLAGE POB. (BGY. 26)',
      'NONG-NONG',
      'OBRERO POB. (BGY. 18)',
      'ONG YIU POB. (BGY. 16)',
      'PAGATPATAN',
      'PANGABUGAN',
      'PIANING',
      'PIGDAULAN',
      'PINAMANCULAN',
      'PORT POYOHON POB. (BGY. 17 - NEW ASIA)',
      'RAJAH SOLIMAN POB. (BGY. 4)',
      'SALVACION',
      'SAN IGNACIO POB. (BGY. 15)',
      'SAN MATEO',
      'SAN VICENTE',
      'SANTO NIÑO',
      'SIKATUNA POB. (BGY. 10)',
      'SILONGAN POB. (BGY. 5)',
      'SUMILE',
      'SUMILIHON',
      'TAGABACA',
      'TAGUIBO',
      'TALIGAMAN',
      'TANDANG SORA POB. (BGY. 12)',
      'TINIWISAN',
      'TUNGAO',
      'URDUJA POB. (BGY. 9)',
      'VILLA KANANGA',
    ],
  },
  'CABADBARAN': {
    province: 'AGUSAN DEL NORTE',
    municipality: 'CABADBARAN',
    region: 'REGION XIII',
    barangay_list: [
      'ANTONIO LUNA',
      'BAY-ANG',
      'BAYABAS',
      'CAASINAN',
      'CABINET',
      'CALAMBA',
      'CALIBUNAN',
      'COMAGASCAS',
      'CONCEPCION',
      'DEL PILAR',
      'KATUGASAN',
      'KAUSWAGAN',
      'LA UNION',
      'MABINI',
      'MAHABA',
      'POBLACION 1',
      'POBLACION 2',
      'POBLACION 3',
      'POBLACION 4',
      'POBLACION 5',
      'POBLACION 6',
      'POBLACION 7',
      'POBLACION 8',
      'POBLACION 9',
      'POBLACION 10',
      'POBLACION 11',
      'POBLACION 12',
      'PUTING BATO',
      'SANGHAN',
      'SORIANO',
      'TOLOSA',
    ],
  },
  JABONGA: {
    province: 'AGUSAN DEL NORTE',
    municipality: 'JABONGA',
    region: 'REGION XIII',
    barangay_list: [
      'A. BELTRAN (CAMALIG)',
      'BALEGUIAN',
      'BANGONAY',
      'BUNGA',
      'COLORADO',
      'CUYAGO',
      'LIBAS',
      'MAGDAGOOC',
      'MAGSAYSAY',
      'MARAIGING',
      'POBLACION (JABONGA)',
      'SAN JOSE',
      'SAN PABLO',
      'SAN VICENTE',
      'SANTO NIÑO',
    ],
  },
  KITCHARAO: {
    province: 'AGUSAN DEL NORTE',
    municipality: 'KITCHARAO',
    region: 'REGION XIII',
    barangay_list: [
      'BANGAYAN',
      'CANAWAY',
      'CROSSING',
      'HINIMBANGAN',
      'JALIOBONG',
      'MAHAYAHAY',
      'POBLACION',
      'SAN ISIDRO',
      'SAN ROQUE',
      'SANGAY',
      'SONGKOY',
    ],
  },
  'LAS NIEVES': {
    province: 'AGUSAN DEL NORTE',
    municipality: 'LAS NIEVES',
    region: 'REGION XIII',
    barangay_list: [
      'AMBACON',
      'BALUNGAGAN',
      'BONIFACIO',
      'CASIKLAN',
      'CONSORCIA',
      'DURIAN',
      'EDUARDO G. MONTILLA (CAMBOAYON)',
      'IBUAN',
      'KATIPUNAN',
      'LINGAYAO',
      'MALICATO',
      'MANINGALAO',
      'MARCOS CALO',
      'MAT-I',
      'PINANA-AN',
      'POBLACION',
      'ROSARIO',
      'SAN ISIDRO',
      'SAN ROQUE',
      'TINUCORAN',
    ],
  },
  MAGALLANES: {
    province: 'SORSOGON',
    municipality: 'MAGALLANES',
    region: 'REGION V',
    barangay_list: [
      'AGUADA NORTE',
      'AGUADA SUR',
      'ANIBONG',
      'BACALON',
      'BACOLOD',
      'BANACUD',
      'BEHIA',
      'BIGA',
      'BINISITAHAN DEL NORTE',
      'BINISITAHAN DEL SUR',
      'BITON',
      'BULALA',
      'BUSAY',
      'CADITAAN',
      'CAGBOLO',
      'CAGTALABA',
      'CAWIT EXTENSION',
      'CAWIT PROPER',
      'GINANGRA',
      'HUBO',
      'INCARIZAN',
      'LAPINIG',
      'MAGSAYSAY',
      'MALBOG',
      'PANTALAN',
      'PAWIK',
      'PILI',
      'POBLACION',
      'SALVACION',
      'SANTA ELENA',
      'SIUTON',
      'TAGAS',
      'TULATULA NORTE',
      'TULATULA SUR',
    ],
  },
  NASIPIT: {
    province: 'AGUSAN DEL NORTE',
    municipality: 'NASIPIT',
    region: 'REGION XIII',
    barangay_list: [
      'ACLAN',
      'AMONTAY',
      'ATA-ATAHON',
      'BARANGAY 1 (POB.)',
      'BARANGAY 2 (POB.)',
      'BARANGAY 3 (POB.)',
      'BARANGAY 4 (POB.)',
      'BARANGAY 5 (POB.)',
      'BARANGAY 6 (POB.)',
      'BARANGAY 7 (POB.)',
      'CAMAGONG',
      'CUBI-CUBI',
      'CULIT',
      'JAGUIMITAN',
      'KINABJANGAN',
      'PUNTA',
      'SANTA ANA',
      'TALISAY',
      'TRIANGULO',
    ],
  },
  'REMEDIOS T. ROMUALDEZ': {
    province: 'AGUSAN DEL NORTE',
    municipality: 'REMEDIOS T. ROMUALDEZ',
    region: 'REGION XIII',
    barangay_list: [
      'BALANGBALANG',
      'BASILISA',
      'HUMILOG',
      'PANAYTAYON',
      'POBLACION I (AGAY)',
      'POBLACION II',
      'SAN ANTONIO',
      'TAGBONGABONG',
    ],
  },
  SANTIAGO: {
    province: 'ILOCOS SUR',
    municipality: 'SANTIAGO',
    region: 'REGION I',
    barangay_list: [
      'AL-ALUDIG',
      'AMBUCAO',
      'BAYBAYABAS',
      'BIGBIGA',
      'BULBULALA',
      'BUSEL-BUSEL',
      'BUTOL',
      'CABURAO',
      'DAN-AR',
      'GABAO',
      'GUINABANG',
      'IMUS',
      'LANG-AYAN',
      'MAMBUG',
      'NALASIN',
      'OLO-OLO NORTE',
      'OLO-OLO SUR',
      'POBLACION NORTE',
      'POBLACION SUR',
      'SABANGAN',
      'SALINCUB',
      'SAN JOSE (BARAOAS)',
      'SAN ROQUE',
      'UBBOG',
    ],
  },
  TUBAY: {
    province: 'AGUSAN DEL NORTE',
    municipality: 'TUBAY',
    region: 'REGION XIII',
    barangay_list: [
      'BINUANGAN',
      'CABAYAWA',
      'DOÑA ROSARIO',
      'DOÑA TELESFORA',
      'LA FRATERNIDAD',
      'LAWIGAN',
      'POBLACION 1',
      'POBLACION 2',
      'SANTA ANA',
      'TAGMAMARKAY',
      'TAGPANGAHOY',
      'TINIGBASAN',
      'VICTORY',
    ],
  },
  'BAYUGAN': {
    province: 'AGUSAN DEL SUR',
    municipality: 'BAYUGAN',
    region: 'REGION XIII',
    barangay_list: [
      'BERSEBA',
      'BUCAC',
      'CAGBAS',
      'CALAITAN',
      'CANAYUGAN',
      'CHARITO',
      'CLARO CORTEZ',
      'FILI',
      'GAMAO',
      'GETSEMANE',
      'GRACE ESTATE',
      'HAMOGAWAY',
      'KATIPUNAN',
      'MABUHAY',
      'MAGKIANGKANG',
      'MAHAYAG',
      'MARCELINA',
      'MAYGATASAN',
      'MONTIVESTA',
      'MT. ARARAT',
      'MT. CARMEL',
      'MT. OLIVE',
      'NEW SALEM',
      'NOLI',
      'OSMEÑA',
      'PANAYTAY',
      'PINAGALAAN',
      'POBLACION',
      'SAGMONE',
      'SAGUMA',
      'SALVACION',
      'SAN AGUSTIN',
      'SAN ISIDRO',
      'SAN JUAN',
      'SANTA IRENE',
      'SANTA TERESITA',
      'SANTO NIÑO',
      'TAGLATAWAN',
      'TAGLIBAS',
      'TAGUBAY',
      'VERDU',
      'VILLA UNDAYON',
      'WAWA',
    ],
  },
  BUNAWAN: {
    province: 'AGUSAN DEL SUR',
    municipality: 'BUNAWAN',
    region: 'REGION XIII',
    barangay_list: [
      'BUNAWAN BROOK',
      'CONSUELO',
      'IMELDA',
      'LIBERTAD',
      'MAMBALILI',
      'NUEVA ERA',
      'POBLACION',
      'SAN ANDRES',
      'SAN MARCOS',
      'SAN TEODORO',
    ],
  },
  'LA PAZ': {
    province: 'ABRA',
    municipality: 'LA PAZ',
    region: 'CAR',
    barangay_list: [
      'BENBEN (BONBON)',
      'BULBULALA',
      'BULI',
      'CANAN (GAPAN)',
      'LIGUIS',
      'MALABBAGA',
      'MUDENG',
      'PIDIPID',
      'POBLACION',
      'SAN GREGORIO',
      'TOON',
      'UDANGAN',
    ],
  },
  LORETO: {
    province: 'DINAGAT ISLANDS',
    municipality: 'LORETO',
    region: 'REGION XIII',
    barangay_list: [
      'CARMEN (POB.)',
      'ESPERANZA',
      'FERDINAND',
      'HELENE',
      'LIBERTY',
      'MAGSAYSAY',
      'PANAMAON',
      'SAN JUAN (POB.)',
      'SANTA CRUZ (POB.)',
      'SANTIAGO (POB.)',
    ],
  },
  PROSPERIDAD: {
    province: 'AGUSAN DEL SUR',
    municipality: 'PROSPERIDAD',
    region: 'REGION XIII',
    barangay_list: [
      'AURORA',
      'AWA',
      'AZPETIA',
      'LA CARIDAD',
      'LA PERIAN',
      'LA PURISIMA',
      'LA SUERTE',
      'LA UNION',
      'LAS NAVAS',
      'LIBERTAD',
      'LOS ARCOS',
      'LUCENA',
      'MABUHAY',
      'MAGSAYSAY',
      'MAPAGA',
      'NAPO',
      'NEW MAUG',
      'PATIN-AY',
      'POBLACION (BAHBAH)',
      'SALIMBOGAON',
      'SALVACION',
      'SAN JOAQUIN',
      'SAN JOSE',
      'SAN LORENZO',
      'SAN MARTIN',
      'SAN PEDRO',
      'SAN RAFAEL',
      'SAN ROQUE',
      'SAN SALVADOR',
      'SAN VICENTE',
      'SANTA IRENE',
      'SANTA MARIA',
    ],
  },
  ROSARIO: {
    province: 'NORTHERN SAMAR',
    municipality: 'ROSARIO',
    region: 'REGION VIII',
    barangay_list: [
      'AGUADA',
      'BANTOLINAO',
      'BUENAVISTA',
      'COMMONWEALTH',
      'GUINDAULAN',
      'JAMOOG',
      'KAILINGAN',
      'LIGAYA',
      'POBLACION',
      'SALHAG',
      'SAN LORENZO',
    ],
  },
  'SAN FRANCISCO': {
    province: 'SOUTHERN LEYTE',
    municipality: 'SAN FRANCISCO',
    region: 'REGION VIII',
    barangay_list: [
      'ANISLAGON',
      'BONGAWISAN',
      'BONGBONG',
      'CAHAYAG',
      'CAUSI',
      'CENTRAL (POB.)',
      'DAKIT (POB.)',
      'GABI',
      'HABAY',
      'MALICO',
      'MARAYAG',
      'NAPANTAO',
      'PASANON',
      'PINAMUDLAN',
      'PUNTA',
      'SANTA CRUZ',
      'SANTA PAZ NORTE',
      'SANTA PAZ SUR',
      'SUDMON',
      'TINAAN',
      'TUNO',
      'UBOS (POB.)',
    ],
  },
  'SAN LUIS': {
    province: 'BATANGAS',
    municipality: 'SAN LUIS',
    region: 'REGION IV-A',
    barangay_list: [
      'ABIACAO',
      'BAGONG TUBIG',
      'BALAGTASIN',
      'BALITE',
      'BANOYO',
      'BOBOY',
      'BONLIW',
      'CALUMPANG EAST',
      'CALUMPANG WEST',
      'DULANGAN',
      'DURUNGAO',
      'LOCLOC',
      'LUYA',
      'MAHABANG PARANG',
      'MANGGAHAN',
      'MUZON',
      'POBLACION',
      'SAN ANTONIO',
      'SAN ISIDRO',
      'SAN JOSE',
      'SAN MARTIN',
      'SANTA MONICA',
      'TALIBA',
      'TALON',
      'TEJERO',
      'TUNGAL',
    ],
  },
  'SANTA JOSEFA': {
    province: 'AGUSAN DEL SUR',
    municipality: 'SANTA JOSEFA',
    region: 'REGION XIII',
    barangay_list: [
      'ANGAS',
      'AURORA',
      'AWAO',
      'CONCEPCION',
      'PAG-ASA',
      'PATROCINIO',
      'POBLACION',
      'SAN JOSE',
      'SANTA ISABEL',
      'SAYON',
      'TAPAZ',
    ],
  },
  SIBAGAT: {
    province: 'AGUSAN DEL SUR',
    municipality: 'SIBAGAT',
    region: 'REGION XIII',
    barangay_list: [
      'AFGA',
      'ANAHAWAN',
      'BANAGBANAG',
      'DEL ROSARIO',
      'EL RIO',
      'ILIHAN',
      'KAUSWAGAN',
      'KIOYA',
      'KOLAMBUGAN',
      'MAGKALAPE',
      'MAGSAYSAY',
      'MAHAYAHAY',
      'NEW TUBIGON',
      'PADIAY',
      'PEREZ',
      'POBLACION',
      'SAN ISIDRO',
      'SAN VICENTE',
      'SANTA CRUZ',
      'SANTA MARIA',
      'SINAI',
      'TABON-TABON',
      'TAG-UYANGO',
      'VILLANGIT',
    ],
  },
  TALACOGON: {
    province: 'AGUSAN DEL SUR',
    municipality: 'TALACOGON',
    region: 'REGION XIII',
    barangay_list: [
      'BATUCAN',
      'BUENAGRACIA',
      'CAUSWAGAN',
      'CULI',
      'DEL MONTE',
      'DESAMPARADOS',
      'LA FLORA',
      'LABNIG',
      'MAHARLIKA',
      'MARBON',
      'SABANG GIBUNG',
      'SAN AGUSTIN (POB.)',
      'SAN ISIDRO (POB.)',
      'SAN NICOLAS (POB.)',
      'ZAMORA',
      'ZILLOVIA',
    ],
  },
  TRENTO: {
    province: 'AGUSAN DEL SUR',
    municipality: 'TRENTO',
    region: 'REGION XIII',
    barangay_list: [
      'BASA',
      'CEBOLIN',
      'CUEVAS',
      'KAPATUNGAN',
      'LANGKILA-AN',
      'MANAT',
      'NEW VISAYAS',
      'PANGYAN',
      'POBLACION',
      'PULANG-LUPA',
      'SALVACION',
      'SAN IGNACIO',
      'SAN ISIDRO',
      'SAN ROQUE',
      'SANTA MARIA',
      'TUDELA',
    ],
  },
  VERUELA: {
    province: 'AGUSAN DEL SUR',
    municipality: 'VERUELA',
    region: 'REGION XIII',
    barangay_list: [
      'ANITAP',
      'BACAY II',
      'BINONGAN',
      'CAIGANGAN',
      'CANDIIS',
      'DEL MONTE',
      'DON MATEO',
      'KATIPUNAN',
      'LA FORTUNA',
      'LIMOT',
      'MAGSAYSAY',
      'MASAYAN',
      'POBLACION',
      'SAMPAGUITA',
      'SAN GABRIEL',
      'SANTA CRUZ',
      'SANTA EMELIA',
      'SAWAGAN',
      'SINOBONG',
      'SISIMON',
    ],
  },
  'BASILISA (RIZAL)': {
    province: 'DINAGAT ISLANDS',
    municipality: 'BASILISA (RIZAL)',
    region: 'REGION XIII',
    barangay_list: [
      'BENGLEN',
      'CATADMAN',
      'COLUMBUS',
      'CORING',
      'CORTES',
      'DIEGAS',
      'DOÑA HELENE',
      'EDERA',
      'FERDINAND',
      'GEOTINA',
      'IMEE (BACTASAN)',
      'MELGAR',
      'MONTAG',
      'NAVARRO',
      'NEW NAZARETH',
      'POBLACION',
      'PUERTO PRINCESA',
      'RITA GLENDA',
      'ROMA',
      'ROXAS',
      'SANTA MONICA',
      'SANTO NIÑO',
      'SERING',
      'SOMBRADO',
      'TAG-ABACA',
      'VILLA ECLEO',
      'VILLA PANTINOPLE',
    ],
  },
  CAGDIANAO: {
    province: 'DINAGAT ISLANDS',
    municipality: 'CAGDIANAO',
    region: 'REGION XIII',
    barangay_list: [
      'BOA',
      'CABUNGA-AN',
      'DEL PILAR',
      'LAGUNA',
      'LEGASPI',
      'MA-ATAS',
      'MABINI (BORJA)',
      'NUEVA ESTRELLA',
      'POBLACION',
      'R. ECLEO, SR.',
      'SAN JOSE',
      'SANTA RITA',
      'TIGBAO',
      'VALENCIA',
    ],
  },
  DINAGAT: {
    province: 'DINAGAT ISLANDS',
    municipality: 'DINAGAT',
    region: 'REGION XIII',
    barangay_list: [
      'BAGUMBAYAN',
      'CAB-ILAN',
      'CABAYAWAN',
      'CAYETANO',
      'ESCOLTA (POB.)',
      'GOMEZ',
      'JUSTINIANA EDERA',
      'MAGSAYSAY',
      'MAUSWAGON (POB.)',
      'NEW MABUHAY',
      'WADAS',
      'WHITE BEACH (POB.)',
    ],
  },
  'LIBJO (ALBOR)': {
    province: 'DINAGAT ISLANDS',
    municipality: 'LIBJO (ALBOR)',
    region: 'REGION XIII',
    barangay_list: [
      'ALBOR (POB.)',
      'ARELLANO',
      'BAYANIHAN',
      'DOÑA HELEN',
      'GARCIA',
      'GENERAL AGUINALDO (BOLOD-BOLOD)',
      'KANIHAAN',
      'LLAMERA',
      'MAGSAYSAY',
      'OSMEÑA',
      'PLARIDEL',
      'QUEZON',
      'ROSITA',
      'SAN ANTONIO (POB.)',
      'SAN JOSE',
      'SANTO NIÑO',
    ],
  },
  'SAN JOSE': {
    province: 'NORTHERN SAMAR',
    municipality: 'SAN JOSE',
    region: 'REGION VIII',
    barangay_list: [
      'AGUADAHAN',
      'BAGONG SABANG',
      'BALITE',
      'BARANGAY EAST (POB.)',
      'BARANGAY NORTH (POB.)',
      'BARANGAY SOUTH (POB.)',
      'BARANGAY WEST (POB.)',
      'BONGLAS',
      'DA-O',
      'GENGAROG',
      'GERATAG',
      'LAYUHAN',
      'MANDUGANG',
      'P. TINGZON',
      'SAN LORENZO',
      'TUBIGDANAO',
    ],
  },
  TUBAJON: {
    province: 'DINAGAT ISLANDS',
    municipality: 'TUBAJON',
    region: 'REGION XIII',
    barangay_list: [
      'DIAZ (ROMUALDEZ)',
      'IMELDA',
      'MABINI',
      'MALINAO',
      'NAVARRO',
      'ROXAS',
      'SAN ROQUE (POB.)',
      'SAN VICENTE (POB.)',
      'SANTA CRUZ (POB.)',
    ],
  },
  ALEGRIA: {
    province: 'CEBU',
    municipality: 'ALEGRIA',
    region: 'REGION VII',
    barangay_list: [
      'COMPOSTELA',
      'GUADALUPE',
      'LEGASPI',
      'LEPANTO',
      'MADRIDEJOS',
      'MONTPELLER',
      'POBLACION',
      'SANTA FILOMENA',
      'VALENCIA',
    ],
  },
  BACUAG: {
    province: 'SURIGAO DEL NORTE',
    municipality: 'BACUAG',
    region: 'REGION XIII',
    barangay_list: [
      'CABUGAO',
      'CAMBUAYON',
      'CAMPO',
      'DUGSANGON',
      'PAUTAO',
      'PAYAPAG',
      'POBLACION',
      'PUNGTOD',
      'SANTO ROSARIO',
    ],
  },
  BURGOS: {
    province: 'ISABELA',
    municipality: 'BURGOS',
    region: 'REGION II',
    barangay_list: [
      'BACNOR EAST',
      'BACNOR WEST',
      'CALIGUIAN (POB.)',
      'CATABBAN',
      'CULLALABO DEL NORTE',
      'CULLALABO DEL SUR',
      'CULLALABO SAN ANTONIO',
      'DALIG',
      'MALASIN',
      'MASIGUN',
      'RANIAG',
      'SAN BONIFACIO',
      'SAN MIGUEL',
      'SAN ROQUE',
    ],
  },
  CLAVER: {
    province: 'SURIGAO DEL NORTE',
    municipality: 'CLAVER',
    region: 'REGION XIII',
    barangay_list: [
      'BAGAKAY (POB. WEST)',
      'CABUGO',
      'CAGDIANAO',
      'DAYWAN',
      'HAYANGGABON',
      'LADGARON (POB.)',
      'LAPINIGAN',
      'MAGALLANES',
      'PANATAO',
      'SAPA',
      'TAGANITO',
      'TAYAGA (POB. EAST)',
      'URBIZTONDO',
      'WANGKE',
    ],
  },
  DAPA: {
    province: 'SURIGAO DEL NORTE',
    municipality: 'DAPA',
    region: 'REGION XIII',
    barangay_list: [
      'BAGAKAY',
      'BARANGAY 1 (POB.)',
      'BARANGAY 2 (POB.)',
      'BARANGAY 3 (POB.)',
      'BARANGAY 4 (POB.)',
      'BARANGAY 5 (POB.)',
      'BARANGAY 6 (POB.)',
      'BARANGAY 7 (POB.)',
      'BARANGAY 8 (POB.)',
      'BARANGAY 9 (POB.)',
      'BARANGAY 10 (POB.)',
      'BARANGAY 11 (POB.)',
      'BARANGAY 12 (POB.)',
      'BARANGAY 13 (POB.)',
      'BUENAVISTA',
      'CABAWA',
      'CAMBAS-AC',
      'CONSOLACION',
      'CORREGIDOR',
      'DAGOHOY',
      'DON PAULINO',
      'JUBANG',
      'MONTSERRAT',
      'OSMEÑA',
      'SAN CARLOS',
      'SAN MIGUEL',
      'SANTA FE',
      'SANTA FELOMINA',
      'UNION',
    ],
  },
  'DEL CARMEN': {
    province: 'SURIGAO DEL NORTE',
    municipality: 'DEL CARMEN',
    region: 'REGION XIII',
    barangay_list: [
      'ANTIPOLO',
      'BAGAKAY (ALBURO)',
      'BITOON',
      'CABUGAO',
      'CANCOHOY',
      'CAUB',
      'DEL CARMEN (POB.)',
      'DOMOYOG',
      'ESPERANZA',
      'HALIAN',
      'JAMOYAON',
      'KATIPUNAN',
      'LOBOGON',
      'MABUHAY',
      'MAHAYAHAY',
      'QUEZON',
      'SAN FERNANDO',
      'SAN JOSE (POB.)',
      'SAYAK',
      'TUBORAN',
    ],
  },
  'GENERAL LUNA': {
    province: 'QUEZON',
    municipality: 'GENERAL LUNA',
    region: 'REGION IV-A',
    barangay_list: [
      'BACONG IBABA',
      'BACONG ILAYA',
      'BARANGAY 1 (POB.)',
      'BARANGAY 2 (POB.)',
      'BARANGAY 3 (POB.)',
      'BARANGAY 4 (POB.)',
      'BARANGAY 5 (POB.)',
      'BARANGAY 6 (POB.)',
      'BARANGAY 7 (POB.)',
      'BARANGAY 8 (POB.)',
      'BARANGAY 9 (POB.)',
      'LAVIDES',
      'MAGSAYSAY',
      'MALAYA',
      'NIEVA',
      'RECTO',
      'SAN IGNACIO IBABA',
      'SAN IGNACIO ILAYA',
      'SAN ISIDRO IBABA',
      'SAN ISIDRO ILAYA',
      'SAN JOSE',
      'SAN NICOLAS',
      'SAN VICENTE',
      'SANTA MARIA IBABA',
      'SANTA MARIA ILAYA',
      'SUMILANG',
      'VILLARICA',
    ],
  },
  GIGAQUIT: {
    province: 'SURIGAO DEL NORTE',
    municipality: 'GIGAQUIT',
    region: 'REGION XIII',
    barangay_list: [
      'ALAMBIQUE (POB.)',
      'ANIBONGAN',
      'CAM-BOAYON',
      'CAMAM-ONAN',
      'IPIL (POB.)',
      'LAHI',
      'MAHANUB',
      'PONIENTE',
      'SAN ANTONIO (BONOT)',
      'SAN ISIDRO',
      'SICO-SICO',
      'VILLAFLOR',
      'VILLAFRANCA',
    ],
  },
  MAINIT: {
    province: 'SURIGAO DEL NORTE',
    municipality: 'MAINIT',
    region: 'REGION XIII',
    barangay_list: [
      'BINGA',
      'BOBONA-ON',
      'CANTUGAS',
      'DAYANO',
      'MABINI',
      'MAGPAYANG',
      'MAGSAYSAY (POB.)',
      'MANSAYAO',
      'MARAYAG',
      'MATIN-AO',
      'PACO',
      'QUEZON (POB.)',
      'ROXAS',
      'SAN FRANCISCO',
      'SAN ISIDRO',
      'SAN JOSE',
      'SIANA',
      'SILOP',
      'TAGBUYAWAN',
      'TAPI-AN',
      'TOLINGON',
    ],
  },
  MALIMONO: {
    province: 'SURIGAO DEL NORTE',
    municipality: 'MALIMONO',
    region: 'REGION XIII',
    barangay_list: [
      'BUNYASAN',
      'CAGTINAE',
      'CAN-AGA',
      'CANSAYONG',
      'CANTAPOY',
      'CAYAWAN',
      'DORO (BINOCARAN)',
      'HANAGDONG',
      'KARIHATAG',
      'MASGAD',
      'PILI',
      'SAN ISIDRO (POB.)',
      'TINAGO',
      'VILLARIZA',
    ],
  },
  PILAR: {
    province: 'ABRA',
    municipality: 'PILAR',
    region: 'CAR',
    barangay_list: [
      'BOLBOLO',
      'BROOKSIDE',
      'DALIT',
      'DINTAN',
      'GAPANG',
      'KINABITI',
      'MALIPLIPIT',
      'NAGCANASAN',
      'NANANGDUAN',
      'NARNARA',
      'OCUP',
      'PANG-OT',
      'PATAD',
      'POBLACION',
      'SAN JUAN EAST',
      'SAN JUAN WEST',
      'SOUTH BALIOAG',
      'TIKITIK',
      'VILLAVIEJA',
    ],
  },
  PLACER: {
    province: 'MASBATE',
    municipality: 'PLACER',
    region: 'REGION V',
    barangay_list: [
      'AGUADA',
      'BAN-AO',
      'BURABOD',
      'CABANGCALAN',
      'CALUMPANG',
      'CAMAYABSAN',
      'DAANLUNGSOD',
      'DANGPANAN',
      'DARAGA',
      'GUIN-AWAYAN',
      'GUINHAN-AYAN',
      'KATIPUNAN',
      'LIBAS',
      'LOCSO-AN',
      'LUNA',
      'MAHAYAG',
      'MAHAYAHAY',
      'MANLUT-OD',
      'MATAGANTANG',
      'NABOCTOT',
      'NAGARAO',
      'NAINDAY',
      'NAOCONDIOT',
      'PASIAGON',
      'PILI',
      'POBLACION',
      'PURO',
      'QUIBRADA',
      'SAN MARCOS',
      'SANTA CRUZ',
      'TABOC',
      'TAN-AWAN',
      'TAVERNA',
      'TUBOD',
      'VILLA INOCENCIO',
    ],
  },
  'SAN BENITO': {
    province: 'SURIGAO DEL NORTE',
    municipality: 'SAN BENITO',
    region: 'REGION XIII',
    barangay_list: [
      'BONGDO',
      'MARIBOJOC',
      'NUEVO CAMPO',
      'SAN JUAN',
      'SANTA CRUZ (POB.)',
      'TALISAY (POB.)',
    ],
  },
  'SAN FRANCISCO (ANAO-AON)': {
    province: 'SURIGAO DEL NORTE',
    municipality: 'SAN FRANCISCO (ANAO-AON)',
    region: 'REGION XIII',
    barangay_list: [
      'AMONTAY',
      'BALITE',
      'BANBANON',
      'DIAZ',
      'HONRADO',
      'JUBGAN',
      'LINONGGANAN',
      'MACOPA',
      'MAGTANGALE',
      'OSLAO',
      'POBLACION',
    ],
  },
  'SANTA MONICA (SAPAO)': {
    province: 'SURIGAO DEL NORTE',
    municipality: 'SANTA MONICA (SAPAO)',
    region: 'REGION XIII',
    barangay_list: [
      'ABAD SANTOS',
      'ALEGRIA',
      'BAILAN',
      'GARCIA',
      'LIBERTAD',
      'MABINI',
      'MABUHAY (POB.)',
      'MAGSAYSAY',
      'RIZAL',
      'T. ARLAN (POB.)',
      'TANGBO',
    ],
  },
  SISON: {
    province: 'PANGASINAN',
    municipality: 'SISON',
    region: 'REGION I',
    barangay_list: [
      'AGAT',
      'ALIBENG',
      'AMAGBAGAN',
      'ARTACHO',
      'ASAN NORTE',
      'ASAN SUR',
      'BANTAY INSIK',
      'BILA',
      'BINMECKEG',
      'BULAOEN EAST',
      'BULAOEN WEST',
      'CABARITAN',
      'CALUNETAN',
      'CAMANGAAN',
      'CAURINGAN',
      'DUNGON',
      'ESPERANZA',
      'INMALOG',
      'KILLO',
      'LABAYUG',
      'PALDIT',
      'PINDANGAN',
      'PINMILAPIL',
      'POBLACION CENTRAL',
      'POBLACION NORTE',
      'POBLACION SUR',
      'SAGUNTO',
      'TARA-TARA',
    ],
  },
  SOCORRO: {
    province: 'ORIENTAL MINDORO',
    municipality: 'SOCORRO',
    region: 'REGION IV-B',
    barangay_list: [
      'BAGSOK',
      'BATONG DALIG',
      'BAYUIN',
      'BUGTONG NA TUOG',
      'CALOCMOY',
      'CALUBAYAN',
      'CATININGAN',
      'FORTUNA',
      'HAPPY VALLEY',
      'LEUTEBORO I',
      'LEUTEBORO II',
      'MA. CONCEPCION',
      'MABUHAY I',
      'MABUHAY II',
      'MALUGAY',
      'MATUNGAO',
      'MONTEVERDE',
      'PASI I',
      'PASI II',
      'SANTO DOMINGO (LAPOG)',
      'SUBAAN',
      'VILLAREAL (DAAN)',
      'ZONE I (POB.)',
      'ZONE II (POB.)',
      'ZONE III (POB.)',
      'ZONE IV (POB.)',
    ],
  },
  'SURIGAO': {
    province: 'SURIGAO DEL NORTE',
    municipality: 'SURIGAO',
    region: 'REGION XIII',
    barangay_list: [
      'ALANG-ALANG',
      'ALEGRIA',
      'ANOMAR',
      'AURORA',
      'BALIBAYON',
      'BAYBAY',
      'BILABID',
      'BITAUGAN',
      'BONIFACIO',
      'BUENAVISTA',
      'CABONGBONGAN',
      'CAGNIOG',
      'CAGUTSAN',
      'CANLANIPA',
      'CANTIASAY',
      'CAPALAYAN',
      'CATADMAN',
      'DANAO',
      'DANAWAN',
      'DAY-ASAN',
      'IPIL',
      'LIBUAC',
      'LIPATA',
      'LISONDRA',
      'LUNA',
      'MABINI',
      'MABUA',
      'MANYAGAO',
      'MAPAWA',
      'MAT-I',
      'NABAGO',
      'NONOC',
      'OROK',
      'POCTOY',
      'PUNTA BILAR',
      'QUEZON',
      'RIZAL',
      'SABANG',
      'SAN ISIDRO',
      'SAN JOSE',
      'SAN JUAN',
      'SAN PEDRO (HANIGAD)',
      'SAN ROQUE',
      'SERNA (BAD-ASAY)',
      'SIDLAKAN',
      'SILOP',
      'SUGBAY',
      'SUKAILANG',
      'TAFT (POB.)',
      'TALISAY',
      'TOGBONGON',
      'TRINIDAD',
      'WASHINGTON (POB.)',
      'ZARAGOZA',
    ],
  },
  'TAGANA-AN': {
    province: 'SURIGAO DEL NORTE',
    municipality: 'TAGANA-AN',
    region: 'REGION XIII',
    barangay_list: [
      'AURORA (POB.)',
      'AZUCENA (POB.)',
      'BANBAN',
      'CAWILAN',
      'FABIO',
      'HIMAMAUG',
      'LAUREL',
      'LOWER LIBAS',
      'OPONG',
      'PATINO',
      'SAMPAGUITA (POB.)',
      'TALAVERA',
      'UNION',
      'UPPER LIBAS',
    ],
  },
  BAROBO: {
    province: 'SURIGAO DEL SUR',
    municipality: 'BAROBO',
    region: 'REGION XIII',
    barangay_list: [
      'AMAGA',
      'BAHI',
      'CABACUNGAN',
      'CAMBAGANG',
      'CAUSWAGAN',
      'DAPDAP',
      'DUGHAN',
      'GAMUT',
      'JAVIER',
      'KINAYAN',
      'MAMIS',
      'POBLACION',
      'RIZAL',
      'SAN JOSE',
      'SAN ROQUE',
      'SAN VICENTE',
      'SUA',
      'SUDLON',
      'TAMBIS',
      'UNIDAD',
      'WAKAT',
    ],
  },
  BAYABAS: {
    province: 'SURIGAO DEL SUR',
    municipality: 'BAYABAS',
    region: 'REGION XIII',
    barangay_list: [
      'AMAG',
      'BALETE (POB.)',
      'CABUGO',
      'CAGBAOTO',
      'LA PAZ',
      'MAGOBAWOK',
      'PANAOSAWON',
    ],
  },
  'BISLIG': {
    province: 'SURIGAO DEL SUR',
    municipality: 'BISLIG',
    region: 'REGION XIII',
    barangay_list: [
      'BUCTO',
      'BURBOANAN',
      'CAGUYAO',
      'COLETO',
      'COMAWAS',
      'KAHAYAG',
      'LABISMA',
      'LAWIGAN',
      'MAHARLIKA',
      'MANGAGOY',
      'MONE',
      'PAMANLINAN',
      'PAMAYPAYAN',
      'POBLACION',
      'SAN ANTONIO',
      'SAN FERNANDO',
      'SAN ISIDRO (BAGNAN)',
      'SAN JOSE',
      'SAN ROQUE (CADANGLASAN)',
      'SAN VICENTE',
      'SANTA CRUZ',
      'SIBAROY',
      'TABON',
      'TUMANAN',
    ],
  },
  CAGWAIT: {
    province: 'SURIGAO DEL SUR',
    municipality: 'CAGWAIT',
    region: 'REGION XIII',
    barangay_list: [
      'ARAS-ASAN',
      'BACOLOD',
      'BITAUGAN EAST',
      'BITAUGAN WEST',
      'LA PURISIMA (PALHE)',
      'LACTUDAN',
      'MAT-E',
      'POBLACION',
      'TAWAGAN',
      'TUBO-TUBO',
      'UNIDAD',
    ],
  },
  CANTILAN: {
    province: 'SURIGAO DEL SUR',
    municipality: 'CANTILAN',
    region: 'REGION XIII',
    barangay_list: [
      'BUGSUKAN',
      'BUNTALID',
      'CABANGAHAN',
      'CABAS-AN',
      'CALAGDAAN',
      'CONSUELO',
      'GENERAL ISLAND',
      'LININTI-AN (POB.)',
      'LOBO',
      'MAGASANG',
      'MAGOSILOM (POB.)',
      'PAG-ANTAYAN',
      'PALASAO',
      'PARANG',
      'SAN PEDRO',
      'TAPI',
      'TIGABONG',
    ],
  },
  CARRASCAL: {
    province: 'SURIGAO DEL SUR',
    municipality: 'CARRASCAL',
    region: 'REGION XIII',
    barangay_list: [
      'ADLAY',
      'BABUYAN',
      'BACOLOD',
      'BAYBAY (POB.)',
      'BON-OT',
      'CAGLAYAG',
      'DAHICAN',
      'DOYOS (POB.)',
      'EMBARCADERO (POB.)',
      'GAMUTON',
      'PANIKIAN',
      'PANTUKAN',
      'SACA (POB.)',
      'TAG-ANITO',
    ],
  },
  CORTES: {
    province: 'BOHOL',
    municipality: 'CORTES',
    region: 'REGION VII',
    barangay_list: [
      'DE LA PAZ',
      'FATIMA',
      'LORETO',
      'LOURDES',
      'MALAYO NORTE',
      'MALAYO SUR',
      'MONSERRAT',
      'NEW LOURDES',
      'PATROCINIO',
      'POBLACION',
      'ROSARIO',
      'SALVADOR',
      'SAN ROQUE',
      'UPPER DE LA PAZ',
    ],
  },
  HINATUAN: {
    province: 'SURIGAO DEL SUR',
    municipality: 'HINATUAN',
    region: 'REGION XIII',
    barangay_list: [
      'BACULIN',
      'BENIGNO AQUINO (ZONE I) POB.',
      'BIGAAN',
      'CAMBATONG',
      'CAMPA',
      'DUGMANON',
      'HARIP',
      'LA CASA (POB.)',
      'LOYOLA',
      'MALIGAYA',
      'PAGTIGNI-AN (BITOON)',
      'POCTO',
      'PORT LAMON',
      'ROXAS',
      'SAN JUAN',
      'SASA',
      'TAGASAKA',
      'TAGBOBONGA',
      'TALISAY',
      'TARUSAN',
      'TIDMAN',
      'TIWI',
      'ZONE II (POB.)',
      'ZONE III MAHARLIKA (POB.)',
    ],
  },
  LANUZA: {
    province: 'SURIGAO DEL SUR',
    municipality: 'LANUZA',
    region: 'REGION XIII',
    barangay_list: [
      'AGSAM',
      'BOCAWE',
      'BUNGA',
      'GAMUTON',
      'HABAG',
      'MAMPI',
      'NURCIA',
      'PAKWAN',
      'SIBAHAY',
      'ZONE I (POB.)',
      'ZONE II (POB.)',
      'ZONE III (POB.)',
      'ZONE IV (POB.)',
    ],
  },
  LIANGA: {
    province: 'SURIGAO DEL SUR',
    municipality: 'LIANGA',
    region: 'REGION XIII',
    barangay_list: [
      'ANIBONGAN',
      'BAN-AS',
      'BANAHAO',
      'BAUCAWE',
      'DIATAGON',
      'GANAYON',
      'LIATIMCO',
      'MANYAYAY',
      'PAYASAN',
      'POBLACION',
      'SAINT CHRISTINE',
      'SAN ISIDRO',
      'SAN PEDRO',
    ],
  },
  LINGIG: {
    province: 'SURIGAO DEL SUR',
    municipality: 'LINGIG',
    region: 'REGION XIII',
    barangay_list: [
      'ANIBONGAN',
      'BARCELONA',
      'BOGAK',
      'BONGAN',
      'HANDAMAYAN',
      'MAHAYAHAY',
      'MANDUS',
      'MANSA-ILAO',
      'PAGTILA-AN',
      'PALO ALTO',
      'POBLACION',
      'RAJAH CABUNGSO-AN',
      'SABANG',
      'SALVACION',
      'SAN ROQUE',
      'TAGPOPORAN',
      'UNION',
      'VALENCIA',
    ],
  },
  MADRID: {
    province: 'SURIGAO DEL SUR',
    municipality: 'MADRID',
    region: 'REGION XIII',
    barangay_list: [
      'BAGSAC',
      'BAYOGO',
      'LINIBONAN',
      'MAGSAYSAY',
      'MANGA',
      'PANAYOGON',
      'PATONG PATONG',
      'QUIRINO (POB.)',
      'SAN ANTONIO',
      'SAN JUAN',
      'SAN ROQUE',
      'SAN VICENTE',
      'SONGKIT',
      'UNION',
    ],
  },
  MARIHATAG: {
    province: 'SURIGAO DEL SUR',
    municipality: 'MARIHATAG',
    region: 'REGION XIII',
    barangay_list: [
      'ALEGRIA',
      'AMONTAY',
      'ANTIPOLO',
      'AROROGAN',
      'BAYAN',
      'MAHABA',
      'MARARAG',
      'POBLACION',
      'SAN ANTONIO',
      'SAN ISIDRO',
      'SAN PEDRO',
      'SANTA CRUZ',
    ],
  },
  'SAN AGUSTIN': {
    province: 'ROMBLON',
    municipality: 'SAN AGUSTIN',
    region: 'REGION IV-B',
    barangay_list: [
      'BACHAWAN',
      'BINONGAHAN',
      'BINUGUSAN',
      'BULI',
      'CABOLUTAN',
      'CAGBUAYA',
      'CAMANTAYA',
      'CARMEN',
      'CAWAYAN',
      'DOÑA JUANA',
      'DUBDUBAN',
      'LUSONG',
      'MAHABANG BAYBAY (LONG BEACH)',
      'POBLACION',
      'SUGOD',
    ],
  },
  TAGBINA: {
    province: 'SURIGAO DEL SUR',
    municipality: 'TAGBINA',
    region: 'REGION XIII',
    barangay_list: [
      'BATUNAN',
      'CARPENITO',
      'DOÑA CARMEN',
      'HINAGDANAN',
      'KAHAYAGAN',
      'LAGO',
      'MAGLAMBING',
      'MAGLATAB',
      'MAGSAYSAY',
      'MALIXI',
      'MANAMBIA',
      'OSMEÑA',
      'POBLACION',
      'QUEZON',
      'SAN VICENTE',
      'SANTA CRUZ',
      'SANTA FE',
      'SANTA JUANA',
      'SANTA MARIA',
      'SAYON',
      'SORIANO',
      'TAGONGON',
      'TRINIDAD',
      'UGOBAN',
      'VILLAVERDE',
    ],
  },
  TAGO: {
    province: 'SURIGAO DEL SUR',
    municipality: 'TAGO',
    region: 'REGION XIII',
    barangay_list: [
      'ALBA',
      'ANAHAO BAG-O',
      'ANAHAO DAAN',
      'BADONG',
      'BAJAO',
      'BANGSUD',
      'CABANGAHAN',
      'CAGDAPAO',
      'CAMAGONG',
      'CARAS-AN',
      'CAYALE',
      'DAYO-AN',
      'GAMUT',
      'JUBANG',
      'KINABIGTASAN',
      'LAYOG',
      'LINDOY',
      'MERCEDES',
      'PURISIMA (POB.)',
      'SUMO-SUMO',
      'UMBAY',
      'UNABAN',
      'UNIDOS',
      'VICTORIA',
    ],
  },
  'TANDAG': {
    province: 'SURIGAO DEL SUR',
    municipality: 'TANDAG',
    region: 'REGION XIII',
    barangay_list: [
      'AWASIAN',
      'BAGONG LUNGSOD (POB.)',
      'BIOTO',
      'BONGTOD POB. (EAST WEST)',
      'BUENAVISTA',
      'DAGOCDOC (POB.)',
      'MABUA',
      'MABUHAY',
      'MAITUM',
      'MATICDUM',
      'PANDANON',
      'PANGI',
      'QUEZON',
      'ROSARIO',
      'SALVACION',
      'SAN AGUSTIN NORTE',
      'SAN AGUSTIN SUR',
      'SAN ANTONIO',
      'SAN ISIDRO',
      'SAN JOSE',
      'TELAJE',
    ],
  },
  ADAMS: {
    province: 'ILOCOS NORTE',
    municipality: 'ADAMS',
    region: 'REGION I',
    barangay_list: ['ADAMS (POB.)'],
  },
  BACARRA: {
    province: 'ILOCOS NORTE',
    municipality: 'BACARRA',
    region: 'REGION I',
    barangay_list: [
      'BANI',
      'BUYON',
      'CABARUAN',
      'CABULALAAN',
      'CABUSLIGAN',
      'CADARATAN',
      'CALIOET-LIBONG',
      'CASILIAN',
      'COROCOR',
      'DURIPES',
      'GANAGAN',
      'LIBTONG',
      'MACUPIT',
      'NAMBARAN',
      'NATBA',
      'PANINAAN',
      'PASIOCAN',
      'PASNGAL',
      'PIPIAS',
      'PULANGI',
      'PUNGTO',
      'SAN AGUSTIN I (POB.)',
      'SAN AGUSTIN II (POB.)',
      'SAN ANDRES I (POB.)',
      'SAN ANDRES II (POB.)',
      'SAN GABRIEL I (POB.)',
      'SAN GABRIEL II (POB.)',
      'SAN PEDRO I (POB.)',
      'SAN PEDRO II (POB.)',
      'SAN ROQUE I (POB.)',
      'SAN ROQUE II (POB.)',
      'SAN SIMON I (POB.)',
      'SAN SIMON II (POB.)',
      'SAN VICENTE (POB.)',
      'SANGIL',
      'SANTA FILOMENA I (POB.)',
      'SANTA FILOMENA II (POB.)',
      'SANTA RITA (POB.)',
      'SANTO CRISTO I (POB.)',
      'SANTO CRISTO II (POB.)',
      'TAMBIDAO',
      'TEPPANG',
      'TUBBURAN',
    ],
  },
  BADOC: {
    province: 'ILOCOS NORTE',
    municipality: 'BADOC',
    region: 'REGION I',
    barangay_list: [
      'ALAY-NANGBABAAN (ALAY 15-B)',
      'ALOGOOG',
      'AR-ARUSIP',
      'ARING',
      'BALBALDEZ',
      'BATO',
      'CAMANGA',
      'CANAAN (POB.)',
      'CARAITAN',
      'GABUT NORTE',
      'GABUT SUR',
      'GARRETA (POB.)',
      'LA VIRGEN MILAGROSA (PAGUETPET)',
      'LABUT',
      'LACUBEN',
      'LUBIGAN',
      'MABUSAG NORTE',
      'MABUSAG SUR',
      'MADUPAYAS',
      'MORONG',
      'NAGREBCAN',
      'NAPU',
      'PAGSANAHAN NORTE',
      'PAGSANAHAN SUR',
      'PALTIT',
      'PARANG',
      'PASUC',
      'SANTA CRUZ NORTE',
      'SANTA CRUZ SUR',
      'SAUD',
      'TUROD',
    ],
  },
  BANGUI: {
    province: 'ILOCOS NORTE',
    municipality: 'BANGUI',
    region: 'REGION I',
    barangay_list: [
      'ABACA',
      'BACSIL',
      'BANBAN',
      'BARUYEN',
      'DADAOR',
      'LANAO',
      'MALASIN',
      'MANAYON',
      'MASIKIL',
      'NAGBALAGAN',
      'PAYAC',
      'SAN LORENZO (POB.)',
      'TAGUIPORO',
      'UTOL',
    ],
  },
  'BANNA (ESPIRITU)': {
    province: 'ILOCOS NORTE',
    municipality: 'BANNA (ESPIRITU)',
    region: 'REGION I',
    barangay_list: [
      'BALIOEG',
      'BANGSAR',
      'BARBARANGAY',
      'BINACAG',
      'BOMITOG',
      'BUGASI',
      'CAESTEBANAN',
      'CARIBQUIB',
      'CATAGTAGUEN',
      'CRISPINA',
      'HILARIO (POB.)',
      'IMELDA',
      'LORENZO (POB.)',
      'MACAYEPYEP',
      'MARCOS (POB.)',
      'NAGPATAYAN',
      'SINAMAR',
      'TABTABAGAN',
      'VALDEZ',
      'VALENCIANO (POB.)',
    ],
  },
  'BATAC': {
    province: 'ILOCOS NORTE',
    municipality: 'BATAC',
    region: 'REGION I',
    barangay_list: [
      'ABLAN POB. (LABUCAO)',
      'ACOSTA POB. (ILOILO)',
      'AGLIPAY (POB.)',
      'BAAY',
      'BALIGAT',
      'BAOA EAST',
      'BAOA WEST',
      'BARANI (POB.)',
      'BEN-AGAN (POB.)',
      'BIL-LOCA',
      'BININGAN',
      'BUNGON',
      'CALLAGUIP (POB.)',
      'CAMANDINGAN',
      'CAMGUIDAN',
      'CANGRUNAAN (POB.)',
      'CAPACUAN',
      'CAUNAYAN (POB.)',
      'COLO',
      'DARIWDIW',
      'LACUB (POB.)',
      'MABALENG',
      'MAGNUANG',
      'MAIPALIG',
      'NAGBACALAN',
      'NAGUIRANGAN',
      'PALONGPONG',
      'PALPALICONG (POB.)',
      'PARANGOPONG',
      'PAYAO',
      'PIMENTEL (CUBOL)',
      'QUILING NORTE',
      'QUILING SUR',
      'QUIOM',
      'RAYURAY',
      'RICARTE POB. (NALASIN)',
      'SAN JULIAN (POB.)',
      'SAN MATEO',
      'SAN PEDRO',
      'SUABIT (POB.)',
      'SUMADER',
      'TABUG',
      'VALDEZ POB. (CAOAYAN)',
    ],
  },
  CARASI: {
    province: 'ILOCOS NORTE',
    municipality: 'CARASI',
    region: 'REGION I',
    barangay_list: ['ANGSET', 'BARBAQUESO (POB.)', 'VIRBIRA'],
  },
  CURRIMAO: {
    province: 'ILOCOS NORTE',
    municipality: 'CURRIMAO',
    region: 'REGION I',
    barangay_list: [
      'ANGGAPANG NORTE',
      'ANGGAPANG SUR',
      'BIMMANGA',
      'CABUUSAN',
      'COMCOMLOONG',
      'GAANG',
      'LANG-AYAN-BARAMBAN',
      'LIOES',
      'MAGLAOI CENTRO',
      'MAGLAOI NORTE',
      'MAGLAOI SUR',
      'PAGULUDAN-SALINDEG',
      'PANGIL',
      'PIAS NORTE',
      'PIAS SUR',
      'POBLACION I',
      'POBLACION II',
      'SALUGAN',
      'SAN SIMEON',
      'SANTA CRUZ',
      'TAPAO-TIGUE',
      'TORRE',
      'VICTORIA',
    ],
  },
  DINGRAS: {
    province: 'ILOCOS NORTE',
    municipality: 'DINGRAS',
    region: 'REGION I',
    barangay_list: [
      'ALBANO (POB.)',
      'BACSIL',
      'BAGUT',
      'BARESBES',
      'BARONG',
      'BUNGCAG',
      'CALI',
      'CAPASAN',
      'DANCEL (POB.)',
      'ELIZABETH',
      'ESPIRITU',
      'FOZ',
      'GUERRERO (POB.)',
      'LANAS',
      'LUMBAD',
      'MADAMBA (POB.)',
      'MANDALOQUE',
      'MEDINA',
      'PARADO (BANGAY)',
      'PERALTA (POB.)',
      'PURUGANAN (POB.)',
      'ROOT (BALDIAS)',
      'SAGPATAN',
      'SALUDARES',
      'SAN ESTEBAN',
      'SAN FRANCISCO (SURRATE)',
      'SAN MARCELINO (PADONG)',
      'SAN MARCOS',
      'SULQUIANO (SIDIRAN)',
      'SUYO',
      'VER (NAGLAYAAN)',
    ],
  },
  DUMALNEG: {
    province: 'ILOCOS NORTE',
    municipality: 'DUMALNEG',
    region: 'REGION I',
    barangay_list: ['CABARITAN', 'KALAW', 'QUIBEL', 'SAN ISIDRO'],
  },
  'LAOAG': {
    province: 'ILOCOS NORTE',
    municipality: 'LAOAG',
    region: 'REGION I',
    barangay_list: [
      'BGY. NO. 1, SAN LORENZO (POB.)',
      'BGY. NO. 2, SANTA JOAQUINA (POB.)',
      'BGY. NO. 3, NSTRA. SRA. DEL ROSARIO (POB.)',
      'BGY. NO. 4, SAN GUILLERMO (POB.)',
      'BGY. NO. 5, SAN PEDRO (POB.)',
      'BGY. NO. 6, SAN AGUSTIN (POB.)',
      'BGY. NO. 7-A, NSTRA. SRA. DE NATIVIDAD (POB.)',
      'BGY. NO. 7-B, NSTRA. SRA. DE NATIVIDAD (POB.)',
      'BGY. NO. 8, SAN VICENTE (POB.)',
      'BGY. NO. 9, SANTA ANGELA (POB.)',
      'BGY. NO. 10, SAN JOSE (POB.)',
      'BGY. NO. 11, SANTA BALBINA (POB.)',
      'BGY. NO. 12, SAN ISIDRO (POB.)',
      'BGY. NO. 13, NSTRA. SRA. DE VISITACION (POB.)',
      'BGY. NO. 14, SANTO TOMAS (POB.)',
      'BGY. NO. 15, SAN GUILLERMO (POB.)',
      'BGY. NO. 16, SAN JACINTO (POB.)',
      'BGY. NO. 17, SAN FRANCISCO (POB.)',
      'BGY. NO. 19, SANTA MARCELA (POB.)',
      'BGY. NO. 20, SAN MIGUEL (POB.)',
      'BGY. NO. 21, SAN PEDRO (POB.)',
      'BGY. NO. 22, SAN ANDRES (POB.)',
      'BGY. NO. 23, SAN MATIAS (POB.)',
      'BGY. NO. 24, NSTRA. SRA. DE CONSOLACION (POB.)',
      'BGY. NO. 25, SANTA CAYETANA (POB.)',
      'BGY. NO. 26, SAN MARCELINO (POB.)',
      'BGY. NO. 27, NSTRA. SRA. DE SOLEDAD (POB.)',
      'BGY. NO. 28, SAN BERNARDO (POB.)',
      'BGY. NO. 29, SANTO TOMAS (POB.)',
      'BGY. NO. 30-A, SUYO',
      'BGY. NO. 30-B, SANTA MARIA',
      'BGY. NO. 31, TALINGAAN',
      'BGY. NO. 32-A, LA PAZ EAST',
      'BGY. NO. 32-B, LA PAZ WEST',
      'BGY. NO. 32-C LA PAZ EAST',
      'BGY. NO. 33-A, LA PAZ PROPER',
      'BGY. NO. 33-B, LA PAZ PROPER',
      'BGY. NO. 34-A, GABU NORTE WEST',
      'BGY. NO. 34-B, GABU NORTE EAST',
      'BGY. NO. 35, GABU SUR',
      'BGY. NO. 36, ARANIW',
      'BGY. NO. 37, CALAYAB',
      'BGY. NO. 38-A, MANGATO EAST',
      'BGY. NO. 38-B, MANGATO WEST',
      'BGY. NO. 39, SANTA ROSA',
      'BGY. NO. 40, BALATONG',
      'BGY. NO. 41, BALACAD',
      'BGY. NO. 42, APAYA',
      'BGY. NO. 43, CAVIT',
      'BGY. NO. 44, ZAMBOANGA',
      'BGY. NO. 45, TANGID',
      'BGY. NO. 46, NALBO',
      'BGY. NO. 47, BENGCAG',
      'BGY. NO. 48-B, CABUNGAAN SOUTH',
      'BGY. NO. 49-A, DARAYDAY',
      'BGY. NO. 49-B, RARABURAN',
      'BGY. NO. 50, BUTTONG',
      'BGY. NO. 51-A, NANGALISAN EAST',
      'BGY. NO. 51-B, NANGALISAN WEST',
      'BGY. NO. 52-A, SAN MATEO',
      'BGY. NO. 52-B, LATAAG',
      'BGY. NO. 53, RIOENG',
      'BGY. NO. 54-A, LAGUI-SAIL',
      'BGY. NO. 54-B, CAMANGAAN',
      'BGY. NO. 55-A, BARIT-PANDAN',
      'BGY. NO. 55-B, SALET-BULANGON',
      'BGY. NO. 55-C, VIRA',
      'BGY. NO. 56-A, BACSIL NORTH',
      'BGY. NO. 56-B, BACSIL SOUTH',
      'BGY. NO. 57, PILA',
      'BGY. NO. 58, CASILI',
      'BGY. NO. 59-A, DIBUA SOUTH',
      'BGY. NO. 59-B, DIBUA NORTH',
      'BGY. NO. 60-A, CAAOACAN',
      'BGY. NO. 60-B, MADILADIG',
      'BGY. NO. 61, CATABAN',
      'BGY. NO. 62-A, NAVOTAS NORTH',
      'BGY. NO. 62-B, NAVOTAS SOUTH',
      'BRY. NO. 18, SAN QUIRINO (POB.)',
      'BRY. NO. 48-A, CABUNGAAN NORTH',
    ],
  },
  MARCOS: {
    province: 'ILOCOS NORTE',
    municipality: 'MARCOS',
    region: 'REGION I',
    barangay_list: [
      'CACAFEAN',
      'DAQUIOAG',
      'ELIZABETH (CULAO)',
      'ESCODA',
      'FERDINAND',
      'FORTUNA',
      'IMELDA (CAPARIAAN)',
      'LYDIA (POB.)',
      'MABUTI',
      'PACIFICO (AGUNIT)',
      'SANTIAGO',
      'TABUCBUC (RAGAS)',
      'VALDEZ (BIDING)',
    ],
  },
  'NUEVA ERA': {
    province: 'ILOCOS NORTE',
    municipality: 'NUEVA ERA',
    region: 'REGION I',
    barangay_list: [
      'ACNAM',
      'BARANGOBONG',
      'BARIKIR',
      'BUGAYONG',
      'CABITTAURAN',
      'CARAY',
      'GARNADEN',
      'NAGUILLAN (PAGPAG-ONG)',
      'POBLACION',
      'SANTO NIÑO',
      'UGUIS',
    ],
  },
  PAGUDPUD: {
    province: 'ILOCOS NORTE',
    municipality: 'PAGUDPUD',
    region: 'REGION I',
    barangay_list: [
      'AGGASI',
      'BADUANG',
      'BALAOI',
      'BURAYOC',
      'CAPARISPISAN',
      'CAUNAYAN',
      'DAMPIG',
      'LIGAYA',
      'PANCIAN',
      'PASALENG',
      'POBLACION 1',
      'POBLACION 2',
      'SAGUIGUI',
      'SAUD',
      'SUBEC',
      'TARRAG',
    ],
  },
  PAOAY: {
    province: 'ILOCOS NORTE',
    municipality: 'PAOAY',
    region: 'REGION I',
    barangay_list: [
      'BACSIL',
      'CABAGOAN',
      'CABANGARAN',
      'CALLAGUIP',
      'CAYUBOG',
      'DOLORES',
      'LAOA',
      'MASINTOC',
      'MONTE',
      'MUMULAAN',
      'NAGBACALAN',
      'NALASIN',
      'NANGUYUDAN',
      'OAIG-UPAY-ABULAO',
      'PAMBARAN',
      'PANNARATAN (POB.)',
      'PARATONG',
      'PASIL',
      'SALBANG (POB.)',
      'SAN AGUSTIN',
      'SAN BLAS (POB.)',
      'SAN JUAN',
      'SAN PEDRO',
      'SAN ROQUE (POB.)',
      'SANGLADAN POB. (NALBUAN)',
      'SANTA RITA (POB.)',
      'SIDEG',
      'SUBA',
      'SUNGADAN',
      'SURGUI',
      'VERONICA',
    ],
  },
  PASUQUIN: {
    province: 'ILOCOS NORTE',
    municipality: 'PASUQUIN',
    region: 'REGION I',
    barangay_list: [
      'BATULI',
      'BINSANG',
      'CARUAN',
      'CARUSIKIS',
      'CARUSIPAN',
      'DADAEMAN',
      'DARUPIDIP',
      'DAVILA',
      'DILANIS',
      'DILAVO',
      'ESTANCIA',
      'NAGLICUAN',
      'NAGSANGA',
      'NALVO (CABABAAN-NALVO)',
      'NGABANGAB',
      'PANGIL',
      'POBLACION 1',
      'POBLACION 2',
      'POBLACION 3',
      'POBLACION 4',
      'PRAGATA (PRAGATA-BUNGRO)',
      'PUYUPUYAN',
      'SALPAD',
      'SAN JUAN',
      'SANTA CATALINA',
      'SANTA MATILDE',
      'SAPAT',
      'SULBEC',
      'SULONGAN',
      'SURONG',
      'SUSUGAEN',
      'TABUNGAO',
      'TADAO',
    ],
  },
  PIDDIG: {
    province: 'ILOCOS NORTE',
    municipality: 'PIDDIG',
    region: 'REGION I',
    barangay_list: [
      'AB-ABUT',
      'ABUCAY',
      'ANAO (POB.)',
      'ARUA-AY',
      'BIMMANGA',
      'BOYBOY',
      'CABAROAN (POB.)',
      'CALAMBEG',
      'CALLUSA',
      'DUPITAC',
      'ESTANCIA',
      'GAYAMAT',
      'LAGANDIT',
      'LIBNAOAN',
      'LOING (POB.)',
      'MAAB-ABACA',
      'MANGITAYAG',
      'MARUAYA',
      'SAN ANTONIO',
      'SANTA MARIA',
      'SUCSUQUEN',
      'TANGAOAN',
      'TONOTON',
    ],
  },
  PINILI: {
    province: 'ILOCOS NORTE',
    municipality: 'PINILI',
    region: 'REGION I',
    barangay_list: [
      'AGLIPAY',
      'APATUT-LUBONG',
      'BADIO',
      'BARBAR',
      'BUANGA',
      'BULBULALA',
      'BUNGRO',
      'CABAROAN',
      'CAPANGDANAN',
      'DALAYAP',
      'DARAT',
      'GULPENG',
      'LILIPUTEN',
      'LUMBAAN-BICBICA',
      'NAGTRIGOAN',
      'PAGDILAO (POB.)',
      'PUGAOAN',
      'PURITAC',
      'PUZOL',
      'SACRITAN',
      'SALANAP',
      'SANTO TOMAS',
      'TARTARABANG',
      'UPON',
      'VALBUENA (POB.)',
    ],
  },
  SARRAT: {
    province: 'ILOCOS NORTE',
    municipality: 'SARRAT',
    region: 'REGION I',
    barangay_list: [
      'SAN AGUSTIN (POB.)',
      'SAN ANDRES',
      'SAN ANTONIO',
      'SAN BERNABE',
      'SAN CRISTOBAL',
      'SAN FELIPE',
      'SAN FRANCISCO (POB.)',
      'SAN ISIDRO',
      'SAN JOAQUIN (POB.)',
      'SAN JOSE',
      'SAN JUAN',
      'SAN LEANDRO (POB.)',
      'SAN LORENZO',
      'SAN MANUEL',
      'SAN MARCOS',
      'SAN NICOLAS',
      'SAN PEDRO',
      'SAN ROQUE',
      'SAN VICENTE (POB.)',
      'SANTA BARBARA (POB.)',
      'SANTA MAGDALENA',
      'SANTA ROSA',
      'SANTO SANTIAGO',
      'SANTO TOMAS',
    ],
  },
  SOLSONA: {
    province: 'ILOCOS NORTE',
    municipality: 'SOLSONA',
    region: 'REGION I',
    barangay_list: [
      'AGUITAP',
      'BAGBAG',
      'BAGBAGO',
      'BARCELONA',
      'BUBUOS',
      'CAPURICTAN',
      'CATANGRARAN',
      'DARASDAS',
      'JUAN (POB.)',
      'LAURETA (POB.)',
      'LIPAY',
      'MAANANTENG',
      'MANALPAC',
      'MARIQUET',
      'NAGPATPATAN',
      'NALASIN',
      'PUTTAO',
      'SAN JUAN',
      'SAN JULIAN',
      'SANTA ANA',
      'SANTIAGO',
      'TALUGTOG',
    ],
  },
  VINTAR: {
    province: 'ILOCOS NORTE',
    municipality: 'VINTAR',
    region: 'REGION I',
    barangay_list: [
      'ABKIR',
      'ALEJO MALASIG',
      'ALSEM',
      'BAGO',
      'BULBULALA',
      'CABANGARAN',
      'CABAYO',
      'CABISOCOLAN',
      'CANAAM',
      'COLUMBIA',
      'DAGUPAN',
      'DIPILAT',
      'ESPERANZA',
      'ESTER',
      'ISIC ISIC',
      'LUBNAC',
      'MABANBANAG',
      'MALAMPA (PENINAAN-MALAMPA)',
      'MANARANG',
      'MARGAAY',
      'NAMOROC',
      'PARPAROROC',
      'PARUT',
      'PEDRO S. ALVIAR (DIATON)',
      'SALSALAMAGUI',
      'SAN JOSE (LIPAY)',
      'SAN NICOLAS (POB.)',
      'SAN PEDRO (POB.)',
      'SAN RAMON (POB.)',
      'SAN ROQUE (POB.)',
      'SANTA MARIA (POB.)',
      'TAMDAGAN',
      'VISAYA',
    ],
  },
  ALILEM: {
    province: 'ILOCOS SUR',
    municipality: 'ALILEM',
    region: 'REGION I',
    barangay_list: [
      'ALILEM DAYA (POB.)',
      'AMILONGAN',
      'ANAAO',
      'APANG',
      'APAYA',
      'BATBATO',
      'DADDAAY',
      'DALAWA',
      'KIAT',
    ],
  },
  BANAYOYO: {
    province: 'ILOCOS SUR',
    municipality: 'BANAYOYO',
    region: 'REGION I',
    barangay_list: [
      'BAGBAGOTOT',
      'BANBANAAL',
      'BISANGOL',
      'CADANGLAAN',
      'CASILAGAN NORTE',
      'CASILAGAN SUR',
      'ELEFANTE',
      'GUARDIA',
      'LINTIC',
      'LOPEZ',
      'MONTERO',
      'NAGUIMBA',
      'PILA',
      'POBLACION',
    ],
  },
  BANTAY: {
    province: 'ILOCOS SUR',
    municipality: 'BANTAY',
    region: 'REGION I',
    barangay_list: [
      'AGGAY',
      'AN-ANNAM',
      'BALALENG',
      'BANAOANG',
      'BARANGAY 1 (POB.)',
      'BARANGAY 2 (POB.)',
      'BARANGAY 3 (POB.)',
      'BARANGAY 4 (POB.)',
      'BARANGAY 5 (POB.)',
      'BARANGAY 6 (POB.)',
      'BULAG',
      'BUQUIG',
      'CABALANGGAN',
      'CABAROAN',
      'CABUSLIGAN',
      'CAPANGDANAN',
      'GUIMOD',
      'LINGSAT',
      'MALINGEB',
      'MIRA',
      'NAGUIDDAYAN',
      'ORA',
      'PAING',
      'PUSPUS',
      'QUIMMARAYAN',
      'SAGNEB',
      'SAGPAT',
      'SAN ISIDRO',
      'SAN JULIAN',
      'SAN MARIANO (SALLACONG)',
      'SINABAAN',
      'TAGUIPORO',
      'TALEB',
      'TAY-AC',
    ],
  },
  CABUGAO: {
    province: 'ILOCOS SUR',
    municipality: 'CABUGAO',
    region: 'REGION I',
    barangay_list: [
      'ALINAAY',
      'ARAGAN',
      'ARNAP',
      'BACLIG (POB.)',
      'BATO',
      'BONIFACIO (POB.)',
      'BUNGRO',
      'CACADIRAN',
      'CAELLAYAN',
      'CARUSIPAN',
      'CATUCDAAN',
      'CUANCABAL',
      'CUANTACLA',
      'DACLAPAN',
      'DARDARAT',
      'LIPIT',
      'MARADODON',
      'MARGAAY',
      'NAGSANTAAN',
      'NAGSINCAOAN',
      'NAMRUANGAN',
      'PILA',
      'PUG-OS',
      'QUEZON (POB.)',
      'REPPAAC',
      'RIZAL (POB.)',
      'SABANG',
      'SAGAYADEN',
      'SALAPASAP',
      'SALOMAGUE',
      'SISIM',
      'TUROD',
      'TUROD-PATAC',
    ],
  },
  'CANDON': {
    province: 'ILOCOS SUR',
    municipality: 'CANDON',
    region: 'REGION I',
    barangay_list: [
      'ALLANGIGAN PRIMERO',
      'ALLANGIGAN SEGUNDO',
      'AMGUID',
      'AYUDANTE',
      'BAGANI CAMPOSANTO',
      'BAGANI GABOR',
      'BAGANI TOCGO',
      'BAGANI UBBOG',
      'BAGAR',
      'BALINGAOAN',
      'BUGNAY',
      'CALAOAAN',
      'CALONGBUYAN',
      'CATERMAN',
      'CUBCUBBOOT',
      'DARAPIDAP',
      'LANGLANGCA PRIMERO',
      'LANGLANGCA SEGUNDO',
      'OAIG-DAYA',
      'PALACAPAC',
      'PARAS',
      'PARIOC PRIMERO',
      'PARIOC SEGUNDO',
      'PATPATA PRIMERO',
      'PATPATA SEGUNDO',
      'PAYPAYAD',
      'SALVADOR PRIMERO',
      'SALVADOR SEGUNDO',
      'SAN AGUSTIN',
      'SAN ANDRES',
      'SAN ANTONIO (POB.)',
      'SAN ISIDRO (POB.)',
      'SAN JOSE (POB.)',
      'SAN JUAN (POB.)',
      'SAN NICOLAS',
      'SAN PEDRO',
      'SANTO TOMAS',
      'TABLAC',
      'TALOGTOG',
      'TAMURONG PRIMERO',
      'TAMURONG SEGUNDO',
      'VILLARICA',
    ],
  },
  CAOAYAN: {
    province: 'ILOCOS SUR',
    municipality: 'CAOAYAN',
    region: 'REGION I',
    barangay_list: [
      'ANONANG MAYOR',
      'ANONANG MENOR',
      'BAGGOC',
      'CALLAGUIP',
      'CAPARACADAN',
      'DON ALEJANDRO QUIROLGICO (POB.)',
      'DON DIMAS QUERUBIN (POB.)',
      'DON LORENZO QUERUBIN (POB.)',
      'FUERTE',
      'MANANGAT',
      'NAGUILIAN',
      'NANSUAGAO',
      'PANDAN',
      'PANTAY TAMURONG',
      'PANTAY-QUITIQUIT',
      'PURO',
      'VILLAMAR',
    ],
  },
  CERVANTES: {
    province: 'ILOCOS SUR',
    municipality: 'CERVANTES',
    region: 'REGION I',
    barangay_list: [
      'ALULING',
      'COMILLAS NORTH',
      'COMILLAS SOUTH',
      'CONCEPCION (POB.)',
      'DINWEDE EAST',
      'DINWEDE WEST',
      'LIBANG',
      'MALAYA',
      'PILIPIL',
      'REMEDIOS',
      'ROSARIO (POB.)',
      'SAN JUAN',
      'SAN LUIS',
    ],
  },
  GALIMUYOD: {
    province: 'ILOCOS SUR',
    municipality: 'GALIMUYOD',
    region: 'REGION I',
    barangay_list: [
      'ABAYA',
      'BARACBAC',
      'BIDBIDAY',
      'BITONG',
      'BOROBOR',
      'CALIMUGTONG',
      'CALONGBUYAN',
      'CALUMBAYA',
      'DALDAGAN',
      'KILANG',
      'LEGASPI',
      'MABAYAG',
      'MATANUBONG',
      'MCKINLEY',
      'NAGSINGCAOAN',
      'OAIG-DAYA',
      'PAGANGPANG',
      'PATAC',
      'POBLACION',
      'RUBIO',
      'SABANGAN-BATO',
      'SACAANG',
      'SAN VICENTE',
      'SAPANG',
    ],
  },
  'GREGORIO DEL PILAR (CONCEPCION)': {
    province: 'ILOCOS SUR',
    municipality: 'GREGORIO DEL PILAR (CONCEPCION)',
    region: 'REGION I',
    barangay_list: [
      'ALFONSO (TANGAOAN)',
      'BUSSOT',
      'CONCEPCION',
      'DAPDAPPIG',
      'MATUE-BUTARAG',
      'POBLACION NORTE',
      'POBLACION SUR',
    ],
  },
  LIDLIDDA: {
    province: 'ILOCOS SUR',
    municipality: 'LIDLIDDA',
    region: 'REGION I',
    barangay_list: [
      'BANUCAL',
      'BEQUI-WALIN',
      'BUGUI',
      'CALUNGBUYAN',
      'CARCARABASA',
      'LABUT',
      'POBLACION NORTE',
      'POBLACION SUR',
      'SAN VICENTE',
      'SUYSUYAN',
      'TAY-AC',
    ],
  },
  MAGSINGAL: {
    province: 'ILOCOS SUR',
    municipality: 'MAGSINGAL',
    region: 'REGION I',
    barangay_list: [
      'ALANGAN',
      'BACAR',
      'BARBARIT',
      'BUNGRO',
      'CABAROAN',
      'CADANGLAAN',
      'CARAISAN',
      'DACUTAN',
      'LABUT',
      'MAAS-ASIN',
      'MACATCATUD',
      'MANZANTE',
      'MARATUDO',
      'MIRAMAR',
      'NAMALPALAN',
      'NAPO',
      'PAGSANAAN NORTE',
      'PAGSANAAN SUR',
      'PANAY NORTE',
      'PANAY SUR',
      'PATONG',
      'PURO',
      'SAN BASILIO (POB.)',
      'SAN CLEMENTE (POB.)',
      'SAN JULIAN (POB.)',
      'SAN LUCAS (POB.)',
      'SAN RAMON (POB.)',
      'SAN VICENTE (POB.)',
      'SANTA MONICA',
      'SARSARACAT',
    ],
  },
  NAGBUKEL: {
    province: 'ILOCOS SUR',
    municipality: 'NAGBUKEL',
    region: 'REGION I',
    barangay_list: [
      'BALAWEG',
      'BANDRIL',
      'BANTUGO',
      'CADACAD',
      'CASILAGAN',
      'CASOCOS',
      'LAPTING',
      'MAPISI',
      'MISSION',
      'POBLACION EAST',
      'POBLACION WEST',
      'TALEB',
    ],
  },
  NARVACAN: {
    province: 'ILOCOS SUR',
    municipality: 'NARVACAN',
    region: 'REGION I',
    barangay_list: [
      'ABUOR',
      'AMBULOGAN',
      'AQUIB',
      'BANGLAYAN',
      'BANTAY ABOT',
      'BULANOS',
      'CADACAD',
      'CAGAYUNGAN',
      'CAMARAO',
      'CASILAGAN',
      'CODOOG',
      'DASAY',
      'DINALAOAN',
      'ESTANCIA',
      'LANIPAO',
      'LUNGOG',
      'MARGAAY',
      'MAROZO',
      'NAGUNEG',
      'ORENCE',
      'PANTOC',
      'PARATONG',
      'PARPARIA',
      'QUINARAYAN',
      'RIVADAVIA',
      'SAN ANTONIO',
      'SAN JOSE (POB.)',
      'SAN PABLO',
      'SAN PEDRO',
      'SANTA LUCIA (POB.)',
      'SARMINGAN',
      'SUCOC',
      'SULVEC',
      'TUROD',
    ],
  },
  'QUIRINO (ANGKAKI)': {
    province: 'ILOCOS SUR',
    municipality: 'QUIRINO (ANGKAKI)',
    region: 'REGION I',
    barangay_list: [
      'BANOEN',
      'CAYUS',
      'LAMAG (TUBTUBA)',
      'LEGLEG (POB.)',
      'MALIDEG',
      'NAMITPIT',
      'PATIACAN',
      'PATUNGCALEO (LAMAG)',
      'SUAGAYAN',
    ],
  },
  'SALCEDO (BAUGEN)': {
    province: 'ILOCOS SUR',
    municipality: 'SALCEDO (BAUGEN)',
    region: 'REGION I',
    barangay_list: [
      'ATABAY',
      'BALIDBID',
      'BALUARTE',
      'BAYBAYADING',
      'BOGUIBOG',
      'BULALA-LEGUEY',
      'CALANGCUASAN',
      'CULIONG',
      'DINARATAN',
      'KALIWAKIW',
      'KINMARIN',
      'LUCBUBAN',
      'MADARANG',
      'MALIGCONG',
      'PIAS',
      'POBLACION NORTE',
      'POBLACION SUR',
      'SAN GASPAR',
      'SAN TIBURCIO',
      'SORIOAN',
      'UBBOG',
    ],
  },
  'SAN EMILIO': {
    province: 'ILOCOS SUR',
    municipality: 'SAN EMILIO',
    region: 'REGION I',
    barangay_list: [
      'CABAROAN (POB.)',
      'KALUMSING',
      'LANCUAS',
      'MATIBUEY',
      'PALTOC',
      'SAN MILIANO',
      'SIBSIBBU',
      'TIAGAN',
    ],
  },
  'SAN ESTEBAN': {
    province: 'ILOCOS SUR',
    municipality: 'SAN ESTEBAN',
    region: 'REGION I',
    barangay_list: [
      'ANSAD',
      'APATOT',
      'BATERIA',
      'CABAROAN',
      'CAPPA-CAPPA',
      'POBLACION',
      'SAN NICOLAS',
      'SAN PABLO',
      'SAN RAFAEL',
      'VILLA QUIRINO',
    ],
  },
  'SAN ILDEFONSO': {
    province: 'BULACAN',
    municipality: 'SAN ILDEFONSO',
    region: 'REGION III',
    barangay_list: [
      'AKLE',
      'ALAGAO',
      'ANYATAM',
      'BAGONG BARRIO',
      'BASUIT',
      'BUBULONG MALAKI',
      'BUBULONG MUNTI',
      'BUHOL NA MANGGA',
      'BULUSUKAN',
      'CALASAG',
      'CALAWITAN',
      'CASALAT',
      'GABIHAN',
      'GARLANG',
      'LAPNIT',
      'MAASIM',
      'MAKAPILAPIL',
      'MALIPAMPANG',
      'MATAAS NA PARANG',
      'MATIMBUBONG',
      'NABAONG GARLANG',
      'PALAPALA',
      'PASONG BANGKAL',
      'PINAOD',
      'POBLACION',
      'PULONG TAMO',
      'SAN JUAN',
      'SANTA CATALINA BATA',
      'SANTA CATALINA MATANDA',
      'SAPANG DAYAP',
      'SAPANG PUTIK',
      'SAPANG PUTOL',
      'SUMANDIG',
      'TELEPATIO',
      'UMPUCAN',
      'UPIG',
    ],
  },
  'SAN JUAN (LAPOG)': {
    province: 'ILOCOS SUR',
    municipality: 'SAN JUAN (LAPOG)',
    region: 'REGION I',
    barangay_list: [
      'ASILANG',
      'BACSIL',
      'BALIW',
      'BANNUAR (POB.)',
      'BARBAR',
      'CABANGLOTAN',
      'CACANDONGAN',
      'CAMANGGAAN',
      'CAMINDOROAN',
      'CARONOAN',
      'DARAO',
      'DARDARAT',
      'GUIMOD NORTE',
      'GUIMOD SUR',
      'IMMAYOS NORTE',
      'IMMAYOS SUR',
      'LABNIG',
      'LAPTING',
      'LIRA (POB.)',
      'MALAMIN',
      'MURAYA',
      'NAGSABARAN',
      'NAGSUPOTAN',
      'PANDAYAN (POB.)',
      'REFARO',
      'RESURRECCION (POB.)',
      'SABANGAN',
      'SAN ISIDRO',
      'SAOANG',
      'SOLOTSOLOT',
      'SUNGGIAM',
      'SURNGIT',
    ],
  },
  'SAN VICENTE': {
    province: 'NORTHERN SAMAR',
    municipality: 'SAN VICENTE',
    region: 'REGION VIII',
    barangay_list: [
      'DESTACADO POB. (BGY.2)',
      'MARAGAT',
      'MONGOL BONGOL POB. (BGY.1)',
      'PUNTA POB. (BGY.3)',
      'SANGPUTAN',
      'SILA',
      'TARNATE',
    ],
  },
  SANTA: {
    province: 'ILOCOS SUR',
    municipality: 'SANTA',
    region: 'REGION I',
    barangay_list: [
      'AMPANDULA',
      'BANAOANG',
      'BASUG',
      'BUCALAG',
      'CABANGARAN',
      'CALUNGBOYAN',
      'CASIBER',
      'DAMMAY',
      'LABUT NORTE',
      'LABUT SUR',
      'MABILBILA NORTE',
      'MABILBILA SUR',
      'MAGSAYSAY DISTRICT (POB.)',
      'MANUEVA',
      'MARCOS (POB.)',
      'NAGPANAOAN',
      'NAMALANGAN',
      'ORIBI',
      'PASUNGOL',
      'QUEZON (POB.)',
      'QUIRINO (POB.)',
      'RANCHO',
      'RIZAL',
      'SACUYYA NORTE',
      'SACUYYA SUR',
      'TABUCOLAN',
    ],
  },
  'SANTA CATALINA': {
    province: 'NEGROS ORIENTAL',
    municipality: 'SANTA CATALINA',
    region: 'REGION VII',
    barangay_list: [
      'ALANGILAN',
      'AMIO',
      'BUENAVISTA',
      'CAIGANGAN',
      'CARANOCHE',
      'CAWITAN',
      'FATIMA',
      'KABULACAN',
      'MABUHAY',
      'MANALONGON',
      'MANSAGOMAYON',
      'MILAGROSA',
      'NAGBALAYE',
      'NAGBINLOD',
      'OBAT',
      'POBLACION',
      'SAN FRANCISCO',
      'SAN JOSE',
      'SAN MIGUEL',
      'SAN PEDRO',
      'SANTO ROSARIO',
      'TALALAK',
    ],
  },
  'SANTA LUCIA': {
    province: 'ILOCOS SUR',
    municipality: 'SANTA LUCIA',
    region: 'REGION I',
    barangay_list: [
      'ALINCAOEG',
      'ANGKILENG',
      'ARANGIN',
      'AYUSAN (POB.)',
      'BANBANABA',
      'BANI',
      'BAO-AS',
      'BARANGOBONG (POB.)',
      'BULICLIC',
      'BURGOS (POB.)',
      'CABARITAN',
      'CATAYAGAN',
      'CONCONIG EAST',
      'CONCONIG WEST',
      'DAMACUAG',
      'LUBA',
      'LUBONG',
      'NAGREBCAN',
      'NAGTABLAAN',
      'NAMATICAN',
      'NANGALISAN',
      'PALALI NORTE',
      'PALALI SUR',
      'PAOC NORTE',
      'PAOC SUR',
      'PARATONG',
      'PILA EAST',
      'PILA WEST',
      'QUINABALAYANGAN',
      'RONDA',
      'SABUANAN',
      'SAN JUAN',
      'SAN PEDRO',
      'SAPANG',
      'SUAGAYAN',
      'VICAL',
    ],
  },
  'SANTO DOMINGO': {
    province: 'NUEVA ECIJA',
    municipality: 'SANTO DOMINGO',
    region: 'REGION III',
    barangay_list: [
      'BALOC',
      'BUASAO',
      'BURGOS',
      'CABUGAO',
      'CASULUCAN',
      'COMITANG',
      'CONCEPCION',
      'DOLORES',
      'GENERAL LUNA',
      'HULO',
      'MABINI',
      'MALASIN',
      'MALAYA (POOK MALAYA)',
      'MALAYANTOC',
      'MAMBARAO',
      'POBLACION',
      'PULONG BULI',
      'SAGABA',
      'SAN AGUSTIN',
      'SAN FABIAN',
      'SAN FRANCISCO',
      'SAN PASCUAL',
      'SANTA RITA',
      'SANTO ROSARIO',
    ],
  },
  SIGAY: {
    province: 'ILOCOS SUR',
    municipality: 'SIGAY',
    region: 'REGION I',
    barangay_list: [
      'ABACCAN',
      'MABILEG',
      'MATALLUCOD',
      'POBLACION (MADAYAW)',
      'SAN ELIAS',
      'SAN RAMON',
      'SANTO ROSARIO',
    ],
  },
  SINAIT: {
    province: 'ILOCOS SUR',
    municipality: 'SINAIT',
    region: 'REGION I',
    barangay_list: [
      'AGUING',
      'BALIW',
      'BALLAIGUI (POB.)',
      'BARACBAC',
      'BARIKIR',
      'BATTOG',
      'BINACUD',
      'CABANGTALAN',
      'CABARAMBANAN',
      'CABULALAAN',
      'CADANGLAAN',
      'CALANUTIAN',
      'CALINGAYAN',
      'CURTIN',
      'DADALAQUITEN NORTE',
      'DADALAQUITEN SUR',
      'DEAN LEOPOLDO YABES (PUG-OS)',
      'DUYAYYAT',
      'JORDAN',
      'KATIPUNAN',
      'MACABIAG (POB.)',
      'MAGSAYSAY',
      'MARNAY',
      'MASADAG',
      'NAGBALIOARTIAN',
      'NAGCULLOOBAN',
      'NAGONGBURAN',
      'NAMNAMA (POB.)',
      'PACIS',
      'PARATONG',
      'PURAG',
      'QUIBIT-QUIBIT',
      'QUIMMALLOGONG',
      'RANG-AY (POB.)',
      'RICUDO',
      'SABAÑGAN (MARCOS)',
      'SALLACAPO',
      'SANTA CRUZ',
      'SAPRIANA',
      'TAPAO',
      'TEPPENG',
      'TUBIGAY',
      'UBBOG',
      'ZAPAT',
    ],
  },
  SUGPON: {
    province: 'ILOCOS SUR',
    municipality: 'SUGPON',
    region: 'REGION I',
    barangay_list: [
      'BALBALAYANG (POB.)',
      'BANGA',
      'CAOAYAN',
      'DANAC',
      'LICUNGAN (CULLANG)',
      'PANGOTAN',
    ],
  },
  SUYO: {
    province: 'ILOCOS SUR',
    municipality: 'SUYO',
    region: 'REGION I',
    barangay_list: [
      'BARINGCUCURONG',
      'CABUGAO',
      'MAN-ATONG',
      'PATOC-AO',
      'POBLACION (KIMPUSA)',
      'SUYO PROPER',
      'URZADAN',
      'USO',
    ],
  },
  TAGUDIN: {
    province: 'ILOCOS SUR',
    municipality: 'TAGUDIN',
    region: 'REGION I',
    barangay_list: [
      'AG-AGUMAN',
      'AMBALAYAT',
      'BARACBAC',
      'BARIO-AN',
      'BARITAO',
      'BECQUES',
      'BIMMANGA',
      'BIO',
      'BITALAG',
      'BORONO',
      'BUCAO EAST',
      'BUCAO WEST',
      'CABAROAN',
      'CABUGBUGAN',
      'CABULANGLANGAN',
      'DACUTAN',
      'DARDARAT',
      'DEL PILAR (POB.)',
      'FAROLA',
      'GABUR',
      'GARITAN',
      'JARDIN',
      'LACONG',
      'LANTAG',
      'LAS-UD',
      'LIBTONG',
      'LUBNAC',
      'MAGSAYSAY (POB.)',
      'MALACAÑANG',
      'PACAC',
      'PALLOGAN',
      'PUDOC EAST',
      'PUDOC WEST',
      'PULA',
      'QUIRINO (POB.)',
      'RANGET',
      'RIZAL (POB.)',
      'SALVACION',
      'SAN MIGUEL',
      'SAWAT',
      'TALLAOEN',
      'TAMPUGO',
      'TARANGOTONG',
    ],
  },
  'VIGAN': {
    province: 'ILOCOS SUR',
    municipality: 'VIGAN',
    region: 'REGION I',
    barangay_list: [
      'AYUSAN NORTE',
      'AYUSAN SUR',
      'BARANGAY I (POB.)',
      'BARANGAY II (POB.)',
      'BARANGAY III (POB.)',
      'BARANGAY IV (POB.)',
      'BARANGAY IX',
      'BARANGAY V (POB.)',
      'BARANGAY VI (POB.)',
      'BARANGAY VII',
      'BARANGAY VIII',
      'BARRACA',
      'BEDDENG DAYA',
      'BEDDENG LAUD',
      'BONGTOLAN',
      'BULALA',
      'CABALANGEGAN',
      'CABAROAN DAYA',
      'CABAROAN LAUD',
      'CAMANGAAN',
      'CAPANGPANGAN',
      'MINDORO',
      'NAGSANGALAN',
      'PANTAY DAYA',
      'PANTAY FATIMA',
      'PANTAY LAUD',
      'PAOA',
      'PARATONG',
      'PONG-OL',
      'PUROK-A-BASSIT',
      'PUROK-A-DACKEL',
      'RAOIS',
      'RUGSUANAN',
      'SALINDEG',
      'SAN JOSE',
      'SAN JULIAN NORTE',
      'SAN JULIAN SUR',
      'SAN PEDRO',
      'TAMAG',
    ],
  },
  AGOO: {
    province: 'LA UNION',
    municipality: 'AGOO',
    region: 'REGION I',
    barangay_list: [
      'AMBITACAY',
      'BALAWARTE',
      'CAPAS',
      'CONSOLACION (POB.)',
      'MACALVA CENTRAL',
      'MACALVA NORTE',
      'MACALVA SUR',
      'NAZARENO',
      'PUROK',
      'SAN AGUSTIN EAST',
      'SAN AGUSTIN NORTE',
      'SAN AGUSTIN SUR',
      'SAN ANTONINO',
      'SAN ANTONIO',
      'SAN FRANCISCO',
      'SAN ISIDRO',
      'SAN JOAQUIN NORTE',
      'SAN JOAQUIN SUR',
      'SAN JOSE NORTE',
      'SAN JOSE SUR',
      'SAN JUAN',
      'SAN JULIAN CENTRAL',
      'SAN JULIAN EAST',
      'SAN JULIAN NORTE',
      'SAN JULIAN WEST',
      'SAN MANUEL NORTE',
      'SAN MANUEL SUR',
      'SAN MARCOS',
      'SAN MIGUEL',
      'SAN NICOLAS CENTRAL (POB.)',
      'SAN NICOLAS EAST',
      'SAN NICOLAS NORTE (POB.)',
      'SAN NICOLAS SUR (POB.)',
      'SAN NICOLAS WEST',
      'SAN PEDRO',
      'SAN ROQUE EAST',
      'SAN ROQUE WEST',
      'SAN VICENTE NORTE',
      'SAN VICENTE SUR',
      'SANTA ANA',
      'SANTA BARBARA (POB.)',
      'SANTA FE',
      'SANTA MARIA',
      'SANTA MONICA',
      'SANTA RITA (NALINAC)',
      'SANTA RITA EAST',
      'SANTA RITA NORTE',
      'SANTA RITA SUR',
      'SANTA RITA WEST',
    ],
  },
  ARINGAY: {
    province: 'LA UNION',
    municipality: 'ARINGAY',
    region: 'REGION I',
    barangay_list: [
      'ALASKA',
      'BASCA',
      'DULAO',
      'GALLANO',
      'MACABATO',
      'MANGA',
      'PANGAO-AOAN EAST',
      'PANGAO-AOAN WEST',
      'POBLACION',
      'SAMARA',
      'SAN ANTONIO',
      'SAN BENITO NORTE',
      'SAN BENITO SUR',
      'SAN EUGENIO',
      'SAN JUAN EAST',
      'SAN JUAN WEST',
      'SAN SIMON EAST',
      'SAN SIMON WEST',
      'SANTA CECILIA',
      'SANTA LUCIA',
      'SANTA RITA EAST',
      'SANTA RITA WEST',
      'SANTO ROSARIO EAST',
      'SANTO ROSARIO WEST',
    ],
  },
  BACNOTAN: {
    province: 'LA UNION',
    municipality: 'BACNOTAN',
    region: 'REGION I',
    barangay_list: [
      'AGTIPAL',
      'AROSIP',
      'BACQUI',
      'BACSIL',
      'BAGUTOT',
      'BALLOGO',
      'BARORO',
      'BITALAG',
      'BULALA',
      'BURAYOC',
      'BUSSAOIT',
      'CABAROAN',
      'CABARSICAN',
      'CABUGAO',
      'CALAUTIT',
      'CARCARMAY',
      'CASIAMAN',
      'GALONGEN',
      'GUINABANG',
      'LEGLEG',
      'LISQUEB',
      'MABANENGBENG 1ST',
      'MABANENGBENG 2ND',
      'MARAGAYAP',
      'NAGATIRAN',
      'NAGSARABOAN',
      'NAGSIMBAANAN',
      'NANGALISAN',
      'NARRA',
      'ORTEGA',
      'OYA-OY',
      'PAAGAN',
      'PANDAN',
      'PANG-PANG',
      'POBLACION',
      'QUIRINO',
      'RAOIS',
      'SALINCOB',
      'SAN MARTIN',
      'SANTA CRUZ',
      'SANTA RITA',
      'SAPILANG',
      'SAYOAN',
      'SIPULO',
      'TAMMOCALAO',
      'UBBOG',
      'ZARAGOSA',
    ],
  },
  BAGULIN: {
    province: 'LA UNION',
    municipality: 'BAGULIN',
    region: 'REGION I',
    barangay_list: [
      'ALIBANGSAY',
      'BAAY',
      'CAMBALY',
      'CARDIZ',
      'DAGUP',
      'LIBBO',
      'SUYO (POB.)',
      'TAGUDTUD',
      'TIO-ANGAN',
      'WALLAYAN',
    ],
  },
  BALAOAN: {
    province: 'LA UNION',
    municipality: 'BALAOAN',
    region: 'REGION I',
    barangay_list: [
      'ALMIEDA',
      'ANTONINO',
      'APATUT',
      'AR-ARAMPANG',
      'BARACBAC ESTE',
      'BARACBAC OESTE',
      'BET-ANG',
      'BULBULALA',
      'BUNGOL',
      'BUTUBUT ESTE',
      'BUTUBUT NORTE',
      'BUTUBUT OESTE',
      'BUTUBUT SUR',
      'CABUAAN OESTE (POB.)',
      'CALLIAT',
      'CALUNGBUYAN',
      'CAMILING',
      'DR. CAMILO OSIAS POB. (CABUAAN ESTE)',
      'GUINABURAN',
      'MASUPE',
      'NAGSABARAN NORTE',
      'NAGSABARAN SUR',
      'NALASIN',
      'NAPASET',
      'PA-O',
      'PAGBENNECAN',
      'PAGLEDDEGAN',
      'PANTAR NORTE',
      'PANTAR SUR',
      'PARAOIR',
      'PATPATA',
      'SABLUT',
      'SAN PABLO',
      'SINAPANGAN NORTE',
      'SINAPANGAN SUR',
      'TALLIPUGO',
    ],
  },
  BANGAR: {
    province: 'LA UNION',
    municipality: 'BANGAR',
    region: 'REGION I',
    barangay_list: [
      'AGDEPPA',
      'ALZATE',
      'BANGAOILAN EAST',
      'BANGAOILAN WEST',
      'BARRACA',
      'CADAPLI',
      'CAGGAO',
      'CENTRAL EAST NO. 1 (POB.)',
      'CENTRAL EAST NO. 2 (POB.)',
      'CENTRAL WEST NO. 1 (POB.)',
      'CENTRAL WEST NO. 2 (POB.)',
      'CENTRAL WEST NO. 3 (POB.)',
      'CONSUEGRA',
      'GENERAL PRIM EAST',
      'GENERAL PRIM WEST',
      'GENERAL TERRERO',
      'LUZONG NORTE',
      'LUZONG SUR',
      'MARIA CRISTINA EAST',
      'MARIA CRISTINA WEST',
      'MINDORO',
      'NAGSABARAN',
      'PARATONG NO. 3',
      'PARATONG NO. 4',
      'PARATONG NORTE',
      'QUINTARONG',
      'REYNA REGENTE',
      'RISSING',
      'SAN BLAS',
      'SAN CRISTOBAL',
      'SINAPANGAN NORTE',
      'SINAPANGAN SUR',
      'UBBOG',
    ],
  },
  BAUANG: {
    province: 'LA UNION',
    municipality: 'BAUANG',
    region: 'REGION I',
    barangay_list: [
      'ACAO',
      'BACCUIT NORTE',
      'BACCUIT SUR',
      'BAGBAG',
      'BALLAY',
      'BAWANTA',
      'BOY-UTAN',
      'BUCAYAB',
      'CABALAYANGAN',
      'CABISILAN',
      'CALUMBAYA',
      'CARMAY',
      'CASILAGAN',
      'CENTRAL EAST (POB.)',
      'CENTRAL WEST (POB.)',
      'DILI',
      'DISSO-OR',
      'GUERRERO',
      'LOWER SAN AGUSTIN',
      'NAGREBCAN',
      'PAGDALAGAN SUR',
      'PALINTUCANG',
      'PALUGSI-LIMMANSANGAN',
      'PARIAN ESTE',
      'PARIAN OESTE',
      'PARINGAO',
      'PAYOCPOC NORTE ESTE',
      'PAYOCPOC NORTE OESTE',
      'PAYOCPOC SUR',
      'PILAR',
      'POTTOT',
      'PUDOC',
      'PUGO',
      'QUINAVITE',
      'SANTA MONICA',
      'SANTIAGO',
      'TABERNA',
      'UPPER SAN AGUSTIN',
      'URAYONG',
    ],
  },
  CABA: {
    province: 'LA UNION',
    municipality: 'CABA',
    region: 'REGION I',
    barangay_list: [
      'BAUTISTA',
      'GANA',
      'JUAN CARTAS',
      'LAS-UD',
      'LIQUICIA',
      'POBLACION NORTE',
      'POBLACION SUR',
      'SAN CARLOS',
      'SAN CORNELIO',
      'SAN FERMIN',
      'SAN GREGORIO',
      'SAN JOSE',
      'SANTIAGO NORTE',
      'SANTIAGO SUR',
      'SOBREDILLO',
      'URAYONG',
      'WENCESLAO',
    ],
  },
  'SAN FERNANDO': {
    province: 'LA UNION',
    municipality: 'SAN FERNANDO',
    region: 'REGION I',
    barangay_list: [
      'ABUT',
      'APALENG',
      'BACSIL',
      'BANGBANGOLAN',
      'BANGCUSAY',
      'BARANGAY I (POB.)',
      'BARANGAY II (POB.)',
      'BARANGAY III (POB.)',
      'BARANGAY IV (POB.)',
      'BARAOAS',
      'BATO',
      'BIDAY',
      'BIRUNGET',
      'BUNGRO',
      'CABAROAN (NEGRO)',
      'CABARSICAN',
      'CADACLAN',
      'CALABUGAO',
      'CAMANSI',
      'CANAOAY',
      'CARLATAN',
      'CATBANGEN',
      'DALLANGAYAN ESTE',
      'DALLANGAYAN OESTE',
      'DALUMPINAS ESTE',
      'DALUMPINAS OESTE',
      'ILOCANOS NORTE',
      'ILOCANOS SUR',
      'LANGCUAS',
      'LINGSAT',
      'MADAYEGDEG',
      'MAMELTAC',
      'MASICONG',
      'NAGYUBUYUBAN',
      'NAMTUTAN',
      'NARRA ESTE',
      'NARRA OESTE',
      'PACPACO',
      'PAGDALAGAN',
      'PAGDARAOAN',
      'PAGUDPUD',
      'PAO NORTE',
      'PAO SUR',
      'PARIAN',
      'PIAS',
      'PORO',
      'PUSPUS',
      'SACYUD',
      'SAGAYAD',
      'SAN AGUSTIN',
      'SAN FRANCISCO',
      'SAN VICENTE',
      'SANTIAGO NORTE',
      'SANTIAGO SUR',
      'SAOAY',
      'SEVILLA',
      'SIBOAN-OTONG',
      'TANQUI',
      'TANQUIGAN',
    ],
  },
  LUNA: {
    province: 'APAYAO',
    municipality: 'LUNA',
    region: 'CAR',
    barangay_list: [
      'BACSAY',
      'CAGANDUNGAN',
      'CALABIGAN',
      'CANGISITAN',
      'CAPAGAYPAYAN',
      'DAGUPAN',
      'LAPPA',
      'LUYON',
      'MARAG',
      'POBLACION',
      'QUIRINO',
      'SALVACION',
      'SAN FRANCISCO',
      'SAN GREGORIO',
      'SAN ISIDRO NORTE',
      'SAN ISIDRO SUR',
      'SAN SEBASTIAN',
      'SANTA LINA',
      'SHALOM',
      'TUMOG',
      'TUROD',
      'ZUMIGUI',
    ],
  },
  NAGUILIAN: {
    province: 'ISABELA',
    municipality: 'NAGUILIAN',
    region: 'REGION II',
    barangay_list: [
      'AGUINALDO',
      'BAGONG SIKAT',
      'BURGOS',
      'CABARUAN',
      'FLORES',
      'LA UNION',
      'MAGSAYSAY (POB.)',
      'MANARING',
      'MANSIBANG',
      'MINALLO',
      'MINANGA',
      'PALATTAO',
      'QUEZON (POB.)',
      'QUINALABASA',
      'QUIRINO (POB.)',
      'RANGAYAN',
      'RIZAL',
      'ROXAS (POB.)',
      'SAN MANUEL',
      'SANTA VICTORIA (VILLA CAPUCHINO)',
      'SANTO TOMAS',
      'SUNLIFE',
      'SURCOC',
      'TOMINES',
      'VILLA PAZ',
    ],
  },
  PUGO: {
    province: 'LA UNION',
    municipality: 'PUGO',
    region: 'REGION I',
    barangay_list: [
      'AMBALITE',
      'AMBANGONAN',
      'CARES',
      'CUENCA',
      'DUPLAS',
      'MAOASOAS NORTE',
      'MAOASOAS SUR',
      'PALINA',
      'POBLACION EAST',
      'POBLACION WEST',
      'SAN LUIS',
      'SAYTAN',
      'TAVORA EAST',
      'TAVORA PROPER',
    ],
  },
  'SAN GABRIEL': {
    province: 'LA UNION',
    municipality: 'SAN GABRIEL',
    region: 'REGION I',
    barangay_list: [
      'AMONTOC',
      'APAYAO',
      'BALBALAYANG',
      'BAYABAS',
      'BUCAO',
      'BUMBUNEG',
      'DAKING',
      'LACONG',
      'LIPAY ESTE',
      'LIPAY NORTE',
      'LIPAY PROPER',
      'LIPAY SUR',
      'LON-OY',
      'POBLACION',
      'POLIPOL',
    ],
  },
  'SAN JUAN': {
    province: 'ABRA',
    municipality: 'SAN JUAN',
    region: 'CAR',
    barangay_list: [
      'ABUALAN',
      'BA-UG',
      'BADAS',
      'CABCABORAO',
      'COLABAOAN',
      'CULIONG',
      'DAOIDAO',
      'GUIMBA',
      'LAM-AG',
      'LUMOBANG',
      'NANGOBONGAN',
      'PATTAOIG',
      'POBLACION NORTH',
      'POBLACION SOUTH',
      'QUIDAOEN',
      'SABANGAN',
      'SILET',
      'SUPI-IL',
      'TAGAYTAY',
    ],
  },
  SANTOL: {
    province: 'LA UNION',
    municipality: 'SANTOL',
    region: 'REGION I',
    barangay_list: [
      'CORROOY',
      'LETTAC NORTE',
      'LETTAC SUR',
      'MANGAAN',
      'PAAGAN',
      'POBLACION',
      'PUGUIL',
      'RAMOT',
      'SAPDAAN',
      'SASABA',
      'TUBADAY',
    ],
  },
  SUDIPEN: {
    province: 'LA UNION',
    municipality: 'SUDIPEN',
    region: 'REGION I',
    barangay_list: [
      'BIGBIGA',
      'BULALAAN',
      'CASTRO',
      'DUPLAS',
      'ILOCANO',
      'IPET',
      'MALICLICO',
      'NAMALTUGAN',
      'OLD CENTRAL',
      'POBLACION',
      'PORPORIKET',
      'SAN FRANCISCO NORTE',
      'SAN FRANCISCO SUR',
      'SAN JOSE',
      'SENGNGAT',
      'TUROD',
      'UP-UPLAS',
    ],
  },
  TUBAO: {
    province: 'LA UNION',
    municipality: 'TUBAO',
    region: 'REGION I',
    barangay_list: [
      'AMALLAPAY',
      'ANDUYAN',
      'CAOIGUE',
      'FRANCIA SUR',
      'FRANCIA WEST',
      'GARCIA',
      'GONZALES',
      'HALOG EAST',
      'HALOG WEST',
      'LEONES EAST',
      'LEONES WEST',
      'LINAPEW',
      'LLOREN',
      'MAGSAYSAY',
      'PIDEG',
      'POBLACION',
      'RIZAL',
      'SANTA TERESA',
    ],
  },
  AGNO: {
    province: 'PANGASINAN',
    municipality: 'AGNO',
    region: 'REGION I',
    barangay_list: [
      'ALLABON',
      'ALOLENG',
      'BANGAN-ODA',
      'BARUAN',
      'BOBOY',
      'CAYUNGNAN',
      'DANGLEY',
      'GAYUSAN',
      'MACABOBONI',
      'MAGSAYSAY',
      'NAMATUCAN',
      'PATAR',
      'POBLACION EAST',
      'POBLACION WEST',
      'SAN JUAN',
      'TUPA',
      'VIGA',
    ],
  },
  AGUILAR: {
    province: 'PANGASINAN',
    municipality: 'AGUILAR',
    region: 'REGION I',
    barangay_list: [
      'BAYAOAS',
      'BAYBAY',
      'BOCACLIW',
      'BOCBOC EAST',
      'BOCBOC WEST',
      'BUER',
      'CALSIB',
      'LAOAG',
      'MANLOCBOC',
      'NINOY',
      'PANACOL',
      'POBLACION',
      'POGOMBOA',
      'POGONSILI',
      'SAN JOSE',
      'TAMPAC',
    ],
  },
  'ALAMINOS': {
    province: 'PANGASINAN',
    municipality: 'ALAMINOS',
    region: 'REGION I',
    barangay_list: [
      'ALOS',
      'AMANDIEGO',
      'AMANGBANGAN',
      'BALANGOBONG',
      'BALAYANG',
      'BALEYADAAN',
      'BISOCOL',
      'BOLANEY',
      'BUED',
      'CABATUAN',
      'CAYUCAY',
      'DULACAC',
      'INERANGAN',
      'LANDOC',
      'LINMANSANGAN',
      'LUCAP',
      'MAAWI',
      'MACATIW',
      'MAGSAYSAY',
      'MONA',
      'PALAMIS',
      'PANDAN',
      'PANGAPISAN',
      'POBLACION',
      'POCALPOCAL',
      'POGO',
      'POLO',
      'QUIBUAR',
      'SABANGAN',
      'SAN ANTONIO (R. MAGSAYSAY)',
      'SAN JOSE',
      'SAN ROQUE',
      'SAN VICENTE',
      'SANTA MARIA',
      'TANAYTAY',
      'TANGCARANG',
      'TAWINTAWIN',
      'TELBANG',
      'VICTORIA',
    ],
  },
  ALCALA: {
    province: 'CAGAYAN',
    municipality: 'ALCALA',
    region: 'REGION II',
    barangay_list: [
      'ABBEG',
      'AFUSING BATO',
      'AFUSING DAGA',
      'AGANI',
      'BACULOD',
      'BAYBAYOG',
      'CABULUAN',
      'CALANTAC',
      'CARALLANGAN',
      'CENTRO NORTE (POB.)',
      'CENTRO SUR (POB.)',
      'DALAOIG',
      'DAMUROG',
      'JURISDICTION',
      'MALALATAN',
      'MARABURAB',
      'MASIN',
      'PAGBANGKERUAN',
      'PARED',
      'PIGGATAN',
      'PINOPOC',
      'PUSSIAN',
      'SAN ESTEBAN',
      'TAMBAN',
      'TUPANG',
    ],
  },
  ANDA: {
    province: 'BOHOL',
    municipality: 'ANDA',
    region: 'REGION VII',
    barangay_list: [
      'ALMARIA',
      'BACONG',
      'BADIANG',
      'BUENASUERTE',
      'CANDABONG',
      'CASICA',
      'KATIPUNAN',
      'LINAWAN',
      'LUNDAG',
      'POBLACION',
      'SANTA CRUZ',
      'SUBA',
      'TALISAY',
      'TANOD',
      'TAWID',
      'VIRGEN',
    ],
  },
  ASINGAN: {
    province: 'PANGASINAN',
    municipality: 'ASINGAN',
    region: 'REGION I',
    barangay_list: [
      'ARISTON ESTE',
      'ARISTON WESTE',
      'BANTOG',
      'BARO',
      'BOBONAN',
      'CABALITIAN',
      'CALEPAAN',
      'CAROSUCAN NORTE',
      'CAROSUCAN SUR',
      'COLDIT',
      'DOMANPOT',
      'DUPAC',
      'MACALONG',
      'PALARIS',
      'POBLACION EAST',
      'POBLACION WEST',
      'SAN VICENTE ESTE',
      'SAN VICENTE WESTE',
      'SANCHEZ',
      'SOBOL',
      'TOBOY',
    ],
  },
  BALUNGAO: {
    province: 'PANGASINAN',
    municipality: 'BALUNGAO',
    region: 'REGION I',
    barangay_list: [
      'ANGAYAN NORTE',
      'ANGAYAN SUR',
      'CAPULAAN',
      'ESMERALDA',
      'KITA-KITA',
      'MABINI',
      'MAUBAN',
      'POBLACION',
      'PUGARO',
      'RAJAL',
      'SAN ANDRES',
      'SAN AURELIO 1ST',
      'SAN AURELIO 2ND',
      'SAN AURELIO 3RD',
      'SAN JOAQUIN',
      'SAN JULIAN',
      'SAN LEON',
      'SAN MARCELINO',
      'SAN MIGUEL',
      'SAN RAYMUNDO',
    ],
  },
  BANI: {
    province: 'PANGASINAN',
    municipality: 'BANI',
    region: 'REGION I',
    barangay_list: [
      'AMBABAAY',
      'APORAO',
      'ARWAS',
      'BALLAG',
      'BANOG NORTE',
      'BANOG SUR',
      'CALABENG',
      'CENTRO TOMA',
      'COLAYO',
      'DACAP NORTE',
      'DACAP SUR',
      'GARRITA',
      'LUAC',
      'MACABIT',
      'MASIDEM',
      'POBLACION',
      'QUINAOAYANAN',
      'RANAO',
      'RANOM ILOCO',
      'SAN JOSE',
      'SAN MIGUEL',
      'SAN SIMON',
      'SAN VICENTE',
      'TIEP',
      'TIPOR',
      'TUGUI GRANDE',
      'TUGUI NORTE',
    ],
  },
  BASISTA: {
    province: 'PANGASINAN',
    municipality: 'BASISTA',
    region: 'REGION I',
    barangay_list: [
      'ANAMBONGAN',
      'BAYOYONG',
      'CABELDATAN',
      'DUMPAY',
      'MALIMPEC EAST',
      'MAPOLOPOLO',
      'NALNERAN',
      'NAVATAT',
      'OBONG',
      'OSMENA SR.',
      'PALMA',
      'PATACBO',
      'POBLACION',
    ],
  },
  BAUTISTA: {
    province: 'PANGASINAN',
    municipality: 'BAUTISTA',
    region: 'REGION I',
    barangay_list: [
      'ARTACHO',
      'BALUYOT',
      'CABUAAN',
      'CACANDONGAN',
      'DIAZ',
      'KETEGAN',
      'NANDACAN',
      'NIBALIW NORTE',
      'NIBALIW SUR',
      'PALISOC',
      'POBLACION EAST',
      'POBLACION WEST',
      'POGO',
      'POPONTO',
      'PRIMICIAS',
      'SINABAAN',
      'VACANTE',
      'VILLANUEVA',
    ],
  },
  BAYAMBANG: {
    province: 'PANGASINAN',
    municipality: 'BAYAMBANG',
    region: 'REGION I',
    barangay_list: [
      'ALINGGAN',
      'AMAMPEREZ',
      'AMANCOSILING NORTE',
      'AMANCOSILING SUR',
      'AMBAYAT I',
      'AMBAYAT II',
      'APALEN',
      'ASIN',
      'ATAYNAN',
      'BACNONO',
      'BALAYBUAYA',
      'BANABAN',
      'BANI',
      'BATANGCAWA',
      'BELENG',
      'BICAL NORTE',
      'BICAL SUR',
      'BONGATO EAST',
      'BONGATO WEST',
      'BUAYAEN',
      'BUENLAG 1ST',
      'BUENLAG 2ND',
      'CADRE SITE',
      'CARUNGAY',
      'CATURAY',
      'DARAWEY (TANGAL)',
      'DUERA',
      'DUSOC',
      'HERMOZA',
      'IDONG',
      'INANLORENZANA',
      'INIRANGAN',
      'ITON',
      'LANGIRAN',
      'LIGUE',
      'M. H. DEL PILAR',
      'MACAYOCAYO',
      'MAGSAYSAY',
      'MAIGPA',
      'MALIMPEC',
      'MALIOER',
      'MANAGOS',
      'MANAMBONG NORTE',
      'MANAMBONG PARTE',
      'MANAMBONG SUR',
      'MANGAYAO',
      'NALSIAN NORTE',
      'NALSIAN SUR',
      'PANGDEL',
      'PANTOL',
      'PARAGOS',
      'POBLACION SUR',
      'PUGO',
      'REYNADO',
      'SAN GABRIEL 1ST',
      'SAN GABRIEL 2ND',
      'SAN VICENTE',
      'SANGCAGULIS',
      'SANLIBO',
      'SAPANG',
      'TAMARO',
      'TAMBAC',
      'TAMPOG',
      'TANOLONG',
      'TATARAO',
      'TELBANG',
      'TOCOC EAST',
      'TOCOC WEST',
      'WARDING',
      'WAWA',
      'ZONE I (POB.)',
      'ZONE II (POB.)',
      'ZONE III (POB.)',
      'ZONE IV (POB.)',
      'ZONE V (POB.)',
      'ZONE VI (POB.)',
      'ZONE VII (POB.)',
    ],
  },
  BINALONAN: {
    province: 'PANGASINAN',
    municipality: 'BINALONAN',
    region: 'REGION I',
    barangay_list: [
      'BALANGOBONG',
      'BUED',
      'BUGAYONG',
      'CAMANGAAN',
      'CANARVACANAN',
      'CAPAS',
      'CILI',
      'DUMAYAT',
      'LINMANSANGAN',
      'MANGCASUY',
      'MORENO',
      'PASILENG NORTE',
      'PASILENG SUR',
      'POBLACION',
      'SAN FELIPE CENTRAL',
      'SAN FELIPE SUR',
      'SAN PABLO',
      'SANTA CATALINA',
      'SANTA MARIA NORTE',
      'SANTIAGO',
      'SANTO NIÑO',
      'SUMABNIT',
      'TABUYOC',
      'VACANTE',
    ],
  },
  BINMALEY: {
    province: 'PANGASINAN',
    municipality: 'BINMALEY',
    region: 'REGION I',
    barangay_list: [
      'AMANCORO',
      'BALAGAN',
      'BALOGO',
      'BASING',
      'BAYBAY LOPEZ',
      'BAYBAY POLONG',
      'BIEC',
      'BUENLAG',
      'CALIT',
      'CALOOCAN DUPO',
      'CALOOCAN NORTE',
      'CALOOCAN SUR',
      'CAMALEY',
      'CANAOALAN',
      'DULAG',
      'GAYAMAN',
      'LINOC',
      'LOMBOY',
      'MALINDONG',
      'MANAT',
      'NAGPALANGAN',
      'NAGUILAYAN',
      'PALLAS',
      'PAPAGUEYAN',
      'PARAYAO',
      'POBLACION',
      'POTOTAN',
      'SABANGAN',
      'SALAPINGAO',
      'SAN ISIDRO NORTE',
      'SAN ISIDRO SUR',
      'SANTA ROSA',
      'TOMBOR',
    ],
  },
  BOLINAO: {
    province: 'PANGASINAN',
    municipality: 'BOLINAO',
    region: 'REGION I',
    barangay_list: [
      'ARNEDO',
      'BALINGASAY',
      'BINABALIAN',
      'CABUYAO',
      'CATUDAY',
      'CATUNGI',
      'CONCORDIA (POB.)',
      'CULANG',
      'DEWEY',
      'ESTANZA',
      'GERMINAL (POB.)',
      'GOYODEN',
      'ILOGMALINO',
      'LAMBES (LAMES)',
      'LIWA-LIWA',
      'LUCERO',
      'LUCIENTE 1.0',
      'LUCIENTE 2.0',
      'LUNA',
      'PATAR',
      'PILAR',
      'SALUD',
      'SAMANG NORTE',
      'SAMANG SUR',
      'SAMPALOC',
      'SAN ROQUE',
      'TARA',
      'TUPA',
      'VICTORY',
      'ZARAGOZA',
    ],
  },
  BUGALLON: {
    province: 'PANGASINAN',
    municipality: 'BUGALLON',
    region: 'REGION I',
    barangay_list: [
      'ANGARIAN',
      'ASINAN',
      'BACABAC',
      'BANAGA',
      'BOLAOEN',
      'BUENLAG',
      'CABAYAOASAN',
      'CAYANGA',
      'GUESET',
      'HACIENDA',
      'LAGUIT CENTRO',
      'LAGUIT PADILLA',
      'MAGTAKING',
      'PANGASCASAN',
      'PANTAL',
      'POBLACION',
      'POLONG',
      'PORTIC',
      'SALASA',
      'SALOMAGUE NORTE',
      'SALOMAGUE SUR',
      'SAMAT',
      'SAN FRANCISCO',
      'UMANDAY',
    ],
  },
  CALASIAO: {
    province: 'PANGASINAN',
    municipality: 'CALASIAO',
    region: 'REGION I',
    barangay_list: [
      'AMBONAO',
      'AMBUETEL',
      'BANAOANG',
      'BUED',
      'BUENLAG',
      'CABILOCAAN',
      'DINALAOAN',
      'DOYONG',
      'GABON',
      'LASIP',
      'LONGOS',
      'LUMBANG',
      'MACABITO',
      'MALABAGO',
      'MANCUP',
      'NAGSAING',
      'NALSIAN',
      'POBLACION EAST',
      'POBLACION WEST',
      'QUESBAN',
      'SAN MIGUEL',
      'SAN VICENTE',
      'SONGKOY',
      'TALIBAEW',
    ],
  },
  'DAGUPAN': {
    province: 'PANGASINAN',
    municipality: 'DAGUPAN',
    region: 'REGION I',
    barangay_list: [
      'BACAYAO NORTE',
      'BACAYAO SUR',
      'BARANGAY I (T. BUGALLON)',
      'BARANGAY II (NUEVA)',
      'BARANGAY IV (ZAMORA)',
      'BOLOSAN',
      'BONUAN BINLOC',
      'BONUAN BOQUIG',
      'BONUAN GUESET',
      'CALMAY',
      'CARAEL',
      'CARANGLAAN',
      'HERRERO',
      'LASIP CHICO',
      'LASIP GRANDE',
      'LOMBOY',
      'LUCAO',
      'MALUED',
      'MAMALINGLING',
      'MANGIN',
      'MAYOMBO',
      'PANTAL',
      'POBLACION OESTE',
      'POGO CHICO',
      'POGO GRANDE',
      'PUGARO SUIT',
      'SALAPINGAO',
      'SALISAY',
      'TAMBAC',
      'TAPUAC',
      'TEBENG',
    ],
  },
  DASOL: {
    province: 'PANGASINAN',
    municipality: 'DASOL',
    region: 'REGION I',
    barangay_list: [
      'ALILAO',
      'AMALBALAN',
      'BOBONOT',
      'EGUIA',
      'GAIS-GUIPE',
      'HERMOSA',
      'MACALANG',
      'MAGSAYSAY',
      'MALACAPAS',
      'MALIMPIN',
      'OSMEÑA',
      'PETAL',
      'POBLACION',
      'SAN VICENTE',
      'TAMBAC',
      'TAMBOBONG',
      'ULI',
      'VIGA',
    ],
  },
  INFANTA: {
    province: 'QUEZON',
    municipality: 'INFANTA',
    region: 'REGION IV-A',
    barangay_list: [
      'ABIAWIN',
      'AGOS-AGOS',
      'ALITAS',
      'AMOLONGIN',
      'ANIBONG',
      'ANTIKIN',
      'BACONG',
      'BALOBO',
      'BANTILAN',
      'BANUGAO',
      'BATICAN',
      'BINONOAN',
      'BINULASAN',
      'BOBOIN',
      'CATAMBUNGAN',
      'CAWAYNIN',
      'COMON',
      'DINAHICAN',
      'GUMIAN',
      'ILOG',
      'INGAS',
      'LANGGAS',
      'LIBJO',
      'LUAL',
      'MAGSAYSAY',
      'MAYPULOT',
      'MISWA',
      'PILAWAY',
      'PINAGLAPATAN',
      'POBLACION 1 (BARANGAY 1)',
      'POBLACION 38 (POBLACION BARANGAY 2)',
      'POBLACION 39 (POBLACION BARANGAY 3)',
      'PULO',
      'SILANGAN',
      'TONGOHIN',
      'TUDTURAN',
    ],
  },
  LABRADOR: {
    province: 'PANGASINAN',
    municipality: 'LABRADOR',
    region: 'REGION I',
    barangay_list: [
      'BOLO',
      'BONGALON',
      'DULIG',
      'LAOIS',
      'MAGSAYSAY',
      'POBLACION',
      'SAN GONZALO',
      'SAN JOSE',
      'TOBUAN',
      'UYONG',
    ],
  },
  LAOAC: {
    province: 'PANGASINAN',
    municipality: 'LAOAC',
    region: 'REGION I',
    barangay_list: [
      'ANIS',
      'BALLIGI',
      'BANUAR',
      'BOTIQUE',
      'CAARINGAYAN',
      'CABILAOAN WEST',
      'CABULALAAN',
      'CALAOAGAN',
      'CALMAY',
      'CASAMPAGAAN',
      'CASANESTEBANAN',
      'CASANTIAGOAN',
      'DOMINGO ALARCIO (CABILAOAN EAST)',
      'INMANDUYAN',
      'LEBUEG',
      'MARABOC',
      'NANBAGATAN',
      'PANAGA',
      'POBLACION (LAOAC)',
      'TALOGTOG',
      'TURKO',
      'YATYAT',
    ],
  },
  LINGAYEN: {
    province: 'PANGASINAN',
    municipality: 'LINGAYEN',
    region: 'REGION I',
    barangay_list: [
      'ALIWEKWEK',
      'BAAY',
      'BALANGOBONG',
      'BALOCOC',
      'BANTAYAN',
      'BASING',
      'CAPANDANAN',
      'DOMALANDAN CENTER',
      'DOMALANDAN EAST',
      'DOMALANDAN WEST',
      'DORONGAN',
      'DULAG',
      'ESTANZA',
      'LASIP',
      'LIBSONG EAST',
      'LIBSONG WEST',
      'MALAWA',
      'MALIMPUEC',
      'MANIBOC',
      'MATALAVA',
      'NAGUELGUEL',
      'NAMOLAN',
      'PANGAPISAN NORTH',
      'PANGAPISAN SUR',
      'POBLACION',
      'QUIBAOL',
      'ROSARIO',
      'SABANGAN',
      'TALOGTOG',
      'TONTON',
      'TUMBAR',
      'WAWA',
    ],
  },
  MABINI: {
    province: 'BOHOL',
    municipality: 'MABINI',
    region: 'REGION VII',
    barangay_list: [
      'ABACA',
      'ABAD SANTOS',
      'AGUIPO',
      'BAYBAYON',
      'BULAWAN',
      'CABIDIAN',
      'CAWAYANAN',
      'CONCEPCION (BANLAS)',
      'DEL MAR',
      'LUNGSODA-AN',
      'MARCELO',
      'MINOL',
      'PARAISO',
      'POBLACION I',
      'POBLACION II',
      'SAN ISIDRO',
      'SAN JOSE',
      'SAN RAFAEL',
      'SAN ROQUE (CABULAO)',
      'TAMBO',
      'TANGKIGAN',
      'VALAGA',
    ],
  },
  MALASIQUI: {
    province: 'PANGASINAN',
    municipality: 'MALASIQUI',
    region: 'REGION I',
    barangay_list: [
      'ABONAGAN',
      'AGDAO',
      'ALACAN',
      'ALIAGA',
      'AMACALAN',
      'ANOLID',
      'APAYA',
      'ASIN ESTE',
      'ASIN WESTE',
      'BACUNDAO ESTE',
      'BACUNDAO WESTE',
      'BAKITIW',
      'BALITE',
      'BANAWANG',
      'BARANG',
      'BAWER',
      'BINALAY',
      'BOBON',
      'BOLAOIT',
      'BONGAR',
      'BUTAO',
      'CABATLING',
      'CABUELDATAN',
      'CALBUEG',
      'CANAN NORTE',
      'CANAN SUR',
      'CAWAYAN BOGTONG',
      'DON PEDRO',
      'GATANG',
      'GOLIMAN',
      'GOMEZ',
      'GUILIG',
      'ICAN',
      'INGALAGALA',
      'LAREG-LAREG',
      'LASIP',
      'LEPA',
      'LOQUEB ESTE',
      'LOQUEB NORTE',
      'LOQUEB SUR',
      'LUNEC',
      'MABULITEC',
      'MALIMPEC',
      'MANGGAN-DAMPAY',
      'NALSIAN NORTE',
      'NALSIAN SUR',
      'NANCAPIAN',
      'NANSANGAAN',
      'OLEA',
      'PACUAN',
      'PALAPAR NORTE',
      'PALAPAR SUR',
      'PALONG',
      'PAMARANUM',
      'PASIMA',
      'PAYAR',
      'POBLACION',
      'POLONG NORTE',
      'POLONG SUR',
      'POTIOCAN',
      'SAN JULIAN',
      'TABO-SILI',
      'TALOSPATANG',
      'TALOY',
      'TALOYAN',
      'TAMBAC',
      'TOBOR',
      'TOLONGUAT',
      'TOMLING',
      'UMANDO',
      'VIADO',
      'WAIG',
      'WAREY',
    ],
  },
  MANAOAG: {
    province: 'PANGASINAN',
    municipality: 'MANAOAG',
    region: 'REGION I',
    barangay_list: [
      'BABASIT',
      'BAGUINAY',
      'BARITAO',
      'BISAL',
      'BUCAO',
      'CABANBANAN',
      'CALAOCAN',
      'INAMOTAN',
      'LELEMAAN',
      'LICSI',
      'LIPIT NORTE',
      'LIPIT SUR',
      'MATOLONG',
      'MERMER',
      'NALSIAN',
      'ORAAN EAST',
      'ORAAN WEST',
      'PANTAL',
      'PAO',
      'PARIAN',
      'POBLACION',
      'PUGARO',
      'SAN RAMON',
      'SANTA INES',
      'SAPANG',
      'TEBUEL',
    ],
  },
  MANGALDAN: {
    province: 'PANGASINAN',
    municipality: 'MANGALDAN',
    region: 'REGION I',
    barangay_list: [
      'ALITAYA',
      'AMANSABINA',
      'ANOLID',
      'BANAOANG',
      'BANTAYAN',
      'BARI',
      'BATENG',
      'BUENLAG',
      'DAVID',
      'EMBARCADERO',
      'GUEGUESANGEN',
      'GUESANG',
      'GUIGUILONEN',
      'GUILIG',
      'INLAMBO',
      'LANAS',
      'LANDAS',
      'MAASIN',
      'MACAYUG',
      'MALABAGO',
      'NAVALUAN',
      'NIBALIW',
      'OSIEM',
      'PALUA',
      'POBLACION',
      'POGO',
      'SALAAN',
      'SALAY',
      'TALOGTOG',
      'TEBAG',
    ],
  },
  MANGATAREM: {
    province: 'PANGASINAN',
    municipality: 'MANGATAREM',
    region: 'REGION I',
    barangay_list: [
      'ANDANGIN',
      'ARELLANO STREET (POB.)',
      'BANTAY',
      'BANTOCALING',
      'BARACBAC',
      'BOGTONG BOLO',
      'BOGTONG BUNAO',
      'BOGTONG CENTRO',
      'BOGTONG NIOG',
      'BOGTONG SILAG',
      'BUAYA',
      'BUENLAG',
      'BUENO',
      'BUNAGAN',
      'BUNLALACAO',
      'BURGOS STREET (POB.)',
      'CABALUYAN 1ST',
      'CABALUYAN 2ND',
      'CABARABUAN',
      'CABARUAN',
      'CABAYAOASAN',
      'CABAYUGAN',
      'CACAOITEN',
      'CALUMBOYAN NORTE',
      'CALUMBOYAN SUR',
      'CALVO (POB.)',
      'CASILAGAN',
      'CATARATARAAN',
      'CATURAY NORTE',
      'CATURAY SUR',
      'CAVIERNESAN',
      'DORONGAN KETAKET',
      'DORONGAN LINMANSANGAN',
      'DORONGAN PUNTA',
      'DORONGAN SAWAT',
      'DORONGAN VALERIO',
      'GENERAL LUNA (POB.)',
      'HISTORIA',
      'LAWAK LANGKA',
      'LINMANSANGAN',
      'LOPEZ (POB.)',
      'MABINI (POB.)',
      'MACARANG',
      'MALABOBO',
      'MALIBONG',
      'MALUNEC',
      'MARAVILLA (POB.)',
      'MARAVILLA-ARELLANO EXT. (POB.)',
      'MUELANG',
      'NAGUILAYAN EAST',
      'NAGUILAYAN WEST',
      'NANCASALAN',
      'NIOG-CABISON-BULANEY',
      'OLEGARIO-CAOILE (POB.)',
      'OLO CACAMPOSAN',
      'OLO CAFABROSAN',
      'OLO CAGARLITAN',
      'OSMEÑA (POB.)',
      'PACALAT',
      'PAMPANO',
      'PARIAN',
      'PAUL',
      'PEANIA PEDANIA (BEDANIA)',
      'POGON-ANIAT',
      'POGON-LOMBOY (POB.)',
      'PONGLO-BALEG',
      'PONGLO-MUELAG',
      'QUETEGAN (POGON-BALEG)',
      'QUEZON (POB.)',
      'SALAVANTE',
      'SAPANG',
      'SONSON ONGKIT',
      'SUACO',
      'TAGAC',
      'TAKIPAN',
      'TALOGTOG',
      'TOCOC BARIKIR',
      'TORRE 1ST',
      'TORRE 2ND',
      'TORRES BUGALLON (POB.)',
      'UMANGAN',
      'ZAMORA (POB.)',
    ],
  },
  MAPANDAN: {
    province: 'PANGASINAN',
    municipality: 'MAPANDAN',
    region: 'REGION I',
    barangay_list: [
      'AMANOAOAC',
      'APAYA',
      'ASERDA',
      'BALOLING',
      'CORAL',
      'GOLDEN',
      'JIMENEZ',
      'LAMBAYAN',
      'LUYAN (LUYAN SOUTH)',
      'NILOMBOT',
      'PIAS',
      'POBLACION',
      'PRIMICIAS',
      'SANTA MARIA (LUYAN NORTH)',
      'TORRES',
    ],
  },
  NATIVIDAD: {
    province: 'PANGASINAN',
    municipality: 'NATIVIDAD',
    region: 'REGION I',
    barangay_list: [
      'BARANGOBONG',
      'BATCHELOR EAST',
      'BATCHELOR WEST',
      'BURGOS (SAN NARCISO)',
      'CACANDUNGAN',
      'CALAPUGAN',
      'CANAREM',
      'LUNA',
      'POBLACION EAST',
      'POBLACION WEST',
      'RIZAL',
      'SALUD',
      'SAN EUGENIO',
      'SAN MACARIO NORTE',
      'SAN MACARIO SUR',
      'SAN MAXIMO',
      'SAN MIGUEL',
      'SILAG',
    ],
  },
  POZORRUBIO: {
    province: 'PANGASINAN',
    municipality: 'POZORRUBIO',
    region: 'REGION I',
    barangay_list: [
      'ALIPANGPANG',
      'AMAGBAGAN',
      'BALACAG',
      'BANDING',
      'BANTUGAN',
      'BATAKIL',
      'BOBONAN',
      'BUNEG',
      'CABLONG',
      'CASANFERNANDOAN',
      'CASTAÑO',
      'DILAN',
      'DON BENITO',
      'HAWAY',
      'IMBALBALATONG',
      'INOMAN',
      'LAOAC',
      'MAAMBAL',
      'MALASIN',
      'MALOKIAT',
      'MANAOL',
      'NAMA',
      'NANTANGALAN',
      'PALACPALAC',
      'PALGUYOD',
      'POBLACION I',
      'POBLACION II',
      'POBLACION III',
      'POBLACION IV',
      'ROSARIO',
      'SUGCONG',
      'TALOGTOG',
      'TULNAC',
      'VILLEGAS',
    ],
  },
  ROSALES: {
    province: 'PANGASINAN',
    municipality: 'ROSALES',
    region: 'REGION I',
    barangay_list: [
      'ACOP',
      'BAKITBAKIT',
      'BALINGCANAWAY',
      'CABALAOANGAN NORTE',
      'CABALAOANGAN SUR',
      'CALANUTAN (DON FELIX COLOMA)',
      'CAMANGAAN',
      'CAPITAN TOMAS',
      'CARMAY EAST',
      'CARMAY WEST',
      'CARMEN EAST',
      'CARMEN WEST',
      'CASANICOLASAN',
      'COLILING',
      'DON ANTONIO VILLAGE',
      'GUILING',
      'PALAKIPAK',
      'PANGAOAN',
      'RABAGO',
      'RIZAL',
      'SALVACION',
      'SAN ANGEL',
      'SAN ANTONIO',
      'SAN BARTOLOME',
      'SAN ISIDRO',
      'SAN LUIS',
      'SAN PEDRO EAST',
      'SAN PEDRO WEST',
      'SAN VICENTE',
      'STATION DISTRICT',
      'TOMANA EAST',
      'TOMANA WEST',
      'ZONE I (POB.)',
      'ZONE II (POB.)',
      'ZONE III (POB.)',
      'ZONE IV (POB.)',
      'ZONE V (POB.)',
    ],
  },
  'SAN CARLOS': {
    province: 'NEGROS OCCIDENTAL',
    municipality: 'SAN CARLOS',
    region: 'REGION VI',
    barangay_list: [
      'BAGONBON',
      'BARANGAY I (POB.)',
      'BARANGAY II (POB.)',
      'BARANGAY III (POB.)',
      'BARANGAY IV (POB.)',
      'BARANGAY V (POB.)',
      'BARANGAY VI (POB.)',
      'BULUANGAN',
      'CODCOD',
      'ERMITA',
      'GUADALUPE',
      'NATABAN',
      'PALAMPAS',
      'PROSPERIDAD',
      'PUNAO',
      'QUEZON',
      'RIZAL',
      'SAN JUAN',
    ],
  },
  'SAN FABIAN': {
    province: 'PANGASINAN',
    municipality: 'SAN FABIAN',
    region: 'REGION I',
    barangay_list: [
      'ALACAN',
      'AMBALANGAN-DALIN',
      'ANGIO',
      'ANONANG',
      'ARAMAL',
      'BIGBIGA',
      'BINDAY',
      'BOLAOEN',
      'BOLASI',
      'CABARUAN',
      'CAYANGA',
      'COLISAO',
      'GOMOT',
      'INMALOG',
      'INMALOG NORTE',
      'LEKEP-BUTAO',
      'LIPIT-TOMEENG',
      'LONGOS',
      'LONGOS PROPER',
      'LONGOS-AMANGONAN-PARAC-PARAC FABRICA',
      'MABILAO',
      'NIBALIW CENTRAL',
      'NIBALIW EAST',
      'NIBALIW MAGLIBA',
      'NIBALIW NARVARTE (NIBALIW WEST COMPOUND)',
      'NIBALIW VIDAL (NIBALIW WEST PROPER)',
      'PALAPAD',
      'POBLACION',
      'RABON',
      'SAGUD-BAHLEY',
      'SOBOL',
      'TEMPRA-GUILIG',
      'TIBLONG',
      'TOCOK',
    ],
  },
  'SAN CARLOS': {
    province: 'PANGASINAN',
    municipality: 'SAN CARLOS',
    region: 'REGION I',
    barangay_list: [],
  },
  'SAN JACINTO': {
    province: 'MASBATE',
    municipality: 'SAN JACINTO',
    region: 'REGION V',
    barangay_list: [
      'ALMIÑE',
      'BAGACAY',
      'BAGAHANGLAD',
      'BARTOLABAC',
      'BURGOS',
      'CALIPAT-AN',
      'DANAO',
      'DISTRICT I (POB.)',
      'DISTRICT II (POB.)',
      'DISTRICT III (POB.)',
      'DISTRICT IV (POB.)',
      'DORONG-AN DAPLIAN',
      'INTERIOR',
      'JAGNA-AN',
      'LUNA',
      'MABINI',
      'PIÑA',
      'ROOSEVELT',
      'SAN ISIDRO',
      'SANTA ROSA',
      'WASHINGTON',
    ],
  },
  'SAN MANUEL': {
    province: 'TARLAC',
    municipality: 'SAN MANUEL',
    region: 'REGION III',
    barangay_list: [
      'COLUBOT',
      'LANAT',
      'LEGASPI',
      'MANGANDINGAY',
      'MATARANNOC',
      'PACPACO',
      'POBLACION',
      'SALCEDO',
      'SAN AGUSTIN',
      'SAN FELIPE',
      'SAN JACINTO',
      'SAN MIGUEL',
      'SAN NARCISO',
      'SAN VICENTE',
      'SANTA MARIA',
    ],
  },
  'SAN QUINTIN': {
    province: 'ABRA',
    municipality: 'SAN QUINTIN',
    region: 'CAR',
    barangay_list: [
      'LABAAN',
      'PALANG',
      'PANTOC',
      'POBLACION',
      'TANGADAN',
      'VILLA MERCEDES',
    ],
  },
  'SANTA BARBARA': {
    province: 'ILOILO',
    municipality: 'SANTA BARBARA',
    region: 'REGION VI',
    barangay_list: [
      'AGUSIPAN',
      'AGUTAYAN',
      'BAGUMBAYAN',
      'BALABAG',
      'BALIBAGAN ESTE',
      'BALIBAGAN OESTE',
      'BAN-AG',
      'BANTAY',
      'BARANGAY ZONE I (POB.)',
      'BARANGAY ZONE II (POB.)',
      'BARANGAY ZONE III (POB.)',
      'BARANGAY ZONE IV (POB.)',
      'BARANGAY ZONE V (POB.)',
      'BARANGAY ZONE VI (POB.)',
      'BARASAN ESTE',
      'BARASAN OESTE',
      'BINANGKILAN',
      'BITAOG-TAYTAY',
      'BOLONG ESTE',
      'BOLONG OESTE',
      'BUAYAHON',
      'BUYO',
      'CABUGAO NORTE',
      'CABUGAO SUR',
      'CADAGMAYAN NORTE',
      'CADAGMAYAN SUR',
      'CAFE',
      'CALABOA ESTE',
      'CALABOA OESTE',
      'CAMAMBUGAN',
      'CANIPAYAN',
      'CONAYNAY',
      'DAGA',
      'DALID',
      'DUYANDUYAN',
      'GEN. MARTIN T. DELGADO',
      'GUNO',
      'INANGAYAN',
      'JIBAO-AN',
      'LACADON',
      'LANAG',
      'LUPA',
      'MAGANCINA',
      'MALAWOG',
      'MAMBUYO',
      'MANHAYANG',
      'MIRAGA-GUIBUANGAN',
      'NASUGBAN',
      'OMAMBOG',
      'PAL-AGON',
      'PUNGSOD',
      'SAN SEBASTIAN',
      'SANGCATE',
      'TAGSING',
      'TALANGHAUAN',
      'TALONGADIAN',
      'TIGTIG',
      'TUBURAN',
      'TUGAS',
      'TUNGAY',
    ],
  },
  SUAL: {
    province: 'PANGASINAN',
    municipality: 'SUAL',
    region: 'REGION I',
    barangay_list: [
      'BAQUIOEN',
      'BAYBAY NORTE',
      'BAYBAY SUR',
      'BOLAOEN',
      'CABALITIAN',
      'CALUMBUYAN',
      'CAMAGSINGALAN',
      'CAOAYAN',
      'CAPANTOLAN',
      'MACAYCAYAWAN',
      'PAITAN EAST',
      'PAITAN WEST',
      'PANGASCASAN',
      'POBLACION',
      'SANTO DOMINGO',
      'SESELANGEN',
      'SIOASIO EAST',
      'SIOASIO WEST',
      'VICTORIA',
    ],
  },
  TAYUG: {
    province: 'PANGASINAN',
    municipality: 'TAYUG',
    region: 'REGION I',
    barangay_list: [
      'AGNO',
      'AMISTAD',
      'BARANGAY A (POB.)',
      'BARANGAY B (POB.)',
      'BARANGAY C (POB.)',
      'BARANGAY D (POB.)',
      'BARANGOBONG',
      'C. LICHAUCO',
      'CARRIEDO',
      'EVANGELISTA',
      'GUZON',
      'LAWAK',
      'LEGASPI',
      'LIBERTAD',
      'MAGALLANES',
      'PANGANIBAN',
      'SALENG',
      'SANTO DOMINGO',
      'TOKETEC',
      'TRENCHERA',
      'ZAMORA',
    ],
  },
  UMINGAN: {
    province: 'PANGASINAN',
    municipality: 'UMINGAN',
    region: 'REGION I',
    barangay_list: [
      'ABOT MOLINA',
      'ALO-O',
      'AMARONAN',
      'ANNAM',
      'BANTUG',
      'BARACBAC',
      'BARAT',
      'BUENAVISTA',
      'CABALITIAN',
      'CABANGARAN',
      'CABARUAN',
      'CABATUAN',
      'CADIZ',
      'CALITLITAN',
      'CAPAS',
      'CARAYUNGAN SUR',
      'CAROSALESAN',
      'CASILAN',
      'CAURDANETAAN',
      'CONCEPCION',
      'DECRETO',
      'DEL ROSARIO',
      'DIAZ',
      'DIKET',
      'DON JUSTO ABALOS (CAROAN)',
      'DON MONTANO',
      'ESPERANZA',
      'EVANGELISTA',
      'FLORES',
      'FULGOSINO',
      'GONZALES',
      'LA PAZ',
      'LABUAN',
      'LAUREN',
      'LUBONG',
      'LUNA ESTE',
      'LUNA WESTE',
      'MANTACDANG',
      'MASEIL-SEIL',
      'NAMPALCAN',
      'NANCALABASAAN',
      'PANGANGAAN',
      'PAPALLASEN',
      'PEMIENTA',
      'POBLACION EAST',
      'POBLACION WEST',
      'PRADO',
      'RESURRECCION',
      'RICOS',
      'SAN ANDRES',
      'SAN JUAN',
      'SAN LEON',
      'SAN PABLO',
      'SAN VICENTE',
      'SANTA MARIA',
      'SANTA ROSA',
      'SINABAAN',
      'TANGGAL SAWANG',
    ],
  },
  URBIZTONDO: {
    province: 'PANGASINAN',
    municipality: 'URBIZTONDO',
    region: 'REGION I',
    barangay_list: [
      'ANGATEL',
      'BALANGAY',
      'BATANGCAOA',
      'BAUG',
      'BAYAOAS',
      'BITUAG',
      'CAMAMBUGAN',
      'DALANGIRING',
      'DUPLAC',
      'GALARIN',
      'GUETEB',
      'MALACA',
      'MALAYO',
      'MALIBONG',
      'PASIBI EAST',
      'PASIBI WEST',
      'PISUAC',
      'POBLACION',
      'REAL',
      'SALAVANTE',
      'SAWAT',
    ],
  },
  'URDANETA': {
    province: 'PANGASINAN',
    municipality: 'URDANETA',
    region: 'REGION I',
    barangay_list: [
      'ANONAS',
      'BACTAD EAST',
      'BAYAOAS',
      'BOLAOEN',
      'CABARUAN',
      'CABULOAN',
      'CAMANANG',
      'CAMANTILES',
      'CASANTAAN',
      'CATABLAN',
      'CAYAMBANAN',
      'CONSOLACION',
      'DILAN PAURIDO',
      'DR. PEDRO T. ORATA (BACTAD PROPER)',
      'LABIT PROPER',
      'LABIT WEST',
      'MABANOGBOG',
      'MACALONG',
      'NANCALOBASAAN',
      'NANCAMALIRAN EAST',
      'NANCAMALIRAN WEST',
      'NANCAYASAN',
      'OLTAMA',
      'PALINA EAST',
      'PALINA WEST',
      'PINMALUDPOD',
      'POBLACION',
      'SAN JOSE',
      'SAN VICENTE',
      'SANTA LUCIA',
      'SANTO DOMINGO',
      'SUGCONG',
      'TIPUSO',
      'TULONG',
    ],
  },
  VILLASIS: {
    province: 'PANGASINAN',
    municipality: 'VILLASIS',
    region: 'REGION I',
    barangay_list: [
      'AMAMPEREZ',
      'BACAG',
      'BARANGOBONG',
      'BARRACA',
      'CAPULAAN',
      'CARAMUTAN',
      'LA PAZ',
      'LABIT',
      'LIPAY',
      'LOMBOY',
      'PIAZ (PLAZA)',
      'PUELAY',
      'SAN BLAS',
      'SAN NICOLAS',
      'TOMBOD',
      'UNZAD',
      'ZONE I (POB.)',
      'ZONE II (POB.)',
      'ZONE III (POB.)',
      'ZONE IV (POB.)',
      'ZONE V (POB.)',
    ],
  },
  BASCO: {
    province: 'BATANES',
    municipality: 'BASCO',
    region: 'REGION II',
    barangay_list: [
      'CHANARIAN',
      'IHUBOK I (KAYCHANARIANAN)',
      'IHUBOK II (KAYVALUGANAN)',
      'KAYHUVOKAN',
      'SAN ANTONIO',
      'SAN JOAQUIN',
    ],
  },
  ITBAYAT: {
    province: 'BATANES',
    municipality: 'ITBAYAT',
    region: 'REGION II',
    barangay_list: [
      'RAELE',
      'SAN RAFAEL (IDIANG)',
      'SANTA LUCIA (KAUHAUHASAN)',
      'SANTA MARIA (MARAPUY)',
      'SANTA ROSA (KAYNATUAN)',
    ],
  },
  IVANA: {
    province: 'BATANES',
    municipality: 'IVANA',
    region: 'REGION II',
    barangay_list: [
      'RADIWAN',
      'SALAGAO',
      'SAN VICENTE (IGANG)',
      'TUHEL (POB.)',
    ],
  },
  MAHATAO: {
    province: 'BATANES',
    municipality: 'MAHATAO',
    region: 'REGION II',
    barangay_list: ['HANIB', 'KAUMBAKAN', 'PANATAYAN', 'UVOY (POB.)'],
  },
  SABTANG: {
    province: 'BATANES',
    municipality: 'SABTANG',
    region: 'REGION II',
    barangay_list: [
      'CHAVAYAN',
      'MALAKDANG (POB.)',
      'NAKANMUAN',
      'SAVIDUG',
      'SINAKAN (POB.)',
      'SUMNANGA',
    ],
  },
  UYUGAN: {
    province: 'BATANES',
    municipality: 'UYUGAN',
    region: 'REGION II',
    barangay_list: [
      'IMNAJBU',
      'ITBUD',
      'KAYUGANAN (POB.)',
      'KAYVALUGANAN (POB.)',
    ],
  },
  ABULUG: {
    province: 'CAGAYAN',
    municipality: 'ABULUG',
    region: 'REGION II',
    barangay_list: [
      'ALINUNU',
      'BAGU',
      'BANGUIAN',
      'CALOG NORTE',
      'CALOG SUR',
      'CANAYUN',
      'CENTRO (POB.)',
      'DANA-ILI',
      'GUIDDAM',
      'LIBERTAD',
      'LUCBAN',
      'PINILI',
      'SAN AGUSTIN',
      'SAN JULIAN',
      'SANTA FILOMENA',
      'SANTA ROSA',
      'SANTO TOMAS',
      'SIGUIRAN',
      'SIMAYUNG',
      'SIRIT',
    ],
  },
  ALLACAPAN: {
    province: 'CAGAYAN',
    municipality: 'ALLACAPAN',
    region: 'REGION II',
    barangay_list: [
      'BESSANG',
      'BINOBONGAN',
      'BULO',
      'BUROT',
      'CAPAGARAN (BRIGIDA)',
      'CAPALUTAN',
      'CAPANICKIAN NORTE',
      'CAPANICKIAN SUR',
      'CATARATAN',
      'CENTRO EAST (POB.)',
      'CENTRO WEST (POB.)',
      'DAAN-ILI',
      'DAGUPAN',
      'DALAYAP',
      'GAGADDANGAN',
      'IRINGAN',
      'LABBEN',
      'MALUYO',
      'MAPURAO',
      'MATUCAY',
      'NAGATTATAN',
      'PACAC',
      'SAN JUAN (MAGUININANGO)',
      'SILANGAN',
      'TAMBOLI',
      'TUBEL',
      'UTAN',
    ],
  },
  AMULUNG: {
    province: 'CAGAYAN',
    municipality: 'AMULUNG',
    region: 'REGION II',
    barangay_list: [
      'ABOLO',
      'AGGUIRIT',
      'ALITUNGTUNG',
      'ANNABUCULAN',
      'ANNAFATAN',
      'ANQUIRAY',
      'BABAYUAN',
      'BACCUIT',
      'BACRING',
      'BACULUD',
      'BALAUINI',
      'BAUAN',
      'BAYABAT',
      'CALAMAGUI',
      'CALINTAAN',
      'CARATACAT',
      'CASINGSINGAN NORTE',
      'CASINGSINGAN SUR',
      'CATARAUAN',
      'CENTRO',
      'CONCEPCION',
      'CORDOVA',
      'DADDA',
      'DAFUNGANAY',
      'DUGAYUNG',
      'ESTEFANIA',
      'GABUT',
      'GANGAUAN',
      'GORAN',
      'JURISDICCION',
      'LA SUERTE',
      'LOGUNG',
      'MAGOGOD',
      'MANALO',
      'MAROBBOB',
      'MASICAL',
      'MONTE ALEGRE',
      'NABBIALAN',
      'NAGSABARAN',
      'NANGALASAUAN',
      'NANUCCAUAN',
      'PACAC-GRANDE',
      'PACAC-PEQUEÑO',
      'PALACU',
      'PALAYAG',
      'TANA',
      'UNAG',
    ],
  },
  APARRI: {
    province: 'CAGAYAN',
    municipality: 'APARRI',
    region: 'REGION II',
    barangay_list: [
      'BACKILING',
      'BANGAG',
      'BINALAN',
      'BISAGU',
      'BUKIG',
      'BULALA NORTE',
      'BULALA SUR',
      'CAAGAMAN',
      'CENTRO 1 (POB.)',
      'CENTRO 2 (POB.)',
      'CENTRO 3 (POB.)',
      'CENTRO 4 (POB.)',
      'CENTRO 5 (POB.)',
      'CENTRO 6 (POB.)',
      'CENTRO 7 (POB.)',
      'CENTRO 8 (POB.)',
      'CENTRO 9 (POB.)',
      'CENTRO 10 (POB.)',
      'CENTRO 11 (POB.)',
      'CENTRO 12 (POB.)',
      'CENTRO 13 (POB.)',
      'CENTRO 14 (POB.)',
      'CENTRO 15 (POB.)',
      'DODAN',
      'FUGA ISLAND',
      'GADDANG',
      'LINAO',
      'MABANGUC',
      'MACANAYA (PESCARIA)',
      'MAURA',
      'MINANGA',
      'NAVAGAN',
      'PADDAYA',
      'PARUDDUN NORTE',
      'PARUDDUN SUR',
      'PLAZA',
      'PUNTA',
      'SAN ANTONIO',
      'SANJA',
      'TALLUNGAN',
      'TORAN',
      'ZINARAG',
    ],
  },
  BAGGAO: {
    province: 'CAGAYAN',
    municipality: 'BAGGAO',
    region: 'REGION II',
    barangay_list: [
      'ADAOAG',
      'AGAMAN (PROPER)',
      'AGAMAN NORTE',
      'AGAMAN SUR',
      'ALBA',
      'ANNAYATAN',
      'ASASSI',
      'ASINGA-VIA',
      'AWALLAN',
      'BACAGAN',
      'BAGUNOT',
      'BARSAT EAST',
      'BARSAT WEST',
      'BITAG GRANDE',
      'BITAG PEQUEÑO',
      'BUNUGAN',
      'C. VERZOSA (VALLEY COVE)',
      'CANAGATAN',
      'CARUPIAN',
      'CATUGAY',
      'DABBAC GRANDE',
      'DALIN',
      'DALLA',
      'HACIENDA INTAL',
      'IBULO',
      'IMURONG',
      'J. PALLAGAO',
      'LASILAT',
      'MABINI',
      'MASICAL',
      'MOCAG',
      'NANGALINAN',
      'POBLACION (CENTRO)',
      'REMUS',
      'SAN ANTONIO',
      'SAN FRANCISCO',
      'SAN ISIDRO',
      'SAN JOSE',
      'SAN MIGUEL',
      'SAN VICENTE',
      'SANTA MARGARITA',
      'SANTOR',
      'TAGUING',
      'TAGUNTUNGAN',
      'TALLANG',
      'TAYTAY',
      'TEMBLIQUE',
      'TUNGEL',
    ],
  },
  BALLESTEROS: {
    province: 'CAGAYAN',
    municipality: 'BALLESTEROS',
    region: 'REGION II',
    barangay_list: [
      'AMMUBUAN',
      'BARAN',
      'CABARITAN EAST',
      'CABARITAN WEST',
      'CABAYU',
      'CABULUAN EAST',
      'CABULUAN WEST',
      'CENTRO EAST (POB.)',
      'CENTRO WEST (POB.)',
      'FUGU',
      'MABUTTAL EAST',
      'MABUTTAL WEST',
      'NARARAGAN',
      'PALLOC',
      'PAYAGAN EAST',
      'PAYAGAN WEST',
      'SAN JUAN',
      'SANTA CRUZ',
      'ZITANGA',
    ],
  },
  BUGUEY: {
    province: 'CAGAYAN',
    municipality: 'BUGUEY',
    region: 'REGION II',
    barangay_list: [
      'ALUCAO WESTE (SAN LORENZO*)',
      'ANTIPORDA',
      'BALLANG',
      'BALZA',
      'CABARITAN',
      'CALAMEGATAN',
      'CENTRO (POB.)',
      'CENTRO WEST',
      'DALAYA',
      'FULA',
      'LERON',
      'MADDALERO',
      'MALA ESTE',
      'MALA WESTE',
      'MINANGA ESTE',
      'MINANGA WESTE',
      'PADDAYA ESTE',
      'PADDAYA WESTE',
      'PATTAO',
      'QUINAWEGAN',
      'REMEBELLA',
      'SAN ISIDRO',
      'SAN JUAN',
      'SAN VICENTE',
      'SANTA ISABEL',
      'SANTA MARIA',
      'TABBAC',
      'VILLA CIELO',
      'VILLA GRACIA',
      'VILLA LEONORA',
    ],
  },
  CALAYAN: {
    province: 'CAGAYAN',
    municipality: 'CALAYAN',
    region: 'REGION II',
    barangay_list: [
      'BABUYAN CLARO',
      'BALATUBAT',
      'CABUDADAN',
      'CENTRO II',
      'DADAO',
      'DALUPIRI',
      'DIBAY',
      'DILAM',
      'MAGSIDEL',
      'MINABEL',
      'NAGUILIAN',
      'POBLACION',
    ],
  },
  CAMALANIUGAN: {
    province: 'CAGAYAN',
    municipality: 'CAMALANIUGAN',
    region: 'REGION II',
    barangay_list: [
      'ABAGAO',
      'AFUNAN CABAYU',
      'AGUSI',
      'ALILINU',
      'BAGGAO',
      'BANTAY',
      'BULALA',
      'CASILI NORTE',
      'CASILI SUR',
      'CATOTORAN NORTE',
      'CATOTORAN SUR',
      'CENTRO NORTE (POB.)',
      'CENTRO SUR (POB.)',
      'CULLIT',
      'DACAL-LAFUGU',
      'DAMMANG NORTE (JOAQUIN DE LA CRUZ*)',
      'DAMMANG SUR (FELIPE TUZON*)',
      'DUGO',
      'FUSINA',
      'GANG-NGO',
      'JURISDICTION',
      'LUEC',
      'MINANGA',
      'PARAGAT',
      'SAPPING',
      'TAGUM',
      'TULUTTUGING',
      'ZIMINILA',
    ],
  },
  ENRILE: {
    province: 'CAGAYAN',
    municipality: 'ENRILE',
    region: 'REGION II',
    barangay_list: [
      'ALIBAGO (VILLA MARIA)',
      'BARANGAY I (POB.)',
      'BARANGAY II (POB.)',
      'BARANGAY III (SAN ROQUE*)',
      'BARANGAY III-A',
      'BARANGAY IV (POB.)',
      'BATU',
      'DIVISORIA',
      'INGA',
      'LANNA',
      'LEMU NORTE',
      'LEMU SUR',
      'LIWAN NORTE',
      'LIWAN SUR',
      'MADDARULUG NORTE',
      'MADDARULUG SUR',
      'MAGALALAG EAST',
      'MAGALALAG WEST (SAN NICOLAS*)',
      'MARACURU',
      'ROMA NORTE',
      'ROMA SUR',
      'SAN ANTONIO',
    ],
  },
  GATTARAN: {
    province: 'CAGAYAN',
    municipality: 'GATTARAN',
    region: 'REGION II',
    barangay_list: [
      'ABRA',
      'AGUIGUICAN',
      'BANGATAN NGAGAN',
      'BARACAOIT',
      'BARAOIDAN',
      'BARBARIT',
      'BASAO',
      'BOLOS POINT',
      'CABAYU',
      'CALAOAGAN BASSIT',
      'CALAOAGAN DACKEL',
      'CAPIDDIGAN',
      'CAPISSAYAN NORTE',
      'CAPISSAYAN SUR',
      'CASICALLAN NORTE',
      'CASICALLAN SUR',
      'CENTRO NORTE (POB.)',
      'CENTRO SUR (POB.)',
      'CULLIT',
      'CUMAO',
      'CUNIG',
      'DUMMUN',
      'FUGU',
      'GANZANO',
      'GUISING',
      'L. ADVIENTO',
      'LANGGAN',
      'LAPOGAN',
      'MABUNO',
      'NABACCAYAN',
      'NADDUNGAN',
      'NAGATUTUAN',
      'NASSIPING',
      'NEWAGAC',
      'PALAGAO NORTE',
      'PALAGAO SUR',
      'PIÑA ESTE',
      'PIÑA WESTE',
      'SAN CARLOS',
      'SAN VICENTE',
      'SANTA ANA',
      'SANTA MARIA',
      'SIDEM',
      'T. ELIZAGA (MABIRBIRA)',
      'TAGUMAY',
      'TAKIKI',
      'TALIGAN',
      'TANGLAGAN',
      'TUBUNGAN ESTE',
      'TUBUNGAN WESTE',
    ],
  },
  GONZAGA: {
    province: 'CAGAYAN',
    municipality: 'GONZAGA',
    region: 'REGION II',
    barangay_list: [
      'AMUNITAN',
      'BATANGAN',
      'BAUA',
      'CABANBANAN NORTE',
      'CABANBANAN SUR',
      'CABIRAOAN',
      'CALAYAN',
      'CALLAO',
      'CAROAN',
      'CASITAN',
      'FLOURISHING (POB.)',
      'IPIL',
      'ISCA',
      'MAGRAFIL',
      'MINANGA',
      'PARADISE (POB.)',
      'PATENG',
      'PROGRESSIVE (POB.)',
      'REBECCA (NAGBABALACAN*)',
      'SAN JOSE',
      'SANTA CLARA',
      'SANTA CRUZ',
      'SANTA MARIA',
      'SMART (POB.)',
      'TAPEL',
    ],
  },
  IGUIG: {
    province: 'CAGAYAN',
    municipality: 'IGUIG',
    region: 'REGION II',
    barangay_list: [
      'AJAT (POB.)',
      'ATULU',
      'BACULUD',
      'BAYO',
      'CAMPO',
      'DUMPAO',
      'GAMMAD',
      'GARAB',
      'MALABBAC',
      'MANAOAG (AQUILIQUILAO)',
      'MINANGA NORTE',
      'MINANGA SUR',
      'NATTANZAN (POB.)',
      'REDONDO',
      'SALAMAGUE',
      'SAN ESTEBAN (CAPITAN)',
      'SAN ISIDRO (UGAC WEST)',
      'SAN LORENZO',
      'SAN VICENTE (UGAC EAST)',
      'SANTA BARBARA',
      'SANTA ROSA',
      'SANTA TERESA (GAMMAD SUR)',
      'SANTIAGO',
    ],
  },
  'LAL-LO': {
    province: 'CAGAYAN',
    municipality: 'LAL-LO',
    region: 'REGION II',
    barangay_list: [
      'ABAGAO',
      'ALAGUIA',
      'BAGUMBAYAN',
      'BANGAG',
      'BICAL',
      'BICUD',
      'BINAG',
      'CABAYABASAN (CAPACUAN)',
      'CAGORAN',
      'CAMBONG',
      'CATAYAUAN',
      'CATUGAN',
      'CENTRO (POB.)',
      'CULLIT',
      'DAGUPAN',
      'DALAYA',
      'FABRICA',
      'FUSINA',
      'JURISDICTION',
      'LALAFUGAN',
      'LOGAC',
      'MAGAPIT',
      'MALANAO',
      'MAXINGAL',
      'NAGUILIAN',
      'PARANUM',
      'ROSARIO',
      'SAN ANTONIO (LAFU)',
      'SAN JOSE',
      'SAN JUAN',
      'SAN LORENZO',
      'SAN MARIANO',
      'SANTA MARIA',
      'SANTA TERESA (MAGALLUNGON)',
      'TUCALANA',
    ],
  },
  LASAM: {
    province: 'CAGAYAN',
    municipality: 'LASAM',
    region: 'REGION II',
    barangay_list: [
      'AGGUNETAN',
      'ALANNAY',
      'BATTALAN',
      'CABATACAN EAST (DULDUGAN*)',
      'CABATACAN WEST',
      'CALAPANGAN NORTE',
      'CALAPANGAN SUR',
      'CALLAO NORTE',
      'CALLAO SUR',
      'CATALIGANAN',
      'CENTRO I (POB.)',
      'CENTRO II (POB.)',
      'CENTRO III (POB.)',
      'FINUGO NORTE',
      'GABUN',
      'IGNACIO JURADO (FINUGU SUR)',
      'MAGSAYSAY',
      'MALINTA',
      'MINANGA NORTE',
      'MINANGA SUR',
      'NABANNAGAN EAST',
      'NABANNAGAN WEST',
      'NEW ORLINS',
      'NICOLAS AGATEP',
      'PERU',
      'SAN PEDRO',
      'SICALAO',
      'TAGAO',
      'TUCALAN PASSING',
      'VIGA',
    ],
  },
  PAMPLONA: {
    province: 'NEGROS ORIENTAL',
    municipality: 'PAMPLONA',
    region: 'REGION VII',
    barangay_list: [
      'ABANTE',
      'BALAYONG',
      'BANAWE',
      'CALICANAN',
      'DATAGON',
      'FATIMA',
      'INAWASAN',
      'MAGSUSUNOG',
      'MALALANGSI',
      'MAMBURAO',
      'MANGOTO',
      'POBLACION',
      'SAN ISIDRO',
      'SANTA AGUEDA',
      'SIMBORIO',
      'YUPISAN',
    ],
  },
  PEÑABLANCA: {
    province: 'CAGAYAN',
    municipality: 'PEÑABLANCA',
    region: 'REGION II',
    barangay_list: [
      'AGGUGADDAN',
      'ALIMANAO',
      'BALIUAG',
      'BICAL',
      'BUGATAY',
      'BUYUN',
      'CABASAN',
      'CABBO',
      'CALLAO',
      'CAMASI',
      'CENTRO (POB.)',
      'DODAN',
      'LAPI',
      'MALIBABAG',
      'MANGA',
      'MINANGA',
      'NABBABALAYAN',
      'NANGUILATTAN',
      'NANNARIAN',
      'PARABBA',
      'PATAGUELEG',
      'QUIBAL',
      'SAN ROQUE (LITTO)',
      'SISIM',
    ],
  },
  PIAT: {
    province: 'CAGAYAN',
    municipality: 'PIAT',
    region: 'REGION II',
    barangay_list: [
      'APAYAO',
      'AQUIB',
      'BAUNG',
      'CALAOAGAN',
      'CATARAUAN',
      'DUGAYUNG',
      'GUMARUENG',
      'MACAPIL',
      'MAGUILLING',
      'MINANGA',
      'POBLACION I',
      'POBLACION II',
      'SANTA BARBARA',
      'SANTO DOMINGO',
      'SICATNA',
      'VILLA REY (SAN GASPAR*)',
      'VILLA REYNO',
      'WARAT',
    ],
  },
  RIZAL: {
    province: 'ZAMBOANGA DEL NORTE',
    municipality: 'RIZAL',
    region: 'REGION IX',
    barangay_list: [
      'BALUBOHAN',
      'BIRAYAN',
      'DAMASING',
      'EAST POBLACION',
      'LA ESPERANZA',
      'MABUHAY',
      'MABUNAO',
      'MITIMOS',
      'NANGCA',
      'NANGCAAN',
      'NAPILAN',
      'NASIPANG',
      'NEW DAPITAN',
      'NILABO',
      'NORTH MAPANG',
      'RIZALINA',
      'SAN ROQUE',
      'SEBACA',
      'SIPAON',
      'SOUTH MAPANG',
      'TOLON',
      'WEST POBLACION',
    ],
  },
  'SANCHEZ-MIRA': {
    province: 'CAGAYAN',
    municipality: 'SANCHEZ-MIRA',
    region: 'REGION II',
    barangay_list: [
      'BANGAN',
      'CALLUNGAN',
      'CENTRO I (POB.)',
      'CENTRO II (POB.)',
      'DACAL',
      'DAGUERAY',
      'DAMMANG',
      'KITTAG',
      'LANGAGAN',
      'MAGACAN',
      'MARZAN',
      'MASISIT',
      'NAGRANGTAYAN',
      'NAMUAC',
      'SAN ANDRES',
      'SANTIAGO',
      'SANTOR',
      'TOKITOK',
    ],
  },
  'SANTA PRAXEDES': {
    province: 'CAGAYAN',
    municipality: 'SANTA PRAXEDES',
    region: 'REGION II',
    barangay_list: [
      'CADONGDONGAN',
      'CAPACUAN',
      'CENTRO I (POB.)',
      'CENTRO II (POB.)',
      'MACATEL',
      'PORTABAGA',
      'SALUNGSONG',
      'SAN JUAN',
      'SAN MIGUEL',
      'SICUL',
    ],
  },
  'SANTA TERESITA': {
    province: 'BATANGAS',
    municipality: 'SANTA TERESITA',
    region: 'REGION IV-A',
    barangay_list: [
      'ANTIPOLO',
      'BIHIS',
      'BUROL',
      'CALAYAAN',
      'CALUMALA',
      'CUTA EAST',
      'CUTANG CAWAYAN',
      'IRUKAN',
      'PACIFICO',
      'POBLACION I',
      'POBLACION II',
      'POBLACION III',
      'SAIMSIM',
      'SAMPA',
      'SINIPIAN',
      'TAMBO IBABA',
      'TAMBO ILAYA',
    ],
  },
  'SANTO NIÑO (FAIRE)': {
    province: 'CAGAYAN',
    municipality: 'SANTO NIÑO (FAIRE)',
    region: 'REGION II',
    barangay_list: [
      'ABARIONGAN RUAR',
      'ABARIONGAN UNEG',
      'BALAGAN',
      'BALANNI',
      'CABAYO',
      'CALAPANGAN',
      'CALASSITAN',
      'CAMPO',
      'CENTRO NORTE (POB.)',
      'CENTRO SUR (POB.)',
      'DUNGAO',
      'LATTAC',
      'LIPATAN',
      'LUBO',
      'MABITBITNONG',
      'MAPITAC',
      'MASICAL',
      'MATALAO',
      'NAG-UMA',
      'NAMUCCAYAN',
      'NIUG NORTE',
      'NIUG SUR',
      'PALUSAO',
      'SAN MANUEL',
      'SAN ROQUE',
      'SANTA FELICITAS',
      'SANTA MARIA',
      'SIDIRAN',
      'TABANG',
      'TAMUCCO',
      'VIRGINIA',
    ],
  },
  SOLANA: {
    province: 'CAGAYAN',
    municipality: 'SOLANA',
    region: 'REGION II',
    barangay_list: [
      'ANDARAYAN NORTH',
      'ANDARAYAN SOUTH',
      'BANGAG',
      'BANTAY',
      'BASI EAST',
      'BASI WEST',
      'BAUAN EAST',
      'BAUAN WEST',
      'CADAANAN',
      'CALAMAGUI',
      'CALILLAUAN',
      'CARILUCUD',
      'CATTARAN',
      'CENTRO NORTHEAST (POB.)',
      'CENTRO NORTHWEST (POB.)',
      'CENTRO SOUTHEAST (POB.)',
      'CENTRO SOUTHWEST (POB.)',
      'DASSUN',
      'FURAGUI',
      'GADU',
      'GEN. EULOGIO BALAO',
      'IRAGA',
      'LANNA',
      'LANNIG',
      'LINGU',
      'MADDARULUG (SANTO DOMINGO)',
      'MAGUIRIG',
      'MALALAM-MALACABIBI',
      'NABBOTUAN',
      'NANGALISAN',
      'NATAPPIAN EAST',
      'NATAPPIAN WEST',
      'PADUL',
      'PALAO',
      'PARUG-PARUG',
      'PATAYA',
      'SAMPAGUITA',
      'UBONG',
    ],
  },
  TUAO: {
    province: 'CAGAYAN',
    municipality: 'TUAO',
    region: 'REGION II',
    barangay_list: [
      'ACCUSILIAN',
      'ALABIAO',
      'ALABUG',
      'ANGANG',
      'BAGUMBAYAN',
      'BALAGAO',
      'BARANCUAG',
      'BATTUNG',
      'BICOK',
      'BUGNAY',
      'CAGUMITAN',
      'CATO',
      'CULONG',
      'DAGUPAN',
      'FUGU',
      'LAKAMBINI',
      'LALLAYUG',
      'MALALINTA',
      'MALUMIN',
      'MAMBACAG',
      'MUNGO',
      'NARUANGAN',
      'PALCA',
      'PATA',
      'POBLACION I (WARD I CENTRO)',
      'POBLACION II (WARD II CENTRO)',
      'SAN JUAN',
      'SAN LUIS (GURENGAD)',
      'SAN VICENTE (MALEG)',
      'SANTO TOMAS',
      'TARIBUBU',
      'VILLA LAIDA',
    ],
  },
  'TUGUEGARAO': {
    province: 'CAGAYAN',
    municipality: 'TUGUEGARAO',
    region: 'REGION II',
    barangay_list: [
      'ANNAFUNAN EAST',
      'ANNAFUNAN WEST',
      'ATULAYAN NORTE',
      'ATULAYAN SUR',
      'BAGAY',
      'BUNTUN',
      'CAGGAY',
      'CAPATAN',
      'CARIG',
      'CARITAN CENTRO',
      'CARITAN NORTE',
      'CARITAN SUR',
      'CATAGGAMAN NUEVO',
      'CATAGGAMAN PARDO',
      'CATAGGAMAN VIEJO',
      'CENTRO 1 (POB.)',
      'CENTRO 2 (POB.)',
      'CENTRO 3 (POB.)',
      'CENTRO 4 (POB.)',
      'CENTRO 5 (POB.)',
      'CENTRO 6 (POB.)',
      'CENTRO 7 (POB.)',
      'CENTRO 8 (POB.)',
      'CENTRO 9 (POB.)',
      'CENTRO 10 (POB.)',
      'CENTRO 11 (POB.)',
      'CENTRO 12 (POB.)',
      'DADDA',
      'GOSI NORTE',
      'GOSI SUR',
      'LARION ALTO',
      'LARION BAJO',
      'LEONARDA',
      'LIBAG NORTE',
      'LIBAG SUR',
      'LINAO EAST',
      'LINAO NORTE',
      'LINAO WEST',
      'NAMBBALAN NORTE',
      'NAMBBALAN SUR',
      'PALLUA NORTE',
      'PALLUA SUR',
      'PENGUE (PENGUE-RUYU)',
      'REYES',
      'SAN GABRIEL',
      'TAGGA',
      'TANZA',
      'UGAC NORTE',
      'UGAC SUR',
    ],
  },
  ALICIA: {
    province: 'ZAMBOANGA SIBUGAY',
    municipality: 'ALICIA',
    region: 'REGION IX',
    barangay_list: [
      'ALEGRIA',
      'BAGONG BUHAY',
      'BELLA',
      'CALADES',
      'CONCEPCION',
      'DAWA-DAWA',
      'GULAYON',
      'ILISAN',
      'KAPATAGAN',
      'KAUSWAGAN',
      'KAWAYAN',
      'LA PAZ',
      'LAMBUYOGAN',
      'LAPIRAWAN',
      'LITAYON',
      'LUTIMAN',
      'MILAGROSA (BALUNO)',
      'NAGA-NAGA',
      'PANDAN-PANDAN',
      'PAYONGAN',
      'POBLACION',
      'SANTA MARIA',
      'SANTO NIÑO',
      'TALAPTAP',
      'TAMPALAN',
      'TANDIONG MUSLIM',
      'TIMBANG-TIMBANG',
    ],
  },
  ANGADANAN: {
    province: 'ISABELA',
    municipality: 'ANGADANAN',
    region: 'REGION II',
    barangay_list: [
      'ALLANGIGAN',
      'ANIOG',
      'BANIKET',
      'BANNAWAG',
      'BANTUG',
      'BARANGCUAG',
      'BAUI',
      'BONIFACIO',
      'BUENAVISTA',
      'BUNNAY',
      'CALABAYAN-MINANGA',
      'CALACCAB',
      'CALAOCAN',
      'CAMPANARIO',
      'CANANGAN',
      'CENTRO I (POB.)',
      'CENTRO II (POB.)',
      'CENTRO III (POB.)',
      'CONSULAR',
      'CUMU',
      'DALAKIP',
      'DALENAT',
      'DIPALUDA',
      'DUROC',
      'ESPERANZA',
      'FUGARU',
      'INGUD NORTE',
      'INGUD SUR',
      'KALUSUTAN',
      'LA SUERTE',
      'LIWLIWA',
      'LOMBOY',
      'LORIA',
      'LOURDES (EL ESCAÑO)',
      'MABUHAY',
      'MACALAUAT',
      'MACANIAO',
      'MALANNAO',
      'MALASIN',
      'MANGANDINGAY',
      'MINANGA PROPER',
      'PAPPAT',
      'PISSAY',
      'RAMONA',
      'RANCHO BASSIT',
      'RANG-AYAN',
      'SALAY',
      'SAN AMBROCIO',
      'SAN GUILLERMO',
      'SAN ISIDRO',
      'SAN MARCELO',
      'SAN ROQUE',
      'SAN VICENTE',
      'SANTO NIÑO',
      'SARANAY',
      'SINABBARAN',
      'VICTORY',
      'VIGA',
      'VILLA DOMINGO',
    ],
  },
  AURORA: {
    province: 'ZAMBOANGA DEL SUR',
    municipality: 'AURORA',
    region: 'REGION IX',
    barangay_list: [
      'ACAD',
      'ALANG-ALANG',
      'ALEGRIA',
      'ANONANG',
      'BAGONG MANDAUE',
      'BAGONG MASLOG',
      'BAGONG OSLOB',
      'BAGONG PITOGO',
      'BAKI',
      'BALAS',
      'BALIDE',
      'BALINTAWAK',
      'BAYABAS',
      'BEMPOSA',
      'CABILINAN',
      'CAMPO UNO',
      'CEBONEG',
      'COMMONWEALTH',
      'GUBAAN',
      'INASAGAN',
      'INROAD',
      'KAHAYAGAN EAST (KATIPUNAN)',
      'KAHAYAGAN WEST',
      'KAUSWAGAN',
      'LA PAZ (TINIBTIBAN)',
      'LA VICTORIA',
      'LANTUNGAN',
      'LIBERTAD',
      'LINTUGOP',
      'LUBID',
      'MAGUIKAY',
      'MAHAYAHAY',
      'MONTE ALEGRE',
      'MONTELA',
      'NAPO',
      'PANAGHIUSA',
      'POBLACION',
      'RESTHOUSE',
      'ROMARATE',
      'SAN JOSE',
      'SAN JUAN',
      'SAPA LOBOC',
      'TAGULALO',
      'WATERFALL',
    ],
  },
  'BENITO SOLIVEN': {
    province: 'ISABELA',
    municipality: 'BENITO SOLIVEN',
    region: 'REGION II',
    barangay_list: [
      'ANDABUEN',
      'ARA',
      'BALLIAO',
      'BINOGTUNGAN',
      'CAPUSERAN (CAPUROCAN)',
      'DAGUPAN',
      'DANIPA',
      'DISTRICT I (POB.)',
      'DISTRICT II (POB.)',
      'GOMEZ',
      'GUILINGAN',
      'LA SALETTE',
      'LUCBAN',
      'MAKINDOL',
      'MALUNO NORTE',
      'MALUNO SUR',
      'NACALMA',
      'NEW MAGSAYSAY',
      'PLACER',
      'PUNIT',
      'SAN CARLOS',
      'SAN FRANCISCO',
      'SANTA CRUZ',
      'SANTIAGO',
      'SEVILLANA',
      'SINIPIT',
      'VILLALUZ',
      'YEBAN NORTE',
      'YEBAN SUR',
    ],
  },
  CABAGAN: {
    province: 'ISABELA',
    municipality: 'CABAGAN',
    region: 'REGION II',
    barangay_list: [
      'AGGUB',
      'ANAO',
      'ANGANCASILIAN',
      'BALASIG',
      'CANSAN',
      'CASIBARAG NORTE',
      'CASIBARAG SUR',
      'CATABAYUNGAN',
      'CENTRO (POB.)',
      'CUBAG',
      'GARITA',
      'LUQUILU',
      'MABANGUG',
      'MAGASSI',
      'MASIPI EAST',
      'MASIPI WEST',
      'NGARAG',
      'PILIG ABAJO',
      'PILIG ALTO',
      'SAN ANTONIO',
      'SAN BERNARDO',
      'SAN JUAN',
      'SAUI',
      'TALLAG',
      'UGAD',
      'UNION',
    ],
  },
  CABATUAN: {
    province: 'ILOILO',
    municipality: 'CABATUAN',
    region: 'REGION VI',
    barangay_list: [
      'ACAO',
      'AMERANG',
      'AMURAO',
      'ANUANG',
      'AYAMAN',
      'AYONG',
      'BACAN',
      'BALABAG',
      'BALUYAN',
      'BANGUIT',
      'BULAY',
      'CADOLDOLAN',
      'CAGBAN',
      'CALAWAGAN',
      'CALAYO',
      'DUYANDUYAN',
      'GAUB',
      'GINES INTERIOR',
      'GINES PATAG',
      'GUIBUANGAN TIGBAUAN',
      'INABASAN',
      'INACA',
      'INALADAN',
      'INGAS',
      'ITO NORTE',
      'ITO SUR',
      'JANIPAAN CENTRAL',
      'JANIPAAN ESTE',
      'JANIPAAN OESTE',
      'JANIPAAN OLO',
      'JELICUON LUSAYA',
      'JELICUON MONTINOLA',
      'LAG-AN',
      'LEONG',
      'LUTAC',
      'MANGUNA',
      'MARAGUIT',
      'MORUBUAN',
      'PACATIN',
      'PAGOTPOT',
      'PAMUL-OGAN',
      'PAMURINGAO GARRIDO',
      'PAMURINGAO PROPER',
      'PUNGTOD',
      'PUYAS',
      'SALACAY',
      'SULANGA',
      'TABUCAN',
      'TACDANGAN',
      'TALANGHAUAN',
      'TIGBAUAN ROAD',
      'TINIO-AN',
      'TIRING',
      'TUPOL CENTRAL',
      'TUPOL ESTE',
      'TUPOL OESTE',
      'TUY-AN',
      'ZONE I POB. (BARANGAY 1)',
      'ZONE II POB. (BARANGAY 2)',
      'ZONE III POB. (BARANGAY 3)',
      'ZONE IV POB. (BARANGAY 4)',
      'ZONE IX POB. (BARANGAY 9)',
      'ZONE V POB. (BARANGAY 5)',
      'ZONE VI POB. (BARANGAY 6 )',
      'ZONE VII POB. (BARANGAY 7)',
      'ZONE VIII POB. (BARANGAY 8)',
      'ZONE X POB. (BARANGAY 10)',
      'ZONE XI POB. (BARANGAY 11)',
    ],
  },
  'CAUAYAN': {
    province: 'ISABELA',
    municipality: 'CAUAYAN',
    region: 'REGION II',
    barangay_list: [
      'ALICAOCAO',
      'ALINAM',
      'AMOBOCAN',
      'ANDARAYAN',
      'BACULOD',
      'BARINGIN NORTE',
      'BARINGIN SUR',
      'BUENA SUERTE',
      'BUGALLON',
      'BUYON',
      'CABARUAN',
      'CABUGAO',
      'CARABATAN BACARENO',
      'CARABATAN CHICA',
      'CARABATAN GRANDE',
      'CARABATAN PUNTA',
      'CASALATAN',
      'CASSAP FUERA',
      'CATALINA',
      'CULALABAT',
      'DABBURAB',
      'DE VERA',
      'DIANAO',
      'DISIMURAY',
      'DISTRICT I (POB.)',
      'DISTRICT II (POB.)',
      'DISTRICT III (POB.)',
      'DUMINIT',
      'FAUSTINO (SIPAY)',
      'GAGABUTAN',
      'GAPPAL',
      'GUAYABAL',
      'LABINAB',
      'LINGLINGAY',
      'MABANTAD',
      'MALIGAYA',
      'MANAOAG',
      'MARABULIG I',
      'MARABULIG II',
      'MINANTE I',
      'MINANTE II',
      'NAGANACAN',
      'NAGCAMPEGAN',
      'NAGRUMBUAN',
      'NUNGNUNGAN I',
      'NUNGNUNGAN II',
      'PINOMA',
      'RIZAL',
      'ROGUS',
      'SAN ANTONIO',
      'SAN FERMIN',
      'SAN FRANCISCO',
      'SAN ISIDRO',
      'SAN LUIS',
      'SAN PABLO (CASAP HACIENDA)',
      'SANTA LUCIANA (DABURAB 2)',
      'SANTA MARIA',
      'SILLAWIT',
      'SINIPPIL',
      'TAGARAN',
      'TURAYONG',
      'UNION',
      'VILLA CONCEPCION',
      'VILLA LUNA',
      'VILLAFLOR',
    ],
  },
  'SANTIAGO': {
    province: 'ISABELA',
    municipality: 'SANTIAGO',
    region: 'REGION II',
    barangay_list: [
      'ABRA',
      'AMBALATUNGAN',
      'BALINTOCATOC',
      'BALUARTE',
      'BANNAWAG NORTE',
      'BATAL',
      'BUENAVISTA',
      'CABULAY',
      'CALAO EAST (POB.)',
      'CALAO WEST (POB.)',
      'CALAOCAN',
      'CENTRO EAST (POB.)',
      'CENTRO WEST (POB.)',
      'DIVISORIA',
      'DUBINAN EAST',
      'DUBINAN WEST',
      'LUNA',
      'MABINI',
      'MALVAR',
      'NABBUAN',
      'NAGGASICAN',
      'PATUL',
      'PLARIDEL',
      'RIZAL',
      'ROSARIO',
      'SAGANA',
      'SALVADOR',
      'SAN ANDRES',
      'SAN ISIDRO',
      'SAN JOSE',
      'SANTA ROSA',
      'SINILI',
      'SINSAYON',
      'VICTORY NORTE',
      'VICTORY SUR',
      'VILLA GONZAGA',
      'VILLASIS',
    ],
  },
  CORDON: {
    province: 'ISABELA',
    municipality: 'CORDON',
    region: 'REGION II',
    barangay_list: [
      'AGUINALDO (RIZALUNA ESTE)',
      'ANONANG (BALITOC*)',
      'CALIMATUROD',
      'CAMARAO',
      'CAPIRPIRIWAN',
      'CAQUILINGAN (SAN LUIS)',
      'DALLAO',
      'GAYONG',
      'LAUREL (CENTRO NORTE)',
      'MAGSAYSAY (CENTRO SUR OESTE)',
      'MALAPAT',
      'OSMENA (CENTRO SUR ESTE)',
      'QUEZON (CENTRO NORTE ESTE)',
      'QUIRINO (MANASIN)',
      'RIZALUNA (RIZALUNA OESTE)',
      'ROXAS POB. (CENTRO SUR)',
      'SAGAT',
      'SAN JUAN (SAN JUAN ESTE)',
      'TALIKTIK',
      'TANGGAL',
      'TARINSING',
      'TUROD NORTE',
      'TUROD SUR',
      'VILLAMARZO',
      'VILLAMIEMBAN',
      'WIGAN',
    ],
  },
  'DELFIN ALBANO (MAGSAYSAY)': {
    province: 'ISABELA',
    municipality: 'DELFIN ALBANO (MAGSAYSAY)',
    region: 'REGION II',
    barangay_list: [
      'AGA',
      'ANDARAYAN',
      'ANEG',
      'BAYABO',
      'CALINAOAN SUR',
      'CALOOCAN',
      'CAPITOL',
      'CARMENCITA',
      'CONCEPCION',
      'MAUI',
      'QUIBAL',
      'RAGAN ALMACEN',
      'RAGAN NORTE',
      'RAGAN SUR (POB.)',
      'RIZAL (RAGAN ALMACEN ALTO)',
      'SAN ANDRES',
      'SAN ANTONIO',
      'SAN ISIDRO',
      'SAN JOSE',
      'SAN JUAN',
      'SAN MACARIO',
      'SAN NICOLAS (FUSI)',
      'SAN PATRICIO',
      'SAN ROQUE',
      'SANTO ROSARIO',
      'SANTOR',
      'VILLA LUZ',
      'VILLA PEREDA',
      'VISITACION',
    ],
  },
  DINAPIGUE: {
    province: 'ISABELA',
    municipality: 'DINAPIGUE',
    region: 'REGION II',
    barangay_list: [
      'AYOD',
      'BUCAL NORTE',
      'BUCAL SUR',
      'DIBULO',
      'DIGUMASED (POB.)',
      'DIMALUADE',
    ],
  },
  DIVILACAN: {
    province: 'ISABELA',
    municipality: 'DIVILACAN',
    region: 'REGION II',
    barangay_list: [
      'BICOBIAN',
      'DIBULOS',
      'DICAMBANGAN',
      'DICAROYAN',
      'DICATIAN',
      'DILAKIT',
      'DIMAPNAT',
      'DIMAPULA (POB.)',
      'DIMASALANSAN',
      'DIPUDO',
      'DITARUM',
      'SAPINIT',
    ],
  },
  ECHAGUE: {
    province: 'ISABELA',
    municipality: 'ECHAGUE',
    region: 'REGION II',
    barangay_list: [
      'ANGOLUAN',
      'ANNAFUNAN',
      'ARABIAT',
      'AROMIN',
      'BABARAN',
      'BACRADAL',
      'BENGUET',
      'BUNEG',
      'BUSILELAO',
      'CABUGAO (POB.)',
      'CANIGUING',
      'CARULAY',
      'CASTILLO',
      'DAMMANG EAST',
      'DAMMANG WEST',
      'DIASAN',
      'DICARAOYAN',
      'DUGAYONG',
      'FUGU',
      'GARIT NORTE',
      'GARIT SUR',
      'GUCAB',
      'GUMBAUAN',
      'IPIL',
      'LIBERTAD',
      'MABBAYAD',
      'MABUHAY',
      'MADADAMIAN',
      'MAGLETICIA',
      'MALIBAGO',
      'MALIGAYA',
      'MALITAO',
      'NARRA',
      'NILUMISU',
      'PAG-ASA',
      'PANGAL NORTE',
      'PANGAL SUR',
      'RUMANG-AY',
      'SALAY',
      'SALVACION',
      'SAN ANTONIO MINIT',
      'SAN ANTONIO UGAD',
      'SAN CARLOS',
      'SAN FABIAN',
      'SAN FELIPE',
      'SAN JUAN',
      'SAN MANUEL',
      'SAN MIGUEL',
      'SAN SALVADOR',
      'SANTA ANA',
      'SANTA CRUZ',
      'SANTA MARIA',
      'SANTA MONICA',
      'SANTO DOMINGO',
      'SILAUAN NORTE (POB.)',
      'SILAUAN SUR (POB.)',
      'SINABBARAN',
      'SOYUNG',
      'TAGGAPPAN',
      'TUGUEGARAO',
      'VILLA CAMPO',
      'VILLA FERMIN',
      'VILLA REY',
      'VILLA VICTORIA',
    ],
  },
  GAMU: {
    province: 'ISABELA',
    municipality: 'GAMU',
    region: 'REGION II',
    barangay_list: [
      'BARCOLAN',
      'BUENAVISTA',
      'DAMMAO',
      'DISTRICT I (POB.)',
      'DISTRICT II (POB.)',
      'DISTRICT III (POB.)',
      'FURAO',
      'GUIBANG',
      'LENZON',
      'LINGLINGAY',
      'MABINI',
      'PINTOR',
      'RIZAL',
      'SONGSONG',
      'UNION',
      'UPI',
    ],
  },
  'ILAGAN': {
    province: 'ISABELA',
    municipality: 'ILAGAN',
    region: 'REGION II',
    barangay_list: [
      'AGGASIAN',
      'ALIBAGU',
      'ALLINGUIGAN 1ST',
      'ALLINGUIGAN 2ND',
      'ALLINGUIGAN 3RD',
      'ARUSIP',
      'BACULOD (POB.)',
      'BAGONG SILANG',
      'BAGUMBAYAN (POB.)',
      'BALIGATAN',
      'BALLACONG',
      'BANGAG',
      'BATONG-LABANG',
      'BIGAO',
      'CABANNUNGAN 1ST',
      'CABANNUNGAN 2ND',
      'CABESERIA 2 (DAPPAT)',
      'CABESERIA 3 (SAN FERNANDO)',
      'CABESERIA 4 (SAN MANUEL)',
      'CABESERIA 5 (BARIBAD)',
      'CABESERIA 6 AND 24 (VILLA MARCOS*)',
      'CABESERIA 7 (NANGALISAN)',
      'CABESERIA 9 AND 11 (CAPOGOTAN)',
      'CABESERIA 10 (LAPIGUI)',
      'CABESERIA 14 AND 16 (CASILAGAN)',
      'CABESERIA 17 AND 21 (SAN RAFAEL)',
      'CABESERIA 19 (VILLA SUERTE*)',
      'CABESERIA 22 (SABLANG)',
      'CABESERIA 23 (SAN FRANCISCO*)',
      'CABESERIA 25 (STA. LUCIA*)',
      'CABESERIA 27 (ABUAN)',
      'CADU',
      'CALAMAGUI 1ST',
      'CALAMAGUI 2ND',
      'CAMUNATAN',
      'CAPELLAN',
      'CAPO',
      'CARIKKIKAN NORTE',
      'CARIKKIKAN SUR',
      'CENTRO - SAN ANTONIO',
      'CENTRO POBLACION',
      'FUGU',
      'FUYO',
      'GAYONG-GAYONG NORTE',
      'GAYONG-GAYONG SUR',
      'GUINATAN',
      'IMELDA BLISS VILLAGE',
      'LULLUTAN',
      'MALALAM',
      'MALASIN (ANGELES)',
      'MANARING',
      'MANGCURAM',
      'MARANA I',
      'MARANA II',
      'MARANA III',
      'MINABANG',
      'MORADO',
      'NAGUILIAN NORTE',
      'NAGUILIAN SUR',
      'NAMNAMA',
      'NANAGUAN',
      'OSMEÑA (SINIPPIL)',
      'PALIUEG',
      'PASA',
      'PILAR',
      'QUIMALABASA',
      'RANG-AYAN (BINTACAN)',
      'RUGAO',
      'SALINDINGAN',
      'SAN ANDRES (ANGARILLA)',
      'SAN FELIPE',
      'SAN IGNACIO (CANAPI)',
      'SAN ISIDRO',
      'SAN JUAN',
      'SAN LORENZO',
      'SAN PABLO',
      'SAN RODRIGO',
      'SAN VICENTE (POB.)',
      'SANTA BARBARA (POB.)',
      'SANTA CATALINA',
      'SANTA ISABEL NORTE',
      'SANTA ISABEL SUR',
      'SANTA MARIA (CABESERIA 8)',
      'SANTA VICTORIA',
      'SANTO TOMAS',
      'SIFFU',
      'SINDON BAYABO',
      'SINDON MARIDE',
      'SIPAY',
      'TANGCUL',
      'VILLA IMELDA (MAPLAS)',
    ],
  },
  JONES: {
    province: 'ISABELA',
    municipality: 'JONES',
    region: 'REGION II',
    barangay_list: [
      'ABULAN',
      'ADDALAM',
      'ARUBUB',
      'BANNAWAG',
      'BANTAY',
      'BARANGAY I (POB.)',
      'BARANGAY II (POB.)',
      'BARANGCUAG',
      'DALIBUBON',
      'DALIGAN',
      'DIARAO',
      'DIBULUAN',
      'DICAMAY I',
      'DICAMAY II',
      'DIPANGIT',
      'DISIMPIT',
      'DIVINAN',
      'DUMAWING',
      'FUGU',
      'LACAB',
      'LINAMANAN',
      'LINOMOT',
      'MALANNIT',
      'MINURI',
      'NAMNAMA',
      'NAPALIONG',
      'PALAGAO',
      'PAPAN ESTE',
      'PAPAN WESTE',
      'PAYAC',
      'PONGPONGAN',
      'SAN ANTONIO',
      'SAN ISIDRO',
      'SAN JOSE',
      'SAN ROQUE',
      'SAN SEBASTIAN',
      'SAN VICENTE',
      'SANTA ISABEL',
      'SANTO DOMINGO',
      'TUPAX',
      'USOL',
      'VILLA BELLO',
    ],
  },
  MACONACON: {
    province: 'ISABELA',
    municipality: 'MACONACON',
    region: 'REGION II',
    barangay_list: [
      'APLAYA',
      'CANADAM',
      'DIANA',
      'ELEONOR (POB.)',
      'FELY (POB.)',
      'LITA (POB.)',
      'MALASIN',
      'MINANGA',
      'REINA MERCEDES',
      'SANTA MARINA (DIANGGO)',
    ],
  },
  MALLIG: {
    province: 'ISABELA',
    municipality: 'MALLIG',
    region: 'REGION II',
    barangay_list: [
      'BINMONTON',
      'CASILI',
      'CENTRO I (POB.)',
      'CENTRO II (POB.)',
      'HOLY FRIDAY',
      'MALIGAYA',
      'MANANO',
      'OLANGO',
      'RANG-AYAN',
      'SAN JOSE NORTE I',
      'SAN JOSE NORTE II',
      'SAN JOSE SUR',
      'SAN PEDRO (BARUCBUC SUR)',
      'SAN RAMON',
      'SIEMPRE VIVA NORTE',
      'SIEMPRE VIVA SUR',
      'TRINIDAD',
      'VICTORIA',
    ],
  },
  PALANAN: {
    province: 'ISABELA',
    municipality: 'PALANAN',
    region: 'REGION II',
    barangay_list: [
      'ALOMANAY',
      'BISAG',
      'CULASI',
      'DIALAOYAO',
      'DICABISAGAN EAST (POB.)',
      'DICABISAGAN WEST (POB.)',
      'DICADYUAN',
      'DIDDADUNGAN',
      'DIDIYAN',
      'DIMALICU-LICU',
      'DIMASARI',
      'DIMATICAN',
      'MALIGAYA',
      'MARIKIT',
      'SAN ISIDRO',
      'SANTA JACINTA',
      'VILLA ROBLES',
    ],
  },
  QUIRINO: {
    province: 'ISABELA',
    municipality: 'QUIRINO',
    region: 'REGION II',
    barangay_list: [
      'BINARZANG',
      'CABARUAN',
      'CAMAAL',
      'DOLORES',
      'LUNA',
      'MANAOAG',
      'RIZAL',
      'SAN ISIDRO',
      'SAN JOSE',
      'SAN JUAN',
      'SAN MATEO',
      'SAN VICENTE',
      'SANTA CATALINA',
      'SANTA LUCIA (POB.)',
      'SANTIAGO',
      'SANTO DOMINGO',
      'SINAIT',
      'SUERTE',
      'VILLA BULUSAN',
      'VILLA MIGUEL (UGAK)',
      'VINTAR',
    ],
  },
  RAMON: {
    province: 'ISABELA',
    municipality: 'RAMON',
    region: 'REGION II',
    barangay_list: [
      'AMBATALI',
      'BANTUG',
      'BUGALLON NORTE',
      'BUGALLON PROPER (POB.)',
      'BURGOS',
      'GENERAL AGUINALDO',
      'NAGBACALAN',
      'OSCARIZ',
      'PABIL',
      'PAGRANG-AYAN',
      'PLANAS',
      'PUROK NI BULAN',
      'RANIAG',
      'SAN ANTONIO',
      'SAN MIGUEL',
      'SAN SEBASTIAN',
      'VILLA BELTRAN',
      'VILLA CARMEN',
      'VILLA MARCOS',
    ],
  },
  'REINA MERCEDES': {
    province: 'ISABELA',
    municipality: 'REINA MERCEDES',
    region: 'REGION II',
    barangay_list: [
      'BANQUERO',
      'BINARSANG',
      'CUTOG GRANDE',
      'CUTOG PEQUEÑO',
      'DANGAN',
      'DISTRICT I (POB.)',
      'DISTRICT II (POB.)',
      'LABINAB GRANDE (POB.)',
      'LABINAB PEQUEÑO (POB.)',
      'MALLALATANG GRANDE',
      'MALLALATANG TUNGGUI',
      'NAPACCU GRANDE',
      'NAPACCU PEQUEÑO',
      'SALUCONG',
      'SANTIAGO',
      'SANTOR',
      'SINIPPIL',
      'TALLUNGAN (POB.)',
      'TUROD',
      'VILLADOR',
    ],
  },
  ROXAS: {
    province: 'PALAWAN',
    municipality: 'ROXAS',
    region: 'REGION IV-B',
    barangay_list: [
      'ABAROAN',
      'ANTONINO',
      'BAGONG BAYAN',
      'BARANGAY 1 (POB.)',
      'BARANGAY II (POB.)',
      'BARANGAY III (POB.)',
      'BARANGAY IV (POB.)',
      'BARANGAY V POB. (PORAO ISLAND)',
      'BARANGAY VI POB. (JOHNSON ISLAND)',
      'CARAMAY',
      'DUMARAO',
      'IRAAN',
      'JOLO',
      'MAGARA (ARASAN)',
      'MALCAMPO',
      'MENDOZA',
      'NARRA (MINARA)',
      'NEW BARBACAN (RETAC)',
      'NEW CUYO',
      'NICANOR ZABALA',
      'RIZAL',
      'SALVACION',
      'SAN ISIDRO',
      'SAN JOSE',
      'SAN MIGUEL',
      'SAN NICOLAS',
      'SANDOVAL',
      'TAGUMPAY',
      'TARADUNGAN',
      'TINITIAN',
      'TUMARBONG',
    ],
  },
  'SAN GUILLERMO': {
    province: 'ISABELA',
    municipality: 'SAN GUILLERMO',
    region: 'REGION II',
    barangay_list: [
      'ANONANG',
      'ARINGAY',
      'BURGOS',
      'CALAOAGAN',
      'CENTRO 1 (POB.)',
      'CENTRO 2 (POB.)',
      'COLORADO',
      'DIETBAN',
      'DINGADING',
      'DIPACAMO',
      'ESTRELLA',
      'GUAM',
      'NAKAR',
      'PALAWAN',
      'PROGRESO',
      'RIZAL',
      'SAN FRANCISCO NORTE',
      'SAN FRANCISCO SUR',
      'SAN MARIANO NORTE',
      'SAN MARIANO SUR',
      'SAN RAFAEL',
      'SINALUGAN',
      'VILLA REMEDIOS',
      'VILLA ROSE',
      'VILLA SANCHEZ',
      'VILLA TERESITA',
    ],
  },
  'SAN MARIANO': {
    province: 'ISABELA',
    municipality: 'SAN MARIANO',
    region: 'REGION II',
    barangay_list: [
      'ALIBADABAD',
      'BALAGAN',
      'BINATUG',
      'BITABIAN',
      'BUYASAN',
      'CADSALAN',
      'CASALA',
      'CATAGUING',
      'DARAGUTAN EAST',
      'DARAGUTAN WEST',
      'DEL PILAR',
      'DIBULUAN',
      'DICAMAY',
      'DIPUSU',
      'DISULAP',
      'DISUSUAN',
      'GANGALAN',
      'IBUJAN',
      'LIBERTAD',
      'MACAYUCAYU',
      'MALLABO',
      'MARANNAO',
      'MINANGA',
      'OLD SAN MARIANO',
      'PALUTAN',
      'PANNINAN',
      'SAN JOSE',
      'SAN PABLO',
      'SAN PEDRO',
      'SANTA FILOMINA',
      'TAPPA',
      'UEG',
      'ZAMORA',
      'ZONE I (POB.)',
      'ZONE II (POB.)',
      'ZONE III (POB.)',
    ],
  },
  'SAN MATEO': {
    province: 'RIZAL',
    municipality: 'SAN MATEO',
    region: 'REGION IV-A',
    barangay_list: [
      'AMPID I',
      'AMPID II',
      'BANABA',
      'DULONG BAYAN 1',
      'DULONG BAYAN 2',
      'GUINAYANG',
      'GUITNANG BAYAN I (POB.)',
      'GUITNANG BAYAN II (POB.)',
      'GULOD MALAYA',
      'MALANDAY',
      'MALY',
      'PINTONG BOCAWE',
      'SANTA ANA',
      'SANTO NIÑO',
      'SILANGAN',
    ],
  },
  'SAN PABLO': {
    province: 'ZAMBOANGA DEL SUR',
    municipality: 'SAN PABLO',
    region: 'REGION IX',
    barangay_list: [
      'BAG-ONG MISAMIS',
      'BUBUAL',
      'BUTON',
      'CULASIAN',
      'DAPLAYAN',
      'KALILANGAN',
      'KAPAMANOK',
      'KONDUM',
      'LUMBAYAO',
      'MABUHAY',
      'MARCOS VILLAGE',
      'MIASIN',
      'MOLANSONG',
      'PANTAD',
      'PAO',
      'PAYAG',
      'POBLACION (SAN PABLO)',
      'PONGAPONG',
      'SACBULAN',
      'SAGASAN',
      'SAN JUAN',
      'SENIOR',
      'SONGGOY',
      'TANDUBUAY',
      'TANIAPAN',
      'TICALA ISLAND',
      'TUBO-PAIT',
      'VILLAKAPA',
    ],
  },
  TUMAUINI: {
    province: 'ISABELA',
    municipality: 'TUMAUINI',
    region: 'REGION II',
    barangay_list: [
      'ANNAFUNAN',
      'ANTAGAN I',
      'ANTAGAN II',
      'ARCON',
      'BALUG',
      'BANIG',
      'BANTUG',
      'BARANGAY DISTRICT 1 (POB.)',
      'BARANGAY DISTRICT 2 (POB.)',
      'BARANGAY DISTRICT 3 (POB.)',
      'BARANGAY DISTRICT 4 (POB.)',
      'BAYABO EAST',
      'CALIGAYAN',
      'CAMASI',
      'CARPENTERO',
      'COMPANIA',
      'CUMABAO',
      'FERMELDY (HCDA. SAN FRANCISCO)',
      'FUGU ABAJO',
      'FUGU NORTE',
      'FUGU SUR',
      'LALAUANAN',
      'LANNA',
      'LAPOGAN',
      'LINGALING',
      'LIWANAG',
      'MALAMAG EAST',
      'MALAMAG WEST',
      'MALIGAYA',
      'MINANGA',
      'MOLDERO',
      'NAMNAMA',
      'PARAGU',
      'PILITAN',
      'SAN MATEO',
      'SAN PEDRO',
      'SAN VICENTE',
      'SANTA',
      'SANTA CATALINA',
      'SANTA VISITACION (MAGGAYU)',
      'SANTO NIÑO',
      'SINIPPIL',
      'SISIM ABAJO',
      'SISIM ALTO',
      'TUNGGUI',
      'UGAD',
    ],
  },
  'ALFONSO CASTANEDA': {
    province: 'NUEVA VIZCAYA',
    municipality: 'ALFONSO CASTANEDA',
    region: 'REGION II',
    barangay_list: [
      'ABUYO',
      'CAUAYAN',
      'GALINTUJA',
      'LIPUGA',
      'LUBLUB (POB.)',
      'PELAWAY',
    ],
  },
  AMBAGUIO: {
    province: 'NUEVA VIZCAYA',
    municipality: 'AMBAGUIO',
    region: 'REGION II',
    barangay_list: [
      'AMMUEG',
      'CAMANDAG',
      'DULLI',
      'LABANG',
      'NAPO',
      'POBLACION',
      'SALINGSINGAN',
      'TIBLAC',
    ],
  },
  ARITAO: {
    province: 'NUEVA VIZCAYA',
    municipality: 'ARITAO',
    region: 'REGION II',
    barangay_list: [
      'ANAYO',
      'BAAN',
      'BALITE',
      'BANGANAN',
      'BETI',
      'BONE NORTH',
      'BONE SOUTH',
      'CALITLITAN',
      'CANABUAN',
      'CANAREM',
      'COMON',
      'CUTAR',
      'DARAPIDAP',
      'KIRANG',
      'LATAR-NOCNOC-SAN FRANCISCO',
      'NAGCUARTELAN',
      'OCAO-CAPINIAAN',
      'POBLACION',
      'SANTA CLARA',
      'TABUENG',
      'TUCANON',
      'YAWAY',
    ],
  },
  BAGABAG: {
    province: 'NUEVA VIZCAYA',
    municipality: 'BAGABAG',
    region: 'REGION II',
    barangay_list: [
      'BAKIR',
      'BARETBET',
      'CAREB',
      'LANTAP',
      'MURONG',
      'NANGALISAN',
      'PANIKI',
      'POGONSINO',
      'QUIRINO (POB.)',
      'SAN GERONIMO (POB.)',
      'SAN PEDRO (POB.)',
      'SANTA CRUZ',
      'SANTA LUCIA',
      'TUAO NORTH',
      'TUAO SOUTH',
      'VILLA COLOMA (POB.)',
      'VILLAROS',
    ],
  },
  BAMBANG: {
    province: 'NUEVA VIZCAYA',
    municipality: 'BAMBANG',
    region: 'REGION II',
    barangay_list: [
      'ABIAN',
      'ABINGANAN',
      'ALIAGA',
      'ALMAGUER NORTH',
      'ALMAGUER SOUTH',
      'BANGGOT (POB.)',
      'BARAT',
      'BUAG (POB.)',
      'CALAOCAN (POB.)',
      'DULLAO',
      'HOMESTEAD',
      'INDIANA',
      'MABUSLO',
      'MACATE',
      'MAGSAYSAY HILLS',
      'MANAMTAM',
      'MAUAN',
      'PALLAS',
      'SALINAS',
      'SAN ANTONIO NORTH',
      'SAN ANTONIO SOUTH',
      'SAN FERNANDO',
      'SAN LEONARDO',
      'SANTO DOMINGO (TABANGAN)',
      'SANTO DOMINGO WEST',
    ],
  },
  BAYOMBONG: {
    province: 'NUEVA VIZCAYA',
    municipality: 'BAYOMBONG',
    region: 'REGION II',
    barangay_list: [
      'BANSING',
      'BONFAL EAST',
      'BONFAL PROPER',
      'BONFAL WEST',
      'BUENAVISTA (VISTA HILLS)',
      'BUSILAC',
      'CABUAAN',
      'CASAT',
      'DISTRICT III POB. (DON M. PEREZ)',
      'DISTRICT IV (POB.)',
      'DON DOMINGO MADDELA POB. (DISTRICT I)',
      'DON MARIANO MARCOS',
      'DON TOMAS MADDELA POB. (DISTRICT II)',
      'IPIL-CUNEG',
      'LA TORRE NORTH',
      'LA TORRE SOUTH',
      'LUYANG',
      'MAGAPUY',
      'MAGSAYSAY',
      'MASOC',
      'PAITAN',
      'SALVACION',
      'SAN NICOLAS NORTH (LUYANG)',
      'SANTA ROSA',
      'VISTA ALEGRE (B. BARINGIN)',
    ],
  },
  DIADI: {
    province: 'NUEVA VIZCAYA',
    municipality: 'DIADI',
    region: 'REGION II',
    barangay_list: [
      'AMPAKLING',
      'ARWAS',
      'BALETE',
      'BUGNAY',
      'BUTAO',
      'DECABACAN',
      'DURUAROG',
      'ESCOTING',
      'LANGCA',
      'LURAD',
      'NAGSABARAN',
      'NAMAMPARAN',
      'PINYA',
      'POBLACION',
      'ROSARIO',
      'SAN LUIS',
      'SAN PABLO',
      'VILLA AURORA',
      'VILLA FLORENTINO',
    ],
  },
  'DUPAX DEL NORTE': {
    province: 'NUEVA VIZCAYA',
    municipality: 'DUPAX DEL NORTE',
    region: 'REGION II',
    barangay_list: [
      'BELANCE',
      'BINNUANGAN',
      'BITNONG',
      'BULALA',
      'INABAN',
      'INEANGAN',
      'LAMO',
      'MABASA',
      'MACABENGA',
      'MALASIN (POB.)',
      'MUNGUIA',
      'NEW GUMIAD',
      'OYAO',
      'PARAI',
      'YABBI',
    ],
  },
  'DUPAX DEL SUR': {
    province: 'NUEVA VIZCAYA',
    municipality: 'DUPAX DEL SUR',
    region: 'REGION II',
    barangay_list: [
      'ABACA',
      'BAGUMBAYAN',
      'BALSAIN',
      'BANILA',
      'BIRUK',
      'CANABAY',
      'CAROLOTAN',
      'DOMANG',
      'DOPAJ',
      'GABUT',
      'GANAO (LINGAD)',
      'KIMBUTAN',
      'KINABUAN',
      'LUKIDNON',
      'MANGAYANG',
      'PALABOTAN',
      'SANGUIT',
      'SANTA MARIA',
      'TALBEK',
    ],
  },
  KASIBU: {
    province: 'NUEVA VIZCAYA',
    municipality: 'KASIBU',
    region: 'REGION II',
    barangay_list: [
      'ALIMIT',
      'ALLOY',
      'ANTUTOT',
      'BILET',
      'BINOGAWAN',
      'BIYOY',
      'BUA',
      'CAMAMASI',
      'CAPISAAN',
      'CATARAWAN',
      'CORDON',
      'DIDIPIO',
      'DINE',
      'KAKIDUGUEN',
      'KONGKONG',
      'LUPA',
      'MACALONG',
      'MALABING',
      'MUTA',
      'NANTAWACAN',
      'PACQUET (ILONGOT RES.)',
      'PAO',
      'PAPAYA',
      'POBLACION (ALLOY)',
      'PUDI',
      'SEGUEM',
      'TADJI',
      'TOKOD',
      'WANGAL',
      'WATWAT',
    ],
  },
  KAYAPA: {
    province: 'NUEVA VIZCAYA',
    municipality: 'KAYAPA',
    region: 'REGION II',
    barangay_list: [
      'ACACIA',
      'ALANG-SALACSAC',
      'AMILONG LABENG',
      'ANSIPSIP',
      'BAAN',
      'BABADI',
      'BALANGABANG',
      'BALETE',
      'BANAO',
      'BESONG',
      'BINALIAN',
      'BUYASYAS',
      'CABALATAN-ALANG',
      'CABANGLASAN',
      'CABAYO',
      'CASTILLO VILLAGE',
      'KAYAPA PROPER EAST',
      'KAYAPA PROPER WEST',
      'LATBANG',
      'LAWIGAN',
      'MAPAYAO',
      'NANSIAKAN',
      'PAMPANG (POB.)',
      'PANGAWAN',
      'PINAYAG',
      'PINGKIAN',
      'SAN FABIAN',
      'TALECABCAB',
      'TIDANG VILLAGE',
      'TUBONGAN',
    ],
  },
  'SANTA FE': {
    province: 'LEYTE',
    municipality: 'SANTA FE',
    region: 'REGION VIII',
    barangay_list: [
      'BACULANAD',
      'BADIANGAY',
      'BULOD',
      'CATOOGAN',
      'CUTAY',
      'GAPAS',
      'KATIPUNAN',
      'MILAGROSA',
      'PILIT',
      'PITOGO',
      'SAN ISIDRO',
      'SAN JUAN',
      'SAN MIGUELAY',
      'SAN ROQUE',
      'TIBAK',
      'VICTORIA',
      'ZONE 1 (POB.)',
      'ZONE 2 (POB.)',
      'ZONE 3 (POB.)',
      'ZONE 4 POB. (CABANGCALAN)',
    ],
  },
  SOLANO: {
    province: 'NUEVA VIZCAYA',
    municipality: 'SOLANO',
    region: 'REGION II',
    barangay_list: [
      'AGGUB',
      'BAGAHABAG',
      'BANGAAN',
      'BANGAR',
      'BASCARAN',
      'COMMUNAL',
      'CONCEPCION (CALALABANGAN)',
      'CURIFANG',
      'DADAP',
      'LACTAWAN',
      'OSMEÑA',
      'PILAR D. GALIMA',
      'POBLACION NORTH',
      'POBLACION SOUTH',
      'QUEZON',
      'QUIRINO',
      'ROXAS',
      'SAN JUAN',
      'SAN LUIS',
      'TUCAL',
      'UDDIAWAN',
      'WACAL',
    ],
  },
  VILLAVERDE: {
    province: 'NUEVA VIZCAYA',
    municipality: 'VILLAVERDE',
    region: 'REGION II',
    barangay_list: [
      'BINTAWAN NORTE',
      'BINTAWAN SUR',
      'CABULUAN',
      'IBUNG',
      'NAGBITIN',
      'OCAPON',
      'PIEZA',
      'POBLACION (TUROD)',
      'SAWMILL',
    ],
  },
  AGLIPAY: {
    province: 'QUIRINO',
    municipality: 'AGLIPAY',
    region: 'REGION II',
    barangay_list: [
      'ALICIA',
      'CABUGAO',
      'DAGUPAN',
      'DIODOL',
      'DUMABEL',
      'DUNGO (OSMEÑA)',
      'GUINALBIN',
      'LIGAYA',
      'NAGABGABAN',
      'PALACIAN',
      'PINARIPAD NORTE',
      'PINARIPAD SUR',
      'PROGRESO (POB.)',
      'RAMOS',
      'RANG-AYAN',
      'SAN ANTONIO',
      'SAN BENIGNO',
      'SAN FRANCISCO',
      'SAN LEONARDO (CABARROGUIS)',
      'SAN MANUEL',
      'SAN RAMON',
      'VICTORIA',
      'VILLA PAGADUAN',
      'VILLA SANTIAGO',
      'VILLA VENTURA',
    ],
  },
  CABARROGUIS: {
    province: 'QUIRINO',
    municipality: 'CABARROGUIS',
    region: 'REGION II',
    barangay_list: [
      'BANUAR',
      'BURGOS',
      'CALAOCAN',
      'DEL PILAR',
      'DIBIBI',
      'DINGASAN',
      'EDEN',
      'GOMEZ',
      'GUNDAWAY (POB.)',
      'MANGANDINGAY (POB.)',
      'SAN MARCOS',
      'SANTO DOMINGO',
      'TUCOD',
      'VILLA PEÑA (CAPELLANGAN)',
      'VILLAMOR',
      'VILLAROSE',
      'ZAMORA',
    ],
  },
  DIFFUN: {
    province: 'QUIRINO',
    municipality: 'DIFFUN',
    region: 'REGION II',
    barangay_list: [
      'AKLAN VILLAGE',
      'ANDRES BONIFACIO (POB.)',
      'AURORA EAST (POB.)',
      'AURORA WEST (POB.)',
      'BAGUIO VILLAGE',
      'BALAGBAG',
      'BANNAWAG',
      'CAJEL',
      'CAMPAMENTO',
      'DIEGO SILANG',
      'DON FAUSTINO PAGADUAN',
      'DON MARIANO PEREZ, SR.',
      'DOÑA IMELDA',
      'DUMANISI',
      'GABRIELA SILANG',
      'GREGORIO PIMENTEL',
      'GULAC',
      'GURIBANG',
      'IFUGAO VILLAGE',
      'ISIDRO PAREDES',
      'LIWAYWAY',
      'LUTTUAD',
      'MAGSAYSAY',
      'MAKATE',
      'MARIA CLARA',
      'RAFAEL PALMA (DON SERGIO OSMEÑA)',
      'RICARTE NORTE',
      'RICARTE SUR',
      'RIZAL (POB.)',
      'SAN ANTONIO',
      'SAN ISIDRO',
      'SAN PASCUAL',
      'VILLA PASCUA',
    ],
  },
  MADDELA: {
    province: 'QUIRINO',
    municipality: 'MADDELA',
    region: 'REGION II',
    barangay_list: [
      'ABBAG',
      'BALLIGUI',
      'BUENAVISTA',
      'CABARUAN',
      'CABUA-AN',
      'COFCAVILLE',
      'DIDUYON',
      'DIPINTIN',
      'DIVISORIA NORTE',
      'DIVISORIA SUR (BISANGAL)',
      'DUMABATO NORTE',
      'DUMABATO SUR',
      'JOSE ANCHETA',
      'LUSOD',
      'MANGLAD',
      'PEDLISAN',
      'POBLACION NORTE',
      'POBLACION SUR',
      'SAN BERNABE',
      'SAN DIONISIO I',
      'SAN MARTIN',
      'SAN PEDRO',
      'SAN SALVADOR',
      'SANTA MARIA',
      'SANTO NIÑO',
      'SANTO TOMAS',
      'VILLA AGULLANA',
      'VILLA GRACIA',
      'VILLA HERMOSA NORTE',
      'VILLA HERMOSA SUR',
      'VILLA JOSE V YLANAN',
      'YSMAEL',
    ],
  },
  NAGTIPUNAN: {
    province: 'QUIRINO',
    municipality: 'NAGTIPUNAN',
    region: 'REGION II',
    barangay_list: [
      'ANAK',
      'ASAKLAT',
      'DIPANTAN',
      'DISSIMUNGAL',
      'GUINO (GIAYAN)',
      'LA CONWAP (GUINGIN)',
      'LANDINGAN',
      'MATADDI',
      'MATMAD',
      'OLD GUMIAD',
      'PONGGO',
      'SAN DIONISIO II',
      'SAN PUGO',
      'SAN RAMOS',
      'SANGBAY',
      'WASID',
    ],
  },
  SAGUDAY: {
    province: 'QUIRINO',
    municipality: 'SAGUDAY',
    region: 'REGION II',
    barangay_list: [
      'CARDENAS',
      'DIBUL',
      'GAMIS',
      'LA PAZ',
      'MAGSAYSAY (POB.)',
      'RIZAL (POB.)',
      'SALVACION',
      'SANTO TOMAS',
      'TRES REYES',
    ],
  },
  BALER: {
    province: 'AURORA',
    municipality: 'BALER',
    region: 'REGION III',
    barangay_list: [
      'BARANGAY I (POB.)',
      'BARANGAY II (POB.)',
      'BARANGAY III (POB.)',
      'BARANGAY IV (POB.)',
      'BARANGAY V (POB.)',
      'BUHANGIN',
      'CALABUANAN',
      'OBLIGACION',
      'PINGIT',
      'RESERVA',
      'SABANG',
      'SUCLAYIN',
      'ZABALI',
    ],
  },
  CASIGURAN: {
    province: 'SORSOGON',
    municipality: 'CASIGURAN',
    region: 'REGION V',
    barangay_list: [
      'ADOVIS (POB.)',
      'BOTON',
      'BURGOS',
      'CASAY',
      'CAWIT',
      'CENTRAL (POB.)',
      'COGON',
      'COLAMBIS',
      'ESCUALA',
      'INLAGADIAN',
      'LUNGIB',
      'MABINI',
      'PONONG',
      'RIZAL',
      'SAN ANTONIO (BOHO)',
      'SAN ISIDRO',
      'SAN JUAN',
      'SAN PASCUAL',
      'SANTA CRUZ',
      'SOMAL-OT (POB.)',
      'TIGBAO',
      'TIMBAYOG (POB.)',
      'TIRIS',
      'TRECE MARTIREZ',
      'TULAY',
    ],
  },
  DILASAG: {
    province: 'AURORA',
    municipality: 'DILASAG',
    region: 'REGION III',
    barangay_list: [
      'DIAGYAN',
      'DICABASAN',
      'DILAGUIDI',
      'DIMASESET',
      'DINIOG',
      'ESPERANZA',
      'LAWANG',
      'MALIGAYA (POB.)',
      'MANGGITAHAN',
      'MASAGANA (POB.)',
      'URA',
    ],
  },
  DINALUNGAN: {
    province: 'AURORA',
    municipality: 'DINALUNGAN',
    region: 'REGION III',
    barangay_list: [
      'ABULEG',
      'DIBARAYBAY',
      'DITAWINI',
      'MAPALAD',
      'NIPOO (BULO)',
      'PALEG',
      'SIMBAHAN',
      'ZONE I (POB.)',
      'ZONE II (POB.)',
    ],
  },
  DINGALAN: {
    province: 'AURORA',
    municipality: 'DINGALAN',
    region: 'REGION III',
    barangay_list: [
      'APLAYA',
      'BUTAS NA BATO',
      'CABOG (MATAWE)',
      'CARAGSACAN',
      'DAVILDAVILAN',
      'DIKAPANIKIAN',
      'IBONA',
      'PALTIC',
      'POBLACION',
      'TANAWAN',
      'UMIRAY (MALAMIG)',
    ],
  },
  DIPACULAO: {
    province: 'AURORA',
    municipality: 'DIPACULAO',
    region: 'REGION III',
    barangay_list: [
      'BAYABAS',
      'BORLONGAN',
      'BUENAVISTA',
      'CALAOCAN',
      'DIAMANEN',
      'DIANED',
      'DIARABASIN',
      'DIBUTUNAN',
      'DIMABUNO',
      'DINADIAWAN',
      'DITALE',
      'GUPA',
      'IPIL',
      'LABOY',
      'LIPIT',
      'LOBBOT',
      'MALIGAYA',
      'MIJARES',
      'MUCDOL',
      'NORTH POBLACION',
      'PUANGI',
      'SALAY',
      'SAPANGKAWAYAN',
      'SOUTH POBLACION',
      'TOYTOYAN',
    ],
  },
  'MARIA AURORA': {
    province: 'AURORA',
    municipality: 'MARIA AURORA',
    region: 'REGION III',
    barangay_list: [
      'ALCALA',
      'BAGTU',
      'BANGCO',
      'BANNAWAG',
      'BARANGAY I (POB.)',
      'BARANGAY II (POB.)',
      'BARANGAY III (POB.)',
      'BARANGAY IV (POB.)',
      'BAUBO',
      'BAYANIHAN',
      'BAZAL',
      'CABITUCULAN EAST',
      'CABITUCULAN WEST',
      'CADAYACAN',
      'DEBUCAO',
      'DECOLIAT',
      'DETAILEN',
      'DIAAT',
      'DIALATMAN',
      'DIAMAN',
      'DIANAWAN',
      'DIKILDIT',
      'DIMANPUDSO',
      'DIOME',
      'ESTONILO',
      'FLORIDA',
      'GALINTUJA',
      'MALASIN',
      'PONGLO',
      'QUIRINO',
      'RAMADA',
      'SAN JOAQUIN',
      'SAN JOSE',
      'SAN JUAN',
      'SAN LEONARDO',
      'SANTA LUCIA',
      'SANTO TOMAS',
      'SUGUIT',
      'VILLA AURORA',
      'WENCESLAO',
    ],
  },
  ABUCAY: {
    province: 'BATAAN',
    municipality: 'ABUCAY',
    region: 'REGION III',
    barangay_list: [
      'BANGKAL',
      'CALAYLAYAN (POB.)',
      'CAPITANGAN',
      'GABON',
      'LAON (POB.)',
      'MABATANG',
      'OMBOY',
      'SALIAN',
      'WAWA (POB.)',
    ],
  },
  BAGAC: {
    province: 'BATAAN',
    municipality: 'BAGAC',
    region: 'REGION III',
    barangay_list: [
      'ATILANO L. RICARDO',
      'BAGUMBAYAN (POB.)',
      'BANAWANG',
      'BINUANGAN',
      'BINUKAWAN',
      'IBABA',
      'IBIS',
      'PAG-ASA (WAWA-SIBACAN)',
      'PARANG',
      'PAYSAWAN',
      'QUINAWAN',
      'SAN ANTONIO',
      'SAYSAIN',
      'TABING-ILOG (POB.)',
    ],
  },
  'BALANGA': {
    province: 'BATAAN',
    municipality: 'BALANGA',
    region: 'REGION III',
    barangay_list: [
      'BAGONG SILANG',
      'BAGUMBAYAN',
      'CABOG-CABOG',
      'CAMACHO',
      'CATANING',
      'CENTRAL',
      'CUPANG NORTH',
      'CUPANG PROPER',
      'CUPANG WEST',
      'DANGCOL (BERNABE)',
      'DOÑA FRANCISCA',
      'IBAYO',
      'LOTE',
      'MALABIA',
      'MUNTING BATANGAS (CADRE)',
      'POBLACION',
      'PTO. RIVAS IBABA',
      'PTO. RIVAS ITAAS',
      'SAN JOSE',
      'SIBACAN',
      'TALISAY',
      'TANATO',
      'TENEJERO',
      'TORTUGAS',
      'TUYO',
    ],
  },
  DINALUPIHAN: {
    province: 'BATAAN',
    municipality: 'DINALUPIHAN',
    region: 'REGION III',
    barangay_list: [
      'AQUINO',
      'BANGAL',
      'BAYAN-BAYANAN',
      'BONIFACIO (POB.)',
      'BURGOS (POB.)',
      'COLO',
      'DAANG BAGO',
      'DALAO',
      'DEL PILAR (POB.)',
      'GEN. LUNA (POB.)',
      'GOMEZ (POB.)',
      'HAPPY VALLEY',
      'JOSE C. PAYUMO, JR.',
      'KATAASAN',
      'LAYAC',
      'LUACAN',
      'MABINI EXT. (POB.)',
      'MABINI PROPER (POB.)',
      'MAGSAYSAY',
      'MALIGAYA',
      'NAPARING',
      'NEW SAN JOSE',
      'OLD SAN JOSE',
      'PADRE DANDAN (POB.)',
      'PAG-ASA',
      'PAGALANGGANG',
      'PAYANGAN',
      'PENTOR',
      'PINULOT',
      'PITA',
      'RIZAL (POB.)',
      'ROOSEVELT',
      'ROXAS (POB.)',
      'SAGUING',
      'SAN BENITO',
      'SAN ISIDRO (POB.)',
      'SAN PABLO (BULATE)',
      'SAN RAMON',
      'SAN SIMON',
      'SANTA ISABEL (TABACAN)',
      'SANTO NIÑO',
      'SAPANG BALAS',
      'TORRES BUGAUEN (POB.)',
      'TUBO-TUBO',
      'TUCOP',
      'ZAMORA (POB.)',
    ],
  },
  HERMOSA: {
    province: 'BATAAN',
    municipality: 'HERMOSA',
    region: 'REGION III',
    barangay_list: [
      'A. RIVERA (POB.)',
      'ALMACEN',
      'BACONG',
      'BALSIC',
      'BAMBAN',
      'BURGOS-SOLIMAN (POB.)',
      'CATANING (POB.)',
      'CULIS',
      'DAUNGAN (POB.)',
      'JUDGE ROMAN CRUZ SR. (MANDAMA)',
      'MABIGA',
      'MABUCO',
      'MAITE',
      'MAMBOG - MANDAMA',
      'PALIHAN',
      'PANDATUNG',
      'PULO',
      'SABA',
      'SACRIFICE VALLEY',
      'SAN PEDRO (POB.)',
      'SANTO CRISTO (POB.)',
      'SUMALO',
      'TIPO',
    ],
  },
  LIMAY: {
    province: 'BATAAN',
    municipality: 'LIMAY',
    region: 'REGION III',
    barangay_list: [
      'ALANGAN',
      'DUALE',
      'KITANG 2 AND LUZ',
      'KITANG I',
      'LAMAO',
      'LANDING',
      'POBLACION',
      'REFORMISTA',
      'SAN FRANCISCO DE ASIS',
      'ST. FRANCIS II',
      'TOWNSITE',
      'WAWA',
    ],
  },
  MARIVELES: {
    province: 'BATAAN',
    municipality: 'MARIVELES',
    region: 'REGION III',
    barangay_list: [
      'ALAS-ASIN',
      'ALION',
      'BALON-ANITO',
      'BASECO COUNTRY (NASSCO)',
      'BATANGAS II',
      'BIAAN',
      'CABCABEN',
      'CAMAYA',
      'IPAG',
      'LUCANIN',
      'MALAYA',
      'MALIGAYA',
      'MT. VIEW',
      'POBLACION',
      'SAN CARLOS',
      'SAN ISIDRO',
      'SISIMAN',
      'TOWNSITE',
    ],
  },
  MORONG: {
    province: 'RIZAL',
    municipality: 'MORONG',
    region: 'REGION IV-A',
    barangay_list: [
      'BOMBONGAN',
      'CAN-CAL-LAN (CANIOGAN-CALERO-LANANG)',
      'LAGUNDI',
      'MAYBANCAL',
      'SAN GUILLERMO',
      'SAN JOSE (POB.)',
      'SAN JUAN (POB.)',
      'SAN PEDRO (POB.)',
    ],
  },
  ORANI: {
    province: 'BATAAN',
    municipality: 'ORANI',
    region: 'REGION III',
    barangay_list: [
      'APOLLO',
      'BAGONG PARAISO (POB.)',
      'BALUT (POB.)',
      'BAYAN (POB.)',
      'CALERO (POB.)',
      'CENTRO I (POB.)',
      'CENTRO II (POB.)',
      'DONA',
      'KABALUTAN',
      'KAPARANGAN',
      'MARIA FE',
      'MASANTOL',
      'MULAWIN',
      'PAG-ASA',
      'PAKING-CARBONERO (POB.)',
      'PALIHAN (POB.)',
      'PANTALAN BAGO (POB.)',
      'PANTALAN LUMA (POB.)',
      'PARANG PARANG (POB.)',
      'PUKSUAN',
      'SIBUL',
      'SILAHIS',
      'TAGUMPAY',
      'TALA',
      'TALIMUNDOC',
      'TAPULAO',
      'TENEJERO (POB.)',
      'TUGATOG',
      'WAWA (POB.)',
    ],
  },
  ORION: {
    province: 'BATAAN',
    municipality: 'ORION',
    region: 'REGION III',
    barangay_list: [
      'ARELLANO (POB.)',
      'BAGUMBAYAN (POB.)',
      'BALAGTAS (POB.)',
      'BALUT (POB.)',
      'BANTAN',
      'BILOLO',
      'CALUNGUSAN',
      'CAMACHILE',
      'DAANG BAGO (POB.)',
      'DAANG BILOLO (POB.)',
      'DAANG PARE',
      'GENERAL LIM (KAPUT)',
      'KAPUNITAN',
      'LATI (POB.)',
      'LUSUNGAN (POB.)',
      'PUTING BUHANGIN',
      'SABATAN',
      'SAN VICENTE (POB.)',
      'SANTA ELENA',
      'SANTO DOMINGO',
      'VILLA ANGELES (POB.)',
      'WAKAS (POB.)',
      'WAWA (POB.)',
    ],
  },
  SAMAL: {
    province: 'BATAAN',
    municipality: 'SAMAL',
    region: 'REGION III',
    barangay_list: [
      'EAST CALAGUIMAN (POB.)',
      'EAST DAANG BAGO (POB.)',
      'GUGO',
      'IBABA (POB.)',
      'IMELDA',
      'LALAWIGAN',
      'PALILI',
      'SAN JUAN (POB.)',
      'SAN ROQUE (POB.)',
      'SANTA LUCIA',
      'SAPA',
      'TABING ILOG',
      'WEST CALAGUIMAN (POB.)',
      'WEST DAANG BAGO (POB.)',
    ],
  },
  ANGAT: {
    province: 'BULACAN',
    municipality: 'ANGAT',
    region: 'REGION III',
    barangay_list: [
      'BANABAN',
      'BAYBAY',
      'BINAGBAG',
      'DONACION',
      'ENCANTO',
      'LAOG',
      'MARUNGKO',
      'NIUGAN',
      'PALTOK',
      'PULONG YANTOK',
      'SAN ROQUE (POB.)',
      'SANTA CRUZ (POB.)',
      'SANTA LUCIA',
      'SANTO CRISTO (POB.)',
      'SULUCAN',
      'TABOC',
    ],
  },
  'BALAGTAS (BIGAA)': {
    province: 'BULACAN',
    municipality: 'BALAGTAS (BIGAA)',
    region: 'REGION III',
    barangay_list: [
      'BOROL 1ST',
      'BOROL 2ND',
      'DALIG',
      'LONGOS',
      'PANGINAY',
      'PULONG GUBAT',
      'SAN JUAN',
      'SANTOL',
      'WAWA (POB.)',
    ],
  },
  BALIUAG: {
    province: 'BULACAN',
    municipality: 'BALIUAG',
    region: 'REGION III',
    barangay_list: [
      'BAGONG NAYON',
      'BARANGCA',
      'CALANTIPAY',
      'CATULINAN',
      'CONCEPCION',
      'HINUKAY',
      'MAKINABANG',
      'MATANGTUBIG',
      'PAGALA',
      'PAITAN',
      'PIEL',
      'PINAGBARILAN',
      'POBLACION',
      'SABANG',
      'SAN JOSE',
      'SAN ROQUE',
      'SANTA BARBARA',
      'SANTO CRISTO',
      'SANTO NIÑO',
      'SUBIC',
      'SULIVAN',
      'TANGOS',
      'TARCAN',
      'TIAONG',
      'TIBAG',
      'TILAPAYONG',
      'VIRGEN DELAS FLORES',
    ],
  },
  BOCAUE: {
    province: 'BULACAN',
    municipality: 'BOCAUE',
    region: 'REGION III',
    barangay_list: [
      'ANTIPONA',
      'BAGUMBAYAN',
      'BAMBANG',
      'BATIA',
      'BIÑANG 1ST',
      'BIÑANG 2ND',
      'BOLACAN',
      'BUNDUKAN',
      'BUNLO',
      'CAINGIN',
      'DUHAT',
      'IGULOT',
      'LOLOMBOY',
      'POBLACION',
      'SULUCAN',
      'TAAL',
      'TAMBOBONG',
      'TURO',
      'WAKAS',
    ],
  },
  BULACAN: {
    province: 'BULACAN',
    municipality: 'BULACAN',
    region: 'REGION III',
    barangay_list: [
      'BAGUMBAYAN',
      'BALUBAD',
      'BAMBANG',
      'MATUNGAO',
      'MAYSANTOL',
      'PEREZ',
      'PITPITAN',
      'SAN FRANCISCO',
      'SAN JOSE (POB.)',
      'SAN NICOLAS',
      'SANTA ANA',
      'SANTA INES',
      'TALIPTIP',
      'TIBIG',
    ],
  },
  BUSTOS: {
    province: 'BULACAN',
    municipality: 'BUSTOS',
    region: 'REGION III',
    barangay_list: [
      'BONGA MAYOR',
      'BONGA MENOR',
      'BUISAN',
      'CAMACHILIHAN',
      'CAMBAOG',
      'CATACTE',
      'LICIADA',
      'MALAMIG',
      'MALAWAK',
      'POBLACION',
      'SAN PEDRO',
      'TALAMPAS',
      'TANAWAN',
      'TIBAGAN',
    ],
  },
  CALUMPIT: {
    province: 'BULACAN',
    municipality: 'CALUMPIT',
    region: 'REGION III',
    barangay_list: [
      'BALITE',
      'BALUNGAO',
      'BUGUION',
      'BULUSAN',
      'CALIZON',
      'CALUMPANG',
      'CANIOGAN',
      'CORAZON',
      'FRANCES',
      'GATBUCA',
      'GUGO',
      'IBA ESTE',
      "IBA O'ESTE",
      'LONGOS',
      'MEYSULAO',
      'MEYTO',
      'PALIMBANG',
      'PANDUCOT',
      'PIO CRUZCOSA',
      'POBLACION',
      'PUNGO',
      'SAN JOSE',
      'SAN MARCOS',
      'SAN MIGUEL',
      'SANTA LUCIA',
      'SANTO NIÑO',
      'SAPANG BAYAN',
      'SERGIO BAYAN',
      'SUCOL',
    ],
  },
  'DOÑA REMEDIOS TRINIDAD': {
    province: 'BULACAN',
    municipality: 'DOÑA REMEDIOS TRINIDAD',
    region: 'REGION III',
    barangay_list: [
      'BAYABAS',
      'CAMACHILE',
      'CAMACHIN',
      'KABAYUNAN',
      'KALAWAKAN',
      'PULONG SAMPALOK',
      'SAPANG BULAK',
      'TALBAK',
    ],
  },
  GUIGUINTO: {
    province: 'BULACAN',
    municipality: 'GUIGUINTO',
    region: 'REGION III',
    barangay_list: [
      'CUTCUT',
      'DAUNGAN',
      'ILANG-ILANG',
      'MALIS',
      'PANGINAY',
      'POBLACION',
      'PRITIL',
      'PULONG GUBAT',
      'SANTA CRUZ',
      'SANTA RITA',
      'TABANG',
      'TABE',
      'TIAONG',
      'TUKTUKAN',
    ],
  },
  'MALOLOS': {
    province: 'BULACAN',
    municipality: 'MALOLOS',
    region: 'REGION III',
    barangay_list: [
      'ANILAO',
      'ATLAG',
      'BABATNIN',
      'BAGNA',
      'BAGONG BAYAN',
      'BALAYONG',
      'BALITE',
      'BANGKAL',
      'BARIHAN',
      'BULIHAN',
      'BUNGAHAN',
      'CAINGIN',
      'CALERO',
      'CALILIGAWAN',
      'CANALATE',
      'CANIOGAN',
      'CATMON',
      'COFRADIA',
      'DAKILA',
      'GUINHAWA',
      'LIGAS',
      'LIYANG',
      'LONGOS',
      'LOOK 1ST',
      'LOOK 2ND',
      'LUGAM',
      'MABOLO',
      'MAMBOG',
      'MASILE',
      'MATIMBO',
      'MOJON',
      'NAMAYAN',
      'NIUGAN',
      'PAMARAWAN',
      'PANASAHAN',
      'PINAGBAKAHAN',
      'SAN AGUSTIN',
      'SAN GABRIEL',
      'SAN JUAN',
      'SAN PABLO',
      'SAN VICENTE (POB.)',
      'SANTIAGO',
      'SANTISIMA TRINIDAD',
      'SANTO CRISTO',
      'SANTO NIÑO (POB.)',
      'SANTO ROSARIO (POB.)',
      'SANTOL',
      'SUMAPANG BATA',
      'SUMAPANG MATANDA',
      'TAAL',
      'TIKAY',
    ],
  },
  MARILAO: {
    province: 'BULACAN',
    municipality: 'MARILAO',
    region: 'REGION III',
    barangay_list: [
      'ABANGAN NORTE',
      'ABANGAN SUR',
      'IBAYO',
      'LAMBAKIN',
      'LIAS',
      'LOMA DE GATO',
      'NAGBALON',
      'PATUBIG',
      'POBLACION I',
      'POBLACION II',
      'PRENZA I',
      'PRENZA II',
      'SANTA ROSA I',
      'SANTA ROSA II',
      'SAOG',
      'TABING ILOG',
    ],
  },
  'MEYCAUAYAN': {
    province: 'BULACAN',
    municipality: 'MEYCAUAYAN',
    region: 'REGION III',
    barangay_list: [
      'BAGBAGUIN',
      'BAHAY PARE',
      'BANCAL',
      'BANGA',
      'BAYUGO',
      'CAINGIN',
      'CALVARIO',
      'CAMALIG',
      'GASAK (SAINT FRANCIS)',
      'HULO',
      'IBA',
      'LANGKA',
      'LAWA',
      'LIBTONG',
      'LIPUTAN',
      'LONGOS',
      'MALHACAN',
      'PAJO',
      'PANDAYAN',
      'PANTOC',
      'PEREZ',
      'POBLACION',
      'SALUYSOY',
      'TUGATOG',
      'UBIHAN',
      'ZAMORA',
    ],
  },
  NORZAGARAY: {
    province: 'BULACAN',
    municipality: 'NORZAGARAY',
    region: 'REGION III',
    barangay_list: [
      'BANGKAL',
      'BARAKA',
      'BIGTE',
      'BITUNGOL',
      'FRIENDSHIP VILLAGE RESOURCES',
      'MATICTIC',
      'MINUYAN',
      'PARTIDA',
      'PINAGTULAYAN',
      'POBLACION',
      'SAN LORENZO',
      'SAN MATEO',
      'TIGBE',
    ],
  },
  OBANDO: {
    province: 'BULACAN',
    municipality: 'OBANDO',
    region: 'REGION III',
    barangay_list: [
      'BINUANGAN',
      'CATANGHALAN',
      'HULO',
      'LAWA',
      'PACO',
      'PAG-ASA (POB.)',
      'PALIWAS',
      'PANGHULO',
      'SALAMBAO',
      'SAN PASCUAL',
      'TAWIRAN',
    ],
  },
  PANDI: {
    province: 'BULACAN',
    municipality: 'PANDI',
    region: 'REGION III',
    barangay_list: [
      'BAGBAGUIN',
      'BAGONG BARRIO',
      'BAKA-BAKAHAN',
      'BUNSURAN I',
      'BUNSURAN II',
      'BUNSURAN III',
      'CACARONG BATA',
      'CACARONG MATANDA',
      'CUPANG',
      'MALIBONG BATA',
      'MALIBONG MATANDA',
      'MANATAL',
      'MAPULANG LUPA',
      'MASAGANA',
      'MASUSO',
      'PINAGKUARTELAN',
      'POBLACION',
      'REAL DE CACARONG',
      'SAN ROQUE',
      'SANTO NIÑO',
      'SILING BATA',
      'SILING MATANDA',
    ],
  },
  PAOMBONG: {
    province: 'BULACAN',
    municipality: 'PAOMBONG',
    region: 'REGION III',
    barangay_list: [
      'BINAKOD',
      'KAPITANGAN',
      'MALUMOT',
      'MASUKOL',
      'PINALAGDAN',
      'POBLACION',
      'SAN ISIDRO I',
      'SAN ISIDRO II',
      'SAN JOSE',
      'SAN ROQUE',
      'SAN VICENTE',
      'SANTA CRUZ',
      'SANTO NIÑO',
      'SANTO ROSARIO',
    ],
  },
  PULILAN: {
    province: 'BULACAN',
    municipality: 'PULILAN',
    region: 'REGION III',
    barangay_list: [
      'BALATONG A',
      'BALATONG B',
      'CUTCOT',
      'DAMPOL I',
      'DAMPOL II-A',
      'DAMPOL II-B',
      'DULONG MALABON',
      'INAON',
      'LONGOS',
      'LUMBAC',
      'PALTAO',
      'PENABATAN',
      'POBLACION',
      'SANTA PEREGRINA',
      'SANTO CRISTO',
      'TAAL',
      'TABON',
      'TIBAG',
      'TINEJERO',
    ],
  },
  'SAN JOSE DEL MONTE': {
    province: 'BULACAN',
    municipality: 'SAN JOSE DEL MONTE',
    region: 'REGION III',
    barangay_list: [
      'ASSUMPTION',
      'BAGONG BUHAY',
      'BAGONG BUHAY II',
      'BAGONG BUHAY III',
      'CITRUS',
      'CIUDAD REAL',
      'DULONG BAYAN',
      'FATIMA',
      'FATIMA II',
      'FATIMA III',
      'FATIMA IV',
      'FATIMA V',
      'FRANCISCO HOMES-GUIJO',
      'FRANCISCO HOMES-MULAWIN',
      'FRANCISCO HOMES-NARRA',
      'FRANCISCO HOMES-YAKAL',
      'GAYA-GAYA',
      'GRACEVILLE',
      'GUMAOC CENTRAL',
      'GUMAOC EAST',
      'GUMAOC WEST',
      'KAYBANBAN',
      'KAYPIAN',
      'LAWANG PARI',
      'MAHARLIKA',
      'MINUYAN',
      'MINUYAN II',
      'MINUYAN III',
      'MINUYAN IV',
      'MINUYAN PROPER',
      'MINUYAN V',
      'MUZON',
      'PARADISE III',
      'POBLACION',
      'POBLACION I',
      'SAN ISIDRO',
      'SAN MANUEL',
      'SAN MARTIN',
      'SAN MARTIN II',
      'SAN MARTIN III',
      'SAN MARTIN IV',
      'SAN PEDRO',
      'SAN RAFAEL',
      'SAN RAFAEL I',
      'SAN RAFAEL III',
      'SAN RAFAEL IV',
      'SAN RAFAEL V',
      'SAN ROQUE',
      'SANTA CRUZ',
      'SANTA CRUZ II',
      'SANTA CRUZ III',
      'SANTA CRUZ IV',
      'SANTA CRUZ V',
      'SANTO CRISTO',
      'SANTO NIÑO',
      'SANTO NIÑO II',
      'SAPANG PALAY',
      'ST. MARTIN DE PORRES',
      'TUNGKONG MANGGA',
    ],
  },
  'SAN RAFAEL': {
    province: 'ILOILO',
    municipality: 'SAN RAFAEL',
    region: 'REGION VI',
    barangay_list: [
      'ARIPDIP',
      'BAGACAY',
      'CALAIGANG',
      'ILONGBUKID',
      'POBLACION',
      'POSCOLON',
      'SAN ANDRES',
      'SAN DIONISIO',
      'SAN FLORENTINO',
    ],
  },
  ALIAGA: {
    province: 'NUEVA ECIJA',
    municipality: 'ALIAGA',
    region: 'REGION III',
    barangay_list: [
      'BETES',
      'BIBICLAT',
      'BUCOT',
      'LA PURISIMA',
      'MACABUCOD',
      'MAGSAYSAY',
      'PANTOC',
      'POBLACION CENTRO',
      'POBLACION EAST I',
      'POBLACION EAST II',
      'POBLACION WEST III',
      'POBLACION WEST IV',
      'SAN CARLOS',
      'SAN EMILIANO',
      'SAN EUSTACIO',
      'SAN FELIPE BATA',
      'SAN FELIPE MATANDA',
      'SAN JUAN',
      'SAN PABLO BATA',
      'SAN PABLO MATANDA',
      'SANTA MONICA',
      'SANTIAGO',
      'SANTO ROSARIO',
      'SANTO TOMAS',
      'SUNSON',
      'UMANGAN',
    ],
  },
  BONGABON: {
    province: 'NUEVA ECIJA',
    municipality: 'BONGABON',
    region: 'REGION III',
    barangay_list: [
      'ANTIPOLO',
      'ARIENDO',
      'BANTUG',
      'CALAANAN',
      'COMMERCIAL (POB.)',
      'CRUZ',
      'CURVA (J. TOMACRUZ)',
      'DIGMALA',
      'KAINGIN (POB.)',
      'LABI',
      'LARCON',
      'LUSOK',
      'MACABACLAY',
      'MAGTANGGOL (POB.)',
      'MANTILE (POB.)',
      'OLIVETE',
      'PALO MARIA (POB.)',
      'PESA',
      'RIZAL (POB.)',
      'SAMPALUCAN (POB.)',
      'SAN ROQUE (POB.)',
      'SANTOR',
      'SINIPIT (POB.)',
      'SISILANG NA LIGAYA (POB.)',
      'SOCIAL (POB.)',
      'TUGATUG',
      'TULAY NA BATO (POB.)',
      'VEGA',
    ],
  },
  'CABANATUAN': {
    province: 'NUEVA ECIJA',
    municipality: 'CABANATUAN',
    region: 'REGION III',
    barangay_list: [
      'ADUAS CENTRO (ADUAS)',
      'ADUAS NORTE',
      'ADUAS SUR',
      'BAGONG BUHAY',
      'BAGONG SIKAT',
      'BAKERO',
      'BAKOD BAYAN',
      'BALITE',
      'BANGAD',
      'BANTUG BULALO',
      'BANTUG NORTE',
      'BARLIS',
      'BARRERA DISTRICT (POB.)',
      'BERNARDO DISTRICT (POB.)',
      'BITAS',
      'BONIFACIO DISTRICT (POB.)',
      'BULIRAN',
      'CAALIBANGBANGAN',
      'CABU',
      'CALAWAGAN (KALAWAGAN)',
      'CAMPO TINIO',
      'CARIDAD',
      'CAUDILLO',
      'CINCO-CINCO',
      'CITY SUPERMARKET (POB.)',
      'COMMUNAL',
      'CRUZ ROJA',
      'DAANG SARILE',
      'DALAMPANG',
      'DICARMA (POB.)',
      'DIMASALANG (POB.)',
      'DIONISIO S. GARCIA',
      'FATIMA (POB.)',
      'GENERAL LUNA (POB.)',
      'HERMOGENES C. CONCEPCION, SR.',
      'IBABAO BANA',
      'IMELDA DISTRICT',
      'ISLA (POB.)',
      'KALIKID NORTE',
      'KALIKID SUR',
      'KAPITAN PEPE (POB.)',
      'LAGARE',
      'LOURDES (MATUNGAL-TUNGAL)',
      'M. S. GARCIA',
      'MABINI EXTENSION',
      'MABINI HOMESITE',
      'MACATBONG',
      'MAGSAYSAY DISTRICT',
      'MAGSAYSAY SOUTH',
      'MARIA THERESA',
      'MATADERO (POB.)',
      'MAYAPYAP NORTE',
      'MAYAPYAP SUR',
      'MELOJAVILLA (POB.)',
      'NABAO (POB.)',
      'OBRERO',
      'PADRE BURGOS (POB.)',
      'PADRE CRISOSTOMO',
      'PAGAS',
      'PALAGAY',
      'PAMALDAN',
      'PANGATIAN',
      'PATALAC',
      'POLILIO',
      'PULA',
      'QUEZON DISTRICT (POB.)',
      'RIZDELIS (POB.)',
      'SAMON',
      'SAN ISIDRO',
      'SAN JOSEF NORTE',
      'SAN JOSEF SUR',
      'SAN JUAN POB. (ACOFA)',
      'SAN ROQUE NORTE',
      'SAN ROQUE SUR',
      'SANBERMICRISTI (POB.)',
      'SANGITAN',
      'SANGITAN EAST',
      'SANTA ARCADIA',
      'SANTO NIÑO',
      'SAPANG',
      'SUMACAB ESTE',
      'SUMACAB NORTE',
      'SUMACAB SOUTH',
      'TALIPAPA',
      'VALDEFUENTE',
      'VALLE CRUZ',
      'VIJANDRE DISTRICT (POB.)',
      'VILLA OFELIA-CARIDAD',
      'ZULUETA DISTRICT (POB.)',
    ],
  },
  CABIAO: {
    province: 'NUEVA ECIJA',
    municipality: 'CABIAO',
    region: 'REGION III',
    barangay_list: [
      'BAGONG BUHAY',
      'BAGONG SIKAT',
      'BAGONG SILANG',
      'CONCEPCION',
      'ENTABLADO',
      'MALIGAYA',
      'NATIVIDAD NORTH (POB.)',
      'NATIVIDAD SOUTH (POB.)',
      'PALASINAN',
      'POLILIO',
      'SAN ANTONIO',
      'SAN CARLOS',
      'SAN FERNANDO NORTE',
      'SAN FERNANDO SUR',
      'SAN GREGORIO',
      'SAN JUAN NORTH (POB.)',
      'SAN JUAN SOUTH (POB.)',
      'SAN ROQUE',
      'SAN VICENTE',
      'SANTA INES',
      'SANTA ISABEL',
      'SANTA RITA',
      'SINIPIT',
    ],
  },
  CARRANGLAN: {
    province: 'NUEVA ECIJA',
    municipality: 'CARRANGLAN',
    region: 'REGION III',
    barangay_list: [
      'BANTUG',
      'BUNGA',
      'BURGOS',
      'CAPINTALAN',
      'D. L. MAGLANOC POB. (BGY.III)',
      'F. C. OTIC POB. (BGY. II)',
      'G. S. ROSARIO POB. (BGY. IV)',
      'GENERAL LUNA',
      'JOSON (DIGIDIG)',
      'MINULI',
      'PIUT',
      'PUNCAN',
      'PUTLAN',
      'R.A.PADILLA (BALUARTE)',
      'SALAZAR',
      'SAN AGUSTIN',
      'T. L. PADILLA POB. (BGY. I)',
    ],
  },
  CUYAPO: {
    province: 'NUEVA ECIJA',
    municipality: 'CUYAPO',
    region: 'REGION III',
    barangay_list: [
      'BALOY',
      'BAMBANABA',
      'BANTUG',
      'BENTIGAN',
      'BIBICLAT',
      'BONIFACIO',
      'BUED',
      'BULALA',
      'BURGOS',
      'CABATUAN',
      'CABILEO',
      'CACAPASAN',
      'CALANCUASAN NORTE',
      'CALANCUASAN SUR',
      'COLOSBOA',
      'COLUMBITIN',
      'CURVA',
      'DISTRICT I (POB. I)',
      'DISTRICT II (POB. II)',
      'DISTRICT IV (POB. IV)',
      'DISTRICT V (POB. V)',
      'DISTRICT VI (POB. VI)',
      'DISTRICT VII (POB. VII)',
      'DISTRICT VIII (POB. VIII)',
      'LANDIG',
      'LATAP',
      'LOOB',
      'LUNA',
      'MALBEG-PATALAN',
      'MALINENG',
      'MATINDEG',
      'MAYCABAN',
      'NACURALAN',
      'NAGMISAHAN',
      'PAITAN NORTE',
      'PAITAN SUR',
      'PIGLISAN',
      'PUGO',
      'RIZAL',
      'SABIT',
      'SALAGUSOG',
      'SAN ANTONIO',
      'SAN JOSE',
      'SAN JUAN',
      'SANTA CLARA',
      'SANTA CRUZ',
      'SINIMBAAN',
      'TAGTAGUMBAO',
      'TUTULOY',
      'UNGAB',
      'VILLAFLORES',
    ],
  },
  'GABALDON (BITULOK AND SABANI)': {
    province: 'NUEVA ECIJA',
    municipality: 'GABALDON (BITULOK AND SABANI)',
    region: 'REGION III',
    barangay_list: [
      'BAGONG SIKAT',
      'BAGTING',
      'BANTUG',
      'BITULOK (NORTH POB.)',
      'BUGNAN',
      'CALABASA',
      'CAMACHILE',
      'CUYAPA',
      'LIGAYA',
      'MACASANDAL',
      'MALINAO',
      'PANTOC',
      'PINAMALISAN',
      'SAWMILL',
      'SOUTH POBLACION',
      'TAGUMPAY',
    ],
  },
  'GAPAN': {
    province: 'NUEVA ECIJA',
    municipality: 'GAPAN',
    region: 'REGION III',
    barangay_list: [
      'BALANTE',
      'BAYANIHAN',
      'BULAK',
      'BUNGO',
      'KAPALANGAN',
      'MABUNGA',
      'MABURAK',
      'MAHIPON',
      'MAKABACLAY',
      'MALIMBA',
      'MANGINO',
      'MARELO',
      'PAMBUAN',
      'PARCUTELA',
      'PUTING TUBIG',
      'SAN LORENZO (POB.)',
      'SAN NICOLAS',
      'SAN ROQUE',
      'SAN VICENTE (POB.)',
      'SANTA CRUZ',
      'SANTO CRISTO NORTE',
      'SANTO CRISTO SUR',
      'SANTO NIÑO',
    ],
  },
  'GENERAL MAMERTO NATIVIDAD': {
    province: 'NUEVA ECIJA',
    municipality: 'GENERAL MAMERTO NATIVIDAD',
    region: 'REGION III',
    barangay_list: [
      'BALANGKARE NORTE',
      'BALANGKARE SUR',
      'BALARING',
      'BELEN',
      'BRAVO',
      'BUROL',
      'KABULIHAN',
      'MAG-ASAWANG SAMPALOC',
      'MANAROG',
      'MATAAS NA KAHOY',
      'PANACSAC',
      'PICALEON',
      'PINAHAN',
      'PLATERO',
      'POBLACION',
      'PULA',
      'PULONG SINGKAMAS',
      'SAPANG BATO',
      'TALABUTAB NORTE',
      'TALABUTAB SUR',
    ],
  },
  'GENERAL TINIO (PAPAYA)': {
    province: 'NUEVA ECIJA',
    municipality: 'GENERAL TINIO (PAPAYA)',
    region: 'REGION III',
    barangay_list: [
      'BAGO',
      'CONCEPCION',
      'NAZARETH',
      'PADOLINA',
      'PALALE',
      'PIAS',
      'POBLACION CENTRAL',
      'POBLACION EAST',
      'POBLACION WEST',
      'PULONG MATONG',
      'RIO CHICO',
      'SAMPAGUITA',
      'SAN PEDRO (POB.)',
    ],
  },
  GUIMBA: {
    province: 'NUEVA ECIJA',
    municipality: 'GUIMBA',
    region: 'REGION III',
    barangay_list: [
      'AGCANO',
      'AYOS LOMBOY',
      'BACAYAO',
      'BAGONG BARRIO',
      'BALBALINO',
      'BALINGOG EAST',
      'BALINGOG WEST',
      'BANITAN',
      'BANTUG',
      'BULAKID',
      'BUNOL',
      'CABALLERO',
      'CABARUAN',
      'CAINGIN TABING ILOG',
      'CALEM',
      'CAMILING',
      'CARDINAL',
      'CASONGSONG',
      'CATIMON',
      'CAVITE',
      'CAWAYAN BUGTONG',
      'CONSUELO',
      'CULONG',
      'ESCANO',
      'FAIGAL',
      'GALVAN',
      'GUISET',
      'LAMORITO',
      'LENNEC',
      'MACAMIAS',
      'MACAPABELLAG',
      'MACATCATUIT',
      'MANACSAC',
      'MANGGANG MARIKIT',
      'MATURANOC',
      'MAYBUBON',
      'NAGLABRAHAN',
      'NAGPANDAYAN',
      'NARVACAN I',
      'NARVACAN II',
      'PACAC',
      'PARTIDA I',
      'PARTIDA II',
      'PASONG INCHIC',
      'SAINT JOHN DISTRICT (POB.)',
      'SAN AGUSTIN',
      'SAN ANDRES',
      'SAN BERNARDINO',
      'SAN MARCELINO',
      'SAN MIGUEL',
      'SAN RAFAEL',
      'SAN ROQUE',
      'SANTA ANA',
      'SANTA CRUZ',
      'SANTA LUCIA',
      'SANTA VERONICA DISTRICT (POB.)',
      'SANTO CRISTO DISTRICT (POB.)',
      'SARANAY DISTRICT (POB.)',
      'SINULATAN',
      'SUBOL',
      'TAMPAC I',
      'TAMPAC II AND III',
      'TRIALA',
      'YUSON',
    ],
  },
  JAEN: {
    province: 'NUEVA ECIJA',
    municipality: 'JAEN',
    region: 'REGION III',
    barangay_list: [
      'CALABASA',
      'DAMPULAN (POB.)',
      'DON MARIANO MARCOS (POB.)',
      'HILERA',
      'IMBUNIA',
      'IMELDA POB. (DOÑA AURORA)',
      'LAMBAKIN',
      'LANGLA',
      'MAGSALISI',
      'MALABON-KAINGIN',
      'MARAWA',
      'NIYUGAN',
      'OCAMPO-RIVERA DISTRICT (POB.)',
      'PAKOL',
      'PAMACPACAN',
      'PINANGGAAN',
      'PUTLOD',
      'SAN JOSE',
      'SAN JOSEF (NABAO)',
      'SAN PABLO',
      'SAN ROQUE',
      'SAN VICENTE',
      'SANTA RITA',
      'SANTO TOMAS NORTH',
      'SANTO TOMAS SOUTH',
      'SAPANG',
      'ULANIN-PITAK',
    ],
  },
  LAUR: {
    province: 'NUEVA ECIJA',
    municipality: 'LAUR',
    region: 'REGION III',
    barangay_list: [
      'BARANGAY I (POB.)',
      'BARANGAY II (POB.)',
      'BARANGAY III (POB.)',
      'BARANGAY IV (POB.)',
      'BETANIA',
      'CANANTONG',
      'NAUZON',
      'PANGARULONG',
      'PINAGBAYANAN',
      'SAGANA',
      'SAN FELIPE',
      'SAN FERNANDO',
      'SAN ISIDRO',
      'SAN JOSEF',
      'SAN JUAN',
      'SAN VICENTE',
      'SICLONG',
    ],
  },
  LICAB: {
    province: 'NUEVA ECIJA',
    municipality: 'LICAB',
    region: 'REGION III',
    barangay_list: [
      'AQUINO',
      'LINAO',
      'POBLACION NORTE',
      'POBLACION SUR',
      'SAN CASIMIRO',
      'SAN CRISTOBAL',
      'SAN JOSE',
      'SAN JUAN',
      'SANTA MARIA',
      'TABING ILOG',
      'VILLAROSA',
    ],
  },
  LLANERA: {
    province: 'NUEVA ECIJA',
    municipality: 'LLANERA',
    region: 'REGION III',
    barangay_list: [
      'A. BONIFACIO',
      'BAGUMBAYAN (POB.)',
      'BOSQUE',
      'CARIDAD NORTE',
      'CARIDAD SUR',
      'CASILE',
      'FLORIDA BLANCA',
      'GENERAL LUNA',
      'GENERAL RICARTE',
      'GOMEZ',
      'INANAMA',
      'LIGAYA',
      'MABINI',
      'MURCON',
      'PLARIDEL',
      'SAN FELIPE',
      'SAN FRANCISCO',
      'SAN NICOLAS',
      'SAN VICENTE',
      'SANTA BARBARA',
      'VICTORIA',
      'VILLA VINIEGAS',
    ],
  },
  LUPAO: {
    province: 'NUEVA ECIJA',
    municipality: 'LUPAO',
    region: 'REGION III',
    barangay_list: [
      'AGUPALO ESTE',
      'AGUPALO WESTE',
      'ALALAY CHICA',
      'ALALAY GRANDE',
      'BAGONG FLORES',
      'BALBALUNGAO',
      'BURGOS',
      'CORDERO',
      'J. U. TIENZO (ARIMAL)',
      'MAPANGPANG',
      'NAMULANDAYAN',
      'PARISTA',
      'POBLACION EAST',
      'POBLACION NORTH',
      'POBLACION SOUTH',
      'POBLACION WEST',
      'SALVACION I',
      'SALVACION II',
      'SAN ANTONIO ESTE',
      'SAN ANTONIO WESTE',
      'SAN ISIDRO',
      'SAN PEDRO',
      'SAN ROQUE',
      'SANTO DOMINGO',
    ],
  },
  NAMPICUAN: {
    province: 'NUEVA ECIJA',
    municipality: 'NAMPICUAN',
    region: 'REGION III',
    barangay_list: [
      'ALEMANIA',
      'AMBASADOR ALZATE VILLAGE',
      'CABADUCAN EAST (POB.)',
      'CABADUCAN WEST (POB.)',
      'CABAWANGAN',
      'EAST CENTRAL POBLACION',
      'EDY',
      'ESTACION (POB.)',
      'MAELING',
      'MAYANTOC',
      'MEDICO',
      'MONIC',
      'NORTH POBLACION',
      'NORTHWEST POBLACION',
      'RECUERDO',
      'SOUTH CENTRAL POBLACION',
      'SOUTHEAST POBLACION',
      'SOUTHWEST POBLACION',
      'TONY',
      'WEST CENTRAL POBLACION',
      'WEST POBLACION',
    ],
  },
  'PALAYAN': {
    province: 'NUEVA ECIJA',
    municipality: 'PALAYAN',
    region: 'REGION III',
    barangay_list: [
      'ATATE',
      'AULO',
      'BAGONG BUHAY',
      'BO. MILITAR (FORT MAGSAYSAY)',
      'CABALLERO',
      'CAIMITO',
      'DOÑA JOSEFA',
      'GANADERIA',
      'IMELDA VALLEY',
      'LANGKA',
      'MALATE (POB.)',
      'MALIGAYA',
      'MANACNAC',
      'MAPAIT',
      'MARCOS VILLAGE',
      'POPOLON PAGAS',
      'SANTOLAN',
      'SAPANG BUHO',
      'SINGALAT',
    ],
  },
  PANTABANGAN: {
    province: 'NUEVA ECIJA',
    municipality: 'PANTABANGAN',
    region: 'REGION III',
    barangay_list: [
      'CADACLAN',
      'CAMBITALA',
      'CONVERSION',
      'FATIMA',
      'GANDUZ',
      'LIBERTY',
      'MALBANG',
      'MARIKIT',
      'NAPON-NAPON',
      'POBLACION EAST',
      'POBLACION WEST',
      'SAMPALOC',
      'SAN JUAN',
      'VILLARICA',
    ],
  },
  PEÑARANDA: {
    province: 'NUEVA ECIJA',
    municipality: 'PEÑARANDA',
    region: 'REGION III',
    barangay_list: [
      'CALLOS',
      'LAS PIÑAS',
      'POBLACION I',
      'POBLACION II',
      'POBLACION III',
      'POBLACION IV',
      'SAN JOSEF',
      'SAN MARIANO (MAUGAT)',
      'SANTO TOMAS',
      'SINASAJAN',
    ],
  },
  'SAN ANTONIO': {
    province: 'NORTHERN SAMAR',
    municipality: 'SAN ANTONIO',
    region: 'REGION VIII',
    barangay_list: [
      'BURABOD',
      'DALUPIRIT',
      'MANRAYA',
      'PILAR',
      'RIZAL',
      'SAN NICOLAS',
      'VINISITAHAN',
      'WARD I (POB.)',
      'WARD II (POB.)',
      'WARD III (POB.)',
    ],
  },
  'SAN JOSE': {
    province: 'NUEVA ECIJA',
    municipality: 'SAN JOSE',
    region: 'REGION III',
    barangay_list: [
      'A. PASCUAL',
      'ABAR 2ND',
      'ABAR IST',
      'BAGONG SIKAT',
      'CAANAWAN',
      'CALAOCAN',
      'CAMANACSACAN',
      'CANUTO RAMOS POB. (DISTRICT III)',
      'CRISANTO SANCHEZ POB. (DISTRICT V)',
      'CULAYLAY',
      'DIZOL',
      'FERDINAND E. MARCOS POB. (DISTRICT II)',
      'KALIWANAGAN',
      'KITA-KITA',
      'MALASIN',
      'MANICLA',
      'PALESTINA',
      'PARANG MANGGA',
      'PINILI',
      'PORAIS',
      'RAFAEL RUEDA, SR. POB. (DISTRICT I)',
      'RAYMUNDO EUGENIO POB. (DISTRICT IV)',
      'SAN AGUSTIN',
      'SAN JUAN',
      'SAN MAURICIO',
      'SANTO NIÑO 1ST',
      'SANTO NIÑO 2ND',
      'SANTO NIÑO 3RD',
      'SANTO TOMAS',
      'SIBUT',
      'SINIPIT BUBON',
      'TABULAC',
      'TAYABO',
      'TONDOD',
      'TULAT',
      'VILLA FLORESCA',
      'VILLA JOSON (PARILLA)',
      'VILLA MARINA',
    ],
  },
  'SAN LEONARDO': {
    province: 'NUEVA ECIJA',
    municipality: 'SAN LEONARDO',
    region: 'REGION III',
    barangay_list: [
      'BONIFACIO DISTRICT (POB.)',
      'BURGOS DISTRICT (POB.)',
      'CASTELLANO',
      'DIVERSION',
      'MAGPAPALAYOC',
      'MALLORCA',
      'MAMBANGNAN',
      'NIEVES',
      'RIZAL DISTRICT (POB.)',
      'SAN ANTON',
      'SAN BARTOLOME (POB.)',
      'SAN ROQUE',
      'TABUATING',
      'TAGUMPAY',
      'TAMBO ADORABLE',
    ],
  },
  'SANTA ROSA': {
    province: 'NUEVA ECIJA',
    municipality: 'SANTA ROSA',
    region: 'REGION III',
    barangay_list: [
      'AGUINALDO',
      'BERANG',
      'BURGOS',
      'COJUANGCO (POB.)',
      'DEL PILAR',
      'GOMEZ',
      'INSPECTOR',
      'ISLA',
      'LA FUENTE',
      'LIWAYWAY',
      'LOURDES',
      'LUNA',
      'MABINI',
      'MALACAÑANG',
      'MALIOLIO',
      'MAPALAD',
      'RAJAL CENTRO',
      'RAJAL NORTE',
      'RAJAL SUR',
      'RIZAL (POB.)',
      'SAN GREGORIO',
      'SAN ISIDRO',
      'SAN JOSEP',
      'SAN MARIANO',
      'SAN PEDRO',
      'SANTA TERESITA',
      'SANTO ROSARIO',
      'SAPSAP',
      'SOLEDAD',
      'TAGPOS',
      'TRAMO',
      'VALENZUELA (POB.)',
      'ZAMORA (POB.)',
    ],
  },
  'SCIENCE MUÑOZ': {
    province: 'NUEVA ECIJA',
    municipality: 'SCIENCE MUÑOZ',
    region: 'REGION III',
    barangay_list: [
      'BAGONG SIKAT',
      'BALANTE',
      'BANTUG',
      'BICAL',
      'CABISUCULAN',
      'CALABALABAAN',
      'CALISITAN',
      'CATALANACAN',
      'CURVA',
      'FRANZA',
      'GABALDON',
      'LABNEY',
      'LICAONG',
      'LINGLINGAY',
      'MAGTANGGOL',
      'MALIGAYA',
      'MANGANDINGAY',
      'MAPANGPANG',
      'MARAGOL',
      'MATINGKIS',
      'NAGLABRAHAN',
      'PALUSAPIS',
      'PANDALLA',
      'POBLACION EAST',
      'POBLACION NORTH',
      'POBLACION SOUTH',
      'POBLACION WEST',
      'RANG-AYAN',
      'RIZAL',
      'SAN ANDRES',
      'SAN ANTONIO',
      'SAN FELIPE',
      'SAPANG CAWAYAN',
      'VILLA CUIZON',
      'VILLA ISLA',
      'VILLA NATI',
      'VILLA SANTOS',
    ],
  },
  TALAVERA: {
    province: 'NUEVA ECIJA',
    municipality: 'TALAVERA',
    region: 'REGION III',
    barangay_list: [
      'ANDAL ALINO (POB.)',
      'BAGONG SIKAT',
      'BAGONG SILANG',
      'BAKAL I',
      'BAKAL II',
      'BAKAL III',
      'BALUGA',
      'BANTUG',
      'BANTUG HACIENDA',
      'BANTUG HAMOG (BASANG HAMOG)',
      'BUGTONG NA BULI',
      'BULAC',
      'BURNAY',
      'CAANIPLAHAN',
      'CABUBULAONAN',
      'CALIPAHAN',
      'CAMPOS',
      'CAPUTICAN',
      'CASULUCAN ESTE',
      'COLLADO',
      'DIMASALANG NORTE',
      'DIMASALANG SUR',
      'DINARAYAT',
      'ESGUERRA DISTRICT (POB.)',
      'GULOD',
      'HOMESTEAD I',
      'HOMESTEAD II',
      'KINALANGUYAN',
      'LA TORRE',
      'LOMBOY',
      'MABUHAY',
      'MAESTRANG KIKAY (POB.)',
      'MAMANDIL',
      'MARCOS DISTRICT (POB.)',
      'MATINGKIS',
      'MINABUYOC',
      'PAG-ASA (POB.)',
      'PALUDPOD',
      'PANTOC BULAC',
      'PINAGPANAAN',
      'POBLACION SUR',
      'PULA',
      'PULONG SAN MIGUEL (POB.)',
      'PUROK MATIAS (POB.)',
      'SAMPALOC',
      'SAN MIGUEL NA MUNTI',
      'SAN PASCUAL',
      'SAN RICARDO',
      'SIBUL',
      'SICSICAN MATANDA',
      'TABACAO',
      'TAGAYTAY',
      'VALLE',
    ],
  },
  TALUGTUG: {
    province: 'NUEVA ECIJA',
    municipality: 'TALUGTUG',
    region: 'REGION III',
    barangay_list: [
      'ALULA',
      'BAYBAYABAS',
      'BUTED',
      'CABIANGAN',
      'CALISITAN',
      'CINENSE',
      'CULIAT',
      'MAASIN',
      'MAGSAYSAY (POB.)',
      'MAYAMOT I',
      'MAYAMOT II',
      'NANGABULAN',
      'OSMEÑA (POB.)',
      'PANGIT',
      'PATOLA',
      'QUEZON (POB.)',
      'QUIRINO (POB.)',
      'ROXAS (POB.)',
      'SAGUING',
      'SAMPALOC',
      'SANTA CATALINA',
      'SANTO DOMINGO',
      'SARINGAYA',
      'SAVERONA',
      'TANDOC',
      'TIBAG',
      'VILLA BOADO',
      'VILLA ROSARIO',
    ],
  },
  ZARAGOZA: {
    province: 'NUEVA ECIJA',
    municipality: 'ZARAGOZA',
    region: 'REGION III',
    barangay_list: [
      'BATITANG',
      'CARMEN',
      'CONCEPCION',
      'DEL PILAR',
      'GENERAL LUNA',
      'H. ROMERO',
      'MACARSE',
      'MANAUL',
      'MAYAMOT',
      'PANTOC',
      'SAN ISIDRO',
      'SAN RAFAEL',
      'SAN VICENTE (POB.)',
      'SANTA CRUZ',
      'SANTA LUCIA OLD',
      'SANTA LUCIA YOUNG',
      'SANTO ROSARIO OLD',
      'SANTO ROSARIO YOUNG',
      'VALERIANA',
    ],
  },
  'ANGELES': {
    province: 'PAMPANGA',
    municipality: 'ANGELES',
    region: 'REGION III',
    barangay_list: [
      'AGAPITO DEL ROSARIO',
      'AMSIC',
      'ANUNAS',
      'BALIBAGO',
      'CAPAYA',
      'CLARO M. RECTO',
      'CUAYAN',
      'CUTCUT',
      'CUTUD',
      'LOURDES NORTH WEST',
      'LOURDES SUR',
      'LOURDES SUR EAST',
      'MALABANIAS',
      'MARGOT',
      'MINING',
      'NINOY AQUINO (MARISOL)',
      'PAMPANG',
      'PANDAN',
      'PULUNG CACUTUD',
      'PULUNG MARAGUL',
      'PULUNGBULU',
      'SALAPUNGAN',
      'SAN JOSE',
      'SAN NICOLAS',
      'SANTA TERESITA',
      'SANTA TRINIDAD',
      'SANTO CRISTO',
      'SANTO DOMINGO',
      'SANTO ROSARIO (POB.)',
      'SAPALIBUTAD',
      'SAPANGBATO',
      'TABUN',
      'VIRGEN DELOS REMEDIOS',
    ],
  },
  APALIT: {
    province: 'PAMPANGA',
    municipality: 'APALIT',
    region: 'REGION III',
    barangay_list: [
      'BALUCUC',
      'CALANTIPE',
      'CANSINALA',
      'CAPALANGAN',
      'COLGANTE',
      'PALIGUI',
      'SAMPALOC',
      'SAN JUAN (POB.)',
      'SAN VICENTE',
      'SUCAD',
      'SULIPAN',
      'TABUYUC (SANTO ROSARIO)',
    ],
  },
  ARAYAT: {
    province: 'PAMPANGA',
    municipality: 'ARAYAT',
    region: 'REGION III',
    barangay_list: [
      'ARENAS',
      'BALITI',
      'BATASAN',
      'BUENSUCESO',
      'CANDATING',
      'CUPANG (SANTA LUCIA)',
      'GATIAWIN',
      'GUEMASAN',
      'KALEDIAN (CAMBA)',
      'LA PAZ (TURU)',
      'LACMIT',
      'LACQUIOS',
      'MANGGA-CACUTUD',
      'MAPALAD',
      'MATAMO (SANTA LUCIA)',
      'PANLINLANG',
      'PARALAYA',
      'PLAZANG LUMA',
      'POBLACION',
      'SAN AGUSTIN NORTE',
      'SAN AGUSTIN SUR',
      'SAN ANTONIO',
      'SAN JOSE MESULO',
      'SAN JUAN BANO',
      'SAN MATEO',
      'SAN NICOLAS',
      'SAN ROQUE BITAS',
      'SANTO NIÑO TABUAN',
      'SUCLAYIN',
      'TELAPAYONG',
    ],
  },
  BACOLOR: {
    province: 'PAMPANGA',
    municipality: 'BACOLOR',
    region: 'REGION III',
    barangay_list: [
      'BALAS',
      'CABALANTIAN',
      'CABAMBANGAN (POB.)',
      'CABETICAN',
      'CALIBUTBUT',
      'CONCEPCION',
      'DOLORES',
      'DUAT',
      'MACABACLE',
      'MAGLIMAN',
      'MALIWALU',
      'MESALIPIT',
      'PARULOG',
      'POTRERO',
      'SAN ANTONIO',
      'SAN ISIDRO',
      'SAN VICENTE',
      'SANTA BARBARA',
      'SANTA INES',
      'TALBA',
      'TINAJERO',
    ],
  },
  CANDABA: {
    province: 'PAMPANGA',
    municipality: 'CANDABA',
    region: 'REGION III',
    barangay_list: [
      'BAHAY PARE',
      'BAMBANG',
      'BARANGCA',
      'BARIT',
      'BUAS (POB.)',
      'CUAYANG BUGTONG',
      'DALAYAP',
      'DULONG ILOG',
      'GULAP',
      'LANANG',
      'LOURDES',
      'MAGUMBALI',
      'MANDASIG',
      'MANDILI',
      'MANGGA',
      'MAPANIQUI',
      'PALIGUI',
      'PANGCLARA',
      'PANSINAO',
      'PARALAYA (POB.)',
      'PASIG',
      'PESCADORES (POB.)',
      'PULONG GUBAT',
      'PULONG PALAZAN',
      'SALAPUNGAN',
      'SAN AGUSTIN (POB.)',
      'SANTO ROSARIO',
      'TAGULOD',
      'TALANG',
      'TENEJERO',
      'VIZAL SAN PABLO',
      'VIZAL SANTO CRISTO',
      'VIZAL SANTO NIÑO',
    ],
  },
  FLORIDABLANCA: {
    province: 'PAMPANGA',
    municipality: 'FLORIDABLANCA',
    region: 'REGION III',
    barangay_list: [
      'ANON',
      'APALIT',
      'BASA AIR BASE',
      'BENEDICTO',
      'BODEGA',
      'CABANGCALAN',
      'CALANTAS',
      'CARMENCITA',
      'CONSUELO',
      'DAMPE',
      'DEL CARMEN',
      'FORTUNA',
      'GUTAD',
      'MABICAL',
      'MALIGAYA',
      'MAWACAT',
      'NABUCLOD',
      'PABANLAG',
      'PAGUIRUAN',
      'PALMAYO',
      'PANDAGUIRIG',
      'POBLACION',
      'SAN ANTONIO',
      'SAN ISIDRO',
      'SAN JOSE',
      'SAN NICOLAS',
      'SAN PEDRO',
      'SAN RAMON',
      'SAN ROQUE',
      'SANTA MONICA',
      'SANTO ROSARIO (MALABO)',
      'SOLIB',
      'VALDEZ',
    ],
  },
  GUAGUA: {
    province: 'PAMPANGA',
    municipality: 'GUAGUA',
    region: 'REGION III',
    barangay_list: [
      'ASCOMO',
      'BANCAL',
      'JOSE ABAD SANTOS (SIRAN)',
      'LAMBAC',
      'MAGSAYSAY',
      'MAQUIAPO',
      'NATIVIDAD',
      'PLAZA BURGOS (POB.)',
      'PULUNGMASLE',
      'RIZAL',
      'SAN AGUSTIN',
      'SAN ANTONIO',
      'SAN ISIDRO',
      'SAN JOSE',
      'SAN JUAN',
      'SAN JUAN BAUTISTA',
      'SAN JUAN NEPOMUCENO',
      'SAN MATIAS',
      'SAN MIGUEL (BETIS)',
      'SAN NICOLAS 1ST',
      'SAN NICOLAS 2ND',
      'SAN PABLO',
      'SAN PEDRO',
      'SAN RAFAEL (DUCK ISLAND)',
      'SAN ROQUE',
      'SAN VICENTE (IBUS)',
      'SANTA FILOMENA (POB.)',
      'SANTA INES',
      'SANTA URSULA',
      'SANTO CRISTO',
      'SANTO NIÑO (POB.)',
    ],
  },
  LUBAO: {
    province: 'PAMPANGA',
    municipality: 'LUBAO',
    region: 'REGION III',
    barangay_list: [
      'BALANTACAN',
      'BANCAL PUGAD',
      'BANCAL SINUBLI',
      'BARUYA (SAN RAFAEL)',
      'CALANGAIN',
      'CONCEPCION',
      'DE LA PAZ',
      'DEL CARMEN',
      'DON IGNACIO DIMSON',
      'LOURDES (LAUC PAU)',
      'PRADO SIONGCO',
      'REMEDIOS',
      'SAN AGUSTIN',
      'SAN ANTONIO',
      'SAN FRANCISCO',
      'SAN ISIDRO',
      'SAN JOSE APUNAN',
      'SAN JOSE GUMI',
      'SAN JUAN (POB.)',
      'SAN MATIAS',
      'SAN MIGUEL',
      'SAN NICOLAS 1ST (POB.)',
      'SAN NICOLAS 2ND',
      'SAN PABLO 1ST',
      'SAN PABLO 2ND',
      'SAN PEDRO PALCARANGAN',
      'SAN PEDRO SAUG',
      'SAN ROQUE ARBOL',
      'SAN ROQUE DAU',
      'SAN VICENTE',
      'SANTA BARBARA',
      'SANTA CATALINA',
      'SANTA CRUZ',
      'SANTA LUCIA (POB.)',
      'SANTA MARIA',
      'SANTA MONICA',
      'SANTA RITA',
      'SANTA TERESA 1ST',
      'SANTA TERESA 2ND',
      'SANTIAGO',
      'SANTO CRISTO',
      'SANTO DOMINGO',
      'SANTO NIÑO (PRADO ARUBA)',
      'SANTO TOMAS (POB.)',
    ],
  },
  'MABALACAT': {
    province: 'PAMPANGA',
    municipality: 'MABALACAT',
    region: 'REGION III',
    barangay_list: [
      'ATLU-BOLA',
      'BICAL',
      'BUNDAGUL',
      'CACUTUD',
      'CALUMPANG',
      'CAMACHILES',
      'DAPDAP',
      'DAU',
      'DOLORES',
      'DUQUIT',
      'LAKANDULA',
      'MABIGA',
      'MACAPAGAL VILLAGE',
      'MAMATITANG',
      'MANGALIT',
      'MARCOS VILLAGE',
      'MAWAQUE',
      'PARALAYUNAN',
      'POBLACION',
      'SAN FRANCISCO',
      'SAN JOAQUIN',
      'SANTA INES',
      'SANTA MARIA',
      'SANTO ROSARIO',
      'SAPANG BALEN',
      'SAPANG BIABAS',
      'TABUN',
    ],
  },
  MACABEBE: {
    province: 'PAMPANGA',
    municipality: 'MACABEBE',
    region: 'REGION III',
    barangay_list: [
      'BATASAN',
      'CADUANG TETE',
      'CANDELARIA',
      'CASTULI',
      'CONSUELO',
      'DALAYAP',
      'MATAGUITI',
      'SAN ESTEBAN',
      'SAN FRANCISCO',
      'SAN GABRIEL (POB.)',
      'SAN ISIDRO',
      'SAN JOSE',
      'SAN JUAN',
      'SAN RAFAEL',
      'SAN ROQUE',
      'SAN VICENTE',
      'SANTA CRUZ (POB.)',
      'SANTA LUTGARDA',
      'SANTA MARIA',
      'SANTA RITA (POB.)',
      'SANTO NIÑO',
      'SANTO ROSARIO (POB.)',
      'SAPLAD DAVID',
      'TACASAN',
      'TELACSAN',
    ],
  },
  MAGALANG: {
    province: 'PAMPANGA',
    municipality: 'MAGALANG',
    region: 'REGION III',
    barangay_list: [
      'AYALA',
      'BUCANAN',
      'CAMIAS',
      'DOLORES',
      'ESCALER',
      'LA PAZ',
      'NAVALING',
      'SAN AGUSTIN',
      'SAN ANTONIO',
      'SAN FRANCISO',
      'SAN ILDEFONSO',
      'SAN ISIDRO',
      'SAN JOSE',
      'SAN MIGUEL',
      'SAN NICOLAS 1ST (POB.)',
      'SAN NICOLAS 2ND',
      'SAN PABLO (POB.)',
      'SAN PEDRO I',
      'SAN PEDRO II',
      'SAN ROQUE',
      'SAN VICENTE',
      'SANTA CRUZ (POB.)',
      'SANTA LUCIA',
      'SANTA MARIA',
      'SANTO NIÑO',
      'SANTO ROSARIO',
      'TURU',
    ],
  },
  MASANTOL: {
    province: 'PAMPANGA',
    municipality: 'MASANTOL',
    region: 'REGION III',
    barangay_list: [
      'ALAULI',
      'BAGANG',
      'BALIBAGO',
      'BEBE ANAC',
      'BEBE MATUA',
      'BULACUS',
      'CAMBASI',
      'MALAULI',
      'NIGUI',
      'PALIMPE',
      'PUTI',
      'SAGRADA (TIBAGIN)',
      'SAN AGUSTIN (CAINGIN)',
      'SAN ISIDRO ANAC',
      'SAN ISIDRO MATUA (POB.)',
      'SAN NICOLAS (POB.)',
      'SAN PEDRO',
      'SANTA CRUZ',
      'SANTA LUCIA ANAC (POB.)',
      'SANTA LUCIA MATUA',
      'SANTA LUCIA PAGUIBA',
      'SANTA LUCIA WAKAS',
      'SANTA MONICA (CAINGIN)',
      'SANTO NIÑO',
      'SAPANG KAWAYAN',
      'SUA',
    ],
  },
  MEXICO: {
    province: 'PAMPANGA',
    municipality: 'MEXICO',
    region: 'REGION III',
    barangay_list: [
      'ACLI',
      'ANAO',
      'BALAS',
      'BUENAVISTA',
      'CAMUNING',
      'CAWAYAN',
      'CONCEPCION',
      'CULUBASA',
      'DIVISORIA',
      'DOLORES (PIRING)',
      'EDEN',
      'GANDUS',
      'LAGUNDI',
      'LAPUT',
      'LAUG',
      'MASAMAT',
      'MASANGSANG',
      'NUEVA VICTORIA',
      'PANDACAQUI',
      'PANGATLAN',
      'PANIPUAN',
      'PARIAN (POB.)',
      'SABANILLA',
      'SAN ANTONIO',
      'SAN CARLOS',
      'SAN JOSE MALINO',
      'SAN JOSE MATULID',
      'SAN JUAN',
      'SAN LORENZO',
      'SAN MIGUEL',
      'SAN NICOLAS',
      'SAN PABLO',
      'SAN PATRICIO',
      'SAN RAFAEL',
      'SAN ROQUE',
      'SAN VICENTE',
      'SANTA CRUZ',
      'SANTA MARIA',
      'SANTO DOMINGO',
      'SANTO ROSARIO',
      'SAPANG MAISAC',
      'SUCLABAN',
      'TANGLE (TANGLAY)',
    ],
  },
  MINALIN: {
    province: 'PAMPANGA',
    municipality: 'MINALIN',
    region: 'REGION III',
    barangay_list: [
      'BULAC',
      'DAWE',
      'LOURDES',
      'MANIANGO',
      'SAN FRANCISCO 1ST',
      'SAN FRANCISCO 2ND',
      'SAN ISIDRO',
      'SAN NICOLAS (POB.)',
      'SAN PEDRO',
      'SANTA CATALINA',
      'SANTA MARIA',
      'SANTA RITA',
      'SANTO DOMINGO',
      'SANTO ROSARIO (POB.)',
      'SAPLAD',
    ],
  },
  PORAC: {
    province: 'PAMPANGA',
    municipality: 'PORAC',
    region: 'REGION III',
    barangay_list: [
      'BABO PANGULO',
      'BABO SACAN',
      'BALUBAD',
      'CALZADANG BAYU',
      'CAMIAS',
      'CANGATBA',
      'DIAZ',
      'DOLORES (HACIENDA DOLORES)',
      'INARARO (AETAS)',
      'JALUNG',
      'MANCATIAN',
      'MANIBAUG LIBUTAD',
      'MANIBAUG PARALAYA',
      'MANIBAUG PASIG',
      'MANUALI',
      'MITLA PROPER',
      'PALAT',
      'PIAS',
      'PIO',
      'PLANAS',
      'POBLACION',
      'PULONG SANTOL',
      'SALU',
      'SAN JOSE MITLA',
      'SANTA CRUZ',
      'SAPANG UWAK (AETAS)',
      'SEPUNG BULAUN',
      'SINURA',
      'VILLA MARIA (AETAS)',
    ],
  },
  'SAN FERNANDO': {
    province: 'PAMPANGA',
    municipality: 'SAN FERNANDO',
    region: 'REGION III',
    barangay_list: [
      'ALASAS',
      'BALITI',
      'BULAON',
      'CALULUT',
      'DEL CARMEN',
      'DEL PILAR',
      'DEL ROSARIO',
      'DELA PAZ NORTE',
      'DELA PAZ SUR',
      'DOLORES',
      'JULIANA',
      'LARA',
      'LOURDES',
      'MAGLIMAN',
      'MAIMPIS',
      'MALINO',
      'MALPITIC',
      'PANDARAS',
      'PANIPUAN',
      'PULUNG BULU',
      'QUEBIAUAN',
      'SAGUIN',
      'SAN AGUSTIN',
      'SAN FELIPE',
      'SAN ISIDRO',
      'SAN JOSE',
      'SAN JUAN',
      'SAN NICOLAS',
      'SAN PEDRO',
      'SANTA LUCIA',
      'SANTA TERESITA',
      'SANTO NIÑO',
      'SANTO ROSARIO (POB.)',
      'SINDALAN',
      'TELABASTAGAN',
    ],
  },
  'SAN SIMON': {
    province: 'PAMPANGA',
    municipality: 'SAN SIMON',
    region: 'REGION III',
    barangay_list: [
      'CONCEPCION',
      'DE LA PAZ',
      'SAN AGUSTIN',
      'SAN ISIDRO',
      'SAN JOSE',
      'SAN JUAN (POB.)',
      'SAN MIGUEL',
      'SAN NICOLAS',
      'SAN PABLO LIBUTAD',
      'SAN PABLO PROPER',
      'SAN PEDRO',
      'SANTA CRUZ',
      'SANTA MONICA',
      'SANTO NIÑO',
    ],
  },
  'SANTA RITA': {
    province: 'SAMAR (WESTERN SAMAR)',
    municipality: 'SANTA RITA',
    region: 'REGION VIII',
    barangay_list: [
      'ALEGRIA',
      'ANIBONGAN',
      'ASLUM',
      'BAGOLIBAS',
      'BINANALAN',
      'BOKINGGAN POB. (ZONE I)',
      'BOUGAINVILLA POB. (ZONE II)',
      'CABACUNGAN',
      'CABUNGA-AN',
      'CAMAYSE',
      'CANSADONG',
      'CATICUGAN',
      'DAMPIGAN',
      'GUINBALOT-AN',
      'GUMAMELA POB. (ZONE III)',
      'HINANGUDTAN',
      'IGANG-IGANG',
      'LA PAZ',
      'LUPIG',
      'MAGSAYSAY',
      'MALIGAYA',
      'NEW MANUNCA',
      'OLD MANUNCA',
      'PAGSULHOGON',
      'ROSAL POB. (ZONE IV)',
      'SALVACION',
      'SAN EDUARDO',
      'SAN ISIDRO',
      'SAN JUAN',
      'SAN PASCUAL (CROSSING)',
      'SAN PEDRO',
      'SAN ROQUE',
      'SANTA ELENA',
      'SANTAN POB. (ZONE V)',
      'TAGACAY',
      'TOMINAMOS',
      'TULAY',
      'UNION',
    ],
  },
  'SASMUAN (SEXMOAN)': {
    province: 'PAMPANGA',
    municipality: 'SASMUAN (SEXMOAN)',
    region: 'REGION III',
    barangay_list: [
      'BATANG 1ST',
      'BATANG 2ND',
      'MABUANBUAN',
      'MALUSAC',
      'SABITANAN',
      'SAN ANTONIO',
      'SAN NICOLAS 1ST',
      'SAN NICOLAS 2ND',
      'SAN PEDRO',
      'SANTA LUCIA (POB.)',
      'SANTA MONICA',
      'SANTO TOMAS',
    ],
  },
  ANAO: {
    province: 'TARLAC',
    municipality: 'ANAO',
    region: 'REGION III',
    barangay_list: [
      'BAGUINDOC (BAGUINLOC)',
      'BANTOG',
      'CAMPOS',
      'CARMEN',
      'CASILI',
      'DON RAMON',
      'HERNANDO',
      'POBLACION',
      'RIZAL',
      'SAN FRANCISCO EAST',
      'SAN FRANCISCO WEST',
      'SAN JOSE NORTH',
      'SAN JOSE SOUTH',
      'SAN JUAN',
      'SAN ROQUE',
      'SANTO DOMINGO',
      'SINENSE',
      'SUAVERDEZ',
    ],
  },
  BAMBAN: {
    province: 'TARLAC',
    municipality: 'BAMBAN',
    region: 'REGION III',
    barangay_list: [
      'ANUPUL',
      'BANABA',
      'BANGCU',
      'CULUBASA',
      'DELA CRUZ',
      'LA PAZ',
      'LOURDES',
      'MALONZO',
      'SAN NICOLAS (POB.)',
      'SAN PEDRO',
      'SAN RAFAEL',
      'SAN ROQUE',
      'SAN VICENTE',
      'SANTO NIÑO',
      'VIRGEN DE LOS REMEDIOS (PACALCAL)',
    ],
  },
  CAMILING: {
    province: 'TARLAC',
    municipality: 'CAMILING',
    region: 'REGION III',
    barangay_list: [
      'ANOLING 1ST',
      'ANOLING 2ND',
      'ANOLING 3RD',
      'BACABAC',
      'BACSAY',
      'BANCAY 1ST',
      'BANCAY 2ND',
      'BILAD',
      'BIRBIRA',
      'BOBON 1ST',
      'BOBON 2ND',
      'BOBON CAAROSIPAN',
      'CABANABAAN',
      'CACAMILINGAN NORTE',
      'CACAMILINGAN SUR',
      'CANIAG',
      'CARAEL',
      'CAYAOAN',
      'CAYASAN',
      'FLORIDA',
      'LASONG',
      'LIBUEG',
      'MALACAMPA',
      'MANAKEM',
      'MANUPEG',
      'MARAWI',
      'MATUBOG',
      'NAGRAMBACAN',
      'NAGSERIALAN',
      'PALIMBO PROPER',
      'PALIMBO-CAAROSIPAN',
      'PAO 1ST',
      'PAO 2ND',
      'PAO 3RD',
      'PAPAAC',
      'PINDANGAN 1ST',
      'PINDANGAN 2ND',
      'POBLACION A',
      'POBLACION B',
      'POBLACION C',
      'POBLACION D',
      'POBLACION E',
      'POBLACION F',
      'POBLACION G',
      'POBLACION H',
      'POBLACION I',
      'POBLACION J',
      'SANTA MARIA',
      'SAWAT',
      'SINILIAN 1ST',
      'SINILIAN 2ND',
      'SINILIAN 3RD',
      'SINILIAN CACALIBOSOAN',
      'SINULATAN 1ST',
      'SINULATAN 2ND',
      'SURGUI 1ST',
      'SURGUI 2ND',
      'SURGUI 3RD',
      'TAMBUGAN',
      'TELBANG',
      'TUEC',
    ],
  },
  CAPAS: {
    province: 'TARLAC',
    municipality: 'CAPAS',
    region: 'REGION III',
    barangay_list: [
      'ARANGUREN',
      'BUENO',
      'CRISTO REY',
      'CUBCUB (POB.)',
      'CUTCUT 1ST',
      'CUTCUT 2ND',
      'DOLORES',
      'ESTRADA (CALINGCUAN)',
      'LAWY',
      'MANGA',
      'MANLAPIG',
      'MARUGLU',
      "O'DONNELL",
      'SANTA JULIANA',
      'SANTA LUCIA',
      'SANTA RITA',
      'SANTO DOMINGO 1ST',
      'SANTO DOMINGO 2ND',
      'SANTO ROSARIO',
      'TALAGA',
    ],
  },
  'TARLAC': {
    province: 'TARLAC',
    municipality: 'TARLAC',
    region: 'REGION III',
    barangay_list: [
      'AGUSO',
      'ALVINDIA SEGUNDO',
      'AMUCAO',
      'ARMENIA',
      'ASTURIAS',
      'ATIOC',
      'BALANTI',
      'BALETE',
      'BALIBAGO I',
      'BALIBAGO II',
      'BALINGCANAWAY',
      'BANABA',
      'BANTOG',
      'BARAS-BARAS',
      'BATANG-BATANG',
      'BINAUGANAN',
      'BORA',
      'BUENAVISTA',
      'BUHILIT (BUBULIT)',
      'BUROT',
      'CALINGCUAN',
      'CAPEHAN',
      'CARANGIAN',
      'CARE',
      'CENTRAL',
      'CULIPAT',
      'CUT-CUT I',
      'CUT-CUT II',
      'DALAYAP',
      'DELA PAZ',
      'DOLORES',
      'LAOANG',
      'LIGTASAN',
      'LOURDES',
      'MABINI',
      'MALIGAYA',
      'MALIWALO',
      'MAPALACSIAO',
      'MAPALAD',
      'MATADERO',
      'MATATALAIB',
      'PARAISO',
      'POBLACION',
      'SALAPUNGAN',
      'SAN CARLOS',
      'SAN FRANCISCO',
      'SAN ISIDRO',
      'SAN JOSE',
      'SAN JOSE DE URQUICO',
      'SAN JUAN DE MATA',
      'SAN LUIS',
      'SAN MANUEL',
      'SAN MIGUEL',
      'SAN NICOLAS',
      'SAN PABLO',
      'SAN PASCUAL',
      'SAN RAFAEL',
      'SAN ROQUE',
      'SAN SEBASTIAN',
      'SAN VICENTE',
      'SANTA CRUZ (ALVINDIA PRIMERO)',
      'SANTA MARIA',
      'SANTO CRISTO',
      'SANTO DOMINGO',
      'SANTO NIÑO',
      'SAPANG MARAGUL',
      'SAPANG TAGALOG',
      'SEPUNG CALZADA',
      'SINAIT',
      'SUIZO',
      'TARIJI',
      'TIBAG',
      'TIBAGAN',
      'TRINIDAD (TRINIDAD PRIMERO)',
      'UNGOT',
      'VILLA BACOLOR',
    ],
  },
  GERONA: {
    province: 'TARLAC',
    municipality: 'GERONA',
    region: 'REGION III',
    barangay_list: [
      'ABAGON',
      'AMACALAN',
      'APSAYAN',
      'AYSON',
      'BAWA',
      'BUENLAG',
      'BULARIT',
      'CALAYAAN',
      'CARBONEL',
      'CARDONA',
      'CATURAY',
      'DANZO',
      'DICOLOR',
      'DON BASILIO',
      'LUNA',
      'MABINI',
      'MAGASPAC',
      'MALAYEP',
      'MATAPITAP',
      'MATAYUMCAB',
      'NEW SALEM',
      'OLOYBUAYA',
      'PADAPADA',
      'PARSOLINGAN',
      'PINASLING (PINASUNG)',
      'PLASTADO',
      'POBLACION 1',
      'POBLACION 2',
      'POBLACION 3',
      'QUEZON',
      'RIZAL',
      'SALAPUNGAN',
      'SAN AGUSTIN',
      'SAN ANTONIO',
      'SAN BARTOLOME',
      'SAN JOSE',
      'SANTA LUCIA',
      'SANTIAGO',
      'SEMBRANO',
      'SINGAT',
      'SULIPA',
      'TAGUMBAO',
      'TANGCARAN',
      'VILLA PAZ',
    ],
  },
  MAYANTOC: {
    province: 'TARLAC',
    municipality: 'MAYANTOC',
    region: 'REGION III',
    barangay_list: [
      'AMBALINGIT',
      'BAYBAYAOAS',
      'BIGBIGA',
      'BINBINACA',
      'CALABTANGAN',
      'CAOCAOAYAN',
      'CARABAOAN',
      'CUBCUB',
      'GAYONGGAYONG',
      'GOSSOOD',
      'LABNEY',
      'MAMONIT',
      'MANINIOG',
      'MAPANDAN',
      'NAMBALAN',
      'PEDRO L. QUINES',
      'PITOMBAYOG',
      'POBLACION NORTE',
      'POBLACION SUR',
      'ROTROTTOOC',
      'SAN BARTOLOME',
      'SAN JOSE',
      'TALDIAPAN',
      'TANGCARANG',
    ],
  },
  MONCADA: {
    province: 'TARLAC',
    municipality: 'MONCADA',
    region: 'REGION III',
    barangay_list: [
      'ABLANG-SAPANG',
      'ARINGIN',
      'ATENCIO',
      'BANAOANG EAST',
      'BANAOANG WEST',
      'BAQUERO NORTE',
      'BAQUERO SUR',
      'BURGOS',
      'CALAMAY',
      'CALAPAN',
      'CAMANGAAN EAST',
      'CAMANGAAN WEST',
      'CAMPOSANTO 1 - NORTE',
      'CAMPOSANTO 1 - SUR',
      'CAMPOSANTO 2',
      'CAPAOAYAN',
      'LAPSING',
      'MABINI',
      'MALUAC',
      'POBLACION 1',
      'POBLACION 2',
      'POBLACION 3',
      'POBLACION 4',
      'RIZAL',
      'SAN JUAN',
      'SAN JULIAN',
      'SAN LEON',
      'SAN PEDRO',
      'SAN ROQUE',
      'SANTA LUCIA EAST',
      'SANTA LUCIA WEST',
      'SANTA MARIA',
      'SANTA MONICA',
      'TOLEGA NORTE',
      'TOLEGA SUR',
      'TUBECTUBANG',
      'VILLA',
    ],
  },
  PANIQUI: {
    province: 'TARLAC',
    municipality: 'PANIQUI',
    region: 'REGION III',
    barangay_list: [
      'ABOGADO',
      'ACOCOLAO',
      'ADUAS',
      'APULID',
      'BALAOANG',
      'BARANG (BORANG)',
      'BRILLANTE',
      'BURGOS',
      'CABAYAOASAN',
      'CANAN',
      'CARINO',
      'CAYANGA',
      'COLIBANGBANG',
      'CORAL',
      'DAPDAP',
      'ESTACION',
      'MABILANG',
      'MANAOIS',
      'MATALAPITAP',
      'NAGMISAAN',
      'NANCAMARINAN',
      'NIPACO',
      'PATALAN',
      'POBLACION NORTE',
      'POBLACION SUR',
      'RANG-AYAN',
      'SALUMAGUE',
      'SAMPUT',
      'SAN CARLOS',
      'SAN ISIDRO',
      'SAN JUAN DE MILLA',
      'SANTA INES',
      'SINIGPIT',
      'TABLANG',
      'VENTENILLA',
    ],
  },
  PURA: {
    province: 'TARLAC',
    municipality: 'PURA',
    region: 'REGION III',
    barangay_list: [
      'BALITE',
      'BUENAVISTA',
      'CADANGLAAN',
      'ESTIPONA',
      'LINAO',
      'MAASIN',
      'MATINDEG',
      'MAUNGIB',
      'NAYA',
      'NILASIN 1ST',
      'NILASIN 2ND',
      'POBLACION 1',
      'POBLACION 2',
      'POBLACION 3',
      'POROC',
      'SINGAT',
    ],
  },
  RAMOS: {
    province: 'TARLAC',
    municipality: 'RAMOS',
    region: 'REGION III',
    barangay_list: [
      'CORAL-ILOCO',
      'GUITEB',
      'PANCE',
      'POBLACION CENTER',
      'POBLACION NORTH',
      'POBLACION SOUTH',
      'SAN JUAN',
      'SAN RAYMUNDO',
      'TOLEDO',
    ],
  },
  'SAN CLEMENTE': {
    province: 'TARLAC',
    municipality: 'SAN CLEMENTE',
    region: 'REGION III',
    barangay_list: [
      'BALLOC',
      'BAMBAN',
      'CASIPO',
      'CATAGUDINGAN',
      'DALDALAYAP',
      'DOCLONG 1',
      'DOCLONG 2',
      'MAASIN',
      'NAGSABARAN',
      'PIT-AO',
      'POBLACION NORTE',
      'POBLACION SUR',
    ],
  },
  'SANTA IGNACIA': {
    province: 'TARLAC',
    municipality: 'SANTA IGNACIA',
    region: 'REGION III',
    barangay_list: [
      'BALDIOS',
      'BOTBOTONES',
      'CAANAMONGAN',
      'CABARUAN',
      'CABUGBUGAN',
      'CADULDULAOAN',
      'CALIPAYAN',
      'MACAGUING',
      'NAMBALAN',
      'PADAPADA',
      'PILPILA',
      'PINPINAS',
      'POBLACION EAST',
      'POBLACION WEST',
      'PUGO-CECILIO',
      'SAN FRANCISCO',
      'SAN SOTERO',
      'SAN VICENTE',
      'SANTA INES CENTRO',
      'SANTA INES EAST',
      'SANTA INES WEST',
      'TAGUIPORO',
      'TIMMAGUAB',
      'VARGAS',
    ],
  },
  VICTORIA: {
    province: 'NORTHERN SAMAR',
    municipality: 'VICTORIA',
    region: 'REGION VIII',
    barangay_list: [
      'ACEDILLO',
      'BUENASUERTE',
      'BUENOS AIRES',
      'COLAB-OG',
      'ERENAS',
      'LIBERTAD',
      'LUISITA',
      'LUNGIB',
      'MAXVILLA',
      'PASABUENA',
      'SAN LAZARO',
      'SAN MIGUEL',
      'SAN ROMAN',
      'ZONE I (POB.)',
      'ZONE II (POB.)',
      'ZONE III (POB.)',
    ],
  },
  BOTOLAN: {
    province: 'ZAMBALES',
    municipality: 'BOTOLAN',
    region: 'REGION III',
    barangay_list: [
      'BANCAL',
      'BANGAN',
      'BATONLAPOC',
      'BELBEL',
      'BENEG',
      'BINUCLUTAN',
      'BURGOS',
      'CABATUAN',
      'CAPAYAWAN',
      'CARAEL',
      'DANACBUNGA',
      'MAGUISGUIS',
      'MALOMBOY',
      'MAMBOG',
      'MORAZA',
      'NACOLCOL',
      'OWAOG-NIBLOC',
      'PACO (POB.)',
      'PALIS',
      'PANAN',
      'PAREL',
      'PAUDPOD',
      'POONBATO',
      'PORAC',
      'SAN ISIDRO',
      'SAN JUAN',
      'SAN MIGUEL',
      'SANTIAGO',
      'TAMPO (POB.)',
      'TAUGTOG',
      'VILLAR',
    ],
  },
  CABANGAN: {
    province: 'ZAMBALES',
    municipality: 'CABANGAN',
    region: 'REGION III',
    barangay_list: [
      'ANONANG',
      'APO-APO',
      'AREW',
      'BANUAMBAYO (POB.)',
      'CADMANG-RESERVA',
      'CAMILING (CAMIING)',
      'CASABAAN',
      'DEL CARMEN (POB.)',
      'DOLORES (POB.)',
      'FELMIDA-DIAZ',
      'LAOAG',
      'LOMBOY',
      'LONGOS',
      'MABANGLIT',
      'NEW SAN JUAN',
      'SAN ANTONIO',
      'SAN ISIDRO',
      'SAN JUAN (POB.)',
      'SAN RAFAEL',
      'SANTA RITA',
      'SANTO NIÑO',
      'TONDO',
    ],
  },
  CANDELARIA: {
    province: 'QUEZON',
    municipality: 'CANDELARIA',
    region: 'REGION IV-A',
    barangay_list: [
      'BUENAVISTA EAST',
      'BUENAVISTA WEST',
      'BUKAL NORTE',
      'BUKAL SUR',
      'KINATIHAN I',
      'KINATIHAN II',
      'MALABANBAN NORTE',
      'MALABANBAN SUR',
      'MANGILAG NORTE',
      'MANGILAG SUR',
      'MASALUKOT I',
      'MASALUKOT II',
      'MASALUKOT III',
      'MASALUKOT IV',
      'MASALUKOT V',
      'MASIN NORTE',
      'MASIN SUR',
      'MAYABOBO',
      'PAHINGA NORTE',
      'PAHINGA SUR',
      'POBLACION',
      'SAN ANDRES',
      'SAN ISIDRO',
      'SANTA CATALINA NORTE',
      'SANTA CATALINA SUR',
    ],
  },
  CASTILLEJOS: {
    province: 'ZAMBALES',
    municipality: 'CASTILLEJOS',
    region: 'REGION III',
    barangay_list: [
      'BALAYBAY',
      'BUENAVISTA',
      'DEL PILAR',
      'LOOC',
      'MAGSAYSAY',
      'NAGBAYAN',
      'NAGBUNGA',
      'SAN AGUSTIN',
      'SAN JOSE (POB.)',
      'SAN JUAN (POB.)',
      'SAN NICOLAS',
      'SAN PABLO (POB.)',
      'SAN ROQUE',
      'SANTA MARIA',
    ],
  },
  IBA: {
    province: 'ZAMBALES',
    municipality: 'IBA',
    region: 'REGION III',
    barangay_list: [
      'AMUNGAN',
      'BANGANTALINGA',
      'DIRITA-BALOGUEN',
      'LIPAY-DINGIN-PANIBUATAN',
      'PALANGINAN (PALANGUINAN-TAMBAK)',
      'SAN AGUSTIN',
      'SANTA BARBARA',
      'SANTO ROSARIO',
      'ZONE 1 POB. (LIBABA)',
      'ZONE 2 POB. (AYPA)',
      'ZONE 3 POB. (BOTLAY)',
      'ZONE 4 POB. (SAGAPAN)',
      'ZONE 5 POB. (BANO)',
      'ZONE 6 POB. (BAYTAN)',
    ],
  },
  MASINLOC: {
    province: 'ZAMBALES',
    municipality: 'MASINLOC',
    region: 'REGION III',
    barangay_list: [
      'BALOGANON',
      'BAMBAN',
      'BANI',
      'COLLAT',
      'INHOBOL',
      'NORTH POBLACION',
      'SAN LORENZO',
      'SAN SALVADOR',
      'SANTA RITA',
      'SANTO ROSARIO',
      'SOUTH POBLACION',
      'TALTAL',
      'TAPUAC',
    ],
  },
  'OLONGAPO': {
    province: 'ZAMBALES',
    municipality: 'OLONGAPO',
    region: 'REGION III',
    barangay_list: [
      'ASINAN',
      'BANICAIN',
      'BARRETO',
      'EAST BAJAC-BAJAC',
      'EAST TAPINAC',
      'GORDON HEIGHTS',
      'KALAKLAN',
      'MABAYUAN',
      'NEW CABALAN',
      'NEW ILALIM',
      'NEW KABABAE',
      'NEW KALALAKE',
      'OLD CABALAN',
      'PAG-ASA',
      'SANTA RITA',
      'WEST BAJAC-BAJAC',
      'WEST TAPINAC',
    ],
  },
  PALAUIG: {
    province: 'ZAMBALES',
    municipality: 'PALAUIG',
    region: 'REGION III',
    barangay_list: [
      'ALWA',
      'BATO',
      'BULAWEN',
      'CAUYAN',
      'EAST POBLACION',
      'GARRETA',
      'LIBABA',
      'LIOZON',
      'LIPAY',
      'LOCLOC',
      'MACARANG',
      'MAGALAWA',
      'PANGOLINGAN',
      'SALAZA',
      'SAN JUAN',
      'SANTO NIÑO',
      'SANTO TOMAS',
      'TITION (SAN VICENTE)',
      'WEST POBLACION',
    ],
  },
  'SAN FELIPE': {
    province: 'ZAMBALES',
    municipality: 'SAN FELIPE',
    region: 'REGION III',
    barangay_list: [
      'AMAGNA (POB.)',
      'APOSTOL (POB.)',
      'BALINCAGUING',
      'FARAÑAL (POB.)',
      'FERIA (POB.)',
      'MALOMA',
      'MANGLICMOT (POB.)',
      'ROSETE (POB.)',
      'SAN RAFAEL',
      'SANTO NIÑO',
      'SINDOL',
    ],
  },
  'SAN MARCELINO': {
    province: 'ZAMBALES',
    municipality: 'SAN MARCELINO',
    region: 'REGION III',
    barangay_list: [
      'AGLAO',
      'BUHAWEN',
      'BURGOS (POB.)',
      'CENTRAL (POB.)',
      'CONSUELO NORTE',
      'CONSUELO SUR (POB.)',
      'LA PAZ (POB.)',
      'LAOAG',
      'LINASIN',
      'LINUSUNGAN',
      'LUCERO (POB.)',
      'NAGBUNGA',
      'RABANES',
      'RIZAL (POB.)',
      'SAN GUILLERMO (POB.)',
      'SAN ISIDRO (POB.)',
      'SAN RAFAEL',
      'SANTA FE',
    ],
  },
  'SAN NARCISO': {
    province: 'QUEZON',
    municipality: 'SAN NARCISO',
    region: 'REGION IV-A',
    barangay_list: [
      'ABUYON',
      'ANDRES BONIFACIO',
      'BANI',
      'BAYANIHAN (POB.)',
      'BINAY',
      'BUENAVISTA',
      'BUSOKBUSOKAN',
      'CALWIT',
      'GUINHALINAN',
      'LACDAYAN',
      'MAGUITING',
      'MALIGAYA (POB.)',
      'MANLAMPONG',
      'PAGDADAMAYAN (POB.)',
      'PAGKAKAISA (POB.)',
      'PUNTA',
      'RIZAL',
      'SAN ISIDRO',
      'SAN JUAN',
      'SAN VICENTE',
      'VIGO CENTRAL',
      'VILLA AURIN (PINAGSAMA)',
      'VILLA REYES',
      'WHITE CLIFF',
    ],
  },
  SUBIC: {
    province: 'ZAMBALES',
    municipality: 'SUBIC',
    region: 'REGION III',
    barangay_list: [
      'ANINGWAY SACATIHAN',
      'ASINAN POBLACION',
      'ASINAN PROPER',
      'BARACA-CAMACHILE (POB.)',
      'BATIAWAN',
      'CALAPACUAN',
      'CALAPANDAYAN (POB.)',
      'CAWAG',
      'ILWAS (POB.)',
      'MANGAN-VACA',
      'MATAIN',
      'NAUGSOL',
      'PAMATAWAN',
      'SAN ISIDRO',
      'SANTO TOMAS',
      'WAWANDUE (POB.)',
    ],
  },
  AGONCILLO: {
    province: 'BATANGAS',
    municipality: 'AGONCILLO',
    region: 'REGION IV-A',
    barangay_list: [
      'ADIA',
      'BAGONG SIKAT',
      'BALANGON',
      'BANGIN',
      'BANYAGA',
      'BARIGON',
      'BILIBINWANG',
      'CORAL NA MUNTI',
      'GUITNA',
      'MABINI',
      'PAMIGA',
      'PANHULAN',
      'PANSIPIT',
      'POBLACION',
      'POOK',
      'SAN JACINTO',
      'SAN TEODORO',
      'SANTA CRUZ',
      'SANTO TOMAS',
      'SUBIC IBABA',
      'SUBIC ILAYA',
    ],
  },
  ALITAGTAG: {
    province: 'BATANGAS',
    municipality: 'ALITAGTAG',
    region: 'REGION IV-A',
    barangay_list: [
      'BALAGBAG',
      'CONCEPCION',
      'CONCORDIA',
      'DALIPIT EAST',
      'DALIPIT WEST',
      'DOMINADOR EAST',
      'DOMINADOR WEST',
      'MUNLAWIN NORTE',
      'MUNLAWIN SUR',
      'MUZON PRIMERO',
      'MUZON SEGUNDO',
      'PINAGKURUSAN',
      'PING-AS',
      'POBLACION EAST',
      'POBLACION WEST',
      'SAN JOSE',
      'SAN JUAN',
      'SANTA CRUZ',
      'TADLAC',
    ],
  },
  BALAYAN: {
    province: 'BATANGAS',
    municipality: 'BALAYAN',
    region: 'REGION IV-A',
    barangay_list: [
      'BACLARAN',
      'BARANGAY 1 (POB.)',
      'BARANGAY 2 (POB.)',
      'BARANGAY 3 (POB.)',
      'BARANGAY 4 (POB.)',
      'BARANGAY 5 (POB.)',
      'BARANGAY 6 (POB.)',
      'BARANGAY 7 (POB.)',
      'BARANGAY 8 (POB.)',
      'BARANGAY 9 (POB.)',
      'BARANGAY 10 (POB.)',
      'BARANGAY 11 (POB.)',
      'BARANGAY 12 (POB.)',
      'CALAN',
      'CALOOCAN',
      'CALZADA',
      'CANDA',
      'CARENAHAN',
      'CAYBUNGA',
      'CAYPONCE',
      'DALIG',
      'DAO',
      'DILAO',
      'DUHATAN',
      'DURUNGAO',
      'GIMALAS',
      'GUMAMELA',
      'LAGNAS',
      'LANATAN',
      'LANGGANGAN',
      'LUCBAN POOK',
      'LUCBAN PUTOL',
      'MAGABE',
      'MALALAY',
      'MUNTING TUBIG',
      'NAVOTAS',
      'PALIKPIKAN',
      'PATUGO',
      'POOC',
      'SAMBAT',
      'SAMPAGA',
      'SAN JUAN',
      'SAN PIRO',
      'SANTOL',
      'SUKOL',
      'TACTAC',
      'TALUDTUD',
      'TANGGOY',
    ],
  },
  BALETE: {
    province: 'AKLAN',
    municipality: 'BALETE',
    region: 'REGION VI',
    barangay_list: [
      'ARANAS',
      'ARCANGEL',
      'CALIZO',
      'CORTES',
      'FELICIANO',
      'FULGENCIO',
      'GUANKO',
      'MORALES',
      'OQUENDO',
      'POBLACION',
    ],
  },
  'BATANGAS': {
    province: 'BATANGAS',
    municipality: 'BATANGAS',
    region: 'REGION IV-A',
    barangay_list: [
      'ALANGILAN',
      'BALAGTAS',
      'BALETE',
      'BANABA CENTER',
      'BANABA IBABA',
      'BANABA KANLURAN',
      'BANABA SILANGAN',
      'BARANGAY 1 (POB.)',
      'BARANGAY 2 (POB.)',
      'BARANGAY 3 (POB.)',
      'BARANGAY 4 (POB.)',
      'BARANGAY 5 (POB.)',
      'BARANGAY 6 (POB.)',
      'BARANGAY 7 (POB.)',
      'BARANGAY 8 (POB.)',
      'BARANGAY 9 (POB.)',
      'BARANGAY 10 (POB.)',
      'BARANGAY 11 (POB.)',
      'BARANGAY 12 (POB.)',
      'BARANGAY 13 (POB.)',
      'BARANGAY 14 (POB.)',
      'BARANGAY 15 (POB.)',
      'BARANGAY 16 (POB.)',
      'BARANGAY 17 (POB.)',
      'BARANGAY 18 (POB.)',
      'BARANGAY 19 (POB.)',
      'BARANGAY 20 (POB.)',
      'BARANGAY 21 (POB.)',
      'BARANGAY 22 (POB.)',
      'BARANGAY 23 (POB.)',
      'BARANGAY 24 (POB.)',
      'BILOGO',
      'BOLBOK',
      'BUKAL',
      'CALICANTO',
      'CATANDALA',
      'CONCEPCION',
      'CONDE ITAAS',
      'CONDE LABAK',
      'CUTA',
      'DALIG',
      'DELA PAZ',
      'DELA PAZ PULOT APLAYA',
      'DELA PAZ PULOT ITAAS',
      'DOMOCLAY',
      'DUMANTAY',
      'GULOD ITAAS',
      'GULOD LABAK',
      'HALIGUE KANLURAN',
      'HALIGUE SILANGAN',
      'ILIHAN',
      'KUMBA',
      'KUMINTANG IBABA',
      'KUMINTANG ILAYA',
      'LIBJO',
      'LIPONPON, ISLA VERDE',
      'MAAPAS',
      'MABACONG (MATOCO)',
      'MAHABANG DAHILIG',
      'MAHABANG PARANG',
      'MAHACOT KANLURAN',
      'MAHACOT SILANGAN',
      'MALALIM',
      'MALIBAYO',
      'MALITAM',
      'MARUCLAP',
      'PAGKILATAN',
      'PAHARANG KANLURAN',
      'PAHARANG SILANGAN',
      'PALLOCAN KANLURAN',
      'PALLOCAN SILANGAN',
      'PINAMUCAN',
      'PINAMUCAN IBABA',
      'PINAMUCAN SILANGAN',
      'SAMPAGA',
      'SAN AGAPITO, ISLA VERDE',
      'SAN AGUSTIN KANLURAN, ISLA VERDE',
      'SAN AGUSTIN SILANGAN, ISLA VERDE',
      'SAN ANDRES, ISLA VERDE',
      'SAN ANTONIO, ISLA VERDE',
      'SAN ISIDRO',
      'SAN JOSE SICO',
      'SAN MIGUEL',
      'SAN PEDRO',
      'SANTA CLARA',
      'SANTA RITA APLAYA',
      'SANTA RITA KARSADA',
      'SANTO DOMINGO',
      'SANTO NIÑO',
      'SIMLONG',
      'SIRANG LUPA',
      'SOROSORO IBABA',
      'SOROSORO ILAYA',
      'SOROSORO KARSADA',
      'TABANGAO AMBULONG',
      'TABANGAO APLAYA (TABANGAO PROPER)',
      'TABANGAO DAO',
      'TALAHIB PANDAYAN',
      'TALAHIB PAYAPA',
      'TALUMPOK KANLURAN',
      'TALUMPOK SILANGAN',
      'TINGA ITAAS',
      'TINGA LABAK',
      'TULO',
      'WAWA',
    ],
  },
  BAUAN: {
    province: 'BATANGAS',
    municipality: 'BAUAN',
    region: 'REGION IV-A',
    barangay_list: [
      'ALAGAO',
      'APLAYA',
      'AS-IS',
      'BAGONG SILANG',
      'BAGUILAWA',
      'BALAYONG',
      'BARANGAY I (POB.)',
      'BARANGAY II (POB.)',
      'BARANGAY III (POB.)',
      'BARANGAY IV (POB.)',
      'BOLO',
      'COLVO',
      'CUPANG',
      'DURUNGAO',
      'GULIBAY',
      'INICBULAN',
      'LOCLOC',
      'MAGALANG-GALANG',
      'MALINDIG',
      'MANALUPONG',
      'MANGHINAO PROPER',
      'MANGHINAO UNO',
      'NEW DANGLAYAN',
      'ORENSE',
      'PITUGO',
      'RIZAL',
      'SAMPAGUITA',
      'SAN AGUSTIN',
      'SAN ANDRES PROPER',
      'SAN ANDRES UNO',
      'SAN DIEGO',
      'SAN MIGUEL',
      'SAN PABLO',
      'SAN PEDRO',
      'SAN ROQUE',
      'SAN TEODORO',
      'SAN VICENTE',
      'SANTA MARIA',
      'SANTO DOMINGO',
      'SINALA',
    ],
  },
  CALACA: {
    province: 'BATANGAS',
    municipality: 'CALACA',
    region: 'REGION IV-A',
    barangay_list: [
      'BACLAS',
      'BAGONG TUBIG',
      'BALIMBING',
      'BAMBANG',
      'BARANGAY 1 (POB.)',
      'BARANGAY 2 (POB.)',
      'BARANGAY 3 (POB.)',
      'BARANGAY 4 (POB.)',
      'BARANGAY 5 (POB.)',
      'BARANGAY 6 (POB.)',
      'BISAYA',
      'CAHIL',
      'CALANTAS',
      'CALUANGAN',
      'CAMASTILISAN',
      'CORAL NI BACAL',
      'CORAL NI LOPEZ (SUGOD)',
      'DACANLAO',
      'DILA',
      'LOMA',
      'LUMBANG CALZADA',
      'LUMBANG NA BATA',
      'LUMBANG NA MATANDA',
      'MADALUNOT',
      'MAKINA',
      'MATIPOK',
      'MUNTING CORAL',
      'NIYUGAN',
      'PANTAY',
      'PUTING BATO EAST',
      'PUTING BATO WEST',
      'PUTING KAHOY',
      'QUISUMBING',
      'SALONG',
      'SAN RAFAEL',
      'SINISIAN',
      'TAKLANG ANAK',
      'TALISAY',
      'TAMAYO',
      'TIMBAIN',
    ],
  },
  CALATAGAN: {
    province: 'BATANGAS',
    municipality: 'CALATAGAN',
    region: 'REGION IV-A',
    barangay_list: [
      'BAGONG SILANG',
      'BAHA',
      'BALIBAGO',
      'BALITOC',
      'BARANGAY 1 (POB.)',
      'BARANGAY 2 (POB.)',
      'BARANGAY 3 (POB.)',
      'BARANGAY 4 (POB.)',
      'BIGA',
      'BUCAL',
      'CARLOSA',
      'CARRETUNAN',
      'ENCARNACION',
      'GULOD',
      'HUKAY',
      'LUCSUHIN',
      'LUYA',
      'PARAISO',
      'QUILITISAN',
      'REAL',
      'SAMBUNGAN',
      'SANTA ANA',
      'TALIBAYOG',
      'TALISAY',
      'TANAGAN',
    ],
  },
  CUENCA: {
    province: 'BATANGAS',
    municipality: 'CUENCA',
    region: 'REGION IV-A',
    barangay_list: [
      'BALAGBAG',
      'BARANGAY 1 (POB.)',
      'BARANGAY 2 (POB.)',
      'BARANGAY 3 (POB.)',
      'BARANGAY 4 (POB.)',
      'BARANGAY 5 (POB.)',
      'BARANGAY 6 (POB.)',
      'BARANGAY 7 (POB.)',
      'BARANGAY 8 (POB.)',
      'BUNGAHAN',
      'CALUMAYIN',
      'DALIPIT EAST',
      'DALIPIT WEST',
      'DITA',
      'DON JUAN',
      'EMMANUEL',
      'IBABAO',
      'LABAC',
      'PINAGKAISAHAN',
      'SAN FELIPE',
      'SAN ISIDRO',
    ],
  },
  IBAAN: {
    province: 'BATANGAS',
    municipality: 'IBAAN',
    region: 'REGION IV-A',
    barangay_list: [
      'BAGO',
      'BALANGA',
      'BUNGAHAN',
      'CALAMIAS',
      'CATANDALA',
      'COLIAT',
      'DAYAPAN',
      'LAPU-LAPU',
      'LUCSUHIN',
      'MABALOR',
      'MALAININ',
      'MATALA',
      'MUNTING-TUBIG',
      'PALINDAN',
      'PANGAO',
      'PANGHAYAAN',
      'POBLACION',
      'QUILO',
      'SABANG',
      'SALABAN I',
      'SALABAN II',
      'SAN AGUSTIN',
      'SANDALAN',
      'SANTO NIÑO',
      'TALAIBON',
      'TULAY NA PATPAT',
    ],
  },
  LAUREL: {
    province: 'BATANGAS',
    municipality: 'LAUREL',
    region: 'REGION IV-A',
    barangay_list: [
      'AS-IS',
      'BALAKILONG',
      'BARANGAY 1 (POB.)',
      'BARANGAY 2 (POB.)',
      'BARANGAY 3 (POB.)',
      'BARANGAY 4 (POB.)',
      'BARANGAY 5 (POB.)',
      'BERINAYAN',
      'BUGAAN EAST',
      'BUGAAN WEST',
      'BUSO-BUSO',
      'DAYAP ITAAS',
      'GULOD',
      'J. LEVISTE',
      'MOLINETE',
      'NIYUGAN',
      'PALIPARAN',
      'SAN GABRIEL',
      'SAN GREGORIO',
      'SANTA MARIA',
      'TICUB',
    ],
  },
  LEMERY: {
    province: 'ILOILO',
    municipality: 'LEMERY',
    region: 'REGION VI',
    barangay_list: [
      'AGPIPILI',
      'ALCANTARA',
      'ALMEÑANA',
      'ANABO',
      'BANKAL',
      'BUENAVISTA',
      'CABANTOHAN',
      'CAPIÑAHAN',
      'DALIPE',
      'DAPDAPAN',
      'GERONGAN',
      'IMBAULAN',
      'LAYOGBATO',
      'MARAPAL',
      'MILAN',
      'NAGSULANG',
      'NASAPAHAN',
      'OMIO',
      'PACUAN',
      'POBLACION NW ZONE',
      'POBLACION SE ZONE',
      'PONTOC',
      'SAN ANTONIO',
      'SAN DIEGO',
      'SAN JOSE MOTO',
      'SEPANTON',
      'SINCUA',
      'TABUNAN',
      'TUGAS',
      'VELASCO',
      'YAWYAWAN',
    ],
  },
  LIAN: {
    province: 'BATANGAS',
    municipality: 'LIAN',
    region: 'REGION IV-A',
    barangay_list: [
      'BAGONG POOK',
      'BALIBAGO',
      'BARANGAY 1 (POB.)',
      'BARANGAY 2 (POB.)',
      'BARANGAY 3 (POB.)',
      'BARANGAY 4 (POB.)',
      'BARANGAY 5 (POB.)',
      'BINUBUSAN',
      'BUNGAHAN',
      'CUMBA',
      'HUMAYINGAN',
      'KAPITO',
      'LUMANIAG',
      'LUYAHAN',
      'MALARUHATAN',
      'MATABUNGKAY',
      'PRENZA',
      'PUTING-KAHOY',
      'SAN DIEGO',
    ],
  },
  'LIPA': {
    province: 'BATANGAS',
    municipality: 'LIPA',
    region: 'REGION IV-A',
    barangay_list: [
      'ADYA',
      'ANILAO',
      'ANILAO-LABAC',
      'ANTIPOLO DEL NORTE',
      'ANTIPOLO DEL SUR',
      'BAGONG POOK',
      'BALINTAWAK',
      'BANAYBANAY',
      'BARANGAY 12 (POB.)',
      'BOLBOK',
      'BUGTONG NA PULO',
      'BULACNIN',
      'BULAKLAKAN',
      'CALAMIAS',
      'CUMBA',
      'DAGATAN',
      'DUHATAN',
      'HALANG',
      'INOSLOBAN',
      'KAYUMANGGI',
      'LATAG',
      'LODLOD',
      'LUMBANG',
      'MABINI',
      'MALAGONLONG',
      'MALITLIT',
      'MARAUOY',
      'MATAAS NA LUPA',
      'MUNTING PULO',
      'PAGOLINGIN BATA',
      'PAGOLINGIN EAST',
      'PAGOLINGIN WEST',
      'PANGAO',
      'PINAGKAWITAN',
      'PINAGTONGULAN',
      'PLARIDEL',
      'POBLACION BARANGAY 1',
      'POBLACION BARANGAY 2',
      'POBLACION BARANGAY 3',
      'POBLACION BARANGAY 4',
      'POBLACION BARANGAY 5',
      'POBLACION BARANGAY 6',
      'POBLACION BARANGAY 7',
      'POBLACION BARANGAY 8',
      'POBLACION BARANGAY 9',
      'POBLACION BARANGAY 9-A',
      'POBLACION BARANGAY 10',
      'POBLACION BARANGAY 11',
      'PUSIL',
      'QUEZON',
      'RIZAL',
      'SABANG',
      'SAMPAGUITA',
      'SAN BENITO',
      'SAN CARLOS',
      'SAN CELESTINO',
      'SAN FRANCISCO',
      'SAN GUILLERMO',
      'SAN JOSE',
      'SAN LUCAS',
      'SAN SALVADOR',
      'SAN SEBASTIAN (BALAGBAG)',
      'SANTO NIÑO',
      'SANTO TORIBIO',
      'SAPAC',
      'SICO',
      'TALISAY',
      'TAMBO',
      'TANGOB',
      'TANGUAY',
      'TIBIG',
      'TIPACAN',
    ],
  },
  LOBO: {
    province: 'BATANGAS',
    municipality: 'LOBO',
    region: 'REGION IV-A',
    barangay_list: [
      'APAR',
      'BALATBAT',
      'BALIBAGO',
      'BANALO',
      'BIGA',
      'BIGNAY',
      'CALO',
      'CALUMPIT',
      'FABRICA',
      'JAYBANGA',
      'LAGADLARIN',
      'MABILOG NA BUNDOK',
      'MALABRIGO',
      'MALALIM NA SANOG',
      'MALAPAD NA PARANG',
      'MASAGUITSIT',
      'NAGTALONGTONG',
      'NAGTOCTOC',
      'OLO-OLO',
      'PINAGHAWANAN',
      'POBLACION',
      'SAN MIGUEL',
      'SAN NICOLAS',
      'SAWANG',
      'SOLOC',
      'TAYUMAN',
    ],
  },
  MALVAR: {
    province: 'BATANGAS',
    municipality: 'MALVAR',
    region: 'REGION IV-A',
    barangay_list: [
      'BAGONG POOK',
      'BILUCAO (SAN ISIDRO WESTERN)',
      'BULIHAN',
      'LUTA DEL NORTE',
      'LUTA DEL SUR',
      'POBLACION',
      'SAN ANDRES',
      'SAN FERNANDO',
      'SAN GREGORIO',
      'SAN ISIDRO EAST',
      'SAN JUAN',
      'SAN PEDRO I (EASTERN)',
      'SAN PEDRO II (WESTERN)',
      'SAN PIOQUINTO',
      'SANTIAGO',
    ],
  },
  'MATAAS NA KAHOY': {
    province: 'BATANGAS',
    municipality: 'MATAAS NA KAHOY',
    region: 'REGION IV-A',
    barangay_list: [
      'BARANGAY II-A (POB.)',
      'BAYORBOR',
      'BUBUYAN',
      'CALINGATAN',
      'DISTRICT I (POB.)',
      'DISTRICT II (POB.)',
      'DISTRICT III (POB.)',
      'DISTRICT IV (POB.)',
      'KINALAGLAGAN',
      'LOOB',
      'LUMANG LIPA',
      'MANGGAHAN',
      'NANGKAAN',
      'SAN SEBASTIAN',
      'SANTOL',
      'UPA',
    ],
  },
  NASUGBU: {
    province: 'BATANGAS',
    municipality: 'NASUGBU',
    region: 'REGION IV-A',
    barangay_list: [
      'AGA',
      'BALAYTIGUI',
      'BANILAD',
      'BARANGAY 1 (POB.)',
      'BARANGAY 2 (POB.)',
      'BARANGAY 3 (POB.)',
      'BARANGAY 4 (POB.)',
      'BARANGAY 5 (POB.)',
      'BARANGAY 6 (POB.)',
      'BARANGAY 7 (POB.)',
      'BARANGAY 8 (POB.)',
      'BARANGAY 9 (POB.)',
      'BARANGAY 10 (POB.)',
      'BARANGAY 11 (POB.)',
      'BARANGAY 12 (POB.)',
      'BILARAN',
      'BUCANA',
      'BULIHAN',
      'BUNDUCAN',
      'BUTUCAN',
      'CALAYO',
      'CATANDAAN',
      'COGUNAN',
      'DAYAP',
      'KAYLAWAY',
      'KAYRILAW',
      'LATAG',
      'LOOC',
      'LUMBANGAN',
      'MALAPAD NA BATO',
      'MATAAS NA PULO',
      'MAUGAT',
      'MUNTING INDAN',
      'NATIPUAN',
      'PANTALAN',
      'PAPAYA',
      'PUTAT',
      'REPARO',
      'TALANGAN',
      'TUMALIM',
      'UTOD',
      'WAWA',
    ],
  },
  'PADRE GARCIA': {
    province: 'BATANGAS',
    municipality: 'PADRE GARCIA',
    region: 'REGION IV-A',
    barangay_list: [
      'BANABA',
      'BANAYBANAY',
      'BAWI',
      'BUKAL',
      'CASTILLO',
      'CAWONGAN',
      'MANGGAS',
      'MAUGAT EAST',
      'MAUGAT WEST',
      'PANSOL',
      'PAYAPA',
      'POBLACION',
      'QUILO-QUILO NORTH',
      'QUILO-QUILO SOUTH',
      'SAN FELIPE',
      'SAN MIGUEL',
      'TAMAK',
      'TANGOB',
    ],
  },
  'SAN PASCUAL': {
    province: 'MASBATE',
    municipality: 'SAN PASCUAL',
    region: 'REGION V',
    barangay_list: [
      'BOCA CHICA',
      'BOLOD (POB.)',
      'BUSING',
      'CUEVA',
      'DANGCALAN',
      'HALABANGBAYBAY',
      'INIWARAN',
      'KI-BUAYA (RIZAL)',
      'KI-ROMERO (ROXAS)',
      'LAURENTE',
      'MABINI',
      'MABUHAY',
      'MALAKING ILOG',
      'MAPANIQUE',
      'NAZARENO',
      'PINAMASINGAN',
      'QUINTINA',
      'SAN JOSE',
      'SAN PEDRO',
      'SAN RAFAEL',
      'SANTA CRUZ',
      'TERRAPLIN (POB.)',
    ],
  },
  TAAL: {
    province: 'BATANGAS',
    municipality: 'TAAL',
    region: 'REGION IV-A',
    barangay_list: [
      'APACAY',
      'BALISONG',
      'BIHIS',
      'BOLBOK',
      'BULI',
      'BUTONG',
      'CARASUCHE',
      'CAWIT',
      'CAYSASAY',
      'CUBAMBA',
      'CULTIHAN',
      'GAHOL',
      'HALANG',
      'IBA',
      'ILOG',
      'IMAMAWO',
      'IPIL',
      'LAGUILE',
      'LATAG',
      'LUNTAL',
      'MAHABANG LODLOD',
      'NIOGAN',
      'PANSOL',
      'POBLACION 1',
      'POBLACION 2',
      'POBLACION 3',
      'POBLACION 4',
      'POBLACION 5',
      'POBLACION 6',
      'POBLACION 7',
      'POBLACION 8',
      'POBLACION 9',
      'POBLACION 10',
      'POBLACION 11',
      'POBLACION 12',
      'POBLACION 13',
      'POBLACION 14',
      'POOK',
      'SEIRAN',
      'TATLONG MARIA',
      'TIERRA ALTA',
      'TULO',
    ],
  },
  TALISAY: {
    province: 'CAMARINES NORTE',
    municipality: 'TALISAY',
    region: 'REGION V',
    barangay_list: [
      'BINANUAAN',
      'CAAWIGAN',
      'CAHABAAN',
      'CALINTAAN',
      'DEL CARMEN',
      'GABON',
      'ITOMANG',
      'POBLACION',
      'SAN FRANCISCO',
      'SAN ISIDRO',
      'SAN JOSE',
      'SAN NICOLAS',
      'SANTA CRUZ',
      'SANTA ELENA',
      'SANTO NIÑO',
    ],
  },
  'TANAUAN': {
    province: 'BATANGAS',
    municipality: 'TANAUAN',
    region: 'REGION IV-A',
    barangay_list: [
      'ALTURA BATA',
      'ALTURA MATANDA',
      'ALTURA-SOUTH',
      'AMBULONG',
      'BAGBAG',
      'BAGUMBAYAN',
      'BALELE',
      'BANADERO',
      'BANJO EAST',
      'BANJO LAUREL (BANJO WEST)',
      'BILOG-BILOG',
      'BOOT',
      'CALE',
      'DARASA',
      'GONZALES',
      'HIDALGO',
      'JANOPOL',
      'JANOPOL ORIENTAL',
      'LAUREL',
      'LUYOS',
      'MABINI',
      'MALAKING PULO',
      'MARIA PAZ',
      'MAUGAT',
      'MONTAÑA (IK-IK)',
      'NATATAS',
      'PAGASPAS',
      'PANTAY BATA',
      'PANTAY MATANDA',
      'POBLACION BARANGAY 1',
      'POBLACION BARANGAY 2',
      'POBLACION BARANGAY 3',
      'POBLACION BARANGAY 4',
      'POBLACION BARANGAY 5',
      'POBLACION BARANGAY 6',
      'POBLACION BARANGAY 7',
      'SALA',
      'SAMBAT',
      'SAN JOSE',
      'SANTOL (DOÑA JACOBA GARCIA)',
      'SANTOR',
      'SULPOC',
      'SUPLANG',
      'TALAGA',
      'TINURIK',
      'TRAPICHE',
      'ULANGO',
      'WAWA',
    ],
  },
  TAYSAN: {
    province: 'BATANGAS',
    municipality: 'TAYSAN',
    region: 'REGION IV-A',
    barangay_list: [
      'BACAO',
      'BILOGO',
      'BUKAL',
      'DAGATAN',
      'GUINHAWA',
      'LAUREL',
      'MABAYABAS',
      'MAHANADIONG',
      'MAPULO',
      'MATAAS NA LUPA',
      'PAG-ASA',
      'PANGHAYAAN',
      'PINAGBAYANAN',
      'PIÑA',
      'POBLACION EAST',
      'POBLACION WEST',
      'SAN ISIDRO',
      'SAN MARCELINO',
      'SANTO NIÑO',
      'TILAMBO',
    ],
  },
  TINGLOY: {
    province: 'BATANGAS',
    municipality: 'TINGLOY',
    region: 'REGION IV-A',
    barangay_list: [
      'BARANGAY 13 (POBLACION 1)',
      'BARANGAY 14 (POBLACION 2)',
      'BARANGAY 15 (POBLACION 3)',
      'CORONA',
      'GAMAO',
      'MAKAWAYAN',
      'MARIKABAN',
      'PAPAYA',
      'PISA',
      'SAN ISIDRO',
      'SAN JOSE',
      'SAN JUAN',
      'SAN PEDRO',
      'SANTO TOMAS',
      'TALAHIB',
    ],
  },
  TUY: {
    province: 'BATANGAS',
    municipality: 'TUY',
    region: 'REGION IV-A',
    barangay_list: [
      'ACLE',
      'BAYUDBUD',
      'BOLBOC',
      'BURGOS (POB.)',
      'DALIMA',
      'DAO',
      'GUINHAWA',
      'LUMBANGAN',
      'LUNA (POB.)',
      'LUNTAL',
      'MAGAHIS',
      'MALIBU',
      'MATAYWANAC',
      'PALINCARO',
      'PUTOL',
      'RILLO (POB.)',
      'RIZAL (POB.)',
      'SABANG',
      'SAN JOSE',
      'TALON',
      'TOONG',
      'TUYON-TUYON',
    ],
  },
  ALFONSO: {
    province: 'CAVITE',
    municipality: 'ALFONSO',
    region: 'REGION IV-A',
    barangay_list: [
      'AMUYONG',
      'BARANGAY I (POB.)',
      'BARANGAY II (POB.)',
      'BARANGAY III (POB.)',
      'BARANGAY IV (POB.)',
      'BARANGAY V (POB.)',
      'BILOG',
      'BUCK ESTATE',
      'ESPERANZA IBABA',
      'ESPERANZA ILAYA',
      'KAYSUYO',
      'KAYTITINGA I',
      'KAYTITINGA II',
      'KAYTITINGA III',
      'LUKSUHIN',
      'LUKSUHIN ILAYA',
      'MANGAS I',
      'MANGAS II',
      'MARAHAN I',
      'MARAHAN II',
      'MATAGBAK I',
      'MATAGBAK II',
      'PAJO',
      'PALUMLUM',
      'SANTA TERESA',
      'SIKAT',
      'SINALIW MALAKI',
      'SINALIW NA MUNTI',
      'SULSUGIN',
      'TAYWANAK IBABA',
      'TAYWANAK ILAYA',
      'UPLI',
    ],
  },
  AMADEO: {
    province: 'CAVITE',
    municipality: 'AMADEO',
    region: 'REGION IV-A',
    barangay_list: [
      'BANAYBANAY',
      'BARANGAY I (POB.)',
      'BARANGAY II (POB.)',
      'BARANGAY III (POB.)',
      'BARANGAY IV (POB.)',
      'BARANGAY IX (POB.)',
      'BARANGAY V (POB.)',
      'BARANGAY VI (POB.)',
      'BARANGAY VII (POB.)',
      'BARANGAY VIII (POB.)',
      'BARANGAY X (POB.)',
      'BARANGAY XI (POB.)',
      'BARANGAY XII (POB.)',
      'BUCAL',
      'BUHO',
      'DAGATAN',
      'HALANG',
      'LOMA',
      'MAITIM I',
      'MAYMANGGA',
      'MINANTOK KANLURAN',
      'MINANTOK SILANGAN',
      'PANGIL',
      'SALABAN',
      'TALON',
      'TAMACAN',
    ],
  },
  'BACOOR': {
    province: 'CAVITE',
    municipality: 'BACOOR',
    region: 'REGION IV-A',
    barangay_list: [
      'ALIMA',
      'ANIBAN I',
      'ANIBAN II',
      'ANIBAN III',
      'ANIBAN IV',
      'ANIBAN V',
      'BANALO',
      'BAYANAN',
      'CAMPO SANTO',
      'DAANG BUKID',
      'DIGMAN',
      'DULONG BAYAN',
      'HABAY I',
      'HABAY II',
      'KAINGIN (POB.)',
      'LIGAS I',
      'LIGAS II',
      'LIGAS III',
      'MABOLO I',
      'MABOLO II',
      'MABOLO III',
      'MALIKSI I',
      'MALIKSI II',
      'MALIKSI III',
      'MAMBOG I',
      'MAMBOG II',
      'MAMBOG III',
      'MAMBOG IV',
      'MAMBOG V',
      'MOLINO I',
      'MOLINO II',
      'MOLINO III',
      'MOLINO IV',
      'MOLINO V',
      'MOLINO VI',
      'MOLINO VII',
      'NIOG I',
      'NIOG II',
      'NIOG III',
      'P.F. ESPIRITU I (PANAPAAN)',
      'P.F. ESPIRITU II',
      'P.F. ESPIRITU III',
      'P.F. ESPIRITU IV',
      'P.F. ESPIRITU V',
      'P.F. ESPIRITU VI',
      'P.F. ESPIRITU VII',
      'P.F. ESPIRITU VIII',
      'QUEENS ROW CENTRAL',
      'QUEENS ROW EAST',
      'QUEENS ROW WEST',
      'REAL I',
      'REAL II',
      'SALINAS I',
      'SALINAS II',
      'SALINAS III',
      'SALINAS IV',
      'SAN NICOLAS I',
      'SAN NICOLAS II',
      'SAN NICOLAS III',
      'SINEGUELASAN',
      'TABING DAGAT',
      'TALABA I',
      'TALABA II',
      'TALABA III',
      'TALABA IV',
      'TALABA V',
      'TALABA VI',
      'TALABA VII',
      'ZAPOTE I',
      'ZAPOTE II',
      'ZAPOTE III',
      'ZAPOTE IV',
      'ZAPOTE V',
    ],
  },
  CARMONA: {
    province: 'CAVITE',
    municipality: 'CARMONA',
    region: 'REGION IV-A',
    barangay_list: [
      'BANCAL',
      'BARANGAY 1 (POB.)',
      'BARANGAY 2 (POB.)',
      'BARANGAY 3 (POB.)',
      'BARANGAY 4 (POB.)',
      'BARANGAY 5 (POB.)',
      'BARANGAY 6 (POB.)',
      'BARANGAY 7 (POB.)',
      'BARANGAY 8 (POB.)',
      'CABILANG BAYBAY',
      'LANTIC',
      'MABUHAY',
      'MADUYA',
      'MILAGROSA',
    ],
  },
  'CAVITE': {
    province: 'CAVITE',
    municipality: 'CAVITE',
    region: 'REGION IV-A',
    barangay_list: [
      'BARANGAY 1 (HEN. M. ALVAREZ)',
      'BARANGAY 2 (C. TIRONA)',
      'BARANGAY 3 (HEN. E. AGUINALDO)',
      'BARANGAY 4 (HEN. M. TRIAS)',
      'BARANGAY 5 (HEN. E. EVANGELISTA)',
      'BARANGAY 6 (DIEGO SILANG)',
      'BARANGAY 7 (KAPITAN KONG)',
      'BARANGAY 8 (MANUEL S. ROJAS)',
      'BARANGAY 9 (KANAWAY)',
      'BARANGAY 10 (KINGFISHER)',
      'BARANGAY 10-A (KINGFISHER-A)',
      'BARANGAY 10-B (KINGFISHER-B)',
      'BARANGAY 11 (LAWIN)',
      'BARANGAY 12 (LOVE BIRD)',
      'BARANGAY 13 (AGUILA)',
      'BARANGAY 14 (LORO)',
      'BARANGAY 15 (KILYAWAN)',
      'BARANGAY 16 (MARTINES)',
      'BARANGAY 17 (KALAPATI)',
      'BARANGAY 18 (MAYA)',
      'BARANGAY 19 (GEMINI)',
      'BARANGAY 20 (VIRGO)',
      'BARANGAY 21 (SCORPIO)',
      'BARANGAY 22 (LEO)',
      'BARANGAY 22-A (LEO A)',
      'BARANGAY 23 (AQUARIUS)',
      'BARANGAY 24 (LIBRA)',
      'BARANGAY 25 (CAPRICORN)',
      'BARANGAY 26 (CANCER)',
      'BARANGAY 27 (SAGITARIUS)',
      'BARANGAY 28 (TAURUS)',
      'BARANGAY 29 (LAO-LAO)',
      'BARANGAY 29-A (LAO-LAO A)',
      'BARANGAY 30 (BID-BID)',
      'BARANGAY 31 (MAYA-MAYA)',
      'BARANGAY 32 (SALAY-SALAY)',
      'BARANGAY 33 (BUWAN-BUWAN)',
      'BARANGAY 34 (LAPU-LAPU)',
      'BARANGAY 35 (HASA-HASA)',
      'BARANGAY 36 (SAP-SAP)',
      'BARANGAY 36-A (SAP-SAP A)',
      'BARANGAY 37 (CADENA DE AMOR)',
      'BARANGAY 37-A (CADENA DE AMOR A)',
      'BARANGAY 38 (SAMPAGUITA)',
      'BARANGAY 38-A (SAMPAGUITA A)',
      'BARANGAY 39 (JASMIN)',
      'BARANGAY 40 (GUMAMELA)',
      'BARANGAY 41 (ROSAL)',
      'BARANGAY 42 (PINAGBUKLOD)',
      'BARANGAY 42-A (PINAGBUKLOD A)',
      'BARANGAY 42-B (PINAGBUKLOD B)',
      'BARANGAY 42-C (PINAGBUKLOD C)',
      'BARANGAY 43 (PINAGPALA)',
      'BARANGAY 44 (MALIGAYA)',
      'BARANGAY 45 (KAUNLARAN)',
      'BARANGAY 45-A (KAUNLARAN A)',
      'BARANGAY 46 (SINAGTALA)',
      'BARANGAY 47 (PAGKAKAISA)',
      'BARANGAY 47-A (PAGKAKAISA A)',
      'BARANGAY 47-B (PAGKAKAISA B)',
      'BARANGAY 48 (NARRA)',
      'BARANGAY 48-A (NARRA A)',
      'BARANGAY 49 (AKASYA)',
      'BARANGAY 49-A (AKASYA A)',
      'BARANGAY 50 (KABALYERO)',
      'BARANGAY 51 (KAMAGONG)',
      'BARANGAY 52 (IPIL)',
      'BARANGAY 53 (YAKAL)',
      'BARANGAY 53-A (YAKAL A)',
      'BARANGAY 53-B (YAKAL B)',
      'BARANGAY 54 (PECHAY)',
      'BARANGAY 54-A (PECHAY A)',
      'BARANGAY 55 (AMPALAYA)',
      'BARANGAY 56 (LABANOS)',
      'BARANGAY 57 (REPOLYO)',
      'BARANGAY 58 (PATOLA)',
      'BARANGAY 58-A (PATOLA A)',
      'BARANGAY 59 (SITAW)',
      'BARANGAY 60 (LETSUGAS)',
      'BARANGAY 61 (TALONG)',
      'BARANGAY 61-A (TALONG A)',
      'BARANGAY 62 (KANGKONG)',
      'BARANGAY 62-A (KANGKONG A)',
      'BARANGAY 62-B (KANGKONG B)',
    ],
  },
  'DASMARIÑAS': {
    province: 'CAVITE',
    municipality: 'DASMARIÑAS',
    region: 'REGION IV-A',
    barangay_list: [
      'BUROL',
      'BUROL I',
      'BUROL II',
      'BUROL III',
      'DATU ESMAEL (BAGO-A-INGUD)',
      'EMMANUEL BERGADO I',
      'EMMANUEL BERGADO II',
      'FATIMA I',
      'FATIMA II',
      'FATIMA III',
      'H-2',
      'LANGKAAN I',
      'LANGKAAN II',
      'LUZVIMINDA I',
      'LUZVIMINDA II',
      'PALIPARAN I',
      'PALIPARAN II',
      'PALIPARAN III',
      'SABANG',
      'SAINT PETER I',
      'SAINT PETER II',
      'SALAWAG',
      'SALITRAN I',
      'SALITRAN II',
      'SALITRAN III',
      'SALITRAN IV',
      'SAMPALOC I',
      'SAMPALOC II',
      'SAMPALOC III',
      'SAMPALOC IV',
      'SAMPALOC V',
      'SAN AGUSTIN I',
      'SAN AGUSTIN II',
      'SAN AGUSTIN III',
      'SAN ANDRES I',
      'SAN ANDRES II',
      'SAN ANTONIO DE PADUA I',
      'SAN ANTONIO DE PADUA II',
      'SAN DIONISIO (BARANGAY 1)',
      'SAN ESTEBAN (BARANGAY 4)',
      'SAN FRANCISCO I',
      'SAN FRANCISCO II',
      'SAN ISIDRO LABRADOR I',
      'SAN ISIDRO LABRADOR II',
      'SAN JOSE',
      'SAN JUAN (SAN JUAN I)',
      'SAN LORENZO RUIZ I',
      'SAN LORENZO RUIZ II',
      'SAN LUIS I',
      'SAN LUIS II',
      'SAN MANUEL I',
      'SAN MANUEL II',
      'SAN MATEO',
      'SAN MIGUEL',
      'SAN MIGUEL II',
      'SAN NICOLAS I',
      'SAN NICOLAS II',
      'SAN ROQUE (STA. CRISTINA II)',
      'SAN SIMON (BARANGAY 7)',
      'SANTA CRISTINA I',
      'SANTA CRISTINA II',
      'SANTA CRUZ I',
      'SANTA CRUZ II',
      'SANTA FE',
      'SANTA LUCIA (SAN JUAN II)',
      'SANTA MARIA (BARANGAY 20)',
      'SANTO CRISTO (BARANGAY 3)',
      'SANTO NIÑO I',
      'SANTO NIÑO II',
      'VICTORIA REYES',
      'ZONE I (POB.)',
      'ZONE I-B',
      'ZONE II (POB.)',
      'ZONE III (POB.)',
      'ZONE IV (POB.)',
    ],
  },
  'GEN. MARIANO ALVAREZ': {
    province: 'CAVITE',
    municipality: 'GEN. MARIANO ALVAREZ',
    region: 'REGION IV-A',
    barangay_list: [
      'ALDIANO OLAES',
      'BARANGAY 1 POBLACION (AREA I)',
      'BARANGAY 2 POBLACION',
      'BARANGAY 3 POBLACION',
      'BARANGAY 4 POBLACION',
      'BARANGAY 5 POBLACION',
      'BENJAMIN TIRONA (AREA D)',
      'BERNARDO PULIDO (AREA H)',
      'EPIFANIO MALIA',
      'FIORELLO CARIMAG (AREA C)',
      'FRANCISCO DE CASTRO (SUNSHINE VILL.)',
      'FRANCISCO REYES',
      'GAVINO MADERAN',
      'GREGORIA DE JESUS',
      'INOCENCIO SALUD',
      'JACINTO LUMBRERAS',
      'KAPITAN KUA (AREA F)',
      'KORONEL JOSE P. ELISES (AREA E)',
      'MACARIO DACON',
      'MARCELINO MEMIJE',
      'NICOLASA VIRATA (SAN JOSE)',
      'PANTALEON GRANADOS (AREA G)',
      'RAMON CRUZ (AREA J)',
      'SAN GABRIEL (AREA K)',
      'SAN JOSE',
      'SEVERINO DE LAS ALAS',
      'TINIENTE TIAGO',
    ],
  },
  'GENERAL EMILIO AGUINALDO': {
    province: 'CAVITE',
    municipality: 'GENERAL EMILIO AGUINALDO',
    region: 'REGION IV-A',
    barangay_list: [
      'A. DALUSAG',
      'BATAS DAO',
      'CASTAÑOS CERCA',
      'CASTAÑOS LEJOS',
      'KABULUSAN',
      'KAYMISAS',
      'KAYPAABA',
      'LUMIPA',
      'NARVAEZ',
      'POBLACION I',
      'POBLACION II',
      'POBLACION III',
      'POBLACION IV',
      'TABORA',
    ],
  },
  'GENERAL TRIAS': {
    province: 'CAVITE',
    municipality: 'GENERAL TRIAS',
    region: 'REGION IV-A',
    barangay_list: [
      'ALINGARO',
      'ARNALDO POB. (BGY. 7)',
      'BACAO I',
      'BACAO II',
      'BAGUMBAYAN POB. (BGY. 5)',
      'BICLATAN',
      'BUENAVISTA I',
      'BUENAVISTA II',
      'BUENAVISTA III',
      'CORREGIDOR POB. (BGY. 10)',
      'DULONG BAYAN POB. (BGY. 3)',
      'GOV. FERRER POB. (BGY. 1)',
      'JAVALERA',
      'MANGGAHAN',
      'NAVARRO',
      'NINETY SIXTH POB. (BGY. 8)',
      'PANUNGYANAN',
      'PASONG CAMACHILE I',
      'PASONG CAMACHILE II',
      'PASONG KAWAYAN I',
      'PASONG KAWAYAN II',
      'PINAGTIPUNAN',
      'PRINZA POB. (BGY. 9)',
      'SAMPALUCAN POB. (BGY. 2)',
      'SAN FRANCISCO',
      'SAN GABRIEL POB. (BGY. 4)',
      'SAN JUAN I',
      'SAN JUAN II',
      'SANTA CLARA',
      'SANTIAGO',
      'TAPIA',
      'TEJERO',
      'VIBORA POB. (BGY. 6)',
    ],
  },
  'IMUS': {
    province: 'CAVITE',
    municipality: 'IMUS',
    region: 'REGION IV-A',
    barangay_list: [
      'ALAPAN I-A',
      'ALAPAN I-B',
      'ALAPAN I-C',
      'ALAPAN II-A',
      'ALAPAN II-B',
      'ANABU I-A',
      'ANABU I-B',
      'ANABU I-C',
      'ANABU I-D',
      'ANABU I-E',
      'ANABU I-F',
      'ANABU I-G',
      'ANABU II-A',
      'ANABU II-B',
      'ANABU II-C',
      'ANABU II-D',
      'ANABU II-E',
      'ANABU II-F',
      'BAGONG SILANG (BAHAYANG PAG-ASA)',
      'BAYAN LUMA I',
      'BAYAN LUMA II',
      'BAYAN LUMA III',
      'BAYAN LUMA IV',
      'BAYAN LUMA IX',
      'BAYAN LUMA V',
      'BAYAN LUMA VI',
      'BAYAN LUMA VII',
      'BAYAN LUMA VIII',
      'BUCANDALA I',
      'BUCANDALA II',
      'BUCANDALA III',
      'BUCANDALA IV',
      'BUCANDALA V',
      'BUHAY NA TUBIG',
      'CARSADANG BAGO I',
      'CARSADANG BAGO II',
      'MAGDALO',
      'MAHARLIKA',
      'MALAGASANG I-A',
      'MALAGASANG I-B',
      'MALAGASANG I-C',
      'MALAGASANG I-D',
      'MALAGASANG I-E',
      'MALAGASANG I-F',
      'MALAGASANG I-G',
      'MALAGASANG II-A',
      'MALAGASANG II-B',
      'MALAGASANG II-C',
      'MALAGASANG II-D',
      'MALAGASANG II-E',
      'MALAGASANG II-F',
      'MALAGASANG II-G',
      'MARIANO ESPELETA I',
      'MARIANO ESPELETA II',
      'MARIANO ESPELETA III',
      'MEDICION I-A',
      'MEDICION I-B',
      'MEDICION I-C',
      'MEDICION I-D',
      'MEDICION II-A',
      'MEDICION II-B',
      'MEDICION II-C',
      'MEDICION II-D',
      'MEDICION II-E',
      'MEDICION II-F',
      'PAG-ASA I',
      'PAG-ASA II',
      'PAG-ASA III',
      'PALICO I',
      'PALICO II',
      'PALICO III',
      'PALICO IV',
      'PASONG BUAYA I',
      'PASONG BUAYA II',
      'PINAGBUKLOD',
      'POBLACION I-A (POB.)',
      'POBLACION I-B',
      'POBLACION I-C',
      'POBLACION II-A (POB.)',
      'POBLACION II-B',
      'POBLACION III-A (POB.)',
      'POBLACION III-B',
      'POBLACION IV-A (POB.)',
      'POBLACION IV-B',
      'POBLACION IV-C',
      'POBLACION IV-D',
      'TANZANG LUMA I',
      'TANZANG LUMA II',
      'TANZANG LUMA III',
      'TANZANG LUMA IV (SOUTHERN CITY)',
      'TANZANG LUMA V',
      'TANZANG LUMA VI',
      'TOCLONG I-A',
      'TOCLONG I-B',
      'TOCLONG I-C',
      'TOCLONG II-A',
      'TOCLONG II-B',
    ],
  },
  INDANG: {
    province: 'CAVITE',
    municipality: 'INDANG',
    region: 'REGION IV-A',
    barangay_list: [
      'AGUS-US',
      'ALULOD',
      'BANABA CERCA',
      'BANABA LEJOS',
      'BANCOD',
      'BARANGAY 1 (POB.)',
      'BARANGAY 2 (POB.)',
      'BARANGAY 3 (POB.)',
      'BARANGAY 4 (POB.)',
      'BUNA CERCA',
      'BUNA LEJOS I',
      'BUNA LEJOS II',
      'CALUMPANG CERCA',
      'CALUMPANG LEJOS I',
      'CARASUCHI',
      'DAINE I',
      'DAINE II',
      'GUYAM MALAKI',
      'GUYAM MUNTI',
      'HARASAN',
      'KAYQUIT I',
      'KAYQUIT II',
      'KAYQUIT III',
      'KAYTAMBOG',
      'KAYTAPOS',
      'LIMBON',
      'LUMAMPONG BALAGBAG',
      'LUMAMPONG HALAYHAY',
      'MAHABANGKAHOY CERCA',
      'MAHABANGKAHOY LEJOS',
      'MATAAS NA LUPA (CHECKPOINT)',
      'PULO',
      'TAMBO BALAGBAG',
      'TAMBO ILAYA',
      'TAMBO KULIT',
      'TAMBO MALAKI',
    ],
  },
  KAWIT: {
    province: 'CAVITE',
    municipality: 'KAWIT',
    region: 'REGION IV-A',
    barangay_list: [
      'BALSAHAN-BISITA',
      'BATONG DALIG',
      'BINAKAYAN-APLAYA',
      'BINAKAYAN-KANLURAN',
      'CONGBALAY-LEGASPI',
      'GAHAK',
      'KAINGEN',
      'MAGDALO (PUTOL)',
      'MANGGAHAN-LAWIN',
      'MARULAS',
      'PANAMITAN',
      'POBLACION',
      'PULVORISTA',
      'SAMALA-MARQUEZ',
      'SAN SEBASTIAN',
      'SANTA ISABEL',
      'TABON I',
      'TABON II',
      'TABON III',
      'TOCLONG',
      'TRAMO-BANTAYAN',
      'WAKAS I',
      'WAKAS II',
    ],
  },
  MARAGONDON: {
    province: 'CAVITE',
    municipality: 'MARAGONDON',
    region: 'REGION IV-A',
    barangay_list: [
      'BUCAL I',
      'BUCAL II',
      'BUCAL III A',
      'BUCAL III B',
      'BUCAL IV A',
      'BUCAL IV B',
      'CAINGIN POB. (BARANGAY 3)',
      'GARITA I A',
      'GARITA I B',
      'LAYONG MABILOG',
      'MABATO',
      'PANTIHAN I (BALAYUNGAN)',
      'PANTIHAN II (SAGBAT)',
      'PANTIHAN III (POOK NA MUNTI)',
      'PANTIHAN IV (POOK NI SARA)',
      'PATUNGAN',
      'PINAGSANHAN I A',
      'PINAGSANHAN I B',
      'POBLACION I A',
      'POBLACION I B',
      'POBLACION II A',
      'POBLACION II B',
      'SAN MIGUEL I A (CAPUTATAN)',
      'SAN MIGUEL I B',
      'TALIPUSNGO',
      'TULAY KANLURAN',
      'TULAY SILANGAN',
    ],
  },
  'MENDEZ (MENDEZ-NUÑEZ)': {
    province: 'CAVITE',
    municipality: 'MENDEZ (MENDEZ-NUÑEZ)',
    region: 'REGION IV-A',
    barangay_list: [
      'ANULING CERCA I',
      'ANULING CERCA II',
      'ANULING LEJOS I (ANULING)',
      'ANULING LEJOS II',
      'ASIS I',
      'ASIS II',
      'ASIS III',
      'BANAYAD',
      'BUKAL',
      'GALICIA I',
      'GALICIA II',
      'GALICIA III',
      'MIGUEL MOJICA',
      'PALOCPOC I',
      'PALOCPOC II',
      'PANUNGYAN I',
      'PANUNGYAN II',
      'POBLACION I (BARANGAY I)',
      'POBLACION II (BARANGAY II)',
      'POBLACION III (BARANGAY III)',
      'POBLACION IV (BARANGAY IV)',
      'POBLACION V (BARANGAY V)',
      'POBLACION VI (BARANGAY VI)',
      'POBLACION VII (BARANGAY VII)',
    ],
  },
  NAIC: {
    province: 'CAVITE',
    municipality: 'NAIC',
    region: 'REGION IV-A',
    barangay_list: [
      'BAGONG KARSADA',
      'BALSAHAN',
      'BANCAAN',
      'BUCANA MALAKI',
      'BUCANA SASAHAN',
      'CALUBCOB',
      'CAPT. C. NAZARENO (POB.)',
      'GOMEZ-ZAMORA (POB.)',
      'HALANG',
      'HUMBAC',
      'IBAYO ESTACION',
      'IBAYO SILANGAN',
      'KANLURAN',
      'LABAC',
      'LATORIA',
      'MABOLO',
      'MAKINA',
      'MALAINEN BAGO',
      'MALAINEN LUMA',
      'MOLINO',
      'MUNTING MAPINO',
      'MUZON',
      'PALANGUE 1',
      'PALANGUE 2 AND 3',
      'SABANG',
      'SAN ROQUE',
      'SANTULAN',
      'SAPA',
      'TIMALAN BALSAHAN',
      'TIMALAN CONCEPCION',
    ],
  },
  NOVELETA: {
    province: 'CAVITE',
    municipality: 'NOVELETA',
    region: 'REGION IV-A',
    barangay_list: [
      'MAGDIWANG',
      'POBLACION',
      'SALCEDO I',
      'SALCEDO II',
      'SAN ANTONIO I',
      'SAN ANTONIO II',
      'SAN JOSE I',
      'SAN JOSE II',
      'SAN JUAN I',
      'SAN JUAN II',
      'SAN RAFAEL I',
      'SAN RAFAEL II',
      'SAN RAFAEL III',
      'SAN RAFAEL IV',
      'SANTA ROSA I',
      'SANTA ROSA II',
    ],
  },
  SILANG: {
    province: 'CAVITE',
    municipality: 'SILANG',
    region: 'REGION IV-A',
    barangay_list: [
      'ACACIA',
      'ADLAS',
      'ANAHAW I',
      'ANAHAW II',
      'BALITE I',
      'BALITE II',
      'BALUBAD',
      'BANABA',
      'BARANGAY I (POB.)',
      'BARANGAY II (POB.)',
      'BARANGAY III (POB.)',
      'BARANGAY IV (POB.)',
      'BARANGAY V (POB.)',
      'BATAS',
      'BIGA I',
      'BIGA II',
      'BILUSO',
      'BUCAL',
      'BUHO',
      'BULIHAN',
      'CABANGAAN',
      'CARMEN',
      'HOYO',
      'HUKAY',
      'IBA',
      'INCHICAN',
      'IPIL I',
      'IPIL II',
      'KALUBKOB',
      'KAONG',
      'LALAAN I',
      'LALAAN II',
      'LITLIT',
      'LUCSUHIN',
      'LUMIL',
      'MAGUYAM',
      'MALABAG',
      'MALAKING TATYAO',
      'MATAAS NA BUROL',
      'MUNTING ILOG',
      'NARRA I',
      'NARRA II',
      'NARRA III',
      'PALIGAWAN',
      'PASONG LANGKA',
      'POOC I',
      'POOC II',
      'PULONG BUNGA',
      'PULONG SAGING',
      'PUTING KAHOY',
      'SABUTAN',
      'SAN MIGUEL I',
      'SAN MIGUEL II',
      'SAN VICENTE I',
      'SAN VICENTE II',
      'SANTOL',
      'TARTARIA',
      'TIBIG',
      'TOLEDO',
      'TUBUAN I',
      'TUBUAN II',
      'TUBUAN III',
      'ULAT',
      'YAKAL',
    ],
  },
  'TAGAYTAY': {
    province: 'CAVITE',
    municipality: 'TAGAYTAY',
    region: 'REGION IV-A',
    barangay_list: [
      'ASISAN',
      'BAGONG TUBIG',
      'CALABUSO',
      'DAPDAP EAST',
      'DAPDAP WEST',
      'FRANCISCO (SAN FRANCISCO)',
      'GUINHAWA NORTH',
      'GUINHAWA SOUTH',
      'IRUHIN EAST',
      'IRUHIN SOUTH',
      'IRUHIN WEST',
      'KAYBAGAL EAST',
      'KAYBAGAL NORTH',
      'KAYBAGAL SOUTH (POB.)',
      'MAG-ASAWANG ILAT',
      'MAHARLIKA EAST',
      'MAHARLIKA WEST',
      'MAITIM 2ND CENTRAL',
      'MAITIM 2ND EAST',
      'MAITIM 2ND WEST',
      'MENDEZ CROSSING EAST',
      'MENDEZ CROSSING WEST',
      'NEOGAN',
      'PATUTONG MALAKI NORTH',
      'PATUTONG MALAKI SOUTH',
      'SAMBONG',
      'SAN JOSE',
      'SILANG JUNCTION NORTH',
      'SILANG JUNCTION SOUTH',
      'SUNGAY NORTH',
      'SUNGAY SOUTH',
      'TOLENTINO EAST',
      'TOLENTINO WEST',
      'ZAMBAL',
    ],
  },
  TANZA: {
    province: 'CAVITE',
    municipality: 'TANZA',
    region: 'REGION IV-A',
    barangay_list: [
      'AMAYA I',
      'AMAYA II',
      'AMAYA III',
      'AMAYA IV',
      'AMAYA V',
      'AMAYA VI',
      'AMAYA VII',
      'BAGTAS',
      'BARANGAY I (POB.)',
      'BARANGAY II (POB.)',
      'BARANGAY III (POB.)',
      'BARANGAY IV (POB.)',
      'BIGA',
      'BIWAS',
      'BUCAL',
      'BUNGA',
      'CALIBUYO',
      'CAPIPISA',
      'DAANG AMAYA I',
      'DAANG AMAYA II',
      'DAANG AMAYA III',
      'HALAYHAY',
      'JULUGAN I',
      'JULUGAN II',
      'JULUGAN III',
      'JULUGAN IV',
      'JULUGAN V',
      'JULUGAN VI',
      'JULUGAN VII',
      'JULUGAN VIII',
      'LAMBINGAN',
      'MULAWIN',
      'PARADAHAN I',
      'PARADAHAN II',
      'PUNTA I',
      'PUNTA II',
      'SAHUD ULAN',
      'SANJA MAYOR',
      'SANTOL',
      'TANAUAN',
      'TRES CRUSES',
    ],
  },
  TERNATE: {
    province: 'CAVITE',
    municipality: 'TERNATE',
    region: 'REGION IV-A',
    barangay_list: [
      'BUCANA',
      'POBLACION I (BARANGAY I)',
      'POBLACION I A',
      'POBLACION II (BARANGAY II)',
      'POBLACION III (BARANGAY III)',
      'SAN JOSE',
      'SAN JUAN I',
      'SAN JUAN II',
      'SAPANG I',
      'SAPANG II',
    ],
  },
  'TRECE MARTIRES': {
    province: 'CAVITE',
    municipality: 'TRECE MARTIRES',
    region: 'REGION IV-A',
    barangay_list: [
      'AGUADO (PISCAL MUNDO)',
      'CABEZAS',
      'CABUCO',
      'CONCHU (LAGUNDIAN)',
      'DE OCAMPO',
      'GREGORIO (ALIANG)',
      'INOCENCIO (B. POOK)',
      'LALLANA',
      'LAPIDARIO (BAYOG)',
      'LUCIANO (BITANGAN)',
      'OSORIO',
      'PEREZ (LUCBANAN)',
      'SAN AGUSTIN (POB.)',
    ],
  },
  ALAMINOS: {
    province: 'LAGUNA',
    municipality: 'ALAMINOS',
    region: 'REGION IV-A',
    barangay_list: [
      'BARANGAY I (POB.)',
      'BARANGAY II (POB.)',
      'BARANGAY III (POB.)',
      'BARANGAY IV (POB.)',
      'DEL CARMEN',
      'PALMA',
      'SAN AGUSTIN',
      'SAN ANDRES',
      'SAN BENITO',
      'SAN GREGORIO',
      'SAN ILDEFONSO',
      'SAN JUAN',
      'SAN MIGUEL',
      'SAN ROQUE',
      'SANTA ROSA',
    ],
  },
  BAY: {
    province: 'LAGUNA',
    municipality: 'BAY',
    region: 'REGION IV-A',
    barangay_list: [
      'BITIN',
      'CALO',
      'DILA',
      'MAITIM',
      'MASAYA',
      'PACIANO RIZAL',
      'PUYPUY',
      'SAN AGUSTIN (POB.)',
      'SAN ANTONIO',
      'SAN ISIDRO',
      'SAN NICOLAS (POB.)',
      'SANTA CRUZ',
      'SANTO DOMINGO',
      'TAGUMPAY',
      'TRANCA',
    ],
  },
  'CABUYAO': {
    province: 'LAGUNA',
    municipality: 'CABUYAO',
    region: 'REGION IV-A',
    barangay_list: [
      'BACLARAN',
      'BANAYBANAY',
      'BANLIC',
      'BARANGAY DOS (POB.)',
      'BARANGAY TRES (POB.)',
      'BARANGAY UNO (POB.)',
      'BIGAA',
      'BUTONG',
      'CASILE',
      'DIEZMO',
      'GULOD',
      'MAMATID',
      'MARINIG',
      'NIUGAN',
      'PITTLAND',
      'PULO',
      'SALA',
      'SAN ISIDRO',
    ],
  },
  'CALAMBA': {
    province: 'LAGUNA',
    municipality: 'CALAMBA',
    region: 'REGION IV-A',
    barangay_list: [
      'BAGONG KALSADA',
      'BANADERO',
      'BANLIC',
      'BARANDAL',
      'BARANGAY 1 (POB.)',
      'BARANGAY 2 (POB.)',
      'BARANGAY 3 (POB.)',
      'BARANGAY 4 (POB.)',
      'BARANGAY 5 (POB.)',
      'BARANGAY 6 (POB.)',
      'BARANGAY 7 (POB.)',
      'BATINO',
      'BUBUYAN',
      'BUCAL',
      'BUNGGO',
      'BUROL',
      'CAMALIGAN',
      'CANLUBANG',
      'HALANG',
      'HORNALAN',
      'KAY-ANLOG',
      'LA MESA',
      'LAGUERTA',
      'LAWA',
      'LECHERIA',
      'LINGGA',
      'LOOC',
      'MABATO',
      'MAJADA LABAS',
      'MAKILING',
      'MAPAGONG',
      'MASILI',
      'MAUNONG',
      'MAYAPA',
      'MILAGROSA (TULO)',
      'PACIANO RIZAL',
      'PALINGON',
      'PALO-ALTO',
      'PANSOL',
      'PARIAN',
      'PRINZA',
      'PUNTA',
      'PUTING LUPA',
      'REAL',
      'SAIMSIM',
      'SAMPIRUHAN',
      'SAN CRISTOBAL',
      'SAN JOSE',
      'SAN JUAN',
      'SIRANG LUPA',
      'SUCOL',
      'TURBINA',
      'ULANGO',
      'UWISAN',
    ],
  },
  CALAUAN: {
    province: 'LAGUNA',
    municipality: 'CALAUAN',
    region: 'REGION IV-A',
    barangay_list: [
      'BALAYHANGIN',
      'BANGYAS',
      'DAYAP',
      'HANGGAN',
      'IMOK',
      'KANLURAN (POB.)',
      'LAMOT 1',
      'LAMOT 2',
      'LIMAO',
      'MABACAN',
      'MASIIT',
      'PALIPARAN',
      'PEREZ',
      'PRINZA',
      'SAN ISIDRO',
      'SANTO TOMAS',
      'SILANGAN (POB.)',
    ],
  },
  CAVINTI: {
    province: 'LAGUNA',
    municipality: 'CAVINTI',
    region: 'REGION IV-A',
    barangay_list: [
      'ANGLAS',
      'BANGCO',
      'BUKAL',
      'BULAJO',
      'CANSUSO',
      'DUHAT',
      'INAO-AWAN',
      'KANLURAN TALAONGAN',
      'LABAYO',
      'LAYASIN',
      'LAYUG',
      'MAHIPON',
      'PAOWIN',
      'POBLACION',
      'SILANGAN TALAONGAN',
      'SISILMIN',
      'SUMUCAB',
      'TIBATIB',
      'UDIA',
    ],
  },
  'BIÑAN': {
    province: 'LAGUNA',
    municipality: 'BIÑAN',
    region: 'REGION IV-A',
    barangay_list: [
      'BIÑAN (POBLACION)',
      'BUNGAHAN',
      'CANLALAY',
      'CASILE',
      'DE LA PAZ',
      'GANADO',
      'LANGKIWA',
      'LOMA',
      'MALABAN',
      'MALAMIG',
      'MAMPALASAN',
      'PLATERO',
      'POBLACION',
      'SAN ANTONIO',
      'SAN FRANCISCO (HALANG)',
      'SAN JOSE',
      'SAN VICENTE',
      'SANTO DOMINGO',
      'SANTO NIÑO',
      'SANTO TOMAS (CALABUSO)',
      'SORO-SORO',
      'TIMBAO',
      'TUBIGAN',
      'ZAPOTE',
    ],
  },
  FAMY: {
    province: 'LAGUNA',
    municipality: 'FAMY',
    region: 'REGION IV-A',
    barangay_list: [
      'ASANA (POB.)',
      'BACONG-SIGSIGAN',
      'BAGONG PAG-ASA (POB.)',
      'BALITOC',
      'BANABA (POB.)',
      'BATUHAN',
      'BULIHAN',
      'CABALLERO (POB.)',
      'CALUMPANG (POB.)',
      'CUEBANG BATO',
      'DAMAYAN (POB.)',
      'KAPATALAN',
      'KATAYPUANAN',
      'LIYANG',
      'MAATE',
      'MAGDALO (POB.)',
      'MAYATBA',
      'MINAYUTAN',
      'SALANGBATO',
      'TUNHAC',
    ],
  },
  KALAYAAN: {
    province: 'PALAWAN',
    municipality: 'KALAYAAN',
    region: 'REGION IV-B',
    barangay_list: ['PAG-ASA (POB.)'],
  },
  LILIW: {
    province: 'LAGUNA',
    municipality: 'LILIW',
    region: 'REGION IV-A',
    barangay_list: [
      'BAGONG ANYO (POB.)',
      'BAYATE',
      'BONGKOL',
      'BUBUKAL',
      'CABUYAO',
      'CALUMPANG',
      'CULOY',
      'DAGATAN',
      'DANIW (DANLIW)',
      'DITA',
      'IBABANG PALINA',
      'IBABANG SAN ROQUE',
      'IBABANG SUNGI',
      'IBABANG TAYKIN',
      'ILAYANG PALINA',
      'ILAYANG SAN ROQUE',
      'ILAYANG SUNGI',
      'ILAYANG TAYKIN',
      'KANLURANG BUKAL',
      'LAGUAN',
      'LUQUIN',
      'MALABO-KALANTUKAN',
      'MASIKAP (POB.)',
      'MASLUN (POB.)',
      'MOJON',
      'NOVALICHES',
      'OPLES',
      'PAG-ASA (POB.)',
      'PALAYAN',
      'RIZAL (POB.)',
      'SAN ISIDRO',
      'SILANGANG BUKAL',
      'TUY-BAANAN',
    ],
  },
  'LOS BAÑOS': {
    province: 'LAGUNA',
    municipality: 'LOS BAÑOS',
    region: 'REGION IV-A',
    barangay_list: [
      'ANOS',
      'BAGONG SILANG',
      'BAMBANG',
      'BATONG MALAKE',
      'BAYBAYIN (POB.)',
      'BAYOG',
      'LALAKAY',
      'MAAHAS',
      'MALINTA',
      'MAYONDON',
      'PUTHO TUNTUNGIN',
      'SAN ANTONIO',
      'TADLAK',
      'TIMUGAN (POB.)',
    ],
  },
  LUISIANA: {
    province: 'LAGUNA',
    municipality: 'LUISIANA',
    region: 'REGION IV-A',
    barangay_list: [
      'BARANGAY ZONE I (POB.)',
      'BARANGAY ZONE II (POB.)',
      'BARANGAY ZONE III (POB.)',
      'BARANGAY ZONE IV (POB.)',
      'BARANGAY ZONE V (POB.)',
      'BARANGAY ZONE VI (POB.)',
      'BARANGAY ZONE VII (POB.)',
      'BARANGAY ZONE VIII (POB.)',
      'DE LA PAZ',
      'SAN ANTONIO',
      'SAN BUENAVENTURA',
      'SAN DIEGO',
      'SAN ISIDRO',
      'SAN JOSE',
      'SAN JUAN',
      'SAN LUIS',
      'SAN PABLO',
      'SAN PEDRO',
      'SAN RAFAEL',
      'SAN ROQUE',
      'SAN SALVADOR',
      'SANTO DOMINGO',
      'SANTO TOMAS',
    ],
  },
  LUMBAN: {
    province: 'LAGUNA',
    municipality: 'LUMBAN',
    region: 'REGION IV-A',
    barangay_list: [
      'BAGONG SILANG',
      'BALIMBINGAN (POB.)',
      'BALUBAD',
      'CALIRAYA',
      'CONCEPCION',
      'LEWIN',
      'MARACTA (POB.)',
      'MAYTALANG I',
      'MAYTALANG II',
      'PRIMERA PARANG (POB.)',
      'PRIMERA PULO (POB.)',
      'SALAC (POB.)',
      'SANTO NIÑO (POB.)',
      'SEGUNDA PARANG (POB.)',
      'SEGUNDA PULO (POB.)',
      'WAWA',
    ],
  },
  MABITAC: {
    province: 'LAGUNA',
    municipality: 'MABITAC',
    region: 'REGION IV-A',
    barangay_list: [
      'AMUYONG',
      'BAYANIHAN (POB.)',
      'LAMBAC (POB.)',
      'LIBIS NG NAYON (POB.)',
      'LUCONG',
      'MALIGAYA (POB.)',
      'MASIKAP (POB.)',
      'MATALATALA',
      'NANGUMA',
      'NUMERO',
      'PAAGAHAN',
      'PAG-ASA (POB.)',
      'SAN ANTONIO',
      'SAN MIGUEL',
      'SINAGTALA (POB.)',
    ],
  },
  MAGDALENA: {
    province: 'LAGUNA',
    municipality: 'MAGDALENA',
    region: 'REGION IV-A',
    barangay_list: [
      'ALIPIT',
      'BAANAN',
      'BALANAC',
      'BUCAL',
      'BUENAVISTA',
      'BUNGKOL',
      'BUO',
      'BURLUNGAN',
      'CIGARAS',
      'HALAYHAYIN',
      'IBABANG ATINGAY',
      'IBABANG BUTNONG',
      'ILAYANG ATINGAY',
      'ILAYANG BUTNONG',
      'ILOG',
      'MALAKING AMBLING',
      'MALINAO',
      'MARAVILLA',
      'MUNTING AMBLING',
      'POBLACION',
      'SABANG',
      'SALASAD',
      'TANAWAN',
      'TIPUNAN',
    ],
  },
  MAJAYJAY: {
    province: 'LAGUNA',
    municipality: 'MAJAYJAY',
    region: 'REGION IV-A',
    barangay_list: [
      'AMONOY',
      'BAKIA',
      'BALANAC',
      'BALAYONG',
      'BANILAD',
      'BANTI',
      'BITAOY',
      'BOTOCAN',
      'BUKAL',
      'BURGOS',
      'BUROL',
      'CORALAO',
      'GAGALOT',
      'IBABANG BANGA',
      'IBABANG BAYUCAIN',
      'ILAYANG BANGA',
      'ILAYANG BAYUCAIN',
      'ISABANG',
      'MALINAO',
      'MAY-IT',
      'MUNTING KAWAYAN',
      'OLLA',
      'OOBI',
      'ORIGUEL (POB.)',
      'PANALABAN',
      'PANGIL',
      'PANGLAN',
      'PIIT',
      'POOK',
      'RIZAL',
      'SAN FRANCISCO (POB.)',
      'SAN ISIDRO',
      'SAN MIGUEL (POB.)',
      'SAN ROQUE',
      'SANTA CATALINA (POB.)',
      'SUBA',
      'TALORTOR',
      'TANAWAN',
      'TAYTAY',
      'VILLA NOGALES',
    ],
  },
  NAGCARLAN: {
    province: 'LAGUNA',
    municipality: 'NAGCARLAN',
    region: 'REGION IV-A',
    barangay_list: [
      'ABO',
      'ALIBUNGBUNGAN',
      'ALUMBRADO',
      'BALAYONG',
      'BALIMBING',
      'BALINACON',
      'BAMBANG',
      'BANAGO',
      'BANCA-BANCA',
      'BANGCURO',
      'BANILAD',
      'BAYAQUITOS',
      'BUBOY',
      'BUENAVISTA',
      'BUHANGINAN',
      'BUKAL',
      'BUNGA',
      'CABUYEW',
      'CALUMPANG',
      'KANLURAN KABUBUHAYAN',
      'KANLURAN LAZAAN',
      'LABANGAN',
      'LAGULO',
      'LAWAGUIN',
      'MAIIT',
      'MALAYA',
      'MALINAO',
      'MANAOL',
      'MARAVILLA',
      'NAGCALBANG',
      'OPLES',
      'PALAYAN',
      'PALINA',
      'POBLACION I (POB.)',
      'POBLACION II (POB.)',
      'POBLACION III (POB.)',
      'SABANG',
      'SAN FRANCISCO',
      'SANTA LUCIA',
      'SIBULAN',
      'SILANGAN ILAYA',
      'SILANGAN KABUBUHAYAN',
      'SILANGAN LAZAAN',
      'SILANGAN NAPAPATID',
      'SINIPIAN',
      'SULSUGUIN',
      'TALAHIB',
      'TALANGAN',
      'TAYTAY',
      'TIPACAN',
      'WAKAT',
      'YUKOS',
    ],
  },
  PAETE: {
    province: 'LAGUNA',
    municipality: 'PAETE',
    region: 'REGION IV-A',
    barangay_list: [
      'BAGUMBAYAN (POB.)',
      'BANGKUSAY (POB.)',
      'ERMITA (POB.)',
      'IBABA DEL NORTE (POB.)',
      'IBABA DEL SUR (POB.)',
      'ILAYA DEL NORTE (POB.)',
      'ILAYA DEL SUR (POB.)',
      'MAYTOONG (POB.)',
      'QUINALE (POB.)',
    ],
  },
  PAGSANJAN: {
    province: 'LAGUNA',
    municipality: 'PAGSANJAN',
    region: 'REGION IV-A',
    barangay_list: [
      'ANIBONG',
      'BARANGAY I (POB.)',
      'BARANGAY II (POB.)',
      'BIÑAN',
      'BUBOY',
      'CABANBANAN',
      'CALUSICHE',
      'DINGIN',
      'LAMBAC',
      'LAYUGAN',
      'MAGDAPIO',
      'MAULAWIN',
      'PINAGSANJAN',
      'SABANG',
      'SAMPALOC',
      'SAN ISIDRO',
    ],
  },
  PAKIL: {
    province: 'LAGUNA',
    municipality: 'PAKIL',
    region: 'REGION IV-A',
    barangay_list: [
      'BANILAN',
      'BAÑO (POB.)',
      'BURGOS (POB.)',
      'CASA REAL',
      'CASINSIN',
      'DORADO',
      'GONZALES (POB.)',
      'KABULUSAN',
      'MATIKIW',
      'RIZAL (POB.)',
      'SARAY',
      'TAFT (POB.)',
      'TAVERA (POB.)',
    ],
  },
  PANGIL: {
    province: 'LAGUNA',
    municipality: 'PANGIL',
    region: 'REGION IV-A',
    barangay_list: [
      'BALIAN',
      'DAMBO',
      'GALALAN',
      'ISLA (POB.)',
      'MABATO-AZUFRE',
      'NATIVIDAD (POB.)',
      'SAN JOSE (POB.)',
      'SULIB',
    ],
  },
  PILA: {
    province: 'LAGUNA',
    municipality: 'PILA',
    region: 'REGION IV-A',
    barangay_list: [
      'APLAYA',
      'BAGONG POOK',
      'BUKAL',
      'BULILAN NORTE (POB.)',
      'BULILAN SUR (POB.)',
      'CONCEPCION',
      'LABUIN',
      'LINGA',
      'MASICO',
      'MOJON',
      'PANSOL',
      'PINAGBAYANAN',
      'SAN ANTONIO',
      'SAN MIGUEL',
      'SANTA CLARA NORTE (POB.)',
      'SANTA CLARA SUR (POB.)',
      'TUBUAN',
    ],
  },
  'SAN PABLO': {
    province: 'LAGUNA',
    municipality: 'SAN PABLO',
    region: 'REGION IV-A',
    barangay_list: [
      'ATISAN',
      'BAGONG BAYAN II-A (POB.)',
      'BAGONG POOK VI-C (POB.)',
      'BARANGAY I-A (POB.)',
      'BARANGAY I-B (POB.)',
      'BARANGAY II-A (POB.)',
      'BARANGAY II-B (POB.)',
      'BARANGAY II-C (POB.)',
      'BARANGAY II-D (POB.)',
      'BARANGAY II-E (POB.)',
      'BARANGAY II-F (POB.)',
      'BARANGAY III-A (POB.)',
      'BARANGAY III-B (POB.)',
      'BARANGAY III-C (POB.)',
      'BARANGAY III-D (POB.)',
      'BARANGAY III-E (POB.)',
      'BARANGAY III-F (POB.)',
      'BARANGAY IV-A (POB.)',
      'BARANGAY IV-B (POB.)',
      'BARANGAY IV-C (POB.)',
      'BARANGAY V-A (POB.)',
      'BARANGAY V-B (POB.)',
      'BARANGAY V-C (POB.)',
      'BARANGAY V-D (POB.)',
      'BARANGAY VI-A (POB.)',
      'BARANGAY VI-B (POB.)',
      'BARANGAY VI-D (POB.)',
      'BARANGAY VI-E (POB.)',
      'BARANGAY VII-A (POB.)',
      'BARANGAY VII-B (POB.)',
      'BARANGAY VII-C (POB.)',
      'BARANGAY VII-D (POB.)',
      'BARANGAY VII-E (POB.)',
      'BAUTISTA',
      'CONCEPCION',
      'DEL REMEDIO',
      'DOLORES',
      'SAN ANTONIO 1',
      'SAN ANTONIO 2',
      'SAN BARTOLOME',
      'SAN BUENAVENTURA',
      'SAN CRISPIN',
      'SAN CRISTOBAL',
      'SAN DIEGO',
      'SAN FRANCISCO',
      'SAN GABRIEL',
      'SAN GREGORIO',
      'SAN IGNACIO',
      'SAN ISIDRO',
      'SAN JOAQUIN',
      'SAN JOSE',
      'SAN JUAN',
      'SAN LORENZO',
      'SAN LUCAS 1',
      'SAN LUCAS 2',
      'SAN MARCOS',
      'SAN MATEO',
      'SAN MIGUEL',
      'SAN NICOLAS',
      'SAN PEDRO',
      'SAN RAFAEL',
      'SAN ROQUE',
      'SAN VICENTE',
      'SANTA ANA',
      'SANTA CATALINA',
      'SANTA CRUZ',
      'SANTA ELENA',
      'SANTA FELOMINA',
      'SANTA ISABEL',
      'SANTA MARIA',
      'SANTA MARIA MAGDALENA',
      'SANTA MONICA',
      'SANTA VERONICA',
      'SANTIAGO I',
      'SANTIAGO II',
      'SANTISIMO ROSARIO',
      'SANTO ANGEL',
      'SANTO CRISTO',
      'SANTO NIÑO',
      'SOLEDAD',
    ],
  },
  'SAN PEDRO': {
    province: 'LAGUNA',
    municipality: 'SAN PEDRO',
    region: 'REGION IV-A',
    barangay_list: [
      'BAGONG SILANG',
      'CALENDOLA',
      'CHRYSANTHEMUM',
      'CUYAB',
      'ESTRELLA',
      'FATIMA',
      'G.S.I.S.',
      'LANDAYAN',
      'LANGGAM',
      'LARAM',
      'MAGSAYSAY',
      'MAHARLIKA',
      'NARRA',
      'NUEVA',
      'PACITA 1',
      'PACITA 2',
      'POBLACION',
      'RIVERSIDE',
      'ROSARIO',
      'SAMPAGUITA VILLAGE',
      'SAN ANTONIO',
      'SAN LORENZO RUIZ',
      'SAN ROQUE',
      'SAN VICENTE',
      'SANTO NIÑO',
      'UNITED BAYANIHAN',
      'UNITED BETTER LIVING',
    ],
  },
  'SANTA ROSA': {
    province: 'LAGUNA',
    municipality: 'SANTA ROSA',
    region: 'REGION IV-A',
    barangay_list: [
      'APLAYA',
      'BALIBAGO',
      'CAINGIN',
      'DILA',
      'DITA',
      'DON JOSE',
      'IBABA',
      'KANLURAN (POB.)',
      'LABAS',
      'MACABLING',
      'MALITLIT',
      'MALUSAK (POB.)',
      'MARKET AREA (POB.)',
      'POOK',
      'PULONG SANTA CRUZ',
      'SANTO DOMINGO',
      'SINALHAN',
      'TAGAPO',
    ],
  },
  SINILOAN: {
    province: 'LAGUNA',
    municipality: 'SINILOAN',
    region: 'REGION IV-A',
    barangay_list: [
      'ACEVIDA',
      'BAGONG PAG-ASA (POB.)',
      'BAGUMBARANGAY (POB.)',
      'BUHAY',
      'G. REDOR (POB.)',
      'GEN. LUNA',
      'HALAYHAYIN',
      'J. RIZAL (POB.)',
      'KAPATALAN',
      'LAGUIO',
      'LIYANG',
      'LLAVAC',
      'MACATAD',
      'MAGSAYSAY',
      'MAYATBA',
      'MENDIOLA',
      'P. BURGOS',
      'PANDENO',
      'SALUBUNGAN',
      'WAWA',
    ],
  },
  AGDANGAN: {
    province: 'QUEZON',
    municipality: 'AGDANGAN',
    region: 'REGION IV-A',
    barangay_list: [
      'BINAGBAG',
      'DAYAP',
      'IBABANG KINAGUNAN',
      'ILAYANG KINAGUNAN',
      'KANLURANG CALUTAN',
      'KANLURANG MALIGAYA',
      'POBLACION I',
      'POBLACION II',
      'SALVACION',
      'SILANGANG CALUTAN',
      'SILANGANG MALIGAYA',
      'SILDORA',
    ],
  },
  ALABAT: {
    province: 'QUEZON',
    municipality: 'ALABAT',
    region: 'REGION IV-A',
    barangay_list: [
      'ANGELES',
      'BACONG',
      'BALUNGAY',
      'BARANGAY 1 (POB.)',
      'BARANGAY 2 (POB.)',
      'BARANGAY 3 (POB.)',
      'BARANGAY 4 (POB.)',
      'BARANGAY 5 (POB.)',
      'BUENAVISTA',
      'CAGLATE',
      'CAMAGONG',
      'GORDON',
      'PAMBILAN NORTE',
      'PAMBILAN SUR',
      'VILLA ESPERANZA',
      'VILLA JESUS ESTE',
      'VILLA JESUS WESTE',
      'VILLA NORTE',
      'VILLA VICTORIA',
    ],
  },
  ATIMONAN: {
    province: 'QUEZON',
    municipality: 'ATIMONAN',
    region: 'REGION IV-A',
    barangay_list: [
      'ANGELES',
      'BALUBAD',
      'BALUGOHIN',
      'BARANGAY ZONE 1 (POB.)',
      'BARANGAY ZONE 2 (POB.)',
      'BARANGAY ZONE 3 (POB.)',
      'BARANGAY ZONE 4 (POB.)',
      'BUHANGIN',
      'CARIDAD IBABA',
      'CARIDAD ILAYA',
      'HABINGAN',
      'INACLAGAN',
      'INALIG',
      'KILAIT',
      'KULAWIT',
      'LAKIP',
      'LUBI',
      'LUMUTAN',
      'MAGSAYSAY',
      'MALINAO IBABA',
      'MALINAO ILAYA',
      'MALUSAK',
      'MANGGALAYAN BUNDOK',
      'MANGGALAYAN LABAK',
      'MATANAG',
      'MONTES BALAON',
      'MONTES KALLAGAN',
      'PONON',
      'RIZAL',
      'SAN ANDRES BUNDOK',
      'SAN ANDRES LABAK',
      'SAN ISIDRO',
      'SAN JOSE BALATOK',
      'SAN RAFAEL',
      'SANTA CATALINA',
      'SAPAAN',
      'SOKOL',
      'TAGBAKIN',
      'TALABA',
      'TINANDOG',
      'VILLA IBABA',
      'VILLA ILAYA',
    ],
  },
  BURDEOS: {
    province: 'QUEZON',
    municipality: 'BURDEOS',
    region: 'REGION IV-A',
    barangay_list: [
      'ALUYON',
      'AMOT',
      'ANIBAWAN',
      'BONIFACIO',
      'CABUGAO',
      'CABUNGALUNAN',
      'CALUTCOT',
      'CANIWAN',
      'CARLAGAN',
      'MABINI',
      'PALASAN',
      'POBLACION',
      'SAN RAFAEL',
    ],
  },
  CALAUAG: {
    province: 'QUEZON',
    municipality: 'CALAUAG',
    region: 'REGION IV-A',
    barangay_list: [
      'AGOHO',
      'ANAHAWAN',
      'ANAS',
      'APAD LUTAO',
      'APAD QUEZON',
      'APAD TAISAN',
      'ATULAYAN',
      'BACLARAN',
      'BAGONG SILANG',
      'BALIBAGO',
      'BANGKURUHAN',
      'BANTOLINAO',
      'BARANGAY I (POB.)',
      'BARANGAY II (POB.)',
      'BARANGAY III (POB.)',
      'BARANGAY IV (POB.)',
      'BARANGAY V (POB.)',
      'BIGAAN',
      'BINUTAS',
      'BIYAN',
      'BUKAL',
      'BULI',
      'DAPDAP',
      'DOMINLOG',
      'DOÑA AURORA',
      'GUINOSAYAN',
      'IPIL',
      'KALIBO (SANTA CRUZ)',
      'KAPALUHAN',
      'KATANGTANG',
      'KIGTAN',
      'KINALIN IBABA',
      'KINALIN ILAYA',
      'KINAMALIGAN',
      'KUMALUDKUD',
      'KUNALUM',
      'KUYAOYAO',
      'LAGAY',
      'LAINGLAINGAN',
      'LUNGIB',
      'MABINI',
      'MADLANGDUNGAN',
      'MAGLIPAD (ROSARIO)',
      'MALIGAYA',
      'MAMBALING',
      'MANHULUGIN',
      'MARILAG (PUNAYA)',
      'MULAY',
      'PANDANAN',
      'PANSOL',
      'PATIHAN',
      'PINAGBAYANAN',
      'PINAGKAMALIGAN',
      'PINAGSAKAHAN',
      'PINAGTALLERAN',
      'RIZAL IBABA',
      'RIZAL ILAYA',
      'SABANG I',
      'SABANG II',
      'SALVACION',
      'SAN QUINTIN',
      'SAN ROQUE IBABA',
      'SAN ROQUE ILAYA',
      'SANTA CECILIA',
      'SANTA MARIA',
      'SANTA MILAGROSA',
      'SANTA ROSA',
      'SANTO ANGEL (PANGAHOY)',
      'SANTO DOMINGO',
      'SINAG',
      'SUMILANG',
      'SUMULONG',
      'TABANSAK',
      'TALINGTING',
      'TAMIS',
      'TIKIWAN',
      'TINIGUIBAN',
      'VILLA MAGSINO',
      'VILLA SAN ISIDRO',
      'VIÑAS',
      'YAGANAK',
    ],
  },
  CATANAUAN: {
    province: 'QUEZON',
    municipality: 'CATANAUAN',
    region: 'REGION IV-A',
    barangay_list: [
      'AJOS',
      'ANUSAN',
      'BARANGAY 1 (POB.)',
      'BARANGAY 2 (POB.)',
      'BARANGAY 3 (POB.)',
      'BARANGAY 4 (POB.)',
      'BARANGAY 5 (POB.)',
      'BARANGAY 6 (POB.)',
      'BARANGAY 7 (POB.)',
      'BARANGAY 8 (POB.)',
      'BARANGAY 9 (POB.)',
      'BARANGAY 10 (POB.)',
      'BOLO',
      'BULAGSONG',
      'CAMANDIISON',
      'CANCULAJAO',
      'CATUMBO',
      'CAWAYANIN IBABA',
      'CAWAYANIN ILAYA',
      'CUTCUTAN',
      'DAHICAN',
      'DOONGAN IBABA',
      'DOONGAN ILAYA',
      'GATASAN',
      'MACPAC',
      'MADULAO',
      'MATANDANG SABANG KANLURAN',
      'MATANDANG SABANG SILANGAN',
      'MILAGROSA',
      'NAVITAS',
      'PACABIT',
      'SAN ANTONIO MAGKUPA',
      'SAN ANTONIO PALA',
      'SAN ISIDRO',
      'SAN JOSE (ANYAO)',
      'SAN PABLO (SUHA)',
      'SAN ROQUE (DOYON)',
      'SAN VICENTE KANLURAN',
      'SAN VICENTE SILANGAN',
      'SANTA MARIA (DAO)',
      'TAGABAS IBABA',
      'TAGABAS ILAYA',
      'TAGBACAN IBABA',
      'TAGBACAN ILAYA',
      'TAGBACAN SILANGAN',
      'TUHIAN',
    ],
  },
  DOLORES: {
    province: 'ABRA',
    municipality: 'DOLORES',
    region: 'CAR',
    barangay_list: [
      'BAYAAN',
      'CABAROAN',
      'CALUMBAYA',
      'CARDONA',
      'ISIT',
      'KIMMALABA',
      'LIBTEC',
      'LUB-LUBBA',
      'MUDIIT',
      'NAMIT-INGAN',
      'PACAC',
      'POBLACION',
      'SALUCAG',
      'TALOGTOG',
      'TAPING',
    ],
  },
  'GENERAL NAKAR': {
    province: 'QUEZON',
    municipality: 'GENERAL NAKAR',
    region: 'REGION IV-A',
    barangay_list: [
      'ANOLING',
      'BANGLOS',
      'BATANGAN',
      'CANAWAY',
      'CATABLINGAN',
      'LUMUTAN',
      'MAGSIKAP',
      'MAHABANG LALIM',
      'MAIGANG',
      'MALIGAYA',
      'MINAHAN NORTE',
      'MINAHAN SUR',
      'PAGSANGAHAN',
      'PAMPLONA',
      'PISA',
      'POBLACION',
      'SABLANG',
      'SAN MARCELINO',
      'UMIRAY',
    ],
  },
  GUINAYANGAN: {
    province: 'QUEZON',
    municipality: 'GUINAYANGAN',
    region: 'REGION IV-A',
    barangay_list: [
      'A. MABINI',
      'ALONEROS',
      'ARBISMEN',
      'BAGONG SILANG',
      'BALINARIN',
      'BUKAL MALIGAYA',
      'CABIBIHAN',
      'CABONG NORTE',
      'CABONG SUR',
      'CALIMPAK',
      'CAPULUAN CENTRAL',
      'CAPULUAN TULON',
      'DANCALAN CAIMAWAN',
      'DANCALAN CENTRAL',
      'DANLAGAN BATIS',
      'DANLAGAN CABAYAO',
      'DANLAGAN CENTRAL',
      'DANLAGAN RESERVA',
      'DEL ROSARIO',
      'DUNGAWAN CENTRAL',
      'DUNGAWAN PAALYUNAN',
      'DUNGAWAN PANTAY',
      'ERMITA',
      'GAPAS',
      'HIMBUBULO ESTE',
      'HIMBUBULO WESTE',
      'HINABAAN',
      'LIGPIT BANTAYAN',
      'LUBIGAN',
      'MAGALLANES',
      'MAGSAYSAY',
      'MANGGAGAWA',
      'MANGGALANG',
      'MANLAYO',
      'POBLACION',
      'SALAKAN',
      'SAN ANTONIO',
      'SAN ISIDRO',
      'SAN JOSE',
      'SAN LORENZO',
      'SAN LUIS I',
      'SAN LUIS II',
      'SAN MIGUEL',
      'SAN PEDRO I',
      'SAN PEDRO II',
      'SAN ROQUE',
      'SANTA CRUZ',
      'SANTA MARIA',
      'SANTA TERESITA',
      'SINTONES',
      'SISI',
      'TIKAY',
      'TRIUMPO',
      'VILLA HIWASAYAN',
    ],
  },
  GUMACA: {
    province: 'QUEZON',
    municipality: 'GUMACA',
    region: 'REGION IV-A',
    barangay_list: [
      'ADIA BITAOG',
      'ANONANGIN',
      'BAGONG BUHAY (POB.)',
      'BAMBAN',
      'BANTAD',
      'BATONG DALIG',
      'BIGA',
      'BINAMBANG',
      'BUENSUCESO',
      'BUNGAHAN',
      'BUTAGUIN',
      'CALUMANGIN',
      'CAMOHAGUIN',
      'CASASAHAN IBABA',
      'CASASAHAN ILAYA',
      'CAWAYAN',
      'GAYAGAYAAN',
      'GITNANG BARRIO',
      'HAGAKHAKIN',
      'HARDINAN',
      'INACLAGAN',
      'INAGBUHAN ILAYA',
      'LABNIG',
      'LAGUNA',
      'LAGYO',
      'MABINI (POB.)',
      'MABUNGA',
      'MALABTOG',
      'MANLAYAAN',
      'MARCELO H. DEL PILAR',
      'MATAAS NA BUNDOK',
      'MAUNLAD (POB.)',
      'PAGSABANGAN',
      'PANIKIHAN',
      'PEÑAFRANCIA (POB.)',
      'PIPISIK (POB.)',
      'PROGRESO',
      'RIZAL (POB.)',
      'ROSARIO',
      'SAN AGUSTIN',
      'SAN DIEGO',
      'SAN DIEGO POBLACION',
      'SAN ISIDRO KANLURAN',
      'SAN ISIDRO SILANGAN',
      'SAN JUAN DE JESUS',
      'SAN VICENTE',
      'SASTRE',
      'TABING DAGAT (POB.)',
      'TUMAYAN',
      'VILLA ARCAYA',
      'VILLA BOTA',
      'VILLA FUERTE',
      'VILLA M. PRINCIPE',
      'VILLA MENDOZA',
      'VILLA NAVA',
      'VILLA PADUA',
      'VILLA PEREZ',
      'VILLA TAÑADA',
      'VILLA VICTORIA',
    ],
  },
  JOMALIG: {
    province: 'QUEZON',
    municipality: 'JOMALIG',
    region: 'REGION IV-A',
    barangay_list: ['APAD', 'BUKAL', 'CASUGURAN', 'GANGO', 'TALISOY (POB.)'],
  },
  LOPEZ: {
    province: 'QUEZON',
    municipality: 'LOPEZ',
    region: 'REGION IV-A',
    barangay_list: [
      'BACUNGAN',
      'BAGACAY',
      'BANABAHIN IBABA',
      'BANABAHIN ILAYA',
      'BAYABAS',
      'BEBITO',
      'BIGAJO',
      'BINAHIAN A',
      'BINAHIAN B',
      'BINAHIAN C',
      'BOCBOC',
      'BUENAVISTA',
      'BURGOS (POB.)',
      'BUYACANIN',
      'CAGACAG',
      'CALANTIPAYAN',
      'CANDA IBABA',
      'CANDA ILAYA',
      'CAWAYAN',
      'CAWAYANIN',
      'COGORIN IBABA',
      'COGORIN ILAYA',
      'CONCEPCION',
      'DANLAGAN',
      'DE LA PAZ',
      'DEL PILAR',
      'DEL ROSARIO',
      'ESPERANZA IBABA',
      'ESPERANZA ILAYA',
      'GOMEZ (POB.)',
      'GUIHAY',
      'GUINUANGAN',
      'GUITES',
      'HONDAGUA',
      'ILAYANG ILOG A',
      'ILAYANG ILOG B',
      'INALUSAN',
      'JONGO',
      'LALAGUNA',
      'LOURDES',
      'MABANBAN',
      'MABINI',
      'MAGALLANES',
      'MAGSAYSAY (POB.)',
      'MAGUILAYAN',
      'MAHAYOD-HAYOD',
      'MAL-AY',
      'MANDOOG',
      'MANGUISIAN',
      'MATINIK',
      'MONTECLARO',
      'PAMAMPANGIN',
      'PANSOL',
      'PEÑAFRANCIA',
      'PISIPIS',
      'RIZAL (POBLACION)',
      'RIZAL (RURAL)',
      'ROMA',
      'ROSARIO',
      'SAMAT',
      'SAN ANDRES',
      'SAN ANTONIO',
      'SAN FRANCISCO A',
      'SAN FRANCISCO B',
      'SAN ISIDRO',
      'SAN JOSE',
      'SAN MIGUEL (DAO)',
      'SAN PEDRO',
      'SAN RAFAEL',
      'SAN ROQUE',
      'SANTA CATALINA',
      'SANTA ELENA',
      'SANTA JACOBE',
      'SANTA LUCIA',
      'SANTA MARIA',
      'SANTA ROSA',
      'SANTA TERESA (MAYUPAG)',
      'SANTO NIÑO IBABA',
      'SANTO NIÑO ILAYA',
      'SILANG',
      'SUGOD',
      'SUMALANG',
      'TALOLONG (POB.)',
      'TAN-AG IBABA',
      'TAN-AG ILAYA',
      'TOCALIN',
      'VEGAFLOR',
      'VERGAÑA',
      'VERONICA',
      'VILLA AURORA',
      'VILLA ESPINA',
      'VILLA GEDA',
      'VILLA HERMOSA',
      'VILLAMONTE',
      'VILLANACAOB',
    ],
  },
  LUCBAN: {
    province: 'QUEZON',
    municipality: 'LUCBAN',
    region: 'REGION IV-A',
    barangay_list: [
      'ABANG',
      'ALILIW',
      'ATULINAO',
      'AYUTI',
      'BARANGAY 1 (POB.)',
      'BARANGAY 2 (POB.)',
      'BARANGAY 3 (POB.)',
      'BARANGAY 4 (POB.)',
      'BARANGAY 5 (POB.)',
      'BARANGAY 6 (POB.)',
      'BARANGAY 7 (POB.)',
      'BARANGAY 8 (POB.)',
      'BARANGAY 9 (POB.)',
      'BARANGAY 10 (POB.)',
      'IGANG',
      'KABATETE',
      'KAKAWIT',
      'KALANGAY',
      'KALYAAT',
      'KILIB',
      'KULAPI',
      'MAHABANG PARANG',
      'MALUPAK',
      'MANASA',
      'MAY-IT',
      'NAGSINAMO',
      'NALUNAO',
      'PALOLA',
      'PIIS',
      'SAMIL',
      'TIAWE',
      'TINAMNAN',
    ],
  },
  'LUCENA': {
    province: 'QUEZON',
    municipality: 'LUCENA',
    region: 'REGION IV-A',
    barangay_list: [
      'BARANGAY 1 (POB.)',
      'BARANGAY 2 (POB.)',
      'BARANGAY 3 (POB.)',
      'BARANGAY 4 (POB.)',
      'BARANGAY 5 (POB.)',
      'BARANGAY 6 (POB.)',
      'BARANGAY 7 (POB.)',
      'BARANGAY 8 (POB.)',
      'BARANGAY 9 (POB.)',
      'BARANGAY 10 (POB.)',
      'BARANGAY 11 (POB.)',
      'BARRA',
      'BOCOHAN',
      'COTTA',
      'DALAHICAN',
      'DOMOIT',
      'GULANG-GULANG',
      'IBABANG DUPAY',
      'IBABANG IYAM',
      'IBABANG TALIM',
      'ILAYANG DUPAY',
      'ILAYANG IYAM',
      'ILAYANG TALIM',
      'ISABANG',
      'MARKET VIEW',
      'MAYAO CASTILLO',
      'MAYAO CROSSING',
      'MAYAO KANLURAN',
      'MAYAO PARADA',
      'MAYAO SILANGAN',
      'RANSOHAN',
      'SALINAS',
      'TALAO-TALAO',
    ],
  },
  MACALELON: {
    province: 'QUEZON',
    municipality: 'MACALELON',
    region: 'REGION IV-A',
    barangay_list: [
      'AMONTAY',
      'ANOS',
      'BUYAO',
      'CALANTAS',
      'CANDANGAL',
      'CASTILLO (POB.)',
      'DAMAYAN (POB.)',
      'LAHING',
      'LUCTOB',
      'MABINI IBABA',
      'MABINI ILAYA',
      'MALABAHAY',
      'MAMBOG',
      'MASIPAG (POB.)',
      'OLONGTAO IBABA',
      'OLONGTAO ILAYA',
      'PADRE HERRERA',
      'PAG-ASA (POB.)',
      'PAJARILLO',
      'PINAGBAYANAN',
      'RIZAL (POB.)',
      'RODRIQUEZ (POB.)',
      'SAN ISIDRO',
      'SAN JOSE',
      'SAN NICOLAS',
      'SAN VICENTE',
      'TAGUIN',
      'TUBIGAN IBABA',
      'TUBIGAN ILAYA',
      'VISTA HERMOSA',
    ],
  },
  MAUBAN: {
    province: 'QUEZON',
    municipality: 'MAUBAN',
    region: 'REGION IV-A',
    barangay_list: [
      'ABO-ABO',
      'ALITAP',
      'BAAO',
      'BAGONG BAYAN (POB.)',
      'BALAYBALAY',
      'BATO',
      'CAGBALETE I',
      'CAGBALETE II',
      'CAGSIAY I',
      'CAGSIAY II',
      'CAGSIAY III',
      'CONCEPCION',
      'DAUNGAN (POB.)',
      'LIWAYWAY',
      'LUAL (POB.)',
      'LUAL RURAL',
      'LUCUTAN',
      'LUYA-LUYA',
      'MABATO (POB.)',
      'MACASIN',
      'POLO',
      'REMEDIOS I',
      'REMEDIOS II',
      'RIZALIANA (POB.)',
      'ROSARIO',
      'SADSARAN (POB.)',
      'SAN GABRIEL',
      'SAN ISIDRO',
      'SAN JOSE',
      'SAN LORENZO',
      'SAN MIGUEL',
      'SAN RAFAEL',
      'SAN ROQUE',
      'SAN VICENTE',
      'SANTA LUCIA',
      'SANTO ANGEL',
      'SANTO NIÑO',
      'SANTOL',
      'SOLEDAD',
      'TAPUCAN',
    ],
  },
  MULANAY: {
    province: 'QUEZON',
    municipality: 'MULANAY',
    region: 'REGION IV-A',
    barangay_list: [
      'AJOS',
      'AMUGUIS',
      'ANONANG',
      'BAGONG SILANG',
      'BAGUPAYE',
      'BARANGAY 1 (POB.)',
      'BARANGAY 2 (POB.)',
      'BARANGAY 3 (POB.)',
      'BARANGAY 4 (POB.)',
      'BOLO',
      'BUENAVISTA',
      'BURGOS',
      'BUTANYOG',
      'CANUYEP',
      'F. NANADIEGO',
      'IBABANG CAMBUGA',
      'IBABANG YUNI',
      'ILAYANG CAMBUGA (MABINI)',
      'ILAYANG YUNI',
      'LATANGAN',
      'MAGSAYSAY',
      'MATATAJA',
      'PAKIING',
      'PATABOG',
      'SAGONGON',
      'SAN ISIDRO',
      'SAN PEDRO',
      'SANTA ROSA',
    ],
  },
  'PADRE BURGOS': {
    province: 'SOUTHERN LEYTE',
    municipality: 'PADRE BURGOS',
    region: 'REGION VIII',
    barangay_list: [
      'BUENAVISTA',
      'BUNGA',
      'CANTUTANG',
      'DINAHUGAN',
      'LACA',
      'LUNGSODAAN',
      'POBLACION',
      'SAN JUAN',
      'SANTA SOFIA',
      'SANTO ROSARIO',
      'TANGKAAN',
    ],
  },
  PAGBILAO: {
    province: 'QUEZON',
    municipality: 'PAGBILAO',
    region: 'REGION IV-A',
    barangay_list: [
      'ALUPAYE',
      'ANTIPOLO',
      'AÑATO',
      'BANTIGUE',
      'BARANGAY 1 CASTILLO (POB.)',
      'BARANGAY 2 DAUNGAN (POB.)',
      'BARANGAY 3 DEL CARMEN (POB.)',
      'BARANGAY 4 PARANG (POB.)',
      'BARANGAY 5 SANTA CATALINA (POB.)',
      'BARANGAY 6 TAMBAK (POB.)',
      'BIGO',
      'BINAHAAN',
      'BUKAL',
      'IBABANG BAGUMBUNGAN',
      'IBABANG PALSABANGON',
      'IBABANG POLO',
      'IKIRIN',
      'ILAYANG BAGUMBUNGAN',
      'ILAYANG PALSABANGON',
      'ILAYANG POLO',
      'KANLURAN MALICBOY',
      'MAPAGONG',
      'MAYHAY',
      'PINAGBAYANAN',
      'SILANGAN MALICBOY',
      'TALIPAN',
      'TUKALAN',
    ],
  },
  PANUKULAN: {
    province: 'QUEZON',
    municipality: 'PANUKULAN',
    region: 'REGION IV-A',
    barangay_list: [
      'BALUNGAY',
      'BATO',
      'BONBON',
      'CALASUMANGA',
      'KINALAGTI',
      'LIBO',
      'LIPATA',
      'MATANGKAP',
      'MILAWID',
      'PAGITAN',
      'PANDAN',
      'RIZAL',
      'SAN JUAN (POB.)',
    ],
  },
  PATNANUNGAN: {
    province: 'QUEZON',
    municipality: 'PATNANUNGAN',
    region: 'REGION IV-A',
    barangay_list: [
      'AMAGA',
      'BUSDAK',
      'KILOGAN',
      'LUOD',
      'PATNANUNGAN NORTE',
      'PATNANUNGAN SUR (POB.)',
    ],
  },
  PEREZ: {
    province: 'QUEZON',
    municipality: 'PEREZ',
    region: 'REGION IV-A',
    barangay_list: [
      'BAGONG PAG-ASA POB. (BARANGAY 3)',
      'BAGONG SILANG POB. (BARANGAY 4)',
      'MAABOT',
      'MAINIT NORTE',
      'MAINIT SUR',
      'MAPAGMAHAL POB. (BARANGAY 2)',
      'PAGKAKAISA POB. (BARANGAY 1)',
      'PAMBUHAN',
      'PINAGTUBIGAN ESTE',
      'PINAGTUBIGAN WESTE',
      'RIZAL',
      'SANGIRIN',
      'VILLAMANZANO NORTE',
      'VILLAMANZANO SUR',
    ],
  },
  PITOGO: {
    province: 'ZAMBOANGA DEL SUR',
    municipality: 'PITOGO',
    region: 'REGION IX',
    barangay_list: [
      'BALABAWAN',
      'BALONG-BALONG',
      'COLOJO',
      'LIASAN',
      'LIGUAC',
      'LIMBAYAN',
      'LOWER PANIKI-AN',
      'MATIN-AO',
      'PANUBIGAN',
      'POBLACION (PITOGO)',
      'PUNTA FLECHA',
      'SAN ISIDRO',
      'SUGBAY DOS',
      'TONGAO',
      'UPPER PANIKI-AN',
    ],
  },
  POLILLO: {
    province: 'QUEZON',
    municipality: 'POLILLO',
    region: 'REGION IV-A',
    barangay_list: [
      'ANAWAN',
      'ATULAYAN',
      'BALESIN',
      'BAÑADERO',
      'BINIBITINAN',
      'BISLIAN',
      'BUCAO',
      'CANICANIAN',
      'KALUBAKIS',
      'LANGUYIN',
      'LIBJO',
      'PAMATDAN',
      'PILION',
      'PINAGLUBAYAN',
      'POBLACION',
      'SABANG',
      'SALIPSIP',
      'SIBULAN',
      'TALUONG',
      'TAMULAYA-ANITONG',
    ],
  },
  REAL: {
    province: 'QUEZON',
    municipality: 'REAL',
    region: 'REGION IV-A',
    barangay_list: [
      'BAGONG SILANG',
      'CAPALONG',
      'CAWAYAN',
      'KILOLORAN',
      'LLAVAC',
      'LUBAYAT',
      'MALAPAD',
      'MARAGONDON',
      'MASIKAP',
      'MAUNLAD',
      'PANDAN',
      'POBLACION 61 (BARANGAY 2)',
      'POBLACION I (BARANGAY 1)',
      'TAGUMPAY',
      'TANAUAN',
      'TIGNOAN',
      'UNGOS',
    ],
  },
  'SAN ANDRES': {
    province: 'ROMBLON',
    municipality: 'SAN ANDRES',
    region: 'REGION IV-B',
    barangay_list: [
      'AGPUDLOS',
      'CALUNACON',
      'DOÑA TRINIDAD',
      'JUNCARLO',
      'LINAWAN',
      'MABINI',
      'MARIGONDON NORTE',
      'MARIGONDON SUR',
      'MATUTUNA',
      'PAG-ALAD',
      'POBLACION',
      'TAN-AGAN',
      'VICTORIA',
    ],
  },
  'SAN FRANCISCO (AURORA)': {
    province: 'QUEZON',
    municipality: 'SAN FRANCISCO (AURORA)',
    region: 'REGION IV-A',
    barangay_list: [
      'BUTANGUIAD',
      'CASAY',
      'CAWAYAN I',
      'CAWAYAN II',
      'DON JUAN VERCELOS',
      'HUYON-UYON',
      'IBABANG TAYUMAN',
      'ILAYANG TAYUMAN',
      'INABUAN',
      'MABUÑGA',
      'NASALAAN',
      'PAGSANGAHAN',
      'POBLACION',
      'PUGON',
      'SANTO NIÑO',
      'SILONGIN',
    ],
  },
  SARIAYA: {
    province: 'QUEZON',
    municipality: 'SARIAYA',
    region: 'REGION IV-A',
    barangay_list: [
      'ANTIPOLO',
      'BALUBAL',
      'BARANGAY 1 (POB.)',
      'BARANGAY 2 (POB.)',
      'BARANGAY 3 (POB.)',
      'BARANGAY 4 (POB.)',
      'BARANGAY 5 (POB.)',
      'BARANGAY 6 (POB.)',
      'BIGNAY 1',
      'BIGNAY 2',
      'BUCAL',
      'CANDA',
      'CASTAÑAS',
      'CONCEPCION BANAHAW',
      'CONCEPCION NO. 1',
      'CONCEPCION PALASAN',
      'CONCEPCION PINAGBAKURAN',
      'GIBANGA',
      'GUISGUIS-SAN ROQUE',
      'GUISGUIS-TALON',
      'JANAGDONG 1',
      'JANAGDONG 2',
      'LIMBON',
      'LUTUCAN 1',
      'LUTUCAN BATA',
      'LUTUCAN MALABAG',
      'MAMALA I',
      'MAMALA II',
      'MANGGALANG 1',
      'MANGGALANG TULO-TULO',
      'MANGGALANG-BANTILAN',
      'MANGGALANG-KILING',
      'MONTECILLO',
      'MORONG',
      'PILI',
      'SAMPALOC 1',
      'SAMPALOC 2',
      'SAMPALOC BOGON',
      'SAMPALOC SANTO CRISTO',
      'TALAAN APLAYA',
      'TALAANPANTOC',
      'TUMBAGA 1',
      'TUMBAGA 2',
    ],
  },
  TAGKAWAYAN: {
    province: 'QUEZON',
    municipality: 'TAGKAWAYAN',
    region: 'REGION IV-A',
    barangay_list: [
      'ALDAVOC',
      'ALIJI',
      'BAGONG SILANG',
      'BAMBAN',
      'BOSIGON',
      'BUKAL',
      'CABIBIHAN',
      'CABUGWANG',
      'CAGASCAS',
      'CANDALAPDAP',
      'CASISPALAN',
      'COLONG-COLONG',
      'DEL ROSARIO',
      'KATIMO',
      'KINATAKUTAN',
      'LANDING',
      'LAUREL',
      'MAGSAYSAY',
      'MAGUIBUAY',
      'MAHINTA',
      'MALBOG',
      'MANATO CENTRAL',
      'MANATO STATION',
      'MANGAYAO',
      'MANSILAY',
      'MAPULOT',
      'MUNTING PARANG',
      'PAYAPA',
      'POBLACION',
      'RIZAL',
      'SABANG',
      'SAN DIEGO',
      'SAN FRANCISCO',
      'SAN ISIDRO',
      'SAN ROQUE',
      'SAN VICENTE',
      'SANTA CECILIA',
      'SANTA MONICA',
      'SANTO NIÑO I',
      'SANTO NIÑO II',
      'SANTO TOMAS',
      'SEGUIWAN',
      'TABASON',
      'TUNTON',
      'VICTORIA',
    ],
  },
  'TAYABAS': {
    province: 'QUEZON',
    municipality: 'TAYABAS',
    region: 'REGION IV-A',
    barangay_list: [
      'ALITAO',
      'ALSAM IBABA',
      'ALSAM ILAYA',
      'ALUPAY',
      'ANGELES ZONE I (POB.)',
      'ANGELES ZONE II',
      'ANGELES ZONE III',
      'ANGELES ZONE IV',
      'ANGUSTIAS ZONE I (POB.)',
      'ANGUSTIAS ZONE II',
      'ANGUSTIAS ZONE III',
      'ANGUSTIAS ZONE IV',
      'ANOS',
      'AYAAS',
      'BAGUIO',
      'BANILAD',
      'BUKAL IBABA',
      'BUKAL ILAYA',
      'CALANTAS',
      'CALUMPANG',
      'CAMAYSA',
      'DAPDAP',
      'DOMOIT KANLURAN',
      'DOMOIT SILANGAN',
      'GIBANGA',
      'IBAS',
      'ILASAN IBABA',
      'ILASAN ILAYA',
      'IPILAN',
      'ISABANG',
      'KATIGAN KANLURAN',
      'KATIGAN SILANGAN',
      'LAKAWAN',
      'LALO',
      'LAWIGUE',
      'LITA (POB.)',
      'MALAOA',
      'MASIN',
      'MATE',
      'MATEUNA',
      'MAYOWE',
      'NANGKA IBABA',
      'NANGKA ILAYA',
      'OPIAS',
      'PALALE IBABA',
      'PALALE ILAYA',
      'PALALE KANLURAN',
      'PALALE SILANGAN',
      'PANDAKAKI',
      'POOK',
      'POTOL',
      'SAN DIEGO ZONE I (POB.)',
      'SAN DIEGO ZONE II',
      'SAN DIEGO ZONE III',
      'SAN DIEGO ZONE IV',
      'SAN ISIDRO ZONE I (POB.)',
      'SAN ISIDRO ZONE II',
      'SAN ISIDRO ZONE III',
      'SAN ISIDRO ZONE IV',
      'SAN ROQUE ZONE I (POB.)',
      'SAN ROQUE ZONE II',
      'TALOLONG',
      'TAMLONG',
      'TONGKO',
      'VALENCIA',
      'WAKAS',
    ],
  },
  TIAONG: {
    province: 'QUEZON',
    municipality: 'TIAONG',
    region: 'REGION IV-A',
    barangay_list: [
      'ANASTACIA (TAGBAK)',
      'AQUINO',
      'AYUSAN I',
      'AYUSAN II',
      'BARANGAY I (POB.)',
      'BARANGAY II (POB.)',
      'BARANGAY III (POB.)',
      'BARANGAY IV (POB.)',
      'BEHIA',
      'BUKAL',
      'BULA',
      'BULAKIN',
      'CABATANG',
      'CABAY',
      'DEL ROSARIO',
      'LAGALAG',
      'LALIG',
      'LUMINGON',
      'LUSACAN',
      'PAIISA',
      'PALAGARAN',
      'QUIPOT',
      'SAN AGUSTIN',
      'SAN FRANCISCO',
      'SAN ISIDRO',
      'SAN JOSE',
      'SAN JUAN',
      'SAN PEDRO',
      'TAGBAKIN',
      'TALISAY',
      'TAMISIAN',
    ],
  },
  UNISAN: {
    province: 'QUEZON',
    municipality: 'UNISAN',
    region: 'REGION IV-A',
    barangay_list: [
      'ALMACEN',
      'BALAGTAS',
      'BALANACAN',
      'BONIFACIO',
      'BULO IBABA',
      'BULO ILAYA',
      'BURGOS',
      'CABULIHAN IBABA',
      'CABULIHAN ILAYA',
      'CAIGDAL',
      'F. DE JESUS (POB.)',
      'GENERAL LUNA',
      'KALILAYAN IBABA',
      'KALILAYAN ILAYA',
      'MABINI',
      'MAIROK IBABA',
      'MAIROK ILAYA',
      'MALVAR',
      'MAPUTAT',
      'MULIGUIN',
      'PAGAGUASAN',
      'PANAON IBABA',
      'PANAON ILAYA',
      'PLARIDEL',
      'POCTOL',
      'PUNTA',
      'R. LAPU-LAPU (POB.)',
      'R. MAGSAYSAY (POB.)',
      'RAJA SOLIMAN (POB.)',
      'RIZAL IBABA',
      'RIZAL ILAYA',
      'SAN ROQUE',
      'SOCORRO',
      'TAGUMPAY',
      'TUBAS',
      'TUBIGAN',
    ],
  },
  ANGONO: {
    province: 'RIZAL',
    municipality: 'ANGONO',
    region: 'REGION IV-A',
    barangay_list: [
      'BAGUMBAYAN',
      'KALAYAAN',
      'MAHABANG PARANG',
      'POBLACION IBABA',
      'POBLACION ITAAS',
      'SAN ISIDRO',
      'SAN PEDRO',
      'SAN ROQUE',
      'SAN VICENTE',
      'SANTO NIÑO',
    ],
  },
  BARAS: {
    province: 'CATANDUANES',
    municipality: 'BARAS',
    region: 'REGION V',
    barangay_list: [
      'ABIHAO',
      'AGBAN',
      'BAGONG SIRANG',
      'BATOLINAO',
      'BENTICAYAN',
      'BUENAVISTA',
      'CARAGUMIHAN',
      'DANAO',
      'EASTERN POBLACION',
      'GINITLIGAN',
      'GUINSAANAN',
      'J. M. ALBERTO',
      'MACUTAL',
      'MONING',
      'NAGBARORONG',
      'OSMEÑA',
      'P. TESTON',
      'PANIQUIHAN',
      'PURARAN',
      'PUTSAN',
      'QUEZON',
      'RIZAL',
      'SAGRADA',
      'SALVACION',
      'SAN LORENZO',
      'SAN MIGUEL',
      'SANTA MARIA',
      'TILOD',
      'WESTERN POBLACION',
    ],
  },
  BINANGONAN: {
    province: 'RIZAL',
    municipality: 'BINANGONAN',
    region: 'REGION IV-A',
    barangay_list: [
      'BANGAD',
      'BATINGAN',
      'BILIBIRAN',
      'BINITAGAN',
      'BOMBONG',
      'BUHANGIN',
      'CALUMPANG',
      'GINOONG SANAY',
      'GULOD',
      'HABAGATAN',
      'ITHAN',
      'JANOSA',
      'KALAWAAN',
      'KALINAWAN',
      'KASILE',
      'KAYTOME',
      'KINABOOGAN',
      'KINAGATAN',
      'LAYUNAN (POB.)',
      'LIBID (POB.)',
      'LIBIS (POB.)',
      'LIMBON-LIMBON',
      'LUNSAD',
      'MACAMOT',
      'MAHABANG PARANG',
      'MALAKABAN',
      'MAMBOG',
      'PAG-ASA',
      'PALANGOY',
      'PANTOK',
      'PILA PILA',
      'PINAGDILAWAN',
      'PIPINDAN',
      'RAYAP',
      'SAN CARLOS',
      'SAPANG',
      'TABON',
      'TAGPOS',
      'TATALA',
      'TAYUMAN',
    ],
  },
  CAINTA: {
    province: 'RIZAL',
    municipality: 'CAINTA',
    region: 'REGION IV-A',
    barangay_list: [
      'SAN ANDRES (POB.)',
      'SAN ISIDRO',
      'SAN JUAN',
      'SAN ROQUE',
      'SANTA ROSA',
      'SANTO DOMINGO',
      'SANTO NIÑO',
    ],
  },
  CARDONA: {
    province: 'RIZAL',
    municipality: 'CARDONA',
    region: 'REGION IV-A',
    barangay_list: [
      'BALIBAGO',
      'BOOR',
      'CALAHAN',
      'DALIG',
      'DEL REMEDIO (POB.)',
      'IGLESIA (POB.)',
      'LAMBAC',
      'LOOC',
      'MALANGGAM-CALUBACAN',
      'NAGSULO',
      'NAVOTAS',
      'PATUNHAY',
      'REAL (POB.)',
      'SAMPAD',
      'SAN ROQUE (POB.)',
      'SUBAY',
      'TICULIO',
      'TUNA',
    ],
  },
  'ANTIPOLO': {
    province: 'RIZAL',
    municipality: 'ANTIPOLO',
    region: 'REGION IV-A',
    barangay_list: [
      'BAGONG NAYON',
      'BEVERLY HILLS',
      'CALAWIS',
      'CUPANG',
      'DALIG',
      'DELA PAZ (POB.)',
      'INARAWAN',
      'MAMBUGAN',
      'MAYAMOT',
      'MUNTINGDILAW',
      'SAN ISIDRO (POB.)',
      'SAN JOSE (POB.)',
      'SAN JUAN',
      'SAN LUIS',
      'SAN ROQUE (POB.)',
      'SANTA CRUZ',
    ],
  },
  'JALA-JALA': {
    province: 'RIZAL',
    municipality: 'JALA-JALA',
    region: 'REGION IV-A',
    barangay_list: [
      'BAGUMBONG',
      'BAYUGO',
      'LUBO',
      'PAALAMAN',
      'PAGKALINAWAN',
      'PALAYPALAY',
      'PUNTA',
      'SECOND DISTRICT (POB.)',
      'SIPSIPIN',
      'SPECIAL DISTRICT (POB.)',
      'THIRD DISTRICT (POB.)',
    ],
  },
  PILILLA: {
    province: 'RIZAL',
    municipality: 'PILILLA',
    region: 'REGION IV-A',
    barangay_list: [
      'BAGUMBAYAN (POB.)',
      'HALAYHAYIN',
      'HULO (POB.)',
      'IMATONG (POB.)',
      'MALAYA',
      'NIOGAN',
      'QUISAO',
      'TAKUNGAN (POB.)',
      'WAWA (POB.)',
    ],
  },
  'RODRIGUEZ (MONTALBAN)': {
    province: 'RIZAL',
    municipality: 'RODRIGUEZ (MONTALBAN)',
    region: 'REGION IV-A',
    barangay_list: [
      'BALITE (POB.)',
      'BURGOS',
      'GERONIMO',
      'MACABUD',
      'MANGGAHAN',
      'MASCAP',
      'PURAY',
      'ROSARIO',
      'SAN ISIDRO',
      'SAN JOSE',
      'SAN RAFAEL',
    ],
  },
  TANAY: {
    province: 'RIZAL',
    municipality: 'TANAY',
    region: 'REGION IV-A',
    barangay_list: [
      'CAYABU',
      'CUYAMBAY',
      'DARAITAN',
      'KATIPUNAN-BAYAN (POB.)',
      'KAYBUTO (POB.)',
      'LAIBAN',
      'MADILAY-DILAY',
      'MAG-AMPON (POB.)',
      'MAMUYAO',
      'PINAGKAMALIGAN (POB.)',
      'PLAZA ALDEA (POB.)',
      'SAMPALOC',
      'SAN ANDRES',
      'SAN ISIDRO (POB.)',
      'SANTA INEZ',
      'SANTO NIÑO',
      'TABING ILOG (POB.)',
      'TANDANG KUTYO (POB.)',
      'TINUCAN',
      'WAWA (POB.)',
    ],
  },
  TAYTAY: {
    province: 'PALAWAN',
    municipality: 'TAYTAY',
    region: 'REGION IV-B',
    barangay_list: [
      'ABONGAN',
      'ALACALIAN',
      'BANBANAN',
      'BANTULAN',
      'BARAS (PANGPANG)',
      'BATAS',
      'BATO',
      'BETON',
      'BUSY BEES',
      'CALAWAG',
      'CASIAN',
      'CATABAN',
      'DEBANGAN',
      'DEPLA',
      'LIBERTAD',
      'LIMINANGCONG',
      'MEYTEGUED',
      'MINAPLA',
      'NEW GUINLO',
      'OLD GUINLO',
      'PAGLAUM',
      'PALY (PALY ISLAND)',
      'PAMANTOLON',
      'PANCOL',
      'POBLACION',
      'PULARAQUEN (CANIQUE)',
      'SAN JOSE',
      'SANDOVAL',
      'SILANGA',
      'TALOG',
      'TUMBOD',
    ],
  },
  TERESA: {
    province: 'RIZAL',
    municipality: 'TERESA',
    region: 'REGION IV-A',
    barangay_list: [
      'BAGUMBAYAN',
      'CALUMPANG SANTO CRISTO',
      'DALIG',
      'DULUMBAYAN',
      'MAY-IBA',
      'POBLACION',
      'PRINZA',
      'SAN GABRIEL',
      'SAN ROQUE',
    ],
  },
  BOAC: {
    province: 'MARINDUQUE',
    municipality: 'BOAC',
    region: 'REGION IV-B',
    barangay_list: [
      'AGOT',
      'AGUMAYMAYAN',
      'AMOINGON',
      'APITONG',
      'BALAGASAN',
      'BALARING',
      'BALIMBING',
      'BALOGO',
      'BAMBAN',
      'BANGBANGALON',
      'BANTAD',
      'BANTAY',
      'BAYUTI',
      'BINUNGA',
      'BOI',
      'BOTON',
      'BULIASNIN',
      'BUNGANAY',
      'CAGANHAO',
      'CANAT',
      'CATUBUGAN',
      'CAWIT',
      'DAIG',
      'DAYPAY',
      'DUYAY',
      'HINAPULAN',
      'IHATUB',
      'ISOK I (POB.)',
      'ISOK II POB. (KALAMIAS)',
      'LAYLAY',
      'LUPAC',
      'MAHINHIN',
      'MAINIT',
      'MALBOG',
      'MALIGAYA',
      'MALUSAK (POB.)',
      'MANSIWAT',
      'MATAAS NA BAYAN (POB.)',
      'MAYBO',
      'MERCADO (POB.)',
      'MURALLON (POB.)',
      'OGBAC',
      'PAWA',
      'PILI',
      'POCTOY',
      'PORAS',
      'PUTING BUHANGIN',
      'PUYOG',
      'SABONG',
      'SAN MIGUEL (POB.)',
      'SANTOL',
      'SAWI',
      'TABI',
      'TABIGUE',
      'TAGWAK',
      'TAMBUNAN',
      'TAMPUS (POB.)',
      'TANZA',
      'TUGOS',
      'TUMAGABOK',
      'TUMAPON',
    ],
  },
  GASAN: {
    province: 'MARINDUQUE',
    municipality: 'GASAN',
    region: 'REGION IV-B',
    barangay_list: [
      'ANTIPOLO',
      'BACHAO IBABA',
      'BACHAO ILAYA',
      'BACONGBACONG',
      'BAHI',
      'BANGBANG',
      'BANOT',
      'BANUYO',
      'BARANGAY I (POB.)',
      'BARANGAY II (POB.)',
      'BARANGAY III (POB.)',
      'BOGNUYAN',
      'CABUGAO',
      'DAWIS',
      'DILI',
      'LIBTANGIN',
      'MAHUNIG',
      'MANGILIOL',
      'MASIGA',
      'MATANDANG GASAN',
      'PANGI',
      'PINGAN',
      'TABIONAN',
      'TAPUYAN',
      'TIGUION',
    ],
  },
  MOGPOG: {
    province: 'MARINDUQUE',
    municipality: 'MOGPOG',
    region: 'REGION IV-B',
    barangay_list: [
      'ANAPOG-SIBUCAO',
      'ARGAO',
      'BALANACAN',
      'BANTO',
      'BINTAKAY',
      'BOCBOC',
      'BUTANSAPA',
      'CANDAHON',
      'CAPAYANG',
      'DANAO',
      'DULONG BAYAN (POB.)',
      'GITNANG BAYAN (POB.)',
      'GUISIAN',
      'HINADHARAN',
      'HINANGGAYON',
      'INO',
      'JANAGDONG',
      'LAMESA',
      'LAON',
      'MAGAPUA',
      'MALAYAK',
      'MALUSAK',
      'MAMPAITAN',
      'MANGYAN-MABABAD',
      'MARKET SITE (POB.)',
      'MATAAS NA BAYAN',
      'MENDEZ',
      'NANGKA I',
      'NANGKA II',
      'PAYE',
      'PILI',
      'PUTING BUHANGIN',
      'SAYAO',
      'SILANGAN',
      'SUMANGGA',
      'TARUG',
      'VILLA MENDEZ (POB.)',
    ],
  },
  TORRIJOS: {
    province: 'MARINDUQUE',
    municipality: 'TORRIJOS',
    region: 'REGION IV-B',
    barangay_list: [
      'BANGWAYIN',
      'BAYAKBAKIN',
      'BOLO',
      'BONLIW',
      'BUANGAN',
      'CABUYO',
      'CAGPO',
      'DAMPULAN',
      'KAY DUKE',
      'MABUHAY',
      'MAKAWAYAN',
      'MALIBAGO',
      'MALINAO',
      'MARANLIG',
      'MARLANGGA',
      'MATUYATUYA',
      'NANGKA',
      'PAKASKASAN',
      'PAYANAS',
      'POBLACION',
      'POCTOY',
      'SIBUYAO',
      'SUHA',
      'TALAWAN',
      'TIGWI',
    ],
  },
  'ABRA DE ILOG': {
    province: 'OCCIDENTAL MINDORO',
    municipality: 'ABRA DE ILOG',
    region: 'REGION IV-B',
    barangay_list: [
      'ARMADO',
      'BALAO',
      'CABACAO',
      'LUMANGBAYAN',
      'POBLACION',
      'SAN VICENTE',
      'STA. MARIA',
      'TIBAG',
      'UDALO (CAMURONG)',
      'WAWA',
    ],
  },
  CALINTAAN: {
    province: 'OCCIDENTAL MINDORO',
    municipality: 'CALINTAAN',
    region: 'REGION IV-B',
    barangay_list: [
      'CONCEPCION',
      'IRIRON',
      'MALPALON',
      'NEW DAGUPAN',
      'POBLACION',
      'POYPOY',
      'TANYAG',
    ],
  },
  LOOC: {
    province: 'ROMBLON',
    municipality: 'LOOC',
    region: 'REGION IV-B',
    barangay_list: [
      'AGOJO',
      'BALATUCAN',
      'BUENAVISTA',
      'CAMANDAO',
      'GUINHAYAAN',
      'LIMON NORTE',
      'LIMON SUR',
      'MANHAC',
      'PILI',
      'POBLACION',
      'PUNTA',
      'TUGUIS',
    ],
  },
  LUBANG: {
    province: 'OCCIDENTAL MINDORO',
    municipality: 'LUBANG',
    region: 'REGION IV-B',
    barangay_list: [
      'ARAW AT BITUIN (POB.)',
      'BAGONG SIKAT (POB.)',
      'BANAAG AT PAG-ASA (POB.)',
      'BINAKAS',
      'CABRA',
      'LIKAS NG SILANGAN (POB.)',
      'MAGINHAWA (POB.)',
      'MALIGAYA',
      'MALIIG',
      'NINIKAT NG PAG-ASA (POB.)',
      'PARAISO (POB.)',
      'SURVILLE (POB.)',
      'TAGBAC',
      'TANGAL',
      'TILIK',
      'VIGO',
    ],
  },
  MAMBURAO: {
    province: 'OCCIDENTAL MINDORO',
    municipality: 'MAMBURAO',
    region: 'REGION IV-B',
    barangay_list: [
      'BALANSAY',
      'FATIMA (TII)',
      'PAYOMPON',
      'POBLACION 1 (BARANGAY 1)',
      'POBLACION 2 (BARANGAY 2)',
      'POBLACION 3 (BARANGAY 3)',
      'POBLACION 4 (BARANGAY 4)',
      'POBLACION 5 (BARANGAY 5)',
      'POBLACION 6 (BARANGAY 6)',
      'POBLACION 7 (BARANGAY 7)',
      'POBLACION 8 (BARANGAY 8)',
      'SAN LUIS (LIGANG)',
      'TALABAAN',
      'TANGKALAN',
      'TAYAMAAN',
    ],
  },
  PALUAN: {
    province: 'OCCIDENTAL MINDORO',
    municipality: 'PALUAN',
    region: 'REGION IV-B',
    barangay_list: [
      'ALIPAOY',
      'BAGONG SILANG POB. (BGY 5)',
      'HANDANG TUMULONG POB. (BGY 2)',
      'HARRISON',
      'LUMANGBAYAN',
      'MANANAO',
      'MAPALAD POB. (BGY 1)',
      'MARIKIT',
      'PAG-ASA NG BAYAN POB. (BGY 4)',
      'SAN JOSE POB. (BGY 6)',
      'SILAHIS NG PAG-ASA POB. (BGY 3)',
      'TUBILI',
    ],
  },
  SABLAYAN: {
    province: 'OCCIDENTAL MINDORO',
    municipality: 'SABLAYAN',
    region: 'REGION IV-B',
    barangay_list: [
      'BATONG BUHAY',
      'BUENAVISTA',
      'BURGOS',
      'CLAUDIO SALGADO',
      'GENERAL EMILIO AGUINALDO',
      'IBUD',
      'ILVITA',
      'LAGNAS',
      'LIGAYA',
      'MALISBONG',
      'PAETAN',
      'PAG-ASA',
      'POBLACION (LUMANGBAYAN)',
      'SAN AGUSTIN',
      'SAN FRANCISCO',
      'SAN NICOLAS',
      'SAN VICENTE',
      'SANTA LUCIA',
      'SANTO NIÑO',
      'TAGUMPAY',
      'TUBAN',
      'VICTORIA',
    ],
  },
  BACO: {
    province: 'ORIENTAL MINDORO',
    municipality: 'BACO',
    region: 'REGION IV-B',
    barangay_list: [
      'ALAG',
      'BANGKATAN',
      'BARAS (MANGYAN MINORITY)',
      'BAYANAN',
      'BURBULI',
      'CATWIRAN I',
      'CATWIRAN II',
      'DULANGAN I',
      'DULANGAN II',
      'LANTUYANG (MANGYAN MINORITY)',
      'LUMANG BAYAN',
      'MALAPAD',
      'MANGANGAN I',
      'MANGANGAN II',
      'MAYABIG',
      'PAMBISAN',
      'POBLACION',
      'PULANG-TUBIG',
      'PUTICAN-CABULO',
      'SAN ANDRES',
      'SAN IGNACIO (DULANGAN III)',
      'SANTA CRUZ',
      'SANTA ROSA I',
      'SANTA ROSA II',
      'TABON-TABON',
      'TAGUMPAY',
      'WATER',
    ],
  },
  BANSUD: {
    province: 'ORIENTAL MINDORO',
    municipality: 'BANSUD',
    region: 'REGION IV-B',
    barangay_list: [
      'ALCADESMA',
      'BATO',
      'CONRAZON',
      'MALO',
      'MANIHALA',
      'PAG-ASA',
      'POBLACION',
      'PROPER BANSUD',
      'PROPER TIGUISAN',
      'ROSACARA',
      'SALCEDO',
      'SUMAGUI',
      'VILLA PAG-ASA',
    ],
  },
  BONGABONG: {
    province: 'ORIENTAL MINDORO',
    municipality: 'BONGABONG',
    region: 'REGION IV-B',
    barangay_list: [
      'ANILAO',
      'APLAYA',
      'BAGUMBAYAN I',
      'BAGUMBAYAN II',
      'BATANGAN',
      'BUKAL',
      'CAMANTIGUE',
      'CARMUNDO',
      'CAWAYAN',
      'DAYHAGAN',
      'FORMON',
      'HAGAN',
      'HAGUPIT',
      'IPIL',
      'KALIGTASAN',
      'LABASAN',
      'LABONAN',
      'LIBERTAD',
      'LISAP',
      'LUNA',
      'MALITBOG',
      'MAPANG',
      'MASAGUISI',
      'MINA DE ORO',
      'MORENTE',
      'OGBOT',
      'ORCONUMA',
      'POBLACION',
      'POLUSAHI',
      'SAGANA',
      'SAN ISIDRO',
      'SAN JOSE',
      'SAN JUAN',
      'SANTA CRUZ',
      'SIGANGE',
      'TAWAS',
    ],
  },
  'BULALACAO (SAN PEDRO)': {
    province: 'ORIENTAL MINDORO',
    municipality: 'BULALACAO (SAN PEDRO)',
    region: 'REGION IV-B',
    barangay_list: [
      'BAGONG SIKAT',
      'BALATASAN',
      'BENLI (MANGYAN SETTLEMENT)',
      'CABUGAO',
      'CAMBUNANG (POB.)',
      'CAMPAASAN (POB.)',
      'MAASIN',
      'MAUJAO',
      'MILAGROSA (GUIOB)',
      'NASUKOB (POB.)',
      'POBLACION',
      'SAN FRANCISCO (ALIMAWAN)',
      'SAN ISIDRO',
      'SAN JUAN',
      'SAN ROQUE (BUYAYAO)',
    ],
  },
  'CALAPAN': {
    province: 'ORIENTAL MINDORO',
    municipality: 'CALAPAN',
    region: 'REGION IV-B',
    barangay_list: [
      'BALINGAYAN',
      'BALITE',
      'BARUYAN',
      'BATINO',
      'BAYANAN I',
      'BAYANAN II',
      'BIGA',
      'BONDOC',
      'BUCAYAO',
      'BUHUAN',
      'BULUSAN',
      'CALERO (POB.)',
      'CAMANSIHAN',
      'CAMILMIL',
      'CANUBING I',
      'CANUBING II',
      'COMUNAL',
      'GUINOBATAN',
      'GULOD',
      'GUTAD',
      'IBABA EAST (POB.)',
      'IBABA WEST (POB.)',
      'ILAYA (POB.)',
      'LALUD',
      'LAZARETO',
      'LIBIS (POB.)',
      'LUMANG BAYAN',
      'MAHAL NA PANGALAN',
      'MAIDLANG',
      'MALAD',
      'MALAMIG',
      'MANAGPI',
      'MASIPIT',
      'NAG-IBA I',
      'NAG-IBA II',
      'NAVOTAS',
      'PACHOCA',
      'PALHI',
      'PANGGALAAN',
      'PARANG',
      'PATAS',
      'PERSONAS',
      'PUTINGTUBIG',
      'SALONG',
      'SAN ANTONIO',
      'SAN VICENTE CENTRAL (POB.)',
      'SAN VICENTE EAST (POB.)',
      'SAN VICENTE NORTH (POB.)',
      'SAN VICENTE SOUTH (POB.)',
      'SAN VICENTE WEST (POB.)',
      'SANTA CRUZ',
      'SANTA ISABEL',
      'SANTA MARIA VILLAGE',
      'SANTA RITA (BUNGAHAN)',
      'SANTO NIÑO',
      'SAPUL',
      'SILONAY',
      'SUQUI',
      'TAWAGAN',
      'TAWIRAN',
      'TIBAG',
      'WAWA',
    ],
  },
  GLORIA: {
    province: 'ORIENTAL MINDORO',
    municipality: 'GLORIA',
    region: 'REGION IV-B',
    barangay_list: [
      'AGOS',
      'AGSALIN',
      'ALMA VILLA',
      'ANDRES BONIFACIO',
      'BALETE',
      'BANUS',
      'BANUTAN',
      'BULAKLAKAN',
      'BUONG LUPA',
      'GAUDENCIO ANTONINO',
      'GUIMBONAN',
      'KAWIT',
      'LUCIO LAUREL',
      'MACARIO ADRIATICO',
      'MALAMIG',
      'MALAYONG',
      'MALIGAYA (POB.)',
      'MALUBAY',
      'MANGUYANG',
      'MARAGOOC',
      'MIRAYAN',
      'NARRA',
      'PAPANDUNGIN',
      'SAN ANTONIO',
      'SANTA MARIA',
      'SANTA THERESA',
      'TAMBONG',
    ],
  },
  MANSALAY: {
    province: 'ORIENTAL MINDORO',
    municipality: 'MANSALAY',
    region: 'REGION IV-B',
    barangay_list: [
      'B. DEL MUNDO',
      'BALUGO',
      'BONBON',
      'BUDBURAN',
      'CABALWA',
      'DON PEDRO',
      'MALIWANAG',
      'MANAUL',
      'PANAYTAYAN',
      'POBLACION',
      'ROMA',
      'SANTA BRIGIDA',
      'SANTA MARIA',
      'SANTA TERESITA',
      'VILLA CELESTIAL',
      'WASIG',
      'WAYGAN',
    ],
  },
  NAUJAN: {
    province: 'ORIENTAL MINDORO',
    municipality: 'NAUJAN',
    region: 'REGION IV-B',
    barangay_list: [
      'ADRIALUNA',
      'ANDRES ILAGAN (MAG-ASAWANG TUBIG)',
      'ANTIPOLO',
      'APITONG',
      'ARANGIN',
      'AURORA',
      'BACUNGAN',
      'BAGONG BUHAY',
      'BALITE',
      'BANCURO',
      'BANUTON',
      'BARCENAGA',
      'BAYANI',
      'BUHANGIN',
      'CABURO',
      'CONCEPCION',
      'DAO',
      'DEL PILAR',
      'ESTRELLA',
      'EVANGELISTA',
      'GAMAO',
      'GENERAL ESCO',
      'HERRERA',
      'INARAWAN',
      'KALINISAN',
      'LAGUNA',
      'MABINI',
      'MAGTIBAY',
      'MAHABANG PARANG',
      'MALAYA',
      'MALINAO',
      'MALVAR',
      'MASAGANA',
      'MASAGUING',
      'MELGAR A',
      'MELGAR B',
      'METOLZA',
      'MONTELAGO',
      'MONTEMAYOR',
      'MOTODERAZO',
      'MULAWIN',
      'NAG-IBA I',
      'NAG-IBA II',
      'PAGKAKAISA',
      'PAITAN',
      'PANIQUIAN',
      'PINAGSABANGAN I',
      'PINAGSABANGAN II',
      'PIÑAHAN',
      'POBLACION I (BARANGAY I)',
      'POBLACION II (BARANGAY II)',
      'POBLACION III (BARANGAY III)',
      'SAMPAGUITA',
      'SAN AGUSTIN I',
      'SAN AGUSTIN II',
      'SAN ANDRES',
      'SAN ANTONIO',
      'SAN CARLOS',
      'SAN ISIDRO',
      'SAN JOSE',
      'SAN LUIS',
      'SAN NICOLAS',
      'SAN PEDRO',
      'SANTA CRUZ',
      'SANTA ISABEL',
      'SANTA MARIA',
      'SANTIAGO',
      'SANTO NIÑO',
      'TAGUMPAY',
      'TIGKAN',
    ],
  },
  PINAMALAYAN: {
    province: 'ORIENTAL MINDORO',
    municipality: 'PINAMALAYAN',
    region: 'REGION IV-B',
    barangay_list: [
      'ANOLING',
      'BACUNGAN',
      'BANGBANG',
      'BANILAD',
      'BULI',
      'CACAWAN',
      'CALINGAG',
      'DEL RAZON',
      'GUINHAWA',
      'INCLANAY',
      'LUMANGBAYAN',
      'MALAYA',
      'MALIANGCOG',
      'MANINGCOL',
      'MARAYOS',
      'MARFRANCISCO',
      'NABUSLOT',
      'PAGALAGALA',
      'PALAYAN',
      'PAMBISAN MALAKI',
      'PAMBISAN MUNTI',
      'PANGGULAYAN',
      'PAPANDAYAN',
      'PILI',
      'QUINABIGAN',
      'RANZO',
      'ROSARIO',
      'SABANG',
      'SANTA ISABEL',
      'SANTA MARIA',
      'SANTA RITA',
      'SANTO NIÑO',
      'WAWA',
      'ZONE I (POB.)',
      'ZONE II (POB.)',
      'ZONE III (POB.)',
      'ZONE IV (POB.)',
    ],
  },
  POLA: {
    province: 'ORIENTAL MINDORO',
    municipality: 'POLA',
    region: 'REGION IV-B',
    barangay_list: [
      'BACAWAN',
      'BACUNGAN',
      'BATUHAN',
      'BAYANAN',
      'BIGA',
      'BUHAY NA TUBIG',
      'CALIMA',
      'CALUBASANHON',
      'CAMPAMENTO',
      'CASILIGAN',
      'MALIBAGO',
      'MALUANLUAN',
      'MATULATULA',
      'MISONG',
      'PAHILAHAN',
      'PANIKIHAN',
      'PULA',
      'PUTING CACAO',
      'TAGBAKIN',
      'TAGUMPAY',
      'TIGUIHAN',
      'ZONE I (POB.)',
      'ZONE II (POB.)',
    ],
  },
  'PUERTO GALERA': {
    province: 'ORIENTAL MINDORO',
    municipality: 'PUERTO GALERA',
    region: 'REGION IV-B',
    barangay_list: [
      'ANINUAN',
      'BACLAYAN',
      'BALATERO',
      'DULANGAN',
      'PALANGAN',
      'POBLACION',
      'SABANG',
      'SAN ANTONIO',
      'SAN ISIDRO',
      'SANTO NIÑO',
      'SINANDIGAN',
      'TABINAY',
      'VILLAFLOR',
    ],
  },
  'SAN TEODORO': {
    province: 'ORIENTAL MINDORO',
    municipality: 'SAN TEODORO',
    region: 'REGION IV-B',
    barangay_list: [
      'BIGAAN',
      'CAAGUTAYAN',
      'CALANGATAN',
      'CALSAPA',
      'ILAG',
      'LUMANGBAYAN',
      'POBLACION',
      'TACLIGAN',
    ],
  },
  ABORLAN: {
    province: 'PALAWAN',
    municipality: 'ABORLAN',
    region: 'REGION IV-B',
    barangay_list: [
      'APO-APORAWAN',
      'APOC-APOC',
      'APORAWAN',
      'BARAKE',
      'CABIGAAN',
      'CULANDANUM',
      'GOGOGNAN',
      'IRAAN',
      'ISAUB',
      'JOSE RIZAL',
      'MABINI',
      'MAGBABADIL',
      'PLARIDEL',
      'POBLACION',
      'RAMON MAGSAYSAY',
      'SAGPANGAN',
      'SAN JUAN',
      'TAGPAIT',
      'TIGMAN',
    ],
  },
  AGUTAYA: {
    province: 'PALAWAN',
    municipality: 'AGUTAYA',
    region: 'REGION IV-B',
    barangay_list: [
      'ABAGAT (POB.)',
      'ALGECIRAS',
      'BANGCAL (POB.)',
      'CAMBIAN (POB.)',
      'CONCEPCION',
      'DIIT',
      'MARACANAO',
      'MATARAWIS',
      'VILLAFRIA',
      'VILLASOL',
    ],
  },
  ARACELI: {
    province: 'PALAWAN',
    municipality: 'ARACELI',
    region: 'REGION IV-B',
    barangay_list: [
      'BALOGO',
      'DAGMAN',
      'DALAYAWON',
      'LUMACAD',
      'MADOLDOLON',
      'MAURINGUEN',
      'OSMEÑA',
      'POBLACION (CENTRO)',
      'SAN JOSE DE ORO',
      'SANTO NIÑO',
      'TALOTO',
      'TININTINAN',
      'TUDELA (CALANDAGAN)',
    ],
  },
  BALABAC: {
    province: 'PALAWAN',
    municipality: 'BALABAC',
    region: 'REGION IV-B',
    barangay_list: [
      'AGUTAYAN',
      'BANCALAAN',
      'BUGSUK (NEW CAGAYANCILLO)',
      'CATAGUPAN',
      'INDALAWAN',
      'MALAKING ILOG',
      'MANGSEE',
      'MELVILLE',
      'PANDANAN',
      'PASIG',
      'POBLACION I',
      'POBLACION II',
      'POBLACION III',
      'POBLACION IV',
      'POBLACION V',
      'POBLACION VI',
      'RABOR',
      'RAMOS',
      'SALANG',
      'SEBARING',
    ],
  },
  BATARAZA: {
    province: 'PALAWAN',
    municipality: 'BATARAZA',
    region: 'REGION IV-B',
    barangay_list: [
      'BONO-BONO',
      'BULALACAO',
      'BULILUYAN',
      'CULANDANUM',
      'IGANG-IGANG',
      'INOGBONG',
      'IWAHIG',
      'MALIHUD',
      'MALITUB',
      'MARANGAS (POB.)',
      'OCAYAN',
      'PURING',
      'RIO TUBA',
      'SANDOVAL',
      'SAPA',
      'SARONG',
      'SUMBILING',
      'TABUD',
      'TAGNATO',
      'TAGOLANGO',
      'TARATAK',
      'TARUSAN',
    ],
  },
  "BROOKE'S POINT": {
    province: 'PALAWAN',
    municipality: "BROOKE'S POINT",
    region: 'REGION IV-B',
    barangay_list: [
      'AMAS',
      'ARIBUNGOS',
      'BARONG-BARONG',
      'CALASAGUEN',
      'IMULNOD',
      'IPILAN',
      'MAASIN',
      'MAINIT',
      'MALIS',
      'MAMBALOT',
      'ORING-ORING',
      'PANGOBILIAN',
      'POBLACION I',
      'POBLACION II',
      'SALOGON',
      'SAMAREÑANA',
      'SARAZA',
      'TUBTUB',
    ],
  },
  BUSUANGA: {
    province: 'PALAWAN',
    municipality: 'BUSUANGA',
    region: 'REGION IV-B',
    barangay_list: [
      'BOGTONG',
      'BULUANG',
      'CHEEY',
      'CONCEPCION',
      'MAGLALAMBAY',
      'NEW BUSUANGA (POB.)',
      'OLD BUSUANGA',
      'PANLAITAN',
      'QUEZON',
      'SAGRADA',
      'SALVACION',
      'SAN ISIDRO',
      'SAN RAFAEL',
      'SANTO NIÑO',
    ],
  },
  CAGAYANCILLO: {
    province: 'PALAWAN',
    municipality: 'CAGAYANCILLO',
    region: 'REGION IV-B',
    barangay_list: [
      'BANTAYAN (POB.)',
      'CALSADA (POB.)',
      'CONVENTO (POB.)',
      'LIPOT NORTH (POB.)',
      'LIPOT SOUTH (POB.)',
      'MAGSAYSAY',
      'MAMPIO',
      'NUSA',
      'SANTA CRUZ',
      'TACAS (POB.)',
      'TALAGA',
      'WAHIG (POB.)',
    ],
  },
  CORON: {
    province: 'PALAWAN',
    municipality: 'CORON',
    region: 'REGION IV-B',
    barangay_list: [
      'BANUANG DAAN',
      'BARANGAY I (POB.)',
      'BARANGAY II (POB.)',
      'BARANGAY III (POB.)',
      'BARANGAY IV (POB.)',
      'BARANGAY V (POB.)',
      'BARANGAY VI (POB.)',
      'BINTUAN',
      'BORAC',
      'BUENAVISTA',
      'BULALACAO',
      'CABUGAO',
      'DECABOBO',
      'DECALACHAO',
      'GUADALUPE',
      'LAJALA',
      'MALAWIG',
      'MARCILLA',
      'SAN JOSE',
      'SAN NICOLAS',
      'TAGUMPAY',
      'TARA',
      'TURDA',
    ],
  },
  CULION: {
    province: 'PALAWAN',
    municipality: 'CULION',
    region: 'REGION IV-B',
    barangay_list: [
      'BALALA',
      'BALDAT',
      'BINUDAC',
      'BURABOD',
      'CULANGO',
      'DE CARABAO',
      'GALOC',
      'HALSEY',
      'JARDIN',
      'LIBIS',
      'LUAC',
      'MALAKING PATAG',
      'OSMEÑA',
      'TIZA',
    ],
  },
  CUYO: {
    province: 'PALAWAN',
    municipality: 'CUYO',
    region: 'REGION IV-B',
    barangay_list: [
      'BALADING',
      'BANGCAL (POB.)',
      'CABIGSING (POB.)',
      'CABURIAN',
      'CAPONAYAN',
      'CATADMAN (POB.)',
      'FUNDA',
      'LAGAORIAO (POB.)',
      'LUBID',
      'LUNGSOD (POB.)',
      'MANAMOC',
      'MARINGIAN',
      'PAWA',
      'SAN CARLOS',
      'SUBA',
      'TENGA-TENGA (POB.)',
      'TOCADAN (POB.)',
    ],
  },
  DUMARAN: {
    province: 'PALAWAN',
    municipality: 'DUMARAN',
    region: 'REGION IV-B',
    barangay_list: [
      'BACAO',
      'BOHOL',
      'CALASAG',
      'CAPAYAS',
      'CATEP',
      'CULASIAN',
      'DANLEG',
      'DUMARAN (POB.)',
      'ILIAN',
      'ITANGIL',
      'MAGSAYSAY',
      'SAN JUAN',
      'SANTA MARIA',
      'SANTA TERESITA',
      'SANTO TOMAS',
      'TANATANAON',
    ],
  },
  'EL NIDO (BACUIT)': {
    province: 'PALAWAN',
    municipality: 'EL NIDO (BACUIT)',
    region: 'REGION IV-B',
    barangay_list: [
      'ABERAWAN',
      'BAGONG BAYAN',
      'BAROTUAN',
      'BEBELADAN',
      'BUCANA',
      'BUENA SUERTE POB. (BARANGAY 2)',
      'CORONG-CORONG POB. (BARANGAY 4)',
      'MABINI',
      'MALIGAYA POB. (BARANGAY 1)',
      'MANLAG',
      'MASAGANA POB. (BARANGAY 3)',
      'NEW IBAJAY',
      'PASADEÑA',
      'SAN FERNANDO',
      'SIBALTAN',
      'TENEGUIBAN',
      'VILLA LIBERTAD',
      'VILLA PAZ',
    ],
  },
  LINAPACAN: {
    province: 'PALAWAN',
    municipality: 'LINAPACAN',
    region: 'REGION IV-B',
    barangay_list: [
      'BARANGONAN (ILOC)',
      'CABUNLAWAN',
      'CALIBANGBANGAN',
      'DECABAITOT',
      'MAROYOGROYOG',
      'NANGALAO',
      'NEW CULAYLAYAN',
      'PICAL',
      'SAN MIGUEL (POB.)',
      'SAN NICOLAS',
    ],
  },
  NARRA: {
    province: 'PALAWAN',
    municipality: 'NARRA',
    region: 'REGION IV-B',
    barangay_list: [
      'ANTIPULUAN',
      'ARAMAYWAN',
      'BAGONG SIKAT',
      'BATANG-BATANG',
      'BATO-BATO',
      'BURIRAO',
      'CAGUISAN',
      'CALATEGAS',
      'DUMAGUEÑA',
      'ELVITA',
      'ESTRELLA VILLAGE',
      'IPILAN',
      'MALATGAO',
      'MALINAO',
      'NARRA (POB.)',
      'PANACAN',
      'PANACAN 2',
      'PRINCESS URDUJA',
      'SANDOVAL',
      'TACRAS',
      'TARITIEN',
      'TERESA',
      'TINAGONG DAGAT',
    ],
  },
  'PUERTO PRINCESA': {
    province: 'PALAWAN',
    municipality: 'PUERTO PRINCESA',
    region: 'REGION IV-B',
    barangay_list: [
      'BABUYAN',
      'BACUNGAN',
      'BAGONG BAYAN',
      'BAGONG PAG-ASA (POB.)',
      'BAGONG SIKAT (POB.)',
      'BAGONG SILANG (POB.)',
      'BAHILE',
      'BANCAO-BANCAO',
      'BARANGAY NG MGA MANGINGISDA',
      'BINDUYAN',
      'BUENAVISTA',
      'CABAYUGAN',
      'CONCEPCION',
      'INAGAWAN',
      'INAGAWAN SUB-COLONY',
      'IRAWAN',
      'IWAHIG (POB.)',
      'KALIPAY (POB.)',
      'KAMUNING',
      'LANGOGAN',
      'LIWANAG (POB.)',
      'LUCBUAN',
      'LUZVIMINDA',
      'MABUHAY (POB.)',
      'MACARASCAS',
      'MAGKAKAIBIGAN (POB.)',
      'MALIGAYA (POB.)',
      'MANALO',
      'MANDARAGAT',
      'MANGGAHAN (POB.)',
      'MANINGNING (POB.)',
      'MAOYON',
      'MARUFINAS',
      'MARUYOGON',
      'MASIGLA (POB.)',
      'MASIKAP (POB.)',
      'MASIPAG (POB.)',
      'MATAHIMIK (POB.)',
      'MATIYAGA (POB.)',
      'MAUNLAD (POB.)',
      'MILAGROSA (POB.)',
      'MODEL (POB.)',
      'MONTIBLE (POB.)',
      'NAPSAN',
      'NEW PANGGANGAN',
      'PAGKAKAISA (POB.)',
      'PRINCESA (POB.)',
      'SALVACION',
      'SAN JOSE',
      'SAN MANUEL',
      'SAN MIGUEL',
      'SAN PEDRO',
      'SAN RAFAEL',
      'SANTA CRUZ',
      'SANTA LOURDES',
      'SANTA LUCIA (POB.)',
      'SANTA MONICA',
      'SEASIDE (POB.)',
      'SICSICAN',
      'SIMPOCAN',
      'TAGABINIT',
      'TAGBUROS',
      'TAGUMPAY (POB.)',
      'TANABAG',
      'TANGLAW (POB.)',
      'TINIGUIBAN',
    ],
  },
  'RIZAL (MARCOS)': {
    province: 'PALAWAN',
    municipality: 'RIZAL (MARCOS)',
    region: 'REGION IV-B',
    barangay_list: [
      'BUNOG',
      'CAMPONG ULAY',
      'CANDAWAGA',
      'CANIPAAN',
      'CULASIAN',
      'IRAAN',
      'LATUD',
      'PANALINGAAN',
      'PUNTA BAJA',
      'RANSANG',
      'TABURI',
    ],
  },
  'SOFRONIO ESPAÑOLA': {
    province: 'PALAWAN',
    municipality: 'SOFRONIO ESPAÑOLA',
    region: 'REGION IV-B',
    barangay_list: [
      'ABO-ABO',
      'IRARAY',
      'ISUMBO',
      'LABOG',
      'PANITIAN',
      'PULOT CENTER',
      'PULOT INTERIOR (PULOT II)',
      'PULOT SHORE (PULOT I)',
      'PUNANG',
    ],
  },
  ALCANTARA: {
    province: 'CEBU',
    municipality: 'ALCANTARA',
    region: 'REGION VII',
    barangay_list: [
      'CABADIANGAN',
      'CABIL-ISAN',
      'CANDABONG',
      'LAWAAN',
      'MANGA',
      'PALANAS',
      'POBLACION',
      'POLO',
      'SALAGMAYA',
    ],
  },
  BANTON: {
    province: 'ROMBLON',
    municipality: 'BANTON',
    region: 'REGION IV-B',
    barangay_list: [
      'BALOGO',
      'BANICE',
      'HAMBI-AN',
      'LAGANG',
      'LIBTONG',
      'MAINIT',
      'NABALAY',
      'NASUNOGAN',
      'POBLACION',
      'SIBAY',
      'TAN-AG',
      'TOCTOC',
      'TOGBONGAN',
      'TOGONG',
      'TUMALUM',
      'TUNGONAN',
      'YABAWON',
    ],
  },
  CAJIDIOCAN: {
    province: 'ROMBLON',
    municipality: 'CAJIDIOCAN',
    region: 'REGION IV-B',
    barangay_list: [
      'ALIBAGON',
      'CAMBAJAO',
      'CAMBALO',
      'CAMBIJANG',
      'CANTAGDA',
      'DANAO',
      'GUTIVAN',
      'LICO',
      'LUMBANG ESTE',
      'LUMBANG WESTE',
      'MARIGONDON',
      'POBLACION',
      'SUGOD',
      'TAGUILOS',
    ],
  },
  CALATRAVA: {
    province: 'NEGROS OCCIDENTAL',
    municipality: 'CALATRAVA',
    region: 'REGION VI',
    barangay_list: [
      'AGPANGI',
      'ANI-E',
      'BAGACAY',
      'BANTAYANON',
      'BUENAVISTA',
      'CABUNGAHAN',
      'CALAMPISAWAN',
      'CAMBAYOBO',
      'CASTELLANO',
      'CRUZ',
      'DOLIS',
      'HILUB-ANG',
      'HINAB-ONGAN',
      'ILAYA',
      'LAGA-AN',
      'LALONG',
      'LEMERY',
      'LIPAT-ON',
      'LO-OK (POB.)',
      'MA-ASLOB',
      'MACASILAO',
      'MAHILUM',
      'MALANOG',
      'MALATAS',
      'MARCELO',
      'MENCHACA',
      'MINA-UTOK',
      'MINAPASUK',
      'PAGHUMAYAN',
      'PANTAO',
      'PATUN-AN',
      'PINOCUTAN',
      'REFUGIO',
      'SAN BENITO',
      'SAN ISIDRO',
      'SUBA (POB.)',
      'TELIM',
      'TIGBAO',
      'TIGBON',
      'WINASWASAN',
    ],
  },
  CORCUERA: {
    province: 'ROMBLON',
    municipality: 'CORCUERA',
    region: 'REGION IV-B',
    barangay_list: [
      'ALEGRIA',
      'AMBULONG',
      'COLONGCOLONG',
      'GOBON',
      'GUINTIGUIBAN',
      'ILIJAN',
      'LABNIG',
      'MABINI',
      'MAHABA',
      'MANGANSAG',
      'POBLACION',
      'SAN AGUSTIN',
      'SAN ROQUE',
      'SAN VICENTE',
      'TACASAN',
    ],
  },
  FERROL: {
    province: 'ROMBLON',
    municipality: 'FERROL',
    region: 'REGION IV-B',
    barangay_list: [
      'AGNONOC',
      'BUNSORAN',
      'CLARO M. RECTO',
      'HINAGUMAN',
      'POBLACION (FERROL)',
      'TUBIGON',
    ],
  },
  MAGDIWANG: {
    province: 'ROMBLON',
    municipality: 'MAGDIWANG',
    region: 'REGION IV-B',
    barangay_list: [
      'AGSAO',
      'AGUTAY',
      'AMBULONG',
      'DULANGAN',
      'IPIL',
      'JAO-ASAN',
      'POBLACION',
      'SILUM',
      'TAMPAYAN',
    ],
  },
  ODIONGAN: {
    province: 'ROMBLON',
    municipality: 'ODIONGAN',
    region: 'REGION IV-B',
    barangay_list: [
      'AMATONG',
      'ANAHAO',
      'BANGON',
      'BATIANO',
      'BUDIONG',
      'CANDUYONG',
      'DAPAWAN',
      'GABAWAN',
      'LIBERTAD',
      'LIGAYA (POB.)',
      'LIWANAG (POB.)',
      'LIWAYWAY (POB.)',
      'MALILICO',
      'MAYHA',
      'PANIQUE',
      'PATO-O',
      'POCTOY',
      'PROGRESO ESTE',
      'PROGRESO WESTE',
      'RIZAL',
      'TABING DAGAT (POB.)',
      'TABOBO-AN',
      'TUBURAN',
      'TULAY',
      'TUMINGAD',
    ],
  },
  ROMBLON: {
    province: 'ROMBLON',
    municipality: 'ROMBLON',
    region: 'REGION IV-B',
    barangay_list: [
      'AGBALUTO',
      'AGBUDIA',
      'AGNAGA',
      'AGNAY',
      'AGNIPA',
      'AGPANABAT',
      'AGTONGO',
      'ALAD',
      'BAGACAY',
      'BARANGAY I (POB.)',
      'BARANGAY II (POB.)',
      'BARANGAY III (POB.)',
      'BARANGAY IV (POB.)',
      'CAJIMOS',
      'CALABOGO',
      'CAPACLAN',
      'COBRADOR (NAGUSO)',
      'GINABLAN',
      'GUIMPINGAN',
      'ILAURAN',
      'LAMAO',
      'LI-O',
      'LOGBON',
      'LONOS',
      'LUNAS',
      'MACALAS',
      'MAPULA',
      'PALJE',
      'SABLAYAN',
      'SAWANG',
      'TAMBAC',
    ],
  },
  'SANTA MARIA (IMELDA)': {
    province: 'ROMBLON',
    municipality: 'SANTA MARIA (IMELDA)',
    region: 'REGION IV-B',
    barangay_list: [
      'BONGA',
      'CONCEPCION NORTE (POB.)',
      'CONCEPCION SUR',
      'PAROYHOG',
      'SAN ISIDRO',
      'SANTO NIÑO',
    ],
  },
  BACACAY: {
    province: 'ALBAY',
    municipality: 'BACACAY',
    region: 'REGION V',
    barangay_list: [
      'BACLAYON',
      'BANAO',
      'BARANGAY 1 (POB.)',
      'BARANGAY 2 (POB.)',
      'BARANGAY 3 (POB.)',
      'BARANGAY 4 (POB.)',
      'BARANGAY 5 (POB.)',
      'BARANGAY 6 (POB.)',
      'BARANGAY 7 (POB.)',
      'BARANGAY 8 (POB.)',
      'BARANGAY 9 (POB.)',
      'BARANGAY 10 (POB.)',
      'BARANGAY 11 (POB.)',
      'BARANGAY 12 (POB.)',
      'BARANGAY 13 (POB.)',
      'BARANGAY 14 (POB.)',
      'BARIW',
      'BASUD',
      'BAYANDONG',
      'BONGA (UPPER)',
      'BUANG',
      'BUSDAC (SAN JOSE)',
      'CABASAN',
      'CAGBULACAO',
      'CAGRARAY',
      'CAJOGUTAN',
      'CAWAYAN',
      'DAMACAN',
      'GUBAT ILAWOD',
      'GUBAT IRAYA',
      'HINDI',
      'IGANG',
      'LANGATON',
      'MANAET',
      'MAPULANG DAGA',
      'MATAAS',
      'MISIBIS',
      'NAHAPUNAN',
      'NAMANDAY',
      'NAMANTAO',
      'NAPAO',
      'PANARAYON',
      'PIGCOBOHAN',
      'PILI ILAWOD',
      'PILI IRAYA',
      'PONGCO (LOWER BONGA)',
      'SAN PABLO',
      'SAN PEDRO',
      'SOGOD',
      'SULA',
      'TAMBILAGAO (TAMBOGNON)',
      'TAMBONGON (TAMBILAGAO)',
      'TANAGAN',
      'USON',
      'VINISITAHAN-BASUD (MAINLAND)',
      'VINISITAHAN-NAPAO (ISLAND)',
    ],
  },
  CAMALIG: {
    province: 'ALBAY',
    municipality: 'CAMALIG',
    region: 'REGION V',
    barangay_list: [
      'ANOLING',
      'BALIGANG',
      'BANTONAN',
      'BARANGAY 1 (POB.)',
      'BARANGAY 2 (POB.)',
      'BARANGAY 3 (POB.)',
      'BARANGAY 4 (POB.)',
      'BARANGAY 5 (POB.)',
      'BARANGAY 6 (POB.)',
      'BARANGAY 7 (POB.)',
      'BARIW',
      'BINANDERAHAN',
      'BINITAYAN',
      'BONGABONG',
      'CABAGÑAN',
      'CABRARAN PEQUEÑO',
      'CAGUIBA',
      'CALABIDONGAN',
      'COMUN',
      'COTMON',
      'DEL ROSARIO',
      'GAPO',
      'GOTOB',
      'ILAWOD',
      'ILULUAN',
      'LIBOD',
      'LIGBAN',
      'MABUNGA',
      'MAGOGON',
      'MANAWAN',
      'MANINILA',
      'MINA',
      'MITI',
      'PALANOG',
      'PANOYPOY',
      'PARIAAN',
      'QUINARTILAN',
      'QUIRANGAY',
      'QUITINDAY',
      'SALUGAN',
      'SOLONG',
      'SUA',
      'SUMLANG',
      'TAGAYTAY',
      'TAGOYTOY',
      'TALADONG',
      'TALOTO',
      'TAPLACON',
      'TINAGO',
      'TUMPA',
    ],
  },
  'LIGAO': {
    province: 'ALBAY',
    municipality: 'LIGAO',
    region: 'REGION V',
    barangay_list: [
      'ABELLA',
      'ALLANG',
      'AMTIC',
      'BACONG',
      'BAGUMBAYAN',
      'BALANAC',
      'BALIGANG',
      'BARAYONG',
      'BASAG',
      'BATANG',
      'BAY',
      'BINANOWAN',
      'BINATAGAN (POB.)',
      'BOBONSURAN',
      'BONGA',
      'BUSAC',
      'BUSAY',
      'CABARIAN',
      'CALZADA (POB.)',
      'CATBURAWAN',
      'CAVASI',
      'CULLIAT',
      'DUNAO',
      'FRANCIA',
      'GUILID',
      'HERRERA',
      'LAYON',
      'MACALIDONG',
      'MAHABA',
      'MALAMA',
      'MAONON',
      'NABONTON',
      'NASISI',
      'OMA-OMA',
      'PALAPAS',
      'PANDAN',
      'PAULBA',
      'PAULOG',
      'PINAMANIQUIAN',
      'PINIT',
      'RANAO-RANAO',
      'SAN VICENTE',
      'SANTA CRUZ (POB.)',
      'TAGPO',
      'TAMBO',
      'TANDARURA',
      'TASTAS',
      'TINAGO',
      'TINAMPO',
      'TIONGSON',
      'TOMOLIN',
      'TUBURAN',
      'TULA-TULA GRANDE',
      'TULA-TULA PEQUEÑO',
      'TUPAS',
    ],
  },
  'DARAGA (LOCSIN)': {
    province: 'ALBAY',
    municipality: 'DARAGA (LOCSIN)',
    region: 'REGION V',
    barangay_list: [
      'ALCALA',
      'ALOBO',
      'ANISLAG',
      'BAGUMBAYAN',
      'BALINAD',
      'BAÑADERO',
      'BAÑAG',
      'BASCARAN',
      'BIGAO',
      'BINITAYAN',
      'BONGALON',
      'BUDIAO',
      'BURGOS',
      'BUSAY',
      'CANAROM',
      'CULLAT',
      'DELA PAZ',
      'DINORONAN',
      'GABAWAN',
      'GAPO',
      'IBAUGAN',
      'ILAWOD AREA POB. (DIST. 2)',
      'INARADO',
      'KIDACO',
      'KILICAO',
      'KIMANTONG',
      'KINAWITAN',
      'KIWALO',
      'LACAG',
      'MABINI',
      'MALABOG',
      'MALOBAGO',
      'MAOPI',
      'MARKET AREA POB. (DIST. 1)',
      'MAROROY',
      'MATNOG',
      'MAYON',
      'MI-ISI',
      'NABASAN',
      'NAMANTAO',
      'PANDAN',
      'PEÑAFRANCIA',
      'SAGPON',
      'SALVACION',
      'SAN RAFAEL',
      'SAN RAMON',
      'SAN ROQUE',
      'SAN VICENTE GRANDE',
      'SAN VICENTE PEQUEÑO',
      'SIPI',
      'TABON-TABON',
      'TAGAS',
      'TALAHIB',
      'VILLAHERMOSA',
    ],
  },
  GUINOBATAN: {
    province: 'ALBAY',
    municipality: 'GUINOBATAN',
    region: 'REGION V',
    barangay_list: [
      'AGPAY',
      'BALITE',
      'BANAO',
      'BATBAT',
      'BINOGSACAN LOWER',
      'BINOGSACAN UPPER',
      'BOLOLO',
      'BUBULUSAN',
      'CALZADA',
      'CATOMAG',
      'DOÑA MERCEDES',
      'DOÑA TOMASA (MAGATOL)',
      'ILAWOD',
      'INAMNAN GRANDE',
      'INAMNAN PEQUEÑO',
      'INASCAN',
      'IRAYA',
      'LOMACAO',
      'MAGUIRON',
      'MAIPON',
      'MALABNIG',
      'MALIPO',
      'MALOBAGO',
      'MANINILA',
      'MAPACO',
      'MARCIAL O. RAÑOLA (CABALOAON)',
      'MASARAWAG',
      'MAURARO',
      'MINTO',
      'MORERA',
      'MULADBUCAD GRANDE',
      'MULADBUCAD PEQUEÑO',
      'ONGO',
      'PALANAS',
      'POBLACION',
      'POOD',
      'QUIBONGBONGAN',
      'QUITAGO',
      'SAN FRANCISCO',
      'SAN JOSE (OGSONG)',
      'SAN RAFAEL',
      'SINUNGTAN',
      'TANDARORA',
      'TRAVESIA',
    ],
  },
  JOVELLAR: {
    province: 'ALBAY',
    municipality: 'JOVELLAR',
    region: 'REGION V',
    barangay_list: [
      'AURORA POB. (BGY. 6)',
      'BAGACAY',
      'BAUTISTA',
      'CABRARAN',
      'CALZADA POB. (BGY. 7)',
      'DEL ROSARIO',
      'ESTRELLA',
      'FLORISTA',
      'MABINI POB. (BGY. 2)',
      'MAGSAYSAY POB (BGY. 4)',
      'MAMLAD',
      'MAOGOG',
      'MERCADO POB. (BGY. 5)',
      'PLAZA POB. (BGY. 3)',
      'QUITINDAY POB. (BGY. 8)',
      'RIZAL POB. (BGY. 1)',
      'SALVACION',
      'SAN ISIDRO',
      'SAN ROQUE',
      'SAN VICENTE',
      'SINAGARAN',
      'VILLA PAZ',
      'WHITE DEER POB. (BGY. 9)',
    ],
  },
  'LEGAZPI': {
    province: 'ALBAY',
    municipality: 'LEGAZPI',
    region: 'REGION V',
    barangay_list: [
      "BGY. 1 - EM'S BARRIO (POB.)",
      "BGY. 2 - EM'S BARRIO SOUTH (POB.)",
      "BGY. 3 - EM'S BARRIO EAST (POB.)",
      'BGY. 4 - SAGPON POB. (SAGPON 1)',
      'BGY. 5 - SAGMIN POB. (SAGPON 2)',
      'BGY. 6 - BAÑADERO POB. (SAGPON 3)',
      'BGY. 7 - BAÑO (POB.)',
      'BGY. 8 - BAGUMBAYAN (POB.)',
      'BGY. 9 - PINARIC (POB.)',
      'BGY. 10 - CABUGAO',
      'BGY. 11 - MAOYOD POB. (BGY. 10 AND 11)',
      'BGY. 12 - TULA-TULA (POB.)',
      'BGY. 13 - ILAWOD WEST POB. (ILAWOD 1)',
      'BGY. 14 - ILAWOD POB. (ILAWOD 2)',
      'BGY. 15 - ILAWOD EAST POB. (ILAWOD 3)',
      'BGY. 16 - KAWIT-EAST WASHINGTON DRIVE (POB.)',
      'BGY. 17 - RIZAL SREET., ILAWOD (POB.)',
      'BGY. 18 - CABAGÑAN WEST (POB.)',
      'BGY. 19 - CABAGÑAN',
      'BGY. 20 - CABAGÑAN EAST (POB.)',
      'BGY. 21 - BINANUAHAN WEST (POB.)',
      'BGY. 22 - BINANUAHAN EAST (POB.)',
      'BGY. 23 - IMPERIAL COURT SUBD. (POB.)',
      'BGY. 24 - RIZAL STREET',
      'BGY. 25 - LAPU-LAPU (POB.)',
      'BGY. 26 - DINAGAAN (POB.)',
      'BGY. 27 - VICTORY VILLAGE SOUTH (POB.)',
      'BGY. 28 - VICTORY VILLAGE NORTH (POB.)',
      'BGY. 29 - SABANG (POB.)',
      'BGY. 30 - PIGCALE (POB.)',
      'BGY. 31 - CENTRO-BAYBAY (POB.)',
      'BGY. 32 - SAN ROQUE (BGY. 66)',
      'BGY. 33 - PNR-PEÑARANDA ST.-IRAYA (POB.)',
      'BGY. 34 - ORO SITE-MAGALLANES ST. (POB.)',
      'BGY. 35 - TINAGO (POB.)',
      'BGY. 36 - KAPANTAWAN (POB.)',
      'BGY. 37 - BITANO (POB.)',
      'BGY. 38 - GOGON (BGY. 54)',
      'BGY. 39 - BONOT (POB.)',
      'BGY. 40 - CRUZADA (BGY. 52)',
      'BGY. 41 - BOGTONG (BGY. 45)',
      'BGY. 42 - RAWIS (BGY. 65)',
      'BGY. 43 - TAMAOYAN (BGY. 67)',
      'BGY. 44 - PAWA (BGY. 61)',
      'BGY. 45 - DITA (BGY. 51)',
      'BGY. 46 - SAN JOAQUIN (BGY. 64)',
      'BGY. 47 - ARIMBAY',
      'BGY. 48 - BAGONG ABRE (BGY. 42)',
      'BGY. 49 - BIGAA (BGY. 44)',
      'BGY. 50 - PADANG (BGY. 60)',
      'BGY. 51 - BUYUAN (BGY. 49)',
      'BGY. 52 - MATANAG',
      'BGY. 53 - BONGA (BGY. 48)',
      'BGY. 54 - MABINIT (BGY. 59)',
      'BGY. 55 - ESTANZA (BGY. 53)',
      'BGY. 56 - TAYSAN (BGY. 68)',
      'BGY. 57 - DAP-DAP (BGY. 69)',
      'BGY. 58 - BURAGWIS',
      'BGY. 59 - PURO (BGY. 63)',
      'BGY. 60 - LAMBA',
      'BGY. 61 - MASLOG (BGY. 58)',
      'BGY. 62 - HOMAPON (BGY. 55)',
      'BGY. 63 - MARIAWA (BGY. 56)',
      'BGY. 64 - BAGACAY (BGY. 41 BAGACAY)',
      'BGY. 65 - IMALNOD (BGY. 57)',
      'BGY. 66 - BANQUEROHAN (BGY. 43)',
      'BGY. 67 - BARIIS (BGY. 46)',
      'BGY. 68 - SAN FRANCISCO (BGY. 62)',
      'BGY. 69 - BUENAVISTA (BGY.47)',
      'BGY. 70 - CAGBACONG (BGY. 50)',
    ],
  },
  LIBON: {
    province: 'ALBAY',
    municipality: 'LIBON',
    region: 'REGION V',
    barangay_list: [
      'ALONGONG',
      'APUD',
      'BACOLOD',
      'BARIW',
      'BONBON',
      'BUGA',
      'BULUSAN',
      'BURABOD',
      'CAGUSCOS',
      'EAST CARISAC',
      'HARIGUE',
      'LIBTONG',
      'LINAO',
      'MABAYAWAS',
      'MACABUGOS',
      'MAGALLANG',
      'MALABIGA',
      'MARAYAG',
      'MATARA',
      'MOLOSBOLOS',
      'NATASAN',
      'NIÑO JESUS (SANTO NIÑO JESUS)',
      'NOGPO',
      'PANTAO',
      'RAWIS',
      'SAGRADA FAMILIA',
      'SALVACION',
      'SAMPONGAN',
      'SAN AGUSTIN',
      'SAN ANTONIO',
      'SAN ISIDRO',
      'SAN JOSE',
      'SAN PASCUAL',
      'SAN RAMON',
      'SAN VICENTE',
      'SANTA CRUZ',
      'TALIN-TALIN',
      'TAMBO',
      'VILLA PETRONA',
      'WEST CARISAC',
      'ZONE I (POB.)',
      'ZONE II (POB.)',
      'ZONE III (POB.)',
      'ZONE IV (POB.)',
      'ZONE V (POB.)',
      'ZONE VI (POB.)',
      'ZONE VII (POB.)',
    ],
  },
  MALILIPOT: {
    province: 'ALBAY',
    municipality: 'MALILIPOT',
    region: 'REGION V',
    barangay_list: [
      'BARANGAY I (POB.)',
      'BARANGAY II (POB.)',
      'BARANGAY III (POB.)',
      'BARANGAY IV (POB.)',
      'BARANGAY V (POB.)',
      'BINITAYAN',
      'CALBAYOG',
      'CANAWAY',
      'SALVACION',
      'SAN ANTONIO SANTICON (POB.)',
      'SAN ANTONIO SULONG',
      'SAN FRANCISCO',
      'SAN ISIDRO ILAWOD',
      'SAN ISIDRO IRAYA',
      'SAN JOSE',
      'SAN ROQUE',
      'SANTA CRUZ',
      'SANTA TERESA',
    ],
  },
  MALINAO: {
    province: 'AKLAN',
    municipality: 'MALINAO',
    region: 'REGION VI',
    barangay_list: [
      'BANAYBANAY',
      'BIGA-A',
      'BULABUD',
      'CABAYUGAN',
      'CAPATAGA',
      'COGON',
      'DANGCALAN',
      'KINALANGAY NUEVO',
      'KINALANGAY VIEJO',
      'LILO-AN',
      'MALANDAYON',
      'MANHANIP',
      'NAVITAS',
      'OSMAN',
      'POBLACION',
      'ROSARIO',
      'SAN DIMAS',
      'SAN RAMON',
      'SAN ROQUE',
      'SIPAC',
      'SUGNOD',
      'TAMBUAN',
      'TIGPALAS',
    ],
  },
  MANITO: {
    province: 'ALBAY',
    municipality: 'MANITO',
    region: 'REGION V',
    barangay_list: [
      'BALABAGON',
      'BALASBAS',
      'BAMBAN',
      'BUYO',
      'CABACONGAN',
      'CABIT',
      'CAWAYAN',
      'CAWIT',
      'HOLUGAN',
      'IT-BA (POB.)',
      'MALOBAGO',
      'MANUMBALAY',
      'NAGOTGOT',
      'PAWA',
      'TINAPIAN',
    ],
  },
  OAS: {
    province: 'ALBAY',
    municipality: 'OAS',
    region: 'REGION V',
    barangay_list: [
      'BADBAD',
      'BADIAN',
      'BAGSA',
      'BAGUMBAYAN',
      'BALOGO',
      'BANAO',
      'BANGIAWON',
      'BOGTONG',
      'BONGORAN',
      'BUSAC',
      'CADAWAG',
      'CAGMANABA',
      'CALAGUIMIT',
      'CALPI',
      'CALZADA',
      'CAMAGONG',
      'CASINAGAN',
      'CENTRO POBLACION',
      'COLIAT',
      'DEL ROSARIO',
      'GUMABAO',
      'ILAOR NORTE',
      'ILAOR SUR',
      'IRAYA NORTE',
      'IRAYA SUR',
      'MANGA',
      'MAPORONG',
      'MARAMBA',
      'MATAMBO',
      'MAYAG',
      'MAYAO',
      'MOROPONROS',
      'NAGAS',
      'OBALIW-RINAS',
      'PISTOLA',
      'RAMAY',
      'RIZAL',
      'SABAN',
      'SAN AGUSTIN',
      'SAN ANTONIO',
      'SAN ISIDRO',
      'SAN JOSE',
      'SAN JUAN',
      'SAN MIGUEL',
      'SAN PASCUAL (NALE)',
      'SAN RAMON',
      'SAN VICENTE (SUCA)',
      'TABLON',
      'TALISAY',
      'TALONGOG',
      'TAPEL',
      'TOBGON',
      'TOBOG',
    ],
  },
  'PIO DURAN': {
    province: 'ALBAY',
    municipality: 'PIO DURAN',
    region: 'REGION V',
    barangay_list: [
      'AGOL',
      'ALABANGPURO',
      'BANAWAN (BINAWAN)',
      'BARANGAY I (POB.)',
      'BARANGAY II (POB.)',
      'BARANGAY III (POB.)',
      'BARANGAY IV (POB.)',
      'BARANGAY V (POB.)',
      'BASICAO COASTAL',
      'BASICAO INTERIOR',
      'BINODEGAHAN',
      'BUENAVISTA',
      'BUYO',
      'CARATAGAN',
      'CUYAOYAO',
      'FLORES',
      'LA MEDALLA',
      'LAWINON',
      'MACASITAS',
      'MALAPAY',
      'MALIDONG',
      'MAMLAD',
      'MARIGONDON',
      'MATANGLAD',
      'NABLANGBULOD',
      'ORINGON',
      'PALAPAS',
      'PANGANIRAN',
      'RAWIS',
      'SALVACION',
      'SANTO CRISTO',
      'SUKIP',
      'TIBABO',
    ],
  },
  POLANGUI: {
    province: 'ALBAY',
    municipality: 'POLANGUI',
    region: 'REGION V',
    barangay_list: [
      'AGOS',
      'ALNAY',
      'ALOMON',
      'AMOGUIS',
      'ANOPOL',
      'APAD',
      'BALABA',
      'BALANGIBANG',
      'BALINAD',
      'BASUD',
      'BINAGBANGAN (PINTOR)',
      'BUYO',
      'CENTRO OCCIDENTAL (POB.)',
      'CENTRO ORIENTAL (POB.)',
      'CEPRES',
      'COTMON',
      'COTNOGAN',
      'DANAO',
      'GABON',
      'GAMOT',
      'ITARAN',
      'KINALE',
      'KINUARTILAN',
      'LA MEDALLA',
      'LA PURISIMA',
      'LANIGAY',
      'LIDONG',
      'LOURDES',
      'MAGPANAMBO',
      'MAGURANG',
      'MATACON',
      'MAYNAGA',
      'MAYSUA',
      'MENDEZ',
      'NAPO',
      'PINAGDAPUGAN',
      'PONSO',
      'SALVACION',
      'SAN ROQUE',
      'SANTA CRUZ',
      'SANTA TERESITA',
      'SANTICON',
      'SUGCAD',
      'UBALIW',
    ],
  },
  'RAPU-RAPU': {
    province: 'ALBAY',
    municipality: 'RAPU-RAPU',
    region: 'REGION V',
    barangay_list: [
      'BAGAOBAWAN',
      'BATAN',
      'BILBAO',
      'BINOSAWAN',
      'BOGTONG',
      'BUENAVISTA',
      'BUHATAN',
      'CALANAGA',
      'CARACARAN',
      'CAROGCOG',
      'DAP-DAP',
      'GABA',
      'GALICIA',
      'GUADALUPE',
      'HAMORAWON',
      'LAGUNDI',
      'LIGUAN',
      'LINAO',
      'MALOBAGO',
      'MANANAO',
      'MANCAO',
      'MANILA',
      'MASAGA',
      'MOROCBOROCAN',
      'NAGCALSOT',
      'PAGCOLBON',
      'POBLACION',
      'SAGRADA',
      'SAN RAMON',
      'SANTA BARBARA',
      'TINOCAWAN',
      'TINOPAN',
      'VIGA',
      'VILLAHERMOSA',
    ],
  },
  'SANTO DOMINGO (LIBOG)': {
    province: 'ALBAY',
    municipality: 'SANTO DOMINGO (LIBOG)',
    region: 'REGION V',
    barangay_list: [
      'ALIMSOG',
      'BAGONG SAN ROQUE',
      'BUHATAN',
      'CALAYUCAY',
      'DEL ROSARIO POB. (BGY. 3)',
      'FIDEL SURTIDA',
      'LIDONG',
      'MARKET SITE POB. (BGY. 9)',
      'NAGSIYA POB. (BGY. 8)',
      'PANDAYAN POB. (BGY. 10)',
      'SALVACION',
      'SAN ANDRES',
      'SAN FERNANDO',
      'SAN FRANCISCO POB. (BGY. 1)',
      'SAN ISIDRO',
      'SAN JUAN POB. (BGY. 2)',
      'SAN PEDRO POB. (BGY. 5)',
      'SAN RAFAEL POB. (BGY. 7)',
      'SAN ROQUE',
      'SAN VICENTE POB. (BGY. 6.)',
      'SANTA MISERICORDIA',
      'SANTO DOMINGO POB. (BGY. 4)',
      'SANTO NIÑO',
    ],
  },
  'TABACO': {
    province: 'ALBAY',
    municipality: 'TABACO',
    region: 'REGION V',
    barangay_list: [
      'AGNAS (SAN MIGUEL ISLAND)',
      'BACOLOD',
      'BANGKILINGAN',
      'BANTAYAN',
      'BARANGHAWON',
      'BASAGAN',
      'BASUD (POB.)',
      'BOGÑABONG',
      'BOMBON (POB.)',
      'BONOT',
      'BUANG',
      'BUHIAN',
      'CABAGÑAN',
      'COBO',
      'COMON',
      'CORMIDAL',
      'DIVINO ROSTRO (POB.)',
      'FATIMA',
      'GUINOBAT',
      'HACIENDA (SAN MIGUEL ISLAND)',
      'MAGAPO',
      'MARIROC',
      'MATAGBAC',
      'ORAS',
      'OSON',
      'PANAL',
      'PAWA',
      'PINAGBOBONG',
      'QUINALE CABASAN (POB.)',
      'QUINASTILLOJAN',
      'RAWIS (SAN MIGUEL ISLAND)',
      'SAGURONG (SAN MIGUEL ISLAND)',
      'SALVACION',
      'SAN ANTONIO',
      'SAN CARLOS',
      'SAN ISIDRO (BORING)',
      'SAN JUAN (POB.)',
      'SAN LORENZO',
      'SAN RAMON',
      'SAN ROQUE',
      'SAN VICENTE',
      'SANTO CRISTO (POB.)',
      'SUA-IGOT',
      'TABIGUIAN',
      'TAGAS',
      'TAYHI (POB.)',
      'VISITA (SAN MIGUEL ISLAND)',
    ],
  },
  TIWI: {
    province: 'ALBAY',
    municipality: 'TIWI',
    region: 'REGION V',
    barangay_list: [
      'BAGUMBAYAN',
      'BARIIS',
      'BAYBAY',
      'BELEN (MALABOG)',
      'BIYONG',
      'BOLO',
      'CALE',
      'CARARAYAN',
      'CORO-CORO',
      'DAP-DAP',
      'GAJO',
      'JOROAN',
      'LIBJO',
      'LIBTONG',
      'MATALIBONG',
      'MAYNONONG',
      'MAYONG',
      'MISIBIS',
      'NAGA',
      'NAGAS',
      'OYAMA',
      'PUTSAN',
      'SAN BERNARDO',
      'SOGOD',
      'TIGBI (POB.)',
    ],
  },
  BASUD: {
    province: 'CAMARINES NORTE',
    municipality: 'BASUD',
    region: 'REGION V',
    barangay_list: [
      'ANGAS',
      'BACTAS',
      'BINATAGAN',
      'CAAYUNAN',
      'GUINATUNGAN',
      'HINAMPACAN',
      'LANGA',
      'LANITON',
      'LIDONG',
      'MAMPILI',
      'MANDAZO',
      'MANGCAMAGONG',
      'MANMUNTAY',
      'MANTUGAWE',
      'MATNOG',
      'MOCONG',
      'OLIVA',
      'PAGSANGAHAN',
      'PINAGWARASAN',
      'PLARIDEL',
      'POBLACION 1',
      'POBLACION 2',
      'SAN FELIPE',
      'SAN JOSE',
      'SAN PASCUAL',
      'TABA-TABA',
      'TACAD',
      'TAISAN',
      'TUACA',
    ],
  },
  CAPALONGA: {
    province: 'CAMARINES NORTE',
    municipality: 'CAPALONGA',
    region: 'REGION V',
    barangay_list: [
      'ALAYAO',
      'BINAWANGAN',
      'CALABACA',
      'CAMAGSAAN',
      'CATABAGUANGAN',
      'CATIOAN',
      'DEL PILAR',
      'ITOK',
      'LUCBANAN',
      'MABINI',
      'MACTANG',
      'MAGSAYSAY',
      'MATAQUE',
      'OLD CAMP',
      'POBLACION',
      'SAN ANTONIO',
      'SAN ISIDRO',
      'SAN ROQUE',
      'TANAWAN',
      'UBANG',
      'VILLA AURORA',
      'VILLA BELEN',
    ],
  },
  DAET: {
    province: 'CAMARINES NORTE',
    municipality: 'DAET',
    region: 'REGION V',
    barangay_list: [
      'ALAWIHAO',
      'AWITAN',
      'BAGASBAS',
      'BARANGAY I (POB.)',
      'BARANGAY II (POB.)',
      'BARANGAY III (POB.)',
      'BARANGAY IV (POB.)',
      'BARANGAY V (POB.)',
      'BARANGAY VI (POB.)',
      'BARANGAY VII (POB.)',
      'BARANGAY VIII (POB.)',
      'BIBIRAO',
      'BORABOD',
      'CALASGASAN',
      'CAMAMBUGAN',
      'COBANGBANG (CARUMPIT)',
      'DOGONGAN',
      'GAHONON',
      'GUBAT',
      'LAG-ON',
      'MAGANG',
      'MAMBALITE',
      'MANCRUZ (MANGCRUZ)',
      'PAMORANGON',
      'SAN ISIDRO',
    ],
  },
  'JOSE PANGANIBAN': {
    province: 'CAMARINES NORTE',
    municipality: 'JOSE PANGANIBAN',
    region: 'REGION V',
    barangay_list: [
      'BAGONG BAYAN',
      'CALERO',
      'DAHICAN',
      'DAYHAGAN',
      'LARAP',
      'LUKLUKAN NORTE',
      'LUKLUKAN SUR',
      'MOTHERLODE',
      'NAKALAYA',
      'NORTH POBLACION',
      'OSMEÑA',
      'PAG-ASA',
      'PARANG',
      'PLARIDEL',
      'SALVACION',
      'SAN ISIDRO',
      'SAN JOSE',
      'SAN MARTIN',
      'SAN PEDRO',
      'SAN RAFAEL',
      'SANTA CRUZ',
      'SANTA ELENA',
      'SANTA MILAGROSA',
      'SANTA ROSA NORTE',
      'SANTA ROSA SUR',
      'SOUTH POBLACION',
      'TAMISAN',
    ],
  },
  LABO: {
    province: 'CAMARINES NORTE',
    municipality: 'LABO',
    region: 'REGION V',
    barangay_list: [
      'ANAHAW (POB.)',
      'ANAMEAM',
      'AWITAN',
      'BAAY',
      'BAGACAY',
      'BAGONG SILANG I',
      'BAGONG SILANG II',
      'BAGONG SILANG III (SILANG)',
      'BAKIAD',
      'BAUTISTA',
      'BAYABAS',
      'BAYAN-BAYAN',
      'BENIT',
      'BULHAO',
      'CABATUHAN',
      'CABUSAY',
      'CALABASA',
      'CANAPAWAN',
      'DAGUIT',
      'DALAS',
      'DUMAGMANG',
      'EXCIBAN',
      'FUNDADO',
      'GUINACUTAN',
      'GUISICAN',
      'GUMAMELA (POB.)',
      'IBERICA',
      'KALAMUNDING (POB.)',
      'LUGUI',
      'MABILO I',
      'MABILO II',
      'MACOGON',
      'MAHAWAN-HAWAN',
      'MALANGCAO-BASUD',
      'MALASUGUI',
      'MALATAP',
      'MALAYA',
      'MALIBAGO',
      'MAOT',
      'MASALONG',
      'MATANLANG',
      'NAPAOD',
      'PAG-ASA',
      'PANGPANG',
      'PINYA (POB.)',
      'SAN ANTONIO',
      'SAN FRANCISCO (POB.)',
      'SANTA CRUZ',
      'SUBMAKIN',
      'TALOBATIB',
      'TIGBINAN',
      'TULAY NA LUPA',
    ],
  },
  MERCEDES: {
    province: 'EASTERN SAMAR',
    municipality: 'MERCEDES',
    region: 'REGION VIII',
    barangay_list: [
      'ANURON',
      'BANUYO',
      'BARANGAY 1 POBLACION',
      'BARANGAY 2 POBLACION',
      'BARANGAY 3 POBLACION',
      'BARANGAY 4 POBLACION',
      'BOBON',
      'BUSAY',
      'BUYAYAWON',
      'CABUNGA-AN',
      'CAMBANTE',
      'PALAMRAG (CABILIRI-AN)',
      'PORT KENNEDY',
      'SAN JOSE',
      'SAN ROQUE',
      'SUNG-AN',
    ],
  },
  PARACALE: {
    province: 'CAMARINES NORTE',
    municipality: 'PARACALE',
    region: 'REGION V',
    barangay_list: [
      'AWITAN',
      'BAGUMBAYAN',
      'BAKAL',
      'BATOBALANI',
      'CALABURNAY',
      'CAPACUAN',
      'CASALUGAN',
      'DAGANG',
      'DALNAC',
      'DANCALAN',
      'GUMAUS',
      'LABNIG',
      'MACOLABO ISLAND',
      'MALACBANG',
      'MALAGUIT',
      'MAMPUNGO',
      'MANGKASAY',
      'MAYBATO',
      'PALANAS',
      'PINAGBIRAYAN MALAKI',
      'PINAGBIRAYAN MUNTI',
      'POBLACION NORTE',
      'POBLACION SUR',
      'TABAS',
      'TALUSAN',
      'TAWIG',
      'TUGOS',
    ],
  },
  'SAN LORENZO RUIZ (IMELDA)': {
    province: 'CAMARINES NORTE',
    municipality: 'SAN LORENZO RUIZ (IMELDA)',
    region: 'REGION V',
    barangay_list: [
      'DACULANG BOLO',
      'DAGOTDOTAN',
      'LANGGA',
      'LANITON',
      'MAISOG',
      'MAMPUROG',
      'MANLIMONSITO',
      'MATACONG (POB.)',
      'SALVACION',
      'SAN ANTONIO',
      'SAN ISIDRO',
      'SAN RAMON',
    ],
  },
  'SANTA ELENA': {
    province: 'CAMARINES NORTE',
    municipality: 'SANTA ELENA',
    region: 'REGION V',
    barangay_list: [
      'BASIAD',
      'BULALA',
      'DON TOMAS',
      'GUITOL',
      'KABULUAN',
      'KAGTALABA',
      'MAULAWIN',
      'PATAG IBABA',
      'PATAG IRAYA',
      'PLARIDEL',
      'POLUNGGUITGUIT',
      'RIZAL',
      'SALVACION',
      'SAN LORENZO',
      'SAN PEDRO',
      'SAN VICENTE',
      'SANTA ELENA (POB.)',
      'TABUGON',
      'VILLA SAN ISIDRO',
    ],
  },
  VINZONS: {
    province: 'CAMARINES NORTE',
    municipality: 'VINZONS',
    region: 'REGION V',
    barangay_list: [
      'AGUIT-IT',
      'BANOCBOC',
      'BARANGAY I (POB.)',
      'BARANGAY II (POB.)',
      'BARANGAY III (POB.)',
      'CAGBALOGO',
      'CALANGCAWAN NORTE',
      'CALANGCAWAN SUR',
      'GUINACUTAN',
      'MANGCAWAYAN',
      'MANGCAYO',
      'MANLUCUGAN',
      'MATANGO',
      'NAPILIHAN',
      'PINAGTIGASAN',
      'SABANG',
      'SANTO DOMINGO',
      'SINGI',
      'SULA',
    ],
  },
  BAAO: {
    province: 'CAMARINES SUR',
    municipality: 'BAAO',
    region: 'REGION V',
    barangay_list: [
      'AGDANGAN POB. (SAN CAYETANO)',
      'ANTIPOLO',
      'BAGUMBAYAN',
      'BULUANG (SAN ANTONIO)',
      'CARANDAY (LA PURISIMA)',
      'CRISTO REY',
      'DEL PILAR',
      'DEL ROSARIO (POB.)',
      'IYAGAN',
      'LA MEDALLA',
      'LOURDES',
      'NABABARERA',
      'PUGAY (SAN RAFAEL)',
      'SAGRADA',
      'SALVACION',
      'SAN FRANCISCO (POB.)',
      'SAN ISIDRO',
      'SAN JOSE (POB.)',
      'SAN JUAN',
      'SAN NICOLAS (POB.)',
      'SAN RAFAEL (IKPAN)',
      'SAN RAMON (POB.)',
      'SAN ROQUE (POB.)',
      'SAN VICENTE',
      'SANTA CRUZ (POB.)',
      'SANTA EULALIA',
      'SANTA ISABEL',
      'SANTA TERESA (VIGA)',
      'SANTA TERESITA',
      'TAPOL',
    ],
  },
  BALATAN: {
    province: 'CAMARINES SUR',
    municipality: 'BALATAN',
    region: 'REGION V',
    barangay_list: [
      'CABANBANAN',
      'CABUNGAN',
      'CAMANGAHAN (CAORASAN)',
      'CAYOGCOG',
      'COGUIT',
      'DURAN',
      'LAGANAC',
      'LULUASAN',
      'MONTENEGRO (MAGUIRON)',
      'PARARAO',
      'PULANG DAGA',
      'SAGRADA NACACALE',
      'SAN FRANCISCO',
      'SANTIAGO NACACALE',
      'SIRAMAG (POB.)',
      'TAPAYAS',
      'TOMATARAYO',
    ],
  },
  BATO: {
    province: 'LEYTE',
    municipality: 'BATO',
    region: 'REGION VIII',
    barangay_list: [
      'ALEGRIA',
      'ALEJOS',
      'AMAGOS',
      'ANAHAWAN',
      'BAGO',
      'BAGONG BAYAN DISTRICT (POB.)',
      'BULI',
      'CEBUANA',
      'DAAN LUNGSOD',
      'DAWAHON',
      'DOLHO',
      'DOMAGOCDOC',
      'GUERRERO DISTRICT (POB.)',
      'HIMAMAA',
      'IMELDA',
      'INIGUIHAN DISTRICT (POB.)',
      'KALANGGAMAN DISTRICT (POB.)',
      'KATIPUNAN',
      'LIBERTY (BINALIW)',
      'MABINI',
      'MARCELO',
      'NAGA',
      'OSMEÑA',
      'PLARIDEL',
      'PONONG',
      'RIVILLA',
      'SAN AGUSTIN',
      'SANTO NIÑO',
      'TABUNOK',
      'TAGAYTAY',
      'TINAGO DISTRICT (POB.)',
      'TUGAS',
    ],
  },
  BOMBON: {
    province: 'CAMARINES SUR',
    municipality: 'BOMBON',
    region: 'REGION V',
    barangay_list: [
      'PAGAO (SAN JUAN)',
      'SAN ANTONIO',
      'SAN FRANCISCO',
      'SAN ISIDRO (POB.)',
      'SAN JOSE (POB.)',
      'SAN ROQUE (POB.)',
      'SANTO DOMINGO',
      'SIEMBRE',
    ],
  },
  BUHI: {
    province: 'CAMARINES SUR',
    municipality: 'BUHI',
    region: 'REGION V',
    barangay_list: [
      'AMLONGAN (DEL ROSARIO)',
      'ANTIPOLO',
      'BUROCBUSOC',
      'CABATUAN',
      'CAGMASLOG',
      'DE LA FE',
      'DELOS ANGELES (LOS ANGELES)',
      'DIVINO ROSTRO',
      'GABAS',
      'IBAYUGAN',
      'IGBAC',
      'IPIL (FATIMA)',
      'IRAYA (DEL ROSARIO)',
      'LABAWON (SANTA TERESITA)',
      'LOURDES (SANTA LOURDES)',
      'MACAANGAY',
      'MONTE CALVARIO',
      'NAMURABOD',
      'SAGRADA FAMILIA',
      'SALVACION',
      'SAN ANTONIO',
      'SAN BUENAVENTURA (POB.)',
      'SAN FRANCISCO (PARADA)',
      'SAN ISIDRO',
      'SAN JOSE BAYBAYON',
      'SAN JOSE SALAY',
      'SAN PASCUAL (POB.)',
      'SAN PEDRO (POB.)',
      'SAN RAFAEL',
      'SAN RAMON',
      'SAN ROQUE (POB.)',
      'SAN VICENTE',
      'SANTA CLARA (POB.)',
      'SANTA CRUZ',
      'SANTA ELENA (POB.)',
      'SANTA ISABEL',
      'SANTA JUSTINA',
      'TAMBO',
    ],
  },
  BULA: {
    province: 'CAMARINES SUR',
    municipality: 'BULA',
    region: 'REGION V',
    barangay_list: [
      'BAGOLADIO',
      'BAGUMBAYAN',
      'BALAOGAN',
      'CAORASAN',
      'CASUGAD',
      'CAUSIP',
      'FABRICA',
      'INOYONAN',
      'ITANGON',
      'KINALABASAHAN',
      'LA PURISIMA',
      'LA VICTORIA',
      'LANIPGA',
      'LUBGAN',
      'OMBAO HEIGHTS',
      'OMBAO POLPOG',
      'PALSONG',
      'PANOYPOYAN',
      'PAWILI',
      'SAGRADA (SAGRADA FAMILIA)',
      'SALVACION (POB.)',
      'SAN AGUSTIN',
      'SAN FRANCISCO',
      'SAN ISIDRO',
      'SAN JOSE',
      'SAN MIGUEL',
      'SAN RAMON',
      'SAN ROQUE (POB.)',
      'SAN ROQUE HEIGHTS',
      'SANTA ELENA',
      'SANTO DOMINGO',
      'SANTO NIÑO',
      'TAISAN',
    ],
  },
  CABUSAO: {
    province: 'CAMARINES SUR',
    municipality: 'CABUSAO',
    region: 'REGION V',
    barangay_list: [
      'BARCELONITA',
      'BIONG',
      'CAMAGONG',
      'CASTILLO',
      'NEW POBLACION',
      'PANDAN',
      'SAN PEDRO',
      'SANTA CRUZ',
      'SANTA LUTGARDA (POB.)',
    ],
  },
  CALABANGA: {
    province: 'CAMARINES SUR',
    municipality: 'CALABANGA',
    region: 'REGION V',
    barangay_list: [
      'BALATASAN',
      'BALOMBON',
      'BALONGAY',
      'BELEN',
      'BIGAAS',
      'BINALIW',
      'BINANUAANAN GRANDE',
      'BINANUAANAN PEQUEÑO',
      'BONOT-SANTA ROSA',
      'BURABOD',
      'CABANBANAN',
      'CAGSAO',
      'CAMUNING',
      'COMAGUINGKING',
      'DEL CARMEN (POB.)',
      'DOMINOROG',
      'FABRICA',
      'HAROBAY',
      'LA PURISIMA',
      'LUGSAD',
      'MANGUIRING',
      'PAGATPAT (SAN JOSE)',
      'PAOLBO',
      'PINADA',
      'PUNTA TARAWAL',
      'QUINALE',
      'SABANG',
      'SALVACION-BAYBAY',
      'SAN ANTONIO (QUIPAYO)',
      'SAN ANTONIO POBLACION',
      'SAN BERNARDINO',
      'SAN FRANCISCO (POB.)',
      'SAN ISIDRO',
      'SAN LUCAS',
      'SAN MIGUEL (POB.)',
      'SAN PABLO (POB.)',
      'SAN ROQUE',
      'SAN VICENTE (POB.)',
      'SANTA CRUZ POBLACION',
      'SANTA CRUZ RATAY',
      'SANTA ISABEL (POB.)',
      'SANTA SALUD (POB.)',
      'SANTO DOMINGO',
      'SANTO NIÑO (QUIPAYO)',
      'SIBA-O',
      'SIBOBO',
      'SOGOD',
      'TOMAGODTOD',
    ],
  },
  CAMALIGAN: {
    province: 'CAMARINES SUR',
    municipality: 'CAMALIGAN',
    region: 'REGION V',
    barangay_list: [
      'DUGCAL',
      'MARUPIT',
      'SAN FRANCISCO',
      'SAN JOSE-SAN PABLO (POB.)',
      'SAN JUAN-SAN RAMON (POB.)',
      'SAN LUCAS (POB.)',
      'SAN MARCOS (POB.)',
      'SAN MATEO (POB.)',
      'SAN ROQUE',
      'SANTO DOMINGO (POB.)',
      'SANTO TOMAS (POB.)',
      'SUA',
      'TAROSANAN',
    ],
  },
  CANAMAN: {
    province: 'CAMARINES SUR',
    municipality: 'CANAMAN',
    region: 'REGION V',
    barangay_list: [
      'BARAS (POB.)',
      'DEL ROSARIO',
      'DINAGA (POB.)',
      'FUNDADO',
      'HARING',
      'IQUIN',
      'LINAGA',
      'MANGAYAWAN',
      'PALO',
      'PANGPANG (POB.)',
      'PORO',
      'SAN AGUSTIN',
      'SAN FRANCISCO',
      'SAN JOSE EAST',
      'SAN JOSE WEST',
      'SAN JUAN',
      'SAN NICOLAS',
      'SAN ROQUE',
      'SAN VICENTE',
      'SANTA CRUZ (POB.)',
      'SANTA TERESITA',
      'SUA',
      'TALIDTID',
      'TIBGAO (POB.)',
    ],
  },
  CARAMOAN: {
    province: 'CAMARINES SUR',
    municipality: 'CARAMOAN',
    region: 'REGION V',
    barangay_list: [
      'AGAAS',
      'ANTOLON',
      'BACGONG',
      'BAHAY',
      'BIKAL',
      'BINANUAHAN (POB.)',
      'CABACONGAN',
      'CADONG',
      'CANATUAN',
      'CAPUTATAN',
      'COLONGCOGONG',
      'DARAGA',
      'GATA',
      'GIBGOS',
      'GOGON',
      'GUIJALO',
      'HANOPOL',
      'HANOY',
      'HAPONAN',
      'ILAWOD',
      'ILI-CENTRO (POB.)',
      'LIDONG',
      'LUBAS',
      'MALABOG',
      'MALIGAYA',
      'MAMPIRAO',
      'MANDICLUM (MANDICLOM)',
      'MAQUEDA',
      'MINALABA',
      'ORING',
      'OROC-OSOC',
      'PAGOLINAN',
      'PANDANAN',
      'PANIMAN',
      'PATAG-BELEN',
      'PILI-CENTRO',
      'PILI-TABIGUIAN',
      'POLOAN',
      'SALVACION',
      'SAN ROQUE',
      'SAN VICENTE',
      'SANTA CRUZ',
      'SOLNOPAN',
      'TABGON',
      'TABIGUIAN',
      'TABOG',
      'TAWOG (POB.)',
      'TEROGO',
      'TOBOAN',
    ],
  },
  'DEL GALLEGO': {
    province: 'CAMARINES SUR',
    municipality: 'DEL GALLEGO',
    region: 'REGION V',
    barangay_list: [
      'BAGONG SILANG',
      'BUCAL',
      'CABASAG',
      'COMADAYCADAY',
      'COMADOGCADOG',
      'DOMAGONDONG',
      'KINALANGAN',
      'MABINI',
      'MAGAIS I',
      'MAGAIS II',
      'MANSALAYA',
      'NAGKALIT',
      'PALASPAS',
      'PAMPLONA',
      'PASAY',
      'PINAGDAPIAN',
      'PINUGUSAN',
      'POBLACION ZONE III',
      'SABANG',
      'SALVACION',
      'SAN JUAN',
      'SAN PABLO',
      'SANTA RITA I',
      'SANTA RITA II',
      'SINAGAWSAWAN',
      'SINUKNIPAN I',
      'SINUKNIPAN II',
      'SUGSUGIN',
      'TABION',
      'TOMAGOKTOK',
      'ZONE I FATIMA (POB.)',
      'ZONE II SAN ANTONIO (POB.)',
    ],
  },
  GAINZA: {
    province: 'CAMARINES SUR',
    municipality: 'GAINZA',
    region: 'REGION V',
    barangay_list: [
      'CAGBUNGA',
      'DAHILIG',
      'DISTRICT I (POB.)',
      'DISTRICT II (POB.)',
      'LOOB',
      'MALBONG',
      'NAMUAT',
      'SAMPALOC',
    ],
  },
  GARCHITORENA: {
    province: 'CAMARINES SUR',
    municipality: 'GARCHITORENA',
    region: 'REGION V',
    barangay_list: [
      'ASON (ANSON)',
      'BAHI',
      'BARANGAY I (POB.)',
      'BARANGAY II (POB.)',
      'BARANGAY III (POB.)',
      'BARANGAY IV (POB.)',
      'BINAGASBASAN',
      'BURABOD',
      'CAGAMUTAN',
      'CAGNIPA',
      'CANLONG',
      'DANGLA',
      'DEL PILAR',
      'DENRICA',
      'HARRISON',
      'MANSANGAT',
      'PAMBUHAN',
      'SAGRADA',
      'SALVACION',
      'SAN VICENTE',
      'SUMAOY',
      'TAMIAWON',
      'TOYTOY',
    ],
  },
  GOA: {
    province: 'CAMARINES SUR',
    municipality: 'GOA',
    region: 'REGION V',
    barangay_list: [
      'ABUCAYAN',
      'BAGUMBAYAN GRANDE (POB.)',
      'BAGUMBAYAN PEQUEÑO (POB.)',
      'BALAYNAN',
      'BELEN (POB.)',
      'BUYO',
      'CAGAYCAY',
      'CATAGBACAN',
      'DIGDIGON',
      'GIMAGA',
      'HALAWIG-GOGON',
      'HIWACLOY',
      'LA PURISIMA (POB.)',
      'LAMON',
      'MATACLA',
      'MAYMATAN',
      'MAYSALAY',
      'NAPAWON (NAPUNUON)',
      'PANDAY (POB.)',
      'PAYATAN',
      'PINAGLABANAN',
      'SALOG',
      'SAN BENITO (POB.)',
      'SAN ISIDRO (POB.)',
      'SAN ISIDRO WEST',
      'SAN JOSE (POB.)',
      'SAN JUAN BAUTISTA (POB.)',
      'SAN JUAN EVANGELISTA (POB.)',
      'SAN PEDRO (ARORO)',
      'SCOUT FUENTEBELLA',
      'TABGON',
      'TAGONGTONG',
      'TAMBAN (MABINI)',
      'TAYTAY (HALGON EAST)',
    ],
  },
  'IRIGA': {
    province: 'CAMARINES SUR',
    municipality: 'IRIGA',
    region: 'REGION V',
    barangay_list: [
      'ANTIPOLO',
      'CRISTO REY',
      'DEL ROSARIO (BANAO)',
      'FRANCIA',
      'LA ANUNCIACION',
      'LA MEDALLA',
      'LA PURISIMA',
      'LA TRINIDAD',
      'NIÑO JESUS',
      'PERPETUAL HELP',
      'SAGRADA',
      'SALVACION',
      'SAN AGUSTIN',
      'SAN ANDRES',
      'SAN ANTONIO',
      'SAN FRANCISCO (POB.)',
      'SAN ISIDRO',
      'SAN JOSE',
      'SAN JUAN',
      'SAN MIGUEL',
      'SAN NICOLAS',
      'SAN PEDRO',
      'SAN RAFAEL',
      'SAN RAMON',
      'SAN ROQUE (POB.)',
      'SAN VICENTE NORTE',
      'SAN VICENTE SUR',
      'SANTA CRUZ NORTE',
      'SANTA CRUZ SUR',
      'SANTA ELENA',
      'SANTA ISABEL',
      'SANTA MARIA',
      'SANTA TERESITA',
      'SANTIAGO',
      'SANTO DOMINGO',
      'SANTO NIÑO',
    ],
  },
  LAGONOY: {
    province: 'CAMARINES SUR',
    municipality: 'LAGONOY',
    region: 'REGION V',
    barangay_list: [
      'AGOSAIS',
      'AGPO-CAMAGONG-TABOG',
      'AMOGUIS',
      'BALATON',
      'BINANUAHAN',
      'BOCOGAN',
      'BURABOD',
      'CABOTONAN',
      'DAHAT',
      'DEL CARMEN',
      'GIMAGTOCON',
      'GINORANGAN (GENORANGAN)',
      'GUBAT',
      'GUIBAHOY',
      'HIMANAG',
      'KINAHOLOGAN',
      'LOHO',
      'MANAMOC',
      'MANGOGON',
      'MAPID',
      'OLAS',
      'OMALO',
      'PANAGAN',
      'PANICUAN',
      'PINAMIHAGAN',
      'SAN FRANCISCO (POB.)',
      'SAN ISIDRO',
      'SAN ISIDRO NORTE (POB.)',
      'SAN ISIDRO SUR (POB.)',
      'SAN RAFAEL',
      'SAN RAMON',
      'SAN ROQUE',
      'SAN SEBASTIAN',
      'SAN VICENTE (POB.)',
      'SANTA CRUZ (POB.)',
      'SANTA MARIA (POB.)',
      'SARIPONGPONG (POB.)',
      'SIPACO',
    ],
  },
  LIBMANAN: {
    province: 'CAMARINES SUR',
    municipality: 'LIBMANAN',
    region: 'REGION V',
    barangay_list: [
      'ASLONG',
      'AWAYAN',
      'BAGACAY',
      'BAGADION',
      'BAGAMELON',
      'BAGUMBAYAN',
      'BAHAO',
      'BAHAY',
      'BEGAJO NORTE',
      'BEGAJO SUR',
      'BEGUITO NUEVO',
      'BEGUITO VIEJO',
      'BIKAL',
      'BUSAK',
      'CAIMA',
      'CALABNIGAN',
      'CAMAMBUGAN',
      'CAMBALIDIO',
      'CANDAMI',
      'CANDATO',
      'CAWAYAN',
      'CONCEPCION',
      'CUYAPI',
      'DANAWAN',
      'DUANG NIOG',
      'HANDONG',
      'IBID',
      'INALAHAN',
      'LABAO',
      'LIBOD I',
      'LIBOD II',
      'LOBA-LOBA',
      'MABINI',
      'MALANSAD NUEVO',
      'MALANSAD VIEJO',
      'MALBOGON',
      'MALINAO',
      'MAMBALITE',
      'MAMBAYAWAS',
      'MAMBULO NUEVO',
      'MAMBULO VIEJO',
      'MANCAWAYAN',
      'MANDACANAN',
      'MANTALISAY',
      'PADLOS',
      'PAG-ORING NUEVO',
      'PAG-ORING VIEJO',
      'PALANGON',
      'PALONG',
      'PATAG',
      'PLANZA',
      'POBLACION',
      'POTOT',
      'PURO-BATIA',
      'RONGOS',
      'SALVACION',
      'SAN ISIDRO',
      'SAN JUAN',
      'SAN PABLO',
      'SAN VICENTE',
      'SIBUJO',
      'SIGAMOT',
      'STATION-CHURCH SITE',
      'TABAN-FUNDADO',
      'TAMPUHAN',
      'TANAG',
      'TARUM',
      'TINALMUD NUEVO',
      'TINALMUD VIEJO',
      'TINANGKIHAN',
      'UDOC',
      'UMALO',
      'USON',
      'VILLADIMA (SANTA CRUZ)',
      'VILLASOCORRO',
    ],
  },
  LUPI: {
    province: 'CAMARINES SUR',
    municipality: 'LUPI',
    region: 'REGION V',
    barangay_list: [
      'ALLEOMAR',
      'BAGANGAN SR.',
      'BAGONG SIKAT',
      'BANGON',
      'BARRERA JR.',
      'BARRERA SR.',
      'BEL-CRUZ',
      'BELWANG',
      'BUENASUERTE',
      'BULAWAN JR.',
      'BULAWAN SR.',
      'CABUTAGAN',
      'CASAY',
      'COLACLING (DEL ROSARIO)',
      'CRISTO REY',
      'DEL CARMEN',
      'HAGUIMIT',
      'HALUBAN (PIGBASAGAN)',
      'KAIBIGAN',
      'LA PURISIMA',
      'LOURDES',
      'MANGCAWAYAN',
      'NAPOLIDAN',
      'POBLACION',
      'POLANTUNA',
      'SAGRADA',
      'SALVACION',
      'SAN ISIDRO',
      'SAN JOSE',
      'SAN PEDRO',
      'SAN RAFAEL NORTE',
      'SAN RAFAEL SUR',
      'SAN RAMON',
      'SAN VICENTE',
      'SOOC',
      'TANAWAN',
      'TAPI (LUPI NUEVO)',
      'TIBLE',
    ],
  },
  MAGARAO: {
    province: 'CAMARINES SUR',
    municipality: 'MAGARAO',
    region: 'REGION V',
    barangay_list: [
      'BAROBAYBAY',
      'BELL (POB.)',
      'CARANGCANG',
      'CARIGSA',
      'CASURAY',
      'MONSERRAT (POB.)',
      'PONONG',
      'SAN FRANCISCO (POB.)',
      'SAN ISIDRO (POB.)',
      'SAN JUAN (POB.)',
      'SAN MIGUEL',
      'SAN PANTALEON (POB.)',
      'SANTA LUCIA (POB.)',
      'SANTA ROSA',
      'SANTO TOMAS (POB.)',
    ],
  },
  MILAOR: {
    province: 'CAMARINES SUR',
    municipality: 'MILAOR',
    region: 'REGION V',
    barangay_list: [
      'ALIMBUYOG',
      'AMPARADO (POB.)',
      'BALAGBAG',
      'BORONGBORONGAN',
      'CABUGAO',
      'CAPUCNASAN',
      'DALIPAY',
      'DEL ROSARIO (POB.)',
      'FLORDELIZ',
      'LIPOT',
      'MAYAOPAYAWAN',
      'MAYCATMON',
      'MAYDASO',
      'SAN ANTONIO',
      'SAN JOSE (POB.)',
      'SAN MIGUEL (POB.)',
      'SAN ROQUE (POB.)',
      'SAN VICENTE (POB.)',
      'SANTO DOMINGO (POB.)',
      'TARUSANAN',
    ],
  },
  MINALABAC: {
    province: 'CAMARINES SUR',
    municipality: 'MINALABAC',
    region: 'REGION V',
    barangay_list: [
      'ANTIPOLO',
      'BAGOLATAO',
      'BAGONGBONG',
      'BALIUAG NUEVO',
      'BALIUAG VIEJO',
      'CATANUSAN',
      'DEL CARMEN-DEL ROSARIO (POB.)',
      'DEL SOCORRO',
      'HAMORAON',
      'HOBO',
      'IRAYANG SOLONG',
      'MAGADAP',
      'MALITBOG',
      'MANAPAO',
      'MATAOROC',
      'SAGRADA (SAGRADA FAMILIA)',
      'SALINGOGON',
      'SAN ANTONIO',
      'SAN FELIPE-SANTIAGO (POB.)',
      'SAN FRANCISCO (POB.)',
      'SAN JOSE',
      'SAN JUAN-SAN LORENZO (POB.)',
      'TABAN',
      'TARIRIC',
      'TIMBANG',
    ],
  },
  NABUA: {
    province: 'CAMARINES SUR',
    municipality: 'NABUA',
    region: 'REGION V',
    barangay_list: [
      'ANGUSTIA (ANGUSTIA INAPATAN)',
      'ANTIPOLO OLD',
      'ANTIPOLO YOUNG',
      'ARO-ALDAO',
      'BUSTRAC',
      'DOLOROSA (DOLOROSA INAPATAN)',
      'DURAN (JESUS DURAN)',
      'INAPATAN (DEL ROSARIO INAPATAN)',
      'LA OPINION',
      'LA PURISIMA (LA PURISIMA AGUPIT)',
      'LOURDES OLD',
      'LOURDES YOUNG',
      'MALAWAG (SAN JOSE MALAWAG)',
      'PALOYON ORIENTAL',
      'PALOYON PROPER (SAGRADA PALOYON)',
      'SALVACION QUE GATOS',
      'SAN ANTONIO (POB.)',
      'SAN ANTONIO OGBON',
      'SAN ESTEBAN (POB.)',
      'SAN FRANCISCO (POB.)',
      'SAN ISIDRO (POB.)',
      'SAN ISIDRO INAPATAN',
      'SAN JOSE (SAN JOSE PANGARAON)',
      'SAN JUAN (POB.)',
      'SAN LUIS (POB.)',
      'SAN MIGUEL (POB.)',
      'SAN NICOLAS (POB.)',
      'SAN ROQUE (POB.)',
      'SAN ROQUE MADAWON',
      'SAN ROQUE SAGUMAY',
      'SAN VICENTE GORONG-GORONG',
      'SAN VICENTE OGBON',
      'SANTA BARBARA (MALIBAN)',
      'SANTA CRUZ',
      'SANTA ELENA BARAS',
      'SANTA LUCIA BARAS',
      'SANTIAGO OLD',
      'SANTIAGO YOUNG',
      'SANTO DOMINGO',
      'TANDAAY',
      'TOPAS PROPER',
      'TOPAS SOGOD',
    ],
  },
  'NAGA': {
    province: 'CEBU',
    municipality: 'NAGA',
    region: 'REGION VII',
    barangay_list: [
      'ALFACO',
      'BAIRAN',
      'BALIRONG',
      'CABUNGAHAN',
      'CANTAO-AN',
      'CENTRAL POBLACION',
      'COGON',
      'COLON',
      'EAST POBLACION',
      'INAYAGAN',
      'INOBURAN',
      'JAGUIMIT',
      'LANAS',
      'LANGTAD',
      'LUTAC',
      'MAINIT',
      'MAYANA',
      'NAALAD',
      'NORTH POBLACION',
      'PANGDAN',
      'PATAG',
      'SOUTH POBLACION',
      'TAGJAGUIMIT',
      'TANGKE',
      'TINAAN',
      'TUYAN',
      'ULING',
      'WEST POBLACION',
    ],
  },
  OCAMPO: {
    province: 'CAMARINES SUR',
    municipality: 'OCAMPO',
    region: 'REGION V',
    barangay_list: [
      'AYUGAN',
      'CABARIWAN',
      'CAGMANABA',
      'DEL ROSARIO',
      'GATBO',
      'GUINABAN',
      'HANAWAN',
      'HIBAGO',
      'LA PURISIMA NUEVO',
      'MAY-OGOB',
      'NEW MORIONES',
      'OLD MORIONES',
      'PINIT',
      'POBLACION CENTRAL',
      'POBLACION EAST',
      'POBLACION WEST',
      'SALVACION',
      'SAN ANTONIO',
      'SAN FRANCISCO',
      'SAN JOSE ORAS',
      'SAN ROQUE COMMONAL',
      'SAN VICENTE',
      'SANTA CRUZ',
      'SANTO NIÑO',
      'VILLAFLORIDA',
    ],
  },
  PASACAO: {
    province: 'CAMARINES SUR',
    municipality: 'PASACAO',
    region: 'REGION V',
    barangay_list: [
      'ANTIPOLO',
      'BAGONG SILANG',
      'BAHAY',
      'BALOGO',
      'CARANAN',
      'CUCO',
      'DALUPAON',
      'HUBO',
      'ITULAN',
      'MACAD (HEBRIO LOURDES)',
      'ODICON',
      'QUITANG',
      'SALVACION',
      'SAN ANTONIO',
      'SAN CIRILO (POB.)',
      'SANTA ROSA DEL NORTE (POB.)',
      'SANTA ROSA DEL SUR (POB.)',
      'TILNAC',
      'TINALMUD',
    ],
  },
  PILI: {
    province: 'CAMARINES SUR',
    municipality: 'PILI',
    region: 'REGION V',
    barangay_list: [
      'ANAYAN',
      'BAGONG SIRANG',
      'BINANWAANAN',
      'BINOBONG',
      'CADLAN',
      'CAROYROYAN',
      'CURRY',
      'DEL ROSARIO',
      'HIMAAO',
      'LA PURISIMA',
      'NEW SAN ROQUE',
      'OLD SAN ROQUE (POB.)',
      'PALESTINA',
      'PAWILI',
      'SAGRADA',
      'SAGURONG',
      'SAN AGUSTIN',
      'SAN ANTONIO (POB.)',
      'SAN ISIDRO (POB.)',
      'SAN JOSE',
      'SAN JUAN (POB.)',
      'SAN VICENTE (POB.)',
      'SANTIAGO (POB.)',
      'SANTO NIÑO',
      'TAGBONG',
      'TINANGIS',
    ],
  },
  'PRESENTACION (PARUBCAN)': {
    province: 'CAMARINES SUR',
    municipality: 'PRESENTACION (PARUBCAN)',
    region: 'REGION V',
    barangay_list: [
      'AYUGAO',
      'BAGONG SIRANG',
      'BALIGUIAN',
      'BANTUGAN',
      'BICALEN',
      'BITAOGAN',
      'BUENAVISTA',
      'BULALACAO',
      'CAGNIPA',
      'LAGHA',
      'LIDONG',
      'LIWACSA',
      'MAANGAS',
      'PAGSANGAHAN',
      'PATROCINIO',
      'PILI',
      'SANTA MARIA (POB.)',
      'TANAWAN',
    ],
  },
  RAGAY: {
    province: 'CAMARINES SUR',
    municipality: 'RAGAY',
    region: 'REGION V',
    barangay_list: [
      'AGAO-AO',
      'AGRUPACION',
      'AMOMOKPOK',
      'APAD',
      'APALE',
      'BANGA CAVES',
      'BAYA',
      'BINAHAN PROPER',
      'BINAHAN UPPER',
      'BUENASUERTE',
      'CABADISAN',
      'CABINITAN',
      'CABUGAO',
      'CADITAAN',
      'CALE',
      'CATABANGAN PROPER',
      'F. SIMEON (PUGOD)',
      'GODOFREDO REYES SR. (CATABANGAN CROSSING)',
      'INANDAWA',
      'LAGUIO',
      'LANIPGA-CAWAYAN',
      'LIBORO',
      'LOHONG',
      'LOWER OMON',
      'LOWER SANTA CRUZ',
      'PANAYTAYAN',
      'PANAYTAYAN NUEVO',
      'PATALUNAN',
      'POBLACION ILAOD',
      'POBLACION IRAYA',
      'PORT JUNCTION NORTE',
      'PORT JUNCTION SUR',
      'SALVACION',
      'SAMAY',
      'SAN RAFAEL',
      'TAGBAC',
      'UPPER OMON',
      'UPPER SANTA CRUZ',
    ],
  },
  SAGÑAY: {
    province: 'CAMARINES SUR',
    municipality: 'SAGÑAY',
    region: 'REGION V',
    barangay_list: [
      'ANIOG',
      'ATULAYAN',
      'BONGALON',
      'BURACAN',
      'CATALOTOAN',
      'DEL CARMEN (POB.)',
      'KILANTAAO',
      'KILOMAON',
      'MABCA',
      'MINADONGJOL',
      'NATO',
      'PATITINAN',
      'SAN ANTONIO (POB.)',
      'SAN ISIDRO (POB.)',
      'SAN ROQUE (POB.)',
      'SANTO NIÑO',
      'SIBAGUAN',
      'TINORONGAN',
      'TURAGUE',
    ],
  },
  SIPOCOT: {
    province: 'CAMARINES SUR',
    municipality: 'SIPOCOT',
    region: 'REGION V',
    barangay_list: [
      'ALDEZAR',
      'ALTEZA',
      'ANIB',
      'AWAYAN',
      'AZUCENA',
      'BAGONG SIRANG',
      'BINAHIAN',
      'BOLO NORTE',
      'BOLO SUR',
      'BULAN',
      'BULAWAN',
      'CABUYAO',
      'CAIMA',
      'CALAGBANGAN',
      'CALAMPINAY',
      'CARAYRAYAN',
      'COTMO',
      'GABI',
      'GAONGAN',
      'IMPIG',
      'LIPILIP',
      'LUBIGAN JR.',
      'LUBIGAN SR.',
      'MALAGUICO',
      'MALUBAGO',
      'MANANGLE',
      'MANGAPO',
      'MANGGA',
      'MANLUBANG',
      'MANTILA',
      'NORTH CENTRO (POB.)',
      'NORTH VILLAZAR',
      'SAGRADA FAMILIA',
      'SALANDA',
      'SALVACION',
      'SAN ISIDRO',
      'SAN VICENTE',
      'SERRANZANA',
      'SOUTH CENTRO (POB.)',
      'SOUTH VILLAZAR',
      'TAISAN',
      'TARA',
      'TIBLE',
      'TULA-TULA',
      'VIGAAN',
      'YABO',
    ],
  },
  SIRUMA: {
    province: 'CAMARINES SUR',
    municipality: 'SIRUMA',
    region: 'REGION V',
    barangay_list: [
      'BAGONG SIRANG',
      'BAHAO',
      'BOBOAN',
      'BUTAWANAN',
      'CABUGAO',
      'FUNDADO',
      'HOMESTEAD',
      'LA PURISIMA',
      'MABUHAY',
      'MALACONINI',
      'MATANDANG SIRUMA',
      'NALAYAHAN',
      'PAMINTAN-BANTILAN',
      'PINITAN',
      'POBLACION',
      'SALVACION',
      'SAN ANDRES',
      'SAN RAMON (DALDAGON)',
      'SULPA',
      'TANDOC',
      'TONGO-BANTIGUE',
      'VITO',
    ],
  },
  TIGAON: {
    province: 'CAMARINES SUR',
    municipality: 'TIGAON',
    region: 'REGION V',
    barangay_list: [
      'ABO',
      'CABALINADAN',
      'CARAYCAYON',
      'CASUNA',
      'CONSOCEP',
      'COYAOYAO',
      'GAAO',
      'GINGAROY',
      'GUBAT',
      'HUYONHUYON',
      'LIBOD',
      'MABALODBALOD',
      'MAY-ANAO',
      'PANAGAN',
      'POBLACION',
      'SALVACION',
      'SAN ANTONIO',
      'SAN FRANCISCO',
      'SAN MIGUEL',
      'SAN RAFAEL',
      'TALOJONGON',
      'TINAWAGAN',
      'VINAGRE',
    ],
  },
  TINAMBAC: {
    province: 'CAMARINES SUR',
    municipality: 'TINAMBAC',
    region: 'REGION V',
    barangay_list: [
      'AGAY-AYAN',
      'ANTIPOLO',
      'BAGACAY',
      'BANGA',
      'BANI',
      'BATAAN',
      'BINALAY',
      'BOLAOBALITE',
      'BUENAVISTA',
      'BUYO',
      'CAGLILIOG',
      'CALOCO',
      'CAMAGONG',
      'CANAYONAN',
      'CAWAYNAN',
      'DALIGAN',
      'FILARCA (POB.)',
      'LA MEDALLA (MILE 9)',
      'LA PURISIMA (POB.)',
      'LUPI',
      'MAGSAYSAY (CAMP 4)',
      'MAGTANG',
      'MANANAO',
      'NEW CAALUAN',
      'OLAG GRANDE',
      'OLAG PEQUEÑO',
      'OLD CAALUAN',
      'PAG-ASA',
      'PANTAT',
      'SAGRADA (CAMP 6)',
      'SALVACION',
      'SALVACION POBLACION',
      'SAN ANTONIO',
      'SAN ISIDRO (POB.)',
      'SAN JOSE (TILTILAN)',
      'SAN PASCUAL (POB.)',
      'SAN RAMON (CAMP 7)',
      'SAN ROQUE',
      'SAN VICENTE',
      'SANTA CRUZ (POB.)',
      'SOGOD',
      'TAMBANG',
      'TIERRA NEVADA',
      'UNION',
    ],
  },
  BAGAMANOC: {
    province: 'CATANDUANES',
    municipality: 'BAGAMANOC',
    region: 'REGION V',
    barangay_list: [
      'ANTIPOLO',
      'BACAK',
      'BAGATABAO',
      'BUGAO',
      'CAHAN',
      'HINIPAAN',
      'MAGSAYSAY',
      'POBLACION',
      'QUEZON (PANCAYANAN)',
      'QUIGARAY',
      'SAGRADA',
      'SALVACION (PANUTO)',
      'SAN ISIDRO',
      'SAN RAFAEL (MAHANTOD)',
      'SAN VICENTE',
      'SANTA MESA',
      'SANTA TERESA',
      'SUCHAN',
    ],
  },
  CARAMORAN: {
    province: 'CATANDUANES',
    municipality: 'CARAMORAN',
    region: 'REGION V',
    barangay_list: [
      'BAYBAY (POB.)',
      'BOCON',
      'BOTHOAN (POB.)',
      'BUENAVISTA',
      'BULALACAO',
      'CAMBURO',
      'DARIAO',
      'DATAG EAST',
      'DATAG WEST',
      'GUIAMLONG',
      'HITOMA',
      'ICANBATO (POB.)',
      'INALMASINAN',
      'IYAO',
      'MABINI',
      'MAUI',
      'MAYSURAN',
      'MILAVIGA',
      'OBI',
      'PANIQUE',
      'SABANGAN',
      'SABLOYON',
      'SALVACION',
      'SUPANG',
      'TOYTOY (POB.)',
      'TUBLI',
      'TUCAO',
    ],
  },
  GIGMOTO: {
    province: 'CATANDUANES',
    municipality: 'GIGMOTO',
    region: 'REGION V',
    barangay_list: [
      'BIONG',
      'DORORIAN',
      'POBLACION DISTRICT I',
      'POBLACION DISTRICT II',
      'POBLACION DISTRICT III',
      'SAN PEDRO',
      'SAN VICENTE',
      'SICMIL',
      'SIORON',
    ],
  },
  PANDAN: {
    province: 'ANTIQUE',
    municipality: 'PANDAN',
    region: 'REGION VI',
    barangay_list: [
      'ARACAY',
      'BADIANGAN',
      'BAGUMBAYAN',
      'BAYBAY',
      'BOTBOT',
      'BUANG',
      'CABUGAO',
      'CANDARI',
      'CARMEN',
      'CENTRO NORTE (POB.)',
      'CENTRO SUR (POB.)',
      'DIONELA',
      'DUMROG',
      'DUYONG',
      'FRAGANTE',
      'GUIA',
      'IDIACACAN',
      'JINALINAN',
      'LUHOD-BAYANG',
      'MAADIOS',
      'MAG-ABA',
      'NAPUID',
      'NAURING',
      'PATRIA',
      'PERFECTA',
      'SAN ANDRES',
      'SAN JOAQUIN',
      'SANTA ANA',
      'SANTA CRUZ',
      'SANTA FE',
      'SANTO ROSARIO',
      'TALISAY',
      'TINGIB',
      'ZALDIVAR',
    ],
  },
  'PANGANIBAN (PAYO)': {
    province: 'CATANDUANES',
    municipality: 'PANGANIBAN (PAYO)',
    region: 'REGION V',
    barangay_list: [
      'ALINAWAN',
      'BABAGUAN',
      'BAGONG BAYAN',
      'BURABOD',
      'CABUYOAN',
      'CAGDARAO',
      'MABINI',
      'MACULIW',
      'PANAY',
      'SALVACION (POB.)',
      'SAN ANTONIO',
      'SAN JOAQUIN (POB.)',
      'SAN JOSE (POB.)',
      'SAN JUAN (POB.)',
      'SAN MIGUEL',
      'SAN NICOLAS (POB.)',
      'SAN PEDRO (POB.)',
      'SAN VICENTE (POB.)',
      'SANTA ANA (POB.)',
      'SANTA MARIA (POB.)',
      'SANTO SANTIAGO (POB.)',
      'TAOPON (PANGCAYANAN)',
      'TIBO',
    ],
  },
  'SAN ANDRES (CALOLBON)': {
    province: 'CATANDUANES',
    municipality: 'SAN ANDRES (CALOLBON)',
    region: 'REGION V',
    barangay_list: [
      'AGOJO',
      'ALIBUAG',
      'ASGAD (JUAN M. ALBERTO)',
      'BAGONG SIRANG',
      'BARIHAY',
      'BATONG PALOWAY',
      'BELMONTE (POB.)',
      'BISLIG',
      'BON-OT',
      'CABCAB',
      'CABUNGAHAN',
      'CARANGAG',
      'CATAGBACAN',
      'CODON',
      'COMAGAYCAY',
      'DATAG',
      'DIVINO ROSTRO (POB.)',
      'ESPERANZA (POB.)',
      'HILAWAN',
      'LICTIN',
      'LUBAS',
      'MANAMBRAG',
      'MAYNGAWAY',
      'PALAWIG',
      'PUTING BAYBAY',
      'RIZAL',
      'SALVACION (POB.)',
      'SAN ISIDRO',
      'SAN JOSE',
      'SAN ROQUE (POB.)',
      'SAN VICENTE',
      'SANTA CRUZ (POB.)',
      'SAPANG PALAY (POB.)',
      'TIBANG',
      'TIMBAAN',
      'TOMINAWOG',
      'WAGDAS (POB.)',
      'YOCTI',
    ],
  },
  VIGA: {
    province: 'CATANDUANES',
    municipality: 'VIGA',
    region: 'REGION V',
    barangay_list: [
      'ALMOJUELA',
      'ANANONG',
      'ASUNCION (POB.)',
      'BATOHONAN',
      'BEGONIA',
      'BOTINAGAN',
      'BUENAVISTA',
      'BURGOS',
      'DEL PILAR',
      'MABINI',
      'MAGSAYSAY',
      'OGBONG',
      'OSMEÑA',
      'PEDRO VERA (SUMMIT)',
      'PEÑAFRANCIA (POB.)',
      'QUEZON',
      'QUIRINO (ABUGAN)',
      'RIZAL',
      'ROXAS',
      'SAGRADA',
      'SAN ISIDRO (POB.)',
      'SAN JOSE OCO',
      'SAN JOSE POBLACION',
      'SAN PEDRO (POB.)',
      'SAN ROQUE (POB.)',
      'SAN VICENTE (POB.)',
      'SANTA ROSA',
      'SOBOC',
      'TAMBONGON',
      'TINAGO',
      'VILLA AURORA',
    ],
  },
  VIRAC: {
    province: 'CATANDUANES',
    municipality: 'VIRAC',
    region: 'REGION V',
    barangay_list: [
      'ANTIPOLO DEL NORTE',
      'ANTIPOLO DEL SUR',
      'BALITE',
      'BATAG',
      'BIGAA',
      'BUENAVISTA',
      'BUYO',
      'CABIHIAN',
      'CALABNIGAN',
      'CALAMPONG',
      'CALATAGAN PROPER',
      'CALATAGAN TIBANG',
      'CAPILIHAN',
      'CASOOCAN',
      'CAVINITAN',
      'CONCEPCION (POB.)',
      'CONSTANTINO (POB.)',
      'DANICOP',
      'DUGUI SAN ISIDRO',
      'DUGUI SAN VICENTE',
      'DUGUI TOO',
      'F. TACORDA VILLAGE',
      'FRANCIA (POB.)',
      'GOGON CENTRO',
      'GOGON SIRANGAN',
      'HAWAN GRANDE',
      'HAWAN ILAYA',
      'HICMING',
      'IBONG SAPA (SAN VICENTE SUR)',
      'IGANG',
      'JUAN M. ALBERTO (PONITON)',
      'LANAO (POB.)',
      'MAGNESIA DEL NORTE',
      'MAGNESIA DEL SUR',
      'MARCELO ALBERTO (POB.)',
      'MARILIMA',
      'PAJO BAGUIO',
      'PAJO SAN ISIDRO',
      'PALNAB DEL NORTE',
      'PALNAB DEL SUR',
      'PALTA BIG',
      'PALTA SALVACION',
      'PALTA SMALL',
      'RAWIS (POB.)',
      'SALVACION',
      'SAN ISIDRO VILLAGE',
      'SAN JOSE (POB.)',
      'SAN JUAN (POB.)',
      'SAN PABLO (POB.)',
      'SAN PEDRO (POB.)',
      'SAN ROQUE (POB.)',
      'SAN VICENTE',
      'SANTA CRUZ',
      'SANTA ELENA (POB.)',
      'SANTO CRISTO',
      'SANTO DOMINGO',
      'SANTO NIÑO',
      'SIMAMLA',
      'SOGOD-SIMAMLA',
      'SOGOD-TIBGAO',
      'TALISOY',
      'TUBAON',
      'VALENCIA',
    ],
  },
  AROROY: {
    province: 'MASBATE',
    municipality: 'AROROY',
    region: 'REGION V',
    barangay_list: [
      'AMBOLONG',
      'AMOROY',
      'AMUTAG',
      'BAGAUMA',
      'BALAWING',
      'BALETE',
      'BANGON',
      'CABANGCALAN',
      'CABAS-AN',
      'CALANAY',
      'CAPSAY',
      'CONCEPCION',
      'DAYHAGAN',
      'DON PABLO DELA ROSA',
      'GUMAHANG',
      'JABOYOAN',
      'LANANG',
      'LUY-A',
      'MACABUG',
      'MALUBI',
      'MANAGANAGA',
      'MANAMOC',
      'MARIPOSA',
      'MATABA',
      'MATALANGTALANG',
      'MATANGOG',
      'NABONGSORAN',
      'PANGLE',
      'PANIQUE',
      'PINANAAN',
      'POBLACION',
      'PURO',
      'SAN AGUSTIN',
      'SAN ISIDRO',
      'SAWANG',
      'SYNDICATE',
      'TALABAAN',
      'TALIB',
      'TIGBAO',
      'TINAGO',
      'TINIGBAN',
    ],
  },
  BALENO: {
    province: 'MASBATE',
    municipality: 'BALENO',
    region: 'REGION V',
    barangay_list: [
      'BAAO',
      'BANASE',
      'BATUILA',
      'CAGARA',
      'CAGPANDAN',
      'CANCAHORAO',
      'CANJUNDAY',
      'DOCOL',
      'EASTERN CAPSAY',
      'GABI',
      'GANGAO',
      'LAGTA',
      'LAHONG INTERIOR',
      'LAHONG PROPER',
      'LIPATA',
      'MADANGCALAN',
      'MAGDALENA',
      'MANOBOC',
      'OBONGON DIOT',
      'POBLACION',
      'POLOT',
      'POTOSON',
      'SOG-ONG',
      'TINAPIAN',
    ],
  },
  BALUD: {
    province: 'MASBATE',
    municipality: 'BALUD',
    region: 'REGION V',
    barangay_list: [
      'BAYBAY (LUMOCAB)',
      'BONGCANAWAY',
      'CALUMPANG',
      'CANTIL',
      'CASAMONGAN',
      'DANAO',
      'DAO',
      'GUINBANWAHAN',
      'ILAYA',
      'JANGAN',
      'JINTOTOLO',
      'MABUHAY (BONGCANAWAY III)',
      'MAPILI',
      'MAPITOGO',
      'PAJO',
      'PALANE',
      'PANGUIRANAN',
      'PANUBIGAN',
      'POBLACION (BALUD)',
      'PULANDUTA',
      'QUINAYANGAN DIOTAY',
      'QUINAYANGAN TONGA',
      'SALVACION',
      'SAMPAD',
      'SAN ANDRES (QUINAYANGAN DACU)',
      'SAN ANTONIO',
      'SAPATOS',
      'TALISAY',
      'TONGA',
      'UBO',
      'VICTORY (VICTORIA)',
      'VILLA ALVAREZ',
    ],
  },
  BATUAN: {
    province: 'BOHOL',
    municipality: 'BATUAN',
    region: 'REGION VII',
    barangay_list: [
      'ALOJA',
      'BEHIND THE CLOUDS (SAN JOSE)',
      'CABACNITAN',
      'CAMBACAY',
      'CANTIGDAS',
      'GARCIA',
      'JANLUD',
      'POBLACION NORTE',
      'POBLACION SUR',
      'POBLACION VIEJA (LONGSUDAAN)',
      'QUEZON',
      'QUIRINO',
      'RIZAL',
      'ROSARIOHAN',
      'SANTA CRUZ',
    ],
  },
  CATAINGAN: {
    province: 'MASBATE',
    municipality: 'CATAINGAN',
    region: 'REGION V',
    barangay_list: [
      'ABACA',
      'AGUADA',
      'BADIANG',
      'BAGUMBAYAN',
      'CADULAWAN',
      'CAGBATANG',
      'CHIMENEA',
      'CONCEPCION',
      'CURVADA',
      'DIVISORIA',
      'DOMOROG',
      'ESTAMPAR',
      'GAHIT',
      'LIBTONG',
      'LIONG',
      'MAANAHAO',
      'MADAMBA',
      'MALOBAGO',
      'MATAYUM',
      'MATUBINAO',
      'MINTAC',
      'NADAWISAN',
      'OSMEÑA',
      'PAWICAN',
      'PITOGO',
      'POBLACION',
      'QUEZON',
      'SAN ISIDRO',
      'SAN JOSE',
      'SAN PEDRO',
      'SAN RAFAEL',
      'SANTA TERESITA',
      'SANTO NIÑO',
      'TAGBOAN',
      'TUYBO',
      'VILLA POGADO',
    ],
  },
  CAWAYAN: {
    province: 'MASBATE',
    municipality: 'CAWAYAN',
    region: 'REGION V',
    barangay_list: [
      'BEGIA',
      'CABAYUGAN',
      'CABUNGAHAN',
      'CALAPAYAN',
      'CALUMPANG',
      'CHICO ISLAND',
      'DALIPE',
      'DIVISORIA',
      'GILOTONGAN',
      'GUIOM',
      'IRAYA',
      'ITOMBATO',
      'LAGUE-LAGUE',
      'LIBERTAD',
      'LOOC',
      'MACTAN',
      'MADBAD',
      'MAHAYAHAY',
      'MAIHAO',
      'MALBUG',
      'NARO',
      'PALOBANDERA',
      'PANANAWAN',
      'PEÑA ISLAND',
      'PIN-AS',
      'POBLACION',
      'PULOT',
      'PUNTA BATSAN',
      'R.M. MAGBALON (BEBINAN)',
      'RECODO',
      'SAN JOSE',
      'SAN VICENTE',
      'TABERNA',
      'TALISAY',
      'TUBOG',
      'TUBURAN',
      'VILLAHERMOSA',
    ],
  },
  DIMASALANG: {
    province: 'MASBATE',
    municipality: 'DIMASALANG',
    region: 'REGION V',
    barangay_list: [
      'BALANTAY',
      'BALOCAWE',
      'BANAHAO',
      'BUENAFLOR',
      'BURACAN',
      'CABANOYOAN',
      'CABRERA',
      'CADULAN',
      'CALABAD',
      'CANOMAY',
      'DIVISORIA',
      'GAID',
      'GREGORIO ALINO (PIA-ONG)',
      'MAGCARAGUIT',
      'MAMBOG',
      'POBLACION',
      'RIZAL',
      'SAN VICENTE',
      'SUBA',
      'T.R. YANGCO (YANCO)',
    ],
  },
  MANDAON: {
    province: 'MASBATE',
    municipality: 'MANDAON',
    region: 'REGION V',
    barangay_list: [
      'ALAS',
      'AYAT',
      'BAT-ONGAN',
      'BUGTONG',
      'BURI',
      'CABITAN',
      'CAGMASOSO',
      'CANOMOY',
      'CENTRO',
      'DAYAO',
      'GUINCAIPTAN',
      'LAGUINBANWA',
      'LANTANGAN',
      'LOOC',
      'MABATOBATO',
      'MAOLINGON',
      'NAILABAN',
      'NANIPSAN',
      'PINAMANGCAAN',
      'POBLACION',
      'POLO DACU',
      'SAN JUAN',
      'SAN PABLO',
      'SANTA FE',
      'TAGPU',
      'TUMALAYTAY',
    ],
  },
  'MASBATE': {
    province: 'MASBATE',
    municipality: 'MASBATE',
    region: 'REGION V',
    barangay_list: [
      'ANAS',
      'ASID',
      'B. TITONG',
      'BAGUMBAYAN',
      'BANTIGUE',
      'BAPOR (POB.)',
      'BATUHAN',
      'BAYOMBON',
      'BIYONG',
      'BOLO',
      'CAGAY',
      'CAWAYAN EXTERIOR',
      'CAWAYAN INTERIOR',
      'CENTRO (POB.)',
      'ESPINOSA',
      'F. MAGALLANES',
      'IBINGAY',
      'IGANG',
      'KALIPAY (POB.)',
      'KINAMALIGAN',
      'MALINTA',
      'MAPIÑA',
      'MAYNGARAN',
      'NURSERY',
      'PATING (POB.)',
      'PAWA',
      'SINALONGAN',
      'TUGBO',
      'UBONGAN DACU',
      'USAB',
    ],
  },
  MILAGROS: {
    province: 'MASBATE',
    municipality: 'MILAGROS',
    region: 'REGION V',
    barangay_list: [
      'BACOLOD',
      'BANGAD',
      'BARA',
      'BONBON',
      'CALASUCHE',
      'CALUMPANG (TAISAN)',
      'CAPACULAN',
      'CAYABON',
      'GUINLUTHANGAN',
      'JAMORAWON',
      'MAGSALANGI',
      'MATAGBAC',
      'MATANGLAD',
      'MATIPORON',
      'MOISES R. ESPINOSA',
      'NARANGASAN',
      'PAMANGPANGON',
      'PARAISO (POTOT)',
      'POBLACION EAST',
      'POBLACION WEST',
      'SAN ANTONIO',
      'SAN CARLOS',
      'SAWMILL',
      'TAGBON',
      'TAWAD',
      'TIGBAO',
      'TINACLIPAN (BATO)',
    ],
  },
  MOBO: {
    province: 'MASBATE',
    municipality: 'MOBO',
    region: 'REGION V',
    barangay_list: [
      'BAANG',
      'BAGACAY',
      'BALATUCAN',
      'BARAG',
      'DACU',
      'FABRICA',
      'GUINTORELAN',
      'HOLJOGON',
      'LALAGUNA',
      'LOMOCLOC',
      'LUYONG CATUNGAN',
      'MABUHAY',
      'MANDALI',
      'MAPUYO',
      'MARINTOC',
      'NASUNDUAN',
      'PINAMALATICAN',
      'PINAMARBUHAN',
      'POBLACION DIST. I',
      'POBLACION DIST. II',
      'POLOT',
      'SAMBULAWAN',
      'SANTA MARIA',
      'SAWMILL',
      'TABUC',
      'TUGAWE',
      'TUGBO',
      'UMABAY EXTERIOR',
      'UMABAY INTERIOR',
    ],
  },
  MONREAL: {
    province: 'MASBATE',
    municipality: 'MONREAL',
    region: 'REGION V',
    barangay_list: [
      'CANTORNA',
      'FAMOSA',
      'GUINHADAP',
      'MACARTHUR',
      'MAGLAMBONG',
      'MOROCBOROCAN',
      'POBLACION',
      'REAL',
      'RIZAL',
      'SANTO NIÑO',
      'TOGORON',
    ],
  },
  PALANAS: {
    province: 'MASBATE',
    municipality: 'PALANAS',
    region: 'REGION V',
    barangay_list: [
      'ANTIPOLO',
      'BANCO',
      'BIGA-A',
      'BONTOD',
      'BUENASUERTE',
      'INTUSAN',
      'JOSE A. ABENIR SR. (LIBTONG)',
      'MAANAHAO',
      'MABINI',
      'MALATAWAN',
      'MALIBAS',
      'MARAVILLA',
      'MATUGNAO',
      'MIABAS',
      'NABANGIG',
      'NIPA',
      'PARINA',
      'PIÑA',
      'POBLACION',
      'SALVACION',
      'SAN ANTONIO',
      'SAN CARLOS',
      'SAN ISIDRO',
      'SANTA CRUZ',
    ],
  },
  'PIO V. CORPUZ (LIMBUHAN)': {
    province: 'MASBATE',
    municipality: 'PIO V. CORPUZ (LIMBUHAN)',
    region: 'REGION V',
    barangay_list: [
      'ALEGRIA',
      'BUENASUERTE',
      'BUGANG',
      'BUGTONG',
      'BUNDUCAN',
      'CABANGRAYAN',
      'CALONGONGAN',
      'CASABANGAN',
      'GUINDAWAHAN',
      'LABIGAN',
      'LAMPUYANG',
      'MABUHAY',
      'PALHO',
      'POBLACION',
      'SALVACION',
      'TANQUE',
      'TUBIGAN',
      'TUBOG',
    ],
  },
  USON: {
    province: 'MASBATE',
    municipality: 'USON',
    region: 'REGION V',
    barangay_list: [
      'ARADO',
      'ARMENIA',
      'AURORA',
      'BADLING',
      'BONIFACIO',
      'BUENASUERTE',
      'BUENAVISTA',
      'CAMPANA',
      'CANDELARIA',
      'CENTRO',
      'CROSSING',
      'DAPDAP',
      'DEL CARMEN',
      'DEL ROSARIO',
      'LIBERTAD',
      'MABINI',
      'MABUHAY',
      'MADAO',
      'MAGSAYSAY',
      'MARCELLA',
      'MIAGA',
      'MONGAHAY',
      'MOROCBOROCAN',
      'PAGUIHAMAN',
      'PANICIJAN',
      'POBLACION',
      'QUEZON',
      'SAN ISIDRO',
      'SAN JOSE',
      'SAN MATEO',
      'SAN RAMON',
      'SAN VICENTE',
      'SANTO CRISTO',
      'SAWANG',
      'SIMAWA',
    ],
  },
  BARCELONA: {
    province: 'SORSOGON',
    municipality: 'BARCELONA',
    region: 'REGION V',
    barangay_list: [
      'ALEGRIA',
      'BAGACAY',
      'BANGATE',
      'BUGTONG',
      'CAGANG',
      'FABRICA',
      'JIBONG',
      'LAGO',
      'LAYOG',
      'LUNETA',
      'MACABARI',
      'MAPAPAC',
      'OLANDIA',
      'PAGHALUBAN',
      'POBLACION CENTRAL',
      'POBLACION NORTE',
      'POBLACION SUR',
      'PUTIAO',
      'SAN ANTONIO',
      'SAN ISIDRO',
      'SAN RAMON',
      'SAN VICENTE',
      'SANTA CRUZ',
      'SANTA LOURDES',
      'TAGDON',
    ],
  },
  BULAN: {
    province: 'SORSOGON',
    municipality: 'BULAN',
    region: 'REGION V',
    barangay_list: [
      'A. BONIFACIO',
      'ABAD SANTOS',
      'AGUINALDO',
      'ANTIPOLO',
      'BEGUIN',
      'BENIGNO S. AQUINO (IMELDA)',
      'BICAL',
      'BONGA',
      'BUTAG',
      'CADANDANAN',
      'CALOMAGON',
      'CALPI',
      'COCOK-CABITAN',
      'DAGANAS',
      'DANAO',
      'DOLOS',
      'E. QUIRINO',
      'FABRICA',
      'G. DEL PILAR (TANGA)',
      'GATE',
      'INARARAN',
      'J. GERONA',
      'J.P. LAUREL (PON-OD)',
      'JAMORAWON',
      'LAJONG',
      'LIBERTAD',
      'M. ROXAS',
      'MAGSAYSAY',
      'MANAGANAGA',
      'MARINAB',
      'MONTECALVARIO',
      'N. ROQUE (RIZAL)',
      'NAMO',
      'NASUJE',
      'OBRERO',
      'OSMEÑA',
      'OTAVI',
      'PADRE DIAZ',
      'PALALE',
      'QUEZON',
      'R. GERONA',
      'RECTO',
      'SAGRADA',
      'SAN FRANCISCO',
      'SAN ISIDRO',
      'SAN JUAN BAG-O',
      'SAN JUAN DAAN',
      'SAN RAFAEL',
      'SAN RAMON',
      'SAN VICENTE',
      'SANTA REMEDIOS',
      'SANTA TERESITA',
      'SIGAD',
      'SOMAGONGSONG',
      'TAROMATA',
      'ZONE I POB. (BGY. 1- SOUTH ILAWOD)',
      'ZONE II POB. (BGY. 2- WEST ILAWOD)',
      'ZONE III POB. (BGY. 3- EAST ILAWOD)',
      'ZONE IV POB. (BGY. 4- WEST CENTRAL)',
      'ZONE V POB. (BGY. 5-LANIPAN)',
      'ZONE VI POB. (BGY. 6- BAYBAY)',
      'ZONE VII POB. (BGY. 7- IRAYA)',
      'ZONE VIII POB. (BGY. 8- LOYO)',
    ],
  },
  BULUSAN: {
    province: 'SORSOGON',
    municipality: 'BULUSAN',
    region: 'REGION V',
    barangay_list: [
      'BAGACAY',
      'CENTRAL (POB.)',
      'COGON',
      'DANCALAN',
      'DAPDAP (POB.)',
      'LALUD',
      'LOOBAN (POB.)',
      'MABUHAY (POB.)',
      'MADLAWON (POB.)',
      'POCTOL (POB.)',
      'POROG',
      'SABANG (POB.)',
      'SALVACION',
      'SAN ANTONIO',
      'SAN BERNARDO',
      'SAN FRANCISCO',
      'SAN ISIDRO',
      'SAN JOSE',
      'SAN RAFAEL',
      'SAN ROQUE',
      'SAN VICENTE (BUHANG)',
      'SANTA BARBARA',
      'SAPNGAN (POB.)',
      'TINAMPO',
    ],
  },
  CASTILLA: {
    province: 'SORSOGON',
    municipality: 'CASTILLA',
    region: 'REGION V',
    barangay_list: [
      'AMOMONTING',
      'BAGALAYAG',
      'BAGONG SIRANG',
      'BONGA',
      'BUENAVISTA',
      'BURABOD',
      'CABURACAN',
      'CANJELA',
      'COGON',
      'CUMADCAD',
      'DANGCALAN',
      'DINAPA',
      'LA UNION',
      'LIBTONG',
      'LORETO',
      'MACALAYA',
      'MARACABAC',
      'MAYON',
      'MAYPANGI',
      'MILAGROSA',
      'MILUYA',
      'MONTE CARMELO',
      'ORAS',
      'PANDAN',
      'POBLACION',
      'QUIRAPI',
      'SACLAYAN',
      'SALVACION',
      'SAN ISIDRO',
      'SAN RAFAEL',
      'SAN ROQUE',
      'SAN VICENTE',
      'SOGOY',
      'TOMALAYTAY',
    ],
  },
  DONSOL: {
    province: 'SORSOGON',
    municipality: 'DONSOL',
    region: 'REGION V',
    barangay_list: [
      'ALIN',
      'AWAI',
      'BANBAN',
      'BANDI',
      'BANUANG GURANG',
      'BARAS',
      'BAYAWAS',
      'BORORAN BARANGAY 1 (POB.)',
      'CABUGAO',
      'CENTRAL BARANGAY 2 (POB.)',
      'CRISTO',
      'DANCALAN',
      'DE VERA',
      'GIMAGAAN',
      'GIRAWAN',
      'GOGON',
      'GURA',
      'JUAN ADRE',
      'LOURDES',
      'MABINI',
      'MALAPOC',
      'MALINAO',
      'MARKET SITE BARANGAY 3 (POB.)',
      'NEW MAGUISA',
      'OGOD (CROSSING)',
      'OLD MAGUISA',
      'ORANGE',
      'PANGPANG',
      'PARINA',
      'PAWALA',
      'PINAMANAAN',
      'POSO POB. (BARANGAY 5)',
      'PUNTA WALING-WALING POB. (BARANGAY 4)',
      'RAWIS',
      'SAN ANTONIO',
      'SAN ISIDRO',
      'SAN JOSE',
      'SAN RAFAEL',
      'SAN RAMON',
      'SAN VICENTE',
      'SANTA CRUZ',
      'SEVILLA',
      'SIBAGO',
      'SUGUIAN',
      'TAGBAC',
      'TINANOGAN',
      'TONGDOL',
      'TRES MARIAS',
      'TUBA',
      'TUPAS',
      'VINISITAHAN',
    ],
  },
  GUBAT: {
    province: 'SORSOGON',
    municipality: 'GUBAT',
    region: 'REGION V',
    barangay_list: [
      'ARIMAN',
      'BAGACAY',
      'BALUD DEL NORTE (POB.)',
      'BALUD DEL SUR (POB.)',
      'BENGUET',
      'BENTUCO',
      'BERIRAN',
      'BUENAVISTA',
      'BULACAO',
      'CABIGAAN',
      'CABIGUHAN',
      'CARRIEDO',
      'CASILI',
      'COGON',
      'COTA NA DACO (POB.)',
      'DITA',
      'JUPI',
      'LAPINIG',
      'LUNA-CANDOL (POB.)',
      'MANAPAO',
      'MANOOK (POB.)',
      'NAAGTAN',
      'NATO',
      'NAZARENO',
      'OGAO',
      'PACO',
      'PANGANIBAN (POB.)',
      'PARADIJON (POB.)',
      'PATAG',
      'PAYAWIN',
      'PINONTINGAN (POB.)',
      'RIZAL',
      'SAN IGNACIO',
      'SANGAT',
      'SANTA ANA',
      'TABI',
      'TAGAYTAY',
      'TIGKIW',
      'TIRIS',
      'TOGAWE',
      'UNION',
      'VILLAREAL',
    ],
  },
  IROSIN: {
    province: 'SORSOGON',
    municipality: 'IROSIN',
    region: 'REGION V',
    barangay_list: [
      'BACOLOD (POB.)',
      'BAGSANGAN',
      'BATANG',
      'BOLOS',
      'BUENAVISTA',
      'BULAWAN',
      'CARRIEDO',
      'CASINI',
      'CAWAYAN',
      'COGON',
      'GABAO',
      'GULANG-GULANG',
      'GUMAPIA',
      'LIANG',
      'MACAWAYAN',
      'MAPASO',
      'MONBON',
      'PATAG',
      'SALVACION',
      'SAN AGUSTIN (POB.)',
      'SAN ISIDRO',
      'SAN JUAN (POB.)',
      'SAN JULIAN (POB.)',
      'SAN PEDRO',
      'SANTO DOMINGO (LAMBOON)',
      'TABON-TABON',
      'TINAMPO',
      'TONGDOL',
    ],
  },
  JUBAN: {
    province: 'SORSOGON',
    municipality: 'JUBAN',
    region: 'REGION V',
    barangay_list: [
      'ANOG',
      'AROROY',
      'BACOLOD',
      'BINANUAHAN',
      'BIRIRAN',
      'BURABURAN',
      'CALATEO',
      'CALMAYON',
      'CAROHAYON',
      'CATANAGAN',
      'CATANUSAN',
      'COGON',
      'EMBARCADERO',
      'GURUYAN',
      'LAJONG',
      'MAALO',
      'NORTH POBLACION',
      'PUTING SAPA',
      'RANGAS',
      'SABLAYAN',
      'SIPAYA',
      'SOUTH POBLACION',
      'TABOC',
      'TINAGO',
      'TUGHAN',
    ],
  },
  MATNOG: {
    province: 'SORSOGON',
    municipality: 'MATNOG',
    region: 'REGION V',
    barangay_list: [
      'BALOCAWE',
      'BANOGAO',
      'BANUANGDAAN',
      'BARIIS',
      'BOLO',
      'BON-OT BIG',
      'BON-OT SMALL',
      'CABAGAHAN',
      'CALAYUAN',
      'CALINTAAN',
      'CALOOCAN (POB.)',
      'CALPI',
      'CAMACHILES (POB.)',
      'CAMCAMAN (POB.)',
      'CORON-CORON',
      'CULASI',
      'GADGARON',
      'GENABLAN OCCIDENTAL',
      'GENABLAN ORIENTAL',
      'HIDHID',
      'LABOY',
      'LAJONG',
      'MAMBAJOG',
      'MANJUNLAD',
      'MANURABI',
      'NABURACAN',
      'PAGHULIRAN',
      'PANGI',
      'PAWA',
      'POROPANDAN',
      'SANTA ISABEL',
      'SINALMACAN',
      'SINANG-ATAN',
      'SINIBARAN',
      'SISIGON',
      'SUA',
      'SULANGAN',
      'TABLAC (POB.)',
      'TABUNAN (POB.)',
      'TUGAS',
    ],
  },
  'PRIETO DIAZ': {
    province: 'SORSOGON',
    municipality: 'PRIETO DIAZ',
    region: 'REGION V',
    barangay_list: [
      'BRILLANTE (POB.)',
      'BULAWAN',
      'CALAO',
      'CARAYAT',
      'DIAMANTE',
      'GOGON',
      'LUPI',
      'MANINGCAY DE ORO',
      'MANLABONG',
      'PERLAS',
      'QUIDOLOG',
      'RIZAL',
      'SAN ANTONIO',
      'SAN FERNANDO',
      'SAN ISIDRO',
      'SAN JUAN',
      'SAN RAFAEL',
      'SAN RAMON',
      'SANTA LOURDES',
      'SANTO DOMINGO',
      'TALISAYAN',
      'TUPAZ',
      'ULAG',
    ],
  },
  'SANTA MAGDALENA': {
    province: 'SORSOGON',
    municipality: 'SANTA MAGDALENA',
    region: 'REGION V',
    barangay_list: [
      'BARANGAY POBLACION I',
      'BARANGAY POBLACION II',
      'BARANGAY POBLACION III',
      'BARANGAY POBLACION IV',
      'LA ESPERANZA',
      'PEÑAFRANCIA',
      'SALVACION',
      'SAN ANTONIO',
      'SAN BARTOLOME (TALAONGAN)',
      'SAN EUGENIO',
      'SAN ISIDRO',
      'SAN RAFAEL',
      'SAN ROQUE',
      'SAN SEBASTIAN',
    ],
  },
  'SORSOGON': {
    province: 'SORSOGON',
    municipality: 'SORSOGON',
    region: 'REGION V',
    barangay_list: [
      'ABUYOG',
      'ALMENDRAS-COGON (POB.)',
      'BALETE',
      'BALOGO',
      'BALOGO ( FR. THE MUN. OF BACON)',
      'BARAYONG',
      'BASUD',
      'BATO',
      'BIBINCAHAN',
      'BITAN-O/DALIPAY (POB.)',
      'BOGÑA',
      'BON-OT',
      'BUCALBUCALAN',
      'BUENAVISTA',
      'BUENAVISTA ( FR. THE MUN. OF BACON)',
      'BUHATAN',
      'BULABOG',
      'BURABOD (POB.)',
      'CABARBUHAN',
      'CABID-AN',
      'CAMBULAGA',
      'CAPUY',
      'CARICARAN',
      'DEL ROSARIO',
      'GATBO',
      'GIMALOTO',
      'GUINLAJON',
      'JAMISLAGAN',
      'MACABOG',
      'MARICRUM',
      'MARINAS',
      'OSIAO',
      'PAMURAYAN',
      'PANGPANG',
      'PANLAYAAN',
      'PEÑAFRANCIA',
      'PIOT (POB.)',
      'POBLACION',
      'POLVORISTA (POB.)',
      'RAWIS',
      'RIZAL',
      'SALOG (POB.)',
      'SALVACION',
      'SALVACION ( FR. THE MUN. OF BACON)',
      'SAMPALOC (POB.)',
      'SAN ISIDRO',
      'SAN ISIDRO ( FR. THE MUN. OF BACON)',
      'SAN JUAN ( FR. THE MUN. OF BACON)',
      'SAN JUAN (RORO)',
      'SAN PASCUAL',
      'SAN RAMON',
      'SAN ROQUE',
      'SAN VICENTE',
      'SANTA CRUZ',
      'SANTA LUCIA',
      'SANTO DOMINGO',
      'SANTO NIÑO',
      'SAWANGA',
      'SIRANGAN (POB.)',
      'SUGOD',
      'SULUCAN (POB.)',
      'TALISAY (POB.)',
      'TICOL',
      'TUGOS',
    ],
  },
  ALTAVAS: {
    province: 'AKLAN',
    municipality: 'ALTAVAS',
    region: 'REGION VI',
    barangay_list: [
      'CABANGILA',
      'CABUGAO',
      'CATMON',
      'DALIPDIP',
      'GINICTAN',
      'LINAYASAN',
      'LUMAYNAY',
      'LUPO',
      'MAN-UP',
      'ODIONG',
      'POBLACION',
      'QUINASAY-AN',
      'TALON',
      'TIBIAO',
    ],
  },
  BATAN: {
    province: 'AKLAN',
    municipality: 'BATAN',
    region: 'REGION VI',
    barangay_list: [
      'AMBOLONG',
      'ANGAS',
      'BAY-ANG',
      'CABUGAO',
      'CAIYANG',
      'CAMALIGAN',
      'CAMANCI',
      'IPIL',
      'LALAB',
      'LUPIT',
      'MAGPAG-ONG',
      'MAGUBAHAY',
      'MAMBUQUIAO',
      'MAN-UP',
      'MANDONG',
      'NAPTI',
      'PALAY',
      'POBLACION',
      'SONGCOLAN',
      'TABON',
    ],
  },
  BURUANGA: {
    province: 'AKLAN',
    municipality: 'BURUANGA',
    region: 'REGION VI',
    barangay_list: [
      'ALEGRIA',
      'BAGONGBAYAN',
      'BALUSBOS',
      'BEL-IS',
      'CABUGAN',
      'EL PROGRESO',
      'HABANA',
      'KATIPUNAN',
      'MAYAPAY',
      'NAZARETH',
      'PANILONGAN',
      'POBLACION',
      'SANTANDER',
      'TAG-OSIP',
      'TIGUM',
    ],
  },
  IBAJAY: {
    province: 'AKLAN',
    municipality: 'IBAJAY',
    region: 'REGION VI',
    barangay_list: [
      'AGBAGO',
      'AGDUGAYAN',
      'ANTIPOLO',
      'APARICIO',
      'AQUINO',
      'ASLUM',
      'BAGACAY',
      'BATUAN',
      'BUENAVISTA',
      'BUGTONGBATO',
      'CABUGAO',
      'CAPILIJAN',
      'COLONGCOLONG',
      'LAGUINBANUA',
      'MABUSAO',
      'MALINDOG',
      'MALOCO',
      'MINA-A',
      'MONLAQUE',
      'NAILE',
      'NAISUD',
      'NALIGUSAN',
      'ONDOY',
      'POBLACION',
      'POLO',
      'REGADOR',
      'RIVERA',
      'RIZAL',
      'SAN ISIDRO',
      'SAN JOSE',
      'SANTA CRUZ',
      'TAGBAYA',
      'TUL-ANG',
      'UNAT',
      'YAWAN',
    ],
  },
  KALIBO: {
    province: 'AKLAN',
    municipality: 'KALIBO',
    region: 'REGION VI',
    barangay_list: [
      'ANDAGAW',
      'BACHAW NORTE',
      'BACHAW SUR',
      'BRIONES',
      'BUSWANG NEW',
      'BUSWANG OLD',
      'CAANO',
      'ESTANCIA',
      'LINABUAN NORTE',
      'MABILO',
      'MOBO',
      'NALOOK',
      'POBLACION',
      'POOK',
      'TIGAYON',
      'TINIGAW',
    ],
  },
  LEZO: {
    province: 'AKLAN',
    municipality: 'LEZO',
    region: 'REGION VI',
    barangay_list: [
      'AGCAWILAN',
      'BAGTO',
      'BUGASONGAN',
      'CARUGDOG',
      'COGON',
      'IBAO',
      'MINA',
      'POBLACION',
      'SANTA CRUZ',
      'SANTA CRUZ BIGAA',
      'SILAKAT-NONOK',
      'TAYHAWAN',
    ],
  },
  LIBACAO: {
    province: 'AKLAN',
    municipality: 'LIBACAO',
    region: 'REGION VI',
    barangay_list: [
      'AGMAILIG',
      'ALFONSO XII',
      'BATOBATO',
      'BONZA',
      'CALACABIAN',
      'CALAMCAN',
      'CAN-AWAN',
      'CASIT-AN',
      'DALAGSA-AN',
      'GUADALUPE',
      'JANLUD',
      'JULITA',
      'LUCTOGA',
      'MAGUGBA',
      'MANIKA',
      'OGSIP',
      'ORTEGA',
      'OYANG',
      'PAMPANGO',
      'PINONOY',
      'POBLACION',
      'RIVERA',
      'ROSAL',
      'SIBALEW',
    ],
  },
  MADALAG: {
    province: 'AKLAN',
    municipality: 'MADALAG',
    region: 'REGION VI',
    barangay_list: [
      'ALAMINOS',
      'ALAS-AS',
      'BACYANG',
      'BALACTASAN',
      'CABANGAHAN',
      'CABILAWAN',
      'CATABANA',
      'DIT-ANA',
      'GALICIA',
      'GUINATU-AN',
      'LOGOHON',
      'MAMBA',
      'MARIA CRISTINA',
      'MEDINA',
      'MERCEDES',
      'NAPNOT',
      'PANG-ITAN',
      'PANINGAYAN',
      'PANIPIASON',
      'POBLACION',
      'SAN JOSE',
      'SINGAY',
      'TALANGBAN',
      'TALIMAGAO',
      'TIGBAWAN',
    ],
  },
  MAKATO: {
    province: 'AKLAN',
    municipality: 'MAKATO',
    region: 'REGION VI',
    barangay_list: [
      'AGBALOGO',
      'AGLUCAY',
      'ALIBAGON',
      'BAGONG BARRIO',
      'BAYBAY',
      'CABATANGA',
      'CAJILO',
      'CALANGCANG',
      'CALIMBAJAN',
      'CASTILLO',
      'CAYANGWAN',
      'DUMGA',
      'LIBANG',
      'MANTIGUIB',
      'POBLACION',
      'TIBIAWAN',
      'TINA',
      'TUGAS',
    ],
  },
  MALAY: {
    province: 'AKLAN',
    municipality: 'MALAY',
    region: 'REGION VI',
    barangay_list: [
      'ARGAO',
      'BALABAG',
      'BALUSBUS',
      'CABULIHAN',
      'CATICLAN',
      'COGON',
      'CUBAY NORTE',
      'CUBAY SUR',
      'DUMLOG',
      'MANOC-MANOC',
      'MOTAG',
      'NAASUG',
      'NABAOY',
      'NAPAAN',
      'POBLACION',
      'SAN VIRAY',
      'YAPAK',
    ],
  },
  NABAS: {
    province: 'AKLAN',
    municipality: 'NABAS',
    region: 'REGION VI',
    barangay_list: [
      'ALIMBO-BAYBAY',
      'BUENAFORTUNA',
      'BUENASUERTE',
      'BUENAVISTA',
      'GIBON',
      'HABANA',
      'LASERNA',
      'LIBERTAD',
      'MAGALLANES',
      'MATABANA',
      'NAGUSTAN',
      'PAWA',
      'PINATUAD',
      'POBLACION',
      'RIZAL',
      'SOLIDO',
      'TAGOROROC',
      'TOLEDO',
      'UNIDOS',
      'UNION',
    ],
  },
  'NEW WASHINGTON': {
    province: 'AKLAN',
    municipality: 'NEW WASHINGTON',
    region: 'REGION VI',
    barangay_list: [
      'CANDELARIA',
      'CAWAYAN',
      'DUMAGUIT',
      'FATIMA',
      'GUINBALIWAN',
      'JALAS',
      'JUGAS',
      'LAWA-AN',
      'MABILO',
      'MATAPHAO',
      'OCHANDO',
      'PINAMUK-AN',
      'POBLACION',
      'POLO',
      'PUIS',
      'TAMBAK',
    ],
  },
  NUMANCIA: {
    province: 'AKLAN',
    municipality: 'NUMANCIA',
    region: 'REGION VI',
    barangay_list: [
      'ALBASAN',
      'ALIPUTOS',
      'BADIO',
      'BUBOG',
      'BULWANG',
      'CAMANCI NORTE',
      'CAMANCI SUR',
      'DONGON EAST',
      'DONGON WEST',
      'JOYAO-JOYAO',
      'LAGUINBANUA EAST',
      'LAGUINBANUA WEST',
      'MARIANOS',
      'NAVITAS',
      'POBLACION',
      'PUSIW',
      'TABANGKA',
    ],
  },
  TANGALAN: {
    province: 'AKLAN',
    municipality: 'TANGALAN',
    region: 'REGION VI',
    barangay_list: [
      'AFGA',
      'BAYBAY',
      'DAPDAP',
      'DUMATAD',
      'JAWILI',
      'LANIPGA',
      'NAPATAG',
      'PANAYAKAN',
      'POBLACION',
      'PUDIOT',
      'TAGAS',
      'TAMALAGON',
      'TAMOKOE',
      'TONDOG',
      'VIVO',
    ],
  },
  'ANINI-Y': {
    province: 'ANTIQUE',
    municipality: 'ANINI-Y',
    region: 'REGION VI',
    barangay_list: [
      'BAYO GRANDE',
      'BAYO PEQUEÑO',
      'BUTUAN',
      'CASAY',
      'CASAY VIEJO',
      'IBA',
      'IGBARABATUAN',
      'IGPALGE',
      'IGTUMAROM',
      'LISUB A',
      'LISUB B',
      'MABUYONG',
      'MAGDALENA',
      'NASULI C',
      'NATO',
      'POBLACION',
      'SAGUA',
      'SALVACION',
      'SAN FRANCISCO',
      'SAN RAMON',
      'SAN ROQUE',
      'TAGAYTAY',
      'TALISAYAN',
    ],
  },
  BARBAZA: {
    province: 'ANTIQUE',
    municipality: 'BARBAZA',
    region: 'REGION VI',
    barangay_list: [
      'BAGHARI',
      'BAHUYAN',
      'BERI',
      'BIGA-A',
      'BINANGBANG',
      'BINANGBANG CENTRO',
      'BINANU-AN',
      'CADIAO',
      'CALAPADAN',
      'CAPOYUAN',
      'CUBAY',
      'EMBRANGGA-AN',
      'ESPARAR',
      'GUA',
      'IDAO',
      'IGPALGE',
      'IGTUNARUM',
      'INTEGASAN',
      'IPIL',
      'JINALINAN',
      'LANAS',
      'LANGCAON (EVELIO JAVIER)',
      'LISUB',
      'LOMBUYAN',
      'MABLAD',
      'MAGTULIS',
      'MARIGNE',
      'MAYABAY',
      'MAYOS',
      'NALUSDAN',
      'NARIRONG',
      'PALMA',
      'POBLACION',
      'SAN ANTONIO',
      'SAN RAMON',
      'SOLIGAO',
      'TABONGTABONG',
      'TIG-ALARAN',
      'YAPO',
    ],
  },
  BELISON: {
    province: 'ANTIQUE',
    municipality: 'BELISON',
    region: 'REGION VI',
    barangay_list: [
      'BOROCBOROC',
      'BUENAVISTA',
      'CONCEPCION',
      'DELIMA',
      'IPIL',
      'MARADIONA',
      'MOJON',
      'POBLACION',
      'ROMBANG',
      'SALVACION',
      'SINAJA',
    ],
  },
  BUGASONG: {
    province: 'ANTIQUE',
    municipality: 'BUGASONG',
    region: 'REGION VI',
    barangay_list: [
      'ANILAWAN',
      'ARANGOTE',
      'BAGTASON',
      'CAMANGAHAN',
      'CENTRO ILAWOD (POB.)',
      'CENTRO ILAYA (POB.)',
      'CENTRO POJO (POB.)',
      'CUBAY NORTH',
      'CUBAY SOUTH',
      'GUIJA',
      'IGBALANGAO',
      'IGSORO',
      'ILAURES',
      'JINALINAN',
      'LACAYON',
      'MARAY',
      'PALIWAN',
      'PANGALCAGAN',
      'SABANG EAST',
      'SABANG WEST',
      'TAGUDTUD NORTH',
      'TAGUDTUD SOUTH',
      'TALISAY',
      'TICA',
      'TONO-AN',
      'YAPU',
      'ZARAGOZA',
    ],
  },
  CALUYA: {
    province: 'ANTIQUE',
    municipality: 'CALUYA',
    region: 'REGION VI',
    barangay_list: [
      'ALEGRIA',
      'BACONG',
      'BANAGO',
      'BONBON',
      'DAWIS',
      'DIONELA',
      'HARIGUE',
      'HININGA-AN',
      'IMBA',
      'MASANAG',
      'POBLACION',
      'SABANG',
      'SALAMENTO',
      'SEMIRARA',
      'SIBATO',
      'SIBAY',
      'SIBOLO',
      'TINOGBOC',
    ],
  },
  CULASI: {
    province: 'ANTIQUE',
    municipality: 'CULASI',
    region: 'REGION VI',
    barangay_list: [
      'ALOJIPAN',
      'BAGACAY',
      'BALAC-BALAC',
      'BATBATAN ISLAND',
      'BATONAN NORTE',
      'BATONAN SUR',
      'BITA',
      'BITADTON NORTE',
      'BITADTON SUR',
      'BUENAVISTA',
      'BUHI',
      'CAMANCIJAN',
      'CARIDAD',
      'CARIT-AN',
      'CENTRO NORTE (POB.)',
      'CENTRO POBLACION',
      'CENTRO SUR (POB.)',
      'CONDES',
      'ESPERANZA',
      'FE',
      'FLORES',
      'JALANDONI',
      'JANLAGASI',
      'LAMPUTONG',
      'LIPATA',
      'MAGSAYSAY (BALUA)',
      'MALACAÑANG',
      'MALALISON ISLAND',
      'MANIGUIN',
      'NABA',
      'OSORIO',
      'PANINGAYAN',
      'SALDE',
      'SAN ANTONIO',
      'SAN GREGORIO',
      'SAN JUAN',
      'SAN LUIS',
      'SAN PASCUAL',
      'SAN VICENTE',
      'SIMBOLA',
      'TIGBOBOLO',
      'TINABUSAN',
      'TOMAO',
      'VALDERAMA',
    ],
  },
  HAMTIC: {
    province: 'ANTIQUE',
    municipality: 'HAMTIC',
    region: 'REGION VI',
    barangay_list: [
      'APDO',
      'ASLUMAN',
      'BANAWON',
      'BIA-AN',
      'BONGBONGAN I-II',
      'BONGBONGAN III',
      'BOTBOT',
      'BUDBUDAN',
      'BUHANG',
      'CALACJA I',
      'CALACJA II',
      'CALALA',
      'CANTULAN',
      'CARIDAD',
      'CAROMANGAY',
      'CASALNGAN',
      'DANGCALAN',
      'DEL PILAR',
      'FABRICA',
      'FUNDA',
      'GENERAL FULLON (TINA)',
      'GOV. EVELIO B. JAVIER (LANAG)',
      'GUINTAS',
      'IGBICAL',
      'IGBUCAGAY',
      'INABASAN',
      'INGWAN-BATANGAN',
      'LA PAZ',
      'LINABAN',
      'MALANDOG',
      'MAPATAG',
      'MASANAG',
      'NALIHAWAN',
      'PAMANDAYAN (BOTBOT)',
      'PASU-JUNGAO',
      'PIAPE I',
      'PIAPE II',
      'PIAPE III',
      'PILI 1, 2, 3',
      'POBLACION 1',
      'POBLACION 2',
      'POBLACION 3',
      'POBLACION 4',
      'POBLACION 5',
      'PU-AO',
      'SULOC',
      'VILLAVERT-JIMENEZ',
    ],
  },
  'LAUA-AN': {
    province: 'ANTIQUE',
    municipality: 'LAUA-AN',
    region: 'REGION VI',
    barangay_list: [
      'BAGONGBAYAN',
      'BANBAN',
      'BONGBONGAN',
      'CABARIWAN',
      'CADAJUG',
      'CANITUAN',
      'CAPNAYAN',
      'CASIT-AN',
      'GUIAMON',
      'GUINBANGA-AN',
      'GUISIJAN',
      'IGTADIAO',
      'INTAO',
      'JAGUIKICAN',
      'JINALINAN',
      'LACTUDAN',
      'LATAZON',
      'LAUA-AN',
      'LIBERATO',
      'LINDERO',
      'LIYA-LIYA',
      'LOON',
      'LUGTA',
      'LUPA-AN',
      'MAGYAPO',
      'MARIA',
      'MAUNO',
      'MAYBUNGA',
      'NECESITO (PANIATAN)',
      'OLOC',
      'OMLOT',
      'PANDANAN',
      'PANINGAYAN',
      'PASCUALA',
      'POBLACION (CENTRO)',
      'SAN RAMON',
      'SANTIAGO',
      'TIBACAN',
      'TIGUNHAO',
      'VIRGINIA',
    ],
  },
  PATNONGON: {
    province: 'ANTIQUE',
    municipality: 'PATNONGON',
    region: 'REGION VI',
    barangay_list: [
      'ALVAÑIZ',
      'AMPARO',
      'APGAHAN',
      'AURELIANA',
      'BADIANGAN',
      'BERNALDO A. JULAGTING (BITAS)',
      'CARIT-AN',
      'CUYAPIAO',
      'GELLA',
      'IGBARAWAN',
      'IGBOBON',
      'IGBURI',
      'LA RIOJA',
      'MABASA',
      'MACARINA',
      'MAGARANG',
      'MAGSAYSAY',
      'PADANG',
      'PANDANAN',
      'PATLABAWON',
      'POBLACION',
      'QUEZON',
      'SALAGUIAWAN',
      'SAMALAGUE',
      'SAN RAFAEL',
      'TAMAYOC',
      'TIGBALOGO',
      'TOBIAS FORNIER',
      'VILLA CRESPO',
      'VILLA CRUZ',
      'VILLA ELIO',
      'VILLA FLORES',
      'VILLA LAUA-AN',
      'VILLA SAL',
      'VILLA SALOMON',
      'VISTA ALEGRE',
    ],
  },
  'SAN REMIGIO': {
    province: 'CEBU',
    municipality: 'SAN REMIGIO',
    region: 'REGION VII',
    barangay_list: [
      'ANAPOG',
      'ARGAWANON',
      'BAGTIC',
      'BANCASAN',
      'BATAD',
      'BUSOGON',
      'CALAMBUA',
      'CANAGAHAN',
      'DAPDAP',
      'GAWAYGAWAY',
      'HAGNAYA',
      'KAYAM',
      'KINAWAHAN',
      'LAMBUSAN',
      'LAWIS',
      'LIBAONG',
      'LOOC',
      'LUYANG',
      'MANO',
      'POBLACION',
      'PUNTA',
      'SAB-A',
      'SAN MIGUEL',
      'TACUP',
      'TAMBONGON',
      'TO-ONG',
      'VICTORIA',
    ],
  },
  SEBASTE: {
    province: 'ANTIQUE',
    municipality: 'SEBASTE',
    region: 'REGION VI',
    barangay_list: [
      'ABIERA',
      'AGUILA',
      'ALEGRE',
      'ARAS-ASAN',
      'BACALAN',
      'CALLAN',
      'IDIO',
      'NAUHON',
      'P. JAVIER',
      'POBLACION',
    ],
  },
  SIBALOM: {
    province: 'ANTIQUE',
    municipality: 'SIBALOM',
    region: 'REGION VI',
    barangay_list: [
      'ALANGAN',
      'BARI',
      'BIGA-A',
      'BONGBONGAN I',
      'BONGBONGAN II',
      'BONGSOD',
      'BONTOL',
      'BUGNAY',
      'BULALACAO',
      'CABANBANAN',
      'CABARIUAN',
      'CABLADAN',
      'CADOLDOLAN',
      'CALO-OY',
      'CALOG',
      'CATMON',
      'CATUNGAN I',
      'CATUNGAN II',
      'CATUNGAN III',
      'CATUNGAN IV',
      'CUBAY-NAPULTAN',
      'CUBAY-SERMON',
      'DISTRICT I (POB.)',
      'DISTRICT II (POB.)',
      'DISTRICT III (POB.)',
      'DISTRICT IV (POB.)',
      'EGAÑA',
      'ESPERANZA I',
      'ESPERANZA II',
      'ESPERANZA III',
      'IGCOCOC',
      'IGDAGMAY',
      'IGDALAQUIT',
      'IGLANOT',
      'IGPANOLONG',
      'IGPARAS',
      'IGSUMING',
      'ILABAS',
      'IMPARAYAN',
      'INABASAN',
      'INDAG-AN',
      'INITAN',
      'INSARAYAN',
      'LACARON',
      'LAGDO',
      'LAMBAYAGAN',
      'LUNA',
      'LUYANG',
      'MAASIN',
      'MABINI',
      'MILLAMENA',
      'MOJON',
      'NAGDAYAO',
      'NAZARETH',
      'ODIONG',
      'OLAGA',
      'PANGPANG',
      'PANLAGANGAN',
      'PANTAO',
      'PASONG',
      'PIS-ANAN',
      'ROMBANG',
      'SALVACION',
      'SAN JUAN',
      'SIDO',
      'SOLONG',
      'TABONGTABONG',
      'TIG-OHOT',
      'TIGBALUA I',
      'TIGBALUA II',
      'TORDESILLAS',
      'TULATULA',
      'VALENTIN GRASPARIL (BAD-AS)',
      'VILLAFONT',
      'VILLAHERMOSA',
      'VILLAR',
    ],
  },
  TIBIAO: {
    province: 'ANTIQUE',
    municipality: 'TIBIAO',
    region: 'REGION VI',
    barangay_list: [
      'ALEGRE',
      'AMAR',
      'BANDOJA (LUPA-AN)',
      'CASTILLO',
      'ESPARAGOZA',
      'IMPORTANTE',
      'LA PAZ',
      'MALABOR',
      'MARTINEZ',
      'NATIVIDAD',
      'PITAC',
      'POBLACION',
      'SALAZAR',
      'SAN FRANCISCO NORTE',
      'SAN FRANCISCO SUR',
      'SAN ISIDRO',
      'SANTA ANA',
      'SANTA JUSTA',
      'SANTO ROSARIO',
      'TIGBABOY',
      'TUNO',
    ],
  },
  'TOBIAS FORNIER (DAO)': {
    province: 'ANTIQUE',
    municipality: 'TOBIAS FORNIER (DAO)',
    region: 'REGION VI',
    barangay_list: [
      'ABACA',
      'ARAS-ASAN',
      'AROBO',
      'ATABAY',
      'ATIOTES',
      'BAGUMBAYAN',
      'BALLOSCAS',
      'BALUD',
      'BARASANAN A',
      'BARASANAN B',
      'BARASANAN C',
      'BARIRI',
      'CAMANDAGAN',
      'CATO-OGAN',
      'DANAWAN',
      'DICLUM',
      'FATIMA',
      'GAMAD (IGDAMACIO)',
      'IGBALOGO',
      'IGBANGCAL-A',
      'IGBANGCAL-B',
      'IGBANGCAL-C',
      'IGCABUAD',
      'IGCADAC',
      'IGCADO',
      'IGCALAWAGAN',
      'IGCAPUYAS',
      'IGCASICAD (SAN PEDRO)',
      'IGDALAGUIT',
      'IGDANLOG',
      'IGDURAROG',
      'IGTUGAS',
      'LAWIGAN',
      'LINDERO',
      'MANALING (CATA-AN)',
      'MASAYO',
      'NAGSUBUAN',
      'NASULI-A (SAN RAMON)',
      'OPSAN (SAN JOSE)',
      'PACIENCIA',
      'POBLACION NORTE',
      'POBLACION SUR',
      'PORTILLO',
      'QUEZON',
      'SALAMAGUE (SANTA MARIA)',
      'SANTO TOMAS',
      'TACBUYAN',
      'TENE',
      'VILLAFLOR',
      'YSULAT',
    ],
  },
  VALDERRAMA: {
    province: 'ANTIQUE',
    municipality: 'VALDERRAMA',
    region: 'REGION VI',
    barangay_list: [
      'ALON',
      'BAKIANG',
      'BINANOGAN',
      'BOROCBOROC',
      'BUGNAY',
      'BULUANGAN I',
      'BULUANGAN II',
      'BUNSOD',
      'BUSOG',
      'CANANGHAN',
      'CANIPAYAN',
      'CANSILAYAN',
      'CULYAT',
      'IGLINAB',
      'IGMASANDIG',
      'LUBLUB',
      'MANLACBO',
      'PANDANAN',
      'SAN AGUSTIN',
      'TAKAS (POB.)',
      'TIGMAMALE',
      'UBOS (POB.)',
    ],
  },
  CUARTERO: {
    province: 'CAPIZ',
    municipality: 'CUARTERO',
    region: 'REGION VI',
    barangay_list: [
      'AGCABUGAO',
      'AGDAHON',
      'AGNAGA',
      'ANGUB',
      'BALINGASAG',
      'BITO-ON ILAWOD',
      'BITO-ON ILAYA',
      'BUN-OD',
      'CARATAYA',
      'LUNAYAN',
      'MAHABANG SAPA',
      'MAHUNODHUNOD',
      'MAINDANG',
      'MAINIT',
      'MALAGAB-I',
      'NAGBA',
      'POBLACION ILAWOD',
      'POBLACION ILAYA',
      'POBLACION TAKAS',
      'PUTI-AN',
      'SAN ANTONIO',
      'SINABSABAN',
    ],
  },
  DAO: {
    province: 'CAPIZ',
    municipality: 'DAO',
    region: 'REGION VI',
    barangay_list: [
      'AGANAN',
      'AGTAMBI',
      'AGTANGUAY',
      'BALUCUAN',
      'BITA',
      'CENTRO',
      'DAPLAS',
      'DUYOC',
      'ILAS SUR',
      'LACARON',
      'MALONOY',
      'MANHOY',
      'MAPULANG BATO',
      'MATAGNOP',
      'NASUNOGAN',
      'POBLACION ILAWOD',
      'POBLACION ILAYA',
      'QUINABCABAN',
      'QUINAYUYA',
      'SAN AGUSTIN (ILAS NORTE)',
    ],
  },
  DUMALAG: {
    province: 'CAPIZ',
    municipality: 'DUMALAG',
    region: 'REGION VI',
    barangay_list: [
      'CONCEPCION',
      'CONSOLACION',
      'DOLORES',
      'DURAN',
      'POBLACION',
      'SAN AGUSTIN',
      'SAN JOSE',
      'SAN MARTIN',
      'SAN MIGUEL',
      'SAN RAFAEL',
      'SAN ROQUE',
      'SANTA CARMEN',
      'SANTA CRUZ',
      'SANTA MONICA',
      'SANTA RITA',
      'SANTA TERESA',
      'SANTO ANGEL',
      'SANTO NIÑO',
      'SANTO ROSARIO',
    ],
  },
  DUMARAO: {
    province: 'CAPIZ',
    municipality: 'DUMARAO',
    region: 'REGION VI',
    barangay_list: [
      'AGBATUAN',
      'AGLALANA',
      'AGLANOT',
      'AGSIRAB',
      'ALIPASIAWAN',
      'ASTORGA',
      'BAYOG',
      'BUNGSUAN',
      'CALAPAWAN',
      'CODINGLE',
      'CUBI',
      'DACUTON',
      'DANGULA',
      'GIBATO',
      'GUINOTOS',
      'JAMBAD',
      'JANGUSLOB',
      'LAWAAN',
      'MALONOY',
      'NAGSULANG',
      'ONGOL ILAWOD',
      'ONGOL ILAYA',
      'POBLACION ILAWOD',
      'POBLACION ILAYA',
      'SAGRADA FAMILIA',
      'SALCEDO',
      'SAN JUAN',
      'SIBARIWAN',
      'TAMULALOD',
      'TASLAN',
      'TINA',
      'TINAYTAYAN',
      'TRACIANO',
    ],
  },
  IVISAN: {
    province: 'CAPIZ',
    municipality: 'IVISAN',
    region: 'REGION VI',
    barangay_list: [
      'AGMALOBO',
      'AGUSTIN NAVARRA',
      'BALARING',
      'BASIAO',
      'CABUGAO',
      'CUDIAN',
      'ILAYA-IVISAN',
      'MALOCLOC NORTE',
      'MALOCLOC SUR',
      'MATNOG',
      'MIANAY',
      'ONDOY',
      'POBLACION NORTE',
      'POBLACION SUR',
      'SANTA CRUZ',
    ],
  },
  JAMINDAN: {
    province: 'CAPIZ',
    municipality: 'JAMINDAN',
    region: 'REGION VI',
    barangay_list: [
      'AGAMBULONG',
      'AGBUN-OD',
      'AGCAGAY',
      'AGLIBACAO',
      'AGLOLOWAY',
      'BAYEBAYE',
      'CARIDAD',
      'ESPERANZA',
      'FE',
      'GANZON',
      'GUINTAS',
      'IGANG',
      'JAENA NORTE',
      'JAENA SUR',
      'JAGNAYA',
      'LAPAZ',
      'LINAMBASAN',
      'LUCERO',
      'MAANTOL',
      'MASGRAU',
      'MILAN',
      'MOLET',
      'PANGABAT',
      'PANGABUAN',
      'PASOL-O',
      'POBLACION',
      'SAN JOSE',
      'SAN JUAN',
      'SAN VICENTE',
      'SANTO ROSARIO',
    ],
  },
  'MA-AYON': {
    province: 'CAPIZ',
    municipality: 'MA-AYON',
    region: 'REGION VI',
    barangay_list: [
      'AGLIMOCON',
      'ALASAGING',
      'ALAYUNAN',
      'BALIGHOT',
      'BATABAT',
      'BONGBONGAN',
      'CABUNGAHAN',
      'CANAPIAN',
      'CARATAYA',
      'DULUAN',
      'EAST VILLAFLORES',
      'FERNANDEZ',
      'GUINBI-ALAN',
      'INDAYAGAN',
      'JEBACA',
      'MAALAN',
      'MANAYUPIT',
      'NEW GUIA',
      'OLD GUIA',
      'PALAGUIAN',
      'PARALLAN',
      'PIÑA',
      'POBLACION ILAWOD',
      'POBLACION ILAYA',
      'POBLACION TABUC',
      'QUEVEDO (NGALAN)',
      'QUINABONGLAN',
      'QUINAT-UYAN',
      'SALGAN',
      'TAPULANG',
      'TUBURAN',
      'WEST VILLAFLORES',
    ],
  },
  MAMBUSAO: {
    province: 'CAPIZ',
    municipality: 'MAMBUSAO',
    region: 'REGION VI',
    barangay_list: [
      'ATIPLO',
      'BALAT-AN',
      'BALIT',
      'BATIANO',
      'BATING',
      'BATO BATO',
      'BAYE',
      'BERGANTE',
      'BULA',
      'BUNGA',
      'BUNGSI',
      'BURIAS',
      'CAIDQUID',
      'CALA-AGUS',
      'LIBO-O',
      'MANIBAD',
      'MARALAG',
      'NAJUS-AN',
      'PANGPANG NORTE',
      'PANGPANG SUR',
      'PINAY',
      'POBLACION PROPER',
      'POBLACION TABUC',
      'SINONDOJAN',
      'TUGAS',
      'TUMALALUD',
    ],
  },
  PANAY: {
    province: 'CAPIZ',
    municipality: 'PANAY',
    region: 'REGION VI',
    barangay_list: [
      'AGBALO',
      'AGBANBAN',
      'AGOJO',
      'ANHAWON',
      'BAGACAY',
      'BAGO CHIQUITO',
      'BAGO GRANDE',
      'BAHIT',
      'BANTIQUE',
      'BATO',
      'BINANGIG',
      'BINANTUAN',
      'BONGA',
      'BUNTOD',
      'BUTACAL',
      'CABUGAO ESTE',
      'CABUGAO OESTE',
      'CALAPAWAN',
      'CALITAN',
      'CANDUAL',
      'COGON',
      'DAGA',
      'ILAMNAY',
      'JAMUL-AWON',
      'LANIPGA',
      'LAT-ASAN',
      'LIBON',
      'LINAO',
      'LINATERAN',
      'LOMBOY',
      'LUS-ONAN',
      'MAGUBILAN',
      'NAVITAS',
      'PAWA',
      'PILI',
      'POBLACION ILAWOD',
      'POBLACION ILAYA',
      'POBLACION TABUC',
      'TALASA',
      'TANZA NORTE',
      'TANZA SUR',
      'TICO',
    ],
  },
  PANITAN: {
    province: 'CAPIZ',
    municipality: 'PANITAN',
    region: 'REGION VI',
    barangay_list: [
      'AGBABADIANG',
      'AGKILO',
      'AGLOWAY',
      'AMBILAY',
      'BAHIT',
      'BALATUCAN',
      'BANGA-AN',
      'CABANGAHAN',
      'CABUGAO',
      'CADIO',
      'CALA-AN',
      'CAPAGAO',
      'COGON',
      'CONCIENCIA',
      'ENSENAGAN',
      'INTAMPILAN',
      'PASUGUE',
      'POBLACION ILAWOD',
      'POBLACION ILAYA',
      'QUIOS',
      'SALOCON',
      'TABUC NORTE',
      'TABUC SUR',
      'TIMPAS',
      'TINCUPON',
      'TINIGBAN',
    ],
  },
  PONTEVEDRA: {
    province: 'NEGROS OCCIDENTAL',
    municipality: 'PONTEVEDRA',
    region: 'REGION VI',
    barangay_list: [
      'ANTIPOLO',
      'BARANGAY I (POB.)',
      'BARANGAY II (POB.)',
      'BARANGAY III (POB.)',
      'BUENAVISTA GIBONG',
      'BUENAVISTA RIZAL',
      'BURGOS',
      'CAMBARUS',
      'CANROMA',
      'DON SALVADOR BENEDICTO (CARMEN)',
      'GENERAL MALVAR',
      'GOMEZ',
      'M. H. DEL PILAR',
      'MABINI',
      'MIRANDA',
      'PANDAN',
      'RECREO',
      'SAN ISIDRO',
      'SAN JUAN',
      'ZAMORA',
    ],
  },
  'ROXAS': {
    province: 'CAPIZ',
    municipality: 'ROXAS',
    region: 'REGION VI',
    barangay_list: [
      'ADLAWAN',
      'BAGO',
      'BALIJUAGAN',
      'BANICA',
      'BARRA',
      'BATO',
      'BAYBAY',
      'BOLO',
      'CABUGAO',
      'CAGAY',
      'COGON',
      'CULAJAO',
      'CULASI',
      'DAYAO',
      'DINGINAN',
      'DUMOLOG',
      'GABU-AN',
      'INZO ARNALDO VILLAGE (CADIMAHAN)',
      'JUMAGUICJIC',
      'LANOT',
      'LAWA-AN',
      'LIBAS',
      'LIONG',
      'LOCTUGAN',
      'LONOY',
      'MILIBILI',
      'MONGPONG',
      'OLOTAYAN',
      'POBLACION I (BARANGAY I)',
      'POBLACION II (BARANGAY II)',
      'POBLACION III (BARANGAY III)',
      'POBLACION IV (BARANGAY IV)',
      'POBLACION IX (BARANGAY IX)',
      'POBLACION V (BARANGAY V)',
      'POBLACION VI (BARANGAY VI)',
      'POBLACION VII (BARANGAY VII)',
      'POBLACION VIII (BARANGAY VIII)',
      'POBLACION X (BARANGAY X)',
      'POBLACION XI (BARANGAY XI)',
      'PUNTA COGON',
      'PUNTA TABUC',
      'SAN JOSE',
      'SIBAGUAN',
      'TALON',
      'TANQUE',
      'TANZA',
      'TIZA',
    ],
  },
  'SAPI-AN': {
    province: 'CAPIZ',
    municipality: 'SAPI-AN',
    region: 'REGION VI',
    barangay_list: [
      'AGSILAB',
      'AGTATACAY NORTE',
      'AGTATACAY SUR',
      'BILAO',
      'DAMAYAN',
      'DAPDAPAN',
      'LONOY',
      'MAJANLUD',
      'MANINANG',
      'POBLACION',
    ],
  },
  SIGMA: {
    province: 'CAPIZ',
    municipality: 'SIGMA',
    region: 'REGION VI',
    barangay_list: [
      'ACBO',
      'AMAGA',
      'BALUCUAN',
      'BANGONBANGON',
      'CAPUYHAN',
      'COGON',
      'DAYHAGON',
      'GUINTAS',
      'MALAPAD COGON',
      'MANGOSO',
      'MANSACUL',
      'MATANGCONG',
      'MATINABUS',
      'MIANAY',
      'OYONG',
      'PAGBUNITAN',
      'PARIAN',
      'PINAMALATICAN',
      'POBLACION NORTE',
      'POBLACION SUR',
      'TAWOG',
    ],
  },
  TAPAZ: {
    province: 'CAPIZ',
    municipality: 'TAPAZ',
    region: 'REGION VI',
    barangay_list: [
      'ABANGAY',
      'ACUÑA',
      'AGCOCOC',
      'AGLINAB',
      'AGLUPACAN',
      'AGPALALI',
      'APERO',
      'ARTUZ',
      'BAG-ONG BARRIO',
      'BATO-BATO',
      'BURI',
      'CAMBURANAN',
      'CANDELARIA',
      'CARIDA',
      'CRISTINA',
      'DA-AN BANWA',
      'DA-AN NORTE',
      'DA-AN SUR',
      'GARCIA',
      'GEBIO-AN',
      'HILWAN',
      'INITAN',
      'KATIPUNAN',
      'LAGDUNGAN',
      'LAHUG',
      'LIBERTAD',
      'MABINI',
      'MALIAO',
      'MALITBOG',
      'MINAN',
      'NAYAWAN',
      'POBLACION',
      'RIZAL NORTE',
      'RIZAL SUR',
      'ROOSEVELT',
      'ROXAS',
      'SALONG',
      'SAN ANTONIO',
      'SAN FRANCISCO',
      'SAN JOSE',
      'SAN JULIAN',
      'SAN MIGUEL ILAWOD',
      'SAN MIGUEL ILAYA',
      'SAN NICOLAS',
      'SAN PEDRO',
      'SAN ROQUE',
      'SAN VICENTE',
      'SANTA ANA',
      'SANTA PETRONILA',
      'SENONOD',
      'SIYA',
      'SWITCH',
      'TABON',
      'TACAYAN',
      'TAFT',
      'TAGANGHIN',
      'TASLAN',
      'WRIGHT',
    ],
  },
  JORDAN: {
    province: 'GUIMARAS',
    municipality: 'JORDAN',
    region: 'REGION VI',
    barangay_list: [
      'ALAGUISOC',
      'BALCON MARAVILLA',
      'BALCON MELLIZA',
      'BUGNAY',
      'BULUANGAN',
      'ESPINOSA',
      'HOSKYN',
      'LAWI',
      'MOROBUAN',
      'POBLACION',
      'RIZAL',
      'SAN MIGUEL',
      'SANTA TERESA',
      'SINAPSAPAN',
    ],
  },
  'NUEVA VALENCIA': {
    province: 'GUIMARAS',
    municipality: 'NUEVA VALENCIA',
    region: 'REGION VI',
    barangay_list: [
      'CABALAGNAN',
      'CALAYA',
      'CANHAWAN',
      'CONCORDIA SUR',
      'DOLORES',
      'GUIWANON',
      'IGANG',
      'IGDARAPDAP',
      'LA PAZ',
      'LANIPE',
      'LUCMAYAN',
      'MAGAMAY',
      'NAPANDONG',
      'ORACON SUR',
      'PANDARAONAN',
      'PANOBOLON',
      'POBLACION',
      'SALVACION',
      'SAN ANTONIO',
      'SAN ROQUE',
      'SANTO DOMINGO',
      'TANDO',
    ],
  },
  'SAN LORENZO': {
    province: 'GUIMARAS',
    municipality: 'SAN LORENZO',
    region: 'REGION VI',
    barangay_list: [
      'AGUILAR',
      'CABANO',
      'CABUNGAHAN',
      'CONSTANCIA',
      'GABAN',
      'IGCAWAYAN',
      'M. CHAVEZ',
      'SAN ENRIQUE (LEBAS)',
      'SAPAL',
      'SEBARIO',
      'SUCLARAN',
      'TAMBORONG',
    ],
  },
  SIBUNAG: {
    province: 'GUIMARAS',
    municipality: 'SIBUNAG',
    region: 'REGION VI',
    barangay_list: [
      'ALEGRIA',
      'AYANGAN',
      'BUBOG',
      'CONCORDIA',
      'DASAL',
      'INAMPOLOGAN',
      'MAABAY',
      'MILLAN',
      'ORACON',
      'RAVINA',
      'SABANG',
      'SAN ISIDRO',
      'SEBASTE',
      'TANGLAD',
    ],
  },
  AJUY: {
    province: 'ILOILO',
    municipality: 'AJUY',
    region: 'REGION VI',
    barangay_list: [
      'ADCADARAO',
      'AGBOBOLO',
      'BADIANGAN',
      'BARRIDO',
      'BATO BIASONG',
      'BAY-ANG',
      'BUCANA BUNGLAS',
      'CENTRAL',
      'CULASI',
      'LANJAGAN',
      'LUCA',
      'MALAYU-AN',
      'MANGOROCORO',
      'NASIDMAN',
      'PANTALAN NABAYE',
      'PANTALAN NAVARRO',
      'PEDADA',
      'PILI',
      'PINANTAN DIEL',
      'PINANTAN ELIZALDE',
      'PINAY ESPINOSA',
      'POBLACION',
      'PROGRESO',
      'PUENTE BUNGLAS',
      'PUNTA BURI',
      'ROJAS',
      'SAN ANTONIO',
      'SANTO ROSARIO',
      'SILAGON',
      'TAGUBANHAN',
      'TAGUHANGIN',
      'TANDUYAN',
      'TIPACLA',
      'TUBOGAN',
    ],
  },
  ALIMODIAN: {
    province: 'ILOILO',
    municipality: 'ALIMODIAN',
    region: 'REGION VI',
    barangay_list: [
      'ABANG-ABANG',
      'AGSING',
      'ATABAY',
      'BA-ONG',
      'BAGSAKAN',
      'BAGUINGIN-LANOT',
      'BAGUMBAYAN-ILAJAS',
      'BALABAGO',
      'BAN-AG',
      'BANCAL',
      'BINALUD',
      'BUGANG',
      'BUHAY',
      'BULOD',
      'CABACANAN PROPER',
      'CABACANAN RIZAL',
      'CAGAY',
      'COLINE',
      'COLINE-DALAG',
      'CUNSAD',
      'CUYAD',
      'DALID',
      'DAO',
      'GINES',
      'GINOMOY',
      'INGWAN',
      'LAYLAYAN',
      'LICO',
      'LUAN-LUAN',
      'MALAMBOY-BONDOLAN',
      'MALAMHAY',
      'MAMBAWI',
      'MANASA',
      'MANDUYOG',
      'PAJO',
      'PIANDA-AN NORTE',
      'PIANDA-AN SUR',
      'POBLACION',
      'PUNONG',
      'QUINASPAN',
      'SINAMAY',
      'SULONG',
      'TABAN-MANGUINING',
      'TABUG',
      'TARUG',
      'TUGASLON',
      'UBODAN',
      'UGBO',
      'ULAY-BUGANG',
      'ULAY-HINABLAN',
      'UMINGAN',
    ],
  },
  ANILAO: {
    province: 'ILOILO',
    municipality: 'ANILAO',
    region: 'REGION VI',
    barangay_list: [
      'AGBATUAN',
      'BADIANG',
      'BALABAG',
      'BALUNOS',
      'CAG-AN',
      'CAMIROS',
      'DANGULA-AN',
      'GUIPIS',
      'MANGANESE',
      'MEDINA',
      'MOSTRO',
      'PALAYPAY',
      'PANTALAN',
      'POBLACION',
      'SAMBAG CULOB',
      'SAN CARLOS',
      'SAN JUAN CRISOSTOMO',
      'SANTA RITA',
      'SANTO ROSARIO',
      'SERALLO',
      'VISTA ALEGRE',
    ],
  },
  BADIANGAN: {
    province: 'ILOILO',
    municipality: 'BADIANGAN',
    region: 'REGION VI',
    barangay_list: [
      'AGUSIPAN',
      'ASTORGA',
      'BINGAUAN',
      'BITA-OYAN',
      'BOTONG',
      'BUDIAWE',
      'CABANGA-AN',
      'CABAYOGAN',
      'CALANSANAN',
      'CATUBIG',
      'GUINAWAHAN',
      'ILONGBUKID',
      'INDOROHAN',
      'INILIGAN',
      'LATAWAN',
      'LINAYUAN',
      'MAINGUIT',
      'MALUBLUB',
      'MANAOLAN',
      'MAPILI GRANDE',
      'MAPILI SANJO',
      'ODIONGAN',
      'POBLACION (BADIANGAN)',
      'SAN JULIAN',
      'SARIRI',
      'SIANON',
      'SINUAGAN',
      'TALABA',
      'TAMOCOL',
      'TENECLAN',
      'TINA',
    ],
  },
  BALASAN: {
    province: 'ILOILO',
    municipality: 'BALASAN',
    region: 'REGION VI',
    barangay_list: [
      'ARANJUEZ',
      'BACOLOD',
      'BALANTI-AN',
      'BATUAN',
      'CABALIC',
      'CAMAMBUGAN',
      'DOLORES',
      'GIMAMANAY',
      'IPIL',
      'KINALKALAN',
      'LAWIS',
      'MALAPOC',
      'MAMHUT NORTE',
      'MAMHUT SUR',
      'MAYA',
      'PANI-AN',
      'POBLACION NORTE',
      'POBLACION SUR',
      'QUIASAN',
      'SALONG',
      'SALVACION',
      'TINGUI-AN',
      'ZARAGOSA',
    ],
  },
  BANATE: {
    province: 'ILOILO',
    municipality: 'BANATE',
    region: 'REGION VI',
    barangay_list: [
      'ALACAYGAN',
      'BARIGA',
      'BELEN',
      'BOBON',
      'BULARAN',
      'CARMELO',
      'DE LA PAZ',
      'DUGWAKAN',
      'FUENTES*',
      'JUANICO',
      'LIBERTAD',
      'MAGDALO',
      'MANAGOPAYA',
      'MERCED',
      'POBLACION',
      'SAN SALVADOR',
      'TALOKGANGAN',
      'ZONA SUR',
    ],
  },
  'BAROTAC NUEVO': {
    province: 'ILOILO',
    municipality: 'BAROTAC NUEVO',
    region: 'REGION VI',
    barangay_list: [
      'ACUIT',
      'AGCUYAWAN CALSADA',
      'AGCUYAWAN PULO',
      'BAGONGBONG',
      'BARAS',
      'BUNGCA',
      'CABILAUAN',
      'CRUZ',
      'GUINTAS',
      'IGBONG',
      'ILAUD POBLACION',
      'ILAYA POBLACION',
      'JALAUD',
      'LAGUBANG',
      'LANAS',
      'LICO-AN',
      'LINAO',
      'MONPON',
      'PALACIAWAN',
      'PATAG',
      'SALIHID',
      'SO-OL',
      'SOHOTON',
      'TABUC-SUBA',
      'TABUCAN',
      'TALISAY',
      'TINORIAN',
      'TIWI',
      'TUBUNGAN',
    ],
  },
  'BAROTAC VIEJO': {
    province: 'ILOILO',
    municipality: 'BAROTAC VIEJO',
    region: 'REGION VI',
    barangay_list: [
      'BUGNAY',
      'CALIFORNIA',
      'DE LA PEÑA',
      'DEL PILAR',
      'GENERAL LUNA',
      'LA FORTUNA',
      'LIPATA',
      'NATIVIDAD',
      'NUEVA INVENCION',
      'NUEVA SEVILLA',
      'POBLACION',
      'PUERTO PRINCESA',
      'RIZAL',
      'SAN ANTONIO',
      'SAN FERNANDO',
      'SAN FRANCISCO',
      'SAN GERONIMO',
      'SAN JUAN',
      'SAN LUCAS',
      'SAN MIGUEL',
      'SAN ROQUE',
      'SANTIAGO',
      'SANTO DOMINGO',
      'SANTO TOMAS',
      'UGASAN',
      'VISTA ALEGRE',
    ],
  },
  BATAD: {
    province: 'ILOILO',
    municipality: 'BATAD',
    region: 'REGION VI',
    barangay_list: [
      'ALAPASCO',
      'ALINSOLONG',
      'BANBAN',
      'BATAD VIEJO',
      'BINON-AN',
      'BOLHOG',
      'BULAK NORTE',
      'BULAK SUR',
      'CABAGOHAN',
      'CALANGAG',
      'CAW-I',
      'DRANCALAN',
      'EMBARCADERO',
      'HAMOD',
      'MALICO',
      'NANGKA',
      'PASAYAN',
      'POBLACION',
      'QUIAZAN FLORETE',
      'QUIAZAN LOPEZ',
      'SALONG',
      'SANTA ANA',
      'TANAO',
      'TAPI-AN',
    ],
  },
  BINGAWAN: {
    province: 'ILOILO',
    municipality: 'BINGAWAN',
    region: 'REGION VI',
    barangay_list: [
      'AGBA-O',
      'ALABIDHAN',
      'BULABOG',
      'CAIROHAN',
      'GUINHULACAN',
      'INAMYUNGAN',
      'MALITBOG ILAWOD',
      'MALITBOG ILAYA',
      'NGINGI-AN',
      'POBLACION',
      'QUINANGYANA',
      'QUINAR-UPAN',
      'TAPACON',
      'TUBOD',
    ],
  },
  CALINOG: {
    province: 'ILOILO',
    municipality: 'CALINOG',
    region: 'REGION VI',
    barangay_list: [
      'AGCALAGA',
      'AGLIBACAO',
      'AGLONOK',
      'ALIBUNAN',
      'BADLAN GRANDE',
      'BADLAN PEQUEÑO',
      'BADU',
      'BAJE SAN JULIAN',
      'BALATICON',
      'BANBAN GRANDE',
      'BANBAN PEQUEÑO',
      'BARRIO CALINOG',
      'BINOLOSAN GRANDE',
      'BINOLOSAN PEQUEÑO',
      'CABAGIAO',
      'CABUGAO',
      'CAHIGON',
      'CAMALONGO',
      'CANABAJAN',
      'CARATAGAN',
      'CARVASANA',
      'DALID',
      'DATAGAN',
      'GAMA GRANDE',
      'GAMA PEQUEÑO',
      'GARANGAN',
      'GUINBONYUGAN',
      'GUISO',
      'HILWAN',
      'IMPALIDAN',
      'IPIL',
      'JAMIN-AY',
      'LAMPAYA',
      'LIBOT',
      'LONOY',
      'MALAG-IT',
      'MALAGUINABOT',
      'MALAPAWE',
      'MALITBOG CENTRO',
      'MAMBIRANAN',
      'MANARIPAY',
      'MARANDIG',
      'MASAROY',
      'MASPASAN',
      'NALBUGAN',
      'OWAK',
      'POBLACION CENTRO',
      'POBLACION DELGADO',
      'POBLACION ILAYA',
      'POBLACION RIZAL ILAUD',
      'SAN NICOLAS',
      'SIMSIMAN',
      'SUPANGA',
      'TABUCAN',
      'TAHING',
      'TIBIAO',
      'TIGBAYOG',
      'TOYUNGAN',
      'ULAYAN',
    ],
  },
  CARLES: {
    province: 'ILOILO',
    municipality: 'CARLES',
    region: 'REGION VI',
    barangay_list: [
      'ABONG',
      'ALIPATA',
      'ASLUMAN',
      'BANCAL',
      'BARANGCALAN',
      'BAROSBOS',
      'BINULUANGAN',
      'BITO-ON',
      'BOLO',
      'BUAYA',
      'BUENAVISTA',
      'CABILAO GRANDE',
      'CABILAO PEQUEÑO',
      'CABUGUANA',
      'CAWAYAN',
      'DAYHAGAN',
      'GABI',
      'GRANADA',
      'GUINTICGAN',
      'ISLA DE CANA',
      'LANTANGAN',
      'MANLOT',
      'NALUMSAN',
      'PANTALAN',
      'POBLACION',
      'PUNTA (BOLOCAWE)',
      'PUNTA BATUANAN',
      'SAN FERNANDO',
      'TABUGON',
      'TALINGTING',
      'TARONG',
      'TINIGBAN',
      'TUPAZ',
    ],
  },
  'PASSI': {
    province: 'ILOILO',
    municipality: 'PASSI',
    region: 'REGION VI',
    barangay_list: [
      'AGDAHON',
      'AGDAYAO',
      'AGLALANA',
      'AGTABO',
      'AGTAMBO',
      'ALIMONO',
      'ARAC',
      'AYUYAN',
      'BACURANAN',
      'BAGACAY',
      'BATU',
      'BAYAN',
      'BITAOGAN',
      'BUENAVISTA',
      'BUYO',
      'CABUNGA',
      'CADILANG',
      'CAIROHAN',
      'DALICANAN',
      'GEGACHAC',
      'GEMAT-Y',
      'GEMUMUA-AGAHON',
      'GINES VIEJO',
      'IMBANG GRANDE',
      'JAGUIMITAN',
      'LIBO-O',
      'MAASIN',
      'MAGDUNGAO',
      'MALAG-IT GRANDE',
      'MALAG-IT PEQUEÑO',
      'MAMBIRANAN GRANDE',
      'MAMBIRANAN PEQUEÑO',
      'MAN-IT',
      'MANTULANG',
      'MULAPULA',
      'NUEVA UNION',
      'PAGAYPAY',
      'PANGI',
      'POBLACION ILAWOD',
      'POBLACION ILAYA',
      'PUNONG',
      'QUINAGARINGAN GRANDE',
      'QUINAGARINGAN PEQUEÑO',
      'SABLOGON',
      'SALNGAN',
      'SANTO TOMAS',
      'SARAPAN',
      'TAGUBONG',
      'TALONGONAN',
      'TUBOD',
      'TUBURAN',
    ],
  },
  DINGLE: {
    province: 'ILOILO',
    municipality: 'DINGLE',
    region: 'REGION VI',
    barangay_list: [
      'ABANGAY',
      'AGSALANAN',
      'AGTATACAY',
      'ALEGRIA',
      'BONGLOY',
      'BUENAVISTA',
      'CAGUYUMAN',
      'CALICUANG',
      'CAMAMBUGAN',
      'DAWIS',
      'GINALINAN NUEVO',
      'GINALINAN VIEJO',
      'GUTAO',
      'ILAJAS',
      'LIBO-O',
      'LICU-AN',
      'LINCUD',
      'MATANGHARON',
      'MOROBORO',
      'NAMATAY',
      'NAZUNI',
      'PANDAN',
      'POBLACION',
      'POTOLAN',
      'SAN JOSE',
      'SAN MATIAS',
      'SINIBA-AN',
      'TABUGON',
      'TAMBUNAC',
      'TANGHAWAN',
      'TIGUIB',
      'TINOCUAN',
      'TULATULA-AN',
    ],
  },
  DUEÑAS: {
    province: 'ILOILO',
    municipality: 'DUEÑAS',
    region: 'REGION VI',
    barangay_list: [
      'AGUTAYAN',
      'ANGARE',
      'ANJAWAN',
      'BAAC',
      'BAGONGBONG',
      'BALANGIGAN',
      'BALINGASAG',
      'BANUGAN',
      'BATUAN',
      'BITA',
      'BUENAVISTA',
      'BUGTONGAN',
      'CABUDIAN',
      'CALACA-AN',
      'CALANG',
      'CALAWINAN',
      'CAPAYCAPAY',
      'CAPULING',
      'CATIG',
      'DILA-AN',
      'FUNDACION',
      'INADLAWAN',
      'JAGDONG',
      'JAGUIMIT',
      'LACADON',
      'LUAG',
      'MALUSGOD',
      'MARIBUYONG',
      'MINANGA',
      'MONPON',
      'NAVALAS',
      'PADER',
      'PANDAN',
      'POBLACION A',
      'POBLACION B',
      'POBLACION C',
      'POBLACION D',
      'PONONG GRANDE',
      'PONONG PEQUEÑO',
      'PUROG',
      'ROMBLON',
      'SAN ISIDRO',
      'SANTO NIÑO',
      'SAWE',
      'TAMINLA',
      'TINOCUAN',
      'TIPOLO',
    ],
  },
  DUMANGAS: {
    province: 'ILOILO',
    municipality: 'DUMANGAS',
    region: 'REGION VI',
    barangay_list: [
      'AURORA-DEL PILAR (POB.)',
      'BACAY',
      'BACONG',
      'BALABAG',
      'BALUD',
      'BANTUD',
      'BANTUD FABRICA',
      'BARAS',
      'BARASAN',
      'BASA-MABINI BONIFACIO (POB.)',
      'BOLILAO',
      'BUENAFLOR EMBARKADERO (POB.)',
      'BURGOS-REGIDOR (POB.)',
      'CALAO',
      'CALI',
      'CANSILAYAN',
      'CAPALIZ',
      'CAYOS',
      'COMPAYAN',
      'DACUTAN',
      'ERMITA',
      'ILAYA 1ST',
      'ILAYA 2ND',
      'ILAYA 3RD',
      'JARDIN',
      'LACTURAN',
      'LOPEZ JAENA - RIZAL (POB.)',
      'MANAGUIT',
      'MAQUINA',
      'NANDING LOPEZ',
      'PAGDUGUE',
      'PALOC BIGQUE',
      'PALOC SOOL',
      'PATLAD',
      'PD MONFORT NORTH (LUBLUB)',
      'PD MONFORT SOUTH (GUINSAMPANAN)',
      'PULAO',
      'ROSARIO',
      'SAPAO',
      'SULANGAN',
      'TABUCAN',
      'TALUSAN',
      'TAMBOBO',
      'TAMBOILAN',
      'VICTORIAS',
    ],
  },
  ESTANCIA: {
    province: 'ILOILO',
    municipality: 'ESTANCIA',
    region: 'REGION VI',
    barangay_list: [
      'BAYAS (BAYAS ISLAND)',
      'BAYUYAN',
      'BOTONGON',
      'BULAQUEÑA',
      'CALAPDAN',
      'CANO-AN',
      'DAAN BANUA',
      'DACULAN',
      'GOGO',
      'JOLOG',
      'LOGUINGOT (LOGUINGOT ISLAND)',
      'LONOY (ROMAN MOSQUEDA)',
      'LUMBIA (ANA CUENCA)',
      'MALBOG',
      'MANIPULON',
      'PA-ON',
      'POBLACION ZONE 1',
      'POBLACION ZONE II',
      'POBLACION ZONE III',
      'SAN ROQUE',
      'SANTA ANA',
      'TABU-AN',
      'TACBUYAN',
      'TANZA',
      'VILLA PANI-AN',
    ],
  },
  GUIMBAL: {
    province: 'ILOILO',
    municipality: 'GUIMBAL',
    region: 'REGION VI',
    barangay_list: [
      'ANONO-O',
      'BACONG',
      'BAGUMBAYAN POB. (BAGUMBAYAN-GRANADA)',
      'BALANTAD-CARLOS FRUTO (POB.)',
      'BARAS',
      'BINANUA-AN',
      'BONGOL SAN MIGUEL',
      'BONGOL SAN VICENTE',
      'BULAD',
      'BULUANGAN',
      'BURGOS-GENGOS (POB.)',
      'CABASI',
      'CABUBUGAN',
      'CALAMPITAO',
      'CAMANGAHAN',
      'GENEROSA-CRISTOBAL COLON (POB.)',
      'GERONA-GIMENO (POB.)',
      'GIRADO-MAGSAYSAY (POB.)',
      'GOTERA (POB.)',
      'IGCOCOLO',
      'IYASAN',
      'LIBO-ON GONZALES (POB.)',
      'LUBACAN',
      'NAHAPAY',
      'NALUNDAN',
      'NANGA',
      'NITO-AN LUPSAG',
      'PARTICION',
      'PESCADORES (POB.)',
      'RIZAL-TUGUISAN (POB.)',
      'SANTA ROSA-LAGUNA',
      'SIPITAN-BADIANG',
      'TORREBLANCA-BLUMENTRITT (POB.)',
    ],
  },
  IGBARAS: {
    province: 'ILOILO',
    municipality: 'IGBARAS',
    region: 'REGION VI',
    barangay_list: [
      'ALAMEDA',
      'AMOROGTONG',
      'ANILAWAN',
      'BAGACAY',
      'BAGACAYAN',
      'BAGAY',
      'BALIBAGAN',
      'BARANGAY 1 POBLACION',
      'BARANGAY 2 POBLACION',
      'BARANGAY 3 POBLACION',
      'BARANGAY 4 POBLACION',
      'BARANGAY 5 POBLACION',
      'BARANGAY 6 POBLACION',
      'BARASAN',
      'BINANUA-AN',
      'BOCLOD',
      'BUENAVISTA',
      'BUGA',
      'BUGNAY',
      'CALAMPITAO',
      'CALE',
      'CATIRINGAN',
      'CORUCUAN',
      'IGCABUGAO',
      'IGPIGUS',
      'IGTALONGON',
      'INDALUYON',
      'JOVELLAR',
      'KINAGDAN',
      'LAB-ON',
      'LACAY DOL-DOL',
      'LUMANGAN',
      'LUTUNGAN',
      'MANTANGON',
      'MULANGAN',
      'PASONG',
      'PASSI',
      'PINAOPAWAN',
      'RIRO-AN',
      'SAN AMBROSIO',
      'SANTA BARBARA',
      'SIGNE',
      'TABIAC',
      'TALAYATAY',
      'TAYTAY',
      'TIGBANABA',
    ],
  },
  'ILOILO': {
    province: 'ILOILO',
    municipality: 'ILOILO',
    region: 'REGION VI',
    barangay_list: [
      'ABETO MIRASOL TAFT SOUTH (QUIRINO ABETO)',
      'AGUINALDO',
      'AIRPORT (TABUCAN AIRPORT)',
      'ALALASAN LAPUZ',
      'ARGUELLES',
      'ARSENAL ADUANA',
      'BAKHAW',
      'BALABAGO',
      'BALANTANG',
      'BALDOZA',
      'BANTUD',
      'BANUYAO',
      'BAYBAY TANZA',
      'BENEDICTO (JARO)',
      'BITO-ON',
      'BOLILAO',
      'BONIFACIO (AREVALO)',
      'BONIFACIO TANZA',
      'BUHANG',
      'BUHANG TAFT NORTH',
      'BUNTATALA',
      'BURGOS-MABINI-PLAZA',
      'CAINGIN',
      'CALAHUNAN',
      'CALAPARAN',
      'CALUBIHAN',
      'CALUMPANG',
      'CAMALIG',
      'COCHERO',
      'COMPANIA',
      'CONCEPCION-MONTES',
      'CUARTERO',
      'CUBAY',
      'DANAO',
      'DELGADO-JALANDONI-BAGUMBAYAN',
      'DEMOCRACIA',
      'DESAMPARADOS',
      'DIVINAGRACIA',
      'DON ESTEBAN-LAPUZ',
      'DULONAN',
      'DUNGON',
      'DUNGON A',
      'DUNGON B',
      'EAST BALUARTE',
      'EAST TIMAWA',
      'EDGANZON',
      'EL 98 CASTILLA (CLAUDIO LOPEZ)',
      'FAJARDO',
      'FLORES',
      'GENERAL HUGHES-MONTES',
      'GLORIA',
      'GUSTILO',
      'GUZMAN-JESENA',
      'HABOG-HABOG SALVACION',
      'HIBAO-AN NORTE',
      'HIBAO-AN SUR',
      'HINACTACAN',
      'HIPODROMO',
      'INDAY',
      'INFANTE',
      'INGORE',
      'JALANDONI ESTATE-LAPUZ',
      'JALANDONI-WILSON',
      'JAVELLANA',
      'JEREOS',
      'KAHIRUPAN',
      'KASINGKASING',
      'KATILINGBAN',
      'KAUSWAGAN',
      'LAGUDA',
      'LANIT',
      'LAPUZ NORTE',
      'LAPUZ SUR',
      'LEGASPI DELA RAMA',
      'LIBERATION',
      'LIBERTAD, SANTA ISABEL',
      'LIBERTAD-LAPUZ',
      'LOBOC-LAPUZ',
      'LOPEZ JAENA (JARO)',
      'LOPEZ JAENA NORTE',
      'LOPEZ JAENA SUR',
      'LUNA (JARO)',
      'LUNA (LA PAZ)',
      'M. V. HECHANOVA',
      'MABOLO-DELGADO',
      'MACARTHUR',
      'MAGDALO',
      'MAGSAYSAY',
      'MAGSAYSAY VILLAGE',
      'MALIPAYON-DELGADO',
      'MANSAYA-LAPUZ',
      'MARCELO H. DEL PILAR',
      'MARIA CLARA',
      'MARIA CRISTINA',
      'MOHON',
      'MOLO BOULEVARD',
      'MONICA BLUMENTRITT',
      'MONTINOLA',
      'MUELLE LONEY-MONTES',
      'NABITASAN',
      'NAVAIS',
      'NONOY',
      'NORTH AVANCEÑA',
      'NORTH BALUARTE',
      'NORTH FUNDIDOR',
      'NORTH SAN JOSE',
      'OBRERO-LAPUZ',
      'OÑATE DE LEON',
      'ORTIZ',
      'OSMEÑA',
      'OUR LADY OF FATIMA',
      'OUR LADY OF LOURDES',
      'PALE BENEDICTO RIZAL (MANDURRIAO)',
      'PHHC BLOCK 17',
      'PHHC BLOCK 22 NHA',
      'POBLACION MOLO',
      'PRESIDENT ROXAS',
      'PROGRESO-LAPUZ',
      'PUNONG-LAPUZ',
      'QUEZON',
      'QUINTIN SALAS',
      'RAILWAY',
      'RIMA-RIZAL',
      'RIZAL (LA PAZ)',
      'RIZAL ESTANZUELA',
      'RIZAL IBARRA',
      'RIZAL PALAPALA I',
      'RIZAL PALAPALA II',
      'ROXAS VILLAGE',
      'SAMBAG',
      'SAMPAGUITA',
      'SAN AGUSTIN',
      'SAN ANTONIO',
      'SAN FELIX',
      'SAN ISIDRO (JARO)',
      'SAN ISIDRO (LA PAZ)',
      'SAN JOSE (AREVALO)',
      'SAN JOSE (CITY PROPER)',
      'SAN JOSE (JARO)',
      'SAN JUAN',
      'SAN NICOLAS',
      'SAN PEDRO (JARO)',
      'SAN PEDRO (MOLO)',
      'SAN RAFAEL',
      'SAN ROQUE',
      'SAN VICENTE',
      'SANTA CRUZ',
      'SANTA FILOMENA',
      'SANTA ROSA',
      'SANTO DOMINGO',
      'SANTO NIÑO NORTE',
      'SANTO NIÑO SUR',
      'SANTO ROSARIO-DURAN',
      'SEMINARIO (BURGOS JALANDONI)',
      'SIMON LEDESMA',
      'SINIKWAY (BANGKEROHAN LAPUZ)',
      'SO-OC',
      'SOUTH BALUARTE',
      'SOUTH FUNDIDOR',
      'SOUTH SAN JOSE',
      'TAAL',
      'TABUC SUBA (JARO)',
      'TABUC SUBA (LA PAZ)',
      'TABUCAN',
      'TACAS',
      'TAGBAC',
      'TANZA-ESPERANZA',
      'TAP-OC',
      'TAYTAY ZONE II',
      'TICUD (LA PAZ)',
      'TIMAWA TANZA I',
      'TIMAWA TANZA II',
      'UNGKA',
      'VETERANS VILLAGE',
      'VILLA ANITA',
      'WEST HABOG-HABOG',
      'WEST TIMAWA',
      'YULO DRIVE',
      'YULO-ARROYO',
      'ZAMORA-MELLIZA',
    ],
  },
  JANIUAY: {
    province: 'ILOILO',
    municipality: 'JANIUAY',
    region: 'REGION VI',
    barangay_list: [
      'ABANGAY',
      'AGCAROPE',
      'AGLOBONG',
      'AGUINGAY',
      'ANHAWAN',
      'AQUINO NOBLEZA EAST (POB.)',
      'AQUINO NOBLEZA WEST (POB.)',
      'ATIMONAN',
      'BALANAC',
      'BARASALON',
      'BONGOL',
      'CABANTOG',
      'CALMAY',
      'CANAWILI',
      'CANAWILLIAN',
      'CAPT. A. TIRADOR (POB.)',
      'CARANAS',
      'CARAUDAN',
      'CARIGANGAN',
      'CONCEPCION POB. (D.G. ABORDO)',
      'CRISPIN SALAZAR NORTH (POB.)',
      'CRISPIN SALAZAR SOUTH (POB.)',
      'CUNSAD',
      'DABONG',
      'DAMIRES',
      'DAMO-ONG',
      'DANAO',
      'DON T. LUTERO CENTER (POB.)',
      'DON T. LUTERO EAST (POB.)',
      'DON T. LUTERO WEST POB. (DON T. LUTERO NORTH)',
      'GINES',
      'GOLGOTA (POB.)',
      'GUADALUPE',
      'JIBOLO',
      'KUYOT',
      'LOCSIN (POB.)',
      'MADONG',
      'MANACABAC',
      'MANGIL',
      'MATAG-UB',
      'MONTE-MAGAPA',
      'PANGILIHAN',
      'PANURAN',
      'PARARINGA',
      'PATONG-PATONG',
      'QUIPOT',
      'R. ARMADA (POB.)',
      'S. M. VILLA (POB.)',
      'SAN JULIAN (POB.)',
      'SAN PEDRO (POB.)',
      'SANTA RITA (POB.)',
      'SANTO TOMAS',
      'SARAWAG',
      'TAMBAL',
      'TAMU-AN',
      'TIRINGANAN',
      'TOLARUCAN',
      'TUBURAN',
      'UBIAN',
      'YABON',
    ],
  },
  LAMBUNAO: {
    province: 'ILOILO',
    municipality: 'LAMBUNAO',
    region: 'REGION VI',
    barangay_list: [
      'AGSIRAB',
      'AGTUMAN',
      'ALUGMAWA',
      'BADIANGAN',
      'BALAGIAO',
      'BANBAN',
      'BANSAG',
      'BAYUCO',
      'BINABA-AN ARMADA',
      'BINABA-AN LABAYNO',
      'BINABA-AN LIMOSO',
      'BINABA-AN PORTIGO',
      'BINABA-AN TIRADOR',
      'BOGONGBONG',
      'BONBON',
      'BONTOC',
      'BURI',
      'BURIRAO',
      'BUWANG',
      'CABATANGAN',
      'CABUGAO',
      'CABUNLAWAN',
      'CAGUISANAN',
      'CALOY-AHAN',
      'CANINGUAN',
      'CAPANGYAN',
      'CAYAN ESTE',
      'CAYAN OESTE',
      'COROT-ON',
      'COTO',
      'CUBAY',
      'CUNARUM',
      'DAANBANWA',
      'GINES',
      'HIPGOS',
      'JAYUBO',
      'JOROG',
      'LANOT GRANDE',
      'LANOT PEQUEÑO',
      'LEGAYADA',
      'LUMANAY',
      'MADARAG',
      'MAGBATO',
      'MAITE GRANDE',
      'MAITE PEQUEÑO',
      'MALAG-IT',
      'MANAULAN',
      'MARIBONG',
      'MARONG',
      'MISI',
      'NATIVIDAD',
      'PAJO',
      'PANDAN',
      'PANURAN',
      'PASIG',
      'PATAG',
      'POBLACION ILAWOD',
      'POBLACION ILAYA',
      'POONG',
      'PUGHANAN',
      'PUNGSOD',
      'QUILING',
      'SAGCUP',
      'SAN GREGORIO',
      'SIBACUNGAN',
      'SIBAGUAN',
      'SIMSIMAN',
      'SUPOC',
      'TAMPUCAO',
      'TRANGHAWAN',
      'TUBUNGAN',
      'TUBURAN',
      'WALANG',
    ],
  },
  LEGANES: {
    province: 'ILOILO',
    municipality: 'LEGANES',
    region: 'REGION VI',
    barangay_list: [
      'BIGKE',
      'BUNTATALA',
      'CAGAMUTAN NORTE',
      'CAGAMUTAN SUR',
      'CALABOA',
      'CAMANGAY',
      'CARI MAYOR',
      'CARI MINOR',
      'GUA-AN',
      'GUIHAMAN',
      'GUINOBATAN',
      'GUINTAS',
      'LAPAYON',
      'M.V. HECHANOVA (BALAGON)',
      'NABITASAN',
      'NAPNUD',
      'POBLACION',
      'SAN VICENTE',
    ],
  },
  LEON: {
    province: 'ILOILO',
    municipality: 'LEON',
    region: 'REGION VI',
    barangay_list: [
      'AGBOY NORTE',
      'AGBOY SUR',
      'AGTA',
      'AMBULONG',
      'ANONANG',
      'APIAN',
      'AVANZADA',
      'AWIS',
      'AYABANG',
      'AYUBO',
      'BACOLOD',
      'BAJE',
      'BANAGAN',
      'BARANGBANG',
      'BARASAN',
      'BAYAG NORTE',
      'BAYAG SUR',
      'BINOLBOG',
      'BIRI NORTE',
      'BIRI SUR',
      'BOBON',
      'BUCARI',
      'BUENAVISTA',
      'BUGA',
      'BULAD',
      'BULWANG',
      'CABOLO-AN',
      'CABUNGA-AN',
      'CABUTONGAN',
      'CAGAY',
      'CAMANDAG',
      'CAMANDO',
      'CANANAMAN',
      'CAPT. FERNANDO',
      'CARARA-AN',
      'CAROLINA',
      'CAWILIHAN',
      'COYUGAN NORTE',
      'COYUGAN SUR',
      'DANAO',
      'DOROG',
      'DUSACAN',
      'GINES',
      'GUMBOC',
      'IGCADIOS',
      'INGAY',
      'ISIAN NORTE',
      'ISIAN VICTORIA',
      'JAMOG GINES',
      'LAMPAYA',
      'LANAG',
      'LANG-OG',
      'LIGTOS',
      'LONOC',
      'MAGCAPAY',
      'MALIAO',
      'MALUBLUB',
      'MANAMPUNAY',
      'MARIRONG',
      'MINA',
      'MOCOL',
      'NAGBANGI',
      'NALBANG',
      'ODONG-ODONG',
      'OLUANGAN',
      'OMAMBONG',
      'PAGA',
      'PANDAN',
      'PANGINMAN',
      'PAOY',
      'PEPE',
      'POBLACION',
      'SALNGAN',
      'SAMLAGUE',
      'SIOL NORTE',
      'SIOL SUR',
      'TACUYONG NORTE',
      'TACUYONG SUR',
      'TAGSING',
      'TALACUAN',
      'TICUAN',
      'TINA-AN NORTE',
      'TINA-AN SUR',
      'TU-OG',
      'TUNGUAN',
    ],
  },
  MAASIN: {
    province: 'ILOILO',
    municipality: 'MAASIN',
    region: 'REGION VI',
    barangay_list: [
      'ABAY',
      'ABILAY',
      'AGROCEL POB. (AGUINALDO-ROXAS--CELSO MAYOR)',
      'AMERANG',
      'BAGACAY EAST',
      'BAGACAY WEST',
      'BOLO',
      'BUG-OT',
      'BULAY',
      'BUNTALAN',
      'BURAK',
      'CABANGCALAN',
      'CABATAC',
      'CAIGON',
      'CANANGHAN',
      'CANAWILI',
      'DAGAMI',
      'DAJA',
      'DALUSAN',
      'DELCAR POB. (DELGADO-CARTAGENA)',
      'INABASAN',
      'LAYOG',
      'LINAB',
      'LIÑAGAN CALSADA',
      'LIÑAGAN TACAS',
      'MAGSAYSAY',
      'MANDOG',
      'MARI POB. (MABINI-RIZAL)',
      'MIAPA',
      'NAGBA',
      'NASAKA',
      'NASLO-BUCAO',
      'NASULI',
      'PANALIAN',
      'PIANDAAN EAST',
      'PIANDAAN WEST',
      'PISPIS',
      'PUNONG',
      'SANTA RITA',
      'SINUBSUBAN',
      'SIWALO',
      'SUBOG',
      'THTP POB. (TAFT-THOMPSON--HUGHES-DEL PILAR)',
      'TIGBAUAN',
      'TRANGKA',
      'TUBANG',
      'TULAHONG',
      'TUY-AN EAST',
      'TUY-AN WEST',
      'UBIAN',
    ],
  },
  MIAGAO: {
    province: 'ILOILO',
    municipality: 'MIAGAO',
    region: 'REGION VI',
    barangay_list: [
      'AGDUM',
      'AGUIAUAN',
      'ALIMODIAS',
      'AWANG',
      'BACAUAN',
      'BACOLOD',
      'BAGUMBAYAN',
      'BANBANAN',
      'BANGA',
      'BANGLADAN',
      'BANUYAO',
      'BARACLAYAN',
      'BARIRI',
      'BAYBAY NORTE (POB.)',
      'BAYBAY SUR (POB.)',
      'BELEN',
      'BOLHO (POB.)',
      'BOLOCAUE',
      'BUENAVISTA NORTE',
      'BUENAVISTA SUR',
      'BUGTONG LUMANGAN',
      'BUGTONG NAULID',
      'CABALAUNAN',
      'CABANGCALAN',
      'CABUNOTAN',
      'CADOLDOLAN',
      'CAGBANG',
      'CAITIB',
      'CALAGTANGAN',
      'CALAMPITAO',
      'CAVITE',
      'CAWAYANAN',
      'CUBAY',
      'CUBAY UBOS',
      'DALIJE',
      'DAMILISAN',
      'DAWOG',
      'DIDAY',
      'DINGLE',
      'DUROG',
      'FRANTILLA',
      'FUNDACION',
      'GINES',
      'GUIBONGAN',
      'IGBITA',
      'IGBUGO',
      'IGCABIDIO',
      'IGCABITO-ON',
      'IGCATAMBOR',
      'IGDALAQUIT',
      'IGDULACA',
      'IGPAJO',
      'IGPANDAN',
      'IGPURO',
      'IGPURO-BARIRI',
      'IGSOLIGUE',
      'IGTUBA',
      'ILOG-ILOG',
      'INDAG-AN',
      'KIRAYAN NORTE',
      'KIRAYAN SUR',
      'KIRAYAN TACAS',
      'LA CONSOLACION',
      'LACADON',
      'LANUTAN',
      'LUMANGAN',
      'MABAYAN',
      'MADUYO',
      'MALAGYAN',
      'MAMBATAD',
      'MANINILA',
      'MARICOLCOL',
      'MARINGYAN',
      'MAT-Y (POB.)',
      'MATALNGON',
      'NACLUB',
      'NAM-O NORTE',
      'NAM-O SUR',
      'NARAT-AN',
      'NAROROGAN',
      'NAULID',
      'OLANGO',
      'ONGYOD',
      'ONOP',
      'OYA-OY',
      'OYUNGAN',
      'PALACA',
      'PARO-ON',
      'POTRIDO',
      'PUDPUD',
      'PUNGTOD MONTECLARO',
      'PUNGTOD NAULID',
      'SAG-ON',
      'SAN FERNANDO',
      'SAN JOSE',
      'SAN RAFAEL',
      'SAPA (MIAGAO)',
      'SARING',
      'SIBUCAO',
      'TAAL',
      'TABUNACAN',
      'TACAS (POB.)',
      'TAMBONG',
      'TAN-AGAN',
      'TATOY',
      'TICDALAN',
      'TIG-AMAGA',
      'TIG-APOG-APOG',
      'TIGBAGACAY',
      'TIGLAWA',
      'TIGMALAPAD',
      'TIGMARABO',
      'TO-OG',
      'TUGURA-AO',
      'TUMAGBOC',
      'UBOS ILAWOD (POB.)',
      'UBOS ILAYA (POB.)',
      'VALENCIA',
      'WAYANG',
    ],
  },
  MINA: {
    province: 'ILOILO',
    municipality: 'MINA',
    region: 'REGION VI',
    barangay_list: [
      'ABAT',
      'AGMANAPHAO',
      'AMIROY',
      'BADIANGAN',
      'BANGAC',
      'CABALABAGUAN',
      'CAPUL-AN',
      'DALA',
      'GUIBUANGAN',
      'JANIPA-AN EAST',
      'JANIPA-AN WEST',
      'MINA EAST (POB.)',
      'MINA WEST (POB.)',
      'NASIRUM',
      'NAUMUAN',
      'SINGAY',
      'TALIBONG GRANDE',
      'TALIBONG PEQUEÑO',
      'TIPOLO',
      'TOLARUCAN',
      'TUMAY',
      'YUGOT',
    ],
  },
  'NEW LUCENA': {
    province: 'ILOILO',
    municipality: 'NEW LUCENA',
    region: 'REGION VI',
    barangay_list: [
      'BACLAYAN',
      'BADIANG',
      'BALABAG',
      'BILIDAN',
      'BITA-OG GAJA',
      'BOLOLACAO',
      'BUROT',
      'CABILAUAN',
      'CABUGAO',
      'CAGBAN',
      'CALUMBUYAN',
      'DAMIRES',
      'DAWIS',
      'GENERAL DELGADO',
      'GUINOBATAN',
      'JANIPA-AN OESTE',
      'JELICUON ESTE',
      'JELICUON OESTE',
      'PASIL',
      'POBLACION',
      'WARI-WARI',
    ],
  },
  OTON: {
    province: 'ILOILO',
    municipality: 'OTON',
    region: 'REGION VI',
    barangay_list: [
      'ABILAY NORTE',
      'ABILAY SUR',
      'ALEGRE',
      'BATUAN ILAUD',
      'BATUAN ILAYA',
      'BITA NORTE',
      'BITA SUR',
      'BOTONG',
      'BURAY',
      'CABANBANAN',
      'CABOLOAN NORTE',
      'CABOLOAN SUR',
      'CADINGLIAN',
      'CAGBANG',
      'CALAM-ISAN',
      'GALANG',
      'LAMBUYAO',
      'MAMBOG',
      'PAKIAD',
      'POBLACION EAST',
      'POBLACION NORTH',
      'POBLACION SOUTH',
      'POBLACION WEST',
      'POLO MAESTRA BITA',
      'RIZAL',
      'SALNGAN',
      'SAMBALUDAN',
      'SAN ANTONIO',
      'SAN NICOLAS',
      'SANTA CLARA',
      'SANTA MONICA',
      'SANTA RITA',
      'TAGBAC NORTE',
      'TAGBAC SUR',
      'TRAPICHE',
      'TUBURAN',
      'TUROG-TUROG',
    ],
  },
  PAVIA: {
    province: 'ILOILO',
    municipality: 'PAVIA',
    region: 'REGION VI',
    barangay_list: [
      'AGANAN',
      'AMPARO',
      'ANILAO',
      'BALABAG',
      'CABUGAO NORTE',
      'CABUGAO SUR',
      'JIBAO-AN',
      'MALI-AO',
      'PAGSANGA-AN',
      'PAL-AGON',
      'PANDAC',
      'PUROK I (POB.)',
      'PUROK II (POB.)',
      'PUROK III (POB.)',
      'PUROK IV (POB.)',
      'TIGUM',
      'UNGKA I',
      'UNGKA II',
    ],
  },
  POTOTAN: {
    province: 'ILOILO',
    municipality: 'POTOTAN',
    region: 'REGION VI',
    barangay_list: [
      'ABANGAY',
      'AMAMAROS',
      'BAGACAY',
      'BARASAN',
      'BATUAN',
      'BONGCO',
      'CAHAGUICHICAN',
      'CALLAN',
      'CANSILAYAN',
      'CASALSAGAN',
      'CATO-OGAN',
      'CAU-AYAN',
      'CULOB',
      'DANAO',
      'DAPITAN',
      'DAWIS',
      'DONGSOL',
      'FERNANDO PARCON WARD (POB.)',
      'FUNDACION',
      'GUIBUANGAN',
      'GUINACAS',
      'IGANG',
      'INTALUAN',
      'IWA ILAUD',
      'IWA ILAYA',
      'JAMABALUD',
      'JEBIOC',
      'LAY-AHAN',
      'LOPEZ JAENA WARD (POB.)',
      'LUMBO',
      'MACATOL',
      'MALUSGOD',
      'NABITASAN',
      'NAGA',
      'NANGA',
      'NASLO',
      'PAJO',
      'PALANGUIA',
      'PITOGO',
      'POLOT-AN',
      'PRIMITIVO LEDESMA WARD (POB.)',
      'PUROG',
      'RUMBANG',
      'SAN JOSE WARD (POB.)',
      'SINUAGAN',
      'TUBURAN',
      'TUMCON ILAUD',
      'TUMCON ILAYA',
      'UBANG',
      'ZARRAGUE',
    ],
  },
  'SAN DIONISIO': {
    province: 'ILOILO',
    municipality: 'SAN DIONISIO',
    region: 'REGION VI',
    barangay_list: [
      'AGDALIRAN',
      'AMAYONG',
      'BAGACAY',
      'BATUAN',
      'BONDULAN',
      'BOROÑGON',
      'CANAS',
      'CAPINANG',
      'CUBAY',
      'CUDIONAN',
      'DUGMAN',
      'HACIENDA CONCHITA',
      'MADANLOG',
      'MANDU-AWAK',
      'MOTO',
      'NABOROT',
      'NIPA',
      'ODIONGAN',
      'PANGI',
      'PASE',
      'POBLACION',
      'SAN NICOLAS',
      'SANTOL',
      'SIEMPREVIVA',
      'SUA',
      'TALO-ATO',
      'TAMANGI',
      'TIABAS',
      'TUBLE',
    ],
  },
  'SAN ENRIQUE': {
    province: 'NEGROS OCCIDENTAL',
    municipality: 'SAN ENRIQUE',
    region: 'REGION VI',
    barangay_list: [
      'BAGONAWA',
      'BALIWAGAN',
      'BATUAN',
      'GUINTORILAN',
      'NAYON',
      'POBLACION',
      'SIBUCAO',
      'TABAO BAYBAY',
      'TABAO RIZAL',
      'TIBSOC',
    ],
  },
  'SAN JOAQUIN': {
    province: 'ILOILO',
    municipality: 'SAN JOAQUIN',
    region: 'REGION VI',
    barangay_list: [
      'AMBOYU-AN',
      'ANDRES BONIFACIO',
      'ANTALON',
      'BAD-AS',
      'BAGUMBAYAN',
      'BALABAGO',
      'BAYBAY',
      'BAYUNAN (PANDAY ORO)',
      'BOLBOGAN',
      'BONGA',
      'BUCAYA',
      'BULHO',
      'CADLUMAN',
      'CADOLDOLAN',
      'CAMABA-AN',
      'CAMIA',
      'CATA-AN',
      'CROSSING DAPUYAN',
      'CUBAY',
      'CUMARASCAS',
      'DACDACANAN',
      'DANAWAN',
      'DOLDOL',
      'DONGOC',
      'ESCALANTERA',
      'GINOT-AN',
      'GUIBONGAN BAYUNAN',
      'HUNA',
      'IGBAJE',
      'IGBANGCAL',
      'IGBINANGON',
      'IGBURI',
      'IGCABUTONG',
      'IGCADLUM',
      'IGCAPHANG',
      'IGCARATONG',
      'IGCONDAO',
      'IGCORES',
      'IGDAGMAY',
      'IGDOMINGDING',
      'IGLILICO',
      'IGPAYONG',
      'JAWOD',
      'LANGCA',
      'LANGUANAN',
      'LAWIGAN',
      'LOMBOY',
      'LOMBOYAN (SANTA ANA)',
      'LOPEZ VITO',
      'MABINI NORTE',
      'MABINI SUR',
      'MANHARA',
      'MANINILA',
      'MASAGUD',
      'MATAMBOG',
      'MAYUNOC',
      'MONTINOLA',
      'NADSADAN',
      'NAGQUIRISAN',
      'NAGSIPIT',
      'NEW GUMAWAN',
      'PANATAN',
      'PITOGO',
      'PUROK 1 (POB.)',
      'PUROK 2 (POB.)',
      'PUROK 3 (POB.)',
      'PUROK 4 (POB.)',
      'PUROK 5 (POB.)',
      'QUI-ANAN',
      'ROMA',
      'SAN LUIS',
      'SAN MATEO NORTE',
      'SAN MATEO SUR',
      'SANTA RITA',
      'SANTIAGO',
      'SINOGBUHAN',
      'SIWARAGAN',
      'TALAGUTAC',
      'TAPIKAN',
      'TASLAN',
      'TIGLAWA',
      'TIOLAS',
      'TO-OG',
      'TOROCADAN',
      'ULAY',
    ],
  },
  SARA: {
    province: 'ILOILO',
    municipality: 'SARA',
    region: 'REGION VI',
    barangay_list: [
      'AGUIRRE',
      'ALDEGUER',
      'ALIBAYOG',
      'ANORING',
      'APELO',
      'APOLOGISTA',
      'APOSAGA',
      'ARANTE',
      'ARDEMIL',
      'ASPERA',
      'ASWE-PABRIAGA',
      'BAGAYGAY',
      'BAKABAK',
      'BATITAO',
      'BATO',
      'CASTOR',
      'CRESPO',
      'DEL CASTILLO',
      'DEVERA',
      'DOMINGO',
      'FERRARIS',
      'GILDORE',
      'IMPROGO',
      'JUANEZA',
      'LABIGAN',
      'LANCIOLA',
      'LATAWAN',
      'MALAPAYA',
      'MUYCO',
      'PADIOS',
      'PASIG',
      'POBLACION ILAWOD',
      'POBLACION ILAYA',
      'POBLACION MARKET',
      'POSADAS',
      'PRECIOSA',
      'SALCEDO',
      'SAN LUIS',
      'TADY',
      'TENTAY',
      'VILLAHERMOSA',
      'ZERRUDO',
    ],
  },
  TIGBAUAN: {
    province: 'ILOILO',
    municipality: 'TIGBAUAN',
    region: 'REGION VI',
    barangay_list: [
      'ALUPIDIAN',
      'ATABAYAN',
      'BAGACAY',
      'BAGUINGIN',
      'BAGUMBAYAN',
      'BANGKAL',
      'BANTUD',
      'BARANGAY 1 (POB.)',
      'BARANGAY 2 (POB.)',
      'BARANGAY 3 (POB.)',
      'BARANGAY 4 (POB.)',
      'BARANGAY 5 (POB.)',
      'BARANGAY 6 (POB.)',
      'BARANGAY 7 (POB.)',
      'BARANGAY 8 (POB.)',
      'BARANGAY 9 (POB.)',
      'BAROSONG',
      'BARROC',
      'BAYUCO',
      'BINALIUAN MAYOR',
      'BINALIUAN MENOR',
      'BITAS',
      'BUENAVISTA',
      'BUGASONGAN',
      'BUYU-AN',
      'CANABUAN',
      'CANSILAYAN',
      'CORDOVA NORTE',
      'CORDOVA SUR',
      'DANAO',
      'DAPDAP',
      'DORONG-AN',
      'GUISIAN',
      'ISAUAN',
      'ISIAN',
      'JAMOG',
      'LANAG',
      'LINOBAYAN',
      'LUBOG',
      'NAGBA',
      'NAMOCON',
      'NAPNAPAN NORTE',
      'NAPNAPAN SUR',
      'OLO BARROC',
      'PARARA NORTE',
      'PARARA SUR',
      'SAN RAFAEL',
      'SERMON',
      'SIPITAN',
      'SUPA',
      'TAN PAEL',
      'TARO',
    ],
  },
  TUBUNGAN: {
    province: 'ILOILO',
    municipality: 'TUBUNGAN',
    region: 'REGION VI',
    barangay_list: [
      'ADGAO',
      'AGO',
      'AMBARIHON',
      'AYUBO',
      'BACAN',
      'BADIANG',
      'BAGUNANAY',
      'BALICUA',
      'BANTAYANAN',
      'BATGA',
      'BATO',
      'BIKIL',
      'BOLOC',
      'BONDOC',
      'BORONG',
      'BUENAVISTA',
      'CADABDAB',
      'DAGA-AY',
      'DESPOSORIO',
      'IGDAMPOG NORTE',
      'IGDAMPOG SUR',
      'IGPAHO',
      'IGTUBLE',
      'INGAY',
      'ISAUAN',
      'JOLASON',
      'JONA',
      'LA-AG',
      'LANAG NORTE',
      'LANAG SUR',
      'MALE',
      'MAYANG',
      'MOLINA',
      'MORCILLAS',
      'NAGBA',
      'NAVILLAN',
      'PINAMACALAN',
      'SAN JOSE',
      'SIBUCAUAN',
      'SINGON',
      'TABAT',
      'TAGPU-AN',
      'TALENTO',
      'TENIENTE BENITO',
      'VICTORIA',
      'ZONE I (POB.)',
      'ZONE II (POB.)',
      'ZONE III (POB.)',
    ],
  },
  ZARRAGA: {
    province: 'ILOILO',
    municipality: 'ZARRAGA',
    region: 'REGION VI',
    barangay_list: [
      'BALUD I',
      'BALUD II',
      'BALUD LILO-AN',
      'DAWIS CENTRO',
      'DAWIS NORTE',
      'DAWIS SUR',
      'GINES',
      'ILAWOD POBLACION',
      'ILAYA POBLACION',
      'INAGDANGAN CENTRO',
      'INAGDANGAN NORTE',
      'INAGDANGAN SUR',
      'JALAUD NORTE',
      'JALAUD SUR',
      'LIBONGCOGON',
      'MALUNANG',
      'PAJO',
      'SAMBAG',
      'SIGANGAO',
      'TALAUGUIS',
      'TALIBONG',
      'TUBIGAN',
      'TUBURAN',
      'TUBURAN SULBOD',
    ],
  },
  'BACOLOD': {
    province: 'NEGROS OCCIDENTAL',
    municipality: 'BACOLOD',
    region: 'REGION VI',
    barangay_list: [
      'ALANGILAN',
      'ALIJIS',
      'BANAGO',
      'BARANGAY 1 (POB.)',
      'BARANGAY 2 (POB.)',
      'BARANGAY 3 (POB.)',
      'BARANGAY 4 (POB.)',
      'BARANGAY 5 (POB.)',
      'BARANGAY 6 (POB.)',
      'BARANGAY 7 (POB.)',
      'BARANGAY 8 (POB.)',
      'BARANGAY 9 (POB.)',
      'BARANGAY 10 (POB.)',
      'BARANGAY 11 (POB.)',
      'BARANGAY 12 (POB.)',
      'BARANGAY 13 (POB.)',
      'BARANGAY 14 (POB.)',
      'BARANGAY 15 (POB.)',
      'BARANGAY 16 (POB.)',
      'BARANGAY 17 (POB.)',
      'BARANGAY 18 (POB.)',
      'BARANGAY 19 (POB.)',
      'BARANGAY 20 (POB.)',
      'BARANGAY 21 (POB.)',
      'BARANGAY 22 (POB.)',
      'BARANGAY 23 (POB.)',
      'BARANGAY 24 (POB.)',
      'BARANGAY 25 (POB.)',
      'BARANGAY 26 (POB.)',
      'BARANGAY 27 (POB.)',
      'BARANGAY 28 (POB.)',
      'BARANGAY 29 (POB.)',
      'BARANGAY 30 (POB.)',
      'BARANGAY 31 (POB.)',
      'BARANGAY 32 (POB.)',
      'BARANGAY 33 (POB.)',
      'BARANGAY 34 (POB.)',
      'BARANGAY 35 (POB.)',
      'BARANGAY 36 (POB.)',
      'BARANGAY 37 (POB.)',
      'BARANGAY 38 (POB.)',
      'BARANGAY 39 (POB.)',
      'BARANGAY 40 (POB.)',
      'BARANGAY 41 (POB.)',
      'BATA',
      'CABUG',
      'ESTEFANIA',
      'FELISA',
      'GRANADA',
      'HANDUMANAN',
      'MANDALAGAN',
      'MANSILINGAN',
      'MONTEVISTA',
      'PAHANOCOY',
      'PUNTA TAYTAY',
      'SINGCANG-AIRPORT',
      'SUM-AG',
      'TACULING',
      'TANGUB',
      'VILLAMONTE',
      'VISTA ALEGRE',
    ],
  },
  'BAGO': {
    province: 'NEGROS OCCIDENTAL',
    municipality: 'BAGO',
    region: 'REGION VI',
    barangay_list: [
      'ABUANAN',
      'ALIANZA',
      'ATIPULUAN',
      'BACONG-MONTILLA',
      'BAGROY',
      'BALINGASAG',
      'BINUBUHAN',
      'BUSAY',
      'CALUMANGAN',
      'CARIDAD',
      'DULAO',
      'ILIJAN',
      'JORGE L. ARANETA (MA-AO CENTRAL)',
      'LAG-ASAN',
      'MA-AO BARRIO',
      'MAILUM',
      'MALINGIN',
      'NAPOLES',
      'PACOL',
      'POBLACION',
      'SAGASA',
      'SAMPINIT',
      'TABUNAN',
      'TALOC',
    ],
  },
  BINALBAGAN: {
    province: 'NEGROS OCCIDENTAL',
    municipality: 'BINALBAGAN',
    region: 'REGION VI',
    barangay_list: [
      'AMONTAY',
      'BAGROY',
      'BI-AO',
      'CANMOROS (POB.)',
      'ENCLARO',
      'MARINA (POB.)',
      'PAGLAUM (POB.)',
      'PAYAO',
      'PROGRESO (POB.)',
      'SAN JOSE',
      'SAN JUAN (POB.)',
      'SAN PEDRO (POB.)',
      'SAN TEODORO (POB.)',
      'SAN VICENTE (POB.)',
      'SANTO ROSARIO (POB.)',
      'SANTOL',
    ],
  },
  'CADIZ': {
    province: 'NEGROS OCCIDENTAL',
    municipality: 'CADIZ',
    region: 'REGION VI',
    barangay_list: [
      'ANDRES BONIFACIO',
      'BANQUEROHAN',
      'BARANGAY 1 POB. (ZONE 1)',
      'BARANGAY 2 POB. (ZONE 2)',
      'BARANGAY 3 POB. (ZONE 3)',
      'BARANGAY 4 POB. (ZONE 4)',
      'BARANGAY 5 POB. (ZONE 5)',
      'BARANGAY 6 POB. (ZONE 6)',
      'BURGOS',
      'CABAHUG',
      'CADIZ VIEJO',
      'CADUHA-AN',
      'CELESTINO VILLACIN',
      'DAGA',
      'JERUSALEM',
      'LUNA',
      'MABINI',
      'MAGSAYSAY',
      'SICABA',
      'TIGLAWIGAN',
      'TINAMPA-AN',
      'V. F. GUSTILO',
    ],
  },
  CANDONI: {
    province: 'NEGROS OCCIDENTAL',
    municipality: 'CANDONI',
    region: 'REGION VI',
    barangay_list: [
      'AGBOY',
      'BANGA',
      'CABIA-AN',
      'CANINGAY',
      'GATUSLAO',
      'HABA',
      'PAYAUAN',
      'POBLACION EAST',
      'POBLACION WEST',
    ],
  },
  CAUAYAN: {
    province: 'NEGROS OCCIDENTAL',
    municipality: 'CAUAYAN',
    region: 'REGION VI',
    barangay_list: [
      'ABACA',
      'BACLAO',
      'BASAK',
      'BULATA',
      'CALILING',
      'CAMALANDA-AN',
      'CAMINDANGAN',
      'ELIHAN',
      'GUILJUNGAN',
      'INAYAWAN',
      'ISIO',
      'LINAON',
      'LUMBIA',
      'MAMBUGSAY',
      'MAN-ULING',
      'MASALING',
      'MOLOBOLO',
      'POBLACION',
      'SURA',
      'TALACDAN',
      'TAMBAD',
      'TILING',
      'TOMINA',
      'TUYOM',
      'YAO-YAO',
    ],
  },
  'ENRIQUE B. MAGALONA (SARAVIA)': {
    province: 'NEGROS OCCIDENTAL',
    municipality: 'ENRIQUE B. MAGALONA (SARAVIA)',
    region: 'REGION VI',
    barangay_list: [
      'ALACAYGAN',
      'ALICANTE',
      'BATEA',
      'CANLUSONG',
      'CONSING',
      'CUDANGDANG',
      'DAMGO',
      'GAHIT',
      'LATASAN',
      'MADALAG',
      'MANTA-ANGAN',
      'NANCA',
      'PASIL',
      'POBLACION I (BARANGAY 1)',
      'POBLACION II (BARANGAY 2)',
      'POBLACION III (BARANGAY 3)',
      'SAN ISIDRO',
      'SAN JOSE',
      'SANTO NIÑO',
      'TABIGUE',
      'TANZA',
      'TOMONGTONG',
      'TUBURAN',
    ],
  },
  'ESCALANTE': {
    province: 'NEGROS OCCIDENTAL',
    municipality: 'ESCALANTE',
    region: 'REGION VI',
    barangay_list: [
      'ALIMANGO',
      'BALINTAWAK (POB.)',
      'BINAGUIOHAN',
      'BUENAVISTA',
      'CERVANTES',
      'DIAN-AY',
      'HACIENDA FE',
      'JAPITAN',
      'JONOBJONOB',
      'LANGUB',
      'LIBERTAD',
      'MABINI',
      'MAGSAYSAY (BINABONGOL)',
      'MALASIBOG',
      'OLD POBLACION',
      'PAITAN',
      'PINAPUGASAN',
      'RIZAL',
      'TAMLANG',
      'UDTONGAN',
      'WASHINGTON',
    ],
  },
  'HIMAMAYLAN': {
    province: 'NEGROS OCCIDENTAL',
    municipality: 'HIMAMAYLAN',
    region: 'REGION VI',
    barangay_list: [
      'AGUISAN',
      'BARANGAY I (POB.)',
      'BARANGAY II (POB.)',
      'BARANGAY III (POB.)',
      'BARANGAY IV (POB.)',
      'BUENAVISTA',
      'CABADIANGAN',
      'CABANBANAN',
      'CARABALAN',
      'CARADIO-AN',
      'LIBACAO',
      'MAHALANG',
      'MAMBAGATON',
      'NABALI-AN',
      'SAN ANTONIO',
      'SARA-ET',
      'SU-AY',
      'TALABAN',
      'TO-OY',
    ],
  },
  HINIGARAN: {
    province: 'NEGROS OCCIDENTAL',
    municipality: 'HINIGARAN',
    region: 'REGION VI',
    barangay_list: [
      'ANAHAW',
      'ARANDA',
      'BAGA-AS',
      'BARANGAY I (POB.)',
      'BARANGAY II (POB.)',
      'BARANGAY III (POB.)',
      'BARANGAY IV (POB.)',
      'BATO',
      'CALAPI',
      'CAMALOBALO',
      'CAMBA-OG',
      'CAMBUGSA',
      'CANDUMARAO',
      'GARGATO',
      'HIMAYA',
      'MIRANDA',
      'NANUNGA',
      'NARAUIS',
      'PALAYOG',
      'PATICUI',
      'PILAR',
      'QUIWI',
      'TAGDA',
      'TUGUIS',
    ],
  },
  'HINOBA-AN (ASIA)': {
    province: 'NEGROS OCCIDENTAL',
    municipality: 'HINOBA-AN (ASIA)',
    region: 'REGION VI',
    barangay_list: [
      'ALIM',
      'ASIA',
      'BACUYANGAN',
      'BARANGAY I (POB.)',
      'BARANGAY II (POB.)',
      'BULWANGAN',
      'CULIPAPA',
      'DAMUTAN',
      'DAUG',
      'PO-OK',
      'SAN RAFAEL',
      'SANGKE',
      'TALACAGAY',
    ],
  },
  ILOG: {
    province: 'NEGROS OCCIDENTAL',
    municipality: 'ILOG',
    region: 'REGION VI',
    barangay_list: [
      'ANDULAUAN',
      'BALICOTOC',
      'BARANGAY I (POB.)',
      'BARANGAY II (POB.)',
      'BOCANA',
      'CALUBANG',
      'CANLAMAY',
      'CONSUELO',
      'DANCALAN',
      'DELICIOSO',
      'GALICIA',
      'MANALAD',
      'PINGGOT',
      'TABU',
      'VISTA ALEGRE',
    ],
  },
  ISABELA: {
    province: 'NEGROS OCCIDENTAL',
    municipality: 'ISABELA',
    region: 'REGION VI',
    barangay_list: [
      'AMIN',
      'BANOGBANOG',
      'BARANGAY 1 (POB.)',
      'BARANGAY 2 (POB.)',
      'BARANGAY 3 (POB.)',
      'BARANGAY 4 (POB.)',
      'BARANGAY 5 (POB.)',
      'BARANGAY 6 (POB.)',
      'BARANGAY 7 (POB.)',
      'BARANGAY 8 (POB.)',
      'BARANGAY 9 (POB.)',
      'BULAD',
      'BUNGAHIN',
      'CABCAB',
      'CAMANGCAMANG',
      'CAMP CLARK',
      'CANSALONGON',
      'GUINTUBHAN',
      'LIBAS',
      'LIMALIMA',
      'MAKILIGNIT',
      'MANSABLAY',
      'MAYTUBIG',
      'PANAQUIAO',
      'RIVERSIDE',
      'RUMIRANG',
      'SAN AGUSTIN',
      'SEBUCAWAN',
      'SIKATUNA',
      'TINONGAN',
    ],
  },
  'KABANKALAN': {
    province: 'NEGROS OCCIDENTAL',
    municipality: 'KABANKALAN',
    region: 'REGION VI',
    barangay_list: [
      'BANTAYAN',
      'BARANGAY 1 (POB.)',
      'BARANGAY 2 (POB.)',
      'BARANGAY 3 (POB.)',
      'BARANGAY 4 (POB.)',
      'BARANGAY 5 (POB.)',
      'BARANGAY 6 (POB.)',
      'BARANGAY 7 (POB.)',
      'BARANGAY 8 (POB.)',
      'BARANGAY 9 (POB.)',
      'BINICUIL',
      'CAMANSI',
      'CAMINGAWAN',
      'CAMUGAO',
      'CAROL-AN',
      'DAAN BANUA',
      'HILAMONAN',
      'INAPOY',
      'LINAO',
      'LOCOTAN',
      'MAGBALLO',
      'ORINGAO',
      'ORONG',
      'PINAGUINPINAN',
      'SALONG',
      'TABUGON',
      'TAGOC',
      'TAGUKON',
      'TALUBANGI',
      'TAMPALON',
      'TAN-AWAN',
      'TAPI',
    ],
  },
  'LA CARLOTA': {
    province: 'NEGROS OCCIDENTAL',
    municipality: 'LA CARLOTA',
    region: 'REGION VI',
    barangay_list: [
      'ARA-AL',
      'AYUNGON',
      'BALABAG',
      'BARANGAY I (POB.)',
      'BARANGAY II (POB.)',
      'BARANGAY III (POB.)',
      'BATUAN',
      'CUBAY',
      'HAGUIMIT',
      'LA GRANJA',
      'NAGASI',
      'ROBERTO S. BENEDICTO (CONSUELO)',
      'SAN MIGUEL',
      'YUBO',
    ],
  },
  'LA CASTELLANA': {
    province: 'NEGROS OCCIDENTAL',
    municipality: 'LA CASTELLANA',
    region: 'REGION VI',
    barangay_list: [
      'BIAKNABATO',
      'CABACUNGAN',
      'CABAGNAAN',
      'CAMANDAG',
      'LALAGSAN',
      'MANGHANOY',
      'MANSALANAO',
      'MASULOG',
      'NATO',
      'PUSO',
      'ROBLES (POB.)',
      'SAG-ANG',
      'TALAPTAP',
    ],
  },
  MANAPLA: {
    province: 'NEGROS OCCIDENTAL',
    municipality: 'MANAPLA',
    region: 'REGION VI',
    barangay_list: [
      'BARANGAY I (POB.)',
      'BARANGAY I-A (POB.)',
      'BARANGAY I-B (POB.)',
      'BARANGAY II (POB.)',
      'BARANGAY II-A (POB.)',
      'CHAMBERY',
      'PUNTA MESA',
      'PUNTA SALONG',
      'PURISIMA',
      'SAN PABLO',
      'SANTA TERESA',
      'TORTOSA',
    ],
  },
  'MOISES PADILLA (MAGALLON)': {
    province: 'NEGROS OCCIDENTAL',
    municipality: 'MOISES PADILLA (MAGALLON)',
    region: 'REGION VI',
    barangay_list: [
      'BARANGAY 1 (POB.)',
      'BARANGAY 2 (POB.)',
      'BARANGAY 3 (POB.)',
      'BARANGAY 4 (POB.)',
      'BARANGAY 5 (POB.)',
      'BARANGAY 6 (POB.)',
      'BARANGAY 7 (POB.)',
      'CROSSING MAGALLON',
      'GUINPANA-AN',
      'INOLINGAN',
      'MACAGAHAY',
      'MAGALLON CADRE',
      'MONTILLA',
      'ODIONG',
      'QUINTIN REMO',
    ],
  },
  MURCIA: {
    province: 'NEGROS OCCIDENTAL',
    municipality: 'MURCIA',
    region: 'REGION VI',
    barangay_list: [
      'ABO-ABO',
      'ALEGRIA',
      'AMAYCO',
      'BLUMENTRITT',
      'BUENAVISTA',
      'CALIBAN',
      'CANLANDOG',
      'CANSILAYAN',
      'DAMSITE',
      'IGLAU-AN',
      'LOPEZ JAENA',
      'MINOYAN',
      'PANDANON',
      'SALVACION',
      'SAN MIGUEL',
      'SANTA CRUZ',
      'SANTA ROSA',
      'TALOTOG',
      'ZONE I (POB.)',
      'ZONE II (POB.)',
      'ZONE III (POB.)',
      'ZONE IV (POB.)',
      'ZONE V (POB.)',
    ],
  },
  PULUPANDAN: {
    province: 'NEGROS OCCIDENTAL',
    municipality: 'PULUPANDAN',
    region: 'REGION VI',
    barangay_list: [
      'BARANGAY ZONE 1 (POB.)',
      'BARANGAY ZONE 1-A (POB.)',
      'BARANGAY ZONE 2 (POB.)',
      'BARANGAY ZONE 3 (POB.)',
      'BARANGAY ZONE 4 (POB.)',
      'BARANGAY ZONE 4-A (POB.)',
      'BARANGAY ZONE 5 (POB.)',
      'BARANGAY ZONE 6 (POB.)',
      'BARANGAY ZONE 7 (POB.)',
      'CANJUSA',
      'CROSSING PULUPANDAN',
      'CULO',
      'MABINI',
      'PAG-AYON',
      'PALAKA NORTE',
      'PALAKA SUR',
      'PATIC',
      'TAPONG',
      'UBAY',
      'UTOD',
    ],
  },
  'SAGAY': {
    province: 'NEGROS OCCIDENTAL',
    municipality: 'SAGAY',
    region: 'REGION VI',
    barangay_list: [
      'ANDRES BONIFACIO',
      'BATO',
      'BAVIERA',
      'BULANON',
      'CAMPO HIMOGA-AN',
      'CAMPO SANTIAGO',
      'COLONIA DIVINA',
      'FABRICA',
      'GENERAL LUNA',
      'HIMOGA-AN BAYBAY',
      'LOPEZ JAENA',
      'MAKILING',
      'MALUBON',
      'MOLOCABOC',
      'OLD SAGAY',
      'PARAISO',
      'PLARIDEL',
      'POBLACION I (BARANGAY 1)',
      'POBLACION II (BARANGAY 2)',
      'PUEY',
      'RAFAELA BARRERA',
      'RIZAL',
      'TABA-AO',
      'TADLONG',
      'VITO',
    ],
  },
  'SALVADOR BENEDICTO': {
    province: 'NEGROS OCCIDENTAL',
    municipality: 'SALVADOR BENEDICTO',
    region: 'REGION VI',
    barangay_list: [
      'BAGO (LALUNG)',
      'BAGONG SILANG (MARCELO)',
      'BUNGA',
      'IGMAYA-AN',
      'KUMALISKIS',
      'PANDANON',
      'PINOWAYAN (PROSPERIDAD)',
    ],
  },
  'SILAY': {
    province: 'NEGROS OCCIDENTAL',
    municipality: 'SILAY',
    region: 'REGION VI',
    barangay_list: [
      'BAGTIC',
      'BALARING',
      'BARANGAY I (POB.)',
      'BARANGAY II (POB.)',
      'BARANGAY III (POB.)',
      'BARANGAY IV (POB.)',
      'BARANGAY V (POB.)',
      'BARANGAY VI POB. (HAWAIIAN)',
      'EUSTAQUIO LOPEZ',
      'GUIMBALA-ON',
      'GUINHALARAN',
      'KAPITAN RAMON',
      'LANTAD',
      'MAMBULAC',
      'PATAG',
      'RIZAL',
    ],
  },
  'SIPALAY': {
    province: 'NEGROS OCCIDENTAL',
    municipality: 'SIPALAY',
    region: 'REGION VI',
    barangay_list: [
      'BARANGAY 1 (POB.)',
      'BARANGAY 2 (POB.)',
      'BARANGAY 3 (POB.)',
      'BARANGAY 4 (POB.)',
      'BARANGAY 5 (POB.)',
      'CABADIANGAN',
      'CAMINDANGAN',
      'CANTURAY',
      'CARTAGENA',
      'CAYHAGAN',
      'GIL MONTILLA',
      'MAMBAROTO',
      'MANLUCAHOC',
      'MARICALUM',
      'NABULAO',
      'NAUHANG',
      'SAN JOSE',
    ],
  },
  'TALISAY': {
    province: 'CEBU',
    municipality: 'TALISAY',
    region: 'REGION VII',
    barangay_list: [
      'BIASONG',
      'BULACAO',
      'CADULAWAN',
      'CAMP IV',
      'CANSOJONG',
      'DUMLOG',
      'JACLUPAN',
      'LAGTANG',
      'LAWAAN I',
      'LAWAAN II',
      'LAWAAN III',
      'LINAO',
      'MAGHAWAY',
      'MANIPIS',
      'MOHON',
      'POBLACION',
      'POOC',
      'SAN ISIDRO',
      'SAN ROQUE',
      'TABUNOC',
      'TANGKE',
      'TAPUL',
    ],
  },
  TOBOSO: {
    province: 'NEGROS OCCIDENTAL',
    municipality: 'TOBOSO',
    region: 'REGION VI',
    barangay_list: [
      'BANDILA',
      'BUG-ANG',
      'GENERAL LUNA',
      'MAGTICOL',
      'POBLACION',
      'SALAMANCA',
      'SAN ISIDRO',
      'SAN JOSE',
      'TABUN-AC',
    ],
  },
  VALLADOLID: {
    province: 'NEGROS OCCIDENTAL',
    municipality: 'VALLADOLID',
    region: 'REGION VI',
    barangay_list: [
      'ALIJIS',
      'AYUNGON',
      'BAGUMBAYAN',
      'BATUAN',
      'BAYABAS',
      'CENTRAL TABAO',
      'DOLDOL',
      'GUINTORILAN',
      'LACARON',
      'MABINI',
      'PACOL',
      'PALAKA',
      'PALOMA',
      'POBLACION',
      'SAGUA BANUA',
      'TABAO PROPER',
    ],
  },
  'VICTORIAS': {
    province: 'NEGROS OCCIDENTAL',
    municipality: 'VICTORIAS',
    region: 'REGION VI',
    barangay_list: [
      'BARANGAY I (POB.)',
      'BARANGAY II (POB.)',
      'BARANGAY III (POB.)',
      'BARANGAY IV (POB.)',
      'BARANGAY IX (DAAN BANWA)',
      'BARANGAY V (POB.)',
      'BARANGAY VI (POB.)',
      'BARANGAY VI-A',
      'BARANGAY VII (POB.)',
      'BARANGAY VIII (POB.)',
      'BARANGAY X (ESTADO)',
      'BARANGAY XI (GAWAHON)',
      'BARANGAY XII',
      'BARANGAY XIII',
      'BARANGAY XIV',
      'BARANGAY XIX',
      'BARANGAY XIX-A',
      'BARANGAY XV',
      'BARANGAY XV-A',
      'BARANGAY XVI',
      'BARANGAY XVI-A',
      'BARANGAY XVII',
      'BARANGAY XVIII',
      'BARANGAY XVIII-A',
      'BARANGAY XX',
      'BARANGAY XXI',
    ],
  },
  ALBURQUERQUE: {
    province: 'BOHOL',
    municipality: 'ALBURQUERQUE',
    region: 'REGION VII',
    barangay_list: [
      'BAHI',
      'BASACDACU',
      'CANTIGUIB',
      'DANGAY',
      'EAST POBLACION',
      'PONONG',
      'SAN AGUSTIN',
      'SANTA FILOMENA',
      'TAGBUANE',
      'TORIL',
      'WEST POBLACION',
    ],
  },
  ANTEQUERA: {
    province: 'BOHOL',
    municipality: 'ANTEQUERA',
    region: 'REGION VII',
    barangay_list: [
      'ANGILAN',
      'BANTOLINAO',
      'BICAHAN',
      'BITAUGAN',
      'BUNGAHAN',
      'CAN-OMAY',
      'CANLAAS',
      'CANSIBUAN',
      'CELING',
      'DANAO',
      'DANICOP',
      'MAG-ASO',
      'POBLACION',
      'QUINAPON-AN',
      'SANTO ROSARIO',
      'TABUAN',
      'TAGUBAAS',
      'TUPAS',
      'UBOJAN',
      'VIGA',
      'VILLA AURORA (CANOC-OC)',
    ],
  },
  BACLAYON: {
    province: 'BOHOL',
    municipality: 'BACLAYON',
    region: 'REGION VII',
    barangay_list: [
      'BUENAVENTURA',
      'CAMBANAC',
      'DASITAM',
      'GUIWANON',
      'LANDICAN',
      'LAYA',
      'LIBERTAD',
      'MONTANA',
      'PAMILACAN',
      'PAYAHAN',
      'POBLACION',
      'SAN ISIDRO',
      'SAN ROQUE',
      'SAN VICENTE',
      'SANTA CRUZ',
      'TAGUIHON',
      'TANDAY',
    ],
  },
  BALILIHAN: {
    province: 'BOHOL',
    municipality: 'BALILIHAN',
    region: 'REGION VII',
    barangay_list: [
      'BAUCAN NORTE',
      'BAUCAN SUR',
      'BOCTOL',
      'BOYOG NORTE',
      'BOYOG PROPER',
      'BOYOG SUR',
      'CABAD',
      'CANDASIG',
      'CANTALID',
      'CANTOMIMBO',
      'COGON',
      'DATAG NORTE',
      'DATAG SUR',
      'DEL CARMEN ESTE (POB.)',
      'DEL CARMEN NORTE (POB.)',
      'DEL CARMEN SUR (POB.)',
      'DEL CARMEN WESTE (POB.)',
      'DEL ROSARIO',
      'DOROL',
      'HAGUILANAN GRANDE',
      'HANOPOL ESTE',
      'HANOPOL NORTE',
      'HANOPOL WESTE',
      'MAGSIJA',
      'MASLOG',
      'SAGASA',
      'SAL-ING',
      'SAN ISIDRO',
      'SAN ROQUE',
      'SANTO NIÑO',
      'TAGUSTUSAN',
    ],
  },
  'BIEN UNIDO': {
    province: 'BOHOL',
    municipality: 'BIEN UNIDO',
    region: 'REGION VII',
    barangay_list: [
      'BILANGBILANGAN DAKO',
      'BILANGBILANGAN DIOT',
      'HINGOTANAN EAST',
      'HINGOTANAN WEST',
      'LIBERTY',
      'MALINGIN',
      'MANDAWA',
      'MAOMAWAN',
      'NUEVA ESPERANZA',
      'NUEVA ESTRELLA',
      'PINAMGO',
      'POBLACION (BIEN UNIDO)',
      'PUERTO SAN PEDRO',
      'SAGASA',
      'TUBORAN',
    ],
  },
  BILAR: {
    province: 'BOHOL',
    municipality: 'BILAR',
    region: 'REGION VII',
    barangay_list: [
      'BONIFACIO',
      'BUGANG NORTE',
      'BUGANG SUR',
      'CABACNITAN (MAGSAYSAY)',
      'CAMBIGSI',
      'CAMPAGAO',
      'CANSUMBOL',
      'DAGOHOY',
      'OWAC',
      'POBLACION',
      'QUEZON',
      'RIVERSIDE',
      'RIZAL',
      'ROXAS',
      'SUBAYON',
      'VILLA AURORA',
      'VILLA SUERTE',
      'YANAYA',
      'ZAMORA',
    ],
  },
  CALAPE: {
    province: 'BOHOL',
    municipality: 'CALAPE',
    region: 'REGION VII',
    barangay_list: [
      'ABUCAYAN NORTE',
      'ABUCAYAN SUR',
      'BANLASAN',
      'BENTIG',
      'BINOGAWAN',
      'BONBON',
      'CABAYUGAN',
      'CABUDBURAN',
      'CALUNASAN',
      'CAMIAS',
      'CANGUHA',
      'CATMONAN',
      'DESAMPARADOS (POB.)',
      'KAHAYAG',
      'KINABAG-AN',
      'LABUON',
      'LAWIS',
      'LIBORON',
      'LO-OC',
      'LOMBOY',
      'LUCOB',
      'MADANGOG',
      'MAGTONGTONG',
      'MANDAUG',
      'MANTATAO',
      'SAMPOANGON',
      'SAN ISIDRO',
      'SANTA CRUZ (POB.)',
      'SOJOTON',
      'TALISAY',
      'TINIBGAN',
      'TULTUGAN',
      'ULBUJAN',
    ],
  },
  CANDIJAY: {
    province: 'BOHOL',
    municipality: 'CANDIJAY',
    region: 'REGION VII',
    barangay_list: [
      'ABIHILAN',
      'ANOLING',
      'BOYO-AN',
      'CADAPDAPAN',
      'CAMBANE',
      'CAN-OLIN',
      'CANAWA',
      'COGTONG',
      'LA UNION',
      'LUAN',
      'LUNGSODA-AN',
      'MAHANGIN',
      'PAGAHAT',
      'PANADTARAN',
      'PANAS',
      'POBLACION',
      'SAN ISIDRO',
      'TAMBONGAN',
      'TAWID',
      'TUBOD (TRES ROSAS)',
      'TUGAS',
    ],
  },
  CATIGBIAN: {
    province: 'BOHOL',
    municipality: 'CATIGBIAN',
    region: 'REGION VII',
    barangay_list: [
      'ALEGRIA',
      'AMBUAN',
      'BAANG',
      'BAGTIC',
      'BONGBONG',
      'CAMBAILAN',
      'CANDUMAYAO',
      'CAUSWAGAN NORTE',
      'HAGBUAYA',
      'HAGUILANAN',
      'KANG-IRAS',
      'LIBERTAD SUR',
      'LIBORON',
      'MAHAYAG NORTE',
      'MAHAYAG SUR',
      'MAITUM',
      'MANTASIDA',
      'POBLACION',
      'POBLACION WESTE',
      'RIZAL',
      'SINAKAYANAN',
      'TRIPLE UNION',
    ],
  },
  CORELLA: {
    province: 'BOHOL',
    municipality: 'CORELLA',
    region: 'REGION VII',
    barangay_list: [
      'ANISLAG',
      'CANANGCA-AN',
      'CANAPNAPAN',
      'CANCATAC',
      'PANDOL',
      'POBLACION',
      'SAMBOG',
      'TANDAY',
    ],
  },
  DAGOHOY: {
    province: 'BOHOL',
    municipality: 'DAGOHOY',
    region: 'REGION VII',
    barangay_list: [
      'BABAG',
      'CAGAWASAN',
      'CAGAWITAN',
      'CALUASAN',
      'CAN-OLING',
      'CANDELARIA',
      'ESTACA',
      'LA ESPERANZA',
      'MAHAYAG',
      'MALITBOG',
      'POBLACION',
      'SAN MIGUEL',
      'SAN VICENTE',
      'SANTA CRUZ',
      'VILLA AURORA',
    ],
  },
  DANAO: {
    province: 'BOHOL',
    municipality: 'DANAO',
    region: 'REGION VII',
    barangay_list: [
      'CABATUAN',
      'CANTUBOD',
      'CARBON',
      'CONCEPCION',
      'DAGOHOY',
      'HIBALE',
      'MAGTANGTANG',
      'NAHUD',
      'POBLACION',
      'REMEDIOS',
      'SAN CARLOS',
      'SAN MIGUEL',
      'SANTA FE',
      'SANTO NIÑO',
      'TABOK',
      'TAMING',
      'VILLA ANUNCIADO',
    ],
  },
  DAUIS: {
    province: 'BOHOL',
    municipality: 'DAUIS',
    region: 'REGION VII',
    barangay_list: [
      'BIKING',
      'BINGAG',
      'CATARMAN',
      'DAO',
      'MARIVELES',
      'MAYACABAC',
      'POBLACION',
      'SAN ISIDRO (CANLONGON)',
      'SONGCULAN',
      'TABALONG',
      'TINAGO',
      'TOTOLAN',
    ],
  },
  DIMIAO: {
    province: 'BOHOL',
    municipality: 'DIMIAO',
    region: 'REGION VII',
    barangay_list: [
      'ABIHID',
      'ALEMANIA',
      'BAGUHAN',
      'BAKILID',
      'BALBALAN',
      'BANBAN',
      'BAUHUGAN',
      'BILISAN',
      'CABAGAKIAN',
      'CABANBANAN',
      'CADAP-AGAN',
      'CAMBACOL',
      'CAMBAYAON',
      'CANHAYUPON',
      'CANLAMBONG',
      'CASINGAN',
      'CATUGASAN',
      'DATAG',
      'GUINDAGUITAN',
      'GUINGOYURAN',
      'ILE',
      'LAPSAON',
      'LIMOKON ILAOD',
      'LIMOKON ILAYA',
      'LUYO',
      'MALIJAO',
      'OAC',
      'PAGSA',
      'PANGIHAWAN',
      'PUANGYUTA',
      'SAWANG',
      'TANGOHAY',
      'TAONGON CABATUAN',
      'TAONGON CAN-ANDAM',
      'TAWID BITAOG',
    ],
  },
  DUERO: {
    province: 'BOHOL',
    municipality: 'DUERO',
    region: 'REGION VII',
    barangay_list: [
      'ALEJAWAN',
      'ANGILAN',
      'ANIBONGAN',
      'BANGWALOG',
      'CANSUHAY',
      'DANAO',
      'DUAY',
      'GUINSULARAN',
      'IMELDA',
      'ITUM',
      'LANGKIS',
      'LOBOGON',
      'MADUA NORTE',
      'MADUA SUR',
      'MAMBOOL',
      'MAWI',
      'PAYAO',
      'SAN ANTONIO (POB.)',
      'SAN ISIDRO',
      'SAN PEDRO',
      'TAYTAY',
    ],
  },
  'GARCIA HERNANDEZ': {
    province: 'BOHOL',
    municipality: 'GARCIA HERNANDEZ',
    region: 'REGION VII',
    barangay_list: [
      'ABIJILAN',
      'ANTIPOLO',
      'BASIAO',
      'CAGWANG',
      'CALMA',
      'CAMBUYO',
      'CANAYAON EAST',
      'CANAYAON WEST',
      'CANDANAS',
      'CANDULAO',
      'CATMON',
      'CAYAM',
      'CUPA',
      'DATAG',
      'ESTACA',
      'LIBERTAD',
      'LUNGSODAAN EAST',
      'LUNGSODAAN WEST',
      'MALINAO',
      'MANABA',
      'PASONG',
      'POBLACION EAST',
      'POBLACION WEST',
      'SACAON',
      'SAMPONG',
      'TABUAN',
      'TOGBONGON',
      'ULBUJAN EAST',
      'ULBUJAN WEST',
      'VICTORIA',
    ],
  },
  GUINDULMAN: {
    province: 'BOHOL',
    municipality: 'GUINDULMAN',
    region: 'REGION VII',
    barangay_list: [
      'BASDIO',
      'BATO',
      'BAYONG',
      'BIABAS',
      'BULAWAN',
      'CABANTIAN',
      'CANHAWAY',
      'CANSIWANG',
      'CASBU',
      'CATUNGAWAN NORTE',
      'CATUNGAWAN SUR',
      'GUINACOT',
      'GUIO-ANG',
      'LOMBOG',
      'MAYUGA',
      'SAWANG (POB.)',
      'TABAJAN (POB.)',
      'TABUNOK',
      'TRINIDAD',
    ],
  },
  INABANGA: {
    province: 'BOHOL',
    municipality: 'INABANGA',
    region: 'REGION VII',
    barangay_list: [
      'ANONANG',
      'BADIANG',
      'BAGUHAN',
      'BAHAN',
      'BANAHAO',
      'BAOGO',
      'BUGANG',
      'CAGAWASAN',
      'CAGAYAN',
      'CAMBITOON',
      'CANLINTE',
      'CAWAYAN',
      'COGON',
      'CUAMING',
      'DAGNAWAN',
      'DAGOHOY',
      'DAIT SUR',
      'DATAG',
      'FATIMA',
      'HAMBONGAN',
      'ILAUD (POB.)',
      'ILAYA',
      'ILIHAN',
      'LAPACAN NORTE',
      'LAPACAN SUR',
      'LAWIS',
      'LILOAN NORTE',
      'LILOAN SUR',
      'LOMBOY',
      'LONOY CAINSICAN',
      'LONOY ROMA',
      'LUTAO',
      'LUYO',
      'MABUHAY',
      'MARIA ROSARIO',
      'NABUAD',
      'NAPO',
      'ONDOL',
      'POBLACION',
      'RIVERSIDE',
      'SAA',
      'SAN ISIDRO',
      'SAN JOSE',
      'SANTO NIÑO',
      'SANTO ROSARIO',
      'SUA',
      'TAMBOOK',
      'TUNGOD',
      'U-OG',
      'UBUJAN',
    ],
  },
  JAGNA: {
    province: 'BOHOL',
    municipality: 'JAGNA',
    region: 'REGION VII',
    barangay_list: [
      'ALEJAWAN',
      'BALILI',
      'BOCTOL',
      'BUNGA ILAYA',
      'BUNGA MAR',
      'BUYOG',
      'CABUNGA-AN',
      'CALABACITA',
      'CAMBUGASON',
      'CAN-IPOL',
      'CAN-UBA',
      'CAN-UPAO',
      'CANJULAO',
      'CANTAGAY',
      'CANTUYOC',
      'FARAON',
      'IPIL',
      'KINAGBAAN',
      'LACA',
      'LARAPAN',
      'LONOY',
      'LOOC',
      'MALBOG',
      'MAYANA',
      'NAATANG',
      'NAUSOK',
      'ODIONG',
      'PAGINA',
      'PANGDAN',
      'POBLACION (PONDOL)',
      'TEJERO',
      'TUBOD MAR',
      'TUBOD MONTE',
    ],
  },
  JETAFE: {
    province: 'BOHOL',
    municipality: 'JETAFE',
    region: 'REGION VII',
    barangay_list: [
      'ALUMAR',
      'BANACON',
      'BUYOG',
      'CABASAKAN',
      'CAMPAO OCCIDENTAL',
      'CAMPAO ORIENTAL',
      'CANGMUNDO',
      'CARLOS P. GARCIA',
      'CORTE BAUD',
      'HANDUMON',
      'JAGOLIAO',
      'JANDAYAN NORTE',
      'JANDAYAN SUR',
      'MAHANAY (MAHANAY ISLAND)',
      'NASINGIN',
      'PANDANON',
      'POBLACION',
      'SAGUISE',
      'SALOG',
      'SAN JOSE',
      'SANTO NIÑO',
      'TAYTAY',
      'TUGAS',
      'TULANG',
    ],
  },
  LILA: {
    province: 'BOHOL',
    municipality: 'LILA',
    region: 'REGION VII',
    barangay_list: [
      'BANBAN',
      'BONKOKAN ILAYA',
      'BONKOKAN UBOS',
      'CALVARIO',
      'CANDULANG',
      'CATUGASAN',
      'CAYUPO',
      'COGON',
      'JAMBAWAN',
      'LA FORTUNA',
      'LOMANOY',
      'MACALINGAN',
      'MALINAO EAST',
      'MALINAO WEST',
      'NAGSULAY',
      'POBLACION',
      'TAUG',
      'TIGUIS',
    ],
  },
  LOAY: {
    province: 'BOHOL',
    municipality: 'LOAY',
    region: 'REGION VII',
    barangay_list: [
      'AGAPE',
      'ALEGRIA NORTE',
      'ALEGRIA SUR',
      'BONBON',
      'BOTOC OCCIDENTAL',
      'BOTOC ORIENTAL',
      'CALVARIO',
      'CONCEPCION',
      'HINAWANAN',
      'LAS SALINAS NORTE',
      'LAS SALINAS SUR',
      'PALO',
      'POBLACION IBABAO',
      'POBLACION UBOS',
      'SAGNAP',
      'TAMBANGAN',
      'TANGCASAN NORTE',
      'TANGCASAN SUR',
      'TAYONG OCCIDENTAL',
      'TAYONG ORIENTAL',
      'TOCDOG DACU',
      'TOCDOG ILAYA',
      'VILLALIMPIA',
      'YANANGAN',
    ],
  },
  LOBOC: {
    province: 'BOHOL',
    municipality: 'LOBOC',
    region: 'REGION VII',
    barangay_list: [
      'AGAPE',
      'ALEGRIA',
      'BAGUMBAYAN',
      'BAHIAN',
      'BONBON LOWER',
      'BONBON UPPER',
      'BUENAVISTA',
      'BUGHO',
      'CABADIANGAN',
      'CALUNASAN NORTE',
      'CALUNASAN SUR',
      'CAMAYAAN',
      'CAMBANCE',
      'CANDABONG',
      'CANDASAG',
      'CANLASID',
      'GON-OB',
      'GOTOZON',
      'JIMILIAN',
      'OY',
      'POBLACION ONDOL',
      'POBLACION SAWANG',
      'QUINOGUITAN',
      'TAYTAY',
      'TIGBAO',
      'UGPONG',
      'VALLADOLID',
      'VILLAFLOR',
    ],
  },
  LOON: {
    province: 'BOHOL',
    municipality: 'LOON',
    region: 'REGION VII',
    barangay_list: [
      'AGSOSO',
      'BADBAD OCCIDENTAL',
      'BADBAD ORIENTAL',
      'BAGACAY KATIPUNAN',
      'BAGACAY KAWAYAN',
      'BAGACAY SAONG',
      'BAHI',
      'BASAC',
      'BASDACU',
      'BASDIO',
      'BIASONG',
      'BONGCO',
      'BUGHO',
      'CABACONGAN',
      'CABADUG',
      'CABUG',
      'CALAYUGAN NORTE',
      'CALAYUGAN SUR',
      'CAMBAQUIZ',
      'CAMPATUD',
      'CANDAIGAN',
      'CANHANGDON OCCIDENTAL',
      'CANHANGDON ORIENTAL',
      'CANIGAAN',
      'CANMAAG',
      'CANMANOC',
      'CANSUAGWIT',
      'CANSUBAYON',
      'CANTAM-IS BAGO',
      'CANTAM-IS BASLAY',
      'CANTAONGON',
      'CANTUMOCAD',
      'CATAGBACAN HANDIG',
      'CATAGBACAN NORTE',
      'CATAGBACAN SUR',
      'COGON NORTE (POB.)',
      'COGON SUR',
      'CUASI',
      'GENOMOAN',
      'LINTUAN',
      'LOOC',
      'MOCPOC NORTE',
      'MOCPOC SUR',
      'MOTO NORTE (POB.)',
      'MOTO SUR (POB.)',
      'NAGTUANG',
      'NAPO (POB.)',
      'NUEVA VIDA',
      'PANANGQUILON',
      'PANTUDLAN',
      'PIG-OT',
      'PONDOL',
      'QUINOBCOBAN',
      'SONDOL',
      'SONG-ON',
      'TALISAY',
      'TAN-AWAN',
      'TANGNAN',
      'TAYTAY',
      'TICUGAN',
      'TIWI',
      'TONTONAN',
      'TUBODACU',
      'TUBODIO',
      'TUBUAN',
      'UBAYON',
      'UBOJAN',
    ],
  },
  MARIBOJOC: {
    province: 'BOHOL',
    municipality: 'MARIBOJOC',
    region: 'REGION VII',
    barangay_list: [
      'AGAHAY',
      'ALIGUAY',
      'ANISLAG',
      'BAYACABAC',
      'BOOD',
      'BUSAO',
      'CABAWAN',
      'CANDAVID',
      'DIPATLONG',
      'GUIWANON',
      'JANDIG',
      'LAGTANGON',
      'LINCOD',
      'PAGNITOAN',
      'POBLACION',
      'PUNSOD',
      'PUNTA CRUZ',
      'SAN ISIDRO',
      'SAN ROQUE (AGHAO)',
      'SAN VICENTE',
      'TINIBGAN',
      'TORIL',
    ],
  },
  PANGLAO: {
    province: 'BOHOL',
    municipality: 'PANGLAO',
    region: 'REGION VII',
    barangay_list: [
      'BIL-ISAN',
      'BOLOD',
      'DANAO',
      'DOLJO',
      'LIBAONG',
      'LOOC',
      'LOURDES',
      'POBLACION',
      'TANGNAN',
      'TAWALA',
    ],
  },
  'PRES. CARLOS P. GARCIA (PITOGO)': {
    province: 'BOHOL',
    municipality: 'PRES. CARLOS P. GARCIA (PITOGO)',
    region: 'REGION VII',
    barangay_list: [
      'AGUINING',
      'BASIAO',
      'BAUD',
      'BAYOG',
      'BOGO',
      'BONBONON',
      'BUTAN',
      'CAMPAMANOG',
      'CANMANGAO',
      'GAUS',
      'KABANGKALAN',
      'LAPINIG',
      'LIPATA',
      'POBLACION',
      'POPOO',
      'SAGUISE',
      'SAN JOSE (TAWID)',
      'SAN VICENTE',
      'SANTO ROSARIO',
      'TILMOBO',
      'TUGAS',
      'TUGNAO',
      'VILLA MILAGROSA',
    ],
  },
  'SAGBAYAN (BORJA)': {
    province: 'BOHOL',
    municipality: 'SAGBAYAN (BORJA)',
    region: 'REGION VII',
    barangay_list: [
      'CALANGAHAN',
      'CANMANO',
      'CANMAYA CENTRO',
      'CANMAYA DIOT',
      'DAGNAWAN',
      'KABASACAN',
      'KAGAWASAN',
      'KATIPUNAN',
      'LANGTAD',
      'LIBERTAD NORTE',
      'LIBERTAD SUR',
      'MANTALONGON',
      'POBLACION',
      'SAGBAYAN SUR',
      'SAN AGUSTIN',
      'SAN ANTONIO',
      'SAN ISIDRO',
      'SAN RAMON',
      'SAN ROQUE',
      'SAN VICENTE NORTE',
      'SAN VICENTE SUR',
      'SANTA CATALINA',
      'SANTA CRUZ',
      'UBOJAN',
    ],
  },
  SEVILLA: {
    province: 'BOHOL',
    municipality: 'SEVILLA',
    region: 'REGION VII',
    barangay_list: [
      'BAYAWAHAN',
      'CABANCALAN',
      'CALINGA-AN',
      'CALINGINAN NORTE',
      'CALINGINAN SUR',
      'CAMBAGUI',
      'EWON',
      'GUINOB-AN',
      'LAGTANGAN',
      'LICOLICO',
      'LOBGOB',
      'MAGSAYSAY',
      'POBLACION',
    ],
  },
  'SIERRA BULLONES': {
    province: 'BOHOL',
    municipality: 'SIERRA BULLONES',
    region: 'REGION VII',
    barangay_list: [
      'ABACHANAN',
      'ANIBONGAN',
      'BUGSOC',
      'CAHAYAG',
      'CANLANGIT',
      'CANTA-UB',
      'CASILAY',
      'DANICOP',
      'DUSITA',
      'LA UNION',
      'LATABAN',
      'MAGSAYSAY',
      'MAN-OD',
      'MATIN-AO',
      'POBLACION',
      'SALVADOR',
      'SAN AGUSTIN',
      'SAN ISIDRO',
      'SAN JOSE',
      'SAN JUAN',
      'SANTA CRUZ',
      'VILLA GARCIA',
    ],
  },
  SIKATUNA: {
    province: 'BOHOL',
    municipality: 'SIKATUNA',
    region: 'REGION VII',
    barangay_list: [
      'ABUCAY NORTE',
      'ABUCAY SUR',
      'BADIANG',
      'BAHAYBAHAY',
      'CAMBUAC NORTE',
      'CAMBUAC SUR',
      'CANAGONG',
      'LIBJO',
      'POBLACION I',
      'POBLACION II',
    ],
  },
  'TAGBILARAN': {
    province: 'BOHOL',
    municipality: 'TAGBILARAN',
    region: 'REGION VII',
    barangay_list: [
      'BOOL',
      'BOOY',
      'CABAWAN',
      'COGON',
      'DAMPAS',
      'DAO',
      'MANGA',
      'MANSASA',
      'POBLACION I',
      'POBLACION II',
      'POBLACION III',
      'SAN ISIDRO',
      'TALOTO',
      'TIPTIP',
      'UBUJAN',
    ],
  },
  TALIBON: {
    province: 'BOHOL',
    municipality: 'TALIBON',
    region: 'REGION VII',
    barangay_list: [
      'BAGACAY',
      'BALINTAWAK',
      'BURGOS',
      'BUSALIAN',
      'CALITUBAN',
      'CATABAN',
      'GUINDACPAN',
      'MAGSAYSAY',
      'MAHANAY',
      'NOCNOCAN',
      'POBLACION',
      'RIZAL',
      'SAG',
      'SAN AGUSTIN',
      'SAN CARLOS',
      'SAN FRANCISCO',
      'SAN ISIDRO',
      'SAN JOSE',
      'SAN PEDRO',
      'SAN ROQUE',
      'SANTO NIÑO',
      'SIKATUNA',
      'SUBA',
      'TANGHALIGUE',
      'ZAMORA',
    ],
  },
  TRINIDAD: {
    province: 'BOHOL',
    municipality: 'TRINIDAD',
    region: 'REGION VII',
    barangay_list: [
      'BANLASAN',
      'BONGBONG',
      'CATOOGAN',
      'GUINOBATAN',
      'HINLAYAGAN ILAUD',
      'HINLAYAGAN ILAYA',
      'KAUSWAGAN',
      'KINAN-OAN',
      'LA UNION',
      'LA VICTORIA',
      'MABUHAY CABIGOHAN',
      'MAHAGBU',
      'MANUEL M. ROXAS',
      'POBLACION',
      'SAN ISIDRO',
      'SAN VICENTE',
      'SANTO TOMAS',
      'SOOM',
      'TAGUM NORTE',
      'TAGUM SUR',
    ],
  },
  TUBIGON: {
    province: 'BOHOL',
    municipality: 'TUBIGON',
    region: 'REGION VII',
    barangay_list: [
      'BAGONGBANWA',
      'BANLASAN',
      'BATASAN (BATASAN ISLAND)',
      'BILANGBILANGAN (BILANGBILANGAN ISLAND)',
      'BOSONGON',
      'BUENOS AIRES',
      'BUNACAN',
      'CABULIHAN',
      'CAHAYAG',
      'CAWAYANAN',
      'CENTRO (POB.)',
      'GENONOCAN',
      'GUIWANON',
      'ILIHAN NORTE',
      'ILIHAN SUR',
      'LIBERTAD',
      'MACAAS',
      'MATABAO',
      'MOCABOC ISLAND',
      'PANADTARAN',
      'PANAYTAYON',
      'PANDAN',
      'PANGAPASAN (PANGAPASAN ISLAND)',
      'PINAYAGAN NORTE',
      'PINAYAGAN SUR',
      'POOC OCCIDENTAL (POB.)',
      'POOC ORIENTAL (POB.)',
      'POTOHAN',
      'TALENCERAS',
      'TAN-AWAN',
      'TINANGNAN',
      'UBAY ISLAND',
      'UBOJAN',
      'VILLANUEVA',
    ],
  },
  UBAY: {
    province: 'BOHOL',
    municipality: 'UBAY',
    region: 'REGION VII',
    barangay_list: [
      'ACHILA',
      'BAY-ANG',
      'BENLIW',
      'BIABAS',
      'BONGBONG',
      'BOOD',
      'BUENAVISTA',
      'BULILIS',
      'CAGTING',
      'CALANGGAMAN',
      'CALIFORNIA',
      'CAMALI-AN',
      'CAMAMBUGAN',
      'CASATE',
      'CUYA',
      'FATIMA',
      'GABI',
      'GOVERNOR BOYLES',
      'GUINTABO-AN',
      'HAMBABAURAN',
      'HUMAYHUMAY',
      'ILIHAN',
      'IMELDA',
      'JUAGDAN',
      'KATARUNGAN',
      'LOMANGOG',
      'LOS ANGELES',
      'PAG-ASA',
      'PANGPANG',
      'POBLACION',
      'SAN FRANCISCO',
      'SAN ISIDRO',
      'SAN PASCUAL',
      'SAN VICENTE',
      'SENTINILA',
      'SINANDIGAN',
      'TAPAL',
      'TAPON',
      'TINTINAN',
      'TIPOLO',
      'TUBOG',
      'TUBORAN',
      'UNION',
      'VILLA TERESITA',
    ],
  },
  VALENCIA: {
    province: 'BOHOL',
    municipality: 'VALENCIA',
    region: 'REGION VII',
    barangay_list: [
      'ADLAWAN',
      'ANAS',
      'ANONANG',
      'ANOYON',
      'BALINGASAO',
      'BANDERAHAN (UPPER GINOPOLAN)',
      'BOTONG',
      'BUYOG',
      'CANDUAO OCCIDENTAL',
      'CANDUAO ORIENTAL',
      'CANLUSONG',
      'CANMANICO',
      'CANSIBAO',
      'CATUG-A',
      'CUTCUTAN',
      'DANAO',
      'GENOVEVA',
      'GINOPOLAN (GINOPOLAN PROPER)',
      'LA VICTORIA',
      'LANTANG',
      'LIMOCON',
      'LOCTOB',
      'MAGSAYSAY',
      'MARAWIS',
      'MAUBO',
      'NAILO',
      'OMJON',
      'PANGI-AN',
      'POBLACION OCCIDENTAL',
      'POBLACION ORIENTAL',
      'SIMANG',
      'TAUG',
      'TAUSION',
      'TAYTAY',
      'TICUM',
    ],
  },
  ALCOY: {
    province: 'CEBU',
    municipality: 'ALCOY',
    region: 'REGION VII',
    barangay_list: [
      'ATABAY',
      'DAAN-LUNGSOD',
      'GUIWANG',
      'NUG-AS',
      'PASOL',
      'POBLACION',
      'PUGALO',
      'SAN AGUSTIN',
    ],
  },
  ALOGUINSAN: {
    province: 'CEBU',
    municipality: 'ALOGUINSAN',
    region: 'REGION VII',
    barangay_list: [
      'ANGILAN',
      'BOJO',
      'BONBON',
      'ESPERANZA',
      'KANDINGAN',
      'KANTABOGON',
      'KAWASAN',
      'OLANGO',
      'POBLACION',
      'PUNAY',
      'ROSARIO',
      'SAKSAK',
      'TAMPA-AN',
      'TOYOKON',
      'ZARAGOSA',
    ],
  },
  ARGAO: {
    province: 'CEBU',
    municipality: 'ARGAO',
    region: 'REGION VII',
    barangay_list: [
      'ALAMBIJUD',
      'ANAJAO',
      'APO',
      'BALAAS',
      'BALISONG',
      'BINLOD',
      'BOGO',
      'BUG-OT',
      'BULASA',
      'BUTONG',
      'CALAGASAN',
      'CANBANTUG',
      'CANBANUA',
      'CANSUJE',
      'CAPIO-AN',
      'CASAY',
      'CATANG',
      'COLAWIN',
      'CONALUM',
      'GUIWANON',
      'GUTLANG',
      'JAMPANG',
      'JOMGAO',
      'LAMACAN',
      'LANGTAD',
      'LANGUB',
      'LAPAY',
      'LENGIGON',
      'LINUT-OD',
      'MABASA',
      'MANDILIKIT',
      'MOMPELLER',
      'PANADTARAN',
      'POBLACION',
      'SUA',
      'SUMAGUAN',
      'TABAYAG',
      'TALAGA',
      'TALAYTAY',
      'TALO-OT',
      'TIGUIB',
      'TULANG',
      'TULIC',
      'UBAUB',
      'USMAD',
    ],
  },
  ASTURIAS: {
    province: 'CEBU',
    municipality: 'ASTURIAS',
    region: 'REGION VII',
    barangay_list: [
      'AGBANGA',
      'AGTUGOP',
      'BAGO',
      'BAIRAN',
      'BANBAN',
      'BAYE',
      'BOG-O',
      'KALUANGAN',
      'LANAO',
      'LANGUB',
      'LOOC NORTE',
      'LUNAS',
      'MAGCALAPE',
      'MANGUIAO',
      'NEW BAGO',
      'OWAK',
      'POBLACION',
      'SAKSAK',
      'SAN ISIDRO',
      'SAN ROQUE',
      'SANTA LUCIA',
      'SANTA RITA',
      'TAG-AMAKAN',
      'TAGBUBONGA',
      'TUBIGAGMANOK',
      'TUBOD',
      'UBOGON',
    ],
  },
  BADIAN: {
    province: 'CEBU',
    municipality: 'BADIAN',
    region: 'REGION VII',
    barangay_list: [
      'ALAWIJAO',
      'BALHAAN',
      'BANHIGAN',
      'BASAK',
      'BASIAO',
      'BATO',
      'BUGAS',
      'CALANGCANG',
      'CANDIIS',
      'DAGATAN',
      'DOBDOB',
      'GINABLAN',
      'LAMBUG',
      'MALABAGO',
      'MALHIAO',
      'MANDUYONG',
      'MATUTINAO',
      'PATONG',
      'POBLACION',
      'SANLAGAN',
      'SANTICON',
      'SOHOTON',
      'SULSUGAN',
      'TALAYONG',
      'TAYTAY',
      'TIGBAO',
      'TIGUIB',
      'TUBOD',
      'ZARAGOSA',
    ],
  },
  BALAMBAN: {
    province: 'CEBU',
    municipality: 'BALAMBAN',
    region: 'REGION VII',
    barangay_list: [
      'ABUCAYAN',
      'ALIWANAY',
      'ARPILI',
      'BALIWAGAN (POB.)',
      'BAYONG',
      'BIASONG',
      'BUANOY',
      'CABAGDALAN',
      'CABASIANGAN',
      'CAMBUHAWE',
      'CANSOMOROY',
      'CANTIBAS',
      'CANTUOD',
      'DUANGAN',
      'GAAS',
      'GINATILAN',
      'HINGATMONAN',
      'LAMESA',
      'LIKI',
      'LUCA',
      'MATUN-OG',
      'NANGKA',
      'PONDOL',
      'PRENZA',
      'SANTA CRUZ-SANTO NIÑO (POB.)',
      'SINGSING',
      'SUNOG',
      'VITO',
    ],
  },
  BANTAYAN: {
    province: 'CEBU',
    municipality: 'BANTAYAN',
    region: 'REGION VII',
    barangay_list: [
      'ATOP-ATOP',
      'BAIGAD',
      'BANTIGUE (POB.)',
      'BAOD',
      'BINAOBAO (POB.)',
      'BOTIGUES',
      'DOONG',
      'GUIWANON',
      'HILOTONGAN',
      'KABAC',
      'KABANGBANG',
      'KAMPINGGANON',
      'KANGKAIBE',
      'LIPAYRAN',
      'LUYONGBAYBAY',
      'MOJON',
      'OBO-OB',
      'PATAO',
      'PUTIAN',
      'SILLON',
      'SUBA (POB.)',
      'SULANGAN',
      'SUNGKO',
      'TAMIAO',
      'TICAD (POB.)',
    ],
  },
  BARILI: {
    province: 'CEBU',
    municipality: 'BARILI',
    region: 'REGION VII',
    barangay_list: [
      'AZUCENA',
      'BAGAKAY',
      'BALAO',
      'BOLOCBOLOC',
      'BUDBUD',
      'BUGTONG KAWAYAN',
      'CABCABAN',
      'CAGAY',
      'CAMPANGGA',
      'CANDUGAY',
      'DAKIT',
      'GILOCTOG',
      'GIWANON',
      'GUIBUANGAN',
      'GUNTING',
      'HILASGASAN',
      'JAPITAN',
      'KALUBIHAN',
      'KANGDAMPAS',
      'LUHOD',
      'LUPO',
      'LUYO',
      'MAGHANOY',
      'MAIGANG',
      'MALOLOS',
      'MANTALONGON',
      'MANTAYUPAN',
      'MAYANA',
      'MINOLOS',
      'NABUNTURAN',
      'NASIPIT',
      'PANCIL',
      'PANGPANG',
      'PARIL',
      'PATUPAT',
      'POBLACION',
      'SAN RAFAEL',
      'SANTA ANA',
      'SAYAW',
      'TAL-OT',
      'TUBOD',
      'VITO',
    ],
  },
  'BOGO': {
    province: 'CEBU',
    municipality: 'BOGO',
    region: 'REGION VII',
    barangay_list: [
      'ANONANG NORTE',
      'ANONANG SUR',
      'BANBAN',
      'BINABAG',
      'BUNGTOD (POB.)',
      'CARBON (POB.)',
      'CAYANG',
      'COGON (POB.)',
      'DAKIT',
      'DON PEDRO RODRIGUEZ',
      'GAIRAN',
      'GUADALUPE',
      'LA PAZ',
      'LA PURISIMA CONCEPCION (POB.)',
      'LIBERTAD',
      'LOURDES (POB.)',
      'MALINGIN',
      'MARANGOG',
      'NAILON',
      'ODLOT',
      'PANDAN (PANDAN HEIGHTS)',
      'POLAMBATO',
      'SAMBAG (POB.)',
      'SAN VICENTE (POB.)',
      'SANTO NIÑO',
      'SANTO ROSARIO (POB.)',
      'SIOCON',
      'SUDLONON',
      'TAYTAYAN',
    ],
  },
  BOLJOON: {
    province: 'CEBU',
    municipality: 'BOLJOON',
    region: 'REGION VII',
    barangay_list: [
      'ARBOR',
      'BACLAYAN',
      'EL PARDO',
      'GRANADA',
      'LOWER BECERRIL',
      'LUNOP',
      'NANGKA',
      'POBLACION',
      'SAN ANTONIO',
      'SOUTH GRANADA',
      'UPPER BECERRIL',
    ],
  },
  BORBON: {
    province: 'CEBU',
    municipality: 'BORBON',
    region: 'REGION VII',
    barangay_list: [
      'BAGACAY',
      'BILI',
      'BINGAY',
      'BONGDO',
      'BONGDO GUA',
      'BONGOYAN',
      'CADARUHAN',
      'CAJEL',
      'CAMPUSONG',
      'CLAVERA',
      'DON GREGORIO ANTIGUA (TAYTAYAN)',
      'LAAW',
      'LUGO',
      'MANAGASE',
      'POBLACION',
      'SAGAY',
      'SAN JOSE',
      'TABUNAN',
      'TAGNUCAN',
    ],
  },
  'CARCAR': {
    province: 'CEBU',
    municipality: 'CARCAR',
    region: 'REGION VII',
    barangay_list: [
      'BOLINAWAN',
      'BUENAVISTA',
      'CALIDNGAN',
      'CAN-ASUJAN',
      'GUADALUPE',
      'LIBURON',
      'NAPO',
      'OCANA',
      'PERRELOS',
      'POBLACION I',
      'POBLACION II',
      'POBLACION III',
      'TUYOM',
      'VALENCIA',
      'VALLADOLID',
    ],
  },
  CATMON: {
    province: 'CEBU',
    municipality: 'CATMON',
    region: 'REGION VII',
    barangay_list: [
      'AGSUWAO',
      'AMANCION',
      'ANAPOG',
      'BACTAS',
      'BASAK',
      'BINONGKALAN',
      'BONGYAS',
      'CABUNGAAN',
      'CAMBANGKAYA',
      'CAN-IBUANG',
      'CATMONDAAN',
      'CORAZON (POB.)',
      'DUYAN',
      'FLORES (POB.)',
      'GINABUCAN',
      'MACAAS',
      'PANALIPAN',
      'SAN JOSE POB. (CATADMAN)',
      'TABILI',
      'TINABYONAN',
    ],
  },
  'CEBU': {
    province: 'CEBU',
    municipality: 'CEBU',
    region: 'REGION VII',
    barangay_list: [
      'ADLAON',
      'AGSUNGOT',
      'APAS',
      'BABAG',
      'BACAYAN',
      'BANILAD',
      'BASAK PARDO',
      'BASAK SAN NICOLAS',
      'BINALIW',
      'BONBON',
      'BUDLA-AN (POB.)',
      'BUHISAN',
      'BULACAO',
      'BUOT-TAUP PARDO',
      'BUSAY (POB.)',
      'CALAMBA',
      'CAMBINOCOT',
      'CAMPUTHAW (POB.)',
      'CAPITOL SITE (POB.)',
      'CARRETA',
      'CENTRAL (POB.)',
      'COGON PARDO',
      'COGON RAMOS (POB.)',
      'DAY-AS',
      'DULJO (POB.)',
      'ERMITA (POB.)',
      'GUADALUPE',
      'GUBA',
      'HIPPODROMO',
      'INAYAWAN',
      'KALUBIHAN (POB.)',
      'KALUNASAN',
      'KAMAGAYAN (POB.)',
      'KASAMBAGAN',
      'KINASANG-AN PARDO',
      'LABANGON',
      'LAHUG (POB.)',
      'LOREGA (LOREGA SAN MIGUEL)',
      'LUSARAN',
      'LUZ',
      'MABINI',
      'MABOLO',
      'MALUBOG',
      'MAMBALING',
      'PAHINA CENTRAL (POB.)',
      'PAHINA SAN NICOLAS',
      'PAMUTAN',
      'PARDO (POB.)',
      'PARI-AN',
      'PARIL',
      'PASIL',
      'PIT-OS',
      'PULANGBATO',
      'PUNG-OL-SIBUGAY',
      'PUNTA PRINCESA',
      'QUIOT PARDO',
      'SAMBAG I (POB.)',
      'SAMBAG II (POB.)',
      'SAN ANTONIO (POB.)',
      'SAN JOSE',
      'SAN NICOLAS CENTRAL',
      'SAN ROQUE (CIUDAD)',
      'SANTA CRUZ (POB.)',
      'SAPANGDAKU',
      'SAWANG CALERO (POB.)',
      'SINSIN',
      'SIRAO',
      'SUBA POB. (SUBA SAN NICOLAS)',
      'SUDLON I',
      'SUDLON II',
      'T. PADILLA',
      'TABUNAN',
      'TAGBAO',
      'TALAMBAN',
      'TAPTAP',
      'TEJERO (VILLA GONZALO)',
      'TINAGO',
      'TISA',
      'TO-ONG PARDO',
      'ZAPATERA',
    ],
  },
  CONSOLACION: {
    province: 'CEBU',
    municipality: 'CONSOLACION',
    region: 'REGION VII',
    barangay_list: [
      'CABANGAHAN',
      'CANSAGA',
      'CASILI',
      'DANGLAG',
      'GARING',
      'JUGAN',
      'LAMAC',
      'LANIPGA',
      'NANGKA',
      'PANAS',
      'PANOYPOY',
      'PITOGO',
      'POBLACION OCCIDENTAL',
      'POBLACION ORIENTAL',
      'POLOG',
      'PULPOGAN',
      'SACSAC',
      'TAYUD',
      'TILHAONG',
      'TOLOTOLO',
      'TUGBONGAN',
    ],
  },
  CORDOBA: {
    province: 'CEBU',
    municipality: 'CORDOBA',
    region: 'REGION VII',
    barangay_list: [
      'ALEGRIA',
      'BANGBANG',
      'BUAGSONG',
      'CATARMAN',
      'COGON',
      'DAPITAN',
      'DAY-AS',
      'GABI',
      'GILUTONGAN',
      'IBABAO',
      'PILIPOG',
      'POBLACION',
      'SAN MIGUEL',
    ],
  },
  DAANBANTAYAN: {
    province: 'CEBU',
    municipality: 'DAANBANTAYAN',
    region: 'REGION VII',
    barangay_list: [
      'AGUHO',
      'BAGAY',
      'BAKHAWAN',
      'BATERIA',
      'BITOON',
      'CALAPE',
      'CARNAZA',
      'DALINGDING',
      'LANAO',
      'LOGON',
      'MALBAGO',
      'MALINGIN',
      'MAYA',
      'PAJO',
      'PAYPAY',
      'POBLACION',
      'TALISAY',
      'TAPILON',
      'TINUBDAN',
      'TOMINJAO',
    ],
  },
  DALAGUETE: {
    province: 'CEBU',
    municipality: 'DALAGUETE',
    region: 'REGION VII',
    barangay_list: [
      'ABLAYAN',
      'BABAYONGAN',
      'BALUD',
      'BANHIGAN',
      'BULAK',
      'CALERIOHAN',
      'CALIONGAN',
      'CASAY',
      'CATOLOHAN',
      'CAWAYAN',
      'CONSOLACION',
      'CORO',
      'DUGYAN',
      'DUMALAN',
      'JOLOMAYNON',
      'LANAO',
      'LANGKAS',
      'LUMBANG',
      'MALONES',
      'MALORAY',
      'MANANGGAL',
      'MANLAPAY',
      'MANTALONGON',
      'NALHUB',
      'OBO',
      'OBONG',
      'PANAS',
      'POBLACION',
      'SACSAC',
      'SALUG',
      'TABON',
      'TAPUN',
      'TUBA',
    ],
  },
  'DANAO': {
    province: 'CEBU',
    municipality: 'DANAO',
    region: 'REGION VII',
    barangay_list: [
      'BALIANG',
      'BAYABAS',
      'BINALIW',
      'CABUNGAHAN',
      'CAGAT-LAMAC',
      'CAHUMAYAN',
      'CAMBANAY',
      'CAMBUBHO',
      'COGON-CRUZ',
      'DANASAN',
      'DUNGGA',
      'DUNGGOAN',
      'GUINACOT',
      'GUINSAY',
      'IBO',
      'LANGOSIG',
      'LAWAAN',
      'LICOS',
      'LOOC',
      'MAGTAGOBTOB',
      'MALAPOC',
      'MANLAYAG',
      'MANTIJA',
      'MASABA',
      'MASLOG',
      'NANGKA',
      'OGUIS',
      'PILI',
      'POBLACION',
      'QUISOL',
      'SABANG',
      'SACSAC',
      'SANDAYONG NORTE',
      'SANDAYONG SUR',
      'SANTA ROSA',
      'SANTICAN',
      'SIBACAN',
      'SUBA',
      'TABOC',
      'TAYTAY',
      'TOGONON',
      'TUBURAN SUR',
    ],
  },
  DUMANJUG: {
    province: 'CEBU',
    municipality: 'DUMANJUG',
    region: 'REGION VII',
    barangay_list: [
      'BALAYGTIKI',
      'BITOON',
      'BULAK',
      'BULLOGAN',
      'CALABOON',
      'CAMBOANG',
      'CANDABONG',
      'COGON',
      'COTCOTON',
      'DOLDOL',
      'ILAYA (POB.)',
      'KABALAASNAN',
      'KABATBATAN',
      'KAMBANOG',
      'KANG-ACTOL',
      'KANGHALO',
      'KANGHUMAOD',
      'KANGUHA',
      'KANTANGKAS',
      'KANYUKO',
      'KOLABTINGON',
      'LAMAK',
      'LAWAAN',
      'LIONG',
      'MANLAPAY',
      'MASA',
      'MATALAO',
      'PACULOB',
      'PANLAAN',
      'PAWA',
      'POBLACION CENTRAL',
      'POBLACION LOOC',
      'POBLACION SIMA',
      'TANGIL',
      'TAPON',
      'TUBOD-BITOON',
      'TUBOD-DUGOAN',
    ],
  },
  GINATILAN: {
    province: 'CEBU',
    municipality: 'GINATILAN',
    region: 'REGION VII',
    barangay_list: [
      'ANAO',
      'CAGSING',
      'CALABAWAN',
      'CAMBAGTE',
      'CAMPISONG',
      'CANORONG',
      'GUIWANON',
      'LOOC',
      'MALATBO',
      'MANGACO',
      'PALANAS',
      'POBLACION',
      'SALAMANCA',
      'SAN ROQUE',
    ],
  },
  'LAPU-LAPU CITY (OPON)': {
    province: 'CEBU',
    municipality: 'LAPU-LAPU CITY (OPON)',
    region: 'REGION VII',
    barangay_list: [
      'AGUS',
      'BABAG',
      'BANKAL',
      'BARING',
      'BASAK',
      'BUAYA',
      'CALAWISAN',
      'CANJULAO',
      'CAUBIAN',
      'CAW-OY',
      'CAWHAGAN',
      'GUN-OB',
      'IBO',
      'LOOC',
      'MACTAN',
      'MARIBAGO',
      'MARIGONDON',
      'PAJAC',
      'PAJO',
      'PANGAN-AN',
      'POBLACION',
      'PUNTA ENGAÑO',
      'PUSOK',
      'SABANG',
      'SAN VICENTE',
      'SANTA ROSA',
      'SUBABASBAS',
      'TALIMA',
      'TINGO',
      'TUNGASAN',
    ],
  },
  LILOAN: {
    province: 'SOUTHERN LEYTE',
    municipality: 'LILOAN',
    region: 'REGION VIII',
    barangay_list: [
      'AMAGA',
      'ANILAO',
      'BAHAY',
      'CAGBUNGALON',
      'CALIAN',
      'CALIGANGAN',
      'CANDAYUMAN',
      'CATIG',
      'ESTELA',
      'FATIMA',
      'GUD-AN',
      'GUINTOYLAN',
      'HIMAYANGAN',
      'ILAG',
      'MAGAUPAS',
      'MALANGSA',
      'MOLOPOLO',
      'PANDAN',
      'POBLACION',
      'PRES. QUEZON (MAUGOC)',
      'PRESIDENT ROXAS',
      'SAN ISIDRO',
      'SAN ROQUE',
      'TABUGON',
    ],
  },
  MADRIDEJOS: {
    province: 'CEBU',
    municipality: 'MADRIDEJOS',
    region: 'REGION VII',
    barangay_list: [
      'BUNAKAN',
      'KANGWAYAN',
      'KAONGKOD',
      'KODIA',
      'MAALAT',
      'MALBAGO',
      'MANCILANG',
      'PILI',
      'POBLACION',
      'SAN AGUSTIN',
      'TABAGAK',
      'TALANGNAN',
      'TARONG',
      'TUGAS',
    ],
  },
  MALABUYOC: {
    province: 'CEBU',
    municipality: 'MALABUYOC',
    region: 'REGION VII',
    barangay_list: [
      'ARMEÑA (CANSILONGAN)',
      'BARANGAY I (POB.)',
      'BARANGAY II (POB.)',
      'CERDEÑA (ANSAN)',
      'LABRADOR (BULOD)',
      'LOMBO',
      'LOOC',
      'MAHANLUD',
      'MINDANAO (PAJO)',
      'MONTAÑEZA (INAMLANG)',
      'SALMERON (BULAK)',
      'SANTO NIÑO',
      'SORSOGON (BALIMAYA)',
      'TOLOSA (CALATAGAN)',
    ],
  },
  'MANDAUE': {
    province: 'CEBU',
    municipality: 'MANDAUE',
    region: 'REGION VII',
    barangay_list: [
      'ALANG-ALANG',
      'BAKILID',
      'BANILAD',
      'BASAK',
      'CABANCALAN',
      'CAMBARO',
      'CANDUMAN',
      'CASILI',
      'CASUNTINGAN',
      'CENTRO (POB.)',
      'CUBACUB',
      'GUIZO',
      'IBABAO-ESTANCIA',
      'JAGOBIAO',
      'LABOGON',
      'LOOC',
      'MAGUIKAY',
      'MANTUYONG',
      'OPAO',
      'PAGSABUNGAN',
      'PAKNA-AN',
      'SUBANGDAKU',
      'TABOK',
      'TAWASON',
      'TINGUB',
      'TIPOLO',
      'UMAPAD',
    ],
  },
  MEDELLIN: {
    province: 'CEBU',
    municipality: 'MEDELLIN',
    region: 'REGION VII',
    barangay_list: [
      'ANTIPOLO',
      'CANHABAGAT',
      'CAPUTATAN NORTE',
      'CAPUTATAN SUR',
      'CURVA',
      'DAANLUNGSOD',
      'DALINGDING SUR',
      'DAYHAGON',
      'DON VIRGILIO GONZALES',
      'GIBITNGIL',
      'KAWIT',
      'LAMINTAK NORTE',
      'LAMINTAK SUR',
      'LUY-A',
      'MAHARUHAY',
      'MAHAWAK',
      'PANUGNAWAN',
      'POBLACION',
      'TINDOG',
    ],
  },
  MINGLANILLA: {
    province: 'CEBU',
    municipality: 'MINGLANILLA',
    region: 'REGION VII',
    barangay_list: [
      'CADULAWAN',
      'CALAJO-AN',
      'CAMP 7',
      'CAMP 8',
      'CUANOS',
      'GUINDARUHAN',
      'LINAO',
      'MANDUANG',
      'PAKIGNE',
      'POBLACION WARD I',
      'POBLACION WARD II',
      'POBLACION WARD III',
      'POBLACION WARD IV',
      'TUBOD',
      'TULAY',
      'TUNGHAAN',
      'TUNGKIL',
      'TUNGKOP',
      'VITO',
    ],
  },
  MOALBOAL: {
    province: 'CEBU',
    municipality: 'MOALBOAL',
    region: 'REGION VII',
    barangay_list: [
      'AGBALANGA',
      'BALA',
      'BALABAGON',
      'BASDIOT',
      'BATADBATAD',
      'BUGHO',
      'BUGUIL',
      'BUSAY',
      'LANAO',
      'POBLACION EAST',
      'POBLACION WEST',
      'SAAVEDRA',
      'TOMONOY',
      'TUBLE',
      'TUNGA',
    ],
  },
  OSLOB: {
    province: 'CEBU',
    municipality: 'OSLOB',
    region: 'REGION VII',
    barangay_list: [
      'ALO',
      'BANGCOGON',
      'BONBON',
      'CALUMPANG',
      'CAN-UKBAN',
      'CANANGCA-AN',
      'CANSALO-AY',
      'CAÑANG',
      'DAANLUNGSOD',
      'GAWI',
      'HAGDAN',
      'LAGUNDE',
      'LOOC',
      'LUKA',
      'MAINIT',
      'MANLUM',
      'NUEVA CACERES',
      'POBLACION',
      'PUNGTOD',
      'TAN-AWAN',
      'TUMALOG',
    ],
  },
  PINAMUNGAHAN: {
    province: 'CEBU',
    municipality: 'PINAMUNGAHAN',
    region: 'REGION VII',
    barangay_list: [
      'ANISLAG',
      'ANOPOG',
      'BINABAG',
      'BUHINGTUBIG',
      'BUSAY',
      'BUTONG',
      'CABIANGON',
      'CAMUGAO',
      'DUANGAN',
      'GUIMBAWIAN',
      'LAMAC',
      'LUT-OD',
      'MANGOTO',
      'OPAO',
      'PANDACAN',
      'POBLACION',
      'PUNOD',
      'RIZAL',
      'SACSAC',
      'SAMBAGON',
      'SIBAGO',
      'TAJAO',
      'TANGUB',
      'TANIBAG',
      'TUPAS',
      'TUTAY',
    ],
  },
  PORO: {
    province: 'CEBU',
    municipality: 'PORO',
    region: 'REGION VII',
    barangay_list: [
      'ADELA',
      'ALTAVISTA',
      'CAGCAGAN',
      'CANSABUSAB',
      'DAAN PAZ',
      'EASTERN POBLACION',
      'ESPERANZA',
      'LIBERTAD',
      'MABINI',
      'MERCEDES',
      'PAGSA',
      'PAZ',
      'RIZAL',
      'SAN JOSE',
      'SANTA RITA',
      'TEGUIS',
      'WESTERN POBLACION',
    ],
  },
  RONDA: {
    province: 'CEBU',
    municipality: 'RONDA',
    region: 'REGION VII',
    barangay_list: [
      'BUTONG',
      'CAN-ABUHON',
      'CANDULING',
      'CANSALONOY',
      'CANSAYAHON',
      'ILAYA',
      'LANGIN',
      'LIBO-O',
      'MALALAY',
      'PALANAS',
      'POBLACION',
      'SANTA CRUZ',
      'TUPAS',
      'VIVE',
    ],
  },
  SAMBOAN: {
    province: 'CEBU',
    municipality: 'SAMBOAN',
    region: 'REGION VII',
    barangay_list: [
      'BASAK',
      'BONBON',
      'BULANGSURAN',
      'CALATAGAN',
      'CAMBIGONG',
      'CAMBUROY',
      'CANORONG',
      'COLASE',
      'DALAHIKAN',
      'JUMANGPAS',
      'MONTEVERDE',
      'POBLACION',
      'SAN SEBASTIAN',
      'SUBA',
      'TANGBO',
    ],
  },
  SANTANDER: {
    province: 'CEBU',
    municipality: 'SANTANDER',
    region: 'REGION VII',
    barangay_list: [
      'BUNLAN',
      'CABUTONGAN',
      'CANDAMIANG',
      'CANLUMACAD',
      'LILOAN',
      'LIP-TONG',
      'LOOC',
      'PASIL',
      'POBLACION',
      'TALISAY',
    ],
  },
  SIBONGA: {
    province: 'CEBU',
    municipality: 'SIBONGA',
    region: 'REGION VII',
    barangay_list: [
      'ABUGON',
      'BAE',
      'BAGACAY',
      'BAHAY',
      'BANLOT',
      'BASAK',
      'BATO',
      'CAGAY',
      'CAN-AGA',
      'CANDAGUIT',
      'CANTOLAROY',
      'DUGOAN',
      'GUIMBANGCO-AN',
      'LAMACAN',
      'LIBO',
      'LINDOGON',
      'MAGCAGONG',
      'MANATAD',
      'MANGYAN',
      'PAPAN',
      'POBLACION',
      'SABANG',
      'SAYAO',
      'SIMALA',
      'TUBOD',
    ],
  },
  SOGOD: {
    province: 'SOUTHERN LEYTE',
    municipality: 'SOGOD',
    region: 'REGION VIII',
    barangay_list: [
      'BENIT',
      'BUAC DAKU',
      'BUAC GAMAY',
      'CABADBARAN',
      'CONCEPCION',
      'CONSOLACION',
      'DAGSA',
      'HIBOD-HIBOD',
      'HINDANGAN',
      'HIPANTAG',
      'JAVIER',
      'KAHUPIAN',
      'KANANGKAAN',
      'KAUSWAGAN',
      'LA PURISIMA CONCEPCION',
      'LIBAS',
      'LUM-AN',
      'MABICAY',
      'MAC',
      'MAGATAS',
      'MAHAYAHAY',
      'MALINAO',
      'MARIA PLANA',
      'MILAGROSO',
      'OLISIHAN',
      'PANCHO VILLA',
      'PANDAN',
      'RIZAL',
      'SALVACION',
      'SAN FRANCISCO MABUHAY',
      'SAN ISIDRO',
      'SAN JOSE',
      'SAN JUAN (AGATA)',
      'SAN MIGUEL',
      'SAN PEDRO',
      'SAN ROQUE',
      'SAN VICENTE',
      'SANTA MARIA',
      'SUBA',
      'TAMPOONG',
      'ZONE I (POB.)',
      'ZONE II (POB.)',
      'ZONE III (POB.)',
      'ZONE IV (POB.)',
      'ZONE V (POB.)',
    ],
  },
  TABOGON: {
    province: 'CEBU',
    municipality: 'TABOGON',
    region: 'REGION VII',
    barangay_list: [
      'ALANG-ALANG',
      'CADUAWAN',
      'CAMOBOAN',
      'CANAOCANAO',
      'COMBADO',
      'DAANTABOGON',
      'ILIHAN',
      'KAL-ANAN',
      'LABANGON',
      'LIBJO',
      'LOONG',
      'MABULI',
      'MANAGASE',
      'MANLAGTANG',
      'MASLOG',
      'MUABOG',
      'PIO',
      'POBLACION',
      'SALAG',
      'SAMBAG',
      'SAN ISIDRO',
      'SAN VICENTE',
      'SOMOSA',
      'TABA-AO',
      'TAPUL',
    ],
  },
  TABUELAN: {
    province: 'CEBU',
    municipality: 'TABUELAN',
    region: 'REGION VII',
    barangay_list: [
      'BONGON',
      'DALID',
      'KANLIM-AO',
      'KANLUHANGON',
      'KANTUBAON',
      'MABUNAO',
      'MARAVILLA',
      'OLIVO',
      'POBLACION',
      'TABUNOK',
      'TIGBAWAN',
      'VILLAHERMOSA',
    ],
  },
  'TOLEDO': {
    province: 'CEBU',
    municipality: 'TOLEDO',
    region: 'REGION VII',
    barangay_list: [
      'AWIHAO',
      'BAGAKAY',
      'BATO',
      'BIGA',
      'BULONGAN',
      'BUNGA',
      'CABITOONAN',
      'CALONGCALONG',
      'CAMBANG-UG',
      'CAMP 8',
      'CANLUMAMPAO',
      'CANTABACO',
      'CAPITAN CLAUDIO',
      'CARMEN',
      'DAANGLUNGSOD',
      'DON ANDRES SORIANO (LUTOPAN)',
      'DUMLOG',
      'GEN. CLIMACO (MALUBOG)',
      'IBO',
      'ILIHAN',
      'JUAN CLIMACO, SR. (MAGDUGO)',
      'LANDAHAN',
      'LOAY',
      'LURAY II',
      'MATAB-ANG',
      'MEDIA ONCE',
      'PANGAMIHAN',
      'POBLACION',
      'POOG',
      'PUTINGBATO',
      'SAGAY',
      'SAM-ANG',
      'SANGI',
      'SANTO NIÑO (MAINGGIT)',
      'SUBAYON',
      'TALAVERA',
      'TUBOD',
      'TUNGKAY',
    ],
  },
  TUBURAN: {
    province: 'BASILAN',
    municipality: 'TUBURAN',
    region: 'BARMM',
    barangay_list: [
      'BOHETAMBIS',
      'CALUT',
      'DUGA-A',
      'KATIPUNAN',
      'LAHI-LAHI',
      'LOWER SINANGKAPAN',
      'LOWER TABLAS',
      'MAHAWID',
      'SINULATAN',
      'TABLAS USEW',
    ],
  },
  'AMLAN (AYUQUITAN)': {
    province: 'NEGROS ORIENTAL',
    municipality: 'AMLAN (AYUQUITAN)',
    region: 'REGION VII',
    barangay_list: [
      'BIO-OS',
      'JANTIANON',
      'JUGNO',
      'MAG-ABO',
      'POBLACION',
      'SILAB',
      'TAMBOJANGIN',
      'TANDAYAG',
    ],
  },
  AYUNGON: {
    province: 'NEGROS ORIENTAL',
    municipality: 'AYUNGON',
    region: 'REGION VII',
    barangay_list: [
      'AMDUS',
      'ANIBONG',
      'ATABAY',
      'AWA-AN',
      'BAN-BAN',
      'CALAGCALAG',
      'CANDANA-AY',
      'CAROL-AN',
      'GOMENTOC',
      'INACBAN',
      'INIBAN',
      'JANDALAMANON',
      'KILABAN',
      'LAMIGAN',
      'MAASLUM',
      'MABATO',
      'MANOGTONG',
      'NABHANG',
      'POBLACION',
      'TAMBO',
      'TAMPOCON I',
      'TAMPOCON II',
      'TIBYAWAN',
      'TIGUIB',
    ],
  },
  BACONG: {
    province: 'NEGROS ORIENTAL',
    municipality: 'BACONG',
    region: 'REGION VII',
    barangay_list: [
      'BALAYAGMANOK',
      'BANILAD',
      'BUNTIS',
      'BUNTOD',
      'CALANGAG',
      'COMBADO',
      'DOLDOL',
      'ISUGAN',
      'LIPTONG',
      'LUTAO',
      'MAGSUHOT',
      'MALABAGO',
      'MAMPAS',
      'NORTH POBLACION',
      'SACSAC',
      'SAN MIGUEL',
      'SOUTH POBLACION',
      'SULODPAN',
      'TIMBANGA',
      'TIMBAO',
      'TUBOD',
      'WEST POBLACION',
    ],
  },
  'BAIS': {
    province: 'NEGROS ORIENTAL',
    municipality: 'BAIS',
    region: 'REGION VII',
    barangay_list: [
      'BARANGAY I (POB.)',
      'BARANGAY II (POB.)',
      'BASAK',
      'BIÑOHON',
      'CABANLUTAN',
      'CALASGA-AN',
      'CAMBAGAHAN',
      'CAMBAGUIO',
      'CAMBANJAO',
      'CAMBUILAO',
      'CANLARGO',
      'CAPIÑAHAN',
      'CONSOLACION',
      'DANSULAN',
      'HANGYAD',
      'KATACGAHAN (TACGAHAN)',
      'LA PAZ',
      'LO-OC',
      'LONOY',
      'MABUNAO',
      'MANLIPAC',
      'MANSANGABAN',
      'OKIOT',
      'OLYMPIA',
      'PANALA-AN',
      'PANAM-ANGAN',
      'ROSARIO',
      'SAB-AHAN',
      'SAN ISIDRO',
      'TAGPO',
      'TALUNGON',
      'TAMISU',
      'TAMOGONG',
      'TANGCULOGAN',
      'VALENCIA',
    ],
  },
  BASAY: {
    province: 'NEGROS ORIENTAL',
    municipality: 'BASAY',
    region: 'REGION VII',
    barangay_list: [
      'ACTIN',
      'BAL-OS',
      'BONGALONAN',
      'CABALAYONGAN',
      'CABATUANAN',
      'LINANTAYAN',
      'MAGLINAO',
      'NAGBO-ALAO',
      'OLANDAO',
      'POBLACION',
    ],
  },
  'BAYAWAN CITY (TULONG)': {
    province: 'NEGROS ORIENTAL',
    municipality: 'BAYAWAN CITY (TULONG)',
    region: 'REGION VII',
    barangay_list: [
      'ALI-IS',
      'BANAYBANAY',
      'BANGA',
      'BOYCO',
      'BUGAY',
      'CANSUMALIG',
      'DAWIS',
      'KALAMTUKAN',
      'KALUMBOYAN',
      'MALABUGAS',
      'MANDU-AO',
      'MANINIHON',
      'MINABA',
      'NANGKA',
      'NARRA',
      'PAGATBAN',
      'POBLACION',
      'SAN ISIDRO',
      'SAN JOSE',
      'SAN MIGUEL',
      'SAN ROQUE',
      'SUBA (POB.)',
      'TABUAN',
      'TAYAWAN',
      'TINAGO (POB.)',
      'UBOS (POB.)',
      'VILLAREAL',
      'VILLASOL (BATO)',
    ],
  },
  'BINDOY (PAYABON)': {
    province: 'NEGROS ORIENTAL',
    municipality: 'BINDOY (PAYABON)',
    region: 'REGION VII',
    barangay_list: [
      'ATOTES',
      'BATANGAN',
      'BULOD',
      'CABCABAN',
      'CABUGAN',
      'CAMUDLAS',
      'CANLUTO',
      'DANAO',
      'DANAWAN',
      'DOMOLOG',
      'MALAGA',
      'MANSEJE',
      'MATOBATO',
      'NAGCASUNOG',
      'NALUNDAN',
      'PANGALAYCAYAN',
      'PEÑAHAN',
      'POBLACION (PAYABON)',
      'SALONG',
      'TAGAYTAY',
      'TINAOGAN',
      'TUBOD',
    ],
  },
  'CANLAON': {
    province: 'NEGROS ORIENTAL',
    municipality: 'CANLAON',
    region: 'REGION VII',
    barangay_list: [
      'BAYOG',
      'BINALBAGAN',
      'BUCALAN (EAST BUDSALAN)',
      'BUDLASAN (WEST BUDLASAN)',
      'LINOTHANGAN',
      'LUMAPAO',
      'MABIGO (POB.)',
      'MALAIBA',
      'MASULOG',
      'NINOY AQUINO',
      'PANUBIGAN',
      'PULA',
    ],
  },
  DAUIN: {
    province: 'NEGROS ORIENTAL',
    municipality: 'DAUIN',
    region: 'REGION VII',
    barangay_list: [
      'ANAHAWAN',
      'APO ISLAND',
      'BAGACAY',
      'BASLAY',
      'BATUHON DACU',
      'BOLOC-BOLOC',
      'BULAK',
      'BUNGA',
      'CASILE',
      'LIBJO',
      'LIPAYO',
      'MAAYONGTUBIG',
      'MAG-ASO',
      'MAGSAYSAY',
      'MALONGCAY DACU',
      'MASAPLOD NORTE',
      'MASAPLOD SUR',
      'PANUBTUBAN',
      'POBLACION I',
      'POBLACION II',
      'POBLACION III',
      'TUGAWE',
      'TUNGA-TUNGA',
    ],
  },
  'DUMAGUETE': {
    province: 'NEGROS ORIENTAL',
    municipality: 'DUMAGUETE',
    region: 'REGION VII',
    barangay_list: [
      'BAGACAY',
      'BAJUMPANDAN',
      'BALUGO',
      'BANILAD',
      'BANTAYAN',
      'BATINGUEL',
      'BUNAO',
      'CADAWINONAN',
      'CALINDAGAN',
      'CAMANJAC',
      'CANDAU-AY',
      'CANTIL-E',
      'DARO',
      'JUNOB',
      'LOOC',
      'MANGNAO-CANAL',
      'MOTONG',
      'PIAPI',
      'POBLACION NO. 1 (BARANGAY 1)',
      'POBLACION NO. 2 (BARANGAY 2)',
      'POBLACION NO. 3 (BARANGAY 3)',
      'POBLACION NO. 4 (BARANGAY 4)',
      'POBLACION NO. 5 (BARANGAY 5)',
      'POBLACION NO. 6 (BARANGAY 6)',
      'POBLACION NO. 7 (BARANGAY 7)',
      'POBLACION NO. 8 (BARANGAY 8)',
      'PULANTUBIG',
      'TABUCTUBIG',
      'TACLOBO',
      'TALAY',
    ],
  },
  'GUIHULNGAN': {
    province: 'NEGROS ORIENTAL',
    municipality: 'GUIHULNGAN',
    region: 'REGION VII',
    barangay_list: [
      'BAKID',
      'BALOGO',
      'BANWAQUE',
      'BASAK',
      'BINOBOHAN',
      'BUENAVISTA',
      'BULADO',
      'CALAMBA',
      'CALUPA-AN',
      'HIBAIYO',
      'HILAITAN',
      'HINAKPAN',
      'HUMAYHUMAY',
      'IMELDA',
      'KAGAWASAN',
      'LINANTUYAN',
      'LUZ',
      'MABUNGA',
      'MAGSAYSAY',
      'MALUSAY',
      'MANIAK',
      'MCKINLEY',
      'NAGSAHA',
      'PADRE ZAMORA',
      'PLAGATASANON',
      'PLANAS',
      'POBLACION',
      'SANDAYAO',
      'TACPAO',
      'TINAYUNAN BEACH',
      'TINAYUNAN HILL',
      'TRINIDAD',
      'VILLEGAS',
    ],
  },
  JIMALALUD: {
    province: 'NEGROS ORIENTAL',
    municipality: 'JIMALALUD',
    region: 'REGION VII',
    barangay_list: [
      'AGLAHUG',
      'AGUTAYON',
      'APANANGON',
      'BAE',
      'BALA-AS',
      'BANGCAL',
      'BANOG',
      'BUTO',
      'CABANG',
      'CAMANDAYON',
      'CANGHARAY',
      'CANLAHAO',
      'DAYOYO',
      'ELI',
      'LACAON',
      'MAHANLUD',
      'MALABAGO',
      'MAMBAID',
      'MONGPONG',
      'NORTH POBLACION',
      'OWACAN',
      'PACUAN',
      'PANGLAYA-AN',
      'POLOPANTAO',
      'SAMPINITON',
      'SOUTH POBLACION',
      'TALAMBAN',
      'TAMAO',
    ],
  },
  'LA LIBERTAD': {
    province: 'ZAMBOANGA DEL NORTE',
    municipality: 'LA LIBERTAD',
    region: 'REGION IX',
    barangay_list: [
      'EL PARAISO',
      'LA UNION',
      'LA VICTORIA',
      'MAUSWAGON',
      'MERCEDES',
      'NEW ARGAO',
      'NEW BATAAN',
      'NEW CARCAR',
      'POBLACION',
      'SAN JOSE',
      'SANTA CATALINA',
      'SANTA CRUZ',
      'SINGARAN',
    ],
  },
  MABINAY: {
    province: 'NEGROS ORIENTAL',
    municipality: 'MABINAY',
    region: 'REGION VII',
    barangay_list: [
      'ABIS',
      'AREBASORE',
      'BAGTIC',
      'BANBAN',
      'BARRAS',
      'BATO',
      'BUGNAY',
      'BULIBULIHAN',
      'BULWANG',
      'CAMPANUN-AN',
      'CANGGOHOB',
      'CANSAL-ING',
      'DAGBASAN',
      'DAHILE',
      'HAGTU',
      'HIMOCDONGON',
      'INAPOY',
      'LAMDAS',
      'LUMBANGAN',
      'LUYANG',
      'MANLINGAY',
      'MAYAPOSI',
      'NAPASU-AN',
      'NEW NAMANGKA',
      'OLD NAMANGKA',
      'PANDANON',
      'PANIABONAN',
      'PANTAO',
      'POBLACION',
      'SAMAC',
      'TADLONG',
      'TARA',
    ],
  },
  MANJUYOD: {
    province: 'NEGROS ORIENTAL',
    municipality: 'MANJUYOD',
    region: 'REGION VII',
    barangay_list: [
      'ALANGILANAN',
      'BAGTIC',
      'BALAAS',
      'BANTOLINAO',
      'BOLISONG',
      'BUTONG',
      'CAMPUYO',
      'CANDABONG',
      'CONCEPCION',
      'DUNGO-AN',
      'KAUSWAGAN',
      'LAMOGONG',
      'LIBJO',
      'MAASLUM',
      'MANDALUPANG',
      'PANCIAO',
      'POBLACION',
      'SAC-SAC',
      'SALVACION',
      'SAN ISIDRO',
      'SAN JOSE',
      'SANTA MONICA',
      'SUBA',
      'SUNDO-AN',
      'TANGLAD',
      'TUBOD',
      'TUPAS',
    ],
  },
  SIATON: {
    province: 'NEGROS ORIENTAL',
    municipality: 'SIATON',
    region: 'REGION VII',
    barangay_list: [
      'ALBIGA',
      'APOLOY',
      'BONAWON',
      'BONBONON',
      'CABANGAHAN',
      'CANAWAY',
      'CASALA-AN',
      'CATICUGAN',
      'DATAG',
      'GILIGA-ON',
      'INALAD',
      'MALABUHAN',
      'MALOH',
      'MANTIQUIL',
      'MANTUYOP',
      'NAPACAO',
      'POBLACION I',
      'POBLACION II',
      'POBLACION III',
      'POBLACION IV',
      'SALAG',
      'SAN JOSE',
      'SANDULOT',
      'SI-IT',
      'SUMALIRING',
      'TAYAK',
    ],
  },
  SIBULAN: {
    province: 'NEGROS ORIENTAL',
    municipality: 'SIBULAN',
    region: 'REGION VII',
    barangay_list: [
      'AGAN-AN',
      'AJONG',
      'BALUGO',
      'BOLOCBOLOC',
      'CALABNUGAN',
      'CANGMATING',
      'ENRIQUE VILLANUEVA',
      'LOOC',
      'MAGATAS',
      'MANINGCAO',
      'MASLOG',
      'POBLACION',
      'SAN ANTONIO',
      'TUBIGON',
      'TUBTUBON',
    ],
  },
  'TANJAY': {
    province: 'NEGROS ORIENTAL',
    municipality: 'TANJAY',
    region: 'REGION VII',
    barangay_list: [
      'AZAGRA',
      'BAHI-AN',
      'LUCA',
      'MANIPIS',
      'NOVALLAS',
      'OBOGON',
      'PAL-EW',
      'POBLACION I (BARANGAY 1)',
      'POBLACION II (BARANGAY 2)',
      'POBLACION III (BARANGAY 3)',
      'POBLACION IV (BARANGAY 4)',
      'POBLACION IX (BARANGAY 9)',
      'POBLACION V (BARANGAY 5)',
      'POBLACION VI (BARANGAY 6)',
      'POBLACION VII (BARANGAY 7)',
      'POBLACION VIII (BARANGAY 8)',
      'POLO',
      'SAN ISIDRO',
      'SAN JOSE',
      'SAN MIGUEL',
      'SANTA CRUZ NUEVO',
      'SANTA CRUZ VIEJO',
      'SANTO NIÑO',
      'TUGAS',
    ],
  },
  TAYASAN: {
    province: 'NEGROS ORIENTAL',
    municipality: 'TAYASAN',
    region: 'REGION VII',
    barangay_list: [
      'BACONG',
      'BAGO',
      'BANGA',
      'CABULOTAN',
      'CAMBAYE',
      'DALAUPON',
      'GUINCALABAN',
      'ILAYA-TAYASAN',
      'JILABANGAN',
      'LAG-IT',
      'LINAO',
      'LUTAY',
      'MAGLIHE',
      'MAGTUHAO',
      'MATAUTA',
      'MATUOG',
      'NUMNUM',
      'PALASLAN',
      'PINALUBNGAN',
      'PINDAHAN',
      'PINOCAWAN',
      'POBLACION',
      'SANTA CRUZ',
      'SAYING',
      'SUQUIB',
      'TAMAO',
      'TAMBULAN',
      'TANLAD',
    ],
  },
  'VALENCIA (LUZURRIAGA)': {
    province: 'NEGROS ORIENTAL',
    municipality: 'VALENCIA (LUZURRIAGA)',
    region: 'REGION VII',
    barangay_list: [
      'APOLONG',
      'BALABAG EAST',
      'BALABAG WEST',
      'BALAYAGMANOK',
      'BALILI',
      'BALUGO',
      'BONG-AO',
      'BONGBONG',
      'CAIDIOCAN',
      'CALAYUGAN',
      'CAMBUCAD',
      'DOBDOB',
      'JAWA',
      'LIPTONG',
      'LUNGA',
      'MALABO',
      'MALAUNAY',
      'MAMPAS',
      'NORTH POBLACION',
      'PALINPINON',
      'PUHAGAN',
      'PULANGBATO',
      'SAGBANG',
      'SOUTH POBLACION',
    ],
  },
  VALLEHERMOSO: {
    province: 'NEGROS ORIENTAL',
    municipality: 'VALLEHERMOSO',
    region: 'REGION VII',
    barangay_list: [
      'BAGAWINES',
      'BAIRAN',
      'CABULIHAN',
      'DON ESPIRIDION VILLEGAS',
      'GUBA',
      'MACAPSO',
      'MAGLAHOS',
      'MALANGSA',
      'MOLOBOLO',
      'PINOCAWAN',
      'POBLACION',
      'PUAN',
      'TABON',
      'TAGBINO',
      'ULAY',
    ],
  },
  ZAMBOANGUITA: {
    province: 'NEGROS ORIENTAL',
    municipality: 'ZAMBOANGUITA',
    region: 'REGION VII',
    barangay_list: [
      'BASAC',
      'CALANGO',
      'LOTUBAN',
      'MALONGCAY DIOT',
      'MALUAY',
      'MAYABON',
      'NABAGO',
      'NAJANDIG',
      'NASIG-ID',
      'POBLACION',
    ],
  },
  'ENRIQUE VILLANUEVA': {
    province: 'SIQUIJOR',
    municipality: 'ENRIQUE VILLANUEVA',
    region: 'REGION VII',
    barangay_list: [
      'BALOLONG',
      'BINO-ONGAN',
      'BITAUG',
      'BOLOT',
      'CAMOGAO',
      'CANGMANGKI',
      'LIBO',
      'LOMANGCAPAN',
      'LOTLOTON',
      'MANAN-AO',
      'OLAVE',
      'PARIAN',
      'POBLACION',
      'TULAPOS',
    ],
  },
  LARENA: {
    province: 'SIQUIJOR',
    municipality: 'LARENA',
    region: 'REGION VII',
    barangay_list: [
      'BAGACAY',
      'BALOLANG',
      'BASAC',
      'BINTANGAN',
      'BONTOD',
      'CABULIHAN',
      'CALUNASAN',
      'CANDIGUM',
      'CANG-ALLAS',
      'CANG-APA',
      'CANGBAGSA',
      'CANGMALALAG',
      'CANLAMBO',
      'CANLASOG',
      'CATAMBOAN',
      'HELEN (DATAG)',
      'NONOC',
      'NORTH POBLACION',
      'PONONG',
      'SABANG',
      'SANDUGAN',
      'SOUTH POBLACION',
      'TACULING',
    ],
  },
  LAZI: {
    province: 'SIQUIJOR',
    municipality: 'LAZI',
    region: 'REGION VII',
    barangay_list: [
      'CAMPALANAS',
      'CANGCLARAN',
      'CANGOMANTONG',
      'CAPALASANAN',
      'CATAMBOAN (POB.)',
      'GABAYAN',
      'KIMBA',
      'KINAMANDAGAN',
      'LOWER CABANGCALAN',
      'NAGERONG',
      'PO-O',
      'SIMACOLONG',
      'TAGMANOCAN',
      'TALAYONG',
      'TIGBAWAN (POB.)',
      'TIGNAO',
      'UPPER CABANGCALAN',
      'YTAYA',
    ],
  },
  MARIA: {
    province: 'SIQUIJOR',
    municipality: 'MARIA',
    region: 'REGION VII',
    barangay_list: [
      'BOGO',
      'BONGA',
      'CABAL-ASAN',
      'CALUNASAN',
      'CANDAPING A',
      'CANDAPING B',
      'CANTAROC A',
      'CANTAROC B',
      'CANTUGBAS',
      'LICO-AN',
      'LILO-AN',
      'LOGUCAN',
      'LOOC',
      'MINALULAN',
      'NABUTAY',
      'OLANG',
      'PISONG A',
      'PISONG B',
      'POBLACION NORTE',
      'POBLACION SUR',
      'SAGUING',
      'SAWANG',
    ],
  },
  SIQUIJOR: {
    province: 'SIQUIJOR',
    municipality: 'SIQUIJOR',
    region: 'REGION VII',
    barangay_list: [
      'BANBAN',
      'BOLOS',
      'CAIPILAN',
      'CAITICAN',
      'CALALINAN',
      'CANAL',
      'CANDANAY NORTE',
      'CANDANAY SUR',
      'CANG-ADIENG',
      'CANG-AGONG',
      'CANG-ALWANG',
      'CANG-ASA',
      'CANG-ATUYOM',
      'CANG-INTE',
      'CANG-ISAD',
      'CANGHUNOGHUNOG',
      'CANGMATNOG',
      'CANGMOHAO',
      'CANTABON',
      'CATICUGAN',
      'DUMANHOG',
      'IBABAO',
      'LAMBOJON',
      'LUYANG',
      'LUZONG',
      'OLO',
      'PANGI',
      'PANLAUTAN',
      'PASIHAGON',
      'PILI',
      'POBLACION',
      'POLANGYUTA',
      'PONONG',
      'SABANG',
      'SAN ANTONIO',
      'SONGCULAN',
      'TACDOG',
      'TACLOBAN',
      'TAMBISAN',
      'TEBJONG',
      'TINAGO',
      'TONGO',
    ],
  },
  ALMERIA: {
    province: 'BILIRAN',
    municipality: 'ALMERIA',
    region: 'REGION VIII',
    barangay_list: [
      'CAUCAB',
      'IYOSAN',
      'JAMORAWON',
      'LO-OK',
      'MATANGA',
      'PILI',
      'POBLACION',
      'PULANG BATO',
      'SALANGI',
      'SAMPAO',
      'TABUNAN',
      'TALAHID',
      'TAMARINDO',
    ],
  },
  BILIRAN: {
    province: 'BILIRAN',
    municipality: 'BILIRAN',
    region: 'REGION VIII',
    barangay_list: [
      'BATO',
      'BURABOD',
      'BUSALI',
      'CANILA',
      'HUGPA',
      'JULITA',
      'PINANGUMHAN',
      'SAN ISIDRO (POB.)',
      'SAN ROQUE (POB.)',
      'SANGGALANG',
      'VILLA ENAGE (BARAS)',
    ],
  },
  CABUCGAYAN: {
    province: 'BILIRAN',
    municipality: 'CABUCGAYAN',
    region: 'REGION VIII',
    barangay_list: [
      'BALAQUID',
      'BASO',
      'BUNGA',
      'CAANIBONGAN',
      'CASIAWAN',
      'ESPERANZA (POB.)',
      'LANGGAO',
      'LIBERTAD',
      'LOOC',
      'MAGBANGON (POB.)',
      'PAWIKAN',
      'SALAWAD',
      'TALIBONG',
    ],
  },
  CAIBIRAN: {
    province: 'BILIRAN',
    municipality: 'CAIBIRAN',
    region: 'REGION VIII',
    barangay_list: [
      'ALEGRIA',
      'ASUG',
      'BARI-IS',
      'BINOHANGAN',
      'CABIBIHAN',
      'CAULANGOHAN (MAREVIL)',
      'KAWAYANON',
      'LOOC',
      'MANLABANG',
      'MAURANG',
      'PALANAY (POB.)',
      'PALENGKE (POB.)',
      'TOMALISTIS',
      'UNION',
      'USON',
      'VICTORY (POB.)',
      'VILLA VICENTA (MAINIT)',
    ],
  },
  CULABA: {
    province: 'BILIRAN',
    municipality: 'CULABA',
    region: 'REGION VIII',
    barangay_list: [
      'ACABAN',
      'BACOLOD',
      'BINONGTOAN',
      'BOOL CENTRAL (POB.)',
      'BOOL EAST (POB.)',
      'BOOL WEST (POB.)',
      'CALIPAYAN',
      'CULABA CENTRAL (POB.)',
      'GUINDAPUNAN',
      'HABUHAB',
      'LOOC',
      'MARVEL (POB.)',
      'PATAG',
      'PINAMIHAGAN',
      'SALVACION',
      'SAN ROQUE',
      'VIRGINIA (POB.)',
    ],
  },
  KAWAYAN: {
    province: 'BILIRAN',
    municipality: 'KAWAYAN',
    region: 'REGION VIII',
    barangay_list: [
      'BAGANITO',
      'BALACSON',
      'BALITE',
      'BILWANG',
      'BULALACAO',
      'BURABOD',
      'BUYO',
      'INASUYAN',
      'KANSANOK',
      'MADA-O',
      'MAPUYO',
      'MASAGAOSAO',
      'MASAGONGSONG',
      'POBLACION',
      'SAN LORENZO',
      'TABUNAN NORTH',
      'TUBIG GUINOO',
      'TUCDAO',
      'UNGALE',
      'VILLA CORNEJO (LOOC)',
    ],
  },
  MARIPIPI: {
    province: 'BILIRAN',
    municipality: 'MARIPIPI',
    region: 'REGION VIII',
    barangay_list: [
      'AGUTAY',
      'BANLAS',
      'BATO',
      'BINALAYAN EAST',
      'BINALAYAN WEST',
      'BINONGTO-AN (POBLACION NORTE)',
      'BURABOD',
      'CALBANI',
      'CANDUHAO',
      'CASIBANG',
      'DANAO',
      'ERMITA (POBLACION SUR)',
      'OL-OG',
      'TRABUGAN',
      'VIGA',
    ],
  },
  NAVAL: {
    province: 'BILIRAN',
    municipality: 'NAVAL',
    region: 'REGION VIII',
    barangay_list: [
      'AGPANGI',
      'ANISLAGAN',
      'ATIPOLO',
      'BORAC',
      'CABUNGAAN',
      'CALUMPANG',
      'CAPIÑAHAN',
      'CARAYCARAY',
      'CATMON',
      'HAGUIKHIKAN',
      'IMELDA',
      'LARRAZABAL',
      'LIBERTAD',
      'LIBTONG',
      'LICO',
      'LUCSOON',
      'MABINI',
      'PADRE INOCENTES GARCIA (POB.)',
      'PADRE SERGIO EAMIGUEL',
      'SABANG',
      'SAN PABLO',
      'SANTISSIMO ROSARIO POB. (SANTO ROSA)',
      'SANTO NIÑO',
      'TALUSTUSAN',
      'VILLA CANEJA',
      'VILLA CONSUELO',
    ],
  },
  ARTECHE: {
    province: 'EASTERN SAMAR',
    municipality: 'ARTECHE',
    region: 'REGION VIII',
    barangay_list: [
      'AGUINALDO',
      'BALUD (POB.)',
      'BATO (SAN LUIS)',
      'BERI',
      'BIGO',
      'BUENAVISTA',
      'CAGSALAY',
      'CAMPACION',
      'CARAPDAPAN',
      'CASIDMAN',
      'CATUMSAN',
      'CENTRAL (POB.)',
      'CONCEPCION',
      'GARDEN (POB.)',
      'INAYAWAN',
      'MACARTHUR',
      'RAWIS (POB.)',
      'TANGBO',
      'TAWAGAN',
      'TEBALAWON',
    ],
  },
  BALANGIGA: {
    province: 'EASTERN SAMAR',
    municipality: 'BALANGIGA',
    region: 'REGION VIII',
    barangay_list: [
      'BACJAO',
      'BARANGAY POBLACION I',
      'BARANGAY POBLACION II',
      'BARANGAY POBLACION III',
      'BARANGAY POBLACION IV',
      'BARANGAY POBLACION V',
      'BARANGAY POBLACION VI',
      'CAG-OLANGO',
      'CANSUMANGCAY',
      'GUINMAAYOHAN',
      'MAYBUNGA',
      'SAN MIGUEL',
      'SANTA ROSA',
    ],
  },
  BALANGKAYAN: {
    province: 'EASTERN SAMAR',
    municipality: 'BALANGKAYAN',
    region: 'REGION VIII',
    barangay_list: [
      'BALOGO',
      'BANGON',
      'CABAY',
      'CAISAWAN',
      'CANTUBI',
      'GENERAL MALVAR',
      'GUINPOLIRAN',
      'JULAG',
      'MAGSAYSAY',
      'MARAMAG',
      'POBLACION I',
      'POBLACION II',
      'POBLACION III',
      'POBLACION IV',
      'POBLACION V',
    ],
  },
  'BORONGAN': {
    province: 'EASTERN SAMAR',
    municipality: 'BORONGAN',
    region: 'REGION VIII',
    barangay_list: [
      'ALANG-ALANG',
      'AMANTACOP',
      'ANDO',
      'BALACDAS',
      'BALUD',
      'BANUYO',
      'BARAS',
      'BATO',
      'BAYOBAY',
      'BENOWANGAN',
      'BUGAS',
      'CABALAGNAN',
      'CABONG',
      'CAGBONGA',
      'CALICO-AN',
      'CALINGATNGAN',
      'CAMADA',
      'CAMPESAO',
      'CAN-ABONG',
      'CAN-AGA',
      'CANJAWAY',
      'CANLARAY',
      'CANYOPAY',
      'DIVINUBO',
      'HEBACONG',
      'HINDANG',
      'LALAWIGAN',
      'LIBUTON',
      'LOCSO-ON',
      'MAYBACONG',
      'MAYPANGDAN',
      'PEPELITAN',
      'PINANAG-AN',
      'PUNTA MARIA',
      'PUROK A (POB.)',
      'PUROK B (POB.)',
      'PUROK C (POB.)',
      'PUROK D1 (POB.)',
      'PUROK D2 (POB.)',
      'PUROK E (POB.)',
      'PUROK F (POB.)',
      'PUROK G (POB.)',
      'PUROK H (POB.)',
      'SABANG NORTH',
      'SABANG SOUTH',
      'SAN ANDRES',
      'SAN GABRIEL',
      'SAN GREGORIO',
      'SAN JOSE',
      'SAN MATEO',
      'SAN PABLO',
      'SAN SATURNINO',
      'SANTA FE',
      'SIHA',
      'SOHUTAN',
      'SONGCO',
      'SURIBAO',
      'SUROK',
      'TABOC',
      'TABUNAN',
      'TAMOSO',
    ],
  },
  'CAN-AVID': {
    province: 'EASTERN SAMAR',
    municipality: 'CAN-AVID',
    region: 'REGION VIII',
    barangay_list: [
      'BALAGON',
      'BARANGAY 1 POBLACION',
      'BARANGAY 2 POBLACION',
      'BARANGAY 3 POBLACION',
      'BARANGAY 4 POBLACION',
      'BARANGAY 5 POBLACION',
      'BARANGAY 6 POBLACION',
      'BARANGAY 7 POBLACION',
      'BARANGAY 8 POBLACION',
      'BARANGAY 9 POBLACION',
      'BARANGAY 10 POBLACION',
      'BARUK',
      'BOCO',
      'CAGHALONG',
      'CAMANTANG',
      'CAN-ILAY',
      'CANSANGAYA',
      'CANTEROS',
      'CAROLINA',
      'GUIBUANGAN',
      'JEPACO',
      'MABUHAY',
      'MALOGO',
      'OBONG',
      'PANDOL',
      'RAWIS',
      'SALVACION',
      'SOLONG',
    ],
  },
  'GENERAL MACARTHUR': {
    province: 'EASTERN SAMAR',
    municipality: 'GENERAL MACARTHUR',
    region: 'REGION VIII',
    barangay_list: [
      'AGUINALDO',
      'ALANG-ALANG',
      'BINALAY',
      'CALUTAN',
      'CAMCUEVAS',
      'DOMROG',
      'LAUREL',
      'LIMBUJAN',
      'MACAPAGAL',
      'MAGSAYSAY',
      'OSMEÑA',
      'PINGAN',
      'POBLACION BARANGAY 1',
      'POBLACION BARANGAY 2',
      'POBLACION BARANGAY 3',
      'POBLACION BARANGAY 4',
      'POBLACION BARANGAY 5',
      'POBLACION BARANGAY 6',
      'POBLACION BARANGAY 7',
      'POBLACION BARANGAY 8',
      'QUEZON',
      'QUIRINO',
      'ROXAS',
      'SAN ISIDRO',
      'SAN ROQUE',
      'SANTA CRUZ (OPONG)',
      'SANTA FE',
      'TANDANG SORA',
      'TUGOP',
      'VIGAN',
    ],
  },
  GIPORLOS: {
    province: 'EASTERN SAMAR',
    municipality: 'GIPORLOS',
    region: 'REGION VIII',
    barangay_list: [
      'BARANGAY 1 (POB.)',
      'BARANGAY 2 (POB.)',
      'BARANGAY 3 (POB.)',
      'BARANGAY 4 (POB.)',
      'BARANGAY 5 (POB.)',
      'BARANGAY 6 (POB.)',
      'BARANGAY 7 (POB.)',
      'BARANGAY 8 (POB.)',
      'BIGA',
      'COTICOT',
      'GIGOSO',
      'HUKNAN',
      'PARINA',
      'PAYA',
      'PRESIDENT ROXAS',
      'SAN ISIDRO (MALABAG)',
      'SAN MIGUEL',
      'SANTA CRUZ (CANSINGKOL)',
    ],
  },
  GUIUAN: {
    province: 'EASTERN SAMAR',
    municipality: 'GUIUAN',
    region: 'REGION VIII',
    barangay_list: [
      'ALINGAROG',
      'BAGUA',
      'BANAAG',
      'BANAHAO',
      'BARAS',
      'BARBO',
      'BITAUGAN',
      'BUCAO',
      'BUENAVISTA',
      'BUNGTOD',
      'CAGDARA-O',
      'CAGUSU-AN',
      'CAMPARANG',
      'CAMPOYONG',
      'CANAWAYON',
      'CANTAHAY',
      'CASUGURAN',
      'COGON',
      'CULASI',
      'DALARAGAN',
      'GAHOY',
      'HABAG',
      'HAGNA',
      'HAMORAWON',
      'HOLLYWOOD',
      'INAPULANGAN',
      'LUPOK (POB.)',
      'MAYANA',
      'NGOLOS',
      'PAGBABANGNAN',
      'PAGNAMITAN',
      'POBLACION WARD 1',
      'POBLACION WARD 2',
      'POBLACION WARD 3',
      'POBLACION WARD 4',
      'POBLACION WARD 4-A',
      'POBLACION WARD 5',
      'POBLACION WARD 6',
      'POBLACION WARD 7',
      'POBLACION WARD 8',
      'POBLACION WARD 9',
      'POBLACION WARD 9-A',
      'POBLACION WARD 10',
      'POBLACION WARD 11',
      'POBLACION WARD 12',
      'SALUG',
      'SAN ANTONIO',
      'SAN JOSE',
      'SAN JUAN',
      'SAN PEDRO',
      'SANTO NIÑO',
      'SAPAO',
      'SULANGAN',
      'SULUAN',
      'SUROK',
      'TAGPORO',
      'TAYTAY',
      'TIMALA',
      'TRINIDAD',
      'VICTORY ISLAND',
    ],
  },
  HERNANI: {
    province: 'EASTERN SAMAR',
    municipality: 'HERNANI',
    region: 'REGION VIII',
    barangay_list: [
      'BARANGAY 1 (POB.)',
      'BARANGAY 2 (POB.)',
      'BARANGAY 3 (POB.)',
      'BARANGAY 4 (POB.)',
      'BATANG',
      'CACATMONAN',
      'CANCILEDES',
      'CARMEN',
      'GARAWON',
      'NAGAJA',
      'PADANG',
      'SAN ISIDRO',
      'SAN MIGUEL',
    ],
  },
  JIPAPAD: {
    province: 'EASTERN SAMAR',
    municipality: 'JIPAPAD',
    region: 'REGION VIII',
    barangay_list: [
      'AGSAMAN',
      'BARANGAY 1 (POB.)',
      'BARANGAY 2 (POB.)',
      'BARANGAY 3 (POB.)',
      'BARANGAY 4 (POB.)',
      'CAGMANABA',
      'DORILLO',
      'JEWARAN',
      'MABUHAY',
      'MAGSAYSAY',
      'RECARE',
      'ROXAS',
      'SAN ROQUE',
    ],
  },
  LAWAAN: {
    province: 'EASTERN SAMAR',
    municipality: 'LAWAAN',
    region: 'REGION VIII',
    barangay_list: [
      'BARANGAY POBLACION 1',
      'BARANGAY POBLACION 2',
      'BARANGAY POBLACION 3',
      'BARANGAY POBLACION 4',
      'BARANGAY POBLACION 5',
      'BARANGAY POBLACION 6',
      'BARANGAY POBLACION 7',
      'BARANGAY POBLACION 8',
      'BARANGAY POBLACION 9',
      'BARANGAY POBLACION 10',
      'BETAOG',
      'BOLUSAO',
      'GUINOB-AN',
      'MASLOG',
      'SAN ISIDRO',
      'TAGUITE',
    ],
  },
  LLORENTE: {
    province: 'EASTERN SAMAR',
    municipality: 'LLORENTE',
    region: 'REGION VIII',
    barangay_list: [
      'ANTIPOLO',
      'BABANIKHON',
      'BACAYAWAN',
      'BARANGAY 1 (POB.)',
      'BARANGAY 2 (POB.)',
      'BARANGAY 3 (POB.)',
      'BARANGAY 4 (POB.)',
      'BARANGAY 5 (POB.)',
      'BARANGAY 6 (POB.)',
      'BARANGAY 7 (POB.)',
      'BARANGAY 8 (POB.)',
      'BARANGAY 9 (POB.)',
      'BARANGAY 10 (POB.)',
      'BARANGAY 11 (POB.)',
      'BARANGAY 12 (POB.)',
      'BAROBO',
      'BURAK',
      'CAN-ATO',
      'CANDOROS',
      'CANLIWAG',
      'CANTOMCO',
      'HUGPA',
      'MACA-ANGA',
      'MAGTINO',
      'MINA-ANOD',
      'NAUBAY',
      'PILIW',
      'SAN JOSE',
      'SAN MIGUEL',
      'SAN ROQUE',
      'SO-ONG',
      'TABOK',
      'WASO',
    ],
  },
  MASLOG: {
    province: 'EASTERN SAMAR',
    municipality: 'MASLOG',
    region: 'REGION VIII',
    barangay_list: [
      'BARANGAY 1 (POB.)',
      'BARANGAY 2 (POB.)',
      'BULAWAN',
      'CARAYACAY',
      'LIBERTAD',
      'MALOBAGO',
      'MAPUTI',
      'SAN MIGUEL',
      'SAN ROQUE',
      'TANGBO',
      'TAYTAY',
      'TUGAS',
    ],
  },
  MAYDOLONG: {
    province: 'EASTERN SAMAR',
    municipality: 'MAYDOLONG',
    region: 'REGION VIII',
    barangay_list: [
      'BARANGAY POBLACION 1',
      'BARANGAY POBLACION 2',
      'BARANGAY POBLACION 3',
      'BARANGAY POBLACION 4',
      'BARANGAY POBLACION 5',
      'BARANGAY POBLACION 6',
      'BARANGAY POBLACION 7',
      'CAMADA',
      'CAMPAKERIT (BOTAY)',
      'CANLOTERIO',
      'DEL PILAR',
      'GUINDALITAN',
      'LAPGAP',
      'MALOBAGO',
      'MAYBOCOG',
      'MAYTIGBAO',
      'OMAWAS',
      'PATAG',
      'SAN GABRIEL',
      'TAGASLIAN',
    ],
  },
  ORAS: {
    province: 'EASTERN SAMAR',
    municipality: 'ORAS',
    region: 'REGION VIII',
    barangay_list: [
      'AGSAM',
      'ALANG-ALANG',
      'BAGACAY',
      'BALINGASAG',
      'BALOCAWE (POB.)',
      'BANTAYAN',
      'BATANG',
      'BATO',
      'BINALAYAN',
      'BUNTAY',
      'BURAK',
      'BUTNGA (POB.)',
      'CADIAN',
      'CAGDINE',
      'CAGPILE',
      'CAGTOOG',
      'CAMANGA (POB.)',
      'DALID',
      'DAO',
      'FACTORIA',
      'GAMOT',
      'IWAYAN',
      'JAPAY',
      'KALAW',
      'MABUHAY',
      'MALINGON',
      'MINAP-OS',
      'NADACPAN',
      'NAGA',
      'PANGUDTAN',
      'PAYPAYON (POB.)',
      'RIVERSIDE (POB.)',
      'RIZAL',
      'SABANG',
      'SAN EDUARDO',
      'SAN ROQUE (POB.)',
      'SANTA MONICA',
      'SAUGAN',
      'SAURONG',
      'TAWAGAN (POB.)',
      'TIGUIB (POB.)',
      'TRINIDAD (MAYCOROT)',
    ],
  },
  QUINAPONDAN: {
    province: 'EASTERN SAMAR',
    municipality: 'QUINAPONDAN',
    region: 'REGION VIII',
    barangay_list: [
      'ALANG-ALANG',
      'ANISLAG',
      'BAGTE',
      'BARANGAY NO. 1 (POB.)',
      'BARANGAY NO. 2 (POB.)',
      'BARANGAY NO. 3 (POB.)',
      'BARANGAY NO. 4 (POB.)',
      'BARANGAY NO. 5 (POB.)',
      'BARANGAY NO. 6 (POB.)',
      'BARANGAY NO. 7 (POB.)',
      'BUENAVISTA',
      'CACULANGAN',
      'CAGDAJA',
      'CAMBILLA',
      'CANTENIO',
      'NAGA',
      'PACO',
      'PALACTAD (VALLEY)',
      'RIZAL (PANA-UGAN)',
      'SAN ISIDRO',
      'SAN PEDRO',
      'SAN VICENTE',
      'SANTA CRUZ (LORO DIYO)',
      'SANTA MARGARITA',
      'SANTO NIÑO',
    ],
  },
  SALCEDO: {
    province: 'EASTERN SAMAR',
    municipality: 'SALCEDO',
    region: 'REGION VIII',
    barangay_list: [
      'ABEJAO',
      'ALOG',
      'ASGAD',
      'BAGTONG',
      'BALUD',
      'BARANGAY 1 (POB.)',
      'BARANGAY 2 (POB.)',
      'BARANGAY 3 (POB.)',
      'BARANGAY 4 (POB.)',
      'BARANGAY 5 (POB.)',
      'BARANGAY 6 (POB.)',
      'BARANGAY 7 (POB.)',
      'BARANGAY 8 (POB.)',
      'BARANGAY 9 (POB.)',
      'BARANGAY 10 (POB.)',
      'BARANGAY 11 (POB.)',
      'BARANGAY 12 (POB.)',
      'BARANGAY 13 (POB.)',
      'BUABUA',
      'BURAK',
      'BUTIG',
      'CAGAUT',
      'CAMANGA',
      'CANTOMOJA',
      'CARAPDAPAN',
      'CARIDAD',
      'CASILI-ON',
      'IBERAN',
      'JAGNAYA',
      'LUSOD',
      'MALBOG',
      'MALIWALIW',
      'MATARINAO',
      'NAPARAAN',
      'PALANAS',
      'SAN ROQUE (BUGAY)',
      'SANTA CRUZ',
      'SEGUINON',
      'TACLA-ON',
      'TAGBACAN',
      'TALANGDAWAN',
    ],
  },
  'SAN JULIAN': {
    province: 'EASTERN SAMAR',
    municipality: 'SAN JULIAN',
    region: 'REGION VIII',
    barangay_list: [
      'BARANGAY NO. 1 POBLACION',
      'BARANGAY NO. 2 POBLACION',
      'BARANGAY NO. 3 POBLACION',
      'BARANGAY NO. 4 POBLACION',
      'BARANGAY NO. 5 POBLACION',
      'BARANGAY NO. 6 POBLACION',
      'BUNACAN',
      'CAMPIDHAN',
      'CASOROY',
      'LIBAS',
      'LUNANG',
      'NENA (LUNA)',
      'PAGBABANGNAN',
      'PUTONG',
      'SAN ISIDRO',
      'SAN MIGUEL',
    ],
  },
  'SAN POLICARPO': {
    province: 'EASTERN SAMAR',
    municipality: 'SAN POLICARPO',
    region: 'REGION VIII',
    barangay_list: [
      'ALUGAN',
      'BAHAY',
      'BANGON',
      'BARANGAY NO. 1 (POB.)',
      'BARANGAY NO. 2 (POB.)',
      'BARANGAY NO. 3 (POB.)',
      'BARANGAY NO. 4 (POB.)',
      'BARANGAY NO. 5 (POB.)',
      'BARAS (LIPATA)',
      'BINOGAWAN',
      'CAJAGWAYAN',
      'JAPUNAN',
      'NATIVIDAD',
      'PANGPANG',
      'SANTA CRUZ',
      'TABO',
      'TAN-AWAN',
    ],
  },
  SULAT: {
    province: 'EASTERN SAMAR',
    municipality: 'SULAT',
    region: 'REGION VIII',
    barangay_list: [
      'A-ET',
      'ABUCAY (POB.)',
      'BAYBAY (POB.)',
      'DEL REMEDIO',
      'KANDALAKIT',
      'LOYOLA HEIGHTS (POB.)',
      'MABINI',
      'MAGLIPAY (POB.)',
      'MARAMARA (POB.)',
      'RIVERSIDE (POB.)',
      'SAN FRANCISCO',
      'SAN ISIDRO',
      'SAN JUAN',
      'SAN MATEO',
      'SAN VICENTE',
      'SANTO NIÑO',
      'SANTO TOMAS',
      'TABI (POB.)',
    ],
  },
  TAFT: {
    province: 'EASTERN SAMAR',
    municipality: 'TAFT',
    region: 'REGION VIII',
    barangay_list: [
      'BATIAWAN',
      'BETO',
      'BINALOAN',
      'BONGDO',
      'DACUL',
      'DANAO',
      'DEL REMEDIOS',
      'GAYAM',
      'LOMATUD (BURAK)',
      'MABUHAY',
      'MALINAO',
      'MANTANG',
      'NATO',
      'PANGABUTAN',
      'POBLACION BARANGAY 1',
      'POBLACION BARANGAY 2',
      'POBLACION BARANGAY 3',
      'POBLACION BARANGAY 4',
      'POBLACION BARANGAY 5',
      'POBLACION BARANGAY 6',
      'POLANGI',
      'SAN LUIS',
      'SAN PABLO',
      'SAN RAFAEL',
    ],
  },
  ABUYOG: {
    province: 'LEYTE',
    municipality: 'ABUYOG',
    region: 'REGION VIII',
    barangay_list: [
      'ALANGILAN',
      'ANIBONGAN',
      'BAGACAY',
      'BAHAY',
      'BALINSASAYAO',
      'BALOCAWE',
      'BALOCAWEHAY',
      'BARAYONG',
      'BAYABAS',
      'BITO (POB.)',
      'BUAYA',
      'BUENAVISTA',
      'BULAK',
      'BUNGA',
      'BUNTAY (POB.)',
      'BURUBUD-AN',
      'CADAC-AN',
      'CAGBOLO',
      'CAN-APORONG',
      'CAN-UGUIB (POB.)',
      'CANMARATING',
      'CAPILIAN',
      'COMBIS',
      'DINGLE',
      'GUINTAGBUCAN (POB.)',
      'HAMPIPILA',
      'KATIPUNAN',
      'KIKILO',
      'LARAY',
      'LAWA-AN',
      'LIBERTAD',
      'LOYONSAWANG (POB.)',
      'MAG-ATUBANG',
      'MAHAGNA (NEW CAGBOLO)',
      'MAHAYAHAY',
      'MAITUM',
      'MALAGUICAY',
      'MATAGNAO',
      'NALIBUNAN (POB.)',
      'NEBGA',
      'NEW TALIGUE',
      'ODIONGAN',
      'OLD TALIGUE',
      'PAGSANG-AN',
      'PAGUITE',
      'PARASANON',
      'PICAS SUR',
      'PILAR',
      'PINAMANAGAN',
      'SALVACION',
      'SAN FRANCISCO',
      'SAN ISIDRO',
      'SAN ROQUE',
      'SANTA FE (POB.)',
      'SANTA LUCIA (POB.)',
      'SANTO NIÑO (POB.)',
      'TABIGUE',
      'TADOC',
      'TIB-O',
      'TINALIAN',
      'TINOCOLAN',
      'TUY-A',
      'VICTORY (POB.)',
    ],
  },
  ALANGALANG: {
    province: 'LEYTE',
    municipality: 'ALANGALANG',
    region: 'REGION VIII',
    barangay_list: [
      'ASLUM',
      'ASTORGA (BURABOD)',
      'BATO',
      'BINONGTO-AN',
      'BINOTONG',
      'BLUMENTRITT (POB.)',
      'BOBONON',
      'BORSETH',
      'BUENAVISTA',
      'BUGHO',
      'BURI',
      'CABADSAN',
      'CALAASAN',
      'CAMBAHANON',
      'CAMBOLAO',
      'CANVERTUDES',
      'CAPIZ',
      'CAVITE',
      'COGON',
      'DAPDAP',
      'DIVISORIA',
      'EKIRAN',
      'HINAPOLAN',
      'HOLY CHILD I (POB.)',
      'HOLY CHILD II (POB.)',
      'HUBANG',
      'HUPIT',
      'LANGIT',
      'LINGAYON',
      'LOURDES',
      'LUKAY',
      'MAGSAYSAY',
      'MILAGROSA (POB.)',
      'MUDBORON',
      'P. BARRANTES',
      'PEÑALOSA',
      'PEPITA',
      'SALVACION',
      'SALVACION POBLACION',
      'SAN ANTONIO',
      'SAN ANTONIO POB. (PATONG NORTE)',
      'SAN DIEGO',
      'SAN FRANCISCO EAST',
      'SAN FRANCISCO WEST',
      'SAN ISIDRO',
      'SAN PEDRO',
      'SAN ROQUE (POB.)',
      'SAN VICENTE',
      'SANTIAGO',
      'SANTO NIÑO (POB.)',
      'SANTOL',
      'TABANGOHAY',
      'TOMBO',
      'VETERANOS',
    ],
  },
  ALBUERA: {
    province: 'LEYTE',
    municipality: 'ALBUERA',
    region: 'REGION VIII',
    barangay_list: [
      'ANTIPOLO',
      'BALUGO',
      'BENOLHO',
      'CAMBALADING',
      'DAMULA-AN',
      'DOÑA MARIA (KANGKUIRINA)',
      'MAHAYAG',
      'MAHAYAHAY',
      'POBLACION',
      'SALVACION',
      'SAN PEDRO',
      'SEGUINON',
      'SHERWOOD',
      'TABGAS',
      'TALISAYAN',
      'TINAG-AN',
    ],
  },
  BABATNGON: {
    province: 'LEYTE',
    municipality: 'BABATNGON',
    region: 'REGION VIII',
    barangay_list: [
      'BACONG',
      'BAGONG SILANG',
      'BIASONG',
      'GOV. E. JARO (BAGAHUPI)',
      'GUINTIGUI-AN',
      'LUKAY',
      'MAGCASUANG',
      'MALIBAGO',
      'NAGA-ASAN',
      'PAGSULHUGON',
      'PLANZA',
      'POBLACION DISTRICT I',
      'POBLACION DISTRICT II',
      'POBLACION DISTRICT III',
      'POBLACION DISTRICT IV',
      'RIZAL I',
      'RIZAL II',
      'SAN AGUSTIN',
      'SAN ISIDRO',
      'SAN RICARDO',
      'SANGPUTAN',
      'TAGUITE',
      'UBAN',
      'VICTORY',
      'VILLA MAGSAYSAY',
    ],
  },
  BARUGO: {
    province: 'LEYTE',
    municipality: 'BARUGO',
    region: 'REGION VIII',
    barangay_list: [
      'ABANGO',
      'AMAHIT',
      'BALIRE',
      'BALUD',
      'BUKID',
      'BULOD',
      'BUSAY',
      'CABARASAN',
      'CABOLO-AN',
      'CALINGCAGUING',
      'CAN-ISAK',
      'CANOMANTAG',
      'CUTA',
      'DOMOGDOG',
      'DUKA',
      'GUINDAOHAN',
      'HIAGSAM',
      'HILABA',
      'HINUGAYAN',
      'IBAG',
      'MINUHANG',
      'MINUSWANG',
      'PIKAS',
      'PITOGO',
      'POBLACION DIST. I',
      'POBLACION DIST. II',
      'POBLACION DIST. III',
      'POBLACION DIST. IV',
      'POBLACION DIST. V',
      'POBLACION DIST. VI (NEW ROAD)',
      'PONGSO',
      'ROOSEVELT',
      'SAN ISIDRO',
      'SAN ROQUE',
      'SANTA ROSA',
      'SANTARIN',
      'TUTUG-AN',
    ],
  },
  'BAYBAY': {
    province: 'LEYTE',
    municipality: 'BAYBAY',
    region: 'REGION VIII',
    barangay_list: [
      'ALTAVISTA',
      'AMBACAN',
      'AMGUHAN',
      'AMPIHANON',
      'BALAO',
      'BANAHAO',
      'BIASONG',
      'BIDLINAN',
      'BITANHUAN',
      'BUBON',
      'BUENAVISTA',
      'BUNGA',
      'BUTIGAN',
      'CANDADAM',
      'CARIDAD',
      'CIABO',
      'COGON',
      'GA-AS',
      'GABAS',
      'GAKAT',
      'GUADALUPE (UTOD)',
      'GUBANG',
      'HIBUNAWAN',
      'HIGULOAN',
      'HILAPNITAN',
      'HIPUSNGO',
      'IGANG',
      'IMELDA',
      'JAENA',
      'KABALASAN',
      'KABATUAN',
      'KABUNGAAN',
      'KAGUMAY',
      'KAMBONGGAN',
      'KAN-IPA',
      'KANSUNGKA',
      'KANTAGNOS',
      'KILIM',
      'LINTAON',
      'MAGANHAN',
      'MAHAYAHAY',
      'MAILHI',
      'MAITUM',
      'MAKINHAS',
      'MAPGAP',
      'MARCOS',
      'MASLUG',
      'MATAM-IS',
      'MAYBOG',
      'MAYPATAG',
      'MONTE VERDE',
      'MONTERICO',
      'PALHI',
      'PANGASUNGAN',
      'PANSAGAN',
      'PATAG',
      'PLARIDEL',
      'POBLACION ZONE 1',
      'POBLACION ZONE 2',
      'POBLACION ZONE 3',
      'POBLACION ZONE 4',
      'POBLACION ZONE 5',
      'POBLACION ZONE 6',
      'POBLACION ZONE 7',
      'POBLACION ZONE 8',
      'POBLACION ZONE 9',
      'POBLACION ZONE 10',
      'POBLACION ZONE 11',
      'POBLACION ZONE 12',
      'POBLACION ZONE 13',
      'POBLACION ZONE 14',
      'POBLACION ZONE 15',
      'POBLACION ZONE 16',
      'POBLACION ZONE 17',
      'POBLACION ZONE 18',
      'POBLACION ZONE 19',
      'POBLACION ZONE 20',
      'POBLACION ZONE 21',
      'POBLACION ZONE 22',
      'POBLACION ZONE 23',
      'POMPONAN',
      'PUNTA',
      'SABANG',
      'SAN AGUSTIN',
      'SAN ISIDRO',
      'SAN JUAN',
      'SANTA CRUZ',
      'SANTO ROSARIO',
      'SAPA',
      'VILLA MAG-ASO',
      'VILLA SOLIDARIDAD',
      'ZACARITO',
    ],
  },
  BURAUEN: {
    province: 'LEYTE',
    municipality: 'BURAUEN',
    region: 'REGION VIII',
    barangay_list: [
      'ABUYOGON',
      'ANONANG',
      'ARADO',
      'BALAO',
      'BALATSON',
      'BALORINAY',
      'BOBON',
      'BUENAVISTA',
      'BURI',
      'CAANISLAGAN',
      'CADAHUNAN',
      'CAGANGON',
      'CALI',
      'CALSADAHAY',
      'CANDAG-ON',
      'CANSIBOY',
      'CATAGBACAN',
      'DAMULO-AN',
      'DINA-AYAN',
      'DUMALAG (PUSOD)',
      'ESPERANZA',
      'GAMAY',
      'GITABLAN',
      'HAPUNAN',
      'HIBONAWAN',
      'HUGPA EAST',
      'HUGPA WEST',
      'ILIHAN',
      'KAGBANA',
      'KALAO',
      'KALIPAYAN',
      'KAPARASANAN',
      'LAGUIWAN',
      'LIBAS',
      'LIMBURAN',
      'LOGSONGAN',
      'MAABAB',
      'MAGHUBAS',
      'MAHAGNAO',
      'MALABCA',
      'MALAGUINABOT',
      'MALAIHAO (SAN RAMON)',
      'MATIN-AO',
      'MOGUING',
      'PAGHUDLAN',
      'PAITAN',
      'PANGDAN',
      'PATAG',
      'PATONG',
      'PAWA',
      'POBLACION DISTRICT I',
      'POBLACION DISTRICT II',
      'POBLACION DISTRICT III',
      'POBLACION DISTRICT IV',
      'POBLACION DISTRICT IX',
      'POBLACION DISTRICT V',
      'POBLACION DISTRICT VI',
      'POBLACION DISTRICT VII',
      'POBLACION DISTRICT VIII',
      'ROXAS',
      'SAMBEL',
      'SAN ESTEBAN',
      'SAN FERNANDO',
      'SAN JOSE EAST',
      'SAN JOSE WEST',
      'SAN PABLO',
      'TABUANON',
      'TAGADTARAN',
      'TAGHUYAN',
      'TAKIN',
      'TAMBIS (NABOYA)',
      'TAMBUKO',
      'TOLOYAO',
      'VILLA AURORA',
      'VILLA CORAZON',
      'VILLA PATRIA',
      'VILLA ROSAS (CABANG)',
    ],
  },
  CALUBIAN: {
    province: 'LEYTE',
    municipality: 'CALUBIAN',
    region: 'REGION VIII',
    barangay_list: [
      'ABANILLA',
      'AGAS',
      'ANISLAGAN',
      'BUNACAN',
      'CABALHIN',
      'CABALQUINTO',
      'CABRADILLA',
      'CANEJA',
      'CANTONGHAO',
      'CAROYOCAN',
      'CASIONGAN',
      'CRISTINA',
      'DALUMPINES',
      'DON LUIS',
      'DULAO',
      'EFE',
      'ENAGE',
      'ESPINOSA',
      'FERDINAND E. MARCOS',
      'GARGANERA',
      'GARRIDO',
      'GUADALUPE (GUADALUPE MENDOZA)',
      'GUTOSAN',
      'IGANG',
      'INALAD',
      'JUBAY',
      'JUSON',
      'KAWAYAN BOGTONG',
      'KAWAYANAN',
      'KOKOY ROMUALDEZ',
      'LABTIC',
      'LARAY',
      'M. VELOSO',
      'MAHAIT',
      'MALOBAGO',
      'MATAGOK',
      'NIPA',
      'OBISPO',
      'PADOGA',
      'PAGATPAT',
      'PANGPANG',
      'PATAG',
      'PATES',
      'PAULA',
      'PETROLIO',
      'POBLACION',
      'RAILES',
      'TABLA',
      'TAGHARIGUE',
      'TUBURAN',
      'VILLAHERMOSA',
      'VILLALON',
      'VILLANUEVA',
    ],
  },
  CAPOOCAN: {
    province: 'LEYTE',
    municipality: 'CAPOOCAN',
    region: 'REGION VIII',
    barangay_list: [
      'BALUCANAD',
      'BALUD',
      'BALUGO',
      'CABUL-AN',
      'CULASIAN',
      'GAYAD',
      'GUINADIONGAN',
      'LEMON',
      'LIBERTAD',
      'MANLOY',
      'NAUGUISAN',
      'PINAMOPOAN',
      'POBLACION ZONE I',
      'POBLACION ZONE II',
      'POTOT',
      'SAN JOAQUIN',
      'SANTO NIÑO',
      'TALAIRAN',
      'TALISAY',
      'TOLIBAO',
      'VISARES',
    ],
  },
  CARIGARA: {
    province: 'LEYTE',
    municipality: 'CARIGARA',
    region: 'REGION VIII',
    barangay_list: [
      'BAGONG LIPUNAN',
      'BALILIT',
      'BARAYONG',
      'BARUGOHAY CENTRAL',
      'BARUGOHAY NORTE',
      'BARUGOHAY SUR',
      'BAYBAY (POB.)',
      'BINIBIHAN',
      'BISLIG',
      'CAGHALO',
      'CAMANSI',
      'CANAL',
      'CANDIGAHUB',
      'CANFABI',
      'CANLAMPAY',
      'COGON',
      'CUTAY',
      'EAST VISORIA',
      'GUINDAPUNAN EAST',
      'GUINDAPUNAN WEST',
      'HILUCTOGAN',
      'JUGABAN (POB.)',
      'LIBO',
      'LOWER HIRAAN',
      'LOWER SOGOD',
      'MACALPI',
      'MANLOY',
      'NAUGUISAN',
      'PAGLAUM',
      'PANGNA',
      'PARAG-UM',
      'PARINA',
      'PILORO',
      'PONONG (POB.)',
      'RIZAL (TAGAK EAST)',
      'SAGKAHAN',
      'SAN ISIDRO',
      'SAN JUAN',
      'SAN MATEO (POB.)',
      'SANTA FE',
      'SAWANG (POB.)',
      'TAGAK',
      'TANGNAN',
      'TIGBAO',
      'TINAGUBAN',
      'UPPER HIRAAN',
      'UPPER SOGOD',
      'UYAWAN',
      'WEST VISORIA',
    ],
  },
  DAGAMI: {
    province: 'LEYTE',
    municipality: 'DAGAMI',
    region: 'REGION VIII',
    barangay_list: [
      'ABACA',
      'ABRE',
      'BALILIT',
      'BALUGO',
      'BANAYON',
      'BAYABAS',
      'BOLIRAO',
      'BUENAVISTA',
      'BUNTAY',
      'CAANISLAGAN',
      'CABARIWAN',
      'CABULORAN',
      'CABUNGA-AN',
      'CALIPAYAN',
      'CALSADAHAY',
      'CALUCTOGAN',
      'CALUTAN',
      'CAMONO-AN',
      'CANDAGARA',
      'CANLINGGA',
      'CANSAMADA EAST',
      'CANSAMADA WEST',
      'CAPULHAN',
      'DIGAHONGAN',
      'GUINARONA',
      'HIABANGAN',
      'HILABAGO',
      'HINABUYAN',
      'HINOLOGAN',
      'HITUMNOG',
      'KATIPUNAN',
      'LAPU-LAPU POB. (DIST. 2)',
      'LOBE-LOBE',
      'LOBE-LOBE EAST',
      'LOS MARTIRES',
      'LUSAD POB. (DIST. 6)',
      'MACAALANG',
      'MALIWALIW',
      'MARAGONDONG',
      'ORMOCAY',
      'PALACIO',
      'PANDA',
      'PARAISO',
      'PATOC',
      'PLARIDEL',
      'POPONTON',
      'RIZAL',
      'SALVACION',
      'SAMPAGUITA',
      'SAMPAO EAST POB. (DIST. 9)',
      'SAMPAO WEST POB. (DIST. 8)',
      'SAN ANTONIO POB. (DIST. 5)',
      'SAN BENITO',
      'SAN JOSE POB. (DIST. 1)',
      'SAN ROQUE POB. (DIST. 3)',
      'SANTO DOMINGO',
      'SAWAHON',
      'SIRAB',
      'STA. MESA POB. (DIST. 7)',
      'TAGKIP',
      'TALINHUGON',
      'TIN-AO',
      'TUNGA POB. (DIST. 4)',
      'TUYA',
      'VICTORIA',
    ],
  },
  DULAG: {
    province: 'LEYTE',
    municipality: 'DULAG',
    region: 'REGION VIII',
    barangay_list: [
      'ALEGRE',
      'ARADO',
      'BARBO (POB.)',
      'BATUG',
      'BOLONGTOHAN',
      'BULOD',
      'BUNTAY (POB.)',
      'CABACUNGAN',
      'CABARASAN',
      'CABATO-AN',
      'CALIPAYAN',
      'CALUBIAN',
      'CAMBULA DISTRICT (POB.)',
      'CAMITOC',
      'CAMOTE',
      'CANDAO (POB.)',
      'CATMONAN (POB.)',
      'COMBIS (POB.)',
      'DACAY',
      'DEL CARMEN',
      'DEL PILAR',
      'FATIMA',
      'GENERAL ROXAS',
      'HIGHWAY (POB.)',
      'LUAN',
      'MAGSAYSAY',
      'MARICUM',
      'MARKET SITE (POB.)',
      'RAWIS',
      'RIZAL',
      'ROMUALDEZ',
      'SABANG DAGUITAN',
      'SALVACION',
      'SAN AGUSTIN',
      'SAN ANTONIO',
      'SAN ISIDRO',
      'SAN JOSE',
      'SAN MIGUEL (POB.)',
      'SAN RAFAEL',
      'SAN VICENTE',
      'SERRANO (POB.)',
      'SUNGI (POB.)',
      'TABU',
      'TIGBAO',
      'VICTORY',
    ],
  },
  HILONGOS: {
    province: 'LEYTE',
    municipality: 'HILONGOS',
    region: 'REGION VIII',
    barangay_list: [
      'AGUTAYAN',
      'ATABAY',
      'BAAS',
      'BAGONG LIPUNAN',
      'BAGUMBAYAN',
      'BALIW',
      'BANTIGUE',
      'BON-OT',
      'BUNG-AW',
      'CACAO',
      'CAMPINA',
      'CATANDOG 1',
      'CATANDOG 2',
      'CENTRAL BARANGAY (POB.)',
      'CONCEPCION',
      'EASTERN BARANGAY (POB.)',
      'HAMPANGAN',
      'HIMO-AW',
      'HITUDPAN',
      'IMELDA MARCOS (PONG-ON)',
      'KANG-IRAS',
      'KANGHA-AS',
      'LAMAK',
      'LIBERTAD',
      'LIBERTY',
      'LUNANG',
      'MAGNANGOY',
      'MANAUL',
      'MARANGOG',
      'MATAPAY',
      'NAVAL',
      'OWAK',
      'PA-A',
      'PONTOD',
      'PROTECCION',
      'SAN AGUSTIN',
      'SAN ANTONIO',
      'SAN ISIDRO',
      'SAN JUAN',
      'SAN ROQUE',
      'SANTA CRUZ',
      'SANTA MARGARITA',
      'SANTO NIÑO',
      'TABUNOK',
      'TAGNATE',
      'TALISAY',
      'TAMBIS',
      'TEJERO',
      'TUGUIPA',
      'UTANAN',
      'WESTERN BARANGAY (POB.)',
    ],
  },
  HINDANG: {
    province: 'LEYTE',
    municipality: 'HINDANG',
    region: 'REGION VIII',
    barangay_list: [
      'ANAHAW',
      'ANOLON',
      'BALDOZA',
      'BONTOC',
      'BULACAN',
      'CANHA-AYON',
      'CAPUDLOSAN',
      'DOOS DEL NORTE',
      'DOOS DEL SUR',
      'HIMACUGO',
      'HIMOKILAN ISLAND',
      'KATIPUNAN',
      'MAASIN',
      'MABAGON',
      'MAHILUM',
      'POBLACION 1',
      'POBLACION 2',
      'SAN VICENTE',
      'TABOK',
      'TAGBIBI',
    ],
  },
  INOPACAN: {
    province: 'LEYTE',
    municipality: 'INOPACAN',
    region: 'REGION VIII',
    barangay_list: [
      'APID',
      'CABULISAN',
      'CAMINTO',
      'CAN-ANGAY',
      'CAULISIHAN',
      'CONALUM',
      'DE LOS SANTOS (MAHILUM)',
      'ESPERANZA',
      'GUADALUPE',
      'GUINSANGA-AN',
      'HINABAY',
      'JUBASAN',
      'LINAO',
      'MACAGOCO',
      'MALJO',
      'MARAO',
      'POBLACION',
      'TAHUD',
      'TAOTAON',
      'TINAGO',
    ],
  },
  ISABEL: {
    province: 'LEYTE',
    municipality: 'ISABEL',
    region: 'REGION VIII',
    barangay_list: [
      'ANISLAG',
      'ANTIPOLO',
      'APALE',
      'BANTIGUE',
      'BENOG',
      'BILWANG',
      'CAN-ANDAN',
      'CANGAG',
      'CONSOLACION',
      'HONAN',
      'LIBERTAD',
      'MAHAYAG',
      'MARVEL (POB.)',
      'MATLANG',
      'MONTE ALEGRE',
      'PUTING BATO',
      'SAN FRANCISCO',
      'SAN ROQUE',
      'SANTA CRUZ',
      'SANTO NIÑO (POB.)',
      'SANTO ROSARIO',
      'TABUNOK',
      'TOLINGON',
      'TUBOD',
    ],
  },
  JARO: {
    province: 'LEYTE',
    municipality: 'JARO',
    region: 'REGION VIII',
    barangay_list: [
      'ALAHAG',
      'ANIBONGAN',
      'ATIPOLO',
      'BADIANG',
      'BATUG',
      'BIAS ZABALA',
      'BUENAVISTA',
      'BUKID',
      'BURABOD',
      'BURI',
      'CANAPUAN',
      'CANHANDUGAN',
      'CROSSING RUBAS',
      'DARO',
      'DISTRICT I (POB.)',
      'DISTRICT II (POB.)',
      'DISTRICT III (POB.)',
      'DISTRICT IV (POB.)',
      'HIAGSAM',
      'HIBUCAWAN',
      'HIBUNAWON',
      'KAGLAWAAN',
      'KALINAWAN',
      'LA PAZ',
      'LIKOD',
      'MACANIP',
      'MACOPA',
      'MAG-ASO',
      'MALOBAGO',
      'OLOTAN',
      'PALANOG',
      'PANGE',
      'PARASAN',
      'PITOGO',
      'SAGKAHAN',
      'SAN AGUSTIN',
      'SAN PEDRO',
      'SAN ROQUE',
      'SANTA CRUZ',
      'SANTO NIÑO',
      'SARI-SARI',
      'TINAMBACAN',
      'TUBA',
      'UGUIAO',
      'VILLA PAZ',
      'VILLAGONZOILO (VILLA CONSUELO)',
    ],
  },
  'JAVIER (BUGHO)': {
    province: 'LEYTE',
    municipality: 'JAVIER (BUGHO)',
    region: 'REGION VIII',
    barangay_list: [
      'ABUYOGAY',
      'ANDRES BONIFACIO',
      'BATUG',
      'BINULHO',
      'CALZADA',
      'CANCAYANG',
      'CARANHUG',
      'CARAYE',
      'CASALUNGAN',
      'COMATIN',
      'GUINDAPUNAN',
      'INAYUPAN',
      'LARAY',
      'MAGSAYSAY (RESPONDE)',
      'MALITBOGAY',
      'MANARUG',
      'MANLILISID',
      'NALIWATAN',
      'ODIONG',
      'PICAS NORTE',
      'PINOCAWAN',
      'POBLACION ZONE 1',
      'POBLACION ZONE 2',
      'RIZAL',
      'SAN SOTERO (TAMBIS)',
      'SANTA CRUZ',
      'TALISAYAN',
      'ULHAY',
    ],
  },
  JULITA: {
    province: 'LEYTE',
    municipality: 'JULITA',
    region: 'REGION VIII',
    barangay_list: [
      'ALEGRIA',
      'ANIBONG',
      'ASLUM',
      'BALANTE',
      'BONGDO',
      'BONIFACIO',
      'BUGHO',
      'CALBASAG',
      'CARIDAD',
      'CUYA-E',
      'DITA',
      'GITABLA',
      'HINDANG',
      'INAWANGAN',
      'JURAO',
      'POBLACION DISTRICT I',
      'POBLACION DISTRICT II',
      'POBLACION DISTRICT III',
      'POBLACION DISTRICT IV',
      'SAN ANDRES',
      'SAN PABLO',
      'SANTA CRUZ',
      'SANTO NIÑO',
      'TAGKIP',
      'TOLOSAHAY',
      'VILLA HERMOSA',
    ],
  },
  KANANGA: {
    province: 'LEYTE',
    municipality: 'KANANGA',
    region: 'REGION VIII',
    barangay_list: [
      'AGUITING',
      'CACAO',
      'HILUCTOGAN',
      'KAWAYAN',
      'LIBERTAD',
      'LIBONGAO',
      'LIM-AO',
      'LONOY',
      'MAHAWAN',
      'MASARAYAO',
      'MONTE ALEGRE',
      'MONTE BELLO',
      'NAGHALIN',
      'NATUBGAN',
      'POBLACION',
      'RIZAL',
      'SAN IGNACIO',
      'SAN ISIDRO',
      'SANTO DOMINGO',
      'SANTO NIÑO',
      'TAGAYTAY',
      'TONGONAN',
      'TUGBONG',
    ],
  },
  LEYTE: {
    province: 'LEYTE',
    municipality: 'LEYTE',
    region: 'REGION VIII',
    barangay_list: [
      'BACHAO',
      'BACO',
      'BAGABA-O',
      'BASUD',
      'BELEN',
      'BURABOD',
      'CALAGUISE',
      'CONSUEGRA',
      'CULASI',
      'DANUS',
      'ELIZABETH',
      'KAWAYAN',
      'LIBAS',
      'MAANDA',
      'MACUPA',
      'MATALOTO',
      'PALARAO',
      'PALID I (ILAWOD)',
      'PALID II (IRAYA)',
      'PARASAN',
      'POBLACION',
      'SALOG',
      'SAMBULAWAN',
      'TAG-ABACA',
      'TAPOL',
      'TIGBAWAN',
      'TINOCDUGAN',
      'TOCTOC',
      'UGBON',
      'WAGUE',
    ],
  },
  MACARTHUR: {
    province: 'LEYTE',
    municipality: 'MACARTHUR',
    region: 'REGION VIII',
    barangay_list: [
      'BATUG',
      'BURABOD',
      'CAPUDLOSAN',
      'CASUNTINGAN',
      'CAUSWAGAN',
      'DANAO',
      'DOÑA JOSEFA',
      'GENERAL LUNA',
      'KILING',
      'LANAWAN',
      'LIWAYWAY',
      'MAYA',
      'OGUISAN',
      'OSMEÑA',
      'PALALE 1',
      'PALALE 2',
      'POBLACION DISTRICT 1',
      'POBLACION DISTRICT 2',
      'POBLACION DISTRICT 3',
      'PONGON',
      'QUEZON',
      'ROMUALDEZ',
      'SALVACION',
      'SAN ANTONIO',
      'SAN ISIDRO',
      'SAN PEDRO',
      'SAN VICENTE',
      'SANTA ISABEL',
      'TINAWAN',
      'TUYO',
      'VILLA IMELDA',
    ],
  },
  MAHAPLAG: {
    province: 'LEYTE',
    municipality: 'MAHAPLAG',
    region: 'REGION VIII',
    barangay_list: [
      'CAMPIN',
      'CUATRO DE AGOSTO',
      'HILUCTOGAN',
      'HILUSIG',
      'HIMAMARA',
      'HINAGUIMITAN',
      'LIBERACION',
      'MABUHAY',
      'MABUNGA',
      'MAGSUGANAO',
      'MAHAYAG',
      'MAHAYAHAY',
      'MALIGAYA',
      'MALINAO',
      'MALIPOON',
      'PALAÑOGAN',
      'PARIL',
      'PINAMONOAN',
      'POBLACION',
      'POLAHONGON',
      'SAN ISIDRO',
      'SAN JUAN',
      'SANTA CRUZ',
      'SANTO NIÑO',
      'TAGAYTAY',
      'UGUIS',
      'UNION',
      'UPPER MAHAPLAG',
    ],
  },
  'MATAG-OB': {
    province: 'LEYTE',
    municipality: 'MATAG-OB',
    region: 'REGION VIII',
    barangay_list: [
      'BALAGTAS',
      'BONOY (POB.)',
      'BULAK',
      'CAMBADBAD',
      'CANDELARIA',
      'CANSOSO',
      'IMELDA',
      'MALAZARTE',
      'MANSAHAON',
      'MANSALIP (POB.)',
      'MASABA',
      'NAULAYAN',
      'RIVERSIDE (POB.)',
      'SAN DIONISIO',
      'SAN GUILLERMO',
      'SAN MARCELINO',
      'SAN SEBASTIAN',
      'SAN VICENTE',
      'SANTA ROSA',
      'SANTO ROSARIO',
      'TALISAY (POB.)',
    ],
  },
  MATALOM: {
    province: 'LEYTE',
    municipality: 'MATALOM',
    region: 'REGION VIII',
    barangay_list: [
      'AGBANGA',
      'ALTAVISTA',
      'BAGONG LIPUNAN',
      'CAHAGNAAN',
      'CALUMPANG',
      'CANINGAG',
      'CARIDAD NORTE',
      'CARIDAD SUR',
      'ELEVADO',
      'ESPERANZA',
      'HITOOG',
      'ITUM',
      'LOWAN',
      'MONTE ALEGRE',
      'PRESIDENT GARCIA',
      'PUNONG',
      'SAN ISIDRO (POB.)',
      'SAN JUAN',
      'SAN PEDRO (POB.)',
      'SAN SALVADOR',
      'SAN VICENTE',
      'SANTA FE',
      'SANTA PAZ',
      'SANTO NIÑO (POB.)',
      'TAG-OS',
      'TAGLIBAS IMELDA',
      'TEMPLANZA',
      'TIGBAO',
      'WATERLOO',
      'ZARAGOZA',
    ],
  },
  MAYORGA: {
    province: 'LEYTE',
    municipality: 'MAYORGA',
    region: 'REGION VIII',
    barangay_list: [
      'A. BONIFACIO',
      'BURGOS',
      'CALIPAYAN',
      'CAMANSI',
      'GENERAL ANTONIO LUNA',
      'LIBERTY',
      'MABINI',
      'ORMOCAY',
      'POBLACION ZONE 1',
      'POBLACION ZONE 2',
      'POBLACION ZONE 3',
      'SAN ROQUE',
      'SANTA CRUZ',
      'TALISAY',
      'UNION',
      'WILSON',
    ],
  },
  MERIDA: {
    province: 'LEYTE',
    municipality: 'MERIDA',
    region: 'REGION VIII',
    barangay_list: [
      'BINABAYE',
      'CABALIWAN',
      'CALUNANGAN',
      'CALUNASAN',
      'CAMBALONG',
      'CAN-UNZO',
      'CANBANTUG',
      'CASILDA',
      'LAMANOC',
      'LIBAS',
      'LIBJO',
      'LUNDAG',
      'MACARIO',
      'MAHALIT',
      'MAHAYAG',
      'MASUMBANG',
      'MAT-E',
      'POBLACION',
      'PUERTO BELLO',
      'SAN ISIDRO',
      'SAN JOSE',
      'TUBOD',
    ],
  },
  'ORMOC': {
    province: 'LEYTE',
    municipality: 'ORMOC',
    region: 'REGION VIII',
    barangay_list: [
      'AIRPORT',
      'ALEGRIA',
      'ALTA VISTA',
      'BAGONG',
      'BAGONG BUHAY',
      'BANTIGUE',
      'BARANGAY 1 (POB.)',
      'BARANGAY 2 (POB.)',
      'BARANGAY 3 (POB.)',
      'BARANGAY 4 (POB.)',
      'BARANGAY 5 (POB.)',
      'BARANGAY 6 (POB.)',
      'BARANGAY 7 (POB.)',
      'BARANGAY 8 (POB.)',
      'BARANGAY 9 (POB.)',
      'BARANGAY 10 (POB.)',
      'BARANGAY 11 (POB.)',
      'BARANGAY 12 (POB.)',
      'BARANGAY 13 (POB.)',
      'BARANGAY 14 (POB.)',
      'BARANGAY 15 (POB.)',
      'BARANGAY 16 (POB.)',
      'BARANGAY 17 (POB.)',
      'BARANGAY 18 (POB.)',
      'BARANGAY 19 (POB.)',
      'BARANGAY 20 (POB.)',
      'BARANGAY 21 (POB.)',
      'BARANGAY 22 (POB.)',
      'BARANGAY 23 (POB.)',
      'BARANGAY 24 (POB.)',
      'BARANGAY 25 (POB.)',
      'BARANGAY 26 (POB.)',
      'BARANGAY 27 (POB.)',
      'BARANGAY 28 (POB.)',
      'BARANGAY 29 (POB.)',
      'BATUAN',
      'BAYOG',
      'BILIBOY',
      'BOROK',
      'CABAON-AN',
      'CABINTAN',
      'CABULIHAN',
      'CAGBUHANGIN',
      'CAMP DOWNES',
      'CAN-ADIENG',
      'CAN-UNTOG',
      'CATMON',
      'COGON COMBADO',
      'CONCEPCION',
      'CURVA',
      'DANAO',
      'DANHUG',
      'DAYHAGAN',
      'DOLORES',
      'DOMONAR',
      'DON FELIPE LARRAZABAL',
      'DON POTENCIANO LARRAZABAL',
      'DONGHOL',
      'DOÑA FELIZA Z. MEJIA',
      'ESPERANZA',
      'GAAS',
      'GREEN VALLEY',
      'GUINTIGUI-AN',
      'HIBUNAWON',
      'HUGPA',
      'IPIL',
      'JUATON',
      'KADAOHAN',
      'LABRADOR (BALION)',
      'LAO',
      'LEONDONI',
      'LIBERTAD',
      'LIBERTY',
      'LICUMA',
      'LILOAN',
      'LINAO',
      'LUNA',
      'MABATO',
      'MABINI',
      'MACABUG',
      'MAGASWI',
      'MAHAYAG',
      'MAHAYAHAY',
      'MANLILINAO',
      'MARGEN',
      'MAS-IN',
      'MATICA-A',
      'MILAGRO',
      'MONTERICO',
      'NASUNOGAN',
      'NAUNGAN',
      'NUEVA SOCIEDAD',
      'NUEVA VISTA',
      'PATAG',
      'PUNTA',
      'QUEZON, JR.',
      'RUFINA M. TAN',
      'SABANG BAO',
      'SALVACION',
      'SAN ANTONIO',
      'SAN ISIDRO',
      'SAN JOSE',
      'SAN JUAN',
      'SAN PABLO (SIMANGAN)',
      'SAN VICENTE',
      'SANTO NIÑO',
      'SUMANGGA',
      'TAMBULILID',
      'TONGONAN',
      'VALENCIA',
    ],
  },
  PALO: {
    province: 'LEYTE',
    municipality: 'PALO',
    region: 'REGION VIII',
    barangay_list: [
      'ANAHAWAY',
      'ARADO',
      'BARAS',
      'BARAYONG',
      'BURI (POB.)',
      'CABARASAN DAKU',
      'CABARASAN GUTI',
      'CAMPETIK',
      'CANDAHUG',
      'CANGUMBANG',
      'CANHIDOC',
      'CAPIRAWAN',
      'CASTILLA',
      'CAVITE EAST (POB.)',
      'CAVITE WEST (POB.)',
      'COGON (COGON SAN JOAQUIN)',
      'GACAO',
      'GUINDAPUNAN',
      'LIBERTAD',
      'LUNTAD (POB.)',
      'NAGA-NAGA',
      'PAWING',
      'SALVACION',
      'SAN AGUSTIN',
      'SAN ANTONIO',
      'SAN FERNANDO',
      'SAN ISIDRO',
      'SAN JOAQUIN',
      'SAN JOSE',
      'SAN MIGUEL (POB.)',
      'SANTA CRUZ (POB.)',
      'TACURANGA',
      'TERAZA',
    ],
  },
  PALOMPON: {
    province: 'LEYTE',
    municipality: 'PALOMPON',
    region: 'REGION VIII',
    barangay_list: [
      'BAGUINBIN',
      'BELEN',
      'BITAOG POB. (YPIL III)',
      'BUENAVISTA',
      'CADUHAAN',
      'CAMBAKBAK',
      'CAMBINOY',
      'CANGCOSME',
      'CANGMUYA',
      'CANIPAAN',
      'CANTANDOY',
      'CANTUHAON',
      'CATIGAHAN',
      'CENTRAL 1 (POB.)',
      'CENTRAL 2 (POB.)',
      'CRUZ',
      'DULJUGAN',
      'GUIWAN 1 (POB.)',
      'GUIWAN 2 (POB.)',
      'HIMARCO',
      'HINABLAYAN POB. (CENTRAL 3)',
      'HINAGBUAN',
      'LAT-OSAN',
      'LIBERTY',
      'LOMONON',
      'MABINI',
      'MAGSAYSAY',
      'MASABA',
      'MAZAWALO POB. (LILI-ON)',
      'PARILLA',
      'PINAGDAIT POB. (YPIL I)',
      'PINAGHI-USA POB. (YPIL II)',
      'PLARIDEL',
      'RIZAL',
      'SABANG',
      'SAN GUILLERMO',
      'SAN ISIDRO',
      'SAN JOAQUIN',
      'SAN JUAN',
      'SAN MIGUEL',
      'SAN PABLO',
      'SAN PEDRO',
      'SAN ROQUE',
      'SANTIAGO',
      'TABERNA',
      'TABUNOK',
      'TAMBIS',
      'TINABILAN',
      'TINAGO',
      'TINUBDAN',
    ],
  },
  PASTRANA: {
    province: 'LEYTE',
    municipality: 'PASTRANA',
    region: 'REGION VIII',
    barangay_list: [
      'ARABUNOG',
      'ARINGIT',
      'BAHAY',
      'CABAOHAN',
      'CALSADAHAY',
      'CANCARAJA',
      'CANINOAN',
      'CAPILLA',
      'COLAWEN',
      'DISTRICT 1 (POB.)',
      'DISTRICT 2 (POB.)',
      'DISTRICT 3 (POB.)',
      'DISTRICT 4 (POB.)',
      'DUMARAG',
      'GUINDAPUNAN',
      'HALABA',
      'JONES',
      'LANAWAN',
      'LIMA',
      'LOURDES',
      'MACALPIAY',
      'MALITBOGAY',
      'MANAYBANAY',
      'MARICUM',
      'PATONG',
      'SAPSAP',
      'SOCSOCON',
      'TINGIB',
      'YAPAD',
    ],
  },
  TABANGO: {
    province: 'LEYTE',
    municipality: 'TABANGO',
    region: 'REGION VIII',
    barangay_list: [
      'BUTASON I',
      'BUTASON II',
      'CAMPOKPOK',
      'CATMON',
      'GIBACUNGAN',
      'GIMARCO',
      'INANGATAN',
      'MANLAWAAN',
      'OMAGANHAN',
      'POBLACION',
      'SANTA ROSA',
      'TABING',
      'TUGAS',
    ],
  },
  TABONTABON: {
    province: 'LEYTE',
    municipality: 'TABONTABON',
    region: 'REGION VIII',
    barangay_list: [
      'AMANDANGAY',
      'ASLUM',
      'BALINGASAG',
      'BELISONG',
      'CAMBUCAO',
      'CAPAHUAN',
      'DISTRICT I POB. (QUEZON)',
      'DISTRICT II POB. (RIZAL)',
      'DISTRICT III POB. (BONIFACIO)',
      'DISTRICT IV POB. (MACARTHUR)',
      'GUINGAWAN',
      'JABONG',
      'MERCADOHAY',
      'MERING',
      'MOHON',
      'SAN PABLO (MOOC)',
    ],
  },
  'TACLOBAN': {
    province: 'LEYTE',
    municipality: 'TACLOBAN',
    region: 'REGION VIII',
    barangay_list: [
      'BARANGAY 2',
      'BARANGAY 5',
      'BARANGAY 5-A',
      'BARANGAY 6',
      'BARANGAY 6-A',
      'BARANGAY 7',
      'BARANGAY 8',
      'BARANGAY 8-A',
      'BARANGAY 12 (PALANOG RESETTLEMENT)',
      'BARANGAY 13',
      'BARANGAY 14',
      'BARANGAY 15',
      'BARANGAY 16',
      'BARANGAY 17',
      'BARANGAY 18',
      'BARANGAY 19',
      'BARANGAY 20',
      'BARANGAY 21',
      'BARANGAY 21-A',
      'BARANGAY 22',
      'BARANGAY 23',
      'BARANGAY 23-A',
      'BARANGAY 24',
      'BARANGAY 25',
      'BARANGAY 26',
      'BARANGAY 27',
      'BARANGAY 28',
      'BARANGAY 29',
      'BARANGAY 30',
      'BARANGAY 31',
      'BARANGAY 32',
      'BARANGAY 33',
      'BARANGAY 34',
      'BARANGAY 35',
      'BARANGAY 35-A',
      'BARANGAY 36',
      'BARANGAY 36-A',
      'BARANGAY 37',
      'BARANGAY 37-A',
      'BARANGAY 38',
      'BARANGAY 39',
      'BARANGAY 40',
      'BARANGAY 41',
      'BARANGAY 42',
      'BARANGAY 42-A',
      'BARANGAY 43',
      'BARANGAY 43-A',
      'BARANGAY 43-B',
      'BARANGAY 44',
      'BARANGAY 44-A',
      'BARANGAY 45',
      'BARANGAY 46',
      'BARANGAY 47',
      'BARANGAY 48',
      'BARANGAY 48-A',
      'BARANGAY 48-B',
      'BARANGAY 49',
      'BARANGAY 50',
      'BARANGAY 50-A',
      'BARANGAY 50-B',
      'BARANGAY 51',
      'BARANGAY 51-A',
      'BARANGAY 52',
      'BARANGAY 53',
      'BARANGAY 54',
      'BARANGAY 54-A',
      'BARANGAY 56',
      'BARANGAY 56-A',
      'BARANGAY 57',
      'BARANGAY 58',
      'BARANGAY 59',
      'BARANGAY 59-A',
      'BARANGAY 59-B',
      'BARANGAY 60',
      'BARANGAY 60-A',
      'BARANGAY 61',
      'BARANGAY 62',
      'BARANGAY 62-A',
      'BARANGAY 62-B',
      'BARANGAY 63',
      'BARANGAY 64',
      'BARANGAY 65',
      'BARANGAY 66',
      'BARANGAY 66-A',
      'BARANGAY 67',
      'BARANGAY 68',
      'BARANGAY 69',
      'BARANGAY 70',
      'BARANGAY 71',
      'BARANGAY 72',
      'BARANGAY 73',
      'BARANGAY 74',
      'BARANGAY 75',
      'BARANGAY 76',
      'BARANGAY 77',
      'BARANGAY 78 (MARASBARAS)',
      'BARANGAY 79 (MARASBARAS)',
      'BARANGAY 80 (MARASBARAS)',
      'BARANGAY 81 (MARASBARAS)',
      'BARANGAY 82 (MARASBARAS)',
      'BARANGAY 83 (SAN JOSE)',
      'BARANGAY 83-A (SAN JOSE)',
      'BARANGAY 83-B',
      'BARANGAY 83-C (SAN JOSE)',
      'BARANGAY 84 (SAN JOSE)',
      'BARANGAY 85 (SAN JOSE)',
      'BARANGAY 86',
      'BARANGAY 87',
      'BARANGAY 88',
      'BARANGAY 89',
      'BARANGAY 90 (SAN JOSE)',
      'BARANGAY 91 (ABUCAY)',
      'BARANGAY 92 (APITONG)',
      'BARANGAY 93 (BAGACAY)',
      'BARANGAY 94 (TIGBAO)',
      'BARANGAY 94-A',
      'BARANGAY 95 (CAIBAAN)',
      'BARANGAY 95-A (CAIBAAN)',
      'BARANGAY 96 (CALANIPAWAN)',
      'BARANGAY 97 (CABALAWAN)',
      'BARANGAY 98 (CAMANSINAY)',
      'BARANGAY 99 (DIIT)',
      'BARANGAY 100 (SAN ROQUE)',
      'BARANGAY 101 (NEW KAWAYAN)',
      'BARANGAY 102 (OLD KAWAYAN)',
      'BARANGAY 103 (PALANOG)',
      'BARANGAY 103-A (SAN PAGLAUM)',
      'BARANGAY 104 (SALVACION)',
      'BARANGAY 105 (SUHI)',
      'BARANGAY 106 (SANTO NIÑO)',
      'BARANGAY 107 (SANTA ELENA)',
      'BARANGAY 108 (TAGAPURO)',
      'BARANGAY 109 (V AND G SUBD.)',
      'BARANGAY 109-A',
      'BARANGAY 110 (UTAP)',
      'EL REPOSO (BARANGAYS 55 AND 55A)',
      'LIBERTAD (BARANGAYS 1 AND 4)',
      'NULA-TULA (BGYS. 3 AND 3A)',
    ],
  },
  TANAUAN: {
    province: 'LEYTE',
    municipality: 'TANAUAN',
    region: 'REGION VIII',
    barangay_list: [
      'ADA',
      'AMANLURAN',
      'ARADO',
      'ATIPOLO',
      'BALUD',
      'BANGON',
      'BANTAGAN',
      'BARAS',
      'BINOLO',
      'BINONGTO-AN',
      'BISLIG',
      'BUNTAY (POB.)',
      'CABALAGNAN',
      'CABARASAN GUTI',
      'CABONGA-AN',
      'CABUYNAN',
      'CAHUMAYHUMAYAN',
      'CALOGCOG',
      'CALSADAHAY',
      'CAMIRE',
      'CANBALISARA',
      'CANRAMOS (POB.)',
      'CATIGBIAN',
      'CATMON',
      'COGON',
      'GUINDAG-AN',
      'GUINGAWAN',
      'HILAGPAD',
      'KILING',
      'LAPAY',
      'LICOD (POB.)',
      'LIMBUHAN DAKU',
      'LIMBUHAN GUTI',
      'LINAO',
      'MAGAY',
      'MAGHULOD',
      'MALAGUICAY',
      'MARIBI',
      'MOHON',
      'PAGO',
      'PASIL',
      'PIKAS',
      'SACME',
      'SALVADOR',
      'SAN ISIDRO',
      'SAN MIGUEL (POB.)',
      'SAN ROQUE (POB.)',
      'SAN VICTOR',
      'SANTA CRUZ',
      'SANTA ELENA',
      'SANTO NIÑO POB. (HACLAGAN)',
      'SOLANO',
      'TALOLORA',
      'TUGOP',
    ],
  },
  TOLOSA: {
    province: 'LEYTE',
    municipality: 'TOLOSA',
    region: 'REGION VIII',
    barangay_list: [
      'BURAK',
      'CANMOGSAY',
      'CANTARIWIS',
      'CAPANGIHAN',
      'DOÑA BRIGIDA',
      'IMELDA',
      'MALBOG',
      'OLOT',
      'OPONG',
      'POBLACION',
      'QUILAO',
      'SAN ROQUE',
      'SAN VICENTE',
      'TANGHAS',
      'TELEGRAFO',
    ],
  },
  TUNGA: {
    province: 'LEYTE',
    municipality: 'TUNGA',
    region: 'REGION VIII',
    barangay_list: [
      'ASTORGA (UPART)',
      'BALIRE',
      'BANAWANG',
      'SAN ANTONIO (POB.)',
      'SAN PEDRO',
      'SAN ROQUE',
      'SAN VICENTE (POB.)',
      'SANTO NIÑO (POB.)',
    ],
  },
  VILLABA: {
    province: 'LEYTE',
    municipality: 'VILLABA',
    region: 'REGION VIII',
    barangay_list: [
      'ABIJAO',
      'BALITE',
      'BANGCAL',
      'BUGABUGA',
      'CABUNGA-AN',
      'CABUNGAHAN',
      'CAGNOCOT',
      'CAHIGAN',
      'CALBUGOS',
      'CAMPOROG',
      'CANQUIASON',
      'CAPINYAHAN',
      'CASILI-ON',
      'CATAGBACAN',
      'FATIMA (POB.)',
      'HIBULANGAN',
      'HINABUYAN',
      'ILIGAY',
      'JALAS',
      'JORDAN',
      'LIBAGONG',
      'NEW BALANAC',
      'PAYAO',
      'POBLACION NORTE',
      'POBLACION SUR',
      'SAMBULAWAN',
      'SAN FRANCISCO',
      'SAN VICENTE',
      'SANTA CRUZ',
      'SILAD',
      'SUBA',
      'SULPA',
      'TABUNOK',
      'TAGBUBUNGA',
      'TINGHUB',
    ],
  },
  ALLEN: {
    province: 'NORTHERN SAMAR',
    municipality: 'ALLEN',
    region: 'REGION VIII',
    barangay_list: [
      'ALEJANDRO VILLAGE (SANTIAGO)',
      'BONIFACIO',
      'CABACUNGAN',
      'CALARAYAN',
      'FREDERIC',
      'GUIN-ARAWAYAN',
      'IMELDA',
      'JUBASAN',
      'KINABRANAN ZONE I (POB.)',
      'KINABRANAN ZONE II (POB.)',
      'KINAGUITMAN',
      'LAGUNDI',
      'LIPATA',
      'LO-OC',
      'LONDRES',
      'SABANG ZONE I (POB.)',
      'SABANG ZONE II (POB.)',
      'SANTA RITA',
      'TASVILLA',
      'VICTORIA',
    ],
  },
  BIRI: {
    province: 'NORTHERN SAMAR',
    municipality: 'BIRI',
    region: 'REGION VIII',
    barangay_list: [
      'KAUSWAGAN (BASUD)',
      'MACARTHUR',
      'PIO DEL PILAR',
      'POBLACION (BIRI)',
      'PROGRESSO',
      'SAN ANTONIO',
      'SAN PEDRO',
      'SANTO NIÑO',
    ],
  },
  BOBON: {
    province: 'NORTHERN SAMAR',
    municipality: 'BOBON',
    region: 'REGION VIII',
    barangay_list: [
      'ACERIDA',
      'ARELLANO',
      'BALAT-BALUD',
      'CALANTIAO (PANGOBI-AN)',
      'DANCALAN',
      'E. DURAN',
      'GEN. LUCBAN (POB.)',
      'JOSE ABAD SANTOS',
      'JOSE P. LAUREL (CASULGAN)',
      'MAGSAYSAY (DOCE)',
      'QUEZON (PANICAYAN)',
      'SALVACION',
      'SAN ISIDRO',
      'SAN JUAN (POB.)',
      'SANTA CLARA (POB.)',
      'SANTANDER',
      'SOMOROY',
      'TROJELLO',
    ],
  },
  CAPUL: {
    province: 'NORTHERN SAMAR',
    municipality: 'CAPUL',
    region: 'REGION VIII',
    barangay_list: [
      'AGUIN',
      'JUBANG',
      'LANDUSAN',
      'OSON',
      'POBLACION BARANGAY 1',
      'POBLACION BARANGAY 2',
      'POBLACION BARANGAY 3',
      'POBLACION BARANGAY 4',
      'POBLACION BARANGAY 5',
      'SAGAOSAWAN',
      'SAN LUIS',
      'SAWANG',
    ],
  },
  CATUBIG: {
    province: 'NORTHERN SAMAR',
    municipality: 'CATUBIG',
    region: 'REGION VIII',
    barangay_list: [
      'ANONGO',
      'BARANGAY 1 (POB.)',
      'BARANGAY 2 (POB.)',
      'BARANGAY 3 (POB.)',
      'BARANGAY 4 (POB.)',
      'BARANGAY 5 (POB.)',
      'BARANGAY 6 (POB.)',
      'BARANGAY 7 (POB.)',
      'BARANGAY 8 (POB.)',
      'BONIFACIO',
      'BORING',
      'CAGBUGNA',
      'CAGMANABA',
      'CAGOGOBNGAN',
      'CALINGNAN',
      'CANUCTAN',
      'CLARO M. RECTO (LOBEDICO)',
      'D. MERCADER (BONGOG)',
      'GUIBWANGAN',
      'HINAGONOYAN',
      'HIPARAYAN',
      'HITAPI-AN',
      'INOBURAN',
      'IRAWAHAN',
      'LENOYAHAN',
      'LIBON',
      'MAGONGON',
      'MAGTUAD',
      'MANERING',
      'NABULO',
      'NAGOOCAN',
      'NAHULID',
      'OPONG',
      'OSANG',
      'OSMEÑA',
      'P. REBADULLA',
      'ROXAS',
      'SAGUDSURON',
      'SAN ANTONIO',
      'SAN FRANCISCO',
      'SAN JOSE (HEBOBOLLAO)',
      'SAN VICENTE',
      'SANTA FE',
      'SULITAN',
      'TANGBO',
      'TUNGODNON',
      'VIENNA MARIA',
    ],
  },
  GAMAY: {
    province: 'NORTHERN SAMAR',
    municipality: 'GAMAY',
    region: 'REGION VIII',
    barangay_list: [
      'ANITO',
      'BANGON',
      'BATO',
      'BAYBAY DISTRICT (POB.)',
      'BONIFACIO',
      'BURABOD (POB.)',
      'CABARASAN',
      'CADAC-AN (CALINGNAN)',
      'CADE-AN',
      'CAGAMUTAN DEL NORTE',
      'CAGAMUTAN DEL SUR',
      'DAO',
      'G. M. OSIAS',
      'GAMAY CENTRAL (POB.)',
      'GAMAY OCCIDENTAL I (POB.)',
      'GAMAY ORIENTAL I (POB.)',
      'GUIBUANGAN',
      'HENOGAWE',
      'LIBERTAD (POB.)',
      'LONOY',
      'LUNETA',
      'MALIDONG',
      'OCCIDENTAL II (POB.)',
      'ORIENTAL II (POB.)',
      'RIZAL',
      'SAN ANTONIO',
    ],
  },
  LAOANG: {
    province: 'NORTHERN SAMAR',
    municipality: 'LAOANG',
    region: 'REGION VIII',
    barangay_list: [
      'ABATON',
      'AGUADAHAN',
      'AROGANGA',
      'ATIPOLO',
      'BAWANG',
      'BAYBAY (POB.)',
      'BINATIKLAN',
      'BOBOLOSAN',
      'BONGLIW',
      'BURABUD',
      'CABADIANGAN',
      'CABAGNGAN',
      'CABAGO-AN',
      'CABULALOAN',
      'CAGAASAN',
      'CAGDARA-O',
      'CAHAYAGAN',
      'CALINTAAN POB. (STO. NIÑO)',
      'CALOMOTAN',
      'CANDAWID',
      'CANGCAHIPOS',
      'CANYOMANAO',
      'CATIGBIAN',
      'E. J. DULAY',
      'G. B. TAN',
      'GIBATANGAN',
      'GUILAOANGI (POB.)',
      'INAMLAN',
      'LA PERLA',
      'LANGOB',
      'LAWAAN',
      'LITTLE VENICE (POB.)',
      'MAGSAYSAY',
      'MARUBAY',
      'MUALBUAL',
      'NAPOTIOCAN (SALVACION)',
      'OLERAS',
      'ONAY (DOÑA LUISA)',
      'PALMERA',
      'PANGDAN',
      'RAWIS',
      'ROMBANG',
      'SAN ANTONIO (SON-OG)',
      'SAN MIGUEL HEIGHTS (POB.)',
      'SANGCOL',
      'SIBUNOT',
      'SIMORA',
      'SUBA',
      'TALISAY',
      'TAN-AWAN',
      'TARUSAN',
      'TINOBLAN',
      'TUMAGUINGTING (POB.)',
      'VIGO',
      'YABYABAN (SAN VICENTE)',
      'YAPAS',
    ],
  },
  LAPINIG: {
    province: 'NORTHERN SAMAR',
    municipality: 'LAPINIG',
    region: 'REGION VIII',
    barangay_list: [
      'ALANG-ALANG',
      'BAGACAY',
      'CAHAGWAYAN',
      'CAN MARIA',
      'CAN OMANIO',
      'IMELDA',
      'LAPINIG DEL NORTE (POB.)',
      'LAPINIG DEL SUR (POB.)',
      'LO-OK',
      'MABINI',
      'MAY-IGOT',
      'PALANAS',
      'PIO DEL PILAR',
      'POTONG',
      'POTONG DEL SUR',
    ],
  },
  'LAS NAVAS': {
    province: 'NORTHERN SAMAR',
    municipality: 'LAS NAVAS',
    region: 'REGION VIII',
    barangay_list: [
      'BALUGO',
      'BUGAY',
      'BUGTOSAN',
      'BUKID',
      'BULAO',
      'CAPUTOAN',
      'CATOTO-OGAN',
      'CUENCO',
      'DAPDAP',
      'DEL PILAR',
      'DOLORES',
      'EPAW',
      'GEGUINTA',
      'GERACDO',
      'GUYO',
      'H. JOLEJOLE',
      'H. JOLEJOLE DISTRICT (POB.)',
      'HANGI',
      'IMELDA',
      'L. EMPON',
      'LAKANDULA',
      'LOURDES',
      'LUMALA-OG',
      'MABINI',
      'MACARTHUR',
      'MAGSAYSAY',
      'MATELARAG',
      'OSMEÑA',
      'PACO',
      'PALANAS',
      'PEREZ',
      'POPONTON',
      'QUEZON',
      'QUIRINO',
      'QUIRINO DISTRICT (POB.)',
      'REBONG',
      'RIZAL',
      'ROXAS',
      'RUFINO',
      'SAG-OD',
      'SAN ANDRES',
      'SAN ANTONIO',
      'SAN FERNANDO',
      'SAN FRANCISCO',
      'SAN ISIDRO',
      'SAN JORGE',
      'SAN JOSE',
      'SAN MIGUEL',
      'SANTO TOMAS',
      'TAGAB-IRAN',
      'TAGAN-AYAN',
      'TAYLOR',
      'VICTORY',
    ],
  },
  LAVEZARES: {
    province: 'NORTHERN SAMAR',
    municipality: 'LAVEZARES',
    region: 'REGION VIII',
    barangay_list: [
      'BALICUATRO',
      'BANI',
      'BAROBAYBAY',
      'CABURIHAN (POB.)',
      'CARAGAS (POB.)',
      'CATAOGAN (POB.)',
      'CHANSVILLA',
      'DATAG',
      'ENRIQUETA',
      'LIBAS',
      'LIBERTAD',
      'MACARTHUR',
      'MAGSAYSAY',
      'MARAVILLA',
      'OCAD (POB.)',
      'SABONG-TABOK',
      'SALVACION',
      'SAN AGUSTIN',
      'SAN ISIDRO',
      'SAN JOSE',
      'SAN JUAN',
      'SAN MIGUEL',
      'TO-OG',
      'URDANETA',
      'VILLA',
      'VILLAHERMOSA',
    ],
  },
  'LOPE DE VEGA': {
    province: 'NORTHERN SAMAR',
    municipality: 'LOPE DE VEGA',
    region: 'REGION VIII',
    barangay_list: [
      'BAYHO',
      'BONIFACIO',
      'CAG-AGUINGAY',
      'CAGAMESARAG',
      'CURRY',
      'GEBONAWAN',
      'GEN. LUNA',
      'GETIGO',
      'HENARONAGAN',
      'LOPE DE VEGA (POB.)',
      'LOWER CAYNAGA',
      'MAGHIPID',
      'MAGSAYSAY',
      'OSMEÑA',
      'PAGUITE',
      'ROXAS',
      'SAMPAGUITA',
      'SAN FRANCISCO',
      'SAN JOSE',
      'SAN MIGUEL',
      'SOMOROY',
      'UPPER CAYNAGA',
    ],
  },
  MAPANAS: {
    province: 'NORTHERN SAMAR',
    municipality: 'MAPANAS',
    region: 'REGION VIII',
    barangay_list: [
      'BURGOS',
      'DEL NORTE (POB.)',
      'DEL SUR (POB.)',
      'E. LAODENIO',
      'JUBASAN',
      'MAGSAYSAY',
      'MAGTAON',
      'MANAYBANAY',
      'NAPARASAN',
      'QUEZON',
      'SAN JOSE',
      'SANTA POTENCIANA (POB.)',
      'SILJAGON',
    ],
  },
  MONDRAGON: {
    province: 'NORTHERN SAMAR',
    municipality: 'MONDRAGON',
    region: 'REGION VIII',
    barangay_list: [
      'BAGASBAS',
      'BUGKO',
      'CABLANGAN',
      'CAGMANABA',
      'CAHICSAN',
      'CHITONGCO (POB.)',
      'DE MARIA',
      'DOÑA LUCIA',
      'ECO (POB.)',
      'FLORMINA',
      'HINABANGAN',
      'IMELDA',
      'LA TRINIDAD',
      'MAKIWALO',
      'MIRADOR',
      'NENITA',
      'ROXAS',
      'SAN AGUSTIN',
      'SAN ANTONIO',
      'SAN ISIDRO',
      'SAN JOSE',
      'SAN JUAN',
      'SANTA CATALINA',
      'TALOLORA',
    ],
  },
  PALAPAG: {
    province: 'NORTHERN SAMAR',
    municipality: 'PALAPAG',
    region: 'REGION VIII',
    barangay_list: [
      'ASUM (POB.)',
      'BAGACAY',
      'BANGON',
      'BENIGNO S. AQUINO, JR.',
      'BINAY',
      'CABARIWAN',
      'CABATUAN',
      'CAMPEDICO',
      'CAPACUJAN',
      'JANGTUD',
      'LANIWAN (POB.)',
      'MABARAS',
      'MAGSAYSAY',
      'MANAJAO',
      'MAPNO',
      'MARAGANO',
      'MATAMBAG',
      'MONBON',
      'NAGBOBTAC',
      'NAPO',
      'NATAWO',
      'NIPA',
      'OSMEÑA',
      'PANGPANG',
      'PAYSUD',
      'SANGAY',
      'SIMORA',
      'SINALARAN',
      'SUMOROY',
      'TALOLORA',
      'TAMBANGAN (POB.)',
      'TINAMPO (POB.)',
    ],
  },
  PAMBUJAN: {
    province: 'NORTHERN SAMAR',
    municipality: 'PAMBUJAN',
    region: 'REGION VIII',
    barangay_list: [
      'CABABTO-AN',
      'CABARI-AN',
      'CAGBIGAJO',
      'CAMPARANGA',
      'CANJUMADAL',
      'DOÑA ANECITA',
      'GE-ADGAWAN',
      'GEPARAYAN',
      'GINULGAN',
      'IGOT',
      'INANAHAWAN',
      'MANAHAO',
      'PANINIRONGAN',
      'POBLACION DISTRICT 1',
      'POBLACION DISTRICT 2',
      'POBLACION DISTRICT 3',
      'POBLACION DISTRICT 4',
      'POBLACION DISTRICT 5',
      'POBLACION DISTRICT 6',
      'POBLACION DISTRICT 7',
      'POBLACION DISTRICT 8',
      'SAN RAMON',
      'SENONOGAN',
      'SIXTO T. BALANGUIT, SR.',
      'TULA',
      'YNAGUINGAYAN',
    ],
  },
  'SAN ROQUE': {
    province: 'NORTHERN SAMAR',
    municipality: 'SAN ROQUE',
    region: 'REGION VIII',
    barangay_list: [
      'BALNASAN',
      'BALUD',
      'BANTAYAN',
      'COROCONOG',
      'DALE',
      'GINAGDANAN',
      'LAO-ANGAN',
      'LAWAAN',
      'MALOBAGO',
      'PAGSANG-AN',
      'ZONE 1 (POB.)',
      'ZONE 2 (POB.)',
      'ZONE 3 (POB.)',
      'ZONE 4 (POB.)',
      'ZONE 5 (POB.)',
      'ZONE 6 (POB.)',
    ],
  },
  'SILVINO LOBOS': {
    province: 'NORTHERN SAMAR',
    municipality: 'SILVINO LOBOS',
    region: 'REGION VIII',
    barangay_list: [
      'BALUD',
      'CABABAYOGAN',
      'CABUNGA-AN',
      'CAGDA-O',
      'CAGHILOT',
      'CAMANGGARAN',
      'CAMAYA-AN',
      'DEIT DE SUBA',
      'DEIT DE TURAG',
      'GEBOLWANGAN',
      'GEBONAWAN',
      'GECBO-AN',
      'GENAGASAN',
      'GEPARAYAN DE TURAG',
      'GIGUIMITAN',
      'GUSARAN',
      'IMELDA',
      'MONTALBAN',
      'POBLACION I',
      'POBLACION II',
      'SAN ANTONIO',
      'SAN ISIDRO',
      'SENONOGAN DE TUBANG',
      'SUBA (POB.)',
      'TOBGON',
      'VICTORY',
    ],
  },
  ALMAGRO: {
    province: 'SAMAR (WESTERN SAMAR)',
    municipality: 'ALMAGRO',
    region: 'REGION VIII',
    barangay_list: [
      'BACJAO',
      'BIASONG I',
      'BIASONG II',
      'COSTA RICA',
      'COSTA RICA II',
      'GUIN-ANSAN',
      'IMELDA (BADJANG)',
      'KERIKITE',
      'LUNANG I (LOOK)',
      'LUNANG II',
      'MABUHAY',
      'MAGSAYSAY',
      'MALOBAGO',
      'MARASBARAS',
      'PANJOBJOBAN I',
      'PANJOBJOBAN II',
      'POBLACION',
      'ROÑO',
      'SAN ISIDRO',
      'SAN JOSE',
      'TALAHID',
      'TONGA-TONGA',
      'VELOSO',
    ],
  },
  BASEY: {
    province: 'SAMAR (WESTERN SAMAR)',
    municipality: 'BASEY',
    region: 'REGION VIII',
    barangay_list: [
      'AMANDAYEHAN',
      'ANGLIT',
      'BACUBAC',
      'BALANTE',
      'BALOOG',
      'BASIAO',
      'BAYBAY (POB.)',
      'BINONGTU-AN',
      'BUENAVISTA',
      'BULAO',
      'BURGOS',
      'BUSCADA (POB.)',
      'CAMBAYAN',
      'CAN-ABAY',
      'CANCAIYAS',
      'CANMANILA',
      'CATADMAN',
      'COGON',
      'DEL PILAR',
      'DOLONGAN',
      'GUINTIGUI-AN',
      'GUIRANG',
      'IBA',
      'INUNTAN',
      'LAWA-AN (POB.)',
      'LOOG',
      'LOYO (POB.)',
      'MABINI',
      'MAGALLANES',
      'MANLILINAB',
      'MAY-IT',
      'MERCADO (POB.)',
      'MONGABONG',
      'NEW SAN AGUSTIN',
      'NOUVELAS OCCIDENTAL',
      'OLD SAN AGUSTIN',
      'PALAYPAY (POB.)',
      'PANUGMONON',
      'PELIT',
      'ROXAS',
      'SALVACION',
      'SAN ANTONIO',
      'SAN FERNANDO (NOUVELAS ORIENTAL)',
      'SAWA',
      'SERUM',
      'SUGCA',
      'SUGPONON',
      'SULOD (POB.)',
      'TINAOGAN',
      'TINGIB',
      'VILLA AURORA',
    ],
  },
  'CALBAYOG': {
    province: 'SAMAR (WESTERN SAMAR)',
    municipality: 'CALBAYOG',
    region: 'REGION VIII',
    barangay_list: [
      'ACEDILLO',
      'AGUIT-ITAN (POB.)',
      'ALIBABA',
      'AMAMPACANG',
      'ANISLAG',
      'AWANG EAST (POB.)',
      'AWANG WEST (POB.)',
      'BA-AY',
      'BAGACAY',
      'BAGONG LIPUNAN',
      'BAJA',
      'BALUD (POB.)',
      'BANTE',
      'BANTIAN',
      'BASUD',
      'BAYO',
      'BEGAHO',
      'BINALIW',
      'BONTAY',
      'BUENAVISTA',
      'BUGTONG',
      'CABACUNGAN',
      'CABATUAN',
      'CABICAHAN',
      'CABUGAWAN',
      'CACARANSAN',
      'CAG-ANAHAW',
      'CAG-ANIBONG',
      'CAG-OLANGO',
      'CAGBANAYACAO',
      'CAGBAYANG',
      'CAGBILWANG',
      'CAGBOBORAC',
      'CAGLANIPAO SUR',
      'CAGMANIPES NORTE',
      'CAGMANIPES SUR',
      'CAGNIPA',
      'CAGSALAOSAO',
      'CAHUMPAN',
      'CALOCNAYAN',
      'CANGOMAOD',
      'CANHUMADAC',
      'CAPACUHAN',
      'CAPOOCAN',
      'CARAYMAN',
      'CARMEN',
      'CATABUNAN',
      'CAYBAGO',
      'CENTRAL (POB.)',
      'COGON',
      'DAGUM',
      'DANAO I',
      'DANAO II',
      'DAWO',
      'DE VICTORIA',
      'DINABONGAN',
      'DINAGAN',
      'DINAWACAN',
      'ESPERANZA',
      'GABAY',
      'GADGARAN',
      'GASDO',
      'GERAGA-AN',
      'GUIMBAOYAN NORTE',
      'GUIMBAOYAN SUR',
      'GUIN-ON',
      'HAMORAWON',
      'HELINO',
      'HIBABNGAN',
      'HIBATANG',
      'HIGASAAN',
      'HIMALANDROG',
      'HUGON ROSALES',
      'JACINTO',
      'JIMAUTAN',
      'JOSE A. ROÑO',
      'KALILIHAN',
      'KILIKILI',
      'LA PAZ',
      'LANGOYON',
      'LAPAAN',
      'LIBERTAD',
      'LIMARAYON',
      'LONGSOB',
      'LONOY',
      'LOOC',
      'MABINI I (CALBAYOG DISTRICT)',
      'MABINI II (OQUENDO DISTRICT)',
      'MACATINGOG',
      'MAG-UBAY',
      'MAGUINO-O',
      'MALAGA',
      'MALAJOG',
      'MALAYOG',
      'MALOPALO',
      'MANCOL',
      'MANTAONG (OQUENDO DISTRICT)',
      'MANUEL BARRAL, SR.',
      'MARCATUBIG',
      'MATOBATO',
      'MAWACAT',
      'MAYBOG',
      'MAYSALONG',
      'MIGARA',
      'NABANG',
      'NAGA',
      'NAGUMA',
      'NAVARRO',
      'NIJAGA',
      'OBOOB',
      'OBRERO',
      'OLERA',
      'OQUENDO (POB.)',
      'OSMEÑA',
      'PAGBALICAN',
      'PALANAS',
      'PALANOGAN',
      'PANLAYAHAN',
      'PANONONGAN',
      'PANOYPOY',
      'PATONG',
      'PAYAHAN',
      'PEÑA',
      'PILAR',
      'PINAMOROTAN',
      'QUEZON',
      'RAWIS',
      'RIZAL I (CALBAYOG DISTRICT)',
      'RIZAL II (OQUENDO DISTRICT)',
      'ROXAS I (CALBAYOG DISTRICT)',
      'ROXAS II (OQUENDO DISTRICT)',
      'SALJAG (BAUT)',
      'SALVACION',
      'SAN ANTONIO',
      'SAN ISIDRO',
      'SAN JOAQUIN',
      'SAN JOSE',
      'SAN POLICARPIO',
      'SAN RUFINO',
      'SAPUTAN',
      'SIGO',
      'SINANTAN',
      'SINIDMAN OCCIDENTAL',
      'SINIDMAN ORIENTAL',
      'TABAWAN',
      'TALAHIBAN',
      'TANVAL',
      'TAPA-E',
      'TARABUCAN',
      'TIGBE',
      'TINAMBACAN NORTE',
      'TINAMBACAN SUR',
      'TINAPLACAN',
      'TOMALIGUEZ',
      'TRINIDAD (SABANG)',
      'VICTORY',
      'VILLAHERMOSA',
    ],
  },
  CALBIGA: {
    province: 'SAMAR (WESTERN SAMAR)',
    municipality: 'CALBIGA',
    region: 'REGION VIII',
    barangay_list: [
      'ANTOL',
      'BACYARAN',
      'BARANGAY 1 (POB.)',
      'BARANGAY 2 (POB.)',
      'BARANGAY 3 (POB.)',
      'BARANGAY 4 (POB.)',
      'BARANGAY 5 (POB.)',
      'BARANGAY 6 (POB.)',
      'BARANGAY 7 (POB.)',
      'BAROBAYBAY',
      'BERI',
      'BINANGGARAN',
      'BORONG',
      'BULAO',
      'BULUAN',
      'CAAMLONGAN',
      'CALAYAAN',
      'CALINGONAN',
      'CANBAGTIC',
      'CANTICUM',
      'DALIGAN',
      'GUINBANGA',
      'HINDANG',
      'HUBASAN',
      'LITERON',
      'LUBANG',
      'MACAALAN',
      'MAHANGCAO',
      'MALABAL',
      'MINATA',
      'OTOC',
      'PANAYURAN',
      'PASIGAY',
      'PATONG',
      'POLANGI',
      'RAWIS',
      'SAN IGNACIO',
      'SAN MAURICIO',
      'SINALANGTAN',
      'TIMBANGAN',
      'TINAGO',
    ],
  },
  'CATBALOGAN': {
    province: 'SAMAR (WESTERN SAMAR)',
    municipality: 'CATBALOGAN',
    region: 'REGION VIII',
    barangay_list: [
      'ALBALATE',
      'BAGONGON',
      'BANGON',
      'BASIAO',
      'BULUAN',
      'BUNUANAN',
      'CABUGAWAN',
      'CAGUDALO',
      'CAGUSIPAN',
      'CAGUTIAN',
      'CAGUTSAN',
      'CANHAWAN GOTE',
      'CANLAPWAS (POB.)',
      'CAWAYAN',
      'CINCO',
      'DARAHUWAY DACO',
      'DARAHUWAY GOTE',
      'ESTAKA',
      'GUINDAPONAN',
      'GUINSORONGAN',
      'IBOL',
      'IGUID',
      'LAGUNDI',
      'LIBAS',
      'LOBO',
      'MANGUEHAY',
      'MAULONG',
      'MERCEDES',
      'MOMBON',
      'MUÑOZ (POBLACION 14)',
      'NEW MAHAYAG',
      'OLD MAHAYAG',
      'PALANYOGON',
      'PANGDAN',
      'PAYAO',
      'POBLACION 1 (BARANGAY 1)',
      'POBLACION 2 (BARANGAY 2)',
      'POBLACION 3 (BARANGAY 3)',
      'POBLACION 4 (BARANGAY 4)',
      'POBLACION 5 (BARANGAY 5)',
      'POBLACION 6 (BARANGAY 6)',
      'POBLACION 7 (BARANGAY 7)',
      'POBLACION 8 (BARANGAY 8)',
      'POBLACION 9 (BARANGAY 9)',
      'POBLACION 10 (BARANGAY 10)',
      'POBLACION 11 (BARANGAY 11)',
      'POBLACION 12 (BARANGAY 12)',
      'POBLACION 13 (BARANGAY 13)',
      'PUPUA',
      'RAMA',
      'SAN ANDRES',
      'SAN PABLO',
      'SAN ROQUE',
      'SAN VICENTE',
      'SILANGA',
      'SOCORRO',
      'TOTORINGON',
    ],
  },
  DARAM: {
    province: 'SAMAR (WESTERN SAMAR)',
    municipality: 'DARAM',
    region: 'REGION VIII',
    barangay_list: [
      'ARAWANE',
      'ASTORGA',
      'BACHAO',
      'BACLAYAN',
      'BAGACAY',
      'BAYOG',
      'BETAUG',
      'BIRAWAN',
      'BONO-ANON',
      'BUENAVISTA',
      'BURGOS',
      'CABAC',
      'CABIL-ISAN',
      'CABITON-AN',
      'CABUGAO',
      'CAGBOBOTO',
      'CALAWAN-AN',
      'CAMBUHAY',
      'CAMPELIPA',
      'CANDUGUE',
      'CANLOLOY',
      'CANSAGANAY',
      'CASAB-AHAN',
      'GUINDAPUNAN',
      'GUINTAMPILAN',
      'IQUIRAN',
      'JACOPON',
      'LOSA',
      'LUCOB-LUCOB',
      'MABINI',
      'MACALPE',
      'MANDOYUCAN',
      'MARUPANGDAN',
      'MAYABAY',
      'MONGOLBONGOL',
      'NIPA',
      'PARASAN',
      'POBLACION 1 (HILABA)',
      'POBLACION 2 (MALINGON)',
      'POBLACION 3 (CANTI-IL)',
      'PONDANG',
      'POSO',
      'REAL',
      'RIZAL',
      'SAN ANTONIO',
      'SAN JOSE',
      'SAN MIGUEL',
      'SAN ROQUE',
      'SAN VICENTE',
      'SAUGAN',
      'SO-ONG',
      'SUA',
      'SUGOD',
      'TALISAY',
      'TUGAS',
      'UBO',
      'VALLES-BELLO',
      'YANGTA',
    ],
  },
  GANDARA: {
    province: 'SAMAR (WESTERN SAMAR)',
    municipality: 'GANDARA',
    region: 'REGION VIII',
    barangay_list: [
      'ADELA HEIGHTS (POB.)',
      'ARONG',
      'BALOCAWE',
      'BANGAHON',
      'BESLIG',
      'BUAO',
      'BUNYAGAN',
      'BURABOD I (POB.)',
      'BURABOD II (POB.)',
      'CALIROCAN',
      'CANHUMAWID',
      'CAPARANGASAN',
      'CARANAS',
      'CARMONA',
      'CASAB-AHAN',
      'CASANDIG',
      'CATORSE DE AGOSTO',
      'CAUGBUSAN',
      'CONCEPCION',
      'DIAZ',
      'DUMALO-ONG (POB.)',
      'ELCANO',
      'GERALI',
      'GEREGANAN',
      'GIABOC',
      'HAMPTON',
      'HETEBAC',
      'HIMAMALOTO',
      'HINAYAGAN',
      'HINUGACAN',
      'HIPARAYAN (KINONONGAN)',
      'JASMINEZ',
      'LUNGIB',
      'MABUHAY',
      'MACUGO',
      'MALAYOG',
      'MARCOS',
      'MINDA (POB.)',
      'NACUBE',
      'NALIHUGAN',
      'NAPALISAN',
      'NATIMONAN',
      'NGOSO',
      'PALAMBRAG',
      'PALANAS',
      'PIÑAPLATA',
      'PIZARRO',
      'POLOGON',
      'PUROG',
      'RAWIS',
      'RIZAL',
      'SAMOYAO',
      'SAN AGUSTIN',
      'SAN ANTONIO',
      'SAN ENRIQUE',
      'SAN FRANCISCO',
      'SAN ISIDRO (DAO)',
      'SAN JOSE',
      'SAN MIGUEL',
      'SAN PELAYO',
      'SAN RAMON',
      'SANTA ELENA',
      'SANTO NIÑO',
      'SENIBARAN (VILLA LEONA)',
      'SIDMON',
      'TAGNAO',
      'TAMBONGAN',
      'TAWIRAN',
      'TIGBAWON',
    ],
  },
  HINABANGAN: {
    province: 'SAMAR (WESTERN SAMAR)',
    municipality: 'HINABANGAN',
    region: 'REGION VIII',
    barangay_list: [
      'BAGACAY',
      'BINOBUCALAN',
      'BUCALAN',
      'CABALAGNAN',
      'CABANG',
      'CANANO',
      'CONCORD',
      'CONSOLABAO',
      'DALOSDOSON',
      'FATIMA',
      'LIM-AO',
      'MALIHAO',
      'MUGDO',
      'OSMEÑA',
      'POBLACION 1 (BARANGAY 1)',
      'POBLACION 2 (BARANGAY 2)',
      'RAWIS',
      'SAN JOSE',
      'SAN RAFAEL',
      'TABAY',
      'YABON',
    ],
  },
  JIABONG: {
    province: 'SAMAR (WESTERN SAMAR)',
    municipality: 'JIABONG',
    region: 'REGION VIII',
    barangay_list: [
      'BARANGAY NO. 1 (POB.)',
      'BARANGAY NO. 2 (POB.)',
      'BARANGAY NO. 3 (POB.)',
      'BARANGAY NO. 4 (POB.)',
      'BARANGAY NO. 5 (POB.)',
      'BARANGAY NO. 6 (POB.)',
      'BARANGAY NO. 7 (POB.)',
      'BARANGAY NO. 8 (POB.)',
      'BAWANG',
      'BUGHO',
      'CAMAROBO-AN',
      'CANDAYAO',
      'CANTONGTONG',
      'CASAPA',
      'CATALINA',
      'CRISTINA',
      'DOGONGAN',
      'GARCIA',
      'HINAGA',
      'JIA-AN',
      'JIDANAO',
      'LULUGAYAN',
      'MACABETAS',
      'MALINO',
      'MALOBAGO (VILLALINDA)',
      'MERCEDES',
      'NAGBAC',
      'PARINA',
      'SALVACION',
      'SAN ANDRES',
      'SAN FERNANDO',
      'SAN MIGUEL',
      'TAGBAYAON',
      'VICTORY',
    ],
  },
  MARABUT: {
    province: 'SAMAR (WESTERN SAMAR)',
    municipality: 'MARABUT',
    region: 'REGION VIII',
    barangay_list: [
      'AMAMBUCALE',
      'AMANTILLO',
      'BINUKYAHAN',
      'CALUWAYAN',
      'CANYOYO',
      'CATATO POB. (DIST. I)',
      'FERRERAS',
      'LEGASPI',
      'LIPATA',
      'LOGERO',
      'MABUHAY',
      'MALOBAGO',
      'ODOC',
      'OSMEÑA',
      'PANAN-AWAN',
      'PINALANGA',
      'PINAMITINAN (POB.)',
      'ROÑO',
      'SAN ROQUE',
      'SANTA RITA',
      'SANTO NIÑO POB. (DIST. II)',
      'TAGALAG',
      'TINABANAN',
      'VELOSO',
    ],
  },
  MATUGUINAO: {
    province: 'SAMAR (WESTERN SAMAR)',
    municipality: 'MATUGUINAO',
    region: 'REGION VIII',
    barangay_list: [
      'ANGYAP',
      'BAG-OTAN',
      'BARRUZ (BARANGAY NO. 1)',
      'CAMONOAN',
      'CAROLINA',
      'DEIT',
      'DEL ROSARIO',
      'INUBOD',
      'LIBERTAD',
      'LIGAYA',
      'MABULIGON POB. (BARANGAY NO. 2)',
      'MADUROTO POB. (BARANGAY NO. 3)',
      'MAHANUD',
      'MAHAYAG',
      'NAGPAPACAO',
      'RIZAL',
      'SALVACION',
      'SAN ISIDRO',
      'SAN ROQUE (MABUHAY)',
      'SANTA CRUZ',
    ],
  },
  MOTIONG: {
    province: 'SAMAR (WESTERN SAMAR)',
    municipality: 'MOTIONG',
    region: 'REGION VIII',
    barangay_list: [
      'ANGYAP',
      'BARAYONG',
      'BAYOG',
      'BERI',
      'BONGA',
      'CALANTAWAN',
      'CALAPI',
      'CALUYAHAN',
      'CANATUAN',
      'CANDOMACOL',
      'CANVAIS',
      'CAPAYSAGAN',
      'CARANAS',
      'CAULAYANAN',
      'HINICA-AN',
      'INALAD',
      'LINONOBAN',
      'MALOBAGO',
      'MALONOY',
      'MARARANGSI',
      'MAYPANGE',
      'NEW MINAROG',
      'OYANDIC',
      'PAMAMASAN',
      'POBLACION I',
      'POBLACION I-A',
      'PUSONGAN',
      'SAN ANDRES',
      'SANTO NIÑO',
      'SARAO',
    ],
  },
  PAGSANGHAN: {
    province: 'SAMAR (WESTERN SAMAR)',
    municipality: 'PAGSANGHAN',
    region: 'REGION VIII',
    barangay_list: [
      'BANGON',
      'BUENOS AIRES',
      'CALANYUGAN',
      'CALOLOMA',
      'CAMBAYE',
      'CANLAPWAS (POB.)',
      'LIBERTAD',
      'PAÑGE',
      'SAN LUIS',
      'SANTO NIÑO',
      'VIEJO',
      'VILLAHERMOSA OCCIDENTAL',
      'VILLAHERMOSA ORIENTAL',
    ],
  },
  'PARANAS (WRIGHT)': {
    province: 'SAMAR (WESTERN SAMAR)',
    municipality: 'PARANAS (WRIGHT)',
    region: 'REGION VIII',
    barangay_list: [
      'ANAGASI',
      'APOLONIA',
      'BAGSA',
      'BALBAGAN',
      'BATO',
      'BURAY (BINOGHO)',
      'CANTAGUIC',
      'CANTAO-AN',
      'CANTATO (CANTURAB)',
      'CASANDIG I',
      'CASANDIG II',
      'CAWAYAN',
      'CONCEPCION',
      'JOSE ROÑO',
      'LAWAAN I',
      'LAWAAN II',
      'LIPATA',
      'LOKILOKON',
      'MANGCAL',
      'MAYLOBE',
      'MINAROG',
      'NAWI',
      'PABANOG',
      'PACO',
      'PAGSA-OGAN',
      'PAGSANJAN (PAGSAN-AN)',
      'PATAG',
      'PEQUIT',
      'POBLACION 1',
      'POBLACION 2',
      'POBLACION 3',
      'POBLACION 4',
      'POBLACION 5',
      'POBLACION 6',
      'SALAY',
      'SAN ISIDRO',
      'SANTO NIÑO',
      'SULOPAN',
      'TABUCAN',
      'TAPUL',
      'TENANI',
      'TIGBAWON',
      'TULA',
      'TUTUBIGAN',
    ],
  },
  PINABACDAO: {
    province: 'SAMAR (WESTERN SAMAR)',
    municipality: 'PINABACDAO',
    region: 'REGION VIII',
    barangay_list: [
      'BANGON',
      'BARANGAY I (POB.)',
      'BARANGAY II (POB.)',
      'BOTOC',
      'BUGHO',
      'CALAMPONG',
      'CANLOBO',
      'CATIGAWAN',
      'DOLORES',
      'LALE',
      'LAWAAN',
      'LAYGAYON',
      'LAYO',
      'LOCTOB',
      'MADALUNOT',
      'MAGDAWAT',
      'MAMBOG',
      'MANAING',
      'NABONG',
      'OBAYAN',
      'PAHUG',
      'PARASANON',
      'PELAON',
      'SAN ISIDRO',
    ],
  },
  'SAN JORGE': {
    province: 'SAMAR (WESTERN SAMAR)',
    municipality: 'SAN JORGE',
    region: 'REGION VIII',
    barangay_list: [
      'ANQUIANA (ANGKIANA)',
      'AURORA',
      'BAY-ANG',
      'BLANCA AURORA',
      'BUENAVISTA I',
      'BUENAVISTA II',
      'BULAO',
      'BUNGLIW',
      'CABUGAO',
      'CAG-OLO-OLO',
      'CALUNDAN',
      'CANTAGUIC',
      'CANYAKI',
      'COGTOTO-OG',
      'ERENAS',
      'GAYONDATO',
      'GUADALUPE',
      'GUINDAPUNAN',
      'HERNANDEZ',
      'HIMAY',
      'JANIPON',
      'LA PAZ',
      'LIBERTAD',
      'LINCORO',
      'MABUHAY',
      'MANCOL (POB.)',
      'MATALUD',
      'MOBO-OB',
      'MOMBON',
      'PUHAGAN',
      'QUEZON',
      'RANERA',
      'RAWIS',
      'ROSALIM',
      'SAN ISIDRO',
      'SAN JORGE I (POB.)',
      'SAN JORGE II (POB.)',
      'SAN JUAN',
      'SAPINIT',
      'SINIT-AN',
      'TOMOGBONG',
    ],
  },
  'SAN JOSE DE BUAN': {
    province: 'SAMAR (WESTERN SAMAR)',
    municipality: 'SAN JOSE DE BUAN',
    region: 'REGION VIII',
    barangay_list: [
      'AGUINGAYAN',
      'BABACLAYON',
      'BARANGAY 1 (POB.)',
      'BARANGAY 2 (POB.)',
      'BARANGAY 3 (POB.)',
      'BARANGAY 4 (POB.)',
      'CAN-APONTE',
      'CATAYDONGAN',
      'GUSA',
      'HAGBAY',
      'HIBACA-AN',
      'HIDUROMA',
      'HILUMOT',
      'SAN NICOLAS',
    ],
  },
  'SAN SEBASTIAN': {
    province: 'SAMAR (WESTERN SAMAR)',
    municipality: 'SAN SEBASTIAN',
    region: 'REGION VIII',
    barangay_list: [
      'BALOGO',
      'BONTOD',
      'CABAYWA',
      'CAMANHAGAY',
      'CAMPIYAK',
      'CANDUYUCAN',
      'DOLORES',
      'HITA-ASAN I',
      'HITA-ASAN II',
      'INOBONGAN',
      'POBLACION BARANGAY 1',
      'POBLACION BARANGAY 2',
      'POBLACION BARANGAY 3',
      'POBLACION BARANGAY 4',
    ],
  },
  'SANTA MARGARITA': {
    province: 'SAMAR (WESTERN SAMAR)',
    municipality: 'SANTA MARGARITA',
    region: 'REGION VIII',
    barangay_list: [
      'AGRUPACION',
      'ARAPISON',
      'AVELINO',
      'BAHAY',
      'BALUD',
      'BANA-AO',
      'BURABOD',
      'CAGSUMJI',
      'CAMPEIG',
      'CAMPERITO',
      'CAN-IPULAN',
      'CANMOROS',
      'CAUTOD (POB.)',
      'CINCO',
      'CURRY',
      'GAJO',
      'HINDANG',
      'ILO',
      'IMELDA',
      'INORAGUIAO',
      'JOLACAO',
      'LAMBAO',
      'MABUHAY',
      'MAHAYAG',
      'MATAYONAS',
      'MONBON (POB.)',
      'NABULO',
      'NAPURO',
      'NAPURO II',
      'PALALE',
      'PANABATAN',
      'PANARUAN',
      'ROXAS',
      'SALVACION',
      'SOLSOGON',
      'SUNDARA',
    ],
  },
  'TAGAPUL-AN': {
    province: 'SAMAR (WESTERN SAMAR)',
    municipality: 'TAGAPUL-AN',
    region: 'REGION VIII',
    barangay_list: [
      'BAGUIW',
      'BALOCAWE',
      'GUINBARUCAN',
      'LABANGBAYBAY',
      'LUNA',
      'MATALUTO',
      'NIPA',
      'PANTALAN',
      'PULANGBATO',
      'SAN JOSE (POB.)',
      'SAN VICENTE',
      'SUAREZ (MANLANGIT)',
      'SUGOD (POB.)',
      'TRINIDAD',
    ],
  },
  TALALORA: {
    province: 'SAMAR (WESTERN SAMAR)',
    municipality: 'TALALORA',
    region: 'REGION VIII',
    barangay_list: [
      'BO. INDEPENDENCIA',
      'MALAGUINING',
      'MALLORGA',
      'NAVATAS DAKU',
      'NAVATAS GUTI',
      'PLACER',
      'POBLACION BARANGAY 1',
      'POBLACION BARANGAY 2',
      'SAN JUAN',
      'TATABUNAN',
      'VICTORY',
    ],
  },
  TARANGNAN: {
    province: 'SAMAR (WESTERN SAMAR)',
    municipality: 'TARANGNAN',
    region: 'REGION VIII',
    barangay_list: [
      'ALCAZAR',
      'AWANG',
      'BAHAY',
      'BALONGA-AS',
      'BALUGO',
      'BANGON GOTE',
      'BARAS',
      'BINALAYAN',
      'BISITAHAN',
      'BONGA',
      'CABUNGA-AN',
      'CAGTUTULO',
      'CAMBATUTAY NUEVO',
      'CAMBATUTAY VIEJO',
      'CANUNGHAN',
      'CATAN-AGAN',
      'DAPDAP',
      'GALLEGO',
      'IMELDA POB. (POSGO)',
      'LAHONG',
      'LIBUCAN DACU',
      'LIBUCAN GOTE',
      'LUCERDONI (IRONG-IRONG)',
      'MAJACOB',
      'MANCARES',
      'MARABUT',
      'OESTE - A',
      'OESTE - B',
      'PAJO',
      'PALENCIA',
      'POBLACION A',
      'POBLACION B',
      'POBLACION C',
      'POBLACION D',
      'POBLACION E',
      'SAN VICENTE',
      'SANTA CRUZ',
      'SUGOD',
      'TALINGA',
      'TIGDARANAO',
      'TIZON',
    ],
  },
  VILLAREAL: {
    province: 'SAMAR (WESTERN SAMAR)',
    municipality: 'VILLAREAL',
    region: 'REGION VIII',
    barangay_list: [
      'BANQUIL',
      'BINO-ONGAN',
      'BURABOD',
      'CAMBAGUIO',
      'CANMUCAT',
      'CENTRAL (POB.)',
      'CONANT',
      'GUINTARCAN',
      'HIMYANGAN',
      'IGOT',
      'INARUMBACAN',
      'INASUDLAN',
      'LAM-AWAN',
      'LAMINGAO',
      'LAWA-AN',
      'MACOPA',
      'MAHAYAG',
      'MALONOY',
      'MERCADO (POB.)',
      'MIRAMAR (POB.)',
      'NAGCADUHA',
      'PACAO',
      'PACOYOY',
      'PANGPANG',
      'PATAG',
      'PLARIDEL',
      'POLANGI',
      'SAN ANDRES',
      'SAN FERNANDO',
      'SAN RAFAEL (BUAYA)',
      'SAN ROQUE',
      'SANTA ROSA',
      'SANTO NIÑO',
      'SOLEDAD (POB.)',
      'TAYUD (POB.)',
      'TOMABE',
      'ULAYAN',
      'VILLAROSA POB. (CAMPIATOT)',
    ],
  },
  ZUMARRAGA: {
    province: 'SAMAR (WESTERN SAMAR)',
    municipality: 'ZUMARRAGA',
    region: 'REGION VIII',
    barangay_list: [
      'ALEGRIA',
      'ARTECHE',
      'BIOSO',
      'BOBLARAN',
      'BOTAERA',
      'BUNTAY',
      'CAMAYSE',
      'CANWARAK',
      'IBARRA',
      'LUMALANTANG',
      'MACALUNOD',
      'MAGA-AN',
      'MAPUTI',
      'MARAPILIT',
      'MONBON',
      'MUALBUAL',
      'PANGDAN',
      'POBLACION 1 (BARANGAY 1)',
      'POBLACION 2 (BARANGAY 2)',
      'PORO',
      'SAN ISIDRO',
      'SUGOD',
      'TALIB',
      'TINAUGAN',
      'TUBIGAN',
    ],
  },
  ANAHAWAN: {
    province: 'SOUTHERN LEYTE',
    municipality: 'ANAHAWAN',
    region: 'REGION VIII',
    barangay_list: [
      'AMAGUSAN',
      'CALINTAAN',
      'CANLABIAN',
      'CAPACUHAN',
      'COGON',
      'KAGINGKINGAN',
      'LEWING',
      'LO-OK',
      'MAHALO',
      'MAINIT',
      'MANIGAWONG',
      'POBLACION',
      'SAN VICENTE',
      'TAGUP-ON',
    ],
  },
  BONTOC: {
    province: 'MOUNTAIN PROVINCE',
    municipality: 'BONTOC',
    region: 'CAR',
    barangay_list: [
      'ALAB ORIENTE',
      'ALAB PROPER',
      'BALILI',
      'BAYYO',
      'BONTOC ILI',
      'CALUTIT',
      'CANEO',
      'DALICAN',
      'GONOGON',
      'GUINAANG',
      'MAINIT',
      'MALIGCONG',
      'POBLACION (BONTOC)',
      'SAMOKI',
      'TALUBIN',
      'TOCUCAN',
    ],
  },
  HINUNANGAN: {
    province: 'SOUTHERN LEYTE',
    municipality: 'HINUNANGAN',
    region: 'REGION VIII',
    barangay_list: [
      'AMBACON',
      'BADIANGON',
      'BANGCAS A',
      'BANGCAS B',
      'BIASONG',
      'BUGHO',
      'CALAG-ITAN',
      'CALAYUGAN',
      'CALINAO',
      'CANIPAAN',
      'CATUBLIAN',
      'ILAYA',
      'INGAN',
      'LABRADOR',
      'LIBAS',
      'LUMBOG',
      'MANALOG',
      'MANLICO',
      'MATIN-AO',
      'NAVA',
      'NUEVA ESPERANZA',
      'OTAMA',
      'PALONGPONG',
      'PANALARON',
      'PATONG',
      'POBLACION',
      'PONDOL',
      'SALOG',
      'SALVACION',
      'SAN PABLO ISLAND',
      'SAN PEDRO ISLAND',
      'SANTO NIÑO I',
      'SANTO NIÑO II',
      'TAHUSAN',
      'TALISAY',
      'TAWOG',
      'TOPTOP',
      'TUBURAN',
      'UNION',
      'UPPER BANTAWON',
    ],
  },
  HINUNDAYAN: {
    province: 'SOUTHERN LEYTE',
    municipality: 'HINUNDAYAN',
    region: 'REGION VIII',
    barangay_list: [
      'AMAGA',
      'AMBAO',
      'AN-AN',
      'BACULOD',
      'BIASONG',
      'BUGHO',
      'CABULISAN',
      'CAT-IWING',
      'DISTRICT I (POB.)',
      'DISTRICT II (POB.)',
      'DISTRICT III (POB.)',
      'HUBASAN',
      'LUNGSODAAN',
      'NAVALITA',
      'PLARIDEL',
      'SABANG',
      'SAGBOK',
    ],
  },
  LIBAGON: {
    province: 'SOUTHERN LEYTE',
    municipality: 'LIBAGON',
    region: 'REGION VIII',
    barangay_list: [
      'BIASONG',
      'BOGASONG',
      'CAWAYAN',
      'GAKAT',
      'JUBAS (POB.)',
      'MAGKASAG',
      'MAYUGA',
      'NAHAONG',
      'NAHULID',
      'OTIKON',
      'PANGI',
      'PUNTA',
      'TALISAY (POB.)',
      'TIGBAO',
    ],
  },
  LIMASAWA: {
    province: 'SOUTHERN LEYTE',
    municipality: 'LIMASAWA',
    region: 'REGION VIII',
    barangay_list: [
      'CABULIHAN',
      'LUGSONGAN',
      'MAGALLANES',
      'SAN AGUSTIN',
      'SAN BERNARDO',
      'TRIANA',
    ],
  },
  'MAASIN': {
    province: 'SOUTHERN LEYTE',
    municipality: 'MAASIN',
    region: 'REGION VIII',
    barangay_list: [
      'ABGAO (POB.)',
      'ACASIA',
      'ASUNCION',
      'BACTUL I',
      'BACTUL II',
      'BADIANG',
      'BAGTICAN',
      'BASAK',
      'BATO I',
      'BATO II',
      'BATUAN',
      'BAUGO',
      'BILIBOL',
      'BOGO',
      'CABADIANGAN',
      'CABULIHAN',
      'CAGNITUAN',
      'CAMBOOC',
      'CANSIRONG',
      'CANTURING',
      'CANYUOM',
      'COMBADO',
      'DONGON',
      'GAWISAN',
      'GUADALUPE',
      'HANGINAN',
      'HANTAG',
      'HINAPU DAKU',
      'HINAPU GAMAY',
      'IBARRA',
      'ISAGANI',
      'LABOON',
      'LANAO',
      'LIB-OG',
      'LIBERTAD',
      'LIBHU',
      'LONOY',
      'LUNAS',
      'MAHAYAHAY',
      'MALAPOC NORTE',
      'MALAPOC SUR',
      'MAMBAJAO (POB.)',
      'MANHILO',
      'MANTAHAN (POB.)',
      'MARIA CLARA',
      'MATIN-AO',
      'NASAUG',
      'NATI',
      'NONOK NORTE',
      'NONOK SUR',
      'PANAN-AWAN',
      'PANSAAN',
      'PASAY',
      'PINASCOHAN',
      'RIZAL',
      'SAN AGUSTIN',
      'SAN ISIDRO',
      'SAN JOSE',
      'SAN RAFAEL',
      'SANTA CRUZ',
      'SANTA ROSA',
      'SANTO NIÑO',
      'SANTO ROSARIO',
      'SORO-SORO',
      'TAGNIPA (POB.)',
      'TAM-IS',
      'TAWID',
      'TIGBAWAN',
      'TOMOY-TOMOY',
      'TUNGA-TUNGA (POB.)',
    ],
  },
  MACROHON: {
    province: 'SOUTHERN LEYTE',
    municipality: 'MACROHON',
    region: 'REGION VIII',
    barangay_list: [
      'AGUINALDO',
      'AMPARO',
      'ASUNCION',
      'BAGONG SILANG',
      'BUSCAYAN',
      'CAMBARO',
      'CANLUSAY',
      'DANAO',
      'FLORDELIZ',
      'GUADALUPE',
      'ICHON',
      'ILIHAN',
      'LARAY',
      'LOWER VILLA JACINTA',
      'MABINI',
      'MOHON',
      'MOLOPOLO',
      'RIZAL',
      'SALVADOR (MANGYANG)',
      'SAN ISIDRO',
      'SAN JOAQUIN',
      'SAN ROQUE',
      'SAN VICENTE (UPPER SAN ROQUE)',
      'SAN VICENTE POBLACION',
      'SANTA CRUZ (POB.)',
      'SANTO NIÑO',
      'SANTO ROSARIO (POB.)',
      'SINDANGAN',
      'UPPER ICHON',
      'UPPER VILLA JACINTA',
    ],
  },
  PINTUYAN: {
    province: 'SOUTHERN LEYTE',
    municipality: 'PINTUYAN',
    region: 'REGION VIII',
    barangay_list: [
      'BADIANG',
      'BALONGBALONG',
      'BUENAVISTA',
      'BULAWAN',
      'CANLAWIS',
      'CATBAWAN',
      'CAUBANG',
      'COGON',
      'DAN-AN',
      'LOBO',
      'MAINIT',
      'MANGLIT',
      'NUEVA ESTRELLA NORTE',
      'NUEVA ESTRELLA SUR',
      'POBLACION IBABAO',
      'POBLACION UBOS',
      'POCIANO D. EQUIPILAG',
      'PONOD',
      'SAN ROQUE',
      'SANTA CRUZ',
      'SON-OK I',
      'SON-OK II',
      'TAUTAG',
    ],
  },
  'SAINT BERNARD': {
    province: 'SOUTHERN LEYTE',
    municipality: 'SAINT BERNARD',
    region: 'REGION VIII',
    barangay_list: [
      'ATUYAN',
      'AYAHAG',
      'BANTAWON',
      'BOLODBOLOD',
      'CABAGAWAN',
      'CARNAGA',
      'CATMON',
      'GUINSAUGON',
      'HIMATAGON (POB.)',
      'HIMBANGAN',
      'HIMOS-ONAN',
      'HINABIAN',
      'HINDAG-AN',
      'KAUSWAGAN',
      'LIBAS',
      'LIPANTO',
      'MAGATAS',
      'MAGBAGACAY',
      'MAHAYAG',
      'MAHAYAHAY',
      'MALIBAGO',
      'MALINAO',
      'NUEVA ESPERANZA (CABAC-AN)',
      'PANIAN',
      'SAN ISIDRO',
      'SANTA CRUZ',
      'SUG-ANGON',
      'TABONTABON',
      'TAMBIS I',
      'TAMBIS II',
    ],
  },
  'SAN JUAN (CABALIAN)': {
    province: 'SOUTHERN LEYTE',
    municipality: 'SAN JUAN (CABALIAN)',
    region: 'REGION VIII',
    barangay_list: [
      'AGAY-AY',
      'BASAK',
      'BOBON A',
      'BOBON B',
      'DAYANOG',
      'GARRIDO',
      'MINOYHO',
      'OSAO',
      'PONG-OY',
      'SAN JOSE (POB.)',
      'SAN ROQUE',
      'SAN VICENTE',
      'SANTA CRUZ (POB.)',
      'SANTA FILOMENA',
      'SANTO NIÑO (POB.)',
      'SOMOJE',
      'SUA',
      'TIMBA',
    ],
  },
  'SAN RICARDO': {
    province: 'SOUTHERN LEYTE',
    municipality: 'SAN RICARDO',
    region: 'REGION VIII',
    barangay_list: [
      'BENIT',
      'BITOON',
      'CABUTAN',
      'CAMANG',
      'ESPERANZA',
      'ESPERANZA DOS',
      'INOLINAN',
      'KINACHAWA',
      'LOOC',
      'PINUT-AN',
      'POBLACION',
      'SAN ANTONIO (ALANGALANG)',
      'SAN RAMON',
      'SAUB',
      'TIMBA',
    ],
  },
  SILAGO: {
    province: 'SOUTHERN LEYTE',
    municipality: 'SILAGO',
    region: 'REGION VIII',
    barangay_list: [
      'BALAGAWAN',
      'CATMON',
      'HINGATUNGAN',
      'IMELDA',
      'KATIPUNAN',
      'LAGUMA',
      'MERCEDES',
      'POB. DISTRICT I',
      'POB. DISTRICT II',
      'PUNTANA',
      'SALVACION',
      'SAP-ANG',
      'SUDMON',
      'TUBA-ON',
      'TUBOD',
    ],
  },
  'TOMAS OPPUS': {
    province: 'SOUTHERN LEYTE',
    municipality: 'TOMAS OPPUS',
    region: 'REGION VIII',
    barangay_list: [
      'ANAHAWAN',
      'BANDAY (POB.)',
      'BIASONG',
      'BOGO (POB.)',
      'CABASCAN',
      'CAMANSI',
      'CAMBITE (POB.)',
      'CANLUPAO',
      'CARNAGA',
      'CAWAYAN',
      'HIGOSOAN',
      'HINAGTIKAN',
      'HINAPO',
      'HUGPA',
      'INIGUIHAN POB. (SALVACION)',
      'LOOC',
      'LUAN',
      'MAANYAG',
      'MAG-ATA',
      'MAPGAP',
      'MASLOG',
      'PONONG',
      'RIZAL',
      'SAN AGUSTIN',
      'SAN ANTONIO',
      'SAN ISIDRO',
      'SAN MIGUEL',
      'SAN ROQUE',
      'TINAGO',
    ],
  },
  'BACUNGAN (LEON T. POSTIGO)': {
    province: 'ZAMBOANGA DEL NORTE',
    municipality: 'BACUNGAN (LEON T. POSTIGO)',
    region: 'REGION IX',
    barangay_list: [
      'BACUNGAN (POB.)',
      'BOGABONGAN',
      'DELUSOM',
      'MANGOP',
      'MANIL',
      'MAWAL',
      'MIDATAG',
      'MOROB',
      'NASIBAC',
      'RIZON',
      'SANTA MARIA',
      'SIPACONG',
      'TALINGA',
      'TINAPLAN',
      'TINIGUIBAN',
      'TINUYOP',
      'TIOGAN',
      'TITIK',
    ],
  },
  BALIGUIAN: {
    province: 'ZAMBOANGA DEL NORTE',
    municipality: 'BALIGUIAN',
    region: 'REGION IX',
    barangay_list: [
      'ALEGRIA',
      'DIANGAS',
      'DICULOM',
      'GUIMOTAN',
      'KAUSWAGAN',
      'KILALABAN',
      'LINAY',
      'LUMAY',
      'MALINAO',
      'MAMAD',
      'MAMAWAN',
      'MILIDAN',
      'NONOYAN',
      'POBLACION',
      'SAN JOSE',
      'TAMAO',
      'TAN-AWAN',
    ],
  },
  'DAPITAN': {
    province: 'ZAMBOANGA DEL NORTE',
    municipality: 'DAPITAN',
    region: 'REGION IX',
    barangay_list: [
      'ALIGUAY',
      'ANTIPOLO',
      'ASENIERO',
      'BA-AO',
      'BAGTING (POB.)',
      'BANBANAN',
      'BANONONG (POB.)',
      'BARCELONA',
      'BAYLIMANGO',
      'BURGOS',
      'CANLUCANI',
      'CARANG',
      'CAWA-CAWA (POB.)',
      'DAMPALAN',
      'DARO',
      'DAWO (POB.)',
      'DIWA-AN',
      'GUIMPUTLAN',
      'HILLTOP',
      'ILAYA',
      'KAUSWAGAN (TALISAY)',
      'LARAYAN',
      'LINABO (POB.)',
      'LIYANG',
      'MARIA CRISTINA',
      'MARIA URAY',
      'MASIDLAKON',
      'MATAGOBTOB POB. (TALISAY)',
      'NAPO',
      'OPAO',
      'ORO',
      'OWAON',
      'OYAN',
      'POLO',
      'POTOL (POB.)',
      'POTUNGAN',
      'SAN FRANCISCO',
      'SAN NICOLAS',
      'SAN PEDRO',
      'SAN VICENTE',
      'SANTA CRUZ (POB.)',
      'SANTO NIÑO',
      'SICAYAB BOCANA',
      'SIGAYAN',
      'SILINOG',
      'SINONOC',
      'SULANGON',
      'TAG-OLO',
      'TAGUILON',
      'TAMION',
    ],
  },
  'DIPOLOG': {
    province: 'ZAMBOANGA DEL NORTE',
    municipality: 'DIPOLOG',
    region: 'REGION IX',
    barangay_list: [
      'BARRA (POB.)',
      'BIASONG (POB.)',
      'CENTRAL (POB.)',
      'COGON',
      'DICAYAS',
      'DIWAN',
      'ESTACA (POB.)',
      'GALAS',
      'GULAYON',
      'LUGDUNGAN',
      'MINAOG',
      'MIPUTAK (POB.)',
      'OLINGAN',
      'PUNTA',
      'SAN JOSE',
      'SANGKOL',
      'SANTA FILOMENA',
      'SANTA ISABEL',
      'SICAYAB',
      'SINAMAN',
      'TURNO',
    ],
  },
  GODOD: {
    province: 'ZAMBOANGA DEL NORTE',
    municipality: 'GODOD',
    region: 'REGION IX',
    barangay_list: [
      'BALUNO',
      'BANUANGAN',
      'BUNAWAN',
      'DILUCOT',
      'DIPOPOR',
      'GUISAPONG',
      'LIMBONGA (LIMBOANGAN)',
      'LOMOGOM',
      'MAUSWAGON',
      'MIAMPIC',
      'POBLACION',
      'RABA',
      'RAMBON',
      'SAN PEDRO',
      'SARAWAGAN',
      'SIANAN',
      'SIORAN',
    ],
  },
  GUTALAC: {
    province: 'ZAMBOANGA DEL NORTE',
    municipality: 'GUTALAC',
    region: 'REGION IX',
    barangay_list: [
      'BACONG',
      'BAGONG SILANG',
      'BANGANON',
      'BAYANIHAN',
      'BUENAVISTA',
      'CANUPONG',
      'COCOB',
      'DATAGAN',
      'IMELDA',
      'IMMACULADA CONCEPCION',
      'LA LIBERTAD',
      'LOAY',
      'LOWER LUX',
      'LUX',
      'MALIAN',
      'MAMAWAN',
      'MAP',
      'MATUNOY',
      'NEW DAPITAN',
      'PANGANURAN',
      'PITAWE',
      'PITOGO',
      'POBLACION (GUTALAC)',
      'SALVADOR',
      'SAN ISIDRO',
      'SAN JUAN',
      'SAN ROQUE',
      'SAN VICENTE',
      'SANTO NIÑO',
      'SAS',
      'SIBALIC',
      'TIPAN',
      'UPPER GUTALAC',
    ],
  },
  'JOSE DALMAN (PONOT)': {
    province: 'ZAMBOANGA DEL NORTE',
    municipality: 'JOSE DALMAN (PONOT)',
    region: 'REGION IX',
    barangay_list: [
      'BALATAKAN',
      'BITOON',
      'DINASAN',
      'ILIHAN',
      'LABAKID',
      'LIPAY',
      'LITALIP',
      'LOPERO',
      'LUMANPING',
      'MADALAG',
      'MANAWAN',
      'MARUPAY',
      'POBLACION (PONOT)',
      'SIGAMOK',
      'SIPAROK',
      'TABON',
      'TAMAROK',
      'TAMIL',
    ],
  },
  KALAWIT: {
    province: 'ZAMBOANGA DEL NORTE',
    municipality: 'KALAWIT',
    region: 'REGION IX',
    barangay_list: [
      'BATAYAN',
      'BOTONG',
      'CONCEPCION',
      'DANIEL MAING (DOMINOLOG)',
      'FATIMA (LACSUTAN)',
      'GATAS',
      'KALAWIT (POB.)',
      'MARCELO',
      'NEW CALAMBA',
      'PALALIAN',
      'PARAISO',
      'PIANON',
      'SAN JOSE',
      'TUGOP',
    ],
  },
  KATIPUNAN: {
    province: 'ZAMBOANGA DEL NORTE',
    municipality: 'KATIPUNAN',
    region: 'REGION IX',
    barangay_list: [
      'BALOK',
      'BARANGAY DOS (POB.)',
      'BARANGAY UNO (POB.)',
      'BASAGAN',
      'BINIRAY',
      'BULAWAN',
      'CARUPAY',
      'DAANGLUNGSOD',
      'DABIAK',
      'DR. JOSE RIZAL (LOWER MIAS)',
      'FIMAGAS',
      'LOYURAN',
      'MALASAY',
      'MALUGAS',
      'MATAM',
      'MIAS',
      'MIATAN',
      'NANGINAN',
      'NEW TAMBO',
      'PATIK',
      'SAN ANTONIO (LOOY)',
      'SAN VICENTE',
      'SANAO',
      'SANTO NIÑO',
      'SERES',
      'SEROAN',
      'SINGATONG',
      'SINUYAK',
      'SITOG',
      'TUBURAN',
    ],
  },
  LABASON: {
    province: 'ZAMBOANGA DEL NORTE',
    municipality: 'LABASON',
    region: 'REGION IX',
    barangay_list: [
      'ANTONINO (POB.)',
      'BALAS',
      'BOBONGAN',
      'DANSALAN',
      'GABU',
      'GIL SANCHEZ',
      'IMELDA',
      'IMMACULADA',
      'KIPIT',
      'LA UNION',
      'LAPATAN',
      'LAWAGAN',
      'LAWIGAN',
      'LOPOC (POB.)',
      'MALINTUBOAN',
      'NEW SALVACION',
      'OSUKAN',
      'PATAWAG',
      'SAN ISIDRO',
      'UBAY',
    ],
  },
  LILOY: {
    province: 'ZAMBOANGA DEL NORTE',
    municipality: 'LILOY',
    region: 'REGION IX',
    barangay_list: [
      'BANIGAN',
      'BAYBAY (POB.)',
      'CABANGCALAN',
      'CANAAN',
      'CANDELARIA',
      'CAUSWAGAN',
      'COMMUNAL',
      'COMPRA',
      'DELA PAZ',
      'EL PARAISO',
      'FATIMA',
      'GANASE',
      'GOAW',
      'GOIN',
      'KAYOK',
      'LA LIBERTAD (MAWAL)',
      'LAMAO',
      'MABUHAY',
      'MAIGANG',
      'MALILA',
      'MAUSWAGON',
      'NEW BETHLEHEM',
      'OVERVIEW',
      'PANABANG',
      'PATAWAG',
      'PUNTA',
      'SAN FRANCISCO',
      'SAN ISIDRO',
      'SAN MIGUEL',
      'SAN ROQUE',
      'SANTA CRUZ',
      'SANTO NIÑO',
      'SILUCAP',
      'TAPICAN',
      'TIMAN',
      'VILLA CALIXTO SUDIACAL',
      'VILLA M. TEJERO',
    ],
  },
  MANUKAN: {
    province: 'ZAMBOANGA DEL NORTE',
    municipality: 'MANUKAN',
    region: 'REGION IX',
    barangay_list: [
      'DIPANE',
      'DISAKAN',
      'DON JOSE AGUIRRE',
      'EAST POBLACION',
      'GUPOT',
      'LIBUTON',
      'LINAY',
      'LINGATONGAN',
      'LUPASANG',
      'MATE',
      'MESES',
      'PALARANAN',
      'PANGANDAO',
      'PATAGAN',
      'POBLACION',
      'PUNTA BLANCA',
      'SALUYONG',
      'SAN ANTONIO',
      'SERONGAN',
      'SUISAYAN',
      'UPPER DISAKAN',
      'VILLARAMOS',
    ],
  },
  MUTIA: {
    province: 'ZAMBOANGA DEL NORTE',
    municipality: 'MUTIA',
    region: 'REGION IX',
    barangay_list: [
      'ALVENDA',
      'BUENASUERTE',
      'DILAND',
      'DIOLEN',
      'HEAD TIPAN',
      'NEW CASUL',
      'NEW SIQUIJOR',
      'NEWLAND',
      'PASO RIO',
      'POBLACION',
      'SAN MIGUEL',
      'SANTO TOMAS',
      'TINGLAN',
      'TOTONGON',
      'TUBAC',
      'UNIDOS',
    ],
  },
  'PIÑAN (NEW PIÑAN)': {
    province: 'ZAMBOANGA DEL NORTE',
    municipality: 'PIÑAN (NEW PIÑAN)',
    region: 'REGION IX',
    barangay_list: [
      'ADANTE',
      'BACUYONG',
      'BAGONG SILANG',
      'CALICAN',
      'DEL PILAR',
      'DESIN',
      'DILAWA',
      'DIONUM',
      'LAPU-LAPU',
      'LOWER GUMAY',
      'LUZVILLA',
      'POBLACION NORTH',
      'POBLACION SOUTH',
      'SANTA FE',
      'SEGABE',
      'SIKITAN',
      'SILANO',
      'TERESITA',
      'TINAYTAYAN',
      'UBAY (DAAN TIPAN)',
      'UPPER GUMAY',
      'VILLARICO',
    ],
  },
  POLANCO: {
    province: 'ZAMBOANGA DEL NORTE',
    municipality: 'POLANCO',
    region: 'REGION IX',
    barangay_list: [
      'ANASTACIO',
      'BANDERA',
      'BETHLEHEM',
      'DANGI',
      'DANSULLAN',
      'DE VENTA PERLA',
      'GUINLES',
      'ISIS',
      'LABRADOR (PRINDA)',
      'LAPAYANBAJA',
      'LETAPAN',
      'LINABO',
      'LINGASAD',
      'MACLEODES',
      'MAGANGON',
      'MALIGAYA',
      'MILAD',
      'NEW LEBANGON',
      'NEW SICAYAB',
      'OBAY',
      'PIAN',
      'POBLACION NORTH',
      'POBLACION SOUTH',
      'SAN ANTONIO (PAETAN)',
      'SAN MIGUEL (LOBOC)',
      'SAN PEDRO',
      'SANTO NIÑO (LANTOY)',
      'SIANIB',
      'SILAWE',
      'VILLAHERMOSA',
    ],
  },
  'PRES. MANUEL A. ROXAS': {
    province: 'ZAMBOANGA DEL NORTE',
    municipality: 'PRES. MANUEL A. ROXAS',
    region: 'REGION IX',
    barangay_list: [
      'BALUBO',
      'BANBANAN',
      'CANIBONGAN',
      'CAPASE',
      'CAPE',
      'DENOMAN',
      'DOHINOB',
      'GALOKSO',
      'GUBAT',
      'IRASAN',
      'LABAKID',
      'LANGATIAN',
      'LIPAKAN',
      'MARUPAY',
      'MOLITON',
      'NABILID',
      'PANAMPALAY',
      'PANGOLOGON',
      'PIAO',
      'PIÑALAN',
      'PIÑAMAR',
      'PONGOLAN',
      'SALISIG',
      'SEBOD',
      'SIBATOG',
      'SITUBO',
      'TANAYAN',
      'TANTINGON',
      'UPPER IRASAN',
      'UPPER MINANG',
      'VILLAHERMOSO',
    ],
  },
  SALUG: {
    province: 'ZAMBOANGA DEL NORTE',
    municipality: 'SALUG',
    region: 'REGION IX',
    barangay_list: [
      'BACONG',
      'BALAKAN',
      'BINONI',
      'CALUCAP',
      'CANAWAN',
      'CARACOL',
      'DANAO',
      'DINOAN',
      'DIPOLOD',
      'FATIMA (POGAN)',
      'IPILAN',
      'LANAWAN',
      'LIGUAC',
      'LIPAKAN',
      'MUCAS',
      'PACUHAN',
      'POBLACION (SALUG)',
      'POBLACION EAST',
      'PUKAY',
      'RAMON MAGSAYSAY',
      'SANTO NIÑO',
      'TAMBALANG',
      'TAPALAN',
    ],
  },
  'SERGIO OSMEÑA SR.': {
    province: 'ZAMBOANGA DEL NORTE',
    municipality: 'SERGIO OSMEÑA SR.',
    region: 'REGION IX',
    barangay_list: [
      'ANTONINO',
      'BAGONG BAGUIO',
      'BAGUMBAYAN',
      'BIAYON',
      'BUENAVISTA',
      'DAMPALAN',
      'DANAO',
      'DON ELENO',
      'KAUSWAGAN',
      'LABIRAY',
      'LIWANAG',
      'MABUHAY',
      'MACALIBRE',
      'MAHAYAHAY',
      'MARAPONG',
      'NAZARETH',
      'NEBO',
      'NEW RIZAL',
      'NEW TANGUB',
      'NUEVAVISTA',
      'PEDAGAN',
      'PENACIO',
      'POBLACION ALTO',
      'POBLACION BAJO',
      'PRINCESA FRESHIA',
      'PRINCESA LAMAYA',
      'SAN ANTONIO',
      'SAN FRANCISCO',
      'SAN ISIDRO',
      'SAN JOSE',
      'SAN JUAN',
      'SINAAD',
      'SINAI',
      'SITUBO',
      'TINAGO',
      'TININDUGAN',
      'TUBURAN',
      'VENUS',
      'WILBEN',
    ],
  },
  SIAYAN: {
    province: 'ZAMBOANGA DEL NORTE',
    municipality: 'SIAYAN',
    region: 'REGION IX',
    barangay_list: [
      'BALOK',
      'BALUNOKAN',
      'DATAGAN',
      'DENOYAN',
      'DIONGAN',
      'DOMOGOK',
      'DUMPILAS',
      'GONAYEN',
      'GUIBO',
      'GUNYAN',
      'LITOLET',
      'MACASING',
      'MANGILAY',
      'MOYO',
      'MUÑOZ',
      'PANGE',
      'PARANGLUMBA (POB.)',
      'POLAYO',
      'SAYAW',
      'SERIAC',
      'SIAYAN PROPER (POB.)',
      'SUGUILON',
    ],
  },
  SIBUCO: {
    province: 'ZAMBOANGA DEL NORTE',
    municipality: 'SIBUCO',
    region: 'REGION IX',
    barangay_list: [
      'ANONGAN',
      'BASAK',
      'BONGALAO',
      'CABBUNAN',
      'CAWIT-CAWIT',
      'CULAGUAN',
      'CUSIPAN',
      'DINULAN',
      'JATIAN',
      'KAMARANGAN',
      'LAKIKI',
      'LAMBAGOAN',
      'LIMPAPA',
      'LINGAYON',
      'LINTANGAN',
      'LITAWAN',
      'LUNDAY',
      'MALAYAL',
      'MANTIVO',
      'NALA (POB.)',
      'PANGANURAN',
      'PANGIAN',
      'PANIRAN',
      'PASILNAHUT',
      'POBLACION',
      'PULIRAN',
      'SANTO NIÑO (CULABOG)',
      'TANGARAK',
    ],
  },
  SIBUTAD: {
    province: 'ZAMBOANGA DEL NORTE',
    municipality: 'SIBUTAD',
    region: 'REGION IX',
    barangay_list: [
      'BAGACAY',
      'CALILIC',
      'CALUBE',
      'DELAPA',
      'KANIM',
      'LIBAY',
      'MAGSAYSAY',
      'MARAPONG',
      'MINLASAG',
      'OYAN',
      'PANGANURAN',
      'POBLACION (SIBUTAD)',
      'SAWANG',
      'SIBULOC',
      'SINIPAY',
      'SIPALOC',
    ],
  },
  SINDANGAN: {
    province: 'ZAMBOANGA DEL NORTE',
    municipality: 'SINDANGAN',
    region: 'REGION IX',
    barangay_list: [
      'BAGO',
      'BALOK',
      'BANTAYAN',
      'BATO',
      'BENIGNO AQUINO JR.',
      'BINUANGAN',
      'BITOON',
      'BUCANA',
      'CALATUNAN',
      'CALUAN',
      'CALUBIAN',
      'DAGOHOY',
      'DAPAON',
      'DATAGAN',
      'DATU TANGKILAN',
      'DICOYONG',
      'DISUD',
      'DON RICARDO MACIAS (DINOBOT)',
      'DOÑA JOSEFA',
      'DUMALOGDOG',
      'FATIMA',
      'GAMPIS',
      'GOLEO',
      'IMELDA',
      'INUMAN',
      'JOAQUIN MACIAS',
      'LA CONCEPCION',
      'LA ROCHE SAN MIGUEL',
      'LABAKID',
      'LAGAG',
      'LAPERO',
      'LAWIS',
      'MAGSAYSAY',
      'MANDIH',
      'MARAS',
      'MAWAL',
      'MISOK',
      'MOTIBOT',
      'NATO',
      'NIPAAN',
      'PANGALALAN',
      'PIAO',
      'POBLACION',
      'SANTO NIÑO',
      'SANTO ROSARIO',
      'SIARE',
      'TALINGA',
      'TIGBAO',
      'TINAPLAN',
      'TITIK',
      'UPPER INUMAN',
      'UPPER NIPAAN',
    ],
  },
  SIOCON: {
    province: 'ZAMBOANGA DEL NORTE',
    municipality: 'SIOCON',
    region: 'REGION IX',
    barangay_list: [
      'ANDRES MICUBO JR. (BALILI)',
      'BALAGUNAN',
      'BUCANA',
      'BULACAN',
      'CANDIZ',
      'DATU SAILILA',
      'DIONISIO RICONALLA',
      'JOSE P. BRILLANTES, SR. (OLD LITUBAN)',
      'LATABON',
      'MAKIANG',
      'MALAMBUHANGIN',
      'MALIPOT',
      'MANAOL',
      'MATEO FRANCISCO',
      'MATIAG',
      'NEW LITUBAN',
      'PANGIAN',
      'PISAWAK',
      'POBLACION',
      'S. CABRAL',
      'SANTA MARIA',
      'SIAY',
      'SUHAILE ARABI',
      'TABAYO',
      'TAGAYTAY',
      'TIBANGAO',
    ],
  },
  SIRAWAI: {
    province: 'ZAMBOANGA DEL NORTE',
    municipality: 'SIRAWAI',
    region: 'REGION IX',
    barangay_list: [
      'BALATAKAN',
      'BALONKAN',
      'BALUBUAN',
      'BITUGAN',
      'BONGON',
      'CATUYAN',
      'CULASIAN',
      'DANGANON',
      'DOÑA CECILIA',
      'GUBAN',
      'LAGUNDI',
      'LIBUCON',
      'LUBOK',
      'MACUYON',
      'MINANGA',
      'MOTONG',
      'NAPULAN',
      'PANABUTAN',
      'PIACAN',
      'PIÑA',
      'PISA ITOM',
      'PISA PUTI',
      'PUGOS',
      'PULA BATO',
      'PULANG LUPA',
      'SAINT MARY (POB.)',
      'SAN NICOLAS (POB.)',
      'SAN ROQUE (POB.)',
      'SAN VICENTE (POB.)',
      'SIPAKIT',
      'SIPAWA',
      'SIRAWAI PROPER (POB.)',
      'TALABIGA',
      'TAPANAYAN',
    ],
  },
  TAMPILISAN: {
    province: 'ZAMBOANGA DEL NORTE',
    municipality: 'TAMPILISAN',
    region: 'REGION IX',
    barangay_list: [
      'BALACBAAN',
      'BANBANAN',
      'BARILI',
      'CABONG',
      'CAMUL',
      'FARMINGTON',
      'GALINGON',
      'LAWAAN',
      'LUMBAYAO',
      'MALILA-T',
      'MOLOS',
      'NEW DAPITAN',
      'POBLACION (TAMPILISAN)',
      'SANDAYONG',
      'SANTO NIÑO',
      'SITUBO',
      'TILUBOG',
      'TININGGAAN',
      'TUBOD',
      'ZNAC',
    ],
  },
  BAYOG: {
    province: 'ZAMBOANGA DEL SUR',
    municipality: 'BAYOG',
    region: 'REGION IX',
    barangay_list: [
      'BAKING',
      'BALUKBAHAN',
      'BALUMBUNAN',
      'BANTAL',
      'BOBUAN',
      'CAMP BLESSING',
      'CANOAYAN',
      'CONACON',
      'DAGUM',
      'DAMIT',
      'DATAGAN',
      'DEPASE',
      'DEPILI',
      'DEPORE',
      'DEPOREHAN',
      'DIMALINAO',
      'KAHAYAGAN',
      'KANIPAAN',
      'LAMARE',
      'LIBA',
      'MATIN-AO',
      'MATUN-OG',
      'PANGI (SAN ISIDRO)',
      'POBLACION',
      'PULANG BATO',
      'SALAWAGAN',
      'SIGACAD',
      'SUPON',
    ],
  },
  DIMATALING: {
    province: 'ZAMBOANGA DEL SUR',
    municipality: 'DIMATALING',
    region: 'REGION IX',
    barangay_list: [
      'BACAYAWAN',
      'BAHA',
      'BALANAGAN',
      'BALUNO',
      'BINUAY',
      'BUBURAY',
      'GRAP',
      'JOSEFINA',
      'KAGAWASAN',
      'LALAB',
      'LIBERTAD',
      'MAGAHIS',
      'MAHAYAG',
      'MERCEDES',
      'POBLACION',
      'SALOAGAN',
      'SAN ROQUE',
      'SUGBAY UNO',
      'SUMBATO',
      'SUMPOT',
      'TINGGABULONG',
      'TINIGUANGAN',
      'TIPANGI',
      'UPPER LUDIONG',
    ],
  },
  DINAS: {
    province: 'ZAMBOANGA DEL SUR',
    municipality: 'DINAS',
    region: 'REGION IX',
    barangay_list: [
      'BACAWAN',
      'BENUATAN',
      'BERAY',
      'DON JOSE',
      'DONGOS',
      'EAST MIGPULAO',
      'GUINICOLALAY',
      'IGNACIO GARRATA (NEW MIRAPAO)',
      'KINACAP',
      'LEGARDA 1',
      'LEGARDA 2',
      'LEGARDA 3',
      'LOWER DIMAYA',
      'LUCOBAN',
      'LUDIONG',
      'NANGKA',
      'NIAN',
      'OLD MIRAPAO',
      'PISA-AN',
      'POBLACION',
      'PROPER DIMAYA',
      'SAGACAD',
      'SAMBULAWAN',
      'SAN ISIDRO',
      'SONGAYAN',
      'SUMPOTAN',
      'TARAKAN',
      'UPPER DIMAYA',
      'UPPER SIBUL',
      'WEST MIGPULAO',
    ],
  },
  DUMALINAO: {
    province: 'ZAMBOANGA DEL SUR',
    municipality: 'DUMALINAO',
    region: 'REGION IX',
    barangay_list: [
      'ANONANG',
      'BAG-ONG MISAMIS',
      'BAG-ONG SILAO',
      'BAGA',
      'BALOBOAN',
      'BANTA-AO',
      'BIBILIK',
      'CALINGAYAN',
      'CAMALIG',
      'CAMANGA',
      'CUATRO-CUATRO',
      'LOCUBAN',
      'MALASIK',
      'MAMA (SAN JUAN)',
      'MATAB-ANG',
      'MECOLONG',
      'METOKONG',
      'MOTOSAWA',
      'PAG-ASA (POB.)',
      'PAGLAUM (POB.)',
      'PANTAD',
      'PINIGLIBANO',
      'REBOKON',
      'SAN AGUSTIN',
      'SIBUCAO',
      'SUMADAT',
      'TIKWAS',
      'TINA',
      'TUBO-PAIT',
      'UPPER DUMALINAO',
    ],
  },
  DUMINGAG: {
    province: 'ZAMBOANGA DEL SUR',
    municipality: 'DUMINGAG',
    region: 'REGION IX',
    barangay_list: [
      'BAG-ONG VALENCIA',
      'BAGONG KAUSWAGAN',
      'BAGONG SILANG',
      'BUCAYAN',
      'CALUMANGGI',
      'CANIBONGAN',
      'CARIDAD',
      'DANLUGAN',
      'DAPIWAK',
      'DATU TOTOCAN',
      'DILUD',
      'DITULAN',
      'DULIAN',
      'DULOP',
      'GUINTANANAN',
      'GUITRAN',
      'GUMPINGAN',
      'LA FORTUNA',
      'LABANGON',
      'LIBERTAD',
      'LICABANG',
      'LIPAWAN',
      'LOWER LANDING',
      'LOWER TIMONAN',
      'MACASING',
      'MAHAYAHAY',
      'MALAGALAD',
      'MANLABAY',
      'MARALAG',
      'MARANGAN',
      'NEW BASAK',
      'SAAD',
      'SALVADOR',
      'SAN JUAN',
      'SAN PABLO (POB.)',
      'SAN PEDRO (POB.)',
      'SAN VICENTE',
      'SENOTE',
      'SINONOK',
      'SUNOP',
      'TAGUN',
      'TAMURAYAN',
      'UPPER LANDING',
      'UPPER TIMONAN',
    ],
  },
  GUIPOS: {
    province: 'ZAMBOANGA DEL SUR',
    municipality: 'GUIPOS',
    region: 'REGION IX',
    barangay_list: [
      'BAGONG OROQUIETA',
      'BAGUITAN',
      'BALONGATING',
      'CANUNAN',
      'DACSOL',
      'DAGOHOY',
      'DALAPANG',
      'DATAGAN',
      'GULING',
      'KATIPUNAN',
      'LINTUM',
      'LITAN',
      'MAGTING',
      'POBLACION (GUIPOS)',
      'REGLA',
      'SIKATUNA',
      'SINGCLOT',
    ],
  },
  JOSEFINA: {
    province: 'ZAMBOANGA DEL SUR',
    municipality: 'JOSEFINA',
    region: 'REGION IX',
    barangay_list: [
      'BOGO CALABAT',
      'DAWA (DIWA)',
      'EBARLE',
      'GUMAHAN (POB.)',
      'LEONARDO',
      'LITAPAN',
      'LOWER BAGONG TUDELA',
      'MANSANAS',
      'MORADJI',
      'NEMEÑO',
      'NOPULAN',
      'SEBUKANG',
      'TAGAYTAY HILL',
      'UPPER BAGONG TUDELA (POB.)',
    ],
  },
  KUMALARANG: {
    province: 'ZAMBOANGA DEL SUR',
    municipality: 'KUMALARANG',
    region: 'REGION IX',
    barangay_list: [
      'BOGAYO',
      'BOLISONG',
      'BOYUGAN EAST',
      'BOYUGAN WEST',
      'BUALAN',
      'DIPLO',
      'GAWIL',
      'GUSOM',
      'KITAAN DAGAT',
      'LANTAWAN',
      'LIMAMAWAN',
      'MAHAYAHAY',
      'PANGI',
      'PICANAN',
      'POBLACION',
      'SALAGMANOK',
      'SECADE',
      'SUMINALUM',
    ],
  },
  LABANGAN: {
    province: 'ZAMBOANGA DEL SUR',
    municipality: 'LABANGAN',
    region: 'REGION IX',
    barangay_list: [
      'BAGALUPA',
      'BALIMBINGAN (WEST LUYA)',
      'BINAYAN',
      'BOKONG',
      'BULANIT',
      'COGONAN',
      'COMBO',
      'DALAPANG',
      'DIMASANGCA',
      'DIPAYA',
      'LANGAPOD',
      'LANTIAN',
      'LOWER CAMPO ISLAM (POB.)',
      'LOWER PULACAN',
      'LOWER SANG-AN',
      'NEW LABANGAN',
      'NOBORAN',
      'OLD LABANGAN',
      'SAN ISIDRO',
      'SANTA CRUZ',
      'TAPODOC',
      'TAWAGAN NORTE',
      'UPPER CAMPO ISLAM (POB.)',
      'UPPER PULACAN',
      'UPPER SANG-AN',
    ],
  },
  LAKEWOOD: {
    province: 'ZAMBOANGA DEL SUR',
    municipality: 'LAKEWOOD',
    region: 'REGION IX',
    barangay_list: [
      'BAGONG KAHAYAG',
      'BAKING',
      'BISWANGAN',
      'BULULAWAN',
      'DAGUM',
      'GASA',
      'GATUB',
      'LUKUAN',
      'MATALANG',
      'POBLACION (LAKEWOOD)',
      'SAPANG PINOLES',
      'SEBUGUEY',
      'TIWALES',
      'TUBOD',
    ],
  },
  LAPUYAN: {
    province: 'ZAMBOANGA DEL SUR',
    municipality: 'LAPUYAN',
    region: 'REGION IX',
    barangay_list: [
      'BULAWAN',
      'CARPOC',
      'DANGANAN',
      'DANSAL',
      'DUMARA',
      'LINOKMADALUM',
      'LUANAN',
      'LUBUSAN',
      'MAHALINGEB',
      'MANDEG',
      'MARALAG',
      'MARUING',
      'MOLUM',
      'PAMPANG',
      'PANTAD',
      'PINGALAY',
      'POBLACION',
      'SALAMBUYAN',
      'SAN JOSE',
      'SAYOG',
      'TABON',
      'TALABAB',
      'TIGUHA',
      'TININGHALANG',
      'TIPASAN',
      'TUGAYA',
    ],
  },
  MAHAYAG: {
    province: 'ZAMBOANGA DEL SUR',
    municipality: 'MAHAYAG',
    region: 'REGION IX',
    barangay_list: [
      'BAG-ONG BALAMBAN',
      'BAG-ONG DALAGUETE',
      'BONIAO',
      'DELUSOM',
      'DIWAN',
      'GURIPAN',
      'KAANGAYAN',
      'KABUHI',
      'LOURMAH (LOWER MAHAYAG)',
      'LOWER SALUG DAKU',
      'LOWER SANTO NIÑO',
      'MALUBO',
      'MANGUILES',
      'MARABANAN (BALANAN)',
      'PANAGAAN',
      'PARAISO',
      'PEDAGAN',
      'POBLACION (UPPER MAHAYAG)',
      'PUGWAN',
      'SAN ISIDRO',
      'SAN JOSE',
      'SAN VICENTE',
      'SANTA CRUZ',
      'SICPAO',
      'TUBORAN',
      'TULAN',
      'TUMAPIC',
      'UPPER SALUG DAKU',
      'UPPER SANTO NIÑO',
    ],
  },
  MARGOSATUBIG: {
    province: 'ZAMBOANGA DEL SUR',
    municipality: 'MARGOSATUBIG',
    region: 'REGION IX',
    barangay_list: [
      'BALINTAWAK',
      'BULARONG',
      'DIGON',
      'GUINIMANAN',
      'IGAT ISLAND',
      'JOSEFINA',
      'KALIAN',
      'KOLOT',
      'LIMABATONG',
      'LIMAMAWAN',
      'LUMBOG',
      'MAGAHIS',
      'POBLACION',
      'SAGUA',
      'TALANUSA',
      'TIGUIAN',
      'TULAPOC',
    ],
  },
  MIDSALIP: {
    province: 'ZAMBOANGA DEL SUR',
    municipality: 'MIDSALIP',
    region: 'REGION IX',
    barangay_list: [
      'BACAHAN',
      'BALONAI',
      'BIBILOP',
      'BULORON',
      'CABALORAN',
      'CANIPAY NORTE',
      'CANIPAY SUR',
      'CUMARON',
      'DAKAYAKAN',
      'DUELIC',
      'DUMALINAO',
      'ECUAN',
      'GOLICTOP',
      'GUINABOT',
      'GUITALOS',
      'GUMA',
      'KAHAYAGAN',
      'LICURO-AN',
      'LUMPUNID',
      'MATALANG',
      'NEW KATIPUNAN',
      'NEW UNIDOS',
      'PALILI',
      'PAWAN',
      'PILI',
      'PISOMPONGAN',
      'PIWAN',
      'POBLACION A',
      'POBLACION B',
      'SIGAPOD',
      'TIMBABOY',
      'TULBONG',
      'TULUAN',
    ],
  },
  MOLAVE: {
    province: 'ZAMBOANGA DEL SUR',
    municipality: 'MOLAVE',
    region: 'REGION IX',
    barangay_list: [
      'ALICIA',
      'ARIOSA',
      'BAGONG ARGAO',
      'BAGONG GUTLANG',
      'BLANCIA',
      'BOGO CAPALARAN',
      'CULO',
      'DALAON',
      'DIPOLO',
      'DONTULAN',
      'GONOSAN',
      'LOWER DIMALINAO',
      'LOWER DIMOROK',
      'MABUHAY',
      'MADASIGON (POB.)',
      'MAKUGUIHON (POB.)',
      'MALOLOY-ON (POB.)',
      'MILIGAN',
      'PARASAN',
      'RIZAL',
      'SANTO ROSARIO',
      'SILANGIT',
      'SIMATA',
      'SUDLON',
      'UPPER DIMOROK',
    ],
  },
  'PAGADIAN': {
    province: 'ZAMBOANGA DEL SUR',
    municipality: 'PAGADIAN',
    region: 'REGION IX',
    barangay_list: [
      'ALEGRIA',
      'BALANGASAN (POB.)',
      'BALINTAWAK',
      'BALOYBOAN',
      'BANALE',
      'BOGO',
      'BOMBA',
      'BUENAVISTA',
      'BULATOK',
      'BULAWAN',
      'DAMPALAN',
      'DANLUGAN',
      'DAO',
      'DATAGAN',
      'DEBOROK',
      'DITORAY',
      'DUMAGOC',
      'GATAS (POB.)',
      'GUBAC',
      'GUBANG',
      'KAGAWASAN',
      'KAHAYAGAN',
      'KALASAN',
      'KAWIT',
      'LA SUERTE',
      'LALA',
      'LAPIDIAN',
      'LENIENZA',
      'LIZON VALLEY',
      'LOURDES',
      'LOWER SIBATANG',
      'LUMAD',
      'LUMBIA',
      'MACASING',
      'MANGA',
      'MURICAY',
      'NAPOLAN',
      'PALPALAN',
      'PEDULONAN',
      'POLOYAGAN',
      'SAN FRANCISCO (POB.)',
      'SAN JOSE (POB.)',
      'SAN PEDRO (POB.)',
      'SANTA LUCIA (POB.)',
      'SANTA MARIA',
      'SANTIAGO (POB.)',
      'SANTO NIÑO',
      'TAWAGAN SUR',
      'TIGUMA',
      'TUBURAN (POB.)',
      'TULANGAN',
      'TULAWAS',
      'UPPER SIBATANG',
      'WHITE BEACH',
    ],
  },
  'RAMON MAGSAYSAY (LIARGO)': {
    province: 'ZAMBOANGA DEL SUR',
    municipality: 'RAMON MAGSAYSAY (LIARGO)',
    region: 'REGION IX',
    barangay_list: [
      'BAGONG OPON',
      'BAMBONG DAKU',
      'BAMBONG DIUT',
      'BOBONGAN',
      'CAMPO IV',
      'CAMPO V',
      'CANIANGAN',
      'DIPALUSAN',
      'EASTERN BOBONGAN',
      'ESPERANZA',
      'GAPASAN',
      'KATIPUNAN',
      'KAUSWAGAN',
      'LOWER SAMBULAWAN',
      'MABINI',
      'MAGSAYSAY',
      'MALATING',
      'PARADISE',
      'PASINGKALAN',
      'POBLACION',
      'SAN FERNANDO',
      'SANTO ROSARIO',
      'SAPA ANDING',
      'SINAGUING',
      'SWITCH',
      'UPPER LAPERIAN',
      'WAKAT',
    ],
  },
  'SOMINOT (DON MARIANO MARCOS)': {
    province: 'ZAMBOANGA DEL SUR',
    municipality: 'SOMINOT (DON MARIANO MARCOS)',
    region: 'REGION IX',
    barangay_list: [
      'BAG-ONG BAROY',
      'BAG-ONG OROQUIETA',
      'BARUBUHAN',
      'BULANAY',
      'DATAGAN',
      'EASTERN POBLACION',
      'LANTAWAN',
      'LIBERTAD',
      'LUMANGOY',
      'NEW CARMEN',
      'PICTURAN',
      'POBLACION',
      'RIZAL',
      'SAN MIGUEL',
      'SANTO NIÑO',
      'SAWA',
      'TUNGAWAN',
      'UPPER SICPAO',
    ],
  },
  TABINA: {
    province: 'ZAMBOANGA DEL SUR',
    municipality: 'TABINA',
    region: 'REGION IX',
    barangay_list: [
      'ABONG-ABONG',
      'BAGANIAN',
      'BAYA-BAYA',
      'CAPISAN',
      'CONCEPCION',
      'CULABAY',
      'DOÑA JOSEFINA',
      'LUMBIA',
      'MABUHAY',
      'MALIM',
      'MANIKAAN',
      'NEW OROQUIETA',
      'POBLACION',
      'SAN FRANCISCO',
      'TULTOLAN',
    ],
  },
  TAMBULIG: {
    province: 'ZAMBOANGA DEL SUR',
    municipality: 'TAMBULIG',
    region: 'REGION IX',
    barangay_list: [
      'ALANG-ALANG',
      'ANGELES',
      'BAG-ONG KAUSWAGAN',
      'BAG-ONG TABOGON',
      'BALUGO',
      'CABGAN',
      'CALOLOT',
      'DIMALINAO',
      'FABIAN (BALUCOT)',
      'GABUNON',
      'HAPPY VALLEY (POB.)',
      'KAPALARAN',
      'LIBATO',
      'LIMAMAWAN',
      'LOWER LIASAN',
      'LOWER LODIONG (POB.)',
      'LOWER TIPARAK',
      'LOWER USOGAN',
      'MAYA-MAYA',
      'NEW VILLAGE (POB.)',
      'PELOCOBAN',
      'RIVERSIDE (POB.)',
      'SAGRADA FAMILIA',
      'SAN JOSE',
      'SAN VICENTE',
      'SUMALIG',
      'TULUAN',
      'TUNGAWAN',
      'UPPER LIASON',
      'UPPER LODIONG',
      'UPPER TIPARAK',
    ],
  },
  TIGBAO: {
    province: 'ZAMBOANGA DEL SUR',
    municipality: 'TIGBAO',
    region: 'REGION IX',
    barangay_list: [
      'BEGONG',
      'BUSOL',
      'CALUMA',
      'DIANA COUNTRYSIDE',
      'GUINLIN',
      'LACARAYAN',
      'LACUPAYAN',
      'LIBAYOY',
      'LIMAS',
      'LONGMOT',
      'MARAGANG',
      'MATE',
      'NANGAN-NANGAN',
      'NEW TUBURAN',
      'NILO',
      'TIGBAO',
      'TIMOLAN',
      'UPPER NILO',
    ],
  },
  TUKURAN: {
    province: 'ZAMBOANGA DEL SUR',
    municipality: 'TUKURAN',
    region: 'REGION IX',
    barangay_list: [
      'ALINDAHAW',
      'BACLAY',
      'BALIMBINGAN',
      'BUENASUERTE',
      'CAMANGA',
      'CURVADA',
      'LAPERIAN',
      'LIBERTAD',
      'LOWER BAYAO',
      'LUY-A',
      'MANILAN',
      'MANLAYAG',
      'MILITAR',
      'NAVALAN',
      'PANDUMA SENIOR',
      'SAMBULAWAN',
      'SAN ANTONIO',
      'SAN CARLOS (POB.)',
      'SANTO NIÑO (POB.)',
      'SANTO ROSARIO',
      'SUGOD',
      'TABUAN',
      'TAGULO',
      'TINOTUNGAN',
      'UPPER BAYAO',
    ],
  },
  'VINCENZO A. SAGUN': {
    province: 'ZAMBOANGA DEL SUR',
    municipality: 'VINCENZO A. SAGUN',
    region: 'REGION IX',
    barangay_list: [
      'AMBULON',
      'BUI-OS',
      'COGON',
      'DANAN',
      'KABATAN',
      'KAPATAGAN',
      'LIMASON',
      'LINOGUAYAN',
      'LUMBAL',
      'LUNIB',
      'MACULAY',
      'MARAYA',
      'SAGUCAN',
      'WALING-WALING',
    ],
  },
  'ZAMBOANGA': {
    province: 'ZAMBOANGA DEL SUR',
    municipality: 'ZAMBOANGA',
    region: 'REGION IX',
    barangay_list: [
      'ARENA BLANCO',
      'AYALA',
      'BALIWASAN',
      'BALUNO',
      'BARANGAY ZONE I (POB.)',
      'BARANGAY ZONE II (POB.)',
      'BARANGAY ZONE III (POB.)',
      'BARANGAY ZONE IV (POB.)',
      'BOALAN',
      'BOLONG',
      'BUENAVISTA',
      'BUNGUIAO',
      'BUSAY (SACOL ISLAND)',
      'CABALUAY',
      'CABATANGAN',
      'CACAO',
      'CALABASA',
      'CALARIAN',
      'CAMINO NUEVO',
      'CAMPO ISLAM',
      'CANELAR',
      'CAPISAN',
      'CAWIT',
      'CULIANAN',
      'CURUAN',
      'DITA',
      'DIVISORIA',
      'DULIAN (UPPER BUNGUIAO)',
      'DULIAN (UPPER PASONANCA)',
      'GUISAO',
      'GUIWAN',
      'KASANYANGAN',
      'LA PAZ',
      'LABUAN',
      'LAMISAHAN',
      'LANDANG GUA',
      'LANDANG LAUM',
      'LANZONES',
      'LAPAKAN',
      'LATUAN (CURUAN)',
      'LICOMO',
      'LIMAONG',
      'LIMPAPA',
      'LUBIGAN',
      'LUMAYANG',
      'LUMBANGAN',
      'LUNZURAN',
      'MAASIN',
      'MALAGUTAY',
      'MAMPANG',
      'MANALIPA',
      'MANGUSU',
      'MANICAHAN',
      'MARIKI',
      'MERCEDES',
      'MUTI',
      'PAMUCUTAN',
      'PANGAPUYAN',
      'PANUBIGAN',
      'PASILMANTA (SACOL ISLAND)',
      'PASOBOLONG',
      'PASONANCA',
      'PATALON',
      'PUTIK',
      'QUINIPUT',
      'RECODO',
      'RIO HONDO',
      'SALAAN',
      'SAN JOSE CAWA-CAWA',
      'SAN JOSE GUSU',
      'SAN ROQUE',
      'SANGALI',
      'SANTA BARBARA',
      'SANTA CATALINA',
      'SANTA MARIA',
      'SANTO NIÑO',
      'SIBULAO (CARUAN)',
      'SINUBUNG',
      'SINUNOC',
      'TAGASILAY',
      'TAGUITI',
      'TALABAAN',
      'TALISAYAN',
      'TALON-TALON',
      'TALUKSANGAY',
      'TETUAN',
      'TICTAPUL',
      'TIGBALABAG',
      'TIGTABON',
      'TOLOSA',
      'TUGBUNGAN',
      'TULUNGATUNG',
      'TUMAGA',
      'TUMALUTAB',
      'TUMITUS',
      'VICTORIA',
      'VITALI',
      'ZAMBOWOOD',
    ],
  },
  BUUG: {
    province: 'ZAMBOANGA SIBUGAY',
    municipality: 'BUUG',
    region: 'REGION IX',
    barangay_list: [
      'AGUTAYAN',
      'BAGONG BORBON',
      'BASALEM',
      'BAWANG',
      'BLISS',
      'BULAAN',
      'COMPOSTELA',
      'DANLUGAN',
      'DATU PANAS',
      'DEL MONTE',
      'GUINTULOAN',
      'GUITOM',
      'GUMINTA',
      'LABRADOR',
      'LANTAWAN',
      'MABUHAY',
      'MAGANAY',
      'MANLIN',
      'MUYO',
      'PAMINTAYAN',
      'PLING',
      'POBLACION',
      'PULOG',
      'SAN JOSE',
      'TALAIRAN',
      'TALAMIMI',
      'VILLACASTOR (GALIT)',
    ],
  },
  DIPLAHAN: {
    province: 'ZAMBOANGA SIBUGAY',
    municipality: 'DIPLAHAN',
    region: 'REGION IX',
    barangay_list: [
      'BALANGAO',
      'BUTONG',
      'DITAY',
      'GAULAN',
      'GOLING',
      'GUINOMAN',
      'KAUSWAGAN',
      'LINDANG',
      'LOBING',
      'LUOP',
      'MANANGON',
      'MEJO',
      'NATAN',
      'PARADISE',
      'PILAR',
      'POBLACION (DIPLAHAN)',
      'SAMPOLI A',
      'SAMPOLI B',
      'SANTA CRUZ',
      'SONGCUYA',
      'TINONGTONGAN',
      'TUNO',
    ],
  },
  IMELDA: {
    province: 'ZAMBOANGA SIBUGAY',
    municipality: 'IMELDA',
    region: 'REGION IX',
    barangay_list: [
      'BALUGO',
      'BALUNGISAN',
      'BALUYAN',
      'CANA-AN',
      'DUMPOC',
      'GANDIANGAN',
      'ISRAEL (BALIAN ISRAEL)',
      'LA VICTORIA',
      'LITTLE BAGUIO',
      'LOWER BALURAN',
      'LUMBOG',
      'LUMPANAC',
      'MALI LITTLE BAGUIO',
      'POBLACION (SANTA FE)',
      'PULAWAN (MT. VIEW)',
      'SAN JOSE',
      'SANTA BARBARA',
      'UPPER BALURAN',
    ],
  },
  IPIL: {
    province: 'ZAMBOANGA SIBUGAY',
    municipality: 'IPIL',
    region: 'REGION IX',
    barangay_list: [
      'BACALAN',
      'BANGKEROHAN',
      'BULU-AN',
      'CAPARAN',
      'DOMANDAN',
      'DON ANDRES',
      'DOÑA JOSEFA',
      'GUITUAN',
      'IPIL HEIGHTS',
      'LABI',
      'LOGAN',
      'LOWER IPIL HEIGHTS',
      'LOWER TAWAY',
      'LUMBIA',
      'MAASIN',
      'MAGDAUP',
      'MAKILAS',
      'PANGI',
      'POBLACION',
      'SANITO',
      'SUCLEMA',
      'TAWAY',
      'TENAN',
      'TIAYON',
      'TIMALANG',
      'TOMITOM',
      'UPPER PANGI',
      "VETERAN'S VILLAGE (RUIZ)",
    ],
  },
  KABASALAN: {
    province: 'ZAMBOANGA SIBUGAY',
    municipality: 'KABASALAN',
    region: 'REGION IX',
    barangay_list: [
      'BANKER',
      'BOLO BATALLION',
      'BUAYAN',
      'CAINGLET',
      'CALAPAN',
      'CALUBIHAN',
      'CONCEPCION (BALUNGIS)',
      'DIAMPAK',
      'DIPALA',
      'GACBUSAN',
      'GOODYEAR',
      'LACNAPAN',
      'LITTLE BAGUIO',
      'LUMBAYAO',
      'NAZARETH',
      'PALINTA',
      'PEÑARANDA',
      'POBLACION',
      'RIVERSIDE',
      'SANGHANAN',
      'SANTA CRUZ',
      'SAYAO',
      'SHIOLAN',
      'SIMBOL',
      'SININAN',
      'TAMIN',
      'TAMPILISAN',
      'TIGBANGAGAN',
      'TIMUAY DANDA (MANGAHAS)',
    ],
  },
  MABUHAY: {
    province: 'ZAMBOANGA SIBUGAY',
    municipality: 'MABUHAY',
    region: 'REGION IX',
    barangay_list: [
      'ABUNDA',
      'BAGONG SILANG',
      'BANGKAW-BANGKAW',
      'CALIRAN',
      'CATIPAN',
      'KAUSWAGAN',
      'LIGAYA',
      'LOOC-BARLAK',
      'MALINAO',
      'PAMANSAAN',
      'PINALIM (SAN ROQUE)',
      'POBLACION',
      'PUNAWAN',
      'SANTO NIÑO (CALIRAN)',
      'SAWA',
      'SIOTON',
      'TAGUISIAN',
      'TANDU-COMOT (KATIPUNAN)',
    ],
  },
  MALANGAS: {
    province: 'ZAMBOANGA SIBUGAY',
    municipality: 'MALANGAS',
    region: 'REGION IX',
    barangay_list: [
      'BACAO',
      'BASAK-BAWANG',
      'BONTONG',
      'CAMANGA',
      'CANDIIS',
      'CATITUAN',
      'DANSULAO',
      'DEL PILAR',
      'GUILAWA',
      'KIGAY',
      'LA DICHA',
      'LIPACAN',
      'LOGPOND',
      'MABINI',
      'MALUNGON',
      'MULOM',
      'OVERLAND',
      'PALALIAN',
      'PAYAG',
      'POBLACION',
      'REBOCON',
      'SAN VICENTE',
      'SINUSAYAN',
      'TACKLING',
      'TIGABON',
    ],
  },
  NAGA: {
    province: 'ZAMBOANGA SIBUGAY',
    municipality: 'NAGA',
    region: 'REGION IX',
    barangay_list: [
      'AGUINALDO',
      'BAGA',
      'BALUNO',
      'BANGKAW-BANGKAW',
      'CABONG',
      'CROSSING STA. CLARA',
      'GUBAWANG',
      'GUINTOLOAN',
      'KALIANTANA',
      'LA PAZ',
      'LOWER SULITAN',
      'MAMAGON',
      'MARSOLO',
      'POBLACION',
      'SAN ISIDRO',
      'SANDAYONG',
      'SANTA CLARA',
      'SULO',
      'TAMBANAN',
      'TAYTAY MANUBO',
      'TILUBOG',
      'TIPAN',
      'UPPER SULITAN',
    ],
  },
  OLUTANGA: {
    province: 'ZAMBOANGA SIBUGAY',
    municipality: 'OLUTANGA',
    region: 'REGION IX',
    barangay_list: [
      'BATERIA',
      'CALAIS (KALINES)',
      'ESPERANZA',
      'FAMA',
      'GALAS',
      'GANDAAN',
      'KAHAYAGAN',
      'LOOC SAPI',
      'MATIM',
      'NOQUE',
      'PULO LAUM',
      'PULO MABAO',
      'SAN ISIDRO',
      'SAN JOSE',
      'SANTA MARIA',
      'SOLAR (POB.)',
      'TAMBANAN',
      'VILLACORTE',
      'VILLAGONZALO',
    ],
  },
  PAYAO: {
    province: 'ZAMBOANGA SIBUGAY',
    municipality: 'PAYAO',
    region: 'REGION IX',
    barangay_list: [
      'BALIAN',
      'BALOGO',
      'BALUNGISAN',
      'BINANGONAN',
      'BULACAN',
      'BULAWAN',
      'CALAPE',
      'DALAMA',
      'FATIMA (SILAL)',
      'GUINTOLAN',
      'GUIWAN',
      'KATIPUNAN',
      'KIMA',
      'KULASIAN',
      'KULISAP',
      'LA FORTUNA',
      'LABATAN',
      'MAYABO (SANTA MARIA)',
      'MINUNDAS (SANTO. NIÑO)',
      'MOUNTAIN VIEW (PULUAN)',
      'NANAN',
      'POBLACION (PAYAO)',
      'SAN ISIDRO',
      'SAN ROQUE',
      'SAN VICENTE (BINANGONAN)',
      'SILAL',
      'SUMILONG',
      'TALAPTAP',
      'UPPER SUMILONG',
    ],
  },
  'ROSELLER LIM': {
    province: 'ZAMBOANGA SIBUGAY',
    municipality: 'ROSELLER LIM',
    region: 'REGION IX',
    barangay_list: [
      'ALI ALSREE',
      'BALANSAG',
      'CALULA',
      'CASACON',
      'DON PERFECTO',
      'GANGO',
      'KATIPUNAN',
      'KULAMBUGAN',
      'MABINI',
      'MAGSAYSAY',
      'MALUBAL',
      'NEW ANTIQUE',
      'NEW SAGAY',
      'PALMERA',
      'PRES. ROXAS',
      'REMEDIOS',
      'SAN ANTONIO',
      'SAN FERNANDINO',
      'SAN JOSE',
      'SANTO ROSARIO',
      'SIAWANG',
      'SILINGAN',
      'SURABAY',
      'TARUC',
      'TILASAN',
      'TUPILAC',
    ],
  },
  SIAY: {
    province: 'ZAMBOANGA SIBUGAY',
    municipality: 'SIAY',
    region: 'REGION IX',
    barangay_list: [
      'BAGONG SILANG',
      'BALAGON',
      'BALINGASAN',
      'BALUCANAN',
      'BATAAN',
      'BATU',
      'BUYOGAN',
      'CAMANGA',
      'COLORAN',
      'KIMOS (KIMA)',
      'LABASAN',
      'LAGTING',
      'LAIH',
      'LOGPOND',
      'MAGSAYSAY',
      'MAHAYAHAY',
      'MALIGAYA',
      'MANIHA',
      'MINSULAO',
      'MIRANGAN',
      'MONCHING',
      'PARUK',
      'POBLACION',
      'PRINCESA SUMAMA',
      'SALINDING',
      'SAN ISIDRO',
      'SIBUGUEY',
      'SILOH',
      'VILLAGRACIA',
    ],
  },
  TALUSAN: {
    province: 'ZAMBOANGA SIBUGAY',
    municipality: 'TALUSAN',
    region: 'REGION IX',
    barangay_list: [
      'AURORA',
      'BAGANIPAY',
      'BOLINGAN',
      'BUALAN',
      'CAWILAN',
      'FLORIDA',
      'KASIGPITAN',
      'LAPARAY',
      'MAHAYAHAY',
      'MOALBOAL',
      'POBLACION (TALUSAN)',
      'SAGAY',
      'SAMONTE',
      'TUBURAN',
    ],
  },
  TITAY: {
    province: 'ZAMBOANGA SIBUGAY',
    municipality: 'TITAY',
    region: 'REGION IX',
    barangay_list: [
      'ACHASOL',
      'AZUSANO',
      'BANGCO',
      'CAMANGA',
      'CULASIAN',
      'DALANGIN',
      'DALANGIN MUSLIM',
      'DALISAY',
      'GOMOTOC',
      'IMELDA (UPPER CAMANGA)',
      'KIPIT',
      'KITABOG',
      'LA LIBERTAD',
      'LONGILOG',
      'MABINI',
      'MALAGANDIS',
      'MATE',
      'MOALBOAL',
      'NAMNAMA',
      'NEW CANAAN',
      'PALOMOC',
      'POBLACION (TITAY)',
      'POBLACION MUSLIM',
      'PULIDAN',
      'SAN ANTONIO',
      'SAN ISIDRO',
      'SANTA FE',
      'SUPIT',
      'TUGOP',
      'TUGOP MUSLIM',
    ],
  },
  TUNGAWAN: {
    province: 'ZAMBOANGA SIBUGAY',
    municipality: 'TUNGAWAN',
    region: 'REGION IX',
    barangay_list: [
      'BALURAN',
      'BATUNGAN',
      'CAYAMCAM',
      'DATU TUMANGGONG',
      'GAYCON',
      'LANGON',
      'LIBERTAD (POB.)',
      'LINGUISAN',
      'LITTLE MARGOS',
      'LOBOC',
      'LOOC-LABUAN',
      'LOWER TUNGAWAN',
      'MALUNGON',
      'MASAO',
      'SAN ISIDRO',
      'SAN PEDRO',
      'SAN VICENTE',
      'SANTO NIÑO',
      'SISAY',
      'TAGLIBAS',
      'TIGBANUANG',
      'TIGBUCAY',
      'TIGPALAY',
      'TIMBABAUAN',
      'UPPER TUNGAWAN',
    ],
  },
  AKBAR: {
    province: 'BASILAN',
    municipality: 'AKBAR',
    region: 'BARMM',
    barangay_list: [
      'CADDAYAN',
      'LINONGAN',
      'LOWER BATO-BATO',
      'MANGALUT',
      'MANGUSO',
      'PAGUENGAN',
      'SEMUT',
      'UPPER BATO-BATO',
      'UPPER SINANGKAPAN',
    ],
  },
  'AL-BARKA': {
    province: 'BASILAN',
    municipality: 'AL-BARKA',
    region: 'BARMM',
    barangay_list: [
      'APIL-APIL',
      'BATO-BATO',
      'BOHE-PIANG',
      'BUCALAO',
      'CAMBUG',
      'DANAPAH',
      'GUINANTA',
      'KAILIH',
      'KINUKUTAN',
      'KUHON',
      'KUHON LENNUH',
      'LINUAN',
      'LOOKBISAYA (KAULUNGAN ISLAND)',
      'MACALANG',
      'MAGCAWA',
      'SANGKAHAN (KAULUNGAN ISLAND)',
    ],
  },
  'HADJI MOHAMMAD AJUL': {
    province: 'BASILAN',
    municipality: 'HADJI MOHAMMAD AJUL',
    region: 'BARMM',
    barangay_list: [
      'BASAKAN',
      'BUTON',
      'CANDIIS',
      'LANGIL',
      'LANGONG',
      'LANGUYAN',
      'PINTASAN',
      'SERONGGON',
      'SIBAGO',
      'SULUTAN MATANGAL',
      'TUBURAN PROPER (POB.)',
    ],
  },
  'HADJI MUHTAMAD': {
    province: 'BASILAN',
    municipality: 'HADJI MUHTAMAD',
    region: 'BARMM',
    barangay_list: [
      'BALUK-BALUK',
      'DASALAN',
      'LUBUKAN',
      'LUUKBONGSOD',
      'MANANGGAL',
      'PALAHANGAN',
      'PANDUCAN',
      'SANGBAY BIG',
      'SANGBAY SMALL',
      'TAUSAN',
    ],
  },
  'ISABELA': {
    province: 'BASILAN',
    municipality: 'ISABELA',
    region: 'BARMM',
    barangay_list: [
      'AGUADA',
      'BALATANAY',
      'BALUNO',
      'BEGANG',
      'BINUANGAN',
      'BUSAY',
      'CABUNBATA',
      'CALVARIO',
      'CARBON',
      'DIKI',
      'DONA RAMONA T. ALANO (ISABELA WESTSITE)',
      'ISABELA EASTSIDE (POB.)',
      'ISABELA PROPER (POB.)',
      'KAPATAGAN GRANDE',
      'KAPAYAWAN',
      'KAUMPURNAH ZONE I',
      'KAUMPURNAH ZONE II',
      'KAUMPURNAH ZONE III',
      'KUMALARANG',
      'LA PIEDAD (POB.)',
      'LAMPINIGAN',
      'LANOTE',
      'LUKBUTON',
      'LUMBANG',
      'MAKIRI',
      'MALIGUE (LUNOT)',
      'MARANG-MARANG',
      'MARKETSITE (POB.)',
      'MASULA',
      'MENZI',
      'PANIGAYAN',
      'PANUNSULAN',
      'PORT AREA (POB.)',
      'RIVERSIDE',
      'SAN RAFAEL',
      'SANTA BARBARA',
      'SANTA CRUZ (POB.)',
      'SEASIDE (POB.)',
      'SMALL KAPATAGAN',
      'SUMAGDANG',
      'SUNRISE VILLAGE (POB.)',
      'TABIAWAN',
      'TABUK (POB.)',
      'TAMPALAN',
      'TIMPUL',
    ],
  },
  'LAMITAN': {
    province: 'BASILAN',
    municipality: 'LAMITAN',
    region: 'BARMM',
    barangay_list: [
      'ARCO',
      'BA-AS',
      'BAIMBING',
      'BALAGTASAN',
      'BALAS',
      'BALOBO',
      'BATO',
      'BAUNGOS',
      'BOHEBESSEY',
      'BOHEIBU',
      'BOHENANGE',
      'BOHESAPA',
      'BOHEYAKAN',
      'BOHEYAWAS',
      'BUAHAN',
      'BULANTING',
      'BULINGAN',
      'CABOBO',
      'CALUGUSAN',
      'CAMPO UNO',
      'COLONIA',
      'DANIT-PUNTOCAN',
      'KULAY BATO',
      'LEBBUH',
      'LIMO-OK',
      'LO-OK',
      'LUKSUMBANG',
      'LUMUTON',
      'MAGANDA',
      'MALAKAS',
      'MALIGAYA',
      'MALINIS (POB.)',
      'MALO-ONG CANAL',
      'MALO-ONG SAN JOSE',
      'MATATAG',
      'MATIBAY',
      'PARANGBASAK',
      'SABONG',
      'SANTA CLARA',
      'SENGAL',
      'SIMBANGON',
      'TANDONG AHAS',
      'TUMAKID',
      'UBIT',
      'ULAME',
    ],
  },
  LANTAWAN: {
    province: 'BASILAN',
    municipality: 'LANTAWAN',
    region: 'BARMM',
    barangay_list: [
      'ATONG-ATONG',
      'BAGBAGON',
      'BAUNGIS',
      'BULAN-BULAN',
      'BULANZA',
      'CALAYAN',
      'CALUGUSAN',
      'CANIBUNGAN',
      'LANDUGAN',
      'LANTAWAN PROPER (POB.)',
      'LAWI-LAWI',
      'LAWILA',
      'LOWER BAÑAS',
      'LOWER MANGGAS',
      'LUUK-MALUHA',
      'MATARLING',
      'MATIKANG',
      'PAMUCALIN',
      'PANIONGAN',
      'PARIAN-BAUNOH',
      'SUBA-AN (PANGASAHAN)',
      'SWITCH YAKAL',
      'TAIRAN',
      'UPPER BAÑAS',
      'UPPER MANGGAS',
    ],
  },
  MALUSO: {
    province: 'BASILAN',
    municipality: 'MALUSO',
    region: 'BARMM',
    barangay_list: [
      'ABONG-ABONG',
      'BATUNGAL',
      'CALANG CANAS',
      'FUENTE MALUSO',
      'GUANAN NORTH (ZONE I)',
      'GUANAN SOUTH (ZONE II)',
      'LIMBUBONG',
      'MAHAYAHAY LOWER (ZONE I)',
      'MAHAYAHAY UPPER (ZONE II)',
      'MUSLIM AREA',
      'PORT HOLLAND ZONE I POB. (UPPER)',
      'PORT HOLLAND ZONE II POB. (SHIPYARD MAIN)',
      'PORT HOLLAND ZONE III POB. (SAMAL VILLAGE)',
      'PORT HOLLAND ZONE IV (LOWER)',
      'PORT HOLLAND ZONE V (SHIPYARD TABUK)',
      'TABERLONGAN',
      'TAMUK',
      'TOWNSITE (POB.)',
      'TUBIGAN',
      'UPPER GARLAYAN',
    ],
  },
  SUMISIP: {
    province: 'BASILAN',
    municipality: 'SUMISIP',
    region: 'BARMM',
    barangay_list: [
      'BACUNG',
      'BAIWAS',
      'BASAK',
      'BENEMBENGAN LOWER',
      'BENEMBENGAN UPPER',
      'BOHE-LANGUYAN',
      'BULI-BULI',
      'CABCABAN',
      'CABENGBENG LOWER',
      'CABENGBENG UPPER',
      'ETTUB-ETTUB',
      'GUIONG',
      'KAUM-AIR',
      'KAUMPAMATSAKEM',
      'LIBUG',
      'LIMBOCANDIS',
      'LUKKETON',
      'LUUK-BAIT',
      'MAHATALANG',
      'MANAUL',
      'MANGAL (POB.)',
      'MARANG',
      'MEBAK',
      'SAHAYA BOHE BATO',
      'SAPAH BULAK',
      'SUMISIP CENTRAL',
      'TIKUS',
      'TONGSENGAL',
      'TUMAHUBONG',
    ],
  },
  'TABUAN-LASA': {
    province: 'BASILAN',
    municipality: 'TABUAN-LASA',
    region: 'BARMM',
    barangay_list: [
      'BABAG (BABUAN ISLAND)',
      'BALANTING',
      'BOLOH-BOLOH',
      'BUKUT-UMUS',
      'KAUMPURNAH',
      'LANAWAN',
      'PISAK-PISAK',
      'SALUPING',
      'SULIGAN (BABUAN ISLAND)',
      'SULLOH (TAPIANTANA)',
      'TAMBULIG BUTON',
      'TONG-UMUS',
    ],
  },
  'TIPO-TIPO': {
    province: 'BASILAN',
    municipality: 'TIPO-TIPO',
    region: 'BARMM',
    barangay_list: [
      'BADJA',
      'BAGUINDAN',
      'BANAH',
      'BANGCUANG',
      'BOHE-TAMBAK',
      'BOHEBACA',
      'BOHELEBUNG',
      'LAGAYAS',
      'LIMBO-UPAS',
      'SILANGKUM',
      'TIPO-TIPO PROPER (POB.)',
    ],
  },
  'UNGKAYA PUKAN': {
    province: 'BASILAN',
    municipality: 'UNGKAYA PUKAN',
    region: 'BARMM',
    barangay_list: [
      'AMALOY',
      'BOHE-PAHUH',
      'BOHE-SUYAK',
      'CABANGALAN',
      'DANIT',
      'KAMAMBURINGAN',
      'MATATA',
      'MATERLING',
      'PIPIL',
      'SUNGKAYUT',
      'TONGBATO',
      'ULITAN',
    ],
  },
  'AMAI MANABILANG (BUMBARAN)': {
    province: 'LANAO DEL SUR',
    municipality: 'AMAI MANABILANG (BUMBARAN)',
    region: 'BARMM',
    barangay_list: [
      'BAGUMBAYAN',
      'BANDARA-INGUD',
      'COMARA',
      'FRANKFORT',
      'LAMBANOGAN',
      'LICO',
      'MANSILANO',
      'NATANGCOPAN',
      'PAGALAMATAN',
      'PAGONAYAN',
      'PIAGMA',
      'POBLACION (APARTFORT)',
      'PUNUD',
      'RANAO-BANING',
      'SAGUA-AN',
      'SALAM',
      'SUMUGOT',
    ],
  },
  'BACOLOD-KALAWI (BACOLOD GRANDE)': {
    province: 'LANAO DEL SUR',
    municipality: 'BACOLOD-KALAWI (BACOLOD GRANDE)',
    region: 'BARMM',
    barangay_list: [
      'AMPAO',
      'BAGOAINGUD',
      'BALUT',
      'BARUA',
      'BUADIAWANI',
      'BUBONG',
      'DARAMOYOD',
      'DILABAYAN',
      'DIPATUAN',
      'GANDAMATO',
      'GURAIN',
      'ILIAN',
      'LAMA',
      'LIAWAO',
      'LUMBACA-INGUD',
      'MADANDING',
      'ORONG',
      'PINDOLONAN',
      'POBLACION I',
      'POBLACION II',
      'RAYA',
      'ROROWAN',
      'SUGOD',
      'TAMBO',
      'TUKA I',
      'TUKA II',
    ],
  },
  BALABAGAN: {
    province: 'LANAO DEL SUR',
    municipality: 'BALABAGAN',
    region: 'BARMM',
    barangay_list: [
      'BAGOAINGUD',
      'BANAGO',
      'BARORAO',
      'BATUAN',
      'BUDAS',
      'BUENAVISTA',
      'BUISAN (BENGABENG)',
      'CALILANGAN',
      'IGABAY',
      'ILIAN',
      'LALABUAN',
      'LORENZO',
      'LOWER ITIL',
      'LUMBAC',
      'MACAO',
      'MAGULALUNG OCCIDENTAL',
      'MAGULALUNG ORIENTAL',
      'MATAMPAY',
      'MATANOG',
      'MOLIMOC',
      'NARRA',
      'PINDOLONAN',
      'PLASAN',
      'POBLACION (BALABAGAN)',
      'PURAKAN',
      'TATAYA',
      'UPPER ITIL',
    ],
  },
  'BALINDONG (WATU)': {
    province: 'LANAO DEL SUR',
    municipality: 'BALINDONG (WATU)',
    region: 'BARMM',
    barangay_list: [
      'ABAGA',
      'BANTOGA WATO',
      'BARIT',
      'BOLINSONG',
      'BORAKIS',
      'BUALAN',
      'BUBONG',
      'BUBONG CADAPAAN',
      'CADAPAAN',
      'CADAYONAN',
      'DADAYAG',
      'DADO',
      'DIBARUSAN',
      'DILAUSAN',
      'DIMARAO',
      'INGUD',
      'KALUNTAY',
      'LALABUAN',
      'LATI',
      'LILOD',
      'LIMBO',
      'LUMBAC LALAN',
      'LUMBAC WATO',
      'LUMBAYAO',
      'MAGARANG',
      'MALAIG',
      'NUSA LUMBA RANAO',
      'PADILA',
      'PAGAYAWAN',
      'PAIGOAY',
      'PANTARAGOO',
      'POBLACION (BALINDONG)',
      'RAYA',
      'SALIPONGAN',
      'TALUB',
      'TANTUA RAYA',
      'TOMAROMPONG',
      'TUKA BUBONG',
    ],
  },
  BAYANG: {
    province: 'LANAO DEL SUR',
    municipality: 'BAYANG',
    region: 'BARMM',
    barangay_list: [
      'BAGOAINGUD',
      'BAIRAN (POB.)',
      'BANDINGUN',
      'BIABI',
      'BIALAAN',
      'BUBONG LILOD',
      'BUBONG RAYA',
      'CADAYONAN',
      'CADINGILAN OCCIDENTAL',
      'CADINGILAN ORIENTAL',
      'CONDARAAN POB. (CONDARAAN DIMADAP)',
      'CORMATAN',
      'GANDAMATO',
      'ILIAN',
      'LALAPUNG CENTRAL',
      'LALAPUNG PROPER (POB.)',
      'LALAPUNG UPPER',
      'LINAO',
      'LINUK (POB.)',
      'LIONG',
      'LUMBAC',
      'LUMBAC CADAYONAN',
      'MALIWANAG',
      'MAPANTAO',
      'MIMBALAWAG',
      'PALAO',
      'PAMA-AN',
      'PAMACOTAN',
      'PANTAR',
      'PARAO',
      'PATONG',
      'POBLACION (BAYANG)',
      'POROTAN',
      'RANTIAN',
      'RAYA CADAYONAN',
      'RINABOR (POB.)',
      'SAMPORNA (POB.)',
      'SAPA',
      'SILID',
      'SUGOD',
      'SULTAN PANDAPATAN',
      'SUMBAG (POB.)',
      'TAGORANAO',
      'TANGCAL',
      'TANGCAL PROPER (POB.)',
      'TOMAROMPONG',
      'TOMONGCAL LIGI',
      'TOROGAN',
      'TUCA (POB.)',
    ],
  },
  BINIDAYAN: {
    province: 'LANAO DEL SUR',
    municipality: 'BINIDAYAN',
    region: 'BARMM',
    barangay_list: [
      'BADAK',
      'BAGUIANGUN',
      'BALUT MAITO',
      'BASAK',
      'BUBONG',
      'BUBONGA-RANAO',
      'DANSALAN DACSULA',
      'INGUD',
      'KIALILIDAN',
      'LUMBAC',
      'MACAGUILING',
      'MADAYA',
      'MAGONAYA',
      'MAINDIG',
      'MASOLUN',
      'OLAMA',
      'PAGALAMATAN (POB.)',
      'PANTAR',
      'PICALILANGAN',
      'PICOTAAN',
      'PINDOLONAN',
      'POBLACION',
      'SOLDARORO',
      'TAMBAC',
      'TIMBANGAN',
      'TUCA',
    ],
  },
  'BUADIPOSO-BUNTONG': {
    province: 'LANAO DEL SUR',
    municipality: 'BUADIPOSO-BUNTONG',
    region: 'BARMM',
    barangay_list: [
      'BACOLOD',
      'BANGON',
      'BANGON PROPER',
      'BOTO RAGONDINGAN',
      'BUADIPOSO LILOD',
      'BUADIPOSO PROPER',
      'BUADIPOSO RAYA',
      'BUBONG',
      'BUNTONG PROPER',
      'CADAYONAN',
      'DANSALAN',
      'DATU TAMBARA',
      'DIRISAN',
      'GATA',
      'KALAKALA',
      'KATOGONAN',
      'LUMBAC',
      'LUMBATAN MANACAB',
      'LUMBATAN PATAINGUD',
      'LUNDUBAN (RAGONDINGAN)',
      'MANACAB (POB.)',
      'MINANGA (BUNTONG)',
      'PALING',
      'PINDOLONAN',
      'PUALAS',
      'RAGONDINGAN EAST',
      'RAGONDINGAN PROPER',
      'RAGONDINGAN WEST',
      'RAYA BUNTONG (BUNTONG EAST)',
      'SAPOT',
      'TANGCAL',
      'TARIK',
      'TUKA',
    ],
  },
  BUBONG: {
    province: 'LANAO DEL SUR',
    municipality: 'BUBONG',
    region: 'BARMM',
    barangay_list: [
      'BACOLOD',
      'BAGOAINGUD',
      'BANSAYAN',
      'BASINGAN',
      'BATANGAN',
      'BUALAN',
      'BUBONGA DIDAGUN',
      'CARIGONGAN',
      'DALAON',
      'DIBAROSAN',
      'DILABAYAN',
      'DIMAPATOY',
      'DIMAYON',
      'DIMAYON PROPER',
      'DIOLANGAN',
      'GUIGUIKUN',
      'MADANDING',
      'MALUNGUN',
      'MASOROT',
      'MATAMPAY DIMARAO',
      'MIABALAWAG',
      'MONTIAAN',
      'PAGAYAWAN',
      'PALAO',
      'PANALAWAN',
      'PANTAR',
      'PENDOGOAN',
      'PINDOLONAN',
      'POBLACION (BUBONG)',
      'POLAYAGAN',
      'PUNUD',
      'RAMAIN BUBONG',
      'ROGERO',
      'SALIPONGAN',
      'SUNGGOD',
      'TABORO',
    ],
  },
  BUTIG: {
    province: 'LANAO DEL SUR',
    municipality: 'BUTIG',
    region: 'BARMM',
    barangay_list: [
      'BAYABAO POBLACION',
      'BUTIG PROPER',
      'CABASARAN',
      'COLOYAN TAMBO',
      'DILIMBAYAN',
      'DOLANGAN',
      'MALUNGUN',
      'PINDOLONAN',
      'POKTAN',
      'RAGAYAN',
      'RAYA',
      'SAMER',
      'SANDAB MADAYA',
      'SUNDIG',
      'TIMBAB',
      'TIOWI',
    ],
  },
  CALANOGAS: {
    province: 'LANAO DEL SUR',
    municipality: 'CALANOGAS',
    region: 'BARMM',
    barangay_list: [
      'BUBONGA RANAO',
      'CALALAOAN (POB.)',
      'GAS',
      'INOMA',
      'INUDARAN',
      'LUGUNA',
      'MIMBALAWAG',
      'NGINGIR',
      'PAGALONGAN',
      'PANGGAWALUPA',
      'PANTAON',
      'PIKSAN',
      'PINDOLONAN',
      'PUNUD',
      'TAGORANAO',
      'TALIBOBOKA',
      'TAMBAC',
    ],
  },
  'DITSAAN-RAMAIN': {
    province: 'LANAO DEL SUR',
    municipality: 'DITSAAN-RAMAIN',
    region: 'BARMM',
    barangay_list: [
      'BACLAYAN LILOD',
      'BACLAYAN RAYA',
      'BAGOAINGUD',
      'BARIMBINGAN',
      'BAYABAO',
      'BUADI ALAO',
      'BUADI BABAI',
      'BUADI OLOC',
      'BUAYAAN LILOD',
      'BUAYAAN MADANDING',
      'BUAYAAN RAYA',
      'BUBONG DANGIPRAMPIAI',
      'DADO',
      'DANGIMPRAMPIAI',
      'DARIMBANG',
      'DILAUSAN',
      'DITSAAN',
      'GADONGAN',
      'LINAMON',
      'LUMBATAN RAMAIN',
      'MAINDIG DITSAAN',
      'MANDARA',
      'MARANAO TIMBER (DALAMA)',
      'PAGALONGAN BUADIADINGAN',
      'PAGALONGAN GINAOPAN',
      'PAGALONGAN MASIOON',
      'PAGALONGAN PROPER',
      'POLO',
      'RAMAIN POBLACION',
      'RAMAIN PROPER',
      'RANTIAN',
      'SULTAN PANGADAPUN',
      'SUNDIGA BAYABAO',
      'TALUB',
      'UPPER PUGAAN',
    ],
  },
  GANASSI: {
    province: 'LANAO DEL SUR',
    municipality: 'GANASSI',
    region: 'BARMM',
    barangay_list: [
      'BAGOAINGUD',
      'BALINTAD',
      'BALINTAD A',
      'BARIT',
      'BARORAO',
      'BATO BATORAY',
      'BAYA',
      'CAMPONG A RAYA',
      'CAMPONG SABELA',
      'DAPAAN',
      'GADONGAN',
      'GUI',
      'LINUK',
      'LUMBAC',
      'LUMBACAINGUD',
      'MACABAO',
      'MACAGUILING',
      'MASOLUN',
      'PAGALONGAN',
      'PAMALIAN',
      'PANGADAPUN',
      'PANGGAWALUPA',
      'PANTAON',
      'PANTAON A',
      'PARA-ABA',
      'PINDOLONAN',
      'POBLACION',
      'SEKUN MATAMPAY',
      'SOGOD MADAYA',
      'TABUAN',
      'TAGANONOK',
      'TALIOGON',
    ],
  },
  KAPAI: {
    province: 'LANAO DEL SUR',
    municipality: 'KAPAI',
    region: 'BARMM',
    barangay_list: [
      'BABAYOG',
      'CORMATAN',
      'DILABAYAN',
      'DILIMBAYAN',
      'DIMAGALING (DIMAGALIN PROPER)',
      'DIMUNDA',
      'DORONAN',
      'GADONGAN',
      'KASAYANAN',
      'KASAYANAN WEST',
      'KIBOLOS',
      'KINING',
      'LIDASAN',
      'MACADAR',
      'MALNA PROPER',
      'PAGALONGAN',
      'PANTAON',
      'PARAO',
      'PINDOLONAN',
      'POBLACION (KAPAI PROPER)',
    ],
  },
  'LUMBA-BAYABAO (MAGUING)': {
    province: 'LANAO DEL SUR',
    municipality: 'LUMBA-BAYABAO (MAGUING)',
    region: 'BARMM',
    barangay_list: [
      'BACOLOD I',
      'BACOLOD II',
      'BANTAYAO',
      'BARIT',
      'BAUGAN',
      'BUAD LUMBAC',
      'CABASARAN',
      'CALILANGAN',
      'CARANDANGAN-MIPAGA',
      'CORMATAN LANGBAN',
      'DIALONGANA',
      'DILINDONGAN-CADAYONAN',
      'GADONGAN',
      'GALAWAN',
      'GAMBAI',
      'KASOLA',
      'LALANGITUN',
      'LAMA',
      'LINDONGAN DIALONGANA',
      'LOBO BASARA',
      'LUMBAC BACAYAWAN',
      'MACAGUILING',
      'MAPANTAO',
      'MAPOLING',
      'MARIBO (POB.)',
      'MINARING DILADIGAN',
      'PAGAYAWAN',
      'POSUDARAGAT',
      'RUMAYAS',
      'SABALA BANTAYAO',
      'SALAMAN',
      'SALOLODUN BERWAR',
      'SARIGIDAN MADIAR',
      'SUNGGOD',
      'TALUAN',
      'TAMLANG',
      'TONGCOPAN',
      'TUROGAN',
    ],
  },
  'LUMBACA-UNAYAN': {
    province: 'LANAO DEL SUR',
    municipality: 'LUMBACA-UNAYAN',
    region: 'BARMM',
    barangay_list: [
      'BANGON (DILAUSAN)',
      'BETA',
      'CALALON',
      'CALIPAPA',
      'DILAUSAN',
      'DIMAPAOK',
      'LUMBAK-DILAUSAN',
      'ORIENTAL BETA',
      'TRINGUN',
    ],
  },
  LUMBATAN: {
    province: 'LANAO DEL SUR',
    municipality: 'LUMBATAN',
    region: 'BARMM',
    barangay_list: [
      'ALOG',
      'BASAYUNGUN',
      'BUAD',
      'BUBONG MACADAR',
      'BUDI',
      'DAGO-OK',
      'DALAMA',
      'DALIPUGA',
      'LALAPUNG',
      'LIGUE',
      'LUMBAC',
      'LUMBAC BACAYAWAN',
      'LUNAY',
      'MACADAR',
      'MADAYA',
      'MINANGA',
      'PANTAR',
      'PENARING',
      'PICOTAAN',
      'POBLACION (LUMBATAN)',
      'TAMBAC',
    ],
  },
  LUMBAYANAGUE: {
    province: 'LANAO DEL SUR',
    municipality: 'LUMBAYANAGUE',
    region: 'BARMM',
    barangay_list: [
      'BAGOAINGUD',
      'BALAIGAY',
      'BUALAN',
      'CABUNTUNGAN',
      'CADAYONAN',
      'CADINGILAN',
      'CADINGILAN A',
      'CASALAYAN',
      'DALA (DALAMA)',
      'DILIMBAYAN',
      'DIROMOYOD',
      'KABASARAN (POB.)',
      'LAMIN',
      'MAPANTAO-BALANGAS',
      'MINIROS',
      'NANAGUN',
      'PANTAON',
      'PINDOLONAN',
      'PITATANGLAN',
      'POCTAN',
      'SINGCARA',
      'WAGO',
    ],
  },
  MADALUM: {
    province: 'LANAO DEL SUR',
    municipality: 'MADALUM',
    region: 'BARMM',
    barangay_list: [
      'ABAGA',
      'BACAYAWAN',
      'BAGOAINGUD',
      'BASAK',
      'BATO',
      'BUBONG',
      'CABASARAN',
      'CADAYONAN',
      'DANDAMUN',
      'DELAUSAN',
      'DIAMPACA',
      'DIBAROSAN',
      'GADONGAN',
      'GURAIN',
      'KORMATAN',
      'LIANGAN',
      'LIANGAN I',
      'LILITUN',
      'LINAO',
      'LINUK',
      'LUMBAC',
      'MADAYA',
      'PADIAN TOROGAN I',
      'PAGAYAWAN',
      'PARIDI-KALIMODAN',
      'POBLACION',
      'PUNUD',
      'RACOTAN',
      'RAYA',
      'RIRAY',
      'SABANDING',
      'SALONGABANDING',
      'SOGOD KALOY',
      'SUGOD',
      'TAMPORONG',
      'TONGANTONGAN',
      'UDANGUN',
    ],
  },
  MADAMBA: {
    province: 'LANAO DEL SUR',
    municipality: 'MADAMBA',
    region: 'BARMM',
    barangay_list: [
      'BALAGUNUN',
      'BALINTAD',
      'BAWANG',
      'BIABE',
      'BUBONG UYAAN',
      'CABASARAN',
      'DIBARUSAN',
      'ILIAN',
      'LAKITAN',
      'LIANGAN',
      'LINUK',
      'LUMBACA INGUD',
      'MADAMBA',
      'PAGAYONAN',
      'PALAO',
      'PANGADAPAN',
      'PANTAON',
      'PANTAR',
      'PUNUD',
      'TAMBO',
      'TUBARAN',
      'TUCA',
      'TULAY',
      'UYAAN PROPER (POB.)',
    ],
  },
  MAGUING: {
    province: 'LANAO DEL SUR',
    municipality: 'MAGUING',
    region: 'BARMM',
    barangay_list: [
      'AGAGAN',
      'BALAGUNUN',
      'BALAWAG',
      'BALINTAO',
      'BATO-BATO',
      'BOLAO',
      'BOROCOT',
      'BORROWA',
      'BOTUD',
      'BUADIANGKAY',
      'BUBONG',
      'BUBONG BAYABAO',
      'CAMALIG',
      'CAMBONG',
      'DILAUSAN (POB.)',
      'DILIMBAYAN',
      'ILALAG',
      'KIANODAN',
      'LILOD BOROCOT',
      'LILOD MAGUING',
      'LUMBAC',
      'LUMBAC-DIMARAO',
      'MADANDING',
      'MADAYA',
      'MAGUING PROPER',
      'MALUNGUN',
      'MALUNGUN BOROCOT',
      'MALUNGUN PAGALONGAN',
      'PAGALONGAN',
      'PANAYANGAN',
      'PILIMOKNAN',
      'PINDOLONAN',
      'RAGAYAN (RUNGAYAN)',
      'SABALA DILAUSAN',
    ],
  },
  MALABANG: {
    province: 'LANAO DEL SUR',
    municipality: 'MALABANG',
    region: 'BARMM',
    barangay_list: [
      'BACAYAWAN',
      'BADAK LUMAO',
      'BAGOAINGUD',
      'BANDAY',
      'BETAYAN',
      'BONIGA',
      'BPS VILLAGE',
      'BUNK HOUSE',
      'CABASARAN (SOUTH)',
      'CALIBAGAT',
      'CALUMBOG',
      'CAMPO MUSLIM',
      'CHINA TOWN (POB.)',
      'CORAHAB',
      'DIAMARO',
      'INANDAYAN',
      'JOSE ABAD SANTOS',
      'LAMIN',
      'MABLE',
      'MACURANDING',
      'MADAYA',
      'MANANAYO',
      'MANGGAHAN',
      'MASAO',
      'MATALIN',
      'MATAMPAY',
      'MATLING',
      'MONTAY',
      'PASIR',
      'PIALOT',
      'REBOCUN',
      'SARANG',
      'SUMBAGAROGONG',
      'TACUB',
      'TAMBARA',
      'TIONGCOP',
      'TUBOC',
    ],
  },
  MARANTAO: {
    province: 'LANAO DEL SUR',
    municipality: 'MARANTAO',
    region: 'BARMM',
    barangay_list: [
      'BACAYAWAN',
      'BACONG',
      'BANGA-PANTAR',
      'BATAL-PUNUD',
      'BUBONG MADANDING (BUBONG)',
      'CAMALIG (POB.)',
      'CAMALIG BANDARA INGUD',
      'CAMALIG BUBONG',
      'CAWAYAN',
      'CAWAYAN BACOLOD',
      'CAWAYAN KALAW',
      'CAWAYAN LINUK',
      'DAANAINGUD',
      'ILIAN',
      'INUDARAN CAMPONG',
      'INUDARAN LOWAY',
      'INUDARAN LUMBAC',
      'KIALDAN',
      'LUBO',
      'LUMBAC KIALDAN',
      'MANTAPOLI',
      'MATAMPAY',
      'MAUL',
      'MAUL ILIAN',
      'MAUL LUMBACA INGUD',
      'NATARON',
      'PAGALONGAN BACAYAWAN',
      'PALAO',
      'PATAIMAS',
      'POBLACION',
      'POONA MARANTAO',
      'PUNUD PROPER',
      'TACUB',
      'TUCA KIALDAN',
    ],
  },
  'MARAWI': {
    province: 'LANAO DEL SUR',
    municipality: 'MARAWI',
    region: 'BARMM',
    barangay_list: [
      'AMBOLONG',
      'AMITO MARANTAO',
      'BACOLOD CHICO PROPER',
      'BANGA',
      'BANGCO',
      'BANGGOLO POBLACION',
      'BANGON',
      'BASAK MALUTLUT',
      'BEYABA-DAMAG',
      'BITO BUADI ITOWA',
      'BITO BUADI PARBA',
      'BOGANGA',
      'BOTO AMBOLONG',
      'BUADI SACAYO (GREEN)',
      'BUBONG LUMBAC',
      'BUBONGA CADAYONAN',
      'BUBONGA LILOD MADAYA',
      'BUBONGA MARAWI',
      'BUBONGA PAGALAMATAN',
      'BUBONGA PUNOD',
      'CABASARAN',
      'CABINGAN',
      'CADAYONAN',
      'CADAYONAN I',
      'CALOCAN EAST',
      'CALOCAN WEST',
      'DAGUDUBAN',
      'DANSALAN',
      'DATU NAGA',
      'DATU SA DANSALAN',
      'DAYAWAN',
      'DIMALUNA',
      'DULAY',
      'DULAY WEST',
      'EAST BASAK',
      'EMIE PUNUD',
      'FORT',
      'GADONGAN',
      'GADONGAN MAPANTAO',
      'GUIMBA (LILOD PROPER)',
      'KAPANTARAN',
      'KILALA',
      'KORMATAN MATAMPAY',
      'LILOD MADAYA (POB.)',
      'LILOD SADUC',
      'LOMIDONG',
      'LUMBAC MARINAUT',
      'LUMBACA MADAYA (POB.)',
      'LUMBACA TOROS',
      'MALIMONO',
      'MARAWI POBLACION',
      'MARINAUT (MARINAUT WEST)',
      'MARINAUT EAST',
      'MATAMPAY',
      'MIPAGA PROPER',
      'MONCADO COLONY',
      'MONCADO KADINGILAN',
      'MORIATAO LOKSADATO',
      'NAVARRO (DATU SABER)',
      'NORHAYA VILLAGE',
      'OLAWA AMBOLONG',
      'PAGALAMATAN GAMBAI',
      'PAGAYAWAN',
      'PANGGAO SADUC',
      'PANTAON (LANGCAF)',
      'PAPANDAYAN',
      'PAPANDAYAN CANIOGAN',
      'PARIDI',
      'PATANI',
      'PINDOLONAN',
      'POONA MARANTAO',
      'PUGAAN',
      'RAPASUN MSU',
      'RAYA MADAYA I',
      'RAYA MADAYA II',
      'RAYA SADUC',
      'ROROGAGUS EAST',
      'ROROGAGUS PROPER',
      'SABALA MANAO',
      'SABALA MANAO PROPER',
      'SADUC PROPER',
      'SAGONSONGAN',
      'SANGCAY DANSALAN',
      'SOMIORANG',
      'SOUTH MADAYA PROPER',
      'SUGOD PROPER',
      'TAMPILONG',
      'TIMBANGALAN',
      'TOLALI',
      'TONGANTONGAN-TUCA TIMBANGALAN',
      'TOROS',
      'TUCA',
      'TUCA AMBOLONG',
      'TUCA MARINAUT',
      'WAWALAYAN CALOCAN',
      'WAWALAYAN MARINAUT',
    ],
  },
  MAROGONG: {
    province: 'LANAO DEL SUR',
    municipality: 'MAROGONG',
    region: 'BARMM',
    barangay_list: [
      'BAGUMBAYAN',
      'BALUT',
      'BITAYAN',
      'BOLAWAN',
      'BONGA',
      'CABASARAN',
      'CADAYONAN',
      'CAHERA',
      'CAIRANTANG',
      'CALUMBOG',
      'CANIBONGAN',
      'DIRAGUN',
      'MANTAILOCO',
      'MAPANTAO',
      'MAROGONG EAST',
      'MAROGONG PROPER (POB.)',
      'MAYAMAN',
      'PABRICA',
      'PAIGOAY CODA',
      'PASAYANAN',
      'PIANGOLOGAN',
      'PURACAN',
      'ROMAGONDONG',
      'SARANG',
    ],
  },
  MASIU: {
    province: 'LANAO DEL SUR',
    municipality: 'MASIU',
    region: 'BARMM',
    barangay_list: [
      'ABDULLAH BUISAN',
      'ALIP LALABUAN',
      'ALUMPANG PAINO MIMBALAY',
      'BUADI AMLOY',
      'CARAMIAN ALIM RAYA',
      'DALOG BALUT',
      'GINDOLONGAN ALABAT',
      'GONDARANGIN ASA ADIGAO',
      'KALILANGAN',
      'LAILA LUMBAC BACON',
      'LAKADUN',
      'LANCO DIMAPATOY',
      'LOMIGIS SUCOD',
      'LUMBACA INGUD',
      'MACABANGAN IMBALA',
      'MACADAAG TALAGUIAN',
      'MACALUPANG LUMBAC CARAMIAN',
      'MACOMPARA APA MIMBALAY',
      'MAGAYO BAGOAINGUD',
      'MAI DITIMBANG BALINDONG',
      'MAI SINDAOLOAN DANSALAN',
      'MANALOCON TALUB',
      'MARANAT BONTALIS',
      'MATAO ARAZA',
      'MOCAMAD TANGUL',
      'MORIATAO-BAI LABAY',
      'PANTAO',
      'PUTAD MARANDANG BUISAN',
      'SAMBOWANG ATAWA',
      'SAWIR',
      'TALUB LANGI',
      'TAMBORO CORMATAN',
      'TOMAMBILING LUMBACA INGUD',
      'TOWANAO ARANGGA',
      'UNDA DAYAWAN',
    ],
  },
  MULONDO: {
    province: 'LANAO DEL SUR',
    municipality: 'MULONDO',
    region: 'BARMM',
    barangay_list: [
      'BAGOAINGUD',
      'BANGON',
      'BUADI-ABALA',
      'BUADI-BAYAWA',
      'BUADI-INSUBA',
      'BUBONG',
      'BUBONGA GUILOPA',
      'CABASARAN',
      'CAIRATAN',
      'CORMATAN',
      'DALAMA',
      'DANSALAN',
      'DIMARAO',
      'GUILOPA',
      'ILIAN',
      'KITAMBUGUN',
      'LAMA (BAGOAINGUD)',
      'LILOD',
      'LILOD RAYBALAI',
      'LUMBAC (LUMBAC BUBONG)',
      'LUMBACA INGUD',
      'MADAYA',
      'PINDOLONAN',
      'POBLACION (DADO)',
      'SALIPONGAN',
      'SUGAN',
    ],
  },
  'PAGAYAWAN (TATARIKAN)': {
    province: 'LANAO DEL SUR',
    municipality: 'PAGAYAWAN (TATARIKAN)',
    region: 'BARMM',
    barangay_list: [
      'AYONG',
      'BANDARA INGUD',
      'BANGON (POB.)',
      'BIALA-AN',
      'DIAMPACA',
      'GUIARONG',
      'ILIAN',
      'KALALUDAN',
      'LININDINGAN',
      'MADANG',
      'MAPANTAO',
      'NGINGIR (KABASARAN)',
      'PADAS',
      'PAIGOAY',
      'PINALANGCA',
      'POBLACION (LUMBAC)',
      'RANGIRAN',
      'RUBOKUN',
    ],
  },
  PIAGAPO: {
    province: 'LANAO DEL SUR',
    municipality: 'PIAGAPO',
    region: 'BARMM',
    barangay_list: [
      'APOSONG',
      'BAGOAINGUD',
      'BANGCO (POB.)',
      'BANSAYAN',
      'BASAK',
      'BOBO',
      'BUALAN',
      'BUBONG ILIAN',
      'BUBONG TAWA-AN',
      'BUBONGA MAMAANUN',
      'GACAP',
      'ILIAN',
      'ILIAN POBLACION',
      'KALANGANAN',
      'KATUMBACAN',
      'LININDING',
      'LUMBACA MAMAAN',
      'MAMAANUN',
      'MENTRING',
      'OLANGO',
      'PALACAT',
      'PALAO',
      'PALING',
      'PANTAON',
      'PANTAR',
      'PARIDI',
      'PINDOLONAN',
      'RADAPAN',
      'RADAPAN POBLACION',
      'RANTIAN',
      'SAPINGIT',
      'TALAO',
      'TAMBO',
      'TAPOCAN',
      'TAPORUG',
      'TAWAAN',
      'UDALO',
    ],
  },
  'PICONG (SULTAN GUMANDER)': {
    province: 'LANAO DEL SUR',
    municipality: 'PICONG (SULTAN GUMANDER)',
    region: 'BARMM',
    barangay_list: [
      'ANAS',
      'BARA-AS',
      'BIASONG',
      'BULANGOS',
      'DURIAN',
      'ILIAN',
      'LIANGAN (POB.)',
      'MAGANDING',
      'MALADI',
      'MAPANTAO',
      'MICALUBO',
      'MIMBALAWAG',
      'PINDOLONAN',
      'PUNONG',
      'RAMITAN',
      'TOROGAN',
      'TUAL',
      'TUCA',
      'UBANOBAN',
    ],
  },
  'POONA BAYABAO (GATA)': {
    province: 'LANAO DEL SUR',
    municipality: 'POONA BAYABAO (GATA)',
    region: 'BARMM',
    barangay_list: [
      'ATARAGADONG',
      'BANGON',
      'BANSAYAN',
      'BUALAN',
      'BUBONG-DIMUNDA',
      'BUGARAN',
      'CADAYONAN',
      'CALILANGAN DICALA',
      'CALUPAAN',
      'DILAUSAN',
      'DIMAYON',
      'DONGCOAN',
      'GADONGAN',
      'LIANGAN',
      'LUMBAC',
      'LUMBACA INGUD',
      'MADANDING',
      'PANTAO',
      'POBLACION (GATA PROPER)',
      'PUNUD',
      'RAGAYAN',
      'ROGAN CAIRAN',
      'ROGAN TANDIONG DIMAYON',
      'TALAGUIAN',
      'TAPOROG',
    ],
  },
  PUALAS: {
    province: 'LANAO DEL SUR',
    municipality: 'PUALAS',
    region: 'BARMM',
    barangay_list: [
      'BADAK',
      'BANTAYAN',
      'BASAGAD',
      'BOLINSONG',
      'BORING',
      'BUALAN',
      'DANUGAN',
      'DAPAO',
      'DIAMLA',
      'GADONGAN',
      'INGUD',
      'LINUK',
      'LUMBAC',
      'MALIGO',
      'MASAO',
      'NOTONG',
      'PORUG',
      'ROMAGONDONG',
      'TAMBO (POB.)',
      'TAMLANG',
      'TOMAROMPONG',
      'TUKA',
      'YARAN',
    ],
  },
  SAGUIARAN: {
    province: 'LANAO DEL SUR',
    municipality: 'SAGUIARAN',
    region: 'BARMM',
    barangay_list: [
      'ALINUN',
      'BAGOAINGUD',
      'BASAK MAITO',
      'BATANGAN',
      'BUBONG',
      'CADAYON',
      'CADINGILAN',
      'COMONAL',
      'DILAUSAN',
      'DILIMBAYAN',
      'GADONGAN',
      'LIMOGAO',
      'LINAO',
      'LUMBAC TOROS',
      'LUMBAYANAGUE',
      'MALIWANAG',
      'MAPANTAO',
      'MIPAGA',
      'NATANGCOPAN',
      'PAGALAMATAN',
      'PAMACOTAN',
      'PANGGAO',
      'PANTAO RAYA',
      'PANTAON',
      'PATPANGKAT',
      'PAWAK',
      'PINDOLONAN',
      'POBLACION',
      'SALOCAD',
      'SUNGCOD',
    ],
  },
  'SULTAN DUMALONDONG': {
    province: 'LANAO DEL SUR',
    municipality: 'SULTAN DUMALONDONG',
    region: 'BARMM',
    barangay_list: [
      'BACAYAWAN',
      'BUTA (SUMALINDAO)',
      'DINGANUN GUILOPA (DINGUNUN)',
      'LUMBAC',
      'MALALIS',
      'PAGALONGAN',
      'TAGORANAO',
    ],
  },
  TAMPARAN: {
    province: 'LANAO DEL SUR',
    municipality: 'TAMPARAN',
    region: 'BARMM',
    barangay_list: [
      'BALUTMADIAR',
      'BANGON',
      'BERUAR',
      'BOCALAN',
      'CABASARAN',
      'DASOMALONG',
      'DILAUSAN',
      'GINAOPAN',
      'LALABUAN',
      'LILOD TAMPARAN',
      'LILOD TUBOK',
      'LINDONGAN',
      'LINUK',
      'LINUK ORIENTAL',
      'LUMBAC',
      'LUMBACA INGUD',
      'LUMBACA LILOD',
      'LUMBACAINGUD SOUTH',
      'MAIDAN LINUK',
      'MALA-ABANGON',
      'MALIWANAG',
      'MARIATAO DATU',
      'MINANGA',
      'MIONDAS',
      'NEW LUMBACAINGUD',
      'OCCIDENTAL LINUK',
      'PAGALAMATAN LINUK',
      'PAGAYAWAN',
      'PICARABAWAN',
      'PIMBAGO-PAGALONGAN',
      'PINDOLONAN MARIATAO SARIP',
      'POBLACION I',
      'POBLACION II',
      'POBLACION III',
      'POBLACION IV',
      'RAYA BUADI BARAO',
      'RAYA NIONDAS',
      'RAYA TAMPARAN',
      'SALONGABANDING',
      'SAMINUNGGAY',
      'TALUB',
      'TATAYAWAN NORTH',
      'TATAYAWAN SOUTH',
      'TUBOK',
    ],
  },
  TARAKA: {
    province: 'LANAO DEL SUR',
    municipality: 'TARAKA',
    region: 'BARMM',
    barangay_list: [
      'BANDERA BUISAN',
      'BORIONGAN',
      'BOROWA',
      'BUADI AMAO',
      'BUADI AMUNTA',
      'BUADI AMUNUD',
      'BUADI ARORAO',
      'BUADI ATOPA',
      'BUADI DAYOMANGGA',
      'BUADI DINGUN',
      'BUADI ONGCALO',
      'BUCALAN',
      'CADAYONAN BAGUMBAYAN',
      'CARAMAT',
      'CARANDANGAN CALOPAAN',
      'DATU MA-AS',
      'DILABAYAN',
      'DIMAYON',
      'GAPAO BALINDONG',
      'ILIAN',
      'LUMASA',
      'LUMASA PROPER (SALVADOR CONCHA)',
      'LUMBAC BAGOAINGUD',
      'LUMBAC BUBONG MAINDANG',
      'LUMBAC PITAKUS',
      'MALUNGUN',
      'MANGAYAO',
      'MARUHOM',
      'MASOLUN',
      'MORIATAO LOKSA DATU',
      'PAGALAMATAN',
      'PINDOLONAN',
      'PITAKUS',
      'RIRIK',
      'SALIPONGAN',
      'SAMBOLAWAN',
      'SAMPORNA SALAMATOLLAH',
      'SIGAYAN PROPER',
      'SOMIORANG BANDINGUN',
      'SUNDING',
      'SUNGGOD',
      'SUPANGAN',
      'TUPA-AN BUADIATUPA',
    ],
  },
  TUBARAN: {
    province: 'LANAO DEL SUR',
    municipality: 'TUBARAN',
    region: 'BARMM',
    barangay_list: [
      'ALOG',
      'BAGIANGUN',
      'BETA',
      'CAMPO',
      'DATUMANONG',
      'DINAIGAN',
      'GADONGAN',
      'GAPUT',
      'GUIARONG',
      'MADAYA',
      'MALAGANDING',
      'METADICOP',
      'MINDAMUDAG',
      'PAGALAMATAN',
      'PAIGOAY-PIMBATAAN',
      'POBLACION (BURIBID)',
      'POLO',
      'RIANTARAN',
      'TANGCAL',
      'TUBARAN PROPER',
      'WAGO',
    ],
  },
  TUGAYA: {
    province: 'LANAO DEL SUR',
    municipality: 'TUGAYA',
    region: 'BARMM',
    barangay_list: [
      'BAGOAINGUD',
      'BUADI ALAWANG',
      'BUADI DICO',
      'BUBONG',
      'CAMPONG TALAO',
      'CAYAGAN',
      'DANDANUN',
      'DILIMBAYAN',
      'GURAIN',
      'LUMBAC',
      'MAIDAN',
      'MAPANTAO',
      'PAGALAMATAN',
      'PANDIARANAO',
      'PINDOLONAN I',
      'PINDOLONAN II',
      'POBLACION (INGUD)',
      'PUTAD',
      'RAYA',
      'SUGOD I',
      'SUGOD MAWATAN',
      'SUMBAGA ROGONG',
      'TANGCAL',
    ],
  },
  WAO: {
    province: 'LANAO DEL SUR',
    municipality: 'WAO',
    region: 'BARMM',
    barangay_list: [
      'AMOYONG',
      'BALATIN',
      'BANGA',
      'BO-OT',
      'BUNTONGUN',
      'CEBUANO GROUP',
      'CHRISTIAN VILLAGE',
      'EASTERN WAO',
      'EXTENSION POBLACION',
      'GATA',
      'KABATANGAN',
      'KADINGILAN',
      'KATUTUNGAN (POB.)',
      'KILIKILI EAST',
      'KILIKILI WEST',
      'MALAIGANG',
      'MANILA GROUP',
      'MILAYA',
      'MIMBUAYA',
      'MUSLIM VILLAGE',
      'PAGALONGAN',
      'PANANG',
      'PARK AREA (POB.)',
      'PILINTANGAN',
      'SERRAN VILLAGE',
      'WESTERN WAO (POB.)',
    ],
  },
  AMPATUAN: {
    province: 'MAGUINDANAO',
    municipality: 'AMPATUAN',
    region: 'BARMM',
    barangay_list: [
      'DICALONGAN (POB.)',
      'KAKAL',
      'KAMASI',
      'KAPINPILAN',
      'KAURAN',
      'MALATIMON',
      'MATAGABONG',
      'SALMAN',
      'SANIAG',
      'TOMICOR',
      'TUBAK',
    ],
  },
  BARIRA: {
    province: 'MAGUINDANAO',
    municipality: 'BARIRA',
    region: 'BARMM',
    barangay_list: [
      'BARIRA (POB.)',
      'BUALAN',
      'GADUNG',
      'KOROSOYAN',
      'LAMIN',
      'LIONG',
      'LIPA',
      'LIPAWAN',
      'MARANG',
      'MINABAY',
      'NABALAWAG',
      'PANGGAO',
      'ROMINIMBANG',
      'TOGAIG',
    ],
  },
  BULDON: {
    province: 'MAGUINDANAO',
    municipality: 'BULDON',
    region: 'BARMM',
    barangay_list: [
      'AMPUAN',
      'ARATUC',
      'CABAYUAN',
      'CALAAN (POB.)',
      'DINGANEN',
      'EDCOR (GALLEGO EDCOR)',
      'KARIM',
      'KULIMPANG',
      'MATAYA',
      'MINABAY',
      'NUYO',
      'ORING',
      'PANTAWAN',
      'PIERS',
      'RUMIDAS',
    ],
  },
  BULUAN: {
    province: 'MAGUINDANAO',
    municipality: 'BULUAN',
    region: 'BARMM',
    barangay_list: [
      'DIGAL',
      'LOWER SILING',
      'MASLABENG',
      'POBLACION',
      'POPOL',
      'TALITAY',
      'UPPER SILING',
    ],
  },
  'COTABATO': {
    province: 'MAGUINDANAO',
    municipality: 'COTABATO',
    region: 'BARMM',
    barangay_list: [
      'BAGUA',
      'BAGUA I',
      'BAGUA II',
      'BAGUA III',
      'KALANGANAN',
      'KALANGANAN I',
      'KALANGANAN II',
      'POBLACION',
      'POBLACION I',
      'POBLACION II',
      'POBLACION III',
      'POBLACION IV',
      'POBLACION IX',
      'POBLACION V',
      'POBLACION VI',
      'POBLACION VII',
      'POBLACION VIII',
      'ROSARY HEIGHTS',
      'ROSARY HEIGHTS I',
      'ROSARY HEIGHTS II',
      'ROSARY HEIGHTS III',
      'ROSARY HEIGHTS IV',
      'ROSARY HEIGHTS IX',
      'ROSARY HEIGHTS V',
      'ROSARY HEIGHTS VI',
      'ROSARY HEIGHTS VII',
      'ROSARY HEIGHTS VIII',
      'ROSARY HEIGHTS X',
      'ROSARY HEIGHTS XI',
      'ROSARY HEIGHTS XII',
      'ROSARY HEIGHTS XIII',
      'TAMONTAKA',
      'TAMONTAKA I',
      'TAMONTAKA II',
      'TAMONTAKA III',
      'TAMONTAKA IV',
      'TAMONTAKA V',
    ],
  },
  'DATU ABDULLAH SANGKI': {
    province: 'MAGUINDANAO',
    municipality: 'DATU ABDULLAH SANGKI',
    region: 'BARMM',
    barangay_list: [
      'BANABA',
      'DIMAAMPAO',
      'GUINIBON',
      'KAYA-KAYA',
      'MAGANOY',
      'MAO',
      'MARANDING',
      'SUGADOL',
      'TALISAWA',
      'TUKANOLOCONG (TUKANOLOGONG)',
    ],
  },
  'DATU ANGGAL MIDTIMBANG': {
    province: 'MAGUINDANAO',
    municipality: 'DATU ANGGAL MIDTIMBANG',
    region: 'BARMM',
    barangay_list: [
      'ADAON',
      'BRAR',
      'MAPAYAG',
      'MIDTIMBANG (POB.)',
      'NUNANGAN (NUNANGEN)',
      'TUGAL',
      'TULUNAN',
    ],
  },
  'DATU BLAH T. SINSUAT': {
    province: 'MAGUINDANAO',
    municipality: 'DATU BLAH T. SINSUAT',
    region: 'BARMM',
    barangay_list: [
      'KINIMI',
      'LAGUITAN',
      'LAPAKEN',
      'MATUBER',
      'METI',
      'NALKAN',
      'PENANSARAN',
      'PURA',
      'RESA',
      'SEDEM',
      'SINIPAK',
      'TAMBAK',
      'TUBUAN',
    ],
  },
  'DATU HOFFER AMPATUAN': {
    province: 'MAGUINDANAO',
    municipality: 'DATU HOFFER AMPATUAN',
    region: 'BARMM',
    barangay_list: [
      'KUBENTONG',
      'LABU-LABU I',
      'LABU-LABU II',
      'LIMPONGO',
      'MACALAG',
      'SAYAP',
      'TAIB',
      'TALIBADOK',
      'TUAYAN',
      'TUAYAN I',
      'TUNTUNGAN',
    ],
  },
  'DATU ODIN SINSUAT (DINAIG)': {
    province: 'MAGUINDANAO',
    municipality: 'DATU ODIN SINSUAT (DINAIG)',
    region: 'BARMM',
    barangay_list: [
      'AMBOLODTO',
      'AWANG',
      'BADAK',
      'BAGOENGED',
      'BAKA',
      'BENOLEN',
      'BITU',
      'BONGUED',
      'BUGAWAS',
      'CAPITON',
      'DADOS',
      'DALICAN POBLACION',
      'DINAIG PROPER',
      'DULANGAN',
      'KAKAR',
      'KENEBEKA',
      'KURINTEM',
      'KUSIONG',
      'LABUNGAN',
      'LINEK',
      'MAKIR',
      'MARGUES',
      'MOMPONG',
      'NEKITAN',
      'SAPALAN',
      'SEMBA',
      'SIBUTO',
      'SIFAREN (SIFARAN)',
      'TAMBAK',
      'TAMONTAKA',
      'TANUEL',
      'TAPIAN',
      'TAVIRAN',
      'TENONGGOS',
    ],
  },
  'DATU PAGLAS': {
    province: 'MAGUINDANAO',
    municipality: 'DATU PAGLAS',
    region: 'BARMM',
    barangay_list: [
      'ALIP (POB.)',
      'BONAWAN',
      'BULOD',
      'DAMALUSAY',
      'DAMAWATO',
      'DATANG',
      'ELBEBE',
      'KALUMENGA (KALUMANGA)',
      'KATIL',
      'LIPAO',
      'LOMOYON',
      'MADIDIS',
      'MAKAT',
      'MALALA',
      'MANGADEG',
      'MANINDOLO',
      'MAO',
      'NAPOK',
      'PALAO SA BUTO',
      'POBLACION',
      'PUYA',
      'SALENDAB',
      'SEPAKA',
    ],
  },
  'DATU PIANG': {
    province: 'MAGUINDANAO',
    municipality: 'DATU PIANG',
    region: 'BARMM',
    barangay_list: [
      'ALONGANAN',
      'AMBADAO',
      'BALANAKAN',
      'BALONG',
      'BUAYAN',
      'DADO',
      'DAMABALAS',
      'DUAMINANGA',
      'KALIPAPA',
      'KANGUAN',
      'LIONG',
      'MAGASLONG',
      'MASIGAY',
      'MONTAY',
      'POBLACION (DULAWAN)',
      'REINA REGENTE',
    ],
  },
  'DATU SALIBO': {
    province: 'MAGUINDANAO',
    municipality: 'DATU SALIBO',
    region: 'BARMM',
    barangay_list: [
      'ALONGANAN',
      'ANDAVIT',
      'BALANAKAN',
      'BUAYAN',
      'BUTILEN',
      'DADO',
      'DAMABALAS',
      'DUAMINANGA',
      'KALIPAPA',
      'LIONG',
      'MAGASLONG',
      'MASIGAY',
      'PAGATIN',
      'PANDI',
      'PENDITEN',
      'SAMBULAWAN',
      'TEE',
    ],
  },
  'DATU SAUDI AMPATUAN': {
    province: 'MAGUINDANAO',
    municipality: 'DATU SAUDI AMPATUAN',
    region: 'BARMM',
    barangay_list: [
      'DAPIAWAN',
      'ELIAN',
      'GAWANG',
      'KABENGI',
      'KITANGO',
      'KITAPOK',
      'MADIA',
      'SALBU',
    ],
  },
  'DATU UNSAY': {
    province: 'MAGUINDANAO',
    municipality: 'DATU UNSAY',
    region: 'BARMM',
    barangay_list: [
      'BULAYAN',
      'IGANAGAMPONG',
      'MACALAG',
      'MAITUMAIG',
      'MALANGOG',
      'META',
      'PANANGETI',
      'TUNTUNGAN',
    ],
  },
  'GEN. S. K. PENDATUN': {
    province: 'MAGUINDANAO',
    municipality: 'GEN. S. K. PENDATUN',
    region: 'BARMM',
    barangay_list: [
      'BADAK',
      'BULOD',
      'KALADTURAN',
      'KULASI',
      'LAO-LAO',
      'LASANGAN',
      'LOWER IDTIG',
      'LUMABAO',
      'MAKAINIS',
      'MIDCONDING',
      'MIDPANDACAN',
      'PANOSOLEN',
      'PIDTIGUIAN',
      'QUIPOLOT',
      'RAMCOR',
      'SADANGEN',
      'SUMAKUBAY',
      'TONGGOL',
      'UPPER LASANGAN',
    ],
  },
  GUINDULUNGAN: {
    province: 'MAGUINDANAO',
    municipality: 'GUINDULUNGAN',
    region: 'BARMM',
    barangay_list: [
      'AHAN',
      'BAGAN',
      'DATALPANDAN',
      'KALUMAMIS',
      'KATEMAN',
      'LAMBAYAO',
      'MACASAMPEN',
      'MUSLIM',
      'MUTI',
      'SAMPAO',
      'TAMBUNAN',
    ],
  },
  'KABUNTALAN (TUMBAO)': {
    province: 'MAGUINDANAO',
    municipality: 'KABUNTALAN (TUMBAO)',
    region: 'BARMM',
    barangay_list: [
      'BAGUMBAYAN',
      'BUTERIN',
      'DADTUMOG (DADTUMEG)',
      'GAMBAR',
      'GANTA',
      'KATIDTUAN',
      'LANGEBAN',
      'LIONG',
      'LOWER TAVIRAN',
      'MAITONG',
      'MATILAK',
      'PAGALUNGAN',
      'PAYAN',
      'PEDTAD',
      'PENED',
      'POBLACION',
      'UPPER TAVIRAN',
    ],
  },
  MAMASAPANO: {
    province: 'MAGUINDANAO',
    municipality: 'MAMASAPANO',
    region: 'BARMM',
    barangay_list: [
      'BAGUMBONG',
      'DABENAYAN',
      'DALADAP',
      'DASIKIL',
      'LIAB',
      'LIBUTAN',
      'LUSAY',
      'MAMASAPANO',
      'MANONGKALING',
      'PIDSANDAWAN',
      'PIMBALAKAN',
      'SAPAKAN',
      'TUKA',
      'TUKANALIPAO',
    ],
  },
  MANGUDADATU: {
    province: 'MAGUINDANAO',
    municipality: 'MANGUDADATU',
    region: 'BARMM',
    barangay_list: [
      'DALADAGAN',
      'KALIAN',
      'LUAYAN',
      'PAITAN',
      'PANAPAN',
      'TENOK',
      'TINAMBULAN',
      'TUMBAO',
    ],
  },
  MATANOG: {
    province: 'MAGUINDANAO',
    municipality: 'MATANOG',
    region: 'BARMM',
    barangay_list: [
      'BAYANGA NORTE',
      'BAYANGA SUR',
      'BUGASAN NORTE',
      'BUGASAN SUR (POB.)',
      'KIDAMA',
      'LANGCO',
      'LANGKONG',
      'SAPAD',
    ],
  },
  'NORTHERN KABUNTALAN': {
    province: 'MAGUINDANAO',
    municipality: 'NORTHERN KABUNTALAN',
    region: 'BARMM',
    barangay_list: [
      'BALONG',
      'DAMATOG',
      'GAYONGA',
      'GUIAWA',
      'INDATUAN',
      'KAPIMPILAN',
      'LIBUNGAN',
      'MONTAY',
      'PAULINO LABIO',
      'SABAKEN',
      'TUMAGUINTING',
    ],
  },
  PAGAGAWAN: {
    province: 'MAGUINDANAO',
    municipality: 'PAGAGAWAN',
    region: 'BARMM',
    barangay_list: [
      'BALATUNGKAYO (BATUNGKAYO)',
      'BULIT',
      'BULOD',
      'DUNGGUAN',
      'LIMBALUD',
      'MARIDAGAO',
      'NABUNDAS',
      'PAGAGAWAN',
      'TALAPAS',
      'TALITAY',
      'TUNGGOL',
    ],
  },
  PAGALUNGAN: {
    province: 'MAGUINDANAO',
    municipality: 'PAGALUNGAN',
    region: 'BARMM',
    barangay_list: [
      'BAGOENGED',
      'BULIOK',
      'DALGAN',
      'DAMALASAK',
      'GALAKIT',
      'INUG-UG',
      'KALBUGAN',
      'KILANGAN',
      'KUDAL',
      'LAYOG',
      'LINANDANGAN',
      'POBLACION',
    ],
  },
  PAGLAT: {
    province: 'MAGUINDANAO',
    municipality: 'PAGLAT',
    region: 'BARMM',
    barangay_list: [
      'CAMPO',
      'DAMAKLING',
      'DAMALUSAY',
      'KAKAL',
      'PAGLAT',
      'SALAM',
      'TUAL',
      'UPPER IDTIG',
    ],
  },
  PANDAG: {
    province: 'MAGUINDANAO',
    municipality: 'PANDAG',
    region: 'BARMM',
    barangay_list: [
      'KABULING',
      'KAYAGA',
      'KAYUPO (CUYAPO)',
      'LEPAK',
      "LOWER D'LAG",
      'MALANGIT',
      'PANDAG',
      "UPPER D'LAG",
    ],
  },
  PARANG: {
    province: 'SULU',
    municipality: 'PARANG',
    region: 'BARMM',
    barangay_list: [
      'ALU LAYAG-LAYAG',
      'ALU PANGKOH',
      'BAGSAK',
      'BAWISAN',
      'BIID',
      'BUKID',
      'BULI BAWANG',
      'BUTON',
      'BUTON MAHABLO',
      'DANAPA',
      'DUYAN KABAO',
      'GIMBA LAGASAN',
      'KAHA',
      'KAHOY SINAH',
      'KANAWAY',
      'KUTAH SAIRAP',
      'LAGASAN HIGAD',
      'LANAO DAKULA',
      'LAUM BUWAHAN',
      'LAUM SUWAH',
      'LIANG',
      'LINUHO',
      'LIPUNOS',
      'LOWER SAMPUNAY',
      'LUMBAAN MAHABA',
      'LUNGAN GITONG',
      'LUPA ABU',
      'NONOKAN',
      'PAUGAN',
      'PAYUHAN',
      'PIYAHAN',
      'POBLACION (PARANG)',
      'SALDANG',
      'SAMPUNAY',
      'SILANGKAN',
      'TAINGTING',
      'TIKONG',
      'TUKAY',
      'TUMANGAS',
      'WANNI PIYANJIHAN',
    ],
  },
  'RAJAH BUAYAN': {
    province: 'MAGUINDANAO',
    municipality: 'RAJAH BUAYAN',
    region: 'BARMM',
    barangay_list: [
      'BAITAL',
      'BAKAT',
      'DAPANTIS',
      'GAUNAN',
      'MALIBPOLOK',
      'MILEB',
      'PANADTABAN',
      'PIDSANDAWAN',
      'SAMPAO',
      'SAPAKAN (POB.)',
      'TABUNGAO',
    ],
  },
  'SHARIFF AGUAK (MAGANOY)': {
    province: 'MAGUINDANAO',
    municipality: 'SHARIFF AGUAK (MAGANOY)',
    region: 'BARMM',
    barangay_list: [
      'BAGONG',
      'BIALONG',
      'KULOY',
      'LABU-LABU',
      'LAPOK (LEPOK)',
      'MALINGAO',
      'POBLACION',
      'POBLACION I',
      'POBLACION II',
      'SATAN',
      'TAPIKAN',
      'TIMBANGAN',
      'TINA',
    ],
  },
  'SHARIFF SAYDONA MUSTAPHA': {
    province: 'MAGUINDANAO',
    municipality: 'SHARIFF SAYDONA MUSTAPHA',
    region: 'BARMM',
    barangay_list: [
      'BAKAT',
      'DALE-BONG',
      'DASAWAO',
      'DATU BAKAL',
      'DATU KILAY',
      'DUGUENGEN',
      'GANTA',
      'INALADAN',
      'LIBUTAN',
      'LINANTANGAN',
      'NABUNDAS',
      'PAGATIN',
      'PAGATIN (PAGATIN I)',
      'PAMALIAN',
      'PIKEG',
      'PUSAO',
    ],
  },
  'SOUTH UPI': {
    province: 'MAGUINDANAO',
    municipality: 'SOUTH UPI',
    region: 'BARMM',
    barangay_list: [
      'BIARONG',
      'BONGO',
      'ITAW',
      'KIGAN',
      'KUYA',
      'LAMUD',
      'LOOY',
      'PANDAN',
      'PILAR',
      'ROMANGAOB (POB.)',
      'SAN JOSE',
    ],
  },
  'SULTAN KUDARAT (NULING)': {
    province: 'MAGUINDANAO',
    municipality: 'SULTAN KUDARAT (NULING)',
    region: 'BARMM',
    barangay_list: [
      'ALAMADA',
      'BANATIN',
      'BANUBO',
      'BULALO',
      'BULIBOD',
      'CALSADA',
      'CROSSING SIMUAY',
      'DALUMANGCOB (POB.)',
      'DAMANIOG',
      'DARAPANAN',
      'GANG',
      'INAWAN',
      'KABUNTALAN',
      'KAKAR',
      'KAPIMPILAN',
      'KATAMLANGAN (MATAMPAY)',
      'KATIDTUAN',
      'KATULI',
      'LADIA',
      'LIMBO',
      'MAIDAPA',
      'MAKAGUILING',
      'MATENGEN',
      'MULAUG',
      'NALINAN',
      'NARA',
      'NEKITAN',
      'OLAS',
      'PANATAN',
      'PIGCALAGAN',
      'PIGKELEGAN (IBOTEGEN)',
      'PINARING',
      'PINGPING',
      'RAGUISI',
      'REBUKEN',
      'SALIMBAO',
      'SAMBOLAWAN',
      'SENDITAN',
      'UNGAP',
    ],
  },
  'SULTAN MASTURA': {
    province: 'MAGUINDANAO',
    municipality: 'SULTAN MASTURA',
    region: 'BARMM',
    barangay_list: [
      'BALUT',
      'BOLIOK',
      'BUNGABONG',
      'DAGURONGAN',
      'KIRKIR',
      'MACABICO (MACABISO)',
      'NAMUKEN',
      'SIMUAY/SEASHORE',
      'SOLON',
      'TAMBO',
      'TAPAYAN',
      'TARIKEN',
      'TUKA',
    ],
  },
  'SULTAN SA BARONGIS (LAMBAYONG)': {
    province: 'MAGUINDANAO',
    municipality: 'SULTAN SA BARONGIS (LAMBAYONG)',
    region: 'BARMM',
    barangay_list: [
      'ANGKAYAMAT',
      'BARURAO',
      'BULOD',
      'DARAMPUA',
      'GADUNGAN',
      'KULAMBOG',
      'LANGGAPANAN',
      'MASULOT',
      'PALDONG',
      'PAPAKAN',
      'TUGAL',
      'TUKANAKUDEN',
    ],
  },
  TALAYAN: {
    province: 'MAGUINDANAO',
    municipality: 'TALAYAN',
    region: 'BARMM',
    barangay_list: [
      'BINANGGA NORTH',
      'BINANGGA SOUTH',
      'BOBOGUIRON',
      'DAMABLAC',
      'FUGOTAN',
      'FUKOL',
      'KATIBPUAN',
      'KEDATI',
      'LANTING',
      'LINAMUNAN',
      'MARADER',
      'TALAYAN',
      'TAMAR',
      'TAMBUNAN I',
      'TIMBALUAN',
    ],
  },
  TALITAY: {
    province: 'MAGUINDANAO',
    municipality: 'TALITAY',
    region: 'BARMM',
    barangay_list: [
      'BINTAN (BENTAN)',
      'GADUNGAN',
      'KILADAP',
      'KILALAN',
      'KUDEN',
      'MAKADAYON',
      'MANGGAY',
      'PAGEDA',
      'TALITAY',
    ],
  },
  UPI: {
    province: 'MAGUINDANAO',
    municipality: 'UPI',
    region: 'BARMM',
    barangay_list: [
      'BANTEK',
      'BAYABAS',
      'BLENSONG',
      'BORONGOTAN',
      'BUGABUNGAN',
      'BUNGCOG',
      'DARUGAO',
      'GANASI',
      'KABAKABA',
      'KIBLEG',
      'KIBUCAY',
      'KIGA',
      'KINITAN (KINITAAN)',
      'MIRAB',
      'NANGI',
      'NURO POBLACION',
      'RANAO PILAYAN',
      'REMPES',
      'RENEDE',
      'RENTI',
      'RIFAO',
      'SEFEGEFEN',
      'TINUNGKAAN',
    ],
  },
  'HADJI PANGLIMA TAHIL (MARUNGGAS)': {
    province: 'SULU',
    municipality: 'HADJI PANGLIMA TAHIL (MARUNGGAS)',
    region: 'BARMM',
    barangay_list: [
      'BANGAS (POB.)',
      'BUBUAN',
      'KABUUKAN',
      'PAG-ASINAN',
      'TEOMABAL',
    ],
  },
  INDANAN: {
    province: 'SULU',
    municipality: 'INDANAN',
    region: 'BARMM',
    barangay_list: [
      'ADJID',
      'BANGALAN',
      'BATO-BATO',
      'BUANZA',
      'BUD-TARAN',
      'BUNUT',
      'JATI-TUNGGAL',
      'KABBON MAAS',
      'KAGAY',
      'KAJATIAN',
      'KAN ISLAM',
      'KANDANG TUKAY',
      'KARAWAN',
      'KATIAN',
      'KUPPONG',
      'LAMBAYONG',
      'LANGPAS',
      'LICUP',
      'MALIMBAYA',
      'MANGGIS',
      'MANILOP',
      'PALIGUE',
      'PANABUAN',
      'PANGLIMA MISUARI (SASAK)',
      'PASIL',
      'POBLACION (INDANAN)',
      'SAPAH MALAUM',
      'SAWAKI',
      'SIONOGAN',
      'TAGBAK',
      'TIMBANGAN',
      'TUBIG DAKULAH',
      'TUBIG PARANG',
      'TUMANTANGIS',
    ],
  },
  JOLO: {
    province: 'SULU',
    municipality: 'JOLO',
    region: 'BARMM',
    barangay_list: [
      'ALAT',
      'ASTURIAS',
      'BUS-BUS',
      'CHINESE PIER',
      'SAN RAYMUNDO',
      'TAKUT-TAKUT',
      'TULAY',
      'WALLED CITY (POB.)',
    ],
  },
  'KALINGALAN CALUANG': {
    province: 'SULU',
    municipality: 'KALINGALAN CALUANG',
    region: 'BARMM',
    barangay_list: [
      'KAMBING',
      'KANLAGAY',
      'KARUNGDONG (POB.)',
      'MASJID BAYLE',
      'MASJID PUNJUNGAN',
      'PANG',
      'PANGDAN PANGDAN',
      'PITOGO',
      'TUNGGOL',
    ],
  },
  LUGUS: {
    province: 'SULU',
    municipality: 'LUGUS',
    region: 'BARMM',
    barangay_list: [
      'ALU BUS-BUS',
      'ALU-DUYONG',
      'BAS LUGUS',
      'GAPAS RUGASAN',
      'GAPAS TUBIG TUWAK',
      'HUWIT-HUWIT BAS NONOK',
      'HUWIT-HUWIT PROPER',
      'KUTAH PARANG',
      'LAHA',
      'LARAP',
      'LUGUS PROPER',
      'MANGKALLAY',
      'MANTAN',
      'PAIT',
      'PARIAN KAYAWAN',
      'SIBUL',
      'TINGKANGAN',
    ],
  },
  LUUK: {
    province: 'SULU',
    municipality: 'LUUK',
    region: 'BARMM',
    barangay_list: [
      'BUAL',
      'GUIMBAUN',
      'KAN-BULAK',
      'KAN-MINDUS',
      'LAMBAGO',
      'LIANUTAN',
      'LINGAH',
      'MANANTI',
      'NIOG-NIOG',
      'TANDU-BATO',
      'TUBIG-PUTI (POB.)',
      'TULAYAN ISLAND',
    ],
  },
  MAIMBUNG: {
    province: 'SULU',
    municipality: 'MAIMBUNG',
    region: 'BARMM',
    barangay_list: [
      'ANAK JATI',
      'BATO UGIS',
      'BUALO LAHI',
      'BUALO LIPID',
      'BULABOG',
      'DUHOL KABBON',
      'GULANGAN',
      'IPIL',
      'KANDANG',
      'KAPOK-PUNGGOL',
      'KULASI',
      'LABAH',
      'LAGASAN ASIBIH',
      'LANTONG',
      'LAPA',
      'LAUD KULASI',
      'LAUM MAIMBUNG',
      'LOWER TAMBAKING',
      'LUNGGANG',
      'MATATAL',
      'PATAO',
      'POBLACION (MAIMBUNG)',
      'RATAG LIMBON',
      'TABU-BATO',
      'TANDU PATONG',
      'TUBIG-SAMIN',
      'UPPER TAMBAKING',
    ],
  },
  'OLD PANAMAO': {
    province: 'SULU',
    municipality: 'OLD PANAMAO',
    region: 'BARMM',
    barangay_list: [
      'ASIN',
      'BAKUD',
      'BANGDAY',
      'BAUNOH',
      'BITANAG',
      'BUD SEIT',
      'BULANGSI',
      'DATAG',
      'KAMALIG',
      'KAN ASAALI',
      'KAN UKOL',
      'KAN-DAYOK',
      'KAN-SIPAT',
      'KAWASAN',
      'KULAY-KULAY',
      'LAKIT',
      'LOWER PATIBULAN',
      'LUNGGANG',
      'PARANG',
      'PUGAD MANAUL',
      'PUHAGAN',
      'SEIT HIGAD',
      'SEIT LAKE (POB.)',
      'SU-UH',
      'TABU MANUK',
      'TANDU-TANDU',
      'TAYUNGAN',
      'TINAH',
      'TUBIG GANTANG',
      'TUBIG JATI',
      'UPPER PATIBULAN',
    ],
  },
  OMAR: {
    province: 'SULU',
    municipality: 'OMAR',
    region: 'BARMM',
    barangay_list: [
      'ANDALAN',
      'ANGILAN',
      'CAPUAL ISLAND',
      'HUWIT-HUWIT',
      'LAHING-LAHING',
      'NIANGKAAN',
      'SUCUBAN',
      'TANGKUAN',
    ],
  },
  PANDAMI: {
    province: 'SULU',
    municipality: 'PANDAMI',
    region: 'BARMM',
    barangay_list: [
      'BALIGTANG',
      'BUD SIBAUD',
      'HAMBILAN',
      'KABBON',
      'LAHI',
      'LAPAK',
      'LAUD SIBAUD',
      'MALANTA',
      'MAMANOK',
      'NORTH MANUBUL',
      'PARIAN DAKULA',
      'SIBAUD PROPER',
      'SIGANGGANG',
      'SOUTH MANUBUL',
      'SUBA-SUBA',
      'TENGA MANUBUL',
    ],
  },
  'PANGLIMA ESTINO (NEW PANAMAO)': {
    province: 'SULU',
    municipality: 'PANGLIMA ESTINO (NEW PANAMAO)',
    region: 'BARMM',
    barangay_list: [
      'GAGGUIL',
      'GATA-GATA',
      'JINGGAN',
      'KAMIH-PUNGUD',
      'LIHBUG KABAW',
      'LIKBAH',
      'LUBUK-LUBUK',
      'MARSADA',
      'PAIKSA',
      'PANDAKAN',
      'PUNAY (POB.)',
      'TIPTIPON',
    ],
  },
  PANGUTARAN: {
    province: 'SULU',
    municipality: 'PANGUTARAN',
    region: 'BARMM',
    barangay_list: [
      'ALU BUNAH',
      'BANGKILAY',
      'KAWITAN',
      'KEHI NIOG',
      'LANTONG BABAG',
      'LUMAH DAPDAP',
      'PANDAN NIOG',
      'PANDUCAN',
      'PANITIKAN',
      'PATUTOL',
      'SE-IPANG',
      'SIMBAHAN (POB.)',
      'SUANG BUNAH',
      'TONGGASANG',
      'TUBIG NONOK',
      'TUBIG SALLANG',
    ],
  },
  PATA: {
    province: 'SULU',
    municipality: 'PATA',
    region: 'BARMM',
    barangay_list: [
      'ANDALAN',
      'DAUNGDONG',
      'KAMAWI',
      'KANJARANG',
      'KAYAWAN (POB.)',
      'KIPUT',
      'LIKUD',
      'LUUK-TULAY',
      'NIOG-NIOG',
      'PATIAN',
      'PISAK-PISAK',
      'SAIMBANGON',
      'SANGKAP',
      'TIMUDDAS',
    ],
  },
  PATIKUL: {
    province: 'SULU',
    municipality: 'PATIKUL',
    region: 'BARMM',
    barangay_list: [
      'ANULING',
      'BAKONG',
      'BANGKAL',
      'BONBON',
      'BUHANGINAN (DARAYAN)',
      'BUNGKAUNG',
      'DANAG',
      'GANDASULI',
      'IGASAN',
      'KABBON TAKAS',
      'KADDAY MAMPALLAM',
      'KAN AGUE',
      'KAUNAYAN',
      'LANGHUB',
      'LATIH',
      'LIANG',
      'MALIGAY',
      'MAUBOH',
      'PANGDANON',
      'PANGLAYAHAN',
      'PANSUL',
      'PATIKUL HIGAD',
      'SANDAH',
      'TAGLIBI (POB.)',
      'TANDU-BAGUA',
      'TANUM',
      'TAUNG',
      'TIMPOK',
      'TUGAS',
      'UMANGAY',
    ],
  },
  SIASI: {
    province: 'SULU',
    municipality: 'SIASI',
    region: 'BARMM',
    barangay_list: [
      'BAKUD',
      'BUAN',
      'BULANSING TARA',
      'BULIHKULLUL',
      'CAMPO ISLAM',
      'DUGGO',
      'DUHOL TARA',
      'EAST KUNGTAD',
      'EAST SISANGAT',
      'IPIL',
      'JAMBANGAN',
      'KABUBU',
      'KONG-KONG LAMINUSA',
      'KUD-KUD',
      'KUNGTAD WEST',
      'LATUNG',
      'LUUK LAMINUSA',
      'LUUK TARA',
      'MANTA',
      'MINAPAN',
      'NIPA-NIPA',
      'NORTH LAUD',
      'NORTH MANTA',
      'NORTH MUSU LAUD',
      'NORTH SILUMPAK',
      'PISLONG',
      'POBLACION (CAMPO BARO)',
      'PUNUNGAN',
      'PUUKAN LAMINUSA',
      'RATAG',
      'SABLAY',
      'SARUKOT',
      'SIBURI',
      'SINGKO',
      'SIOLAKAN',
      'SIOWING',
      'SIPANDING',
      'SISANGAT',
      'SIUNDOH',
      'SOUTH MUSU LAUD',
      'SOUTH SILUMPAK',
      'SOUTHWESTERN BULIKULLUL',
      'SUBAH BUAYA',
      'TAMPAKAN LAMINUSA',
      'TENGAH LAMINUSA',
      'TONG LAMINUSA',
      'TONG-TONG',
      'TONGLABAH',
      'TUBIG KUTAH',
      'TULLING',
    ],
  },
  TALIPAO: {
    province: 'SULU',
    municipality: 'TALIPAO',
    region: 'BARMM',
    barangay_list: [
      'ANDALAN',
      'BAGSAK',
      'BANDANG',
      'BILAAN (POB.)',
      'BUD BUNGA',
      'BUNTOD',
      'BUROH',
      'DALIH',
      'GATA',
      'KABATUHAN BILAAN',
      'KABATUHAN TIIS',
      'KABUNGKOL',
      'KAGAY',
      'KAHAWA',
      'KANDAGA',
      'KANLIBOT',
      'KIUTAAN',
      'KUHAW',
      'KULAMBOH',
      'KUTTONG',
      'LAGTOH',
      'LAMBANAH',
      'LIBAN',
      'LIU-BUD PANTAO',
      'LOWER BINUANG',
      'LOWER KAMUNTAYAN',
      'LOWER LAUS',
      'LOWER SINUMAAN',
      'LOWER TALIPAO',
      'LUMBAYAO',
      'LUMPING PIGIH DAHO',
      'LUNGKIABAN',
      'MABAHAY',
      'MAHALA',
      'MAMPALLAM',
      'MARSADA',
      'MAUBOH',
      'MUNGIT-MUNGIT',
      'NIOG-SANGAHAN',
      'PANTAO',
      'SAMAK',
      'TALIPAO PROPER',
      'TAMPAKAN',
      'TIIS',
      'TINGGAH',
      'TUBOD',
      'TUYANG',
      'UPPER BINUANG',
      'UPPER KAMUNTAYAN',
      'UPPER LAUS',
      'UPPER SINUMAAN',
      'UPPER TALIPAO',
    ],
  },
  TAPUL: {
    province: 'SULU',
    municipality: 'TAPUL',
    region: 'BARMM',
    barangay_list: [
      'ALU-KABINGAAN',
      'BANTING',
      'HAWAN',
      'KALANG (POB.)',
      'KAMAUNGGI',
      'KANAWAY',
      'KANMANGON',
      'KAUMPANG',
      'PAGATPAT',
      'PANGDAN',
      'PUOK',
      'SAYLI',
      'SUMAMBAT',
      'TANGKAPAAN',
      'TULAKAN',
    ],
  },
  TONGKIL: {
    province: 'SULU',
    municipality: 'TONGKIL',
    region: 'BARMM',
    barangay_list: [
      'BAKKAAN',
      'BANGALAW',
      'DANAO',
      'DUNGON',
      'KAHIKUKUK',
      'LUUK (POB.)',
      'NORTH PAAROL',
      'SIGUMBAL',
      'SOUTH PAAROL',
      'TABIALAN',
      'TAINGA-BAKKAO',
      'TAMBUN-BUN',
      'TATTALAN',
      'TINUTUNGAN',
    ],
  },
  BONGAO: {
    province: 'TAWI-TAWI',
    municipality: 'BONGAO',
    region: 'BARMM',
    barangay_list: [
      'BONGAO POBLACION',
      'IPIL',
      'KAMAGONG',
      'KARUNGDONG',
      'LAGASAN',
      'LAKIT LAKIT',
      'LAMION',
      'LAPID LAPID',
      'LATO LATO',
      'LUUK PANDAN',
      'LUUK TULAY',
      'MALASSA',
      'MANDULAN',
      'MASANTONG',
      'MONTAY MONTAY',
      'NALIL',
      'PABABAG',
      'PAG-ASA',
      'PAGASINAN',
      'PAGATPAT',
      'PAHUT',
      'PAKIAS',
      'PANIONGAN',
      'PASIAGAN',
      'SANGA-SANGA',
      'SILUBOG',
      'SIMANDAGIT',
      'SUMANGAT',
      'TARAWAKAN',
      'TONGSINAH',
      'TUBIG BASAG',
      'TUBIG TANAH',
      'TUBIG-BOH',
      'TUBIG-MAMPALLAM',
      'UNGUS-UNGUS',
    ],
  },
  LANGUYAN: {
    province: 'TAWI-TAWI',
    municipality: 'LANGUYAN',
    region: 'BARMM',
    barangay_list: [
      'ADNIN',
      'BAKAW-BAKAW',
      'BAKONG',
      'BAS-BAS PROPER',
      'BASLIKUD',
      'BASNUNUK',
      'DARUSSALAM',
      'JAKARTA (LOOKAN LATUAN)',
      'KALUPAG',
      'KINIKTAL',
      'LANGUYAN PROPER (POB.)',
      'MARANG-MARANG',
      'MARANING',
      'PARANG PANTAY',
      'SIKULLIS',
      'SIMALAK',
      'TUBIG DAKULA',
      'TUHOG-TUHOG',
      'TUMAHUBONG',
      'TUMBAGAAN',
    ],
  },
  'MAPUN (CAGAYAN DE TAWI-TAWI)': {
    province: 'TAWI-TAWI',
    municipality: 'MAPUN (CAGAYAN DE TAWI-TAWI)',
    region: 'BARMM',
    barangay_list: [
      'BOKI',
      'DUHUL BATU',
      'EROK-EROK',
      'GUPPAH',
      'KOMPANG',
      'LIYUBUD',
      'LUBBAK PARANG',
      'LUPA PULA (POB.)',
      'MAHALO',
      'PAWAN',
      'SAPA',
      'SIKUB',
      'TABULIAN',
      'TANDUAN',
      'UMUS MATAHA',
    ],
  },
  'PANGLIMA SUGALA (BALIMBING)': {
    province: 'TAWI-TAWI',
    municipality: 'PANGLIMA SUGALA (BALIMBING)',
    region: 'BARMM',
    barangay_list: [
      'BALIMBING PROPER',
      'BATU-BATU (POB.)',
      'BAUNO GARING',
      'BELATAN HALU',
      'BUAN',
      'DUNGON',
      'KARAHA',
      'KULAPE',
      'LIYABURAN',
      'LUUK BUNTAL',
      'MAGSAGGAW',
      'MALACCA',
      'PARANGAN',
      'SUMANGDAY',
      'TABUNAN',
      'TUNDON',
      'TUNGBANGKAW',
    ],
  },
  'SAPA-SAPA': {
    province: 'TAWI-TAWI',
    municipality: 'SAPA-SAPA',
    region: 'BARMM',
    barangay_list: [
      'BALDATAL ISLAM',
      'BUTUN',
      'DALO-DALO',
      'LAKIT-LAKIT',
      'LATUAN (SUASANG)',
      'LOOK NATUH',
      'LOOKAN BANARAN',
      'LOOKAN LATUAN',
      'MALANTA',
      'MANTABUAN TABUNAN',
      'NUNUK LIKUD SIKUBONG',
      'PALATE GADJAMINAH',
      'PAMASAN',
      'SAPA-SAPA (POB.)',
      'SAPAAT',
      'SUKAH-SUKAH',
      'TABUNAN LIKUD SIKUBONG',
      'TANGNGAH (LALUNG SIKUBONG)',
      'TAPIAN BOHE NORTH',
      'TAPIAN BOHE SOUTH',
      'TONGGUSONG BANARAN',
      'TUP-TUP BANARAN',
    ],
  },
  SIBUTU: {
    province: 'TAWI-TAWI',
    municipality: 'SIBUTU',
    region: 'BARMM',
    barangay_list: [
      'AMBULONG SAPAL',
      'DATU AMILHAMJA JAAFAR',
      'HADJI IMAM BIDIN',
      'HADJI MOHTAR SULAYMAN',
      'HADJI TAHA',
      'IMAM HADJI MOHAMMAD',
      'LIGAYAN',
      'NUNUKAN',
      'SHEIK MAKDUM',
      'SIBUTU (POB.)',
      'TALISAY',
      'TANDU BANAK',
      'TAUNGOH',
      'TONGEHAT',
      'TONGSIBALO',
      'UNGUS-UNGUS',
    ],
  },
  SIMUNUL: {
    province: 'TAWI-TAWI',
    municipality: 'SIMUNUL',
    region: 'BARMM',
    barangay_list: [
      'BAGID*',
      'BAKONG',
      'DOH-TONG',
      'LUUK DATAN',
      'MANUK MANGKAW',
      'MARUWA',
      'MONGKAY',
      'PAGASINAN',
      'PANGLIMA MASTUL',
      'SUKAH-BULAN',
      'TAMPAKAN (POB.)',
      'TIMUNDON',
      'TONGGOSONG',
      'TUBIG INDANGAN',
      'UBOL',
    ],
  },
  SITANGKAI: {
    province: 'TAWI-TAWI',
    municipality: 'SITANGKAI',
    region: 'BARMM',
    barangay_list: [
      'DATU BAGUINDA PUTIH',
      'IMAM SAPIE',
      'NORTH LARAP',
      'PANGLIMA ALARI',
      'SIPANGKOT',
      'SITANGKAI POBLACION',
      'SOUTH LARAP (LARAP)',
      'TONGMAGENG',
      'TONGUSONG',
    ],
  },
  'SOUTH UBIAN': {
    province: 'TAWI-TAWI',
    municipality: 'SOUTH UBIAN',
    region: 'BARMM',
    barangay_list: [
      'BABAGAN',
      'BENGKOL',
      'BINTAWLAN',
      'BOHE',
      'BUBUAN',
      'BUNAY BUNAY CENTER',
      'BUNAY BUNAY LOOKAN',
      'BUNAY BUNAY TONG',
      'EAST TALISAY',
      'LAHAD DAMPONG',
      'LAITAN',
      'LAMBI-LAMBIAN',
      'LAUD',
      'LIKUD DAMPONG',
      'LIKUD TABAWAN',
      'NUNUK',
      'NUSA',
      'NUSA-NUSA',
      'PAMPANG',
      'PUTAT',
      'SOLLOGAN',
      'TALISAY',
      'TAMPAKAN DAMPONG',
      'TANGNGAH',
      'TINDA-TINDAHAN',
      'TONG TAMPAKAN',
      'TUBIG DAYANG',
      'TUBIG DAYANG CENTER',
      'TUBIG DAYANG RIVERSIDE',
      'TUKKAI',
      'UNAS-UNAS',
    ],
  },
  TANDUBAS: {
    province: 'TAWI-TAWI',
    municipality: 'TANDUBAS',
    region: 'BARMM',
    barangay_list: [
      'BALIUNGAN',
      'BALLAK',
      'BUTUN',
      'HIMBAH',
      'KAKOONG',
      'KALANG-KALANG',
      'KEPENG',
      'LAHAY-LAHAY',
      'NAUNGAN',
      'SALAMAT',
      'SALLANGAN',
      'SAPA',
      'SIBAKLOON',
      'SILANTUP',
      'TANDUBATO',
      'TANGNGAH (TANGNGAH UNGUS MATATA)',
      'TAPIAN',
      'TAPIAN SUKAH',
      'TARUK',
      'TONGBANGKAW',
    ],
  },
  'TURTLE ISLANDS': {
    province: 'TAWI-TAWI',
    municipality: 'TURTLE ISLANDS',
    region: 'BARMM',
    barangay_list: ['LIKUD BAKKAO', 'TAGANAK POBLACION'],
  },
  BANGUED: {
    province: 'ABRA',
    municipality: 'BANGUED',
    region: 'CAR',
    barangay_list: [
      'AGTANGAO',
      'ANGAD',
      'BANGBANGAR',
      'BAÑACAO',
      'CABULOAN',
      'CALABA',
      'COSILI EAST (PROPER)',
      'COSILI WEST (BUAYA)',
      'DANGDANGLA',
      'LINGTAN',
      'LIPCAN',
      'LUBONG',
      'MACARCARMAY',
      'MACRAY',
      'MALITA',
      'MAOAY',
      'PALAO',
      'PATUCANNAY',
      'SAGAP',
      'SAN ANTONIO',
      'SANTA ROSA',
      'SAO-ATAN',
      'SAPPAAC',
      'TABLAC (CALOT)',
      'ZONE 1 POB. (NALASIN)',
      'ZONE 2 POB. (CONSILIMAN)',
      'ZONE 3 POB. (LALAUD)',
      'ZONE 4 POB. (TOWN PROPER)',
      'ZONE 5 POB. (BO. BARIKIR)',
      'ZONE 6 POB. (SINAPANGAN)',
      'ZONE 7 POB. (BALILING)',
    ],
  },
  BOLINEY: {
    province: 'ABRA',
    municipality: 'BOLINEY',
    region: 'CAR',
    barangay_list: [
      'AMTI',
      'BAO-YAN',
      'DANAC EAST',
      'DANAC WEST',
      'DAO-ANGAN',
      'DUMAGAS',
      'KILONG-OLAO',
      'POBLACION (BOLINEY)',
    ],
  },
  BUCAY: {
    province: 'ABRA',
    municipality: 'BUCAY',
    region: 'CAR',
    barangay_list: [
      'ABANG',
      'BANGBANGCAG',
      'BANGCAGAN',
      'BANGLOLAO',
      'BUGBOG',
      'CALAO',
      'DUGONG',
      'LABON',
      'LAYUGAN',
      'MADALIPAY',
      'NORTH POBLACION',
      'PAGALA',
      'PAKILING',
      'PALAQUIO',
      'PATOC',
      'QUIMLOONG',
      'SALNEC',
      'SAN MIGUEL',
      'SIBLONG',
      'SOUTH POBLACION',
      'TABIOG',
    ],
  },
  BUCLOC: {
    province: 'ABRA',
    municipality: 'BUCLOC',
    region: 'CAR',
    barangay_list: ['DUCLIGAN', 'LABAAN', 'LAMAO (POB.)', 'LINGAY'],
  },
  DAGUIOMAN: {
    province: 'ABRA',
    municipality: 'DAGUIOMAN',
    region: 'CAR',
    barangay_list: ['ABLEG', 'CABARUYAN', 'PIKEK', 'TUI (POB.)'],
  },
  DANGLAS: {
    province: 'ABRA',
    municipality: 'DANGLAS',
    region: 'CAR',
    barangay_list: [
      'ABAQUID',
      'CABARUAN',
      'CAUPASAN (POB.)',
      'DANGLAS',
      'NAGAPARAN',
      'PADANGITAN',
      'PANGAL',
    ],
  },
  LACUB: {
    province: 'ABRA',
    municipality: 'LACUB',
    region: 'CAR',
    barangay_list: [
      'BACAG',
      'BUNEG',
      'GUINGUINABANG',
      'LAN-AG',
      'PACOC',
      'POBLACION (TALAMPAC)',
    ],
  },
  LAGANGILANG: {
    province: 'ABRA',
    municipality: 'LAGANGILANG',
    region: 'CAR',
    barangay_list: [
      'AGUET',
      'BACOOC',
      'BALAIS',
      'CAYAPA',
      'DALAGUISEN',
      'LAANG',
      'LAGBEN',
      'LAGUIBEN',
      'NAGTIPULAN',
      'NAGTUPACAN',
      'PAGANAO',
      'PAWA',
      'POBLACION',
      'PRESENTAR',
      'SAN ISIDRO',
      'TAGODTOD',
      'TAPING',
    ],
  },
  LAGAYAN: {
    province: 'ABRA',
    municipality: 'LAGAYAN',
    region: 'CAR',
    barangay_list: ['BA-I', 'COLLAGO', 'PANG-OT', 'POBLACION', 'PULOT'],
  },
  LANGIDEN: {
    province: 'ABRA',
    municipality: 'LANGIDEN',
    region: 'CAR',
    barangay_list: [
      'BAAC',
      'DALAYAP (NALAAS)',
      'MABUNGTOT',
      'MALAPAAO',
      'POBLACION',
      'QUILLAT',
    ],
  },
  'LICUAN-BAAY (LICUAN)': {
    province: 'ABRA',
    municipality: 'LICUAN-BAAY (LICUAN)',
    region: 'CAR',
    barangay_list: [
      'BONGLO (PATAGUI)',
      'BULBULALA',
      'CAWAYAN',
      'DOMENGLAY',
      'LENNENG',
      'MAPISLA',
      'MOGAO',
      'NALBUAN',
      'POBLACION',
      'SUBAGAN',
      'TUMALIP',
    ],
  },
  LUBA: {
    province: 'ABRA',
    municipality: 'LUBA',
    region: 'CAR',
    barangay_list: [
      'AMPALIOC',
      'BARIT',
      'GAYAMAN',
      'LUL-LUNO',
      'LUZONG',
      'NAGBUKEL-TUQUIPA',
      'POBLACION',
      'SABNANGAN',
    ],
  },
  MALIBCONG: {
    province: 'ABRA',
    municipality: 'MALIBCONG',
    region: 'CAR',
    barangay_list: [
      'BAYABAS',
      'BINASARAN',
      'BUANAO',
      'DULAO',
      'DULDULAO',
      'GACAB',
      'LAT-EY',
      'MALIBCONG (POB.)',
      'MATARAGAN',
      'PACGUED',
      'TARIPAN',
      'UMNAP',
    ],
  },
  MANABO: {
    province: 'ABRA',
    municipality: 'MANABO',
    region: 'CAR',
    barangay_list: [
      'AYYENG (POB.)',
      'CATACDEGAN NUEVO',
      'CATACDEGAN VIEJO',
      'LUZONG',
      'SAN JOSE NORTE',
      'SAN JOSE SUR',
      'SAN JUAN NORTE',
      'SAN JUAN SUR',
      'SAN RAMON EAST',
      'SAN RAMON WEST',
      'SANTO TOMAS',
    ],
  },
  PEÑARRUBIA: {
    province: 'ABRA',
    municipality: 'PEÑARRUBIA',
    region: 'CAR',
    barangay_list: [
      'DUMAYCO',
      'LUSUAC',
      'MALAMSIT (PAU-MALAMSIT)',
      'NAMARABAR',
      'PATIAO',
      'POBLACION',
      'RIANG (TIANG)',
      'SANTA ROSA',
      'TATTAWA',
    ],
  },
  PIDIGAN: {
    province: 'ABRA',
    municipality: 'PIDIGAN',
    region: 'CAR',
    barangay_list: [
      'ALINAYA',
      'ARAB',
      'GARRETA',
      'IMMULI',
      'LASKIG',
      'MONGGOC',
      'NAGUIRAYAN',
      'PAMUTIC',
      'PANGTUD',
      'POBLACION EAST',
      'POBLACION WEST',
      'SAN DIEGO',
      'SULBEC',
      'SUYO (MALIDONG)',
      'YUYENG',
    ],
  },
  SALLAPADAN: {
    province: 'ABRA',
    municipality: 'SALLAPADAN',
    region: 'CAR',
    barangay_list: [
      'BAZAR',
      'BILABILA',
      'GANGAL (POB.)',
      'MAGUYEPYEP',
      'NAGUILIAN',
      'SACCAANG',
      'SALLAPADAN',
      'SUBUSOB',
      'UD-UDIAO',
    ],
  },
  TAYUM: {
    province: 'ABRA',
    municipality: 'TAYUM',
    region: 'CAR',
    barangay_list: [
      'BAGALAY',
      'BASBASA',
      'BUDAC',
      'BUMAGCAT',
      'CABAROAN',
      'DEET',
      'GADDANI',
      'PATUCANNAY',
      'PIAS',
      'POBLACION',
      'VELASCO',
    ],
  },
  TINEG: {
    province: 'ABRA',
    municipality: 'TINEG',
    region: 'CAR',
    barangay_list: [
      'ALAOA',
      'ANAYAN',
      'APAO',
      'BELAAT',
      'CAGANAYAN',
      'COGON',
      'LANEC',
      'LAPAT-BALANTAY',
      'NAGLIBACAN',
      'POBLACION (AGSIMAO)',
    ],
  },
  TUBO: {
    province: 'ABRA',
    municipality: 'TUBO',
    region: 'CAR',
    barangay_list: [
      'ALANGTIN',
      'AMTUAGAN',
      'DILONG',
      'KILI',
      'POBLACION (MAYABO)',
      'SUPO',
      'TABACDA',
      'TIEMPO',
      'TUBTUBA',
      'WAYANGAN',
    ],
  },
  VILLAVICIOSA: {
    province: 'ABRA',
    municipality: 'VILLAVICIOSA',
    region: 'CAR',
    barangay_list: [
      'AP-APAYA',
      'BOL-LILISING',
      'CAL-LAO',
      'LAP-LAPOG',
      'LUMABA',
      'POBLACION',
      'TAMAC',
      'TUQUIB',
    ],
  },
  'CALANASAN (BAYAG)': {
    province: 'APAYAO',
    municipality: 'CALANASAN (BAYAG)',
    region: 'CAR',
    barangay_list: [
      'BUTAO',
      'CADACLAN',
      'DON ROQUE ABLAN SR.',
      'ELEAZAR',
      'EVA PUZON',
      'KABUGAWAN',
      'LANGNAO',
      'LUBONG',
      'MACALINO',
      'NAGUILIAN',
      'NAMALTUGAN',
      'POBLACION',
      'SABANGAN',
      'SANTA ELENA',
      'SANTA FILOMENA',
      'TANGLAGAN',
      'TUBANG',
      'TUBONGAN',
    ],
  },
  CONNER: {
    province: 'APAYAO',
    municipality: 'CONNER',
    region: 'CAR',
    barangay_list: [
      'ALLANGIGAN',
      'BANBAN',
      'BULUAN',
      'CAGLAYAN (NEW POB.)',
      'CALAFUG',
      'CUPIS',
      'DAGA',
      'GUINAANG',
      'GUINAMGAMAN',
      'ILI',
      'KARIKITAN',
      'KATABLANGAN',
      'MALAMA',
      'MANAG',
      'MAWEGUI',
      'NABUANGAN',
      'PADDAOAN',
      'PUGUIN',
      'RIPANG (OLD POB.)',
      'SACPIL',
      'TALIFUGO',
    ],
  },
  FLORA: {
    province: 'APAYAO',
    municipality: 'FLORA',
    region: 'CAR',
    barangay_list: [
      'ALLIG',
      'ANNINIPAN',
      'ATOK',
      'BAGUTONG',
      'BALASI',
      'BALLUYAN',
      'MALAYUGAN',
      'MALLIG',
      'MALUBIBIT NORTE',
      'MALUBIBIT SUR',
      'POBLACION EAST',
      'POBLACION WEST',
      'SAN JOSE',
      'SANTA MARIA',
      'TAMALUNOG',
      'UPPER ATOK (COLIMAN)',
    ],
  },
  KABUGAO: {
    province: 'APAYAO',
    municipality: 'KABUGAO',
    region: 'CAR',
    barangay_list: [
      'BADDUAT',
      'BALIWANAN',
      'BULU',
      'CABETAYAN',
      'DAGARA',
      'DIBAGAT',
      'KARAGAWAN',
      'KUMAO',
      'LACO',
      'LENNENG (LIYYENG)',
      'LUCAB',
      'LUTTUACAN',
      'MADATAG',
      'MADDUANG',
      'MAGABTA',
      'MARAGAT',
      'MUSIMUT',
      'NAGBABALAYAN',
      'POBLACION',
      'TUYANGAN',
      'WAGA',
    ],
  },
  PUDTOL: {
    province: 'APAYAO',
    municipality: 'PUDTOL',
    region: 'CAR',
    barangay_list: [
      'AGA',
      'ALEM',
      'AMADO',
      'AURORA',
      'CABATACAN',
      'CACALAGGAN',
      'CAPANNIKIAN',
      'DOÑA LORETA',
      'EMILIA',
      'IMELDA',
      'LOWER MATON',
      'LT. BILAG',
      'LYDIA',
      'MALIBANG',
      'MATAGUISI',
      'POBLACION',
      'SAN ANTONIO (PUGO)',
      'SAN JOSE',
      'SAN LUIS',
      'SAN MARIANO',
      'SWAN',
      'UPPER MATON',
    ],
  },
  'SANTA MARCELA': {
    province: 'APAYAO',
    municipality: 'SANTA MARCELA',
    region: 'CAR',
    barangay_list: [
      'BAROCBOC',
      'CONSUELO',
      'EMILIANA',
      'IMELDA (SIPA ANNEX)',
      'MALEKKEG',
      'MARCELA (POB.)',
      'NUEVA',
      'PANAY',
      'SAN ANTONIO',
      'SAN CARLOS',
      'SAN JUAN',
      'SAN MARIANO',
      'SIPA PROPER',
    ],
  },
  ATOK: {
    province: 'BENGUET',
    municipality: 'ATOK',
    region: 'CAR',
    barangay_list: [
      'ABIANG',
      'CALIKING',
      'CATTUBO',
      'NAGUEY',
      'PAOAY',
      'PASDONG',
      'POBLACION',
      'TOPDAC',
    ],
  },
  'BAGUIO': {
    province: 'BENGUET',
    municipality: 'BAGUIO',
    region: 'CAR',
    barangay_list: [
      'A. BONIFACIO-CAGUIOA-RIMANDO (ABCR)',
      'ABANAO-ZANDUETA-KAYANG-CHUGUM-OTEK (AZKCO)',
      'ALFONSO TABORA',
      'AMBIONG',
      'ANDRES BONIFACIO (LOWER BOKAWKAN)',
      'APUGAN-LOAKAN',
      'ASIN ROAD',
      'ATOK TRAIL',
      'AURORA HILL PROPER (MALVAR-SGT. FLORESCA)',
      'AURORA HILL, NORTH CENTRAL',
      'AURORA HILL, SOUTH CENTRAL',
      'BAKAKENG CENTRAL',
      'BAKAKENG NORTH',
      'BAL-MARCOVILLE (MARCOVILLE)',
      'BALSIGAN',
      'BAYAN PARK EAST',
      'BAYAN PARK VILLAGE',
      'BAYAN PARK WEST (BAYAN PARK)',
      'BGH COMPOUND',
      'BROOKSIDE',
      'BROOKSPOINT',
      "CABINET HILL-TEACHER'S CAMP",
      'CAMDAS SUBDIVISION',
      'CAMP 7',
      'CAMP 8',
      'CAMP ALLEN',
      'CAMPO FILIPINO',
      'CITY CAMP CENTRAL',
      'CITY CAMP PROPER',
      'COUNTRY CLUB VILLAGE',
      'CRESENCIA VILLAGE',
      'DAGSIAN, LOWER',
      'DAGSIAN, UPPER',
      'DIZON SUBDIVISION',
      'DOMINICAN HILL-MIRADOR',
      'DONTOGAN',
      'DPS AREA',
      "ENGINEERS' HILL",
      'FAIRVIEW VILLAGE',
      'FERDINAND (HAPPY HOMES-CAMPO SIOCO)',
      'FORT DEL PILAR',
      'GABRIELA SILANG',
      'GENERAL EMILIO F. AGUINALDO (QUIRINO-MAGSAYSAY, LOWER)',
      'GENERAL LUNA, LOWER',
      'GENERAL LUNA, UPPER',
      'GIBRALTAR',
      'GREENWATER VILLAGE',
      'GUISAD CENTRAL',
      'GUISAD SORONG',
      'HAPPY HOLLOW',
      'HAPPY HOMES (HAPPY HOMES-LUCBAN)',
      'HARRISON-CLAUDIO CARANTES',
      'HILLSIDE',
      'HOLY GHOST EXTENSION',
      'HOLY GHOST PROPER',
      'HONEYMOON (HONEYMOON-HOLY GHOST)',
      'IMELDA R. MARCOS (LA SALLE)',
      'IMELDA VILLAGE',
      'IRISAN',
      'KABAYANIHAN',
      'KAGITINGAN',
      'KAYANG EXTENSION',
      'KAYANG-HILLTOP',
      'KIAS',
      'LEGARDA-BURNHAM-KISAD',
      'LIWANAG-LOAKAN',
      'LOAKAN PROPER',
      'LOPEZ JAENA',
      'LOURDES SUBDIVISION EXTENSION',
      'LOURDES SUBDIVISION, LOWER',
      'LOURDES SUBDIVISION, PROPER',
      'LUALHATI',
      'LUCNAB',
      'MAGSAYSAY PRIVATE ROAD',
      'MAGSAYSAY, LOWER',
      'MAGSAYSAY, UPPER',
      'MALCOLM SQUARE-PERFECTO (JOSE ABAD SANTOS)',
      'MANUEL A. ROXAS',
      'MARKET SUBDIVISION, UPPER',
      'MIDDLE QUEZON HILL SUBDIVISION(QUEZON HILL MIDDLE)',
      'MILITARY CUT-OFF',
      'MINES VIEW PARK',
      'MODERN SITE, EAST',
      'MODERN SITE, WEST',
      'MRR-QUEEN OF PEACE',
      'NEW LUCBAN',
      'OUTLOOK DRIVE',
      'PACDAL',
      'PADRE BURGOS',
      'PADRE ZAMORA',
      'PALMA-URBANO (CARIÑO-PALMA)',
      'PHIL-AM',
      'PINGET',
      'PINSAO PILOT PROJECT',
      'PINSAO PROPER',
      'POLIWES',
      'PUCSUSAN',
      'QUEZON HILL PROPER',
      'QUEZON HILL, UPPER',
      'QUIRINO HILL, EAST',
      'QUIRINO HILL, LOWER',
      'QUIRINO HILL, MIDDLE',
      'QUIRINO HILL, WEST',
      'QUIRINO-MAGSAYSAY, UPPER (UPPER QM)',
      'RIZAL MONUMENT AREA',
      'ROCK QUARRY, LOWER',
      'ROCK QUARRY, MIDDLE',
      'ROCK QUARRY, UPPER',
      'SAINT JOSEPH VILLAGE',
      'SALUD MITRA',
      'SAN ANTONIO VILLAGE',
      'SAN LUIS VILLAGE',
      'SAN ROQUE VILLAGE',
      'SAN VICENTE',
      'SANITARY CAMP, NORTH',
      'SANITARY CAMP, SOUTH',
      'SANTA ESCOLASTICA',
      'SANTO ROSARIO',
      'SANTO TOMAS PROPER',
      'SANTO TOMAS SCHOOL AREA',
      'SCOUT BARRIO',
      'SESSION ROAD AREA',
      'SLAUGHTER HOUSE AREA (SANTO NIÑO SLAUGHTER)',
      'SLU-SVP HOUSING VILLAGE',
      'SOUTH DRIVE',
      'TEODORA ALONZO',
      'TRANCOVILLE',
      'VICTORIA VILLAGE',
    ],
  },
  BAKUN: {
    province: 'BENGUET',
    municipality: 'BAKUN',
    region: 'CAR',
    barangay_list: [
      'AMPUSONGAN',
      'BAGU',
      'DALIPEY',
      'GAMBANG',
      'KAYAPA',
      'POBLACION (CENTRAL)',
      'SINACBAT',
    ],
  },
  BOKOD: {
    province: 'BENGUET',
    municipality: 'BOKOD',
    region: 'CAR',
    barangay_list: [
      'AMBUCLAO',
      'BILA',
      'BOBOK-BISAL',
      'DACLAN',
      'EKIP',
      'KARAO',
      'NAWAL',
      'PITO',
      'POBLACION',
      'TIKEY',
    ],
  },
  BUGUIAS: {
    province: 'BENGUET',
    municipality: 'BUGUIAS',
    region: 'CAR',
    barangay_list: [
      'ABATAN',
      'AMGALEYGUEY',
      'AMLIMAY',
      'BACULONGAN NORTE',
      'BACULONGAN SUR',
      'BANGAO',
      'BUYACAOAN',
      'CALAMAGAN',
      'CATLUBONG',
      'LENGAOAN',
      'LOO',
      'NATUBLENG',
      'POBLACION (CENTRAL)',
      'SEBANG',
    ],
  },
  ITOGON: {
    province: 'BENGUET',
    municipality: 'ITOGON',
    region: 'CAR',
    barangay_list: [
      'AMPUCAO',
      'DALUPIRIP',
      'GUMATDANG',
      'LOACAN',
      'POBLACION (CENTRAL)',
      'TINONGDAN',
      'TUDING',
      'UCAB',
      'VIRAC',
    ],
  },
  KABAYAN: {
    province: 'BENGUET',
    municipality: 'KABAYAN',
    region: 'CAR',
    barangay_list: [
      'ADAOAY',
      'ANCHUKEY',
      'BALLAY',
      'BASHOY',
      'BATAN',
      'DUACAN',
      'EDDET',
      'GUSARAN',
      'KABAYAN BARRIO',
      'LUSOD',
      'PACSO',
      'POBLACION (CENTRAL)',
      'TAWANGAN',
    ],
  },
  KAPANGAN: {
    province: 'BENGUET',
    municipality: 'KAPANGAN',
    region: 'CAR',
    barangay_list: [
      'BALAKBAK',
      'BELENG-BELIS',
      'BOKLAOAN',
      'CAYAPES',
      'CUBA',
      'DATAKAN',
      'GADANG',
      'GASWELING',
      'LABUEG',
      'PAYKEK',
      'POBLACION CENTRAL',
      'PONGAYAN',
      'PUDONG',
      'SAGUBO',
      'TABA-AO',
    ],
  },
  KIBUNGAN: {
    province: 'BENGUET',
    municipality: 'KIBUNGAN',
    region: 'CAR',
    barangay_list: [
      'BADEO',
      'LUBO',
      'MADAYMEN',
      'PALINA',
      'POBLACION',
      'SAGPAT',
      'TACADANG',
    ],
  },
  'LA TRINIDAD': {
    province: 'BENGUET',
    municipality: 'LA TRINIDAD',
    region: 'CAR',
    barangay_list: [
      'ALAPANG',
      'ALNO',
      'AMBIONG',
      'BAHONG',
      'BALILI',
      'BECKEL',
      'BETAG',
      'BINENG',
      'CRUZ',
      'LUBAS',
      'PICO',
      'POBLACION',
      'PUGUIS',
      'SHILAN',
      'TAWANG',
      'WANGAL',
    ],
  },
  MANKAYAN: {
    province: 'BENGUET',
    municipality: 'MANKAYAN',
    region: 'CAR',
    barangay_list: [
      'BALILI',
      'BEDBED',
      'BULALACAO',
      'CABITEN',
      'COLALO',
      'GUINAOANG',
      'PACO',
      'PALASAAN',
      'POBLACION',
      'SAPID',
      'TABIO',
      'TANEG',
    ],
  },
  SABLAN: {
    province: 'BENGUET',
    municipality: 'SABLAN',
    region: 'CAR',
    barangay_list: [
      'BAGONG',
      'BALLUAY',
      'BANANGAN',
      'BANENGBENG',
      'BAYABAS',
      'KAMOG',
      'PAPPA',
      'POBLACION',
    ],
  },
  TUBA: {
    province: 'BENGUET',
    municipality: 'TUBA',
    region: 'CAR',
    barangay_list: [
      'ANSAGAN',
      'CAMP 3',
      'CAMP 4',
      'CAMP ONE',
      'NANGALISAN',
      'POBLACION',
      'SAN PASCUAL',
      'TABAAN NORTE',
      'TABAAN SUR',
      'TADIANGAN',
      'TALOY NORTE',
      'TALOY SUR',
      'TWIN PEAKS',
    ],
  },
  TUBLAY: {
    province: 'BENGUET',
    municipality: 'TUBLAY',
    region: 'CAR',
    barangay_list: [
      'AMBASSADOR',
      'AMBONGDOLAN',
      'BA-AYAN',
      'BASIL',
      'CAPONGA (POB.)',
      'DACLAN',
      'TUBLAY CENTRAL',
      'TUEL',
    ],
  },
  AGUINALDO: {
    province: 'IFUGAO',
    municipality: 'AGUINALDO',
    region: 'CAR',
    barangay_list: [
      'AWAYAN',
      'BUNHIAN',
      'BUTAC',
      'BUWAG',
      'CHALALO',
      'DAMAG',
      'GALONOGON',
      'HALAG',
      'ITAB',
      'JACMAL',
      'MAJLONG',
      'MONGAYANG',
      'POSNAAN',
      'TA-ANG',
      'TALITE',
      'UBAO',
    ],
  },
  'ALFONSO LISTA (POTIA)': {
    province: 'IFUGAO',
    municipality: 'ALFONSO LISTA (POTIA)',
    region: 'CAR',
    barangay_list: [
      'BANGAR',
      'BUSILAC',
      'CALIMAG',
      'CALUPAAN',
      'CARAGASAN',
      'DOLOWOG',
      'KILING',
      'LAYA',
      'LITTLE TADIAN',
      'NAMILLANGAN',
      'NAMNAMA',
      'NGILEB',
      'PINTO',
      'POBLACION',
      'SAN JOSE',
      'SAN JUAN',
      'SAN MARCOS',
      'SAN QUINTIN',
      'SANTA MARIA',
      'SANTO DOMINGO (CABICALAN)',
    ],
  },
  ASIPULO: {
    province: 'IFUGAO',
    municipality: 'ASIPULO',
    region: 'CAR',
    barangay_list: [
      'AMDUNTOG',
      'ANTIPOLO',
      'CAMANDAG',
      'CAWAYAN',
      'HALLAP',
      'LIWON',
      'NAMAL',
      'NUNGAWA',
      'PANUBTUBAN',
      'PULA',
    ],
  },
  BANAUE: {
    province: 'IFUGAO',
    municipality: 'BANAUE',
    region: 'CAR',
    barangay_list: [
      'AMGANAD',
      'ANABA',
      'BALAWIS',
      'BANAO',
      'BANGAAN',
      'BATAD',
      'BOCOS',
      'CAMBULO',
      'DUCLIGAN',
      'GOHANG',
      'KINAKIN',
      'OHAJ',
      'POBLACION',
      'POITAN',
      'PULA',
      'SAN FERNANDO',
      'TAM-AN',
      'VIEW POINT',
    ],
  },
  HINGYON: {
    province: 'IFUGAO',
    municipality: 'HINGYON',
    region: 'CAR',
    barangay_list: [
      'ANAO',
      'BANGTINON',
      'BITU',
      'CABABUYAN',
      'MOMPOLIA',
      'NAMULDITAN',
      'NORTHERN CABABUYAN',
      'O-ONG',
      'PIWONG',
      'POBLACION (HINGYON)',
      'UBUAG',
      'UMALBONG',
    ],
  },
  HUNGDUAN: {
    province: 'IFUGAO',
    municipality: 'HUNGDUAN',
    region: 'CAR',
    barangay_list: [
      'ABATAN',
      'BA-ANG',
      'BANGBANG',
      'BOKIAWAN',
      'HAPAO',
      'LUBO-ONG',
      'MAGGOK',
      'NUNGULUNAN',
      'POBLACION',
    ],
  },
  KIANGAN: {
    province: 'IFUGAO',
    municipality: 'KIANGAN',
    region: 'CAR',
    barangay_list: [
      'AMBABAG',
      'BAGUINGE',
      'BOKIAWAN',
      'BOLOG',
      'DALLIGAN',
      'DUIT',
      'HUCAB',
      'JULONGAN',
      'LINGAY',
      'MUNGAYANG',
      'NAGACADAN',
      'PINDONGAN',
      'POBLACION',
      'TUPLAC',
    ],
  },
  LAGAWE: {
    province: 'IFUGAO',
    municipality: 'LAGAWE',
    region: 'CAR',
    barangay_list: [
      'ABINUAN',
      'BANGA',
      'BOLIWONG',
      'BURNAY',
      'BUYABUYAN',
      'CABA',
      'CUDOG',
      'DULAO',
      'JUCBONG',
      'LUTA',
      'MONTABIONG',
      'OLILICON',
      'POBLACION EAST',
      'POBLACION NORTH',
      'POBLACION SOUTH',
      'POBLACION WEST',
      'PONGHAL',
      'PULLAAN',
      'TUNGNGOD',
      'TUPAYA',
    ],
  },
  LAMUT: {
    province: 'IFUGAO',
    municipality: 'LAMUT',
    region: 'CAR',
    barangay_list: [
      'AMBASA',
      'BIMPAL',
      'HAPID',
      'HOLOWON',
      'LAWIG',
      'LUCBAN',
      'MABATOBATO (LAMUT)',
      'MAGULON',
      'NAYON',
      'PANOPDOPAN',
      'PAYAWAN',
      'PIEZA',
      'POBLACION EAST',
      'POBLACION WEST',
      'PUGOL (IFUGAO RESERVATION)',
      'SALAMAGUE',
      'SANAFE',
      'UMILAG',
    ],
  },
  MAYOYAO: {
    province: 'IFUGAO',
    municipality: 'MAYOYAO',
    region: 'CAR',
    barangay_list: [
      'ADUYONGAN',
      'ALIMIT',
      'AYANGAN',
      'BALANGBANG',
      'BANAO',
      'BANHAL',
      'BATO-ALATBANG',
      'BONGAN',
      'BUNINAN',
      'CHAYA',
      'CHUMANG',
      'EPENG',
      'GUINIHON',
      'INWALOY',
      'LANGAYAN',
      'LIWO',
      'MAGA',
      'MAGULON',
      'MAPAWOY',
      'MAYOYAO PROPER',
      'MONGOL',
      'NALBU',
      'NATTUM',
      'PALAAD',
      'POBLACION',
      'TALBOC',
      'TULAED',
    ],
  },
  TINOC: {
    province: 'IFUGAO',
    municipality: 'TINOC',
    region: 'CAR',
    barangay_list: [
      'AHIN',
      'AP-APID',
      'BINABLAYAN',
      'DANGGO',
      'EHEB',
      'GUMHANG',
      'IMPUGONG',
      'LUHONG',
      'TINOC',
      'TUKUCAN',
      'TULLUDAN',
      'WANGWANG',
    ],
  },
  BALBALAN: {
    province: 'KALINGA',
    municipality: 'BALBALAN',
    region: 'CAR',
    barangay_list: [
      'ABABA-AN',
      'BALANTOY',
      'BALBALAN PROPER',
      'BALBALASANG',
      'BUAYA',
      'DAO-ANGAN',
      'GAWA-AN',
      'MABACA',
      'MALING (KABUGAO)',
      'PANTIKIAN',
      'POBLACION (SALEGSEG)',
      'POSWOY',
      'TALALANG',
      'TAWANG',
    ],
  },
  LUBUAGAN: {
    province: 'KALINGA',
    municipality: 'LUBUAGAN',
    region: 'CAR',
    barangay_list: [
      'ANTONIO CANAO',
      'DANGOY',
      'LOWER UMA',
      'MABILONG',
      'MABONGTOT',
      'POBLACION',
      'TANGLAG',
      'UMA DEL NORTE (WESTERN LUNA UMA)',
      'UPPER UMA',
    ],
  },
  PASIL: {
    province: 'KALINGA',
    municipality: 'PASIL',
    region: 'CAR',
    barangay_list: [
      'ABLEG',
      'BAGTAYAN',
      'BALATOC',
      'BALENCIAGAO SUR',
      'BALINCIAGAO NORTE',
      'CAGALUAN',
      'COLAYO',
      'DALUPA',
      'DANGTALAN',
      'GALDANG (CASALOAN)',
      'GUINA-ANG (POB.)',
      'MAGSILAY',
      'MALUCSAD',
      'PUGONG',
    ],
  },
  PINUKPUK: {
    province: 'KALINGA',
    municipality: 'PINUKPUK',
    region: 'CAR',
    barangay_list: [
      'ACIGA',
      'ALLAGUIA',
      'AMMACIAN',
      'APATAN',
      'ASIBANGLAN',
      'BA-AY',
      'BALLAYANGON',
      'BAYAO',
      'CAMALOG',
      'CAWAGAYAN',
      'DUGPA',
      'KATABBOGAN',
      'LIMOS',
      'MAGAOGAO',
      'MALAGNAT',
      'MAPACO',
      'PAKAWIT',
      'PINOCOC',
      'PINUKPUK JUNCTION',
      'SOCBOT',
      'TAGA (POB.)',
      'TAGGAY',
      'WAGUD',
    ],
  },
  'RIZAL (LIWAN)': {
    province: 'KALINGA',
    municipality: 'RIZAL (LIWAN)',
    region: 'CAR',
    barangay_list: [
      'BABALAG EAST (POB.)',
      'BABALAG WEST (POB.)',
      'BULBOL',
      'CALAOCAN',
      'KINAMA',
      'LIWAN EAST',
      'LIWAN WEST',
      'MACUTAY',
      'ROMUALDEZ',
      'SAN FRANCISCO',
      'SAN PASCUAL',
      'SAN PEDRO',
      'SAN QUINTIN',
      'SANTOR',
    ],
  },
  'TABUK': {
    province: 'KALINGA',
    municipality: 'TABUK',
    region: 'CAR',
    barangay_list: [
      'AGBANNAWAG',
      'AMLAO',
      'APPAS',
      'BADO DANGWA',
      'BAGUMBAYAN',
      'BALAWAG',
      'BALONG',
      'BANTAY',
      'BULANAO',
      'BULANAO NORTE',
      'BULO',
      'CABARITAN',
      'CABARUAN',
      'CALACCAD',
      'CALANAN',
      'CASIGAYAN',
      'CUDAL',
      'DAGUPAN CENTRO (POB.)',
      'DAGUPAN WESTE',
      'DILAG',
      'DUPAG',
      'GOBGOB',
      'GUILAYON',
      'IPIL',
      'LACNOG',
      'LANNA',
      'LAYA EAST',
      'LAYA WEST',
      'LUCOG',
      'MAGNAO',
      'MAGSAYSAY',
      'MALALAO',
      'MALIN-AWA',
      'MASABLANG',
      'NAMBARAN',
      'NAMBUCAYAN',
      'NANENG',
      'NEW TANGLAG',
      'SAN JUAN',
      'SAN JULIAN',
      'SUYANG',
      'TUGA',
    ],
  },
  TANUDAN: {
    province: 'KALINGA',
    municipality: 'TANUDAN',
    region: 'CAR',
    barangay_list: [
      'ANGGACAN',
      'ANGGACAN SUR',
      'BABBANOY',
      'DACALAN',
      'DUPLIGAN',
      'GAANG',
      'LAY-ASAN',
      'LOWER LUBO',
      'LOWER MANGALI',
      'LOWER TALOCTOC',
      'MABACA',
      'MANGALI CENTRO',
      'PANGOL',
      'POBLACION',
      'UPPER LUBO',
      'UPPER TALOCTOC',
    ],
  },
  TINGLAYAN: {
    province: 'KALINGA',
    municipality: 'TINGLAYAN',
    region: 'CAR',
    barangay_list: [
      'AMBATO LEGLEG',
      'BANGAD CENTRO',
      'BASAO',
      'BELONG MANUBAL',
      'BUGNAY',
      'BUSCALAN (BUSCALAN-LOCONG)',
      'BUTBUT (BUTBUT-NGIBAT)',
      'DANANAO',
      'LOCCONG',
      'LOWER BANGAD',
      'LUPLUPA',
      'MALLANGO',
      'NGIBAT',
      'OLD TINGLAYAN',
      'POBLACION',
      'SUMADEL 1',
      'SUMADEL 2',
      'TULGAO EAST',
      'TULGAO WEST',
      'UPPER BANGAD',
    ],
  },
  BARLIG: {
    province: 'MOUNTAIN PROVINCE',
    municipality: 'BARLIG',
    region: 'CAR',
    barangay_list: [
      'CHUPAC',
      'FIANGTIN',
      'GAWANA (POB.)',
      'KALEO',
      'LATANG',
      'LIAS KANLURAN',
      'LIAS SILANGAN',
      'LINGOY',
      'LUNAS',
      'MACALANA',
      'OGOOG',
    ],
  },
  BAUKO: {
    province: 'MOUNTAIN PROVINCE',
    municipality: 'BAUKO',
    region: 'CAR',
    barangay_list: [
      'ABATAN',
      'BAGNEN ORIENTE',
      'BAGNEN PROPER',
      'BALINTAUGAN',
      'BANAO',
      'BILA (BUA)',
      'GUINZADAN CENTRAL',
      'GUINZADAN NORTE',
      'GUINZADAN SUR',
      'LAGAWA',
      'LESEB',
      'MABAAY',
      'MAYAG',
      'MONAMON NORTE',
      'MONAMON SUR',
      'MOUNT DATA',
      'OTUCAN NORTE',
      'OTUCAN SUR',
      'POBLACION (BAUKO)',
      'SADSADAN',
      'SINTO',
      'TAPAPAN',
    ],
  },
  BESAO: {
    province: 'MOUNTAIN PROVINCE',
    municipality: 'BESAO',
    region: 'CAR',
    barangay_list: [
      'AGAWA',
      'AMBAGUIO',
      'BANGUITAN',
      'BESAO EAST (BESAO PROPER)',
      'BESAO WEST',
      'CATENGAN',
      'GUEDAY',
      'KIN-IWAY (POB.)',
      'LACMAAN',
      'LAYLAYA',
      'PANDANGAN',
      'PAYEO',
      'SUQUIB',
      'TAMBOAN',
    ],
  },
  NATONIN: {
    province: 'MOUNTAIN PROVINCE',
    municipality: 'NATONIN',
    region: 'CAR',
    barangay_list: [
      'ALUNOGAN',
      'BALANGAO',
      'BANAO',
      'BANAWAL',
      'BUTAC',
      'MADUCAYAN',
      'POBLACION',
      'PUDO',
      'SALIOK',
      'SANTA ISABEL',
      'TONGLAYAN',
    ],
  },
  PARACELIS: {
    province: 'MOUNTAIN PROVINCE',
    municipality: 'PARACELIS',
    region: 'CAR',
    barangay_list: [
      'ANONAT',
      'BACARNI',
      'BANANAO',
      'BANTAY',
      'BUNOT',
      'BURINGAL',
      'BUTIGUE',
      'PALITOD',
      'POBLACION',
    ],
  },
  SABANGAN: {
    province: 'MOUNTAIN PROVINCE',
    municipality: 'SABANGAN',
    region: 'CAR',
    barangay_list: [
      'BAO-ANGAN',
      'BUN-AYAN',
      'BUSA',
      'CAMATAGAN',
      'CAPINITAN',
      'DATA',
      'GAYANG',
      'LAGAN',
      'LOSAD',
      'NAMATEC',
      'NAPUA',
      'PINGAD',
      'POBLACION',
      'SUPANG',
      'TAMBINGAN',
    ],
  },
  SADANGA: {
    province: 'MOUNTAIN PROVINCE',
    municipality: 'SADANGA',
    region: 'CAR',
    barangay_list: [
      'ANABEL',
      'BEKIGAN',
      'BELWANG',
      'BETWAGAN',
      'DEMANG',
      'POBLACION',
      'SACASACAN',
      'SACLIT',
    ],
  },
  SAGADA: {
    province: 'MOUNTAIN PROVINCE',
    municipality: 'SAGADA',
    region: 'CAR',
    barangay_list: [
      'AGUID',
      'AMBASING',
      'ANGKELING',
      'ANTADAO',
      'BALUGAN',
      'BANGAAN',
      'DAGDAG (POB.)',
      'DEMANG (POB.)',
      'FIDELISAN',
      'KILONG',
      'MADONGO',
      'NACAGANG',
      'PIDE',
      'POBLACION (PATAY)',
      'SUYO',
      'TACCONG',
      'TANULONG',
      'TETEPAN NORTE',
      'TETEPAN SUR',
    ],
  },
  TADIAN: {
    province: 'MOUNTAIN PROVINCE',
    municipality: 'TADIAN',
    region: 'CAR',
    barangay_list: [
      'BALAOA',
      'BANAAO',
      'BANTEY',
      'BATAYAN',
      'BUNGA',
      'CADAD-ANAN',
      'CAGUBATAN',
      'DACUDAC',
      'DUAGAN',
      'KAYAN EAST',
      'KAYAN WEST',
      'LENGA',
      'LUBON',
      'MABALITE',
      'MASLA',
      'PANDAYAN',
      'POBLACION',
      'SUMADEL',
      'TUE',
    ],
  },
  'LAS PIÑAS': {
    province: 'METRO MANILA',
    municipality: 'LAS PIÑAS',
    region: 'NCR',
    barangay_list: [
      'ALMANZA DOS',
      'ALMANZA UNO',
      'B. F. INTERNATIONAL VILLAGE',
      'DANIEL FAJARDO',
      'ELIAS ALDANA',
      'ILAYA',
      'MANUYO DOS',
      'MANUYO UNO',
      'PAMPLONA DOS',
      'PAMPLONA TRES',
      'PAMPLONA UNO',
      'PILAR',
      'PULANG LUPA DOS',
      'PULANG LUPA UNO',
      'TALON DOS',
      'TALON KUATRO',
      'TALON SINGKO',
      'TALON TRES',
      'TALON UNO',
      'ZAPOTE',
    ],
  },
  'MAKATI': {
    province: 'METRO MANILA',
    municipality: 'MAKATI',
    region: 'NCR',
    barangay_list: [
      'BANGKAL',
      'BEL-AIR',
      'CARMONA',
      'CEMBO',
      'COMEMBO',
      'DASMARIÑAS',
      'EAST REMBO',
      'FORBES PARK',
      'GUADALUPE NUEVO',
      'GUADALUPE VIEJO',
      'KASILAWAN',
      'LA PAZ',
      'MAGALLANES',
      'OLYMPIA',
      'PALANAN',
      'PEMBO',
      'PINAGKAISAHAN',
      'PIO DEL PILAR',
      'PITOGO',
      'POBLACION',
      'POST PROPER NORTHSIDE',
      'POST PROPER SOUTHSIDE',
      'RIZAL',
      'SAN ANTONIO',
      'SAN ISIDRO',
      'SAN LORENZO',
      'SANTA CRUZ',
      'SINGKAMAS',
      'SOUTH CEMBO',
      'TEJEROS',
      'URDANETA',
      'VALENZUELA',
      'WEST REMBO',
    ],
  },
  'MANILA': {
    province: 'METRO MANILA',
    municipality: 'MANILA',
    region: 'NCR',
    barangay_list: [
      "BINONDO",
      "ERMITA",
      "INTRAMUROS",
      "MALATE",
      "PACO",
      "PANDACAN",
      "PORT AREA",
      "QUIAPO",
      "SAMPALOC",
      "SAN ANDRÉS",
      "SAN MIGUEL",
      "SAN NICOLAS",
      "SANTA ANA",
      "SANTA CRUZ",
      "SANTA MESA",
      "TONDO"
    ],
  },
  'MUNTINLUPA': {
    province: 'METRO MANILA',
    municipality: 'MUNTINLUPA',
    region: 'NCR',
    barangay_list: [
      'ALABANG',
      'BAYANAN',
      'BULI',
      'CUPANG',
      'NEW ALABANG VILLAGE',
      'POBLACION',
      'PUTATAN',
      'SUCAT',
      'TUNASAN',
    ],
  },
  'PARAÑAQUE': {
    province: 'METRO MANILA',
    municipality: 'PARAÑAQUE',
    region: 'NCR',
    barangay_list: [
      'B. F. HOMES',
      'BACLARAN',
      'DON BOSCO',
      'DON GALO',
      'LA HUERTA',
      'MARCELO GREEN VILLAGE',
      'MERVILLE',
      'MOONWALK',
      'SAN ANTONIO',
      'SAN DIONISIO',
      'SAN ISIDRO',
      'SAN MARTIN DE PORRES',
      'SANTO NIÑO',
      'SUN VALLEY',
      'TAMBO',
      'VITALEZ',
    ],
  },
  'PASAY': {
    province: 'METRO MANILA',
    municipality: 'PASAY',
    region: 'NCR',
    barangay_list: [
      'BARANGAY 1',
      'BARANGAY 2',
      'BARANGAY 3',
      'BARANGAY 4',
      'BARANGAY 5',
      'BARANGAY 6',
      'BARANGAY 7',
      'BARANGAY 8',
      'BARANGAY 9',
      'BARANGAY 10',
      'BARANGAY 11',
      'BARANGAY 12',
      'BARANGAY 13',
      'BARANGAY 14',
      'BARANGAY 15',
      'BARANGAY 16',
      'BARANGAY 17',
      'BARANGAY 18',
      'BARANGAY 19',
      'BARANGAY 20',
      'BARANGAY 21',
      'BARANGAY 22',
      'BARANGAY 23',
      'BARANGAY 24',
      'BARANGAY 25',
      'BARANGAY 26',
      'BARANGAY 27',
      'BARANGAY 28',
      'BARANGAY 29',
      'BARANGAY 30',
      'BARANGAY 31',
      'BARANGAY 32',
      'BARANGAY 33',
      'BARANGAY 34',
      'BARANGAY 35',
      'BARANGAY 36',
      'BARANGAY 37',
      'BARANGAY 38',
      'BARANGAY 39',
      'BARANGAY 40',
      'BARANGAY 41',
      'BARANGAY 42',
      'BARANGAY 43',
      'BARANGAY 44',
      'BARANGAY 45',
      'BARANGAY 46',
      'BARANGAY 47',
      'BARANGAY 48',
      'BARANGAY 49',
      'BARANGAY 50',
      'BARANGAY 51',
      'BARANGAY 52',
      'BARANGAY 53',
      'BARANGAY 54',
      'BARANGAY 55',
      'BARANGAY 56',
      'BARANGAY 57',
      'BARANGAY 58',
      'BARANGAY 59',
      'BARANGAY 60',
      'BARANGAY 61',
      'BARANGAY 62',
      'BARANGAY 63',
      'BARANGAY 64',
      'BARANGAY 65',
      'BARANGAY 66',
      'BARANGAY 67',
      'BARANGAY 68',
      'BARANGAY 69',
      'BARANGAY 70',
      'BARANGAY 71',
      'BARANGAY 72',
      'BARANGAY 73',
      'BARANGAY 74',
      'BARANGAY 75',
      'BARANGAY 76',
      'BARANGAY 77',
      'BARANGAY 78',
      'BARANGAY 79',
      'BARANGAY 80',
      'BARANGAY 81',
      'BARANGAY 82',
      'BARANGAY 83',
      'BARANGAY 84',
      'BARANGAY 85',
      'BARANGAY 86',
      'BARANGAY 87',
      'BARANGAY 88',
      'BARANGAY 89',
      'BARANGAY 90',
      'BARANGAY 91',
      'BARANGAY 92',
      'BARANGAY 93',
      'BARANGAY 94',
      'BARANGAY 95',
      'BARANGAY 96',
      'BARANGAY 97',
      'BARANGAY 98',
      'BARANGAY 99',
      'BARANGAY 100',
      'BARANGAY 101',
      'BARANGAY 102',
      'BARANGAY 103',
      'BARANGAY 104',
      'BARANGAY 105',
      'BARANGAY 106',
      'BARANGAY 107',
      'BARANGAY 108',
      'BARANGAY 109',
      'BARANGAY 110',
      'BARANGAY 111',
      'BARANGAY 112',
      'BARANGAY 113',
      'BARANGAY 114',
      'BARANGAY 115',
      'BARANGAY 116',
      'BARANGAY 117',
      'BARANGAY 118',
      'BARANGAY 119',
      'BARANGAY 120',
      'BARANGAY 121',
      'BARANGAY 122',
      'BARANGAY 123',
      'BARANGAY 124',
      'BARANGAY 125',
      'BARANGAY 126',
      'BARANGAY 127',
      'BARANGAY 128',
      'BARANGAY 129',
      'BARANGAY 130',
      'BARANGAY 131',
      'BARANGAY 132',
      'BARANGAY 133',
      'BARANGAY 134',
      'BARANGAY 135',
      'BARANGAY 136',
      'BARANGAY 137',
      'BARANGAY 138',
      'BARANGAY 139',
      'BARANGAY 140',
      'BARANGAY 141',
      'BARANGAY 142',
      'BARANGAY 143',
      'BARANGAY 144',
      'BARANGAY 145',
      'BARANGAY 146',
      'BARANGAY 147',
      'BARANGAY 148',
      'BARANGAY 149',
      'BARANGAY 150',
      'BARANGAY 151',
      'BARANGAY 152',
      'BARANGAY 153',
      'BARANGAY 154',
      'BARANGAY 155',
      'BARANGAY 156',
      'BARANGAY 157',
      'BARANGAY 158',
      'BARANGAY 159',
      'BARANGAY 160',
      'BARANGAY 161',
      'BARANGAY 162',
      'BARANGAY 163',
      'BARANGAY 164',
      'BARANGAY 165',
      'BARANGAY 166',
      'BARANGAY 167',
      'BARANGAY 168',
      'BARANGAY 169',
      'BARANGAY 170',
      'BARANGAY 171',
      'BARANGAY 172',
      'BARANGAY 173',
      'BARANGAY 174',
      'BARANGAY 175',
      'BARANGAY 176',
      'BARANGAY 177',
      'BARANGAY 178',
      'BARANGAY 179',
      'BARANGAY 180',
      'BARANGAY 181',
      'BARANGAY 182',
      'BARANGAY 183',
      'BARANGAY 184',
      'BARANGAY 185',
      'BARANGAY 186',
      'BARANGAY 187',
      'BARANGAY 188',
      'BARANGAY 189',
      'BARANGAY 190',
      'BARANGAY 191',
      'BARANGAY 192',
      'BARANGAY 193',
      'BARANGAY 194',
      'BARANGAY 195',
      'BARANGAY 196',
      'BARANGAY 197',
      'BARANGAY 198',
      'BARANGAY 199',
      'BARANGAY 200',
      'BARANGAY 201',
    ],
  },
  'MANDALUYONG': {
    province: 'METRO MANILA',
    municipality: 'MANDALUYONG',
    region: 'NCR',
    barangay_list: [
      'ADDITION HILLS',
      'BAGONG SILANG',
      'BARANGKA DRIVE',
      'BARANGKA IBABA',
      'BARANGKA ILAYA',
      'BARANGKA ITAAS',
      'BUAYANG BATO',
      'BUROL',
      'DAANG BAKAL',
      'HAGDANG BATO ITAAS',
      'HAGDANG BATO LIBIS',
      'HARAPIN ANG BUKAS',
      'HIGHWAY HILLS',
      'HULO',
      'MABINI-J. RIZAL',
      'MALAMIG',
      'MAUWAY',
      'NAMAYAN',
      'NEW ZAÑIGA',
      'OLD ZAÑIGA',
      'PAG-ASA',
      'PLAINVIEW',
      'PLEASANT HILLS',
      'POBLACION',
      'SAN JOSE',
      'VERGARA',
      'WACK-WACK GREENHILLS',
    ],
  },
  'MARIKINA': {
    province: 'METRO MANILA',
    municipality: 'MARIKINA',
    region: 'NCR',
    barangay_list: [
      'BARANGKA',
      'CALUMPANG',
      'CONCEPCION DOS',
      'CONCEPCION UNO',
      'FORTUNE',
      'INDUSTRIAL VALLEY',
      'JESUS DE LA PEÑA',
      'MALANDAY',
      'MARIKINA HEIGHTS (CONCEPCION)',
      'NANGKA',
      'PARANG',
      'SAN ROQUE',
      'SANTA ELENA (POB.)',
      'SANTO NIÑO',
      'TAÑONG',
      'TUMANA',
    ],
  },
  'PASIG': {
    province: 'METRO MANILA',
    municipality: 'PASIG',
    region: 'NCR',
    barangay_list: [
      'BAGONG ILOG',
      'BAGONG KATIPUNAN',
      'BAMBANG',
      'BUTING',
      'CANIOGAN',
      'DELA PAZ',
      'KALAWAAN',
      'KAPASIGAN',
      'KAPITOLYO',
      'MALINAO',
      'MANGGAHAN',
      'MAYBUNGA',
      'ORANBO',
      'PALATIW',
      'PINAGBUHATAN',
      'PINEDA',
      'ROSARIO',
      'SAGAD',
      'SAN ANTONIO',
      'SAN JOAQUIN',
      'SAN JOSE',
      'SAN MIGUEL',
      'SAN NICOLAS (POB.)',
      'SANTA CRUZ',
      'SANTA LUCIA',
      'SANTA ROSA',
      'SANTO TOMAS',
      'SANTOLAN',
      'SUMILANG',
      'UGONG',
    ],
  },
  'QUEZON': {
    province: 'METRO MANILA',
    municipality: 'QUEZON',
    region: 'NCR',
    barangay_list: [
      'ALICIA',
      'AMIHAN',
      'APOLONIO SAMSON',
      'AURORA',
      'BAESA',
      'BAGBAG',
      'BAGONG LIPUNAN NG CRAME',
      'BAGONG PAG-ASA',
      'BAGONG SILANGAN',
      'BAGUMBAYAN',
      'BAGUMBUHAY',
      'BAHAY TORO',
      'BALINGASA',
      'BALONG BATO',
      'BATASAN HILLS',
      'BAYANIHAN',
      'BLUE RIDGE A',
      'BLUE RIDGE B',
      'BOTOCAN',
      'BUNGAD',
      'CAMP AGUINALDO',
      'CAPRI',
      'CENTRAL',
      'CLARO',
      'COMMONWEALTH',
      'CULIAT',
      'DAMAR',
      'DAMAYAN',
      'DAMAYANG LAGI',
      'DEL MONTE',
      'DIOQUINO ZOBEL',
      'DON MANUEL',
      'DOÑA IMELDA',
      'DOÑA JOSEFA',
      'DUYAN-DUYAN',
      'E. RODRIGUEZ',
      'EAST KAMIAS',
      'ESCOPA I',
      'ESCOPA II',
      'ESCOPA III',
      'ESCOPA IV',
      'FAIRVIEW',
      'GREATER LAGRO',
      'GULOD',
      'HOLY SPIRIT',
      'HORSESHOE',
      'IMMACULATE CONCEPCION',
      'KALIGAYAHAN',
      'KALUSUGAN',
      'KAMUNING',
      'KATIPUNAN',
      'KAUNLARAN',
      'KRISTONG HARI',
      'KRUS NA LIGAS',
      'LAGING HANDA',
      'LIBIS',
      'LOURDES',
      'LOYOLA HEIGHTS',
      'MAHARLIKA',
      'MALAYA',
      'MANGGA',
      'MANRESA',
      'MARIANA',
      'MARIBLO',
      'MARILAG',
      'MASAGANA',
      'MASAMBONG',
      'MATANDANG BALARA',
      'MILAGROSA',
      'N.S. AMORANTO (GINTONG SILAHIS)',
      'NAGKAISANG NAYON',
      'NAYONG KANLURAN',
      'NEW ERA (CONSTITUTION HILLS)',
      'NORTH FAIRVIEW',
      'NOVALICHES PROPER',
      'OBRERO',
      'OLD CAPITOL SITE',
      'PAANG BUNDOK',
      'PAG-IBIG SA NAYON',
      'PALIGSAHAN',
      'PALTOK',
      'PANSOL',
      'PARAISO',
      'PASONG PUTIK PROPER (PASONG PUTIK)',
      'PASONG TAMO',
      'PAYATAS',
      'PHIL-AM',
      'PINAGKAISAHAN',
      'PINYAHAN',
      'PROJECT 6',
      'QUIRINO 2-A',
      'QUIRINO 2-B',
      'QUIRINO 2-C',
      'QUIRINO 3-A',
      'RAMON MAGSAYSAY',
      'ROXAS',
      'SACRED HEART',
      'SAINT IGNATIUS',
      'SAINT PETER',
      'SALVACION',
      'SAN AGUSTIN',
      'SAN ANTONIO',
      'SAN BARTOLOME',
      'SAN ISIDRO',
      'SAN ISIDRO LABRADOR',
      'SAN JOSE',
      'SAN MARTIN DE PORRES',
      'SAN ROQUE',
      'SAN VICENTE',
      'SANGANDAAN',
      'SANTA CRUZ',
      'SANTA LUCIA',
      'SANTA MONICA',
      'SANTA TERESITA',
      'SANTO CRISTO',
      'SANTO DOMINGO (MATALAHIB)',
      'SANTO NIÑO',
      'SANTOL',
      'SAUYO',
      'SIENNA',
      'SIKATUNA VILLAGE',
      'SILANGAN',
      'SOCORRO',
      'SOUTH TRIANGLE',
      'TAGUMPAY',
      'TALAYAN',
      'TALIPAPA',
      'TANDANG SORA',
      'TATALON',
      'TEACHERS VILLAGE EAST',
      'TEACHERS VILLAGE WEST',
      'U.P. CAMPUS',
      'U.P. VILLAGE',
      'UGONG NORTE',
      'UNANG SIGAW',
      'VALENCIA',
      'VASRA',
      'VETERANS VILLAGE',
      'VILLA MARIA CLARA',
      'WEST KAMIAS',
      'WEST TRIANGLE',
      'WHITE PLAINS',
    ],
  },
  'SAN JUAN': {
    province: 'METRO MANILA',
    municipality: 'SAN JUAN',
    region: 'NCR',
    barangay_list: [
      'ADDITION HILLS',
      'BALONG-BATO',
      'BATIS',
      'CORAZON DE JESUS',
      'ERMITAÑO',
      'GREENHILLS',
      'HALO-HALO (ST. JOSEPH)',
      'ISABELITA',
      'KABAYANAN',
      'LITTLE BAGUIO',
      'MAYTUNAS',
      'ONSE',
      'PASADEÑA',
      'PEDRO CRUZ',
      'PROGRESO',
      'RIVERA',
      'SALAPAN',
      'SAN PERFECTO',
      'SANTA LUCIA',
      'TIBAGAN',
      'WEST CRAME',
    ],
  },
  'CALOOCAN': {
    province: 'METRO MANILA',
    municipality: 'CALOOCAN',
    region: 'NCR',
    barangay_list: [
      'BARANGAY 1',
      'BARANGAY 2',
      'BARANGAY 3',
      'BARANGAY 4',
      'BARANGAY 5',
      'BARANGAY 6',
      'BARANGAY 7',
      'BARANGAY 8',
      'BARANGAY 9',
      'BARANGAY 10',
      'BARANGAY 11',
      'BARANGAY 12',
      'BARANGAY 13',
      'BARANGAY 14',
      'BARANGAY 15',
      'BARANGAY 16',
      'BARANGAY 17',
      'BARANGAY 18',
      'BARANGAY 19',
      'BARANGAY 20',
      'BARANGAY 21',
      'BARANGAY 22',
      'BARANGAY 23',
      'BARANGAY 24',
      'BARANGAY 25',
      'BARANGAY 26',
      'BARANGAY 27',
      'BARANGAY 28',
      'BARANGAY 29',
      'BARANGAY 30',
      'BARANGAY 31',
      'BARANGAY 32',
      'BARANGAY 33',
      'BARANGAY 34',
      'BARANGAY 35',
      'BARANGAY 36',
      'BARANGAY 37',
      'BARANGAY 38',
      'BARANGAY 39',
      'BARANGAY 40',
      'BARANGAY 41',
      'BARANGAY 42',
      'BARANGAY 43',
      'BARANGAY 44',
      'BARANGAY 45',
      'BARANGAY 46',
      'BARANGAY 47',
      'BARANGAY 48',
      'BARANGAY 49',
      'BARANGAY 50',
      'BARANGAY 51',
      'BARANGAY 52',
      'BARANGAY 53',
      'BARANGAY 54',
      'BARANGAY 55',
      'BARANGAY 56',
      'BARANGAY 57',
      'BARANGAY 58',
      'BARANGAY 59',
      'BARANGAY 60',
      'BARANGAY 61',
      'BARANGAY 62',
      'BARANGAY 63',
      'BARANGAY 64',
      'BARANGAY 65',
      'BARANGAY 66',
      'BARANGAY 67',
      'BARANGAY 68',
      'BARANGAY 69',
      'BARANGAY 70',
      'BARANGAY 71',
      'BARANGAY 72',
      'BARANGAY 73',
      'BARANGAY 74',
      'BARANGAY 75',
      'BARANGAY 76',
      'BARANGAY 77',
      'BARANGAY 78',
      'BARANGAY 79',
      'BARANGAY 80',
      'BARANGAY 81',
      'BARANGAY 82',
      'BARANGAY 83',
      'BARANGAY 84',
      'BARANGAY 85',
      'BARANGAY 86',
      'BARANGAY 87',
      'BARANGAY 88',
      'BARANGAY 89',
      'BARANGAY 90',
      'BARANGAY 91',
      'BARANGAY 92',
      'BARANGAY 93',
      'BARANGAY 94',
      'BARANGAY 95',
      'BARANGAY 96',
      'BARANGAY 97',
      'BARANGAY 98',
      'BARANGAY 99',
      'BARANGAY 100',
      'BARANGAY 101',
      'BARANGAY 102',
      'BARANGAY 103',
      'BARANGAY 104',
      'BARANGAY 105',
      'BARANGAY 106',
      'BARANGAY 107',
      'BARANGAY 108',
      'BARANGAY 109',
      'BARANGAY 110',
      'BARANGAY 111',
      'BARANGAY 112',
      'BARANGAY 113',
      'BARANGAY 114',
      'BARANGAY 115',
      'BARANGAY 116',
      'BARANGAY 117',
      'BARANGAY 118',
      'BARANGAY 119',
      'BARANGAY 120',
      'BARANGAY 121',
      'BARANGAY 122',
      'BARANGAY 123',
      'BARANGAY 124',
      'BARANGAY 125',
      'BARANGAY 126',
      'BARANGAY 127',
      'BARANGAY 128',
      'BARANGAY 129',
      'BARANGAY 130',
      'BARANGAY 131',
      'BARANGAY 132',
      'BARANGAY 133',
      'BARANGAY 134',
      'BARANGAY 135',
      'BARANGAY 136',
      'BARANGAY 137',
      'BARANGAY 138',
      'BARANGAY 139',
      'BARANGAY 140',
      'BARANGAY 141',
      'BARANGAY 142',
      'BARANGAY 143',
      'BARANGAY 144',
      'BARANGAY 145',
      'BARANGAY 146',
      'BARANGAY 147',
      'BARANGAY 148',
      'BARANGAY 149',
      'BARANGAY 150',
      'BARANGAY 151',
      'BARANGAY 152',
      'BARANGAY 153',
      'BARANGAY 154',
      'BARANGAY 155',
      'BARANGAY 156',
      'BARANGAY 157',
      'BARANGAY 158',
      'BARANGAY 159',
      'BARANGAY 160',
      'BARANGAY 161',
      'BARANGAY 162',
      'BARANGAY 163',
      'BARANGAY 164',
      'BARANGAY 165',
      'BARANGAY 166',
      'BARANGAY 167',
      'BARANGAY 168',
      'BARANGAY 169',
      'BARANGAY 170',
      'BARANGAY 171',
      'BARANGAY 172',
      'BARANGAY 173',
      'BARANGAY 174',
      'BARANGAY 175',
      'BARANGAY 176',
      'BARANGAY 177',
      'BARANGAY 178',
      'BARANGAY 179',
      'BARANGAY 180',
      'BARANGAY 181',
      'BARANGAY 182',
      'BARANGAY 183',
      'BARANGAY 184',
      'BARANGAY 185',
      'BARANGAY 186',
      'BARANGAY 187',
      'BARANGAY 188',
    ],
  },
  'VALENZUELA': {
    province: 'METRO MANILA',
    municipality: 'VALENZUELA',
    region: 'NCR',
    barangay_list: [
      'ARKONG BATO',
      'BAGBAGUIN',
      'BALANGKAS',
      'BIGNAY',
      'BISIG',
      'CANUMAY EAST',
      'CANUMAY WEST',
      'COLOONG',
      'DALANDANAN',
      'HEN. T. DE LEON',
      'ISLA',
      'KARUHATAN',
      'LAWANG BATO',
      'LINGUNAN',
      'MABOLO',
      'MALANDAY',
      'MALINTA',
      'MAPULANG LUPA',
      'MARULAS',
      'MAYSAN',
      'PALASAN',
      'PARADA',
      'PARIANCILLO VILLA',
      'PASO DE BLAS',
      'PASOLO',
      'POBLACION',
      'PULO',
      'PUNTURIN',
      'RINCON',
      'TAGALAG',
      'UGONG',
      'VIENTE REALES',
      'WAWANG PULO',
    ],
  },
  'MALABON': {
    province: 'METRO MANILA',
    municipality: 'MALABON',
    region: 'NCR',
    barangay_list: [
      'ACACIA',
      'BARITAN',
      'BAYAN-BAYANAN',
      'CATMON',
      'CONCEPCION',
      'DAMPALIT',
      'FLORES',
      'HULONG DUHAT',
      'IBABA',
      'LONGOS',
      'MAYSILO',
      'MUZON',
      'NIUGAN',
      'PANGHULO',
      'POTRERO',
      'SAN AGUSTIN',
      'SANTOLAN',
      'TAÑONG (POB.)',
      'TINAJEROS',
      'TONSUYA',
      'TUGATOG',
    ],
  },
  'NAVOTAS': {
    province: 'METRO MANILA',
    municipality: 'NAVOTAS',
    region: 'NCR',
    barangay_list: [
      'BAGUMBAYAN NORTH',
      'BAGUMBAYAN SOUTH',
      'BANGCULASI',
      'DAANGHARI',
      'NAVOTAS EAST',
      'NAVOTAS WEST',
      'NBBS DAGAT-DAGATAN',
      'NBBS KAUNLARAN',
      'NBBS PROPER',
      'NORTH BAY BLVD., NORTH',
      'SAN JOSE (POB.)',
      'SAN RAFAEL VILLAGE',
      'SAN ROQUE',
      'SIPAC-ALMACEN',
      'TANGOS NORTH',
      'TANGOS SOUTH',
      'TANZA 1',
      'TANZA 2',
    ],
  },
  PATEROS: {
    province: 'METRO MANILA',
    municipality: 'PATEROS',
    region: 'NCR',
    barangay_list: [
      'AGUHO',
      'MAGTANGGOL',
      'MARTIRES DEL 96',
      'POBLACION',
      'SAN PEDRO',
      'SAN ROQUE',
      'SANTA ANA',
      'SANTO ROSARIO-KANLURAN',
      'SANTO ROSARIO-SILANGAN',
      'TABACALERA',
    ],
  },
  TAGUIG: {
    province: 'METRO MANILA',
    municipality: 'TAGUIG',
    region: 'NCR',
    barangay_list: [
      'BAGUMBAYAN',
      'BAMBANG',
      'CALZADA',
      'CENTRAL BICUTAN',
      'CENTRAL SIGNAL VILLAGE',
      'FORT BONIFACIO',
      'HAGONOY',
      'IBAYO-TIPAS',
      'KATUPARAN',
      'LIGID-TIPAS',
      'LOWER BICUTAN',
      'MAHARLIKA VILLAGE',
      'NAPINDAN',
      'NEW LOWER BICUTAN',
      'NORTH DAANG HARI',
      'NORTH SIGNAL VILLAGE',
      'PALINGON',
      'PINAGSAMA',
      'SAN MIGUEL',
      'SANTA ANA',
      'SOUTH DAANG HARI',
      'SOUTH SIGNAL VILLAGE',
      'TANYAG',
      'TUKTUKAN',
      'UPPER BICUTAN',
      'USUSAN',
      'WAWA',
      'WESTERN BICUTAN',
    ],
  },
};