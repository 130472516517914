import React, { useContext, useEffect } from 'react';
import { Grid, Button, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import { StyleContext } from '../context/StyleProvider';
import { useTheme } from '@emotion/react';
import QRCode from 'react-qr-code';

const Main = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { setMaxWidth } = useContext(StyleContext);

  useEffect(() => {
    if (!isMobile) {
      setMaxWidth('sm');
    } else {
      setMaxWidth(false);
    }
  }, [isMobile]);

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      style={isMobile ? { height: '80vh' } : {}}>
      <div className="center-container">
        <img src="/logo.png" alt="" />
        {isMobile ? (
          <>
            <Link to="/customer">
              <Button variant="contained" className="action">
                I am a Customer
              </Button>
            </Link>
            {/* <Link to="/customer">
              <Button
                variant="contained"
                className="action"
                style={{
                  backgroundColor: '#136a90',
                }}>
                I am a Merchant
              </Button>
            </Link> */}
            <Link to="/driver">
              <Button color="secondary" variant="contained" className="action">
                I am a Driver
              </Button>
            </Link>
            <Link to="/dropoff/login">
              <Button
                color="secondary"
                variant="contained"
                className="action"
                style={{
                  backgroundColor: '#878181',
                }}>
                Dropoff Account
              </Button>
            </Link>
          </>
        ) : (
          <>
            <Link to="/dropoff/login">
              <Button color="secondary" variant="contained" className="action">
                Dropoff Account
              </Button>
            </Link>
            <p>
              <strong>
                If you are a Customer or Driver, please use your mobile phone to
                scan this QR code
              </strong>
            </p>
            <div
              style={{
                background: 'white',
                padding: '16px',
                textAlign: 'center',
              }}>
              <QRCode value={`https://portal.boxtrackcargo.com.ph/`} />
            </div>
          </>
        )}
      </div>
    </Grid>
  );
};

export default Main;
