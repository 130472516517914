import React, { useState, useEffect, useContext } from 'react';
import { Button, FormControl } from '@mui/material';
import {
  computationSameDay,
  show,
  update as updateOrder,
  orderPaymentProcess,
} from '../../../services/order';
import { accountBalance } from '../../../services/user';
import { UserContext } from '../../../context/UserProvider';
import { moneyFormat } from '../../../utils/helpers';
import Select from '../../../components/FormSelect';
import LoadingScreen from '../../../components/LoadingScreen';
import { useNavigate } from 'react-router-dom';

const App = ({
  label,
  data,
  backHandler,
  nextHandler,
  removeReloadWarning,
  isComputed,
}) => {
  const { token } = useContext(UserContext);
  const [jobOrder, setJobOrder] = useState(null);
  const [payment, setPayment] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (token && payment) {
      loadComputation();
    }
  }, [token, payment]);

  useEffect(() => {
    if (data?.receiver?.cod) {
      return setPayment('cod');
    }

    data.stops.forEach(item => {
      if (item.cod) {
        return setPayment('cod');
      }
    });
  }, [data]);

  const loadComputation = () => {
    if (!payment) {
      return null;
    }

    setIsLoading(true);
    computationSameDay(token, {
      ...data,
      'payment-type': payment,
    }).then(res => {
      setJobOrder(res);
      let isCod = res?.pricing?.totalCodAmount || 0;

      if (isCod > 0) {
        setPayment('cod');
      }
      setIsLoading(false);
    });
  };

  const cancelHandler = () => {
    let confirm = window.confirm('Are you sure you want to cancel?');

    if (confirm) {
      setIsLoading(true);
      updateOrder(
        token,
        {
          status: 'canceled',
        },
        jobOrder['order-id'],
      ).then(res => {
        navigate('/customer/dashboard');
      });
    }
  };

  const checkWalletIfSufficient = () => {
    setIsLoading(true);
    accountBalance(token).then(res => {
      if (res.balance < jobOrder?.pricing?.total) {
        setIsLoading(false);
        return removeReloadWarning(() => {
          const linkPayment = `${process.env.REACT_APP_API}/account/tapup?t=${token}&order=${jobOrder['order-id']}&type=sameday`;
          // open new window
          // return window.open(linkPayment, '_blank', 'noopener,noreferrer');

          try {
            return window.appilixRedirect(linkPayment);
          } catch (error) {
            return (window.location.href = linkPayment);
          }
        });
      } else {
        deductFromWallet();
      }
      setIsLoading(false);
    });
  };

  const deductFromWallet = () => {
    orderPaymentProcess(token, {
      'order-id': jobOrder['order-id'],
      type: 'sameday',
    }).then(res => {
      if (res.status === 'success') {
        placeOrder();
      }
    });
  };

  const submitProcess = () => {
    let confirm = window.confirm('Are you sure you want to place order?');

    if (!confirm) {
      return;
    }

    if (!payment) {
      return alert('Please select payment method');
    }
    setIsLoading(true);

    if (payment === 'wallet') {
      checkWalletIfSufficient();
    } else {
      placeOrder();
    }
  };

  const placeOrder = () => {
    setIsLoading(true);
    updateOrder(
      token,
      {
        status: 'active',
        'payment-type': payment,
      },
      jobOrder['order-id'],
    ).then(res => {
      show(token, jobOrder['order-id']).then(res => {
        setIsLoading(false);
        if (res.data.status != 'draft') {
          navigate(`/customer/delivery/${jobOrder['order-id']}`);
        } else {
          return alert('Something went wrong, try again later.');
        }
        setIsLoading(false);
      });
    });
  };

  if (!payment) {
    return (
      <div
        style={{
          marginTop: '25px',
        }}>
        <strong>Payment Method</strong>
        <FormControl fullWidth style={{ marginBottom: '10px' }}>
          <Select
            onChange={e => setPayment(e.value)}
            options={process.env.REACT_APP_ENABLE_DRAGONPAY == 'false' ? 
              [
                {
                  label: 'Pay via Cash',
                  value: 'cash',
                }
              ] :
              [
                {
                  label: 'Pay via Wallet',
                  value: 'wallet',
                },
                {
                  label: 'Pay via Cash',
                  value: 'cash',
                },
              ]
            }
            placeholder="Select Payment Method"
          />
          <Button
            variant="contained"
            color="primary"
            style={{ marginTop: '10px' }}
            onClick={() => backHandler()}
          >
            Back
          </Button>
        </FormControl>
      </div>
    );
  }

  if (!jobOrder) {
    return <LoadingScreen />;
  }

  let isCod = jobOrder?.pricing?.totalCodAmount || 0;
  isCod = isCod > 0 ? true : false;

  return (
    <>
      {isLoading && <LoadingScreen />}
      <div
        style={{
          textAlign: 'center',
          fontSize: '1.2rem',
          paddingTop: '15px',
          paddingBottom: '15px',
        }}>
        {label}
      </div>

      <div
        style={{
          backgroundColor: '#dfdfdf',
          padding: '10px',
          marginBottom: '15px',
          borderRadius: '10px',
        }}>
        <div>
          Sub Total:{' '}
          <span style={{ float: 'right' }}>
            PHP {moneyFormat(jobOrder?.pricing?.subTotal || 0)}{' '}
          </span>
        </div>
        {isCod && (
          <div>
            COD Amount:{' '}
            <span style={{ float: 'right' }}>
              PHP {moneyFormat(jobOrder?.pricing?.totalCodAmount || 0)}
            </span>
          </div>
        )}
        <div style={{ fontSize: '25px' }}>
          Total:{' '}
          <span style={{ float: 'right' }}>
            PHP {moneyFormat(jobOrder?.pricing?.total || 0)}
          </span>
        </div>
      </div>

      <div style={{ width: '100%' }}>
        {isCod ? (
          <>
            <p style={{ textAlign: 'center' }}>
              Payment Method: <strong>Cash on Delivery</strong>
            </p>
            <Button
              variant="contained"
              color="primary"
              onClick={() => submitProcess()}
              style={{ marginBottom: '10px' }}>
              PLACE ORDER
            </Button>
          </>
        ) : (
          <>
            <p style={{ textAlign: 'center' }}>
              Payment Method: <strong>{payment == 'wallet' ? 'Wallet' : 'Cash'}</strong>
            </p>
            <Button
              variant="contained"
              color="primary"
              onClick={() => submitProcess()}
              style={{ marginBottom: '10px' }}>
              PLACE ORDER
            </Button>
          </>
        )}
        <Button
          variant="contained"
          color="primary"
          style={{ marginBottom: '10px' }}
          onClick={() => {
            if (payment == 'cod') {
              backHandler();
            } else {
              setPayment(null);
            }
          }}>
          Back
        </Button>

        <Button
          variant="outlined"
          fullWidth
          color="error"
          onClick={() => cancelHandler()}>
          Cancel
        </Button>

        <p style={{ textAlign: 'center' }}>
              Quotation Ref #: <br></br><strong>{jobOrder['reference-number'] ?? ''}</strong>
        </p>
      </div>
    </>
  );
};

export default App;
