import React, { useContext, useState } from 'react';
import Navbar from '../../../components/DesktopNavbar/admin.jsx';
import { mainMenu } from '../../../config/adminMenu.jsx';
import { UserContext } from '../../../context/UserProvider.jsx';

const App = () => {
  return (
    <>
      <Navbar pages={mainMenu} />
      <div
        style={{
          margin: '10px',
          textAlign: 'center',
        }}>
            <h1>Welcome To Boxtrack</h1>
      </div>
    </>
  );
};

export default App;
