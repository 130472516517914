import React, { useEffect, useState } from 'react';
import { Grid, Button, Typography } from '@mui/material';
import { recover } from '../../services/authentication';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import Fields from '../../components/Fields';
import { Link } from 'react-router-dom';
import { phoneRegex } from '../../utils/constants';
import { useNavigate } from 'react-router-dom';
import { FormControlLabel, Switch } from '@mui/material';
import LoadingScreen from '../LoadingScreen';
import { isMobile } from 'react-device-detect';

const config = [
  {
    type: 'textfield',
    id: 'login',
    label: 'Mobile Number',
  },
];
const schema = z
  .object({
    login: z.coerce.string().min(11),
  })
  .superRefine(({ login }, ctx) => {
    if (!phoneRegex.test(login)) {
      ctx.addIssue({
        code: 'custom',
        message: 'Invalid login info',
        path: ['login'],
      });
    }
  });

const configFull = [
  {
    type: 'textfield',
    id: 'otp',
    label: 'One Time Pin',
  },
  {
    type: 'textfield',
    id: 'newPassword',
    label: 'New Password',
    isPassword: true,
  },
  {
    type: 'textfield',
    id: 'retypePassword',
    label: 'Re-type New Password',
    isPassword: true,
  },
];
const schemaFull = z
  .object({
    otp: z.coerce.number().min(5),
    newPassword: z.string().min(1),
    retypePassword: z.string().min(1),
  })
  .superRefine(({ retypePassword, newPassword }, ctx) => {
    if (retypePassword !== newPassword) {
      ctx.addIssue({
        code: 'custom',
        message: 'The passwords did not match',
        path: ['retypePassword'],
      });
    }
  });

const RecoverForm = ({ userType, allowEmailOtp }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [loginData, setLoginData] = useState({});
  const [isChecked, setIsChecked] = React.useState(
    allowEmailOtp ? false : true,
  );
  const navigate = useNavigate();

  const { register, control, handleSubmit, formState } = useForm({
    resolver: zodResolver(!otpSent ? schema : schemaFull),
  });

  const onSubmit = data => {
    setIsLoading(true);
    let payload;
    if (otpSent) {
      payload = {
        otp: data.otp,
        login: loginData.login,
        'new-password': data.newPassword,
        type: userType,
        channel: isChecked ? 'sms' : 'email',
      };
    } else {
      payload = {
        login: data.login,
        type: userType,
        channel: isChecked ? 'sms' : 'email',
      };
    }
    recover(payload)
      .then(res => {
        if (!otpSent) {
          setLoginData(payload);
          setOtpSent(true);
        } else {
          if (res?.status === 'Failed') {
            alert('Invalid OTP');
          } else if (res?.status == 400) {
            alert('Invalid OTP');
          } else {
            alert('Success');
            navigate(`/${userType}/login`);
          }
        }
        setIsLoading(false);
      })
      .catch(() => {
        alert('Invalid user');
        setIsLoading(false);
      });
  };

  const { errors } = formState;
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      style={
        isMobile
          ? {
              margin: 'auto',
            }
          : {
              width: '400px',
              margin: 'auto',
            }
      }>
      {isLoading && <LoadingScreen />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="center-container">
          <Fields
            config={!otpSent ? config : configFull}
            errors={errors}
            register={register}
            control={control}></Fields>
          {!otpSent && allowEmailOtp && (
            <>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    defaultChecked={isChecked}
                    onChange={e => setIsChecked(e.target.checked)}
                  />
                }
                label={isChecked ? 'OTP Send to SMS' : 'OTP Send to Email'}
                labelPlacement="start"
                style={{ marginBottom: '10px' }}
              />
            </>
          )}
          <Button type="submit" variant="contained" className="action">
            {!otpSent ? 'Recover Password' : 'Update Password'}
          </Button>
        </div>
        <div className="action-container text-center">
          <Link to={`/${userType}/login`}>
            <Typography variant="subtitle1">Back</Typography>
          </Link>
        </div>
      </form>
    </Grid>
  );
};

export default RecoverForm;
