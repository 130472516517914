import React, { createContext, useEffect, useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { showRegular } from '../services/order';
import { UserContext } from './UserProvider';

const DeliveryTemplateContext = createContext();

const DeliveryTemplateProvider = ({ children, type, pricePage }) => {
  const { token } = useContext(UserContext);
  let { id } = useParams();
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [jobOrder, setJobOrder] = useState({
    type: type,
    sender: null,
    dropoffOrigin: null,
    dropoffDestination: null,
    receiver: null,
    package: null,
    pricing: null,
  });

  const handleBeforeUnload = e => {
    e.preventDefault();
    e.returnValue = 'Reloading will reset the form';
  };

  useEffect(() => {
    loadPresetData();
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const loadPresetData = () => {
    if (!id) {
      return;
    }

    showRegular(token, id).then(res => {
      setJobOrder({
        ...res.data.data,
        'order-id': id,
      });
      
      setStep(pricePage);
    });
  };

  const backHandler = () => {
    setStep(step - 1);
  };
  const nextHandler = () => {
    setStep(step + 1);
  };

  return (
    <DeliveryTemplateContext.Provider value={{
        step,
        setStep,
        jobOrder,
        setJobOrder,
        backHandler,
        nextHandler,
        navigate,
        id,
        handleBeforeUnload
    }}>
      {children}
    </DeliveryTemplateContext.Provider>
  );
};

export default DeliveryTemplateProvider;
export { DeliveryTemplateContext };
