import React, { useEffect, useState, useContext } from 'react';
import { Button, TextField } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import CallIcon from '@mui/icons-material/Call';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { UserContext } from '../../../context/UserProvider';
import { show as showFile } from '../../../services/file';
import { moneyFormat, toTitleCase } from '../../../utils/helpers';
import { status as sameDayStatus } from '../../../config/sameDayStatus';
import { update } from '../../../services/order';
import SettingsIcon from '@mui/icons-material/Settings';

const styles = {
  sectionContatiner: {
    textAlign: 'left',
    backgroundColor: '#dedede',
    border: 'solid 1px #b7b7b7',
    padding: '5px 10px 15px 10px',
    borderRadius: '10px',
    marginBottom: '10px',
    fontSize: '15px',
    color: '#494949',
  },
  sectionContatinerDriver: {
    textAlign: 'left',
    backgroundColor: '#ffff',
    border: 'solid 1px #b7b7b7',
    padding: '5px 10px 15px 10px',
    borderRadius: '10px',
    marginBottom: '10px',
    textAlign: 'center',
  },
  sectionHeader: {
    marginBottom: '0px',
    marginTop: '0px',
  },
  sectionData: {
    marginBottom: '0px',
    marginTop: '0px',
  },
  sectionDataTo: {
    fontWeight: 'bold',
  },
  sectionButton: {
    marginTop: '10px',
  },
  packageImage: {
    width: '100%',
    borderRadius: '10px',
    marginTop: '10px',
  },
};

const App = ({
  id,
  status,
  data,
  showMap,
  reference,
  driverInformation,
  paymentType,
}) => {
  const navigate = useNavigate();
  const { token, userType } = useContext(UserContext);

  const cancelHandler = () => {
    const confirm = window.confirm('Are you sure you want to cancel?');
    if (confirm) {
      update(token, { status: 'canceled' }, id)
        .then(res => {
          navigate('/customer');
        })
        .catch(err => {
          alert("Order can't be canceled at this time.");
          window.location.reload();
        });
    }
  };

  return (
    <>
      <div
        style={{
          textAlign: 'center',
          fontSize: '1.2rem',
          paddingTop: '35px',
          fontWeight: 'bold',
        }}>
        Order Information <br />
        <small style={{ fontSize: '12px' }}>ORDER # {reference}</small>
        <br />
        <small style={{ fontSize: '12px' }}>
          Vehicle Type - {data?.vehicle?.label}
        </small>
      </div>
      <div
        style={{
          textAlign: 'center',
          fontSize: '0.9rem',
          paddingTop: '10px',
          paddingBottom: '10px',
          background: '#bec6e2',
          fontWeight: 'bold',
        }}>
        {sameDayStatus[status] ?? status}
      </div>
      <div
        style={{
          textAlign: 'center',
          fontSize: '1.2rem',
          paddingTop: '15px',
          paddingBottom: '15px',
        }}>
        {!['active', 'waiting for payment', 'canceled'].includes(status) &&
          driverInformation && (
            <>
              <DriverContainer
                status={status}
                showMap={() => showMap()}
                data={driverInformation}
              />
            </>
          )}
        <AddressContainer title={'Pickup'} data={data.sender} />
        {data.stops.map((item, index) => {
          return (
            <AddressContainer
              key={`stop-${index + 1}`}
              title={`Stop ${index + 1}`}
              data={item}
            />
          );
        })}
        <AddressContainer title={'Receiver'} data={data.receiver} />
        <PackageContainer data={data.package} />
        <PricingContainer data={data.pricing} type={paymentType} />

        {status == 'completed' && data['proof-of-delivery'] && (
          <>
            <ProofOfDeliveryContainer data={data['proof-of-delivery']} />
          </>
        )}
      </div>
      <div style={{ width: '100%', paddingBottom: '15px' }}>
        <Button
          variant="contained"
          color="primary"
          style={{ width: '100%', marginTop: '15px' }}
          onClick={() => {
            navigate('/customer/dashboard');
          }}>
          Back
        </Button>
        {status == 'active' && (
          <Button
            variant="contained"
            color="error"
            style={{ width: '100%', marginTop: '15px' }}
            onClick={() => cancelHandler()}>
            Cancel
          </Button>
        )}
      </div>
    </>
  );
};

const AddressContainer = ({ title, data }) => {
  const [show, setShow] = useState(false);

  const onClick = () => {
    setShow(!show);
  };

  const label = show ? 'Hide' : 'View';

  return (
    <>
      <div style={styles.sectionContatiner}>
        <div style={styles.sectionHeader}>
          <strong>{title}</strong>
        </div>
        {!show ? (
          <>
            <p style={styles.sectionData}>
              {data?.addressLine1} {data?.addressLine2 || ''} {data.city}{' '}
              {data.province} {data.zipCode}
            </p>
          </>
        ) : (
          <>
            <table>
              <tbody>
                <tr>
                  <td style={styles.sectionDataTo}>Name: </td>
                  <td>{data?.name || ''}</td>
                </tr>
                <tr>
                  <td style={styles.sectionDataTo}>Phone: </td>
                  <td>{data?.contactNumber || ''}</td>
                </tr>
                <tr>
                  <td style={styles.sectionDataTo}>Address: </td>
                  <td></td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    {data?.addressLine1 || ''} {data?.addressLine1 || ''}{' '}
                    {data?.city || ''} {data?.province || ''}{' '}
                    {data?.zipCode || ''}
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        )}
        <Button
          onClick={onClick}
          variant="outlined"
          color="primary"
          size={'small'}
          fullWidth
          style={styles.sectionButton}>
          {label}
        </Button>
      </div>
    </>
  );
};

const PackageContainer = ({ data }) => {
  const { token } = useContext(UserContext);
  const [url, setUrl] = useState(null);

  useEffect(() => {
    loadFile();
  }, []);

  const loadFile = () => {
    showFile(token, data?.packagePhoto).then(res => {
      setUrl(res?.data?.file || null);
    });
  };

  return (
    <div style={styles.sectionContatiner}>
      <p style={styles.sectionHeader}>
        <strong>Package</strong>
      </p>
      {url && (
        <p style={styles.sectionData}>
          <img src={url} style={styles.packageImage} />
        </p>
      )}
      <p style={styles.sectionData}>
        <strong>Package Description: </strong>
        <br />
        {data?.packageDescription || ''}
      </p>
    </div>
  );
};

const DriverContainer = ({ data, showMap, status }) => {
  return (
    <div style={styles.sectionContatinerDriver}>
      <p style={styles.sectionHeader}>
        <strong>Your Driver</strong>
      </p>
      <p style={styles.sectionData}>
        {data?.driver?.['first-name']} {data?.driver?.['last-name']}
      </p>
      <p style={styles.sectionData}>{data?.user?.login}</p>
      {!['completed'].includes(status) && (
        <>
          <Button
            variant="contained"
            color="primary"
            size={'small'}
            fullWidth
            style={styles.sectionButton}
            startIcon={<CallIcon />}
            href={`tel:${data?.user?.login}`}>
            Call
          </Button>
          <Button
            variant="contained"
            color="primary"
            size={'small'}
            fullWidth
            style={styles.sectionButton}
            startIcon={<LocationOnIcon />}
            onClick={() => showMap()}>
            View Location
          </Button>
        </>
      )}
    </div>
  );
};

const PricingContainer = ({ data, type }) => {
  const [showDetails, setShowDetails] = useState(false);
  const query = new URLSearchParams(useLocation().search);

  const RenderCod = () => {
    if (!data.totalCodAmount || data.totalCodAmount == 0) {
      return null;
    }

    return (
      <>
        <div>
          COD Amount:{' '}
          <span style={{ float: 'right' }}>
            PHP {moneyFormat(data?.totalCodAmount || 0)}
          </span>
        </div>
      </>
    );
  };

  if (showDetails && query.get('debug')) {
    return (
      <div
        style={{
          ...styles.sectionContatiner,
          minHeight: '300px',
          paddingBottom: '50px',
        }}
        onClick={() => setShowDetails(!showDetails)}>
        <div>
          Payment Type:{' '}
          <span style={{ float: 'right' }}>{toTitleCase(type)} </span>
        </div>
        <div>
          Sub Total:{' '}
          <span style={{ float: 'right' }}>
            PHP {moneyFormat(data?.subTotal || 0)}{' '}
          </span>
        </div>
        <RenderCod />
        <div style={{ fontSize: '25px' }}>
          Total:{' '}
          <span style={{ float: 'right' }}>
            PHP {moneyFormat(data?.total || 0)}
          </span>
        </div>

        <div style={{ fontSize: '25px', borderTop: 'solid 1px' }}>
          Service Variables
        </div>
        <div>
          Base Fare:{' '}
          <span style={{ float: 'right' }}>
            PHP {moneyFormat(data?.totalDeliveryFee?.totalBaseFee || 0)}
          </span>
        </div>
        {data?.longDistance && (
          <>
            <div>
              Long Distance Fee:{' '}
              <span style={{ float: 'right' }}>
                {data?.totalDeliveryFee?.config?.longDistancePercentage}%
              </span>
            </div>
          </>
        )}
        <div>
          Price Per KM:{' '}
          <span style={{ float: 'right' }}>
            PHP {moneyFormat(data?.totalDeliveryFee?.config?.perKm || 0)}
          </span>
        </div>
        {data?.totalDeliveryFee?.config?.numberOfStops > 0 && (
          <div>
            Stop Fee:{' '}
            <span style={{ float: 'right' }}>
              PHP {moneyFormat(data?.totalDeliveryFee?.config?.stopsFee || 0)}
            </span>
          </div>
        )}
        {data?.totalCodFee > 0 && (
          <div>
            COD Fee:{' '}
            <span style={{ float: 'right' }}>
              PHP {moneyFormat(data?.totalCodFee || 0)}
            </span>
          </div>
        )}

        {data?.paymentProcessorFee > 0 && (
          <>
            <div>
              Convenience Fee:{' '}
              <span style={{ float: 'right' }}>
                <>PHP {moneyFormat(data?.paymentProcessorFee || 0)} </>
              </span>
            </div>
          </>
        )}

        <div style={{ fontSize: '25px', borderTop: 'solid 1px' }}>
          Breakdown
        </div>

        <div>
          <strong>
            <i>Base Fare:</i>
          </strong>{' '}
          <span style={{ float: 'right' }}>
            <strong>
              PHP {moneyFormat(data?.totalDeliveryFee?.totalBaseFee || 0)}
            </strong>
          </span>
        </div>

        <div>
          <strong>
            <i>Total Distance:</i>
          </strong>{' '}
          <br />
          {data?.totalDistance || 0} km X PHP{' '}
          {moneyFormat(data?.totalDeliveryFee?.config?.perKm || 0)} =
          <span style={{ float: 'right' }}>
            <strong>
              PHP {moneyFormat(data?.totalDeliveryFee?.totalDistanceFee || 0)}
            </strong>{' '}
          </span>
        </div>

        {data?.totalDeliveryFee?.config?.numberOfStops > 0 && (
          <div>
            <strong>
              <i>Total Stop Fee:</i>
            </strong>{' '}
            <br />
            {data?.totalDeliveryFee?.config?.numberOfStops || 0} STOP/S X PHP{' '}
            {moneyFormat(data?.totalDeliveryFee?.config?.stopsFee || 0)} =
            <span style={{ float: 'right' }}>
              <strong>
                PHP {moneyFormat(data?.totalDeliveryFee?.totalStopsFee || 0)}
              </strong>{' '}
            </span>
          </div>
        )}

        {data?.longDistance && (
          <>
            <div>
              <strong>
                <i>Long Distance:</i>
              </strong>{' '}
              <br />
              PHP {moneyFormat(
                data?.totalDeliveryFee?.totalDistanceFee || 0,
              )} x {data?.totalDeliveryFee?.config?.longDistancePercentage}% =
              <span style={{ float: 'right' }}>
                <strong>
                  PHP{' '}
                  {moneyFormat(
                    data?.totalDeliveryFee?.totalLongDistanceFee || 0,
                  )}{' '}
                </strong>
              </span>
            </div>
          </>
        )}

        {data?.totalCodFee > 0 && (
          <>
            <div>
              <strong>
                <i>COD Fee:</i>
              </strong>{' '}
              <span style={{ float: 'right' }}>
                <strong>PHP {moneyFormat(data?.totalCodFee || 0)} </strong>
              </span>
            </div>
            <div>
              <strong>
                <i>COD Amount:</i>
              </strong>{' '}
              <span style={{ float: 'right' }}>
                <strong>PHP {moneyFormat(data?.totalCodAmount || 0)} </strong>
              </span>
            </div>
          </>
        )}

        {data?.paymentProcessorFee > 0 && (
          <>
            <div>
              <strong>
                <i>Convenience Fee:</i>
              </strong>{' '}
              <span style={{ float: 'right' }}>
                <strong>
                  PHP {moneyFormat(data?.paymentProcessorFee || 0)}{' '}
                </strong>
              </span>
            </div>
          </>
        )}
      </div>
    );
  }

  return (
    <div
      style={styles.sectionContatiner}
      onClick={() => setShowDetails(!showDetails)}>
      <div>
        Payment Type:{' '}
        <span style={{ float: 'right' }}>{toTitleCase(type)} </span>
      </div>
      <div>
        Sub Total:{' '}
        <span style={{ float: 'right' }}>
          PHP {moneyFormat(data?.subTotal || 0)}{' '}
        </span>
      </div>
      <RenderCod />
      <div style={{ fontSize: '25px' }}>
        Total:{' '}
        <span style={{ float: 'right' }}>
          PHP {moneyFormat(data?.total || 0)}
        </span>
      </div>
    </div>
  );
};

const ProofOfDeliveryContainer = ({ data }) => {
  const { token } = useContext(UserContext);
  const [url, setUrl] = useState(null);

  useEffect(() => {
    loadFile();
  }, []);

  const loadFile = () => {
    showFile(token, data?.packagePhoto).then(res => {
      setUrl(res?.data?.file || null);
    });
  };

  return (
    <div style={styles.sectionContatiner}>
      <p style={styles.sectionHeader}>
        <strong>Proof Of Delivery</strong>
      </p>
      {url && (
        <p style={styles.sectionData}>
          <img src={url} style={styles.packageImage} />
        </p>
      )}
      <p style={styles.sectionData}>
        <strong>Delivery Note: </strong>
        <br />
        {data?.packageDescription || ''}
      </p>
    </div>
  );
};

export default App;
