import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingScreen from '../../../components/LoadingScreen';
import { UserContext } from '../../../context/UserProvider';
import {
  showRegular as show,
  acceptRegular,
  orderOwnerChecker,
  acceptRegularForDeliver,
} from '../../../services/order';
import { Button } from '@mui/material';
import { styles } from '../../../config/defaultPageStyles';
import CallIcon from '@mui/icons-material/Call';
import { show as showFile } from '../../../services/file';
import { moneyFormat, toTitleCase } from '../../../utils/helpers';
import ProofOfPickup from './proofOfPickup';
import ProofOfDelivery from './proofOfDeliveryRegular';
import QRCode from 'react-qr-code';

const stylesContainer = {
  sectionContainerAddress: {
    textAlign: 'left',
    backgroundColor: '#dedede',
    border: 'solid 1px #b7b7b7',
    padding: '5px 10px 15px 10px',
    borderRadius: '10px',
    marginBottom: '10px',
    fontSize: '15px',
    color: '#494949',
  },
  sectionHeader: {
    marginBottom: '0px',
    marginTop: '0px',
  },
  sectionData: {
    marginBottom: '0px',
    marginTop: '0px',
  },
  sectionDataTo: {
    fontWeight: 'bold',
  },
  sectionButton: {
    marginTop: '10px',
  },
  packageImage: {
    width: '100%',
    borderRadius: '10px',
    marginTop: '10px',
  },
};

let regularLabels = {
  door2door: 'Door 2 Door',
  dropoff2dropoff: 'Dropoff 2 Dropoff',
  dropoff2door: 'Dropoff 2 Door',
  door2dropoff: 'Door 2 Dropoff',
};

const App = () => {
  let { id } = useParams();
  const { token, userType } = useContext(UserContext);
  const [jobOrder, setJobOrder] = useState(null);
  const [activeOrder, setActiveOrder] = useState(false);
  const [initClose, setInitClose] = useState(false);
  const [initDeliver, setInitDeliver] = useState(false);
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    loadOrder();
  }, []);

  const loadOrder = () => {
    show(token, id).then(res => {
      const jobOrder = res.data;
      setJobOrder(jobOrder);
      console.log('JOB ORDER INFORMATION', jobOrder);

      if (jobOrder?.status == 'delivery in progress') {
        setActiveOrder(true);
      } else {
        if (jobOrder['dropoff-destination-main-id'] || jobOrder['dropoff-destination-id']) {
          isThisAssignedToMe(result => {
            setProperActiveOrder(jobOrder?.status, 'destination', result);
          });
        } else {
          acceptRegular(token, {}, id).then(res => {
            setProperActiveOrder(res?.status, 'origin');
          });
        }
      }
    });
  };

  const isThisAssignedToMe = (callback) => {
    orderOwnerChecker(token, id, {'destination-driver-id': true}).then(res => {
      callback(res);
    });
  };

  const setProperActiveOrder = (status, type, isAssignedToMe = false) => {
    if (type == 'origin') {
      switch (status) {
        case 'success':
        case 'delivery in progress':
          setActiveOrder(true);
          break;
        case 'open':
        case 'completed':
        case 'for delivery':
        case 'for pickup':
          setActiveOrder(false);
          break;
        case 'dropoff destination main':
        case 'dropoff destination':
          alert('Order is not ready for dispatch.');
          navigate(`/driver/orders`);
          break;
        default:
          alert('Order was already accepted by another driver.');
          navigate(`/driver/orders`);
      }
    } else if (type == 'destination') {
      switch (status) {
        case 'delivery in progress':
          setActiveOrder(isAssignedToMe);
          break;
        case 'completed':
        case 'for delivery':
        case 'for pickup':
          setActiveOrder(false);
          break;
        case 'dropoff destination main':
        case 'dropoff destination':
          alert('Order is not ready for dispatch.');
          navigate(`/driver/orders`);
          break;
        default:
          alert('Parcel is not ready, please try again later.');
          navigate(`/driver/orders`);
      }
    }
  };

  const acceptOrder = () => {
    setIsSubmitting(true);
    acceptRegular(token, { accept: true }, id)
      .then(res => {
        if (res.status == 'success') {
          setActiveOrder(true);
        } else if (res.message) {
          alert(res.message);
          navigate(`/driver/orders`);
        } else {
          alert('Order was already accepted by another driver.');
          navigate(`/driver/orders`);
        }
      })
      .catch(err => {
        alert('Order was already accepted by another driver.');
        navigate(`/driver/orders`);
      });
  };

  const acceptOrderForDelivery = () => {
    setIsSubmitting(true);
    acceptRegularForDeliver(token, { accept: true }, id)
      .then(res => {
        if (res.status == 'success') {
          window.location.reload();
        } else if (res.message) {
          alert(res.message);
          navigate(`/driver/orders`);
        } else {
          alert('Order was already accepted by another driver.');
          console.log('ssssssssssssss', res);
          // navigate(`/driver/orders`);
        }
      })
      .catch(err => {
        alert('Order was already accepted by another driver.');
        navigate(`/driver/orders`);
      });
  };

  if (!jobOrder) {
    return <LoadingScreen />;
  }

  if (initClose) {
    return (
      <ProofOfPickup
        id={id}
        backHandler={() => setInitClose(false)}
        data={jobOrder}
      />
    );
  } else if (initDeliver) {
    return (
      <ProofOfDelivery
        id={id}
        backHandler={() => setInitDeliver(false)}
        data={jobOrder}
      />
    );
  }

  return (
    <div style={styles.pageContainer}>
      <div style={styles.pageHeader}>
        Regular Order Information
        <br />
        <small style={{ fontSize: '12px' }}>
          {regularLabels[jobOrder?.type] ?? ''} -{' '}
          {toTitleCase(jobOrder?.status)}
        </small>
        <br />
        <small style={{ fontSize: '12px' }}>
          ORDER # {jobOrder?.['reference-number']}
        </small>
      </div>
      {!['active', 'driver matched'].includes(jobOrder?.status) && (
        <QRCodeContainer id={id} data={jobOrder?.data} />
      )}
      <PackageContainer data={jobOrder?.data?.package || {}} />
      <PricingContainer
        data={jobOrder?.data?.pricing}
        type={jobOrder?.['payment-type']}
      />
      <AddressContainer
        id={'origin'}
        title={
          jobOrder?.data?.dropoffOrigin ? 'Sender Details' : 'Origin Location'
        }
        data={jobOrder?.data?.sender || {}}
      />
      {jobOrder?.data?.dropoffOrigin && (
        <DropoffContainer
          title={'Dropoff Location for Origin'}
          data={jobOrder?.data?.dropoffOrigin}
        />
      )}
      {jobOrder?.data?.dropoffDestination && (
        <DropoffContainer
          title={'Dropoff Location for Destination'}
          data={jobOrder?.data?.dropoffDestination}
        />
      )}
      <div style={{ paddingBottom: '30px' }}>
        <AddressContainer
          id={'destination'}
          title={
            jobOrder?.data?.dropoffDestination
              ? 'Receiver Details'
              : 'Destination Location'
          }
          data={jobOrder?.data?.receiver || {}}
        />

        {jobOrder?.data?.['proof-of-pickup'] && (
          <ProofContainer
            label={'Proof of Pickup'}
            data={jobOrder?.data?.['proof-of-pickup'] || {}}
          />
        )}

        {activeOrder ? (
          <>
            {jobOrder.status == 'completed' ? (
              <>
                <ProofContainer
                  label={'Proof of Delivery'}
                  data={jobOrder?.data?.['proof-of-delivery'] || {}}
                />
              </>
            ) : (
              <>
                {['active', 'driver matched'].includes(jobOrder.status) && (
                  <>
                    <Button
                      onClick={() => setInitClose(true)}
                      variant="outlined"
                      color="error"
                      size={'small'}
                      fullWidth
                      style={stylesContainer.sectionButton}>
                      MARK AS PICKUP ORDER
                    </Button>
                  </>
                )}

                {['delivery in progress'].includes(jobOrder.status) && (
                  <>
                    <Button
                      onClick={() => setInitDeliver(true)}
                      variant="outlined"
                      color="error"
                      size={'small'}
                      fullWidth
                      style={stylesContainer.sectionButton}>
                      MARK AS DELIVERED
                    </Button>
                  </>
                )}
              </>
            )}
          </>
        ) : (
          <>
            {!['completed'].includes(jobOrder.status) && (
              <>
                <Button
                  onClick={() => {
                    jobOrder?.status == 'for delivery'
                      ? acceptOrderForDelivery()
                      : acceptOrder();
                  }}
                  variant="contained"
                  color="primary"
                  size={'small'}
                  fullWidth
                  style={stylesContainer.sectionButton}
                  disabled={isSubmitting}
                >
                  GET ORDER
                </Button>
              </>
            )}
          </>
        )}
        <Button
          onClick={() => {
            navigate(-1);
          }}
          variant="contained"
          color="primary"
          size={'small'}
          fullWidth
          style={stylesContainer.sectionButton}>
          Back To Orders
        </Button>
      </div>
    </div>
  );
};

export default App;

const AddressContainer = ({
  id,
  title,
  data,
  activeOrder,
  activeTask,
  setActiveTask,
  completeTaskHadler,
  allowStartTask,
}) => {
  const [show, setShow] = useState(false);
  const [taskCompleted, setTaskCompleted] = useState(
    data?.taskCompleted ?? false,
  );
  const [startTask, setStartTask] = useState(false);
  const label = show ? 'Hide' : 'View';

  const onClick = () => {
    setShow(!show);
  };

  useEffect(() => {
    if (activeTask == id) {
      setStartTask(true);
    } else {
      setStartTask(false);
    }
  }, [id, activeTask]);

  useEffect(() => {
    if (startTask) {
      setActiveTask(id);
    }
  }, [startTask]);

  const handleTaskCompleted = () => {
    setTaskCompleted(true);
    setActiveTask(null);
    completeTaskHadler(id);
  };

  return (
    <>
      <div style={stylesContainer.sectionContainerAddress}>
        <div style={stylesContainer.sectionHeader}>
          <strong>{title}</strong>
        </div>
        {!show ? (
          <>
            <p style={stylesContainer.sectionData}>
              {data?.addressLine1} {data?.addressLine2 || ''} {data.city}{' '}
              {data.province} {data.zipCode}
            </p>
          </>
        ) : (
          <>
            <table>
              <tbody>
                <tr>
                  <td style={stylesContainer.sectionDataTo}>Name: </td>
                  <td>{data?.name || ''}</td>
                </tr>
                <tr>
                  <td style={stylesContainer.sectionDataTo}>Phone: </td>
                  <td>{data?.contactNumber || ''}</td>
                </tr>
                <tr>
                  <td style={stylesContainer.sectionDataTo}>Address: </td>
                  <td></td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    {data?.addressLine1 || ''} {data?.addressLine1 || ''}{' '}
                    {data?.city || ''} {data?.province || ''}{' '}
                    {data?.zipCode || ''}
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        )}
        {show && activeOrder && (
          <>
            <Button
              startIcon={<CallIcon />}
              href="tel:09999999999"
              variant="contained"
              color="primary"
              size={'small'}
              fullWidth
              style={stylesContainer.sectionButton}>
              CALL
            </Button>
            <Button
              onClick={() => {
                const lat = data.lat;
                const lon = data.lng;
                const url = `https://www.google.com/maps/search/?api=1&query=${lat},${lon}`;

                window.open(url, '_blank');
              }}
              variant="contained"
              color="primary"
              size={'small'}
              fullWidth
              style={stylesContainer.sectionButton}>
              NAVIGATE VIA GOOGLE MAP
            </Button>
            <Button
              onClick={() => {
                const lat = data.lat;
                const lon = data.lng;
                const url = `https://waze.com/ul?ll=${lat}%2C${lon}&navigate=yes`;

                window.open(url, '_blank');
              }}
              variant="contained"
              color="primary"
              size={'small'}
              fullWidth
              style={stylesContainer.sectionButton}>
              NAVIGATE VIA WAZE
            </Button>
          </>
        )}

        {activeOrder && allowStartTask && !taskCompleted && !startTask && (
          <Button
            onClick={() => setStartTask(true)}
            variant="outlined"
            color="error"
            size={'small'}
            fullWidth
            style={stylesContainer.sectionButton}
            disabled={activeTask && activeTask != id}>
            Start Task
          </Button>
        )}

        {!taskCompleted && startTask && (
          <Button
            onClick={() => handleTaskCompleted()}
            variant="contained"
            color="error"
            size={'small'}
            fullWidth
            style={stylesContainer.sectionButton}>
            MARK AS COMPLETE TASK
          </Button>
        )}

        <Button
          onClick={onClick}
          variant="outlined"
          color="primary"
          size={'small'}
          fullWidth
          style={stylesContainer.sectionButton}>
          {label}
        </Button>
      </div>
    </>
  );
};

const PackageContainer = ({ data }) => {
  const { token } = useContext(UserContext);
  const [url, setUrl] = useState(null);

  useEffect(() => {
    loadFile();
  }, []);

  const loadFile = () => {
    showFile(token, data?.packagePhoto).then(res => {
      setUrl(res?.data?.file || null);
    });
  };

  return (
    <div style={stylesContainer.sectionContainerAddress}>
      <p style={stylesContainer.sectionHeader}>
        <strong>Parcel Information</strong>
      </p>
      {url && (
        <p style={stylesContainer.sectionData}>
          <img src={url} style={stylesContainer.packageImage} />
        </p>
      )}
      <p style={stylesContainer.sectionData}>
        <strong>Parcel Description: </strong>
        <br />
        {data?.packageDescription || ''}
      </p>
    </div>
  );
};

const PricingContainer = ({ data, type }) => {
  const [showDetails, setShowDetails] = useState(false);

  const RenderCod = () => {
    if (!data.totalCodAmount || data.totalCodAmount == 0) {
      return null;
    }

    return (
      <>
        <div>
          COD Amount:{' '}
          <span style={{ float: 'right' }}>
            PHP {moneyFormat(data?.totalCodAmount || 0)}
          </span>
        </div>
      </>
    );
  };

  const RenderEarnings = () => {
    const tax = data?.subTotal * 0.12;
    const driverEarning = 15;
    const bankFee = data?.paymentProcessorFee || 0;
    const adminFee = data?.subTotal - tax + bankFee - driverEarning;
    const totalTransactionFee = tax + adminFee;
    const total = totalTransactionFee + driverEarning;

    const floatNum = num => {
      return Math.round((num + Number.EPSILON) * 100) / 100;
    };

    if (floatNum(total) === data?.subTotal) {
      console.info('Total is correct.');
    } else {
      console.error('Total is incorrect.', floatNum(total), data?.subTotal);
    }

    return (
      <>
        <div style={{ color: 'red' }}>
          <div>
            Admin Fee:{' '}
            <span style={{ float: 'right' }}>
              - PHP {moneyFormat(adminFee || 0)}
            </span>
          </div>
          <div>
            VAT:{' '}
            <span style={{ float: 'right' }}>
              - PHP {moneyFormat(tax || 0)}
            </span>
          </div>
          <div style={{ fontWeight: 'bold' }}>
            Total Transaction Fee:{' '}
            <span style={{ float: 'right' }}>
              - PHP {moneyFormat(totalTransactionFee || 0)}
            </span>
          </div>
        </div>

        <div style={{ fontWeight: 'bold', color: 'green' }}>
          Your Earning:{' '}
          <span style={{ float: 'right' }}>
            PHP {moneyFormat(driverEarning || 0)}
          </span>
        </div>
      </>
    );
  };

  return (
    <div
      style={stylesContainer.sectionContainerAddress}
      onClick={() => setShowDetails(!showDetails)}>
      <div>
        Payment Type:{' '}
        <span style={{ float: 'right' }}>{toTitleCase(type)}</span>
      </div>
      <div>
        Sub Total:{' '}
        <span style={{ float: 'right' }}>
          PHP {moneyFormat(data?.subTotal || 0)}{' '}
        </span>
      </div>
      <RenderCod />
      <div style={{ fontSize: '25px' }}>
        Total:{' '}
        <span style={{ float: 'right' }}>
          PHP {moneyFormat(data?.overallTotal || 0)}
        </span>
      </div>
      {showDetails && <RenderEarnings />}
    </div>
  );
};

const ProofContainer = ({ label, data }) => {
  const { token } = useContext(UserContext);
  const [url, setUrl] = useState(null);

  useEffect(() => {
    loadFile();
  }, []);

  const loadFile = () => {
    showFile(token, data?.packagePhoto).then(res => {
      setUrl(res?.data?.file || null);
    });
  };

  return (
    <div style={stylesContainer.sectionContainerAddress}>
      <p style={stylesContainer.sectionHeader}>
        <strong>{label}</strong>
      </p>
      {url && (
        <p style={stylesContainer.sectionData}>
          <img src={url} style={stylesContainer.packageImage} />
        </p>
      )}
      <p style={stylesContainer.sectionData}>
        <strong>Note: </strong>
        <br />
        {data?.packageDescription || ''}
      </p>
    </div>
  );
};

const DropoffContainer = ({ title, data }) => {
  return (
    <>
      <div style={stylesContainer.sectionContainerAddress}>
        <div style={stylesContainer.sectionHeader}>
          <strong>{title}</strong>
        </div>
        <>
          <p style={stylesContainer.sectionData}>
            {data?.info?.addressLine1} {data?.info?.addressLine2 || ''}{' '}
            {data?.info.city} {data?.info.province} {data?.info.zipCode}
          </p>
        </>
      </div>
    </>
  );
};

const QRCodeContainer = ({ id, data }) => {
  return (
    <>
      <div
        style={{ background: 'white', padding: '16px', textAlign: 'center' }}>
        <QRCode
          value={`${data?.verification}:${data?.['reference-number']}:${id}`}
        />
        <div>{data?.verification}</div>
      </div>
    </>
  );
};
