import axios from 'axios';
import { checkErrorResponse } from '../utils/helpers';

export const create = payload => {
  const headers = {
    'Content-Type': 'application/json',
  };

  return axios
    .post(`${process.env.REACT_APP_API}/admin-management/`, payload, {
      headers,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(error => {
      return checkErrorResponse(error.response);
    });
};