import React, { useState, useContext, useEffect, useRef } from 'react';
import Navbar from '../../../components/DesktopNavbar';
import { mainMenu } from '../../../config/dropoffMenu.jsx';
import { status as statusLabels } from '../../../config/regularStatus';
import { isMobile } from 'react-device-detect';
import { show as showFile } from '../../../services/file';
import { UserContext } from '../../../context/UserProvider';
import QRCodeScanner from '../../../components/QrCodeScanner';
import { moneyFormat, toTitleCase } from '../../../utils/helpers';
import { styles } from '../../../config/defaultPageStyles';
import {
  showRegular as show,
  acceptByDropoff,
  acceptByMainDropoff,
  updateRegular as update,
  listRegular as list,
} from '../../../services/order';
import {  accountBalance } from '../../../services/user';
import CallIcon from '@mui/icons-material/Call';
import { useNavigate, useParams } from 'react-router-dom';
import QRCode from 'react-qr-code';
import { isDesktop } from 'react-device-detect';
import { Button, FormControl, TextField } from '@mui/material';

const stylesContainer = {
  sectionContainerAddress: {
    textAlign: 'left',
    backgroundColor: '#dedede',
    border: 'solid 1px #b7b7b7',
    padding: '5px 10px 15px 10px',
    borderRadius: '10px',
    marginBottom: '10px',
    fontSize: '15px',
    color: '#494949',
  },
  sectionContatinerDriver: {
    textAlign: 'left',
    backgroundColor: '#ffff',
    border: 'solid 1px #b7b7b7',
    padding: '5px 10px 15px 10px',
    borderRadius: '10px',
    marginBottom: '10px',
    textAlign: 'center',
  },
  sectionHeader: {
    marginBottom: '0px',
    marginTop: '0px',
  },
  sectionData: {
    marginBottom: '0px',
    marginTop: '0px',
  },
  sectionDataTo: {
    fontWeight: 'bold',
  },
  sectionButton: {
    marginTop: '10px',
  },
  packageImage: {
    width: '100%',
    borderRadius: '10px',
    marginTop: '10px',
  },
};

let regularLabels = {
  door2door: 'Door 2 Door',
  dropoff2dropoff: 'Dropoff 2 Dropoff',
  dropoff2door: 'Dropoff 2 Door',
  door2dropoff: 'Door 2 Dropoff',
};

const App = () => {
  const navigate = useNavigate();
  let { id, code } = useParams();
  const { token, userAttr } = useContext(UserContext);
  const [transferCode, setTransferCode] = useState(null);
  const [orderId, setOrderId] = useState(null);
  const [jobOrder, setJobOrder] = useState(null);
  const [dropoffType, setDropoffType] = useState(null);
  const [userId, setUserId] = useState(null);
  const refOrderNumber = useRef(null);
  const refCode = useRef(null);

  useEffect(() => {
    if (userAttr) {
      let userAttribute = JSON.parse(userAttr);
      setDropoffType(userAttribute?.type);
      setUserId(userAttribute?.i ?? null);
    }
  }, [userAttr]);

  const onChangeHandler = data => {
    console.log('SCAN DATA', data);

    if (data) {
      const qrCodeData = data.split(':');
      const code = qrCodeData[0];
      const id = qrCodeData[2];
      setTransferCode(code);
      setOrderId(id);
    }
  };

  const loadOrder = () => {
    show(token, orderId).then(res => {
      setJobOrder(res.data);
    });
  };

  useEffect(() => {
    if (orderId) {
      loadOrder();
    }
  }, [orderId]);

  useEffect(() => {
    if (id && code) {
      setTransferCode(code);
      setOrderId(id);
    }
  }, []);

  useEffect(() => {
    console.log('jobOrder', jobOrder);
  }, [jobOrder]);

  const handleSubDropoffAccept = () => {
    window.confirm('Are you sure you want to mark this order as received?') &&
      acceptByDropoff(
        token,
        {
          type: 'origin',
          from: 'driver',
        },
        orderId,
      ).then(res => {
        if (res?.status == 'success') {
          loadOrder();
        } else if (res?.status == 'error') {
          alert(res?.message ?? 'Something went wrong. Please try again.');
        }
      });
  };

  const handleMainDropoffOriginAccept = () => {
    window.confirm(
      'Are you sure you want to mark this order as received by main dropoff?',
    ) &&
      acceptByMainDropoff(
        token,
        {
          type: 'origin',
          from: 'sub',
        },
        orderId,
      ).then(res => {
        loadOrder();
      });
  };

  const handleSubDropoffReceived = () => {
    window.confirm(
      'Are you sure you want to mark this order as received by sub dropoff?',
    ) &&
      acceptByDropoff(
        token,
        {
          type: 'destination',
          from: 'main',
        },
        orderId,
      ).then(res => {
        if (res?.status == 'success') {
          loadOrder();
        } else if (res?.status == 'error') {
          alert(res?.message ?? 'Something went wrong. Please try again.');
        }
      });
  };

  const handleSubDropoffDelivery = () => {
    window.confirm(
      'Are you sure you want to mark this order as ready for delivery?',
    ) &&
      update(
        token,
        {
          status: 'for delivery',
        },
        orderId,
      ).then(res => {
        loadOrder();
      });
  };

  const handleSubDropoffPickup = () => {
    window.confirm(
      'Are you sure you want to mark this order as ready for customer pickup?',
    ) &&
      update(
        token,
        {
          status: 'for pickup',
        },
        orderId,
      ).then(res => {
        loadOrder();
      });
  };

  const handleDesktopProcess = () => {
    list(token, {
      fields: {
        'reference-number': refOrderNumber.current.value,
      },
      operator: '=',
      'user-specific': false,
      'current-page': 1,
      'record-per-page': 'all',
    }).then(res => {
      if (res['data-total'] == 0) {
        alert('Order not found');
        return;
      }

      if (res.collection[0]?.data?.data?.verification == refCode.current.value) {
        setTransferCode(refCode.current.value);
        setOrderId(res.collection[0].raw.id);
      } else {
        alert('Invalid verification code');
      }
    });
  };

  const handleMainDropoffDirectAccept = () => {
    window.confirm('Are you sure you want to mark this order as received?') &&
      acceptByMainDropoff(
        token,
        {
          type: 'origin',
          from: 'customer',
        },
        orderId,
      ).then(res => {
        if (res?.status == 'success') {
          loadOrder();
        } else if (res?.status == 'error') {
          alert(res?.message ?? 'Something went wrong. Please try again.');
        }
      });
  }

  const handleMainDropoffDispatchDirectly = () => {
    accountBalance(token).then(res => {
      let walletBalance = res?.balance ?? 0;
      let totalAmount = jobOrder?.total ?? 0;
      let paymentType = jobOrder?.['payment-type'] ?? null;

      if (paymentType == 'cod') {
        if (totalAmount == 0) {
          alert('You can\'t dispatch this order. Please contact admin for support.');
          console.error('Total amount is 0', jobOrder);
          return;
        } else if (walletBalance < totalAmount ) {
          alert('Insufficient balance. Please reload your wallet.');
          return;
        }
      }

      if (window.confirm('Are you sure you want to dispatch this order directly?')) {
        jobOrder?.type == 'dropoff2dropoff' ||
        jobOrder?.type == 'door2dropoff'
          ? handleSubDropoffPickup()
          : handleSubDropoffDelivery()
      }
    });
  };

  if (isDesktop && !transferCode) {
    return (
      <>
        <Navbar pages={mainMenu} />
        <div
          style={{
            margin: '10px',
          }}>
          <div
            style={{
              width: '400px',
              margin: 'auto',
              marginTop: '20px',
              textAlign: 'center',
            }}>
            <strong>Enter Order Ref Number and Verification Code</strong>
            <p>
              Note: you can open this page in scanner mode using your mobile
              device
            </p>
            <FormControl fullWidth>
              <TextField
                label="Order Ref # *"
                variant="outlined"
                type={'text'}
                inputRef={refOrderNumber}
              />
              <TextField
                label="Verification # *"
                variant="outlined"
                type={'text'}
                inputRef={refCode}
              />
              <Button
                onClick={() => handleDesktopProcess()}
                variant="contained"
                color="primary"
                size={'small'}
                fullWidth={false}>
                Submit
              </Button>
            </FormControl>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Navbar pages={mainMenu} />
      <div
        style={{
          margin: '10px',
        }}>
        {!jobOrder ? (
          <div style={!isMobile ? { width: '300px', margin: 'auto' } : {}}>
            <QRCodeScanner onChange={onChangeHandler} />
          </div>
        ) : (
          <>
            <div
              style={
                isMobile
                  ? styles.pageContainer
                  : {
                      width: '500px',
                      margin: 'auto',
                    }
              }>
              <div style={{ ...styles.pageHeader, marginBottom: '10px' }}>
                Regular Order Information
                <br />
                <small style={{ fontSize: '12px' }}>
                  {regularLabels[jobOrder?.type] ?? ''} -{' '}
                  {jobOrder?.status == 'active'
                    ? 'Waiting for the Parcel'
                    : statusLabels[jobOrder?.status] ??
                      toTitleCase(jobOrder?.status)}
                </small>
                <br />
                <small style={{ fontSize: '12px' }}>
                  ORDER # {jobOrder?.['reference-number']}
                </small>
              </div>
              <QRCodeContainer id={orderId} data={jobOrder} />
              <PackageContainer data={jobOrder?.data?.package || {}} />
              <PricingContainer
                data={jobOrder?.data?.pricing}
                type={jobOrder?.['payment-type']}
              />
              <AddressContainer
                id={'origin'}
                title={
                  jobOrder?.data?.dropoffOrigin
                    ? 'Sender Details'
                    : 'Origin Location'
                }
                data={jobOrder?.data?.sender || {}}
              />
              {jobOrder?.data?.dropoffOrigin && (
                <DropoffContainer
                  title={'Dropoff Location for Origin'}
                  data={jobOrder?.data?.dropoffOrigin}
                />
              )}
              {jobOrder?.data?.dropoffDestination && (
                <DropoffContainer
                  title={'Dropoff Location for Destination'}
                  data={jobOrder?.data?.dropoffDestination}
                />
              )}

              <AddressContainer
                id={'destination'}
                title={
                  jobOrder?.data?.dropoffDestination
                    ? 'Receiver Details'
                    : 'Destination Location'
                }
                data={jobOrder?.data?.receiver || {}}
              />

              <div style={{ marginBottom: '10px' }}>
                {/* STEP 1 - TO ACCEPT */}
                {['active', 'picked up'].includes(jobOrder?.status) && (
                  <>
                    <Button
                      onClick={() => dropoffType == 'sub' 
                        ? handleSubDropoffAccept() 
                        : handleMainDropoffDirectAccept()
                      }
                      variant="contained"
                      color="primary"
                      size={'small'}
                      fullWidth
                      style={stylesContainer.sectionButton}>
                      Mark as Received
                    </Button>

                    <Button
                      onClick={() => {
                        setJobOrder(null);
                      }}
                      variant="contained"
                      color="primary"
                      size={'small'}
                      fullWidth
                      style={stylesContainer.sectionButton}>
                      Re-Scan
                    </Button>
                  </>
                )}

                {dropoffType == 'sub' && (
                  <>
                    {['dropoff destination main'].includes(
                      jobOrder?.status,
                    ) && (
                      <>
                        <Button
                          onClick={() => handleSubDropoffReceived()}
                          variant="contained"
                          color="primary"
                          size={'small'}
                          fullWidth
                          style={stylesContainer.sectionButton}>
                          Mark as Received by Sub Dropoff
                        </Button>
                      </>
                    )}

                    {['dropoff destination'].includes(jobOrder?.status) && (
                      <>
                        <Button
                          onClick={() =>
                            jobOrder?.type == 'dropoff2dropoff' ||
                            jobOrder?.type == 'door2dropoff'
                              ? handleSubDropoffPickup()
                              : handleSubDropoffDelivery()
                          }
                          variant="contained"
                          color="primary"
                          size={'small'}
                          fullWidth
                          style={stylesContainer.sectionButton}>
                          {jobOrder?.type == 'dropoff2dropoff' ||
                          jobOrder?.type == 'door2dropoff'
                            ? 'Ready For Pickup'
                            : 'Ready For Delivery'}
                        </Button>
                      </>
                    )}

                    {['for pickup'].includes(jobOrder?.status) && (
                      <>
                        <Button
                          onClick={() => {
                            navigate(`/dropoff/parcel/${orderId}/pickup`);
                          }}
                          variant="contained"
                          color="primary"
                          size={'small'}
                          fullWidth
                          style={stylesContainer.sectionButton}>
                          Mark as Picked-up
                        </Button>
                      </>
                    )}
                  </>
                )}

                {dropoffType == 'main' && (
                  <>
                    {/* STEP 2 - Receive By Main */}
                    {['dropoff origin'].includes(jobOrder?.status) && (
                      <>
                        <Button
                          onClick={() => handleMainDropoffOriginAccept()}
                          variant="contained"
                          color="primary"
                          size={'small'}
                          fullWidth
                          style={stylesContainer.sectionButton}>
                          Mark as Received By Main Dropoff - Origin
                        </Button>
                      </>
                    )}

                    {/* STEP 3 - Ready to assign to manifest */}
                    {['dropoff origin main'].includes(jobOrder?.status) && (
                      <>
                        {jobOrder['dropoff-origin-main-id'] == userId ? (
                          <>
                            <Button
                              onClick={() => {
                                navigate(`/dropoff/parcel/${orderId}/manifest`);
                              }}
                              variant="contained"
                              color="primary"
                              size={'small'}
                              fullWidth
                              style={stylesContainer.sectionButton}>
                              {jobOrder['manifest-id']
                                ? 'Update Manifest'
                                : 'Assigned to Manifest'}
                            </Button>
                          </>
                        ) : (
                          <>
                            <div style={{ textAlign: 'center' }}>
                              {' '}
                              This order was accepted by other main dropoff
                            </div>
                          </>
                        )}
                      </>
                    )}

                    {/* Prepare by Main Dropoff for dispatch */}
                    {['dropoff destination main'].includes(jobOrder?.status) && (
                      <>
                        {jobOrder['dropoff-destination-main-id'] == userId ? (
                          <>
                            <Button
                              onClick={() => handleMainDropoffDispatchDirectly()}
                              variant="contained"
                              color="primary"
                              size={'small'}
                              fullWidth
                              style={stylesContainer.sectionButton}
                            >
                              Dispatch Directly
                            </Button>
                          </>
                        ) : (
                          <>
                            <div style={{ textAlign: 'center' }}>
                              {' '}
                              This order was accepted by other main dropoff
                            </div>
                          </>
                        )}
                      </>
                    )}


                    {/* Ready for Customer Pickup */}
                    {['for pickup'].includes(jobOrder?.status) && (
                      <>
                        <Button
                          onClick={() => {
                            navigate(`/dropoff/parcel/${orderId}/pickup`);
                          }}
                          variant="contained"
                          color="primary"
                          size={'small'}
                          fullWidth
                          style={stylesContainer.sectionButton}>
                          Mark as Picked-up
                        </Button>
                      </>
                    )}
                  </>
                )}

                <Button
                  onClick={() => {
                    window.open(`/dropoff/waybill/${orderId}`, '_blank');
                  }}
                  variant="contained"
                  color="primary"
                  size={'small'}
                  fullWidth
                  style={{
                    ...stylesContainer.sectionButton,
                    marginBottom: '20px',
                  }}>
                  PRINT WAYBILL
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default App;

const PackageContainer = ({ data }) => {
  const { token } = useContext(UserContext);
  const [url, setUrl] = useState(null);

  useEffect(() => {
    if (data?.packagePhoto) {
      console.log('load file', data);
      loadFile();
    }
  }, []);

  const loadFile = () => {
    showFile(token, data?.packagePhoto).then(res => {
      setUrl(res?.data?.file || null);
    });
  };

  return (
    <div style={stylesContainer.sectionContainerAddress}>
      <p style={stylesContainer.sectionHeader}>
        <strong>Parcel Information</strong>
      </p>
      {url && (
        <p style={stylesContainer.sectionData}>
          <img src={url} style={stylesContainer.packageImage} />
        </p>
      )}
      <p style={stylesContainer.sectionData}>
        <strong>Parcel Description: </strong>
        <br />
        {data?.packageDescription || ''}
      </p>
      <p style={stylesContainer.sectionData}>
        <strong>Parcel Weight: </strong>
        <br />
        {data?.packageWeight || 'N/A'} KG
      </p>
    </div>
  );
};

const PricingContainer = ({ data, type }) => {
  const [showDetails, setShowDetails] = useState(false);

  const RenderCod = () => {
    if (!data.totalCodAmount || data.totalCodAmount == 0) {
      return null;
    }

    return (
      <>
        <div>
          COD Amount:{' '}
          <span style={{ float: 'right' }}>
            PHP {moneyFormat(data?.totalCodAmount || 0)}
          </span>
        </div>
      </>
    );
  };

  const RenderEarnings = () => {
    const tax = data?.subTotal * 0.12;
    const driverEarning = 15;
    const bankFee = data?.paymentProcessorFee || 0;
    const adminFee = data?.subTotal - tax + bankFee - driverEarning;
    const totalTransactionFee = tax + adminFee;
    const total = totalTransactionFee + driverEarning;

    const floatNum = num => {
      return Math.round((num + Number.EPSILON) * 100) / 100;
    };

    if (floatNum(total) === data?.subTotal) {
      console.info('Total is correct.');
    } else {
      console.error('Total is incorrect.', floatNum(total), data?.subTotal);
    }

    return (
      <>
        <div style={{ color: 'red' }}>
          <div>
            Admin Fee:{' '}
            <span style={{ float: 'right' }}>
              - PHP {moneyFormat(adminFee || 0)}
            </span>
          </div>
          <div>
            VAT:{' '}
            <span style={{ float: 'right' }}>
              - PHP {moneyFormat(tax || 0)}
            </span>
          </div>
          <div style={{ fontWeight: 'bold' }}>
            Total Transaction Fee:{' '}
            <span style={{ float: 'right' }}>
              - PHP {moneyFormat(totalTransactionFee || 0)}
            </span>
          </div>
        </div>

        <div style={{ fontWeight: 'bold', color: 'green' }}>
          Your Earning:{' '}
          <span style={{ float: 'right' }}>
            PHP {moneyFormat(driverEarning || 0)}
          </span>
        </div>
      </>
    );
  };

  return (
    <div
      style={stylesContainer.sectionContainerAddress}
      onClick={() => setShowDetails(!showDetails)}>
      <div>
        Payment Type:{' '}
        <span style={{ float: 'right' }}>{toTitleCase(type)}</span>
      </div>
      <div>
        Sub Total:{' '}
        <span style={{ float: 'right' }}>
          PHP {moneyFormat(data?.subTotal || 0)}{' '}
        </span>
      </div>
      <RenderCod />
      <div style={{ fontSize: '25px' }}>
        Total:{' '}
        <span style={{ float: 'right' }}>
          PHP {moneyFormat(data?.overallTotal || 0)}
        </span>
      </div>
      {showDetails && <RenderEarnings />}
    </div>
  );
};

const AddressContainer = ({
  id,
  title,
  data,
  activeOrder,
  activeTask,
  setActiveTask,
  completeTaskHandler,
  allowStartTask,
}) => {
  const [show, setShow] = useState(false);
  const [taskCompleted, setTaskCompleted] = useState(
    data?.taskCompleted ?? false,
  );
  const [startTask, setStartTask] = useState(false);
  const label = show ? 'Hide' : 'View';

  const onClick = () => {
    setShow(!show);
  };

  useEffect(() => {
    if (activeTask == id) {
      setStartTask(true);
    } else {
      setStartTask(false);
    }
  }, [id, activeTask]);

  useEffect(() => {
    if (startTask) {
      setActiveTask(id);
    }
  }, [startTask]);

  const handleTaskCompleted = () => {
    setTaskCompleted(true);
    setActiveTask(null);
    completeTaskHandler(id);
  };

  return (
    <>
      <div style={stylesContainer.sectionContainerAddress}>
        <div style={stylesContainer.sectionHeader}>
          <strong>{title}</strong>
        </div>
        {!show ? (
          <>
            <p style={stylesContainer.sectionData}>
              {data?.addressLine1} {data?.addressLine2 || ''} {data.city}{' '}
              {data.province} {data.zipCode}
            </p>
          </>
        ) : (
          <>
            <table>
              <tbody>
                <tr>
                  <td style={stylesContainer.sectionDataTo}>Name: </td>
                  <td>{data?.name || ''}</td>
                </tr>
                <tr>
                  <td style={stylesContainer.sectionDataTo}>Phone: </td>
                  <td>{data?.contactNumber || ''}</td>
                </tr>
                <tr>
                  <td style={stylesContainer.sectionDataTo}>Address: </td>
                  <td></td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    {data?.addressLine1 || ''} {data?.addressLine1 || ''}{' '}
                    {data?.city || ''} {data?.province || ''}{' '}
                    {data?.zipCode || ''}
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        )}
        {show && activeOrder && (
          <>
            <Button
              startIcon={<CallIcon />}
              href="tel:09999999999"
              variant="contained"
              color="primary"
              size={'small'}
              fullWidth
              style={stylesContainer.sectionButton}>
              CALL
            </Button>
            <Button
              onClick={() => {
                const lat = data.lat;
                const lon = data.lng;
                const url = `https://www.google.com/maps/search/?api=1&query=${lat},${lon}`;

                window.open(url, '_blank');
              }}
              variant="contained"
              color="primary"
              size={'small'}
              fullWidth
              style={stylesContainer.sectionButton}>
              NAVIGATE VIA GOOGLE MAP
            </Button>
            <Button
              onClick={() => {
                const lat = data.lat;
                const lon = data.lng;
                const url = `https://waze.com/ul?ll=${lat}%2C${lon}&navigate=yes`;

                window.open(url, '_blank');
              }}
              variant="contained"
              color="primary"
              size={'small'}
              fullWidth
              style={stylesContainer.sectionButton}>
              NAVIGATE VIA WAZE
            </Button>
          </>
        )}

        {activeOrder && allowStartTask && !taskCompleted && !startTask && (
          <Button
            onClick={() => setStartTask(true)}
            variant="outlined"
            color="error"
            size={'small'}
            fullWidth
            style={stylesContainer.sectionButton}
            disabled={activeTask && activeTask != id}>
            Start Task
          </Button>
        )}

        {!taskCompleted && startTask && (
          <Button
            onClick={() => handleTaskCompleted()}
            variant="contained"
            color="error"
            size={'small'}
            fullWidth
            style={stylesContainer.sectionButton}>
            MARK AS COMPLETE TASK
          </Button>
        )}

        <Button
          onClick={onClick}
          variant="outlined"
          color="primary"
          size={'small'}
          fullWidth
          style={stylesContainer.sectionButton}>
          {label}
        </Button>
      </div>
    </>
  );
};

const DropoffContainer = ({ title, data }) => {
  return (
    <>
      <div style={stylesContainer.sectionContainerAddress}>
        <div style={stylesContainer.sectionHeader}>
          <strong>{title}</strong>
        </div>
        <>
          <p style={stylesContainer.sectionData}>
            {data?.info?.addressLine1} {data?.info?.addressLine2 || ''}{' '}
            {data?.info.city} {data?.info.province} {data?.info.zipCode}
          </p>
        </>
      </div>
    </>
  );
};

const QRCodeContainer = ({ id, data }) => {

  return (
    <>
      <div
        style={{ background: 'white', padding: '16px', textAlign: 'center' }}>
        <QRCode
          value={`${data?.verification ?? data?.data?.verification}:${data?.['reference-number']}:${id}`}
        />
        <div>{data?.verification ?? data?.data?.verification}</div>
      </div>
    </>
  );
};
