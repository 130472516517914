import axios from 'axios';
import { checkErrorResponse } from '../utils/helpers';

export const list = (token, payload) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  return axios
    .post(`${process.env.REACT_APP_API}/wallet-management/list`, payload, {
      headers,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(error => {
      return checkErrorResponse(error.response);
    });
};

export const create = (token, payload) => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
  
    return axios
      .post(`${process.env.REACT_APP_API}/wallet-management`, payload, {
        headers,
      })
      .then(function (response) {
        return response.data;
      })
      .catch(error => {
        return checkErrorResponse(error.response);
      });
  };