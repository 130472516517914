import Select from 'react-select';

const App = props => {
  const optionsSorted = props.options.sort((a, b) => a.label.localeCompare(b.label));

  return (
    <>
      <Select
        {...props}
        options={optionsSorted}
        styles={{
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
          control: (provided, state) => ({
            ...provided,
            background: '#fff',
            borderColor: '#9e9e9e',
            height: '55px',
            boxShadow: state.isFocused ? null : null,
          }),
          valueContainer: (provided, state) => ({
            ...provided,
            height: '55px',
            padding: '0 6px',
          }),
          input: (provided, state) => ({
            ...provided,
            margin: '0px',
          }),
          indicatorSeparator: state => ({
            display: 'none',
          }),
          indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '55px',
          }),
        }}
      />
    </>
  );
};

export default App;
