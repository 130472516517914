import React, { useEffect, useState, useContext } from 'react';
import Select from '../../components/FormSelect';
import { Grid, Button, FormControl, TextField } from '@mui/material';
import { getList } from '../../services/dropoff';
import { UserContext } from '../../context/UserProvider';
import LoadingScreen from '../LoadingScreen';

const styles = {
  title: {
    textAlign: 'center',
    fontSize: '1.2rem',
    paddingTop: '15px',
    paddingBottom: '15px',
  },
  addressInformation: {
    textAlign: 'left',
    width: '100%',
    marginTop: '50px',
    background: '#ececec',
    padding: '10px',
    borderRadius: '10px',
    border: 'solid 1px gainsboro',
  },
  actionButton: {
    width: '100%',
    //always on bottom
    position: 'absolute',
    bottom: '0',
    left: '0',
    padding: '10px',
  },
};

const App = ({
  labelForm,
  description,
  userData,
  nextHandler,
  backHandler,
}) => {
  const { token } = useContext(UserContext);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectionOptions, setSelectionOptions] = useState(null);

  const loadList = () => {
    getList(token, {
      'current-page': 1,
      'record-per-page': 'all',
      'operator': '=',
      'user-specific': false,
      'fields': {
        'status': 'active'
      }
    }).then((res) => {
      try {
        const summary = [];
        res?.collection.forEach(element => {
          summary.push({
            value: element['user-id'],
            label: element['address-province'] + ', ' + element['address-city'],
            info: {
              contact: element['first-name'] + ' ' + element['last-name'],
              address1: element['address-line-1'],
              address2: element['address-line-2'],
              province: element['address-province'],
              city: element['address-city'],
              zipCode: element['address-zip-code'],
              phone: element['contact'],
            },
          });
        });

        summary.sort((a, b) => (a.label > b.label ? 1 : -1));
        setSelectionOptions(summary);
      } catch (error) {
        console.log(error);
      }
    });
  }

  const nextStep = () => {
    if (!selectedOption) {
      alert('Please select a dropoff location.');
      return;
    }

    nextHandler(selectedOption);
  }

  useEffect(() => {
    loadList();
  }, []);

  if (!selectionOptions) {
    return <LoadingScreen />;
  }

  return (
    <Grid container direction="column" alignItems="center">
      <div style={styles.title}>{labelForm}</div>
      <FormControl fullWidth style={{ marginBottom: '10px' }}>
        <Select
          onChange={e => {
            setSelectedOption(e);
          }}
          options={selectionOptions}
          placeholder="Select Dropoff Location *"
          isSearchable={true} 
        />
      </FormControl>
      <small>{description}</small>
      {selectedOption && (
        <div style={styles.addressInformation}>
          <strong>Dropoff Information</strong>
          <p>
            <strong>Contact Person:</strong> {selectedOption?.info?.contact ?? ''}
          </p>
          <p>
            <strong>Phone:</strong> {selectedOption?.info?.phone ?? ''}
          </p>
          <p>
            <strong>Addres:</strong> {selectedOption?.info?.address1 ?? ''}{' '}
            {selectedOption?.info?.address2 ?? ''}
          </p>
          <p>
            <strong>Province:</strong> {selectedOption?.info?.province ?? ''}
          </p>
          <p>
            <strong>City:</strong> {selectedOption?.info?.city ?? ''}
          </p>
          <p>
            <strong>Zip Code:</strong> {selectedOption?.info?.zipCode ?? ''}
          </p>
        </div>
      )}

      <div style={styles.actionButton}>
        <Button
          onClick={() => nextStep()}
          variant="contained"
          className="action"
          fullWidth>
          Next
        </Button>
        <Button
          onClick={() => backHandler()}
          variant="contained"
          className="action"
          fullWidth>
          Back
        </Button>
      </div>
    </Grid>
  );
};

export default App;
