import axios from 'axios';
import { checkErrorResponse } from '../utils/helpers';

export const computationSameDay = (token, payload) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  return axios
    .post(`${process.env.REACT_APP_API}/order/computation/sameday`, payload, {
      headers,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(error => {
      return checkErrorResponse(error.response);
    });
};

export const update = (token, payload, order) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  return axios
    .patch(`${process.env.REACT_APP_API}/order-sameday-management/${order}`, payload, {
      headers,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(error => {
      return checkErrorResponse(error.response);
    });
};

export const show = (token, id) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  return axios
    .get(`${process.env.REACT_APP_API}/order-sameday-management/${id}`, {
      headers,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(error => {
      return checkErrorResponse(error.response);
    });
}

export const list = (token, payload) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  return axios
    .post(`${process.env.REACT_APP_API}/order-sameday-management/list`, payload, {
      headers,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(error => {
      return checkErrorResponse(error.response);
    });
}

export const acceptSameDay = (token, payload, order) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  return axios
    .patch(`${process.env.REACT_APP_API}/order/sameday/${order}`, payload, {
      headers,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(error => {
      return checkErrorResponse(error.response);
    });
};

export const computationRegular = (token, payload) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  return axios
    .post(`${process.env.REACT_APP_API}/order/computation/regular`, payload, {
      headers,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(error => {
      return checkErrorResponse(error.response);
    });
};

export const updateRegular = (token, payload, order) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  return axios
    .patch(`${process.env.REACT_APP_API}/order-regular-management/${order}`, payload, {
      headers,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(error => {
      return checkErrorResponse(error.response);
    });
};

export const listRegular = (token, payload) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  return axios
    .post(`${process.env.REACT_APP_API}/order-regular-management/list`, payload, {
      headers,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(error => {
      return checkErrorResponse(error.response);
    });
}

export const showRegular = (token, id) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  return axios
    .get(`${process.env.REACT_APP_API}/order-regular-management/${id}`, {
      headers,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(error => {
      return checkErrorResponse(error.response);
    });
}

export const orderPaymentProcess =  (token, payload) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  return axios
    .post(`${process.env.REACT_APP_API}/account/wallet/order/process`, payload, { headers })
    .then(function (response) {
      return response.data;
    })
    .catch(error => {
      return checkErrorResponse(error.response);
    });
};

export const orderOwnerChecker = (token, orderId, keys = {}) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  let query = '';
  if (keys) {
    query = Object.keys(keys).map(key => `${key}=${keys[key]}`).join('&');
  }

  return axios
    .get(`${process.env.REACT_APP_API}/order/check/owner/${orderId}?${query}`, {
      headers,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(error => {
      return checkErrorResponse(error.response);
    });
};

export const acceptRegular = (token, payload, order) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  return axios
    .patch(`${process.env.REACT_APP_API}/order/regular/${order}`, payload, {
      headers,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(error => {
      return checkErrorResponse(error.response);
    });
};

export const acceptRegularForDeliver = (token, payload, order) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  return axios
    .patch(`${process.env.REACT_APP_API}/order/regular/${order}/delivery`, payload, {
      headers,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(error => {
      return checkErrorResponse(error.response);
    });
};


export const acceptByDropoff = (token, payload, order) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  return axios
    .patch(`${process.env.REACT_APP_API}/dropoff/order/${order}`, payload, {
      headers,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(error => {
      return checkErrorResponse(error.response);
    });
};


export const acceptByMainDropoff = (token, payload, order) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  return axios
    .patch(`${process.env.REACT_APP_API}/dropoff/main/order/${order}`, payload, {
      headers,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(error => {
      return checkErrorResponse(error.response);
    });
};

export const orderManifestUpdate = (token, manifestId, payload) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  return axios
    .patch(`${process.env.REACT_APP_API}/manifest/${manifestId}/orders`, payload, {
      headers,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(error => {
      return checkErrorResponse(error.response);
    });
};


export const orderManifestAccept = (token, manifestId) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  return axios
    .patch(`${process.env.REACT_APP_API}/manifest/${manifestId}/accept`, {}, {
      headers,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(error => {
      return checkErrorResponse(error.response);
    });
};

