import React, { useContext, useState, useEffect } from 'react';
import Navbar from '../../../components/DesktopNavbar';
import { mainMenu } from '../../../config/dropoffMenu.jsx';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../context/UserProvider';
import { create } from '../../../services/manifest';
import { moneyFormat, toTitleCase } from '../../../utils/helpers';
import { isMobile } from 'react-device-detect';
import { Button } from '@mui/material';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { locationPh } from '../../../config/locationPH.jsx';
import Select from '../../../components/FormSelect';

const App = () => {
  const navigate = useNavigate();
  const { token } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [destination, setDestination] = useState('');
  const [province, setProvince] = useState(null);
  

  const loadLocationConfig = () => {
    const proviceSelection = [];
    const citySelection = [];
    for (const key in locationPh) {
      const value = locationPh[key];
      const provinceIndex = proviceSelection.findIndex(
        item => item.value === value.province,
      );

      if (provinceIndex !== -1) {
        citySelection[value.province].push({
          label: value.municipality,
          value: value.municipality,
        });
      } else {
        proviceSelection.push({
          label: value.province,
          value: value.province,
        });
        citySelection[value.province] = [
          {
            label: value.municipality,
            value: value.municipality,
          },
        ];
      }
    }

    proviceSelection.sort((a, b) => {
      if (a.label < b.label) return -1;
      if (a.label > b.label) return 1;
      return 0;
    });

    setProvince(proviceSelection);
  };

  const handleCreateManifest = () => {
    if (!name) {
      alert('Please enter a manifest name');
      return;
    } else if (!destination) {
      alert('Please select a destination');
      return;
    }

    const data = {
      name: name,
      description: description ?? '',
      destination: destination,
    };

    create(token, data).then(response => {
      navigate('/dropoff/manifest');
    });
  };

  useEffect(() => {
    loadLocationConfig();
  }, []);

  if (!province) {
    return null;
  }

  return (
    <>
      <Navbar pages={mainMenu} />
      <Container maxWidth={'xl'}>
        <Grid container spacing={2} style={{ height: 'auto' }}>
          <Grid item xs={12} sm={6} style={{ height: '100px' }}>
            <h1>Create Manifest</h1>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            style={
              isMobile
                ? { height: '100px' }
                : { height: '100px', paddingTop: '40px' }
            }>
            <Button
              variant="contained"
              onClick={() => handleCreateManifest()}
              style={
                isMobile
                  ? {}
                  : {
                      width: '160px',
                      float: 'right',
                    }
              }>
              Save
            </Button>
            <Button
              variant="contained"
              onClick={() => navigate('/dropoff/manifest')}
              style={
                isMobile
                  ? {}
                  : {
                      width: '160px',
                      float: 'right',
                      marginRight: '10px',
                    }
              }>
              Back
            </Button>
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              label={'Manifest Name'}
              variant="outlined"
              type={'text'}
              onChange={e => {
                setName(e.target.value);
              }}
            />
            <TextField
              label={'Description'}
              variant="outlined"
              type={'text'}
              onChange={e => {
                setDescription(e.target.value);
              }}
            />
            <Select
              onChange={e => {
                setDestination(e.value);
              }}
              options={province}
              placeholder="Select Destination Province"
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default App;
