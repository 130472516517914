import React, { useContext, useState, useEffect } from 'react';
import { styles } from '../../../config/defaultPageStyles';
import { list as listSameday, listRegular } from '../../../services/order';
import { getAccountInformation } from '../../../services/user';
import { Link, useNavigate } from 'react-router-dom';
import { UserContext } from '../../../context/UserProvider';
import { toTitleCase, moneyFormat } from '../../../utils/helpers';
import { Button, Card, Grid, Typography } from '@mui/material';
import { status as sameDayStatus } from '../../../config/sameDayStatus';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import LoadingScreen from '../../../components/LoadingScreen';

let regularLabels = {
  door2door: 'Door 2 Door',
  dropoff2dropoff: 'Dropoff 2 Dropoff',
  dropoff2door: 'Dropoff 2 Door',
  door2dropoff: 'Door 2 Dropoff',
};

const App = () => {
  const { token, logout } = useContext(UserContext);
  const navigate = useNavigate();
  const [sameDayOrders, setSameDayOrders] = useState([]);
  const [regularOrders, setRegularOrders] = useState([]);
  const [vehicle, setVehicle] = useState(null);
  const [province, setProvince] = useState(null);
  const [loading, setLoading] = useState(true);
  const timeAgo = new TimeAgo('en-US');

  const loadSameDayOrders = () => {
    listSameday(token, {
      fields: {
        status: ['active'],
        'sender-province': [province],
      },
      'user-specific': false,
      'per-page': 'all',
      operator: 'in',
    }).then(res => {
      setSameDayOrders(res.collection);
    });
  };

  const loadRegularOrders = () => {
    listRegular(token, {
      fields: {
        status: ['active'],
        type: ['door2door', 'door2dropoff'],
        'sender-province': [province],
      },
      'user-specific': false,
      'per-page': 'all',
      operator: 'in',
    }).then(res => {
      setRegularOrders(res.collection);
    });
  };

  const loadAccountInformation = () => {
    getAccountInformation(token).then(res => {
      setProvince(res?.driver['address-province'] ?? null);
      setVehicle(res?.vehicle?.type ?? null);
    });
  };

  useEffect(() => {
    loadAccountInformation();
  }, []);

  useEffect(() => {
    if (province) {
        loadOrders();
    }
  }, [province]);

  const loadOrders = () => {
    setLoading(true);
    loadSameDayOrders();
    loadRegularOrders();
  }

  useEffect(() => {
    if (sameDayOrders && regularOrders) {
      setLoading(false);
    }
  }, [sameDayOrders, regularOrders]);

  const RenderOrders = () => {
    let renderSameDay = sameDayOrders.map((order, index) => {
      if (order.data.data.vehicle.id != vehicle) {
        return null;
      }

      return (
        <div
          key={`same-day-order-${index}`}
          style={{
            width: '100%',
            backgroundColor: '#F5F5F5',
            padding: '10px',
            marginBottom: '10px',
            borderRadius: '5px',
            border: 'solid 1px gainsboro',
          }}
          onClick={() => {
            navigate(`/driver/orders/sameday/${order.raw.id}`);
          }}>
          <Typography variant="body1">
            Same Day Delivery to {toTitleCase(order.data.data.receiver.city)}
          </Typography>
          <Typography variant="body1">
            Looking for:  {toTitleCase(order.data.data.vehicle.label)}
          </Typography>
          <Typography variant="body2">
            Payment Type: {toTitleCase(order.data['payment-type'])}
          </Typography>
          <Typography variant="body2">
            Total: PHP {moneyFormat(order.data.total)}
          </Typography>
          <Typography variant="body2">
            {timeAgo.format(new Date(order.raw.created_last))}
          </Typography>
        </div>
      );
    });

    let renderRegular = regularOrders.map((order, index) => {
      return (
        <div
          key={`same-day-order-${index}`}
          style={{
            width: '100%',
            backgroundColor: '#F5F5F5',
            padding: '10px',
            marginBottom: '10px',
            borderRadius: '5px',
            border: 'solid 1px gainsboro',
          }}
          onClick={() => {
            navigate(`/driver/orders/regular/${order.raw.id}`);
          }}>
          <Typography variant="body1">
            Regular Delivery to {toTitleCase(order.data.data.receiver.city)}
          </Typography>
          <Typography variant="body2">
            Payment Type: {toTitleCase(order.data['payment-type'])}
          </Typography>
          <Typography variant="body2">
            Total: PHP {moneyFormat(order.data.total)}
          </Typography>
          <Typography variant="body2">
            {timeAgo.format(new Date(order.raw.created_last))}
          </Typography>
        </div>
      );
    });

    renderSameDay = renderSameDay.filter(function (el) {
      return el != null;
    });

    renderRegular = renderRegular.filter(function (el) {
      return el != null;
    });

    if (renderSameDay.length === 0 && renderRegular.length === 0) {
      return (
        <div
          style={{
            width: '100%',
            textAlign: 'center',
            fontSize: '1.2rem',
            paddingTop: '15px',
            paddingBottom: '15px',
          }}>
          No orders in {toTitleCase(province ?? '')}
        </div>
      );
    }

    return (
      <>
        {renderSameDay}
        {renderRegular}
      </>
    );
  };

  return (
    <div style={styles.pageContainer}>
        {loading && <LoadingScreen />}
      <div style={styles.pageHeader}>Orders in  {toTitleCase(province ?? '')}</div>
      <RenderOrders />
      <Button
        onClick={() => loadOrders()}
        variant="contained"
        fullWidth
        style={{ marginTop: '10px' }}>
        Reload
      </Button>
      <Button
        onClick={() => {
          navigate('/driver/orders/scan');
        }}
        variant="contained"
        fullWidth
        style={{ marginTop: '10px' }}
      >
        Get Delivery From Dropoff
      </Button>
      <Button
        onClick={() => navigate('/driver')}
        variant="contained"
        fullWidth
        style={{ marginTop: '10px' }}>
        Back
      </Button>
    </div>
  );
};

export default App;
