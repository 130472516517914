export const mainMenu = {
    sub : [
        {
            label: 'Dashboard',
            path: '/dropoff/dashboard',
            desktop: true,
            mobile: true    
        },
        {
            label: 'Scan',
            path: '/dropoff/scan',
            desktop: true,
            mobile: true
        },
        {
            label: 'My Parcels',
            path: '/dropoff/parcels',
            desktop: true,
            mobile: true
        },
        {
            label: 'Received Parcels',
            path: '/dropoff/received/parcels',
            desktop: true,
            mobile: true    
        },
        {
            label: 'Wallet',
            path: '/dropoff/wallet',
            desktop: true,
            mobile: true    
        }
    ],
    main: [
        {
            label: 'Dashboard',
            path: '/dropoff/dashboard',
            desktop: true,
            mobile: true    
        },
        {
            label: 'Scan',
            path: '/dropoff/scan',
            desktop: true,
            mobile: true
        },
        {
            label: 'My Parcels',
            path: '/dropoff/parcels',
            desktop: true,
            mobile: true
        },
        {
            label: 'My Manifest',
            path: '/dropoff/manifest',
            desktop: true,
            mobile: true    
        },
        {
            label: 'Received Manifest',
            path: '/dropoff/received',
            desktop: true,
            mobile: true    
        },
        {
            label: 'Wallet',
            path: '/dropoff/wallet',
            desktop: true,
            mobile: true    
        }
    ]
};