import React, { useState, useRef, useEffect } from 'react';
import { Grid, Button, Typography } from '@mui/material';
import Uploady, { useUploady } from '@rpldy/uploady';
import UploadPreview from '@rpldy/upload-preview';
import Webcam from 'react-webcam';
import { create } from '../../../services/file';
import { dataURLtoFile } from '../../../utils/helpers';
import ImageUploader from '../../../components/ImageUploader';

const config = {
  license: {
    label: 'Drivers License',
    documentType: 'Drivers License',
    fieldName: 'drivers-license-file-id',
  },
  id: {
    label: 'Other Valid ID',
    documentType: 'Other Valid ID',
    fieldName: 'valid-identification-card-file-id',
  },
  receipt: {
    label: 'Vehicle Official Receipt',
    documentType: 'Vehicle Official Receipt',
    fieldName: 'vehicle-official-receipt-file-id',
  },
  registration: {
    label: 'Certificate of Registration',
    documentType: 'Certificate of Registration',
    fieldName: 'certificate-of-registration-file-id',
  },
};

const CaptureForm = ({
  type,
  stateName,
  userData,
  nextStep,
  prevStep,
  handleFormSubmit,
}) => {
  const photoList = useRef([]);
  const [configData, setConfigData] = useState(null);
  const nextHandler = () => {
    if (photoList.current.length < 1) {
      return alert('Please upload photo');
    }

    handleFormSubmit(stateName, {
      [`${configData.fieldName}-url`]: photoList.current[0].url,
      [`${configData.fieldName}`]: photoList.current[0].id,
    });
    nextStep();
  };

  useEffect(() => {
    photoList.current = [];
    setConfigData(config[type]);

    if (userData[`${config[type].fieldName}-url`]) {
      photoList.current.push({
        name: config[type].label,
        url: userData[`${config[type].fieldName}-url`],
        id: userData[`${config[type].fieldName}`],
      });
    }
  }, [type]);

  return (
    <>
      {configData && (
        <>
          <h2>{configData.label}</h2>
          <ImageUploader
            name={
              configData.label +
              ' - ' +
              userData.firstName +
              ' ' +
              userData.lastName
            }
            type={configData.documentType}
            title={'Please Upload Photo'}
            label={configData.label}
            ref={photoList}
            defaultImage={
              photoList.current[0] ? photoList.current[0].url : null
            }
          />
        </>
      )}
      <div style={{ width: '100%' }}>
        <Button
          onClick={() => nextHandler()}
          variant="contained"
          className="action">
          Next
        </Button>
        <Button onClick={prevStep} variant="contained" className="action">
          Back
        </Button>
      </div>
    </>
  );
};

export default CaptureForm;
