import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: white;
`;

const Content = styled.div`
  text-align: center;
`;

const Heading = styled.h1`
  font-size: 72px;
  color: #393973;
`;

const SubHeading = styled.h2`
  font-size: 32px;
  color: #393973;
`;

const NotFoundPage = () => (
  <Wrapper>
    <Content>
      <Heading>404</Heading>
      <SubHeading>Page Not Found</SubHeading>
    </Content>
  </Wrapper>
);

export default NotFoundPage;
