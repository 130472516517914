import React from 'react';
import LoginForm from '../../../components/LoginForm';

const LoginPage = () => {
  return (
    <LoginForm nextPage={'/dropoff/dashboard'} userType={'dropoff'} />
  );
};

export default LoginPage;
