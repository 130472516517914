import React from 'react';
import LoginForm from '../../../components/LoginForm';

const LoginPage = () => {
  return (
    <LoginForm nextPage={'/driver/dashboard'} userType={'driver'} />
  );
};

export default LoginPage;
