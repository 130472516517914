import React from 'react';

import { Grid, Button, Typography } from '@mui/material';

const Finish = ({ nextStep }) => {
  const onSubmit = e => {
    e.preventDefault();

    nextStep();
  };

  return (
    <Grid container direction="column" alignItems="center">
      <form onSubmit={onSubmit}>
        <Typography
          margin={'auto'}
          alignContent={'center'}
          variant="h6"
          gutterBottom
          textAlign={'center'}
        >
          Thank you for signing up. <br />
        </Typography>
        <div className="action-container">
          <Button type="submit" variant="contained" className="action">
            Finish
          </Button>
        </div>
      </form>
    </Grid>
  );
};

export default Finish;
