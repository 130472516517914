import React from 'react';
import { Grid, Button, Typography } from '@mui/material';
import { login } from '../../services/authentication';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import Fields from '../../components/Fields';
import { FormControlLabel, Switch } from '@mui/material';
import LoadingScreen from '../../components/LoadingScreen';
const config = [
  {
    type: 'textfield',
    id: 'login',
    label: 'Mobile Number',
  },
  {
    type: 'textfield',
    id: 'password',
    label: 'Password',
    isPassword: true,
  },
];

const schema = z.object({
  login: z.string().min(1),
  password: z.string().min(1),
});

const LoginForm = ({ nextStep, handleFormSubmit, userType, allowEmailOtp }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [isChecked, setIsChecked] = React.useState(
    allowEmailOtp ? false : true,
  );
  const { register, control, handleSubmit, formState } = useForm({
    resolver: zodResolver(schema),
  });
  const onSubmit = data => {
    const channel = isChecked ? 'sms' : 'email';
    setIsLoading(true);
    login({
      login: data.login,
      password: data.password,
      type: userType,
      channel: channel,
    })
      .then((response) => {
        if (response.status && response.status === 500) {
          alert('Too many login attempts. Please try again later.');
          setIsLoading(false);
          return;
        } else if (response.status && response.status === 403) { 
          alert('Invalid login or password');
          setIsLoading(false);
          return;
        }

        handleFormSubmit({
          login: data.login,
          password: data.password,
          type: userType,
          channel: channel,
        });

        setIsLoading(false);
        nextStep();
      })
      .catch(() => {
        alert('Invalid login or password');
      });
  };

  const { errors } = formState;
  return (
    <Grid container direction="column" alignItems="center">
      {isLoading && <LoadingScreen />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="center-container">
          <Typography variant="h4">{userType.charAt(0).toUpperCase() + userType.slice(1)} Login</Typography> <br></br>
          <Fields
            config={config}
            errors={errors}
            register={register}
            control={control}></Fields>
          {allowEmailOtp && (
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  defaultChecked={isChecked}
                  onChange={e => setIsChecked(e.target.checked)}
                />
              }
              label={isChecked ? 'OTP Send to SMS' : 'OTP Send to Email'}
              labelPlacement="start"
              style={{ marginBottom: '10px' }}
            />
          )}
          <Button type="submit" variant="contained" className="action">
            Login
          </Button>
          <Link to={`/${userType}/signup`}>
            <Button variant="contained" className="action">
              Register
            </Button>
          </Link>
          <Link to={`/${userType}/recover`}>
            <Typography variant="subtitle1">Forgot Password?</Typography>
          </Link>
        </div>
        <div className="action-container text-center">
          <Link to="/">
            <Typography variant="subtitle1">Back</Typography>
          </Link>
        </div>
      </form>
    </Grid>
  );
};

export default LoginForm;
