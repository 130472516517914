import React from 'react';
import styled from 'styled-components';
import SecurityUpdateGoodIcon from '@mui/icons-material/SecurityUpdateGood';
import QRCode from "react-qr-code";
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: white;
`;

const Content = styled.div`
  text-align: center;
`;

const Heading = styled.h1`
  font-size: 72px;
`;

const SubHeading = styled.h2`
  font-size: 32px;
`;

const NotFoundPage = () => (
  <Wrapper>
    <Content>
      <img src="/logo.png" alt="" />
      <Heading>MOBILE ONLY</Heading>
      <div style={{ background: 'white', padding: '16px', textAlign: 'center'}}>
        <QRCode value={`https://portal.boxtrackcargo.com.ph/`} />
      </div>
      <SubHeading>SCAN FOR MOBILE ACCESS</SubHeading>
      <small>You can only access this page from a mobile device</small>
    </Content>
  </Wrapper>
);

export default NotFoundPage;
