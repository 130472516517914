import React, { useState, useEffect, useContext } from 'react';
import {
  GoogleMap,
  LoadScript,
  Marker,
  DirectionsRenderer,
} from '@react-google-maps/api';
import { Button } from '@mui/material';
import { UserContext } from '../../../context/UserProvider';
import LoadingScreen from '../../../components/LoadingScreen';
import { useNavigate, useParams } from 'react-router-dom';
import { submitGps } from '../../../services/driver';

const containerStyle = {
  width: '100%',
  height: '85vh',
};
const SEND_GPS_INTERVAL = 5000;

const App = ({}) => {
  const navigate = useNavigate();
  let { id, destinationLat, destinationLng } = useParams();
  const { token } = useContext(UserContext);
  const [driverLat, setDriverLat] = useState(null);
  const [driverLng, setDriverLng] = useState(null);
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [directions, setDirections] = useState(null);
  const [error, setError] = useState(null);
  const [markerData, setMarkerData] = useState(null);
  const [markerDataCustomer, setMarkerDataCustomer] = useState(null);
  const [lastGps, setLastGps] = useState(null);
  const [marketSet, setMarketSet] = useState(false);

  useEffect(() => {
    if (driverLat && driverLng && isMapLoaded && !marketSet) {
      loadDirectons();

      setInterval(() => {
        submitGpsLocation();
      }, SEND_GPS_INTERVAL);
    }
  }, [driverLat, driverLng, isMapLoaded]);

  const loadDirectons = () => {
    if (isMapLoaded === false) {
      return;
    }

    if (window.google) {
      setMarketSet(true);
      const directionsService = new window.google.maps.DirectionsService();
      setMarkerData();
      const targetLat = parseFloat(destinationLat);
      const targetLng = parseFloat(destinationLng);

      setMarkerData({
        url: '/img/boxy.png',
        scaledSize: new window.google.maps.Size(50, 50),
      });

      setMarkerDataCustomer({
        url: '/img/box.png',
        scaledSize: new window.google.maps.Size(50, 50),
      });

      directionsService.route(
        {
          origin: { lat: driverLat, lng: driverLng },
          destination: { lat: targetLat, lng: targetLng },
          travelMode: window.google.maps.TravelMode.DRIVING,
          optimizeWaypoints: true,
          provideRouteAlternatives: true,
          avoidFerries: true,
        },
        (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            setDirections(result);
          } else {
            setError(result);
          }
        },
      );
    }
  };

  const updateLocation = position => {
    setDriverLat(position.coords.latitude);
    setDriverLng(position.coords.longitude);
  };

  const handleError = error => {
    console.warn('Error occurred while tracking location:', error);
  };

  useEffect(() => {
    let watcher = null;
    if ('geolocation' in navigator) {
      watcher = navigator.geolocation.watchPosition(
        updateLocation,
        handleError,
        { enableHighAccuracy: true },
      );
    } else {
      alert('Geolocation is not supported by your browser.');
    }

    return () => {
      if (watcher) {
        navigator.geolocation.clearWatch(watcher);
      }
    };
  }, []);

  const submitGpsLocation = () => {
    if (!navigator.geolocation) {
      setLastGps(null);
      alert('Please enable gps on your device.');
      return;
    }

    navigator.geolocation.getCurrentPosition(function (position) {
      setLastGps(new Date().getTime());
      submitGps(token, {
        order: id,
        currentLocation: {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        },
        destination: {
          lat: destinationLat,
          lng: destinationLng,
        },
      });
    });
  };

  if (!driverLat || !driverLng || !destinationLat || !destinationLng) {
    return <LoadingScreen />;
  }

  return (
    <>
      <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}>
        <style>
          {`
            body,
            html,
            #root,
            .MuiContainer-root {
            margin: 0 !important;
            padding: 0  !important;
            height: 100%;
            width: 100%;
            }
        `}
        </style>
        <GoogleMap
          mapContainerStyle={containerStyle}
          defaultCenter={{ lat: driverLat, lng: driverLng }}
          onLoad={() => setIsMapLoaded(true)}
          onZoomChanged={test => {
            console.log('test', test);
          }}
          center={{ lat: driverLat, lng: driverLng }}
          options={{
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
            panControl: true,
            rotateControl: true,
            gestureHandling: 'greedy',
          }}>
          <Marker
            position={{ lat: driverLat, lng: driverLng }}
            title="Driver's Location"
            icon={markerData}
            optimized={true}
            visible={true}
          />
          <Marker
            position={{
              lat: parseFloat(destinationLat),
              lng: parseFloat(destinationLng),
            }}
            icon={markerDataCustomer}
            title="Destination"
            optimized={true}
            visible={true}
          />
          {directions && (
            <DirectionsRenderer
              directions={directions}
              options={{
                suppressMarkers: true,
              }}
            />
          )}
        </GoogleMap>
      </LoadScript>
      <div
        style={{
          position: 'absolute',
          bottom: '0',
          height: '17vh',
          width: '100%',
          display: 'block',
          justifyContent: 'center',
          padding: '0 1rem 0 1rem',
          backgroundColor: 'white',
        }}>
        <div
          style={{
            marginBottom: '1rem',
            fontSize: '0.8rem',
            textAlign: 'center',
            marginTop: '1rem',
          }}>
          Last Driver Location Update:{' '}
          {lastGps ? new Date(lastGps).toLocaleTimeString() : 'N/A'}
        </div>
        {/* loadDirectons */}
        <Button
          variant="contained"
          color="primary"
          onClick={() => loadDirectons()}
          style={{ marginBottom: '1rem' }}>
          Recalculate Route
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate(-1)}
          style={{ marginBottom: '2rem' }}>
          Back
        </Button>
      </div>
    </>
  );
};

export default App;
