import React, { useState, useRef, useEffect } from 'react';
import { Button, Typography } from '@mui/material';
import ImageUploader from '../../../components/ImageUploader';
import { Switch } from '@mui/material';

const config = {
  title: 'Authorization Letter',
  id: 'vehicleOwner',
  label: 'Are you the vehicle Owner?',
  documentType: 'Authorization Letter',
  fieldName: 'authorization-file-id',
};

const CaptureForm = ({
  stateName,
  userData,
  nextStep,
  prevStep,
  handleFormSubmit,
}) => {
  const photoList = useRef([]);
  const [isOwner, setIsOwner] = useState(null);

  const nextHandler = () => {
    if (!isOwner && photoList.current.length < 1) {
      return alert('Please upload photo');
    }

    const toSaveData = {
      owner: isOwner,
      [`${config.fieldName}-url`]: null,
      [config.fieldName]: null,
    };

    if (!isOwner) {
      toSaveData[`${config.fieldName}-url`] = photoList.current[0].url;
      toSaveData[`${config.fieldName}`] = photoList.current[0].id;
    }

    handleFormSubmit(stateName, toSaveData);
    nextStep();
  };

  useEffect(() => {
    if (userData[`${config.fieldName}-url`]) {
      photoList.current.push({
        name: config.label,
        url: userData[`${config.fieldName}-url`],
        id: userData[`${config.fieldName}`],
      });
    }

    if (userData['owner'] !== undefined) {
      setIsOwner(userData['owner']);
    } else {
      setIsOwner(true);
    }

    console.log('userData', userData);
  }, []);

  useEffect(() => {
    if (isOwner) {
      photoList.current = [];
    }
  }, [isOwner]);

  if (isOwner === null) return null;

  return (
    <>
      <div className="text-center" style={{ marginTop: '20px' }}>
        <Typography variant="h6" gutterBottom>
          {config.label}
        </Typography>
        <Typography variant="body2" gutterBottom>
          {isOwner ? 'Yes I am the owner' : 'No I am not the owner'}
        </Typography>
        <Switch
          color="primary"
          checked={isOwner}
          onChange={e => setIsOwner(e.target.checked)}
        />
      </div>
      {!isOwner && (
        <ImageUploader
          name={config.title}
          type={config.documentType}
          title={'Please Upload Photo'}
          label={config.title}
          ref={photoList}
          defaultImage={photoList.current[0] ? photoList.current[0].url : null}
        />
      )}
      <div style={{ width: '100%' }}>
        <Button
          onClick={() => nextHandler()}
          variant="contained"
          className="action">
          Next
        </Button>
        <Button onClick={prevStep} variant="contained" className="action">
          Back
        </Button>
      </div>
    </>
  );
};

export default CaptureForm;
