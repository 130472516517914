import React, { useState, useContext, useEffect } from 'react';
import Navbar from '../../../components/DesktopNavbar/admin.jsx';
import { mainMenu } from '../../../config/adminMenu.jsx';
import { status as statusLabels } from '../../../config/regularStatus';
import { isMobile } from 'react-device-detect';
import { show as showFile } from '../../../services/file';
import { UserContext } from '../../../context/UserProvider';
import QRCodeScanner from '../../../components/QrCodeScanner';
import { moneyFormat, toTitleCase } from '../../../utils/helpers';
import { styles } from '../../../config/defaultPageStyles';

import { showRegular as show, updateRegular as update } from '../../../services/order';
import { show as showDriver } from '../../../services/driver';
import { show as showDropoff } from '../../../services/dropoff';

import { Button } from '@mui/material';
import CallIcon from '@mui/icons-material/Call';
import { useNavigate, useParams } from 'react-router-dom';
import QRCode from 'react-qr-code';
import LoadingScreen from '../../../components/LoadingScreen';

const stylesContainer = {
  sectionContatinerAddress: {
    textAlign: 'left',
    backgroundColor: '#dedede',
    border: 'solid 1px #b7b7b7',
    padding: '5px 10px 15px 10px',
    borderRadius: '10px',
    marginBottom: '10px',
    fontSize: '15px',
    color: '#494949',
  },
  sectionContatinerDriver: {
    textAlign: 'left',
    backgroundColor: '#ffff',
    border: 'solid 1px #b7b7b7',
    padding: '5px 10px 15px 10px',
    borderRadius: '10px',
    marginBottom: '10px',
    textAlign: 'center',
  },
  sectionHeader: {
    marginBottom: '0px',
    marginTop: '0px',
  },
  sectionData: {
    marginBottom: '0px',
    marginTop: '0px',
  },
  sectionDataTo: {
    fontWeight: 'bold',
  },
  sectionButton: {
    marginTop: '10px',
  },
  packageImage: {
    width: '100%',
    borderRadius: '10px',
    marginTop: '10px',
  },
};

let regularLabels = {
  door2door: 'Door 2 Door',
  dropoff2dropoff: 'Dropoff 2 Dropoff',
  dropoff2door: 'Dropoff 2 Door',
  door2dropoff: 'Door 2 Dropoff',
};

const statusToCancel = ['active', 'draft', 'driver matched'];

const App = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const { token } = useContext(UserContext);
  const [orderId, setOrderId] = useState(null);
  const [jobOrder, setJobOrder] = useState(null);
  const [isAllowedToCancel, setIsAllowedToCancel] = useState(false);
  const [originDriverData, setOriginDriverData] = useState(null);
  const [originSubDropoffData, setOriginSubDropoffData] = useState(null);
  const [originMainDropoffData, setOriginMainDropoffData] = useState(null);
  const [destinationMainDropoffData, setDestinationMainDropoffData] = useState(
    null,
  );
  const [destinationSubDropoffData, setDestinationSubDropoffData] = useState(
    null,
  );
  const [destinationDriverData, setDestinationDriverData] = useState(null);
  const [accountUid, setAccountUid] = useState(null);

  const loadOrder = () => {
    show(token, orderId).then(res => {
      console.log('Order Information', res.data);
      const originDriver = res.data?.['origin-driver-id'] || null;
      const originSubDropoff = res.data?.['dropoff-origin-id'] || null;
      const originMainDropoff = res.data?.['dropoff-origin-main-id'] || null;
      const destinationMainDropoff =
        res.data?.['dropoff-destination-main-id'] || null;
      const destinationSubDropoff =
        res.data?.['dropoff-destination-id'] || null;
      const destinationDriver = res.data?.['destination-driver-id'] || null;

      loadDriver(originDriver, 'origin');
      loadDropoff(originSubDropoff, 'origin', 'sub');
      loadDropoff(originMainDropoff, 'origin', 'main');
      loadDropoff(destinationMainDropoff, 'destination', 'main');
      loadDropoff(destinationSubDropoff, 'destination', 'sub');
      loadDriver(destinationDriver, 'destination');
      setJobOrder(res.data);
      setAccountUid(res.created_by);

      if (statusToCancel.includes(res?.data?.status || res?.status)) {
        setIsAllowedToCancel(true);
      }
    });
  };

  const loadDriver = (driverId, type = 'origin') => {
    if (!driverId) {
      return;
    }

    showDriver(token, driverId).then(res => {
      if (type == 'origin') {
        setOriginDriverData(res);
      } else {
        setDestinationDriverData(res);
      }
    });
  };

  const loadDropoff = (dropoffId, type = 'origin', location) => {
    if (!dropoffId) {
      return;
    }

    showDropoff(token, dropoffId).then(res => {
      if (type == 'origin') {
        if (location == 'main') {
          setOriginMainDropoffData(res);
        } else {
          setOriginSubDropoffData(res);
        }
      } else {
        if (location == 'main') {
          setDestinationMainDropoffData(res);
        } else {
          setDestinationSubDropoffData(res);
        }
      }
    });
  };

  const handleCancelOrder = () => {
    window.confirm(
      'Are you sure you want to cancel this order? This action is irreversible.',
    ) &&
      update(
        token,
        {
          status: 'canceled',
        },
        orderId,
      ).then(res => {
        window.location.reload();
      });
  };

  useEffect(() => {
    if (orderId) {
      loadOrder();
    }
  }, [orderId]);

  useEffect(() => {
    if (id) {
      setOrderId(id);
    }
  }, []);

  return (
    <>
      <Navbar pages={mainMenu} />
      <div
        style={{
          margin: '10px',
        }}>
        {!jobOrder ? (
          <LoadingScreen />
        ) : (
          <>
            <div
              style={
                isMobile
                  ? styles.pageContainer
                  : {
                      width: '500px',
                      margin: 'auto',
                    }
              }>
              <div style={{ ...styles.pageHeader, marginBottom: '10px' }}>
                Regular Order Information
                <br />
                <small style={{ fontSize: '12px' }}>
                  {regularLabels[jobOrder?.type] ?? ''} -{' '}
                  {jobOrder?.status == 'active'
                    ? 'Waiting for the Parcel'
                    : statusLabels[jobOrder?.status] ??
                      toTitleCase(jobOrder?.status)}
                </small>
                <br />
                <small style={{ fontSize: '12px' }}>
                  ORDER # {jobOrder?.['reference-number']}
                </small>
              </div>
              <QRCodeContainer id={orderId} data={jobOrder} />
              <PackageContainer data={jobOrder?.data?.package || {}} />
              <PricingContainer
                data={jobOrder?.data?.pricing}
                type={jobOrder?.['payment-type'] || 'draft'}
              />
              <AddressContainer
                id={'origin'}
                title={
                  jobOrder?.data?.dropoffOrigin
                    ? 'Sender Details'
                    : 'Origin Location'
                }
                data={jobOrder?.data?.sender || {}}
              />
              {jobOrder?.data?.dropoffOrigin && (
                <DropoffContainer
                  title={'Dropoff Location for Origin'}
                  data={jobOrder?.data?.dropoffOrigin}
                />
              )}
              {jobOrder?.data?.dropoffDestination && (
                <DropoffContainer
                  title={'Dropoff Location for Destination'}
                  data={jobOrder?.data?.dropoffDestination}
                />
              )}

              <AddressContainer
                id={'destination'}
                title={
                  jobOrder?.data?.dropoffDestination
                    ? 'Reciever Details'
                    : 'Destination Location'
                }
                data={jobOrder?.data?.receiver || {}}
              />
              {console.log('originDriverData', originDriverData)}
              <RenderHandler
                label={'Origin Driver Assigned'}
                value={
                  originDriverData ? (
                    <>
                      {`${originDriverData?.driver?.['first-name']} ${originDriverData?.driver?.['last-name']}`}
                      <br />
                      {originDriverData?.user?.['login']}
                    </>
                  ) : (
                    'N/A'
                  )
                }
              />
              <RenderHandler
                label={'Origin Dropoff Assigned'}
                value={
                  originSubDropoffData ? (
                    <>
                      {`${originSubDropoffData?.dropoff?.['first-name']} ${originSubDropoffData?.dropoff?.['last-name']}`}
                      <br />
                      {originSubDropoffData?.user?.['login']}
                    </>
                  ) : (
                    'N/A'
                  )
                }
              />
              <RenderHandler
                label={'Origin Main Dropoff Assigned'}
                value={
                  originMainDropoffData ? (
                    <>
                      {`${originMainDropoffData?.dropoff?.['first-name']} ${originMainDropoffData?.dropoff?.['last-name']}`}
                      <br />
                      {originMainDropoffData?.user?.['login']}
                    </>
                  ) : (
                    'N/A'
                  )
                }
              />
              <RenderHandler
                label={'Destination Main Dropoff Assigned'}
                value={
                  destinationMainDropoffData ? (
                    <>
                      {`${destinationMainDropoffData?.dropoff?.['first-name']} ${destinationMainDropoffData?.dropoff?.['last-name']}`}
                      <br />
                      {destinationMainDropoffData?.user?.['login']}
                    </>
                  ) : (
                    'N/A'
                  )
                }
              />
              <RenderHandler
                label={'Destination Dropoff Assigned'}
                value={
                  destinationSubDropoffData ? (
                    <>
                      {`${destinationSubDropoffData?.dropoff?.['first-name']} ${destinationSubDropoffData?.dropoff?.['last-name']}`}
                      <br />
                      {destinationSubDropoffData?.user?.['login']}
                    </>
                  ) : (
                    'N/A'
                  )
                }
              />
              <RenderHandler
                label={'Destination Driver Assigned'}
                value={
                  destinationDriverData ? (
                    <>
                      {`${destinationDriverData?.driver?.['first-name']} ${destinationDriverData?.driver?.['last-name']}`}
                      <br />
                      {destinationDriverData?.user?.['login']}
                    </>
                  ) : (
                    'N/A'
                  )
                }
              />

              <div style={{ marginBottom: '10px' }}>
                {accountUid && (
                  <Button onClick={() => navigate('/admin/customer/' + accountUid)} variant="contained" color="primary">
                    View Account Information
                  </Button>
                )}

                {isAllowedToCancel && (
                  <Button 
                    onClick={() => handleCancelOrder()} 
                    variant="contained" 
                    color="error"
                    style={{ marginTop: '10px' }}
                  >
                    Cancel Order
                  </Button>)
                }

              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default App;

const PackageContainer = ({ data }) => {
  const { token } = useContext(UserContext);
  const [url, setUrl] = useState(null);

  useEffect(() => {
    if (data?.packagePhoto) {
      console.log('load file', data);
      loadFile();
    }
  }, []);

  const loadFile = () => {
    showFile(token, data?.packagePhoto).then(res => {
      setUrl(res?.data?.file || null);
    });
  };

  return (
    <div style={stylesContainer.sectionContatinerAddress}>
      <p style={stylesContainer.sectionHeader}>
        <strong>Parcel Information</strong>
      </p>
      {url && (
        <p style={stylesContainer.sectionData}>
          <img src={url} style={stylesContainer.packageImage} />
        </p>
      )}
      <p style={stylesContainer.sectionData}>
        <strong>Parcel Description: </strong>
        <br />
        {data?.packageDescription || ''}
      </p>
      <p style={stylesContainer.sectionData}>
        <strong>Parcel Weight: </strong>
        <br />
        {data?.packageWeight || 'N/A'} KG
      </p>
    </div>
  );
};

const PricingContainer = ({ data, type }) => {
  console.log({ data, type });
  const [showDetails, setShowDetails] = useState(false);

  const RenderCod = () => {
    if (!data.totalCodAmount || data.totalCodAmount == 0) {
      return null;
    }

    return (
      <>
        <div>
          COD Amount:{' '}
          <span style={{ float: 'right' }}>
            PHP {moneyFormat(data?.totalCodAmount || 0)}
          </span>
        </div>
      </>
    );
  };

  const RenderEarnings = () => {
    const tax = data?.subTotal * 0.12;
    const driverEarning = 15;
    const bankFee = data?.paymentProcessorFee || 0;
    const adminFee = data?.subTotal - tax + bankFee - driverEarning;
    const totalTransactionFee = tax + adminFee;
    const total = totalTransactionFee + driverEarning;

    const floatNum = num => {
      return Math.round((num + Number.EPSILON) * 100) / 100;
    };

    if (floatNum(total) === data?.subTotal) {
      console.info('Total is correct.');
    } else {
      console.error('Total is incorrect.', floatNum(total), data?.subTotal);
    }

    return (
      <>
        <div style={{ color: 'red' }}>
          <div>
            Admin Fee:{' '}
            <span style={{ float: 'right' }}>
              - PHP {moneyFormat(adminFee || 0)}
            </span>
          </div>
          <div>
            VAT:{' '}
            <span style={{ float: 'right' }}>
              - PHP {moneyFormat(tax || 0)}
            </span>
          </div>
          <div style={{ fontWeight: 'bold' }}>
            Total Transaction Fee:{' '}
            <span style={{ float: 'right' }}>
              - PHP {moneyFormat(totalTransactionFee || 0)}
            </span>
          </div>
        </div>

        <div style={{ fontWeight: 'bold', color: 'green' }}>
          Your Earning:{' '}
          <span style={{ float: 'right' }}>
            PHP {moneyFormat(driverEarning || 0)}
          </span>
        </div>
      </>
    );
  };

  return (
    <div
      style={stylesContainer.sectionContatinerAddress}
      // onClick={() => setShowDetails(!showDetails)}
    >
      <div>
        Payment Type:{' '}
        <span style={{ float: 'right' }}>{toTitleCase(type)}</span>
      </div>
      <div>
        Sub Total:{' '}
        <span style={{ float: 'right' }}>
          PHP {moneyFormat(data?.subTotal || 0)}{' '}
        </span>
      </div>
      <RenderCod />
      <div style={{ fontSize: '25px' }}>
        Total:{' '}
        <span style={{ float: 'right' }}>
          PHP {moneyFormat(data?.overallTotal || 0)}
        </span>
      </div>
      {showDetails && <RenderEarnings />}
    </div>
  );
};

const AddressContainer = ({
  id,
  title,
  data,
  activeOrder,
  activeTask,
  setActiveTask,
  completeTaskHadler,
  allowStartTask,
}) => {
  const [show, setShow] = useState(false);
  const [taskCompleted, setTaskCompleted] = useState(
    data?.taskCompleted ?? false,
  );
  const [startTask, setStartTask] = useState(false);
  const label = show ? 'Hide' : 'View';

  const onClick = () => {
    setShow(!show);
  };

  useEffect(() => {
    if (activeTask == id) {
      setStartTask(true);
    } else {
      setStartTask(false);
    }
  }, [id, activeTask]);

  useEffect(() => {
    if (startTask) {
      setActiveTask(id);
    }
  }, [startTask]);

  const handleTaskCompleted = () => {
    setTaskCompleted(true);
    setActiveTask(null);
    completeTaskHadler(id);
  };

  return (
    <>
      <div style={stylesContainer.sectionContatinerAddress}>
        <div style={stylesContainer.sectionHeader}>
          <strong>{title}</strong>
        </div>
        {!show ? (
          <>
            <p style={stylesContainer.sectionData}>
              {data?.addressLine1} {data?.addressLine2 || ''} {data.city}{' '}
              {data.province} {data.zipCode}
            </p>
          </>
        ) : (
          <>
            <table>
              <tbody>
                <tr>
                  <td style={stylesContainer.sectionDataTo}>Name: </td>
                  <td>{data?.name || ''}</td>
                </tr>
                <tr>
                  <td style={stylesContainer.sectionDataTo}>Phone: </td>
                  <td>{data?.contactNumber || ''}</td>
                </tr>
                <tr>
                  <td style={stylesContainer.sectionDataTo}>Address: </td>
                  <td></td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    {data?.addressLine1 || ''} {data?.addressLine1 || ''}{' '}
                    {data?.city || ''} {data?.province || ''}{' '}
                    {data?.zipCode || ''}
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        )}
        {show && activeOrder && (
          <>
            <Button
              startIcon={<CallIcon />}
              href="tel:09999999999"
              variant="contained"
              color="primary"
              size={'small'}
              fullWidth
              style={stylesContainer.sectionButton}>
              CALL
            </Button>
            <Button
              onClick={() => {
                const lat = data.lat;
                const lon = data.lng;
                const url = `https://www.google.com/maps/search/?api=1&query=${lat},${lon}`;

                window.open(url, '_blank');
              }}
              variant="contained"
              color="primary"
              size={'small'}
              fullWidth
              style={stylesContainer.sectionButton}>
              NAVIGATE VIA GOOGLE MAP
            </Button>
            <Button
              onClick={() => {
                const lat = data.lat;
                const lon = data.lng;
                const url = `https://waze.com/ul?ll=${lat}%2C${lon}&navigate=yes`;

                window.open(url, '_blank');
              }}
              variant="contained"
              color="primary"
              size={'small'}
              fullWidth
              style={stylesContainer.sectionButton}>
              NAVIGATE VIA WAZE
            </Button>
          </>
        )}

        {activeOrder && allowStartTask && !taskCompleted && !startTask && (
          <Button
            onClick={() => setStartTask(true)}
            variant="outlined"
            color="error"
            size={'small'}
            fullWidth
            style={stylesContainer.sectionButton}
            disabled={activeTask && activeTask != id}>
            Start Task
          </Button>
        )}

        {!taskCompleted && startTask && (
          <Button
            onClick={() => handleTaskCompleted()}
            variant="contained"
            color="error"
            size={'small'}
            fullWidth
            style={stylesContainer.sectionButton}>
            MARK AS COMPLETE TASK
          </Button>
        )}

        <Button
          onClick={onClick}
          variant="outlined"
          color="primary"
          size={'small'}
          fullWidth
          style={stylesContainer.sectionButton}>
          {label}
        </Button>
      </div>
    </>
  );
};

const DropoffContainer = ({ title, data }) => {
  return (
    <>
      <div style={stylesContainer.sectionContatinerAddress}>
        <div style={stylesContainer.sectionHeader}>
          <strong>{title}</strong>
        </div>
        <>
          <p style={stylesContainer.sectionData}>
            {data?.info?.addressLine1} {data?.info?.addressLine2 || ''}{' '}
            {data?.info.city} {data?.info.province} {data?.info.zipCode}
          </p>
        </>
      </div>
    </>
  );
};

const QRCodeContainer = ({ id, data }) => {
  return (
    <>
      <div
        style={{ background: 'white', padding: '16px', textAlign: 'center' }}>
        <QRCode
          value={`${data?.data?.verification}:${data?.['reference-number']}:${id}`}
        />
        <div>{data?.verification}</div>
      </div>
    </>
  );
};

const RenderHandler = ({ label, value }) => {
  return (
    <>
      <div style={stylesContainer.sectionContatinerAddress}>
        <div style={stylesContainer.sectionHeader}>
          <strong>{label}</strong>
        </div>
        <>{value}</>
      </div>
    </>
  );
};
