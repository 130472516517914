import React, { useContext, useState, useEffect } from 'react';
import Navbar from '../../../components/DesktopNavbar/admin.jsx';
import { mainMenu } from '../../../config/adminMenu.jsx';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../context/UserProvider';
import { getList } from '../../../services/customer';
import { list } from '../../../services/manifest';
import { moneyFormat, toTitleCase } from '../../../utils/helpers';
import { isMobile } from 'react-device-detect';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { Button } from '@mui/material';
import TableSearch from '../../../components/TableSearch/index.jsx';

const columns = isMobile
  ? [
      { field: 'index', headerName: '#', width: 70},
      { field: 'id', headerName: 'ID', width: 70 },
      { field: 'login', headerName: 'Mobile Number', width: 270 },
      { field: 'status', headerName: 'Status', width: 270 },
      { field: 'first-name', headerName: 'First Name', width: 270 },
      { field: 'last-name', headerName: 'Last Name', width: 270 },
      { field: 'address-city', headerName: 'City', width: 270 },
      { field: 'address-province', headerName: 'Province', width: 270 },
      { field: 'affiliateStatus', headerName: 'Affiliate Status', width: 270}
    ]
  : [
      { field: 'index', headerName: '#', width: 70},
      { field: 'id', headerName: 'ID', width: 70 },
      { field: 'login', headerName: 'Mobile Number', flex: 1 },
      { field: 'status', headerName: 'Status', flex: 1 },
      { field: 'first-name', headerName: 'First Name', flex: 1 },
      { field: 'last-name', headerName: 'Last Name', flex: 1 },
      { field: 'address-city', headerName: 'City', flex: 1 },
      { field: 'address-province', headerName: 'Province', flex: 1 },
      { field: 'affiliateStatus', headerName: 'Affiliate Status', flex: 1 }
    ];

const App = () => {
  const navigate = useNavigate();
  const { token, userAttr } = useContext(UserContext);
  const [rows, setRows] = useState([]);
  const [pageInfoCurrentPage, setPageInfoCurrentPage] = useState(0);
  const [pageInfoTotalRows, setPageInfoTotalRows] = useState(0);
  const [pageInfoRowPerPage, setPageInfoRowPerPage] = useState(25);
  const [loading, setLoading] = useState(true);
  const [pending, setPending] = useState(false);

  const updatePageHandler = pageInfo => {
    loadData({
      'current-page': pageInfo.page + 1,
      'record-per-page': pageInfo.pageSize,
    });
  };

  const handleRecordSelect = row => {
    navigate(`/admin/customer/${row.id}`);
  };

  const loadData = payload => {
    setLoading(true);
    getList(token, {
      ...payload,
      'user-specific': false,
    }).then(res => {
      const summary = [];
      let indexCounter = res['data-from'] ?? 1;
      res.collection.forEach(item => {
        summary.push({
          index: indexCounter++,
          id: item['user-id'],
          affiliateStatus: toTitleCase(item.type),
          ...item,
        });
      });

      setRows(summary);

      setPageInfoCurrentPage(res['current-page']);
      setPageInfoRowPerPage(res['record-per-page']);
      setPageInfoTotalRows(res['data-total']);
      setLoading(false);
    });
  };

  useEffect(() => {
    loadData({
      'current-page': 1,
      'record-per-page': 25,
    });
  }, []);

  useEffect(() => {
    if (pending) {
      loadData({
        fields: {
          type: 'For Affiliation Verification'
        },
        'current-page': 1,
        'record-per-page': 25,
      });
    } else {
      loadData({
        'current-page': 1,
        'record-per-page': 25,
      });
    }
  }, [pending]);

  return (
    <>
      <Navbar pages={mainMenu} />
      <Container maxWidth={'xl'}>
        <Grid container spacing={2} style={{ height: 'auto' }}>
          <Grid item xs={12} sm={3} style={{ height: '100px' }}>
            <h1>Customer List</h1>
          </Grid>
          <Grid item xs={12} sm={6} style={{ height: '100px' }}>
            <TableSearch
              onSearch={value => {
                loadData({
                  fields: {
                    login: value,
                  },
                  'current-page': 1,
                  'record-per-page': 25,
                  operator: 'like',
                });
              }}
              label="Search Mobile Number"
              value={pending ? '' : null}
            />
          </Grid>
          <Grid item xs={12} sm={3} style={{ height: '100px' }}>
          <Button
              variant="contained"
              onClick={() => setPending(!pending)}
              style={
                isMobile
                  ? {}
                  : {
                      width: '260px',
                      float: 'right',
                      marginTop: '20px',
                    }
              }>
              {pending ? 'All' : 'Pending Affiliation'}
            </Button>
          </Grid>
          <Grid item xs={12} sm={12}>
            <DataGrid
              rows={rows}
              columns={columns}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    id: false,
                  },
                },
                pagination: {
                  paginationModel: {
                    pageSize: pageInfoRowPerPage,
                    page: pageInfoCurrentPage,
                  },
                },
              }}
              pagination
              paginationMode="server"
              rowCount={pageInfoTotalRows}
              loading={loading}
              onPaginationModelChange={newPage => updatePageHandler(newPage)}
              disableColumnFilter
              disableColumnMenu
              onRowClick={handleRecordSelect}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default App;
