import React, { useContext, useState, useEffect } from 'react';
import { accountBalance } from '../../../services/user';
import { moneyFormat } from '../../../utils/helpers';
import { UserContext } from '../../../context/UserProvider';
import { styles } from '../../../config/defaultPageStyles';
import {
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  FormControl,
  TextField,
} from '@mui/material';
import CurrencyFormat from 'react-currency-format';
import { Link, useNavigate } from 'react-router-dom';
import { mainMenu } from '../../../config/dropoffMenu.jsx';
import Navbar from '../../../components/DesktopNavbar';
import { isDesktop } from 'react-device-detect';

const GCASH_NUMBER = '09671801905 - JEFREY G.';
const PAYMAYA_NUMBER = '09671801905';
const UNIONBANK_NUMBER = '002440015060';
const UNIONBANK_NAME = 'BOX TRACK CARGO INC';

const App = () => {
  const { token } = useContext(UserContext);
  const [accountCredit, setAccountCredit] = useState(0);
  const [initTapUp, setInitTapUp] = useState(false);
  const [tapUpAmount, setTapUpAmount] = useState(0);
  const [tapUpProcess, setTapUpProcess] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    loadAccountCredit();
  }, []);

  const loadAccountCredit = () => {
    accountBalance(token).then(res => {
      setAccountCredit(res.balance);
    });
  };

  const handleTapUp = () => {
    if (tapUpAmount === 0) {
      return alert('Please enter amount');
    }

    if (tapUpAmount > 10000) {
      return alert('Maximum amount is 10,000');
    }

    setTapUpProcess(true);
  };

  if (tapUpProcess) {
    return (
      <>
        <Navbar pages={mainMenu} />
        <div
          style={
            isDesktop
              ? {
                  width: '30%',
                  margin: 'auto',
                }
              : styles.pageContainer
          }>
          <div style={styles.pageHeader}>Top-Up Process</div>
          <p>
            1) Send <strong>PHP {moneyFormat(tapUpAmount)}</strong> amount to
            GCASH <strong>{GCASH_NUMBER}</strong> <br />
            or Paymaya <strong>{PAYMAYA_NUMBER}</strong>
            <br />
            or Unionbank account <strong>{UNIONBANK_NUMBER}</strong> -{' '}
            {UNIONBANK_NAME}
          </p>
          <p>2) Take a screenshot of the transaction</p>
          <p>
            3) Send the screenshot to our{' '}
            <a href="https://www.facebook.com/boxtrackcargo" target="_blank">
              Facebook page messenger
            </a>
          </p>
          <p>4) Wait for the confirmation</p>
          <p>5) Once confirmed, your account will be credited</p>
          <p>6) You can now use your account balance to accept orders</p>
          <p>
            Note: Confirmation is not real-time, contact CSR during office hours 8:30-5:30pm  (02) 8521-2309 0947-8558891
          </p>
          <p><strong>Paymaya QR Code:</strong></p>
          <img src="/img/paymaya-qr.jpg" alt="paymaya-qr" style={{ width: '100%' }} />
          <Button
            onClick={() => {
              setInitTapUp(false);
              setTapUpProcess(false);
            }}
            variant="contained"
            fullWidth
            style={{ marginTop: '20px' }}>
            View My Wallet
          </Button>
        </div>
      </>
    );
  }

  return (
    <>
      <Navbar pages={mainMenu} />
      <div
        style={
          isDesktop
            ? {
                width: '30%',
                margin: 'auto',
              }
            : styles.pageContainer
        }>
        <div style={styles.pageHeader}>Wallet</div>
        <div>
          Account Balance:{' '}
          <span style={{ float: 'right' }}>
            PHP {moneyFormat(accountCredit || 0)}
          </span>
        </div>
        <div>
          {initTapUp && (
            <>
              <CurrencyFormat
                displayType={'input'}
                thousandSeparator={true}
                prefix={'PHP'}
                decimalScale={2}
                fixedDecimalScale={true}
                onValueChange={values => {
                  const { floatValue } = values;
                  let temp = floatValue;
                  if (isNaN(temp)) {
                    temp = 0;
                  } else if (temp > 10000) {
                    setTapUpAmount(0);
                    return alert('Maximum amount is 10,000');
                  }
                  setTapUpAmount(temp);
                }}
                customInput={TextField}
                placeholder="Top-up Amount"
              />
              <Button
                onClick={() => handleTapUp()}
                variant="contained"
                fullWidth
                style={{ marginTop: '20px' }}>
                Submit
              </Button>
            </>
          )}
          <Button
            onClick={() => setInitTapUp(!initTapUp)}
            variant="contained"
            fullWidth
            style={{ marginTop: '20px' }}>
            {initTapUp ? 'Cancel Top-up' : 'Top-up'}
          </Button>
          <Button
            onClick={() => navigate('/dropoff/wallet/transaction')}
            variant="contained"
            fullWidth
            style={{ marginTop: '20px' }}>
            VIEW TRANSACTION
          </Button>
        </div>
      </div>
    </>
  );
};

export default App;
