import React from 'react';
import LoginForm from '../../../components/LoginForm';

const LoginPage = () => {
  return (
    <LoginForm nextPage={'/admin/dashboard'} userType={'admin'} allowEmailOtp={false} />
  );
};

export default LoginPage;
