import React, { useState, useRef } from 'react';
import { Button, TextField } from '@mui/material';
import ImageUploader from '../../../components/ImageUploader';

const App = ({ label, data, backHandler, nextHandler }) => {
  const photoList = useRef([]);
  const description = useRef(null);

  const submit = () => {
    if (photoList.current.length < 1) {
      return alert('Please upload photo');
    } else if (!description.current.value || description.current.value === '') {
      return alert('Please enter package description');
    }

    nextHandler({
      packagePhoto: photoList.current[0].id,
      packageDescription: description.current.value,
    });
  };

  return (
    <>
      <div
        style={{
          textAlign: 'center',
          fontSize: '1.2rem',
          paddingTop: '15px',
          paddingBottom: '15px',
        }}>
        {label}
      </div>
      <div
        style={{
          textAlign: 'center',
          fontSize: '1.2rem',
          paddingTop: '15px',
          paddingBottom: '15px',
        }}>
        <ImageUploader
          name={'packagePhoto'}
          type={'Package Photo'}
          title={'Please Upload Photo'}
          label={''}
          ref={photoList}
          defaultImage={photoList.current[0] ? photoList.current[0].url : null}
        />
        <TextField
          label={'Package Description'}
          variant="outlined"
          type={'text'}
          multiline
          rows={4}
          fullWidth
          inputRef={description}
        />
      </div>
      <div style={{ width: '100%',  paddingBottom: '15px' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => submit()}
          style={{ marginBottom: '10px' }}>
          Next
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => backHandler()}>
          Back
        </Button>
      </div>
    </>
  );
};

export default App;
