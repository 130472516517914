import React, { useContext, useEffect, useState, useRef } from 'react';
import { Button, Card, Grid, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { UserContext } from '../../../context/UserProvider';
import { getAccountInformation } from '../../../services/user';
import { submitAffiliateValidIdentification } from '../../../services/customer';
import ImageUploader from '../../../components/ImageUploader';
import LoadingScreen from '../../../components/LoadingScreen';

import { toTitleCase } from '../../../utils/helpers';
import { status as sameDayStatus } from '../../../config/sameDayStatus';
import { renderStatusLabel } from '../../../config/regularStatus';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import { styles } from '../../../config/defaultPageStyles';
import { moneyFormat } from '../../../utils/helpers';

// TimeAgo.addDefaultLocale(en);

const CustomerAffiliationPage = () => {
  const navigate = useNavigate();
  const { token, logout } = useContext(UserContext);
  //   const timeAgo = new TimeAgo('en-US');
  const [isAffiliate, setIsAffiliated] = useState(null);
  const [submittedId, setSubmittedId] = useState(null);

  useEffect(() => {
    loadUserInformation();
  }, []);

  const loadUserInformation = () => {
    getAccountInformation(token)
      .then(response => {
        setIsAffiliated(response?.customer?.type === 'Affiliated' ? true : false);
        setSubmittedId(response?.customer['affiliate-valid-identification-card-file-id'] ? true : false);
      })
      .catch(error => {
        console.log(error);
      });
  };

  if (isAffiliate === null) {
    return <LoadingScreen/>;
  }

  return (
    <Grid container spacing={2} layout={'row'}>
      {!isAffiliate && !submittedId && <AffiliateForm token={token} />}
      {!isAffiliate && submittedId && <>
        <Grid item xs={12} height={'auto'} style={{ marginTop: '20px', textAlign: 'center' }}>
            <h1>Thank you for your submission</h1>
            <img
              src="/img/review.gif"
              style={{ width: '100%' }}
            />
            <p>We are still verifying your ID. Please check back later.</p>
        </Grid>
      </>}
     
      <Grid item xs={12} height={'auto'}></Grid>
      <Grid item xs={12} height={'auto'}>
        <Button onClick={() => navigate(-1)} variant="contained" fullWidth>
          Back
        </Button>
      </Grid>
    </Grid>
  );
};

const AffiliateForm = ({ token }) => {
  const photoList = useRef([]);

  const submit = () => {
    if (photoList.current.length < 1) {
      return alert('Please upload photo');
    }

    submitAffiliateValidIdentification(token, photoList.current[0].id)
        .then(response => {
            window.location.reload();
        });
  };

  return (
    <>
      <Grid item xs={12} height={'auto'} style={{ marginTop: '20px'}}>
        <Typography variant="h5">Affiliate Form</Typography>
        <p>Before you can get your affiliation code you need to submit valid Government ID for verification</p>
      </Grid>
      <Grid item xs={12} height={'auto'} style={{ marginTop: '0px'}}>
        <ImageUploader
          name={'affiliationIDPhoto'}
          type={'Affiliation ID Photo'}
          title={'Upload Valid ID Photo'}
          label={''}
          ref={photoList}
          defaultImage={null}
        />
      </Grid>
      <Grid item xs={12} height={'auto'}>
        <Button onClick={() => submit()} variant="contained" fullWidth>
          Submit
        </Button>
      </Grid>
    </>
  );
};

export default CustomerAffiliationPage;
