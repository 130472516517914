import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  GoogleMap,
  LoadScript,
  Marker,
  DirectionsRenderer,
} from '@react-google-maps/api';
import { Button } from '@mui/material';
import CallIcon from '@mui/icons-material/Call';
import { getGps } from '../../../services/driver';
import { UserContext } from '../../../context/UserProvider';
import LoadingScreen from '../../../components/LoadingScreen';

const containerStyle = {
  width: '100%',
  height: '85vh',
};
const SEND_GPS_INTERVAL = 5000;

const App = ({ driverId, orderId, hideMap }) => {
  const { token } = useContext(UserContext);
  const [driverLat, setDriverLat] = useState(null);
  const [driverLng, setDriverLng] = useState(null);
  const [destinationLat, setDestinationLat] = useState(null);
  const [destinationLng, setDestinationLng] = useState(null);
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [directions, setDirections] = useState(null);
  const [error, setError] = useState(null);
  const [markerData, setMarkerData] = useState(null);
  const [lastGps, setLastGps] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(10);
  const mapRef = useRef(null);

  useEffect(() => {
    if (driverLat && driverLng && isMapLoaded) {
      if (!markerData) {
        setMarkerData({
          url: '/img/boxy.png',
          scaledSize: new window.google.maps.Size(50, 50),
        });
      }
      loadDirectons();
    }
  }, [driverLat, driverLng, isMapLoaded]);

  const loadDirectons = () => {
    if (window.google) {
      const directionsService = new window.google.maps.DirectionsService();
      directionsService.route(
        {
          origin: { lat: driverLat, lng: driverLng },
          destination: { lat: destinationLat, lng: destinationLng },
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            setDirections(result);
          } else {
            setError(result);
          }
        },
      );
    }
  };

  const loadLatestLocation = () => {
    getGps(token, driverId, orderId)
      .then(res => {
        if (res?.data?.current_latitude && res?.data?.current_longitude) {
          const currentLat = parseFloat(res.data.current_latitude);
          const currentLng = parseFloat(res.data.current_longitude);

          setDriverLat(currentLat);
          setDriverLng(currentLng);
        }

        if (
          res?.data?.destination_latitude &&
          res?.data?.destination_longitude
        ) {
          const destinationLatTemp = parseFloat(res.data.destination_latitude);
          const destinationLngTemp = parseFloat(res.data.destination_longitude);

          if (
            destinationLatTemp !== destinationLat ||
            destinationLngTemp !== destinationLng
          ) {
            setDestinationLat(destinationLatTemp);
            setDestinationLng(destinationLngTemp);
          }
        }

        setLastGps(res.data.updated_last);
      })
      .catch(err => {
        window.location.reload();
      });
  };

  useEffect(() => {
    loadLatestLocation();
    setInterval(() => {
      loadLatestLocation();
    }, SEND_GPS_INTERVAL);
  }, []);

  console.log('driverLat', driverLat);
  console.log('driverLng', driverLng);
  console.log('destinationLat', destinationLat);
  console.log('destinationLng', destinationLng);

  if (!driverLat || !driverLng || !destinationLat || !destinationLng) {
    return <LoadingScreen />;
  }

  return (
    <>
      <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}>
        <style>
          {`
            body,
            html,
            #root,
            .MuiContainer-root {
            margin: 0 !important;
            padding: 0  !important;
            height: 100%;
            width: 100%;
            }
        `}
        </style>
        <GoogleMap
          mapContainerStyle={containerStyle}
          defaultCenter={{ lat: destinationLat, lng: destinationLng }}
          onLoad={() => setIsMapLoaded(true)}
          onZoomChanged={() => {
            if (mapRef.current) {
              const currentZoomLevel = mapRef.current.state.map.getZoom();
              setZoomLevel(currentZoomLevel);
            }
          }}
          zoom={zoomLevel}
          ref={mapRef}
          options={{
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
          }}>
          <Marker
            position={{ lat: driverLat, lng: driverLng }}
            title="Driver's Location"
            icon={markerData}
            optimized={true}
            visible={true}
          />
          <Marker
            position={{ lat: destinationLat, lng: destinationLng }}
            title="Destination"
            visible={true}
          />
          {directions && (
            <DirectionsRenderer
              directions={directions}
              options={{ suppressMarkers: true }}
            />
          )}
        </GoogleMap>
      </LoadScript>
      <div
        style={{
          position: 'absolute',
          bottom: '0',
          height: '17vh',
          width: '100%',
          display: 'block',
          justifyContent: 'center',
          padding: '0 1rem 0 1rem',
          backgroundColor: 'white',
        }}>
        <div
          style={{
            marginBottom: '1rem',
            fontSize: '0.8rem',
            textAlign: 'center',
            marginTop: '1rem',
          }}>
          Last Driver Location Update: {new Date(lastGps).toLocaleTimeString()}
        </div>
        <Button
          variant="contained"
          color="primary"
          onClick={() => hideMap()}
          style={{ marginBottom: '2rem' }}>
          Back
        </Button>
      </div>
    </>
  );
};

export default App;
