import axios from 'axios';
import { checkErrorResponse } from '../utils/helpers';

export const create = payload => {
  const headers = {
    'Content-Type': 'application/json',
  };

  return axios
    .post(`${process.env.REACT_APP_API}/dropoff-management/`, payload, {
      headers,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(error => {
      return checkErrorResponse(error.response);
    });
};

export const getList = (token, payload) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  return axios
    .post(`${process.env.REACT_APP_API}/dropoff/list`, payload, {
      headers,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(error => {
      return checkErrorResponse(error.response);
    });
};


export const show = (token, id) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  return axios
    .get(`${process.env.REACT_APP_API}/dropoff/${id}`, {
      headers,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(error => {
      return checkErrorResponse(error.response);
    });
};

export const update = (token, payload, id) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  return axios
    .patch(`${process.env.REACT_APP_API}/dropoff-management/${id}`, payload, {
      headers,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(error => {
      return checkErrorResponse(error.response);
    });
};