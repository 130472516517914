import React, { useEffect, useState } from 'react';
import { Grid, Button } from '@mui/material';
import dayjs from 'dayjs';
import { getVehicleType } from '../../../services/vehicle';
import {
  TextField,
  FormControl,
  Box,
} from '@mui/material';
import SingleDatePicker from '../../../components/SingleDatePicker';
import Select from  '../../../components/FormSelect';

const VehicleForm = ({
  stateName,
  vehicleData,
  nextStep,
  prevStep,
  handleFormSubmit,
}) => {
  const [vehicleType, setVehicleType] = useState(null);
  const futureDate = dayjs().add(1, 'year').format('YYYY-MM-DD');
  const [formData, setFormData] = useState({
    type: null,
    brand: null,
    model: null,
    color: null,
    plateNumber: null,
    validityDate: futureDate,
  });

  useEffect(() => {
    getVehicleType().then(res => {
      const result = Object.keys(res).map(key => ({
        label: res[key].label,
        value: key
      }));

      setVehicleType(result);
    });
  }, []);


  const handleFormSubmitForm = () => {
    console.log('formData', formData);
    for (let key in formData) {
      if (!formData[key]) {
        alert('Please fill all fields');
        return;
      }
    }

    handleFormSubmit(stateName, {
      category: formData.category,
      'type': formData.type,
      'type-id': formData.type.value,
      brand: formData.brand,
      model: formData.model,
      color: formData.color,
      'plate-number': formData.plateNumber,
      'validity-date': formData.validityDate,
    });

    nextStep();
  };

  useEffect(() => {
   preLoadData();
  }, [vehicleType]);

  const preLoadData = () => {
    if (Object.keys(vehicleData).length === 0) return;

    setFormData({
      type: vehicleData.type,
      brand: vehicleData.brand,
      model: vehicleData.model,
      color: vehicleData.color,
      plateNumber: vehicleData['plate-number'],
      validityDate: vehicleData['validity-date'] || futureDate,
    });
  };

  if (!vehicleType) return null;

  return (
    <Grid container direction="column" alignItems="center">
      <Box sx={{ width: '100%'}}>
        <FormControl fullWidth style={{marginBottom: '10px'}}>
          <Select
            onChange={e =>
              setFormData({ ...formData, type: e })
            }
            value={formData?.type}
            options={vehicleType}
            placeholder="Vehicle Type *"
          />
        </FormControl>
        <FormControl fullWidth>
          <TextField
            label="Vehicle Make *"
            variant="outlined"
            type={'text'}
            onChange={e => setFormData({ ...formData, brand: e.target.value })}
            value={formData.brand ?? ''}
          />
        </FormControl>
        <FormControl fullWidth>
          <TextField
            label="Vehicle Model *"
            variant="outlined"
            type={'text'}
            onChange={e => setFormData({ ...formData, model: e.target.value })}
            value={formData.model ?? ''}
          />
        </FormControl>
        <FormControl fullWidth>
          <TextField
            label="Vehicle Color *"
            variant="outlined"
            type={'text'}
            onChange={e => setFormData({ ...formData, color: e.target.value })}
            value={formData.color ?? ''}
          />
        </FormControl>
        <FormControl fullWidth>
          <TextField
            label="Vehicle Plate Number *"
            variant="outlined"
            type={'text'}
            onChange={e =>
              setFormData({ ...formData, plateNumber: e.target.value })
            }
            value={formData.plateNumber ?? ''}
          />
        </FormControl>
        <FormControl fullWidth>
          <SingleDatePicker
            maxDate={dayjs().add(10, 'year')}
            onChange={date => setFormData({ ...formData, validityDate: date })}
            value={formData.validityDate ?? futureDate}
            label="Validity Date *"
            minDate={dayjs()}
          />
        </FormControl>
      </Box>
      <Button
        onClick={() => handleFormSubmitForm()}
        variant="contained"
        className="action">
        Next
      </Button>
      <Button onClick={() => prevStep()} variant="contained" className="action">
        Back
      </Button>
    </Grid>
  );
};

export default VehicleForm;
