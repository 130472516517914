export const mainMenu = [
  {
    label: 'Dashboard',
    path: '/admin/dashboard',
    desktop: true,
    mobile: true,
    key: 'dashboard',
  },
  {
    label: 'Drivers',
    path: '/admin/drivers',
    desktop: true,
    mobile: true,
    key: 'drivers',
  },
  {
    label: 'Customers',
    path: '/admin/customers',
    desktop: true,
    mobile: true,
    key: 'customers',
  },
  {
    label: 'Dropoff',
    path: '/admin/dropoff-partners',
    desktop: true,
    mobile: true,
    key: 'dropoff',
  },
  {
    label: 'Regular Orders',
    path: '/admin/orders-regular',
    desktop: true,
    mobile: true,
    key: 'orders-regular',
  },
  {
    label: 'Same Day Orders',
    path: '/admin/orders-same-day',
    desktop: true,
    mobile: true,
    key: 'orders-same-day',
  },
  {
    label: 'Manifest',
    path: '/admin/regular-manifest',
    desktop: true,
    mobile: true,
    key: 'regular-manifest',
  },
];
