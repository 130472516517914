import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Link } from 'react-router-dom';
import { Grid, Button } from '@mui/material';
import dayjs from 'dayjs';
import Fields from '../../../components/Fields';
import { create as createDropoff } from '../../../services/dropoff';
import { create as createUser } from '../../../services/user';
import { phoneRegex } from '../../../utils/constants';
import { mobileCheck } from '../../../services/authentication';
import { getProvincesAndMunicipalities } from '../../../config/locationPH2.jsx';

const dateFormat = 'YYYY-MM-DD';
const legalAgeToday = dayjs().subtract(18, 'year');

const config = [
  {
    type: 'textfield',
    id: 'firstName',
    label: 'First Name',
  },
  {
    type: 'textfield',
    id: 'lastName',
    label: 'Last Name',
  },
  {
    type: 'textfield',
    id: 'middleName',
    label: 'Middle Name',
  },
  {
    type: 'date',
    id: 'birthDate',
    label: 'Birth Date',
    format: dateFormat,
    maxDate: legalAgeToday,
  },
  {
    type: 'textfield',
    id: 'emailAddress',
    label: 'Email Address',
  },
  {
    type: 'textfield',
    id: 'contactNumber',
    label: 'Contact Number (09xxxxxxxxx)',
  },
  {
    type: 'textfield',
    id: 'addressLine1',
    label: 'Address Line 1',
    inputProps: {
      maxLength: 200
    },
  },
  {
    type: 'textfield',
    id: 'addressLine2',
    label: 'Address Line 2',
  },
  {
    type: 'select',
    id: 'province',
    label: 'Province',
  },
  {
    type: 'select',
    id: 'city',
    label: 'City',
  },
  {
    type: 'textfield',
    id: 'zipCode',
    label: 'Zip Code',
  },
  {
    type: 'textfield',
    id: 'password',
    label: 'Password',
    isPassword: true,
  },
  {
    type: 'textfield',
    id: 'retypePassword',
    label: 'Re-type Password',
    isPassword: true,
  }
];
const schema = z
  .object({
    firstName: z.string().min(1),
    lastName: z.string().min(1),
    middleName: z.string().min(1),
    birthDate: z.coerce.string().min(10),
    emailAddress: z.string().min(1).email(),
    contactNumber: z.coerce.string().min(1),
    addressLine1: z.string().min(1),
    addressLine2: z.string(),
    province: z.string().min(1),
    city: z.string().min(1),
    zipCode: z.string().min(1),
    password: z.string().min(1),
    retypePassword: z.string().min(1),
  })
  .superRefine(({ retypePassword, password, contactNumber }, ctx) => {
    if (retypePassword !== password) {
      ctx.addIssue({
        code: 'custom',
        message: 'The passwords did not match',
        path: ['retypePassword'],
      });
    }

    if (!phoneRegex.test(contactNumber)) {
      ctx.addIssue({
        code: 'custom',
        message: 'Invalid contact number',
        path: ['contactNumber'],
      });
    }

    if (!contactNumber.startsWith('09')) {
      ctx.addIssue({
        code: 'custom',
        message: 'Invalid contact number, must start with 09',
        path: ['contactNumber'],
      });
    }
  });

const SignupForm = ({ stateName, userData, nextStep, handleFormSubmit }) => {
  const [selectionOptions, setSelectionOptions] = useState({
    province: [],
    city: [],
  });

  useEffect(() => {
    const summary = getProvincesAndMunicipalities();

    let city = [];
    if (userData.province) {
      let locationData = summary.find(item => item.value === userData.province);
      if (locationData) {
        city = locationData.municipality.map(item => {
          return {
            label: item,
            value: item,
          };
        });
      }
    }

    setSelectionOptions(
      {
        province: summary,
        city: city,
      },
      [],
    );
  }, []);

  const { register, control, handleSubmit, formState, watch, setValue } = useForm({
    defaultValues: { ...userData, birthDate: legalAgeToday }, // prefill values
    resolver: zodResolver(schema),
  });

  const { errors } = formState;

  const onSubmit = async data => {
    try {
      const userPayload = {
        ...data,
        birthDate: dayjs(data.birthDate).format('YYYY-MM-DD'),
        type: 'regular',
      };
      
      const customerResponse = await createDropoff({
        'first-name': userPayload.firstName,
        'last-name': userPayload.lastName,
        'middle-name': userPayload.middleName,
        'birth-date': userPayload.birthDate,
        'email-address': userPayload.emailAddress,
        'contact-number': userPayload.contactNumber,
        'address-line-1': userPayload.addressLine1,
        'address-line-2': userPayload.addressLine2,
        'address-city': userPayload.city,
        'address-province': userPayload.province,
        'address-zip-code': userPayload.zipCode,
        'type': 'sub'
      });

      const {
        raw: { id },
      } = customerResponse;

      const userManagementPayload = {
        login: userPayload.contactNumber,
        password: userPayload.password,
        type: 'dropoff',
        'info-id': id,
        access: [],
      };
      createUser(userManagementPayload);

      nextStep();
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    let locationData = selectionOptions.province.find(
      item => item.value === watch('province'),
    );
    if (locationData) {
      let city = locationData.municipality.map(item => {
        return {
          label: item,
          value: item,
        };
      });

      setSelectionOptions({
        ...selectionOptions,
        city: city,
      });
    }
  }, [watch('province')]);

  useEffect(() => {
    if (!watch('contactNumber')) return;

    if (phoneRegex.test(watch('contactNumber'))) {
      mobileCheck('dropoff', watch('contactNumber')).then(res => {
        if (res.message === 'Mobile number does exist') {
          alert('Mobile number is already registered');
          setValue('contactNumber', '');
          return;
        }
      });
    }
  }, [watch('contactNumber')]);

  return (
    <Grid container direction="column" alignItems="center">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Fields
          config={config}
          errors={errors}
          register={register}
          control={control}
          selections={selectionOptions}
        ></Fields>
        <div>
          <Button type="submit" variant="contained" className="action">
            Next
          </Button>
          <Link to="/dropoff/login">
            <Button variant="contained" className="action">
              Back
            </Button>
          </Link>
        </div>
      </form>
    </Grid>
  );
};

export default SignupForm;
