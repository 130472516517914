import React, { useState, useEffect, useContext } from 'react';
import { Button, FormControl } from '@mui/material';
import {
  computationRegular,
  showRegular as show,
  updateRegular as updateOrder,
  orderPaymentProcess
} from '../../services/order';
import { accountBalance } from '../../services/user';
import { UserContext } from '../../context/UserProvider';
import { moneyFormat } from '../../utils/helpers';
import Select from '../../components/FormSelect';
import LoadingScreen from '../../components/LoadingScreen';
import { useNavigate } from 'react-router-dom';

const dummy1 = {
  "type": "door2door",
  "sender": {
      "name": "Krissam Tapa",
      "contactNumber": "09994992118",
      "addressLine1": "San Mateo Rizal, test",
      "addressLine2": "test",
      "province": "RIZAL",
      "city": "SAN MATEO",
      "zipCode": "1850",
      "remarks": null,
      "cod": false,
      "codAmount": null,
      "lat": 14.6620373,
      "lng": 121.1525354
  },
  "receiver": {
      "name": "Vlagie Tolosa",
      "contactNumber": "09994992118",
      "addressLine1": "Blk 43 Lot 7 Ph 2 Tierra Monte Village Brgy. Silangan",
      "addressLine2": null,
      "province": "CEBU",
      "city": "CEBU CITY",
      "zipCode": "1850",
      "remarks": null,
      "cod": false,
      "codAmount": null,
      "lat": 10.3111099,
      "lng": 123.9209109
  },
  "package": {
      "packagePhoto": 23,
      "packageDescription": "test",
      "packageWeight": 30
  },
  "pricing": null
};

let typeUrl = {
  'door2door': 'door-2-door',
  'dropoff2dropoff': 'dropoff-2-dropoff',
  'dropoff2door': 'dropoff-2-door',
  'door2dropoff': 'door-2-dropoff'
};

const App = ({ label, data, backHandler, removeReloadWarning, isComputed, orderType = 'regular' }) => {
  const { token } = useContext(UserContext);
  const [jobOrder, setJobOrder] = useState(null);
  const [payment, setPayment] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    console.log('data', data);
  }, [data]);

  useEffect(() => {
    if (token) {
      loadComputation();
    }
  }, [token]);

  const loadComputation = () => {
    if (isComputed) {
      let isCod = data?.pricing?.totalCodAmount || 0;
      setJobOrder(data);
      if (isCod > 0) {
        setPayment('cod');
      }
      return;
    }

    setIsLoading(true);
    computationRegular(token, data).then(res => {
      setJobOrder(res);
      let isCod = res?.pricing?.codFee || 0;

      if (isCod > 0) {
        setPayment('cod');
      }
      setIsLoading(false);
    });
  };

  const cancelHandler = () => {
    let confirm = window.confirm('Are you sure you want to cancel?');

    if (confirm) {
      setIsLoading(true);
      updateOrder(token, {
        status: 'canceled',
      },
      jobOrder['order-id']).then(res => {
        navigate('/customer/dashboard');
      });
    }
  };

  const checkWalletIfSufficient = () => {
    setIsLoading(true);
    accountBalance(token).then(res => {
      if (res.balance < (jobOrder?.pricing?.overallTotal || 0)) {
        setIsLoading(false);
        removeReloadWarning();
        return window.location.href = `${process.env.REACT_APP_API}/account/tapup?t=${token}&order=${jobOrder['order-id']}&type=regular`;
      } else {
        deductFromWallet();
      } 
    });
  };

  const deductFromWallet = () => {
    orderPaymentProcess(token, {
        'order-id': jobOrder['order-id'],
        'type': orderType
    }).then(res => {
        if (res.status === 'success') {
          placeOrder();
        }
    });
  };

  const submitProcess = () => {
    let confirm = window.confirm('Are you sure you want to place order?');

    if (!confirm) {
      return;
    }

    if (!payment) {
      return alert('Please select payment method');
    }
    setIsLoading(true);

    if (payment === 'wallet') {
      checkWalletIfSufficient();
    } else {
      placeOrder();
    }
  };

  const placeOrder = () => {
    setIsLoading(true);
    updateOrder(
      token,
      {
        status: 'active',
        'payment-type': payment,
      },
      jobOrder['order-id'],
    ).then(res => {
      show(token, jobOrder['order-id']).then(res => {
        setIsLoading(false);
        if (res.data.status != 'draft') {
          navigate(`/customer/delivery/regular/${jobOrder['order-id']}`);
        } else {
          return alert('Something went wrong, try again later.');
        }
        setIsLoading(false);
      });
    });
  };

  if (!jobOrder) {
    return <LoadingScreen />;
  }

  let isCod = jobOrder?.pricing?.totalCodAmount || 0;
  isCod = isCod > 0 ? true : false;

  return (
    <>
      {isLoading && <LoadingScreen />}
      <div
        style={{
          textAlign: 'center',
          fontSize: '1.2rem',
          paddingTop: '15px',
          paddingBottom: '15px',
        }}>
        {label}
      </div>

      <div
        style={{
          backgroundColor: '#dfdfdf',
          padding: '10px',
          marginBottom: '15px',
          borderRadius: '10px',
        }}>
        <div>
          Sub Total:{' '}
          <span style={{ float: 'right' }}>
            PHP {moneyFormat(jobOrder?.pricing?.subTotal || 0)}{' '}
          </span>
        </div>
        {isCod && (
          <div>
            COD Amount:{' '}
            <span style={{ float: 'right' }}>
              PHP {moneyFormat(jobOrder?.pricing?.totalCodAmount || 0)}
            </span>
          </div>
        )}
        <div style={{ fontSize: '25px' }}>
          Total:{' '}
          <span style={{ float: 'right' }}>
            PHP {moneyFormat(jobOrder?.pricing?.overallTotal || 0)}
          </span>
        </div>
      </div>

      <div style={{ width: '100%' }}>
        {isCod ? (
          <>
            <p style={{ textAlign: 'center' }}>
              Payment Method: <strong>Cash on Delivery</strong>
            </p>
            <Button
              variant="contained"
              color="primary"
              onClick={() => submitProcess()}
              style={{ marginBottom: '10px' }}>
              PLACE ORDER
            </Button>
          </>
        ) : (
          <>
            <FormControl fullWidth style={{ marginBottom: '10px' }}>
              <Select
                onChange={e => setPayment(e.value)}
                options={process.env.REACT_APP_ENABLE_DRAGONPAY == 'false' ? 
                  [
                    {
                      label: 'Pay via Cash',
                      value: 'cash',
                    }
                  ] :
                  [
                    {
                      label: 'Pay via Wallet',
                      value: 'wallet',
                    },
                    {
                      label: 'Pay via Cash',
                      value: 'cash',
                    },
                  ]
                }
                placeholder="Select Payment Method"
              />
            </FormControl>
            <Button
              variant="contained"
              color="primary"
              onClick={() => submitProcess()}
              style={{ marginBottom: '10px' }}>
              PLACE ORDER
            </Button>
          </>
        )}
        <Button
          variant="contained"
          color="primary"
          style={{ marginBottom: '10px' }}
          onClick={() => backHandler()}>
          Back
        </Button>

        <Button
          variant="outlined"
          fullWidth
          color="error"
          onClick={() => cancelHandler()}>
          Cancel
        </Button>
      </div>
    </>
  );
};

export default App;
