import React, { useEffect, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { UserContext } from '../../../context/UserProvider';
import { moneyFormat, toTitleCase } from '../../../utils/helpers';
import { list, info } from '../../../services/manifest';
import { listRegular } from '../../../services/order';

let regularLabels = {
  door2door: 'Door 2 Door',
  dropoff2dropoff: 'Dropoff 2 Dropoff',
  dropoff2door: 'Dropoff 2 Door',
  door2dropoff: 'Door 2 Dropoff',
};

const tableStyle = {
  width: '100%',
  borderCollapse: 'collapse',
  border: '1px solid black',
  marginTop: '20px',
};

const cellStyle = {
  border: '1px solid black',
  padding: '8px',
  textAlign: 'left',
};

const ShippingLabel = () => {
  const { id } = useParams();
  const { token } = useContext(UserContext);
  const [manifestInfo, setManifestInfo] = useState(null);
  const [rows, setRows] = useState([]);

  const handlePrint = () => {
    setTimeout(() => {
      window.print();
    }, 1000);
  };

  const loadData = payload => {
    listRegular(token, {
      ...payload,
      fields: {
        'manifest-id': parseInt(id),
      },
      operator: '=',
      'user-specific': false,
    }).then(res => {
      const summary = [];
      res.collection.forEach(item => {
        summary.push({
          id: item.raw.id,
          ...item.data,
          type: regularLabels[item.data.type],
          orgin: `${item.data['sender-city']}, ${item.data['sender-province']}`,
          destination: `${item.data['receiver-city']}, ${item.data['receiver-province']}`,
          payment: toTitleCase(item.data['payment-type']),
          total: 'PHP ' + moneyFormat(item.data['total']),
        });
      });

      setRows(summary);
    });
  };

  const loadInformation = () => {
    info(token, id).then(res => {
      setManifestInfo(res);
    });
  };

  useEffect(() => {
    loadInformation();
    loadData({
      'current-page': 1,
      'record-per-page': 'all',
    });
  }, []);

  useEffect(() => {
    if (manifestInfo) {
      handlePrint();
    }
  }, [manifestInfo]);

  if (!manifestInfo || !rows) {
    return null;
  }

  return (
    <>
      <div
        style={{
          width: '8.5in',
          height: '11in',
          padding: '20px',
          margin: 'auto',
        }}>
        <div
          style={{
            width: '100%',
            float: 'left',
          }}>
          <img
            src="/img/logo.png"
            alt="Logo"
            style={{
              width: '165px',
              float: 'left',
            }}
          />
          <div
            style={{ marginBottom: '20px', marginTop: '50px', float: 'left' }}>
            <strong style={{ margin: '0px' }}>Box Track Cargo, Inc. </strong>
            <p style={{ margin: '0px' }}>
              651 2nd Floor Casa Maritima Bldg. Gen. Luna St. Intramuros,
              Manila, Philippines
            </p>
            <p style={{ margin: '0px' }}>Phone: +63 905 247 0338</p>
          </div>
        </div>
        <div
          style={{
            width: '100%',
            float: 'left',
          }}>
          <p style={{ margin: '0px' }}>
            <strong>Manifest Number:</strong>{' '}
            {manifestInfo.data['reference-id']}
          </p>
          <p style={{ margin: '0px' }}>
            <strong>Manifest Name:</strong> {manifestInfo.data['name']}
          </p>
          <p style={{ margin: '0px' }}>
            <strong>Origin:</strong> {manifestInfo.data['origin']}
          </p>
          <p style={{ margin: '0px' }}>
            <strong>Destination:</strong> {manifestInfo.data['destination']}
          </p>
          <p style={{ margin: '0px' }}>
            <strong>Description:</strong> {manifestInfo.data['description']}
          </p>

          <table style={tableStyle}>
            <thead>
              <tr>
                <th style={cellStyle}>Order Number</th>
                <th style={cellStyle}>Delivery Type</th>
                <th style={cellStyle}>Origin</th>
                <th style={cellStyle}>Destination</th>
                <th style={cellStyle}>Cost</th>
                <th style={cellStyle}>Payment Type</th>
              </tr>
            </thead>
            <tbody>
              {rows.map((row, index) => (
                <tr key={index}>
                  <td style={cellStyle}>{row['reference-number']}</td>
                  <td style={cellStyle}>{row.type}</td>
                  <td style={cellStyle}>{row.orgin}</td>
                  <td style={cellStyle}>{row.destination}</td>
                  <td style={cellStyle}>{row.total}</td>
                  <td style={cellStyle}>{row.payment}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <SignatureBox 
            label={'Prepared By'}
            description={'Main Origin Drop Off - Name and Location'}
          />
           <SignatureBox 
            label={'Accepted By'}
            description={'BTC Representative / Driver'}
          />
          <SignatureBox 
            label={'Received By'}
            description={'Main Destination Drop Off - Name and Location'}
          />
        </div>
      </div>
    </>
  );
};


const SignatureBox = ({label, description}) => {

  return <>
    <div style={{
      width: '50%',
      float: 'left',
      marginTop: '55px',
    }}>
      <strong>{label} : ______________________________</strong>
      <p>{description}</p>
      <strong>Date : _____________________________________</strong>
    </div>
  </>
}

export default ShippingLabel;
