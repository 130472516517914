import React, { useState, useContext, useEffect } from 'react';
import { getAccountBalanceHistory } from '../../../services/user';
import { moneyFormat } from '../../../utils/helpers';
import { UserContext } from '../../../context/UserProvider';
import { styles } from '../../../config/defaultPageStyles';
import {
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  FormControl,
  TextField,
} from '@mui/material';
import CurrencyFormat from 'react-currency-format';
import { Link, useNavigate } from 'react-router-dom';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';

const App = () => {
  const { token } = useContext(UserContext);
  const [accountBalanceHistory, setAccountBalanceHistory] = useState([]);
  const timeAgo = new TimeAgo('en-US');
  const navigate = useNavigate();

  const loadAccountBalanceHistory = () => {
    getAccountBalanceHistory(token).then(res => {
      console.log('res', res);
      setAccountBalanceHistory(res.collection);
    });
  };

  const RenderInformation = ({ data }) => {
    const tempDom = [];
    console.log('data', data);
    /*
    {
    "COD": 1000,
    "NET": 289.2032,
    "WHT (2%)": 4.6272512,
    "VAT (12%)": 39.4368,
    "Admin Fee (20%)": 57.84064,
    "Total Deduction": 1101.9046912,
    "Your NET Earning": 226.7353088,
    "Your Earning (80%)": 231.36255999999997
}
    */
    const sortByKey = [
      "VAT (12%)",
      "NET",
      "Admin Fee (20%)",
      "Your Earning (80%)",
      "WHT (2%)",
      "COD",
      "Your NET Earning",
      "Total Deduction",
    ];

    const keyColor = {
      "VAT (12%)": "black",
      "NET": "black",
      "Admin Fee (20%)": "black",
      "Your Earning (80%)": "black",
      "WHT (2%)": "black",
      "COD": "black",
      "Your NET Earning": "green",
      "Total Deduction": "black"
    };

    const showItems = {
      "VAT (12%)": true,
      "NET": false,
      "Admin Fee (20%)": true,
      "Your Earning (80%)": true,
      "WHT (2%)": false,
      "COD": true,
      "Your NET Earning": false,
      "Total Deduction": false
    }

    let sortedData = {};
    sortByKey.forEach(key => {
      if (data[key] > 0) {
        sortedData[key] = data[key];
      }
    });

    data = sortedData;

    for (const [key, value] of Object.entries(data)) {
      if (value > 0) {
        if (showItems[key]) {
          tempDom.push(
            <div key={key} style={{color: (keyColor[key] ?? 'black')}}>
              <strong>{key}:</strong> <span style={{float: 'right'}}> PHP {moneyFormat(value)}</span>
            </div>,
          );
        }
      }
    }
    
    return (
      <div style={
        {
          "borderTop": "solid 1px #b3b3b3",
          "marginTop": "10px",
          "paddingTop": "10px"
        }
      }>
        <strong>Information</strong>
        {tempDom}
      </div>
    );
  };

  useEffect(() => {
    loadAccountBalanceHistory();
  }, []);

  return (
    <div style={styles.pageContainer}>
      <div style={styles.pageHeader}>Transaction History</div>
      <div style={{ marginTop: '20px' }}>
        {accountBalanceHistory.map((item, index) => (
          <div
            key={index}
            style={{
              width: '100%',
              background: '#e1dede',
              padding: '10px',
              borderRadius: '10px',
              marginBottom: '10px',
            }}>
            <div style={{ width: '100%' }}>
              <strong>
                {item.data.data.description ?? item.data.processor}
              </strong>
              <br />
              <small>{timeAgo.format(new Date(item.raw.created_last))}</small>
            </div>
            <div style={{
              display: 'flex',
            }}>
            <div style={{ width: '50%' }}>
              {item.data.credit > 0 && (
                <div>
                  <strong>Transaction:</strong>
                  <br />{' '}
                  <span style={{ color: 'green' }}>
                    + PHP {moneyFormat(item.data.credit)}
                  </span>
                </div>
              )}
              {item.data.debit > 0 && (
                <div>
                  <strong>Transaction:</strong>
                  <br />
                  <span style={{ color: 'red' }}>
                    - PHP {moneyFormat(item.data.debit)}
                  </span>
                </div>
              )}
            </div>
            <div style={{ width: '50%' }}>
              <div>
                <strong>Balance:</strong>
                <br /> PHP {moneyFormat(item.data.balance)}
              </div>
            </div>
            </div>

            {item.data.credit > 0 && item.data.data?.information && <>
              <div style={{ width: '100%' }}>
                <RenderInformation data={item.data.data.information} />
              </div>
            </>}
            
            {item?.data?.data?.remarks && item?.data?.data?.remarks?.length > 0 &&
              <div style={{ width: '100%' }}>
                <div>
                  <strong>Remarks:</strong>
                  <br /> {item.data.data.remarks}
                </div>
              </div>
            }

          </div>
          
        ))}
      </div>
      <Button
        onClick={() => navigate('/driver/wallet')}
        variant="contained"
        fullWidth
        style={{ marginTop: '20px' }}>
        BACK
      </Button>
    </div>
  );
};

export default App;
