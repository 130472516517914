import React, { useContext, useState, useEffect } from 'react';
import Navbar from '../../../components/DesktopNavbar/admin.jsx';
import ImageLoader from '../../../components/ImageLoader';
import { mainMenu } from '../../../config/adminMenu.jsx';
import { UserContext } from '../../../context/UserProvider';
import { get, update } from '../../../services/customer';
import { moneyFormat, toTitleCase } from '../../../utils/helpers';
import { isMobile } from 'react-device-detect';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { Button } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

const tableStyle = {
  borderCollapse: 'collapse',
  width: '100%',
};

const thStyle = {
  border: '1px solid black',
  textAlign: 'left',
  padding: '8px',
  backgroundColor: '#f2f2f2',
};

const tdStyle = {
  border: '1px solid black',
  textAlign: 'left',
  padding: '8px',
};

const App = () => {
  const navigate = useNavigate();
  const { token, userAttr } = useContext(UserContext);
  const [data, setData] = useState(null);
  const [needAffiliation, setNeedAffiliation] = useState(false);
  const [affiliateStatus, setAffiliateStatus] = useState('Not Affiliated');
  let { id } = useParams();

  const loadData = payload => {
    get(token, id).then(res => {
      setData(res);
      if (
        res.customer.type == 'For Affiliation Verification' &&
        res.customer['affiliate-valid-identification-card-file-id']
      ) {
        setNeedAffiliation(true);
        setAffiliateStatus(res.customer.type);
      }
      setAffiliateStatus(res.customer.type);
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  const RowEntry = ({ label, value }) => {
    return (
      <tr>
        <td style={thStyle}>{label}</td>
        <td style={tdStyle}>{value}</td>
      </tr>
    );
  };

  const activateAffiliation = () => {
    let confirm = window.confirm('Are you sure you want to approve this user?');

    if (!confirm) {
      return;
    }

    update(token, { type: 'Affiliated' }, data.user['info-id']).then(res => {
      loadData();
    });
  };

  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Navbar pages={mainMenu} />
      <Container maxWidth={'xl'}>
        <Grid container spacing={2} style={{ height: 'auto' }}>
          <Grid item xs={12} sm={6} style={{ height: '100px' }}>
            <h1>Customer Information</h1>
          </Grid>
          <Grid item xs={12} sm={6} style={{ height: '100px' }}>
            <Button
              variant="contained"
              onClick={() => navigate('/admin/orders-same-day/customer/' + id)}
              style={
                isMobile
                  ? {}
                  : {
                      width: '160px',
                      float: 'right',
                      marginTop: '20px',
                      marginLeft: '20px',
                    }
              }>
              Same Day
            </Button>
            {data.user['status'] === 'active' && (
              <>
                <Button
                  variant="contained"
                  onClick={() => navigate('/admin/wallet/customer/' + id)}
                  style={
                    isMobile
                      ? {}
                      : {
                          width: '160px',
                          float: 'right',
                          marginTop: '20px',
                          marginLeft: '20px',
                        }
                  }>
                  Wallet
                </Button>
              </>
            )}

            {needAffiliation && (
              <>
                <Button
                  variant="contained"
                  onClick={() => activateAffiliation()}
                  style={
                    isMobile
                      ? {}
                      : {
                          width: '260px',
                          float: 'right',
                          marginTop: '20px',
                          marginLeft: '20px',
                        }
                  }>
                  Approve Affiliation
                </Button>
              </>
            )}

            <Button
              variant="contained"
              onClick={() => navigate('/admin/customers')}
              style={
                isMobile
                  ? {}
                  : {
                      width: '160px',
                      float: 'right',
                      marginTop: '20px',
                    }
              }>
              Back
            </Button>
          </Grid>
          <Grid item xs={12} sm={12}>
            <table style={tableStyle}>
              <tbody>
                <tr>
                  <td
                    style={{
                      ...thStyle,
                      fontWeight: 'bold',
                      fontSize: '20px',
                      textAlign: 'center',
                      backgroundColor: '#dedff4',
                    }}
                    colSpan={2}>
                    Account Information
                  </td>
                </tr>
                <RowEntry
                  label="Account Status"
                  value={toTitleCase(data.user['status'])}
                />
                <RowEntry label="Mobile Number" value={data.user['login']} />
                <RowEntry
                  label="Name"
                  value={`${data.customer['first-name']} ${data.customer['middle-name']} ${data.customer['last-name']}`}
                />
                <RowEntry
                  label="Birth Date"
                  value={data.customer['birth-date']}
                />
                <RowEntry
                  label="Address Line 1"
                  value={data.customer['address-line-1']}
                />
                <RowEntry
                  label="Address Line 2"
                  value={data.customer['address-line-2']}
                />
                <RowEntry label="City" value={data.customer['address-city']} />
                <RowEntry
                  label="Province"
                  value={data.customer['address-province']}
                />
                <RowEntry
                  label="Zip Code"
                  value={data.customer['address-zip-code']}
                />
                <RowEntry
                  label="Email Address"
                  value={data.customer['email-address']}
                />
                <RowEntry label="Affiliate Status" value={affiliateStatus} />
                {data.customer[
                  'affiliate-valid-identification-card-file-id'
                ] && (
                  <RowEntry
                    label="Affiliate ID"
                    value={
                      <ImageLoader
                        id={
                          data.customer[
                            'affiliate-valid-identification-card-file-id'
                          ]
                        }
                        width="40%"
                      />
                    }
                  />
                )}
              </tbody>
            </table>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default App;
