import React, { useState, useRef, useContext } from 'react';
import { Button, TextField } from '@mui/material';
import ImageUploader from '../../../components/ImageUploader';
import { updateRegular } from '../../../services/order';
import { UserContext } from '../../../context/UserProvider';
import { useNavigate, useParams } from 'react-router-dom';

const App = ({ id, backHandler, data }) => {
  const navigate = useNavigate();
  const { token } = useContext(UserContext);
  const photoList = useRef([]);
  const description = useRef(null);

  const submit = () => {
    if (photoList.current.length < 1) {
      return alert('Please upload photo');
    } else if (!description.current.value || description.current.value === '') {
      return alert('Please enter delivery note');
    }

    updateRegular(token, {
      data: {
        ...data.data,
        "proof-of-delivery": {
          packagePhoto: photoList.current[0].id,
          packageDescription: description.current.value,
          dateTimeCompleted: new Date().toISOString(),
        }
      },
      status: 'completed',
    }, id).then(res => {
      console.log(res);
      if (res && res.message === "Record Updated") {
        alert('Order completed');
        navigate('/driver');
      } else {
        alert('Something went wrong');
      }
    });
  };

  return (
    <>
      <div
        style={{
          textAlign: 'center',
          fontSize: '1.2rem',
          paddingTop: '15px',
          paddingBottom: '15px',
        }}>
        PROOF OF DELIVERY
      </div>
      <div
        style={{
          textAlign: 'center',
          fontSize: '1.2rem',
          paddingTop: '15px',
          paddingBottom: '15px',
        }}>
        <ImageUploader
          name={'packagePhoto'}
          type={'Package Photo'}
          title={'Please Upload Photo of the receiver with the package'}
          label={''}
          ref={photoList}
          defaultImage={photoList.current[0] ? photoList.current[0].url : null}
        />
        <TextField
          label={'Delivery Note'}
          variant="outlined"
          type={'text'}
          multiline
          rows={4}
          fullWidth
          inputRef={description}
        />
      </div>
      <div style={{ width: '100%', paddingBottom: '15px' }}>
        <Button
          variant="contained"
          color="error"
          onClick={() => submit()}
          style={{ marginBottom: '10px' }}>
          SUBMIT
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => backHandler()}>
          Back
        </Button>
      </div>
    </>
  );
};

export default App;
