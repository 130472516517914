import React, { createContext, useEffect, useState, useRef } from 'react';

const StyleContext = createContext({ maxWidth: 'sm', backgroundColor: '#fff' });

const StyleProvider = ({ children }) => {
  const [backgroundColor, setBackgroundColor] = useState('#fff');
  const [maxWidth, setMaxWidth] = useState('sm');
  const isMountedRef = useRef(false);

  useEffect(() => {
    document.getElementById('root').style.backgroundColor = backgroundColor;
  }, [backgroundColor]);

  useEffect(() => {
    //
  }, [maxWidth]);

  return (
    <StyleContext.Provider
      value={{ maxWidth, setMaxWidth, backgroundColor, setBackgroundColor }}
    >
      {children}
    </StyleContext.Provider>
  );
};

export default StyleProvider;
export { StyleContext };
