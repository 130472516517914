import React from 'react';

import { Grid, Button, Typography } from '@mui/material';
import { create as createDriver } from '../../../services/driver';
import { create as createVehicle } from '../../../services/vehicle';
import { create as createUser } from '../../../services/user';
import dayjs from 'dayjs';

const Terms = ({ userData, vehicleData, nextStep, prevStep, handleSubmit }) => {
  const handleFormSubmit = () => {
    console.log('userData', userData);
    console.log('vehicleData', vehicleData);
    const confirm = window.confirm('Kindly review all the information carefully before clicking OK to proceed.');

    if (!confirm) return;
    
    createDriver(prepDriverPayload()).then(res => {
      const driverId = res.raw.id;
      createUser(prepUserManagementPayload(driverId)).then(res => {
        createVehicle(prepVehiclePayload(driverId)).then(res => {
          nextStep();
        });
      });
    });
  };

  const prepUserManagementPayload = driverId => {
    return {
      login: userData.contactNumber,
      password: userData.password,
      type: 'driver',
      'info-id': driverId,
      access: [],
    };
  };

  const prepDriverPayload = () => {
    return {
      'first-name': userData.firstName,
      'last-name': userData.lastName,
      'middle-name': userData.middleName,
      'birth-date': dayjs(userData.birthDate).format('YYYY-MM-DD'),
      'address-line-1': userData.addressLine1,
      'address-line-2': userData.addressLine2,
      'address-city': userData.city,
      'address-province': userData.province,
      'address-zip-code': userData.zipCode,
      'email-address': userData.emailAddress,
      'drivers-license-file-id': userData['drivers-license-file-id'],
      'valid-identification-card-file-id':
        userData['valid-identification-card-file-id'],
    };
  };

  const prepVehiclePayload = driverId => {
    return {
      type: vehicleData['type-id'],
      brand: vehicleData.brand,
      model: vehicleData.model,
      color: vehicleData.color,
      'plate-number': vehicleData['plate-number'],
      'validity-date': vehicleData['validity-date'],
      owner: vehicleData.owner,
      'official-receipt-file-id':
        vehicleData['vehicle-official-receipt-file-id'],
      'certification-of-registration-file-id':
        vehicleData['certificate-of-registration-file-id'],
      'authorization-file-id': vehicleData['authorization-file-id'],
      'driver-id': driverId,
    };
  };

  return (
    <Grid container direction="column" alignItems="center">
      <form>
        <Typography variant="h6" gutterBottom textAlign={'center'}>
          Terms & Condition
        </Typography>
        <Typography variant="caption">
          <div>
            <p>
              <span style={{ fontSize: '11pt' }}>
                This Boxtrack Delivery Partner Terms of Use (“Terms of Use”)
                governs the access or use by you (the “Delivery Partner”) of the
                information service software (“Boxtrack Driver App” or
                “Software”) through our mobile applications and website
                applications (together, the “Platforms”) or websites to receive
                services made available by Box Track Cargo Inc, a company
                incorporated in the Philippines with its registered office at
                651 2nd Floor Casa Maritima Bldg. Gen. Luna St. Intramuros,
                Manila, Philippines &nbsp;(“Boxtrack”), and the rights and
                obligations between you, as the Delivery Partner, and Boxtrack.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                Please make sure to carefully read through these Terms of Use
                whether stated or otherwise referenced herein before accessing
                or using the Software.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                This Terms of Use is a binding legal agreement setting forth the
                rights and obligations between Boxtrack and you, the Delivery
                Partner.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                By accessing or using the Software, you hereby expressly affirm
                that:
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                you have read and understand these Terms of Use;
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                you will comply with these Terms of Use;
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                you are at least the age of legal majority in your place of
                residence and legally competent to enter into contracts; and
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                you have the authority to enter into these Terms of Use
                personally and, if applicable, on behalf of any organization on
                whose behalf you have enrolled for use of the Software and to
                bind such organization to these Terms of Use
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                IF YOU DO NOT AGREE TO ANY OF THESE TERMS, PLEASE DO NOT ACCESS
                OR USE THE SOFTWARE. PLEASE READ CAREFULLY “DISCLAIMER” AND
                “INDEMNIFICATION”. &nbsp;THESE PROVISIONS LIMIT OUR LIABILITY TO
                YOU.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                In these Terms of Use, “Applicable Law” means applicable
                Philippine laws, rules, and regulations governing payments and
                all services provided hereunder, including: any and all local
                laws, treaties, rules, regulations, regulatory guidance,
                directives, policies, orders or determinations of (or agreements
                with), and mandatory written direction from (or agreements with)
                any regulatory authority, as each of the foregoing may be
                amended and in effect from time to time.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                Boxtrack reserves the right to change these Terms of Use from
                time to time. If we make changes, we will notify you by revising
                the date at the top of the Terms of Use, and, in some cases, we
                will provide you with additional notice (such as adding a
                statement to our homepage or sending you an email notification).
                We encourage you to review these Terms of Use periodically to
                stay informed about our practices. Whenever we make changes to
                these Terms of Use, they are effective when the revised Terms of
                Use are posted unless we notify you otherwise. If you continue
                to use the Software after the revised Terms of Use have been
                posted, then you will be deemed to have accepted the changes to
                these Terms of Use.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>1. OUR SERVICES&nbsp;</span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                The services provided by Boxtrack (“Services”) are meant to
                connect persons, whether natural or juridical, who install or
                utilize a copy of the User applications, whether mobile or
                through the website (“User Software”), (the “Users”) and
                Delivery Partners, for the Delivery Partners to provide Private
                Carrier Services for the account of the Users. “Private Carrier
                Services” means the services provided by a Participating Driver
                for the account of a User, including the pickup, transportation,
                and delivery of the User’s shipment from and to stated locations
                in the Philippines using a booked vehicle (“Shipment Delivery”),
                as well as any add-on services.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                You acknowledge and agree that Boxtrack only provides you and
                Users with neutral, independent third-party information matching
                the offer to provide Private Carrier Services, on one hand, and
                the User’s intent to engage the Private Carrier Services, on the
                other. The Shipment Delivery and Private Carrier Services
                provided by Delivery Partners shall not be deemed to be provided
                by Boxtrack. You acknowledge that Boxtrack is not responsible
                for your acts and/or omissions as a Delivery Partner, and any
                liability in relation to the Shipment Delivery shall be borne by
                you as a Delivery Partner.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                You understand, affirm, and acknowledge that your relationship
                with Boxtrack is independent, and not an employer-and-employee
                relationship. As an accredited Delivery Partner, you acknowledge
                that you have full control over your time and that you have the
                discretion as to when to accept and perform a Shipment Delivery.
                Additionally, no joint venture, partnership, employment, or
                agency relationship exists between you as a Delivery Partner and
                Boxtrack as a result of this Terms of Use or use of the
                Software. Even if you, as a Delivery Partner, choose on your own
                to wear apparel or accessories bearing the Boxtrack trademark,
                brand, or logo, or use Boxtrack signages, stickers, or
                identification badges, you agree that such decision is of your
                own accord, and is not required by Boxtrack, and may thus not be
                deemed an indication of any employer-employee, joint venture,
                partnership, or agency relationship between you and Boxtrack.
                You also acknowledge that your use of any signage, sticker, or
                identification badge does not lead to an inference of being an
                employee.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                2. USE OF THE SOFTWARE&nbsp;
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                While using the Software provided by Boxtrack, you agree that:
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                you are not permitted to rent, lease, sublicense, distribute or
                transfer copies of the Software or the license for the use of
                the Software to any third parties;
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                you shall not probe, scan or test the vulnerability of the
                Software or any network connected to the Software nor breach the
                security or authentication measures on the Software or any
                network connected to the Software;
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                you shall not modify, adapt, reverse engineer, decompile,
                disassemble, translate the Software or create derivative works
                based on the Software;
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                you shall not interrupt the normal operation of the Software, or
                use any methods to export or modify the source code of the
                Software;
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                you shall not upload or send out any kinds of computer viruses,
                worms, trojans, or malicious codes;
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                you shall not install and/or execute the Software on any device
                other than the mobile device running the operating systems
                approved by Boxtrack;
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                you shall not allow access to the Software by any party not duly
                authorized by Boxtrack; and
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                other than the license to use the Software granted herein, no
                other license or right is hereby granted to you, and the
                ownership of the Software and all other rights are hereby
                expressly reserved by Boxtrack and its suppliers.
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                3. USE OF Boxtrack BRAND, LOGO, AND COLLATERAL
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                You acknowledge that you as a Delivery Partner has the option to
                use Boxtrack collaterals such as uniform, jackets and IDs, and
                the Boxtrack Insulated Bag (the “Lalabag”) (collectively, the
                “Collaterals”). You acknowledge that the use of the Lalabag
                shall be subject to the relevant agreement pertaining to the
                sale and use of such Lalabag and for which purpose, you
                authorize Boxtrack to deduct from the Driver Wallet or from the
                Security Deposit.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                You, as Delivery Partner, undertake to use Collaterals,
                particularly the Lalabag, containing the Boxtrack brand with
                care and shall always conduct yourself in a responsible manner
                so as not to cause damage and harm to the Boxtrack brand, and
                that you shall not use the Lalabag for illegal activities. You
                shall indemnify Boxtrack and be held liable from any claims or
                liabilities arising from the breach of this provision.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                You acknowledge that, if you are accredited in Cebu with 600kg
                pick-ups, you shall install the Boxtrack sticker on your vehicle
                as part of the accreditation process. For this purpose, you
                and/or the owner of the vehicle agree to sign and execute the
                Advertising Agreement with Boxtrack. &nbsp;
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                You acknowledge that any unauthorized use of the Boxtrack brand,
                logo, and collaterals is a violation of the intellectual
                property rights of Boxtrack and may be subject to criminal
                action.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                4. DELIVERY FREELANCER ACCOUNT&nbsp;
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                In order for you to use the Services, you are required to
                register for a Delivery Partner Account (“Delivery Partner
                Account”) with Boxtrack. Once your registration is successfully
                completed with Boxtrack, you will be provided with a Delivery
                Partner Account, accessible with a password of your choice.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                Upon registration, you are obligated to provide your personal
                information, such as your name, photograph, and contact
                information, directly or indirectly in accordance with
                Boxtrack’s Privacy Policy. You agree that the details entered
                and maintained by you are accurate, complete, and valid.
                Boxtrack is not liable for false, incomplete, old, or incorrect
                registration information provided by you. Your failure to enter
                or maintain accurate, complete, and valid information may result
                in your inability to access and use the Services. You are solely
                responsible for all activity that occurs under your Delivery
                Partner Account. Boxtrack is entitled to verify the information
                that you have provided at all times and refuse the use of the
                Services without providing reasons and prior notice.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                You are responsible for acquiring and updating compatible
                hardware or devices necessary to access and use the Services.
                Boxtrack does not guarantee that the Services, or any portion
                thereof, will function on any particular hardware or device.
                Boxtrack reserves the right to unilaterally terminate the use of
                the Services should you be using the Service in an incompatible
                or unauthorized device, or when the security of your Delivery
                Partner Account has been compromised in any way, or for any
                reason we may find just.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                While using the Services, you agree that:
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                you shall only access the Services using means explicitly
                authorized by Boxtrack;
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                the Software and Services provided will solely be used by you,
                for your personal use and will not be resold to any third party;
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                you warrant that you are the exclusive user of the mobile number
                registered with the Software;
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                you will immediately inform Boxtrack of any loss or change in
                the mobile number you are using for the software;
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                you will not authorize others to use your Delivery Partner
                Account;
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                you are only allowed to open a single Delivery Partner Account;
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                transferring of your Delivery Partner Account to any other
                person or legal entity is not permitted;
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                you cannot use an account that is subject to any rights of a
                person other than you without appropriate authorization;
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                the Services cannot be used for unlawful purposes, including but
                not limited to (i) violation of Applicable Law; (ii) storing or
                sending any unlawful material; (iii) sharing of sensitive
                personal information of others, without their consent (iv)
                causing intentional harm, nuisance, disparagement, inconvenience
                or annoyance; (v) impairing or harming the proper operation of
                the Services; (vi) exhibiting the tenor of impersonating another
                person; or (vii) copying or distributing the Services without
                the permission of Boxtrack;
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                your password or any identification credentials that Boxtrack
                provides to you should be secure and confidential;
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                proof of identity or other documentation must be submitted
                immediately if requested by Boxtrack;
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                you shall not use any "deep-link", "page-scrape", "robot",
                "spider", or other automatic device, program, algorithm, or
                methodology, or any similar or equivalent manual process, to
                access, acquire, copy, or monitor any portion of the Software or
                any content, or in any way reproduce or circumvent the
                navigational structure or presentation of the Software or any
                content, to obtain or attempt to obtain any materials, documents
                or information through any means not purposely made available
                through the Software. Boxtrack reserves the right to bar any
                such activity;
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                you shall not attempt to interfere with, compromise the system
                integrity or security, or decipher any transmissions to or from
                the servers running the Software;
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                you shall not collect or harvest any personally identifiable
                information, including account names, from the Software; and
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                you shall not engage in threatening, harassing, discriminatory
                (based on race, gender, age, disability, or any other protected
                classification) or any other behavior that Boxtrack deems
                inappropriate when using the Services.
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                5. DELIVERY PARTNER WARRANTIES AND REPRESENTATIONS&nbsp;
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                You, as the Delivery Partner, hereby irrevocably make the
                following warranties and representations:
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                After obtaining the information of Users and shipments, you
                shall verify such information by yourself. As the Delivery
                Partner, you shall, at your own discretion, make the decision
                whether to accept and provide the Shipment Delivery after
                properly verifying the information obtained;
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                You understand and agree that you are responsible for complying
                with all delivery schedules established between you and User.
                You shall make all reasonable efforts to deliver the shipment
                according to the respective delivery schedules and special
                instructions established with User;
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                You shall be solely responsible for any and all claims,
                judgments and liabilities resulting from any accident, loss or
                damage including, but not limited to, personal injuries, death,
                total loss and property damage which is due to or is alleged to
                be a result of the Shipment Delivery provided by you;
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                You shall be responsible for the contents of all shipments and
                all damage or loss to shipments upon taking delivery. You shall
                take all reasonable precautions to prevent unauthorized persons
                from having access to the shipment and shall also take all
                reasonable precautions against loss of or damage to the
                shipment;
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                You shall, at all times while you are providing the Shipment
                Delivery, hold a valid driver’s license and all other required
                licenses, permits, registrations, approvals and/or authorities
                required to provide Shipment Delivery within the jurisdiction in
                which you will provide Shipment Delivery. In order for Boxtrack
                to comply with any obligations applicable to it, you will, upon
                request by Boxtrack, provide copies in the form required by
                Boxtrack of all licenses, permits, registrations, approvals
                and/or authorities as requested from time to time;
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                Aside from the Software, you shall be responsible for providing
                all of the equipment necessary to perform the Shipment
                Deliveries. You shall hold title or otherwise have sufficient
                rights in the equipment you will use to provide Shipment
                Delivery. Delivery Partner is solely responsible for maintaining
                all such equipment and ensuring such equipment complies with all
                legal, safety, and quality standards. Except as otherwise
                required by Applicable Laws, Delivery Partner assumes all risk
                of damage or loss to its equipment;
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                You shall not use a vehicle other than your registered vehicle
                to provide Shipment Delivery to Users, which must at all times
                meet all applicable vehicle standards as updated from time to
                time, including possessing the relevant roadworthiness
                certification in your jurisdiction. You shall use vehicles in
                good operating condition and compliant with all Applicable Laws.
                You shall keep records of all vehicle registrations and
                inspections and shall provide the latest version of such records
                upon request;
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                You shall at all times comply, at your expense, with your legal
                obligations in respect of insurance (including but not limited
                to compulsory third-party motor vehicle insurance for any
                vehicle used to provide Shipment Delivery, if applicable) and
                maintain, at your expense, such other policies as someone
                providing Shipment Delivery would prudently maintain.
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                You shall maintain, at your expense, any other minimum insurance
                cover which Boxtrack requests you to hold, including but not
                limited to a valid third-party motor vehicle insurance policy,
                which insurance policy/policies shall include provisions to
                cover risks attendant to commercial usage of the vehicle, and a
                general liability insurance policy at levels which satisfy:
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                the minimum requirements that apply to entities providing
                services similar to private carrier services in the jurisdiction
                in which you are registered to provide Shipment Delivery;
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                the provision of Shipment Delivery and any other services
                contemplated by these Terms of Use; and
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                any other minimum standards imposed by Boxtrack (as notified by
                Boxtrack from time to time) for continued accreditation as
                Delivery Partner and Use of Software in Clause 2 above.
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                In order for Boxtrack to confirm your ongoing compliance with
                these requirements, you will, upon request by Boxtrack, provide
                copies of any relevant insurance policies and insurance
                certificates of currency and or other equivalent proof of
                insurance. If, at any time, you cease to hold a required
                insurance policy, you must immediately notify Boxtrack of this
                change in your circumstances and cease providing Shipment
                Delivery;
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                You shall obey all Applicable Laws related to Shipment Delivery
                and will be solely responsible for any violations of such
                Applicable Laws. You shall not accept any requests where the
                nature of the service solely contemplates the movement or
                carriage of passengers;
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                You shall obey all Applicable Laws related to Shipment Delivery
                and will be solely responsible for any violations of such
                Applicable Laws. You shall not accept any requests where the
                nature of delivery is solely the movement of passengers;
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                During the course of your work, you shall follow and comply with
                the Boxtrack Community Guidelines: Community Guidelines
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                The Boxtrack Community Guidelines apply to everyone who uses the
                Boxtrack Platforms. Delivery Partners shall have sole
                responsibility to familiarize themselves with Boxtrack Community
                Guidelines, as amended from time to time. The Delivery Partners
                hereby acknowledge that the Boxtrack Community Guidelines are
                mere guidelines, and that the Delivery Partners, as independent
                contractors, are independently and exclusively responsible for
                the means and method of performing the Private Carrier Services,
                provided that such means and methods of performance do not
                result in claims, losses, and/or damage to Boxtrack and/or the
                Users. Boxtrack hereby reserves the right to revoke/cancel a
                Delivery Partner’s accreditation as such if its/his/her actions
                in the performance of the Private Carrier Services expose
                Boxtrack and/or its Users to risk of claims, losses, and/or
                damage.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                Boxtrack retains the right to suspend the processing of any
                transaction where it reasonably believes that the transaction
                may be fraudulent, illegal, or involves any criminal activity or
                where you and/or the User have breached any of the provisions
                under these Terms of Use.
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                Delivery Partner acknowledges that during the course of
                its/his/her work, the Delivery Partner may gain knowledge of
                third parties’ and/or User’s confidential, proprietary, trade
                secret, protected health, and/or personally identifiable
                information (“Confidential Information”). This information
                includes, but is not limited to, information about User, User’s
                business and the contents of shipments, User’s address, User’s
                contact information, delivery address, payment information,
                payment methods, credit card information, financial accounts,
                demographic information, business address, information regarding
                User’s partners, associates, customers, and similar information
                regarding the delivery recipient. Delivery Partner agrees that
                it/he/she will maintain the confidentiality of all such
                Confidential Information and not disclose it to any other
                person, except as required to carry out Shipment Deliveries or
                as required by law. Unauthorized disclosure of such Confidential
                Information will constitute a material breach of this Terms of
                Use, giving Boxtrack the right to temporarily lock or
                permanently deactivate your Delivery Partner Account.
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                If Delivery Partner fails to comply with any of the above
                warranties and representations, Boxtrack shall have the right to
                immediately temporarily lock or permanently deactivate the
                corresponding Delivery Partner Account. Delivery Partner shall
                bear all damages or losses arising therefrom. Delivery Partner
                shall also compensate Boxtrack for all losses suffered by
                Boxtrack, its affiliates, its partners, and/or any other third
                party due to Delivery Partner's failure to comply with the above
                warranties and representations.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>6. PAYMENT</span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                Delivery Partner acknowledges that Users – not Boxtrack – are
                responsible for paying all charges and fees associated with the
                shipment. Once the User and the Delivery Partner have verified
                that the Shipment Delivery has been completed, the User’s
                payment account will be charged. Boxtrack reserves the right to
                change its prices charged at any time, at its discretion.
                Delivery Partner acknowledges and agrees that any payment
                received by Boxtrack from the User pursuant to any Shipment
                Delivery is due to the information service provided through the
                Software. &nbsp;All incentives, rewards, and bonuses granted to
                the Delivery Partner shall be credited to the Driver Wallet.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                Boxtrack retains the right to suspend the processing of any
                transaction where it reasonably believes that the transaction
                may be fraudulent, illegal, or involves any criminal activity,
                or where you and/or the User have breached any of the terms in
                this Terms of Use. In such an event, you shall not hold Boxtrack
                liable for any withholding of, delay in, suspension, forfeiture,
                or cancellation of, any payment(s) to you.
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                Boxtrack shall administer payments to you and from you through a
                wallet system. Your Delivery Partner wallet balance can be
                withdrawn by you to your designated bank account or third-party
                mobile wallet. Boxtrack reserves the right to make relevant
                deductions from your wallet balance based on our Terms of Use,
                Boxtrack Community Guidelines, as authorized by you or as
                notified to you via our Software.&nbsp;
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                As a Delivery Partner, depending on the services requested by
                our Users, you may be eligible to receive a combination of the
                fees stated below:
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>Fares:</span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                Base fares - This consists of the distance fare incurred during
                Shipment Delivery.
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                Order subsidies - This consists of the subsidies paid by
                Boxtrack and is included as part of the basic fare, subsidies
                not limited to peak demand subsidies.
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                Lala Coupon - This consists of the amount of promotion applied
                to a particular order. Promotions are typically given to Users
                and subsidized by Boxtrack.
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>Fees and other charges</span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                Priority Fee - This relates to the additional fees a User has
                committed to pay prior to the Shipment Delivery with an
                objective to encourage speedy fulfillment.
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                Waiting Fee - This relates to the additional fees imposed to a
                particular order where Deliver Partners were requested by User
                to wait for goods receival or goods deliveries.
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                High demand surcharge - This relates to the additional fees
                imposed to a particular order to encourage Shipment Delivery
                during super peak hours. Boxtrack retains the discretion to
                define super peak hours during non-peak season based on
                operational needs.
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                Minimum surcharge - This relates to the shortfall between the
                calculated fare and the pre-determined minimum fares. Boxtrack
                reserves the right to amend the minimum fares from time to time.
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                Toll fees - This refers to the tunnel and toll charges you have
                incurred during Shipment Delivery. This shall be claimed on an
                actual cost basis and shall be mutually agreed on with the User.
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                Order charges - This relates to other fees and surcharges in
                relation to the value-added services requested by the User. This
                category includes items, but not limited to, moving fees,
                purchase service surcharges etc.
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                Peak season surcharge – This refers to an additional fixed fee
                imposed charged during the holiday season, where there is an
                extreme disproportionate ratio between the demand for private
                carrier services and availability of Delivery Partners.
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>Boxtrack initiatives</span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                Offline marketing retainers - This relates to the amount
                Boxtrack pays you in return of you agreeing to affix offline
                marketing materials, such as stickers or decals, bearing the
                Boxtrack brand or logo onto your vehicle. Dimensions, design,
                and position of said offline marketing material on the Delivery
                Partner vehicle is to be determined by Boxtrack. You further
                agree that you will cooperate with Boxtrack's offline marketing
                material retention check when requested. Failure to comply may
                lead to offline marketing retainers being forfeited.
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                Driver-refer-user campaign - This is a campaign where you will
                receive monetary rewards after successful referrals of
                first-time users into the Boxtrack platform. You will be issued
                a unique promotion code if you opt into this campaign, where you
                will be encouraged to distribute this code to third parties. In
                the event where there was a successful redemption of the
                promotion code to a completed order originating from a new user,
                you will be rewarded as per the rates defined by Boxtrack.
                Boxtrack reserves the right to revoke rewards if we reasonably
                believe there are attempts to defraud Boxtrack through this
                campaign.
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>7. SECURITY DEPOSIT</span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                Delivery Partners shall be required to furnish Boxtrack a
                security deposit as per the Security Deposit Policy. Link here
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                Delivery Partners shall have sole responsibility to familiarize
                themselves with Security Deposit Policy before paying the
                security deposit.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>8. COMMUNICATION&nbsp;</span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                By creating a Delivery Partner Account, you agree to accept and
                receive electronic communications from Boxtrack including via
                email, text message, calls, and push notifications to the mobile
                device or cellphone number you provide to Boxtrack. You
                understand and agree that you may receive communications
                generated by automatic telephone dialing systems and/or devices
                which will deliver prerecorded messages sent by or on behalf of
                Boxtrack, its affiliated companies and/or third-party
                contractors, including but not limited to communications
                concerning orders accepted through your registration with
                respect to the Services. You may opt-out by changing your
                notification preferences. Please note that opting out may impact
                your use of the Services.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>9. PROMOTIONS&nbsp;</span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                For the mutual benefit of both Boxtrack and all Delivery
                Partners including you, from time to time, Boxtrack on your
                behalf may offer promotions to some or all Users that may have
                the effect of Users paying you a lower amount than would
                otherwise have been the case, and where a promotion applies, you
                agree to provide Shipment Delivery to the Users at the lower
                amount. Where a promotion applies and where applicable, Boxtrack
                will reduce the value of its fees, as provided under Clause 6
                herein, by an amount equal to the value of the promotions.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                10. OTHER THIRD PARTY INTERACTIONS&nbsp;
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                While using or accessing the Software, you may enter into
                correspondence with, purchase goods and/or services from, or
                participate in promotions of third party service providers,
                advertisers or sponsors showing their goods and/or services
                through the Software. Any such activity, and any terms,
                conditions, warranties or representations associated with such
                activity, is solely between you and the applicable third party.
                Boxtrack and its licensors shall have no liability, obligation
                or responsibility for any such correspondence, purchase,
                transaction or promotion between you and any such third-party.
                Boxtrack has no responsibility or liability arising from any
                agreements between you and such third party providers.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>&nbsp;</span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                11. INTELLECTUAL PROPERTY RIGHTS&nbsp;
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                Boxtrack alone (and its licensors, where applicable) shall own
                all rights, title and interest, including all related
                intellectual property rights, in and to the Software and the
                Services. These Terms of Use do not constitute a sale and do not
                convey to you any rights of ownership in or related to the
                Software and the Services, or any intellectual property rights
                owned by Boxtrack. Company names, logos, and the product names
                associated with the Software and the Services are trademarks
                and/or intellectual property of Boxtrack or third parties, and
                no right or license is granted to use them. You agree that you
                will not remove, alter or obscure any copyright, trademark,
                service mark, or other proprietary rights notices incorporated
                in or accompanying the Software and the Services.&nbsp;
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                By providing content, information, or materials, except personal
                information, (“Delivery Partner Content”) to Boxtrack, you grant
                Boxtrack a worldwide, perpetual, irrevocable, transferrable,
                royalty-free license, with the right to sublicense, to use,
                copy, modify, create derivative works of, distribute, publicly
                display, publicly perform, and otherwise exploit in any manner
                such Delivery Partner Content in all formats and distribution
                channels now known or hereafter devised, without further notice
                to or consent from you, and without the requirement of payment
                to you or any other person or entity, except as may be limited
                under Applicable Law.&nbsp;
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                You acknowledge that Boxtrack only acts as a passive channel for
                the distribution of the Delivery Partner Content and is not
                responsible or liable to you or to any third party for the
                content or accuracy of the Delivery Partner Content. Boxtrack
                shall not be continuously monitoring Delivery Partner Content
                published by you, nor shall Boxtrack be under an obligation to
                do so. Without limiting the foregoing, you acknowledge and agree
                that any remarks, opinions, comments, suggestions, and other
                information expressed or included in the Delivery Partner
                Content do not necessarily represent those of Boxtrack.&nbsp;
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                Any use by you of the Delivery Partner Content is entirely at
                your own risk. You represent and warrant that any Delivery
                Partner Content posted or transmitted by you is original to you
                and does not copy the work of any third party or otherwise
                infringe any third party intellectual property rights, rights of
                privacy or personality rights and does not contain any
                defamatory or disparaging statements. Furthermore, you represent
                and warrant that you have the capacity to grant the license as
                stipulated in this paragraph.&nbsp;
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                You agree to indemnify and keep Boxtrack, its affiliates, and
                licensors indemnified against all costs, expenses, damages,
                losses and liabilities incurred or suffered by Boxtrack or its
                affiliated companies related to any Delivery Partner Content
                posted or transmitted by you or your other use of the website,
                the Service or the Software.&nbsp;
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                Boxtrack reserves the right at its sole discretion to block or
                remove (in whole or in part) any Delivery Partner Content posted
                or transmitted by you and which Boxtrack believes is not in
                accordance with these Terms of Use (including materials which
                infringe or may infringe third party intellectual property
                rights, rights of privacy or personality rights), or is
                otherwise unacceptable to us.&nbsp;
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                Boxtrack respects your rights to your ideas. Please do not
                submit any confidential ideas, information, or suggestions in
                any form to Boxtrack or any of its affiliates. For any ideas,
                information, or suggestions you do submit, regardless of what
                your communication regarding your submissions says, you
                understand that your submissions are voluntary and the following
                terms shall apply to your submissions: (i) your submissions and
                their contents will automatically become the property of
                Boxtrack, without any compensation to you; (ii) Boxtrack has no
                obligation to review your submissions; (iii) Boxtrack may
                implement and distribute any portion of your submissions and
                their contents for any purpose in any way, without any
                compensation to you; and (iv) Boxtrack has no obligation to keep
                your submissions confidential.&nbsp;
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                You may use information on the Software purposely made available
                by Boxtrack for downloading from the Software, provided that
                you:
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                do not remove any proprietary notice language in all copies of
                such documents and make no modifications to the information;
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                use such information only for your personal, non-commercial
                informational purpose and do not copy or post such information
                on any networked computer or broadcast it in any media; and
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                do not make any additional representations or warranties
                relating to such information.&nbsp;
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>12. INDEMNIFICATION</span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                You acknowledge that you are responsible for all shipments and
                all Shipment Deliveries while in process and even upon
                completion, should the User have any issues relating to the
                delivery. Delivery Partner agrees Boxtrack has no control or
                responsibility over shipments or Shipment Deliveries and only
                has control or responsibility for the functioning of the
                Software. Delivery Partner agrees that Boxtrack will not be
                responsible or liable for any actions, wrongdoing, losses or
                damages suffered by the Delivery Partner, except if such action
                is willful breach of this Terms of Use or gross
                negligence.&nbsp;
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                Delivery Partner agrees to defend, indemnify and hold harmless
                Boxtrack, as well as its past and present successors, assigns,
                affiliates, officers, owners, employees, and agents (“Boxtrack
                Indemnitee”) from any and all losses, actual or threatened
                suits, actions, proceedings (at law or in equity), claims,
                damages, claims for payment, deficiencies, fines, judgments,
                settlements, liabilities, costs, and expenses (including
                reasonable attorneys' fees, costs, penalties, interest, and
                disbursements) arising from or incurred in connection with
                claims asserted by a third party against a Boxtrack Indemnitee
                arising directly or indirectly from, or as a result of or in
                connection with, Delivery Partner’s (i) breach of this Terms of
                Use; (ii) conduct with respect to the Software, Services, and/or
                Shipment Deliveries; (iii) violation or alleged violation of any
                law or the rights of any third party, including, without
                limitation, other Delivery Partners, Users, drivers, employees,
                subcontractors, assigns, and pedestrians; (iv) disclosure of
                “Confidential Information”; (v) ownership, use or operation of
                any vehicle used in the Shipment Delivery; (vi) failure to have
                proper insurance, licenses, permits or authorizations; (vii) any
                damages or losses as a result of Delivery Partner’s work and/or
                conduct, and/or Delivery Partner’s work with third parties
                and/or Users, including, but not limited to, damages to
                shipments; (viii) wrongdoing, violation of policy, violation of
                law or other misconduct; and (ix) failure or alleged failure to
                pay all required taxes, withholding, insurance contributions or
                premiums. Under Delivery Partner’s duty to defend, Boxtrack
                shall be entitled to use its own counsel.&nbsp;
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                Delivery Partner agrees to perform Shipment Deliveries in
                compliance with all Applicable Laws and shall defend, indemnify
                and hold harmless Boxtrack for any violation or alleged
                violation by Delivery Partner of any such Applicable Laws.
                Boxtrack reserves the right to lock-out, suspend or terminate
                access to the Software of a Delivery Partner for any violations
                of this Terms of Use or any other conduct deemed inappropriate
                by Boxtrack. In the unlikely event Delivery Partner’s status as
                an independent contractor is challenged and Delivery Partner is
                determined by a court, agency or arbitrator not to be an
                independent contractor, Delivery Partner agrees to defend,
                indemnify and hold harmless Boxtrack from all costs, penalties,
                back taxes, damages and attorney’s fees associated with such a
                determination. Under Delivery Partner’s duty to defend, Boxtrack
                shall be entitled to use its own counsel.&nbsp;
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                Delivery Partner further agrees and acknowledges that it will
                not and cannot hold Boxtrack liable for any issues beyond
                Boxtrack’s direct control, including any acts of god, loss of
                power, disruption of power, shutdown, or technical difficulties
                with the Software, system maintenance, failure of other cellular
                reception, failure of other service providers, cancelation of
                services by other third-parties, pandemic, epidemics, outbreaks,
                the COVID-19 pandemic, strikes, labor disputes, civil
                disturbances, hostilities, war, natural disasters, flood, fire,
                sabotage, accident, loss or destruction of property,
                intervention by governmental entities, change in laws,
                regulations or orders, or other events or circumstances or
                analogous causes.&nbsp;
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>13. DISCLAIMERS&nbsp;</span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                The Services are provided "as is" and "as available." Boxtrack
                makes no representation, warranty, or guarantee regarding the
                reliability, timeliness, quality, suitability, or availability
                of the Services or any services requested through the use of the
                Services, or that the Services will be uninterrupted or
                error-free.&nbsp;
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                Boxtrack does not guarantee the quality, suitability, safety, or
                ability of third-party providers. You agree that the entire risk
                arising out of your use of the Services, and any service or
                shipment requested in connection therewith, remains solely with
                you, to the maximum extent permitted under Applicable Law.
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                Boxtrack does not control, manage or direct any third-party
                providers. Third party providers are not actual agents, apparent
                agents, ostensible agents, or employees of Boxtrack.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                Boxtrack does not control, endorse or take responsibility for
                any Delivery Partner Content or third party content available on
                or linked to by the Services. Boxtrack cannot and does not
                represent or warrant that the services or servers are free of
                viruses or other harmful components.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>&nbsp;</span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                14. TERMINATION OF SERVICES&nbsp;
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                Delivery Partner may stop using the Services and leave the
                Platform at any time. The Delivery Partner may be off-boarded
                from using the Services in cases of violation of this Terms of
                Use, Boxtrack Privacy Policy, or the Boxtrack Rules for
                Continued Accreditation or Community Guidelines. Boxtrack can
                blacklist the Delivery Partner from ever using the Services. In
                case of any outstanding liability to Boxtrack or to the User and
                such amount cannot be covered by the Security Deposit mentioned
                above in Clause 6, you undertake to pay the same directly in
                cash to Boxtrack or to the User.
              </span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                Termination or deletion of Delivery Partner Account by Delivery
                Partner shall comply with the procedure and fulfill the
                requirements set out in Delivery Partner Offboarding Policy.
                Delivery Partner shall have sole responsibility to familiarize
                themselves with Delivery Partner Offboarding Policy before
                requesting to terminate or delete its account.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                Boxtrack also may stop providing the Services at any time, or
                create limits on use of the Services, whether specifically to
                Delivery Partner or generally. Termination will not limit any of
                Boxtrack’s other rights or remedies. The provisions of this
                Terms of Use shall survive any termination of services. Nothing
                in this provision provides Boxtrack the right to terminate
                Delivery Partner’s ability to contact Users on its own and to
                provide Shipment Deliveries to Users, only the right to
                terminate Delivery Partner’s use of the Software.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                In case the Delivery Partner wishes to use the Service again
                after leaving or after having been off-boarded, he/she must
                again undergo the process of application as a Delivery Partner
                and pay the required security deposit &nbsp;defined upon
                re-joining. Boxtrack reserves the sole right to accept or deny
                any application as a Delivery Partner.&nbsp;
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>15. SEVERABILTY&nbsp;</span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                Any provision of this Terms of Use which is prohibited or
                unenforceable in any jurisdiction shall, as to such jurisdiction
                and the application to such facts and circumstances, be
                ineffective to the extent of such prohibition or
                unenforceability without invalidating the remaining provisions
                hereof or the application thereof to other facts and
                circumstances, and any such prohibition or unenforceability in
                any jurisdiction shall not invalidate or render unenforceable
                such provision in any other jurisdiction.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>16. NO WAIVER&nbsp;</span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                The failure of Boxtrack or Delivery Partner in any instance to
                insist upon a strict performance of the terms of this Terms of
                Use or to exercise any option herein, shall not be construed as
                a waiver or relinquishment of such term or option and such term
                or option shall continue in full force and effect.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>17. GOVERNING LAW&nbsp;</span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                Except as otherwise set forth in these Terms of Use, these Terms
                of Use shall be exclusively governed by and construed in
                accordance with the laws of the Philippines. Any dispute,
                controversy, or claim arising out of or relating to these Terms
                of Use, or the breach, termination, or invalidity thereof, shall
                be settled by arbitration.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>18. SUCCESSORS&nbsp;</span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                These Terms of Use, and any rights and licenses granted
                hereunder, may not be transferred or assigned by you, but may be
                assigned by Boxtrack without restriction. Any attempted transfer
                or assignment in violation hereof shall be null and void. These
                Terms of Use binds and inures to the benefit of each party and
                the party’s successors and permitted assigns.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>19. CAPTIONS&nbsp;</span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                Captions appearing in this Terms of Use are for convenience only
                and do not in any way limit, amplify, modify, or otherwise
                affect the terms and provisions of this Terms of Use.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>20. LANGUAGE VERSIONS</span>
            </p>
            <p>
              <span style={{ fontSize: '11pt' }}>
                In case of any discrepancy between the English version and any
                regional language version, the English version prevails.
              </span>
            </p>
          </div>
        </Typography>
        <br />
        <div className="action-container">
          <Button
            onClick={() => handleFormSubmit()}
            variant="contained"
            className="action">
            Agree
          </Button>
          <Button
            onClick={() => prevStep()}
            variant="contained"
            className="action">
            Back
          </Button>
        </div>
      </form>
    </Grid>
  );
};

export default Terms;
