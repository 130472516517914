import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SignupForm from './signupForm';
import Finish from './finish';
import { isDesktop } from 'react-device-detect';

const Signup = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [userData, setUserData] = useState({});

  const prevStep = () => {
    if (step === 1) return;
    setStep(step - 1);
  };
  const nextStep = () => {
    setStep(step + 1);
  };
  const handleFormSubmit = (type, newData) => {
    if (type === 'userData') setUserData({ ...userData, ...newData });
  };

  switch (step) {
    case 1:
      return (
        <div
          style={
            isDesktop
              ? {
                  width: '400px',
                  margin: 'auto',
                }
              : {}
          }>
          <SignupForm
            stateName={'userData'}
            userData={userData}
            nextStep={nextStep}
            prevStep={prevStep}
            handleFormSubmit={handleFormSubmit}></SignupForm>
        </div>
      );
    case 2:
      return (
        <div
          style={
            isDesktop
              ? {
                  width: '400px',
                  margin: 'auto',
                }
              : {}
          }>
          <Finish nextStep={nextStep} prevStep={prevStep} />
        </div>
      );
    default:
      navigate('/dropoff/login');
  }
};

export default Signup;
