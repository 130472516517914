import axios from 'axios';
import md5 from 'md5';
import { checkErrorResponse } from '../utils/helpers';

export const create = payload => {
  const headers = {
    'Content-Type': 'application/json',
  };

  payload.password = md5(payload.password);

  return axios
    .post(`${process.env.REACT_APP_API}/user-management/`, payload, { headers })
    .then(function (response) {
      return response.data;
    })
    .catch(error => {
      return checkErrorResponse(error.response);
    });
};

export const accountBalance = (token) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  return axios
    .get(`${process.env.REACT_APP_API}/account/wallet/balance`, { headers })
    .then(function (response) {
      return response.data;
    })
    .catch(error => {
      return checkErrorResponse(error.response);
    });
};

export const getAccountBalanceHistory = (token) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  return axios
    .get(`${process.env.REACT_APP_API}/account/wallet/transaction`, { headers })
    .then(function (response) {
      return response.data;
    })
    .catch(error => {
      return checkErrorResponse(error.response);
    });
};


export const getAccountInformation = (token) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  return axios
    .get(`${process.env.REACT_APP_API}/account`, { headers })
    .then(function (response) {
      return response.data;
    })
    .catch(error => {
      return checkErrorResponse(error.response);
    });
};

export const update = (token, payload, id) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  return axios
    .patch(`${process.env.REACT_APP_API}/user-management/${id}`, payload, {
      headers,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(error => {
      return checkErrorResponse(error.response);
    });
};

export const remove = (token, id) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  return axios
    .delete(`${process.env.REACT_APP_API}/user-management/${id}`, {
      headers,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(error => {
      return checkErrorResponse(error.response);
    });
};