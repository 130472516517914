import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import LoadingScreen from '../../../components/LoadingScreen';
import { UserContext } from '../../../context/UserProvider';
import { show, acceptSameDay, update } from '../../../services/order';
import { submitGps } from '../../../services/driver';
import { Button } from '@mui/material';
import { styles } from '../../../config/defaultPageStyles';
import CallIcon from '@mui/icons-material/Call';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { show as showFile } from '../../../services/file';
import { moneyFormat, toTitleCase } from '../../../utils/helpers';
import ProofOfDelivery from './proofOfDelivery';
import TimeAgo from 'javascript-time-ago';

const stylesContainer = {
  sectionContatinerAddress: {
    textAlign: 'left',
    backgroundColor: '#dedede',
    border: 'solid 1px #b7b7b7',
    padding: '5px 10px 15px 10px',
    borderRadius: '10px',
    marginBottom: '10px',
    fontSize: '15px',
    color: '#494949',
  },
  sectionContatinerDriver: {
    textAlign: 'left',
    backgroundColor: '#ffff',
    border: 'solid 1px #b7b7b7',
    padding: '5px 10px 15px 10px',
    borderRadius: '10px',
    marginBottom: '10px',
    textAlign: 'center',
  },
  sectionHeader: {
    marginBottom: '0px',
    marginTop: '0px',
  },
  sectionData: {
    marginBottom: '0px',
    marginTop: '0px',
  },
  sectionDataTo: {
    fontWeight: 'bold',
  },
  sectionButton: {
    marginTop: '10px',
  },
  packageImage: {
    width: '100%',
    borderRadius: '10px',
    marginTop: '10px',
  },
};

// 5 seconds
const SEND_GPS_INTERVAL = 5000;
let interval = null;

const App = () => {
  const timeAgo = new TimeAgo('en-US');
  let { id } = useParams();
  const { token, userType } = useContext(UserContext);
  const [jobOrder, setJobOrder] = useState(null);
  const [activeOrder, setActiveOrder] = useState(false);
  const [activeTask, setActiveTask] = useState(null);
  const [taskList, setTaskList] = useState([]);
  const [taskListGps, setTaskListGps] = useState([]);
  const [allowToClose, setAllowToClose] = useState(false);
  const [initClose, setInitClose] = useState(false);
  const navigate = useNavigate();
  const [lastGpsTimeSent, setLastGpsTimeSent] = useState(null);

  useEffect(() => {
    loadOrder();
  }, []);

  useEffect(() => {
    console.log('lastGpsTimeSent', lastGpsTimeSent);
  }, [lastGpsTimeSent]);

  useEffect(() => {
    if (activeTask == 'origin') {
      update(token, { status: 'driver on the way' }, id);
    } else if (activeTask) {
      update(token, { status: 'delivery in progress' }, id);
    }

    if (activeTask) {
      submitGpsLocation(taskListGps[activeTask]);
      if (interval) {
        clearInterval(interval);
      }

      interval = setInterval(() => {
        console.log('send gps', activeTask);
        submitGpsLocation(taskListGps[activeTask]);
      }, SEND_GPS_INTERVAL);
    }
  }, [activeTask]);

  useEffect(() => {
    window.addEventListener('beforeunload', event => {
      clearInterval(interval);
    });
  }, []);

  const submitGpsLocation = destinationGps => {
    if (!navigator.geolocation) {
      setLastGpsTimeSent(null);
      alert('Please enable gps on your device.');
      return;
    }

    if (jobOrder.status != 'completed') {
      let currentLocation = null;
      navigator.geolocation.getCurrentPosition(function (position) {
        currentLocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };

        setLastGpsTimeSent(new Date().getTime());

        submitGps(token, {
          order: id,
          currentLocation: currentLocation,
          destination: destinationGps,
        });
      });
    }
  };

  useEffect(() => {
    let tempAllowToClose = true;

    Object.keys(taskList).map((item, index) => {
      if (!taskList[item]) {
        tempAllowToClose = false;
      }
    });

    setAllowToClose(tempAllowToClose);
  }, [taskList]);

  const loadOrder = () => {
    acceptSameDay(token, {}, id).then(res => {
      if (res.status == 'success') {
        show(token, id).then(res => {
          setJobOrder(res.data);
          setActiveOrder(true);

          let tempTaskList = { origin: false, destination: false };
          let destinationGps = {
            origin: {
              lat: res.data?.data?.sender?.lat,
              lng: res.data?.data?.sender?.lng,
            },
            destination: {
              lat: res.data?.data?.receiver?.lat,
              lng: res.data?.data?.receiver?.lng,
            },
          };
          res.data?.data?.stops.map((item, index) => {
            tempTaskList[`stop-${index + 1}`] = false;
            destinationGps[`stop-${index + 1}`] = {
              lat: item.lat,
              lng: item.lng,
            };
          });

          setTaskList(tempTaskList);
          setTaskListGps(destinationGps);
        });
      } else if (res.status == 'open') {
        show(token, id).then(res => {
          setJobOrder(res.data);
          setActiveOrder(false);
        });
      } else {
        alert('Order was already accepted by another driver.');
        navigate(`/driver/orders`);
      }
    });
  };

  const acceptOrder = () => {
    setJobOrder(null);
    acceptSameDay(token, { accept: true }, id)
      .then(res => {
        if (res.status == 'success') {
          setActiveOrder(true);
        } else if (res.message) {
          alert(res.message);
          return navigate(`/driver/orders`);
        } else {
          alert('Order was already accepted by another driver.');
          return navigate(`/driver/orders`);
        }

        return window.location.reload();
      })
      .catch(err => {
        alert('Order was already accepted by another driver.');
        return navigate(`/driver/orders`);
      });
  };

  const completeTaskHadler = id => {
    let tempTaskList = { ...taskList };
    tempTaskList[id] = true;
    setTaskList(tempTaskList);
  };

  if (!jobOrder) {
    return <LoadingScreen />;
  }

  if (initClose) {
    return (
      <ProofOfDelivery
        id={id}
        backHandler={() => setInitClose(false)}
        data={jobOrder}
      />
    );
  }

  return (
    <>
      {lastGpsTimeSent && (
        <>
          <div
            style={{
              textAlign: 'center',
              color: 'white',
              fontWeight: 'bold',
              fontSize: '12px',
              marginBottom: '10px',
              marginTop: '10px',
              backgroundColor: 'green',
            }}>
            Location updated last {new Date(lastGpsTimeSent).toLocaleTimeString()}
          </div>
        </>
      )}
      <div style={styles.pageContainer}>
        <div style={styles.pageHeader}>
          Same Day Order Information
          <br />
          <small style={{ fontSize: '12px' }}>SAME DAY DELIVERY</small>
          <br />
          <small style={{ fontSize: '12px' }}>
            ORDER # {jobOrder?.['reference-number']}
          </small>
        </div>
        <PackageContainer data={jobOrder?.data?.package || {}} />
        <PricingContainer
          data={jobOrder?.data?.pricing}
          earning={jobOrder?.data?.earning}
          type={jobOrder?.['payment-type']}
        />
        <AddressContainer
          jobOrderId={id}
          id={'origin'}
          title={'Pickup'}
          data={jobOrder?.data?.sender || {}}
          activeOrder={activeOrder}
          activeTask={activeTask}
          setActiveTask={setActiveTask}
          completeTaskHadler={completeTaskHadler}
          allowStartTask={
            jobOrder.status != 'completed'
              ? taskList['origin']
                ? false
                : true
              : false
          }
        />
        {jobOrder?.data?.stops.map((item, index) => {
          return (
            <AddressContainer
              jobOrderId={id}
              id={`stop-${index + 1}`}
              key={`stop-${index + 1}`}
              title={`Stop ${index + 1}`}
              data={item}
              activeOrder={activeOrder}
              activeTask={activeTask}
              setActiveTask={setActiveTask}
              completeTaskHadler={completeTaskHadler}
              allowStartTask={
                jobOrder.status != 'completed'
                  ? taskList[`stop-${index + 1}`]
                    ? false
                    : taskList.origin
                  : false
              }
            />
          );
        })}
        <div style={{ paddingBottom: '30px' }}>
          <AddressContainer
            jobOrderId={id}
            id={'destination'}
            title={'Receiver'}
            data={jobOrder?.data?.receiver || {}}
            activeOrder={activeOrder}
            activeTask={activeTask}
            setActiveTask={setActiveTask}
            completeTaskHadler={completeTaskHadler}
            allowStartTask={
              jobOrder.status != 'completed'
                ? taskList[`destination`]
                  ? false
                  : taskList.origin
                : false
            }
          />

          {activeOrder ? (
            <>
              {jobOrder.status == 'completed' ? (
                <>
                  <ProofOfDeliveryContainer
                    data={jobOrder?.data?.['proof-of-delivery'] || {}}
                  />
                </>
              ) : (
                <>
                  <Button
                    onClick={() => setInitClose(true)}
                    variant="outlined"
                    color="error"
                    size={'small'}
                    fullWidth
                    style={stylesContainer.sectionButton}
                    disabled={!allowToClose}>
                    CLOSE ORDER
                  </Button>
                </>
              )}
            </>
          ) : (
            <Button
              onClick={() => acceptOrder()}
              variant="contained"
              color="primary"
              size={'small'}
              fullWidth
              style={stylesContainer.sectionButton}>
              GET ORDER
            </Button>
          )}
          <Button
            onClick={() => {
              navigate(-1);
            }}
            variant="contained"
            color="primary"
            size={'small'}
            fullWidth
            style={stylesContainer.sectionButton}>
            Back To Orders
          </Button>
        </div>
      </div>
    </>
  );
};

export default App;

const AddressContainer = ({
  id,
  title,
  data,
  activeOrder,
  activeTask,
  setActiveTask,
  completeTaskHadler,
  allowStartTask,
  jobOrderId
}) => {
  const [show, setShow] = useState(false);
  const [taskCompleted, setTaskCompleted] = useState(
    data?.taskCompleted ?? false,
  );
  const [startTask, setStartTask] = useState(false);
  const [currentLocation, setCurrentLocataion] = useState(null);
  const navigate = useNavigate();
  const label = show ? 'Hide' : 'View';

  const onClick = () => {
    setShow(!show);
  };

  useEffect(() => {
    if (activeTask == id) {
      setStartTask(true);
    } else {
      setStartTask(false);
    }
  }, [id, activeTask]);

  useEffect(() => {
    if (startTask) {
      setActiveTask(id);
    }
  }, [startTask]);

  const handleTaskCompleted = () => {
    setTaskCompleted(true);
    setActiveTask(null);
    completeTaskHadler(id);
  };

  function navigateGoogleMap(endLat, endLon) {
    let confirmation = window.confirm('Please note: Using a third-party map app may affect GPS tracking accuracy. For best results, open the map in split-screen mode. Do you wish to proceed?');
    if (confirmation) {
      if (currentLocation) {
        const url = `https://www.google.com/maps/dir/?api=1&origin=${currentLocation.startLat},${currentLocation.startLon}&destination=${endLat},${endLon}`;
        window.open(url, '_blank');
      } else {
        alert('Please start task and enable gps on your device.');
      }
    }
  }

  function navigateMap(endLat, endLon) {
    if (currentLocation) {
      navigate(`/driver/map/sameday/${jobOrderId}/${endLat}/${endLon}`);
    } else {
      alert('Please start task and enable gps on your device.');
    }
  }

  const loadCurrentLocation = () => {
    if (navigator.geolocation) {
      console.info('Getting current location...');
      navigator.geolocation.getCurrentPosition(
        position => {
          const startLat = position.coords.latitude;
          const startLon = position.coords.longitude;

          setCurrentLocataion({
            startLat: startLat,
            startLon: startLon,
          });
          console.info('Location successfuly set');
        },
        () => {
          alert('Please enable gps on your device.');
        },
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
      alert('Please enable gps on your device.');
    }
  };

  useEffect(() => {
    loadCurrentLocation();
  }, []);

  return (
    <>
      <div style={stylesContainer.sectionContatinerAddress}>
        <div style={stylesContainer.sectionHeader}>
          <strong>{title}</strong>
        </div>
        {!show ? (
          <>
            <p style={stylesContainer.sectionData}>
              {data?.addressLine1} {data?.addressLine2 || ''} {data.city}{' '}
              {data.province} {data.zipCode}
            </p>
          </>
        ) : (
          <>
            <table>
              <tbody>
                <tr>
                  <td style={stylesContainer.sectionDataTo}>Name: </td>
                  <td>{data?.name || ''}</td>
                </tr>
                <tr>
                  <td style={stylesContainer.sectionDataTo}>Phone: </td>
                  <td>{data?.contactNumber || ''}</td>
                </tr>
                <tr>
                  <td style={stylesContainer.sectionDataTo}>Address: </td>
                  <td></td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    {data?.addressLine1 || ''} {data?.addressLine1 || ''}{' '}
                    {data?.city || ''} {data?.province || ''}{' '}
                    {data?.zipCode || ''}
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        )}
        {show && activeOrder && (
          <>
            <Button
              startIcon={<CallIcon />}
              href="tel:09999999999"
              variant="contained"
              color="primary"
              size={'small'}
              fullWidth
              style={stylesContainer.sectionButton}>
              CALL
            </Button>
            <Button
              onClick={() => navigateMap(data.lat, data.lng)}
              variant="contained"
              color="primary"
              size={'small'}
              fullWidth
              style={stylesContainer.sectionButton}>
              NAVIGATE VIA MAP
            </Button>
            <Button
              onClick={() => navigateGoogleMap(data.lat, data.lng)}
              variant="contained"
              color="primary"
              size={'small'}
              fullWidth
              style={stylesContainer.sectionButton}>
              NAVIGATE VIA GOOGLE MAP
            </Button>
            <Button
              onClick={() => {
                let confirmation = window.confirm('Please note: Using a third-party map app may affect GPS tracking accuracy. For best results, open the map in split-screen mode. Do you wish to proceed?');
                if (confirmation) {
                  const lat = data.lat;
                  const lon = data.lng;
                  const url = `https://waze.com/ul?ll=${lat}%2C${lon}&navigate=yes`;

                  window.open(url, '_blank'); 
                }
              }}
              variant="contained"
              color="primary"
              size={'small'}
              fullWidth
              style={stylesContainer.sectionButton}>
              NAVIGATE VIA WAZE
            </Button>
          </>
        )}

        {activeOrder && allowStartTask && !taskCompleted && !startTask && (
          <Button
            onClick={() => setStartTask(true)}
            variant="outlined"
            color="error"
            size={'small'}
            fullWidth
            style={stylesContainer.sectionButton}
            disabled={activeTask && activeTask != id}>
            Start Task
          </Button>
        )}

        {!taskCompleted && startTask && (
          <Button
            onClick={() => handleTaskCompleted()}
            variant="contained"
            color="error"
            size={'small'}
            fullWidth
            style={stylesContainer.sectionButton}>
            MARK AS COMPLETE TASK
          </Button>
        )}

        <Button
          onClick={onClick}
          variant="outlined"
          color="primary"
          size={'small'}
          fullWidth
          style={stylesContainer.sectionButton}>
          {label}
        </Button>
      </div>
    </>
  );
};

const PackageContainer = ({ data }) => {
  const { token } = useContext(UserContext);
  const [url, setUrl] = useState(null);

  useEffect(() => {
    loadFile();
  }, []);

  const loadFile = () => {
    showFile(token, data?.packagePhoto).then(res => {
      setUrl(res?.data?.file || null);
    });
  };

  return (
    <div style={stylesContainer.sectionContatinerAddress}>
      <p style={stylesContainer.sectionHeader}>
        <strong>Parcel Information</strong>
      </p>
      {url && (
        <p style={stylesContainer.sectionData}>
          <img src={url} style={stylesContainer.packageImage} />
        </p>
      )}
      <p style={stylesContainer.sectionData}>
        <strong>Parcel Description: </strong>
        <br />
        {data?.packageDescription || ''}
      </p>
    </div>
  );
};

const PricingContainer = ({ data, type, earning }) => {
  const [showDetails, setShowDetails] = useState(false);
  const query = new URLSearchParams(useLocation().search);

  const RenderCod = () => {
    if (!data.totalCodAmount || data.totalCodAmount == 0) {
      return null;
    }

    return (
      <>
        <div>
          COD Amount:{' '}
          <span style={{ float: 'right' }}>
            PHP {moneyFormat(data?.totalCodAmount || 0)}
          </span>
        </div>
      </>
    );
  };

  const RenderEarnings = () => {
    const wht = earning.wht || 0;
    const tax = earning.tax || 0;
    const net = earning.net || 0;
    const adminFee = earning.adminFee || 0;
    const driverEarning = earning.driverEarning || 0;
    const totalDriverEarning = earning.totalDriverEarning || 0;
    const amountToCollect = earning.amountToDeduct || 0;

    return (
      <>
        <div
          style={{
            borderTop: 'solid 1px #b7b7b7',
            paddingTop: '10px',
            marginTop: '10px',
          }}>
          <strong>Breakdown Computation</strong>
        </div>
        <div style={{ color: 'red' }}>
          VAT (12%):{' '}
          <span style={{ float: 'right' }}>- PHP {moneyFormat(tax || 0)}</span>
        </div>
        <div>
          NET:{' '}
          <span style={{ float: 'right' }}>PHP {moneyFormat(net || 0)}</span>
        </div>
        <div style={{ color: 'red' }}>
          Admin Fee (20%):{' '}
          <span style={{ float: 'right' }}>
            - PHP {moneyFormat(adminFee || 0)}
          </span>
        </div>
        <div>
          Your Earning (80%):{' '}
          <span style={{ float: 'right' }}>
            PHP {moneyFormat(driverEarning || 0)}
          </span>
        </div>
        <div style={{ color: 'red' }}>
          Withholding Tax (2%):{' '}
          <span style={{ float: 'right' }}>- PHP {moneyFormat(wht || 0)}</span>
        </div>

        {(data.totalCodAmount || data.totalCodAmount > 0) && (
          <>
            <div style={{ color: 'red' }}>
              COD Amount:{' '}
              <span style={{ float: 'right' }}>
                - PHP {moneyFormat(data?.totalCodAmount || 0)}
              </span>
            </div>
          </>
        )}

        <div style={{ fontWeight: 'bold', color: 'green', marginTop: '20px' }}>
          Your NET Earning:{' '}
          <span style={{ float: 'right' }}>
            PHP {moneyFormat(totalDriverEarning || 0)}
          </span>
        </div>
        <div style={{ fontWeight: 'bold', color: 'red' }}>
          Total Deduction:{' '}
          <span style={{ float: 'right' }}>
            PHP {moneyFormat(amountToCollect || 0)}
          </span>
        </div>
      </>
    );
  };

  return (
    <div
      style={stylesContainer.sectionContatinerAddress}
      onClick={() => setShowDetails(!showDetails)}>
      <div>
        Payment Type:{' '}
        <span style={{ float: 'right' }}>{toTitleCase(type)}</span>
      </div>
      <div>
        Sub Total:{' '}
        <span style={{ float: 'right' }}>
          PHP {moneyFormat(data?.subTotal || 0)}{' '}
        </span>
      </div>
      <RenderCod />
      <div style={{ fontSize: '25px' }}>
        Total:{' '}
        <span style={{ float: 'right' }}>
          PHP {moneyFormat(data?.total || 0)}
        </span>
      </div>
      {showDetails && query.get('debug') && <RenderEarnings />}
    </div>
  );
};

const ProofOfDeliveryContainer = ({ data }) => {
  console.log('data', data);
  const { token } = useContext(UserContext);
  const [url, setUrl] = useState(null);

  useEffect(() => {
    loadFile();
  }, []);

  const loadFile = () => {
    showFile(token, data?.packagePhoto).then(res => {
      setUrl(res?.data?.file || null);
    });
  };

  return (
    <div style={stylesContainer.sectionContatinerAddress}>
      <p style={stylesContainer.sectionHeader}>
        <strong>Proof Of Delivery</strong>
      </p>
      {url && (
        <p style={stylesContainer.sectionData}>
          <img src={url} style={stylesContainer.packageImage} />
        </p>
      )}
      <p style={stylesContainer.sectionData}>
        <strong>Delivery Note: </strong>
        <br />
        {data?.packageDescription || ''}
      </p>
    </div>
  );
};
