import React, { useState, useRef, useContext, useEffect } from 'react';
import { Button, TextField } from '@mui/material';
import ImageUploader from '../../../components/ImageUploader';
import { updateRegular } from '../../../services/order';
import { UserContext } from '../../../context/UserProvider';
import { useNavigate, useParams } from 'react-router-dom';
import QRCodeScanner from '../../../components/QrCodeScanner';

const App = ({ id, backHandler, data }) => {
  const navigate = useNavigate();
  const { token } = useContext(UserContext);
  const photoList = useRef([]);
  const description = useRef(null);
  const [valid, setValid] = useState(false);
  const [code, setCode] = useState(null);

  const submit = () => {
    if (photoList.current.length < 1) {
      return alert('Please upload photo');
    } else if (!description.current.value || description.current.value === '') {
      return alert('Please enter deelivery note');
    }

    updateRegular(
      token,
      {
        data: {
          ...data.data,
          'proof-of-pickup': {
            packagePhoto: photoList.current[0].id,
            packageDescription: description.current.value,
            dateTimeCompleted: new Date().toISOString(),
          },
        },
        status: 'picked up',
      },
      id,
    ).then(res => {
      console.log(res);
      if (res && res.message === 'Record Updated') {
        alert('Order Pickup Successful');
        window.location.reload();
      } else {
        alert('Something went wrong');
      }
    });
  };

  const handleScan = data => {
    if (data) {
      const qrCodeData = data.split(':');
      if (qrCodeData[0]) {
        setCode(qrCodeData[0]);
      } else {
        setCode(data);
      }
    }
  };

  useEffect(() => {
    if (code && code === data?.data?.verification) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [code]);

  return (
    <>
      <div
        style={{
          textAlign: 'center',
          fontSize: '1.2rem',
          paddingTop: '15px',
          paddingBottom: '15px',
        }}>
        PROOF OF PICKUP
      </div>
      <div
        style={{
          textAlign: 'center',
          fontSize: '1.2rem',
          paddingTop: '15px',
          paddingBottom: '15px',
        }}>
        {valid ? (
          <>
            <ImageUploader
              name={'packagePhoto'}
              type={'Package Photo'}
              title={'Please Upload Photo of the package'}
              label={''}
              ref={photoList}
              defaultImage={
                photoList.current[0] ? photoList.current[0].url : null
              }
            />
            <TextField
              label={'Pickup Note'}
              variant="outlined"
              type={'text'}
              multiline
              rows={4}
              fullWidth
              inputRef={description}
            />
          </>
        ) : (
          <>
            <strong>Scan QR Code from the customer phone</strong>
            <QRCodeScanner onChange={handleScan} />
            <label>Confirmation Code</label>
            <TextField
              label={''}
              variant="outlined"
              type={'text'}
              fullWidth
              onChange={e => setCode(e.target.value)}
              value={code ?? ''}
            />
            {(!valid && code) && (
              <div style={{ color: 'red' }}>Invalid confirmation code</div>
            )}
          </>
        )}
      </div>
      <div style={{ width: '100%', paddingBottom: '15px' }}>
        <Button
          variant="contained"
          color="error"
          onClick={() => submit()}
          style={{ marginBottom: '10px' }}
          disabled={!valid}>
          SUBMIT
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => backHandler()}>
          Back
        </Button>
      </div>
    </>
  );
};

export default App;
