import React, { useContext, useState } from 'react';
import Navbar from '../../../components/DesktopNavbar';
import { mainMenu } from '../../../config/dropoffMenu.jsx';
import { UserContext } from '../../../context/UserProvider.jsx';

const App = () => {
  const { userAttr } = useContext(UserContext);
  const [type, setType] = useState(null);

  React.useEffect(() => {
    if (userAttr) {
      setType(JSON.parse(userAttr)?.type ?? 'sub');
    }
  }, [userAttr]);

  return (
    <>
      <Navbar pages={mainMenu} />
      <div
        style={{
          margin: '10px',
        }}>
          {type == 'sub' ? <> <h1>Sub Dropoff</h1></> : <> <h1>Main Dropoff</h1></>}
       
      </div>
    </>
  );
};

export default App;
