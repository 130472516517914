import React from 'react';
import { Button } from '@mui/material';
import { styles } from '../../../config/defaultPageStyles.jsx';
import { useNavigate, Link } from 'react-router-dom';

const App = () => {
  const navigate = useNavigate();

  return (
    <div style={styles.pageContainer}>
      <div style={styles.pageBody}>
        <div style={styles.pageHeader}>Select Delivery Type</div>
        <Link
          to="/customer/delivery/regular/door-2-door"
          style={{ width: '100%' }}>
          <div style={styles.cardSelection}>
            <img
              src="/img/door-2-door.png"
              style={{ width: '90px' }}
              alt="Regular Delivery"
            />
            <div style={{ paddingTop: '15px' }}>
              Door to Door Delivery
              <small style={{ display: 'block', fontSize: '10px' }}>
                We will pick up your package and deliver it to the recipient at
                the address you provide.
              </small>
            </div>
          </div>
        </Link>
        <Link
          to="/customer/delivery/regular/door-2-dropoff"
          style={{ width: '100%' }}>
        <div style={styles.cardSelection}>
          <img
            src="/img/door-2-dropo-ff.png"
            style={{ width: '90px' }}
            alt="Regular Delivery"
          />
          <div style={{ paddingTop: '15px' }}>
            Door to Drop Off Location
            <small style={{ display: 'block', fontSize: '10px' }}>
              We will pick up your package and deliver it to the drop off
              location you choose.
            </small>
          </div>
        </div>
        </Link>

        <Link
          to="/customer/delivery/regular/dropoff-2-dropoff"
          style={{ width: '100%' }}>
          <div style={styles.cardSelection}>
            <img
              src="/img/drop-off-2-drop-off.png"
              style={{ width: '90px' }}
              alt="Regular Delivery"
            />
            <div style={{ paddingTop: '12px' }}>
              Drop Off to Drop Off Location
              <small style={{ display: 'block', fontSize: '10px' }}>
                You will drop off your package at the drop off location you
                choose and we will deliver it to the drop off location you
                choose.
              </small>
            </div>
          </div>
        </Link>

        <Link
          to="/customer/delivery/regular/dropoff-2-door"
          style={{ width: '100%' }}>
          <div style={styles.cardSelection}>
            <img
              src="/img/drop-off-2-door.png"
              style={{ width: '90px' }}
              alt="Regular Delivery"
            />
            <div style={{ paddingTop: '12px' }}>
              Drop Off Location to Door
              <small style={{ display: 'block', fontSize: '10px' }}>
                You will drop off your package at the drop off location you
                choose and we will deliver it to the recipient at the address
                you provide.
              </small>
            </div>
          </div>
        </Link>
      </div>
      <div style={{ width: '100%', paddingBottom: '15px' }}>
        <Button
          variant="contained"
          color="primary"
          style={{ width: '100%', marginTop: '15px' }}
          onClick={() => {
            navigate('/customer/dashboard');
          }}>
          Back
        </Button>
      </div>
    </div>
  );
};

export default App;
