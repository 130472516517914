import React, { useState, useEffect } from 'react';
import { Button, Grid } from '@mui/material';
import { getVehicleType } from '../../../services/vehicle';
import LoadingScreen from '../../../components/LoadingScreen';


const App = ({ label, data, backHandler, nextHandler }) => {
  const [vehicleType, setVehicleType] = useState(null);
  const loadVehicleType = () => {
    getVehicleType().then(res => {
      const result = Object.keys(res).map(key => ({
        ...res[key],
        id: key,
      }));

      setVehicleType(result);
    });
  };

  useEffect(() => {
    loadVehicleType();
  }, []);

  if (!vehicleType) {
    return <LoadingScreen />;
  }

  return (
    <>
      <div
        style={{
          textAlign: 'center',
          fontSize: '1.2rem',
          paddingTop: '15px',
          paddingBottom: '15px',
        }}>
        {label}
      </div>
      {vehicleType.map((item, index) => (
        <div
          key={index}
          style={{
            textAlign: 'left',
            backgroundColor: '#e6e6e6',
            padding: '10px',
            borderRadius: '5px',
            marginBottom: '10px',
            display: 'flex',
          }}
          onClick={() => {
            nextHandler(item);
          }}>
          <div style={{width: '30%'}}>
            <img src={`/img/${item.id}.png`} style={{width: '100%'}} />
          </div>
          <div style={{width: '70%', marginLeft: '10px'}}>
            <div>
              <strong>{item.label}</strong>
            </div>
            <div>{item.description}</div>
            <div>
              <small>
                <strong>Dimention: {item.dimension}</strong>
              </small>
            </div>
            <div>
              <small>
                <strong>Weight: {item.weightLimit} KG</strong>
              </small>
            </div>
          </div>
        </div>
      ))}
      <div style={{ width: '100%', marginBottom: '10px' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => backHandler()}>
          Back
        </Button>
      </div>
    </>
  );
};

export default App;
