import React, { useContext, useState, useEffect } from 'react';
import Navbar from '../../../components/DesktopNavbar/admin.jsx';
import ImageLoader from '../../../components/ImageLoader';
import { mainMenu } from '../../../config/adminMenu.jsx';
import { UserContext } from '../../../context/UserProvider';
import { get } from '../../../services/driver';
import { moneyFormat, toTitleCase } from '../../../utils/helpers';
import { isMobile } from 'react-device-detect';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { Button } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { update, remove } from '../../../services/user';

const tableStyle = {
  borderCollapse: 'collapse',
  width: '100%',
};

const thStyle = {
  border: '1px solid black',
  textAlign: 'left',
  padding: '8px',
  backgroundColor: '#f2f2f2',
};

const tdStyle = {
  border: '1px solid black',
  textAlign: 'left',
  padding: '8px',
};

const App = () => {
  const navigate = useNavigate();
  const { token, userAttr } = useContext(UserContext);
  const [data, setData] = useState(null);
  let { id } = useParams();

  const loadData = payload => {
    get(token, id).then(res => {
      setData(res);
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  const RowEntry = ({ label, value}) => {
    const [edit, setEdit] = useState(false);

    return (
      <tr>
        <td style={thStyle}>{label}</td>
        <td style={tdStyle}>
          {value}
        </td>
      </tr>
    );
  };

  const activateUser = () => {
    let confirm = window.confirm(
      'Are you sure you want to activate this user?',
    );

    if (!confirm) {
      return;
    }

    update(token, { status: 'active' }, id).then(res => {
      loadData();
    });
  };

  const handelDelete = () => {
    let confirm = window.confirm('Are you sure you want to delete this user?');

    if (!confirm) {
      return;
    }

    remove(token, id).then(res => {
      navigate('/admin/drivers');
    });
  };

  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Navbar pages={mainMenu} />
      <Container maxWidth={'xl'}>
        <Grid container spacing={2} style={{ height: 'auto' }}>
          <Grid item xs={12} sm={6} style={{ height: '100px' }}>
            <h1>Driver Information</h1>
          </Grid>
          <Grid item xs={12} sm={6} style={{ height: '100px' }}>
            {data.user['status'] === 'pending' && (
              <>
                <Button
                  variant="contained"
                  onClick={() => activateUser()}
                  style={
                    isMobile
                      ? {}
                      : {
                          width: '160px',
                          float: 'right',
                          marginTop: '20px',
                          marginLeft: '20px',
                        }
                  }>
                  Activate
                </Button>
              </>
            )}

            {data.user['status'] === 'active' && (
              <>
                <Button
                  variant="contained"
                  onClick={() => navigate('/admin/wallet/driver/' + id)}
                  style={
                    isMobile
                      ? {}
                      : {
                          width: '160px',
                          float: 'right',
                          marginTop: '20px',
                          marginLeft: '20px',
                        }
                  }>
                  Wallet
                </Button>
              </>
            )}
            <Button
              variant="contained"
              onClick={() => handelDelete()}
              style={
                isMobile
                  ? {}
                  : {
                      width: '160px',
                      float: 'right',
                      marginLeft: '20px',
                      marginTop: '20px',
                    }
              }>
              Delete
            </Button>
            <Button
              variant="contained"
              onClick={() => navigate('/admin/drivers')}
              style={
                isMobile
                  ? {}
                  : {
                      width: '160px',
                      float: 'right',
                      marginTop: '20px',
                    }
              }>
              Back
            </Button>
          </Grid>
          <Grid item xs={12} sm={12}>
            <table style={tableStyle}>
              <tbody>
                <tr>
                  <td
                    style={{
                      ...thStyle,
                      fontWeight: 'bold',
                      fontSize: '20px',
                      textAlign: 'center',
                      backgroundColor: '#dedff4',
                    }}
                    colSpan={2}>
                    Account Information
                  </td>
                </tr>
                <RowEntry
                  label="Account Status"
                  value={toTitleCase(data.user['status'])}
                />
                <RowEntry label="Mobile Number" value={data.user['login']} />
                <RowEntry
                  label="Name"
                  value={`${data.driver['first-name']} ${data.driver['middle-name']} ${data.driver['last-name']}`}
                />
                <RowEntry
                  label="Birth Date"
                  value={data.driver['birth-date']}
                />
                <RowEntry
                  label="Address Line 1"
                  value={data.driver['address-line-1']}
                />
                <RowEntry
                  label="Address Line 2"
                  value={data.driver['address-line-2']}
                />
                <RowEntry label="City" value={data.driver['address-city']} />
                <RowEntry
                  label="Province"
                  value={data.driver['address-province']}
                />
                <RowEntry
                  label="Zip Code"
                  value={data.driver['address-zip-code']}
                />
                <RowEntry
                  label="Email Address"
                  value={data.driver['email-address']}
                />
                <RowEntry
                  label="Driver ID"
                  value={
                    <ImageLoader
                      id={data.driver['valid-identification-card-file-id']}
                      width='40%'
                    />
                  }
                />
                <RowEntry
                  label="Drivers License"
                  value={
                    <ImageLoader id={data.driver['drivers-license-file-id']} width='40%' />
                  }
                />
                <tr>
                  <td
                    style={{
                      ...thStyle,
                      fontWeight: 'bold',
                      fontSize: '20px',
                      textAlign: 'center',
                      backgroundColor: '#dedff4',
                    }}
                    colSpan={2}>
                    Vehicle Information
                  </td>
                </tr>
                <RowEntry label="Type" value={data.vehicle.type} />
                <RowEntry label="Brand" value={data.vehicle.brand} />
                <RowEntry label="Model" value={data.vehicle.model} />
                <RowEntry label="Color" value={data.vehicle.color} />
                <RowEntry
                  label="Plate Number"
                  value={data.vehicle['plate-number']}
                />
                <RowEntry
                  label="Validity Date"
                  value={data.vehicle['validity-date']}
                />
                <RowEntry
                  label="Owner"
                  value={data.vehicle.owner ? 'Yes' : 'No'}
                />
                {!data.vehicle.owner &&
                  data.vehicle['authorization-file-id'] && (
                    <RowEntry
                      label="Authorization Letter"
                      value={
                        <ImageLoader
                          id={data.vehicle['authorization-file-id']}
                          width='40%' 
                        />
                      }
                    />
                  )}
                <RowEntry
                  label="Official Receipt File ID"
                  value={
                    <ImageLoader
                      id={data.vehicle['official-receipt-file-id']}
                      width='40%' 
                    />
                  }
                />
                <RowEntry
                  label="Certification of Registration File ID"
                  value={
                    <ImageLoader
                      id={data.vehicle['certification-of-registration-file-id']}
                      width='40%' 
                    />
                  }
                />
              </tbody>
            </table>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default App;
