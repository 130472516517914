export const status = {
    'active': 'Looking for a driver',
    'waiting for payment': 'Waiting for payment',
    'driver matched': 'Driver matched',
    'driver on the way': 'Driver on the way',
    'picked up': 'Item picked up',
    'delivery in progress': 'Delivery in progress',
    'completed': 'Completed',
    'canceled': 'Canceled',

    'dropoff origin': 'Origin Hub',
    'dropoff origin main': 'Origin - Main Hub',

    'transfer inprogress': 'Transfer In Progress',
    
    'dropoff destination main': 'Destination - Main Hub',
    'dropoff destination': 'Destination Hub',
    
    'for delivery': 'Ready For Delivery',
    'for pickup': 'Ready For Customer Pickup',    
};

export const renderStatusLabel = (status, orderType) => {
    let resultLabel = status[status] || status.toUpperCase();

    if (orderType == 'dropoff2dropoff' || orderType == 'dropoff2door') {
        switch (status) {
            case 'active':
                resultLabel = 'Waiting for the parcel to drop off';
                break;
        
            default:
                break;
        }
    }

    return resultLabel.toUpperCase();
}

export const regularLabels = {
    door2door: 'Door 2 Door',
    dropoff2dropoff: 'Dropoff 2 Dropoff',
    dropoff2door: 'Dropoff 2 Door',
    door2dropoff: 'Door 2 Dropoff',
  };
  