import { useNavigate, useOutlet } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { UserContext } from '../../../context/UserProvider';
import { StyleContext } from '../../../context/StyleProvider';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';

const CustomerProtectedLayout = () => {
  const { token, userType } = useContext(UserContext);
  const navigate = useNavigate();
  const { setMaxWidth } = useContext(StyleContext);
  const outlet = useOutlet();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (!isMobile) {
      setMaxWidth(false);
    } else {
      setMaxWidth('sm');
    }
  }, [isMobile]);

  useEffect(() => {
    setTimeout(() => {
      if (!token) {
        navigate('/customer');
      }
    }, 1000);
  }, [token]);

  return <>{outlet}</>;
};

export default CustomerProtectedLayout;
