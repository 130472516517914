import React, { useState, useContext, useEffect } from 'react';
import { getAccountBalanceHistory } from '../../../services/user';
import { moneyFormat } from '../../../utils/helpers';
import { UserContext } from '../../../context/UserProvider';
import { styles } from '../../../config/defaultPageStyles';
import {
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  FormControl,
  TextField,
} from '@mui/material';
import CurrencyFormat from 'react-currency-format';
import { Link, useNavigate } from 'react-router-dom';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';

const App = () => {
  const { token } = useContext(UserContext);
  const [accountBalanceHistory, setAccountBalanceHistory] = useState([]);
  const timeAgo = new TimeAgo('en-US');
  const navigate = useNavigate();

  const loadAccountBalanceHistory = () => {
    getAccountBalanceHistory(token).then(res => {
      console.log('res', res);
      setAccountBalanceHistory(res.collection);
    });
  };

  const RenderInformation = ({ data }) => {
    const tempDom = [];
    for (const [key, value] of Object.entries(data)) {
      if (key == 'reference') {
        tempDom.push(
          <div key={key}>
            <strong>Reference #:</strong>{' '}
            <span style={{ float: 'right' }}>  {value}</span>
          </div>,
        );
      }
    }

    if (tempDom.length == 0) {
      return null;
    }

    return (
      <div
        style={{
          borderTop: 'solid 1px #b3b3b3',
          marginTop: '10px',
          paddingTop: '10px',
        }}>
        <strong>Information</strong>
        {tempDom}
      </div>
    );
  };

  useEffect(() => {
    loadAccountBalanceHistory();
  }, []);

  return (
    <div style={{
        ...styles.pageContainer,
        minHeight: '100vh',
        height: 'auto',
    }}>
      <div style={styles.pageHeader}>Transaction History</div>
      <div style={{ marginTop: '20px' }}>
        {accountBalanceHistory.map((item, index) => (
          <div
            key={index}
            style={{
              width: '100%',
              background: '#e1dede',
              padding: '10px',
              borderRadius: '10px',
              marginBottom: '20px',
            }}>
            <div style={{ width: '100%' }}>
              <strong>
                {item.data.data.description ?? item.data.processor}
              </strong>
              <br />
              <small>{timeAgo.format(new Date(item.raw.created_last))}</small>
            </div>
            <div
              style={{
                display: 'flex',
              }}>
              <div style={{ width: '50%' }}>
                {item.data.credit > 0 && (
                  <div>
                    <strong>Transaction:</strong>
                    <br />{' '}
                    <span style={{ color: 'green' }}>
                      + PHP {moneyFormat(item.data.credit)}
                    </span>
                  </div>
                )}
                {item.data.debit > 0 && (
                  <div>
                    <strong>Transaction:</strong>
                    <br />
                    <span style={{ color: 'red' }}>
                      - PHP {moneyFormat(item.data.debit)}
                    </span>
                  </div>
                )}
              </div>
              <div style={{ width: '50%' }}>
                <div>
                  <strong>Balance:</strong>
                  <br /> PHP {moneyFormat(item.data.balance)}
                </div>
              </div>
            </div>
            <RenderInformation data={item.data?.data} />
                
            {item?.data?.data?.remarks && item?.data?.data?.remarks?.length > 0 &&
                <div style={{ width: '100%' }}>
                  <div>
                    <strong>Remarks:</strong>
                    <br /> {item.data.data.remarks}
                  </div>
                </div>
              }
          </div>
        ))}
      </div>
      <Button
        onClick={() => navigate(-1)}
        variant="contained"
        fullWidth
        style={{ marginTop: '20px', float: 'left', marginBottom: '30px' }}>
        BACK
      </Button>
    </div>
  );
};

export default App;
