import React, { useState } from 'react';

import SignupForm from './signupForm';
import Finish from './finish';

const CustomerSignup = ({ navigate }) => {
  const [step, setStep] = useState(1);
  const [userData, setUserData] = useState({});

  const prevStep = () => {
    if (step === 1) return;
    setStep(step - 1);
  };
  const nextStep = () => {
    setStep(step + 1);
  };
  const handleFormSubmit = (type, newData) => {
    if (type === 'userData') setUserData({ ...userData, ...newData });
  };

  switch (step) {
    case 1:
      return (
        <SignupForm
          stateName={'userData'}
          userData={userData}
          nextStep={nextStep}
          prevStep={prevStep}
          handleFormSubmit={handleFormSubmit}
        ></SignupForm>
      );
    case 2:
      return <Finish nextStep={nextStep} prevStep={prevStep} />;
    default:
      navigate('/customer');
  }
};

export default CustomerSignup;
