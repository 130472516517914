import React, { useState } from 'react';
import SignupForm from './signupForm';
import CaptureForm from './captureForm';
import OwnerPrompt from './ownerPrompt';
import VehicleForm from './vehicleForm';
import Terms from './terms';
import Finish from './finish';

const Signup = ({ navigate }) => {
  const [step, setStep] = useState(1);
  const [userData, setUserData] = useState({});
  const [vehicleData, setVehicleData] = useState({});

  const prevStep = () => {
    if (step === 1) return;
    setStep(step - 1);
  };
  const nextStep = () => {
    setStep(step + 1);
  };
  const handleFormSubmit = (type, newData) => {
    if (type === 'userData') setUserData({ ...userData, ...newData });
    if (type === 'vehicleData') setVehicleData({ ...vehicleData, ...newData });
  };

  switch (step) {
    case 1:
      return (
        <SignupForm
          stateName={'userData'}
          userData={userData}
          nextStep={nextStep}
          prevStep={prevStep}
          handleFormSubmit={handleFormSubmit}
        ></SignupForm>
      );
    case 2:
      return (
        <CaptureForm
          type={'license'}
          stateName={'userData'}
          userData={userData}
          nextStep={nextStep}
          prevStep={prevStep}
          handleFormSubmit={handleFormSubmit}
        />
      );
    case 3:
      return (
        <CaptureForm
          type={'id'}
          stateName={'userData'}
          userData={userData}
          nextStep={nextStep}
          prevStep={prevStep}
          handleFormSubmit={handleFormSubmit}
        />
      );
    case 4:
      return (
        <OwnerPrompt
          stateName={'vehicleData'}
          userData={vehicleData}
          nextStep={nextStep}
          prevStep={prevStep}
          handleFormSubmit={handleFormSubmit}
        />
      );
    case 5:
      return (
        <VehicleForm
          stateName={'vehicleData'}
          vehicleData={vehicleData}
          nextStep={nextStep}
          prevStep={prevStep}
          handleFormSubmit={handleFormSubmit}
          
        ></VehicleForm>
      );
    case 6:
      return (
        <CaptureForm
          type={'receipt'}
          stateName={'vehicleData'}
          userData={vehicleData}
          nextStep={nextStep}
          prevStep={prevStep}
          handleFormSubmit={handleFormSubmit}
        />
      );
    case 7:
      return (
        <CaptureForm
          type={'registration'}
          stateName={'vehicleData'}
          userData={vehicleData}
          nextStep={nextStep}
          prevStep={prevStep}
          handleFormSubmit={handleFormSubmit}
        />
      );
    case 8:
      return (
        <Terms
          userData={userData}
          vehicleData={vehicleData}
          nextStep={nextStep}
          prevStep={prevStep}
          handleFormSubmit={handleFormSubmit}
        />
      );
    case 9:
      return <Finish nextStep={nextStep} prevStep={prevStep} />;
    default:
      navigate('/driver');
  }
};

export default Signup;
