import React, { useEffect, useState } from 'react';
import { Button, TextField } from '@mui/material';
import { Search } from '@mui/icons-material';
import { styles } from '../../config/defaultPageStyles';

const TableSearch = ({ onSearch, label = 'Search', value = null}) => {
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (value !== null) setSearch(value);
  }, [value]);

  const handleSearch = () => {
    onSearch(search);
  };

  return (
    <div style={styles.searchContainer}>
      <TextField
        style={styles.searchInput}
        variant="outlined"
        label={label}
        value={search}
        onChange={e => setSearch(e.target.value)}
        size='small'
      />
      <Button
        style={styles.searchButton}
        variant="contained"
        color="primary"
        onClick={handleSearch}
        startIcon={<Search />}
        size='small'
      >
        Search
      </Button>
    </div>
  );
};

export default TableSearch;
