import React from 'react';
import RecoveryForm from '../../../components/LoginForm/recoverForm';

const RecoverForm = () => {
  return (
    <RecoveryForm nextPage={'/admin/login'} userType={'admin'} allowEmailOtp={false} />
  );
};

export default RecoverForm;
