import { useContext } from 'react';
import {
  Container,
  CssBaseline,
  ThemeProvider,
  createTheme,
} from '@mui/material';
import Controller from './pages/Controller';
import UserProvider from './context/UserProvider';
import './App.css';
import { StyleContext } from './context/StyleProvider';
import { isMobile } from 'react-device-detect';

const theme = createTheme({
  palette: {
    primary: {
      light: '#000FA0',
      main: '#000FA0',
      dark: '#333fb3',
      contrastText: '#fff',
    },
    secondary: {
      light: '#FF2443',
      main: '#FF2443',
      dark: '#ff4f68',
      contrastText: '#fff',
    },
  },
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        contained: {
          width: '100%',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          width: '100%',
          paddingBottom: '15px',
          input: {
            backgroundColor: '#fff',
            borderRadius: '5px',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
          marginBottom: '15px',
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          height: '100vh',
          padding: '15px 0',
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          color: '#000FA0',
        },
        colorPrimary: {
          '&.Mui-checked': {
            color: '#FF2443',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          // backgroundColor: '#056597',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          display: 'flex',
        },
      },
    },
  },
});

function App() {
  const { maxWidth } = useContext(StyleContext);
  const currentURL = window.location.pathname;
  
  if (currentURL.includes('/dropoff') || !isMobile) {
    return (
      <ThemeProvider theme={theme}>
        <UserProvider>
          <Controller />
        </UserProvider>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <UserProvider>
        <CssBaseline />
        <Container maxWidth={maxWidth}>
          <Controller />
        </Container>
      </UserProvider>
    </ThemeProvider>
  );
}

export default App;
